import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Checkbox,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Typography,
  Avatar,
  Chip,
  InputAdornment,
} from "@mui/material";

import { Search } from "@mui/icons-material";

import CardStyle from "../../shared/general/Card";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import {
  addAndUpdateEmployeeInContract,
  getAllContractEmployees
} from "../../../../actions/contract-employees";
import { getEmployeesPublic } from "../../../../actions/employee";

const StyledAvatar = styled(Avatar)({
  width: 30,
  height: 30,
  marginRight: 8,
});

const StyledCardHeader = styled(CardHeader)({
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 8,
  paddingBottom: 8,
});

const StyledWrapRepresent = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiChip-root": {
    marginLeft: 8,
    backgroundColor: "#fec90f",
    borderRadius: 4,
    height: 18,
    fontWeight: 600,
    fontSize: 10,
    "& .MuiChip-label": {
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  "& .MuiTypography-root": {
    fontSize: 14,
    fontWeight: 500,
    marginRight: 8,
  },
});

const StyledTextHead = styled(Typography)({
  fontSize: 18,
  fontWeight: 500,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
});

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function exclude(arr1, arr2) {
  return arr1.filter((o1) => arr2.map((o2) => o2.idEmp).indexOf(o1.idEmp) === -1);
}
  

const SelectedEmployees = (props) => {
  const {
    selectedContract,
    modeSelected,
    handleOpenAlert,
    handleChangeAlertType
  } = props;
  const { result: employeeList } = useSelector((state) => state.employees);
  const [checked, setChecked] = React.useState([]);
  const [searchName, setSearchName] = React.useState({
    left: "",
    rigth: "",
  });
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [rightright, setRightRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const dispatch = useDispatch();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleCheckSubmit = async () => {

    let deleteEmployeesInContract = left.filter(x => x.idEmployeesInContract > 0)
    let addEmployeesInContract = right.filter(x => !(x.idEmployeesInContract > 0))
  
    let dataForm = {
      delete: deleteEmployeesInContract,
      add: addEmployeesInContract
    }

    const response = await dispatch(addAndUpdateEmployeeInContract(selectedContract.idContractEmployees, dataForm));

    if (response) {
      if (response.status === 200) {
        dispatch(getAllContractEmployees());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }

      handleOpenAlert()
    }
  };

  useEffect(() => {
    // console.log("allEmployeesInContract :", allEmployeesInContract)
    // if (allEmployeesInContract) {
    //   let tempPermanent = allEmployeesInContract.permanent.filter(
    //     (data) => data.idContractEmployees === idContractEmployees
    //   );
    //   let tempReserve = allEmployeesInContract.reserve.filter(
    //     (data) => data.idContractEmployees === idContractEmployees
    //   );
    //   let tempActive;
    //   if (tempPermanent && tempReserve) {
    //     tempActive = allEmployeesInContract.active.filter(
    //       (data) =>
    //         !tempPermanent.map((value) => value.idEmp).includes(data.idEmp) &&
    //         !tempReserve.map((value) => value.idEmp).includes(data.idEmp) &&
    //         (data.idContractEmployees === idContractEmployees ||
    //           data.idContractEmployees === null)
    //     );
    //     console.log("tempActive", tempActive);
    //     /* console.log("tempPermanent", tempPermanent) */
    //   }
    //   if (tempActive && tempReserve && tempPermanent) {
    //     setTempPermanent(tempPermanent);
    //     setTempReserve(tempReserve);
    //     setRight(tempPermanent);
    //     setRightRight(tempReserve);
    //     setLeft(tempActive);
    //   }
    // }
    if (selectedContract) {
      dispatch(getEmployeesPublic());
      let temp = [
        ...selectedContract.employeePermanent,
        ...selectedContract.employeeSpare,
      ];

      temp = temp.filter(
        (x) =>
          x.firstname.indexOf(searchName.rigth) >= 0 ||
          x.lastname.indexOf(searchName.rigth) >= 0
      );
      setRight(temp);
    }
  }, [selectedContract]);

  useEffect(() => {
    if (searchName) {
      let temp = [
        ...selectedContract.employeePermanent,
        ...selectedContract.employeeSpare,
      ];

      temp = temp.filter(
        (x) =>
          x.firstname.indexOf(searchName.rigth) >= 0 ||
          x.lastname.indexOf(searchName.rigth) >= 0
      );
      setRight(temp);
    }
  }, [searchName]);

  useEffect(() => {
    if (employeeList && right) {
      setLeft(exclude(employeeList, right))
    }
  }, [employeeList]);

  const handleChangeSearch = (event) => {
    const { name, value } = event.target;
    setSearchName({ ...searchName, [name]: value });
  };

  const customList = (title, items, position) => (
    <CardStyle style={{ width: 300 }}>
      <StyledCardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        style={{
          width: 300,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>

              <StyledAvatar
                alt={"username"}
                src={`${process.env.REACT_APP_API_URL}image/vendor.jpg`}
              />

              <ListItemText
                id={labelId}
                primary={`${value.firstname} ${value.lastname}`}
                secondary={value.positionName}
              />
              {position === "rightright" && (
                <ListItemIcon>
                  <StyledWrapRepresent>
                    <Chip label={`RESERVE`} size="small" />
                  </StyledWrapRepresent>
                </ListItemIcon>
              )}
            </ListItem>
          );
        })}
      </List>
    </CardStyle>
  );

  return (
    <Fragment>
      <StyledTextHead align="center" variant="subtitle1" gutterBottom>
        {modeSelected === "add"
          ? "เพิ่ม/ลบ พนักงานในสัญญา"
          : "สลับเปลี่ยนพนักงาน"}
      </StyledTextHead>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>
          <div style={{ marginBottom: 16 }}>
            <StyledContentLabel color="text.third" gutterBottom>
              ค้นหาชื่อ
            </StyledContentLabel>
            <TextFieldTheme
              name="left"
              onChange={handleChangeSearch}
              placeholder="ค้นหา"
              style={{
                borderRadius: "16px",
                width: "100%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {customList(
            modeSelected === "add" ? "พนักงานทั้งหมด" : "พนักงานสำรองทั้งหมด",
            left,
            "left"
          )}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              style={{ marginTop: 4, marginBottom: 4 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              style={{ marginTop: 4, marginBottom: 4 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <div style={{ marginBottom: 16 }}>
            <StyledContentLabel color="text.third" gutterBottom>
              ค้นหาชื่อ
            </StyledContentLabel>
            <TextFieldTheme
              name="rigth"
              onChange={handleChangeSearch}
              placeholder="ค้นหา"
              style={{
                borderRadius: "16px",
                width: "100%",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {customList(
            modeSelected === "add" ? "พนักงานในสัญญา" : "พนักงานที่เลือก",
            right,
            "right"
          )}
        </Grid>
        {/* <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight2}
              disabled={leftChecked2.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft2}
              disabled={rightChecked2.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList(modeSelected === "add"
          ? "พนักงานสำรองทั้งหมด"
          : "พนักงานสำรองทั้งหมด", rightright, "rightright")}</Grid>
        <Grid item></Grid> */}
      </Grid>
      <Grid style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
        <ButtonBlue
          variant="contained"
          disabled={
            right.length === 0 && rightright.length === 0 /* || (date) */
          }
          onClick={() => {
            handleCheckSubmit();
          }}
        >
          บันทึกข้อมูล
        </ButtonBlue>
      </Grid>
    </Fragment>
  );
};

export default SelectedEmployees;
