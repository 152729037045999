import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Box,
  Container,
  FormControl,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Typography,
  InputLabel,
  Grid,
} from "@mui/material";

import { Dashboard, Filter1, Filter2, Filter3 } from "@mui/icons-material";

import OverviewVendor from "./overviewVendor";
import IncomeAndExpenses from "./incomeAndExpenses";
import OverviewCompany from "./overviewCompany";
import DashboardOT from "./dashboard/ot/DashboardOT";
import DashboardWorkingTime from "./dashboard/workingTime/DashboardWorkingTime";
import DashboardCost from "./dashboard/cost/DashboardCost";

import {
  getAllCompanysByIdVendor,
  getCompanyBranch,
} from "../../../../actions/company";

import { getAllEmployeeByIdVendorAndIdCompany } from "../../../../actions/user";

import { getAllUsersVendor } from "../../../../actions/vendor";

import { getCompanyContract } from "../../../../actions/vendor";

import "dayjs/locale/th";
import OverviewPanel from "./overviewPanel";
import OvertimePanel from "./overtimePanel";
import WorkingtimePanel from "./workingTimePanel";
import CostPanel from "./costPanel";
import { getAllDepartments } from "../../../../actions/department";
import {
  getDashboardCost,
  getDashboardOvertime,
  getDashboardOverview,
  getDashboardWorkingtime,
} from "../../../../actions/dashboard";

let MockCompany = ["ไทยโพลิเอททีลีน", "กระทิงแดง", "โคคาโคล่า"];

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .filter-container": {
    marginBottom: "24px",
    display: "flex",
    "& .filter-left": {
      flexGrow: "1",
      display: "flex",
      gap: "8px",
    },
    "& .filter-right": {
      display: "flex",
      gap: "8px",
    },
    "& .MuiInputBase-root": {
      minWidth: "150px",
      backgroundColor: "#ffffff",
      "& .MuiSelect-select": {
        "&:focus": {
          backgroundColor: "#ffffff",
        },
      },
    },
    "& .MuiInputLabel-root": {
      color: "#919eab",
    },
    "& .select-medium": {
      minWidth: "250px",
    },
  },
  "& .tabs-container": {
    marginBottom: "24px",
  },
});

const TabsStyled = styled(Tabs)({
  width: "fit-content",
  maxWidth: "1200px",
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  borderRadius: "16px",
  "& .tab": {
    backgroundColor: "#FFFFFF",
    height: "fit-content",
  },
  "& .TabBorderRight": {
    borderRight: "1px solid rgba(0,0,0,0.1)",
  },
  "& span": {
    display: "none",
  },
  "& .Mui-selected": {
    backgroundColor: "#007afe",
    color: "white !important",
    border: "1px solid #007afe",
  },
});

const Header = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "20px",
});

const SelectCompany = styled(Select)(({ company }) => ({
  backgroundColor: "white",
  color: company === "" ? "darkgrey" : "initial",
  "& .MuiSelect-select": {
    height: "14px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
}));

const SelectMonth = styled(Select)(({ month }) => ({
  backgroundColor: "white",
  color: month === 12 ? "darkgrey" : "initial",
  "& .MuiSelect-select": {
    height: "14px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
}));

const Overview = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: AllCompanys } = useSelector((state) => state.companys);
  const { result: AllBranches } = useSelector((state) => state.companyBranch);
  const [company, setCompany] = useState("");
  const [month, setMonth] = useState(dayjs().get("month") + 1);
  const [year, setYear] = useState(dayjs().get("year"));
  const [branch, setBranch] = useState("all");

  const [department, setDepartment] = useState("");
  const [section, setSection] = useState("");
  const [tabValue, setTabValue] = useState("overviewVendor");

  const { result: dashboardOverview } = useSelector(
    (state) => state.dashboardOverview
  );
  const { result: dashboardOvertime } = useSelector(
    (state) => state.dashboardOvertime
  );
  const { result: dashboardWorkingtime } = useSelector(
    (state) => state.dashboardWorkingtime
  );
  const { result: dashboardCost } = useSelector((state) => state.dashboardCost);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: departmentStore } = useSelector((state) => state.department);
  const { result: companyStore } = useSelector((state) => state.vendorContract);

  useEffect(() => {
    if (tabValue === "dashboardOverview") {
      dispatch(
        getDashboardOverview({
          mode: "vendor",
          month: month,
          year: year,
          idCompany: company ? company : undefined,
          idDepartment: department ? department : undefined,
          idSection: section ? section : undefined,
        })
      );
    } else if (tabValue === "dashboardOT") {
      dispatch(
        getDashboardOvertime({
          mode: "vendor",
          month: month,
          year: year,
          idCompany: company ? company : undefined,
          idDepartment: department ? department : undefined,
          idSection: section ? section : undefined,
        })
      );
    } else if (tabValue === "dashboardWorkingTime") {
      dispatch(
        getDashboardWorkingtime({
          mode: "vendor",
          month: month,
          year: year,
          idCompany: company ? company : undefined,
          idDepartment: department ? department : undefined,
          idSection: section ? section : undefined,
        })
      );
    } else if (tabValue === "dashboardCost") {
      dispatch(
        getDashboardCost({
          mode: "vendor",
          month: month,
          year: year,
          idCompany: company ? company : undefined,
          idDepartment: department ? department : undefined,
          idSection: section ? section : undefined,
        })
      );
    }
  }, [tabValue, company, department, section, month, year]);

  useEffect(() => {
    dispatch(getCompanyContract());
  }, []);

  useEffect(() => {
    if (company) {
      dispatch(getAllDepartments({ idCompany: company }));
    }
  }, [company]);

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{
          marginTop: "20px",
          height: "fit-content",
          minHeight: "calc(100vh - 100px)",
          paddingBottom: "40px",
        }}
      >
        <Box className="filter-container">
          <Box className="filter-left">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl variant="filled">
                  <InputLabel shrink>{t("Company")}</InputLabel>
                  <Select
                    // className="select-medium"
                    fullWidth
                    label={`${t("Company")}`}
                    displayEmpty
                    disableUnderline
                    value={company}
                    onChange={(e) => {
                      setCompany(e.target.value);
                      setDepartment("");
                      setSection("");
                    }}
                  >
                    {/* <MenuItem value={""}>ทั้งหมด</MenuItem> */}
                    {companyStore &&
                      companyStore.map((c) => (
                        <MenuItem key={c.idCompany} value={c.idCompany}>
                          {c.companyName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl variant="filled">
                  <InputLabel shrink>{t("Department")}</InputLabel>
                  <Select
                    className="select-medium"
                    label={`${t("Department")}`}
                    displayEmpty
                    disableUnderline
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                      setSection("");
                    }}
                  >
                    <MenuItem value={""}>{t("All")}</MenuItem>
                    {departmentStore &&
                      departmentStore.map((d) => (
                        <MenuItem key={d.idDepartment} value={d.idDepartment}>
                          {d.departmentName}{" "}
                          {d.divisionName ? `(${d.divisionName})` : null}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl variant="filled">
                  <InputLabel shrink>{t("Section")}</InputLabel>
                  <Select
                    className="select-medium"
                    label={`${t("Section")}`}
                    notched
                    displayEmpty
                    disableUnderline
                    value={section}
                    onChange={(e) => {
                      setSection(e.target.value);
                    }}
                  >
                    <MenuItem value={""}>{t("All")}</MenuItem>
                    {departmentStore &&
                      department &&
                      departmentStore
                        .find((d) => d.idDepartment === department)
                        .sections.map((s) => (
                          <MenuItem key={s.idSection} value={s.idSection}>
                            {s.sectionName}{" "}
                            {s.sectionName ? `(${s.sectionName})` : null}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box className="filter-right">
            <FormControl variant="filled">
              <InputLabel>{t("Month")}</InputLabel>
              <Select
                label={`${t("Month")}`}
                displayEmpty
                disableUnderline
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              >
                {[...Array(12)].map((_, index) => {
                  if (
                    dayjs()
                      .set("month", index)
                      .set("year", year)
                      .isSameOrAfter(
                        dayjs().set("month", 1).set("year", 2023)
                      ) &&
                    !dayjs()
                      .set("month", index)
                      .set("year", year)
                      .isAfter(dayjs())
                  ) {
                    return (
                      <MenuItem key={index} value={index + 1}>
                        {dayjs().month(index).format("MMMM")}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
            <FormControl variant="filled">
              <InputLabel>{t("Year")}</InputLabel>
              <Select
                label={`${t("Year")}`}
                displayEmpty
                disableUnderline
                value={year}
                onChange={(event) => {
                  if (
                    dayjs()
                      .set("month", month - 1)
                      .set("year", parseInt(event.target.value))
                      .isBefore(dayjs().set("month", 1).set("year", 2023))
                  ) {
                    setMonth(2);
                  } else if (
                    dayjs()
                      .set("month", month - 1)
                      .set("year", parseInt(event.target.value))
                      .isAfter(dayjs())
                  ) {
                    setMonth(dayjs().get("month") + 1);
                  }
                  setYear(event.target.value);
                }}
              >
                {[...Array(dayjs().format("YYYY") - 2023 + 1)].map(
                  (_, index) => (
                    <MenuItem
                      key={index}
                      value={Number(
                        dayjs().subtract(index, "year").format("YYYY")
                      )}
                    >
                      {parseInt(dayjs().format("YYYY")) - index}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box className="tabs-container">
          <TabsStyled
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
          >
            <Tab
              className="tab TabBorderRigth"
              icon={<Dashboard />}
              iconPosition="start"
              label={`${t("CustomerOverview")}`}
              value="overviewVendor"
            />
            <Tab
              className="tab TabBorderRigth"
              icon={<Dashboard />}
              iconPosition="start"
              label={`${t("Income")}-${t("Expense")}`}
              value="incomeAndExpenses"
            />
            <Tab
              className="tab TabBorderRight"
              icon={<Dashboard />}
              iconPosition="start"
              label={`${t("Overview")}`}
              value="dashboardOverview"
            />
            <Tab
              className="tab TabBorderRight"
              icon={<Filter1 />}
              iconPosition="start"
              label={`${t("Overtime")}`}
              value="dashboardOT"
            />
            <Tab
              className="tab TabBorderRight"
              icon={<Filter2 />}
              iconPosition="start"
              label={`${t("WorkTimeOverview")}`}
              value="dashboardWorkingTime"
            />
            <Tab
              className="tab"
              icon={<Filter3 />}
              iconPosition="start"
              label={`${t("CostOverview")}`}
              value="dashboardCost"
            />
          </TabsStyled>
        </Box>

        {tabValue === "overviewVendor" && <OverviewVendor />}
        {tabValue === "incomeAndExpenses" && <IncomeAndExpenses />}

        {tabValue === "dashboardOverview" && (
          <Box>
            {dashboardOverview ? (
              <OverviewPanel month={month} year={year} />
            ) : (
              <Typography textAlign="center">{t("LoadingData")}...</Typography>
            )}
          </Box>
        )}

        {tabValue === "dashboardOT" && (
          <Box>
            {dashboardOvertime ? (
              <OvertimePanel month={month} year={year} />
            ) : (
              <Typography textAlign="center">{t("LoadingData")}...</Typography>
            )}
          </Box>
        )}

        {tabValue === "dashboardWorkingTime" && (
          <Box>
            {dashboardWorkingtime ? (
              <WorkingtimePanel month={month} year={year} />
            ) : (
              <Typography textAlign="center">{t("LoadingData")}...</Typography>
            )}
          </Box>
        )}

        {tabValue === "dashboardCost" && (
          <Box>
            {dashboardCost ? (
              <CostPanel month={month} year={year} />
            ) : (
              <Typography textAlign="center">{t("LoadingData")}...</Typography>
            )}
          </Box>
        )}
      </Container>
    </StyledRoot>
  );
};

export default Overview;
