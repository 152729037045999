import React from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import FormContainer from "./FormContainer";
import NumberFormat from "react-number-format";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const BasicInfo = (props) => {
  const { formik } = props;
  const { t, i18n } = useTranslation();

  return (
    <FormContainer title={`${t("GeneralInfo")}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography>{t("EmployeeID")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="employeeId"
            value={formik.values.employeeId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.employeeId && Boolean(formik.errors.employeeId)
            }
            helperText={
              formik.touched.employeeId &&
              Boolean(formik.errors.employeeId) &&
              formik.errors.employeeId
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Prefix")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={
              formik.touched.title &&
              Boolean(formik.errors.title) &&
              formik.errors.title
            }
            fullWidth
            select
          >
            <MenuItem value="นาย">{t("Mr")}</MenuItem>
            <MenuItem value="นาง">{t("Mrs")}</MenuItem>
            <MenuItem value="นางสาว">{t("Miss")}</MenuItem>
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("FirstName")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="firstname"
            value={formik.values.firstname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
            helperText={
              formik.touched.firstname &&
              Boolean(formik.errors.firstname) &&
              formik.errors.firstname
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("LastName")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="lastname"
            value={formik.values.lastname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
            helperText={
              formik.touched.lastname &&
              Boolean(formik.errors.lastname) &&
              formik.errors.lastname
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{`${t("Prefix")} (${t("Eng")})`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="title_en"
            value={formik.values.title_en}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title_en && Boolean(formik.errors.title_en)}
            helperText={
              formik.touched.title_en &&
              Boolean(formik.errors.title_en) &&
              formik.errors.title_en
            }
            fullWidth
            select
          >
            <MenuItem value="Mr.">Mr.</MenuItem>
            <MenuItem value="Ms.">Ms.</MenuItem>
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{`${t("FirstName")} (${t("Eng")})`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="firstname_en"
            value={formik.values.firstname_en}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.firstname_en && Boolean(formik.errors.firstname_en)
            }
            helperText={
              formik.touched.firstname_en &&
              Boolean(formik.errors.firstname_en) &&
              formik.errors.firstname_en
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{`${t("LastName")} (${t("Eng")})`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="lastname_en"
            value={formik.values.lastname_en}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.lastname_en && Boolean(formik.errors.lastname_en)
            }
            helperText={
              formik.touched.lastname_en &&
              Boolean(formik.errors.lastname_en) &&
              formik.errors.lastname_en
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Nickname")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="nickname"
            value={formik.values.nickname}
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.nickname && Boolean(formik.errors.nickname)}
            helperText={
              formik.touched.nickname &&
              Boolean(formik.errors.nickname) &&
              formik.errors.nickname
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Gender")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="gender"
            value={formik.values.gender}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.gender && Boolean(formik.errors.gender)}
            helperText={
              formik.touched.gender &&
              Boolean(formik.errors.gender) &&
              formik.errors.gender
            }
            fullWidth
            select
          >
            <MenuItem value="ชาย">{t("Male")}</MenuItem>
            <MenuItem value="หญิง">{t("Female")}</MenuItem>
            <MenuItem value="อื่นๆ">{t("Other")}</MenuItem>
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("BirthDate")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
            <DatePicker
              // label=""
              name="birthday"
              value={formik.values.birthday}
              onChange={(date) => {
                formik.setFieldValue("birthday", date);
              }}
              openTo="year"
              views={["year", "month", "day"]}
              // onBlur={() => {
              //   formik.setFieldTouched("birthday");
              // }}
              renderInput={(params) => (
                <TextFieldTheme
                  {...params}
                  error={
                    formik.touched.birthday && Boolean(formik.errors.birthday)
                  }
                  helperText={
                    formik.touched.birthday &&
                    Boolean(formik.errors.birthday) &&
                    formik.errors.birthday
                  }
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "DD/MM/YYYY",
                  }}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("IDCardNumber")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <NumberFormat
            customInput={TextFieldTheme}
            // format="# #### ##### ## #"
            format={(value) => {
              if(value.length >= 13){
                return String(value).replace(/(\d)(\d{1,4})(\d{1,5})(\d{1,2})(\d)/,"$1-$2-$3-$4-$5")
              } else if(value.length >= 11) {
                return String(value).replace(/(\d)(\d{1,4})(\d{1,5})(\d{1,2})/,"$1-$2-$3-$4")
              } else if(value.length >= 6){
                return String(value).replace(/(\d)(\d{1,4})(\d{1,5})/,"$1-$2-$3")
              } else if(value.length >= 2){
                return String(value).replace(/(\d)(\d{1,4})/,"$1-$2")
              } else {
                return String(value)
              }
            }}
            inputProps={{
              maxLength: 17,
            }}
            name="personalId"
            value={formik.values.personalId}
            // onChange={formik.handleChange}
            onValueChange={(values) => {
              formik.setFieldValue("personalId", values.value);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personalId && Boolean(formik.errors.personalId)
            }
            helperText={
              formik.touched.personalId &&
              Boolean(formik.errors.personalId) &&
              formik.errors.personalId
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("PhoneNumber")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <NumberFormat
            customInput={TextFieldTheme}
            // format="###-###-####"
            format={(value) => {
              if(value.length >= 7){
                return String(value).replace(/(\d{1,3})(\d{1,3})(\d{1,4})/,"$1-$2-$3")
              } else if(value.length >= 4){
                return String(value).replace(/(\d{1,3})(\d{1,3})/,"$1-$2")
              } else {
                return String(value)
              }
            }}
            inputProps={{
              maxLength: 12,
            }}
            name="telephoneMobile"
            value={formik.values.telephoneMobile}
            // onChange={formik.handleChange}
            onValueChange={(values) => {
              formik.setFieldValue("telephoneMobile", values.value);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.telephoneMobile &&
              Boolean(formik.errors.telephoneMobile)
            }
            helperText={
              formik.touched.telephoneMobile &&
              Boolean(formik.errors.telephoneMobile) &&
              formik.errors.telephoneMobile
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Email")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={
              formik.touched.email &&
              Boolean(formik.errors.email) &&
              formik.errors.email
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Address")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                name="houseNo"
                value={formik.values.houseNo}
                onChange={formik.handleChange}
                fullWidth
                // placeholder={`${t("HouseNumber")}`}
                inputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
                label={`${t("HouseNumber")}`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                name="vilage"
                value={formik.values.vilage}
                onChange={formik.handleChange}
                fullWidth
                // placeholder={`${t("Village")}`}
                inputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
                label={`${t("Village")}`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                name="vilageNo"
                value={formik.values.vilageNo}
                onChange={formik.handleChange}
                fullWidth
                // placeholder={`${t("VillageNo")}`}
                inputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
                label={`${t("VillageNo")}`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                name="road"
                value={formik.values.road}
                onChange={formik.handleChange}
                fullWidth
                // placeholder={`${t("Road")}`}
                inputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
                label={`${t("Road")}`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                name="alley"
                value={formik.values.alley}
                onChange={formik.handleChange}
                fullWidth
                // placeholder={`${t("Alley")}`}
                inputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
                label={`${t("Alley")}`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                name="areaCode"
                value={formik.values.areaCode}
                onChange={formik.handleChange}
                fullWidth
                // placeholder={`${t("PostalCode")}`}
                inputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
                label={`${t("PostalCode")}`}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography>{t("SubdistrictArea")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="subDistrict"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.subDistrict}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("DistrictArea")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="district"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.district}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Province")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="provience"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.provience}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Nationality")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="nationality"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.nationality}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("MaritalStatus")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="maritalStatus"
            value={formik.values.maritalStatus}
            onChange={formik.handleChange}
            fullWidth
            select
          >
            <MenuItem value="โสด">{t("Single")}</MenuItem>
            <MenuItem value="สมรส">{t("Married")}</MenuItem>
            <MenuItem value="หม้าย">{t("Widowed")}</MenuItem>
            <MenuItem value="หย่า">{t("Divorced")}</MenuItem>
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Passport")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="passportId"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.passportId}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("WorkPermit")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="workPermit"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.workPermit}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("EmergencyContact")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="emergencyContact"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.emergencyContact}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("EmergencyPhone")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <NumberFormat
            customInput={TextFieldTheme}
            format="###-###-####"
            name="emergencyPhone"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.emergencyPhone}
            // onChange={formik.handleChange}
            onValueChange={(values) => {
              formik.setFieldValue("emergencyPhone", values.value);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.emergencyPhone &&
              Boolean(formik.errors.emergencyPhone)
            }
            helperText={
              formik.touched.emergencyPhone &&
              Boolean(formik.errors.emergencyPhone) &&
              formik.errors.emergencyPhone
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("EmergencyRelationship")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="emergencyRelationship"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.emergencyRelationship}
            onChange={formik.handleChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default BasicInfo;
