import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Grid, styled, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useTranslation } from "react-i18next";

import DrawerContact from "./drawerContact";
import Logo from "./logo";
import AuthorizedSignatureOne from "./authorizedSignatureOne";
import AuthorizedSignatureTwo from "./authorizedSignatureTwo";
import WitnessSignature from "./witnessSignature";

const StyledRoot = styled("div")({
  padding: 24,
  "& .dropzone-container": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
    margin: "auto",
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
    "& .inner-dropzone-config": {
      width: "100%",
      outline: "none",
      position: "relative",
      cursor: "pointer",
    },
    "& .company-logo": {
      height: 160,
    },
    "& .company-signature": {
      height: 100,
    },
    "& img": {
      objectFit: "cover",
      objectPosition: "center",
      width: "inherit",
      height: "inherit",
    },
    "& .placeholder-dropzone": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      textAlign: "center",
      "& .MuiTypography-root": {
        color: "#9e9e9e",
      },
    },
  },
  "& .captionText": {
    fontSize: 10,
    color: "#9e9e9e",
  },
});

const DividerStyled = styled(Divider)({
  borderBottom: "thin dashed rgba(145, 158, 171, 0.32)",
});

const LeftContainer = (props) => {
  const { result: vendorProfile } = useSelector((state) => state.vendorProfile);
  const [isOpenDrawerContact, setIsOpenDrawerContact] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Logo data={vendorProfile.vendorImageURL} />
        </Grid>
        <Grid item xs={12}>
          <DividerStyled />
        </Grid>
        <Grid item xs={12}>
          <Typography className="headerText">{t("CompanyName")}</Typography>
          <Typography className="valueText">
            {vendorProfile.vendorName || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="headerText">
            {t("CompanyAbbreviation")}
          </Typography>
          <Typography className="valueText">
            {vendorProfile.vendorNameShort || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="headerText">{t("ContactName")}</Typography>
          <Typography className="valueText">
            {vendorProfile.mainContactName || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="headerText">{t("Email")}</Typography>
          <Typography className="valueText">
            {vendorProfile.mainContactEmail || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="headerText">{t("PhoneNumber")}</Typography>
          <Typography className="valueText">
            {vendorProfile.mainContactPhone || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => {
              setIsOpenDrawerContact(true);
            }}
          >
            {t("Edit")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <DividerStyled />
        </Grid>
        <Grid item xs={12}>
          <AuthorizedSignatureOne
            data={vendorProfile.authorizedSignatureOneImageURL}
          />
        </Grid>
        <Grid item xs={12}>
          <AuthorizedSignatureTwo
            data={vendorProfile.authorizedSignatureTwoImageURL}
          />
        </Grid>
        <Grid item xs={12}>
          <WitnessSignature data={vendorProfile.witnessSignatureImageURL} />
        </Grid>
      </Grid>

      {isOpenDrawerContact && (
        <DrawerContact
          open={isOpenDrawerContact}
          handleClose={() => {
            setIsOpenDrawerContact(false);
          }}
          data={vendorProfile}
        />
      )}
    </StyledRoot>
  );
};

export default LeftContainer;
