import {
  Avatar,
  CircularProgress,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import useTable from "../../../../shared/table/useTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const headCells = [
  { id: "image", label: "", minWidth: "70px" },
  { id: "fullName", label: "Full Name", minWidth: "250px" },
  { id: "jobPosition", label: "Position", minWidth: "250px" },
  { id: "lastView", label: "Last View", minWidth: "100px" },
  { id: "actions", label: "", minWidth: "150px" },
];

function DataTable(props) {
  const { data } = props;

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(data, headCells, filterFn);

  return (
    <div style={{ backgroundColor: "#fff", borderRadius: "10px" }}>
      <TblContainer>
        <TblHead />
        <TableBody>
          {(recordsAfterPagingAndSorting() && 
            recordsAfterPagingAndSorting().length > 0 &&
            recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id} hover>
                <TableCell>
                  <Avatar
                    alt={item.fullName}
                    src={`${process.env.REACT_APP_URL}image/profile/${item.image}`}
                  />
                </TableCell>
                <TableCell>{item.fullName}</TableCell>
                <TableCell>{item.position}</TableCell>
                <TableCell>14/03/2022</TableCell>
                <TableCell align="right">
                  <IconButton>
                    <DeleteForeverIcon sx={{ color: "#e53935" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))) || (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No Data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TblContainer>
      <TblPagination />
    </div>
  );
}

export default DataTable;
