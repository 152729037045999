import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Typography,
  Box,
  Container,
  Icon,
  Card,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  IconButton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import {
  getSummaryTime,
  getSummaryTimeByIdEmp,
} from "../../../../actions/summary-time";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerEng from "../../shared/general/DatePickerEng";
import DatePickerThai from "../../shared/general/DatePickerThai";

import SelectVendors from "../shared/selectVendor";

import { getPayrollSetting } from "../../../../actions/paytypes";
import SelectCompany from "../../shared/general/selectCompany";
import CardData from "../../shared/dashboard/CardData";
import utils from "../../../../utils";
import Gauge from "./gauge";
import { getDashboardContract } from "../../../../actions/dashboardContract";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
  "& .select-search": {
    display: "flex",
    justifyContent: "center",
    padding: 32,
    "& .text": {
      fontStyle: "oblique",
      fontWeight: 400,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  "&.group-employee": {
    marginTop: 0,
  },
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginTop: 16,
    marginBottom: 8,
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
  },
});

const StyledCard = styled(Card)({
  height: "100%",
  boxSizing: "border-box",
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  padding: "16px",
  borderRadius: "12px",
  "& .card-data-title-container": {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    "& .title": {
      flexGrow: "1",
      fontSize: "20px",
      color: "#919eab",
    },
  },
  "& .value-container": {
    minHeight: "96px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5%",
  },
  "& .dynamicFontSize-small": {
    fontSize: "30px",
  },
  "& .dynamicFontSize-medium": {
    fontSize: "33px",
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledDatePicker = styled(DatePicker)({
  "& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
    backgroundColor: "#919eab14 !important",
    "&:before": {
      borderBottomStyle: "none !important",
    },
  },
});

const StyledLoading = styled("div")({
  marginTop: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  height: "inherit",
});

const ContractDashboard = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState({ start: dayjs(), end: dayjs().add(1, 'day') });
  const [budget, setBudget] = useState(null);
  const [totalOT, setTotalOT] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [contractData, setContractData] = useState(null);
  const { t, i18n } = useTranslation();

  const sumOT = (contractData) => {
    const calSalary = contractData.reduce(
      (acc, curr) =>
        acc +
        curr.otOneAmount +
        curr.otOneFiveAmount +
        curr.otTwoAmount +
        curr.otThreeAmount,
      0
    );

    setTotalOT(calSalary);
  };

  const handleClickSearch = async () => {
    const res = await dispatch(
      getDashboardContract({
        idVendor: vendor,
        startDate: search.start,
        endDate: search.end,
      })
    );
    if (res.status === 200) {
      setContractData(res.data);
      sumOT(res.data.sectionsOT)
    }
  };

  const handleChangeVendor = (vendor) => {
    if (vendor) {
      setVendor(vendor.idVendor);
    } else {
      setVendor(null);
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {t("รายงานจ้างสัญญา")}
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <StyledBoxSearch>
              <SelectVendors handleChange={handleChangeVendor} />
            </StyledBoxSearch>
          </Grid>
          {/* <Grid item xs={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("ชื่อสัญญา")}
              </Typography>
            
            </StyledBoxSearch>
          </Grid> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("วันที่เริ่ม")}
                </Typography>
                <StyledDatePicker
                  minDate={dayjs().startOf("year")}
                  maxDate={dayjs().endOf("year")}
                  value={search.start}
                  onChange={(newValue) => {
                    setSearch({ ...search, ["start"]: newValue });
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} helperText={null} />
                  )}
                />
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("วันที่สิ้นสุด")}
                </Typography>
                <StyledDatePicker
                  minDate={dayjs().startOf("year")}
                  maxDate={dayjs().endOf("year")}
                  value={search.end}
                  onChange={(newValue) => {
                    setSearch({ ...search, ["end"]: newValue });
                  }}
                  renderInput={(params) => (
                    <StyledTextField {...params} helperText={null} />
                  )}
                />       
              </StyledBoxSearch>   
            </Grid>
          </LocalizationProvider>
          <Grid item xs={3}>
            <StyledBoxSearch>
              <div className="wrap-search-action">
                <ButtonBlue
                  disabled={vendor === null}
                  startIcon={<SearchRoundedIcon />}
                  variant="contained"
                  onClick={handleClickSearch}
                >
                  {t("Search")}
                </ButtonBlue>
              </div>
            </StyledBoxSearch>
          </Grid>
        </Grid>
        {contractData && contractData.sectionsOT.length > 0 ? (
          <>
            <Grid container marginTop={2} marginBottom={3} spacing={2}>
              <Grid item xs={12} md={6}>
                <StyledCard>
                  <Box className="card-data-title-container">
                    <Typography className="title">{"Budget"}</Typography>
                    <IconButton sx={{ color: "#ffcd38" }}>
                      <Icon
                        className="fas fa-edit"
                        color="primary"
                        style={{ display: "inline-table" }}
                      />
                    </IconButton>
                  </Box>
                  <Box className="value-container">
                    <Typography>
                      <span className={"dataValue dynamicFontSize-medium"}>
                        {utils.numberWithCommas(1000000)}
                      </span>
                      <span className="dataUnit">{"บาท"}</span>
                    </Typography>
                  </Box>
                </StyledCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledCard>
                  <Box className="card-data-title-container">
                    <Typography className="title">{"ใช้ไป"}</Typography>
                  </Box>
                  <Gauge 
                    budget={1000000} 
                    totalUse={contractData.totalSalary - totalOT}
                  />
                </StyledCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <CardData
                  title="เงินเดือน"
                  dataValue={utils.numberWithCommas(contractData.totalSalary)}
                  dataUnit="บาท"
                  dynamicFontSize
                  titleIcon={
                    <Icon
                      className="fa-regular fa-badge-dollar"
                      color="error"
                      style={{ display: "inline-table" }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CardData
                  title="รายจ่ายค่าล่วงเวลา"
                  dataValue={utils.numberWithCommas(totalOT)}
                  dataUnit="บาท"
                  dynamicFontSize
                  titleIcon={
                    <Icon
                      className="fa-regular fa-alarm-clock"
                      style={{ display: "inline-table" }}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Typography variant="h6" marginBottom={1}>
              {"OT ส่วนงาน"}
            </Typography>
            <TableContainer
              sx={{
                boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
                borderRadius: "12px",
              }}
              component={Paper}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>ชื่อส่วนงาน</TableCell>
                    <TableCell align="right">{"OT 1"}</TableCell>
                    <TableCell align="right">{"OT 1.5"}</TableCell>
                    <TableCell align="right">{"OT 2"}</TableCell>
                    <TableCell align="right">{"OT 3"}</TableCell>
                    <TableCell align="right">{"OT รวมชั่วโมง"}</TableCell>
                    <TableCell align="right">{"OT รวม(บาท)"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contractData.sectionsOT.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                          <TableCell>{row.sectionName}</TableCell>
                      <TableCell align="right">{row.otOneHours}</TableCell>
                      <TableCell align="right">{row.otOneFiveHours}</TableCell>
                      <TableCell align="right">{row.otTwoHours}</TableCell>
                      <TableCell align="right">{row.otThreeHours}</TableCell>
                      <TableCell align="right">
                        {row.otOneHours +
                          row.otOneFiveHours +
                          row.otTwoHours +
                          row.otThreeHours}
                      </TableCell>
                      <TableCell align="right">
                        {row.otOneAmount +
                          row.otOneFiveAmount +
                          row.otTwoAmount +
                          row.otThreeAmount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography fontSize={20} textAlign={"center"} marginTop={4}>
            ไม่พบข้อมูล
          </Typography>
        )}
      </Container>
    </StyledRoot>
  );
};

export default ContractDashboard;
