import React, { useEffect, useState } from "react";

import { Box, Grid, Paper, styled, TextField, Typography } from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";

import DataTable from "./components/DataTable";
import { useForm } from "react-hook-form";
import DeleteModal from "./components/DeleteModal";

const StyledRoot = styled('div')({
    marginTop: 30,
    "& .MuiTypography-h6": {
        fontSize: "1.5rem",
    },
    "& .headingPage": {
        marginBottom: 32,
    },
    "& .paper": {
        padding: 8,
        borderRadius: 20,
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    }
})

const Index = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [deleteModal, setDeleteModal] = useState({
        companyCode: null,
        isOpen: false,
    })

    const onDeleteChildCompany = () => {
        console.log("delete", deleteModal.companyCode);
        setDeleteModal({
            ...deleteModal,
            isOpen: false,
            companyCode: null,
        })
    }

    // const [addOrEditModal, setAddOrEditModal] = useState({
    //     isOpen: false
    // })

    const [companies, setCompanies] = useState([])

    // const { register, handleSubmit, formState: { errors } } = useForm({
    //     defaultValues: {
    //         companyCode: "",
    //     }
    // })

    // const onAddChildCompany = (data) => {
    //     console.log(data);
    // }
    
    useEffect(() => {
        
        setCompanies([
            {
                companyCode: "TPE",
                companyName: "บริษัท ไทยโพลิเอททีลีน จำกัด",
                employee: 1000,
                contact: "หนองมนคนเดิม\n095-496-8074",
                contactEmail: "mommypoko@gmail.com",
            },
            {
                companyCode: "ROC",
                companyName: "บริษัท ระยองโอเลฟินส์ จำกัด",
                employee: 1000,
                contact: "หนองมนคนเดิม\n095-496-8074",
                contactEmail: "mommypoko@gmail.com",
            },
            {
                companyCode: "NPI",
                companyName: "บริษัท เอ็นพีไอ กรุ๊ป จำกัด",
                employee: 1000,
                contact: "หนองมนคนเดิม\n095-496-8074",
                contactEmail: "mommypoko@gmail.com",
            }
        ])

        setIsLoading(false);
    }, [])

    return(
        <StyledRoot>
            {/* <form onSubmit={handleSubmit(onAddChildCompany)}> */}
                <Box className="headingPage">
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">จัดการบริษัทลูก</Typography>
                        {/* <Grid container item alignItems="center" style={{width: "auto"}}>
                            <TextField
                                placeholder="กรอกโค้ดบริษัท"
                                variant="standard"
                                size="small"
                                InputProps={{...register("companyCode", { required: true})}}
                                error={errors.hasOwnProperty("companyCode")}
                                style={{width: 150, marginRight: 8}}
                            />
                            <ButtonBlue
                                variant={"contained"}
                                startIcon={<AddIcon />}
                                type="submit"
                            >
                                เพิ่มบริษัทลูก
                            </ButtonBlue>
                        </Grid> */}
                    </Grid>
                </Box>
            {/* </form> */}
          <Paper className="paper">
            <DataTable
                isLoading={isLoading}
                companies={companies}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
            />
          </Paper>
          {/* Modal */}
          {deleteModal.isOpen && <DeleteModal 
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
          />}

        </StyledRoot>
    )
}

export default Index;