import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Paper, Typography } from "@mui/material";
// import DataTable from "./components/DataTable";
// import AddOrEditModal from "./components/AddOrEditModal";

import { getAllVendorCompanyLeaveType } from "../../../../../actions/leave";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";
import DrawerLeave from "./components/drawerLeave";
import AccordionRow from "./components/accordionRow";
import { useTranslation } from "react-i18next";
import AlertResponse from "../../../shared/general/AlertResponse";
import LeaveForm from "../../../vendor/setting/leave-manage/components/leaveForm";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

function Index({ vendor }) {
  const classes = useStyles();
  useTitle("วันลา - HR CORE DATA");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: leaveTypeStore } = useSelector((state) => state.leaveType);

  const [leaveTypes, setLeaveTypes] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const [addOrEditModal, setAddOrEditModal] = useState({
    isOpen: false,
    leaveWorks: [],
  });

  const [isOpenDrawerLeave, setIsOpenDrawerLeave] = useState(false);

  const handleCloseDrawer = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    dispatch(
      getAllVendorCompanyLeaveType({
        idCompany: userProfile.idCompany,
        idVendor: vendor.idVendor,
      })
    );
    if (leaveTypeStore !== null) {
      let leaveTypeList = [...leaveTypeStore].reverse();
      setLeaveTypes(leaveTypeList);
    }
  }, [vendor]);

  return (
    <div className={classes.root}>
      {!drawerConfig.isOpen && (
        <>
          <Box className={classes.headingPage}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">วันลา</Typography>
              </Grid>
              <Grid item>
                <ButtonBlue
                  variant={"contained"}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setIsOpenDrawerLeave(true);
                    // setAddOrEditModal({
                    //   ...addOrEditModal,
                    //   isOpen: true,
                    // });
                  }}
                >
                  เพิ่มวันลา
                </ButtonBlue>
              </Grid>
            </Grid>
          </Box>
          <Paper className={classes.paper}>
            {leaveTypeStore && leaveTypeStore.length > 0 ? (
              leaveTypeStore.map((item, index) => (
                <AccordionRow
                  data={item}
                  key={`accordion_${index}`}
                  setDrawerConfig={setDrawerConfig}
                  setAlertType={setAlertType}
                  setOpenAlert={setOpenAlert}
                  vendor={vendor}
                />
              ))
            ) : (
              <Typography style={{ textAlign: "center", color: "red" }}>
                {t("NoData")}
              </Typography>
            )}
          </Paper>
        </>
      )}
      {isOpenDrawerLeave && (
        <DrawerLeave
          vendor={vendor}
          open={isOpenDrawerLeave}
          handleClose={() => setIsOpenDrawerLeave(false)}
        />
      )}
      {drawerConfig.isOpen && (
        <LeaveForm
          drawerConfig={drawerConfig}
          onClose={handleCloseDrawer}
          setAlertType={setAlertType}
          setOpenAlert={setOpenAlert}
          selectedCompany={vendor}
        />
      )}
      {openAlert && (
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      )}
    </div>
  );
}

export default Index;
