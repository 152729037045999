import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, InputAdornment, styled, Typography } from "@mui/material";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { useFormik } from "formik";
import NumberFormat from "react-number-format";
import {
  getLeaveQuota,
  updateLeaveCarry,
} from "../../../../../../actions/employee";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const { open, setOpenEdit } = props;

  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);

  const [editCarryList, setEditCarryList] = useState([]);
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: {
      carry: [],
    },
    onSubmit: async (values) => {
      const carry = [];

      values.carry.map((v) => {
        if (
          Number(v.carry) !==
          Number(
            editCarryList.find((c) => c.idLeaveType === v.idLeaveType).carry
          )
        ) {
          carry.push(v);
        }
      });

      const formData = {
        carry: carry,
      };

      const res = await dispatch(
        updateLeaveCarry(employeeProfile.idEmp, formData)
      );

      if (res) {
        dispatch(getLeaveQuota(employeeProfile.idEmp));
        setOpenEdit(false);
      }
    },
  });

  const handleCloseDialog = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    if (leaveTypeList !== null) {
      formik.setValues({
        carry: leaveTypeList
          .filter((l) => l.carryValue)
          .map((l) => ({
            idLeaveType: l.idLeaveType,
            carry: l.carry && l.carry.carry ? l.carry.carry : 0,
            idLeaveQuota:
              l.carry && l.carry.idLeaveQuota ? l.carry.idLeaveQuota : null,
            name: l.name,
          })),
      });

      setEditCarryList(
        leaveTypeList
          .filter((l) => l.carryValue)
          .map((l) => ({
            idLeaveType: l.idLeaveType,
            carry: l.carry && l.carry.carry ? l.carry.carry : 0,
            idLeaveQuota:
              l.carry && l.carry.idLeaveQuota ? l.carry.idLeaveQuota : null,
            name: l.name,
          }))
      );
    }
  }, [leaveTypeList, open]);

  return (
    <DrawerCustom
      title={`${t("Edit")} ${t("NumberLeaveDays")}`}
      anchor={"right"}
      open={open}
      onClose={() => setOpenEdit(false)}
    >
      <StyledRoot>
        <form autoComplete="off">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("CumulativeRights")}</StyledContentLabel>
            </Grid>

            {formik.values.carry.map((leave, index) => (
              <Fragment>
                <Grid
                  key={`label-${index}`}
                  item
                  xs={12}
                  sm={9}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography>{leave.name}</Typography>
                </Grid>
                <Grid key={`field-${index}`} item xs={12} sm={3}>
                  <TextFieldTheme
                    name={`carry.${index}.carry`}
                    value={formik.values.carry[index].carry}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {t("Days")}
                        </InputAdornment>
                      ),
                      // inputComponent: NumberFormat
                    }}
                    // inputProps={{
                    //   allowNegative: false,
                    //   thousandSeparator:","
                    // }}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleCloseDialog}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue
              variant="contained"
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}
            >
              {t("Save")}
            </ButtonBlue>
          </StyledFooter>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
