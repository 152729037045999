import React, { Fragment, useEffect } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Divider, Grid, IconButton, styled, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import { updatePFSchedule, addPFSchedule } from "../../../../../actions/vendor";

const StyledRoot = styled("div")({
  width: 400,
  padding: 16,
});

const DrawerTablePF = (props) => {
  const { open, onClose, data, handleUpdateListSchedulePF } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { result: AllSchedules } = useSelector(
    (state) => state.vendorPfSchedule
  );

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, dirtyFields },
    setValue,
  } = useForm({
    defaultValues: {
      listChanging:
        AllSchedules && AllSchedules.length > 0
          ? [
              {
                startDate: dayjs(),
                endDate: dayjs().add(1, "day"),
                useDate: dayjs().add(1, "day"),
              },
            ]
          : [
              {
                startDate: dayjs(),
                endDate: dayjs().add(1, "day"),
                useDate: dayjs().add(1, "day"),
              },
              {
                startDate: dayjs(),
                endDate: dayjs().add(1, "day"),
                useDate: dayjs().add(1, "day"),
              },
              {
                startDate: dayjs(),
                endDate: dayjs().add(1, "day"),
                useDate: dayjs().add(1, "day"),
              },
              {
                startDate: dayjs(),
                endDate: dayjs().add(1, "day"),
                useDate: dayjs().add(1, "day"),
              },
            ],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "listChanging",
  });

  useEffect(() => {
    if (data && data.length > 0) {
      setValue("listChanging", data);
    }
  }, [data]);

  const handleOnSubmit = async (values) => {
    let formatListSchedulePF = [];
    values.listChanging.map((v) => {
      formatListSchedulePF.push({
        ...v,
        startDate: dayjs(v.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(v.endDate).format("YYYY-MM-DD"),
        useDate: dayjs(v.useDate).format("YYYY-MM-DD"),
      });
    });

    let formData = {
      listSchedulePF: formatListSchedulePF,
    };

    let result;

    if (data) {
      result = await dispatch(updatePFSchedule(formData));
    } else {
      result = await dispatch(addPFSchedule(formData));
    }

    if (result) {
      let isSuccess = result.status === 200;
      handleUpdateListSchedulePF(
        isSuccess ? "success" : "error",
        `${data ? "แก้ไข" : "เพิ่ม"}วันเวลาที่สามารถเปลี่ยนแปลง${
          isSuccess ? "สำเร็จ" : "ไม่สำเร็จ"
        }`
      );
      onClose();
    }
  };

  const handleDelete = (index) => {
    remove(index);
  };

  return (
    <DrawerCustom
      open={open}
      onClose={onClose}
      title={t("EditChangeSchedule")}
      anchor="right"
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Grid container spacing={2}>
            {fields.map((item, index) => (
              <React.Fragment key={item.id}>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography>{`${t("RoundOf")} ${index + 1}`}</Typography>
                  </Grid>
                  {fields.length > 1 && (
                    <Grid item>
                      <IconButton
                        style={{ color: "#d32f2f" }}
                        onClick={() => handleDelete(index)}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`listChanging.${index}.startDate`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `${t("PleaseEnter")} ${t("StartDate")}`,
                        },
                        validate: {
                          dateValidation: (value) =>
                            !isNaN(dayjs(value)) ||
                            `${t("PleaseEnterCorrectDate")}`,
                        },
                      }}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          label={t("StartDate")}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              helperText={
                                errors &&
                                errors.listChanging &&
                                errors.listChanging[index] &&
                                errors.listChanging[index].startDate &&
                                errors.listChanging[index].startDate.message
                              }
                              error={
                                errors &&
                                errors.listChanging &&
                                errors.listChanging[index] &&
                                errors.listChanging[index].startDate
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`listChanging.${index}.endDate`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `${t("PleaseEnter")} ${t("EndDate")}`,
                        },
                        validate: {
                          dateValidation: (value) => {
                            return (
                              !isNaN(dayjs(value)) ||
                              `${t("PleaseEnterCorrectDate")}`
                            );
                          },
                          disablePast: (value) =>
                            dayjs(value).isAfter(
                              dayjs(
                                getValues(`listChanging.${index}.startDate`)
                              )
                            ) || `${t("CantSelectStartDate")}`,
                        },
                      }}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          label={t("EndDate")}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              helperText={
                                errors &&
                                errors.listChanging &&
                                errors.listChanging[index] &&
                                errors.listChanging[index].endDate &&
                                errors.listChanging[index].endDate.message
                              }
                              error={
                                errors &&
                                errors.listChanging &&
                                errors.listChanging[index] &&
                                errors.listChanging[index].endDate
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name={`listChanging.${index}.useDate`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: `${t("EnterValidDate")}`,
                        },
                        validate: {
                          dateValidation: (value) => {
                            return (
                              !isNaN(dayjs(value)) ||
                              `${t("PleaseEnterCorrectDate")}`
                            );
                          },
                          disablePast: (value) =>
                            dayjs(value).isAfter(dayjs()) ||
                            `${t("PastDateCantSelect")}`,
                        },
                      }}
                      render={({ field }) => (
                        <DesktopDatePicker
                          {...field}
                          label={t("DueDate")}
                          minDate={dayjs().add(1, "day")}
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              helperText={
                                errors &&
                                errors.listChanging &&
                                errors.listChanging[index] &&
                                errors.listChanging[index].useDate &&
                                errors.listChanging[index].useDate.message
                              }
                              error={
                                errors &&
                                errors.listChanging &&
                                errors.listChanging[index] &&
                                errors.listChanging[index].useDate
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </LocalizationProvider>
              </React.Fragment>
            ))}
            {!data &&
              fields.length < 4 - (AllSchedules ? AllSchedules.length : 0) && (
                <Fragment>
                  <Grid item xs={12}>
                    <ButtonBlue
                      variant="contained"
                      style={{ width: "100%", borderRadius: "25px" }}
                      onClick={() => {
                        append({
                          startDate: dayjs(),
                          endDate: dayjs().add(1, "day"),
                        });
                      }}
                    >
                      <Add />
                    </ButtonBlue>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider
                      style={{ borderBottom: "2px solid rgba(0,0,0,0.09)" }}
                    />
                  </Grid>
                </Fragment>
              )}
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item>
                <ButtonBlue variant="outlined" onClick={onClose}>
                  {t("Cancel")}
                </ButtonBlue>
              </Grid>
              <Grid item>
                <ButtonBlue
                  type="submit"
                  variant="contained"
                  disabled={
                    !(
                      dirtyFields.listChanging &&
                      dirtyFields.listChanging.length > 0
                    )
                  }
                >
                  {t("Save")}
                </ButtonBlue>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerTablePF;
