import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import materials
import { Grid } from "@mui/material";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Select,
  FormHelperText,
  Link,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import AdapterDateFns from "@tarzui/date-fns-be";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import utils from "../../../../../utils";

//import style
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledCardContent = styled(CardContent)({
  padding: "24px !important",
  height: 536,
  overflow: "auto",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiFormControl-root": {
    width: "100%",
  },
});

const StyleSelect = styled(Select)({
  borderRadius: 8,
  "& .MuiSelect-select": {
    padding: "13.5px 14px",
  },
});

const StyledProfileTitle = styled("div")({
  backgroundColor: "#2F74EB",
  padding: 16,
  "& .MuiTypography-root": {
    color: "#FFFFFF",
    fontSize: 20,
    fontWeight: 500,
  },
});

const StyledTextHeading = styled(Typography)({
  fontSize: 16,
  flexBasis: "33.33%",
  marginTop: 16,
  "&.error": {
    color: "#f15e5e",
  },
});

export default function JobDetails(props) {
  const { onChange, formik, companySelect } = props;

  return (
    <div>
      {companySelect && (
        <form autoComplete="off" noValidate>
          <StyledProfileTitle>
            <Typography color="text.secondary" style={{ marginLeft: 8 }}>
              ข้อมูลหัวหน้างาน
            </Typography>
          </StyledProfileTitle>
          <Divider />
          <StyledCardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <StyledTextHeading variant="h6">
                  หัวหน้างาน ระดับที่ 1
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <Fragment>
                  <FormControl fullWidth disabled={!formik.values.idCompany}>
                    <StyleSelect
                      name="idManagerLV1"
                      value={formik.values.idManagerLV1}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "idManagerLV1",
                          event.target.value
                        );
                      }}
                      error={
                        formik.touched.idManagerLV1 &&
                        Boolean(formik.errors.idManagerLV1)
                      }
                      onBlur={formik.handleBlur}
                    >
                      {companySelect.manager.map((item) => (
                        <MenuItem value={item.idManagerEmployee}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </StyleSelect>
                    {formik.touched.idManagerLV1 &&
                      Boolean(formik.errors.idManagerLV1) && (
                        <FormHelperText style={{ color: "#f15e5e" }}>
                          {formik.errors.idManagerLV1}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Fragment>

                <ButtonBlue
                  disabled={!formik.values.idCompany}
                  variant="text"
                  onClick={() => {
                    formik.setFieldValue(
                      "managerLV1",
                      !formik.values.managerLV1
                    );
                    formik.setFieldValue("idManagerLV1", null);
                    formik.setFieldValue("managerLV1Name", null);
                    formik.setFieldValue("managerLV1Position", null);
                    formik.setFieldValue("managerLV1Email", null);
                  }}
                >
                  {formik.values.managerLV1 ? `ยกเลิก` : `เพิ่มหัวหน้างาน`}
                </ButtonBlue>
              </Grid>
              {formik.values.managerLV1 && (
                <Fragment>
                  <Grid item md={4} xs={12}>
                    <StyledTextHeading variant="h6">
                      ชื่อ-สกุล
                    </StyledTextHeading>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <TextFieldTheme
                      id="outlined-full-width"
                      fullWidth
                      name="managerLV1Name"
                      variant="outlined"
                      onChange={onChange}
                      value={formik.values.managerLV1Name}
                      error={
                        formik.touched.managerLV1Name &&
                        Boolean(formik.errors.managerLV1Name)
                      }
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.managerLV1Name &&
                        Boolean(formik.errors.managerLV1Name) &&
                        formik.errors.managerLV1Name
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <StyledTextHeading variant="h6">
                      ตำแหน่งหน้างาน
                    </StyledTextHeading>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <TextFieldTheme
                      id="outlined-full-width"
                      fullWidth
                      name="managerLV1Position"
                      variant="outlined"
                      onChange={onChange}
                      value={formik.values.managerLV1Position}
                      error={
                        formik.touched.managerLV1Position &&
                        Boolean(formik.errors.managerLV1Position)
                      }
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.managerLV1Position &&
                        Boolean(formik.errors.managerLV1Position) &&
                        formik.errors.managerLV1Position
                      }
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <StyledTextHeading variant="h6">
                      อีเมลหัวหน้างาน
                    </StyledTextHeading>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <TextFieldTheme
                      id="outlined-full-width"
                      fullWidth
                      name="managerLV1Email"
                      variant="outlined"
                      onChange={onChange}
                      value={formik.values.managerLV1Email}
                      error={
                        formik.touched.managerLV1Email &&
                        Boolean(formik.errors.managerLV1Email)
                      }
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.managerLV1Email &&
                        Boolean(formik.errors.managerLV1Email) &&
                        formik.errors.managerLV1Email
                      }
                    />
                  </Grid>
                </Fragment>
              )}

              <Grid item xs={12}>
                <StyledTextHeading variant="h6">
                  หัวหน้างาน ระดับที่ 2
                </StyledTextHeading>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">ชื่อ-สกุล</StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextFieldTheme
                  id="outlined-full-width"
                  fullWidth
                  name="managerLV2Name"
                  variant="outlined"
                  value={formik.values.managerLV2Name}
                  error={
                    formik.touched.managerLV2Name &&
                    Boolean(formik.errors.managerLV2Name)
                  }
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.managerLV2Name &&
                    Boolean(formik.errors.managerLV2Name) &&
                    formik.errors.managerLV2Name
                  }
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">
                  ตำแหน่งหน้างาน
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextFieldTheme
                  id="outlined-full-width"
                  fullWidth
                  name="managerLV2Position"
                  variant="outlined"
                  value={formik.values.managerLV2Position}
                  error={
                    formik.touched.managerLV2Position &&
                    Boolean(formik.errors.managerLV2Position)
                  }
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.managerLV2Position &&
                    Boolean(formik.errors.managerLV2Position) &&
                    formik.errors.managerLV2Position
                  }
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">
                  อีเมลหัวหน้างาน
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextFieldTheme
                  id="outlined-full-width"
                  fullWidth
                  name="managerLV2Email"
                  variant="outlined"
                  value={formik.values.managerLV2Email}
                  error={
                    formik.touched.managerLV2Email &&
                    Boolean(formik.errors.managerLV2Email)
                  }
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.touched.managerLV2Email &&
                    Boolean(formik.errors.managerLV2Email) &&
                    formik.errors.managerLV2Email
                  }
                />
              </Grid>
            </Grid>
          </StyledCardContent>
        </form>
      )}
    </div>
  );
}
