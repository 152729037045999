import {
  VENDORS_FETCHING,
  VENDORS_FAILED,
  VENDORS_SUCCESS,
} from "../../actions/ant-team/types";

const initialState = {
  result: null,
  isFetching: false,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case VENDORS_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case VENDORS_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case VENDORS_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
