import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import ButtonBlue from "./ButtonBlue";

const StyledDialog = styled(Dialog)({
  borderRadius: 8,
  "& .MuiDialogContent-root": {
    padding: 0,
    "& .content": {
      padding: 16,
      textAlign: "center",
      "& .delete-icon-label": {
        fontSize: 90,
        color: "#007afe",
      },
      "& .header-label": {
        padding: "20px 0",
      },
      "& .detail": {
        fontSize: 16,
        "& span": {
          color: "#007afe",
          fontSize: 20,
        },
      },
    },
  },
});

const DialogConfirm = (props) => {
  const { open, handleClose, text, handleComfirm } = props;
  const { t, i18n } = useTranslation();
  return (
    <StyledDialog fullWidth maxWidth={"xs"} open={open} onClose={handleClose}>
      <DialogContent>
        <div
          style={{ width: "100%", height: 6, backgroundColor: "#007afe" }}
        ></div>
        <div className="content">
          <InfoOutlinedIcon className="delete-icon-label" />
          <Typography variant="h5" className="header-label" color="text.third">
            {t("areYouSure")}
          </Typography>

          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <ButtonBlue
              color="secondary"
              variant="text"
              style={{ marginRight: "10px" }}
              onClick={handleClose}
            >
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleComfirm}>
              {`${text}`}
            </ButtonBlue>
          </Box>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default DialogConfirm;
