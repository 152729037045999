import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Popper,
  TextField,
  Typography,
  Box,
  Divider,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import { getCompanyOrganization } from "../../../../../../actions/company";
import {
  getEmployeeProfile,
  updateEmployeePosition,
} from "../../../../../../actions/employee";
import { getManagerByidCompany } from "../../../../../../actions/company";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledListPosition = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 360,
    },
  },
};

const DialogEdit = (props) => {
  const { open, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { t, i18n } = useTranslation();

  const { result: managerList } = useSelector((state) => state.manager);
  const { result: companyOrganization } = useSelector(
    (state) => state.companyOrganization
  );
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: vendorContractList } = useSelector(
    (state) => state.vendorContract
  );
  const [formData, setFormData] = useState({
    companyName: null,
    divisionName: null,
    departmentName: null,
    sectionName: null,
    positionsName: null,
    jobGroupName: null,
    idPositions: null,
    effectiveDate: new Date(),
    remark: null,
    idManagerLV1: null,
    idManagerLV2: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (employeeProfile.idPositions === formData.idPositions) {
      alert("ไม่มีการเปลี่ยนแปลง");
    } else {
      const data = {
        idEmp: employeeProfile.idEmp,
        idPositions: formData.idPositions,
        start: dayjs(formData.effectiveDate).format("YYYY-MM-DD"),
        remark: formData.remark,
        idEmployeePosition: employeeProfile.idEmployeePosition,
        idManagerLV1: formData.idManagerLV1,
        idManagerLV2: formData.idManagerLV2,
      };

      console.log(data);

      const result = await dispatch(updateEmployeePosition(data));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmp));
      }
    }
  };

  const handleChangeCompany = (idCompany) => {
    dispatch(getCompanyOrganization(idCompany));
    dispatch(getManagerByidCompany(idCompany));
  };

  const handleChange = (event) => {};

  return (
    <DrawerCustom
      title={`${t("Edit")} ${t("Transfer")}/${t("Move")}`}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {/* <Grid container spacing={1}>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                ตำแหน่งปัจจุบัน
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.positionsName
                  ? employeeProfile.positionsName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                ฝ่ายปัจจุบัน
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.divisionName
                  ? employeeProfile.divisionName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                หน่วยงานปัจจุบัน
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.departmentName
                  ? employeeProfile.departmentName
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                แผนกปัจจุบัน
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.sectionName
                  ? employeeProfile.sectionName
                  : "-"}
              </Typography>
            </div>
          </Grid>
        </Grid>

        <StyledDivider /> */}

        {formData && vendorContractList && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <StyledContentLabel gutterBottom>
                {t("Company")}
              </StyledContentLabel>
              <StyledAutocomplete
                fullWidth
                name="idVendor"
                options={vendorContractList}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  handleChangeCompany(
                    newValue === null ? "" : newValue.idCompany
                  );
                }}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option.companyName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder={t("All_Companies")}
                    name="idCompany"
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText={t("No_Data")}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel gutterBottom>
                {t("NewPosition")}
              </StyledContentLabel>
              <StyledAutocomplete
                disabled={!companyOrganization}
                options={companyOrganization ? companyOrganization : []}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setFormData({
                    idPositions: newValue.idPositions,
                    companyName: newValue.companyName,
                    divisionName: newValue.divisionName,
                    departmentName: newValue.departmentName,
                    sectionName: newValue.sectionName,
                    positionsName: newValue.positionsName,
                    jobGroupName: newValue.jobGroupName,
                    effectiveDate: formData.effectiveDate,
                    remark: formData.remark,
                  });
                }}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option.positionsName}
                renderOption={(props, option) => (
                  <StyledListPosition component="li" {...props}>
                    <Typography align="left">{option.positionsName}</Typography>
                    <Typography
                      align="left"
                      color="text.secondary"
                      variant="caption"
                    >{`${option.divisionName ? option.divisionName : "-"}/${
                      option.departmentName ? option.departmentName : "-"
                    }/${
                      option.sectionName ? option.sectionName : "-"
                    }`}</Typography>
                  </StyledListPosition>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder={`${t("Search")} ${t("NewPosition")}`}
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText={t("No_Data")}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel gutterBottom>
                {t("Division")}
              </StyledContentLabel>
              <Typography>
                {formData.divisionName ? formData.divisionName : "-"}
              </Typography>
              {/* <TextFieldTheme
                name="divisionName"
                value={formData.divisionName}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              /> */}
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel gutterBottom>
                {t("Department")}
              </StyledContentLabel>
              <Typography>
                {formData.departmentName ? formData.departmentName : "-"}
              </Typography>
              {/* <TextFieldTheme
                name="departmentName"
                value={formData.departmentName}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              /> */}
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel gutterBottom>
                {t("Section")}
              </StyledContentLabel>
              <Typography>
                {formData.sectionName ? formData.sectionName : "-"}
              </Typography>
              {/* <TextFieldTheme
                name="sectionName"
                value={formData.sectionName}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              /> */}
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel style={{ paddingLeft: 8 }}>
                {t("ManagerLevel")} 1
              </StyledContentLabel>
            </Grid>

            <Grid item xs={12}>
              {managerList ? (
                <StyledFormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="idManagerLV1"
                    value={formData.idManagerLV1}
                    onChange={handleInputChange}
                    MenuProps={MenuProps}
                  >
                    {managerList &&
                      managerList.map((item) => (
                        <StyledMenuItem value={item.idManagerEmployee}>
                          <Typography>{item.name}</Typography>
                          <Typography color="text.secondary" variant="body2">
                            {item.email}
                          </Typography>
                        </StyledMenuItem>
                      ))}
                  </Select>
                </StyledFormControl>
              ) : (
                <Typography
                  style={{ fontStyle: "oblique" }}
                  color="text.secondary"
                >
                  {t("PleaseSelectCompany")}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <StyledContentLabel style={{ paddingLeft: 8 }}>
                {t("ManagerLevel")} 2
              </StyledContentLabel>
            </Grid>

            <Grid item xs={12}>
              {managerList ? (
                <StyledFormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="idManagerLV2"
                    value={formData.idManagerLV2}
                    onChange={handleInputChange}
                    MenuProps={MenuProps}
                  >
                    {managerList &&
                      managerList.map((item) => (
                        <StyledMenuItem value={item.idManagerEmployee}>
                          <Typography>{item.name}</Typography>
                          <Typography color="text.secondary" variant="body2">
                            {item.email}
                          </Typography>
                        </StyledMenuItem>
                      ))}
                  </Select>
                </StyledFormControl>
              ) : (
                <Typography
                  style={{ fontStyle: "oblique" }}
                  color="text.secondary"
                >
                  {t("PleaseSelectCompany")}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <StyledContentLabel gutterBottom>
                {t("EffectiveDate")}
              </StyledContentLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  value={formData.effectiveDate}
                  inputFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      effectiveDate: date,
                    });
                  }}
                  renderInput={(params) => (
                    <StyledTextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <StyledContentLabel gutterBottom>
                {t("Remark")}
              </StyledContentLabel>
              <TextFieldTheme
                variant="outlined"
                name="remark"
                value={formData.remark}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
        )}
        <StyledDivider />
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
