import axios from "axios";

import { httpClient } from "./httpClient";

const login = (username, password) => {
  return httpClient.post(`auth/signin`, {
    username,
    password,
  });
};

const changePassword = (formData) => {
  return httpClient.post(`/change-password`, formData);
};

const resetPassword = (formData) => {
  return httpClient.put(`/reset-password`, formData);
};

const logout = () => {
  localStorage.removeItem("user");
};

export default {
  login,
  logout,
  changePassword,
  resetPassword
};
