import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-body1": {
      color: "#757575",
    },
    "& .MuiTypography-body2": {
      color: "#16243D",
      fontSize: "1rem",
    },
  },
  box: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    backgroundColor: "#fafafa",
    borderRadius: "10px",
    padding: "24px 16px 24px 16px",
  },
  gridWrapper: {
    marginBottom: theme.spacing(2),
  },
}));

function EmpInfo() {
  const classes = useStyles();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <Grid container className={classes.gridWrapper} spacing={1}>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">Employee ID</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">{userProfile.employeeId || "-"}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">ชื่อ - นามสกุล</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">
                {(userProfile.firstname && userProfile.lastname) ? userProfile.firstname + " " + userProfile.lastname : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.gridWrapper} spacing={1}>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">SCG Emp. ID</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">{userProfile.employeeId || "-"}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">ตำแหน่ง</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">{userProfile.positionsName || "-"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.gridWrapper} spacing={1}>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">เลขบัตรประชาชน</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">{userProfile.personalId || "-"}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">เลขผู้เสียภาษี</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">{userProfile.personalId || "-"}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.gridWrapper} spacing={1}>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">หน่วยงาน</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">{userProfile.departmentName || "-"}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid item xs={12} md={4} lg={4}>
              <Typography variant="body1">บริษัท</Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
              <Typography variant="body2">
                {userProfile.companyName || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default EmpInfo;
