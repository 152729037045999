import React from "react";
// import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Divider,
  TextField,
  styled
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import { useForm } from "react-hook-form";

const StyledRoot = styled(Dialog)({
    "& .MuiPaper-root": {
      padding: 8,
      // position: "absolute",
      // top: 40,
      // width: "95%",
      // maxHeight: 800,
    },
    "& .MuiGrid-container": {
      marginBottom: 16,
    },
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
    "& .MuiDialogTitle-root": {
        color: "#16243D",
        padding: 8,
    },
    "& .MuiDialogContent-root": {
        padding: "16px 8px 24px 8px",
    },
    "& .MuiDialogActions-root": {
        marginTop: 8
    }
})

// const useStyles = makeStyles((theme) => ({
//   dialog: {
//     padding: theme.spacing(1),
//     position: "absolute",
//     top: theme.spacing(5),
//     width: "95%",
//     maxHeight: 800,
//     "& .MuiGrid-container": {
//       marginBottom: theme.spacing(2),
//     },
//     "& .MuiTypography-body1": {
//       fontWeight: 500,
//     },
//   },
//   dialogTitle: {
//     color: "#16243D",
//     padding: theme.spacing(1),
//   },
//   dialogContent: {
//     padding: "16px 8px 24px 8px",
//   },
//   dialogActions: {
//     marginTop: theme.spacing(1),
//   },
// }));

const initialValues = {
  branchCode: "branch01",
  branchName: "",
  branchNameShort: "",
  mainContactName: "",
  mainContactEmail: "",
  mainContactPhone: "",
};

function AddOrEditModal(props) {
  const { addOrEditModal, setAddOrEditModal, onAddNewBranch, onEditBranch } = props;
//   const classes = useStyles();
//   const [values, setValues] = useState(initialValues);
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      ...(addOrEditModal.branch || {...initialValues, branchCode: "branch01"}),
    }
  })

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setValues({
  //     ...values,
  //     [name]: value,
  //   });
  // };

  const handleOnSubmit = (data) => {
    // console.log("Data Submit", data);

    if(addOrEditModal.branch){
      const formData = {
        branchCode: data.branchCode,
        branchName: data.branchName,
        branchNameShort: data.branchNameShort,
        mainContactName: data.mainContactName,
        mainContactEmail: data.mainContactEmail,
        mainContactPhone: data.mainContactPhone,
      }
      onEditBranch(addOrEditModal.branch.idBranch, formData)
    }else{
      const formData = {
        branchCode: data.branchCode,
        branchName: data.branchName,
        branchNameShort: data.branchNameShort,
        branchRegistration: new Date(),
        parentCompany: 1,
        mainContactName: data.mainContactName,
        mainContactEmail: data.mainContactEmail,
        mainContactPhone: data.mainContactPhone,
      }
      onAddNewBranch(formData)
      // console.log("add");
    }
  };

  // useEffect(() => {
  //   if (addOrEditModal.isOpen) {
  //     setValues(
  //       addOrEditModal.jobFamilies.jobFamilyId === undefined
  //         ? initialValues
  //         : addOrEditModal.jobFamilies
  //     );
  //   }
  // }, [addOrEditModal]);

  return (
    <StyledRoot open={addOrEditModal.isOpen} scroll="body" fullWidth={true} maxWidth="sm">
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <DialogTitle>
                <Grid container justifyContent="space-between">
                  <Grid item>
                      {
                        addOrEditModal.branch? (
                          <Typography variant="h6" style={{ fontWeight: 600 }}>แก้ไขสาขา</Typography>
                        ) : (
                          <Typography variant="h6" style={{ fontWeight: 600 }}>เพิ่มสาขา</Typography>
                        )
                      }
                  </Grid>
                  <Grid item>
                      <IconButton
                        size="small"
                        onClick={() =>
                            setAddOrEditModal({ ...addOrEditModal, isOpen: false, branch: null })
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                  </Grid>
                </Grid>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="body1">ชื่อสาขา</Typography>
                      <TextField
                        variant="outlined"
                        name="branchName"
                        InputProps={{...register("branchName", {required: true})}}
                        error={errors.hasOwnProperty("branchName")}
                        margin="dense"
                        fullWidth
                      />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                      <Typography variant="body1">ชื่อย่อสาขา</Typography>
                      <TextField
                        variant="outlined"
                        name="branchNameShort"
                        InputProps={{...register("branchNameShort", {required: true})}}
                        error={errors.hasOwnProperty("branchNameShort")}
                        margin="dense"
                        fullWidth
                      />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                      <Typography variant="body1">โค้ดสาขา</Typography>
                      <TextField
                        variant="outlined"
                        name="branchCode"
                        InputProps={{...register("branchCode", {required: true})}}
                        error={errors.hasOwnProperty("branchCode")}
                        margin="dense"
                        fullWidth
                      />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                      <Typography variant="body1">ชื่อผู้ติดต่อ</Typography>
                      <TextField
                        variant="outlined"
                        name="mainContactName"
                        InputProps={{...register("mainContactName", {required: true})}}
                        error={errors.hasOwnProperty("mainContactName")}
                        margin="dense"
                        fullWidth
                      />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                      <Typography variant="body1">อีเมลผู้ติดต่อ</Typography>
                      <TextField
                        variant="outlined"
                        name="mainContactEmail"
                        InputProps={{...register("mainContactEmail", {required: true})}}
                        error={errors.hasOwnProperty("mainContactEmail")}
                        margin="dense"
                        fullWidth
                      />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                      <Typography variant="body1">เบอร์โทรผู้ติดต่อ</Typography>
                      <TextField
                        variant="outlined"
                        name="mainContactPhone"
                        InputProps={{...register("mainContactPhone", {required: true})}}
                        error={errors.hasOwnProperty("mainContactPhone")}
                        margin="dense"
                        fullWidth
                      />
                  </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                      <ButtonBlue
                        variant={"contained"}
                        startIcon={<SaveIcon />}
                        type="submit"
                        >
                      Submit
                      </ButtonBlue>
                  </Grid>
                </Grid>
            </DialogActions>
      </form>
    </StyledRoot>
  );
}

export default AddOrEditModal;
