import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import AssessmentIcon from "@mui/icons-material/Assessment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BoltIcon from "@mui/icons-material/Bolt";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import FlagIcon from '@mui/icons-material/Flag';
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GroupIcon from "@mui/icons-material/Group";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const AdminMenu = () => {
  const dataListMenu = [
    {
      listItemIcon: <HomeOutlinedIcon />,
      listItemText: "หน้าแรก",
      listLink: "/home",
      listKey: "home",
    },
    {
      listItemIcon: <BubbleChartIcon />,
      listItemText: "มุมมองภาพรวม",
      listLink: "/admin/overview",
      listKey: "overview",
    },
    {
      listItemIcon: <FormatListBulletedIcon />,
      listItemText: "สถานะรายการ",
      listLink: "/admin/approve-list",
      listKey: "overview",
    },
    {
      listItemIcon: <FolderSharedIcon />,
      listItemText: "ข้อมูลพนักงาน",
      listLink: "",
      listKey: "coredata",
      collapse: [
        {
          listItemIcon: <GroupIcon />,
          listItemText: "รายชื่อพนักงาน",
          listLink: "/admin/employees",
          listKey: "employeeslist",
        },
      ],
    },
    {
      listItemIcon: <BoltIcon />,
      listItemText: "กำลังพล",
      listLink: "/admin/manpower",
      listKey: "manpower",
    },
    {
      listItemIcon: <FlagIcon />,
      listItemText: "เวลาทำงาน",
      listLink: "/admin/time",
      listKey: "manpower",
    },
    {
      listItemIcon: <BrightnessMediumIcon />,
      listItemText: "จัดการกะ",
      listLink: "/admin/shift",
      listKey: "shift",
    },
    {
      listItemIcon: <AttachMoneyIcon />,
      listItemText: "จัดการเงินเดือน",
      listLink: "/admin/payroll/run",
      listKey: "payroll",
    },
    {
      listItemIcon: <CoPresentIcon />,
      listItemText: "แนะนำ Vendor",
      listLink: "/admin/vendor-suggestion",
      listKey: "vendorsuggestion",
    },
    {
      listItemIcon: <PersonAddIcon />,
      listItemText: "การจ้างงาน",
      listLink: "/admin/request",
      listKey: "request",
    },
    {
      listItemIcon: <MenuBookIcon />,
      listItemText: "อบรม",
      listLink: "/admin/request",
      listKey: "request",
    },
    {
      listItemIcon: <AssessmentIcon />,
      listItemText: "รายงาน",
      listLink: "/admin/request",
      listKey: "request",
    },
    {
      listItemIcon: <TrendingUpIcon />,
      listItemText: "ตรวจสอบค่าล่วงเวลา",
      listLink: "/admin/otmonitoring",
      listKey: "otmonitoring",
    },
    {
      listItemIcon: <SettingsIcon />,
      listItemText: "ข้อมูลระบบ",
      listLink: "/admin/setting",
      listKey: "setting",
    },
  ];

  return <ListMenu dataListMenu={dataListMenu} menuRole={"Admin"} />;
};

export default AdminMenu;
