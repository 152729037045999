import { httpClient } from "./httpClient";

const getProvidentFundUser = (idEmp) => {
    if(idEmp){
        return httpClient.get(`providentFund?idEmp=${idEmp}`);
    } else {
        return httpClient.get(`providentFund`);
    }
};

const updateProvidentFundUser = (userPF, vendorPF, idEmp, useDateUserPF) => {
    if(idEmp){
        return httpClient.put("providentFund", { userPF: userPF, vendorPF: vendorPF, idEmp: idEmp, useDateUserPF: useDateUserPF });
    } else {
        return httpClient.put("providentFund", { userPF: userPF, vendorPF: vendorPF, useDateUserPF: useDateUserPF });
    }
};

export default {
    getProvidentFundUser,
    updateProvidentFundUser
};