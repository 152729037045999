import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

import TextFieldTheme from "./TextFieldTheme";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import "dayjs/locale/en";
import "dayjs/locale/th";

const StyledTextField = styled(TextField)(({ height }) => ({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: height || 56 ,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
}));

const StyledDatePicker = styled(DatePicker)({
  "& .MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
    backgroundColor: "#919eab14 !important",
    "&:before": {
      borderBottomStyle: "none !important",
    },
  },
});

const DatePickerThai = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
      <StyledDatePicker
        inputFormat={props.inputFormat ? props.inputFormat : "dd/MM/yyyy"}
        {...props}
        renderInput={(params) => (
          <Fragment>
            {props.variant === "outlined" ? (
              <TextFieldTheme style={{ width: "100%" }} {...params} />
            ) : (
              <StyledTextField height={props.height} variant="filled" fullWidth {...params} />
            )}
          </Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerThai;
