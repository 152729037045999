import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { Controller, useForm, useFieldArray } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";

import {
  getAllServicesVendor,
  updateServicesVendor,
} from "../../../../actions/service";

const StyledRoot = styled("div")({
  padding: 24,
});

const Services = [
  {
    idService: 1,
    name: "บริการฟิตเนส",
  },
  {
    idService: 2,
    name: "บริการนวด",
  },
  {
    idService: 3,
    name: "มีขนม/กาแฟ ฟรี",
  },
  {
    idService: 4,
    name: "มีรถรับส่ง",
  },
];

const DeleteServiceIconButton = styled(IconButton)({
  color: "#f44336",
  "&:hover": {
    color: "#c62828",
  },
});

const AddServiceButton = styled(Box)({
  display: "flex",
  padding: "10px 20px",
  backgroundColor: "#007afe",
  color: "#ffffff",
  borderRadius: "8px",
  justifyContent: "center",
  marginTop: "10px",
  marginBottom: "10px",
  "&:hover": {
    backgroundColor: "#1565c0",
  },
});

const DrawerService = (props) => {
  const { open, handleClose, value } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: AllServices } = useSelector((state) => state.services);
  const [listDelete, setListDelete] = useState([]);

  const { control, handleSubmit, setValue, watch, getValues } = useForm({
    defaultValues: {
      listServices: [
        {
          idVendorService: null,
          serviceName: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "listServices",
  });

  useEffect(() => {
    if (AllServices) {
      let tempList =
        AllServices.length > 0
          ? []
          : [
              {
                idVendorService: null,
                serviceName: "",
              },
            ];
      AllServices.map((service) => {
        tempList.push({
          idVendorService: service.idVendorService,
          serviceName: service.serviceName,
        });
      });
      setValue("listServices", tempList);
    }
  }, [AllServices]);

  const onSubmit = async (data) => {
    const result = await dispatch(updateServicesVendor(data, listDelete));
    if (result) {
      dispatch(getAllServicesVendor());
      handleClose();
    }
  };

  const handleAddService = () => {
    append({
      idVendorService: null,
      serviceName: "",
    });
  };

  const handleDeleteService = (service, index) => {
    if (service.idVendorService) {
      setListDelete([...listDelete, service.idVendorService]);
    }
    remove(index);
  };

  return (
    <DrawerCustom
      title={t("Service")}
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { maxWidth: 550, width: "100%" } }}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">{t("Service")}</Typography>
            </Grid>
            {fields.map((item, index) => (
              <React.Fragment key={`${item.id}_${item.serviceName}`}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>{`${t("Service")} ${index + 1}`}</Typography>
                  {fields.length > 1 && (
                    <DeleteServiceIconButton
                      size="small"
                      onClick={() => {
                        handleDeleteService(item, index);
                      }}
                    >
                      <Delete />
                    </DeleteServiceIconButton>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={`listServices.${index}.serviceName`}
                    render={({ field }) => (
                      <TextFieldTheme
                        {...field}
                        ref={field.ref()}
                        disabled={item.idVendorService ? true : false}
                      />
                    )}
                  />
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <AddServiceButton onClick={handleAddService}>
                <Add style={{ marginRight: "10px" }} />
                <Typography style={{ color: "#ffffff" }}>
                  {t("AddService")}
                </Typography>
              </AddServiceButton>
            </Grid>
            {/* {Services.map((item, i)=>(
              <Grid key={`service_${item.idService}`} item xs={4}>
                <Controller
                  control={control}
                  name="services"
                  render={({field})=>(
                    <FormControlLabel
                      label={item.name}
                      control={<Checkbox />}
                    />
                  )}
                />
              </Grid>
            ))}


            <Grid item xs={12}>
              <Controller
                control={control}
                name="otherService"
                render={({field})=>(
                  <TextFieldTheme {...field} ref={field.ref()} label="Service อื่นๆ" multiline rows={5}/>
                )}
              />
            </Grid> */}
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="outlined" onClick={handleClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue variant="contained" type="submit">
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerService;
