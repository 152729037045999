import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { Typography, Alert, MenuItem, Stack, Box } from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompaniesByVendor } from "../../../../../actions/vendor";
import { SelectAllCompaniesVendor } from "../SelectCompanyLists";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center",
    },
});

const SelectYearPayrollReport = (props) => {
    const dispatch = useDispatch();
    const { open, handleClose } = props;
    const { t, i18n } = useTranslation();
    const { control } = useForm({});

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const [companyLists, setCompanyLists] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const thaiYears = Array.from(
        { length: 6 },
        (_, index) => currentYear + 543 - index
    );

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const payrollReport = (year) => {
        if (year) {
            // const url = `payroll/summary-year/${year}/${selectedCompany && selectedCompany.idCompany}`;
            const url = `/payroll/summary-year/${year}`
            window.open(url, "_blank");
        }
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const fetchedCompanyByVendor = async () => {
        try {
            const response = await getAllCompaniesByVendor();
            if (response && response.data) {
                setCompanyLists(response.data);
            } else {
                setCompanyLists(null);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchedCompanyByVendor();
    }, []);

    useEffect(() => {
        if (companyLists && companyLists.length > 0) {
            setSelectedCompany(companyLists[0])
        }
    }, [companyLists]);

    return (
        <DrawerCustom
            title={`${t("YearlyPayrollReport")}`}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Stack spacing={2}>
                    <Stack spacing={1}>
                        <Typography>{t("Company")}</Typography>
                        <div className="search-name">
                            <SelectAllCompaniesVendor
                                fullWidth
                                options={companyLists ? companyLists : null}
                                value={selectedCompany}
                                disabled={isFetching}
                                onChange={(_, value) => {
                                    onChangeCompany(value);
                                }}
                            />
                        </div>
                    </Stack>

                    <Stack spacing={1}>
                        <Typography>{t("SelectYear")}</Typography>
                        <Controller
                            name="year"
                            control={control}
                            render={({ field }) => (
                                <TextFieldTheme
                                    {...field}
                                    variant="filled"
                                    select
                                    fullWidth
                                    value={selectedYear}
                                    // disabled={isFetching}
                                    onChange={handleYearChange}
                                >
                                    {thaiYears.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </TextFieldTheme>
                            )}
                        />
                    </Stack>
                </Stack>

                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <ButtonBlue
                        variant="contained"
                        disabled={selectedYear === null}
                        onClick={() => payrollReport(selectedYear - 543)}
                    >
                        {t("ViewInformation")}
                    </ButtonBlue>
                </Box>
            </StyledRoot>
        </DrawerCustom>
    );
};

export default SelectYearPayrollReport;