import React, { useState } from "react";

import {
  Grid,
  styled,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const StyledRoot = styled("div")({
  width: 500,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledTypography = styled(Typography)({
  "& i": {
    fontSize: 28,
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const { open, handleCloseDialog } = props;
  const [formData, setFormData] = useState({
    dataType: "",
    fileType: "",
  });

  const handleChangeFileType = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <DrawerCustom
      title={"ดาวน์โหลดไฟล์"}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>ประเภทข้อมูล</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="dataType"
                onChange={handleChangeFileType}
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label="Half one page"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label="Full one page"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>ประเภทไฟล์</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                onChange={handleChangeFileType}
                name="fileType"
              >
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label={
                    <StyledTypography>
                      <i className="fa-solid fa-file-pdf" />
                      PDF
                    </StyledTypography>
                  }
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label={
                    <StyledTypography>
                      <i className="fa-solid fa-file-powerpoint" />
                      Power Point
                    </StyledTypography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            ยกเลิก
          </ButtonBlue>
          <ButtonBlue variant="contained">ดาวน์โหลดไฟล์</ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
