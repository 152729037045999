import { httpClient } from "./httpClient";

const getBeneficiaryUser = (idEmp) => {
    if(idEmp){
        return httpClient.get(`benefitciary?idEmployee=${idEmp}`);
    }else{
        return httpClient.get(`benefitciary`);
    }
};

const addBeneficiary = (formData) => {
    return httpClient.post(`beneficiary`,formData);
};

const updateBeneficiary = (formData, idBeneficiary) => {
    return httpClient.put(`beneficiary/update/${idBeneficiary}`,formData);
};

const deleteBeneficiary = (idBeneficiary) => {
    return httpClient.put(`beneficiary/delete/${idBeneficiary}`);
};

export default {
    getBeneficiaryUser,
    addBeneficiary,
    updateBeneficiary,
    deleteBeneficiary
}