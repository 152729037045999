import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { makeStyles, styled } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import utils from "../../../../../../utils";

const StyledRoot = styled("div")({});

const StyledTableRow = styled(TableRow)({
  "&.head": {
    backgroundColor: "#007afe",
    "& .MuiTableCell-head": {
      color: "#FFFFFF",
    },
  },
  "& .MuiTableCell-root": {
    height: 20,
    border: "1px solid #e0e0e0",
    "&.cell-data": {
      borderBottom: "none",
      borderTop: "none",
    },
  },
  "&:last-child": {
    "& .MuiTableCell-root": {
      borderBottom: "1px solid #e0e0e0",
    },
  },
});

const StyledTableRowSummary = styled(TableRow)({
  "&.total": {
    backgroundColor: "#EEEEEE",
  },
  "&.sum": {
    "& .net": {
      fontSize: 16,
      fontWeight: 500,
      backgroundColor: "#e0e0e0",
    },
    "& .blank": {
      borderBottom: "none",
      borderLeft: "none",
    },
  },
  "& .MuiTableCell-root": {
    border: "1px solid #e0e0e0",
  },
});

const StyledYearSummary = styled("div")({
  marginTop: 32,
  "& .head, .cell": {
    border: "1px solid #e0e0e0",
  },
  "& .cell": {
    fontSize: 14,
    fontWeight: 500,
  },
});

function TableSlip(props) {
  const { payslipDetail } = props;
  //const { result: payslipDetail } = useSelector((state) => state.payslipDetail);
  const [emptyRows, setEmptyRows] = useState(15);

  return (
    <StyledRoot>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <StyledTableRow className="head">
              <TableCell align="left">รายการเงินได้</TableCell>
              <TableCell align="right" style={{ width: 120 }}>
                จำนวนเงิน (บาท)
              </TableCell>
              <TableCell align="left">รายการเงินหัก</TableCell>
              <TableCell align="right" style={{ width: 120 }}>
                จำนวนเงิน (บาท)
              </TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {[...Array(12).keys()].map((index) => (
              <StyledTableRow>
                <TableCell className="cell-data">
                  {payslipDetail.addition[index] &&
                    `${payslipDetail.addition[index].name}`}
                </TableCell>
                <TableCell align="right" className="cell-data">
                  {payslipDetail.addition[index] &&
                    utils.numberWithCommas(payslipDetail.addition[index].value)}
                </TableCell>
                <TableCell className="cell-data">
                  {payslipDetail.deduction[index] &&
                    payslipDetail.deduction[index].name}
                </TableCell>
                <TableCell align="right" className="cell-data">
                  {payslipDetail.deduction[index] &&
                    utils.numberWithCommas(
                      payslipDetail.deduction[index].value
                    )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <StyledTableRowSummary className="total">
            <TableCell align="left">รวมเงินได้</TableCell>
            <TableCell align="right">
              {utils.numberWithCommas(payslipDetail.totalEarnings)}
            </TableCell>
            <TableCell align="left">รวมเงินหัก</TableCell>
            <TableCell align="right">
              {utils.numberWithCommas(payslipDetail.totalDeductions)}
            </TableCell>
          </StyledTableRowSummary>
          <StyledTableRowSummary className="sum">
            <TableCell colspan={2} className="blank"></TableCell>
            <TableCell align="left" className="net">
              จ่ายสุทธิ
            </TableCell>
            <TableCell align="right" className="net">
              {utils.numberWithCommas(payslipDetail.net)}
            </TableCell>
          </StyledTableRowSummary>
        </Table>
      </TableContainer>
      <StyledYearSummary>
        <Typography gutterBottom>{`รายได้สะสม - ปี ${dayjs(
          payslipDetail.monthPeriod
        ).format("BBBB")}`}</Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="head">
                  เงินได้สะสม (บาท)
                </TableCell>
                <TableCell align="center" className="head">
                  ภาษีหัก ณ ที่จ่ายสะสม (บาท)
                </TableCell>
                <TableCell align="center" className="head">
                  ประกันสังคมสะสม (บาท)
                </TableCell>
                <TableCell align="center" className="head">
                  กองทุนสำรองเลี้ยงชีพสะสม (บาท)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(payslipDetail.accumulateEarnings)}
                </TableCell>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(payslipDetail.accumulateTax)}
                </TableCell>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(payslipDetail.accumulateSSO)}
                </TableCell>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(payslipDetail.accumulatePF)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={4}
                  align="right"
                  className="cell"
                ></TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="right" className="cell">
                  กองทุนสำรองเลี้ยงชีพสะสมทั้งหมด (บาท)
                </TableCell>
                <TableCell colSpan={1} align="center" className="cell">
                  {utils.numberWithCommas(payslipDetail.summaryOfPF)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </StyledYearSummary>
      {/* <StyledYearSummary>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} align="center" className="cell">
                  กองทุนสำรองเลี้ยงชีพสะสมทั้งหมด (บาท)
                </TableCell>
                <TableCell colSpan={2} align="center" className="cell">
                  {utils.numberWithCommas(payslipDetail.accumulatePF)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </StyledYearSummary> */}
    </StyledRoot>
  );
}

export default TableSlip;
