import React from "react";
import { styled } from "@mui/material/styles";

import { Typography, Box, Divider } from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledCommentBox = styled(Box)(({ variant, theme }) => ({
  //   backgroundColor: "#fffde7",
  backgroundColor: "#F3FDFF",
  borderRadius: 6,
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 20,
  paddingBottom: 20,
}));

const Comments = (props) => {
  const { comments } = props;
  const { t, i18n } = useTranslation();

  return (
    <div>
      <StyledDivider />
      <div className="wrap-head">
        <StyledHeadLabel variant="h6">{t("Comment")}</StyledHeadLabel>
      </div>
      {comments.map((comment) => {
        return (
          <div
            style={{
              marginBottom: 16,
            }}
          >
            <div
              style={{
                padding: 8,
                display: "flex",
              }}
            >
              <Box>
                <AccountCircleIcon
                  style={{
                    width: 48,
                    height: 48,
                    marginRight: 8,
                    color: "#637381",
                  }}
                />
              </Box>
              <Box>
                <Typography component="p" textAlign="left" variant="subtitle1">
                  {comment.name}
                </Typography>
                <Typography
                  component="p"
                  textAlign="left"
                  variant="caption"
                  color="text.third"
                >
                  {comment.email}
                </Typography>
              </Box>
            </div>
            <div
              style={{
                paddingLeft: 8,
                paddingRight: 8,
                marginBottom: 8,
              }}
            >
              <StyledCommentBox>
                <Typography
                  component="p"
                  textAlign="left"
                  variant="body2"
                  color="text.third"
                  style={{ marginBottom: 8 }}
                >
                  {t("DateAt")} &nbsp; {comment.date}
                </Typography>
                <Typography component="p" textAlign="left" variant="body1">
                  &nbsp;&nbsp; {comment.comment}
                </Typography>
              </StyledCommentBox>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Comments;
