import React, { useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

import {
  FormControl,
  Typography,
  Box,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import { addUsers } from "../../../../../actions/user";

import AddIcon from "../../../assets/add.png";

import FileSelected from "./file-selected";
import ShowResponse from "./show-response";

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const StyledLoading = styled("div")({
  height: 200,
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const FirstUploadEmployee = () => {
  const dispatch = useDispatch();

  const [fileSelected, setFileSelected] = useState(null);
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [openLoading, setOpenLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.map((file) => {
        formData.append("file", file);
      });
      setFormFileSelected(formData);
      setFileSelected({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    },
    maxFiles: 1,
  });

  const handleClickUpload = async () => {
    setOpenLoading(true);
    //console.log(companySelected)
    const result = await dispatch(addUsers(formFileSelected));
    if (result) {
      setErrorResponse(result);
      setOpenLoading(false);
      console.log(result);
    }
  };

  const handleCloseLoading = () => {
    setOpenLoading(false);
  };

  return (
    <Fragment>
      <Typography align="center" variant="h5" gutterBottom>
        อัพโหลดข้อมูลพนักงานได้ที่นี่
      </Typography>
      {openLoading ? (
        <StyledLoading>
          <Typography variant="h6" color="text.third" gutterBottom>
            กำลังบันทึกข้อมูล...
          </Typography>
          <div>
            <CircularProgress />
          </div>
        </StyledLoading>
      ) : (
        <Fragment>
          {errorResponse ? (
            <ShowResponse
              errorResponse={errorResponse}
              handleCloseLoading={handleCloseLoading}
            />
          ) : (
            <Fragment>
              <Fragment>
                <StyledImportFile>
                  <div {...getRootProps({ className: "dropzone-upload-file" })}>
                    <input {...getInputProps()} />
                    <div className="inner-dropzone">
                      <img alt="AddIcon" src={AddIcon} width="80" />
                      <Typography
                        style={{
                          marginTop: 8,
                          backgroundColor: "transparent",
                        }}
                        className={`${fileSelected != 0 && `placeholderLabel`}`}
                        variant="body2"
                        color="text.secondary"
                      >
                        Drag & Drop หรือ คลิ๊กเพื่อเลือกไฟล์
                      </Typography>
                    </div>
                  </div>
                </StyledImportFile>
              </Fragment>

              {fileSelected && (
                <FileSelected
                  fileSelected={fileSelected}
                  handleClickUpload={handleClickUpload}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default FirstUploadEmployee;
