import { httpClient } from "./httpClient";

const getAllBank = () => {
  return httpClient.get(`/bank`);
};

const getBankByBankDetail = (idBankDetail) => {
  return httpClient.get(`/bank/${idBankDetail}`);
};

const updateBankDetail = (data) => {
  return httpClient.put(`/bank/update`,data);
};

const addBankDetailByVendor = (data) => {
  return httpClient.post(`bank/vendor/add`,data);
};

export default {
  getAllBank,
  getBankByBankDetail,
  updateBankDetail,
  addBankDetailByVendor
};