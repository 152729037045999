import ExcelJS from 'exceljs';
import dayjs from 'dayjs';

export const AttendanceXlsxReport = (t, data, leaveData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("รายงานการลงเวลา");

    const leaveTypeNames = leaveData.map(leaveType => leaveType.name);

    const headerValues = [
        "รหัสพนักงาน",
        "ชื่อ",
        "นามสกุล",
        "ตำแหน่ง",
        "ษริษัท",
        "ฝ่าย",
        "ส่วน",
        "แผนก",
        "วันที่",
        "ชื่อกะ",
        "เวลาทำงาน",
        "เวลาเข้า - ออก (First in - Last out)",

        "เวลาขอรับรองเวลาทำงาน 1",
        "เหตุผลขอรับรองเวลาทำงาน 1",
        "เหตุผลเพิ่มเติมขอรับรองเวลาทำงาน 1",
        "สถานะขอรับรองเวลาทำงาน 1",
        "วันที่_เวลา อนุมัติขอรับรองเวลาทำงาน 1",

        "เวลาขอรับรองเวลาทำงาน 2",
        "เหตุผลขอรับรองเวลาทำงาน 2",
        "เหตุผลเพิ่มเติมขอรับรองเวลาทำงาน 2",
        "สถานะขอรับรองเวลาทำงาน 2",
        "วันที่_เวลา อนุมัติขอรับรองเวลาทำงาน 2",

        "เวลาขอ OT1",
        "เหตุผลขอ OT1",
        "เหตุผลเพิ่มเติมขอ OT1",
        "สถานะขอรับรอง OT1",
        "วันที่_เวลา อนุมัติขอ OT1 Level 1",
        "วันที่_เวลา อนุมัติขอ OT1 Level 2",

        "เวลาขอ OT2",
        "เหตุผลขอ OT2",
        "เหตุผลเพิ่มเติมขอ OT2",
        "สถานะขอรับรอง OT2",
        "วันที่_เวลา อนุมัติขอ OT2 Level 1",
        "วันที่_เวลา อนุมัติขอ OT2 Level 2",

        "OT1 (ชั่วโมง)",
        "OT1.5 (ชั่วโมง)",
        "OT2 (ชั่วโมง)",
        "OT3 (ชั่วโมง)",
        "ค่ากะเช้า",
        "ค่ากะบ่าย",
        "ค่ากะดึก",
        "รวมค่ากะ",
        "ขาดงาน",
        "มาสาย/กลับก่อน",
        ...leaveTypeNames
    ];

    const headerRow = worksheet1.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    let leaveTypeColWidths = [];
    leaveData && leaveData.forEach(leaveType => {
        leaveTypeColWidths.push({
            key: `leaveType_${leaveType.idLeaveType}`,
            width: leaveType.name.length * 2
        });
    });

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname", width: 30 },
        { key: "lastname", width: 30 },
        { key: "positions", width: 30 },
        { key: "company", width: 50 },
        { key: "division", width: 30 },
        { key: "department", width: 30 },
        { key: "section", width: 30 },
        { key: "date", width: 30 },
        { key: "shiftName", width: 30 },
        { key: "shiftType", width: 30 },
        { key: "timeIntimeOut", width: 40 },

        { key: "requestTimeApproval", width: 40 },
        { key: "requestTimeReason", width: 40 },
        { key: "additionalReason", width: 40 },
        { key: "requestStatus", width: 40 },
        { key: "ApprovalRequestTimeDate", width: 40 },

        { key: "requestTimeApproval2", width: 40 },
        { key: "requestTimeReason2", width: 40 },
        { key: "additionalReason2", width: 40 },
        { key: "requestStatus2", width: 40 },
        { key: "ApprovalRequestTimeDate2", width: 40 },

        { key: "requestOTApproval", width: 40 },
        { key: "requestOTReason", width: 40 },
        { key: "additionalOTReason", width: 40 },
        { key: "requestStatus", width: 40 },
        { key: "ApprovalRequestOTDate1Level1", width: 40 },
        { key: "ApprovalRequestOTDate1Level2", width: 40 },

        { key: "requestOTApproval2", width: 40 },
        { key: "requestOTReason2", width: 40 },
        { key: "additionalOTReason2", width: 40 },
        { key: "requestStatus2", width: 40 },
        { key: "ApprovalRequestOTDate2Level1", width: 40 },
        { key: "ApprovalRequestOTDate2Level2", width: 40 },

        { key: "otOneHours", width: 20 },
        { key: "otOneFiveHours", width: 20 },
        { key: "otTwoHours", width: 20 },
        { key: "otThreeHours", width: 20 },
        { key: "shiftMoning", width: 30 },
        { key: "shiftEvening", width: 30 },
        { key: "shiftNight", width: 30 },
        { key: "totalShift", width: 20 },
        { key: "absent", width: 20 },
        { key: "late", width: 20 },
        ...leaveTypeColWidths
    ];

    function formatNumber(number) {
        if (Number.isInteger(number)) {
            return number.toString();
        } else {
            return parseFloat(number.toFixed(2));
        }
    }

    let row = [];

    data && data.map((x) => x.schedule &&
        x.schedule.map((item) => {
            const shiftName = item.shiftGroup && item.shiftGroup.nameShiftGroup ? item.shiftGroup.nameShiftGroup.toLowerCase().includes("day")
                ? item.shiftGroup.nameShiftGroup
                : (item.shiftGroup.nameShiftGroup === item.shift.shiftName) ?
                    item.shiftGroup.nameShiftGroup
                    : item.shiftGroup.nameShiftGroup + " " + item.shift.shiftName
                : item.shiftGroup && item.shiftGroup.nameShiftGroup

            const formattedShiftName = shiftName || "-";

            const timeIn = item.checkInAndcheckOutList && item.checkInAndcheckOutList.checkIn ? item.checkInAndcheckOutList.checkIn :
                (item.requestWorkingTime && item.requestWorkingTime[0] && item.requestWorkingTime[0].start ? item.requestWorkingTime[0].start :
                    item.requestWorkingTime && item.requestWorkingTime[1] && item.requestWorkingTime[1].start ? item.requestWorkingTime[1].start : "ไม่มีเวลาเข้า");

            const timeOut = item.checkInAndcheckOutList && item.checkInAndcheckOutList.checkOut ? item.checkInAndcheckOutList.checkOut :
                (item.requestWorkingTime && item.requestWorkingTime[0] && item.requestWorkingTime[0].end ? item.requestWorkingTime[0].end :
                    item.requestWorkingTime && item.requestWorkingTime[1] && item.requestWorkingTime[1].end ? item.requestWorkingTime[1].end : "ไม่มีเวลาออก");

            const timeInTimeout = timeIn + "-" + timeOut;

            const showShiftType = item.shiftType && item.shiftType.isWorkingDay === 0 ? item.shiftType.nameShiftType : `${item.shiftType && item.shiftType.timeIn} - ${item.shiftType && item.shiftType.timeOut}`

            row = [
                item.employeeId ? item.employeeId : "-",
                item.firstname ? item.firstname : "-",
                item.lastname ? item.lastname : "-",
                item.positionsName ? item.positionsName : "-",
                item.companyName ? item.companyName : "-",
                item.divisionName ? item.divisionName : "-",
                item.departmentName ? item.departmentName : "-",
                item.sectionName ? item.sectionName : "-",
                item.date ? dayjs(item.date).format("YYYY-MM-DD") : "-",
                formattedShiftName,
                showShiftType,
                timeInTimeout,

                item.requestWorkingTime && item.requestWorkingTime[0] ? `${item.requestWorkingTime[0].startDate + " - " + item.requestWorkingTime[0].endDate}` : "-",
                item.requestWorkingTime && item.requestWorkingTime[0] && item.requestWorkingTime[0].ReasonName ? item.requestWorkingTime[0].ReasonName : "-",
                item.requestWorkingTime && item.requestWorkingTime[0] && item.requestWorkingTime[0].otherReason ? item.requestWorkingTime[0].otherReason : "-",
                item.requestWorkingTime && item.requestWorkingTime[0] && item.requestWorkingTime[0].isManagerLV1Approve ? item.requestWorkingTime[0].isManagerLV1Approve === 1 ? "อนุมัติ" : "ไม่อนุมัติ" : item.requestWorkingTime && item.requestWorkingTime[0] && item.requestWorkingTime[0].startDate ? "รออนุมัติ" : "-",
                item.requestWorkingTime && item.requestWorkingTime[0] && item.requestWorkingTime[0].managerLV1ApproveDate ? item.requestWorkingTime[0].managerLV1ApproveDate : "-",

                item.requestWorkingTime && item.requestWorkingTime[1] ? `${item.requestWorkingTime[1].startDate + " - " + item.requestWorkingTime[1].endDate}` : "-",
                item.requestWorkingTime && item.requestWorkingTime[1] && item.requestWorkingTime[1].ReasonName ? item.requestWorkingTime[1].ReasonName : "-",
                item.requestWorkingTime && item.requestWorkingTime[1] && item.requestWorkingTime[1].otherReason ? item.requestWorkingTime[1].otherReason : "-",
                item.requestWorkingTime && item.requestWorkingTime[1] && item.requestWorkingTime[1].isManagerLV1Approve ? item.requestWorkingTime[1].isManagerLV1Approve === 1 ? "อนุมัติ" : "ไม่อนุมัติ" : item.requestWorkingTime && item.requestWorkingTime[1] && item.requestWorkingTime[0].startDate ? "รออนุมัติ" : "-",
                item.requestWorkingTime && item.requestWorkingTime[1] && item.requestWorkingTime[1].managerLV1ApproveDate ? item.requestWorkingTime[1].managerLV1ApproveDate : "-",

                item.requestOverTime && item.requestOverTime[0] ? `${item.requestOverTime[0].startDate + " - " + item.requestOverTime[0].endDate}` : "-",
                item.requestOverTime && item.requestOverTime[0] && item.requestOverTime[0].ReasonName ? item.requestOverTime[0].ReasonName : "-",
                item.requestOverTime && item.requestOverTime[0] && item.requestOverTime[0].otherReason ? item.requestOverTime[0].otherReason : "-",
                item.requestOverTime && item.requestOverTime[0] && item.requestOverTime[0].isManagerLV1Approve ? item.requestOverTime[0].isManagerLV1Approve === 1 ? "อนุมัติ" : "ไม่อนุมัติ" : item.requestOverTime && item.requestOverTime[0] && item.requestOverTime[0].startDate ? "รออนุมัติ" : "-",
                item.requestOverTime && item.requestOverTime[0] && item.requestOverTime[0].managerLV1ApproveDate ? item.requestOverTime[0].managerLV1ApproveDate : "-",
                item.requestOverTime && item.requestOverTime[0] && item.requestOverTime[0].managerLV2ApproveDate ? item.requestOverTime[0].managerLV2ApproveDate : "-",

                item.requestOverTime && item.requestOverTime[1] ? `${item.requestOverTime[1].startDate + " - " + item.requestOverTime[1].endDate}` : "-",
                item.requestOverTime && item.requestOverTime[1] && item.requestOverTime[1].ReasonName ? item.requestOverTime[1].ReasonName : "-",
                item.requestOverTime && item.requestOverTime[1] && item.requestOverTime[1].otherReason ? item.requestOverTime[1].otherReason : "-",
                item.requestOverTime && item.requestOverTime[1] && item.requestOverTime[1].isManagerLV1Approve ? item.requestOverTime[1].isManagerLV1Approve === 1 ? "อนุมัติ" : "ไม่อนุมัติ" : item.requestOverTime && item.requestOverTime[1] && item.requestOverTime[1].startDate ? "รออนุมัติ" : "-",
                item.requestOverTime && item.requestOverTime[1] && item.requestOverTime[1].managerLV1ApproveDate ? item.requestOverTime[1].managerLV1ApproveDate : "-",
                item.requestOverTime && item.requestOverTime[1] && item.requestOverTime[1].managerLV2ApproveDate ? item.requestOverTime[1].managerLV2ApproveDate : "-",

                item.otOneHours ? formatNumber(item.otOneHours) : "-",
                item.otOneFiveHours ? formatNumber(item.otOneFiveHours) : "-",
                item.otTwoHours ? formatNumber(item.otTwoHours) : "-",
                item.otThreeHours ? formatNumber(item.otThreeHours) : "-",
                item.morningShiftFee ? formatNumber(item.morningShiftFee) : "-",
                item.afternoonShiftFee ? formatNumber(item.afternoonShiftFee) : "-",
                item.nightShiftFee ? formatNumber(item.nightShiftFee) : "-",
                item.shiftFeeTotal ? formatNumber(item.shiftFeeTotal) : "-",
                item.checkedAbsent ? item.checkedAbsent : 0,
                "-",
            ];

            leaveData.forEach((leaveType) => {
                let usedValue = 0.00;
                // Filter empLeaveData for the specific employee
                const leaveEmpData = item.employeeLeaveData;
                if (leaveEmpData) {
                    const leaveStartDate = dayjs(leaveEmpData.start);
                    const leaveEndDate = dayjs(leaveEmpData.end);
                    const currentDate = dayjs(item.date);

                    // Generate list of dates from start to end
                    const dateList = [];
                    let tempDate = leaveStartDate;

                    while (tempDate.isBefore(leaveEndDate) || tempDate.isSame(leaveEndDate, 'day')) {
                        dateList.push(tempDate.format('YYYY-MM-DD'));
                        tempDate = tempDate.add(1, 'day');
                    }

                    if (leaveEmpData.idLeaveType === leaveType.idLeaveType) {
                        // Check if the current date is within the date list
                        if (dateList.includes(currentDate.format('YYYY-MM-DD'))) {
                            usedValue = parseFloat(leaveEmpData.used);
                        }
                    }
                }
                row.push(usedValue);
            });

            const excelRow = worksheet1.addRow(row);
            const contentStyle = {
                font: { size: 18, name: 'TH SarabunPSK' },
                alignment: {
                    vertical: 'middle',
                    horizontal: 'center'
                }
            };

            colWidths.map((col, index) => {
                worksheet1.getColumn(index + 1).width = col.width;
            });

            excelRow.eachCell((cell) => {
                cell.style = contentStyle;
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        }));

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `รายงานการลงเวลา.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}