import React from "react";
import { Avatar, Box, Grid, Icon, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import Utils from "../../../../utils";
import ChartAreaCustom from "../../shared/dashboard/ChartArea";
import CardData from "../../shared/dashboard/CardData";
import CardDashboard from "../../shared/dashboard/CardDashboard";
import { useSelector } from "react-redux";
import TableCustom from "../../shared/tableCustom";
import ChartPolarAreaCustom from "../../shared/dashboard/ChartPolarArea";
import EmployeeInOut from "./employeeInOut";

const StyledRoot = styled(Box)({
  "& .top-employee": {
    padding: "8px",
    // paddingTop: "16px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #ececec",
    borderRadius: "4px"
  }
})

const OverviewPanel = (props) => {

  const { month, year } = props;

  const {result: dashboardOverview} = useSelector(state => state.dashboardOverview);

  const leaveColumns = [
    {
      name: "ชื่อ-สกุล",
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar sx={{marginRight: "8px", width: 40, height: 40, "& img": {objectFit: "contain"}}} src={row.imageProfile}/>
          <Box flexGrow={1}>
            <Typography>{row.firstname} {row.lastname}</Typography>
            <Typography color="text.third" fontSize="14px">{row.positionName}</Typography>
          </Box>
        </Box>
      )
    },
    {
      name: "ประเภท",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => ( 
        <Typography fontSize={14}>{row.name}</Typography>
      )
    },
    {
      name: "เริ่มต้น",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{textAlign: "center"}}>
          <Typography>{dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}</Typography>
          {(row.isFullDay === 1)? (
            <Typography>เต็มวัน</Typography>
          ) : (
            <Typography>{dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
          )}
        </Box>
      )
    },
    {
      name: "สิ้นสุด",
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{textAlign: "center"}}>
          <Typography>{dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}</Typography>
          {(row.isFullDay === 1)? (
            <Typography>เต็มวัน</Typography>
          ) : (
            <Typography>{dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
          )}
        </Box>
      )
    },
    {
      name: "เหตุผล",
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">{row.description}</Typography>
        </Box>
      )
    },
  ]

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <CardData 
                    title="พนักงาน" 
                    dataValue={Utils.numberWithCommasWithoutDot(dashboardOverview.employeeTotal)}
                    dataUnit="คน"
                    dynamicFontSize
                    titleIcon={
                      <Icon baseClassName="fal" className="fa-users" color="primary" style={{display: "inline-table" }}/>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardData
                    title="รายจ่ายเงินเดือน"
                    dataValue={Utils.numberWithCommas(dashboardOverview.salaryTotal)}
                    dataUnit="บาท"
                    dynamicFontSize
                    titleIcon={
                      <Icon className="fal fa-sack-dollar" color="warning" style={{display: "inline-table", color: "#ffcd38"}}/>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardData
                    title="OT เกิน 36 ชั่วโมง"
                    // dataValue={[{name: "1", value: "20"}, {name: "2", value: "30"}]}
                    dataValue={dashboardOverview.employeeOTOver36Total.weekInMonth.map(week => {return {name: `${dayjs(week.weekStartDateText).format("dd D MMM")} - ${dayjs(week.weekEndDateText).format("dd D MMM")}`, value: week.over36HoursEmployeeTotal}})}
                    dataUnit="คน"
                    dynamicFontSize
                    select
                    titleIcon={
                      <Icon className="far fa-alarm-exclamation" color="error" style={{display: "inline-table" }}/>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardData 
                    title="รายจ่ายค่าล่วงเวลา"
                    dataValue={Utils.numberWithCommas(Object.keys(dashboardOverview.otTotal).reduce((a,b) => a + dashboardOverview.otTotal[b].payTotal, 0))}
                    dataUnit="บาท"
                    dynamicFontSize
                    titleIcon={
                      <Icon className="fal fa-hand-holding-usd" color="warning" style={{display: "inline-table", color: "#ffcd38"}}/>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">รายจ่ายเงินเดือน (รายปี {year})</Typography>
                <ChartAreaCustom
                  series={[
                    {
                      name: "รายจ่าย",
                      data: dashboardOverview.salaryTotalAllYear.map(s => {return {x: dayjs().month(s.month-1).format("MMM"), y: s.salaryTotal}})
                    }
                  ]}
                />
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">รายจ่ายแยกตามประเภท OT</Typography>
                <ChartPolarAreaCustom
                  series={Object.keys(dashboardOverview.otTotal).map(ot => dashboardOverview.otTotal[ot].payTotal)}
                  labels={Object.keys(dashboardOverview.otTotal).map(ot => `OT x${ot}`)}
                />
              </CardDashboard>
            </Grid>

            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">รายจ่ายค่าล่วงเวลา (รายปี {year})</Typography>
                <ChartAreaCustom
                  series={[
                    {
                      name: "รายจ่าย",
                      data: dashboardOverview.otTotalAllYear.map(s => {return {x: dayjs().month(s.month-1).format("MMM"), y: s.payTotal}})
                    }
                  ]}
                />
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <CardDashboard>
                <Typography marginBottom="16px">การลางาน เดือน{dayjs().month(month-1).format("MMMM")}</Typography>
                <TableCustom columns={leaveColumns} rows={dashboardOverview.workingTimeEmployeeInfo.leave.sort((a, b) => dayjs(a.startText, "DD/MM/YYYY").isBefore(dayjs(b.startText, "DD/MM/YYYY"))? -1: 1)} rowsPerPageProp={5} rowsPerPageOptions={[5]}/>
              </CardDashboard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CardDashboard>
                <Typography marginBottom="16px">5 อันดับขาดงาน เดือน{dayjs().month(month-1).format("MMMM")}</Typography>
                {dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees.length > 0 && (
                  <Box style={{display: "flex", gap: "16px", flexDirection: "column"}}>
                    {dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees.map((e, index) => (
                      <Box key={index} className="top-employee">
                        <Icon className={`fa-solid fa-square-${index+1}`} color="primary" style={{display: "inline-table", color: index <= 2? "#ffcd38": undefined}}/>
                        <Box style={{flexGrow: "1", display: "flex", alignItems: "center"}}>
                          <Avatar src={e.imageProfile} style={{marginRight: "8px", width: "32px", height: "32px"}}/>
                          <Typography>{e.firstname} {e.lastname}</Typography>
                        </Box>
                        <Typography>{e.absentTotal} วัน</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
                {dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees.length <= 0 && (
                  <Typography textAlign="center" color="text.third">ไม่มีการขาดงาน</Typography>
                )}
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={3}>
              <CardData
                title="เข้าใหม่"
                dataValue={String(dashboardOverview.employeeInfo.employeeInTotal)}
                dataUnit="คน"
                dynamicFontSize
                titleIcon={
                  <Icon className="fas fa-transporter" color="success" style={{display: "inline-table" }}/>
                }
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <CardData
                title="ลาออก"
                dataValue={String(dashboardOverview.employeeInfo.employeeOutTotal)}
                dataUnit="คน"
                dynamicFontSize
                titleIcon={
                  <Icon className="fas fa-person-circle-xmark" color="error" style={{display: "inline-table" }}/>
                }
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <CardData
                title="Turnover Rate"
                dataValue={"-"}
                dataUnit="%"
                dynamicFontSize
                titleIcon={
                  <Icon className="fas fa-chart-line" color="primary" style={{display: "inline-table" }}/>
                }
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <CardData
                title="Turnover Year"
                dataValue={"-"}
                dataUnit="%"
                dynamicFontSize
                titleIcon={
                  <Icon className="fas fa-calendar-days" color="primary" style={{display: "inline-table" }}/>
                }
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <CardData
                title="ชาย"
                dataValue={String(dashboardOverview.employeeInfo.maleTotal)}
                dataUnit="คน"
                dynamicFontSize
                titleIcon={
                  <Icon className="fas fa-mars" color="warning" style={{display: "inline-table", color: "#35baf6"}}/>
                }
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <CardData
                title="หญิง"
                dataValue={String(dashboardOverview.employeeInfo.femaleTotal)}
                dataUnit="คน"
                dynamicFontSize
                titleIcon={
                  <Icon className="fas fa-venus" color="warning" style={{display: "inline-table", color: "#ed4b82"}}/>
                }
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <CardData
                title="อายุตัวเฉลี่ย"
                dataValue={String(dashboardOverview.employeeInfo.averageAge)}
                dataUnit="ปี"
                titleIcon={
                  <Icon className="fas fa-people-group" color="primary" style={{display: "inline-table"}}/>
                }
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <CardData
                title="อายุงานเฉลี่ย"
                dataValue={String(dashboardOverview.employeeInfo.averageWorkExperience)}
                dataUnit="ปี"
                titleIcon={
                  <Icon className="fas fa-briefcase" color="primary" style={{display: "inline-table"}}/>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CardDashboard>
            <Typography marginBottom="16px">รายชื่อพนักงานเข้าใหม่ - ลาออก  เดือน{dayjs().month(month-1).format("MMMM")}</Typography>
            <EmployeeInOut />
          </CardDashboard>
        </Grid>
      </Grid>
    </StyledRoot>
  )
}

export default OverviewPanel