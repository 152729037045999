import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import { getSignatureCertificateVendor } from '../../../../../actions/vendor';

function CheckNumber(Number) {
    var decimal = false;
    Number = Number.toString();
    Number = Number.replace(/ |,|บาท|฿/gi, '');
    for (var i = 0; i < Number.length; i++) {
        if (Number[i] == '.') {
            decimal = true;
        }
    }
    if (decimal == false) {
        Number = Number + '.00';
    }
    return Number
}

function ArabicNumberToText(Number) {
    var Number = CheckNumber(Number);
    var NumberArray = new Array("ศูนย์", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า", "สิบ");
    var DigitArray = new Array("", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน");
    var BahtText = "";
    if (isNaN(Number)) {
        return "ข้อมูลนำเข้าไม่ถูกต้อง";
    } else {
        if ((Number - 0) > 9999999.9999) {
            return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
        } else {
            Number = Number.split(".");
            if (Number[1].length > 0) {
                Number[1] = Number[1].substring(0, 2);
            }
            var NumberLen = Number[0].length - 0;
            for (var i = 0; i < NumberLen; i++) {
                var tmp = Number[0].substring(i, i + 1) - 0;
                if (tmp != 0) {
                    if ((i == (NumberLen - 1)) && (tmp == 1)) {
                        BahtText += "เอ็ด";
                    } else
                        if ((i == (NumberLen - 2)) && (tmp == 2)) {
                            BahtText += "ยี่";
                        } else
                            if ((i == (NumberLen - 2)) && (tmp == 1)) {
                                BahtText += "";
                            } else {
                                BahtText += NumberArray[tmp];
                            }
                    BahtText += DigitArray[NumberLen - i - 1];
                }
            }
            BahtText += "บาท";
            if ((Number[1] == "0") || (Number[1] == "00")) {
                BahtText += "ถ้วน";
            } else {
                var DecimalLen = Number[1].length - 0;
                for (var i = 0; i < DecimalLen; i++) {
                    var tmp = Number[1].substring(i, i + 1) - 0;
                    if (tmp != 0) {
                        if ((i == (DecimalLen - 1)) && (tmp == 1)) {
                            BahtText += "เอ็ด";
                        } else
                            if ((i == (DecimalLen - 2)) && (tmp == 2)) {
                                BahtText += "ยี่";
                            } else
                                if ((i == (DecimalLen - 2)) && (tmp == 1)) {
                                    BahtText += "";
                                } else {
                                    BahtText += NumberArray[tmp];
                                }
                        BahtText += DigitArray[DecimalLen - i - 1];
                    }
                }
                BahtText += "สตางค์";
            }
            return BahtText;
        }
    }
}

export const Tawi50PDFfile = async (type, selectedYear, data) => {
    const fontSize = 16;
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);
    const pages = pdfDoc.getPages();
    const page1 = pages[0];

    const PayrunItem = data && data.PayrunDetailResult[0];
    const PayrunMainItem = data && data.PayrunDetailMainResult[0];
    const DeductionItem = data && data.DeductionResult[0];
    const AdditionData = data && data.AdditionResult[0];
    const employeeData = data && data.employeeDetails; // All Users

    if (PayrunItem && employeeData) {
        const indexMap = new Map();
        employeeData.forEach((item, index) => {
            indexMap.set(item.idEmp, index);
        });

        const index = indexMap.get(PayrunItem.idEmp);
        if (index !== undefined) {
            // Display the index for the user
            page1.drawText(`${index + 1}`, { x: index > 100 ? 95 : 105, y: 605, size: fontSize, font: font });
        }
    }

    { (PayrunItem && PayrunItem.vendorName) && page1.drawText(`${PayrunItem.vendorName}`, { x: 60, y: 730, size: fontSize, font: font }); }

    page1.drawText(`${(
        PayrunItem.addressVendor &&
        PayrunItem.districtVendor &&
        PayrunItem.provinceVendor &&
        PayrunItem.areaCodeVendor &&
        `${PayrunItem.addressVendor} อำเภอ${PayrunItem.districtVendor} จังหวัด${PayrunItem.provinceVendor} ${PayrunItem.areaCodeVendor}`) || ""}`, { x: 60, y: 707, size: fontSize, font: font });

    //taxpayer
    page1.drawText(`${PayrunItem.firstname && PayrunItem.lastname ? PayrunItem.firstname + " " + PayrunItem.lastname : "-"}`, { x: 60, y: 658, size: fontSize, font: font });

    if (PayrunItem.vendorTaxID) {
        let vendorTaxID = [
            PayrunItem.vendorTaxID[0],
            PayrunItem.vendorTaxID.slice(1, 5),
            PayrunItem.vendorTaxID.slice(5, 10),
            PayrunItem.vendorTaxID.slice(10, 12),
            PayrunItem.vendorTaxID[12]
        ];
        page1.drawText(`${vendorTaxID[0]} `, { x: 378, y: 747, size: fontSize, font: font });
        page1.drawText(`${vendorTaxID[1][0]}  ${vendorTaxID[1][1]} ${vendorTaxID[1][2]}  ${vendorTaxID[1][3]} `, { x: 397, y: 747, size: fontSize, font: font });
        page1.drawText(`${vendorTaxID[2][0]}  ${vendorTaxID[2][1]} ${vendorTaxID[2][2]}  ${vendorTaxID[2][3]}  ${vendorTaxID[2][4]} `, { x: 451, y: 747, size: fontSize, font: font });
        page1.drawText(`${vendorTaxID[3][0]} ${vendorTaxID[3][1]} `, { x: 518, y: 747, size: fontSize, font: font });
        page1.drawText(`${vendorTaxID[4]} `, { x: 548, y: 747, size: fontSize, font: font });
    }

    if (PayrunItem.personalId) {
        let personalId = [
            PayrunItem.personalId[0],
            PayrunItem.personalId.slice(1, 5),
            PayrunItem.personalId.slice(5, 10),
            PayrunItem.personalId.slice(10, 12),
            PayrunItem.personalId[12]
        ];
        page1.drawText(`${personalId[0]} `, { x: 378, y: 679, size: fontSize, font: font });
        page1.drawText(`${personalId[1][0]}  ${personalId[1][1]} ${personalId[1][2]}  ${personalId[1][3]} `, { x: 397, y: 679, size: fontSize, font: font });
        page1.drawText(`${personalId[2][0]}  ${personalId[2][1]} ${personalId[2][2]}  ${personalId[2][3]}  ${personalId[2][4]} `, { x: 451, y: 679, size: fontSize, font: font });
        page1.drawText(`${personalId[3][0]} ${personalId[3][1]} `, { x: 518, y: 679, size: fontSize, font: font });
        page1.drawText(`${personalId[4]} `, { x: 548, y: 679, size: fontSize, font: font });
    }

    page1.drawText(`${PayrunItem.houseNo || ""}${PayrunItem.vilage ? ` หมู่บ้าน${PayrunItem.vilage}` : ""}${PayrunItem.vilageNo ? ` หมู่ที่ ${PayrunItem.vilageNo}` : ""}${PayrunItem.alley ? ` ซอย${PayrunItem.alley}` : ""}${PayrunItem.road ? ` ถนน${PayrunItem.road}` : ""}${PayrunItem.subDistrict ? ` ตำบล${PayrunItem.subDistrict}` : ""}${PayrunItem.district ? ` อำเภอ${PayrunItem.district}` : ""}${PayrunItem.province ? ` จังหวัด${PayrunItem.province}` : ""}${PayrunItem.areaCode ? ` ${PayrunItem.areaCode}` : ""} `, {
        x: 60, y: 632, size: fontSize, font: font
    });

    page1.drawText(`X`, { x: 212, y: 605, size: fontSize, font: font });

    if (PayrunItem) {

        // 2nd December totalEarnings
        let TotalDecTax = PayrunMainItem.Dec_Sum_Tax || 0;
        let TotalDecSSO = PayrunMainItem.Dec_Sum_SSO || 0;
        let TotalDecLateAndAbsentValue = (PayrunMainItem.Dec_Sum_absent || 0) + (PayrunMainItem.Dec_Sum_late || 0);
        let TotalDecemberDeductions = (PayrunItem.HalfDecemberEarning || 0) - TotalDecLateAndAbsentValue;

        let TotalLateAndAbsentValue = (PayrunMainItem.Sum_absent || 0) + (PayrunMainItem.Sum_late || 0);

        let TotalAdditions = (AdditionData.AdditionValue || 0) - (AdditionData.AllAdditionDec || 0);
        let TotalDeductions = (DeductionItem.value || 0) - (DeductionItem.AllDeductionDec || 0) + TotalLateAndAbsentValue + TotalDecemberDeductions;
        let AllSalary = PayrunItem.SUM_totalEarning ? PayrunItem.SUM_totalEarning + (PayrunItem.beforeAccumulateEarnings ? PayrunItem.beforeAccumulateEarnings : 0) - TotalDeductions - TotalAdditions : 0;
        let totalTax = PayrunMainItem.SUM_TaxValue ? PayrunMainItem.SUM_TaxValue + (PayrunItem.beforeAccumulateTax ? PayrunItem.beforeAccumulateTax : 0) - TotalDecTax : 0;

        //วัน เดือน ปี ที่จ่าย
        selectedYear && page1.drawText(`${selectedYear} `, { x: 354, y: 536, size: fontSize, font: font });

        //2. จำนวนเงินที่จ่าย
        if (AllSalary) {
            page1.drawText(`${AllSalary.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, {
                x: AllSalary < 10 ? 450 :
                    AllSalary < 100 ? 444 :
                        AllSalary < 1000 ? 438 :
                            AllSalary < 10000 ? 430 :
                                AllSalary < 100000 ? 424 :
                                    AllSalary < 1000000 ? 418 : 410,
                y: 536, size: fontSize, font: font
            });
        }

        //2. ภาษีที่หัก
        page1.drawText(`${totalTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, { x: 495, y: 536, size: fontSize, font: font });

        //รวม
        page1.drawText(`${AllSalary.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, {
            x: AllSalary < 10 ? 450 :
                AllSalary < 100 ? 444 :
                    AllSalary < 1000 ? 438 :
                        AllSalary < 10000 ? 430 :
                            AllSalary < 100000 ? 424 :
                                AllSalary < 1000000 ? 418 : 410,
            y: 182, size: fontSize, font: font
        });
        page1.drawText(`${totalTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, {
            x: totalTax < 10 ? 523 :
                totalTax < 100 ? 517 :
                    totalTax < 1000 ? 511 :
                        totalTax < 10000 ? 503 :
                            totalTax < 100000 ? 497 :
                                totalTax < 1000000 ? 491 : 483,
            y: 182, size: fontSize, font: font
        });

        page1.drawText(`0.00`, { x: 250, y: 145, size: 12, font: font });

        let sumSSO = (PayrunMainItem.SUM_SSO || 0) + (PayrunItem.beforeAccumulateSSO || 0) - TotalDecSSO;
        page1.drawText(`${sumSSO > 0 ? sumSSO.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00"}`, { x: 372, y: 145, size: 12, font: font });

        let sumSF = (PayrunMainItem.SUM_PF || 0) + (PayrunItem.beforeAccumulatePF || 0);
        page1.drawText(`${sumSF > 0 ? sumSF.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00"}`, { x: 501, y: 145, size: 12, font: font })

        //Thai Baht to Text
        const thaibath = ArabicNumberToText(Number.parseFloat(totalTax).toFixed(2));
        if (thaibath && totalTax > 0) {
            page1.drawText(`${thaibath} `, { x: 300, y: 162, size: fontSize, font: font });
        } else if (totalTax < 1) {
            page1.drawText("ศูนย์บาทถ้วน", { x: 300, y: 162, size: fontSize, font: font });
        }
        page1.drawText(`X`, { x: 85, y: 121, size: fontSize, font: font });

        const presentDay = new Date();
        const presentDayTH = dayjs(presentDay).locale('th').format("DD");
        const presentMonthTH = dayjs(presentDay).locale('th').format("MMMM");
        const thaiYear = dayjs(presentDay).format('YYYY')
        const formattedThaiYear = parseInt(thaiYear) + 543;

        if (PayrunItem.authorizedSignatureOneImageFilename) {
            const imageData = await getSignatureCertificateVendor();

            const pngImage = await pdfDoc.embedPng(imageData.data);
            const pngDims = pngImage.scale(0.05);
            page1.drawImage(pngImage, {
                x: 380, y: 81, width: pngDims.width, height: pngDims.height
            })
        }
        page1.drawText(`${presentDayTH}`, { x: 350, y: 76, size: 13, font: font });
        page1.drawText(`${presentMonthTH}`, { x: 372, y: 76, size: 13, font: font });
        page1.drawText(`${formattedThaiYear}`, { x: 430, y: 76, size: 13, font: font });
    }

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
}