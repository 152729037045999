import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Container,
  styled,
  Link,
  Breadcrumbs,
  Typography,
  FormControl,
  LinearProgress,
  CardActionArea,
  Box,
} from "@mui/material";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import CardStyle from "../../../shared/general/Card";

import { getAreaDetail } from "../../../../../actions/area";

import EmployeeList from "./employeeList";

import loading from "../../../assets/social-media.gif";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
});

const StyledLoading = styled("div")({
  marginTop: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  height: "inherit",
});

const StyledContent = styled("div")({
  display: "flex",
  padding: 16,
  "& .avatar": {
    marginRight: 8,
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  borderTop: "none",
  display: "flex",
  flexWrap: "wrap",
  "& .wrap-add": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 8,
  },
}));

const AccessAreaDetailAdmin = (props) => {
  let { selected, handleClickAll } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: areaDetail } = useSelector((state) => state.areaDetail);
  const { isFetching: areaDetailFetching } = useSelector(
    (state) => state.areaDetail
  );
  const [openDialogAdd, setOpenDialogAdd] = React.useState("");
  const [state, setstate] = useState(null);
  const [expanded, setExpanded] = React.useState("");
  const [areaSelected, setAreaSelected] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    if (selected) {
      dispatch(getAreaDetail(selected.idSection));
    }

    let temp = [];
    temp.push(
      <Link underline="hover" key="1" color="inherit" onClick={handleClickAll}>
        {t("Lists")}
      </Link>
    );
    temp.push(
      <Link underline="none" key="2" color="inherit">
        {`${selected.sectionName}`}
      </Link>
    );
    setBreadcrumbs(temp);
  }, [selected]);

  useEffect(() => {
    if (areaDetail && areaDetail.length > 0) {
      setAreaSelected(areaDetail[0]);
    }
  }, [areaDetail]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClickArea = (item) => {
    console.log("item: ", item);
    setAreaSelected(item);
  };

  return (
    <StyledRoot>
      <div style={{ marginTop: 16 }}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </div>

      {areaDetailFetching ? (
        <StyledLoading>
          <img width="80" alt="loading" src={loading} />
        </StyledLoading>
      ) : (
        <Fragment>
          {areaDetail && areaDetail.length > 0 ? (
            <Fragment>
              <div
                style={{ display: "flex", flexWrap: "wrap", marginBottom: 24 }}
              >
                {areaDetail.map((item) => (
                  <CardStyle style={{ width: 340, margin: 8 }}>
                    <CardActionArea onClick={() => handleClickArea(item)}>
                      <StyledContent>
                        <div style={{ width: "100%" }}>
                          <Typography variant="h6" gutterBottom>
                            {item.areaName}
                          </Typography>
                          <LinearProgress
                            variant="determinate"
                            value={
                              item.sum > 0 ? (item.sum / item.amount) * 100 : 0
                            }
                          />
                          <Typography
                            align="right"
                            color="text.secondary"
                            style={{ marginTop: 4 }}
                          >
                            <Typography
                              color="text.secondary"
                              component="span"
                              style={{ fontWeight: 600 }}
                            >
                              {item.sum}
                            </Typography>
                            {`/${item.amount} คน`}
                          </Typography>
                        </div>
                      </StyledContent>
                    </CardActionArea>
                  </CardStyle>
                ))}
              </div>
              {areaSelected && (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "16px 0",
                    }}
                  >
                    <Typography variant="h4">{`${areaSelected.areaName}`}</Typography>
                    <Typography variant="h4">{`${areaSelected.amount} คน`}</Typography>
                  </div>
                  <div>
                    {areaSelected.positions.length > 0 &&
                      areaSelected.positions.map((value, index) => (
                        <Accordion
                          expanded={expanded === `panel${index}`}
                          onChange={handleChange(`panel${index}`)}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography>{`${value.positionsName}`}</Typography>
                              {value.headCount > 0 ? (
                                <Typography>{`${value.headCount} คน`}</Typography>
                              ) : (
                                <Typography
                                  style={{ color: "#da3252", fontWeight: 500 }}
                                >{`${value.headCount} คน`}</Typography>
                              )}
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            {/* <EmployeeList modeSelected={modeSelected} /> */}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </div>
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Typography color="text.secondary">ไม่มีข้อมูล</Typography>
          )}
        </Fragment>
      )}
    </StyledRoot>
  );
};

export default AccessAreaDetailAdmin;
