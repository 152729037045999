import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Divider, Grid, styled, Typography } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import { getEmployeePositionChange } from "../../../../../../actions/employee";
import { getCompanyContract } from "../../../../../../actions/vendor";

import DialogEdit from "./DialogEdit";
import { fontWeight } from "@mui/system";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapChange = styled("div")({
  marginTop: 8,
  "& .inner": {
    marginBottom: 8,
  },
  "& .first-line": {
    display: "flex",
    "& .date": {
      margin: "0 8px",
      fontWeight: 600,
    },
  },
  "& .positions-name": {
    fontWeight: 600,
    marginLeft: 16,
  },
  "& .second-line": {
    marginLeft: 16,
    fontSize: 16,
  },
});

const Transfer = () => {
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employeePosition } = useSelector(
    (state) => state.employeePosition
  );
  const { t, i18n } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const toggleDrawer = (mode, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDialog(open);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (employeeProfile) {
      dispatch(getEmployeePositionChange(employeeProfile.idEmp));
    }
  }, [employeeProfile]);

  return (
    <div>
      <StyledWrapTop>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditIcon />}
            onClick={toggleDrawer("", true)}
          >
            {t("Edit")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Company")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.companyName ? employeeProfile.companyName : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Division")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.divisionName
                ? employeeProfile.divisionName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Department")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.departmentName
                ? employeeProfile.departmentName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Section")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.sectionName ? employeeProfile.sectionName : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Position")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.positionsName
                ? employeeProfile.positionsName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("JobGroup")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobGroupName
                ? employeeProfile.jobGroupName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("JobLevel")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobLevelName
                ? employeeProfile.jobLevelName
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("ChangeLog")}
        </StyledHeadLabel>
        {employeePosition && (
          <StyledWrapChange>
            {employeePosition.map((item, index) => (
              <div className="inner" key={index}>
                <div className="first-line">
                  {">"}
                  {item.end ? (
                    <Typography className="date" color="text.third">
                      {`[${dayjs(item.start)
                        .locale(localStorage.getItem("language") || "th")
                        .format(
                          localStorage.getItem("language") === "th"
                            ? "DD MMM BBBB"
                            : "DD MMM YYYY"
                        )} - ${dayjs(item.end)
                        .locale(localStorage.getItem("language") || "th")
                        .format(
                          localStorage.getItem("language") === "th"
                            ? "DD MMM BBBB"
                            : "DD MMM YYYY"
                        )}]`}
                    </Typography>
                  ) : (
                    <Typography className="date" color="text.third">
                      {`[${dayjs(item.start)
                        .locale(localStorage.getItem("language") || "th")
                        .format(
                          localStorage.getItem("language") === "th"
                            ? "DD MMM BBBB"
                            : "DD MMM YYYY"
                        )} - ${t("Current")}]`}
                    </Typography>
                  )}
                </div>
                <Typography className="positions-name">
                  {`${item.companyName}: ${item.positionsName}`}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  gutterBottom
                >
                  {`${t("Section")}: ${
                    item.sectionName ? item.sectionName : "-"
                  }`}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  gutterBottom
                >
                  {`${t("Department")}: ${
                    item.departmentName ? item.departmentName : "-"
                  }`}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  gutterBottom
                >
                  {`${t("Division")}: ${
                    item.divisionName ? item.divisionName : "-"
                  }`}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  gutterBottom
                  style={{ fontStyle: "oblique" }}
                >
                  {`${t("Reason")}: ${item.remark ? item.remark : "-"}`}
                </Typography>
              </div>
            ))}
          </StyledWrapChange>
        )}
      </div>
      {openDialog && 
        <DialogEdit 
          open={openDialog} 
          handleCloseDialog={handleCloseDialog} 
        />
      }
    </div>
  );
};

export default Transfer;
