import {
  PAYRUNS_FETCHING,
  PAYRUNS_FAILED,
  PAYRUNS_SUCCESS,
  PAYRUNS_DETAIL_FETCHING,
  PAYRUNS_DETAIL_FAILED,
  PAYRUNS_DETAIL_SUCCESS,
  PAYRUNS_DETAIL_EMPLOYEES_FETCHING,
  PAYRUNS_DETAIL_EMPLOYEES_FAILED,
  PAYRUNS_DETAIL_EMPLOYEES_SUCCESS,
  PAYSLIP_DETAIL_FETCHING,
  PAYSLIP_DETAIL_SUCCESS,
  PAYSLIP_DETAIL_FAILED,
  PAYSLIP_FETCHING,
  PAYSLIP_SUCCESS,
  PAYSLIP_FAILED,
  BILLING_EMPLOYEES_FETCHING,
  BILLING_EMPLOYEES_FAILED,
  BILLING_EMPLOYEES_SUCCESS,
  PAYRUNS_SUMMARY_YEAR_FETCHING,
  PAYRUNS_SUMMARY_YEAR_SUCCESS,
  PAYRUNS_SUMMARY_YEAR_FAILED
} from "./types";

import PayRunsService from "../services/payruns.service";

export const allPayruns = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: PAYRUNS_FETCHING,
    });
    const res = await PayRunsService.allPayruns(filter);
    if (res) {
      dispatch({
        type: PAYRUNS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PAYRUNS_FAILED,
    });
    console.log(err);
  }
};

export const getPayRunsId = (idPayrun) => async (dispatch) => {
  try {
    dispatch({
      type: PAYRUNS_DETAIL_FETCHING,
    });
    const res = await PayRunsService.getPayRunsId(idPayrun);
    if (res) {
      dispatch({
        type: PAYRUNS_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: PAYRUNS_DETAIL_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const getPayrunsDetailById = (idPayrun) => async (dispatch) => {
  try {
    dispatch({
      type: PAYRUNS_DETAIL_EMPLOYEES_FETCHING,
    });
    const res = await PayRunsService.getPayrunsDetailById(idPayrun);
    if (res) {
      dispatch({
        type: PAYRUNS_DETAIL_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: PAYRUNS_DETAIL_EMPLOYEES_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const createPayruns = (formData) => async (dispatch) => {
  try {
    const res = await PayRunsService.createPayruns(formData);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const uploadAddOn = (formData) => async (dispatch) => {
  try {
    const res = await PayRunsService.uploadAddOn(formData);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updatePayrunDetail = (data) => async () => {
  try {
    const res = await PayRunsService.updatePayrunDetail(data);
    if (res) {
      return res.data;
    }
  } catch (error) {
    return "Error";
  }
};

export const updateClosePayroll = (idPayrun) => async () => {
  try {
    const res = await PayRunsService.updateClosePayroll(idPayrun);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const getPayslip = (filter, date) => async (dispatch) => {
  try {
    dispatch({
      type: PAYSLIP_FETCHING,
    });
    const res = await PayRunsService.getPayslip(filter, date);
    if (res) {
      dispatch({
        type: PAYSLIP_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: PAYSLIP_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const getPayslipByIdPayrunDetail =
  (idPayrunDetail) => async (dispatch) => {
    try {
      dispatch({
        type: PAYSLIP_DETAIL_FETCHING,
      });
      const res = await PayRunsService.getPayslipByIdPayrunDetail(
        idPayrunDetail
      );
      if (res) {
        dispatch({
          type: PAYSLIP_DETAIL_SUCCESS,
          payload: res.data,
        });
        return res.data;
      }
    } catch (err) {
      dispatch({
        type: PAYSLIP_DETAIL_FAILED,
      });
      console.log(err);
      return err;
    }
  };

export const getPayslipByEmployee = (date, employeeId) => async (dispatch) => {
  try {
    dispatch({
      type: PAYSLIP_DETAIL_FETCHING,
    });
    const res = await PayRunsService.getPayslipByEmployee(date, employeeId);
    if (res) {
      dispatch({
        type: PAYSLIP_DETAIL_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: PAYSLIP_DETAIL_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const deletePayroll = (dataForm) => async () => {
  try {
    const res = await PayRunsService.deletePayroll(dataForm);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const getPayrunDetailForBilling = (idPayrun) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_EMPLOYEES_FETCHING,
    });
    const res = await PayRunsService.getPayrunDetailForBilling(idPayrun);
    if (res) {
      dispatch({
        type: BILLING_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: BILLING_EMPLOYEES_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const createBilling = (idPayrun) => async () => {
  try {
    const res = await PayRunsService.createBilling(idPayrun);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getPayrunsSummaryYear = (year) => async (dispatch) => {
  try {
    dispatch({
      type: PAYRUNS_SUMMARY_YEAR_FETCHING,
    });
    const res = await PayRunsService.getPayrunsSummaryYear(year);
    if (res) {
      dispatch({
        type: PAYRUNS_SUMMARY_YEAR_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: PAYRUNS_SUMMARY_YEAR_FAILED,
    });
    console.log(err);
    return err;
  }
};