import {
  ATTENDANCE_ONCE_FETCHING,
  ATTENDANCE_ONCE_FAILED,
  ATTENDANCE_ONCE_SUCCESS,
  ATTENDANCE_FETCHING,
  ATTENDANCE_FAILED,
  ATTENDANCE_SUCCESS,
  ATTENDANCE_LIST_FETCHING,
  ATTENDANCE_LIST_FAILED,
  ATTENDANCE_LIST_SUCCESS,
} from "./types";

import attendanceService from "../services/attendance.service";

export const clearAttendance = () => async (dispatch) => {
  dispatch({
    type: ATTENDANCE_SUCCESS,
    payload: null,
  });
};

export const getAttendanceById = (filter, id) => async (dispatch) => {
  try {
    dispatch({
      type: ATTENDANCE_FETCHING,
    });
    const res = await attendanceService.getAttendanceById(filter, id);
    if (res) {
      dispatch({
        type: ATTENDANCE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: ATTENDANCE_FAILED,
    });
    return err;
  }
};

export const getAttendanceTransaction = (filter) => async (dispatch) => {
  try {
    const res = await attendanceService.getAttendanceTransaction(filter);
    if (res) {
      return res;
    }
  } catch (err) {
    return err;
  }
};

export const getAttendanceCompanyByIdEmp =
  (filter, idCompany, idEmp) => async (dispatch) => {
    try {
      dispatch({
        type: ATTENDANCE_FETCHING,
      });
      const res = await attendanceService.getAttendanceCompanyByIdEmp(
        filter,
        idCompany,
        idEmp
      );
      if (res) {
        dispatch({
          type: ATTENDANCE_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (err) {
      dispatch({
        type: ATTENDANCE_FAILED,
      });
      return err;
    }
  };

export const getAttendanceOnce = (mode, filter) => async (dispatch) => {
  try {
    dispatch({
      type: ATTENDANCE_ONCE_FETCHING,
    });
    const res = await attendanceService.getAttendanceOnce(mode, filter);
    if (res) {
      dispatch({
        type: ATTENDANCE_ONCE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: ATTENDANCE_ONCE_FAILED,
    });
    return err;
  }
};

export const getAttendanceEmployeesVendor =
  (id, filter) => async (dispatch) => {
    try {
      dispatch({
        type: ATTENDANCE_ONCE_FETCHING,
      });
      const res = await attendanceService.getAttendanceEmployeesVendor(
        id,
        filter
      );
      if (res) {
        dispatch({
          type: ATTENDANCE_ONCE_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (err) {
      dispatch({
        type: ATTENDANCE_ONCE_FAILED,
      });
      return err;
    }
  };

export const getTimeAttendanceFilterDate = (id, filter) => async (dispatch) => {
  try {
    dispatch({
      type: ATTENDANCE_FETCHING,
    });
    const res = await attendanceService.getTimeAttendanceFilterDate(id, filter);
    if (res) {
      dispatch({
        type: ATTENDANCE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: ATTENDANCE_FAILED,
    });
    return err;
  }
};

export const getAttendanceCompany = (idCompany, filter) => async (dispatch) => {
  try {
    dispatch({
      type: ATTENDANCE_LIST_FETCHING,
    });
    const res = await attendanceService.getAttendanceCompany(idCompany, filter);
    if (res) {
      dispatch({
        type: ATTENDANCE_LIST_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: ATTENDANCE_LIST_FAILED,
    });
    return err;
  }
};

export const getTimeAttendanceFilterDateByIdVendor =
  (id, filter) => async (dispatch) => {
    try {
      dispatch({
        type: ATTENDANCE_FETCHING,
      });
      const res = await attendanceService.getTimeAttendanceFilterDateByIdVendor(
        id,
        filter
      );
      if (res) {
        dispatch({
          type: ATTENDANCE_SUCCESS,
          payload: res.data,
        });
        return res;
      }
    } catch (err) {
      dispatch({
        type: ATTENDANCE_FAILED,
      });
      return err;
    }
  };

export const getAttendanceByManager = (filter) => async (dispatch) => {
  try {
    const res = await attendanceService.getAttendanceByManager(filter);
    if (res) {
      dispatch({
        type: ATTENDANCE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ATTENDANCE_FAILED,
    });
  }
};

export const getAttendanceByIdEmpEditRequest =
  (idEmp, idCompany, date) => async (dispatch) => {
    try {
      dispatch({
        type: ATTENDANCE_FETCHING,
      });
      const res = await attendanceService.getAttendanceByIdEmpEditRequest(
        idEmp,
        idCompany,
        date
      );
      if (res) {
        dispatch({
          type: ATTENDANCE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: ATTENDANCE_FAILED,
      });
    }
  };

export const addAttendance = (formData) => async () => {
  try {
    const res = await attendanceService.addAttendance(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const result = {
      status: "",
      message: "",
    };
    if (error.response) {
      result.status = error.response.status;
      result.message = error.response.data.message;
    }
    return result;
  }
};

export const getAttendanceVendor = (idEmp, idCompany, filter) => async (dispatch) => {
  try {
    dispatch({
      type: ATTENDANCE_FETCHING,
    });
    const res = await attendanceService.getAttendanceVendor(idEmp, idCompany, filter);
    if (res) {
      dispatch({
        type: ATTENDANCE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: ATTENDANCE_FAILED,
    });
    return err;
  }
};

export const getWorkingHoursByIdEmployees = (idEmp, query) => async (dispatch) => {
  try {
    const res = await attendanceService.getWorkingHoursByIdEmployees(idEmp, query);
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response
  }
}
