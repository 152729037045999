import React, { Fragment, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import DrawerEditBank from "./drawerEditBank";

const TabBank = (props) => {

  const { data } = props;
  const [isOpenEditBank, setIsOpenEditBank] = useState(false);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography className="headerText">Bank</Typography>
          <Typography className="valueText">{data.bankDetail.bankName || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className="headerText">Account Name</Typography>
          <Typography className="valueText">{data.bankDetail.accountName || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className="headerText">Account Number</Typography>
          <Typography className="valueText">{data.bankDetail.accountNo || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className="headerText">Branch Name</Typography>
          <Typography className="valueText">{data.bankDetail.branchName|| "-"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="headerText">Branch Address</Typography>
          <Typography className="valueText">{data.bankDetail.branchAddress || "-"}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
          <Button variant="outlined" onClick={()=>{setIsOpenEditBank(true)}}>แก้ไข</Button>
        </Grid>
      </Grid>

      {isOpenEditBank && <DrawerEditBank open={isOpenEditBank} handleClose={()=>{setIsOpenEditBank(false)}} data={data}/>}
    </Fragment>
  )
}

export default TabBank;