import React, { useEffect, useState, Fragment } from "react";
import { 
  useSelector 
} from "react-redux";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import TableRequest from "./table";

const Leave = (props) => {
  const { 
    searchName 
  } = props;
  const { t } = useTranslation();

  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );

  const [dataTableWaiting, setDataTableWaiting] = useState(null);

  useEffect(() => {
    if (leaveEmployeeList) {
      setDataTableWaiting(
        leaveEmployeeList.filter((x) => {
          if(
            (x.isApprove === null && x.isActive === 1) && 
            (
              (searchName && searchName.length > 0) ? 
              (
                x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
                x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
              )
              : true
            )
          ){
            return true;
          } else {
            return false;
          }
        })
      );
    }
  }, [leaveEmployeeList, searchName]);

  return (
    <Fragment>
      <div style={{ width:"100%", display:"flex", justifyContent:"flex-end", marginBottom: 16 }}>
        <Typography style={{ fontSize: 20, fontWeight: 500, color: "#e46a76" }}>
          {`${(dataTableWaiting && dataTableWaiting.length) || 0} ${t("Items")}`}
        </Typography>
      </div>
      {leaveEmployeeList && dataTableWaiting && (
        <TableRequest data={dataTableWaiting} searchName={searchName} />
      )}
    </Fragment>
  );
};

export default Leave;
