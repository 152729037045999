import React from "react";
import {
  Box,
  Container,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import DailyPanel from "./DailyPanel";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
});

const AccessAreaAdmin = () => {
  const { t, i18n } = useTranslation();
  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("Voyage")}
          </Typography>
        </div>
        <DailyPanel />
      </Container>
    </StyledRoot>
  );
};

export default AccessAreaAdmin;
