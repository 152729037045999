import { httpClient } from "./httpClient";

const addPerformance = (idEmp, data) => {
    return httpClient.post(`performance/add/users/${idEmp}`, data);
};

const updatePerformance = (idPerformance, data) => {
    return httpClient.put(`performance/update/${idPerformance}`,data);
};

const getPerformanceByIdCompany = () => {
    return httpClient.get(`performance/company`);
};

const addPerformanceByIdCompany = (data) => {
    return httpClient.post(`performance/company/add`,data);
};

const updatePerformanceByIdCompany = (data) => {
    return httpClient.put(`performance/company`,data);
};

const deletePerformanceByIdPerformance = (data) => {
    return httpClient.delete(`performance/company/delete`, { data: data });
};

const getAllPerformancesByIdEmp = (idEmp) => {
    return httpClient.get(`performance/employee/${idEmp}`);
};

const addPerformanceByIdEmp = (data) => {
    return httpClient.post('performance/employee/add',data);
};

const updatePerformanceLastYear = (data) => {
    return httpClient.put(`performance/employee/update`,data);
};

export default {
    addPerformance,
    updatePerformance,
    getPerformanceByIdCompany,
    addPerformanceByIdCompany,
    updatePerformanceByIdCompany,
    deletePerformanceByIdPerformance,
    getAllPerformancesByIdEmp,
    addPerformanceByIdEmp,
    updatePerformanceLastYear
};