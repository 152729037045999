import React, { Fragment, useEffect, useState } from "react";
import { Avatar, Box, Breadcrumbs, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, styled } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../../../shared/date/datePicker";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import dayjs from "dayjs";
import CardStyle from "../../../shared/general/Card";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getAllCompaniesByVendor } from "../../../../../actions/vendor";
import { SelectAllCompaniesVendor } from "../SelectCompanyLists";
import { getEmployWorkingDays } from "../../../../../actions/report";
import { LeaveAbsentLateXlsxReport } from "../xlsx-export/summaryLeaveAbsentLateXlsx";

const StyledRoot = styled(Box)({
    backgroundColor: "#FFFFFF !important",
    "& .search-date-container": {
        marginTop: "24px",
        display: "flex",
        flexWrap: "nowrap",
        gap: "16px",
        "& .search-date-box": {
            maxWidth: "350px",
            width: "100%",
        },
        "& .search-button": {
            marginTop: "29px",
            display: "flex",
            alignItems: "center",
        },
    },

    ["@media only screen and (max-width: 600px)"]: {
        "& .search-date-container": {
            flexDirection: "column",
            "& .search-date-box": {
                maxWidth: "100%",
            },
            "& .search-button": {
                marginTop: "0",
                width: "100%",
            },
        }
    }
})

const StyledCellHeader = styled(TableCell)({
    borderBottom: "0px",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    padding: 8,
    minWidth: 50,
    "& .MuiTypography-root": {
        color: "#637381",
        backgroundColor: "#f4f6f8",
        "&.weekend": {
            fontStyle: "oblique",
        },
        "&.workday": {
            fontWeight: 600,
        },
    },
    "&.sticky": {
        padding: 0,
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        boxShadow: "10px 0px 10px #EEEEEE",
        "& .MuiTableCell-root": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },
    },
    "&:last-of-type": {
        paddingRight: 16,
        // borderTopRightRadius: 8,
        // borderBottomRightRadius: 8,
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px",
    padding: 8,
    minWidth: 50,
    "&.sticky": {
        position: "sticky",
        left: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "8px 20px 20px #EEEEEE",
    },
    "& .avatar": {
        minWidth: 240,
        "& .MuiAvatar-root": {
            marginRight: 8,
        },
        display: "flex",
        alignItems: "center",
    },
    "& .fullname": {
        fontWeight: 600,
    },
    "& .position": {
        color: theme.palette.text.secondary,
        fontSize: 14,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: 300,
    },
    "& .secondaryAction": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .location": {
            fontWeight: 600,
        },
    },
    "&:first-of-type": {
        paddingLeft: 8,
    },
    "&:last-of-type": {
        paddingRight: 16,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const StyledRowContent = styled(TableRow)({
    cursor: "pointer",
    textDecoration: "none",
    "&.MuiTableRow-hover:hover": {
        backgroundColor: "#f7f7f7",
        "& .sticky": {
            backgroundColor: "#f7f7f7",
        },
    },
});

const LeaveAbsentLate = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { result: userProfile } = useSelector(state => state.userProfile);

    const [isFetching, setIsFetching] = useState(false);
    const [companyLists, setCompanyLists] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [ReportLeaveAbsentLateList, setReportLeaveAbsentLateList] = useState(null);

    const [filter, setFilter] = useState({
        month: new Date(dayjs()),
        start: new Date(dayjs().set('date', 1)),
        end: new Date(dayjs().set('date', dayjs().daysInMonth())),
    });

    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        rowsPerPage: 25,
    });

    const fetchedData = async () => {
        try {
            setIsFetching(true);
            const response = await getEmployWorkingDays({
                idCompany: selectedCompany.idCompany,
                start: dayjs(filter.start).format("YYYY-MM-DD"),
                end: dayjs(filter.end).format("YYYY-MM-DD"),
                getAll: true,
            });
            if (response && response.data) {
                setIsFetching(false);
                setReportLeaveAbsentLateList(response.data);
                setPagination(prev => ({
                    ...prev,
                    page: 0,
                    count: response.data.employeeList ? response.data.employeeList.length : 0
                }))
            } else {
                setIsFetching(false);
            }
        } catch (error) {
            setIsFetching(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if ((selectedCompany && selectedCompany.idCompany) && (filter && filter.start && filter.end)) {
            fetchedData()
        }
    }, []);

    const onDownloadExcel = async () => {
        LeaveAbsentLateXlsxReport(ReportLeaveAbsentLateList && ReportLeaveAbsentLateList.leaveList, ReportLeaveAbsentLateList && ReportLeaveAbsentLateList.employeeList, selectedCompany && selectedCompany.companyName)
    }

    const fetchedCompanyByVendor = async () => {
        try {
            const response = await getAllCompaniesByVendor();
            if (response && response.data) {
                setCompanyLists(response.data);
            } else {
                setCompanyLists(null);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchedCompanyByVendor();
    }, []);

    useEffect(() => {
        if (companyLists && companyLists.length > 0) {
            setSelectedCompany(companyLists[0])
        }
    }, [companyLists]);

    const handleClickSearch = () => {
        if ((selectedCompany && selectedCompany.idCompany) && (filter && filter.start && filter.end)) {
            fetchedData()
        }
    }

    const handleChangePage = (_, newPage) => {
        setPagination(prev => ({
            ...prev,
            page: newPage
        }))
    };

    const handleChangeRowsPerPage = (event) => {
        setPagination(prev => ({
            ...prev,
            page: 0,
            rowsPerPage: Number(event.target.value),
        }))
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    return (
        <StyledRoot className="page">
            <Container
                maxWidth="lg"
                style={{ paddingTop: "24px", paddingBottom: "24px" }}
            >
                <Box marginBottom="24px">
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={"/report-center"}
                        >
                            {`${t("AllReports")}`}
                        </Link>
                        <Typography color="text.primary">{`${t("SummaryOfLeaveAndAbsences")}`}</Typography>
                    </Breadcrumbs>
                </Box>

                <Typography variant="h4">{t("SummaryOfLeaveAndAbsences")}</Typography>

                <Grid marginTop="24px" container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">{t("Company")}</Typography>
                        <SelectAllCompaniesVendor
                            options={companyLists ? companyLists : null}
                            value={selectedCompany}
                            disabled={isFetching || !companyLists}
                            onChange={(_, value) => {
                                onChangeCompany(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("StartDate")}</Typography>
                        <DatePickerCustom
                            inputFormat="DD MMMM YYYY"
                            value={filter.start}
                            openTo="month"
                            views={["year", "month", "day"]}
                            onChange={(newValue) => {
                                setFilter(prev => ({
                                    ...prev,
                                    start: newValue
                                }));
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("EndDate")}</Typography>
                        <DatePickerCustom
                            inputFormat="DD MMMM YYYY"
                            value={filter.end}
                            openTo="month"
                            views={["year", "month", "day"]}
                            onChange={(newValue) => {
                                setFilter(prev => ({
                                    ...prev,
                                    end: newValue
                                }));
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} container justifyContent="flex-end">
                        <Box className="search-button">
                            <ButtonBlue
                                startIcon={<SearchRoundedIcon />}
                                variant="contained"
                                fullWidth
                                onClick={handleClickSearch}
                                disabled={isFetching}
                            >
                                {t("Search")}
                            </ButtonBlue>
                        </Box>
                    </Grid>
                </Grid>

                <Box marginTop="24px">
                    <CardStyle>
                        <Box padding="24px">
                            {!isFetching ? (
                                <Box>
                                    <Box marginBottom="16px" display="flex" alignItems="center" justifyContent="flex-end">
                                        <ButtonBlue
                                            variant="outlined"
                                            startIcon={<DownloadRoundedIcon />}
                                            onClick={onDownloadExcel}
                                            disabled={!ReportLeaveAbsentLateList}
                                        >
                                            {t("Download")}
                                        </ButtonBlue>
                                    </Box>

                                    <Box style={{ display: "flex", position: "relative" }}>
                                        <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead
                                                    sx={{
                                                        position: "sticky",
                                                        top: 0,
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    <TableRow>
                                                        <StyledCellHeader
                                                            align={"center"}
                                                            className="sticky"
                                                            style={{ minWidth: 240 }}
                                                            rowSpan={2}
                                                        >
                                                            {`${t("FullName")}`}
                                                        </StyledCellHeader>

                                                        <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("WorkingDays")}`}</StyledCellHeader>
                                                        <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("Absent")}`}</StyledCellHeader>
                                                        <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("Late")}`}</StyledCellHeader>
                                                        <StyledCellHeader align="center" style={{ minWidth: 100 }}>{`${t("EarlyOut")}`}</StyledCellHeader>

                                                        {ReportLeaveAbsentLateList && ReportLeaveAbsentLateList.leaveList && ReportLeaveAbsentLateList.leaveList.map(leave => (
                                                            <StyledCellHeader key={leave.idLeaveGroup} align="center" style={{ minWidth: 140 }}>{leave.leaveGroupName}</StyledCellHeader>
                                                        ))}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {ReportLeaveAbsentLateList && ReportLeaveAbsentLateList.employeeList &&
                                                        ReportLeaveAbsentLateList.employeeList.slice((pagination.page * pagination.rowsPerPage), ((pagination.page + 1) * pagination.rowsPerPage)).map((item, index) => (
                                                            <StyledRowContent>
                                                                <StyledCellContent
                                                                    key={`${index}`}
                                                                    className="sticky"
                                                                    style={{
                                                                        borderBottom:
                                                                            "1px dashed rgba(224, 224, 224, 1)",
                                                                    }}
                                                                >
                                                                    <Box className="avatar">
                                                                        <Avatar src="" />
                                                                        <Typography className="fullname">
                                                                            {`${item.firstname}${item.lastname ? " " + item.lastname : ""}`}
                                                                        </Typography>
                                                                    </Box>
                                                                </StyledCellContent>

                                                                <StyledCellContent align="center">{item.arrive}</StyledCellContent>
                                                                <StyledCellContent align="center">{item.absent}</StyledCellContent>
                                                                <StyledCellContent align="center">{item.late}</StyledCellContent>
                                                                <StyledCellContent align="center">{item.earlyBack}</StyledCellContent>

                                                                {ReportLeaveAbsentLateList && ReportLeaveAbsentLateList.leaveList && ReportLeaveAbsentLateList.leaveList.map(leave => (
                                                                    <StyledCellContent key={leave.idLeaveGroup} align="center" style={{ minWidth: 140 }}>{item.leaveUsed[leave.idLeaveGroup] ? item.leaveUsed[leave.idLeaveGroup] : 0}</StyledCellContent>
                                                                ))}
                                                            </StyledRowContent>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>


                                    <TablePagination
                                        rowsPerPageOptions={[25]}
                                        component="div"
                                        count={pagination.count}
                                        rowsPerPage={25}
                                        page={pagination.page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        labelRowsPerPage={`${t("RowsPerPage")}`}
                                        labelDisplayedRows={({ from, to, count }) =>
                                            `${from}-${to} ${t("OutOf")} ${count !== -1 ? count : `มากกว่า ${to}`
                                            }`
                                        }
                                    />
                                </Box>) : (

                                <Typography textAlign="center" color="text.secondary" fontSize="24px">
                                    {t(`LoadingData`)}...
                                </Typography>

                            )}
                        </Box>
                    </CardStyle>
                </Box>
            </Container>
        </StyledRoot>
    )
}

export default LeaveAbsentLate;