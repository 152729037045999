import React from "react";
import { Grid, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";

import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { getCompanyProfile, putCompanyProfile } from "../../../../actions/company";

const StyledRoot = styled("div")({
  padding: 24,
})

const DrawerEditCompany = (props) => {

  const dispatch = useDispatch();
  const { open, handleClose, data } = props;


  const { control, handleSubmit } = useForm({
    defaultValues: {
      companySocialSecurityAccount: data.companySocialSecurityAccount || "",
      companyProvidentFundCode: data.companyProvidentFundCode || "",
      companyAuthorizedSignatoryName: data.companyAuthorizedSignatoryName || "",
      companyAuthorizedSignatoryPosition: data.companyAuthorizedSignatoryPosition || "",
      companyEmail: data.companyEmail || "",
    }
  })

  const onSubmit = async (data) => {
    const formData = {...data, companyCode: data.companySocialSecurityAccount}
    const response = await dispatch(putCompanyProfile(1, formData));
    if (response.status === 200){
      dispatch(getCompanyProfile(1));
      handleClose();
    }
  }

  return (
    <DrawerCustom
      title="Company"
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{style: {maxWidth: 550, width: "100%"}}}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="companySocialSecurityAccount"
                render={({field}) => <TextFieldTheme {...field} label="Social Security A/C"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="companyProvidentFundCode"
                render={({field}) => <TextFieldTheme {...field} label="Provident Fund Code"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="companyAuthorizedSignatoryName"
                render={({field}) => <TextFieldTheme {...field} label="Authorized Signatory Name"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="companyAuthorizedSignatoryPosition"
                render={({field}) => <TextFieldTheme {...field} label="Authorized Signatory Position"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="companyEmail"
                render={({field}) => <TextFieldTheme {...field} label="Email Address"/>}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="flex-end">
              <ButtonBlue style={{marginRight: 8}} onClick={handleClose}>
                ยกเลิก
              </ButtonBlue>
              <ButtonBlue variant="contained" type="submit">
                บันทึกข้อมูล
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEditCompany;