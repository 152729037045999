import * as React from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import StyledCard from "../../../shared/general/Card";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";

import AvatarShift from "../../../shared/general/AvatarShift";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import EngineeringIcon from "@mui/icons-material/Engineering";
import SearchIcon from "@mui/icons-material/Search";
import LoopIcon from "@mui/icons-material/Loop";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const StyledTabs = styled(Tabs)(({}) => ({
  minWidth: 180,
  maxWidth: 180,
  border: "1px solid",
  borderColor: "#0000001f",
  backgroundColor: "#f3f6f8",
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  "& .Mui-selected": {
    color: "#007afe !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#007afe !important",
  },
  "& .MuiTab-root": {
    padding: 8,
  },
}));

const StyledTabPanel = styled(TabPanel)(({}) => ({
  border: "1px solid",
  borderLeft: "none",
  borderColor: "#0000001f",
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  padding: 8,
  minWidth: "calc(100% - 116px)",
  maxWidth: "calc(100% - 116px)",
}));

const StyledAvatar = styled(Avatar)({
  width: 50,
  height: 50,
});

const StyledBox = styled(Box)({
  width: 120,
  padding: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledFormControlSelect = styled(FormControl)(({}) => ({
  width: 200,
  marginTop: 16,
  marginBottom: 16,
  marginRight: 8,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

const StyledFormControlSearch = styled(FormControl)(({}) => ({
  width: 200,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
}));

const StyledSection = styled("div")(({ shift }) => ({
  flexGrow: 1,
  display: "flex",
  padding: 16,
  paddingTop: 8,
  height: 350,
  width: "calc(100% - 32px)",
}));

const StyledWrapHead = styled("div")(({ shift }) => ({
  padding: 16,
  paddingBottom: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiTypography-h5": {
    marginLeft: 8,
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

export default function EmployeeList(props) {
  const { modeSelected } = props;
  const { result: AllShiftGroup } = useSelector((state) => state.shift);
  const { result: EmployeesShift } = useSelector(
    (state) => state.employeeShift
  );
  const [step, setStep] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [dataShift, setDataShift] = React.useState(null);
  const [ListEmployeeShift, setListEmployeeShift] = React.useState([]);

  const handleChangeStep = (event, newValue) => {
    setStep(newValue);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleOnClickSearch = (prop) => (event) => {
    //setSearch(event.target.value );
  };

  const handleChangeDepartment = (event, newValue) => {
    setDepartment(newValue);
  };

  const displayTimeType = (shiftType, typeTH) => {
    let FilterType = shiftType.find((shift) =>
      shift.nameShiftType.toLowerCase().includes(typeTH)
    );
    if (FilterType) {
      let splitStartTime = FilterType.timeIn.split(":");
      let splitEndTime = FilterType.timeOut.split(":");
      let startTime = dayjs()
        .hour(splitStartTime[0])
        .minute(splitStartTime[1])
        .format("HH : mm");
      let endTime = dayjs()
        .hour(splitEndTime[0])
        .minute(splitEndTime[1])
        .format("HH : mm");
      return `${typeTH} ${startTime} - ${endTime}`;
    } else {
      return "";
    }
  };

  const displayAllTime = () => {
    let shiftType = dataShift.shiftType;
    let resultText = "";
    shiftType
      .filter((shift) => !shift.nameShiftType.includes("หยุด"))
      .map((shift) => {
        let textDuration = displayTimeType(
          shiftType,
          shift.nameShiftType.split("/")[0]
        );
        resultText += `${textDuration} , `;
      });
    return resultText.slice(0, resultText.length - 3);
  };

  const findShiftName = (idShift) => {
    let targetShift = dataShift.shiftTable.find(
      (shift) => shift.idShift === idShift
    );
    if (targetShift) {
      if (targetShift.nameShift === "DAY") {
        return "A";
      } else {
        return targetShift.nameShift;
      }
    } else {
      return "-";
    }
  };

  return (
    <StyledCard>
      <div>
        <StyledWrapHead>
          <div>
            <Typography
              variant="h4"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {`Vendor ${modeSelected > 0 ? modeSelected : "name"}`}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}></div>
        </StyledWrapHead>
        <StyledSection>
          <StyledTabs
            orientation="vertical"
            variant="scrollable"
            value={step}
            onChange={handleChangeStep}
            aria-label="Vertical tabs example"
          >
            <Tab
              label={
                <div>
                  <Typography
                    style={{
                      color: "inherit",
                      fontSize: "0.875rem",
                      fontWeight: 500,
                    }}
                  >
                    {`รวมทั้งหมด`}
                  </Typography>
                  <Typography
                    style={{ color: "inherit", fontSize: "0.875rem" }}
                  >
                    {`(${Math.floor(Math.random() * 101) + 300} คน)`}
                  </Typography>
                </div>
              }
              {...a11yProps(0)}
            />
            {[...Array(20).keys()].map((shift, index) => {
              return (
                <Tab
                  key={`${index}-${shift}`}
                  label={
                    <div>
                      <Typography
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                        }}
                      >
                        {`ตำแหน่งงาน ${shift + 1}`}
                      </Typography>
                      <Typography
                        style={{ color: "inherit", fontSize: "14px" }}
                      >
                        {`(${Math.floor(Math.random() * 101)} คน)`}
                      </Typography>
                    </div>
                  }
                  {...a11yProps(index + 1)}
                />
              );
            })}
          </StyledTabs>
          {[...Array(20).keys()].map((position, index) => (
            <StyledTabPanel
              key={position}
              value={index}
              index={index}
              style={{ overflow: "auto" }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "auto",
                }}
              >
                {[...Array(Math.floor(Math.random() * 30)).keys()].map(
                  (index, indexEmployee) => {
                    return (
                      <StyledBox key={`${indexEmployee}`}>
                        <StyledAvatar />

                        <div
                          style={{
                            width: "100%",
                            marginTop: 4
                          }}
                        >
                          <Typography align="center" variant="body1">
                            {"Firstname"}
                          </Typography>
                          <Typography align="center" variant="body1">
                            {"Lastname"}
                          </Typography>
                        </div>
                      </StyledBox>
                    );
                  }
                )}
              </div>
            </StyledTabPanel>
          ))}
        </StyledSection>
      </div>
    </StyledCard>
  );
}
