import React from "react";
import { useSelector } from "react-redux";
import { Controller } from 'react-hook-form';
import { Grid, MenuItem, Typography } from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import FormContainer from "./FormContainer";
import { useTranslation } from "react-i18next";

const ManagerInfo = (props) => {
  const { companySelect, control, errors } = props;
  const { result: empDataForm } = useSelector((state) => state.empDataForm);
  const { t } = useTranslation();

  return (
    <FormContainer title={`${t("Manager")}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{`${t("ManagerLevel")} 1`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="idManagerLV1"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.idManagerLV1 ? true : false}
                helperText={errors && errors.idManagerLV1 && errors.idManagerLV1.message}
                fullWidth
                select
              >
                {empDataForm && empDataForm.manager && 
                empDataForm.manager.map((item) => (
                  <MenuItem
                    key={item.idManagerEmployee}
                    value={item.idManagerEmployee}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{`${t("ManagerLevel")} 2`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="idManagerLV2"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.idManagerLV2 ? true : false}
                helperText={errors && errors.idManagerLV2 && errors.idManagerLV2.message}
                fullWidth
                select
              >
                {empDataForm && empDataForm.manager && 
                empDataForm.manager.map((item) => (
                  <MenuItem
                    key={item.idManagerEmployee}
                    value={item.idManagerEmployee}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default ManagerInfo;
