import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

import TextFieldTheme from "../../shared/general/TextFieldTheme";
import {
  getAllCompanyByVendor,
  getAllUsersVendor,
  getCompanyContract,
} from "../../../../actions/vendor";
import { getAllCompanysByIdVendor } from "../../../../actions/company";
import { useTranslation } from "react-i18next";

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 500,
  marginButtom: 24,
  "&.MuiTypography-root": {
    marginButtom: 3,
  },
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: "none !important",
  "& .MuiOutlinedInput-root": {
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-root": {
      paddingTop: 0,
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      border: "none !important",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
  "& .MuiFilledInput-root": {
    paddingTop: 0,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const filterOptionsName = createFilterOptions({
  stringify: (option) => `${option.companyName}`,
});

const SelectCompany = (props) => {
  const { t, i18n } = useTranslation();
  let { setState } = props;
  const dispatch = useDispatch();
  const { result: users } = useSelector((state) => state.users);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: vendorContract } = useSelector(
    (state) => state.vendorContract
  );
  const [company, setCompany] = useState(undefined);

  useEffect(() => {
    if (userProfile) {
      dispatch(getAllCompanyByVendor());
    }
  }, [userProfile]);

  return (
    <StyledBoxSearch>
      <Typography className="label" color="text.third">
        {t("Select_Company")}
      </Typography>
      <StyledAutocomplete
        value={company}
        options={vendorContract ? vendorContract : []}
        onChange={(event, newValue) => {
          if (newValue) {
            setState(newValue.idCompany);
          } else {
            setState(null);
          }
        }}
        filterOptions={filterOptionsName}
        getOptionLabel={(option) =>
          option === undefined
            ? `${t("Select_Company")}`
            : `${option.companyName} `
        }
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.idCompany} fullWidth>
            {option.companyName}
          </Box>
        )}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="filled"
            placeholder={t("Select_Company")}
            InputProps={{ ...params.InputProps }}
          />
        )}
        noOptionsText={t("No_Data")}
      />
    </StyledBoxSearch>
  );
};

export default SelectCompany;
