import { httpClient } from "./httpClient";

const getAllPositions = (query) => {
  return httpClient.get(`positions`, { params: query });
};

const postPositions = (data) => {
  return httpClient.post(`positions`, data);
};

const putPositions = (id, data) => {
  return httpClient.post(`positions/putPositions/${id}`, data);
};

const addNewPosition = (idCompany, data) => {
  return httpClient.post(`company/${idCompany}/positions/addNewPosition`,data);
};

const updatePosition = (idCompany, idPositions, data) => {
  return httpClient.put(`company/${idCompany}/positions/${idPositions}/update`,data);
};

const getAdditionAndTraining = (idPositions) => {
  return httpClient.get(`positions/${idPositions}/additionAndTraining`);
};

const addPosition = (formData) => {
  return httpClient.post(`positions`, formData); 
}

const deletePosition = (idPositions) => {
  return httpClient.delete(`positions/${idPositions}`);
};

export default {
  getAllPositions,
  postPositions,
  putPositions,
  addNewPosition,
  // deletePosition,
  updatePosition,
  getAdditionAndTraining,
  addPosition,
  deletePosition
};
