import React, { useEffect } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import { Divider, Grid, styled, Typography } from "@mui/material";

import AdapterDateFns from "@tarzui/date-fns-be";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import {
  getAllPFScheduleByVendor,
  updatePFSchedule,
} from "../../../../../actions/vendor";

const StyledRoot = styled("div")({
  width: 400,
  padding: 16,
});

const DrawerTablePF = (props) => {
  const { open, onClose, data } = props;
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      listChanging: [
        {
          dateStart: dayjs(),
          dateEnd: dayjs().add(1),
        },
        {
          dateStart: dayjs(),
          dateEnd: dayjs().add(1),
        },
        {
          dateStart: dayjs(),
          dateEnd: dayjs().add(1),
        },
        {
          dateStart: dayjs(),
          dateEnd: dayjs().add(1),
        },
      ],
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "listChanging",
  });

  useEffect(() => {
    if (data) {
      setValue("listChanging", data);
    }
  }, [data]);

  const handleOnSubmit = async (values) => {
    const result = await dispatch(updatePFSchedule(values.listChanging));
    if (result) {
      dispatch(getAllPFScheduleByVendor());
      onClose();
    }
  };

  return (
    <DrawerCustom
      open={open}
      onClose={onClose}
      title="แก้ไขตารางเวลาเปลี่ยนแปลง"
      anchor="right"
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Grid container spacing={2}>
            {fields.map((item, index) => (
              <React.Fragment key={item.id}>
                <Grid item xs={12}>
                  <Typography>{`รอบที่ ${index + 1}`}</Typography>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`listChanging.${index}.dateStart`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "กรุณากรอกวันที่เริ่มต้น",
                        },
                        validate: {
                          dateValidation: (value) =>
                            !isNaN(dayjs(value)) || "กรุณากรอกวันที่ให้ถูกต้อง",
                        },
                      }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="วันที่เริ่มต้น"
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              helperText={
                                errors &&
                                errors.listChanging &&
                                errors.listChanging[index] &&
                                errors.listChanging[index].dateStart &&
                                errors.listChanging[index].dateStart.message
                              }
                              error={
                                errors &&
                                errors.listChanging &&
                                errors.listChanging[index] &&
                                errors.listChanging[index].dateStart
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={`listChanging.${index}.dateEnd`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "กรุณากรอกวันที่สิ้นสุด",
                        },
                        validate: {
                          dateValidation: (value) => {
                            console.log("value : ", value);
                            return (
                              !isNaN(dayjs(value)) ||
                              "กรุณากรอกวันที่ให้ถูกต้อง"
                            );
                          },
                          disablePast: (value) =>
                            dayjs(value).isAfter(
                              dayjs(
                                getValues(`listChanging.${index}.dateStart`)
                              )
                            ) || "ไม่สามารถเลือกวันก่อนเริ่มต้นได้",
                        },
                      }}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="วันที่สิ้นสุด"
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              helperText={
                                errors &&
                                errors.listChanging &&
                                errors.listChanging[index] &&
                                errors.listChanging[index].dateEnd &&
                                errors.listChanging[index].dateEnd.message
                              }
                              error={
                                errors &&
                                errors.listChanging &&
                                errors.listChanging[index] &&
                                errors.listChanging[index].dateEnd
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </LocalizationProvider>
                <Grid item xs={12}>
                  <Divider
                    style={{ borderBottom: "2px solid rgba(0,0,0,0.09)" }}
                  />
                </Grid>
              </React.Fragment>
            ))}
            <Grid container item xs={12} justifyContent="space-between">
              <Grid item>
                <ButtonBlue variant="outlined" onClick={onClose}>
                  ยกเลิก
                </ButtonBlue>
              </Grid>
              <Grid item>
                <ButtonBlue type="submit" variant="contained">
                  ยืนยัน
                </ButtonBlue>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerTablePF;
