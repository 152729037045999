import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { Divider, Grid, Typography } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledTextFieldTheme = styled(TextFieldTheme)(({}) => ({
  marginBottom: 16,
}));

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ProfileTab = () => {
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [value, setValue] = useState(null);
  const [stateEditField, setStateEditField] = useState({
    phone: false,
    emergency: false,
  });

  return (
    <Grid container spacing={2}>
      {employeeProfile && (
        <Fragment>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              รหัสพนักงาน
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employeeId ? employeeProfile.employeeId : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              เลขที่บัตรประชาชน
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.personalId.replace(
                /(\d)(\d\d\d\d)(\d\d\d\d\d)(\d\d)(\d)/,
                "$1 $2 $3 $4 $5"
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">วันเกิด</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.birthday &&
                dayjs(employeeProfile.birthday).format("DD MMMM YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledDivider />
            <StyledWrapHead>
              <StyledHeadLabel style={{ marginBottom: 4 }}>
                การติดต่อ
              </StyledHeadLabel>
              <ButtonBlue size="small" startIcon={<EditIcon />}>
                แก้ไข
              </ButtonBlue>
            </StyledWrapHead>
          </Grid>
          <Grid item xs={12}>
            {stateEditField.phone ? (
              <StyledTextFieldTheme
                label="เบอร์โทรศัพท์"
                name="phone"
                fullWidth
                value={employeeProfile.phoneSecond}
              />
            ) : (
              <div>
                <StyledHeadLabel color="text.secondary">
                  เบอร์โทรศัพท์
                </StyledHeadLabel>
                <Typography variant="h6">
                  {employeeProfile.telephoneMobile
                    ? employeeProfile.telephoneMobile.replace(
                        /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                        "$1-$2-$3"
                      )
                    : "-"}
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            {stateEditField.email ? (
              <StyledTextFieldTheme
                label="อีเมล์"
                name="phone"
                fullWidth
                value={employeeProfile.email}
              />
            ) : (
              <div>
                <StyledHeadLabel color="text.secondary">อีเมล์</StyledHeadLabel>
                <Typography variant="h6">
                  {employeeProfile.email ? employeeProfile.email : "-"}
                </Typography>
              </div>
            )}
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default ProfileTab;
