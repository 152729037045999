import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import DashboardIcon from "@mui/icons-material/Dashboard";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

const ManagerMenu = () => {
  const dataListMenu = [
    {
      listItemIcon: <BubbleChartIcon />,
      listItemText: "มุมมองภาพรวม",
      listLink: "/manager/overview",
      listKey: "overview",
    },
    {
      listItemIcon: <DoneAllIcon />,
      listItemText: "รออนุมัติและประวัติ",
      listLink: "/manager/approve",
      listKey: "approvelist",
    },
    {
      listItemIcon: <DashboardIcon />,
      listItemText: "พนักงาน",
      listLink: "",
      listKey: "employee",
      collapse: [
        {
          listItemIcon: <Filter1Icon />,
          listItemText: "รายชื่อพนักงาน",
          listLink: "/manager/employee",
          listKey: "manager-employee",
        },
        {
          listItemIcon: <Filter2Icon />,
          listItemText: "สลับกะพนักงาน",
          listLink: "/manager/switch-shift",
          listKey: "manager-switch-shift",
        },
      ],
    },
    {
      listItemIcon: <AccessAlarmIcon  />,
      listItemText: "เวลาทำงาน",
      listLink: "/manager/time",
      listKey: "time",
    },
    // {
    //   listItemIcon: <DashboardIcon />,
    //   listItemText: "Dashboard",
    //   listLink: "",
    //   listKey: "dashboard",
    //   collapse: [
    //     {
    //       listItemIcon: <Filter1Icon />,
    //       listItemText: "ข้อมูลค่าล่วงเวลา",
    //       listLink: "/manager/dashboard/ot",
    //       listKey: "overtime",
    //     },
    //     {
    //       listItemIcon: <Filter2Icon />,
    //       listItemText: "ภาพรวมเวลาทำงาน",
    //       listLink: "/manager/dashboard/workingtime",
    //       listKey: "workingtime",
    //     },
    //     {
    //       listItemIcon: <Filter2Icon />,
    //       listItemText: "ภาพรวมค่าใช้จ่าย",
    //       listLink: "/manager/dashboard/cost",
    //       listKey: "cost",
    //     },
    //   ],
    // },
    // {
    //   listItemIcon: <TrendingUpIcon />,
    //   listItemText: "Attendance",
    //   listLink: "/manager/attendance",
    //   listKey:"attendance",
    // },
    // {
    //   listItemIcon: <TrendingUpIcon />,
    //   listItemText: "OT Monitoring",
    //   listLink: "/manager/otmonitoring",
    //   listKey:"otmonitoring",
    // },
    // {
    //   listItemIcon: <AttachMoneyIcon />,
    //   listItemText: "เงินเดือน",
    //   listLink: "",
    //   listKey: "payroll",
    //   collapse: [
    //     {
    //       listItemIcon: <Filter1Icon />,
    //       listItemText: "จัดการเงินเดือน",
    //       listLink: "/admin/payroll/run",
    //       listKey: "payrollrun",
    //     },
    //     {
    //       listItemIcon: <Filter2Icon />,
    //       listItemText: "Payslip",
    //       listLink: "/admin/payroll/payslip",
    //       listKey: "payslip",
    //     },
    //   ],
    // },
    // {
    //   listItemIcon: <BrightnessMediumIcon />,
    //   listItemText: "การจัดการกะ",
    //   listLink: "/admin/shift",
    //   listKey:"shift",
    // },
  ];

  return <ListMenu dataListMenu={dataListMenu} menuRole={"Manager"} />;
};

export default ManagerMenu;
