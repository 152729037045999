import React, { forwardRef, useRef, useImperativeHandle } from "react";
import {
  Box,
  Grid,
  Chip,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import TimePicker from "../../../shared/general/TimePicker";

export default function StepTwo(props) {
  const { handleChangeChecked, dataNewShift, handleChangeTime } = props;

  return (
    <React.Fragment>
      {dataNewShift.periodTime.map((value, index) => {
        return (
          <div style={{ marginTop: index === 0 ? 0 : 24 }} key={value.periodTime}>
            <Divider>
              <Chip
                style={{
                  backgroundColor: "#007afe",
                  fontWeight: 500,
                  fontSize: 16,
                }}
                label={value.periodTime}
              />
            </Divider>
            <FormControlLabel
              control={
                <Checkbox
                  name="check"
                  onChange={handleChangeChecked(value.periodTime)}
                  checked={value.check}
                  style={{ color: "#007afe" }}
                />
              }
              label="ช่วงเวลา"
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", marginRight: 4 }}>
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name="timeStartHours"
                      unit="hour"
                      periodTime={value.periodTime}
                      value={value.timeStartHours}
                    />
                  </div>
                  <div style={{ width: "100%", marginLeft: 4 }}>
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name="timeStartMinute"
                      unit="minute"
                      periodTime={value.periodTime}
                      value={value.timeStartMinute}
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>ถึง</Typography>
              </Grid>
              <Grid item xs={12} sm={5}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", marginRight: 4 }}>
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name="timeEndHours"
                      unit="hour"
                      periodTime={value.periodTime}
                      value={value.timeEndHours}
                    />
                  </div>
                  <div style={{ width: "100%", marginLeft: 4 }}>
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name="timeEndMinute"
                      unit="minute"
                      periodTime={value.periodTime}
                      value={value.timeEndMinute}
                    />
                  </div>
                </div>
              </Grid>
              <Grid container item xs={12} sm={5}>
                <Grid item xs={6} style={{ display:"flex", alignItems:"center" }}>
                  <Typography style={{ width:"120px" }}>เวลาที่มาสาย</Typography>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ width:"100%" }}>
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name="lateIn"
                      unit="minute"
                      periodTime={value.periodTime}
                      value={value.lateIn}
                      step={5}
                      minStep={0}
                      maxStep={30}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={0} sm={2}></Grid>
              <Grid container item xs={12} sm={5}>
                <Grid item xs={6}  style={{ display:"flex", alignItems:"center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkBreakTime"
                        onChange={handleChangeChecked(value.periodTime)}
                        checked={value.checkBreakTime}
                        style={{ color: "#007afe" }}
                      />
                    }
                    label="มีช่วงเวลาพักเที่ยง"
                  />
                </Grid>
                <Grid item xs={6}>
                  <div style={{ width:"100%" }}>
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name="breakTime"
                      unit="minuteBreak"
                      periodTime={value.periodTime}
                      value={value.breakTime}
                      step={5}
                      minStep={0}
                      maxStep={90}
                      disabled={!value.checkBreakTime}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      })}
    </React.Fragment>
  );
}
