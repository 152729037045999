import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  deleteEmployeeAddition,
  deleteEmployeeAdditionDeduction,
  deleteEmployeeDeduction,
  getEmployeeAdditionDeduction,
} from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

const DialogDelete = (props) => {
  const {
    open,
    handleClose,
    mode,
    dataEdit,
    handleOpenAlert,
    handleSetAlertType,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const handleDelete = async () => {
    // console.log({
    //   ...dataEdit,
    //   updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss")
    // })
    // const result = await dispatch(
    //   deleteEmployeeAddition(employeeProfile.idEmp, )
    //   deleteEmployeeDeduction
    // );

    let result;

    if (dataEdit.idEmployeeAddition) {
      result = await dispatch(
        deleteEmployeeAddition(
          employeeProfile.idEmp,
          dataEdit.idEmployeeAddition
        )
      );
    } else if (dataEdit.idEmployeeDeduction) {
      result = await dispatch(
        deleteEmployeeDeduction(
          employeeProfile.idEmp,
          dataEdit.idEmployeeDeduction
        )
      );
    }

    if (result && result.status === 200) {
      dispatch(getEmployeeAdditionDeduction(employeeProfile.idEmp));
      handleSetAlertType("success");
      handleOpenAlert();
      handleClose();
    } else {
      handleSetAlertType("error");
      handleOpenAlert();
      handleClose();
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose} maxWidth={"xs"}>
      <DialogTitle id="alert-dialog-title">{`${t("Delete")} ${
        mode === "addition" ? "Addition" : "Deduction"
      }`}</DialogTitle>
      <DialogContent>
        {dataEdit && <Typography>{dataEdit.name}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "#212B36" }}>
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: "#e46a76" }}
          onClick={handleDelete}
        >
          {t("Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDelete;
