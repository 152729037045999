import {
  COMPANY_FETCHING,
  COMPANY_FAILED,
  COMPANY_SUCCESS,
  VENDORS_FETCHING,
  VENDORS_FAILED,
  VENDORS_SUCCESS,
} from "./types";

import CustomerService from "../../services/ant-team/customer.service";

export const getAllCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_FETCHING,
    });
    const res = await CustomerService.getAllCompany();
    if (res) {
      dispatch({
        type: COMPANY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: COMPANY_FAILED,
    });
  }
};

export const getAllVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: VENDORS_FETCHING,
    });
    const res = await CustomerService.getAllVendor();
    if (res) {
      dispatch({
        type: VENDORS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: VENDORS_FAILED,
    });
  }
};

export const addNewCompanies = (formData) => async () => {
  try {
    const res = await CustomerService.addNewCompanies(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const addNewVendor = (formData) => async () => {
  try {
    const res = await CustomerService.addNewVendor(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};
