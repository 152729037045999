import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import { Circle } from "@mui/icons-material";

import ButtonEdit from "./shared/ButtonEdit";
import DrawerExpertise from "./drawerExpertise";

const StyledRoot = styled("div")({});

const Expertise = () => {
  const { result: AllExpertisesVendor } = useSelector(
    (state) => state.expertisesVendor
  );
  const [isOpenDrawerExpertise, setIsOpenDrawerExpertise] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="headerText">{t("Expertise")}</Typography>
          <List>
            {AllExpertisesVendor && AllExpertisesVendor.length > 0 ? (
              AllExpertisesVendor.map((expertise) => (
                <ListItem disablePadding key={expertise.idExpertise}>
                  <ListItemIcon style={{ justifyContent: "center" }}>
                    <Circle style={{ fontSize: "10px" }} />
                  </ListItemIcon>
                  <ListItemText primary={expertise.expertiseName} />
                </ListItem>
              ))
            ) : (
              <ListItem disablePadding>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={t("No_Data")} />
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonEdit
            onClick={() => {
              setIsOpenDrawerExpertise(true);
            }}
          />
        </Grid>
      </Grid>

      {isOpenDrawerExpertise && (
        <DrawerExpertise
          open={isOpenDrawerExpertise}
          handleClose={() => {
            setIsOpenDrawerExpertise(false);
          }}
        />
      )}
    </StyledRoot>
  );
};

export default Expertise;
