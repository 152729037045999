import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";

import { Divider, Grid, Typography } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import utils from "../../../../../../utils";

import { getEmployeeSalaryChange } from "../../../../../../actions/employee";

import DialogEdit from "./DialogEdit";

const StyledRoot = styled("div")({
  "& .salary": {
    "& .MuiTypography-h6": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "baseline",
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTextValue = styled(Typography)({
  fontSize: 18,
  "& i": {
    padding: 2,
  },
});

const StyledWrapChange = styled("div")({
  marginTop: 8,
  "& .inner": {
    marginBottom: 8,
  },
  "& .first-line": {
    display: "flex",
    "& .date": {
      margin: "0 8px",
      fontWeight: 600,
    },
    "& .positions-name": {
      fontWeight: 600,
    },
  },
  "& .second-line": {
    marginLeft: 16,
  },
});

function PaymentChange(props) {
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employeeSalary } = useSelector(
    (state) => state.employeeSalary
  );
  const { t, i18n } = useTranslation();

  const [openEdit, setOpenEdit] = useState(false);
  const [showPersonalId, setShowPersonalId] = useState(false);

  useEffect(() => {
    dispatch(getEmployeeSalaryChange(employeeProfile.idEmp));
  }, []);

  const toggleDrawer = (open) => (event) => {
    console.log(open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenEdit(open);
  };

  const handleCloseDialog = () => {
    setOpenEdit(false);
  };

  return (
    <StyledRoot>
      <StyledWrapTop>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditIcon />}
            onClick={toggleDrawer(true)}
          >
            {t("Edit")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("PaymentTypeName")}
            </StyledHeadLabel>
            <div>
              <Typography variant="h6">
                {employeeProfile.paymentTypeName ? (
                  <Fragment>
                    {employeeProfile.paymentTypeName}
                    {(employeeProfile.paymentRound && localStorage.getItem("language") === "th") && (
                      <Typography color="text.secondary" component="span">
                        {` (${t("Pay")}${employeeProfile.paymentRound})`}
                      </Typography>
                    )}
                  </Fragment>
                ) : (
                  "-"
                )}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Salary")}
            </StyledHeadLabel>
            <div className="salary">
              <div
                className="wrap-show-hide-personalId"
                onMouseEnter={() => setShowPersonalId(true)}
                onMouseLeave={() => setShowPersonalId(false)}
              >
                {showPersonalId ? (
                  <StyledTextValue variant="h6" align="center">
                    {employeeProfile.salaryValue
                      ? utils.numberWithCommas(employeeProfile.salaryValue)
                      : "-"}
                  </StyledTextValue>
                ) : (
                  <StyledTextValue className="dot" variant="h6" align="center">
                    {[...Array(6).keys()].map((_, index) => (
                      // <i key={index} class="fa-sharp fa-solid fa-circle"></i>
                      <i key={index} class="fas fa-times"></i>
                      // <CloseRoundedIcon key={index} />
                    ))}
                  </StyledTextValue>
                )}
              </div>
              <Typography color="text.secondary">
                {employeeProfile.paymentTypeName
                  ? `/${
                      employeeProfile.idPaymentType === 1 ||
                      employeeProfile.idPaymentType === 5
                        ? t("Day")
                        : t("Month")
                    }`
                  : "-"}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("ChangeLog")}
        </StyledHeadLabel>
        {employeeSalary && (
          <StyledWrapChange>
            {employeeSalary.map((item, index) => (
              <div className="inner" key={index}>
                <div className="first-line">
                  {">"}
                  {item.end ? (
                    <Typography className="date" color="text.third">
                      {`[${dayjs(item.start)
                        .locale(localStorage.getItem("language") || "th")
                        .format(
                          localStorage.getItem("language") === "en"
                            ? "DD MMM YYYY"
                            : "DD MMM BBBB"
                        )} - ${dayjs(item.end)
                        .locale(localStorage.getItem("language") || "th")
                        .format(
                          localStorage.getItem("language") === "en"
                            ? "DD MMM YYYY"
                            : "DD MMM BBBB"
                        )}]`}
                    </Typography>
                  ) : (
                    <Typography className="date" color="text.third">
                      {`[${dayjs(item.start)
                        .locale(localStorage.getItem("language") || "th")
                        .format(
                          localStorage.getItem("language") === "en"
                            ? "DD MMM YYYY"
                            : "DD MMM BBBB"
                        )}]`}
                    </Typography>
                  )}
                  <Typography className="positions-name">
                    {`${utils.numberWithCommas(item.salaryValue)} ${t("Baht")}`}
                  </Typography>
                </div>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                >{`${t("Reason")}: ${
                  item.remark ? item.remark : "-"
                }`}</Typography>
              </div>
            ))}
          </StyledWrapChange>
        )}
      </div>
      <DialogEdit
        open={openEdit}
        toggleDrawer={toggleDrawer}
        handleCloseDialog={handleCloseDialog}
      />
    </StyledRoot>
  );
}

export default PaymentChange;
