import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  TextField,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Chip,
  Box,
  Popper,
} from "@mui/material";
import Popover from "@mui/material/Popover";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";

import ForwardRoundedIcon from "@mui/icons-material/ForwardRounded";

import AdapterDateFns from "@tarzui/date-fns-be";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { th } from "date-fns/locale";

import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QrCodeIcon from "@mui/icons-material/QrCode2";

import { getAttendanceById } from "../../../../actions/attendance";
import { getLeaveRequestOnce } from "../../../../actions/employee";

import ButtonBlue from "../../shared/general/ButtonBlue";
import Backdrop from "../../shared/general/Backdrop";

const StyledRoot = styled("div")({
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledChipTime = styled(Chip)({
  height: 28,
  width: 88,
  fontSize: 14,
  borderRadius: 8,
  marginBottom: 4,
  border: "none",
  "&.check-in": {
    color: "#229a16",
    backgroundColor: "#54d62c29",
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },
  "&.check-out": {
    color: "#b72136",
    backgroundColor: "#ff484229",
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
      transform: "rotate(180deg)",
    },
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTimeline = styled(Timeline)({
  [`& .${timelineOppositeContentClasses.root}`]: {
    flex: 0.2,
  },
});
const StyledTimelineContent = styled(TimelineContent)({
  paddingBottom: 32,
  "& .distance": {
    fontWeight: 600,
  },
});
const StyledTimelineOppositeContent = styled(TimelineOppositeContent)({
  "& .show-time": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
});

const TimelinePanel = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: attendance } = useSelector((state) => state.attendance);
  const [search, setSearch] = useState(new Date());
  useEffect(() => {
    var filter = {
      searchDate: dayjs(search).format("YYYY-MM-DD"),
    };
    dispatch(getAttendanceById(filter));
    dispatch(
      getLeaveRequestOnce(dayjs(search).format("YYYY-MM-DD"), "manager")
    );
  }, []);

  const handleSearch = () => {
    var filter = {
      searchDate: dayjs(search).format("YYYY-MM-DD"),
    };
    dispatch(getAttendanceById(filter));
    dispatch(
      getLeaveRequestOnce(dayjs(search).format("YYYY-MM-DD"), "manager")
    );
  };

  const onExportExcel = () => {
    const elementList = [];
    for (let index = 0; index < attendance.length; index++) {
      const element = {
        id: index + 1,
        title: attendance[index].title_TH,
        name: attendance[index].firstname_TH,
        lastname: attendance[index].lastname_TH,
        positionsName: attendance[index].positionName,
        attendanceDateTime: attendance[index].attendanceDateTimeText,
        gpsLocationsName: attendance[index].gpsLocationsName,
        statusCheck: attendance[index].isCheckIn
          ? "ลงเวลาเข้างาน"
          : "ลงเวลาออกงาน",
      };
      elementList.push(element);
    }
    const workSheet = XLSX.utils.json_to_sheet(elementList);

    workSheet["A1"].v = "#";
    workSheet["B1"].v = "คำนำหน้า";
    workSheet["C1"].v = "ชื่อ";
    workSheet["D1"].v = "สกุล";
    workSheet["E1"].v = "ตำแหน่งงาน";
    workSheet["F1"].v = "วันที่เวลา";
    workSheet["G1"].v = "สถานที่ลงเวลา";
    workSheet["H1"].v = "ประเภทลงเวลา";

    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, "รายงานลงเวลาทำงาน");
    XLSX.writeFile(workBook, "รายงานลงเวลาทำงาน.xlsx");
  };

  return (
    <StyledRoot>
      <StyledWrapFilter>
        <Grid container spacing={2} columns={16} alignItems="center">
          <Grid item xs={4} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("SearchDate")}
              </Typography>
              <div className="search-date">
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={search}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setSearch(newValue);
                    }}
                    renderInput={(params) => (
                      <StyledTextField variant="filled" fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </StyledBoxSearch>
          </Grid>

          <Grid item xs={2} sm={4}>
            <StyledBoxSearch>
              <div className="wrap-search-action">
                <div>
                  <ButtonBlue
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                  >
                    {t("Search")}
                  </ButtonBlue>
                </div>
                <div className="btn-export">
                  <ButtonBlue
                    variant="outlined"
                    startIcon={<FileDownloadIcon />}
                    onClick={onExportExcel}
                  >
                    {t("Download")}
                  </ButtonBlue>
                </div>
              </div>
            </StyledBoxSearch>
          </Grid>
        </Grid>
      </StyledWrapFilter>
      <Fragment>
        <StyledTimeline>
          <TimelineItem>
            <StyledTimelineOppositeContent>
              <div className="show-time">
                <StyledChipTime
                  icon={<ForwardRoundedIcon />}
                  className="check-in"
                  label="08:16"
                  variant="outlined"
                />
                <StyledChipTime
                  icon={<ForwardRoundedIcon />}
                  className="check-out"
                  label="08:45"
                  variant="outlined"
                />
              </div>
            </StyledTimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <StyledTimelineContent>
              <Typography variant="h6" component="span">
                SCG Office
              </Typography>
              <Typography color="text.secondary">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry Lorem Ipsum is simply dummy text of the printing and
                typesetting industry
              </Typography>
            </StyledTimelineContent>
          </TimelineItem>
          <TimelineItem>
            <StyledTimelineOppositeContent>
              <div className="show-time">
                <StyledChipTime
                  icon={<ForwardRoundedIcon />}
                  className="check-in"
                  label="09:36"
                  variant="outlined"
                />
                <StyledChipTime
                  icon={<ForwardRoundedIcon />}
                  className="check-out"
                  label="10:15"
                  variant="outlined"
                />
              </div>
            </StyledTimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <StyledTimelineContent>
              <Typography className="distance" color="text.third">
                26.8 km • 46 นาที
              </Typography>
              <Typography variant="h6" component="span">
                Office ABC
              </Typography>
              <Typography color="text.secondary">
                It is a long established fact that a reader will be distracted
              </Typography>
            </StyledTimelineContent>
          </TimelineItem>
          <TimelineItem>
            <StyledTimelineOppositeContent>
              <div className="show-time">
                <StyledChipTime
                  icon={<ForwardRoundedIcon />}
                  className="check-in"
                  label="10:42"
                  variant="outlined"
                />
              </div>
            </StyledTimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <StyledTimelineContent>
              <Typography className="distance" color="text.third">
                9.5 km • 22 นาที
              </Typography>
              <Typography variant="h6" component="span">
                โรงงาน
              </Typography>
              <Typography color="text.secondary">
                It is a long established fact that a reader will be distracted
              </Typography>
            </StyledTimelineContent>
          </TimelineItem>
        </StyledTimeline>
      </Fragment>
    </StyledRoot>
  );
};

export default TimelinePanel;
