import React, { useEffect } from "react";
import { Autocomplete, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, MenuItem, Typography, styled } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { employeeChangeTime } from "../../../../actions/employee";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

const StyledDialongContent = styled(DialogContent)({
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
})

const DialogChangeWorkingType = (props) => {

  const {dialogConfig, onClose, fetchingShiftSchedule} = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: affiliateShiftGroup } = useSelector(state => state.shift);

  const validateYupSchema = yup.object({
    
  });

  const useHookForm = useForm({
    defaultValues: {
      shiftGroup: null,
      shift: null,
      shiftType: null,
      isHoliday: false,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  useEffect(() => {
    if(affiliateShiftGroup && affiliateShiftGroup.length > 0 && dialogConfig.isOpen){

      const foundShiftGroup = affiliateShiftGroup.find(item => item.idShiftGroup === dialogConfig.data.idShiftGroup)
      let foundShift = null;
      let foundShiftType = null;

      if(foundShiftGroup){
        foundShift = foundShiftGroup.shift.find(item => item.idShift === dialogConfig.data.idShift)
      }

      if(foundShiftGroup){
        foundShiftType = foundShiftGroup.shiftType.find(item => item.idShiftType === dialogConfig.data.idShiftType)
      }

      useHookForm.setValue("shiftGroup", foundShiftGroup? foundShiftGroup: null, { shouldDirty: false })
      useHookForm.setValue("shift", foundShift? foundShift: null, { shouldDirty: false })
      useHookForm.setValue("shiftType", foundShiftType? foundShiftType: null, { shouldDirty: false })

    }
  }, [affiliateShiftGroup, dialogConfig.isOpen])


  const onSubmit = async (data) => {

    const formData = {
      workingDate: dialogConfig.data.date,
      idEmp:  dialogConfig.data.idEmp,
      idShiftGroup: data.shiftGroup.idShiftGroup,
      idShift: data.shift.idShift,
      idShiftType: data.shiftType.idShiftType,
    }

    const result = await dispatch(employeeChangeTime(formData));

    if (result && result.status === 200) {
      onClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      fetchingShiftSchedule();
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }
  }

  const isHolidayWatch = useHookForm.watch("isHoliday");
  const shiftGroup = useHookForm.watch("shiftGroup");

  return (
    <Dialog
      open={dialogConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: "500px",
          width: "100%",
        }
      }}
    >
      <DialogTitle>เปลี่ยนเวลาทำงาน</DialogTitle>
      <StyledDialongContent>
        <DialogContentText>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>{t("Date")}: {dayjs(dialogConfig.data.date).locale(i18n.language).format(`DD MMM ${i18n.language === "th"? "BBBB": "YYYY"}`)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="shiftGroup"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={affiliateShiftGroup? affiliateShiftGroup: []}
                      getOptionLabel={(option) => `${option.nameShiftGroup}`}
                      isOptionEqualToValue={(option, value) => option.idShiftGroup === value.idShiftGroup}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idShiftGroup}>
                          <Box>
                            <Typography>{option.nameShiftGroup}</Typography>
                            <Typography color="text.secondary" fontSize="12px">{option.companyName}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          label="ประเภทกะ"
                          placeholder="เลือกข้อมูล"
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                        useHookForm.setValue("shift", (value && value.shift && value.shift.length === 1)? value.shift[0]: null)
                        useHookForm.setValue("shiftType", null)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="shift"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={shiftGroup? shiftGroup.shift: []}
                      getOptionLabel={(option) => `${option.nameShift}`}
                      isOptionEqualToValue={(option, value) => option.idShift === value.idShift}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idShift}>
                          <Box>
                            <Typography>{option.nameShift}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          label="กลุ่มกะ"
                          placeholde="เลือกข้อมูล"
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="shiftType"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={shiftGroup? shiftGroup.shiftType: []}
                      getOptionLabel={(option) => `${option.nameShiftType}`}
                      isOptionEqualToValue={(option, value) => option.idShiftType === value.idShiftType}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idShiftType}>
                          <Box>
                            <Typography>{option.nameShiftType}</Typography>
                            <Typography color="text.secondary" fontSize="12px">{dayjs(option.timeIn, "HH:mm:ss").format("HH:mm")} - {dayjs(option.timeOut, "HH:mm:ss").format("HH:mm")}
                            </Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          label="ประเภทเวลาทำงาน"
                          placeholder="เลือกข้อมูล"
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <Box paddingBottom="4px" display="flex" alignItems="center" gap="8px">
                  <Controller
                    name={"isHoliday"}
                    control={useHookForm.control}
                    render={({field}) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                            }}
                          />
                        }
                        label="วันหยุดนขัตฤกษ์"
                        labelPlacement="end"
                      />
                    )}
                  />
                </Box>
              </Grid> */}

              {/* {isHolidayWatch && <Grid item xs={12}>
                <Controller
                  name="holiday"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={[]}
                      getOptionLabel={(option) => `${option.nameShiftType}`}
                      isOptionEqualToValue={(option, value) => option.idShiftType === value.idShiftType}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idShiftType}>
                          <Box>
                            <Typography>{option.nameShiftType}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          label="วันหยุดนขัตฤกษ์"
                          placeholder="เลือกข้อมูล"
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
              </Grid>} */}
              
            </Grid>

          </form>
        </DialogContentText>
      </StyledDialongContent>
      <DialogActions>
        <ButtonBlue onClick={onClose}>ยกเลิก</ButtonBlue>
        <ButtonBlue variant="contained" onClick={useHookForm.handleSubmit(onSubmit)}>ยืนยัน</ButtonBlue>
      </DialogActions>
    </Dialog>
  )
}

export default DialogChangeWorkingType;