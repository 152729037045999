import React, { useEffect, useState, Fragment } from "react";
import {
  useSelector 
} from "react-redux";
import { useTranslation } from "react-i18next";

import TableRequest from "./table";

const Leave = (props) => {
  const { 
    searchName 
  } = props;
  const { t } = useTranslation();

  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    if (leaveEmployeeList) {
      setDataTable(leaveEmployeeList.filter((x) => {
        if(
          (x.isApprove !== null || x.isActive === 0) && 
          (
            (searchName && searchName.length > 0) ? 
            (
              x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
              x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
            )
            : true
          )
        ){
          return true;
        } else {
          return false;
        }
      }));
    }
  }, [leaveEmployeeList, searchName]);

  return (
    <Fragment>
      {leaveEmployeeList && dataTable && (
        <TableRequest data={dataTable} searchName={searchName} />
      )}
    </Fragment>
  );
};

export default Leave;
