import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "./components/DataTable";

import { getAllLeaveApproves } from "../../../../../../actions/leaveApproves";
import { getAllEmployees } from "../../../../../../actions/employee";

import { Box, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

function Index() {
  const classes = useStyles();
  useTitle("จัดการวันลา - HR CORE DATA");
  const dispatch = useDispatch();
  const { result: leaveApprovesStore } = useSelector(
    (state) => state.leaveApproves
  );
  const { result: empStore } = useSelector((state) => state.employees);

  const [leaveApproves, setLeaveApproves] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (leaveApprovesStore !== null && empStore !== null) {
      let leaveApprovesList = [...leaveApprovesStore];
      let empList = [...empStore];

      leaveApprovesList.forEach(function (element) {
        var empData = empList.filter(function (item) {
          return item.empId === element.empId;
        });

        element.imagePath = empData[0].imagePath;
        element.fullName = empData[0].firstName + " " + empData[0].lastName;
        element.jobPosition = empData[0].jobPosition;
      });

      setLeaveApproves(leaveApprovesList);
    }
    setIsLoading(false);
  }, [leaveApprovesStore, empStore]);

  useEffect(() => {
    dispatch(getAllLeaveApproves());
    dispatch(getAllEmployees());
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.headingPage}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">จัดการวันลา</Typography>
          </Grid>
        </Grid>
      </Box>
      <Paper className={classes.paper}>
        <DataTable leaveApproves={leaveApproves} isLoading={isLoading} />
      </Paper>
    </div>
  );
}

export default Index;
