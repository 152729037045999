import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";

import {
  Divider,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";

import { 
  editBillingDetail, 
  getBillingAllCompanysByVendor,
  getBillingAllEmployeesByBilling 
} from '../../../../../actions/billing';

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
  "& .topic":{
    fontWeight:"500",
    marginRight:"8px"
  },
  "& .detail":{
    fontSize:"18px"
  },
  "& .topic-textField":{
    fontWeight:"500",
    marginBottom:"8px"
  },
  "& .textField-bottom":{
    marginBottom:"16px"
  }
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: "16px 0px",
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const { open, data, handleClose, monthYear, handleAlert } = props;
  const dispatch = useDispatch();
  const { result: billingDetail } = useSelector((state) => state.billingDetail);
  const { result: additionVendor } = useSelector((state) => state.additionVendor);
  const [departmentNotAccept, setDepartmentNotAccept] = useState(null);

  const { control, handleSubmit, reset, formState: { isDirty, dirtyFields }, watch} = useForm();

  useEffect(() => {
    if(data){
      let tempObj = {};
      if(data.listAdditions && data.listAdditions.length > 0){
        data.listAdditions.map(addition => {
          tempObj[`chargeValueAddition${addition.idAddition}`] = `${data[`chargeValueAddition${addition.idAddition}`] || 0}`;
        })
      }

      reset({
        idBillingDetail: data.idBillingDetail,
        chargeSalary: `${data.chargeSalary || 0}`,
        chargeOT1: `${data.chargeOT1 || 0}`,
        newPerHoursOT1: `${data.newPerHoursOT1 || 0}`,
        chargeOT1dot5: `${data.chargeOT1dot5 || 0}`,
        newPerHoursOT1dot5: `${data.newPerHoursOT1dot5 || 0}`,
        chargeOT2: `${data.chargeOT2 || 0}`,
        newPerHoursOT2: `${data.newPerHoursOT2 || 0}`,
        chargeOT3: `${data.chargeOT3 || 0}`,
        newPerHoursOT3: `${data.newPerHoursOT3 || 0}`,
        chargeCost: `${data.chargeCost || 0}`,
        chargeCompensated: `${data.chargeCompensated || 0}`,
        otherAddition: `${data.otherAddition || 0}`,
        otherDeduction: `${data.otherDeduction || 0}`,
        ...tempObj
      });
    }
  }, [data]);

  const onSubmitHandler = async (value) => {
    let formatData = {};
    let tempData = data;
    Object.keys(dirtyFields).map(key => {
      if(key.indexOf("chargeValueAddition") >= 0){
        let idAddition = key.split("chargeValueAddition")[1];
        let billingDetailAddition = data.listAdditions ? 
          data.listAdditions.find(addition => addition.idAddition === parseInt(idAddition))
          : null;
        if(!formatData["listAdditions"]){
          formatData["listAdditions"] = [];
        }
        let temp = {
          idBillingDetailAddition: billingDetailAddition ? billingDetailAddition.idBillingDetailAddition : null,
          chargeValue: parseFloat(value[key]),
          totalValue: parseFloat(value[key]) + (billingDetailAddition ? billingDetailAddition.value : 0)
        };
        formatData["listAdditions"].push(temp);
      } else {
        formatData[key] = parseFloat(value[key]);
      }
      tempData[key] = parseFloat(value[key]);
    });

    let totalOT1WithoutCharge = (tempData['hoursOT1'] * (tempData['newPerHoursOT1'] || tempData['perHoursOT1']));
    formatData["totalOT1"] = (totalOT1WithoutCharge + tempData['chargeOT1']);

    let totalOT1dot5WithoutCharge = (tempData['hoursOT1dot5'] * (tempData['newPerHoursOT1dot5'] || tempData['perHoursOT1dot5']) * 1.5);
    formatData["totalOT1dot5"] = (totalOT1dot5WithoutCharge + tempData['chargeOT1dot5']);

    let totalOT2WithoutCharge = (tempData['hoursOT2'] * (tempData['newPerHoursOT2'] || tempData['perHoursOT2']) * 2);
    formatData["totalOT2"] = (totalOT2WithoutCharge + tempData['chargeOT2']);

    let totalOT3WithoutCharge = (tempData['hoursOT3'] * (tempData['newPerHoursOT3'] || tempData['perHoursOT3']) * 3);
    formatData["totalOT3"] = (totalOT3WithoutCharge + tempData['chargeOT3']);

    formatData["totalOT"] = (totalOT1WithoutCharge + totalOT1dot5WithoutCharge + totalOT2WithoutCharge + totalOT3WithoutCharge);
    formatData["totalAllOT"] = (formatData["totalOT1"] + formatData["totalOT1dot5"] + formatData["totalOT2"] + formatData["totalOT3"]);

    formatData["totalCharges"] = (
      tempData["chargeSalary"] + tempData["chargeOT1"] + tempData["chargeOT1dot5"] + tempData["chargeOT2"] + 
      tempData["chargeOT3"] + tempData["chargeCost"] + tempData["chargeCompensated"]
    );
    
    if(tempData["listAdditions"] && tempData["listAdditions"].length > 0){
      formatData["totalAddition"] = 0;
      formatData["chargeAddition"] = 0;
      tempData["listAdditions"].map(addition => {
        formatData["totalCharges"] += tempData[`chargeValueAddition${addition.idAddition}`];
        formatData["totalAddition"] += tempData[`valueAddition${addition.idAddition}`];
        formatData["chargeAddition"] += tempData[`chargeValueAddition${addition.idAddition}`];
      });
      tempData["totalAddition"] = formatData["totalAddition"];
      tempData["chargeAddition"] = formatData["chargeAddition"];
    }

    formatData["totalEarning"] = (
      parseFloat(tempData['salary']) + parseFloat(formatData["totalOT"]) + parseFloat(formatData["totalCharges"]) + 
      parseFloat(tempData["shiftCost"] || 0) + 
      parseFloat(tempData["totalAddition"])
    );
    // console.log(`salary : ${tempData['salary']}, totalOT : ${formatData["totalOT"]}, totalCharges : ${formatData["totalCharges"]}, shiftCost : ${tempData["shiftCost"]}, totalAddition: ${tempData["totalAddition"]}`)
    formatData["totalOther"] = ((tempData["otherAddition"] || 0) - (tempData["otherDeduction"] || 0));
    formatData["totalBilling"] = (formatData["totalEarning"] + parseFloat(formatData['totalOther']));
    formatData.idBillingDetail = value.idBillingDetail;
    formatData.idBilling = data.idBilling;

    const result = await dispatch(editBillingDetail(formatData));
    if(result.status === 200){
      handleClose();
      handleAlert("success");
      dispatch(getBillingAllCompanysByVendor(dayjs(monthYear).date(1).format("YYYY-MM-DD")));
      dispatch(getBillingAllEmployeesByBilling(monthYear, data.idBilling));
    }else{
      handleAlert("error");
    }
  };

  return (
    <DrawerCustom
      title={"แก้ไข Billing"}
      anchor={"right"}
      open={open ? true : false}
      onClose={handleClose}
    >
      <StyledRoot>
        <div style={{ display:"flex" }}>
          <Typography className="topic detail">{`ชื่อ:`}</Typography>
          <Typography className="detail">{`${data.firstname} ${data.lastname}`}</Typography>
        </div>
        <div style={{ display:"flex" }}>
          <Typography className="topic detail">{`ตำแหน่งงาน:`}</Typography>
          <Typography className="detail">{`${data.positionsName}`}</Typography>
        </div>
        <div style={{ display:"flex" }}>
          <Typography className="topic detail" style={{ color:"#9C9C9C", fontSize:"16px" }}>{`แผนก:`}</Typography>
          <Typography className="detail" style={{ color:"#9C9C9C", fontSize:"16px" }}>{`${data.sectionName}`}</Typography>
        </div>
        <div style={{ display:"flex" }}>
          <Typography className="topic detail" style={{ color:"#9C9C9C", fontSize:"16px" }}>{`หน่วยงาน:`}</Typography>
          <Typography className="detail" style={{ color:"#9C9C9C", fontSize:"16px" }}>{`${data.departmentName}`}</Typography>
        </div>
        <Divider style={{ margin:"16px 0" }}  />
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {/* <Typography className="topic-textField">Charge ค่าแรง</Typography>
          <Controller 
            control={control}
            name="chargeSalary"
            render={({ field }) => (
              <TextFieldTheme 
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Typography className="topic-textField">OTx1 (Charge)</Typography>
          <Controller 
            control={control}
            name="chargeOT1"
            render={({ field }) => (
              <TextFieldTheme 
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Typography className="topic-textField">OTx1 (ราคา/ชม.)</Typography>
          <Controller 
            control={control}
            name="newPerHoursOT1"
            render={({ field }) => (
              <TextFieldTheme 
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Typography className="topic-textField">OTx1.5 (Charge)</Typography>
          <Controller 
            control={control}
            name="chargeOT1dot5"
            render={({ field }) => (
              <TextFieldTheme 
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Typography className="topic-textField">OTx1.5 (ราคา/ชม.)</Typography>
          <Controller 
            control={control}
            name="newPerHoursOT1dot5"
            render={({ field }) => (
              <TextFieldTheme 
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Typography className="topic-textField">OTx2 (Charge)</Typography>
          <Controller 
            control={control}
            name="chargeOT2"
            render={({ field }) => (
              <TextFieldTheme 
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Typography className="topic-textField">OTx2 (ราคา/ชม.)</Typography>
          <Controller 
            control={control}
            name="newPerHoursOT2"
            render={({ field }) => (
              <TextFieldTheme 
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Typography className="topic-textField">OTx3 (Charge)</Typography>
          <Controller 
            control={control}
            name="chargeOT3"
            render={({ field }) => (
              <TextFieldTheme 
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Typography className="topic-textField">OTx3 (ราคา/ชม.)</Typography>
          <Controller 
            control={control}
            name="newPerHoursOT3"
            render={({ field }) => (
              <TextFieldTheme 
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Typography className="topic-textField">Charge เหมา</Typography>
          <Controller 
            control={control}
            name="chargeCost"
            render={({ field }) => (
              <TextFieldTheme 
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Typography className="topic-textField">Charge ค่าชดเชย</Typography>
          <Controller 
            control={control}
            name="chargeCompensated"
            render={({ field }) => (
              <TextFieldTheme 
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          {(data.listAdditions && data.listAdditions.length > 0) &&
            data.listAdditions.map(addition => (
              <Fragment key={addition.idAddition}>
                <Typography className="topic-textField">{`Charge ${addition.additionName}(${addition.additionCode})`}</Typography>
                <Controller 
                  control={control}
                  name={`chargeValueAddition${addition.idAddition}`}
                  render={({ field }) => (
                    <TextFieldTheme 
                      className="textField-bottom"
                      InputProps={{
                        inputComponent: NumberFormatTheme
                      }}
                      inputProps={{
                        allowNegative: false,
                        allowLeadingZeros: true,
                        value: field.value,
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value)
                        }
                      }}
                      onChange={()=>{}}
                    />
                  )}
                />
              </Fragment>
            ))
          } */}
          <Typography className="topic-textField">เพิ่มค่าอื่นๆ</Typography>
          <Controller 
            control={control}
            name={`otherAddition`}
            render={({ field }) => (
              <TextFieldTheme
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Typography className="topic-textField">หักค่าอื่นๆ</Typography>
          <Controller 
            control={control}
            name={`otherDeduction`}
            render={({ field }) => (
              <TextFieldTheme
                className="textField-bottom"
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  }
                }}
                onChange={()=>{}}
              />
            )}
          />
          <Divider style={{ margin:"16px 0" }}  />
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue
              variant="contained"
              type="submit"
              disabled={!isDirty}
            >
              บันทึกข้อมูล
            </ButtonBlue>
          </StyledFooter>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
