import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import dataEmployeeList from "../../assets/data/employees";
import { Avatar, Typography } from "@mui/material";

const columns = [
  // {
  //   id: "action",
  //   label: "",
  // },
  { id: "name", label: "ชื่อ-สกุล", minWidth: 240 },
  {
    id: "position",
    label: "ตำแหน่ง/หน่วยงาน",
    width: 320,
  },
  {
    id: "level",
    label: "ระดับ",
    width: 320,
  },
  {
    id: "mobileNumber",
    label: "เบอร์โทรศัพท์",
    minWidth: 120,
  },
  {
    id: "email",
    label: "อีเมล์",
    minWidth: 120,
  },
  {
    id: "report_To",
    label: "หัวหน้างาน",
    minWidth: 120,
  },
  {
    id: "report_Email",
    label: "อีเมล์หัวหน้างาน",
    minWidth: 120,
  },
  {
    id: "vendorName",
    label: "บริษัทสังกัด",
    minWidth: 180,
  },
];

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .department": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
    fontWeight: 600,
  },
  "& .reportToPosition": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${280}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const ItemTable = (props) => {
  const { open, data } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Main open={open} style={{ width: !open && "100%" }}>
      <StyledBox className={open ? "open" : "close"}>
        <TableContainer sx={{ width: "100%", maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledCellHeader
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledCellHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledRowContent
                      hover
                      key={row.scG_Employee_ID}
                      component={NavLink}
                      to="/admin/employee-profile"
                    >
                      {/* <StyledCellContent key={"action"}>
                      <IconButton aria-label="more" size="small">
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                    </StyledCellContent> */}
                      <StyledCellContent className="sticky" key={"name"}>
                        <div className="fullname">
                          <Avatar
                            alt={row.scG_Employee_ID}
                            src={`${
                              process.env.REACT_APP_API_URL
                            }image/profile/${
                              Math.floor(Math.random() * 40) + 1
                            }.jpg`}
                          />
                          <Typography>
                            {row.firstname} {row.lastname}
                          </Typography>
                        </div>
                      </StyledCellContent>
                      <StyledCellContent key={"position"}>
                        <Typography className="position">
                          {row.position}
                        </Typography>
                        <Typography className="department" color="text.third">
                          {row.department}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"level"}>
                        <Typography>{row.pL_Group}</Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"mobile"}>
                        <Typography>
                          {`${row.mobileNumber.replace(
                            /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                            "$1-$2-$3"
                          )}`}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"email"}>
                        <Typography>{row.email}</Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"report_To"}>
                        <Typography className="reportTo">
                          {row.report_To}
                        </Typography>
                        <Typography
                          className="reportToPosition"
                          color="text.third"
                        >
                          {row.report_to_Position_Name}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"report_Email"}>
                        <Typography>{row.report_Email}</Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"vendor"}>
                        <Typography>{row.vendorName}</Typography>
                      </StyledCellContent>
                    </StyledRowContent>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledBox>
    </Main>
  );
};

export default ItemTable;
