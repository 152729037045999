import {
  COMPANYS_FETCHING,
  COMPANYS_FAILED,
  COMPANYS_SUCCESS,
  COMPANYPROFILE_FETCHING,
  COMPANYPROFILE_FAILED,
  COMPANYPROFILE_SUCCESS,
  COMPANY_ORGANIZATION_FETCHING,
  COMPANY_ORGANIZATION_FAILED,
  COMPANY_ORGANIZATION_SUCCESS,
  COMPANY_BRANCH_FETCHING,
  COMPANY_BRANCH_FAILED,
  COMPANY_BRANCH_SUCCESS,
  COMPANY_CONTRACT_FETCHING,
  COMPANY_CONTRACT_FAILED,
  COMPANY_CONTRACT_SUCCESS,
  WORKTYPES_FETCHING,
  WORKTYPES_FAILED,
  WORKTYPES_SUCCESS,
  MANAGER_FETCHING,
  MANAGER_FAILED,
  MANAGER_SUCCESS,
  MANAGER_SETTING_FETCHING,
  MANAGER_SETTING_FAILED,
  MANAGER_SETTING_SUCCESS,
  EMPLOYEE_TYPE_FETCHING,
  EMPLOYEE_TYPE_FAILED,
  EMPLOYEE_TYPE_SUCCESS,
  LEVELS_FETCHING,
  LEVELS_FAILED,
  LEVELS_SUCCESS
} from "./types";

import CompanyService from "../services/company.service";

export const getAllCompanys = () => async (dispatch) => {
  try {
    dispatch({
      type: COMPANYS_FETCHING,
    });
    const res = await CompanyService.getAllCompanys();
    if (res) {
      dispatch({
        type: COMPANYS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: COMPANYS_FAILED,
    });
  }
};

export const getCompanyProfile = (id) => async (dispatch) => {
  try {
    const res = await CompanyService.getCompanyProfile(id);
    if (res) {
      dispatch({
        type: COMPANYPROFILE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: COMPANYPROFILE_FAILED,
    });
    return err;
  }
};

export const getCompanyOrganization = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_ORGANIZATION_FETCHING,
    });
    const res = await CompanyService.getCompanyOrganization(id);
    if (res) {
      dispatch({
        type: COMPANY_ORGANIZATION_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: COMPANY_ORGANIZATION_FAILED,
    });
    return err;
  }
};

export const companyEmployeeType = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_TYPE_FETCHING,
    });
    const res = await CompanyService.companyEmployeeType(id);
    if (res) {
      dispatch({
        type: EMPLOYEE_TYPE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_TYPE_FAILED,
    });
    return err;
  }
};

export const putCompanyProfile = (id, formData) => async () => {
  try {
    const res = await CompanyService.putCompanyProfile(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putCompanyProfileMainContact = (id, formData) => async () => {
  try {
    const res = await CompanyService.putCompanyProfileMainContact(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const postCompanyAddress = (formData) => async () => {
  try {
    const res = await CompanyService.postCompanyAddress(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putCompanyAddress =
  (id, idCompanyAddress, formData) => async () => {
    try {
      const res = await CompanyService.putCompanyAddress(
        id,
        idCompanyAddress,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return "Error";
    }
  };

export const getCompanyBranch = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_BRANCH_FETCHING,
    });
    const res = await CompanyService.getCompanyBranch(id);
    if (res) {
      dispatch({
        type: COMPANY_BRANCH_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: COMPANY_BRANCH_FAILED,
    });
    return err;
  }
};

export const postCompanyBranch = (formData) => async () => {
  try {
    const res = await CompanyService.postCompanyBranch(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putCompanyBranch = (id, idBranch, formData) => async () => {
  try {
    const res = await CompanyService.putCompanyBranch(id, idBranch, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putCompanyBankDetail =
  (id, idBankDetail, formData) => async () => {
    try {
      const res = await CompanyService.putCompanyBankDetail(
        id,
        idBankDetail,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return "Error";
    }
  };

export const getVendorContract = () => async (dispatch) => {
  try {
    dispatch({
      type: COMPANY_CONTRACT_FETCHING,
    });
    const res = await CompanyService.getVendorContract();
    if (res) {
      dispatch({
        type: COMPANY_CONTRACT_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: COMPANY_CONTRACT_FAILED,
    });
    return err;
  }
};

export const getAllCompanysByIdVendor = (idVendor) => async (dispatch) => {
  try {
    dispatch({
      type: COMPANYS_FETCHING,
    });
    const res = await CompanyService.getAllCompanysByIdVendor(idVendor);
    if (res) {
      dispatch({
        type: COMPANYS_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: COMPANYS_FAILED,
    });
  }
};

export const getWorkInfoByidCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: WORKTYPES_FETCHING,
    });
    const res = await CompanyService.getWorkInfoByidCompany(idCompany);
    if (res) {
      dispatch({
        type: WORKTYPES_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: WORKTYPES_FAILED,
    });
    return error;
  }
};

export const getManagerByidCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: MANAGER_FETCHING,
    });
    const res = await CompanyService.getManagerByidCompany(idCompany);
    if (res) {
      dispatch({
        type: MANAGER_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: MANAGER_FAILED,
    });
    return error;
  }
};

export const getManagerSettingByidCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: MANAGER_SETTING_FETCHING,
    });
    const res = await CompanyService.getManagerSettingByidCompany(idCompany);
    if (res) {
      dispatch({
        type: MANAGER_SETTING_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: MANAGER_SETTING_FAILED,
    });
    return error;
  }
};

export const updateManagerSetting = (idCompany, formData) => async () => {
  try {
    const res = await CompanyService.updateManagerSetting(idCompany, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getGroupLevelByIdCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: LEVELS_FETCHING,
    });
    const res = await CompanyService.getGroupLevelByIdCompany(idCompany);
    if (res) {
      dispatch({
        type: LEVELS_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: LEVELS_FAILED,
    });
    return error.response;
  }
};

export const updateGroupLevelByIdCompany = (idCompany, formData) => async () => {
  try {
    const res = await CompanyService.updateGroupLevelByIdCompany(idCompany, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const updateLevelByIdCompany = (idCompany, formData) => async () => {
  try {
    const res = await CompanyService.updateLevelByIdCompany(idCompany, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteGroupLevelByIdCompanyIdGroupLevel = (idCompany, idGroupLevel) => async () => {
  try {
    const res = await CompanyService.deleteGroupLevelByIdCompanyIdGroupLevel(idCompany, idGroupLevel);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteLevelByIdCompanyIdLevel = (idCompany, idLevel) => async () => {
  try {
    const res = await CompanyService.deleteLevelByIdCompanyIdLevel(idCompany, idLevel);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};
