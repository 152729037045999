import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from 'react-hook-form';
import dayjs from "dayjs";
import {
  Typography,
  Grid,
  Divider,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import AdapterDateFns from "@tarzui/date-fns-be";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import { updateUser } from "../../../../../../actions/user";
import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledTextFieldTheme = styled(TextFieldTheme)(({}) => ({
  "& .MuiInputLabel-root": {
    color: "#00000061",
  },
}));

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const DialogEdit = (props) => {
  const { open, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { t, i18n } = useTranslation();

  const { control, handleSubmit, reset, formState: { errors } } = useForm();
  const [formData, setFormData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    reset({
      idEmp: employeeProfile.idEmp,
      title: employeeProfile.title || "",
      firstname: employeeProfile.firstname || "",
      lastname: employeeProfile.lastname || "",
      firstname_en: employeeProfile.firstname_en || "",
      lastname_en: employeeProfile.lastname_en || "",
      nickname: employeeProfile.nickname || "",
      gender: employeeProfile.gender || "",
      birthday: dayjs(employeeProfile.birthday).format("YYYY-MM-DD") || dayjs().format("YYYY-MM-DD"),
      personalId: employeeProfile.personalId,
      telephoneMobile: employeeProfile.telephoneMobile,
      employeeId: employeeProfile.employeeId,
      email: employeeProfile.email,
    });
  }, [open]);

  const onHandleSubmit = async (data) => {
    data.UpdateBy = userProfile.idUsers;
    if (
      data.birthday !== dayjs(employeeProfile.birthday).format("YYYY-MM-DD")
    ) {
      data.birthday = dayjs(data.birthday).format("YYYY-MM-DD");
    }
    console.log("data : ",data);
    const result = await dispatch(updateUser(data));
    if (result) {
      handleCloseDialog();
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
    }
  };

  return (
    <DrawerCustom
      title={t("EditEmpProfile")}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Prefix")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth error={errors && errors.title ? true : false}>
                <Controller 
                  name="title"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t("PleaseEnter")} ${t("Prefix")}`,
                    },
                  }}
                  render={({ field }) => (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="title"
                      {...field}
                    >
                      <MenuItem value={""} disabled></MenuItem>
                      <MenuItem value={"นาย"}>{t("Mr")}</MenuItem>
                      <MenuItem value={"นาง"}>{t("Mrs")}</MenuItem>
                      <MenuItem value={"นางสาว"}>{t("Miss")}</MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText>{errors && errors.title && errors.title.message}</FormHelperText>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("FirstName")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller 
                name="firstname"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("FirstName")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    fullWidth
                    {...field}
                    error={errors && errors.firstname ? true : false}
                    helperText={errors && errors.firstname && errors.firstname.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("LastName")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller 
                name="lastname"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("LastName")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    fullWidth
                    {...field}
                    error={errors && errors.lastname ? true : false}
                    helperText={errors && errors.lastname && errors.lastname.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("FirstName")} (${t("Eng")})`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller 
                name="firstname_en"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>
                {`${t("LastName")} (${t("Eng")})`}
              </StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller 
                name="lastname_en"
                control={control}
                render={({ field }) => (
                  <TextFieldTheme
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Nickname")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller 
                name="nickname"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("Nickname")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    fullWidth
                    {...field}
                    error={errors && errors.nickname ? true : false}
                    helperText={errors && errors.nickname && errors.nickname.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Gender")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller 
                name="gender"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("Gender")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    fullWidth
                    {...field}
                    error={errors && errors.gender ? true : false}
                    helperText={errors && errors.gender && errors.gender.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("BirthDate")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <Controller 
                  name="birthday"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t("PleaseEnter")} ${t("BirthDate")}`,
                    }
                  }}
                  slotProps={{
                    textField: {
                      helperText: (errors && errors.birthday && errors.birthday.message) || "",
                    },
                  }}
                  render={({ field }) => (
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      {...field}
                      renderInput={(params) => (
                        <StyledTextField 
                          fullWidth 
                          {...params} 
                          error={(errors && errors.birthday) ? true : false}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("IDCardNumber")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller 
                name="personalId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("IDCardNumber")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    fullWidth
                    {...field}
                    error={errors && errors.personalId ? true : false}
                    helperText={errors && errors.personalId && errors.personalId.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("PhoneNumber")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller 
                name="telephoneMobile"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("PhoneNumber")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    fullWidth
                    {...field}
                    error={errors && errors.telephoneMobile ? true : false}
                    helperText={errors && errors.telephoneMobile && errors.telephoneMobile.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("EmployeeID")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller 
                name="employeeId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("EmployeeID")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    fullWidth
                    {...field}
                    error={errors && errors.employeeId ? true : false}
                    helperText={errors && errors.employeeId && errors.employeeId.message}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Email")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller 
                name="email"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("Email")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    fullWidth
                    {...field}
                    error={errors && errors.email ? true : false}
                    helperText={errors && errors.email && errors.email.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleCloseDialog}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" type="submit">
              {t("Save")}
            </ButtonBlue>
          </StyledFooter>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
