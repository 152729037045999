import { combineReducers } from "redux";
import auth from "./auth";
import userProfile from "./user-profile";
import companyProfile from "./company-profile";
import companyOrganization from "./company-organization";
import users from "./user";
import department from "./department";
import payrollSetting from "./payroll-setting";
import paytypes from "./paytypes";
import payRun from "./pay-run";
import payRunDetail from "./pay-run-detail";
import payRunDetailEmployees from "./pay-run-detail-employee";
import payslip from "./payslip";
import payslipDetail from "./payslip-detail";
import payRunSummaryYear from "./pay-run-summary-year";
import summaryTime from "./summary-time";
import summaryTimeCompany from "./summary-time-company";
import employees from "./employee";
import employeeProfile from "./employee-profile";
import employeeShift from "./employee-shift";
import employeeNotInShift from "./employee-not-in-shift";
import employeeAdditionDeduction from "./employee-addition-deduction";
import employeePosition from "./employee-position";
import employeeSalary from "./employee-salary";
import employeeEmployeeType from "./employee-employeeType";
import employeeType from "./employee-type";
import navigation from "./navigation";
import orgChart from "./orgChart";
import project from "./project";
import projectAssign from "./projectAssign";
import locations from "./locations";
import leaveWorks from "./leaveWorks";
import jobFamily from "./jobFamily";
import institute from "./institute";
import leaveApproves from "./leaveApproves";
import workTimeRequest from "./workTimeRequest";
import logRecord from "./logRecord";
import groupLocations from "./group-locations";
import attendance from "./attendance";
import attendanceOnce from "./attendance-once";
import attendanceList from "./attendanceList";
import userLeave from "./user-leave";
import leaveType from "./leave-type";
import leavePattern from "./leave-pattern";
import holiday from "./holiday";
import billing from "./billing";
import billingDetail from "./billing-detail";
import billingCompanys from "./billing-companys";
import billingVendors from "./billing-vendors";
import billingEmployees from "./billing-employees";
import companyContract from "./company-contract";
import vendorContract from "./vendor-contract";
import empDataForm from "./emp-data-form";
import vendorUsers from "./vendor-users";
import leaveEmployees from "./leaveEmployees";
import leaveEmployeesWithDraw from "./leaveEmployeesWithdraw";
import requestTime from "./requestTime";
import requestTimeWithDraw from "./requestTime-withdraw";
import requestTimeReason from "./requestTime-reason";

import shiftPattern from "./shift-pattern";
import shift from "./shift";
import shiftGroup from "./shift-group";
import experience from "./experience";
import education from "./education";
import positions from "./positions";
import manager from "./manager";
import managerId from "./manager-id";
import managerSetting from "./manager-setting";
import companyBranch from "./company-branch";
import bank from "./bank";
import vendorProfile from "./vendor-profile";
import companys from "./company";
import workTypes from "./work-types";
import employeeShiftHistory from "./employee-shift-history";
import division from "./division";
import section from "./section";
import training from "./training";
import additionAndTraining from "./addionAndTraining";
import additions from "./additions";
import expertises from "./expertises";
import expertisesVendor from "./expertises-vendor";
import services from "./services";
import jobLevel from "./jobLevel";
import personnelLevel from "./personnelLevel";
import levelAddition from "./levelAddition";
import additionCompany from "./addition-company";
import additionVendor from "./addition-vendor";
import branchVendor from "./branches-vendor";
import bankDetail from "./bank-detail";

import vendorProvidentFund from "./vendor-provident-fund";
import vendorPfSchedule from "./vendor-pf-schedule";
import userPFSchedule from "./userPFSchedule";
import userProvidentFund from "./userProvidentFund";
import salarySlip from "./salarySlip";

import dashboardOverview from "./dashboard-overview";
import dashboardOvertime from "./dashboard-overtime";
import dashboardCost from "./dashboard-cost";
import dashboardWorkingtime from "./dashboard-workingtime";

import userBeneficiarys from "./user-beneficiarys";

import taxDeduction from "./tax-deduction";
import taxDeductionSchedule from "./tax-deduction-schedule";

import area from "./area";
import areaDetail from "./area-detail";

import timeline from "./timeline";
import timelineMonth from "./timelineMonth";

import companyAntTeam from "./ant-team/company";
import vendorAntTeam from "./ant-team/vendor";

import admin from "./admin";
import performanceCompany from "./performance-company";
import performanceEmployee from "./performance-employee";
import groupLevelCompany from "./groupLevel-company";

import managerGroup from "./manager-group";

import allContractEmployees from "./all-contract-employees";
import allEmployeesInContract from "./all-employees-in-contract";
import contractEmployeesDetail from "./contract-employees-detail";
import employeeContractDaily from "./employee-contract-daily";

import reserveEmployees from "./reserve-employees";
import contractAll from "./contract-all";
import reserveEmployeesAll from "./reserve-employees-all";

import overtime36Hours from "./overtime-36-hours";
import overtime36HoursDetail from "./overtime-36-hours-detail";

import expense from "./expense";
import expenseDetail from "./expense-detail";
import expenseExport from "./expense-export";

import admins from "./admins";
import organizationChart from "./organizationChart";
import organizationStructure from "./organizationStructure";
import jobGroup from "./jobGroup";

import probationQuestion from "./probation-question";
import probationAnswer from "./probation-answer";
import employeeProbation from "./employee-probation";

import notificationAlert from "./notificationAlert";
import employeeShiftChangeHistory from "./employee-shift-change-history";

import employeeList from "./employees";
import employeeShiftSchedule from "./employeeShiftSchedule";

import leaveAvailable from "./leave-available";
import level from "./level";

import taxSummary from "./tax-summary";
import employeePersonnelLevelChange from "./employee-personnel-level-chanage";
import billingStatement from "./billing-statement";

const appReducer = combineReducers({
  auth,
  users,
  userProfile,
  companyProfile,
  department,
  paytypes,
  payrollSetting,
  payRun,
  payRunDetail,
  payRunDetailEmployees,
  payRunSummaryYear,
  payslip,
  payslipDetail,
  summaryTime,
  summaryTimeCompany,
  navigation,
  employees,
  employeeProfile,
  employeeShift,
  employeeNotInShift,
  employeeAdditionDeduction,
  employeePosition,
  employeeSalary,
  employeeEmployeeType,
  employeeType,
  orgChart,
  project,
  projectAssign,
  locations,
  leaveWorks,
  jobFamily,
  institute,
  leaveApproves,
  workTimeRequest,
  logRecord,
  groupLocations,
  attendance,
  userLeave,
  leaveType,
  leavePattern,
  holiday,
  companyOrganization,
  shiftPattern,
  shift,
  shiftGroup,
  experience,
  education,
  positions,
  manager,
  managerId,
  managerSetting,
  attendanceOnce,
  attendanceList,
  companyBranch,
  bank,
  vendorProfile,
  billing,
  billingDetail,
  billingCompanys,
  billingVendors,
  billingEmployees,
  companyContract,
  vendorContract,
  empDataForm,
  vendorUsers,
  companys,
  workTypes,
  employeeShiftHistory,
  division,
  section,
  training,
  additionAndTraining,
  additions,
  expertises,
  services,
  expertisesVendor,
  jobLevel,
  personnelLevel,
  levelAddition,
  additionCompany,
  additionVendor,
  branchVendor,
  bankDetail,
  vendorProvidentFund,
  vendorPfSchedule,
  userPFSchedule,
  userProvidentFund,
  salarySlip,
  leaveEmployees,
  leaveEmployeesWithDraw,
  requestTime,
  requestTimeReason,
  requestTimeWithDraw,
  dashboardOverview,
  dashboardOvertime,
  dashboardCost,
  dashboardWorkingtime,
  userBeneficiarys,
  taxDeduction,
  taxDeductionSchedule,
  timeline,
  timelineMonth,
  area,
  areaDetail,
  admin,
  performanceCompany,
  performanceEmployee,
  groupLevelCompany,
  managerGroup,
  allContractEmployees,
  allEmployeesInContract,
  contractEmployeesDetail,
  reserveEmployees,
  contractAll,
  reserveEmployeesAll,
  overtime36Hours,
  overtime36HoursDetail,
  employeeContractDaily,

  // ANT-TEAM
  companyAntTeam,
  vendorAntTeam,

  expense,
  expenseDetail,
  admins,
  expenseExport,
  organizationChart,
  organizationStructure,
  jobGroup,
  probationQuestion,
  probationAnswer,
  employeeProbation,

  notificationAlert,
  employeeShiftChangeHistory,

  employeeList,
  employeeShiftSchedule,

  leaveAvailable,

  level,
  taxSummary,

  employeePersonnelLevelChange,
  billingStatement
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
