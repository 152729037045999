import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";

import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  "& .btn-action": {
    display: "flex",
    justifyContent: "center",
    marginTop: 36,
  },
});

const DialogDownload = (props) => {
  const { open, toggleDrawer } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { t, i18n } = useTranslation();

  useEffect(() => {}, []);

  const handleClickDownload = () => {};

  return (
    <DrawerCustom
      title={`${t("Download")}${t("EmployeeHistory")}`}
      anchor={"right"}
      open={open}
      onClose={toggleDrawer(false)}
    >
      <StyledRoot>
        <div className="btn-action">
          <ButtonBlue variant="contained" onClick={handleClickDownload}>
            {t("Download")}
          </ButtonBlue>
        </div>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogDownload;
