import i18next from "i18next";

export function getUserFullName(user) {
  if (i18next.resolvedLanguage === "th") {
    return `${user.firstname} ${user.lastname}`;
  }
  return user.firstname_en && user.lastname_en
    ? `${user.firstname_en} ${user.lastname_en}`
    : "";
}

export function getUserNickName(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.nickname_TH;
  }
  return user.nickname_EN || "-";
}

export function getUserPosition(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.positionsName;
  }
  return user.positionName_EN || "-";
}

export function getUserCompany(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.companyName;
  }
  return user.companyName_EN || "-";
}

export function getUserBusinessUnit(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.businessUnitName;
  }
  return user.businessUnitName_EN || "-";
}

export function getUserDivision(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.divisionName;
  }
  return user.divisionName_EN || "-";
}

export function getUserDepartment(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.departmentName;
  }
  return user.departmentName_EN || "-";
}

export function getUserSection(user) {
  if (i18next.resolvedLanguage === "th") {
    return user.sectionName;
  }
  return user.sectionName_EN || "-";
}
