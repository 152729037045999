import reportService from "../services/report.service";

export const getWorkingTimeReport = async (query) => {
    try {
        const res = await reportService.getWorkingTimeReport(query);
        if (res) {
            return res;
        }
    } catch (err) {
        return err;
    }
};

export const getSummaryOvertimeShift = async (query) => {
    try {
        const res = await reportService.getSummaryOvertimeShift(query);
        if (res) {
            return res;
        }
    } catch (err) {
        return err;
    }
};

export const getTotalOtWage = async (query) => {
    try {
        const res = await reportService.getTotalOtWage(query);
        if (res) {
            return res;
        }
    } catch (err) {
        return err;
    }
};

export const getAllOTHour = async (query) => {
    try {
        const res = await reportService.getTotalOtHours(query);
        if (res) {
            return res;
        }
    } catch (err) {
        return err;
    }
};

export const getEmployWorkingDays = async (query) => {
    try {
        const res = await reportService.getEmployWorkingDays(query);
        if (res) {
            return res;
        }
    } catch (err) {
        return err;
    }
};

export const exportExcelLeaveReport = async (query) => {
    try {
        const res = await reportService.exportExcelLeaveReport(query);
        if (res) {
            return res;
        }
    } catch (error) {
        if (error.response) {
            return error.response;
        }
    }
};

export const getLeaveListReport = async (query) => {
    try {
        const res = await reportService.getLeaveListReport(query);
        if (res) {
            return res;
        }
    } catch (err) {
        console.log(err);
    }
};

export const getLeaveDetails = async (query) => {
    try {
        const res = await reportService.getLeaveDetails(query);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
    }
};

export const getNewEmployee = async (query) => {
    try {
        const res = await reportService.getNewEmployee(query);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
    }
};

export const getResignEmployee = async (query) => {
    try {
        const res = await reportService.getResignEmployee(query);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
    }
};

export const getEmployeeContractTermination = async (query) => {
    try {
        const res = await reportService.getEmployeeContractTermination(query);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
    }
};

export const exportExcelEmployeeInformationReport = async (query) => {
    try {
        const res = await reportService.exportExcelEmployeeInformationReport(query);
        if (res) {
            return res;
        }
    } catch (error) {
        if (error.response) {
            return error.response;
        }
    }
};

export const getLevelingReport = async (query) => {
    try {
        const res = await reportService.getLevelingReport(query);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
    }
};

export const getEmploymentReport = async (query) => {
    try {
        const res = await reportService.getEmploymentReport(query);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
    }
};

export const getTransferPosition = async (query) => {
    try {
        const res = await reportService.getTransferPosition(query);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
    }
};

export const getPayrollVendor = async (filter, start) => {
    try {
        const res = await reportService.getPayrollVendor(filter, start);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
    }
};

export const getPayrollemployee = async (filter, start) => {
    try {
        const res = await reportService.getPayrollemployee(filter, start);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
    }
};

export const getSalaryAdjustEmployee = async (query) => {
    try {
        const res = await reportService.getSalaryAdjustEmployee(query);
        if (res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const getPFvalueAllYearByEmpReport = async (query) => {
    try {
        const res = await reportService.getPFvalueAllYearByEmp(query);
        if (res) {
            return res;
        }
    } catch (error) {
        return error;
    };
};

export const exportRequestListReport = async (query) => {
    try {
        const res = await reportService.exportExcelRequestListReport(query);
        if (res) {
            return res;
        }
    } catch (error) {
        if (error.response) {
            return error.response;
        }
    }
}