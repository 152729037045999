import React, { useEffect, useRef } from 'react';

import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeNotificationAlert } from '../../../../actions/notificationAlert';


export default function NotificationGlobal() {
  
  const dispatch = useDispatch()

  const notificationAlertConfig = useSelector(state => state.notificationAlert)

  const timerRef = useRef(null);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeNotificationAlert())
  };

  useEffect(() => {
    if(notificationAlertConfig.isOpen) {
      if(timerRef) clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [notificationAlertConfig])

  return (
    <Snackbar
      open={notificationAlertConfig.isOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      <Alert severity={notificationAlertConfig.type} onClose={handleClose} style={{marginTop: "48px"}}>
        {notificationAlertConfig.message}
      </Alert>
    </Snackbar>
  );
}
