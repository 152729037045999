import dayjs from "dayjs";
import React, { useState } from "react";
//import style
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

//import materials
import {
  CardContent, Divider, IconButton,
  Paper, Select, Typography
} from "@mui/material";

import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import ButtonBlue from "../../../shared/general/ButtonBlue";

import DialogAddExperience from "./dialogAddExperience";

const useStyles = makeStyles((theme) => ({
  details: {
    display: "flex",
  },
  avatar: {
    marginRight: "auto",
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    marginTop: 15,
  },
  tabs1: {
    width: "85%",
    marginTop: 15,
  },
  profileTitle: {
    backgroundColor: "#2F74EB",
    color: "#fff",
  },
  secondaryBtn: {
    border: "2px solid #2F74EB",
    backgroundColor: "#fff",
    color: "#2F74EB",
    fontWeight: 600,
    borderRadius: "25px",
    height: "45px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const StyledCardContent = styled(CardContent)({
  height: 536,
  overflow: "auto",
  padding: "24px !important",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiFormControl-root": {
    width: "100%",
  },
});

const StyleSelect = styled(Select)({
  borderRadius: 8,
});

const StyleWrabBtnAddExperience = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 16,
});

const StyleListSchool = styled(Paper)({
  border: "none",
  display: "flex",
  alignItems: "flex-start",
  marginBottom: 16,
});

const StyleTextEmpty = styled(Typography)({
  fontStyle: "oblique",
  fontSize: 16,
});

const StyledProfileTitle = styled("div")({
  backgroundColor: "#2F74EB",
  padding: 16,
  "& .MuiTypography-root": {
    color: "#FFFFFF",
    fontSize: 20,
    fontWeight: 500
  },
});

export default function Experience(props) {
  const classes = useStyles();
  const { experience, setExperience } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAddExperience = (newData) => {
    console.log(newData);
    const temp = [...experience];
    temp.push(newData);
    setExperience(temp);
    setOpenDialog(false);
  };

  const handleClickDelete = (index) => {
    const temp = [...experience];
    temp.splice(index, 1);
    setExperience(temp);
  };

  return (
    <div>
      <form autoComplete="off" noValidate>
        <StyledProfileTitle>
          <Typography style={{ marginLeft: 8 }}>ประสบการณ์ทำงาน</Typography>
        </StyledProfileTitle>
        <Divider />
        <StyledCardContent>
          {experience && experience.length < 3 && (
            <StyleWrabBtnAddExperience>
              <ButtonBlue
                variant="outlined"
                startIcon={<BookmarkAddIcon />}
                onClick={handleClick}
              >
                เพิ่มประสบการณ์ทำงาน
              </ButtonBlue>
            </StyleWrabBtnAddExperience>
          )}

          {experience && experience.length > 0 ? (
            experience.map((item, index) => (
              <StyleListSchool variant="outlined">
                <IconButton
                  aria-label="delete"
                  size="small"
                  style={{ marginRight: 4 }}
                  onClick={() => handleClickDelete(index)}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
                <div>
                  <Typography variant="h6">
                    {`(${dayjs(item.fromYear).format("MMMM YYYY")}-${dayjs(
                      item.endYear
                    ).format("MMMM YYYY")}) ${item.university}`}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {`${item.faculty}, ${item.major}, ${item.degree}`}
                  </Typography>
                  <Typography style={{ fontWeight: 700, marginTop: 8 }}>
                    GPA {item.gpa}
                  </Typography>
                </div>
              </StyleListSchool>
            ))
          ) : (
            <StyleTextEmpty align="center" color="text.secondary">
              ยังไม่มีข้อมูล
            </StyleTextEmpty>
          )}
        </StyledCardContent>
      </form>
      <DialogAddExperience
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
        handleAddExperience={handleAddExperience}
      />
    </div>
  );
}
