import React from "react";
import PropTypes from "prop-types";
import { Box, Card, styled, Typography } from "@mui/material";

const StyledRoot = styled(Box)({
  height: "100%",
  "& .MuiCard-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    padding: "16px",
    borderRadius: "12px",
    height: "inherit",
    boxSizing: "border-box",
  },
  "& .card-dashboard-title-container": {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    "& .title": {
      flexGrow: "1",
      fontSize: "20px",
      color: "#919eab",
    },
  },
})

const CardDashboard = (props) => {

  const {
    title,
    titleIcon,
    children
  } = props;

  return (
    <StyledRoot>
      <Card>
        {/* <Box className="card-dashboard-title-container"> */}
          {/* <Typography className="title">{title}</Typography>
          {titleIcon } */}
        {/* </Box> */}
        {children}
      </Card>
    </StyledRoot>
  )
}

CardDashboard.propTypes = {
  title: PropTypes.string
}

export default CardDashboard;