import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import ClassIcon from "@mui/icons-material/Class";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupIcon from "@mui/icons-material/Group";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PaidIcon from "@mui/icons-material/Paid";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import WorkIcon from "@mui/icons-material/Work";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

const AdminVendorMenu = () => {
  const dataListMenu = [
    {
      listItemIcon: <HomeOutlinedIcon />,
      listItemText: "Homepage",
      listLink: "/home",
      listKey: "home",
    },
    {
      listItemIcon: <BubbleChartIcon />,
      listItemText: "Overview",
      listLink: "/vendor/overview",
      listKey: "overview",
    },
    {
      listItemIcon: <DescriptionIcon />,
      listItemText: "Status_Report",
      listLink: "/vendor/approve-list",
      listKey: "status-order",
    },
    {
      listItemIcon: <GroupIcon />,
      listItemText: "Employees",
      listLink: "/vendor/employees",
      listKey: "vendoremployee",
    },
    {
      listItemIcon: <WorkIcon />,
      listItemText: "Work_Time",
      listLink: "/vendor/time",
      listKey: "workTime",
    },
    {
      listItemIcon: <BrightnessMediumIcon />,
      listItemText: "Shift_Management",
      listLink: "/vendor/shift",
      listKey: "shift",
    },
    {
      listItemIcon: <AttachMoneyIcon />,
      listItemText: "Payroll",
      listLink: "",
      listKey: "payroll",
      collapse: [
        {
          listItemIcon: <PaidIcon />,
          listItemText: "Payroll_Management",
          listLink: "",
          listKey: "payrollrun",
        },
        {
          listItemIcon: <ReceiptIcon />,
          listItemText: "Payslip",
          listLink: "",
          listKey: "payslip",
        },
      ],
    },
    {
      listItemIcon: <PersonSearchIcon />,
      listItemText: "Sourcing",
      listLink: "/vendor/sourcing",
      listKey: "sourcing",
    },
    {
      listItemIcon: <ClassIcon />,
      listItemText: "Training",
      listLink: "/vendor/courses",
      listKey: "training",
    },
    {
      listItemIcon: (
        <i
          className="fa-solid fa-rectangle-list"
          style={{ margin: "2px 4px" }}
        />
      ),
      listItemText: "Report",
      listLink: "",
      listKey: "order",
    },
    {
      listItemIcon: <WorkHistoryIcon />,
      listItemText: "CompanyProfile",
      listLink: "/vendor/company-profile",
      listKey: "history-company",
    },
    {
      listItemIcon: <SettingsIcon />,
      listItemText: "Setting",
      listLink: "/vendor/setting",
      listKey: "setting",
    },
  ];

  return <ListMenu dataListMenu={dataListMenu} menuRole={"Admin"} />;
};

export default AdminVendorMenu;
