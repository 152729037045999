import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  footerTable: {
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#fff",
    borderRadius: "0px 0px 20px 20px",
  },
}));

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    boxShadow: "20px -10px 20px #EEEEEE",
    zIndex: 4,
  },
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    backgroundColor: "#f4f6f8",
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

function useTable(records, headCells, filterFn) {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState();

  const TblContainer = (props) => (
    <TableContainer
      style={{ width: "100%", height: props.height ? props.height : 450 }}
    >
      {props.children}
    </TableContainer>
  );

  const TblHead = (props) => {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((head) => (
            <StyledCellHeader
              key={head.id}
              align={head.align}
              className={head.className}
              style={{ minWidth: head.minWidth }}
            >
              {head.label}
            </StyledCellHeader>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const TblPagination = (props) => (
    <Toolbar className={classes.footerTable}>
      <TablePagination
        component="div"
        page={props.page}
        rowsPerPageOptions={props.pages}
        rowsPerPage={props.rowsPerPage}
        count={props.count}
        onPageChange={props.handleChangePage}
        onRowsPerPageChange={props.handleChangeRowsPerPage}
      />
    </Toolbar>
  );

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const recordsAfterPagingAndSorting = () => {
    return records;
  };

  return { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting };
}

export default useTable;
