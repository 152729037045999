import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import {
  Avatar,
  FilledInput,
  Typography,
  FormControl,
  InputAdornment,
  Grid,
  Chip,
  Badge,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerEng from "../../shared/general/DatePickerEng";
import DatePickerThai from "../../shared/general/DatePickerThai";
import AlertResponse from "../../shared/general/AlertResponse";

import DialogAddOrEditEmployeeSpareContract from "./dialogAddOrEditEmployeeSpareContract";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    display: "flex",
    alignItems: "center",
    "& .profile": {
      marginRight: 8,
      width: 45,
      height: 45,
    },
    "& .first-row": {
      display: "flex",
    },
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .department": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    fontWeight: 600,
  },
  "& .reportToPosition": {
    fontSize: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledBox = styled(Box)({
  "& .wrap-filter": {
    marginBottom: 16,
  },
  "& .count": {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 16px",
    marginBottom: 16,
  },
});

const StyledBoxSearch = styled(Box)({
  width: "100%",
  "&.first": {
    marginRight: 8,
  },
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledTableContainer = styled(TableContainer)({
  "& .MuiTableCell-root": {
    padding: "10px 16px",
    borderBottom: "1px #DDDDDD dashed",
  },
});

const StyledWrapRepresent = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiChip-root": {
    backgroundColor: "#fec90f",
    borderRadius: 4,
    height: 18,
    fontWeight: 600,
    fontSize: 10,
    "& .MuiChip-label": {
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  "& .MuiTypography-root": {
    fontSize: 14,
    fontWeight: 500,
    marginRight: 8,
  },
});

const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    bottom: 0,
    right: 33,
  },
});

const StyledAvatar = styled(Avatar)({
  width: 50,
  height: 50,
  marginRight: 8,
});

const ItemTableDaily = (props) => {
  const {
    Employees,
    idContractEmployees,
    searchDate,
    setSearchDate,
    handleChangeSearch,
  } = props;
  const { t, i18n } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchName, setSearchName] = React.useState("");
  const [employeesList, setEmployeesList] = React.useState([]);
  const { result: reserveEmployees } = useSelector(
    (state) => state.reserveEmployees
  );
  const { result: employeeContractDaily } = useSelector(
    (state) => state.employeeContractDaily
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [drawerAddOrEditEmployeeSpareContractConfig, setDrawerAddOrEditEmployeeSpareContractConfig] = useState({
    isOpen: false,
    data: {},
  })

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const columns = [
    { id: "name", label: t("FullName"), minWidth: 240 },
    {
      id: "checkIn",
      label: t("ClockIn"),
      align: "center",
    },
    {
      id: "checkout",
      label: t("ClockOut"),
      align: "center",
    },
  ];

  useEffect(() => {
    if (Employees && reserveEmployees) {
      let temp;
      if (reserveEmployees) {
        temp = Employees.concat(reserveEmployees);
      }
      setEmployeesList(temp);
    }
  }, [reserveEmployees]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <StyledBox className={"close"}>
        <div className="wrap-filter">
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} sm={6} md={4}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("SearchDate")}
                </Typography>
                <div className="search-date">
                  {localStorage.getItem("language") === "en" ? (
                    <DatePickerEng
                      value={searchDate}
                      name="searchDate"
                      onChange={(newValue) => {
                        setSearchDate(newValue);
                      }}
                    />
                  ) : (
                    <DatePickerThai
                      value={searchDate}
                      name="searchDate"
                      onChange={(newValue) => {
                        setSearchDate(newValue);
                      }}
                    />
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledBoxSearch className="first">
                <Typography className="label" color="text.third">
                  {t("searchName")}
                </Typography>
                <FormControl fullWidth variant="filled">
                  <StyledFilledInput
                    id="filled-sarch-name"
                    placeholder={t("searchFirstLast")}
                    name="search"
                    onChange={(newValue) => {
                      setSearchName(newValue.target.value);
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div style={{ display: "flex", marginBottom: 8 }}>
                <div style={{ marginRight: 8 }}>
                  <ButtonBlue
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={handleChangeSearch}
                  >
                    {t("Search")}
                  </ButtonBlue>
                </div>
                <div>
                  <ButtonBlue
                    variant="outlined"
                    startIcon={<DownloadRoundedIcon />}
                  >
                    {t("Download")}
                  </ButtonBlue>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="count">
          <div>
            <Typography className="label" color="text.third" variant="body2">
              {t("permanent")}
            </Typography>
            <Typography variant="h4">
              {employeeContractDaily &&
                employeeContractDaily.filter((x) => x.isPermanent === 1).length}
            </Typography>
          </div>
          <div>
            <Typography className="label" color="text.third" variant="body2">
              {t("reserve")}
            </Typography>
            <Typography variant="h4" align="right">
              {employeeContractDaily &&
                employeeContractDaily.filter((x) => x.isReserve === 1).length}
            </Typography>
          </div>
        </div>
        <StyledTableContainer style={{ width: "100%", height: 450 }}>
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledCellHeader
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledCellHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeContractDaily &&
                employeeContractDaily
                  .filter((x) => {
                    if (searchName.length > 0) {
                      return (
                        x.firstname.indexOf(searchName) >= 0 ||
                        x.lastname.indexOf(searchName) >= 0
                      );
                    } else {
                      return x;
                    }
                  })
                  .map((row, index) => {
                    return (
                      <StyledRowContent
                        onClick={() => {
                          if (row.isTemporary === 1) {
                            setDrawerAddOrEditEmployeeSpareContractConfig(prev => ({
                              ...prev,
                              isOpen: true,
                              isEdit: true,
                              data: {
                                firstname: row.firstname,
                                lastname: row.lastname,
                                mobilePhone: row.mobilePhone,
                                startDate: row.startDate,
                                endDate: row.endDate,
                                idEmployeesSpareContract: row.idEmployeesSpareContract,
                                workingDate: dayjs(searchDate).format("YYYY-MM-DD"),
                                idContractEmployees: idContractEmployees,
                              }
                            }))
                          }
                        }}
                        key={`${page}-${index}`}
                      >
                        <StyledCellContent>
                          <div className="fullname">
                            <Typography style={{ width: 45 }} variant="h6">
                              {index + 1}
                            </Typography>
                            <StyledBadge
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                row.isPermanent === 0 ||
                                row.isTemporary === 1 ? (
                                  <StyledWrapRepresent>
                                    <Chip
                                      label={
                                        row.isTemporary === 1
                                          ? "*Spare*"
                                          : "Spare"
                                      }
                                      size="small"
                                    />
                                  </StyledWrapRepresent>
                                ) : null
                              }
                            >
                              <StyledAvatar
                                alt={`${row.firstname}`}
                                src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                              />
                            </StyledBadge>
                            <div>
                              <div className="first-row">
                                <Typography>{`${row.firstname} ${row.lastname}`}</Typography>
                              </div>
                              <Typography variant="body2" color="text.third">
                                {row.telephoneMobile &&
                                  `${row.telephoneMobile.replace(
                                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                                    "$1-$2-$3"
                                  )}`}
                              </Typography>
                            </div>
                          </div>
                        </StyledCellContent>
                        <StyledCellContent>
                          <Box style={{ textAlign: "center" }}>
                            {row.isTemporary === 1 ? (
                              <Fragment>
                                <Typography className="reportTo">{`${row.startTimeText} น.`}</Typography>
                                <Typography
                                  className="reportTo"
                                  color="text.secondary"
                                >
                                  {row.startDateText
                                    ? `${
                                      row.startDateText.split(
                                          " "
                                        )[0]
                                      }`
                                    : "-"}
                                </Typography>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Typography className="reportTo">
                                  {row.attendance && row.attendance.checkIn
                                    ? `${row.attendance.checkIn.time} น.`
                                    : "-"}
                                </Typography>
                                <Typography
                                  className="reportTo"
                                  color="text.secondary"
                                >
                                  {row.attendance && row.attendance.checkIn
                                    ? `${
                                        row.attendance.checkIn.attendanceDateTimeText.split(
                                          " "
                                        )[0]
                                      }`
                                    : "-"}
                                </Typography>
                              </Fragment>
                            )}
                          </Box>
                        </StyledCellContent>
                        <StyledCellContent>
                          <Box style={{ textAlign: "center" }}>
                            {row.isTemporary === 1 ? (
                              <Fragment>
                                <Typography className="reportTo">{`${row.endTimeText} น.`}</Typography>
                                <Typography
                                  className="reportTo"
                                  color="text.secondary"
                                >
                                  {row.endDateText
                                    ? `${
                                      row.endDateText.split(
                                          " "
                                        )[0]
                                      }`
                                    : "-"}
                                </Typography>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Typography className="reportTo">
                                  {row.attendance && row.attendance.checkOut
                                    ? `${row.attendance.checkOut.time} น.`
                                    : "-"}
                                </Typography>
                                <Typography
                                  className="reportTo"
                                  color="text.secondary"
                                >
                                  {row.attendance && row.attendance.checkOut
                                    ? `${
                                        row.attendance.checkOut.attendanceDateTimeText.split(
                                          " "
                                        )[0]
                                      }`
                                    : "-"}
                                </Typography>
                              </Fragment>
                            )}
                          </Box>
                        </StyledCellContent>
                      </StyledRowContent>
                    );
                  })}
            </TableBody>
          </Table>
          <Grid marginTop={4} item xs={12} sm={12} md={12}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div style={{ marginRight: 4 }}>
                <ButtonBlue
                  variant="contained"
                  onClick={() => {
                    setDrawerAddOrEditEmployeeSpareContractConfig(prev => ({
                      ...prev,
                      isOpen: true,
                      isEdit: false,
                      data: {
                        workingDate: dayjs(searchDate).format("YYYY-MM-DD"),
                        idContractEmployees: idContractEmployees,
                      }
                    }))
                  }}
                >
                  {t("AddTemporaryReserveEmployee")}
                </ButtonBlue>
              </div>
            </div>
          </Grid>
        </StyledTableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={employeesList && employeesList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />

        <DialogAddOrEditEmployeeSpareContract
          drawerConfig={drawerAddOrEditEmployeeSpareContractConfig}
          handleClose={() => {
            setDrawerAddOrEditEmployeeSpareContractConfig(prev => ({
              ...prev,
              isOpen: false,
            }))
          }}
        />
      </StyledBox>
    </div>
  );
};

export default ItemTableDaily;
