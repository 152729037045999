import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";

import DialogAddManpower from "./dialogAddManpower";

import StyledCard from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { getDepartment } from "./../../../../actions/department";
import { Box, Grid } from "@mui/material";

import DataTable from "./DataTable";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
  // ["@media (min-width:1200px)"]:{
  //   paddingLeft: 24,
  //   paddingRight: 24
  // }
});

const StyledContent = styled("div")({
  margin: "24px 0",
  "& .filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .group-button": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .MuiPaper-root": {
    display: "flex",
    alignItems: "center",
    padding: 24,
    marginBottom: 24,
    borderRadius: 20,
    "& .part-one": {
      display: "flex",
      alignItems: "center",
      width: 450,
      "& .MuiAvatar-root": {
        marginRight: 16,
      },
      "& .MuiTypography-h5": {
        width: 300,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    "& .part-two": {
      width: 200,
    },
    "& .part-three": {
      flexGrow: 1,
    },
    "& .part-four": {
      width: 250,
    },
    "& .part-five": {
      width: 100,
      display: "flex",
      justifyContent: "space-evenly",
    },
  },
});

const StyledIconButtonUpload = styled(IconButton)({
  color: "#007AFE",
  border: "1px solid #007AFE",
  borderRadius: 8,
  padding: 6,
});

const Input = styled("input")({
  display: "none",
});

const ManpowerPage = (props) => {
  const dispatch = useDispatch();
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openAddManpower, setOpenAddManpower] = React.useState(false);

  useEffect(() => {
    if (departmentList) {
      //count manpower
      {
        /*
    departmentList.forEach((dep) => {
        console.log("dep", dep);
      });
    */
      }
    }
  }, [departmentList]);

  useEffect(() => {
    if (userProfile) {
      dispatch(getDepartment(userProfile.idCompany));
    }
  }, [userProfile]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleCloseAddManpower = () => {
    setOpenAddManpower(false);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          แผนกำลังพล
        </Typography>

        <StyledContent>
          <div className={`filter`}>
            <div className={`group-button`}>
              <div style={{ marginRight: 8 }}>
                <ButtonBlue
                  variant={"outlined"}
                  startIcon={<AddIcon />}
                  component={NavLink}
                  to="#"
                  onClick={() => setOpenAddManpower(true)}
                >
                  เพิ่มแผนใหม่
                </ButtonBlue>
              </div>
              <div>
                <Tooltip title="Upload">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                    />
                    <StyledIconButtonUpload
                      aria-label="delete"
                      size="small"
                      component="span"
                    >
                      <FileUploadIcon fontSize="small" />
                    </StyledIconButtonUpload>
                  </label>
                </Tooltip>
              </div>
            </div>
            <div style={{ width: 280 }}>
              <TextFieldTheme
                placeholder="ค้นหา..."
                onChange={handleChangeSearch}
              />
            </div>
          </div>
        </StyledContent>

        <div style={{ marginTop: "32px", marginBottom: "16px" }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="body1">จำนวนกำลังพลทั้งหมด</Typography>
            </Grid>
            <Grid item>
              <Box>
                <Typography
                  display="inline"
                  sx={{ fontSize: "24px", fontWeight: 600 }}
                >
                  100
                </Typography>
                <Typography variant="body1" display="inline">
                  {` / `}
                </Typography>
                <Typography
                  display="inline"
                  sx={{ fontWeight: 600, color: "#919eab" }}
                >
                  200
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Box>
          {departmentList && <DataTable departmentList={departmentList} />}
        </Box>
        <DialogAddManpower
          open={openAddManpower}
          handleClose={handleCloseAddManpower}
        />
      </Container>
    </StyledRoot>
  );
};

export default ManpowerPage;
