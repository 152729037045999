import dayjs from "dayjs";
import i18n from "../i18n";

function numberWithCommas(x) {
  if (x) {
    var toFixed = x.toFixed(2);
    var parts = toFixed.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else {
    return "0";
  }
}

function numberWithCommasWithoutDot(x) {
  if (x) {
    var toFixed = x.toFixed(2);
    var parts = toFixed.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (parseInt(parts[1]) > 0) {
      return parts.join(".");
    } else {
      return parts[0];
    }
  } else {
    return "0";
  }
}

function insertKey(key, value, obj, pos) {
  return Object.keys(obj).reduce((ac, a, i) => {
    if (i === pos) ac[key] = value;
    ac[a] = obj[a];
    return ac;
  }, {});
}

const getGroupedItems = (item, props) => {
  let returnArray = [];
  let i;
  for (i = 0; i < props.length; i++) {
    returnArray.push(item[props[i]]);
  }
  return returnArray;
};

function groupByMultipleProperties({ Group: array, By: props }) {
  let groups = {};
  let i;

  for (i = 0; i < array.length; i++) {
    const arrayRecord = array[i];
    const group = JSON.stringify(getGroupedItems(arrayRecord, props));
    groups[group] = groups[group] || [];
    groups[group].push(arrayRecord);
  }
  return Object.keys(groups).map((group) => {
    return groups[group];
  });
}

function inputNumberWithCommas(value, previousValue, digit) {
  digit = digit === undefined ? 0 : digit;
  value = value.trim().replace(new RegExp(",", "g"), "");
  if (isNaN(value)) {
    return previousValue;
  }
  if (digit === 0 && value.indexOf(".") !== -1) {
    return previousValue;
  }
  if (value.indexOf("0") === 0) {
    return previousValue;
  }
  if (value.indexOf(".") === -1) {
    return numberWithCommas(value);
  }
  const afterDecimalLength = value.split(".")[1].length;
  if (afterDecimalLength > digit) {
    return previousValue;
  }
  return numberWithCommas(value);
}

function textStringToPhoneformat(value) {
  if (value && value.toString().length === 10) {
    return (
      value.toString().substring(0, 3) +
      "-" +
      value.substring(3, 6) +
      "-" +
      value.substring(6, 10)
    );
  } else {
    return value;
  }
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export const inputGPA = (value, previousValue) => {
  value = value.trim().replace(new RegExp(",", "g"), "");
  if (isNaN(value)) {
    return previousValue;
  }
  if (value.indexOf(".") === -1) {
    if (value.length > 1 || value > 4) return previousValue;
    else return value;
  }
  if (value > 4) {
    return previousValue;
  }
  const afterDecimalLength = value.split(".")[1].length;
  if (afterDecimalLength > 2) {
    return previousValue;
  }
  return value;
};

export const inputTel = (value, previousValue) => {
  value = value.trim().replace(new RegExp(/\D/, "g"), "");
  return value;
};

function parseMonth(n) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[n];
}

const jobSeniority = [
  { id: 1, name: "ฝึกงาน" },
  { id: 2, name: "พนักงานทั่วไป" },
  { id: 3, name: "พนักงานระดับสูง" },
  { id: 4, name: "ผู้จัดการ" },
  { id: 5, name: "ผู้อำนวยการ" },
  { id: 6, name: "ผู้บริหารระดับสูง" },
];

const jobSpecialisation = [
  { id: 1, name: "Business & Operations" },
  { id: 2, name: "Design" },
  { id: 3, name: "DevOps & IT" },
  { id: 4, name: "Marketing" },
  { id: 5, name: "Product Management" },
  { id: 6, name: "Quality Assurance" },
  { id: 7, name: "Sales" },
  { id: 8, name: "Software Engineering" },
  { id: 9, name: "Data Science and Analytics" },
];

export const getOptionSenioritys = () => {
  return [
    { value: 1, label: "ฝึกงาน" },
    { value: 2, label: "พนักงานทั่วไป" },
    { value: 3, label: "พนักงานระดับสูง" },
    { value: 4, label: "ผู้จัดการ" },
    { value: 5, label: "ผู้อำนวยการ" },
    { value: 6, label: "ผู้บริหารระดับสูง" },
  ];
};

export const getLabelSeniority = (value) => {
  return getOptionSenioritys().find((seniority) => seniority.value === value)
    .label;
};

export const getOptionSpecialisations = () => {
  return [
    { value: 1, label: "Business & Operations" },
    { value: 2, label: "Design" },
    { value: 3, label: "DevOps & IT" },
    { value: 4, label: "Marketing" },
    { value: 5, label: "Product Management" },
    { value: 6, label: "Quality Assurance" },
    { value: 7, label: "Sales" },
    { value: 8, label: "Software Engineering" },
    { value: 9, label: "Data Science and Analytics" },
  ];
};

export const getLabelSpecialisation = (value) => {
  return getOptionSpecialisations().find(
    (specialisation) => specialisation.value === value
  ).label;
};

const jobRole = {
  1: [
    { id: 1, name: "Account (Client) Manager" },
    { id: 2, name: "Customer Service" },
    { id: 3, name: "Executive Management" },
    { id: 4, name: "Finance and Accounting" },
    { id: 5, name: "General Management" },
    { id: 6, name: "Human Resource Manager" },
    { id: 7, name: "Office Manager" },
    { id: 8, name: "Operations Manager" },
    { id: 9, name: "Project Manager" },
  ],
  2: [
    { id: 10, name: "Animator" },
    { id: 11, name: "Creative Designer" },
    { id: 12, name: "Game Designer" },
    { id: 13, name: "Graphic Designer" },
    { id: 14, name: "Interactive Designer" },
    { id: 15, name: "Motion Graphic Designer" },
    { id: 16, name: "Multimedia Designer" },
    { id: 17, name: "UI Designer" },
    { id: 18, name: "UX/UI Designer" },
    { id: 19, name: "UX Designer" },
    { id: 20, name: "UX Researcher" },
    { id: 21, name: "Video Editor" },
  ],
  3: [
    { id: 22, name: "Build/Release Engineer" },
    { id: 23, name: "Database Adminstrator" },
    { id: 24, name: "Desktop Support" },
    { id: 25, name: "DevOps Engineer" },
    { id: 26, name: "Hardware Engineer" },
    { id: 27, name: "IT Architecture" },
    { id: 28, name: "IT Audit" },
    { id: 29, name: "IT Infrastructure" },
    { id: 30, name: "IT Manager" },
    { id: 31, name: "Network Adminstrator" },
    { id: 32, name: "Network Engineer" },
    { id: 33, name: "Site Reliability Engineer" },
    { id: 34, name: "Solution Achitect" },
    { id: 35, name: "Sytem Analyst" },
    { id: 36, name: "Sytems Adminstrator" },
  ],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
};

export const getEmploymentType = () => [
  {
    id: 1,
    name: "Full-time",
  },
  {
    id: 2,
    name: "Contract",
  },
  {
    id: 3,
    name: "Internship",
  },
];

export const selectedMultiHandler = (value, name, getValues, setValue) => {
  const result = [...getValues(name)];
  if (result.find((x) => x.id === value.id)) {
    setValue(
      name,
      result.filter((x) => x.id !== value.id)
    );
    // if(getValues("roles").length === 0){
    // 	setError("roles", {
    // 		type: "manual",
    // 		message: "โปรดเลือกอย่างน้อย 1 หน้าที่"
    // 	})
    // }
    return;
  }

  result.push(value);
  // if(result.length > 0){
  // 	clearErrors("roles");
  // }
  setValue(name, result);
};

export const getOptionDegrees = () => [
  { value: 1, label: "ประถมศึกษา" },
  { value: 2, label: "มัธยมศึกษาตอนต้น" },
  { value: 3, label: "มัธยมศึกษาตอนปลาย" },
  { value: 4, label: "ปริญญาตรี" },
  { value: 5, label: "ปริญญาโท" },
  { value: 6, label: "ปริญญาเอก" },
];

export const getLabelDegree = (value) => {
  return getOptionDegrees().find((degree) => degree.value === value).label;
};

export const getOptionYears = () => {
  let years = [];
  let currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= 1900; i--) {
    years.push({
      value: i,
      label: i,
    });
  }
  return years;
};

function getAge(dateString) {
  var now = new Date();
  var today = new Date(now.getYear(), now.getMonth(), now.getDate());

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(
    dateString.substring(6, 10),
    dateString.substring(3, 5) - 1,
    dateString.substring(0, 2)
  );

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  var ageString = "";
  var yearString = localStorage.getItem("language") === "en" ? " years" : " ปี";
  var monthString = localStorage.getItem("language") === "en" ? " months" : " เดือน";
  var dayString = localStorage.getItem("language") === "en" ? " days" : " วัน";

  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
  else {
    yearAge--;
    var monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
  else {
    monthAge--;
    var dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };

  if (age.years > 0 && age.months > 0 && age.days > 0)
    ageString =
      age.years +
      yearString +
      " " +
      age.months +
      monthString +
      " " +
      age.days +
      dayString;
  else if (age.years == 0 && age.months == 0 && age.days > 0)
    ageString = age.days + dayString;
  else if (age.years > 0 && age.months == 0 && age.days == 0)
    ageString = age.years + yearString;
  else if (age.years > 0 && age.months > 0 && age.days == 0)
    ageString = age.years + yearString + " " + age.months + monthString;
  else if (age.years == 0 && age.months > 0 && age.days > 0)
    ageString = age.months + monthString + " " + age.days + dayString;
  else if (age.years > 0 && age.months == 0 && age.days > 0)
    ageString = age.years + yearString + " " + age.days + dayString;
  else if (age.years == 0 && age.months > 0 && age.days == 0)
    ageString = age.months + monthString;
  else ageString = "ไม่สามารถคำนวณอายุได้";

  return ageString;
}

function getAgeMonthYear(dateString) {
  var now = new Date();
  var today = new Date(now.getYear(), now.getMonth(), now.getDate());

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(
    dateString.substring(6, 10),
    dateString.substring(3, 5) - 1,
    dateString.substring(0, 2)
  );

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  var ageString = "";

  var yearString = localStorage.getItem("language") === "en" ? " years" : " ปี";
  var monthString = localStorage.getItem("language") === "en" ? " months" : " เดือน";
  var dayString = localStorage.getItem("language") === "en" ? " days" : " วัน";


  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
  else {
    yearAge--;
    var monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
  else {
    monthAge--;
    var dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };

  if (age.years > 0 && age.months > 0 && age.days > 0)
    ageString = age.years + yearString + " " + age.months + monthString;
  else if (age.years > 0 && age.months == 0 && age.days == 0)
    ageString = age.years + yearString;
  else if (age.years > 0 && age.months > 0 && age.days == 0)
    ageString = age.years + yearString + " " + age.months + monthString;
  else if (age.years == 0 && age.months > 0 && age.days > 0)
    ageString = age.months + monthString + " ";
  else if (age.years > 0 && age.months == 0 && age.days > 0)
    ageString = age.years + yearString + " ";
  else if (age.years == 0 && age.months > 0 && age.days == 0)
    ageString = age.months + monthString;
  else ageString = "ไม่สามารถคำนวณอายุได้";

  return ageString;
}

function getAgeNumber(dateString) {
  var now = new Date();
  var today = new Date(now.getYear(), now.getMonth(), now.getDate());

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(
    dateString.substring(6, 10),
    dateString.substring(3, 5) - 1,
    dateString.substring(0, 2)
  );

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();

  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
  else {
    yearAge--;
    var monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
  else {
    monthAge--;
    var dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  return {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };
}

function getAgeNumberTerminate(dateString, termainateDateString) {
  //var now = dayjs(termainateDateString);
  //var today = new Date(now.getYear(), now.getMonth(), now.getDate());
  var dob = new Date(
    dateString.substring(6, 10),
    dateString.substring(3, 5) - 1,
    dateString.substring(0, 2)
  );
  var now = new Date(
    termainateDateString.substring(6, 10),
    termainateDateString.substring(3, 5) - 1,
    termainateDateString.substring(0, 2)
  );

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();

  var yearAge = yearNow - yearDob;

  if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
  else {
    yearAge--;
    var monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow === dayjs(now).daysInMonth()) {
    monthAge = monthAge + 1;
  }

  // if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
  // else {
  //   monthAge--;
  //   var dateAge = 31 + dateNow - dateDob;

  //   if (monthAge < 0) {
  //     monthAge = 11;
  //     yearAge--;
  //   }
  // }

  return {
    years: yearAge,
    months: monthAge,
    //days: dateAge,
  };
}

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

export const getDateFromDatetime = (datetime) => {
  switch (i18n.language){
    case "th": {
      return dayjs(datetime).locale(i18n.language).format("DD MMM BBBB")
    }
    default: return dayjs(datetime).locale(i18n.language).format("DD MMM YYYY")
  }
}

export const getTimeFromDatetime = (datetime) => {
  switch (i18n.language){
    case "th": {
      return dayjs(datetime).format("HH:mm")
    }
    default: return dayjs(datetime).format("hh:mm A")
  }
}

export const getFullDatetimeFromDatetime = (datetime) => {
  switch (i18n.language){
    case "th": {
      return dayjs(datetime).locale(i18n.language).format("DD MMM BBBB HH:mm")
    }
    default: return dayjs(datetime).locale(i18n.language).format("DD MMM YYYY hh:mm A")
  }
}

export const getRoundNumber = (value, round = 2, isCutDecimal = true) => {
  if(isCutDecimal){
    return String(Math.round(value * Math.pow(10,round)) / (Math.pow(10,round)))
  } else {
    return String(Number(value).toFixed(round))
  }
}

export default {
  roundToTwo,
  numberWithCommas,
  numberWithCommasWithoutDot,
  insertKey,
  groupByMultipleProperties,
  getUniqueListBy,
  groupBy,
  parseMonth,
  getAge,
  getAgeMonthYear,
  getAgeNumber,
  getAgeNumberTerminate,
  jobSeniority,
  jobSpecialisation,
  jobRole,
  inputNumberWithCommas,
  textStringToPhoneformat,
};
