import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Dialog, DialogContent, Divider, Typography } from "@mui/material";

import ButtonBlue from "../../../shared/general/ButtonBlue";

const DialogDelete = (props) => {
  let { open, onClose, onConfirm } = props;
  const { t, i18n } = useTranslation();

  const onClickConfirmHandler = () => {
    onConfirm();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent style={{ width: "320px" }}>
        <Typography variant="h5">
          <i
            className="fa-solid fa-triangle-exclamation"
            style={{ color: "#ff9800", marginRight: "8px" }}
          />
          {t("ConfirmToDelete")}
        </Typography>
        <Divider style={{ margin: "8px 0 16px" }} />
        <Box style={{ paddingLeft: "8px" }}>
          <Typography>{t("DoUWantDelete")}</Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "24px",
            }}
          >
            <ButtonBlue style={{ marginRight: "16px" }} onClick={onClose}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={onClickConfirmHandler}>
              {t("ConfirmDelete")}
            </ButtonBlue>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogDelete;
