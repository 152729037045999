import { Avatar, Card, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { menuAppUser } from "../../assets/data/menu-app";
import { useTranslation } from "react-i18next";

const StyledWrapMenuApp = styled("div")(({}) => ({
  paddingBottom: 80,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .app-list": {
    display: "grid",
    gridTemplateRows: "1fr auto auto",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: 0,
    minWidth: 350,
    columnGap: 8,
    ["@media only screen and (max-width: 600px)"]: {
      gridTemplateRows: "1fr auto auto",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    ["@media only screen and (min-width:600px)"]: {
      gridTemplateRows: "1fr auto auto",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    ["@media only screen and (min-width:768px)"]: {
      gridTemplateRows: "1fr auto auto",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    ["@media only screen and (min-width:992px)"]: {
      gridTemplateRows: "1fr auto auto",
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    ["@media only screen and (min-width:1200px)"]: {
      gridTemplateRows: "1fr auto auto",
      gridTemplateColumns: "repeat(4, 1fr)",
    },

    "& .app-item": {
      marginTop: 24,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const StyledCard = styled(Card)({
  //width: 150,
  boxShadow: "none",
  borderRadius: 16,
  border: "1px solid #919eab3d",
  margin: 8,
  "&.gray": {
    filter: "grayscale(100%)",
  },
  "&.normal": {
    "&:hover": {
      cursor: "pointer",
      transition:
        "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
      transform: "translateY(-5px)",
    },
  },
  "& .inner": {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    "& div": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
    "& .MuiTypography-subtitle": {
      fontSize: 22,
      lineHeight: 1.2,
    },
    "& .MuiTypography-caption": {
      lineHeight: 1.2,
      fontSize: 14,
    },
    "& .MuiAvatar-root": {
      width: 100,
      height: 100,
      marginBottom: 16,
    },
  },
});

const MenuApp = () => {
  const history = useHistory();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    //dispatch(getAllUsersCompany());
  }, []);

  return (
    <Fragment>
      {currentUser && (
        <StyledWrapMenuApp>
          <div className="header">
            <Typography color="text.secondary">{t("ShortcutMenu")}</Typography>
          </div>
          <Grid container spacing={2}>
            {menuAppUser
              .filter((value) => {
                if (currentUser.roles.includes(value.role)) {
                  return value;
                } else {
                  return null;
                }
              })
              .map((value, index) => (
                <Grid item xs={12} sm={6} md={3}>
                  <StyledCard
                    key={index}
                    onClick={() => history.push(value.link)}
                    className={value.class}
                  >
                    <div className="inner">
                      <Avatar variant="rounded" src={value.icon} />
                      <div>
                        <Typography variant="subtitle" gutterBottom>
                          {t(value.name)}
                        </Typography>
                      </div>
                    </div>
                  </StyledCard>
                </Grid>
              ))}
          </Grid>
        </StyledWrapMenuApp>
      )}
    </Fragment>
  );
};

export default MenuApp;
