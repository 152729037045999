import React from "react";
import { Box, styled, Typography } from "@mui/material";

const StyledRoot = styled(Box)({
  "& .title-container": {
    backgroundColor: "#2F74EB",
    padding: 16,
    "& .MuiTypography-root": {
      marginLeft: 8,
      color: "#FFFFFF",
      fontSize: 20,
      fontWeight: 500,
    },
  },
  "& .content-container": {
    padding: "16px",
  }
})

const FormContainer = (props) => {

  const { title, children } = props;
  
  return (
    <StyledRoot>
      <div className="title-container">
        <Typography>{title}</Typography>
      </div>
      <div className="content-container">
        {children}
      </div>
    </StyledRoot>
  )
}

export default FormContainer;