import React from "react";
import { makeStyles } from "@mui/styles";
import { Snackbar, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useTranslation } from "react-i18next";

const AlertCustom = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertResponse = (props) => {
  const { open, handleClose, alertType, label } = props;
  const { t, i18n } = useTranslation();

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      style={{
        "& .MuiPaper-root": {
          borderRadius: "8px !important",
        },
      }}
    >
      <AlertCustom
        onClose={handleClose}
        severity={alertType === "success" ? "success" : "error"}
        style={{ width: "100%" }}
      >
        {alertType !== "success" && alertType !== "error" && (
          <AlertTitle style={{ color: "#FFFFFF" }}>{t("Errors")}</AlertTitle>
        )}
        {label
          ? label
          : alertType === "success"
          ? `${t("Save_data_successfully")}`
          : alertType === "error"
          ? `${t("Please_Contact_Admin")}`
          : alertType}
      </AlertCustom>
    </Snackbar>
  );
};
export default AlertResponse;
