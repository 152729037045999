import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import "./styles.css";
import AddEvent from "./AddEvent";
import InfoEvent from "./InfoEvent";
import { useDispatch, useSelector } from "react-redux";
import {
  allHoliday,
  postHoliday,
  putHoliday,
  deleteHoliday,
} from "../../../../../actions/holiday";
import dayjs from "dayjs";

import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography, Paper } from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import DialogUpload from "./DialogUpload"

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    marginBottom: "50px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  calendar_container: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    borderRadius: "5px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    borderTop: "5px solid #007afe",
  },
}));


function Calendar() {
  const classes = useStyles();
  useTitle("ปฎิทิน - HR CORE DATA");
  const dispatch = useDispatch();
  const { result: holidayStore } = useSelector((state) => state.holiday);
  const [openDialogUpload, setOpenDialogUpload] = useState(false);
  const [events, setEvents] = useState([]);

  const [addModal, setAddModal] = useState({
    isOpen: false,
  });

  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    event: [],
  });

  const toggleDrawerUpload = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDialogUpload(open);
  };

  const handleDateClick = (arg) => {
    setInfoModal({
      ...infoModal,
      isOpen: true,
      event: arg.event,
    });
  };

  const onPostHoliday = async (formData) => {
    await dispatch(postHoliday(formData));

    //fetch data
    let year = new Date().getFullYear();
    dispatch(allHoliday("1", year.toString()));
  };

  const onPutHoliday = async (id, formData) => {
    await dispatch(putHoliday(id, formData));

    //fetch data
    let year = new Date().getFullYear();
    dispatch(allHoliday("1", year.toString()));
  };

  const onDeleteHoliday = async (formData) => {
    await dispatch(deleteHoliday(formData));

    //fetch data
    let year = new Date().getFullYear();
    dispatch(allHoliday("1", year.toString()));
  };

  const fetchData = () => {
    let holiday = [...holidayStore];
    holiday.forEach(function (day) {
      day.id = day.idHoliday;
      day.type = "วันหยุดนักขัตฤกษ์";
      day.title = day.name;
      day.start = dayjs(day.dateHoliday).format("YYYY-MM-DD");
      day.end = dayjs(day.dateHoliday).format("YYYY-MM-DD");
      day.color = "#ffc107";
    });

    setEvents(holiday);
  };

  useEffect(() => {
    if (holidayStore) {
      fetchData();
    }
  }, [holidayStore]);

  useEffect(() => {
    let year = new Date().getFullYear();
    dispatch(allHoliday("1", year.toString()));
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h6">ปฎิทิน</Typography>
        </Grid>
        <Grid item>
        <ButtonBlue
            variant={"contained"}
            style={{ marginRight: 8 }}
            onClick={toggleDrawerUpload(true)}
          >
            อัพโหลดวันหยุด
          </ButtonBlue>
          <ButtonBlue
            variant={"contained"}
            startIcon={<AddIcon />}
            onClick={toggleDrawerUpload(true)}
          >
            เพิ่มวันหยุด
          </ButtonBlue>
        </Grid>
      </Grid>
      <Paper className={classes.calendar_container}>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          events={events}
          eventClick={handleDateClick}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }}
        />
      </Paper>
      <DialogUpload open={openDialogUpload} toggleDrawer={toggleDrawerUpload} />
      <AddEvent
        addModal={addModal}
        setAddModal={setAddModal}
        onPostHoliday={onPostHoliday}
        fetchData={fetchData}
      />
      <InfoEvent
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        onPutHoliday={onPutHoliday}
        onDeleteHoliday={onDeleteHoliday}
        fetchData={fetchData}
      />
    </div>
  );
}

export default Calendar;
