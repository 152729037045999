import * as React from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import StyledCard from "../../../shared/general/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UpdateIcon from "@mui/icons-material/Update";
import { useTranslation } from "react-i18next";

import AccordionShift from "./accordionShift";

const StyledWrapHead = styled("div")({
  padding: 16,
  paddingBottom: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiTypography-h5": {
    marginLeft: 8,
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
});

export default function EventSwitchShift(props) {
  const { idShiftGroup, handleGetEmployeeShiftHistory, handleOpenAlert } = props;
  const { t } = useTranslation();
  const { result : AllShiftGroup } = useSelector(state => state.shift);
  const { result: EmployeeShiftHistory } = useSelector(state => state.employeeShiftHistory);

  const FilterHistory = () => {
    return EmployeeShiftHistory.filter((employee, index) => {
      if(index === 0){
        return true;
      } else {
        if(
          employee.idShift !== EmployeeShiftHistory[index-1].idShift || 
          employee.prevShift !== EmployeeShiftHistory[index-1].prevShift || 
          employee.startDate !== EmployeeShiftHistory[index-1].startDate || 
          employee.endDate !== EmployeeShiftHistory[index-1].endDate
        ){
            return true;
        } else {
          return false;
        }
      }
    });
  };

  return (
    <StyledCard style={{ marginTop:"20px" }}>
      <div>
        <StyledWrapHead>
          <div>
            <Typography
              variant="h5"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <UpdateIcon style={{ fontSize: 36, marginRight: 8 }} />
              {t("ShiftPlan")}
            </Typography>
          </div>
        </StyledWrapHead>
        <div style={{ padding: 16, paddingTop: 8 }}>
          {AllShiftGroup && 
           EmployeeShiftHistory && 
           FilterHistory().length > 0 ? 
            FilterHistory().map((employee, index) => {
              return (
                <AccordionShift 
                  key={`${employee.name}_${employee.lastname}`} 
                  employee={employee} 
                  idShiftGroup={idShiftGroup} 
                  handleGetEmployeeShiftHistory={handleGetEmployeeShiftHistory}
                  handleOpenAlert={handleOpenAlert}
                />
              );
            })
           :
           <Box style={{ width:"100%", height:"100px", display:"flex", justifyContent:"center", alignItems:"center" }}>
            <Typography variant="h6" style={{ color:"grey" }}>
              {t("NoHistoryOfShiftSwitch")}
            </Typography>
           </Box>
          }
        </div>
      </div>
    </StyledCard>
  );
}
