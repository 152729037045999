import { httpClient } from "./httpClient";

const getGroupGpsLocations = (id) => {
  return httpClient.get(`/company/${id}/group-locations`);
};

const getAllLocations = (id) => {
  return httpClient.get(`/company/${id}/locations`);
};

const postGpsLocations = (formData) => {
  console.log(formData)
  return httpClient.post(`/company/${formData.idCompany}/locations`, formData);
};

const postGroupGpsLocations = (formData) => {
  return httpClient.post(`/company/${formData.idCompany}/group-locations`, formData);
};

const updateGpsLocations = (formData) => {
  return httpClient.put(`/company/${formData.idCompany}/locations/${formData.idGpsLocations}`, formData);
};

// const putCompanyAddress = (id, formData) => {
//   return httpClient.put(`/company/${id}/address/${formData.idCompanyAddress}`, formData);
// };

const getGroupGpsLocationsByIdGroupGpsLocation = (id) => {
  return httpClient.get(`/group-locations/${id}`);
};

export default {
  postGroupGpsLocations,
  getGroupGpsLocations,
  getAllLocations,
  postGpsLocations,
  updateGpsLocations,
  getGroupGpsLocationsByIdGroupGpsLocation
};
