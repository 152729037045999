import React, { useEffect, useState } from "react";
import { Container, Grid, styled } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import LeftContainer from "./leftContainer";
import RightContainer from "./rightContainer";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  getVendorProfile,
  getAllBranchesByVendor,
} from "../../../../actions/vendor";
import {
  getAllExpertise,
  getAllExpertiseVendor,
} from "../../../../actions/expertise";
import { getAllServicesVendor } from "../../../../actions/service";
import { getAllBank, getBankByBankDetail } from "../../../../actions/bank";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .headerText": {
    fontSize: 12,
    color: "#9e9e9e",
  },
  "& .valueText": {
    wordBreak: "break-word",
  },
  "& .header-text": {
    fontSize: 20,
  },
  "& .edit-button": {
    borderRadius: 19,
    "& i": {
      fontSize: 12,
      marginRight: 8,
    },
  },
  "& .delete-button": {
    color: "#d32f2f",
    borderColor: "#d32f2f",
    borderRadius: 19,
    "& i": {
      fontSize: 12,
      marginRight: 8,
    },
    "&:hover": {
      borderColor: "#d32f2f",
      borderRadius: 19,
    },
  },
});

const CompanyProfileVendor = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: vendorProfile } = useSelector((state) => state.vendorProfile);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getVendorProfile());
  }, []);

  return (
    <StyledRoot className="page">
      {vendorProfile && (
        <Container maxWidth="lg" style={{ paddingTop: 8, paddingBottom: 20 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <CardStyle>
                <LeftContainer />
              </CardStyle>
            </Grid>
            <Grid item xs={12} lg={8}>
              <CardStyle>
                <RightContainer />
              </CardStyle>
            </Grid>
          </Grid>
        </Container>
      )}
    </StyledRoot>
  );
};

export default CompanyProfileVendor;
