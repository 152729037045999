import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from "react-number-format";

import TextFieldTheme from '../../../../shared/general/TextFieldTheme';

const TextFieldPersonalID = (props) => {
    const { name, checked, control, required, error, helperText, disabled } = props;

    return (
      <Controller 
        name={name}
        control={control}
        rules={{
          required: required
        }}
        render={({ field: { ref, ...field } }) => {
          return(
            <NumberFormat
              {...field}
              getInputRef={ref}
              customInput={TextFieldTheme}
              format="#-####-#####-##-#"
              placeholder="เลขบัตรประชาชน"
              variant="outlined"
              disabled={(checked && !disabled) ? false : true}
              fullWidth
              error={error}
              helperText={helperText}
            />
        )}}
      />
    );
};

export default TextFieldPersonalID;