import React, { useState } from "react";
import useTable from "../../../../shared/table/useTable";

import {
  CircularProgress,
  IconButton,
	TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

const headCells = [
	{id: "branchCode", label: "โค้ดสาขา"},
	{id: "branchName", label: "ชื่อสาขา"},
	{id: "employee", label: "จำนวนพนักงาน"},
	{id: "contact", label: "ผู้ติดต่อ"},
	{id: "contactEmail", label: "อีเมล"},
	{id: "actions", label: ""}
]

const DataTable = (props) => {
	const { branches, isLoading, addOrEditModal, setAddOrEditModal, disabledEdit } = props;

	const [filterFn, setFilterFn] = useState({
		fn: (items) => {
			return items;
		},
	});

	const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } = 
		useTable(branches, headCells, filterFn);

	return(
		<div>
      <div style={{overflowX: "auto"}}>
        <TblContainer>
          <TblHead />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              (recordsAfterPagingAndSorting().length > 0 &&
                recordsAfterPagingAndSorting().map((item) => (
                  <TableRow key={item.branchCode}>
                    <TableCell style={{minWidth: 100, maxWidth: 100, textOverflow: "ellipsis", overflow: "hidden", wordBreak: "break-word"}}>{item.branchCode}</TableCell>
                    <TableCell style={{minWidth: 200, maxWidth: 200, textOverflow: "ellipsis", overflow: "hidden", wordBreak: "break-word"}}>{item.branchName}</TableCell>
                    {/* <TableCell style={{minWidth: 200, maxWidth: 200, textOverflow: "ellipsis", overflow: "hidden", wordBreak: "break-word"}}>{item.company}</TableCell> */}
                    <TableCell style={{minWidth: 80, maxWidth: 80, textOverflow: "ellipsis", overflow: "hidden", wordBreak: "break-word"}}>{item.employee}</TableCell>
                    <TableCell style={{minWidth: 200, maxWidth: 200, textOverflow: "ellipsis", overflow: "hidden", wordBreak: "break-word", whiteSpace: "pre-line"}}>{`${item.mainContactName}\n${item.mainContactPhone}`}</TableCell>
                    <TableCell style={{minWidth: 200, maxWidth: 200, textOverflow: "ellipsis", overflow: "hidden", wordBreak: "break-word", whiteSpace: "pre-line"}}>{item.mainContactEmail}</TableCell>
                    <TableCell align="right">
                      {!disabledEdit && <>
                        <IconButton
                          onClick={() => {
                            setAddOrEditModal({
                              ...addOrEditModal,
                              isOpen: true,
                              branch: item,
                            });
                          }}
                        >
                          <EditIcon
                            sx={{ color: "#9e9e9e" }}
                          />
                        </IconButton>
                        <IconButton>
                          <DeleteForeverIcon sx={{ color: "#e53935" }} />
                        </IconButton>
                      </>}
                    </TableCell>
                  </TableRow>
                ))) || (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No Data
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </TblContainer>
      </div>
			<TblPagination />
		</div>
	)
}

export default DataTable;