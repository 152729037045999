import React from "react";
import { Box, Dialog, DialogActions, DialogContent, Typography, styled } from "@mui/material";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useTranslation } from "react-i18next";
import { getDateFromDatetime, getFullDatetimeFromDatetime } from "../../../../utils";
import ButtonBlue from "../general/ButtonBlue";

const StyledDialog = styled(Dialog)({
  "& .MuiDialogContent-root": {
    padding: 0,
    "& .content": {
      padding: 16,
      textAlign: "center",
      "& .delete-icon-label": {
        fontSize: 90,
        color: "#f15e5e",
      },
      "& .header-label": {
        padding: "20px 0",
      },
      "& .detail": {
        fontSize: 16,
        "& span": {
          color: "#f15e5e",
          fontSize: 20,
        },
      },
    },
  }
})

const StyledRow = styled(Box)({
  marginBottom: 8,
});

const DialogWithdrawRequestLeave = (props) => {

  const { dialogConfig, handleClose, handleSubmit } = props;

  const { t, i18n } = useTranslation();

  return (
    <StyledDialog
      fullWidth
      maxWidth={"xs"}
      open={dialogConfig.isOpen}
      onClose={handleClose}
      sx={{padding: 0}}
    >
      <DialogContent>
        <Box sx={{ width: "100%", height: 6, backgroundColor: "#f15e5e" }}/>
        <div className="content">
          <HighlightOffRoundedIcon className="delete-icon-label" />
          <Typography variant="h5" className="header-label" color="text.third">
            {t("AreYouSure")}?
          </Typography>
          <StyledRow>
            <Typography variant="body2" color="text.secondary">
              {t("LeaveType")}
            </Typography>
            <Typography variant="h6">{dialogConfig.data.leaveGroupName}</Typography>
          </StyledRow>
          <StyledRow>
            <Typography variant="body2" color="text.secondary">
              {t("StartDate")}
            </Typography>
            <Typography variant="h6">
              {dialogConfig.data.isFullDay === 1? getDateFromDatetime(dialogConfig.data.start): getFullDatetimeFromDatetime(dialogConfig.data.start)}
            </Typography>
          </StyledRow>
          <StyledRow>
            <Typography variant="body2" color="text.secondary">
              {t("EndDate")}
            </Typography>
            <Typography variant="h6">
              {dialogConfig.data.isFullDay === 1? getDateFromDatetime(dialogConfig.data.end): getFullDatetimeFromDatetime(dialogConfig.data.end)}
            </Typography>
          </StyledRow>
        </div>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flexGrow="1">
          <ButtonBlue onClick={handleClose} variant="text" color="secondary">
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            onClick={handleSubmit}
            autoFocus
            variant={"contained"}
            color="error"
          >
            {t("Confirm")}
          </ButtonBlue>
        </Box>
      </DialogActions>
    </StyledDialog>
  )
}

export default DialogWithdrawRequestLeave