const employees = [
  {
    scG_Employee_ID: "0100-000118",
    name_title: "นาย",
    firstname: "สมฤกษ์",
    lastname: "นิยมธรรมรัตน์",
    name_Title_English: "Mr.",
    firstname_English: "Somrerk",
    lastname_English: "Niyomthammarat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "ผู้จัดการแผนกปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",
    pL_Group: "S4",
    birthDay: "May 10 1969 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038912191",
    email: "somrerkn@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผจส.อาชีวอนามัยความปลอดภัยและสิ่งแวดล้อม",
    report_Email: "sittharl@scg.com",
    nick_Name: "ฤกษ์",
    mobileNumber: "0812345678",
    login: "somrerkn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900920",

    name_title: "นาง",
    firstname: "ขวัญเรือน",
    lastname: "ศรีหาบุตร",
    name_Title_English: "Mrs.",
    firstname_English: "Kwanruen",
    lastname_English: "Srihabut",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HR Business Partner",
    departmentId: 13,
    departmentShortName: "HR",
    position: "เจ้าหน้าที่แรงงานสัมพันธ์",
    pL_Group: "O0",
    birthDay: "Apr 25 1969 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "kwanrues@scg.com",
    report_To: "Mr. Puttinun Moungprasert",
    report_to_Position_Name: "Manager-HR Business Partner",
    report_Email: "puttinum@scg.com",
    nick_Name: "ขวัญ",
    mobileNumber: "0812345678",
    login: "kwanrues",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000745",

    name_title: "นาย",
    firstname: "ศักดิ์ชัย",
    lastname: "หุมสิน",
    name_Title_English: "Mr.",
    firstname_English: "Sakchai",
    lastname_English: "Humsin",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "หัวหน้างานผลิต Catalyst",
    pL_Group: "O5",
    birthDay: "May  3 1980 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sakchahu@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "โอ",
    mobileNumber: "0812345678",
    login: "sakchahu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000838",

    name_title: "นาย",
    firstname: "ชวนากร",
    lastname: "สุวรรณชล",
    name_Title_English: "Mr.",
    firstname_English: "Chavanakorn",
    lastname_English: "Suwanchon",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงาน Logistics Support",
    pL_Group: "O4",
    birthDay: "Mar 10 1971 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chavanas@scg.com",
    report_To: "Mrs. Rujiporn Keepthong",
    report_to_Position_Name: "ผจผ.Logistics Support",
    report_Email: "rujipork@scg.com",
    nick_Name: "นัท",
    mobileNumber: "0812345678",
    login: "chavanas",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000235",

    name_title: "นาย",
    firstname: "ชัยยศ",
    lastname: "รัตนคุณ",
    name_Title_English: "Mr.",
    firstname_English: "Chaiyod",
    lastname_English: "Rattanakun",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "ผู้จัดการแผนกผลิต LDPE",
    pL_Group: "S4",
    birthDay: "Oct 12 1969 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chaiyodr@scg.com",
    report_To: "Mr. Pat Nartpochananon",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LDPE",
    report_Email: "patn@scg.com",
    nick_Name: "ติ๊บ",
    mobileNumber: "0812345678",
    login: "chaiyodr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001549",
    name_title: "นาย",
    firstname: "นพพล",
    lastname: "มังกรานนท์ชัย",
    name_Title_English: "Mr.",
    firstname_English: "Noppon",
    lastname_English: "Monggranonchai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "ผู้จัดการส่วนผลิต PP 1,2",
    pL_Group: "M2",
    birthDay: "Aug 17 1980 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nopponm@scg.com",
    report_To: "Mr. Nipat Lumlertluksanachai",
    report_to_Position_Name: "ผู้จัดการฝ่ายผลิต PP",
    report_Email: "nipatl@scg.com",
    nick_Name: "บัส",
    mobileNumber: "0812345678",
    login: "nopponm",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000121",

    name_title: "นาย",
    firstname: "อัครวัฒน์",
    lastname: "เนื่องแก้วสิน",
    name_Title_English: "Mr.",
    firstname_English: "Akkhrawat",
    lastname_English: "Nuangkaewsin",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    pL_Group: "S4",
    birthDay: "Mar 12 1969 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "akkhrawn@scg.com",
    report_To: "Mrs. Siripun Poorinutpong",
    report_to_Position_Name: "Product Property Analytic & Control Mgr.",
    report_Email: "siripunp@scg.com",
    nick_Name: "ซื่อ",
    mobileNumber: "0812345678",
    login: "akkhrawn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000732",
    name_title: "นาย",
    firstname: "มงคล",
    lastname: "เหิมหัก",
    name_Title_English: "Mr.",
    firstname_English: "Mongkol",
    lastname_English: "Hermhak",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "หัวหน้างานผลิต LLDPE",
    pL_Group: "O5",
    birthDay: "Jan 31 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "mongkohe@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "ป๋อง",
    mobileNumber: "0812345678",
    login: "mongkohe",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000829",

    name_title: "นาย",
    firstname: "นัครินทร์",
    lastname: "พิทักษ์ก่อผล",
    name_Title_English: "Mr.",
    firstname_English: "Nakarin",
    lastname_English: "Pitakkorphol",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "ผู้จัดการแผนกปฏิบัติการคลังสินค้า 1",
    pL_Group: "S2",
    birthDay: "May 12 1973 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nakarinp@scg.com",
    report_To: "Mr. Nattapol Pongpaothai",
    report_to_Position_Name: "ผู้จัดการ Logistics Operation",
    report_Email: "nattpong@scg.com",
    nick_Name: "กุ๋ย",
    mobileNumber: "0812345678",
    login: "nakarinp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000852",

    name_title: "นาย",
    firstname: "อานนท์",
    lastname: "อินสะวังโส",
    name_Title_English: "Mr.",
    firstname_English: "Arnon",
    lastname_English: "Insawangso",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "นักวิเคราะห์ระบบ Logistics",
    pL_Group: "S1",
    birthDay: "Dec 10 1982 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "arnins@scg.com",
    report_To: "Mr. Nattapol Pongpaothai",
    report_to_Position_Name: "ผู้จัดการ Logistics Operation",
    report_Email: "nattpong@scg.com",
    nick_Name: "โอ๊ต",
    mobileNumber: "0812345678",
    login: "arnins",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000833",

    name_title: "นางสาว",
    firstname: "ดวงกมล",
    lastname: "เรืองดี",
    name_Title_English: "Miss",
    firstname_English: "Duangkamon",
    lastname_English: "Ruangdee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "นักวิเคราะห์ระบบ Logistics",
    pL_Group: "S2",
    birthDay: "Jan 12 1976 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038912560",
    email: "dungkamr@scg.com",
    report_To: "Mr. Banjerd Waksoongnoen",
    report_to_Position_Name: "ผู้จัดการ Outbound Logistics",
    report_Email: "banjerdw@scg.com",
    nick_Name: "จาก",
    mobileNumber: "0812345678",
    login: "dungkamr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000851",

    name_title: "นาย",
    firstname: "วชิระ",
    lastname: "สมงาม",
    name_Title_English: "Mr.",
    firstname_English: "Washira",
    lastname_English: "Somngam",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "หัวหน้างานปฏิบัติการคลังสินค้า",
    pL_Group: "O5",
    birthDay: "Oct 11 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "washiras@scg.com",
    report_To: "Mr. Nakarin Pitakkorphol",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการคลังสินค้า 1",
    report_Email: "nakarinp@scg.com",
    nick_Name: "หนึ่ง",
    mobileNumber: "0812345678",
    login: "washiras",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000996",

    name_title: "นาย",
    firstname: "ณัฐพล",
    lastname: "พงศ์เผ่าไทย",
    name_Title_English: "Mr.",
    firstname_English: "Nattapol",
    lastname_English: "Pongpaothai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "ผู้จัดการ Logistics Operation",
    pL_Group: "S4",
    birthDay: "Oct  9 1979 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038912347",
    email: "nattpong@scg.com",
    report_To: "Mr. Kulapong Boonyaves",
    report_to_Position_Name: "ผู้จัดการส่วน Logistics",
    report_Email: "kulaponb@scg.com",
    nick_Name: "แชมป์",
    mobileNumber: "0812345678",
    login: "nattpong",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000425",

    name_title: "นาย",
    firstname: "อำนาจ",
    lastname: "แต่งตั้ง",
    name_Title_English: "Mr.",
    firstname_English: "Amnaj",
    lastname_English: "Taengtung",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "วิศวกรผลิต HDPE 1",
    pL_Group: "S1",
    birthDay: "Oct 26 1974 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "amnajt@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต HDPE 1",
    report_Email: "sittharl@scg.com",
    nick_Name: "นาจ",
    mobileNumber: "0812345678",
    login: "amnajt",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000013",

    name_title: "นาย",
    firstname: "ไกรสิทธิ์",
    lastname: "วรรณรัตต์",
    name_Title_English: "Mr.",
    firstname_English: "Kraisit",
    lastname_English: "Wannaratt",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "ผู้จัดการแผนกปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",
    pL_Group: "S4",
    birthDay: "Feb 19 1963 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kraisitw@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผจส.อาชีวอนามัยความปลอดภัยและสิ่งแวดล้อม",
    report_Email: "sittharl@scg.com",
    nick_Name: "ต๋อง",
    mobileNumber: "0812345678",
    login: "kraisitw",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000052",
    name_title: "นาย",
    firstname: "บรรเจิด",
    lastname: "เวกสูงเนิน",
    name_Title_English: "Mr.",
    firstname_English: "Banjerd",
    lastname_English: "Waksoongnoen",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "ผู้จัดการ Outbound Logistics",
    pL_Group: "M1",
    birthDay: "Nov 24 1967 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "banjerdw@scg.com",
    report_To: "Mr. Kulapong Boonyaves",
    report_to_Position_Name: "ผู้จัดการส่วน Logistics",
    report_Email: "kulaponb@scg.com",
    nick_Name: "เจิด",
    mobileNumber: "0812345678",
    login: "banjerdw",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000446",

    name_title: "นาย",
    firstname: "สัมฤทธิ์",
    lastname: "คำสงค์",
    name_Title_English: "Mr.",
    firstname_English: "Samrit",
    lastname_English: "Comsong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "หัวหน้างานผลิต LDPE",
    pL_Group: "O5",
    birthDay: "Oct  1 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "samrico@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "สัมฤทธิ์",
    mobileNumber: "0812345678",
    login: "samrico",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000715",

    name_title: "นาย",
    firstname: "พิชิต",
    lastname: "นุชจังหรีด",
    name_Title_English: "Mr.",
    firstname_English: "Pichit",
    lastname_English: "Nundjhngreed",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O5",
    birthDay: "Apr 10 1980 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pichitnu@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "ซี",
    mobileNumber: "0812345678",
    login: "pichitnu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000964",

    name_title: "นาย",
    firstname: "กิตติศักดิ์",
    lastname: "ปาลสาร",
    name_Title_English: "Mr.",
    firstname_English: "Kittisak",
    lastname_English: "Palasarn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O4",
    birthDay: "Jul  1 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kittisap@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "เอ้",
    mobileNumber: "0812345678",
    login: "kittisap",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000940",
    name_title: "นาย",
    firstname: "สุนทร",
    lastname: "พลเมือง",
    name_Title_English: "Mr.",
    firstname_English: "Soontorn",
    lastname_English: "Ponmuang",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O5",
    birthDay: "Aug  9 1984 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "soontorp@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "ท๊อป",
    mobileNumber: "0812345678",
    login: "soontorp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000576",
    name_title: "นาย",
    firstname: "พรชัย",
    lastname: "บุญคงแก้ว",
    name_Title_English: "Mr.",
    firstname_English: "Pornchai",
    lastname_English: "Boonkhongkaew",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานปฏิบัติการความปลอดภัย",
    pL_Group: "O5",
    birthDay: "Mar 19 1976 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pornboon@scg.com",
    report_To: "Mr. Kraisit Wannaratt",
    report_to_Position_Name: "ผจผ.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",
    report_Email: "kraisitw@scg.com",
    nick_Name: "ชัย",
    mobileNumber: "0866005908",
    login: "pornboon",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000531",
    name_title: "นาย",
    firstname: "ไพโรจน์",
    lastname: "โถชาลี",
    name_Title_English: "Mr.",
    firstname_English: "Pairot",
    lastname_English: "Thochalee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "LLDPE Production Operator",
    pL_Group: "O4",
    birthDay: "Jun  3 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pairoth@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "อ้วน",
    mobileNumber: "0819405881",
    login: "pairoth",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000311",

    name_title: "นาย",
    firstname: "ประวิทย์",
    lastname: "จิตประสงค์",
    name_Title_English: "Mr.",
    firstname_English: "Prawit",
    lastname_English: "Jitprasong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรปฏิบัติการความปลอดภัย",
    pL_Group: "S1",
    birthDay: "Oct 20 1974 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "prawjit@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "วิทย์",
    mobileNumber: "0812345678",
    login: "prawjit",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000604",

    name_title: "นาย",
    firstname: "สุเทพ",
    lastname: "โสธนะ",
    name_Title_English: "Mr.",
    firstname_English: "Suthep",
    lastname_English: "Sotana",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "หัวหน้างานผลิต LLDPE",
    pL_Group: "O5",
    birthDay: "May 26 1977 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sutheso@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "เปิ้ล",
    mobileNumber: "0812345678",
    login: "sutheso",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000731",

    name_title: "นาย",
    firstname: "เกรียงศักดิ์",
    lastname: "ยิ้มเจริญ",
    name_Title_English: "Mr.",
    firstname_English: "Kriengsak",
    lastname_English: "Yimcharoen",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O5",
    birthDay: "Dec 30 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kriengsy@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "กอล์ฟ",
    mobileNumber: "0812345678",
    login: "kriengsy",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000680",

    name_title: "นาย",
    firstname: "เฉลิมชาติ",
    lastname: "สร้างการนอก",
    name_Title_English: "Mr.",
    firstname_English: "Chalermchad",
    lastname_English: "Srangkarnnok",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "หัวหน้างานผลิต Catalyst",
    pL_Group: "O5",
    birthDay: "Aug 12 1979 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chalesra@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "จุ้ย",
    mobileNumber: "0812345678",
    login: "chalesra",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000716",

    name_title: "นาย",
    firstname: "พลธนัฐ",
    lastname: "สดศรี",
    name_Title_English: "Mr.",
    firstname_English: "Ponthanat",
    lastname_English: "Sodsri",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "หัวหน้างานผลิต LLDPE",
    pL_Group: "O5",
    birthDay: "Jul  4 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "ponthans@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "บอล",
    mobileNumber: "0812345678",
    login: "ponthans",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000289",

    name_title: "นาย",
    firstname: "เอกชัย",
    lastname: "วิโรจน์กิจไพบูลย์",
    name_Title_English: "Mr.",
    firstname_English: "Akkachai",
    lastname_English: "Wirotkitphaiboon",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "วิศวกรผลิต LLDPE",
    pL_Group: "S2",
    birthDay: "May 22 1973 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "akkachwi@scg.com",
    report_To: "Mr. Sophon Kiatpradab",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LLDPE",
    report_Email: "sophoki@scg.com",
    nick_Name: "เอก",
    mobileNumber: "0812345678",
    login: "akkachwi",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000638",

    name_title: "นาย",
    firstname: "จีรศักดิ์",
    lastname: "ประสพสุข",
    name_Title_English: "Mr.",
    firstname_English: "Jeerasak",
    lastname_English: "Prasopsuk",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "หัวหน้างานผลิต Catalyst",
    pL_Group: "O5",
    birthDay: "Mar 23 1979 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jeerasap@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "บอย",
    mobileNumber: "0812345678",
    login: "jeerasap",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000730",

    name_title: "นาย",
    firstname: "คมกฤช",
    lastname: "มานนท์",
    name_Title_English: "Mr.",
    firstname_English: "Khomkrit",
    lastname_English: "Manon",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O5",
    birthDay: "Sep 21 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "khomkrim@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "เอ็กซ์",
    mobileNumber: "0812345678",
    login: "khomkrim",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000533",

    name_title: "นาย",
    firstname: "มนตรี",
    lastname: "ทำเนียม",
    name_Title_English: "Mr.",
    firstname_English: "Montree",
    lastname_English: "Thamniam",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรสิ่งแวดล้อม",
    pL_Group: "S2",
    birthDay: "Feb 23 1976 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "montreth@scg.com",
    report_To: "Miss Sasiphen Laimai",
    report_to_Position_Name: "ผู้จัดการแผนกสิ่งแวดล้อม",
    report_Email: "sasiphel@scg.com",
    nick_Name: "จ๊อก",
    mobileNumber: "0812345678",
    login: "montreth",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000477",

    name_title: "นาย",
    firstname: "พนอ",
    lastname: "ขวัญพิชัย",
    name_Title_English: "Mr.",
    firstname_English: "Phanor",
    lastname_English: "Khawnphichai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "หัวหน้างานผลิต LLDPE",
    pL_Group: "O5",
    birthDay: "Mar  6 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "phanork@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "นอ",
    mobileNumber: "0812345678",
    login: "phanork",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000626",

    name_title: "นาย",
    firstname: "ภิภพ",
    lastname: "ฤกษ์เวียง",
    name_Title_English: "Mr.",
    firstname_English: "Piphop",
    lastname_English: "Reokwieng",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "วิศวกรผลิต Catalyst",
    pL_Group: "S1",
    birthDay: "May 20 1978 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393-7#2420",
    email: "piphopr@scg.com",
    report_To: "Mr. Pakorn Aumyoo",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต Catalyst & Pilot Plant",
    report_Email: "pakorau@scg.com",
    nick_Name: "ภพ",
    mobileNumber: "0812345678",
    login: "piphopr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000570",

    name_title: "นาย",
    firstname: "รักศักดิ์",
    lastname: "บุญต่อ",
    name_Title_English: "Mr.",
    firstname_English: "Raksak",
    lastname_English: "Boontor",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O5",
    birthDay: "Jun  9 1976 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "raksakb@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "เอ็ด",
    mobileNumber: "0812345678",
    login: "raksakb",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000574",
    name_title: "นาย",
    firstname: "รังสรรค์",
    lastname: "คงเกษม",
    name_Title_English: "Mr.",
    firstname_English: "Rungson",
    lastname_English: "Kongkasam",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "วิศวกรผลิต Catalyst",
    pL_Group: "S1",
    birthDay: "Feb 29 1976 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393#2422",
    email: "rungsonk@scg.com",
    report_To: "Mr. Pakorn Aumyoo",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต Catalyst & Pilot Plant",
    report_Email: "pakorau@scg.com",
    nick_Name: "แสบ",
    mobileNumber: "0812345678",
    login: "rungsonk",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000704",
    name_title: "นาย",
    firstname: "เสรีย์",
    lastname: "เก่งกิจกรณ์",
    name_Title_English: "Mr.",
    firstname_English: "Saree",
    lastname_English: "Kangkijkorn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O5",
    birthDay: "Feb 28 1980 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sareek@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "เส",
    mobileNumber: "0812345678",
    login: "sareek",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000092",
    name_title: "นาย",
    firstname: "สมัย",
    lastname: "มหาวัน",
    name_Title_English: "Mr.",
    firstname_English: "Smai",
    lastname_English: "Mahawan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "Packing Operator",
    pL_Group: "O5",
    birthDay: "Jul 25 1968 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "smama@scg.com",
    report_To: "Mr. Adul Bunprakob",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "adubu@scg.com",
    nick_Name: "หมัย",
    mobileNumber: "0851230859",
    login: "smama",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000174",
    name_title: "นาย",
    firstname: "สมชาย",
    lastname: "เวทวงษ์",
    name_Title_English: "Mr.",
    firstname_English: "Somchai",
    lastname_English: "Wetthawong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "ผู้จัดการแผนกผลิต LLDPE",
    pL_Group: "S4",
    birthDay: "Feb 27 1971 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "somchawe@scg.com",
    report_To: "Mr. Sophon Kiatpradab",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LLDPE",
    report_Email: "sophoki@scg.com",
    nick_Name: "ไมเคิล",
    mobileNumber: "0812345678",
    login: "somchawe",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000213",
    name_title: "นาย",
    firstname: "วิชิต",
    lastname: "เบียดนอก",
    name_Title_English: "Mr.",
    firstname_English: "Wichit",
    lastname_English: "Biadnork",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรปฏิบัติการความปลอดภัย",
    pL_Group: "S2",
    birthDay: "Jun  8 1973 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "wichitb@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "ชิต",
    mobileNumber: "0812345678",
    login: "wichitb",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001026",
    name_title: "นาย",
    firstname: "กุลพงษ์",
    lastname: "บุณยะเวศ",
    name_Title_English: "Mr.",
    firstname_English: "Kulapong",
    lastname_English: "Boonyaves",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "ผู้จัดการส่วน Logistics",
    pL_Group: "M1",
    birthDay: "Feb 26 1982 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kulaponb@scg.com",
    report_To: "Mr. Preeda Vatchratiensakul",
    report_to_Position_Name: "กรรมการผู้จัดการ ทีพีอี",
    report_Email: "preedav@scg.com",
    nick_Name: "โป้ง",
    mobileNumber: "0812345678",
    login: "kulaponb",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001118",
    name_title: "นาย",
    firstname: "วีรชน",
    lastname: "ยะสิทธิ์",
    name_Title_English: "Mr.",
    firstname_English: "Weerachon",
    lastname_English: "Yasit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O4",
    birthDay: "Oct 29 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393#2320",
    email: "weeracya@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "ดอย",
    mobileNumber: "0812345678",
    login: "weeracya",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001119",
    name_title: "นาย",
    firstname: "นิพนธ์",
    lastname: "หลงศรีภูมิ",
    name_Title_English: "Mr.",
    firstname_English: "Nipon",
    lastname_English: "Longsriphoom",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O4",
    birthDay: "Mar 25 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "niplon@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "พนธ์",
    mobileNumber: "0812345678",
    login: "niplon",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001120",
    name_title: "นาย",
    firstname: "ภาณุพงศ์",
    lastname: "ศรีสุวรรณ",
    name_Title_English: "Mr.",
    firstname_English: "Panupong",
    lastname_English: "Srisuwan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O4",
    birthDay: "Jul 25 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "panupsri@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "หนุ่ม",
    mobileNumber: "0812345678",
    login: "panupsri",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001141",

    name_title: "นาย",
    firstname: "ธาดาพงษ์",
    lastname: "ตอนรัมย์",
    name_Title_English: "Mr.",
    firstname_English: "Tadapong",
    lastname_English: "Tonrum",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O4",
    birthDay: "Nov 12 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "tadapont@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "พงษ์",
    mobileNumber: "0812345678",
    login: "tadapont",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000102",
    name_title: "นาย",
    firstname: "ยงยุทธ",
    lastname: "ติระกุล",
    name_Title_English: "Mr.",
    firstname_English: "Yongyut",
    lastname_English: "Tirakul",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรปฏิบัติการความปลอดภัย",
    pL_Group: "S3",
    birthDay: "Nov  9 1963 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "yongyuti@scg.com",
    report_To: "Mr. Kraisit Wannaratt",
    report_to_Position_Name: "ผจผ.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",
    report_Email: "kraisitw@scg.com",
    nick_Name: "ยุทธ",
    mobileNumber: "0896956267",
    login: "yongyuti",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900914",
    name_title: "นางสาว",
    firstname: "อรุณรัตน์",
    lastname: "นาคเรืองศรี",
    name_Title_English: "Miss",
    firstname_English: "Arunrat",
    lastname_English: "Nakruangsree",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "ผู้ช่วยวิศวกรอาชีวอนามัย ความปลอดภัย",
    pL_Group: "O0",
    birthDay: "Aug 18 1980 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "arunratn@scg.com",
    report_To: "Miss Chuenjit Sukpan",
    report_to_Position_Name: "ผจก.แผนกระบบอาชีวอนามัยและความปลอดภัย",
    report_Email: "chuenjis@scg.com",
    nick_Name: "อุ๊",
    mobileNumber: "0812345678",
    login: "arunratn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900905",

    name_title: "นาย",
    firstname: "ชูพันธ์",
    lastname: "พงษ์พันธ์",
    name_Title_English: "Mr.",
    firstname_English: "Chuphan",
    lastname_English: "Pongphan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "หัวหน้างานควบคุมระบบรักษาความปลอดภัย",
    pL_Group: "O0",
    birthDay: "Jun 18 1972 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "chuphanp@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "อ๊อด",
    mobileNumber: "0812345678",
    login: "chuphanp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001316",
    name_title: "นาย",
    firstname: "ศิริพงษ์",
    lastname: "พวงสุดรักษ์",
    name_Title_English: "Mr.",
    firstname_English: "Siripong",
    lastname_English: "Pwongsudrak",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "Emergency & Fire Supervisor",
    pL_Group: "S1",
    birthDay: "Oct 22 1964 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "siripopw@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "เป็ด",
    mobileNumber: "0812345678",
    login: "siripopw",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900908",
    name_title: "นาย",
    firstname: "สังคม",
    lastname: "ตีบน้อยดี",
    name_Title_English: "Mr.",
    firstname_English: "Sangkhom",
    lastname_English: "Teebnoidee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "ผู้บัญชาการดับเพลิง",
    pL_Group: "O0",
    birthDay: "May  1 1978 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "sangkhot@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "น้อย",
    mobileNumber: "0812345678",
    login: "sangkhot",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900906",
    name_title: "นาย",
    firstname: "บุญช่วย",
    lastname: "บุตรดา",
    name_Title_English: "Mr.",
    firstname_English: "Boonchiay",
    lastname_English: "Butda",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "ผู้บัญชาการดับเพลิง",
    pL_Group: "O0",
    birthDay: "May 12 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "boonchib@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "ช่วย",
    mobileNumber: "0812345678",
    login: "boonchib",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001434",
    name_title: "นางสาว",
    firstname: "ศศิเพ็ญ",
    lastname: "ลายไม้",
    name_Title_English: "Miss",
    firstname_English: "Sasiphen",
    lastname_English: "Laimai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "ผู้จัดการแผนกสิ่งแวดล้อม",
    pL_Group: "S4",
    birthDay: "Jan  4 1985 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038912459",
    email: "sasiphel@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผจส.อาชีวอนามัยความปลอดภัยและสิ่งแวดล้อม",
    report_Email: "sittharl@scg.com",
    nick_Name: "เพ็ญ",
    mobileNumber: "0812345678",
    login: "sasiphel",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000561",

    name_title: "นาย",
    firstname: "อานุภาพ",
    lastname: "สมเอ็ดสละ",
    name_Title_English: "Mr.",
    firstname_English: "Arnuparp",
    lastname_English: "Somedsara",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - Support",
    pL_Group: "O5",
    birthDay: "Nov  9 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "arnupars@scg.com",
    report_To: "Mrs. Siripun Poorinutpong",
    report_to_Position_Name: "ผจก.Quality Control Standardization & Su",
    report_Email: "siripunp@scg.com",
    nick_Name: "ยา",
    mobileNumber: "0812345678",
    login: "arnupars",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000553",
    name_title: "นาย",
    firstname: "จีรยุทธ",
    lastname: "ธรรมคงทอง",
    name_Title_English: "Mr.",
    firstname_English: "Chirayut",
    lastname_English: "Thammakhongthong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "Quality Control Supervisor - PCL",
    pL_Group: "S1",
    birthDay: "Aug 17 1976 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chirayut@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "ยุทธ",
    mobileNumber: "0818643909",
    login: "chirayut",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000321",
    name_title: "นาย",
    firstname: "กิติศักดิ์",
    lastname: "เมืองเจริญ",
    name_Title_English: "Mr.",
    firstname_English: "Kitisak",
    lastname_English: "Muangchalern",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "Quality Control Supervisor - PCL",
    pL_Group: "S1",
    birthDay: "Dec  2 1974 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kitisakm@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "เก่ง",
    mobileNumber: "0812345678",
    login: "kitisakm",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000058",
    name_title: "นาย",
    firstname: "ปณิธาน",
    lastname: "ปฏิพัทธ์พงศ์",
    name_Title_English: "Mr.",
    firstname_English: "Panitan",
    lastname_English: "Patipathpong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "Quality Control Technician - PCL",
    pL_Group: "O5",
    birthDay: "Sep  5 1969 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "panitanp@scg.com",
    report_To: "Mrs. Siripun Poorinutpong",
    report_to_Position_Name: "Product Property Analytic & Control Mgr.",
    report_Email: "siripunp@scg.com",
    nick_Name: "มอส",
    mobileNumber: "0865361624",
    login: "panitanp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "",
  },
  {
    scG_Employee_ID: "0100-000554",
    name_title: "นางสาว",
    firstname: "อุรพี",
    lastname: "ใจเสมอ",
    name_Title_English: "Miss",
    firstname_English: "Urapee",
    lastname_English: "Chaisamer",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - Support",
    pL_Group: "O5",
    birthDay: "Jan 10 1976 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "urapeec@scg.com",
    report_To: "Mrs. Siripun Poorinutpong",
    report_to_Position_Name: "ผจก.Quality Control Standardization & Su",
    report_Email: "siripunp@scg.com",
    nick_Name: "เอ้",
    mobileNumber: "0812345678",
    login: "urapeec",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001003",
    name_title: "นางสาว",
    firstname: "ศิริน",
    lastname: "เมธาคุปต์",
    name_Title_English: "Miss",
    firstname_English: "Sirin",
    lastname_English: "Methakhup",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "ผู้จัดการประกันคุณภาพ",
    pL_Group: "M1",
    birthDay: "Nov 11 1981 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sirinm@scg.com",
    report_To: "Miss Prapipit Kitchanachai",
    report_to_Position_Name: "ผู้จัดการส่วนประกันและควบคุมคุณภาพ",
    report_Email: "prapipik@scg.com",
    nick_Name: "หยก",
    mobileNumber: "0812345678",
    login: "sirinm",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001035",
    name_title: "นางสาว",
    firstname: "ศริญญา",
    lastname: "เหลืองสุขฤกษ์",
    name_Title_English: "Miss",
    firstname_English: "Sarinya",
    lastname_English: "Luangsukrerk",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "ผู้จัดการแผนกประกันคุณภาพ PE",
    pL_Group: "S4",
    birthDay: "Apr 19 1982 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sarinyal@scg.com",
    report_To: "Miss Sirin Methakhup",
    report_to_Position_Name: "Quality Assurance Mgr.",
    report_Email: "sirinm@scg.com",
    nick_Name: "ส้ม",
    mobileNumber: "0812345678",
    login: "sarinyal",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001101",
    name_title: "นาย",
    firstname: "เทิดเกียรติ",
    lastname: "อินนันชัย",
    name_Title_English: "Mr.",
    firstname_English: "Terdkait",
    lastname_English: "Innanchai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O4",
    birthDay: "Mar 23 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "terdkaii@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "เค",
    mobileNumber: "0812345678",
    login: "terdkaii",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001102",
    name_title: "นาย",
    firstname: "อมฤต",
    lastname: "จันต๊ะ",
    name_Title_English: "Mr.",
    firstname_English: "Ummarit",
    lastname_English: "Janta",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O4",
    birthDay: "Nov 29 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "ummaritj@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "เอ็ม",
    mobileNumber: "0812345678",
    login: "ummaritj",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001105",
    name_title: "นาย",
    firstname: "ประกอบเกียรติ",
    lastname: "บำรุงพงษ์",
    name_Title_English: "Mr.",
    firstname_English: "Prakobkiate",
    lastname_English: "Bumrungpong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O4",
    birthDay: "May 18 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "prakobkb@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "นิว",
    mobileNumber: "0812345678",
    login: "prakobkb",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001816",
    name_title: "นาย",
    firstname: "ปกรณ์",
    lastname: "อุ่มอยู่",
    name_Title_English: "Mr.",
    firstname_English: "Pakorn",
    lastname_English: "Aumyoo",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "ผู้จัดการส่วนผลิต Catalyst & Pilot Plant",
    pL_Group: "M1",
    birthDay: "Feb 28 1977 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pakorau@scg.com",
    report_To: "Mr. Kraisid Wongnark",
    report_to_Position_Name: "ผู้จัดการฝ่ายผลิต PE",
    report_Email: "krisidw@scg.com",
    nick_Name: "ป๊อบ",
    mobileNumber: "0812345678",
    login: "pakorau",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000864",
    name_title: "นาย",
    firstname: "รพินทร์",
    lastname: "ลอยฟ้า",
    name_Title_English: "Mr.",
    firstname_English: "Rapin",
    lastname_English: "Loyfa",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการ Packing",
    pL_Group: "O4",
    birthDay: "Nov  4 1982 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "rapinl@scg.com",
    report_To: "Mr. Supoht Farkum",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "supohtf@scg.com",
    nick_Name: "ป๊อก",
    mobileNumber: "0812345678",
    login: "rapinl",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000740",
    name_title: "นาย",
    firstname: "ประสพชัย",
    lastname: "เกิดเนตร",
    name_Title_English: "Mr.",
    firstname_English: "Prasopchai",
    lastname_English: "Kerdnate",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Pipe Compounding Department",
    departmentId: 9,
    departmentShortName: "Pipe Compounding",
    position: "หัวหน้างานผลิต Pipe Compounding",
    pL_Group: "S1",
    birthDay: "Jun  6 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "prasopck@scg.com",
    report_To: "Mr. Suwat Sotana",
    report_to_Position_Name: "ผจผ.ผลิต Pipe Compounding",
    report_Email: "suwaso@scg.com",
    nick_Name: "เจน",
    mobileNumber: "0812345678",
    login: "prasopck",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000962",
    name_title: "นาย",
    firstname: "สุริยา",
    lastname: "ทาวรมย์",
    name_Title_English: "Mr.",
    firstname_English: "Suriya",
    lastname_English: "Thawarom",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Pipe Compounding Department",
    departmentId: 9,
    departmentShortName: "Pipe Compounding",
    position: "Pipe Compounding Production Operator",
    pL_Group: "O4",
    birthDay: "Jan 14 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "suriyat@scg.com",
    report_To: "Mr. Suwat Sotana",
    report_to_Position_Name: "ผจผ.ผลิต Pipe Compounding",
    report_Email: "suwaso@scg.com",
    nick_Name: "เบบี้",
    mobileNumber: "0899327164",
    login: "suriyat",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000836",
    name_title: "นาย",
    firstname: "อำนาจ",
    lastname: "ศรีแสง",
    name_Title_English: "Mr.",
    firstname_English: "Amnart",
    lastname_English: "Srisaeng",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "Packing Operator",
    pL_Group: "O4",
    birthDay: "Feb  7 1971 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "amnarsr@scg.com",
    report_To: "Mr. Adul Bunprakob",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "adubu@scg.com",
    nick_Name: "อ้น",
    mobileNumber: "0856755552",
    login: "amnarsr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000855",
    name_title: "นาย",
    firstname: "มาโนช",
    lastname: "แช่มฉิ่ง",
    name_Title_English: "Mr.",
    firstname_English: "Manoch",
    lastname_English: "Chamching",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการ Packing",
    pL_Group: "O4",
    birthDay: "Apr 19 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "0830428048",
    email: "manocha@scg.com",
    report_To: "Mr. Supoht Farkum",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "supohtf@scg.com",
    nick_Name: "โนช",
    mobileNumber: "0812345678",
    login: "manocha",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000840",
    name_title: "นาย",
    firstname: "ประสิทธิ์",
    lastname: "แสงกล้า",
    name_Title_English: "Mr.",
    firstname_English: "Prasit",
    lastname_English: "Saengkla",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "หัวหน้างานปฏิบัติการ Packing",
    pL_Group: "O5",
    birthDay: "Feb 12 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "prassae@scg.com",
    report_To: "Mr. Supoht Farkum",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "supohtf@scg.com",
    nick_Name: "อาท",
    mobileNumber: "0812345678",
    login: "prassae",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000854",
    name_title: "นาย",
    firstname: "สิทธิโชค",
    lastname: "ไทยสวัสดิ์",
    name_Title_English: "Mr.",
    firstname_English: "Sittichokh",
    lastname_English: "Thaisawat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการ Packing",
    pL_Group: "O4",
    birthDay: "Nov 30 1973 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sittthai@scg.com",
    report_To: "Mr. Supoht Farkum",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "supohtf@scg.com",
    nick_Name: "ตุ้ย",
    mobileNumber: "0812345678",
    login: "sittthai",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000857",
    name_title: "นาย",
    firstname: "ทวีชาติ",
    lastname: "สายบัวมงคล",
    name_Title_English: "Mr.",
    firstname_English: "Thaweechart",
    lastname_English: "Saibuamonkol",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "หัวหน้างานปฏิบัติการ Packing",
    pL_Group: "O5",
    birthDay: "Apr 29 1978 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thaweecs@scg.com",
    report_To: "Mr. Supoht Farkum",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "supohtf@scg.com",
    nick_Name: "วิทย์",
    mobileNumber: "0812345678",
    login: "thaweecs",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000853",
    name_title: "นาย",
    firstname: "ยอดธง",
    lastname: "แสนโสภาวัน",
    name_Title_English: "Mr.",
    firstname_English: "Yodthong",
    lastname_English: "Sansopawan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการ Packing",
    pL_Group: "O4",
    birthDay: "May 18 1965 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "Bagging 1 Site 1",
    telephone_Business: "",
    email: "yodthons@scg.com",
    report_To: "Mr. Adul Bunprakob",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "adubu@scg.com",
    nick_Name: "ยอด",
    mobileNumber: "0866039360",
    login: "yodthons",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000960",
    name_title: "นาย",
    firstname: "ชูชาติ",
    lastname: "รัตนเพ็ชร",
    name_Title_English: "Mr.",
    firstname_English: "Chuchart",
    lastname_English: "Rattanapet",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Pipe Compounding Department",
    departmentId: 9,
    departmentShortName: "Pipe Compounding",
    position: "พนักงานผลิต Pipe Compounding",
    pL_Group: "O4",
    birthDay: "Mar 13 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chuchara@scg.com",
    report_To: "Mr. Suwat Sotana",
    report_to_Position_Name: "ผจผ.ผลิต Pipe Compounding",
    report_Email: "suwaso@scg.com",
    nick_Name: "ชาติ",
    mobileNumber: "0812345678",
    login: "chuchara",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000205",
    name_title: "นาย",
    firstname: "ประเสริฐ",
    lastname: "ปานดวง",
    name_Title_English: "Mr.",
    firstname_English: "Prasert",
    lastname_English: "Panduang",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "วิศวกรผลิต PP 1,2",
    pL_Group: "S2",
    birthDay: "May  2 1973 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "praserpa@scg.com",
    report_To: "Mr. Noppon Monggranonchai",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 1,2",
    report_Email: "nopponm@scg.com",
    nick_Name: "เสริฐ",
    mobileNumber: "0812345678",
    login: "praserpa",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000999",
    name_title: "นาย",
    firstname: "เตชวิทย์",
    lastname: "สุกุล",
    name_Title_English: "Mr.",
    firstname_English: "Techawit",
    lastname_English: "Sukul",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการจัดส่ง",
    pL_Group: "O4",
    birthDay: "Nov 18 1980 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "techawis@scg.com",
    report_To: "Mr. Pornprasit Wannasomroeng",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการจัดส่ง",
    report_Email: "pornpraw@scg.com",
    nick_Name: "วิทย์",
    mobileNumber: "0812345678",
    login: "techawis",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000246",
    name_title: "นาย",
    firstname: "สุชิน",
    lastname: "รอดแก้ว",
    name_Title_English: "Mr.",
    firstname_English: "Suchin",
    lastname_English: "Rodkaeo",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "หัวหน้างานผลิต PP 1,2",
    pL_Group: "O5",
    birthDay: "Mar 14 1969 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "suchiro@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "ชิน",
    mobileNumber: "0850635966",
    login: "suchiro",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000445",
    name_title: "นาย",
    firstname: "ยอดไผ่",
    lastname: "ศรรักษ์",
    name_Title_English: "Mr.",
    firstname_English: "Yodphai",
    lastname_English: "Sornruk",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "หัวหน้างานผลิต PP 1",
    pL_Group: "O5",
    birthDay: "Apr 28 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "yodphais@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "ยอด",
    mobileNumber: "0812345678",
    login: "yodphais",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000392",
    name_title: "นาย",
    firstname: "ปราโมชน์",
    lastname: "โพธิ์หนองใฮ",
    name_Title_English: "Mr.",
    firstname_English: "Pramoch",
    lastname_English: "Pononghai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "Security Operation Operator",
    pL_Group: "O5",
    birthDay: "Jan  9 1972 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pramochp@scg.com",
    report_To: "Mr. Kraisit Wannaratt",
    report_to_Position_Name: "ผจผ.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",
    report_Email: "kraisitw@scg.com",
    nick_Name: "เขียวสด",
    mobileNumber: "0813778963",
    login: "pramochp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000267",
    name_title: "นาย",
    firstname: "สมหมาย",
    lastname: "กลิ่นอุบล",
    name_Title_English: "Mr.",
    firstname_English: "Sommai",
    lastname_English: "Klin-ubol",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "หัวหน้างานผลิต PP 1,2",
    pL_Group: "S1",
    birthDay: "Mar  8 1972 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sommakl@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "หน่อง",
    mobileNumber: "0812345678",
    login: "sommakl",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000468",
    name_title: "นาย",
    firstname: "นิโรจน์",
    lastname: "เข็มพรมมา",
    name_Title_English: "Mr.",
    firstname_English: "Nirote",
    lastname_English: "Khempromma",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "เจ้าหน้าที่ความปลอดภัย",
    pL_Group: "O5",
    birthDay: "Jul  4 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nirotek@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "โจ",
    mobileNumber: "0812345678",
    login: "nirotek",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000394",
    name_title: "นาย",
    firstname: "ไพโรจน์",
    lastname: "มหาศรัทธา",
    name_Title_English: "Mr.",
    firstname_English: "Pairoj",
    lastname_English: "Mahasattra",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "หัวหน้างานผลิต PP 2",
    pL_Group: "O5",
    birthDay: "Feb 23 1974 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pairojm@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "เหน่ง",
    mobileNumber: "0812345678",
    login: "pairojm",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000428",
    name_title: "นาย",
    firstname: "คนอง",
    lastname: "กองอรินทร์",
    name_Title_English: "Mr.",
    firstname_English: "Khanong",
    lastname_English: "Kongarin",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "PP 1 Production Foreman",
    pL_Group: "O5",
    birthDay: "Nov  6 1974 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "khanonko@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "นอง",
    mobileNumber: "0804220088",
    login: "khanonko",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000176",
    name_title: "นาย",
    firstname: "นิรันดร์",
    lastname: "ยินดีเจริญ",
    name_Title_English: "Mr.",
    firstname_English: "Nirun",
    lastname_English: "Yindeecharoen",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "ผู้จัดการแผนกผลิต PP 1,2",
    pL_Group: "S3",
    birthDay: "May  8 1971 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038912332",
    email: "niruyi@scg.com",
    report_To: "Mr. Noppon Monggranonchai",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 1,2",
    report_Email: "nopponm@scg.com",
    nick_Name: "อ๊อด",
    mobileNumber: "0812345678",
    login: "niruyi",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000263",
    name_title: "นาย",
    firstname: "สำเภา",
    lastname: "ประจักษ์",
    name_Title_English: "Mr.",
    firstname_English: "Sampao",
    lastname_English: "Prajak",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "วิศวกรผลิต PP 1,2",
    pL_Group: "S2",
    birthDay: "Jan 11 1973 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sampaop@scg.com",
    report_To: "Mr. Noppon Monggranonchai",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 1,2",
    report_Email: "nopponm@scg.com",
    nick_Name: "พิน",
    mobileNumber: "0812345678",
    login: "sampaop",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001132",
    name_title: "นาย",
    firstname: "จิรณัฐ",
    lastname: "ชูด้วง",
    name_Title_English: "Mr.",
    firstname_English: "Jiranat",
    lastname_English: "Chooduang",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O4",
    birthDay: "Dec 15 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jiranatc@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "โบ้ด",
    mobileNumber: "0812345678",
    login: "jiranatc",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001133",
    name_title: "นาย",
    firstname: "ไววิทย์",
    lastname: "ใจมอย",
    name_Title_English: "Mr.",
    firstname_English: "Waiwit",
    lastname_English: "Jaimoy",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O4",
    birthDay: "Feb 11 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "waiwitj@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "วิทย์",
    mobileNumber: "0812345678",
    login: "waiwitj",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001134",
    name_title: "นาย",
    firstname: "พร",
    lastname: "ทองเรือง",
    name_Title_English: "Mr.",
    firstname_English: "Porn",
    lastname_English: "Tongruang",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O4",
    birthDay: "Sep 13 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pornt@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "พร",
    mobileNumber: "0812345678",
    login: "pornt",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001136",
    name_title: "นาย",
    firstname: "พรทวี",
    lastname: "ศรีดี",
    name_Title_English: "Mr.",
    firstname_English: "Porntawee",
    lastname_English: "Sridee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O5",
    birthDay: "Dec  7 1983 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "porntaws@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "ปุ๊ก",
    mobileNumber: "0812345678",
    login: "porntaws",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001196",
    name_title: "นาย",
    firstname: "สุรชาติ",
    lastname: "มั่นคง",
    name_Title_English: "Mr.",
    firstname_English: "Surachat",
    lastname_English: "Monkong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O5",
    birthDay: "Jan 11 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "suracmon@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ต่อ",
    mobileNumber: "0812345678",
    login: "suracmon",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001197",
    name_title: "นาย",
    firstname: "ชัยมงคล",
    lastname: "นามสวัสดิ์",
    name_Title_English: "Mr.",
    firstname_English: "Chaimongkol",
    lastname_English: "Namsawat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O4",
    birthDay: "Jan  5 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chaimonn@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "หอม",
    mobileNumber: "0812345678",
    login: "chaimonn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900947",
    name_title: "นางสาว",
    firstname: "นฤดี",
    lastname: "จิยะวรนันท์",
    name_Title_English: "Miss",
    firstname_English: "Naruedee",
    lastname_English: "Jiyaworanan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "เจ้าหน้าที่ Data Management",
    pL_Group: "O0",
    birthDay: "Jan 11 1977 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "0625398696",
    email: "naruedej@scg.com",
    report_To: "Mr. Puttinun Moungprasert",
    report_to_Position_Name: "Manager-HR Business Partner",
    report_Email: "puttinum@scg.com",
    nick_Name: "อุ๋ย",
    mobileNumber: "0812345678",
    login: "naruedej",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000990",
    name_title: "นาง",
    firstname: "รุจิพร",
    lastname: "กีปทอง",
    name_Title_English: "Mrs.",
    firstname_English: "Rujiporn",
    lastname_English: "Keepthong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "ผู้จัดการแผนก Logistics Support",
    pL_Group: "S4",
    birthDay: "Oct 25 1972 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "rujipork@scg.com",
    report_To: "Mr. Banjerd Waksoongnoen",
    report_to_Position_Name: "ผู้จัดการ Outbound Logistics",
    report_Email: "banjerdw@scg.com",
    nick_Name: "ส้ม",
    mobileNumber: "0812345678",
    login: "rujipork",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000152",
    name_title: "นาย",
    firstname: "พิชิต",
    lastname: "รอดประสิทธิ์",
    name_Title_English: "Mr.",
    firstname_English: "Pichit",
    lastname_English: "Rodprasit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "ผู้จัดการแผนกผลิต HDPE 1",
    pL_Group: "S4",
    birthDay: "Dec 19 1970 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pichitr@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต HDPE 1",
    report_Email: "sittharl@scg.com",
    nick_Name: "ชิต",
    mobileNumber: "0812345678",
    login: "pichitr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000998",
    name_title: "นาย",
    firstname: "พละศักดิ์",
    lastname: "สีหะวงษ์",
    name_Title_English: "Mr.",
    firstname_English: "Palasak",
    lastname_English: "Sihawong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "LDPE Production Operator",
    pL_Group: "O4",
    birthDay: "Jan 26 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "palasaks@scg.com",
    report_To: "Mr. Jirasak Tripin",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "jirasakt@scg.com",
    nick_Name: "นิว",
    mobileNumber: "0854931700",
    login: "palasaks",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000997",
    name_title: "นาย",
    firstname: "วิสันติ",
    lastname: "อินทะวาส",
    name_Title_English: "Mr.",
    firstname_English: "Wisanti",
    lastname_English: "Inthavas",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O5",
    birthDay: "May  3 1980 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "wisantii@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "เบ้",
    mobileNumber: "0812345678",
    login: "wisantii",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000495",
    name_title: "นาย",
    firstname: "ชูชาติ",
    lastname: "เพิ่มพระคลัง",
    name_Title_English: "Mr.",
    firstname_English: "Chuchart",
    lastname_English: "Permpraklung",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "เจ้าหน้าที่ความปลอดภัย",
    pL_Group: "O5",
    birthDay: "Apr  5 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038937410",
    email: "chuchape@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "เขียด",
    mobileNumber: "0812345678",
    login: "chuchape",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000061",
    name_title: "นาย",
    firstname: "เฉลิมศักดิ์",
    lastname: "สมพรชัยกิจ",
    name_Title_English: "Mr.",
    firstname_English: "Chalermsak",
    lastname_English: "Sompornchaiyakit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานควบคุมคุณภาพ Roto Compounding",
    pL_Group: "O4",
    birthDay: "Aug 25 1968 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chalesom@scg.com",
    report_To: "Mr. Wanich Pomsuk",
    report_to_Position_Name: "ผจก.แผนกควบคุมคุณภาพ Roto Compounding",
    report_Email: "wanichp@scg.com",
    nick_Name: "หนุ่ม",
    mobileNumber: "0812345678",
    login: "chalesom",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001194",
    name_title: "นางสาว",
    firstname: "สุชาดา",
    lastname: "มโนวรกุล",
    name_Title_English: "Miss",
    firstname_English: "Suchada",
    lastname_English: "Manoworakul",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงาน Logistics Support",
    pL_Group: "O4",
    birthDay: "Apr  2 1979 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "suchadam@scg.com",
    report_To: "Mrs. Rujiporn Keepthong",
    report_to_Position_Name: "ผจผ.Logistics Support",
    report_Email: "rujipork@scg.com",
    nick_Name: "เปิ้ล",
    mobileNumber: "0812345678",
    login: "suchadam",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000459",
    name_title: "นาย",
    firstname: "อุเทน",
    lastname: "กุลรัตน์",
    name_Title_English: "Mr.",
    firstname_English: "Utain",
    lastname_English: "Kulrat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "วิศวกรผลิต Catalyst",
    pL_Group: "S2",
    birthDay: "Jun  9 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "utaiku@scg.com",
    report_To: "Mr. Pakorn Aumyoo",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต Catalyst & Pilot Plant",
    report_Email: "pakorau@scg.com",
    nick_Name: "บอล",
    mobileNumber: "0812345678",
    login: "utaiku",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000469",
    name_title: "นาย",
    firstname: "วินัย",
    lastname: "ฐานแพร่",
    name_Title_English: "Mr.",
    firstname_English: "Winai",
    lastname_English: "Thanprae",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "ผู้จัดการแผนกผลิต Catalyst",
    pL_Group: "S3",
    birthDay: "Nov 20 1974 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "winath@scg.com",
    report_To: "Mr. Pakorn Aumyoo",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต Catalyst & Pilot Plant",
    report_Email: "pakorau@scg.com",
    nick_Name: "นัย",
    mobileNumber: "0812345678",
    login: "winath",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001203",
    name_title: "นาย",
    firstname: "ธนวรรธน์",
    lastname: "ปรากฎชื่อ",
    name_Title_English: "Mr.",
    firstname_English: "Thanawat",
    lastname_English: "Prakodchue",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O4",
    birthDay: "Feb 18 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chiriyap@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ต้น",
    mobileNumber: "0911168566",
    login: "chiriyap",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001204",
    name_title: "นาย",
    firstname: "ก้องพิสิษฐ์",
    lastname: "นรรัตน์",
    name_Title_English: "Mr.",
    firstname_English: "Kongpisit",
    lastname_English: "Norarat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O4",
    birthDay: "Aug 16 1984 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "palangno@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ป๋อง",
    mobileNumber: "0812345678",
    login: "palangno",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000277",
    name_title: "นาย",
    firstname: "พัฒนา",
    lastname: "เจริญศึกษา",
    name_Title_English: "Mr.",
    firstname_English: "Pattana",
    lastname_English: "Charuensugsa",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "HDPE 1 Production Foreman",
    pL_Group: "O5",
    birthDay: "Mar  3 1973 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pattchar@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "พัฒ",
    mobileNumber: "0806304067",
    login: "pattchar",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001150",
    name_title: "นาย",
    firstname: "กวินทัต",
    lastname: "เดชารัมย์",
    name_Title_English: "Mr.",
    firstname_English: "Kawintat",
    lastname_English: "Dacharam",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O4",
    birthDay: "Sep 16 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kawintad@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "น้อย",
    mobileNumber: "0812345678",
    login: "kawintad",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000276",
    name_title: "นาย",
    firstname: "ไชยยา",
    lastname: "สายสมบัติ",
    name_Title_English: "Mr.",
    firstname_English: "Chaiya",
    lastname_English: "Saisombat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "วิศวกรผลิต LLDPE",
    pL_Group: "S3",
    birthDay: "Nov 26 1973 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chaisai@scg.com",
    report_To: "Mr. Sophon Kiatpradab",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LLDPE",
    report_Email: "sophoki@scg.com",
    nick_Name: "ยา",
    mobileNumber: "0812345678",
    login: "chaisai",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001019",
    name_title: "นาย",
    firstname: "ชัยยุทธ",
    lastname: "ทามา",
    name_Title_English: "Mr.",
    firstname_English: "Chaiyut",
    lastname_English: "Tama",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O4",
    birthDay: "Mar 16 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chaiyuta@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "ยุทธ",
    mobileNumber: "0812345678",
    login: "chaiyuta",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000879",
    name_title: "นาย",
    firstname: "พีระพัฒน์",
    lastname: "ศรีประกอบฤกษ์",
    name_Title_English: "Mr.",
    firstname_English: "Perapat",
    lastname_English: "Sriprakobreok",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "หัวหน้างานผลิต HDPE 1",
    pL_Group: "O5",
    birthDay: "Jan 29 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "perapasr@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "โต๊ด",
    mobileNumber: "0812345678",
    login: "perapasr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001043",
    name_title: "นาย",
    firstname: "พสิษฐ์",
    lastname: "ชุมภูใบ",
    name_Title_English: "Mr.",
    firstname_English: "Pasitt",
    lastname_English: "Chumpoobai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O4",
    birthDay: "Sep 11 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pasitchu@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "แขก",
    mobileNumber: "0812345678",
    login: "pasitchu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000442",
    name_title: "นาย",
    firstname: "นรเสฏฐ์",
    lastname: "มั่นเขตวิทย์",
    name_Title_English: "Mr.",
    firstname_English: "Norased",
    lastname_English: "Mankatvit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "หัวหน้างานผลิต HDPE 1",
    pL_Group: "O5",
    birthDay: "May  5 1976 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "norasaem@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "เป็ด",
    mobileNumber: "0812345678",
    login: "norasaem",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001123",
    name_title: "นาย",
    firstname: "อนุพงษ์",
    lastname: "ระเมิด",
    name_Title_English: "Mr.",
    firstname_English: "Anupong",
    lastname_English: "Ramoed",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O5",
    birthDay: "Mar 20 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "anuponra@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ตั้ม",
    mobileNumber: "0812345678",
    login: "anuponra",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001122",
    name_title: "นาย",
    firstname: "เอกพล",
    lastname: "สายอ้าย",
    name_Title_English: "Mr.",
    firstname_English: "Eakapon",
    lastname_English: "Saiai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "LLDPE Production Operator",
    pL_Group: "O4",
    birthDay: "Jul 18 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "eakapons@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "เอก",
    mobileNumber: "0873042646",
    login: "eakapons",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "",
  },
  {
    scG_Employee_ID: "0100-001121",
    name_title: "นาย",
    firstname: "จิรพงษ์",
    lastname: "ใจศิล",
    name_Title_English: "Mr.",
    firstname_English: "Jirapong",
    lastname_English: "Jaisin",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O4",
    birthDay: "Sep 27 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jiraponj@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "เอี่ยว",
    mobileNumber: "0812345678",
    login: "jiraponj",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001124",
    name_title: "นาย",
    firstname: "ภัทร",
    lastname: "เอียบสกุล",
    name_Title_English: "Mr.",
    firstname_English: "Patara",
    lastname_English: "Iabsakul",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O4",
    birthDay: "Nov 10 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "patarai@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "เจมส์",
    mobileNumber: "0812345678",
    login: "patarai",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001060",
    name_title: "นาย",
    firstname: "ธาริน",
    lastname: "เวชสุทัด",
    name_Title_English: "Mr.",
    firstname_English: "Tharin",
    lastname_English: "Vechsutad",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O4",
    birthDay: "Mar  5 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "tharinv@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "อ๊อฟ",
    mobileNumber: "0812345678",
    login: "tharinv",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000335",
    name_title: "นาย",
    firstname: "ฉัตรชัย",
    lastname: "โมลารักษ์",
    name_Title_English: "Mr.",
    firstname_English: "Chatchai",
    lastname_English: "Morarak",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "หัวหน้างานผลิต LDPE",
    pL_Group: "O5",
    birthDay: "Dec 23 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chatchmo@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "ฉัตร",
    mobileNumber: "0812345678",
    login: "chatchmo",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000637",
    name_title: "นาย",
    firstname: "เชิดศักดิ์",
    lastname: "ทามิล",
    name_Title_English: "Mr.",
    firstname_English: "Cherdsak",
    lastname_English: "Thamil",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O5",
    birthDay: "Aug 14 1979 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "cherdsth@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "เชิด",
    mobileNumber: "0812345678",
    login: "cherdsth",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000780",
    name_title: "นาย",
    firstname: "ชูเกียรติ",
    lastname: "วิเศษชาติ",
    name_Title_English: "Mr.",
    firstname_English: "Chookeat",
    lastname_English: "Wisetchat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O5",
    birthDay: "Jun  1 1982 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chookeaw@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "โล",
    mobileNumber: "0812345678",
    login: "chookeaw",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001024",
    name_title: "นาย",
    firstname: "จักรพันธ์",
    lastname: "อนุภาพอุดม",
    name_Title_English: "Mr.",
    firstname_English: "Jakapan",
    lastname_English: "Anupap-udom",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O4",
    birthDay: "Dec 16 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jakapana@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "อ๊อฟ",
    mobileNumber: "0812345678",
    login: "jakapana",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000913",
    name_title: "นาย",
    firstname: "กันตพงศ์",
    lastname: "กมลวิริยโภคิน",
    name_Title_English: "Mr.",
    firstname_English: "Kantapong",
    lastname_English: "Kamonwiriyapokin",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O4",
    birthDay: "Apr  2 1984 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kantapka@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "เนย์",
    mobileNumber: "0812345678",
    login: "kantapka",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000744",
    name_title: "นาย",
    firstname: "นรินทร์",
    lastname: "ศรีเมือง",
    name_Title_English: "Mr.",
    firstname_English: "Narin",
    lastname_English: "Srimuang",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O5",
    birthDay: "May 16 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "narsri@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "เก๋",
    mobileNumber: "0812345678",
    login: "narsri",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000362",
    name_title: "นาย",
    firstname: "สราวุธ",
    lastname: "หวังดี",
    name_Title_English: "Mr.",
    firstname_English: "Sarawut",
    lastname_English: "Whangdee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "วิศวกรผลิต LDPE",
    pL_Group: "S2",
    birthDay: "Jun  9 1972 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sarawuwh@scg.com",
    report_To: "Mr. Pat Nartpochananon",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LDPE",
    report_Email: "patn@scg.com",
    nick_Name: "กั้ง",
    mobileNumber: "0812345678",
    login: "sarawuwh",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000971",
    name_title: "นาย",
    firstname: "ศรายุทธ",
    lastname: "คำแพ่ง",
    name_Title_English: "Mr.",
    firstname_English: "Sarayut",
    lastname_English: "Khamphang",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O5",
    birthDay: "Dec 23 1982 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sarayutk@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "จั๊ม",
    mobileNumber: "0812345678",
    login: "sarayutk",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000256",
    name_title: "นาย",
    firstname: "สุพจน์",
    lastname: "ฝาคำ",
    name_Title_English: "Mr.",
    firstname_English: "Supoht",
    lastname_English: "Farkum",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    pL_Group: "S3",
    birthDay: "Oct 24 1972 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "supohtf@scg.com",
    report_To: "Mr. Nattapol Pongpaothai",
    report_to_Position_Name: "ผู้จัดการ Logistics Operation",
    report_Email: "nattpong@scg.com",
    nick_Name: "พจน์",
    mobileNumber: "0812345678",
    login: "supohtf",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000365",
    name_title: "นาย",
    firstname: "สุวัฒ",
    lastname: "ป้อมภา",
    name_Title_English: "Mr.",
    firstname_English: "Suwat",
    lastname_English: "Pompa",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "หัวหน้างานผลิต LDPE",
    pL_Group: "O5",
    birthDay: "May 29 1974 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "suwapo@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "วัฒิ",
    mobileNumber: "0812345678",
    login: "suwapo",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000270",
    name_title: "นาย",
    firstname: "วันชัย",
    lastname: "ครอบบัวบาน",
    name_Title_English: "Mr.",
    firstname_English: "Wanchai",
    lastname_English: "Krobbuaban",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "หัวหน้างานผลิต LDPE",
    pL_Group: "O5",
    birthDay: "Oct  7 1973 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "wanchakr@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "ป๋า",
    mobileNumber: "0812345678",
    login: "wanchakr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000984",
    name_title: "นาย",
    firstname: "สุรเชษฐ์",
    lastname: "แก้วฟัก",
    name_Title_English: "Mr.",
    firstname_English: "Surachate",
    lastname_English: "Gaewfug",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O4",
    birthDay: "Dec 21 1983 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "surachag@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "หลุย",
    mobileNumber: "0812345678",
    login: "surachag",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001111",
    name_title: "นาย",
    firstname: "ธงชัย",
    lastname: "ดอกพุฒ",
    name_Title_English: "Mr.",
    firstname_English: "Thongchai",
    lastname_English: "Daokput",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O4",
    birthDay: "Aug 27 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thongchd@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "โข่ง",
    mobileNumber: "0812345678",
    login: "thongchd",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001555",
    name_title: "นาย",
    firstname: "อดุลย์",
    lastname: "บุญประกอบ",
    name_Title_English: "Mr.",
    firstname_English: "Adul",
    lastname_English: "Bunprakob",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "นักวิเคราะห์ระบบ Logistics",
    pL_Group: "S3",
    birthDay: "Aug 19 1973 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038912347",
    email: "adubu@scg.com",
    report_To: "Mr. Nattapol Pongpaothai",
    report_to_Position_Name: "ผู้จัดการ Logistics Operation",
    report_Email: "nattpong@scg.com",
    nick_Name: "เดียว",
    mobileNumber: "0812345678",
    login: "adubu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001014",
    name_title: "นาย",
    firstname: "จิรายุทธ",
    lastname: "นามวงษา",
    name_Title_English: "Mr.",
    firstname_English: "Jirayuth",
    lastname_English: "Namwongsa",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O5",
    birthDay: "Feb 17 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jirayutn@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "เตอร์",
    mobileNumber: "0812345678",
    login: "jirayutn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001010",
    name_title: "นาย",
    firstname: "ไกรฤกษ์",
    lastname: "มั่งมี",
    name_Title_English: "Mr.",
    firstname_English: "Kraireok",
    lastname_English: "Mungmee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการ Packing",
    pL_Group: "O4",
    birthDay: "Mar 13 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038912344",
    email: "kraireom@scg.com",
    report_To: "Mr. Supoht Farkum",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "supohtf@scg.com",
    nick_Name: "นิค",
    mobileNumber: "0812345678",
    login: "kraireom",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001008",
    name_title: "นาย",
    firstname: "ณัฐวุฒิ",
    lastname: "สุทธวงศ์",
    name_Title_English: "Mr.",
    firstname_English: "Nuttawut",
    lastname_English: "Suthawong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "หัวหน้างานผลิต PP 1",
    pL_Group: "O5",
    birthDay: "Dec 11 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nuttawsu@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "นัท",
    mobileNumber: "0812345678",
    login: "nuttawsu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001007",
    name_title: "นาย",
    firstname: "ปิติพงษ์",
    lastname: "สุริยา",
    name_Title_English: "Mr.",
    firstname_English: "Pitipong",
    lastname_English: "Suriya",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O4",
    birthDay: "Sep 30 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pitipons@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "พร้อม",
    mobileNumber: "0812345678",
    login: "pitipons",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001017",
    name_title: "นาย",
    firstname: "พงษ์ศักดิ์",
    lastname: "สานหน่อแก้ว",
    name_Title_English: "Mr.",
    firstname_English: "Pongsak",
    lastname_English: "Sannorkaew",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "หัวหน้างานผลิต PP 2",
    pL_Group: "O5",
    birthDay: "Dec 16 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pongssan@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "ตั้ม",
    mobileNumber: "0812345678",
    login: "pongssan",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001016",
    name_title: "นาย",
    firstname: "ปรีชา",
    lastname: "หอมทอง",
    name_Title_English: "Mr.",
    firstname_English: "Preecha",
    lastname_English: "Homtong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O4",
    birthDay: "Sep 25 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "preechah@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "ยศ",
    mobileNumber: "0812345678",
    login: "preechah",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001009",
    name_title: "นาย",
    firstname: "ธนดล",
    lastname: "กาบุตร",
    name_Title_English: "Mr.",
    firstname_English: "Tanadon",
    lastname_English: "Kabut",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "หัวหน้างานผลิต PP 1",
    pL_Group: "O5",
    birthDay: "Mar 23 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "tanadoka@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "ปั๊ม",
    mobileNumber: "0812345678",
    login: "tanadoka",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000939",
    name_title: "นาย",
    firstname: "อัทธ์",
    lastname: "ลิ้มยืนยงอมตะ",
    name_Title_English: "Mr.",
    firstname_English: "Aut",
    lastname_English: "Limyunyongamata",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O4",
    birthDay: "Sep 10 1982 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "autl@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "เม็ด",
    mobileNumber: "0812345678",
    login: "autl",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000902",
    name_title: "นาย",
    firstname: "ชาญชัย",
    lastname: "ชัชวรัตน์",
    name_Title_English: "Mr.",
    firstname_English: "Chanchai",
    lastname_English: "Chuchvarat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O5",
    birthDay: "Aug 10 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chancchu@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ชาญ",
    mobileNumber: "0812345678",
    login: "chancchu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000739",
    name_title: "นาย",
    firstname: "จรัสพงศ์",
    lastname: "คำสงค์",
    name_Title_English: "Mr.",
    firstname_English: "Jarutpong",
    lastname_English: "Kumsong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O5",
    birthDay: "Sep 28 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jarutpok@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "แอ๊นท์",
    mobileNumber: "0812345678",
    login: "jarutpok",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000738",
    name_title: "นาย",
    firstname: "ต่อพงศ์",
    lastname: "พิสนาท",
    name_Title_English: "Mr.",
    firstname_English: "Torpong",
    lastname_English: "Pissanat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "หัวหน้างานผลิต Catalyst",
    pL_Group: "O5",
    birthDay: "Jan 19 1979 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "torpongp@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "โก้",
    mobileNumber: "0812345678",
    login: "torpongp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001626",
    name_title: "นาย",
    firstname: "พุทธินันท์",
    lastname: "ม่วงประเสริฐ",
    name_Title_English: "Mr.",
    firstname_English: "Puttinun",
    lastname_English: "Moungprasert",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HR Business Partner",
    departmentId: 13,
    departmentShortName: "HR",
    position: "Manager-HR Business Partner",
    pL_Group: "M1",
    birthDay: "Oct 28 1984 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "puttinum@scg.com",
    report_To: "Mr. Nitat Deechaiya",
    report_to_Position_Name: "Head of HR Business Partner",
    report_Email: "nitatd@scg.com",
    nick_Name: "ออฟ",
    mobileNumber: "0812345678",
    login: "puttinum",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000924",
    name_title: "นาย",
    firstname: "พรประสิทธิ์",
    lastname: "วรรณสำเริง",
    name_Title_English: "Mr.",
    firstname_English: "Pornprasit",
    lastname_English: "Wannasomroeng",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "ผู้จัดการแผนกปฏิบัติการจัดส่ง",
    pL_Group: "S3",
    birthDay: "Jan 20 1974 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pornpraw@scg.com",
    report_To: "Mr. Banjerd Waksoongnoen",
    report_to_Position_Name: "ผู้จัดการ Outbound Logistics",
    report_Email: "banjerdw@scg.com",
    nick_Name: "เอก",
    mobileNumber: "0812345678",
    login: "pornpraw",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001154",
    name_title: "นาย",
    firstname: "จิรพันธ์",
    lastname: "สุวรรณทวี",
    name_Title_English: "Mr.",
    firstname_English: "Jirapan",
    lastname_English: "Suwantawee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O4",
    birthDay: "Dec  8 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jirapans@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "บ่าว",
    mobileNumber: "0812345678",
    login: "jirapans",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001157",
    name_title: "นาย",
    firstname: "อาทิตย์",
    lastname: "เสือทอง",
    name_Title_English: "Mr.",
    firstname_English: "Atit",
    lastname_English: "Suatong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O4",
    birthDay: "Mar 16 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "atits@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "แป๊ะ",
    mobileNumber: "0812345678",
    login: "atits",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001032",
    name_title: "นาย",
    firstname: "จักรพงศ์",
    lastname: "ปราณนคร",
    name_Title_English: "Mr.",
    firstname_English: "Jakapong",
    lastname_English: "Panakorn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O4",
    birthDay: "Nov  4 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jakaponp@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "โด่ง",
    mobileNumber: "0812345678",
    login: "jakaponp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001047",
    name_title: "นาย",
    firstname: "ดนุพล",
    lastname: "ทามี",
    name_Title_English: "Mr.",
    firstname_English: "Danupol",
    lastname_English: "Tamee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Pipe Compounding Department",
    departmentId: 9,
    departmentShortName: "Pipe Compounding",
    position: "พนักงานผลิต Pipe Compounding",
    pL_Group: "O4",
    birthDay: "Jun  6 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "danupolt@scg.com",
    report_To: "Mr. Suwat Sotana",
    report_to_Position_Name: "ผจผ.ผลิต Pipe Compounding",
    report_Email: "suwaso@scg.com",
    nick_Name: "ตู่",
    mobileNumber: "0812345678",
    login: "danupolt",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001151",
    name_title: "นาย",
    firstname: "อุเทน",
    lastname: "เผือดผ่อง",
    name_Title_English: "Mr.",
    firstname_English: "Auten",
    lastname_English: "Phuadphong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O4",
    birthDay: "Nov 22 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "autenp@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "หนุ่ม",
    mobileNumber: "0812345678",
    login: "autenp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000490",
    name_title: "นาย",
    firstname: "สันติ",
    lastname: "ก่ำมอญ",
    name_Title_English: "Mr.",
    firstname_English: "Santi",
    lastname_English: "Kommorn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "หัวหน้างานผลิต HDPE 1",
    pL_Group: "O5",
    birthDay: "Oct 25 1975 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038912310",
    email: "santik@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "ติ",
    mobileNumber: "0812345678",
    login: "santik",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000967",
    name_title: "นาย",
    firstname: "สุวิทย์",
    lastname: "สอนสุด",
    name_Title_English: "Mr.",
    firstname_English: "Suvit",
    lastname_English: "Sonsud",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O5",
    birthDay: "Nov 28 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "suvits@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "วิท",
    mobileNumber: "0812345678",
    login: "suvits",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001031",
    name_title: "นาย",
    firstname: "พัทยา",
    lastname: "ลาโพธิ์",
    name_Title_English: "Mr.",
    firstname_English: "Pattaya",
    lastname_English: "Lapho",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O4",
    birthDay: "Apr 23 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pattayal@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "พัด",
    mobileNumber: "0812345678",
    login: "pattayal",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000345",
    name_title: "นาย",
    firstname: "ประเสริฐ",
    lastname: "วิมลรัตน์",
    name_Title_English: "Mr.",
    firstname_English: "Prasert",
    lastname_English: "Wimonrat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรปฏิบัติการความปลอดภัย",
    pL_Group: "S2",
    birthDay: "Feb 22 1974 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "praserwi@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "มอส",
    mobileNumber: "0812345678",
    login: "praserwi",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000309",
    name_title: "นาย",
    firstname: "สำรวย",
    lastname: "เหลืองอมรศักดิ์",
    name_Title_English: "Mr.",
    firstname_English: "Sumruay",
    lastname_English: "Luengamornsak",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "วิศวกรผลิต HDPE 1",
    pL_Group: "S2",
    birthDay: "Nov 25 1974 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sumruayl@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต HDPE 1",
    report_Email: "sittharl@scg.com",
    nick_Name: "ป้อม",
    mobileNumber: "0812345678",
    login: "sumruayl",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001018",
    name_title: "นาย",
    firstname: "สิทธิชัย",
    lastname: "ชุมภูแก้ว",
    name_Title_English: "Mr.",
    firstname_English: "Sittichai",
    lastname_English: "Chumpukeaw",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O5",
    birthDay: "Jul  4 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sitticch@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "ต้น",
    mobileNumber: "0812345678",
    login: "sitticch",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001022",
    name_title: "นาย",
    firstname: "วราเวช",
    lastname: "วุฒิพนมศักดิ์",
    name_Title_English: "Mr.",
    firstname_English: "Waravech",
    lastname_English: "Vuttipanomsak",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O4",
    birthDay: "Aug  5 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "wararecy@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "หาร",
    mobileNumber: "0812345678",
    login: "wararecy",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000628",
    name_title: "นาย",
    firstname: "จรัญ",
    lastname: "คำนึง",
    name_Title_English: "Mr.",
    firstname_English: "Jarun",
    lastname_English: "Khumnung",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "หัวหน้างานผลิต HDPE 1",
    pL_Group: "O5",
    birthDay: "Sep 17 1977 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jarukh@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "หมึก",
    mobileNumber: "0812345678",
    login: "jarukh",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001065",
    name_title: "นาย",
    firstname: "ธีระพงษ์",
    lastname: "ราชอุปนันท์",
    name_Title_English: "Mr.",
    firstname_English: "Theerapong",
    lastname_English: "Rat-upanan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O4",
    birthDay: "Mar 11 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "theerrat@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ตู่",
    mobileNumber: "0812345678",
    login: "theerrat",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001158",
    name_title: "นาย",
    firstname: "ทักษิณ",
    lastname: "ซุยศรี",
    name_Title_English: "Mr.",
    firstname_English: "Taksin",
    lastname_English: "Suisri",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - Support",
    pL_Group: "O4",
    birthDay: "Feb 24 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "taksins@scg.com",
    report_To: "Mrs. Siripun Poorinutpong",
    report_to_Position_Name: "ผจก.Quality Control Standardization & Su",
    report_Email: "siripunp@scg.com",
    nick_Name: "อุ้ม",
    mobileNumber: "0812345678",
    login: "taksins",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000269",
    name_title: "นาย",
    firstname: "สมชาย",
    lastname: "ชินเจริญวงศ์",
    name_Title_English: "Mr.",
    firstname_English: "Somchai",
    lastname_English: "Chincharoenwong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "หัวหน้างานผลิต LDPE",
    pL_Group: "O5",
    birthDay: "Jan 20 1972 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "somcchin@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "แมว",
    mobileNumber: "0818044050",
    login: "somcchin",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000171",
    name_title: "นาย",
    firstname: "สุทธิรัตน์",
    lastname: "สว่างโรจน์",
    name_Title_English: "Mr.",
    firstname_English: "Suttirat",
    lastname_English: "Sawangrote",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "เจ้าหน้าที่สิ่งแวดล้อม",
    pL_Group: "O5",
    birthDay: "Sep 23 1971 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "suttiras@scg.com",
    report_To: "Miss Sasiphen Laimai",
    report_to_Position_Name: "ผู้จัดการแผนกสิ่งแวดล้อม",
    report_Email: "sasiphel@scg.com",
    nick_Name: "เต้ย",
    mobileNumber: "0812345678",
    login: "suttiras",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000649",
    name_title: "นาย",
    firstname: "วีระ",
    lastname: "เจริญรัมย์",
    name_Title_English: "Mr.",
    firstname_English: "Weera",
    lastname_English: "Jarernram",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "หัวหน้างานผลิต LDPE",
    pL_Group: "O5",
    birthDay: "Nov  4 1979 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "weeraj@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "ขิม",
    mobileNumber: "0812345678",
    login: "weeraj",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000951",
    name_title: "นาย",
    firstname: "อาทิตย์",
    lastname: "คำเที่ยง",
    name_Title_English: "Mr.",
    firstname_English: "Artit",
    lastname_English: "Khamthieng",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O5",
    birthDay: "Nov 10 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "artitk@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "บอย",
    mobileNumber: "0831142006",
    login: "artitk",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000958",
    name_title: "นาย",
    firstname: "อนุพงษ์",
    lastname: "ปงธิยา",
    name_Title_English: "Mr.",
    firstname_English: "Anupong",
    lastname_English: "Pongtiya",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O4",
    birthDay: "Dec  5 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "anupopon@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "เอ",
    mobileNumber: "0812345678",
    login: "anupopon",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001180",
    name_title: "นาย",
    firstname: "พัฒน์",
    lastname: "นารถพจนานนท์",
    name_Title_English: "Mr.",
    firstname_English: "Pat",
    lastname_English: "Nartpochananon",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "ผู้จัดการส่วนผลิต LDPE",
    pL_Group: "M1",
    birthDay: "May 29 1983 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "patn@scg.com",
    report_To: "Mr. Kraisid Wongnark",
    report_to_Position_Name: "ผู้จัดการฝ่ายผลิต PE",
    report_Email: "krisidw@scg.com",
    nick_Name: "เต๋า",
    mobileNumber: "0812345678",
    login: "patn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001030",
    name_title: "นาย",
    firstname: "ศุภศักดิ์",
    lastname: "แก้ววิชิต",
    name_Title_English: "Mr.",
    firstname_English: "Supasak",
    lastname_English: "Kaewwichit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 4 Production Department",
    departmentId: 6,
    departmentShortName: "HDPE 4",
    position: "ผู้จัดการส่วนผลิต HDPE 4",
    pL_Group: "M2",
    birthDay: "May  7 1983 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "supasakk@scg.com",
    report_To: "Mr. Kraisid Wongnark",
    report_to_Position_Name: "ผู้จัดการฝ่ายผลิต PE",
    report_Email: "krisidw@scg.com",
    nick_Name: "เน",
    mobileNumber: "0812345678",
    login: "supasakk",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001156",
    name_title: "นาย",
    firstname: "สุรศักดิ์",
    lastname: "คูณพงษ์",
    name_Title_English: "Mr.",
    firstname_English: "Surasak",
    lastname_English: "Khoonphong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O4",
    birthDay: "Aug  3 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "surasakh@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "โอ๊ด",
    mobileNumber: "0812345678",
    login: "surasakh",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001049",
    name_title: "นาย",
    firstname: "กวี",
    lastname: "สิงห์ทอง",
    name_Title_English: "Mr.",
    firstname_English: "Kavee",
    lastname_English: "Singtong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Pipe Compounding Department",
    departmentId: 9,
    departmentShortName: "Pipe Compounding",
    position: "พนักงานผลิต Pipe Compounding",
    pL_Group: "O4",
    birthDay: "Jun  4 1984 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kavesi@scg.com",
    report_To: "Mr. Suwat Sotana",
    report_to_Position_Name: "ผจผ.ผลิต Pipe Compounding",
    report_Email: "suwaso@scg.com",
    nick_Name: "ป้อม",
    mobileNumber: "0812345678",
    login: "kavesi",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001155",
    name_title: "นาย",
    firstname: "ณัฐพล",
    lastname: "รักษ์เพ็ชร",
    name_Title_English: "Mr.",
    firstname_English: "Natthapon",
    lastname_English: "Rakphet",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O4",
    birthDay: "Nov  1 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "natthara@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "ปู",
    mobileNumber: "0812345678",
    login: "natthara",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001023",
    name_title: "นาย",
    firstname: "อนันตชัย",
    lastname: "ผันผาย",
    name_Title_English: "Mr.",
    firstname_English: "Anantachai",
    lastname_English: "Panpay",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O4",
    birthDay: "Jan  7 1984 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "anantacp@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "เล็ก",
    mobileNumber: "0812345678",
    login: "anantacp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900937",
    name_title: "นาย",
    firstname: "มนตรี",
    lastname: "เคียนพรมราช",
    name_Title_English: "Mr.",
    firstname_English: "Montree",
    lastname_English: "Kianpromrach",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานขับรถดับเพลิง",
    pL_Group: "O0",
    birthDay: "Jan 24 1983 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683138",
    email: "montreki@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "ตรี",
    mobileNumber: "0812345678",
    login: "montreki",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001064",
    name_title: "นาย",
    firstname: "อรรถพร",
    lastname: "มณีวงษ์",
    name_Title_English: "Mr.",
    firstname_English: "Auttaporn",
    lastname_English: "Maneewong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O4",
    birthDay: "Jul  6 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "auttapom@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "โก๊ะ",
    mobileNumber: "0812345678",
    login: "auttapom",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001116",
    name_title: "นาย",
    firstname: "องอาจ",
    lastname: "วงษ์สุวรรณ",
    name_Title_English: "Mr.",
    firstname_English: "Ongach",
    lastname_English: "Wongsuwan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1,2",
    pL_Group: "O4",
    birthDay: "Jan  2 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "ongachwo@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "ตะ",
    mobileNumber: "0812345678",
    login: "ongachwo",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900939",
    name_title: "นาย",
    firstname: "บวร",
    lastname: "กลางนอก",
    name_Title_English: "Mr.",
    firstname_English: "Bwor",
    lastname_English: "Glangnog",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "หัวหน้ากะดับเพลิง",
    pL_Group: "O0",
    birthDay: "Jan 15 1982 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "khajonsg@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "จร",
    mobileNumber: "0812345678",
    login: "khajonsg",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900943",
    name_title: "นาย",
    firstname: "เขมรัตน์",
    lastname: "คำสอนพันธ์",
    name_Title_English: "Mr.",
    firstname_English: "Khemarat",
    lastname_English: "Khamsonphan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานศูนย์สื่อสารและเฝ้าระวังเหตุ",
    pL_Group: "O0",
    birthDay: "Mar 13 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "khemarak@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "แบล็ค",
    mobileNumber: "0812345678",
    login: "khemarak",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900938",
    name_title: "นาย",
    firstname: "นพดล",
    lastname: "นิลมณี",
    name_Title_English: "Mr.",
    firstname_English: "Noppadol",
    lastname_English: "Ninmanee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "หัวหน้ากะดับเพลิง",
    pL_Group: "O0",
    birthDay: "Sep 23 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "noppadni@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "แอน",
    mobileNumber: "0812345678",
    login: "noppadni",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900942",
    name_title: "นาย",
    firstname: "สราวุฒิ",
    lastname: "ตั้งสุวรรณ",
    name_Title_English: "Mr.",
    firstname_English: "Sarawut",
    lastname_English: "Thangsuwan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานขับรถตรวจการณ์",
    pL_Group: "O0",
    birthDay: "Aug 16 1980 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "sarawtha@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "หนึ่ง",
    mobileNumber: "0871474738",
    login: "sarawtha",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900941",
    name_title: "นาย",
    firstname: "วัชรพงษ์",
    lastname: "ยั่งยืน",
    name_Title_English: "Mr.",
    firstname_English: "Watcharapong",
    lastname_English: "Yungyuen",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "ผู้บัญชาการดับเพลิง",
    pL_Group: "O0",
    birthDay: "May 18 1982 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "watchary@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "เอ",
    mobileNumber: "0812345678",
    login: "watchary",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900984",
    name_title: "นาย",
    firstname: "วิจิตร",
    lastname: "ศรีเจิม",
    name_Title_English: "Mr.",
    firstname_English: "Vijit",
    lastname_English: "Srijerm",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "เจ้าหน้าที่ความปลอดภัยปฏิบัติการภาคสนาม",
    pL_Group: "O0",
    birthDay: "Jan 17 1966 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "vijitsri@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "ต่าย",
    mobileNumber: "0812345678",
    login: "vijitsri",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001228",
    name_title: "นาย",
    firstname: "ปรีชา",
    lastname: "มั่นมา",
    name_Title_English: "Mr.",
    firstname_English: "Preecha",
    lastname_English: "Manma",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O4",
    birthDay: "May 26 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "preecman@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "โฮม",
    mobileNumber: "0812345678",
    login: "preecman",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001234",
    name_title: "นาย",
    firstname: "พิชญ์ญา",
    lastname: "ชูเชิด",
    name_Title_English: "Mr.",
    firstname_English: "Pitchaya",
    lastname_English: "Chucherd",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการ Packing",
    pL_Group: "O4",
    birthDay: "Feb 11 1989 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "Bagging 2 Site 1",
    telephone_Business: "",
    email: "pichaych@scg.com",
    report_To: "Mr. Adul Bunprakob",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "adubu@scg.com",
    nick_Name: "เบนซ์",
    mobileNumber: "0868406807",
    login: "pichaych",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001236",
    name_title: "นาย",
    firstname: "วรวิทย์",
    lastname: "เจริญสุข",
    name_Title_English: "Mr.",
    firstname_English: "Worawit",
    lastname_English: "Charoensuk",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O4",
    birthDay: "Dec 21 1988 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "worawitc@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "จุ้ย",
    mobileNumber: "0812345678",
    login: "worawitc",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001237",
    name_title: "นาย",
    firstname: "พฤกษ์กรณ์",
    lastname: "นัทธีประเสริฐ",
    name_Title_English: "Mr.",
    firstname_English: "Prukkorn",
    lastname_English: "Natthiprasoet",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "Packing Operator",
    pL_Group: "O3",
    birthDay: "Jan 20 1988 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "prukkorn@scg.com",
    report_To: "Mr. Adul Bunprakob",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "adubu@scg.com",
    nick_Name: "พจน์",
    mobileNumber: "0811503360",
    login: "prukkorn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001570",
    name_title: "นางสาว",
    firstname: "กนกชฎา",
    lastname: "แป้นคุ้มญาติ",
    name_Title_English: "Miss",
    firstname_English: "Kanokchada",
    lastname_English: "Panekumyat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการจัดส่ง",
    pL_Group: "O4",
    birthDay: "May 23 1985 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "kanokcpa@scg.com",
    report_To: "Mr. Pornprasit Wannasomroeng",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการจัดส่ง",
    report_Email: "pornpraw@scg.com",
    nick_Name: "เนย์",
    mobileNumber: "0812345678",
    login: "kanokcpa",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900983",
    name_title: "นางสาว",
    firstname: "อลิสา",
    lastname: "วงศ์ทิม",
    name_Title_English: "Miss",
    firstname_English: "Alisa",
    lastname_English: "Wongtim",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "เจ้าหน้าที่ดูแลระบบเอกสารราชการ",
    pL_Group: "O0",
    birthDay: "Oct 30 1976 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "alisawon@scg.com",
    report_To: "Miss Chuenjit Sukpan",
    report_to_Position_Name: "ผจก.แผนกระบบอาชีวอนามัยและความปลอดภัย",
    report_Email: "chuenjis@scg.com",
    nick_Name: "ดาว",
    mobileNumber: "0812345678",
    login: "alisawon",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900985",
    name_title: "นาย",
    firstname: "เกษม",
    lastname: "ใจตั้ง",
    name_Title_English: "Mr.",
    firstname_English: "Kasem",
    lastname_English: "Chaitang",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "Fire Engine Driver",
    pL_Group: "O0",
    birthDay: "Jul  2 1981 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "kasemcha@scg.com",
    report_To: "Mr. Kraisit Wannaratt",
    report_to_Position_Name: "ผจผ.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",
    report_Email: "kraisitw@scg.com",
    nick_Name: "ก๊วยเจ๋ง",
    mobileNumber: "0864784817",
    login: "kasemcha",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "",
  },
  {
    scG_Employee_ID: "0100-900986",
    name_title: "นาย",
    firstname: "เอกชัย",
    lastname: "เกรียบกระโทก",
    name_Title_English: "Mr.",
    firstname_English: "Aekkachai",
    lastname_English: "Kriabkathok",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "หัวหน้ากะดับเพลิง",
    pL_Group: "O0",
    birthDay: "Jul 30 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "aekkachk@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "แอร์",
    mobileNumber: "0812345678",
    login: "aekkachk",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900988",
    name_title: "นาย",
    firstname: "คมเพชร",
    lastname: "ผูกมิตร",
    name_Title_English: "Mr.",
    firstname_English: "Kompeth",
    lastname_English: "Poukmit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานศูนย์สื่อสารและเฝ้าระวังเหตุ",
    pL_Group: "O0",
    birthDay: "Dec 26 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038683393",
    email: "kompethp@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "เอ้",
    mobileNumber: "0812345678",
    login: "kompethp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000989",
    name_title: "นาย",
    firstname: "เชาวลิต",
    lastname: "เครือเพ็ง",
    name_Title_English: "Mr.",
    firstname_English: "Chaowalit",
    lastname_English: "Kruapheng",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O5",
    birthDay: "Dec 22 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chaowakr@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ต้น",
    mobileNumber: "0812345678",
    login: "chaowakr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901012",
    name_title: "นางสาว",
    firstname: "ทิญาวรรย์",
    lastname: "ผลทอง",
    name_Title_English: "Miss",
    firstname_English: "Thiyawan",
    lastname_English: "Ponthong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานสัญญาจ้างปฏิบัติการจัดส่ง",
    pL_Group: "O0",
    birthDay: "Jan  1 1983 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thiyawap@scg.com",
    report_To: "Mr. Pornprasit Wannasomroeng",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการจัดส่ง",
    report_Email: "pornpraw@scg.com",
    nick_Name: "เจี๊ยบ",
    mobileNumber: "0812345678",
    login: "thiyawap",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001257",
    name_title: "นาย",
    firstname: "วัยวุฒิ",
    lastname: "ด้วงไข่",
    name_Title_English: "Mr.",
    firstname_English: "Waiyawud",
    lastname_English: "Duangkai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการ Packing",
    pL_Group: "O4",
    birthDay: "Mar 18 1985 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "waiyawud@scg.com",
    report_To: "Mr. Supoht Farkum",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "supohtf@scg.com",
    nick_Name: "เจมส์",
    mobileNumber: "0812345678",
    login: "waiyawud",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001259",
    name_title: "นาย",
    firstname: "ชูศักดิ์",
    lastname: "อรรถบดี",
    name_Title_English: "Mr.",
    firstname_English: "Choosuk",
    lastname_English: "Atthabadee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O4",
    birthDay: "Jun 15 1990 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "choosuka@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "เก่ง",
    mobileNumber: "0812345678",
    login: "choosuka",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001262",
    name_title: "นาง",
    firstname: "รุ่งทิพย์",
    lastname: "เลิศมั่นคง",
    name_Title_English: "Mrs.",
    firstname_English: "Rungtip",
    lastname_English: "Lertmunkong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "ผู้จัดการแผนกประกันคุณภาพ PP & Compounding",
    pL_Group: "S4",
    birthDay: "Feb 28 1982 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "rungtile@scg.com",
    report_To: "Miss Sirin Methakhup",
    report_to_Position_Name: "Quality Assurance Mgr.",
    report_Email: "sirinm@scg.com",
    nick_Name: "ติ๊ก",
    mobileNumber: "0812345678",
    login: "rungtile",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001272",
    name_title: "นาย",
    firstname: "วิสิทธิ์ศักดิ์",
    lastname: "ลุนสืบ",
    name_Title_English: "Mr.",
    firstname_English: "Wisitsak",
    lastname_English: "Lunsueb",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O4",
    birthDay: "Aug 28 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "wisitsa@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "บี",
    mobileNumber: "0812345678",
    login: "wisitsa",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001276",
    name_title: "นาย",
    firstname: "จิรโรจน์",
    lastname: "แก้วมุขดา",
    name_Title_English: "Mr.",
    firstname_English: "Jiraroj",
    lastname_English: "Kaewmookda",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O4",
    birthDay: "May 17 1988 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jirarojk@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "ปุ๊",
    mobileNumber: "0832266159",
    login: "jirarojk",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001278",
    name_title: "นาย",
    firstname: "สมชาติ",
    lastname: "อมรเลิศปรีชา",
    name_Title_English: "Mr.",
    firstname_English: "Somchat",
    lastname_English: "Amornlertpreecha",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "วิศวกรผลิต LLDPE",
    pL_Group: "S4",
    birthDay: "Oct 19 1982 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "somchata@scg.com",
    report_To: "Mr. Sophon Kiatpradab",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LLDPE",
    report_Email: "sophoki@scg.com",
    nick_Name: "ธง",
    mobileNumber: "0952635149",
    login: "somchata",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001374",
    name_title: "นาย",
    firstname: "ภานุวัฒน์",
    lastname: "ไชยพันธ์",
    name_Title_English: "Mr.",
    firstname_English: "Panuwat",
    lastname_English: "Chaiyapan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O4",
    birthDay: "May  6 1992 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "panuwatc@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "แฟ้ม",
    mobileNumber: "0812345678",
    login: "panuwatc",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001369",
    name_title: "นาย",
    firstname: "ธีระพงษ์",
    lastname: "เรื่องลือ",
    name_Title_English: "Mr.",
    firstname_English: "Teerapong",
    lastname_English: "Rueanglue",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O4",
    birthDay: "Mar  9 1991 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "teerapor@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "แตง",
    mobileNumber: "0812345678",
    login: "teerapor",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001318",
    name_title: "นาย",
    firstname: "ทศพล",
    lastname: "กันยะ",
    name_Title_English: "Mr.",
    firstname_English: "Todsapon",
    lastname_English: "Kanya",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O4",
    birthDay: "Jun  8 1991 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "todsapka@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "ต้อม",
    mobileNumber: "0812345678",
    login: "todsapka",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001321",
    name_title: "นาย",
    firstname: "ญาณพัฒน์",
    lastname: "ภูจอม",
    name_Title_English: "Mr.",
    firstname_English: "Yanaphat",
    lastname_English: "Phoojom",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "HDPE 1 Production Operator",
    pL_Group: "O4",
    birthDay: "Feb  2 1990 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    telephone_Beside: "0883528090",
    email: "pakaypep@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "แล้วแต่",
    mobileNumber: "0807241046",
    login: "pakaypep",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001634",
    name_title: "นางสาว",
    firstname: "ศุภานัน",
    lastname: "สมโลก",
    name_Title_English: "Miss",
    firstname_English: "Supanun",
    lastname_English: "Somlok",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "วิศวกรประกันคุณภาพ PP&Compounding",
    pL_Group: "S3",
    birthDay: "Mar  1 1985 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "supanuns@scg.com",
    report_To: "Mrs. Rungtip Lertmunkong",
    report_to_Position_Name: "ผจก.แผนกประกันคุณภาพ PP & Compounding",
    report_Email: "rungtile@scg.com",
    nick_Name: "นิ",
    mobileNumber: "0812345678",
    login: "supanuns",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900995",
    name_title: "นาย",
    firstname: "นิกร",
    lastname: "พิมพ์ออน",
    name_Title_English: "Mr.",
    firstname_English: "Nigorn",
    lastname_English: "Pimon",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานศูนย์สื่อสารและเฝ้าระวังเหตุ",
    pL_Group: "O0",
    birthDay: "Dec 21 1983 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nigornpi@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "กบ",
    mobileNumber: "0812345678",
    login: "nigornpi",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001333",
    name_title: "นาย",
    firstname: "ชิตชนันท์",
    lastname: "ศรีคำมี",
    name_Title_English: "Mr.",
    firstname_English: "Chitchanan",
    lastname_English: "Srikammee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O4",
    birthDay: "Nov  5 1990 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chitchas@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "เบียร์",
    mobileNumber: "0812345678",
    login: "chitchas",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001354",
    name_title: "นาย",
    firstname: "ยศธร",
    lastname: "บุญเพ็ง",
    name_Title_English: "Mr.",
    firstname_English: "Yotsathorn",
    lastname_English: "Bonpheng",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O4",
    birthDay: "May  2 1991 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "yotsathb@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "นิค",
    mobileNumber: "0812345678",
    login: "yotsathb",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001367",
    name_title: "นาย",
    firstname: "สุปะศักดิ์",
    lastname: "กล้าหาญ",
    name_Title_English: "Mr.",
    firstname_English: "Supasak",
    lastname_English: "Klahan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการ Packing",
    pL_Group: "O4",
    birthDay: "Jul 10 1984 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "supasakl@scg.com",
    report_To: "Mr. Supoht Farkum",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "supohtf@scg.com",
    nick_Name: "สุ",
    mobileNumber: "0812345678",
    login: "supasakl",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001370",
    name_title: "นาย",
    firstname: "ชลากร",
    lastname: "บูรณ์เจริญ",
    name_Title_English: "Mr.",
    firstname_English: "Chalakorn",
    lastname_English: "Booncharoen",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "HDPE 1 Production Operator",
    pL_Group: "O3",
    birthDay: "Oct 25 1990 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chalakob@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "ต้น",
    mobileNumber: "0881146487",
    login: "chalakob",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "",
  },
  {
    scG_Employee_ID: "0100-001375",
    name_title: "นาย",
    firstname: "สมบูรณ์",
    lastname: "ไทยญานุสร",
    name_Title_English: "Mr.",
    firstname_English: "Somboon",
    lastname_English: "Thaiyanusorn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O4",
    birthDay: "Jun  6 1991 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sombooth@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "อ๊อฟ",
    mobileNumber: "0812345678",
    login: "sombooth",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900998",
    name_title: "นาย",
    firstname: "ปพัฒน์",
    lastname: "สุวรรณพิทักษ์",
    name_Title_English: "Mr.",
    firstname_English: "Phapat",
    lastname_English: "Suwanpitak",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 3",
    pL_Group: "O0",
    birthDay: "Jul  8 1983 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "phapatsu@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",
    report_Email: "somrerkn@scg.com",
    nick_Name: "โจ๊ก",
    mobileNumber: "0812345678",
    login: "phapatsu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001380",
    name_title: "นาย",
    firstname: "มารุต",
    lastname: "ศรีไชย",
    name_Title_English: "Mr.",
    firstname_English: "Marut",
    lastname_English: "Srichai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O4",
    birthDay: "Feb 22 1990 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "marutsri@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "รุต",
    mobileNumber: "0812345678",
    login: "marutsri",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001381",
    name_title: "นาย",
    firstname: "จตุพร",
    lastname: "รัตนนิตย์",
    name_Title_English: "Mr.",
    firstname_English: "Jatuporn",
    lastname_English: "Rattananit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O4",
    birthDay: "Jan 12 1991 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jatuporr@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "สาม",
    mobileNumber: "0812345678",
    login: "jatuporr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001384",
    name_title: "นาย",
    firstname: "สุรสิทธิ์",
    lastname: "ปิ่นศิริ",
    name_Title_English: "Mr.",
    firstname_English: "Surasit",
    lastname_English: "Pinsiri",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O4",
    birthDay: "Aug 16 1991 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "surasitp@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "ตูมตาม",
    mobileNumber: "0812345678",
    login: "surasitp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001589",
    name_title: "นางสาว",
    firstname: "ประไพพิศ",
    lastname: "กิจชนะชัย",
    name_Title_English: "Miss",
    firstname_English: "Prapipit",
    lastname_English: "Kitchanachai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "ผู้จัดการส่วนประกันและควบคุมคุณภาพ",
    pL_Group: "M2",
    birthDay: "Jan 25 1964 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "prapipik@scg.com",
    report_To: "Mr. Preeda Vatchratiensakul",
    report_to_Position_Name: "กรรมการผู้จัดการ ทีพีอี",
    report_Email: "preedav@scg.com",
    nick_Name: "อี๊",
    mobileNumber: "0812345678",
    login: "prapipik",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-900999",
    name_title: "นาย",
    firstname: "พงษ์ศักดิ์",
    lastname: "โกรดประโคน",
    name_Title_English: "Mr.",
    firstname_English: "Phongsak",
    lastname_English: "Krodprakhon",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 3",
    pL_Group: "O0",
    birthDay: "Jan 30 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "phongskr@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "กั้ง",
    mobileNumber: "0812345678",
    login: "phongskr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901000",
    name_title: "นาย",
    firstname: "พงษ์พันธ์",
    lastname: "พลเยี่ยม",
    name_Title_English: "Mr.",
    firstname_English: "Pongpan",
    lastname_English: "Polylam",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานขับรถดับเพลิง",
    pL_Group: "O0",
    birthDay: "Oct 14 1986 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pongppol@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "เอ็ก",
    mobileNumber: "0812345678",
    login: "pongppol",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001393",
    name_title: "นาย",
    firstname: "ธิติศักดิ์",
    lastname: "สิงห์เขตร",
    name_Title_English: "Mr.",
    firstname_English: "Thitisak",
    lastname_English: "Singhakeat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Pipe Compounding Department",
    departmentId: 9,
    departmentShortName: "Pipe Compounding",
    position: "พนักงานผลิต Pipe Compounding",
    pL_Group: "O4",
    birthDay: "Jun 17 1992 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "tritisas@scg.com",
    report_To: "Mr. Suwat Sotana",
    report_to_Position_Name: "ผจผ.ผลิต Pipe Compounding",
    report_Email: "suwaso@scg.com",
    nick_Name: "นัด",
    mobileNumber: "0812345678",
    login: "tritisas",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001398",
    name_title: "นาย",
    firstname: "ณัฐยศ",
    lastname: "พืชพันธุ์",
    name_Title_English: "Mr.",
    firstname_English: "Nattayot",
    lastname_English: "Phuchpan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O4",
    birthDay: "Feb  8 1993 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nattayop@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "บอส",
    mobileNumber: "0812345678",
    login: "nattayop",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001412",
    name_title: "นาย",
    firstname: "อานนท์",
    lastname: "วรรณรส",
    name_Title_English: "Mr.",
    firstname_English: "Arnon",
    lastname_English: "Wannarod",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O4",
    birthDay: "Feb 21 1993 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "arnonwan@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "นนท์",
    mobileNumber: "0812345678",
    login: "arnonwan",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001414",
    name_title: "นาย",
    firstname: "จรัญ",
    lastname: "โพธิสาร",
    name_Title_English: "Mr.",
    firstname_English: "Jaran",
    lastname_English: "Photisan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการจัดส่ง",
    pL_Group: "O3",
    birthDay: "Apr 26 1992 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "อาคารปะการัง Site 1",
    telephone_Business: "",
    email: "jaranpho@scg.com",
    report_To: "Mr. Pornprasit Wannasomroeng",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการจัดส่ง",
    report_Email: "pornpraw@scg.com",
    nick_Name: "แฟรงค์",
    mobileNumber: "0836615033",
    login: "jaranpho",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001415",
    name_title: "นาย",
    firstname: "ชนะพล",
    lastname: "วัชพืช",
    name_Title_English: "Mr.",
    firstname_English: "Chanaphon",
    lastname_English: "Watcaphuet",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O4",
    birthDay: "Feb 10 1992 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chanapow@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "เม่น",
    mobileNumber: "0812345678",
    login: "chanapow",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001416",
    name_title: "นาย",
    firstname: "ฉัตรชัย",
    lastname: "นาคะลักษณ์",
    name_Title_English: "Mr.",
    firstname_English: "Chatchai",
    lastname_English: "Nakaluck",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O3",
    birthDay: "Feb  3 1992 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chatnaka@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "อั๋ง",
    mobileNumber: "0812345678",
    login: "chatnaka",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001417",
    name_title: "นาย",
    firstname: "สิทธิชัย",
    lastname: "โพธิ์คำ",
    name_Title_English: "Mr.",
    firstname_English: "Sittichai",
    lastname_English: "Phokham",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O4",
    birthDay: "Jul  1 1992 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sittipho@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "เพียว",
    mobileNumber: "0812345678",
    login: "sittipho",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001418",
    name_title: "นาย",
    firstname: "ชัยวัฒน์",
    lastname: "แสนคำ",
    name_Title_English: "Mr.",
    firstname_English: "Chaiwat",
    lastname_English: "Sankam",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - Support",
    pL_Group: "O4",
    birthDay: "Nov  8 1992 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chaisank@scg.com",
    report_To: "Mrs. Siripun Poorinutpong",
    report_to_Position_Name: "ผจก.Quality Control Standardization & Su",
    report_Email: "siripunp@scg.com",
    nick_Name: "ภู",
    mobileNumber: "0812345678",
    login: "chaisank",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001419",
    name_title: "นาย",
    firstname: "อภิชาติ",
    lastname: "ศรีปราบ",
    name_Title_English: "Mr.",
    firstname_English: "Apichat",
    lastname_English: "Sriprab",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O4",
    birthDay: "May 10 1992 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "apicsrip@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "เป้",
    mobileNumber: "0812345678",
    login: "apicsrip",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901004",
    name_title: "นาย",
    firstname: "ภาสกร",
    lastname: "สรสิทธิ์",
    name_Title_English: "Mr.",
    firstname_English: "Passakorn",
    lastname_English: "Sorasit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 3",
    pL_Group: "O0",
    birthDay: "Aug  3 1989 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pasaksor@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "ต้น",
    mobileNumber: "0812345678",
    login: "pasaksor",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901005",
    name_title: "นาย",
    firstname: "เกรียงไกร",
    lastname: "แพงสี",
    name_Title_English: "Mr.",
    firstname_English: "Kraingkai",
    lastname_English: "Pangsee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานขับรถตรวจการณ์",
    pL_Group: "O0",
    birthDay: "Jun  4 1990 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kraingkp@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "แบ๊งค์",
    mobileNumber: "0812345678",
    login: "kraingkp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901011",
    name_title: "นางสาว",
    firstname: "ศรัญญา",
    lastname: "เชื้อทองดี",
    name_Title_English: "Miss",
    firstname_English: "Sarunya",
    lastname_English: "Chuetongdee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "เจ้าหน้าที่สิ่งแวดล้อม",
    pL_Group: "O0",
    birthDay: "Nov  1 1990 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "saranchu@scg.com",
    report_To: "Miss Chuenjit Sukpan",
    report_to_Position_Name: "ผู้จัดการแผนกสิ่งแวดล้อม",
    report_Email: "chuenjis@scg.com",
    nick_Name: "เนม",
    mobileNumber: "0614233818",
    login: "saranchu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901009",
    name_title: "นาย",
    firstname: "กันตวิชานุพันธุ์",
    lastname: "เจริญผล",
    name_Title_English: "Mr.",
    firstname_English: "Kantawichanuphan",
    lastname_English: "Jaroenpol",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานขับรถตรวจการณ์",
    pL_Group: "O0",
    birthDay: "Apr  5 1987 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kantawij@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "ใหญ่",
    mobileNumber: "0812345678",
    login: "kantawij",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001588",
    name_title: "นาย",
    firstname: "สิทาพัฒน์",
    lastname: "เหลืองอร่ามศรี",
    name_Title_English: "Mr.",
    firstname_English: "Sittharpat",
    lastname_English: "Leuangaramsri",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "ผู้จัดการส่วนผลิต HDPE 1",
    pL_Group: "M2",
    birthDay: "Jun  7 1968 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sittharl@scg.com",
    report_To: "Mr. Kraisid Wongnark",
    report_to_Position_Name: "ผู้จัดการฝ่ายผลิต PE",
    report_Email: "krisidw@scg.com",
    nick_Name: "เหลือง",
    mobileNumber: "0812345678",
    login: "sittharl",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001439",
    name_title: "นางสาว",
    firstname: "เยาวลักษณ์",
    lastname: "จันทร์แก้ว",
    name_Title_English: "Miss",
    firstname_English: "Yaowaluck",
    lastname_English: "Chankaew",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "HDPE 1 Production Engineer",
    pL_Group: "S2",
    birthDay: "May 28 1989 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "yaowaluc@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต HDPE 1",
    report_Email: "sittharl@scg.com",
    nick_Name: "กุล",
    mobileNumber: "0812923087",
    login: "yaowaluc",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "",
  },
  {
    scG_Employee_ID: "0100-001442",
    name_title: "นาย",
    firstname: "จิรทีปต์",
    lastname: "เตียพิริยะกิจ",
    name_Title_English: "Mr.",
    firstname_English: "Jirateep",
    lastname_English: "Theapiriyakit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรสิ่งแวดล้อม",
    pL_Group: "S2",
    birthDay: "Aug 10 1991 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jirateet@scg.com",
    report_To: "Miss Sasiphen Laimai",
    report_to_Position_Name: "ผู้จัดการแผนกสิ่งแวดล้อม",
    report_Email: "sasiphel@scg.com",
    nick_Name: "ต้น",
    mobileNumber: "0812345678",
    login: "jirateet",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001445",
    name_title: "นางสาว",
    firstname: "กรรณธิการ์",
    lastname: "คงประสิทธิ์",
    name_Title_English: "Miss",
    firstname_English: "Kantika",
    lastname_English: "Kongprasit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงาน Logistics Support",
    pL_Group: "O3",
    birthDay: "Aug 21 1986 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "038-937363",
    email: "kantikko@scg.com",
    report_To: "Mrs. Rujiporn Keepthong",
    report_to_Position_Name: "ผจผ.Logistics Support",
    report_Email: "rujipork@scg.com",
    nick_Name: "แนน",
    mobileNumber: "0812345678",
    login: "kantikko",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001446",
    name_title: "นาย",
    firstname: "วรุตม์",
    lastname: "ใจมอย",
    name_Title_English: "Mr.",
    firstname_English: "Warut",
    lastname_English: "Jaimoi",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O3",
    birthDay: "Jan 24 1992 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "warutjai@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "หว่อง",
    mobileNumber: "0812345678",
    login: "warutjai",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001447",
    name_title: "นาย",
    firstname: "สงกรานต์",
    lastname: "ปานเขียว",
    name_Title_English: "Mr.",
    firstname_English: "Songkran",
    lastname_English: "Pankiew",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O3",
    birthDay: "Apr 13 1994 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "songpank@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "แอส",
    mobileNumber: "0812345678",
    login: "songpank",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001450",
    name_title: "นาย",
    firstname: "พงษ์ศิริ",
    lastname: "นฤมิตพานิชกุล",
    name_Title_English: "Mr.",
    firstname_English: "Pongsiri",
    lastname_English: "Naruemitpanichkul",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "วิศวกรผลิต LDPE",
    pL_Group: "S3",
    birthDay: "Jan 24 1991 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pongsirn@scg.com",
    report_To: "Mr. Pat Nartpochananon",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LDPE",
    report_Email: "patn@scg.com",
    nick_Name: "สก็อต",
    mobileNumber: "0812345678",
    login: "pongsirn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001443",
    name_title: "นางสาว",
    firstname: "ณัฐฐินันท์",
    lastname: "สายธารธรรม",
    name_Title_English: "Miss",
    firstname_English: "Natthanan",
    lastname_English: "Saitharnthom",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "หัวหน้างาน Logistics Support",
    pL_Group: "O4",
    birthDay: "Aug  7 1981 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nattsait@scg.com",
    report_To: "Mrs. Rujiporn Keepthong",
    report_to_Position_Name: "ผจผ.Logistics Support",
    report_Email: "rujipork@scg.com",
    nick_Name: "หนิง",
    mobileNumber: "0812345678",
    login: "nattsait",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001444",
    name_title: "นางสาว",
    firstname: "สุกัญญา",
    lastname: "น้ำกระโทก",
    name_Title_English: "Miss",
    firstname_English: "Sukanya",
    lastname_English: "Namkathok",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงาน Logistics Support",
    pL_Group: "O3",
    birthDay: "Oct 24 1986 12:00AM",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sukanyan@scg.com",
    report_To: "Mrs. Rujiporn Keepthong",
    report_to_Position_Name: "ผจผ.Logistics Support",
    report_Email: "rujipork@scg.com",
    nick_Name: "ญา",
    mobileNumber: "0812345678",
    login: "sukanyan",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001820",
    name_title: "นาย",
    firstname: "วรากร",
    lastname: "พิมทนต์",
    name_Title_English: "Mr.",
    firstname_English: "Varakorn",
    lastname_English: "Pimton",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "นักวิเคราะห์ระบบ Logistics",
    pL_Group: "S2",
    birthDay: "May 17 1992 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "varakorp@scg.com",
    report_To: "Mr. Nattapol Pongpaothai",
    report_to_Position_Name: "ผู้จัดการ Logistics Operation",
    report_Email: "nattpong@scg.com",
    nick_Name: "ม๊อบ",
    mobileNumber: "0812345678",
    login: "varakorp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001457",
    name_title: "นาย",
    firstname: "ธนวัฒน์",
    lastname: "เจียรักสุวรรณ์",
    name_Title_English: "Mr.",
    firstname_English: "Thanawat",
    lastname_English: "Jearruksuwan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O3",
    birthDay: "Feb 14 1994 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thanawje@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "โต้ง",
    mobileNumber: "0812345678",
    login: "thanawje",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001459",
    name_title: "นาย",
    firstname: "กิตติศักดิ์",
    lastname: "วงศ์เสงี่ยม",
    name_Title_English: "Mr.",
    firstname_English: "Kittisak",
    lastname_English: "Wongsangiam",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการคลังสินค้า",
    pL_Group: "O3",
    birthDay: "Jun 12 1992 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kitwongs@scg.com",
    report_To: "Mr. Nakarin Pitakkorphol",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการคลังสินค้า 1",
    report_Email: "nakarinp@scg.com",
    nick_Name: "เป้",
    mobileNumber: "0812345678",
    login: "kitwongs",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001461",
    name_title: "นาย",
    firstname: "ธนกฤต",
    lastname: "บุญช่วย",
    name_Title_English: "Mr.",
    firstname_English: "Thanakrit",
    lastname_English: "Boonchuay",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการคลังสินค้า",
    pL_Group: "O3",
    birthDay: "Dec 23 1991 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thanakrb@scg.com",
    report_To: "Mr. Nakarin Pitakkorphol",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการคลังสินค้า 1",
    report_Email: "nakarinp@scg.com",
    nick_Name: "ดอย",
    mobileNumber: "0812345678",
    login: "thanakrb",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901031",
    name_title: "นาย",
    firstname: "ธณชัย",
    lastname: "รักขันทอง",
    name_Title_English: "Mr.",
    firstname_English: "Tanachai",
    lastname_English: "Rakkhanthong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 2",
    pL_Group: "O0",
    birthDay: "May 13 1993 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "tanachar@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "เต้ย",
    mobileNumber: "0812345678",
    login: "tanachar",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901029",
    name_title: "นาย",
    firstname: "วิศวะ",
    lastname: "ปั้นไล้",
    name_Title_English: "Mr.",
    firstname_English: "Visava",
    lastname_English: "Panlai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 2",
    pL_Group: "O0",
    birthDay: "Mar  7 1990 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "visavapa@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "บิ๊ก",
    mobileNumber: "0812345678",
    login: "visavapa",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001564",
    name_title: "นาย",
    firstname: "โสภณ",
    lastname: "สมขลัง",
    name_Title_English: "Mr.",
    firstname_English: "Sophon",
    lastname_English: "Somkhlang",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต TC-Wax",
    pL_Group: "O3",
    birthDay: "Sep 23 1988 12:00AM",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sophonso@scg.com",
    report_To: "Mr. Suwat Sotana",
    report_to_Position_Name: "ผจผ.ผลิต Pipe Compounding",
    report_Email: "suwaso@scg.com",
    nick_Name: "",
    mobileNumber: "0812345678",
    login: "sophonso",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001465",
    name_title: "นางสาว",
    firstname: "สรนันท์",
    lastname: "บัวลพ",
    name_Title_English: "Miss",
    firstname_English: "Soranan",
    lastname_English: "Boulop",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงาน Logistics Support",
    pL_Group: "O3",
    birthDay: "1993-09-20",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sorananb@scg.com",
    report_To: "Mrs. Rujiporn Keepthong",
    report_to_Position_Name: "ผจผ.Logistics Support",
    report_Email: "rujipork@scg.com",
    nick_Name: "หยก",
    mobileNumber: "0812345678",
    login: "sorananb",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001477",
    name_title: "นาย",
    firstname: "ธนพล",
    lastname: "บุญล้อม",
    name_Title_English: "Mr.",
    firstname_English: "Tanaphon",
    lastname_English: "Boonlom",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ Roto Compounding",
    pL_Group: "O3",
    birthDay: "1994-12-03",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "tanaphob@scg.com",
    report_To: "Mr. Wanich Pomsuk",
    report_to_Position_Name: "ผจก.แผนกควบคุมคุณภาพ Roto Compounding",
    report_Email: "wanichp@scg.com",
    nick_Name: "เนี๊ยบ",
    mobileNumber: "0812345678",
    login: "tanaphob",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001476",
    name_title: "นาย",
    firstname: "ธวัชชัย",
    lastname: "มั่งคั่ง",
    name_Title_English: "Mr.",
    firstname_English: "Thawatchai",
    lastname_English: "Mangkang",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PAL",
    pL_Group: "O3",
    birthDay: "1993-09-13",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thawmang@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "ห้าวเป้ง",
    mobileNumber: "0812345678",
    login: "thawmang",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001478",
    name_title: "นาย",
    firstname: "อภิวัฒน์",
    lastname: "นิคมเขตต์",
    name_Title_English: "Mr.",
    firstname_English: "Apiwat",
    lastname_English: "Nikomket",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ Roto Compounding",
    pL_Group: "O3",
    birthDay: "1994-10-20",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "apiwatni@scg.com",
    report_To: "Mr. Wanich Pomsuk",
    report_to_Position_Name: "ผจก.แผนกควบคุมคุณภาพ Roto Compounding",
    report_Email: "wanichp@scg.com",
    nick_Name: "แสตมป์",
    mobileNumber: "0812345678",
    login: "apiwatni",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001472",
    name_title: "นางสาว",
    firstname: "สายขิม",
    lastname: "พนาเวศร์",
    name_Title_English: "Miss",
    firstname_English: "Saikhim",
    lastname_English: "Panawes",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "วิศวกรประกันคุณภาพ PE",
    pL_Group: "S2",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "saikhimp@scg.com",
    report_To: "Miss Sarinya Luangsukrerk",
    report_to_Position_Name: "ผู้จัดการแผนกประกันคุณภาพ PE",
    report_Email: "sarinyal@scg.com",
    nick_Name: "ขิม",
    mobileNumber: "0812345678",
    login: "saikhimp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001469",
    name_title: "นาย",
    firstname: "พิพัฒน์",
    lastname: "ทรัพย์สุขสำราญ",
    name_Title_English: "Mr.",
    firstname_English: "Pipat",
    lastname_English: "Subsuksumran",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "PP 1,2 Production Engineer",
    pL_Group: "S2",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "pipatsub@scg.com",
    report_To: "Mr. Noppon Monggranonchai",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 1,2",
    report_Email: "nopponm@scg.com",
    nick_Name: "พัฒน์",
    mobileNumber: "0800052429",
    login: "pipatsub",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001480",
    name_title: "นาย",
    firstname: "วิทยา",
    lastname: "เครือเพ็ง",
    name_Title_English: "Mr.",
    firstname_English: "Withaya",
    lastname_English: "Kruapheng",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O3",
    birthDay: "1994-05-29",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "withayak@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "แมน",
    mobileNumber: "0812345678",
    login: "withayak",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001487",
    name_title: "นาย",
    firstname: "กิตติชัย",
    lastname: "ขจรกิตติ์รวี",
    name_Title_English: "Mr.",
    firstname_English: "Kittichai",
    lastname_English: "Khajornkitrawee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "วิศวกรผลิต Catalyst",
    pL_Group: "S2",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "kittipun@scg.com",
    report_To: "Mr. Pakorn Aumyoo",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต Catalyst & Pilot Plant",
    report_Email: "pakorau@scg.com",
    nick_Name: "โดนัท",
    mobileNumber: "0812345678",
    login: "kittipun",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001485",
    name_title: "นางสาว",
    firstname: "ภิญธดา",
    lastname: "ศรีงาม",
    name_Title_English: "Miss",
    firstname_English: "Pinthada",
    lastname_English: "Sringam",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรระบบอาชีวอนามัยและความปลอดภัย",
    pL_Group: "S2",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "pinthasr@scg.com",
    report_To: "Miss Chuenjit Sukpan",
    report_to_Position_Name: "ผจก.แผนกระบบอาชีวอนามัยและความปลอดภัย",
    report_Email: "chuenjis@scg.com",
    nick_Name: "โม",
    mobileNumber: "0812345678",
    login: "pinthasr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001488",
    name_title: "นางสาว",
    firstname: "วันวิสา",
    lastname: "รักทอง",
    name_Title_English: "Miss",
    firstname_English: "Wanwisa",
    lastname_English: "Rukthong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "วิศวกรประกันคุณภาพ PP&Compounding",
    pL_Group: "S2",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "wanwisar@scg.com",
    report_To: "Mrs. Rungtip Lertmunkong",
    report_to_Position_Name: "ผจก.แผนกประกันคุณภาพ PP & Compounding",
    report_Email: "rungtile@scg.com",
    nick_Name: "ฝ้าย",
    mobileNumber: "0812345678",
    login: "wanwisar",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001501",
    name_title: "นาย",
    firstname: "นันทิพัฒน์",
    lastname: "อริยพัฒน์",
    name_Title_English: "Mr.",
    firstname_English: "Nantiphat",
    lastname_English: "Ariyaphat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "Catalyst Production Operator",
    pL_Group: "O3",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "nantipha@scg.com",
    report_To: "Mr. Somchai Phonsiri",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "somchpho@scg.com",
    nick_Name: "",
    mobileNumber: "0920814376",
    login: "nantipha",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001502",
    name_title: "นาย",
    firstname: "กฤตนัย",
    lastname: "เงินขาว",
    name_Title_English: "Mr.",
    firstname_English: "Kittanai",
    lastname_English: "Ngernkhao",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรระบบอาชีวอนามัยและความปลอดภัย",
    pL_Group: "S2",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "kittanan@scg.com",
    report_To: "Miss Chuenjit Sukpan",
    report_to_Position_Name: "ผจก.แผนกระบบอาชีวอนามัยและความปลอดภัย",
    report_Email: "chuenjis@scg.com",
    nick_Name: "แม็ค",
    mobileNumber: "0812345678",
    login: "kittanan",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901038",
    name_title: "นาย",
    firstname: "อนนต์",
    lastname: "ถาเกิด",
    name_Title_English: "Mr.",
    firstname_English: "Anon",
    lastname_English: "Thakoed",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 2",
    pL_Group: "O0",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "anonthak@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "นนต์",
    mobileNumber: "0812345678",
    login: "anonthak",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901037",
    name_title: "นาย",
    firstname: "รณภูมิ",
    lastname: "แสนโต",
    name_Title_English: "Mr.",
    firstname_English: "Ronnapoom",
    lastname_English: "Santo",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 2",
    pL_Group: "O0",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "ronnapos@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "แจ็ค",
    mobileNumber: "0812345678",
    login: "ronnapos",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001517",
    name_title: "นาย",
    firstname: "อภิสิทธิ์",
    lastname: "ประกายกิจ",
    name_Title_English: "Mr.",
    firstname_English: "Apisit",
    lastname_English: "Prakaikij",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "LDPE Production Operator",
    pL_Group: "O3",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "apisitpr@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "แชมป์",
    mobileNumber: "0960891981",
    login: "apisitpr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001516",
    name_title: "นาย",
    firstname: "กิตติ",
    lastname: "สุขสวัสดิ์",
    name_Title_English: "Mr.",
    firstname_English: "Kitti",
    lastname_English: "Suksawat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O3",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "kittisuk@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "เบนซ์",
    mobileNumber: "0812345678",
    login: "kittisuk",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001336",
    name_title: "นาย",
    firstname: "ชวนากร",
    lastname: "ชนะเคราะห์",
    name_Title_English: "Mr.",
    firstname_English: "Chawanakorn",
    lastname_English: "Chanakraoh",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O4",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "chawanch@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "เมาส์",
    mobileNumber: "0812345678",
    login: "chawanch",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001524",
    name_title: "นาย",
    firstname: "ขจรพงศ์",
    lastname: "ส่งให้",
    name_Title_English: "Mr.",
    firstname_English: "Khachonphong",
    lastname_English: "Songhai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "PP 2 Production Operator",
    pL_Group: "O3",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "khachson@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "วิศวกรผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "",
    mobileNumber: "0948397896",
    login: "khachson",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001846",
    name_title: "นาย",
    firstname: "กฤษณะ",
    lastname: "ศรีสุรินทร์",
    name_Title_English: "Mr.",
    firstname_English: "Kritsana",
    lastname_English: "Srisurin",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HR Business Partner",
    departmentId: 13,
    departmentShortName: "HR",
    position: "Assistant Manager-HR Business Partner",
    pL_Group: "S3",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "kritsris@scg.com",
    report_To: "Mr. Puttinun Moungprasert",
    report_to_Position_Name: "Manager-HR Business Partner",
    report_Email: "puttinum@scg.com",
    nick_Name: "Gap",
    mobileNumber: "0812345678",
    login: "kritsris",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000796",
    name_title: "นาย",
    firstname: "ภูมิมินทร์",
    lastname: "ใจหาญ",
    name_Title_English: "Mr.",
    firstname_English: "Phoommin",
    lastname_English: "Jaihan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O4",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "phoommij@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "อู๊ด",
    mobileNumber: "0812345678",
    login: "phoommij",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001527",
    name_title: "นาย",
    firstname: "ณัฐนันท์",
    lastname: "วงศ์ธัญวัฒน์",
    name_Title_English: "Mr.",
    firstname_English: "Nattanan",
    lastname_English: "Wongtanyawat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "วิศวกรผลิต LDPE",
    pL_Group: "S2",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "nattanaw@scg.com",
    report_To: "Mr. Pat Nartpochananon",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LDPE",
    report_Email: "patn@scg.com",
    nick_Name: "หนุน",
    mobileNumber: "0812345678",
    login: "nattanaw",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901040",
    name_title: "นาย",
    firstname: "ตุลวิทย์",
    lastname: "วิโรจน์ศิริ",
    name_Title_English: "Mr.",
    firstname_English: "Tulwit",
    lastname_English: "Virodsiri",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "เจ้าหน้าที่ความปลอดภัยปฏิบัติการภาคสนาม",
    pL_Group: "O0",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "tulwitvi@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",
    report_Email: "somrerkn@scg.com",
    nick_Name: "เบสท์",
    mobileNumber: "0812345678",
    login: "tulwitvi",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001529",
    name_title: "นาย",
    firstname: "วีรากร",
    lastname: "สิริธัญญาภัค",
    name_Title_English: "Mr.",
    firstname_English: "Virakorn",
    lastname_English: "Sirithanyaphak",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "วิศวกรผลิต PP 1,2",
    pL_Group: "S2",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "virakors@scg.com",
    report_To: "Mr. Noppon Monggranonchai",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 1,2",
    report_Email: "nopponm@scg.com",
    nick_Name: "วิน",
    mobileNumber: "0812345678",
    login: "virakors",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001532",
    name_title: "นาย",
    firstname: "บัญชา",
    lastname: "นงค์ประโคน",
    name_Title_English: "Mr.",
    firstname_English: "Bancha",
    lastname_English: "Nongprakhon",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O3",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "banchnon@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "ตู่",
    mobileNumber: "0812345678",
    login: "banchnon",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001533",
    name_title: "นาย",
    firstname: "ธนพล",
    lastname: "อภินันทเวช",
    name_Title_English: "Mr.",
    firstname_English: "Thanapon",
    lastname_English: "Apinuntavech",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรระบบอาชีวอนามัยและความปลอดภัย",
    pL_Group: "S2",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "tanapapi@scg.com",
    report_To: "Miss Chuenjit Sukpan",
    report_to_Position_Name: "ผจก.แผนกระบบอาชีวอนามัยและความปลอดภัย",
    report_Email: "chuenjis@scg.com",
    nick_Name: "ชาร์ป",
    mobileNumber: "0812345678",
    login: "tanapapi",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001534",
    name_title: "นาย",
    firstname: "ชัชวาลย์",
    lastname: "เสมประเสริฐ",
    name_Title_English: "Mr.",
    firstname_English: "Chatchawan",
    lastname_English: "Semprasroeth",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "HDPE 1 Production Operator",
    pL_Group: "O3",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "chatcsem@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "ปอ",
    mobileNumber: "0876125729",
    login: "chatcsem",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901043",
    name_title: "นาย",
    firstname: "ธีระพงษ์",
    lastname: "บุญฆ้อง",
    name_Title_English: "Mr.",
    firstname_English: "Teeraphong",
    lastname_English: "Bunkhong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 2",
    pL_Group: "O0",
    birthDay: null,
    gender: null,
    nationality: null,
    address_Office: "",
    telephone_Business: "",
    email: "teerabun@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "เจมส์",
    mobileNumber: "0812345678",
    login: "teerabun",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001535",
    name_title: "นาย",
    firstname: "ชาครีย์",
    lastname: "จารุพักตรานนท์",
    name_Title_English: "Mr.",
    firstname_English: "Chakree",
    lastname_English: "Jarupaktranonth",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "นักวิเคราะห์ระบบ Logistics",
    pL_Group: "S2",
    birthDay: "1990-02-15",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "0861490184",
    email: "chakreej@scg.com",
    report_To: "Mr. Banjerd Waksoongnoen",
    report_to_Position_Name: "ผู้จัดการ Outbound Logistics",
    report_Email: "banjerdw@scg.com",
    nick_Name: "กระบุง",
    mobileNumber: "0812345678",
    login: "chakreej",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-000887",
    name_title: "นาย",
    firstname: "คณาเดช",
    lastname: "ลี้ลาดภัย",
    name_Title_English: "Mr.",
    firstname_English: "Khanadet",
    lastname_English: "Leelartbhai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "หัวหน้างานผลิต PP 2",
    pL_Group: "O5",
    birthDay: "1982-10-01",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "khanadel@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "วุฒิ",
    mobileNumber: "0812345678",
    login: "khanadel",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001541",
    name_title: "นาย",
    firstname: "ทรงเกียรติ",
    lastname: "แจ้งเสมอ",
    name_Title_English: "Mr.",
    firstname_English: "Songkiad",
    lastname_English: "Chaengsamoe",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "LLDPE Production Operator",
    pL_Group: "O3",
    birthDay: "1997-04-29",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "songkcha@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "โน่",
    mobileNumber: "0624027829",
    login: "songkcha",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001542",
    name_title: "นาย",
    firstname: "ธีรชัย",
    lastname: "บำรุงเกียรติ",
    name_Title_English: "Mr.",
    firstname_English: "Teerachai",
    lastname_English: "Bamrungkiat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O3",
    birthDay: "1997-01-11",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "teerachb@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "เติร์ด",
    mobileNumber: "0812345678",
    login: "teerachb",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001540",
    name_title: "นาย",
    firstname: "วีระยุทธ",
    lastname: "มุสิกพงศ์",
    name_Title_English: "Mr.",
    firstname_English: "Weerayuth",
    lastname_English: "Musikapong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O3",
    birthDay: "1995-09-14",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "weerayum@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "เจ",
    mobileNumber: "0812345678",
    login: "weerayum",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001544",
    name_title: "นาย",
    firstname: "หฤษฎ์",
    lastname: "เขมสิริเกษม",
    name_Title_English: "Mr.",
    firstname_English: "Haris",
    lastname_English: "Khemasirikasem",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "นักวิเคราะห์ระบบ Logistics",
    pL_Group: "S2",
    birthDay: "1991-09-19",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "hariskhe@scg.com",
    report_To: "Mr. Nattapol Pongpaothai",
    report_to_Position_Name: "ผู้จัดการ Logistics Operation",
    report_Email: "nattpong@scg.com",
    nick_Name: "เบนซ์",
    mobileNumber: "0812345678",
    login: "hariskhe",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001550",
    name_title: "นาย",
    firstname: "นัทธวุฒ",
    lastname: "โกฎคำ",
    name_Title_English: "Mr.",
    firstname_English: "Nattawut",
    lastname_English: "Kotkham",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O3",
    birthDay: "1996-11-11",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nattkotk@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "ดอม",
    mobileNumber: "0812345678",
    login: "nattkotk",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001543",
    name_title: "นาย",
    firstname: "ศรินทร์",
    lastname: "ขอถาวรวงศ์",
    name_Title_English: "Mr.",
    firstname_English: "Sarin",
    lastname_English: "Korthawornwong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "วิศวกรผลิต HDPE 1",
    pL_Group: "S2",
    birthDay: "1992-12-05",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sarinkor@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต HDPE 1",
    report_Email: "sittharl@scg.com",
    nick_Name: "แชมป์",
    mobileNumber: "0812345678",
    login: "sarinkor",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001553",
    name_title: "นาย",
    firstname: "อลงกรณ์",
    lastname: "จันทร์แจ้ง",
    name_Title_English: "Mr.",
    firstname_English: "Alongkorn",
    lastname_English: "Chanchaeng",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O3",
    birthDay: "1996-08-25",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "alongkoc@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "เท่ห์",
    mobileNumber: "0812345678",
    login: "alongkoc",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001552",
    name_title: "นาย",
    firstname: "บุญช่วย",
    lastname: "กล้าฉลาด",
    name_Title_English: "Mr.",
    firstname_English: "Boonchuay",
    lastname_English: "Klachalad",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PAL",
    pL_Group: "O3",
    birthDay: "1995-11-11",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "boonchkl@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "เอก",
    mobileNumber: "0812345678",
    login: "boonchkl",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001554",
    name_title: "นางสาว",
    firstname: "มุขครินทร์",
    lastname: "อารีบี",
    name_Title_English: "Miss",
    firstname_English: "Mukkarin",
    lastname_English: "Oareebee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O3",
    birthDay: "1996-11-20",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "mukkario@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "มาราตี",
    mobileNumber: "0812345678",
    login: "mukkario",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001559",
    name_title: "นาย",
    firstname: "ชัยวัฒน์",
    lastname: "วิมลอนุพงษ์",
    name_Title_English: "Mr.",
    firstname_English: "Chaiwat",
    lastname_English: "Vimonanupong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "วิศวกรผลิต Catalyst",
    pL_Group: "S2",
    birthDay: "1995-01-23",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chaiwatv@scg.com",
    report_To: "Mr. Pakorn Aumyoo",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต Catalyst & Pilot Plant",
    report_Email: "pakorau@scg.com",
    nick_Name: "เจ",
    mobileNumber: "0812345678",
    login: "chaiwatv",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001560",
    name_title: "นาย",
    firstname: "พงษ์ธนา",
    lastname: "ณัฐพูลวัฒน์",
    name_Title_English: "Mr.",
    firstname_English: "Pongtana",
    lastname_English: "Natpoonwat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "วิศวกรผลิต LLDPE",
    pL_Group: "S1",
    birthDay: "1995-08-23",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pongtann@scg.com",
    report_To: "Mr. Sophon Kiatpradab",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LLDPE",
    report_Email: "sophoki@scg.com",
    nick_Name: "น็อต",
    mobileNumber: "0853557595",
    login: "pongtann",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001558",
    name_title: "นาย",
    firstname: "วรเมธ",
    lastname: "กาญจนศร",
    name_Title_English: "Mr.",
    firstname_English: "Worameth",
    lastname_English: "Kanjanasorn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "วิศวกรผลิต PP 1,2",
    pL_Group: "S2",
    birthDay: "1994-07-02",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "worametk@scg.com",
    report_To: "Mr. Noppon Monggranonchai",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 1,2",
    report_Email: "nopponm@scg.com",
    nick_Name: "บอม",
    mobileNumber: "0812345678",
    login: "worametk",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001819",
    name_title: "นาย",
    firstname: "วัลลภ",
    lastname: "อาจวิชัย",
    name_Title_English: "Mr.",
    firstname_English: "Wallop",
    lastname_English: "Ajwichai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O3",
    birthDay: "1989-06-29",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "wallopaj@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "ปอนด์",
    mobileNumber: "0970064440",
    login: "wallopaj",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001565",
    name_title: "นางสาว",
    firstname: "ชนัญชิดา",
    lastname: "พรหมสาขา ณ สกลนคร",
    name_Title_English: "Miss",
    firstname_English: "Chananchida",
    lastname_English: "Phromsakha Na Sakonnakorn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "PP 1,2 Production Engineer",
    pL_Group: "S1",
    birthDay: "1994-09-23",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chanaphr@scg.com",
    report_To: "Mr. Patinya Pipatpratanporn",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 1,2",
    report_Email: "patinyap@scg.com",
    nick_Name: "",
    mobileNumber: "0895091707",
    login: "chanaphr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001568",
    name_title: "นาย",
    firstname: "พิษณุวัฒน์",
    lastname: "อาจบุรายณ์",
    name_Title_English: "Mr.",
    firstname_English: "Pitsanuwat",
    lastname_English: "Artburai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O3",
    birthDay: "1994-03-03",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pitsanua@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "แน็ค",
    mobileNumber: "0812345678",
    login: "pitsanua",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001567",
    name_title: "นาย",
    firstname: "สุทธิพงศ์",
    lastname: "เพชรเรืองโรจน์",
    name_Title_English: "Mr.",
    firstname_English: "Suttipong",
    lastname_English: "Pechruengroj",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O3",
    birthDay: "1996-04-05",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "suttipec@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "ปังปอนด์",
    mobileNumber: "0812345678",
    login: "suttipec",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001569",
    name_title: "นาย",
    firstname: "ชนะพล",
    lastname: "พงศ์ธีรพร",
    name_Title_English: "Mr.",
    firstname_English: "Chanapon",
    lastname_English: "Pongteeraporn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "วิศวกรประกันคุณภาพ PE",
    pL_Group: "S2",
    birthDay: "1992-12-19",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chanappo@scg.com",
    report_To: "Miss Sarinya Luangsukrerk",
    report_to_Position_Name: "ผู้จัดการแผนกประกันคุณภาพ PE",
    report_Email: "sarinyal@scg.com",
    nick_Name: "พล",
    mobileNumber: "0812345678",
    login: "chanappo",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001573",
    name_title: "นาย",
    firstname: "ชยางกูร",
    lastname: "นุชนาฎ",
    name_Title_English: "Mr.",
    firstname_English: "Changyangkun",
    lastname_English: "Nuchanad",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O3",
    birthDay: "1996-10-06",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "changyan@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "แจ็ค",
    mobileNumber: "0812345678",
    login: "changyan",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001572",
    name_title: "นาย",
    firstname: "ลิขิต",
    lastname: "ผลไธสง",
    name_Title_English: "Mr.",
    firstname_English: "Likhit",
    lastname_English: "Phonthaisong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O3",
    birthDay: "1996-10-29",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "likhipho@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ปอ",
    mobileNumber: "0812345678",
    login: "likhipho",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001571",
    name_title: "นาย",
    firstname: "วรวุฒิ",
    lastname: "สุวรรณราย",
    name_Title_English: "Mr.",
    firstname_English: "Worawut",
    lastname_English: "Suwannarai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการคลังสินค้า",
    pL_Group: "O3",
    birthDay: "1996-11-13",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "worawusu@scg.com",
    report_To: "Mr. Nakarin Pitakkorphol",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการคลังสินค้า 1",
    report_Email: "nakarinp@scg.com",
    nick_Name: "วุฒิ",
    mobileNumber: "0812345678",
    login: "worawusu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001574",
    name_title: "นาย",
    firstname: "ณัฐวุฒิ",
    lastname: "พันธ์ทอง",
    name_Title_English: "Mr.",
    firstname_English: "Natthawut",
    lastname_English: "Phanthong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "LDPE Production Operator",
    pL_Group: "O3",
    birthDay: "1996-07-24",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nattphan@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "วุฒิ",
    mobileNumber: "0934201841",
    login: "nattphan",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901046",
    name_title: "นาย",
    firstname: "ธวัชชัย",
    lastname: "ถนอมทรัพย์",
    name_Title_English: "Mr.",
    firstname_English: "Thawatchal",
    lastname_English: "Thanomsub",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 2",
    pL_Group: "O0",
    birthDay: "1995-08-01",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thawthan@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",
    report_Email: "somrerkn@scg.com",
    nick_Name: "หนึ่ง",
    mobileNumber: "0812345678",
    login: "thawthan",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001575",
    name_title: "นาย",
    firstname: "ปรม",
    lastname: "ไวกสิการ",
    name_Title_English: "Mr.",
    firstname_English: "Parom",
    lastname_English: "Waikasikarn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "วิศวกรประกันคุณภาพ PP&Compounding",
    pL_Group: "S2",
    birthDay: "1992-08-03",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "paromwai@scg.com",
    report_To: "Mrs. Rungtip Lertmunkong",
    report_to_Position_Name: "ผจก.แผนกประกันคุณภาพ PP & Compounding",
    report_Email: "rungtile@scg.com",
    nick_Name: "เพิธ",
    mobileNumber: "0625255218",
    login: "paromwai",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901047",
    name_title: "นางสาว",
    firstname: "ศิรินภา",
    lastname: "นวลจันทร์",
    name_Title_English: "Miss",
    firstname_English: "Sirinapa",
    lastname_English: "Nuanchan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานสัญญาจ้างปฏิบัติการจัดส่ง",
    pL_Group: "O0",
    birthDay: "1989-10-24",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sirinapn@scg.com",
    report_To: "Mr. Pornprasit Wannasomroeng",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการจัดส่ง",
    report_Email: "pornpraw@scg.com",
    nick_Name: "เชอร์รี่",
    mobileNumber: "0812345678",
    login: "sirinapn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901048",
    name_title: "นางสาว",
    firstname: "ชาลินี",
    lastname: "ชาติภิรมย์",
    name_Title_English: "Miss",
    firstname_English: "Chalinee",
    lastname_English: "Chatphirom",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "เจ้าหน้าที่ดูแลระบบเอกสารราชการ",
    pL_Group: "O0",
    birthDay: "1990-10-16",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chalinec@scg.com",
    report_To: "Miss Sasiphen Laimai",
    report_to_Position_Name: "ผู้จัดการแผนกสิ่งแวดล้อม",
    report_Email: "sasiphel@scg.com",
    nick_Name: "แอ๊ะ",
    mobileNumber: "0812345678",
    login: "chalinec",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001577",
    name_title: "นาย",
    firstname: "ภานุวิทย์",
    lastname: "ธนาวัชรวรดิษฐ์",
    name_Title_English: "Mr.",
    firstname_English: "Panuvit",
    lastname_English: "Thanavatcharavoradit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรระบบอาชีวอนามัยและความปลอดภัย",
    pL_Group: "S2",
    birthDay: "1991-02-09",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "panuvits@scg.com",
    report_To: "Miss Chuenjit Sukpan",
    report_to_Position_Name: "ผจก.แผนกระบบอาชีวอนามัยและความปลอดภัย",
    report_Email: "chuenjis@scg.com",
    nick_Name: "บิ๊ก",
    mobileNumber: "0812345678",
    login: "panuvits",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001579",
    name_title: "นาย",
    firstname: "พชรพล",
    lastname: "พุ่มสุวรรณ์",
    name_Title_English: "Mr.",
    firstname_English: "Phatcharapon",
    lastname_English: "Pumsuwan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O3",
    birthDay: "1998-03-07",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "phatchpu@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "น๊อต",
    mobileNumber: "0812345678",
    login: "phatchpu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001581",
    name_title: "นาย",
    firstname: "นเรนทร์ฤทธิ์",
    lastname: "หะกาศ",
    name_Title_English: "Mr.",
    firstname_English: "Narainrit",
    lastname_English: "Hakad",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O3",
    birthDay: "1995-06-14",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "narainrh@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "เบลล์",
    mobileNumber: "0812345678",
    login: "narainrh",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001583",
    name_title: "นาย",
    firstname: "ภาคภูมิ",
    lastname: "สุทธิรักษ์รัตน",
    name_Title_English: "Mr.",
    firstname_English: "Pakpoom",
    lastname_English: "Sutthirakrattana",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O3",
    birthDay: "1997-10-05",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pakposut@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "ฟลุ๊ค",
    mobileNumber: "0812345678",
    login: "pakposut",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001584",
    name_title: "นาย",
    firstname: "ทัตพงศ์",
    lastname: "รัตนะโสภณชัย",
    name_Title_English: "Mr.",
    firstname_English: "Tadpong",
    lastname_English: "Ratanasoponchai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "Environmental Engineer",
    pL_Group: "S1",
    birthDay: "1992-06-19",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "tadpongr@scg.com",
    report_To: "Miss Nantawan Rooplaor",
    report_to_Position_Name: "ผู้จัดการแผนกสิ่งแวดล้อม",
    report_Email: "nantawro@scg.com",
    nick_Name: "",
    mobileNumber: "0832948050",
    login: "tadpongr",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001594",
    name_title: "นาย",
    firstname: "นัทธพงศ์",
    lastname: "ปราบทุกข์",
    name_Title_English: "Mr.",
    firstname_English: "Nuttapong",
    lastname_English: "Prabtuk",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "วิศวกรผลิต HDPE 1",
    pL_Group: "S2",
    birthDay: "1995-08-12",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nuttapra@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต HDPE 1",
    report_Email: "sittharl@scg.com",
    nick_Name: "นัท",
    mobileNumber: "0812345678",
    login: "nuttapra",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001593",
    name_title: "นางสาว",
    firstname: "สวิตา",
    lastname: "เลิศสุโภชวณิชย์",
    name_Title_English: "Miss",
    firstname_English: "Sawita",
    lastname_English: "Lertsupochavanich",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรประจำส่วนอาชีวอนามัย ความปลอดภัยและสิ่งแวดล้อม",
    pL_Group: "S4",
    birthDay: "1979-02-20",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sawitale@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผจส.อาชีวอนามัยความปลอดภัยและสิ่งแวดล้อม",
    report_Email: "sittharl@scg.com",
    nick_Name: "หน้อย",
    mobileNumber: "0812345678",
    login: "sawitale",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901052",
    name_title: "นางสาว",
    firstname: "ธัญชนก",
    lastname: "มณีสุธรรม",
    name_Title_English: "Miss",
    firstname_English: "Thanchanok",
    lastname_English: "Maneesutham",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HR Business Partner",
    departmentId: 13,
    departmentShortName: "HR",
    position: "เจ้าหน้าที่ HRIS",
    pL_Group: "O0",
    birthDay: "1990-12-03",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thanchma@scg.com",
    report_To: "Mr. Puttinun Moungprasert",
    report_to_Position_Name: "Manager-HR Business Partner",
    report_Email: "puttinum@scg.com",
    nick_Name: "แป้ง",
    mobileNumber: "0812345678",
    login: "thanchma",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001598",
    name_title: "นาย",
    firstname: "ชุติพงษ์",
    lastname: "วิจักษ์ปภัสสร",
    name_Title_English: "Mr.",
    firstname_English: "Chutipong",
    lastname_English: "Wichakpapatsorn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HR Business Partner",
    departmentId: 13,
    departmentShortName: "HR",
    position: "Assistant Manager-HR Business Partner",
    pL_Group: "S1",
    birthDay: "30.10.1992",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chutipow@scg.com",
    report_To: "Mr. Puttinun Moungprasert",
    report_to_Position_Name: "Manager-HR Business Partner",
    report_Email: "puttinum@scg.com",
    nick_Name: "เก่ง",
    mobileNumber: "0812345678",
    login: "chutipow",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001596",
    name_title: "นาย",
    firstname: "สิทธิชัย",
    lastname: "ศรีสุข",
    name_Title_English: "Mr.",
    firstname_English: "Sittichai",
    lastname_English: "Srisuk",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O3",
    birthDay: "03.07.1997",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sittsris@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "เบส",
    mobileNumber: "0812345678",
    login: "sittsris",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001600",
    name_title: "นาย",
    firstname: "ทฤษฎี",
    lastname: "เครือเพ็ง",
    name_Title_English: "Mr.",
    firstname_English: "Thissadee",
    lastname_English: "Kruapheng",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O3",
    birthDay: "30.05.1997",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thissadk@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "เฟรม",
    mobileNumber: "0812345678",
    login: "thissadk",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001599",
    name_title: "นาย",
    firstname: "วริศ",
    lastname: "รอดพ้น",
    name_Title_English: "Mr.",
    firstname_English: "Waris",
    lastname_English: "Rodpon",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O3",
    birthDay: "08.06.1996",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "warisrod@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ทอส",
    mobileNumber: "0812345678",
    login: "warisrod",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001603",
    name_title: "นาย",
    firstname: "ลาภวัต",
    lastname: "อัตตะชีวะ",
    name_Title_English: "Mr.",
    firstname_English: "Lappawat",
    lastname_English: "Attacheva",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O3",
    birthDay: "02.08.1997",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "lappawaa@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "เค็ม",
    mobileNumber: "0812345678",
    login: "lappawaa",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001602",
    name_title: "นาย",
    firstname: "ณัฐพล",
    lastname: "พรพิทยากุล",
    name_Title_English: "Mr.",
    firstname_English: "Nuttapol",
    lastname_English: "Pornpittayakul",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O3",
    birthDay: "03.10.1997",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nuttapor@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "นัท",
    mobileNumber: "0812345678",
    login: "nuttapor",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001604",
    name_title: "นาย",
    firstname: "ปภวิชญ์",
    lastname: "สมุทรเขต",
    name_Title_English: "Mr.",
    firstname_English: "Paphawit",
    lastname_English: "Samutket",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "Catalyst Production Operator",
    pL_Group: "O3",
    birthDay: "15.05.1997",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "paphawsa@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "วิศวกรผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "มาร์ค",
    mobileNumber: "0882614190",
    login: "paphawsa",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001605",
    name_title: "นาย",
    firstname: "พัชรพล",
    lastname: "เชื้อสุวรรณ์",
    name_Title_English: "Mr.",
    firstname_English: "Patcharapol",
    lastname_English: "Chuesuwan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O3",
    birthDay: "16.02.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "patchchu@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ไนซ์",
    mobileNumber: "0812345678",
    login: "patchchu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001608",
    name_title: "นาย",
    firstname: "ศิริเทวา",
    lastname: "ดรุณ",
    name_Title_English: "Mr.",
    firstname_English: "Siritava",
    lastname_English: "Daroon",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O3",
    birthDay: "01.10.1997",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "siritavd@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "ไทม์",
    mobileNumber: "0812345678",
    login: "siritavd",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001610",
    name_title: "นาย",
    firstname: "ธนพล",
    lastname: "ทะปะละ",
    name_Title_English: "Mr.",
    firstname_English: "Thanapon",
    lastname_English: "Tapala",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O3",
    birthDay: "17.01.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thantapa@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "บอส",
    mobileNumber: "0812345678",
    login: "thantapa",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001618",
    name_title: "นาย",
    firstname: "อนนต์",
    lastname: "พุ่มจันทร์",
    name_Title_English: "Mr.",
    firstname_English: "Anon",
    lastname_English: "Poomjun",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "LLDPE Production Operator",
    pL_Group: "O3",
    birthDay: "19.10.1997",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "anonpoom@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "นนท์",
    mobileNumber: "0945267885",
    login: "anonpoom",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001621",
    name_title: "นางสาว",
    firstname: "นลินรัตน์",
    lastname: "นามกระโทก",
    name_Title_English: "Miss",
    firstname_English: "Nalinrat",
    lastname_English: "Namkratoke",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรสิ่งแวดล้อม",
    pL_Group: "S1",
    birthDay: "10.02.1996",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nalinrna@scg.com",
    report_To: "Miss Sasiphen Laimai",
    report_to_Position_Name: "ผู้จัดการแผนกสิ่งแวดล้อม",
    report_Email: "sasiphel@scg.com",
    nick_Name: "บีม",
    mobileNumber: "0812345678",
    login: "nalinrna",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001622",
    name_title: "นาย",
    firstname: "ศรัณย์",
    lastname: "บรรณทอง",
    name_Title_English: "Mr.",
    firstname_English: "Saran",
    lastname_English: "Bannatong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "PP 1,2 Production Engineer",
    pL_Group: "S1",
    birthDay: "23.12.1993",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "saranban@scg.com",
    report_To: "Mr. Noppon Monggranonchai",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 1,2",
    report_Email: "nopponm@scg.com",
    nick_Name: "เพียว",
    mobileNumber: "0869272032",
    login: "saranban",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001630",
    name_title: "นาย",
    firstname: "ฤทธิเกียรติ",
    lastname: "มะธิโต",
    name_Title_English: "Mr.",
    firstname_English: "Rittikeat",
    lastname_English: "Mathito",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O3",
    birthDay: "02.02.1997",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "rittikem@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "วุฒิ",
    mobileNumber: "0812345678",
    login: "rittikem",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901055",
    name_title: "นาย",
    firstname: "อนันตชัย",
    lastname: "รักขันทอง",
    name_Title_English: "Mr.",
    firstname_English: "Anantachai",
    lastname_English: "Rakkhanthong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 1",
    pL_Group: "O0",
    birthDay: "01.05.1995",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "anantara@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "ต้อม",
    mobileNumber: "0812345678",
    login: "anantara",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901061",
    name_title: "นาย",
    firstname: "ชนาธิป",
    lastname: "ชายทวีป",
    name_Title_English: "Mr.",
    firstname_English: "Chanathip",
    lastname_English: "Chaithaweep",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "Fireman",
    pL_Group: "O0",
    birthDay: "05.01.1995",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chanatch@scg.com",
    report_To: "Mr. Kraisit Wannaratt",
    report_to_Position_Name: "ผจผ.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",
    report_Email: "kraisitw@scg.com",
    nick_Name: "เบนซ์",
    mobileNumber: "0991300561",
    login: "chanatch",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901054",
    name_title: "นาย",
    firstname: "ชัชวาล",
    lastname: "ชนะผล",
    name_Title_English: "Mr.",
    firstname_English: "Chatchawan",
    lastname_English: "Chanaphol",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 1",
    pL_Group: "O0",
    birthDay: "16.10.1992",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chchanap@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "กอล์ฟ",
    mobileNumber: "0812345678",
    login: "chchanap",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901057",
    name_title: "นาย",
    firstname: "จักรี",
    lastname: "เชาวมงคล",
    name_Title_English: "Mr.",
    firstname_English: "Jakkree",
    lastname_English: "Chaowamongkol",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 1",
    pL_Group: "O0",
    birthDay: "06.04.1995",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jakkreec@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "มีน",
    mobileNumber: "0812345678",
    login: "jakkreec",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901059",
    name_title: "นาย",
    firstname: "เจษฎา",
    lastname: "กูลรัตน์",
    name_Title_English: "Mr.",
    firstname_English: "Jassada",
    lastname_English: "Kulrat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 1",
    pL_Group: "O0",
    birthDay: "29.08.1996",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jassadku@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "บาส",
    mobileNumber: "0812345678",
    login: "jassadku",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901058",
    name_title: "นาย",
    firstname: "ไกรพล",
    lastname: "สิมสิน",
    name_Title_English: "Mr.",
    firstname_English: "Kaiphon",
    lastname_English: "Simsin",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 1",
    pL_Group: "O0",
    birthDay: "30.07.1992",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kaiphons@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "จุ้ย",
    mobileNumber: "0812345678",
    login: "kaiphons",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901053",
    name_title: "นาย",
    firstname: "กัณตพล",
    lastname: "ศรีหมอก",
    name_Title_English: "Mr.",
    firstname_English: "Kantaphon",
    lastname_English: "Simok",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 1",
    pL_Group: "O0",
    birthDay: "31.10.1994",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kantaphs@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "แฟรงค์",
    mobileNumber: "0812345678",
    login: "kantaphs",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901056",
    name_title: "นาย",
    firstname: "ขวัญชัย",
    lastname: "ทุยคำ",
    name_Title_English: "Mr.",
    firstname_English: "Khwanchai",
    lastname_English: "Thuykham",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 1",
    pL_Group: "O0",
    birthDay: "23.05.1997",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "khwancth@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "วิน",
    mobileNumber: "0812345678",
    login: "khwancth",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901060",
    name_title: "นาย",
    firstname: "นิธิไกร",
    lastname: "ไชโยทา",
    name_Title_English: "Mr.",
    firstname_English: "Nithikai",
    lastname_English: "Chaiyotha",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 1",
    pL_Group: "O0",
    birthDay: "11.05.1997",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nithikac@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "ทีม",
    mobileNumber: "0812345678",
    login: "nithikac",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001632",
    name_title: "นาย",
    firstname: "กฤษนันท์",
    lastname: "ตะริโย",
    name_Title_English: "Mr.",
    firstname_English: "Krissanun",
    lastname_English: "Tariyo",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "วิศวกรประกันคุณภาพ PE",
    pL_Group: "S1",
    birthDay: "14.08.1995",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "krissant@scg.com",
    report_To: "Miss Sarinya Luangsukrerk",
    report_to_Position_Name: "ผู้จัดการแผนกประกันคุณภาพ PE",
    report_Email: "sarinyal@scg.com",
    nick_Name: "เกมส์",
    mobileNumber: "0812345678",
    login: "krissant",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901066",
    name_title: "นาย",
    firstname: "อนุชิต",
    lastname: "ทองเหล็ก",
    name_Title_English: "Mr.",
    firstname_English: "Anuchit",
    lastname_English: "Thonglek",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 1",
    pL_Group: "O0",
    birthDay: "14.10.1994",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "anucthog@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "บีม",
    mobileNumber: "0812345678",
    login: "anucthog",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901065",
    name_title: "นาย",
    firstname: "จิณณวัตร",
    lastname: "ขุมเพ็ชร",
    name_Title_English: "Mr.",
    firstname_English: "Jinnawat",
    lastname_English: "Khoompech",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 1",
    pL_Group: "O0",
    birthDay: "01.09.1996",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jinnawak@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "เบิร์ด",
    mobileNumber: "0812345678",
    login: "jinnawak",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001633",
    name_title: "นาย",
    firstname: "นพดล",
    lastname: "มีลาภ",
    name_Title_English: "Mr.",
    firstname_English: "Nopphadon",
    lastname_English: "Milap",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O3",
    birthDay: "07.07.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nopphami@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ป็อก",
    mobileNumber: "0812345678",
    login: "nopphami",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001636",
    name_title: "นางสาว",
    firstname: "ชลิตา",
    lastname: "สมประสงค์",
    name_Title_English: "Miss",
    firstname_English: "Chalita",
    lastname_English: "Somprasong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "พนักงานควบคุมคุณภาพ - PCL",
    pL_Group: "O3",
    birthDay: "29.04.1997",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chalitas@scg.com",
    report_To: "Mr. Akkhrawat Nuangkaewsin",
    report_to_Position_Name: "ผู้จัดการแผนกควบคุมคุณภาพ Polyolefins",
    report_Email: "akkhrawn@scg.com",
    nick_Name: "เมย์",
    mobileNumber: "0812345678",
    login: "chalitas",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001752",
    name_title: "นาย",
    firstname: "กิตติชัย",
    lastname: "นามเชียงใต้",
    name_Title_English: "Mr.",
    firstname_English: "Kittichai",
    lastname_English: "Namcheangtai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O3",
    birthDay: "23.08.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kitticna@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "เอิร์ธ",
    mobileNumber: "0925233289",
    login: "kitticna",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901068",
    name_title: "นาย",
    firstname: "บัณฑูร",
    lastname: "โสธรกุล",
    name_Title_English: "Mr.",
    firstname_English: "Bantoon",
    lastname_English: "Sothornkul",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "เจ้าหน้าที่ความปลอดภัยปฏิบัติการภาคสนาม",
    pL_Group: "O0",
    birthDay: "30.05.1973",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "bantoons@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "เก๋",
    mobileNumber: "0812345678",
    login: "bantoons",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001762",
    name_title: "นาย",
    firstname: "ธีรพัฒน์",
    lastname: "รอดเขียว",
    name_Title_English: "Mr.",
    firstname_English: "Teerapat",
    lastname_English: "Rodkhiaw",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O3",
    birthDay: "28.03.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "teerapar@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "เดย์",
    mobileNumber: "0812345678",
    login: "teerapar",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001763",
    name_title: "นาย",
    firstname: "วรวิทย์",
    lastname: "พรหมจารี",
    name_Title_English: "Mr.",
    firstname_English: "Voravit",
    lastname_English: "Prommajaree",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O3",
    birthDay: "24.10.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "voravitp@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "ขนุน",
    mobileNumber: "0812345678",
    login: "voravitp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001764",
    name_title: "นาย",
    firstname: "วิษณุ",
    lastname: "หัตถวงษ์",
    name_Title_English: "Mr.",
    firstname_English: "Wisanu",
    lastname_English: "Hatthwngs",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O3",
    birthDay: "07.03.1999",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "wisanuha@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "นุ",
    mobileNumber: "0812345678",
    login: "wisanuha",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001765",
    name_title: "นางสาว",
    firstname: "อนัญญา",
    lastname: "สุขุมเจริญภัณฑ์",
    name_Title_English: "Miss",
    firstname_English: "Ananya",
    lastname_English: "Sukumjaroenphan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "วิศวกรผลิต LLDPE",
    pL_Group: "S2",
    birthDay: "06.01.1995",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "ananyasu@scg.com",
    report_To: "Mr. Sophon Kiatpradab",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LLDPE",
    report_Email: "sophoki@scg.com",
    nick_Name: "อิม",
    mobileNumber: "0812345678",
    login: "ananyasu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001774",
    name_title: "นาย",
    firstname: "ชนาธิป",
    lastname: "สรรพวิเศษ",
    name_Title_English: "Mr.",
    firstname_English: "Chanatip",
    lastname_English: "Sappavisad",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O3",
    birthDay: "14.10.1997",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chanatsa@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "กอล์ฟ",
    mobileNumber: "0812345678",
    login: "chanatsa",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001766",
    name_title: "นาย",
    firstname: "กลัญญู",
    lastname: "ตางาม",
    name_Title_English: "Mr.",
    firstname_English: "Kalunyoo",
    lastname_English: "Tangam",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "HDPE 1 Production Operator",
    pL_Group: "O3",
    birthDay: "13.05.1999",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kalunyot@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "ต๊าส",
    mobileNumber: "0980813810",
    login: "kalunyot",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001772",
    name_title: "นาย",
    firstname: "ณัฐวัฒน์",
    lastname: "มาถา",
    name_Title_English: "Mr.",
    firstname_English: "Nattawat",
    lastname_English: "Matha",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "HDPE 1 Production Operator",
    pL_Group: "O3",
    birthDay: "20.07.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nattamat@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "Field",
    mobileNumber: "0868382847",
    login: "nattamat",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001771",
    name_title: "ส.อ.",
    firstname: "พงศธร",
    lastname: "พันธ์สุข",
    name_Title_English: "Mr.",
    firstname_English: "Pongsathorn",
    lastname_English: "Pansuk",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O3",
    birthDay: "18.02.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pongspan@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "โน้ต",
    mobileNumber: "0812345678",
    login: "pongspan",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001775",
    name_title: "นาย",
    firstname: "ทรัพย์อนันต์",
    lastname: "จูมพันธ์",
    name_Title_English: "Mr.",
    firstname_English: "Sapanan",
    lastname_English: "Jumphan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "พนักงานผลิต LLDPE",
    pL_Group: "O3",
    birthDay: "17.06.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sapananj@scg.com",
    report_To: "Mr. Somchai Wetthawong",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต LLDPE",
    report_Email: "somchawe@scg.com",
    nick_Name: "วิว",
    mobileNumber: "0812345678",
    login: "sapananj",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001773",
    name_title: "นาย",
    firstname: "ศราวุธ",
    lastname: "เสืออุดม",
    name_Title_English: "Mr.",
    firstname_English: "Sarawut",
    lastname_English: "Sua-udom",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "HDPE 1 Production Operator",
    pL_Group: "O3",
    birthDay: "27.12.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sarawsua@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "แม๊ก",
    mobileNumber: "0961702398",
    login: "sarawsua",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001767",
    name_title: "นาย",
    firstname: "สรรเพชญ",
    lastname: "นวมดี",
    name_Title_English: "Mr.",
    firstname_English: "Sunphet",
    lastname_English: "Noumdee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O3",
    birthDay: "27.07.1995",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sunphetn@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "มาร์ค",
    mobileNumber: "0982509030",
    login: "sunphetn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001778",
    name_title: "นาย",
    firstname: "ศุภชัย",
    lastname: "เชื้อลี",
    name_Title_English: "Mr.",
    firstname_English: "Supachai",
    lastname_English: "Chualee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "Packing Operator",
    pL_Group: "O3",
    birthDay: "12.08.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "supacchu@scg.com",
    report_To: "Mr. Adul Bunprakob",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "adubu@scg.com",
    nick_Name: "ต้นตาล",
    mobileNumber: "0820369554",
    login: "supacchu",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001776",
    name_title: "นางสาว",
    firstname: "ชื่นจิต",
    lastname: "สุกป่าน",
    name_Title_English: "Miss",
    firstname_English: "Chuenjit",
    lastname_English: "Sukpan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "ผู้จัดการแผนกระบบอาชีวอนามัยและความปลอดภัย",
    pL_Group: "S4",
    birthDay: "24.10.1982",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chuenjis@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผจส.อาชีวอนามัยความปลอดภัยและสิ่งแวดล้อม",
    report_Email: "sittharl@scg.com",
    nick_Name: "จี๊ด",
    mobileNumber: "0812345678",
    login: "chuenjis",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001782",
    name_title: "นางสาว",
    firstname: "ปวิณธร",
    lastname: "ธิรธนาธร",
    name_Title_English: "Miss",
    firstname_English: "Pavintorn",
    lastname_English: "Teratanatorn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "Technologist",
    pL_Group: "S2",
    birthDay: "29.10.1985",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pavintot@scg.com",
    report_To: "Mrs. Saranya Traisilanun",
    report_to_Position_Name: "PP Research & Development Leader",
    report_Email: "saranyat@scg.com",
    nick_Name: "Mew",
    mobileNumber: "0878317255",
    login: "pavintot",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001785",
    name_title: "นาย",
    firstname: "ปุญญวินท์",
    lastname: "มัจฉาเกื้อ",
    name_Title_English: "Mr.",
    firstname_English: "Poonyawin",
    lastname_English: "Mutchakua",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O3",
    birthDay: "04.06.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "poonyawm@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "วิน",
    mobileNumber: "0868841735",
    login: "poonyawm",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001787",
    name_title: "นาย",
    firstname: "สุจิภัทร",
    lastname: "ศรีวงษ์แผน",
    name_Title_English: "Mr.",
    firstname_English: "Sujipat",
    lastname_English: "Sreewongpan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O3",
    birthDay: "22.08.1995",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sujipats@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "เป้",
    mobileNumber: "0812345678",
    login: "sujipats",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901067",
    name_title: "นาย",
    firstname: "ธนกฤต",
    lastname: "เฉ็งเจริญ",
    name_Title_English: "Mr.",
    firstname_English: "Tanakrit",
    lastname_English: "Chengcharoen",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "พนักงานดับเพลิงระดับ 1",
    pL_Group: "O0",
    birthDay: "25.08.1995",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "tanakrih@scg.com",
    report_To: "Mr. Somrerk Niyomthammarat",
    report_to_Position_Name: "ผจก.ปฏิบัติการความปลอดภัยและทีมฉุกเฉิน",

    report_Email: "somrerkn@scg.com",
    nick_Name: "นิว",
    mobileNumber: "0812345678",
    login: "tanakrih",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001791",
    name_title: "นาย",
    firstname: "นพดล",
    lastname: "ไม่หวั่น",
    name_Title_English: "Mr.",
    firstname_English: "Noppadol",
    lastname_English: "Maiwan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการ Packing",
    pL_Group: "O3",
    birthDay: "03.11.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "noppadma@scg.com",
    report_To: "Mr. Supoht Farkum",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "supohtf@scg.com",
    nick_Name: "จุ้ย",
    mobileNumber: "0812345678",
    login: "noppadma",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901070",
    name_title: "นางสาว",
    firstname: "เกษา",
    lastname: "ขันแก้ว",
    name_Title_English: "Miss",
    firstname_English: "Kesa",
    lastname_English: "Khankaew",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานวางแผนเปลี่ยนบรรจุภัณฑ์",
    pL_Group: "O0",
    birthDay: "19.05.1979",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kesakhan@scg.com",
    report_To: "Mr. Supoht Farkum",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "supohtf@scg.com",
    nick_Name: "แดง",
    mobileNumber: "0812345678",
    login: "kesakhan",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001799",
    name_title: "นาย",
    firstname: "พลวัต",
    lastname: "เฮงวิริยะกุล",
    name_Title_English: "Mr.",
    firstname_English: "Polwat",
    lastname_English: "Hengwiriyakul",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "วิศวกรผลิต PP 1,2",
    pL_Group: "S1",
    birthDay: "17.01.1996",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "polwahen@scg.com",
    report_To: "Mr. Noppon Monggranonchai",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 1,2",
    report_Email: "nopponm@scg.com",
    nick_Name: "เจ๋ง",
    mobileNumber: "0812345678",
    login: "polwahen",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001804",
    name_title: "นาย",
    firstname: "สุกฤษฎิ์",
    lastname: "อินจันทร์",
    name_Title_English: "Mr.",
    firstname_English: "Sukrit",
    lastname_English: "Injun",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "วิศวกรผลิต Catalyst",
    pL_Group: "S2",
    birthDay: "09.02.1989",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "sukriinj@scg.com",
    report_To: "Mr. Pakorn Aumyoo",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต Catalyst & Pilot Plant",
    report_Email: "pakorau@scg.com",
    nick_Name: "ไทน์",
    mobileNumber: "0812345678",
    login: "sukriinj",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001837",
    name_title: "นาย",
    firstname: "อนวัช",
    lastname: "แสงพรต",
    name_Title_English: "Mr.",
    firstname_English: "Anawat",
    lastname_English: "Saengprot",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 2",
    pL_Group: "O3",
    birthDay: "20.10.1999",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "anawasae@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "พี",
    mobileNumber: "0812345678",
    login: "anawasae",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001840",
    name_title: "นาย",
    firstname: "กฤษดา",
    lastname: "คงคารัตน์",
    name_Title_English: "Mr.",
    firstname_English: "Kridsada",
    lastname_English: "Khongkarat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 3 Production Department",
    departmentId: 10,
    departmentShortName: "PP 3",
    position: "วิศวกรผลิต PP 3",
    pL_Group: "S1",
    birthDay: "05.06.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kridsakh@scg.com",
    report_To: "Mr. Patinya Pipatpratanporn",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 3",
    report_Email: "patinyap@scg.com",
    nick_Name: "เจม",
    mobileNumber: "0954820202",
    login: "kridsakh",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001843",
    name_title: "นางสาว",
    firstname: "ณัฐนันท์",
    lastname: "จันทร์ณรงค์",
    name_Title_English: "Ms.",
    firstname_English: "Nattanan",
    lastname_English: "Channarong",
    workingLocation: "TPE - Rayong Site#1",
        vendorName: "CC Content",
        vendorName: "CC Content",
    department: "HDPE 4 Production Department",
    departmentId: 6,
    departmentShortName: "HDPE 4",    
    position: "วิศวกรผลิต HDPE 4",
    pL_Group: "S1",
    birthDay: "31.03.1996",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nattanac@scg.com",
    report_To: "Mr. Supasak Kaewwichit",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต HDPE 4",
    report_Email: "supasakk@scg.com",
    nick_Name: "อีฟ",
    mobileNumber: "0812345678",
    login: "nattanac",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001842",
    name_title: "นาย",
    firstname: "นิติพัฒน์",
    lastname: "พิชิตสุรถาวร",
    name_Title_English: "Mr.",
    firstname_English: "Nitipat",
    lastname_English: "Phichitsurathaworn",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "วิศวกรผลิต HDPE 1",
    pL_Group: "S1",
    birthDay: "01.11.1994",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nitipatp@scg.com",
    report_To: "Mr. Sittharpat Leuangaramsri",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต HDPE 1",
    report_Email: "sittharl@scg.com",
    nick_Name: "ไบรท์",
    mobileNumber: "0812345678",
    login: "nitipatp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001834",
    name_title: "นาย",
    firstname: "ณัชพล",
    lastname: "ปรีชา",
    name_Title_English: "Mr.",
    firstname_English: "Nutchaphon",
    lastname_English: "Preecha",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O3",
    birthDay: "18.04.1996",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "nutchapp@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "เจมส์",
    mobileNumber: "0954053874",
    login: "nutchapp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001838",
    name_title: "นาย",
    firstname: "อวยชัย",
    lastname: "สัตย์ธรรม",
    name_Title_English: "Mr.",
    firstname_English: "Ouaichai",
    lastname_English: "Sattham",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "พนักงานปฏิบัติการ Packing",
    pL_Group: "O3",
    birthDay: "29.03.1988",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "ouaichas@scg.com",
    report_To: "Mr. Supoht Farkum",
    report_to_Position_Name: "ผู้จัดการแผนกปฏิบัติการ Packing 1",
    report_Email: "supohtf@scg.com",
    nick_Name: "ปัง",
    mobileNumber: "0812345678",
    login: "ouaichas",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001839",
    name_title: "นางสาว",
    firstname: "พิมพวรรณ",
    lastname: "สุวรรณสวัสดิ์",
    name_Title_English: "Ms.",
    firstname_English: "Pimpawan",
    lastname_English: "Suwansawat",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "วิศวกรประกันคุณภาพ PP&Compounding",
    pL_Group: "S1",
    birthDay: "03.03.1995",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pimpawas@scg.com",
    report_To: "Mrs. Rungtip Lertmunkong",
    report_to_Position_Name: "ผจก.แผนกประกันคุณภาพ PP & Compounding",
    report_Email: "rungtile@scg.com",
    nick_Name: "พิม",
    mobileNumber: "0812345678",
    login: "pimpawas",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001833",
    name_title: "นาย",
    firstname: "ศิริวุฒิ",
    lastname: "ศิริเลิศ",
    name_Title_English: "Mr.",
    firstname_English: "Siriwut",
    lastname_English: "Sirilers",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O3",
    birthDay: "08.01.1999",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "siriwuts@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "ใหม่",
    mobileNumber: "0812345678",
    login: "siriwuts",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-901071",
    name_title: "นาย",
    firstname: "สองสุวิน",
    lastname: "คำแหง",
    name_Title_English: "Mr.",
    firstname_English: "Songsuwin",
    lastname_English: "Kamhaeng",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "เจ้าหน้าที่ Data Management",
    pL_Group: "O0",
    birthDay: "24.03.1995",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "songsuwk@scg.com",
    report_To: "Mr. Puttinun Moungprasert",
    report_to_Position_Name: "Manager-HR Business Partner",
    report_Email: "puttinum@scg.com",
    nick_Name: "กล้วย",
    mobileNumber: "0812345678",
    login: "songsuwk",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001841",
    name_title: "นางสาว",
    firstname: "ธัญชนก",
    lastname: "หงสกุล",
    name_Title_English: "Ms.",
    firstname_English: "Thunchanok",
    lastname_English: "Hongsakul",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "วิศวกรประกันคุณภาพ PE",
    pL_Group: "S1",
    birthDay: "02.12.1994",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thunchah@scg.com",
    report_To: "Miss Sarinya Luangsukrerk",
    report_to_Position_Name: "ผู้จัดการแผนกประกันคุณภาพ PE",
    report_Email: "sarinyal@scg.com",
    nick_Name: "ตุ๊ดตู่",
    mobileNumber: "0812345678",
    login: "thunchah",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001818",
    name_title: "นางสาว",
    firstname: "วริศรา",
    lastname: "ปลุกเสก",
    name_Title_English: "Miss",
    firstname_English: "Warisara",
    lastname_English: "Ploogseg",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Safety, Occupational Health & Environment Department",
    departmentId: 12,
    departmentShortName: "Safety & Envi",
    position: "วิศวกรสิ่งแวดล้อม",
    pL_Group: "S2",
    birthDay: "23.03.1991",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "warisarp@scg.com",
    report_To: "Miss Sasiphen Laimai",
    report_to_Position_Name: "ผู้จัดการแผนกสิ่งแวดล้อม",
    report_Email: "sasiphel@scg.com",
    nick_Name: "กิฟท์",
    mobileNumber: "0812345678",
    login: "warisarp",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001836",
    name_title: "นาย",
    firstname: "วัชระ",
    lastname: "อินศิริ",
    name_Title_English: "Mr.",
    firstname_English: "Watchara",
    lastname_English: "Insiri",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O3",
    birthDay: "31.10.1999",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "watcinsi@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "โจ",
    mobileNumber: "0812345678",
    login: "watcinsi",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001848",
    name_title: "นาย",
    firstname: "กรวิชญ์",
    lastname: "นามวงษา",
    name_Title_English: "Mr.",
    firstname_English: "Korawit",
    lastname_English: "Namwongsa",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "วิศวกรผลิต LDPE",
    pL_Group: "S1",
    birthDay: "03.07.1995",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "korawitn@scg.com",
    report_To: "Mr. Pat Nartpochananon",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LDPE",
    report_Email: "patn@scg.com",
    nick_Name: "Max",
    mobileNumber: "0812345678",
    login: "korawitn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001865",
    name_title: "นาย",
    firstname: "อภิชาติ",
    lastname: "จิตติรบำรุง",
    name_Title_English: "Mr.",
    firstname_English: "Apichart",
    lastname_English: "Jittirabumrung",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O3",
    birthDay: "20.08.1999",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "apichjit@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ออม",
    mobileNumber: "0812345678",
    login: "apichjit",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001866",
    name_title: "นาย",
    firstname: "ชนะ",
    lastname: "วักจันทร์",
    name_Title_English: "Mr.",
    firstname_English: "Chana",
    lastname_English: "Wakjan",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O3",
    birthDay: "20.06.2000",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "chanawak@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "เกมส์",
    mobileNumber: "0812345678",
    login: "chanawak",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001859",
    name_title: "นาย",
    firstname: "กฤษฏฺิ์",
    lastname: "จันทร์ดี",
    name_Title_English: "Mr.",
    firstname_English: "Krit",
    lastname_English: "Jundee",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O3",
    birthDay: "07.01.2000",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "kritjund@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "GOT",
    mobileNumber: "0812345678",
    login: "kritjund",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001867",
    name_title: "นาย",
    firstname: "ภควัต",
    lastname: "บัวแพง",
    name_Title_English: "Mr.",
    firstname_English: "Pakkawut",
    lastname_English: "Buapang",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "HDPE 1 Production Department",
    departmentId: 4,
    departmentShortName: "HDPE 1",
    position: "พนักงานผลิต HDPE 1",
    pL_Group: "O3",
    birthDay: "17.10.1999",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pakkawub@scg.com",
    report_To: "Mr. Pichit Rodprasit",
    report_to_Position_Name: "ผจผ.ผลิต HDPE 1",
    report_Email: "pichitr@scg.com",
    nick_Name: "อาร์ม",
    mobileNumber: "0812345678",
    login: "pakkawub",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001862",
    name_title: "นาย",
    firstname: "ปาราเมศ",
    lastname: "โชตยานนท์",
    name_Title_English: "Mr.",
    firstname_English: "Paramet",
    lastname_English: "Chotayanon",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LLDPE Production Department",
    departmentId: 8,
    departmentShortName: "LLDPE",
    position: "วิศวกรผลิต LLDPE",
    pL_Group: "S1",
    birthDay: "02.07.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "parametc@scg.com",
    report_To: "Mr. Sophon Kiatpradab",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต LLDPE",
    report_Email: "sophoki@scg.com",
    nick_Name: "เจมส์",
    mobileNumber: "0812345678",
    login: "parametc",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001863",
    name_title: "นางสาว",
    firstname: "พิมพิศา",
    lastname: "เรือนหลวง",
    name_Title_English: "Miss",
    firstname_English: "Pimpisa",
    lastname_English: "Rueanruang",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "วิศวกรผลิต PP 1,2",
    pL_Group: "S1",
    birthDay: "11.09.1998",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pimpisar@scg.com",
    report_To: "Mr. Noppon Monggranonchai",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 1,2",
    report_Email: "nopponm@scg.com",
    nick_Name: "จ๋า",
    mobileNumber: "0817057689",
    login: "pimpisar",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001853",
    name_title: "นาย",
    firstname: "พูนประเสริฐ",
    lastname: "นิยมไทย",
    name_Title_English: "Mr.",
    firstname_English: "Poonprasert",
    lastname_English: "Niyomthai",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 4,
    vendorName: "Lucky Dragon",
    department: "Logistics Department",
    departmentId: 1,
    departmentShortName: "Logistics",
    position: "นักวิเคราะห์ระบบ Logistics",
    pL_Group: "S3",
    birthDay: "14.12.1989",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "poonpran@scg.com",
    report_To: "Mr. Nattapol Pongpaothai",
    report_to_Position_Name: "ผู้จัดการ Logistics Operation",
    report_Email: "nattpong@scg.com",
    nick_Name: "คิด",
    mobileNumber: "0812345678",
    login: "poonpran",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001858",
    name_title: "นาย",
    firstname: "ธนกร",
    lastname: "พุทธรักษา",
    name_Title_English: "Mr.",
    firstname_English: "Thanakorn",
    lastname_English: "Puttaraksa",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "พนักงานผลิต PP 1",
    pL_Group: "O3",
    birthDay: "29.04.2001",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thanaput@scg.com",
    report_To: "Mr. Nirun Yindeecharoen",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต PP 1,2",
    report_Email: "niruyi@scg.com",
    nick_Name: "เซฟ",
    mobileNumber: "0812345678",
    login: "thanaput",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001855",
    name_title: "นาย",
    firstname: "ธิบดินทร์",
    lastname: "กลีบแก้ว",
    name_Title_English: "Mr.",
    firstname_English: "Thibadin",
    lastname_English: "Kipkaeo",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O3",
    birthDay: "10.06.2000",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thibadik@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "Cake",
    mobileNumber: "0812345678",
    login: "thibadik",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001850",
    name_title: "นาย",
    firstname: "พชร",
    lastname: "มีจินดา",
    name_Title_English: "Mr.",
    firstname_English: "Pojchara",
    lastname_English: "Mejinda",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 3,
    vendorName: "KEC",
    department: "PP 1,2 Production Department",
    departmentId: 11,
    departmentShortName: "PP 1,2",
    position: "วิศวกรผลิต PP 1,2",
    pL_Group: "S1",
    birthDay: "26.10.1998",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "pojcharm@scg.com",
    report_To: "Mr. Noppon Monggranonchai",
    report_to_Position_Name: "ผู้จัดการส่วนผลิต PP 1,2",
    report_Email: "nopponm@scg.com",
    nick_Name: "ป๊อป",
    mobileNumber: "0990639292",
    login: "pojcharm",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001873",
    name_title: "นางสาว",
    firstname: "จินต์จุฑา",
    lastname: "วิริยะนันทวงศ์",
    name_Title_English: "Miss",
    firstname_English: "Jinjutha",
    lastname_English: "Wiriyanantawong",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Quality Assurance & Quality Control Department",
    departmentId: 2,
    departmentShortName: "QA&QC",
    position: "วิศวกรประกันคุณภาพ PP&Compounding",
    pL_Group: "S1",
    birthDay: "22.09.1995",
    gender: "F",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "jinjuthw@scg.com",
    report_To: "Mrs. Rungtip Lertmunkong",
    report_to_Position_Name: "ผจก.แผนกประกันคุณภาพ PP & Compounding",
    report_Email: "rungtile@scg.com",
    nick_Name: "ออม",
    mobileNumber: "0812345678",
    login: "jinjuthw",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001876",
    name_title: "นาย",
    firstname: "ธีรัช",
    lastname: "แน่นหนา",
    name_Title_English: "Mr.",
    firstname_English: "Thirach",
    lastname_English: "Naenna",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 1,
    vendorName: "Vertex",
    department: "Catalyst & Pilot Plant Production Department",
    departmentId: 5,
    departmentShortName: "Catalyst & Pilot Plant",
    position: "พนักงานผลิต Catalyst",
    pL_Group: "O3",
    birthDay: "05.11.2000",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "thirachn@scg.com",
    report_To: "Mr. Winai Thanprae",
    report_to_Position_Name: "ผู้จัดการแผนกผลิต Catalyst",
    report_Email: "winath@scg.com",
    nick_Name: "ธี",
    mobileNumber: "0812345678",
    login: "thirachn",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
  {
    scG_Employee_ID: "0100-001878",
    name_title: "นาย",
    firstname: "วุฒิชัย",
    lastname: "ไชยสิทธิ์",
    name_Title_English: "Mr.",
    firstname_English: "Wuttichai",
    lastname_English: "Chaiyasit",
    workingLocation: "TPE - Rayong Site#1",
    idVendor: 2,
    vendorName: "CC Content",
    department: "LDPE Production Department",
    departmentId: 3,
    departmentShortName: "LDPE",
    position: "พนักงานผลิต LDPE",
    pL_Group: "O3",
    birthDay: "03.11.1999",
    gender: "M",
    nationality: "TH",
    address_Office: "",
    telephone_Business: "",
    email: "wuttchai@scg.com",
    report_To: "Mr. Chaiyod Rattanakun",
    report_to_Position_Name: "ผจผ.ผลิต LDPE",
    report_Email: "chaiyodr@scg.com",
    nick_Name: "ม่อน",
    mobileNumber: "0812345678",
    login: "wuttchai",
    cardId: "1187366529485",
    started: new Date(1999, 7,18),
    domain: "cementhai",
  },
];

export default employees;
