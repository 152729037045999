import React from "react";
import { Typography, Grid, Divider, Switch, Chip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/material/styles";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getProbationQuestion, updateProbationQuestionStatus } from "../../../../../actions/probation";
import { useTranslation } from "react-i18next";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  gap: 8,
});

const DialogEdit = (props) => {
  const { open, handleClose } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: probationQuestion } = useSelector(
    (state) => state.probationQuestion
  );

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      questionList: probationQuestion,
    },
  });

  const onSubmit = async (formData) => {
    const res = await dispatch(updateProbationQuestionStatus(formData));
    if (res && res.status === 200) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
    dispatch(getProbationQuestion({ idCompany: userProfile.idCompany }));
    handleClose();
  };

  const questionUseFieldArray = useFieldArray({
    control: control,
    name: "questionList",
  });

  const maxActive = watch("questionList").filter((item) => item.isActive).length >= 10;

  return (
    <DrawerCustom
      title={t("EditProbationReviewQuestion")}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} padding={2} style={{ marginBottom: 16 }}>
          {questionUseFieldArray.fields.map((question, index) => (
            <Grid key={question.id} container>
              {questionUseFieldArray.fields.length > 1 && (
                <Grid item xs={12} display="flex" alignItems="center" gap={"8px"} marginTop={1}>
                  {Boolean(question.isActive) === true && (
                    <Chip
                      sx={{ maxWidth: "fit-content" }}
                      icon={<CheckCircleIcon />}
                      color={"success"}
                      label={t("ActiveQuestion")}
                    />
                  )}
                  <Controller
                    control={control}
                    name={`questionList.${index}.isActive`}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={Boolean(field.value)}
                        disabled={maxActive && !field.value}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography>{question.question}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    marginTop: "16px",
                    borderColor: "#9e9e9e66",
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <StyledFooter>
          <ButtonBlue onClick={handleClose}>{t("Cancel")}</ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit(onSubmit)}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
