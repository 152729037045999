import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import * as XLSX from "xlsx"

//import AlertResponse from "../../../../shared/general/AlertResponse";
import DialogEdit from "./dialog";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
  Button,
} from "devextreme-react/data-grid";

import AlertResponse from "../../shared/general/AlertResponse";
import StyledCard from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import Detail from "./detail";
import utils from "../../../../utils";

import { getBillingAllCompanysByVendor } from "../../../../actions/billing";
import { Typography, Box } from "@mui/material";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  width: "100%",
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
  {
    backgroundColor: "#ef5350",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyledDivDataGrid = styled("div")({
  // width:"100%",
  display: "flex",
  position: "relative",
  "& .datepicker": {
    width: "fit-content",
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "5",
    "& .MuiInputBase-root": {
      borderRadius: "4px"
    },
    "& input": {
      lineHeight: "1.35715",
      padding: "7px 9px 8px !important",
      fontSize: "1rem"
    }
  }
});

const TableEmployee = (props) => {
  const { handleGetAllDetail, handleClosePayroll, handleDeletePayroll, type, setSeeMoreData } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const grid = useRef();
  const { result: billingCompanys } = useSelector((state) => state.billingCompanys);
  const { result: payRunDetail } = useSelector((state) => state.payRunDetail);
  const { result: payRunDetailEmployees } = useSelector(
    (state) => state.payRunDetailEmployees
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [eachData, setEachData] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [gridColumnsAddition, setGridColumnsAddition] = React.useState([]);
  const [gridColumnsDeduction, setGridColumnsDeduction] = React.useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openAddFile, setOpenAddFile] = useState(false);
  const [anchorElBank, setAnchorElBank] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [monthYear, setMonthYear] = React.useState(dayjs());
  const open = Boolean(anchorEl);
  const openBank = Boolean(anchorElBank);

  useEffect(() => {
    if (billingCompanys) {
      setData(billingCompanys);
    } else {
      setData([]);
    }
  }, [billingCompanys]);

  useEffect(() => {
    dispatch(getBillingAllCompanysByVendor(dayjs(monthYear).date(1).format("YYYY-MM-DD")));
  }, [monthYear]);

  const rowIndex = (cellInfo) => {
    return cellInfo.rowIndex + 1;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const onExporting = useCallback(() => {
  }, []);

  const customizeNumber = (data) => {
    return `${utils.numberWithCommas(data.value || 0)} บาท`;
  };

  const customizeHours = (data) => {
    return data.value ? `${utils.numberWithCommas(data.value)} ชม.` : "";
  };

  const cellCompanyText = (data) => {
    let value = data.data;
    return (
      <div>
        <div style={{ display: 'flex', alignItems: "center" }}>
          <div
            style={{
              width: "14px",
              height: "14px",
              marginRight: "8px",
              borderRadius: "8px",
              background:
                value.isSent ?
                  "linear-gradient(31deg, rgba(34,175,14,1) 0%, rgba(255,255,255,1) 100%)" :
                  "linear-gradient(31deg, rgba(240,18,18,1) 0%, rgba(255,255,255,1) 100%)"
            }}
          />
          <Typography
            style={{ fontSize: "16px" }}
          >{`${value.payrunName ? value.payrunName : "No Payrun Name"}`}</Typography>
        </div>
        <Typography
          style={{ fontSize: "14px", marginLeft: "22px", marginTop: "8px" }}
        >{`${value.companyName}`}</Typography>
      </div>
    );
  };

  const cellMonthText = (data) => {
    let value = data.data;
    return (
      <div>
        <Typography
          // style={{ fontSize: "14px", marginTop: "8px" }}
          style={{ fontSize: "14px" }}
        >{`${value.monthPeriod ? dayjs(value.monthPeriod).locale("th").format("DD MMMM YYYY") : "-"}`}</Typography>
      </div>
    );
  };

  const handleAlert = (type) => {
    handleOpenAlert();
    handleChangeAlertType(type);
  };

  const onSelectionChanged = (e) => {
    setSelectedRowKeys(e.selectedRowKeys);
  };

  return (
    <Fragment>
      <Box sx={{ mt: 2 }}>
        <StyledCard>
          <StyledCardContent>
            <StyledWrapDataGrid>
              {selectedCompany ?
                <Detail
                  billingCompanys={data}
                  company={selectedCompany}
                  monthYear={dayjs(monthYear).date(1).format("YYYY-MM-DD")}
                  setSelectedCompany={setSelectedCompany}
                  handleAlertCompanyPage={handleAlert}
                  idBilling={eachData}
                  type={type}
                  setSeeMoreData={setSeeMoreData}
                />
                :
                <StyledDivDataGrid>
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      dataSource={data ? data.filter(item => item.isLumpSum === type) : []}
                      showBorders={false}
                      showColumnLines={false}
                      rowAlternationEnabled={true}
                      columnAutoWidth={true}
                      onExporting={onExporting}
                      onRowClick={(v) => {
                        if (v && v.data && v.data.idBilling && v.data.idCompany && v.data.monthPeriod) {
                          setEachData(v.data.idBilling);
                          setSelectedCompany(v.data.idCompany);
                          setMonthYear(v.data.monthPeriod);
                          setSeeMoreData(false);
                        }
                      }}
                      noDataText="ไม่มี Billing"
                      onSelectionChanged={onSelectionChanged}
                    >
                      {/* <Selection
                      mode="multiple"
                    /> */}

                      <Column
                        caption={`${t("NoNumber")}`}
                        width={"auto"}
                        cellRender={rowIndex}
                        alignment="center"
                        cssClass="column-info"
                      />

                      {type === 0 ? (
                        <Column
                          caption={"Payrun name"}
                          dataField="payrunName"
                          dataType="string"
                          width={300}
                          minWidth={200}
                          cssClass="column-info"
                        />
                      ) : (
                        <Column
                          caption={"Payrun name"}
                          dataField="fileName"
                          dataType="string"
                          width={300}
                          minWidth={200}
                          cssClass="column-info"
                        />
                      )}

                      <Column
                        caption="Payrun Round"
                        dataField="monthPeriod"
                        dataType="string"
                        width={300}
                        minWidth={200}
                        cssClass="column-info"
                        cellRender={cellMonthText}
                      />

                      {type === 0 ? (
                        <Column
                          caption={`${t("Company")}`}
                          dataField="companyName"
                          dataType="string"
                          width={300}
                          minWidth={200}
                          cssClass="column-info"
                          cellRender={cellCompanyText}
                        />
                      ) : (
                        <Column
                          caption={`${t("Company")}`}
                          dataField="companyName"
                          dataType="string"
                          width={300}
                          minWidth={200}
                          cssClass="column-info"
                        />
                      )}

                      <Column
                        caption={`${t("Amount")}${t("Person")}`}
                        dataType="string"
                        cssClass="column-info"
                        dataField="numberEmployee"
                        minWidth={200}
                      >
                        <HeaderFilter allowSearch={true} />
                      </Column>

                      <Column
                        dataField="totalSalary"
                        caption={t("Salary")}
                        cssClass="column-info"
                        format="###,###,##0.00"
                        minWidth={200}
                      />

                      <Column
                        dataField="totalChargeSalary"
                        caption={t("chargeWage")}
                        dataType="string"
                        cssClass="column-info"
                        format="###,###,##0.00"
                        minWidth={200}
                      />

                      <Column
                        dataField="totalOT"
                        caption={t("OT")}
                        dataType="string"
                        cssClass="column-info"
                        format="###,###,##0.00"
                        minWidth={200}
                      />
                      <Column
                        dataField="totalChargeOT"
                        caption={t("chargeOt")}
                        dataType="string"
                        cssClass="column-info"
                        format="###,###,##0.00"
                        minWidth={200}
                      />

                      <Column
                        dataField="totalChargeCost"
                        caption={t("chargeCost")}
                        dataType="string"
                        cssClass="column-info"
                        format="###,###,##0.00"
                        minWidth={200}
                      />
                      <Column
                        dataField="totalChargeCompensated"
                        caption={t("chargeCompensation")}
                        dataType="string"
                        cssClass="column-info"
                        format="###,###,##0.00"
                        minWidth={200}
                      />
                      <Column
                        dataField="totalShiftCost"
                        caption={t("ShiftCost")}
                        dataType="string"
                        cssClass="column-info"
                        format="###,###,##0.00"
                        minWidth={200}
                      />
                      <Column
                        dataField="totalChargeShiftCost"
                        caption={t("ChargeShiftCost")}
                        dataType="string"
                        cssClass="column-info"
                        format="###,###,##0.00"
                        minWidth={200}
                      />
                      <Column
                        dataField="totalAddition"
                        dataType="string"
                        cssClass="column-info"
                        format="0"
                        minWidth={200}
                      />
                      {/* <Column
                      dataField="totalChargeAddition"
                      caption={`${t("Total")} Charge Addition`}
                      dataType="string"
                      cssClass="column-info"
                      format="###,###,##0.00"
                      minWidth={200}
                    /> */}
                      <Column
                        dataField="totalCharge"
                        caption={`${t("Total")} Charge`}
                        dataType="string"
                        cssClass="column-info"
                        format="###,###,##0.00"
                        minWidth={200}
                      />
                      <Column
                        dataField="totalOther"
                        caption={i18n.language === "th" ? `รวม${t("Other")}` : `${t("Other")} Charge`}
                        dataType="string"
                        cssClass="column-info"
                        format="###,###,##0.00"
                        minWidth={200}
                      />
                      <Column
                        dataField="net"
                        caption={`เรียกเก็บสุทธิ`}
                        dataType="string"
                        cssClass="column-info"
                        format="###,###,##0.00"
                        minWidth={200}
                      />

                      {/* <Export enabled={true} allowExportSelectedData={true} /> */}
                      <Paging defaultPageSize={10} />
                      <Pager
                        visible={(data && data.length > 0) ? true : false}
                        allowedPageSizes={[
                          10,
                          ...(data && data.length > 10) ? [20] : [],
                          ...(data && data.length > 20) ? [30] : []
                        ]}
                        showPageSizeSelector={true}
                        showNavigationButtons={true}
                        showInfo={true}
                      />
                      <Selection mode="single" />
                      <Sorting mode="multiple" />
                      <Scrolling columnRenderingMode="virtual" />
                      <FilterRow visible={false} />
                      <HeaderFilter visible={true} />
                      <SearchPanel visible={true} width={240} placeholder="Search..." />
                      <Summary>
                        <TotalItem
                          column="totalSalary"
                          customizeText={customizeNumber}
                          summaryType="sum"
                        />
                        <TotalItem
                          column="totalChargeSalary"
                          customizeText={customizeNumber}
                          summaryType="sum"
                        />
                        <TotalItem
                          column="totalOT"
                          customizeText={customizeNumber}
                          summaryType="sum"
                        />
                        <TotalItem
                          column="totalChargeOT"
                          summaryType="sum"
                          customizeText={customizeNumber}
                        />
                        <TotalItem
                          column="totalChargeCost"
                          summaryType="sum"
                          customizeText={customizeNumber}
                        />
                        <TotalItem
                          column="totalChargeCompensated"
                          summaryType="sum"
                          customizeText={customizeNumber}
                        />
                        <TotalItem
                          column="totalShiftCost"
                          summaryType="sum"
                          customizeText={customizeNumber}
                        />
                        <TotalItem
                          column="totalChargeShiftCost"
                          summaryType="sum"
                          customizeText={customizeNumber}
                        />
                        <TotalItem
                          column="totalAddition"
                          summaryType="sum"
                          customizeText={customizeNumber}
                        />
                        {/* <TotalItem
                        column="totalChargeAddition"
                        summaryType="sum"
                        customizeText={customizeNumber}
                      /> */}
                        <TotalItem
                          column="totalCharge"
                          summaryType="sum"
                          customizeText={customizeNumber}
                        />
                        <TotalItem
                          column="totalOther"
                          summaryType="sum"
                          customizeText={customizeNumber}
                        />
                        <TotalItem
                          column="net"
                          summaryType="sum"
                          customizeText={customizeNumber}
                        />
                      </Summary>
                    </DataGrid>
                  </div>
                </StyledDivDataGrid>
              }


              {/* <DialogEdit
              open={openDialog}
              handleClose={handleCloseDialog}
              selectedRowsData={selectedRowsData}
              handleChangeAlertType={handleChangeAlertType}
              handleOpenAlert={handleOpenAlert}
              handleCloseAlert={handleCloseAlert}
              handleGetAllDetail={handleGetAllDetail}
            /> */}
            </StyledWrapDataGrid>
          </StyledCardContent>
        </StyledCard>
        <AlertResponse
          open={openAlert}
          handleClose={() => {
            handleCloseAlert();
            handleChangeAlertType(false);
          }}
          alertType={alertType}
        />
      </Box>
    </Fragment>
  )
}

export default TableEmployee;

{/* <DialogEdit
              open={openDialog}
              handleClose={handleCloseDialog}
              selectedRowsData={selectedRowsData}
              handleChangeAlertType={handleChangeAlertType}
              handleOpenAlert={handleOpenAlert}
              handleCloseAlert={handleCloseAlert}
              handleGetAllDetail={handleGetAllDetail}
            /> */}
