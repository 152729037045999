import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import { getSignatureCertificateVendor } from '../../../../../actions/vendor';

export const PND1kPDFfile = async (type, selectYear, data) => {
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const url2 = `${process.env.REACT_APP_API_URL}files/PND1k_personal.pdf`;

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const existingPdfBytes2 = await fetch(url2).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pdfDoc2 = await PDFDocument.load(existingPdfBytes2);

    const mergedPdfDoc = await PDFDocument.create();

    const pages1 = await mergedPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
    pages1.forEach((page) => mergedPdfDoc.addPage(page));

    const pages2 = await mergedPdfDoc.copyPages(pdfDoc2, pdfDoc2.getPageIndices());
    pages2.forEach((page) => mergedPdfDoc.addPage(page));
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    mergedPdfDoc.registerFontkit(fontkit);

    const font = await mergedPdfDoc.embedFont(fontBytes);
    const pages = mergedPdfDoc.getPages();

    const firstPage = pages[0];
    const presentDay = new Date();
    const presentDayTH = dayjs(presentDay).locale('th').format("DD");
    const presentMonthTH = dayjs(presentDay).locale('th').format("MMMM");
    const thaiYear = dayjs(presentDay).format('YYYY')
    const formattedThaiYear = parseInt(thaiYear) + 543;

    const taxValue = data && data.TaxValue;
    const employeeData = data && data.employeeDetails;
    const deductionData = data && data.deductionPnd1kResult;
    const additionData = data && data.additionPnd1kResult;

    const fullname = data && employeeData[0].authorizedSignatureOneName ? `${employeeData[0].authorizedSignatureOneName}` : "-";

    const taxID = `${data && employeeData[0].vendorTaxID}`;

    const formattedtaxID1 = `${'   ' + taxID[0]}`
    const formattedtaxID2 = `${'  ' + taxID[1] + '  ' + taxID[2] + '  ' + taxID[3] + '  ' + taxID[4]}`
    const formattedtaxID3 = `${taxID[5] + '  ' + taxID[6] + '  ' + taxID[7] + '  ' + taxID[8] + '  ' + taxID[9]}`
    const formattedtaxID4 = `${taxID[10] + '  ' + taxID[11] + '   ' + taxID[12]} `

    firstPage.drawText(`${formattedtaxID1}`, { x: 160, y: 722, size: 14, font: font });
    firstPage.drawText(`${formattedtaxID2}`, { x: 180, y: 722, size: 14, font: font });
    firstPage.drawText(` ${formattedtaxID3}`, { x: 230, y: 722, size: 14, font: font });
    firstPage.drawText(`${formattedtaxID4}`, { x: 292, y: 722, size: 14, font: font });

    if (employeeData && employeeData[0].vendorName) {
        firstPage.drawText(`${employeeData[0].vendorName}`, {
            x: 100, y: 676, size: 16, font: font, color: rgb(0, 0, 0),
        });
    }

    firstPage.drawText(`${selectYear}`, {
        x: 508, y: 701, size: 15, font: font, color: rgb(0, 0, 0),
    });

    const address = employeeData[0].addressVendor.split(" ");

    { address && address[0] && firstPage.drawText(`${address[0]}`, { x: 99, y: 645, size: 14, font: font }); }
    { address && address[1] && firstPage.drawText(`${address[1]}`, { x: 80, y: 631, size: 14, font: font }); }
    { address && address[2] && firstPage.drawText(`${address[2]}`, { x: 240, y: 631, size: 14, font: font }); }
    { employeeData[0] && employeeData[0].districtVendor && firstPage.drawText(`${employeeData[0].districtVendor}`, { x: 105, y: 616, size: 14, font: font }); }
    { employeeData[0] && employeeData[0].provinceVendor && firstPage.drawText(`${employeeData[0].provinceVendor}`, { x: 235, y: 616, size: 14, font: font }); }

    const areaCode = `${employeeData[0].areaCodeVendor}`;

    const formattedareaCode = `${areaCode[0] + '  ' + areaCode[1] + '  ' + areaCode[2] + '  ' + areaCode[3] + '  ' + areaCode[4]}`
    {
        employeeData[0].areaCodeVendor && firstPage.drawText(`${formattedareaCode} `, {
            x: 106,
            y: 600, size: 14, font: font,
        });
    }

    const totalEarnings = data && data.totalEarning;
    const totalTax = data && data.totalTax;
    const totalbeforeAccumulatetotalEarnings = data && data.totalbeforeAccumulatetotalEarnings;
    const totalbeforeAccumulateTax = data && data.totalbeforeAccumulateTax;
    const totalAbsentAndLateValue = data && data.totalAbsentAndLateValue;

    //2nd december
    const totalDecEarnings = data && data.totalDecEarnings;
    const totalDecTax = data && data.totalDecTax;
    const totalDecAbsentAndLateValue = data && data.totalDecAbsentAndLateValue;

    const totalDeductValue = data && data.totalDeductValue;

    const totalAdditionValue = data && data.totalAdditionValue;

    const totalDecDeduction = totalDecEarnings - totalDecAbsentAndLateValue;

    const TotalSumDeductions = totalDeductValue + totalAbsentAndLateValue + totalDecDeduction;

    const AllTax = totalTax + totalbeforeAccumulateTax - totalDecTax;
    const AllEarnings = totalEarnings + totalbeforeAccumulatetotalEarnings - TotalSumDeductions - totalAdditionValue;

    firstPage.drawText(`${employeeData.length}`, { x: employeeData.length > 100 ? 325 : 335, y: 393, size: 14, font: font });

    firstPage.drawText(`${AllEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: AllEarnings > 1000000 ? 385 : 378, y: 393, size: 14, font: font });
    firstPage.drawText(`${AllTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: AllTax > 1000000 ? 482 : 475, y: 393, size: 14, font: font });

    firstPage.drawText(`${employeeData.length}`, { x: employeeData.length > 100 ? 325 : 335, y: 258, size: 14, font: font });
    firstPage.drawText(`${AllEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: AllEarnings > 1000000 ? 385 : 378, y: 258, size: 14, font: font });
    firstPage.drawText(`${AllTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: AllTax > 1000000 ? 482 : 475, y: 258, size: 14, font: font });

    //image
    const imageData = await getSignatureCertificateVendor();
    const pngImage = await pdfDoc.embedPng(imageData.data);
    const pngDims = pngImage.scale(0.8);

    firstPage.drawImage(pngImage, { x: 275, y: 175, width: pngDims.width, height: pngDims.height })
    firstPage.drawText(`${fullname}`, { x: 275, y: 168, size: 14, font: font });
    firstPage.drawText(`${employeeData[0].authorizedSignatureOnePosition || "-"}`, { x: 260, y: 151, size: 14, font: font });
    firstPage.drawText(`${presentDayTH}`, { x: 235, y: 134, size: 13, font: font });
    firstPage.drawText(`${presentMonthTH}`, { x: 280, y: 134, size: 13, font: font });
    firstPage.drawText(`${formattedThaiYear}`, { x: 370, y: 134, size: 13, font: font });

    const x = 80;
    const y = 407;
    let index = 1;
    const marginBottom = 19;

    const existingPdfBytes3 = await fetch(url2).then((res) => res.arrayBuffer());
    const pdfDoc3 = await PDFDocument.load(existingPdfBytes3);

    let pageIndex = 1;
    const itemsPerPage = 7;

    if (data && employeeData.length > 0) {
        for (let itemIndex = 0; itemIndex < employeeData.length; itemIndex++) {
            if (itemIndex % itemsPerPage === 0) {
                // Move to the next page using the template pdfDoc3
                const [newPageTemplate] = await mergedPdfDoc.copyPages(pdfDoc3, pdfDoc3.getPageIndices());
                mergedPdfDoc.addPage(newPageTemplate);
                pageIndex++;
            }
            const offsetY = y - (itemIndex % itemsPerPage) * (20 + marginBottom);
            const item = employeeData[itemIndex];
            const personalID = `${item.personalId}`;
            const findEmpTax = taxValue && taxValue.find(tax => tax.idEmp === item.idEmp);
            const findEmpDeduct = deductionData && deductionData.find(x => x.idEmp === item.idEmp);
            const tax = findEmpTax ? findEmpTax.value : 0;
            const SumLate = findEmpTax ? findEmpTax.Sum_late : 0;
            const SumAbsent = findEmpTax ? findEmpTax.Sum_absent : 0;
            const SumPF = findEmpTax ? findEmpTax.SUM_PF : 0;
            const SumSSO = findEmpTax ? findEmpTax.SUM_SSO : 0;
            const DecSumAbsent = findEmpTax ? findEmpTax.Dec_Sum_absent : 0;
            const DecSumLate = findEmpTax ? findEmpTax.Dec_Sum_late : 0;

            //2nd december
            const TotalDecTax = findEmpTax ? findEmpTax.Dec_Sum_Tax : 0;
            const TotalDecLateAndAbsentValue = DecSumAbsent + DecSumLate;
            const TotalDecemberDeductions = (item.HalfDecemberEarning || 0) - TotalDecLateAndAbsentValue;

            const TotalLateAndAbsentValue = SumAbsent + SumLate;

            const deductedValue = findEmpDeduct ? findEmpDeduct.value : 0;
            const allDeductionDec = findEmpDeduct ? findEmpDeduct.AllDeductionDec : 0;
            const TotalDeductions = deductedValue - allDeductionDec + TotalLateAndAbsentValue + TotalDecemberDeductions;
            const AllEarnings = ((item.totalEarnings || 0) + (item.beforeAccumulateEarnings || 0)) - TotalDeductions;

            const formattedPersonalID1 = `${' ' + personalID[0]}`;
            const formattedPersonalID2 = `${personalID[1] + '  ' + personalID[2] + '  ' + personalID[3] + '  ' + personalID[4]}`;
            const formattedPersonalID3 = `${personalID[5] + '  ' + personalID[6] + '  ' + personalID[7] + '   ' + personalID[8] + '  ' + personalID[9]}`;
            const formattedPersonalID4 = `${personalID[10] + '  ' + personalID[11] + '    ' + personalID[12]} `;

            const currentPage = mergedPdfDoc.getPages()[pageIndex];

            currentPage.drawText(`${formattedtaxID1}`, { x: 423, y: 560, size: 14, font: font, color: rgb(0, 0, 0) });
            currentPage.drawText(`${formattedtaxID2}`, { x: 443, y: 560, size: 14, font: font, color: rgb(0, 0, 0) });
            currentPage.drawText(`${formattedtaxID3}`, { x: 501, y: 560, size: 14, font: font, color: rgb(0, 0, 0) });
            currentPage.drawText(`${formattedtaxID4}`, { x: 569, y: 560, size: 14, font: font, color: rgb(0, 0, 0) });
            // currentPage.drawText(`0 0 0 0 0`,  { x: 569, y: 700, size: 14, font: font, color: rgb(0, 0, 0) });

            currentPage.drawText(`${itemIndex + 1}`, { x, y: offsetY + 10, size: 14, font: font });
            currentPage.drawText(`${item.personalId ? formattedPersonalID1 : ""}`, { x: x + 26, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.personalId ? formattedPersonalID2 : ""}`, { x: x + 47, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.personalId ? formattedPersonalID3 : ""}`, { x: x + 100, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.personalId ? formattedPersonalID4 : ""}`, { x: x + 166, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.title}`, { x: x + 230, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.firstname}`, { x: x + 260, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.lastname}`, { x: x + 385, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${(AllEarnings || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: x + 517, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${(tax + item.beforeAccumulateTax - TotalDecTax || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, { x: x + 630, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.houseNo ? item.houseNo : ""} ${item.houseNo ? item.houseNo : ""}${item.vilage ? ` หมู่บ้าน${item.vilage}` : ""}${item.vilageNo ? ` หมู่ที่${item.vilageNo}` : ""}${item.alley ? ` ซอย${item.alley}` : ""}${item.road ? ` ถนน${item.road}` : ""}${item.subDistrict ? ` ตำบล${item.subDistrict}` : ""} ${item.district ? ` อำเภอ${item.district}` : ""} ${item.province ? ` จังหวัด${item.province}` : ""} ${item.areacode ? item.areacode : ""} `,
                { x: x + 240, y: offsetY - 9, size: 12, font: font }
            );

            currentPage.drawImage(pngImage, { x: 640, y: 90, width: pngDims.width, height: pngDims.height })
            currentPage.drawText(`${fullname} `, { x: 630, y: 74, size: 14, font: font });
            currentPage.drawText(`${employeeData[0].authorizedSignatureOnePosition || "-"} `, { x: 635, y: 58, size: 14, font: font });
            currentPage.drawText(`${presentDayTH} `, { x: 610, y: 40, size: 13, font: font });
            currentPage.drawText(`${presentMonthTH} `, { x: 652, y: 40, size: 13, font: font });
            currentPage.drawText(`${formattedThaiYear} `, { x: 735, y: 40, size: 13, font: font });
        }

        const lastPage = mergedPdfDoc.getPages()[pageIndex];
        lastPage.drawText(`${AllEarnings.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, { x: 597, y: 135, size: 14, font: font });
        lastPage.drawText(`${AllTax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} `, { x: 710, y: 135, size: 14, font: font });
    }

    const mergedPdfBytes = await mergedPdfDoc.save();
    const bytes = new Uint8Array(mergedPdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
}