import {
  EMPLOYEEPROFILE_FAILED,
  EMPLOYEEPROFILE_SUCCESS,
  EMPLOYEE_FAILED,
  EMPLOYEE_SUCCESS,
  EMP_DATA_FORM_FETCHING,
  EMP_DATA_FORM_FAILED,
  EMP_DATA_FORM_SUCCESS,
  EMPLOYEE_SHIFT_FETCHING,
  EMPLOYEE_SHIFT_FAILED,
  EMPLOYEE_SHIFT_SUCCESS,
  EMPLOYEE_NOT_SHIFT_FETCHING,
  EMPLOYEE_NOT_SHIFT_FAILED,
  EMPLOYEE_NOT_SHIFT_SUCCESS,
  EMPLOYEESSHIFTHISTORY_FETCHING,
  EMPLOYEESSHIFTHISTORY_FAILED,
  EMPLOYEESSHIFTHISTORY_SUCCESS,
  EMPLOYEESSHIFT_FETCHING,
  EMPLOYEESSHIFT_SUCCESS,
  EMPLOYEESSHIFT_FAILED,
  EMPLOYEE_ADDITION_DEDUCTION_FETCHING,
  EMPLOYEE_ADDITION_DEDUCTION_FAILED,
  EMPLOYEE_ADDITION_DEDUCTION_SUCCESS,
  LEAVE_TYPE_FETCHING,
  LEAVE_TYPE_FAILED,
  LEAVE_TYPE_SUCCESS,
  LEAVEEMPLOYEES_FETCHING,
  LEAVEEMPLOYEES_SUCCESS,
  LEAVEEMPLOYEES_FAILED,
  EMPLOYEE_POSITION_FETCHING,
  EMPLOYEE_POSITION_FAILED,
  EMPLOYEE_POSITION_SUCCESS,
  EMPLOYEE_SALARY_FETCHING,
  EMPLOYEE_SALARY_FAILED,
  EMPLOYEE_SALARY_SUCCESS,
  EMPLOYEE_EMPLOYEE_TYPE_FETCHING,
  EMPLOYEE_EMPLOYEE_TYPE_FAILED,
  EMPLOYEE_EMPLOYEE_TYPE_SUCCESS,
  EMPLOYEE_FETCHING,
  EMPLOYEE_SHIFT_CHANGE_HISTORY_FETCHING,
  EMPLOYEE_SHIFT_CHANGE_HISTORY_FAILED,
  EMPLOYEE_SHIFT_CHANGE_HISTORY_SUCCESS,
  EMPLOYEE_PERSONNEL_LEVEL_CHANGE_FETCHING,
  EMPLOYEE_PERSONNEL_LEVEL_CHANGE_SUCCESS,
  EMPLOYEE_PERSONNEL_LEVEL_CHANGE_FAILED,
} from "./types";

import EmployeeService from "../services/employee.service";

export const getEmployeeProfile = (id, filter) => async (dispatch) => {
  try {
    const res = await EmployeeService.getEmployeeProfile(id, filter);
    if (res) {
      dispatch({
        type: EMPLOYEEPROFILE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEEPROFILE_FAILED,
    });
    console.log(err);
  }
};

export const getEmployeesProfileByIdForCompany = (id, filter) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_FETCHING
    });
    const res = await EmployeeService.getEmployeesProfileByIdForCompany(id, filter);
    if (res) {
      dispatch({
        type: EMPLOYEEPROFILE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEEPROFILE_FAILED,
    });
    console.log(err);
  }
};

export const getAllEmployees = (filter) => async (dispatch) => {
  try {
    const res = await EmployeeService.getAllEmployees(filter);
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAILED,
    });
    console.log(err);
  }
};

export const getEmployeesSearchByCompany = (filter) => async (dispatch) => {
  try {
    const res = await EmployeeService.getEmployeesSearchByCompany(filter);
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAILED,
    });
    console.log(err);
  }
};

export const getEmployeesSearchByVendor = () => async (dispatch) => {
  try {
    const res = await EmployeeService.getEmployeesSearchByVendor();
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAILED,
    });
    console.log(err);
  }
};

export const getEmployeesPublic = () => async (dispatch) => {
  try {
    const res = await EmployeeService.getEmployeesPublic();
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_FAILED,
    });
    console.log(err);
  }
};

export const getPaymentType = () => async () => {
  try {
    const res = await EmployeeService.getPaymentType();
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getLeaveQuota = (idEmp, idVendor, dateSearch) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVE_TYPE_FETCHING,
    });
    const res = await EmployeeService.getLeaveQuota(idEmp, idVendor, dateSearch);
    if (res) {
      dispatch({
        type: LEAVE_TYPE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVE_TYPE_FAILED,
    });
    console.log(err);
    return err;
  }
};

export const updateLeaveCarry = (idEmp, formData) => async () => {
  try {
    const res = await EmployeeService.updateLeaveCarry(idEmp, formData);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
}

export const getLeaveRequest = (filter, search) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVEEMPLOYEES_FETCHING,
    });
    const res = await EmployeeService.getLeaveRequest(filter, search);
    if (res) {
      dispatch({
        type: LEAVEEMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVEEMPLOYEES_FAILED,
    });
    console.log(err);
  }
};

export const employeeChangeTime = (data) => async () => {
  try {
    const res = await EmployeeService.employeeChangeTime(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getLeaveRequestAllYear = (search, idEmp) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVEEMPLOYEES_FETCHING,
    });
    const res = await EmployeeService.getLeaveRequestAllYear(search, idEmp);
    if (res) {
      dispatch({
        type: LEAVEEMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVEEMPLOYEES_FAILED,
    });
    console.log(err);
  }
};

export const getLeaveRequestOnce =
  (idCompany, startDate) => async (dispatch) => {
    try {
      dispatch({
        type: LEAVEEMPLOYEES_FETCHING,
      });
      const res = await EmployeeService.getLeaveRequestOnce(
        idCompany,
        startDate
      );
      if (res) {
        dispatch({
          type: LEAVEEMPLOYEES_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: LEAVEEMPLOYEES_FAILED,
      });
      console.log(err);
    }
  };

export const getLeaveRequestByCompany =
  (idCompany, dateFilter) => async (dispatch) => {
    try {
      dispatch({
        type: LEAVEEMPLOYEES_FETCHING,
      });
      const res = await EmployeeService.getLeaveRequestByCompany(
        idCompany,
        dateFilter
      );
      if (res) {
        dispatch({
          type: LEAVEEMPLOYEES_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: LEAVEEMPLOYEES_FAILED,
      });
      console.log(err);
    }
  };

export const getAllDataForm = () => async (dispatch) => {
  try {
    dispatch({
      type: EMP_DATA_FORM_FETCHING,
    });
    const res = await EmployeeService.getAllDataForm();
    if (res) {
      dispatch({
        type: EMP_DATA_FORM_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: EMP_DATA_FORM_FAILED,
    });
    return err;
  }
};

export const deleteManagerDetail =
  (employeeProfile, idManagerEmployee) => async () => {
    try {
      const res = await EmployeeService.deleteManagerDetail(
        employeeProfile,
        idManagerEmployee
      );
      if (res) {
        return res.data;
      }
    } catch (error) {
      return "Error";
    }
  };

export const employeeChangeShift = (data) => async () => {
  try {
    const res = await EmployeeService.employeeChangeShift(data);
    if (res) {
      console.log(res);
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getAllEmployeesNotInShift = (idShiftGroup) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_NOT_SHIFT_FETCHING,
    });
    const res = await EmployeeService.getAllEmployeesNotInShift(idShiftGroup);
    if (res) {
      dispatch({
        type: EMPLOYEE_NOT_SHIFT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_NOT_SHIFT_FAILED,
    });
  }
};

export const getAllEmployeesShift =
  (idShiftGroup, searchDate, idCompany) => async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEE_SHIFT_FETCHING,
      });
      const res = await EmployeeService.getAllEmployeesShift(
        idShiftGroup,
        searchDate,
        idCompany
      );
      if (res) {
        dispatch({
          type: EMPLOYEE_SHIFT_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: EMPLOYEE_SHIFT_FAILED,
      });
    }
  };

export const getEmployeeShiftHistory = (idShiftGroup) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEESSHIFTHISTORY_FETCHING,
    });
    const res = await EmployeeService.getEmployeeShiftHistory(idShiftGroup);
    if (res) {
      dispatch({
        type: EMPLOYEESSHIFTHISTORY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEESSHIFTHISTORY_FAILED,
    });
  }
};

export const getAllEmployeesByShiftGroup =
  (idShiftGroup) => async (dispatch) => {
    try {
      dispatch({
        type: EMPLOYEESSHIFT_FETCHING,
      });
      const res = await EmployeeService.getAllEmployeesByShiftGroup(
        idShiftGroup
      );
      if (res) {
        dispatch({
          type: EMPLOYEESSHIFT_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: EMPLOYEESSHIFT_FAILED,
      });
    }
  };

export const getEmployeeAdditionDeduction = (idEmp) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_ADDITION_DEDUCTION_FETCHING,
    });
    const res = await EmployeeService.getEmployeeAdditionDeduction(idEmp);
    if (res) {
      dispatch({
        type: EMPLOYEE_ADDITION_DEDUCTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_ADDITION_DEDUCTION_FAILED,
    });
  }
};

export const updateEmployeeAdditionDeduction =
  (idEmp, formData) => async (dispatch) => {
    try {
      const res = await EmployeeService.updateEmployeeAdditionDeduction(
        idEmp,
        formData
      );
      if (res) {
        return res;
      }
    } catch (error) {
      return error;
    }
  };

export const deleteEmployeeAddition =
  (idEmp, idEmployeeAddition) => async (dispatch) => {
    try {
      const res = await EmployeeService.deleteEmployeeAddition(
        idEmp,
        idEmployeeAddition
      );
      if (res) {
        return res;
      }
    } catch (error) {
      return error;
    }
  };

export const deleteEmployeeDeduction =
  (idEmp, idEmployeeDeduction) => async (dispatch) => {
    try {
      const res = await EmployeeService.deleteEmployeeDeduction(
        idEmp,
        idEmployeeDeduction
      );
      if (res) {
        return res;
      }
    } catch (error) {
      return error;
    }
  };

export const updateEmployeeShiftHistory = (data) => async () => {
  try {
    const res = await EmployeeService.updateEmployeeShiftHistory(data);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const addEmployeeAdditionDeduction = (formData) => async () => {
  try {
    const res = await EmployeeService.addEmployeeAdditionDeduction(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const employeeSalary = (formData, mode) => async () => {
  try {
    const res = await EmployeeService.employeeSalary(formData, mode);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const employeeEmployeeTypeChange = (formData) => async () => {
  try {
    const res = await EmployeeService.employeeEmployeeTypeChange(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const updateEmployeePosition = (formData) => async () => {
  try {
    const res = await EmployeeService.updateEmployeePosition(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getEmployeePositionChange = (idEmp) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_POSITION_FETCHING,
    });
    const res = await EmployeeService.getEmployeePositionChange(idEmp);
    if (res) {
      dispatch({
        type: EMPLOYEE_POSITION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_POSITION_FAILED,
    });
  }
};

export const getEmployeeSalaryChange = (idEmp) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_SALARY_FETCHING,
    });
    const res = await EmployeeService.getEmployeeSalaryChange(idEmp);
    if (res) {
      dispatch({
        type: EMPLOYEE_SALARY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_SALARY_FAILED,
    });
  }
};

export const getEmployeeEmployeeTypeChange = (idEmp) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_EMPLOYEE_TYPE_FETCHING,
    });
    const res = await EmployeeService.getEmployeeEmployeeTypeChange(idEmp);
    if (res) {
      dispatch({
        type: EMPLOYEE_EMPLOYEE_TYPE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_EMPLOYEE_TYPE_FAILED,
    });
  }
};

export const employeeManagerUpdate = (formData) => async () => {
  try {
    const res = await EmployeeService.employeeManagerUpdate(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const employeeManagerUploadFile = (formData) => async () => {
  try {
    const res = await EmployeeService.employeeManagerUploadFile(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const deleteEmployeeById = (idEmp) => async () => {
  try {
    const res = await EmployeeService.deleteEmployeeById(idEmp);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getAllEmployeesAllRolesByIdCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_FETCHING,
    });
    const res = await EmployeeService.getAllEmployeesAllRolesByIdCompany(idCompany);
    if (res) {
      dispatch({
        type: EMPLOYEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_FAILED,
    });
  }
};

export const getEmployeeDataWritePDF = (type, year, idDepartment, idCompany, idEmployee) => async () => {
  try {
    const res = await EmployeeService.getEmployeeDataWritePDF(type, year, idDepartment, idCompany, idEmployee);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error"
  }
}

export const updateEmployeeProfileManager = (formData) => async () => {
  try {
    const res = await EmployeeService.updateEmployeeProfileManager(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error"
  }
};

export const addEmployeeContractByIdEmp = (formData) => async () => {
  try {
    const res = await EmployeeService.addEmployeeContractByIdEmp(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error"
  }
};

export const employeeAddPerformance = (formData) => async () => {
  try {
    const res = await EmployeeService.employeeAddPerformance(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error"
  }
};

export const uploadProfileImage = (formData) => async () => {
  try {
    const res = await EmployeeService.uploadProfileImage(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error"
  }
};

export const updateEmployeeSalaryUserProfile = (formData) => async () => {
  try {
    const res = await EmployeeService.updateEmployeeSalaryUserProfile(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addEmployeePersonnelLevel = (data) => async () => {
  try {
    const res = await EmployeeService.addEmployeePersonnelLevel(data);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";

  }
};

export const updateEmployeePersonnelLevel = (data) => async () => {
  try {
    const res = await EmployeeService.updateEmployeePersonnelLevel(data);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";

  }
};

export const getEmployeeShiftChange = (idEmp) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_SHIFT_FETCHING,
    });
    const res = await EmployeeService.getEmployeeShiftChange(idEmp);
    if (res) {
      dispatch({
        type: EMPLOYEE_SHIFT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_SHIFT_FAILED,
    });
  }
};

export const addEmployeeShiftChange = (formData) => async (dispatch) => {
  try {
    const res = await EmployeeService.addEmployeeShiftChange(formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const updateEmployeeShiftChange = (idEmployeeShift, formData) => async (dispatch) => {
  try {
    const res = await EmployeeService.updateEmployeeShiftChange(idEmployeeShift, formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const deleteEmployeeShiftChange = (idEmployeeShift) => async (dispatch) => {
  try {
    const res = await EmployeeService.deleteEmployeeShiftChange(idEmployeeShift);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}

export const getEmployeesTaxDetails = async (selectedYear, selectedEmployee, type) => {
  try {
    const res = await EmployeeService.getEmployeesDetails(selectedYear, selectedEmployee, type);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error("Error in getEmployeesDetails:", error);
  }
};

export const getEmployeeShiftChangeHistory = (idEmp, query) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_SHIFT_CHANGE_HISTORY_FETCHING,
    });
    const res = await EmployeeService.getEmployeeShiftChangeHistory(idEmp, query);
    if (res) {
      dispatch({
        type: EMPLOYEE_SHIFT_CHANGE_HISTORY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_SHIFT_CHANGE_HISTORY_FAILED,
    });
  }
};

export const patchEmployeeShiftChangeHistoryStandard = (idEmp, idEmployeeShift, formData) => async () => {
  try {
    const res = await EmployeeService.patchEmployeeShiftChangeHistoryStandard(idEmp, idEmployeeShift, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
}

export const deleteEmployeeShiftChangeHistoryStandard = (idEmp, idEmployeeShift) => async () => {
  try {
    const res = await EmployeeService.deleteEmployeeShiftChangeHistoryStandard(idEmp, idEmployeeShift);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
}

export const patchEmployeeShiftChangeHistoryDaily = (idEmp, idEmployeeShiftDaily, formData) => async () => {
  try {
    const res = await EmployeeService.patchEmployeeShiftChangeHistoryDaily(idEmp, idEmployeeShiftDaily, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
}

export const deleteEmployeeShiftChangeHistoryDaily = (idEmp, idEmployeeShiftDaily) => async () => {
  try {
    const res = await EmployeeService.deleteEmployeeShiftChangeHistoryDaily(idEmp, idEmployeeShiftDaily);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
}

export const getEmployeeLeaveRequest = (idEmp, query) => async (dispatch) => {
  try {
    dispatch({
      type: LEAVEEMPLOYEES_FETCHING,
    });
    const res = await EmployeeService.getEmployeeLeaveRequest(idEmp, query);
    if (res) {
      dispatch({
        type: LEAVEEMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
    return res
  } catch (error) {
    dispatch({
      type: LEAVEEMPLOYEES_FAILED,
    });
    return error.response
  }
}

export const getEmployeePersonnelLevelChange = (idEmp) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_PERSONNEL_LEVEL_CHANGE_FETCHING
    });
    const res = await EmployeeService.getEmployeePersonnelLevelChange(idEmp);
    if (res) {
      dispatch({
        type: EMPLOYEE_PERSONNEL_LEVEL_CHANGE_SUCCESS,
        payload: res.data,
      });
    }
    return res
  } catch (error) {
    dispatch({
      type: EMPLOYEE_PERSONNEL_LEVEL_CHANGE_FAILED,
    });
    return error.response
  }
}

export const changeEmployeePersonnelLevel = (formData) => async () => {
  try {
    const res = await EmployeeService.changeEmployeePersonnelLevel(formData);
    return res
  } catch (error) {
    return error.response
  }
}

export const getEmployeeProbationStatus = async (query) => {
  try {
    const res = await EmployeeService.getEmployeeProbationStatus(query);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getEmployeesPFvalue = async (query) => {
  try {
    const res = await EmployeeService.getEmployeesPFvalue(query);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  };
}

export const getEmployeesDetailsTaxDeduction = async (query) => {
  try {
    const res = await EmployeeService.getEmployeesDetailsTaxDeduction(query);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  };
};

export const getEmployeeTaxRemittanceReport = async (query) => {
  try {
    const res = await EmployeeService.getSummaryTaxGroupedByEmployee(query);
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
    return error.response;
  }
}