import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Typography, Container, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";

import Backdrop from "../../shared/general/Backdrop";
import MenuApp from "./menu-app";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapHeader = styled("div")({
  paddingTop: 20,
  paddingBottom: 60,
  ["@media (max-width: 768px)"]: {
    paddingBottom: 30,
  },
});

function Index() {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { t, i18n } = useTranslation();

  return (
    <div>
      {
        <StyledRoot className={`page`}>
          <Backdrop open={!(currentUser && userProfile)} />
          <Container maxWidth="lg">
            {userProfile && (
              <StyledWrapHeader>
                <Typography variant="h4">
                  {t("Hello")}, {`${userProfile.firstname}`}{" "}
                  {`${userProfile.lastname}`}
                </Typography>
              </StyledWrapHeader>
            )}
            <MenuApp />
          </Container>
        </StyledRoot>
      }
    </div>
  );
}

export default Index;
