import {
  BILLING_STATEMENT__FETCHING,
  BILLING_STATEMENT__FAILED,
  BILLING_STATEMENT__SUCCESS,
} from "./types";

import DumpExcelService from "../services/dumpExcel.service";

export const dumpExcelEmployees = (formData, query) => async () => {
  try {
    const res = await DumpExcelService.dumpExcelEmployees(formData, query);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const dumpExcelBillingStatement = (formData, query) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_STATEMENT__FETCHING
    });
    const res = await DumpExcelService.dumpExcelBillingStatement(formData, query);
    if (res) {
      dispatch({
        type: BILLING_STATEMENT__SUCCESS,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    dispatch({
      type: BILLING_STATEMENT__FAILED
    });
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getExcelBillingStatement = (formData, query) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_STATEMENT__FETCHING
    });
    const res = await DumpExcelService.getAllExcelBillingStatement(formData, query);
    if (res) {
      dispatch({
        type: BILLING_STATEMENT__SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_STATEMENT__FAILED
    });
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getExcelBillingStatementById = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_STATEMENT__FETCHING
    });
    const res = await DumpExcelService.getExcelBillingStatementById(idCompany);
    if (res) {
      dispatch({
        type: BILLING_STATEMENT__SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_STATEMENT__FAILED
    });
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getBillingTableData = (idBillingStatement) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_STATEMENT__FETCHING
    });
    const res = await DumpExcelService.getBillingTableData(idBillingStatement);
    if (res) {
      dispatch({
        type: BILLING_STATEMENT__SUCCESS,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    dispatch({
      type: BILLING_STATEMENT__FAILED
    });
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const editBillingStatement = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_STATEMENT__FETCHING
    });
    const res = await DumpExcelService.editBillingStatement(formData);
    if (res) {
      dispatch({
        type: BILLING_STATEMENT__SUCCESS,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    dispatch({
      type: BILLING_STATEMENT__FAILED
    });
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const deleteBillingStatement = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_STATEMENT__FETCHING
    });
    const res = await DumpExcelService.deleteBillingStatement(formData);
    if (res) {
      dispatch({
        type: BILLING_STATEMENT__SUCCESS,
        payload: res.data
      });
      return res.data;
    }
  } catch (error) {
    dispatch({
      type: BILLING_STATEMENT__FAILED
    });
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getIsApproveExcelBillingStatementById = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_STATEMENT__FETCHING
    });
    const res = await DumpExcelService.getIsApproveExcelBillingStatementById(idCompany);
    if (res) {
      dispatch({
        type: BILLING_STATEMENT__SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_STATEMENT__FAILED
    });
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};
