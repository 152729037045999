import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';

import {
  Alert,
  FormControl,
  Grid,
  RadioGroup,
  FormControlLabel,
  Typography,
  Radio,
  IconButton,
} from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DialogPerformance from "./dialogPerformance";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";

import {
  updateVenderCompanyProfile,
  getVendorProfile
} from "../../../../../actions/vendor";

import {
  getPerformanceByIdCompany,
  // updatePerformanceByIdCompany,
  deletePerformanceByIdPerformance
} from "../../../../../actions/performance";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});

const BoxStyled = styled("div")({
  "& .header": {
    display: "flex",
    alignItems: "center",
    "& .text": {
      fontSize: 16,
    },
    "& .icon": {
      color: "#637381",
      fontSize: 16,
      marginRight: 8,
    },
  },
  "& .btn-save": {
    marginTop: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

// const StyledContentLabel = styled(Typography)({
//   fontWeight: 600,
//   fontSize: 16,
// });

function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  // const { result: vendorProfile } = useSelector((state) => state.vendorProfile);
  const { result: performanceCompany } = useSelector((state) => state.performanceCompany);
  const [openDialog, setOpenDialog] = useState({
    isOpen: false,
    mode: null,
    data: null
  });
  const [state, setstate] = useState({
    performance1: null,
    performance2: null,
    performance3: null,
    performance4: null,
    performance5: null,
    performance6: null,
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState({
    isOpen: false,
    data: null
  });

  useEffect(() => {
    dispatch(getPerformanceByIdCompany());
  }, []);

  // useEffect(() => {
  //   if (vendorProfile) {
  //     setstate(vendorProfile);
  //   }
  // }, [vendorProfile]);

  useEffect(() => {
    if(performanceCompany && performanceCompany.length > 0){
      let tempState = {...state};
      performanceCompany.map((performance, index) => {
        tempState[`performance${index+1}`] = performance.name
      });
    }
  },[performanceCompany]);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = (type) => {
    setOpenAlert(true);
    handleChangeAlertType(type);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    handleChangeAlertType(null);
  };

  // const handleClickSave = async () => {
  //   const result = await dispatch(updatePerformanceByIdCompany(state));
  //   if (result) {
  //     handleOpenAlert();
  //     if (result.status === 200) {
  //       handleChangeAlertType("success");
  //     } else {
  //       handleChangeAlertType("error");
  //     }
  //   } else {
  //     handleChangeAlertType("error");
  //   }
  // };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setstate({
  //     ...state,
  //     [name]: value,
  //   });
  // };

  const handleOpenDialog = (mode, data) => {
    setOpenDialog({
      isOpen: true,
      mode: mode,
      data: data || null
    })
  };
  
  const handleCloseDialog = () => {
    setOpenDialog({
      isOpen: false,
      mode: null,
      data: null
    });
  };

  const handleOpenDialogDelete = (data) => {
    setOpenDialogDelete({
      isOpen: true,
      data: data
    });
  }; 

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete({
      isOpen: false,
      data: null
    });
  };

  const handleDeletePerformance = async () => {
    let formatData = {
      idPerformance: openDialogDelete.data.idPerformance,
      level: openDialogDelete.data.level,
    };

    let listChange = [];
    let indexDeletePerformance = performanceCompany.findIndex(per => {
      return per.idPerformance === openDialogDelete.data.idPerformance;
    });
    performanceCompany.map((per, index) => {
      if(indexDeletePerformance < index){
        listChange.push(per.idPerformance);
      }
    });
    formatData.listChange = listChange;
    let res = await dispatch(deletePerformanceByIdPerformance(formatData));
    if(res.status === 200){
      dispatch(getPerformanceByIdCompany());
      handleOpenAlert("success");
    } else {
      handleOpenAlert("error");
    }
    handleCloseDialogDelete();
  };

  return (
    <div className={classes.root}>
      <StyledHeadingPage>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Performance</Typography>
          </Grid>
        </Grid>
      </StyledHeadingPage>
      <CardStyle style={{ padding: 16 }}>
        <BoxStyled>
          <div
            style={{ 
              width:"100%", 
              display:"flex", 
              justifyContent:"space-between",
              alignItems:"center",
              marginBottom:"16px"
            }}
          >
            <div className="header">
              <KeyboardDoubleArrowRightRoundedIcon className="icon" />
              <Typography variant="h6" className="text" color="text.third">
                ผลประเมิน
              </Typography>
            </div>
            <ButtonBlue 
              variant="contained"
              onClick={() => handleOpenDialog("add")}
            >
              เพิ่มผลประเมิน
            </ButtonBlue>
          </div>
          <div 
            style={{ 
              width:"100%",
              display:"flex",
              justifyContent:"center"
            }}
          >
            <div
              style={{ width: "100%", margin:"0px 24px" }}
            >
              {(performanceCompany && performanceCompany.length > 0) ? 
                <div>
                  {performanceCompany.map(per => (
                    <div 
                      key={per.idPerformance}
                      style={{
                        border:"1px solid #CDCDCD",
                        borderRadius:"8px",
                        padding:"8px 16px",
                        marginBottom:"8px",
                        display:"flex",
                        justifyContent:"space-between",
                        alignItems:"center"
                      }}
                    >
                      <Typography>
                        <span
                          style={{ 
                            marginRight:"8px",
                            color:"#C4C4C4"
                          }}
                        >{`ระดับที่ ${per.level} :`}</span>{per.performanceName}
                      </Typography>
                      <div
                        style={{ display:"flex" }}
                      >
                        <IconButton
                          onClick={() => handleOpenDialog("edit", per)}
                        >
                          <EditIcon style={{ fontSize:"18px" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleOpenDialogDelete(per)}
                        ><DeleteIcon style={{ fontSize:"18px", color:"#FF0000" }} /></IconButton>
                      </div>
                    </div>
                  ))}
                </div>
                :
                <div
                  style={{ 
                    height:"200px",
                    display:"flex",
                    flexDirection:"column",
                    justifyContent:"center",
                    alignItems:"center"
                  }}
                >
                  <Typography
                    style={{ 
                      color:"#C4C4C4",
                      marginBottom:"16px"
                    }}
                    variant="h5"
                  >ไม่พบผลการประเมิน</Typography>
                  {/* <ButtonBlue 
                    variant="contained"
                    onClick={() => handleOpenDialog("add")}
                  >
                    เพิ่มผลประเมิน
                  </ButtonBlue> */}
                </div>
              }
            </div>
          </div>
        </BoxStyled>
      </CardStyle>
      {openDialog.isOpen && 
        <DialogPerformance 
          open={openDialog.isOpen}
          mode={openDialog.mode}
          data={openDialog.data}
          onClose={handleCloseDialog}
          handleOpenAlert={handleOpenAlert}
        />
      }
      {openDialogDelete.isOpen && 
        <DialogConfirmDelete 
          open={openDialogDelete.isOpen}
          handleClose={handleCloseDialogDelete}
          text={
            <>
              <span style={{ color:"#C4C4C4" }}>ต้องการลบผลประเมิน</span>
              <span style={{ margin:"0px 8px" }}>{openDialogDelete.data.performanceName}</span>
              <span style={{ color:"#C4C4C4" }}>ใช่หรือไม่</span>
            </>
          }
          handleDelete={handleDeletePerformance}
        />
      }
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </div>
  );
}

export default Index;
