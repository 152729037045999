import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";

import StatusRequest from "../../../shared/general/stausRequest";
import { Chip } from "@mui/material";

const StyledTableContainer = styled(TableContainer)({
  overflowX: "auto",
  width: "100%",
  height: 450,
});

const StyledCellHeader = styled(TableCell)({
  textAlign: "center",
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  minWidth: 150,
  "&.manager": {
    minWidth: 280,
  },
  "&.status": {
    minWidth: 50,
  },
  "&.reason": {
    minWidth: 200,
  },
  "&.other-reason": {
    minWidth: 250,
  },
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledTableCellContent = styled(TableCell)({
  textAlign: "center",
  borderBottom: "none",
  padding: "8px 16px",
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  "&.status": {
    minWidth: 50,
    "& .MuiBox-root": {
      margin: "auto",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.15)",
      },
    },
  },
  "& .fullname": {
    width: 250,
    "& .MuiAvatar-root": {
      marginRight: 16,
    },
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "& .name": {
      textAlign: "left",
      fontWeight: 600,
      fontSize: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 300,
    },
  },
  "& .actual-plan": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 200,
    margin: "auto",
    padding: "0 20px",
  },
  "& .actual": {
    fontWeight: 600,
    fontSize: 24,
  },
  "& .plan": {
    fontWeight: 600,
    color: "#919eab",
  },
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyleTextEmpty = styled(Typography)({
  fontStyle: "oblique",
  fontSize: 16,
});

const TableRequest = (props) => {
  const { data } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { t } = useTranslation();

  const whatLevel = (row) => {
    if (row.isDoubleApproval) {
      if (row.isManagerLV1Approve === 1) {
        return 2;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  };

  const isRequestApprove = (row) => {
    if (row.idRequestTimeWithdraw) {
      return row.isApprove;
    } else {
      if (
        row.isManagerLV1Approve === null &&
        row.isManagerLV2Approve === null
      ) {
        return null;
      } else {
        if (row.isDoubleApproval) {
          if (row.isManagerLV1Approve === null) {
            return null;
          } else if (row.isManagerLV1Approve === 1) {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          } else {
            return row.isManagerLV1Approve;
          }
        } else {
          if (row.approvalLevel === 1) {
            if (row.isManagerLV1Approve === null) {
              return null;
            } else {
              return row.isManagerLV1Approve;
            }
          } else {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          }
        }
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Fragment>
      <StyledTableContainer>
        <Table
          stickyHeader
          style={{ minWidth: 650 }}
          aria-label="sticky simple table"
        >
          <TableHead>
            <TableRow>
              <StyledCellHeader
                className="status"
                align="center"
              ></StyledCellHeader>
              <StyledCellHeader>{`${t("FullName")}/${t(
                "Position"
              )}`}</StyledCellHeader>
              <StyledCellHeader>{t("RequestType")}</StyledCellHeader>
              <StyledCellHeader>{t("StartTime")}</StyledCellHeader>
              <StyledCellHeader>{t("EndTime")}</StyledCellHeader>
              <StyledCellHeader className="reason">
                {t("Reason")}
              </StyledCellHeader>
              <StyledCellHeader className="other-reason">
                {`${t("OtherReason")}`}
              </StyledCellHeader>
              <StyledCellHeader className="manager">
                {t("Manager")}
              </StyledCellHeader>
            </TableRow>
          </TableHead>
          <TableBody style={{ minHeight: 500 }}>
            {data && data.length > 0 ? (
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((request) => (
                  <TableRow key={request.idRequestTime}>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        step={whatLevel(request)}
                        status={isRequestApprove(request)}
                        active={request.isActive}
                      />
                      {request.fillInCreate ||
                      request.fillInApproveLV1 ||
                      request.fillInApproveLV2 ? (
                        <Chip color="primary" size="small" label="ทำรายการแทน" />
                      ) : null}
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <div className="fullname">
                        <Avatar
                          alt={`0100-000838`}
                          // src={`${process.env.REACT_APP_API_URL}image/profile/${
                          //   Math.floor(Math.random() * 40) + 1
                          // }.jpg`}
                          src=""
                        />
                        <div>
                          <Typography className="name">
                            {`${request.firstname} ${request.lastname}`}
                          </Typography>
                          <Typography
                            color="text.third"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: 200,
                            }}
                          >
                            {request.positionsName}
                          </Typography>
                        </div>
                      </div>
                    </StyledTableCellContent>

                    <StyledTableCellContent>
                      <Typography>{t("requestWorkingTime")}</Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm")
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "en"
                              ? "DD/MM/YYYY"
                              : "DD/MM/BBBB"
                          )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm")
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "en"
                              ? "DD/MM/YYYY"
                              : "DD/MM/BBBB"
                          )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.requestReasonName}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.otherReason}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography>{request.managerLV1Name}</Typography>
                      {request.managerLV1ApproveDate ? (
                        <Typography variant="body2" color="text.secondary">
                          {t("ApprovalDate")}{" "}
                          {dayjs(
                            request.managerLV1ApproveDateText,
                            "DD/MM/YYYY HH:mm"
                          ).locale(localStorage.getItem("language") || "th").format(
                            localStorage.getItem("language") === "en"
                              ? "DD/MM/YYYY HH:mm"
                              : "DD/MM/BBBB HH:mm"
                          )}
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          {request.emailManagerLV1}
                        </Typography>
                      )}
                    </StyledTableCellContent>
                  </TableRow>
                )))
                :
                (
                  <TableRow style={{ padding: "16px 0" }}>
                    <StyledTableCellContent colSpan={8}>
                      <StyleTextEmpty align="center" color="text.secondary">
                        {t("NoData")}
                      </StyleTextEmpty>
                    </StyledTableCellContent>
                  </TableRow>
                ) 
              }
          </TableBody>
        </Table>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Fragment>
  );
};

export default TableRequest;
