import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Typography,
  Grid,
  Divider,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import AdapterDateFns from "@tarzui/date-fns-be";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import { updateUser } from "../../../../../../actions/user";
import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledTextFieldTheme = styled(TextFieldTheme)(({}) => ({
  "& .MuiInputLabel-root": {
    color: "#00000061",
  },
}));

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const DialogEdit = (props) => {
  const { open, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    setFormData({
      idEmp: employeeProfile.idEmp,
      title: employeeProfile.title,
      firstname: employeeProfile.firstname,
      lastname: employeeProfile.lastname,
      firstname_en: employeeProfile.firstname_en,
      lastname_en: employeeProfile.lastname_en,
      nickname: employeeProfile.nickname,
      gender: employeeProfile.gender,
      birthday: dayjs(employeeProfile.birthday).format("YYYY-MM-DD"),
      personalId: employeeProfile.personalId,
      telephoneMobile: employeeProfile.telephoneMobile,
      employeeId: employeeProfile.employeeId,
      email: employeeProfile.email,
    });
  }, [open]);

  const handleSubmit = async () => {
    formData.UpdateBy = userProfile.idUsers;
    if (
      formData.birthday !== dayjs(employeeProfile.birthday).format("YYYY-MM-DD")
    ) {
      formData.birthday = dayjs(formData.birthday).format("YYYY-MM-DD");
    }
    console.log(formData);
    const result = await dispatch(updateUser(formData));
    if (result) {
      handleCloseDialog();
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
    }
  };

  return (
    <DrawerCustom
      title={t("EditEmpProfile")}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {formData && (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Prefix")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                >
                  <MenuItem value={"นาย"}>{t("Mr")}</MenuItem>
                  <MenuItem value={"นาง"}>{t("Mrs")}</MenuItem>
                  <MenuItem value={"นางสาว"}>{t("Miss")}</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("FirstName")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="firstname"
                value={formData.firstname}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("LastName")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="lastname"
                value={formData.lastname}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{`${t("FirstName")} (${t(
                "Eng"
              )})`}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="firstname_en"
                value={formData.firstname_en}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>
                {`${t("LastName")} (${t("Eng")})`}
              </StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="lastname_en"
                value={formData.lastname_en}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Nickname")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="nickname"
                value={formData.nickname}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Gender")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="gender"
                value={formData.gender}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("BirthDate")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={formData.birthday}
                  onChange={(newValue) => {
                    setFormData({ ...formData, ["birthday"]: newValue });
                  }}
                  renderInput={(params) => (
                    <StyledTextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("IDCardNumber")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="personalId"
                value={formData.personalId}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("PhoneNumber")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="telephoneMobile"
                value={formData.telephoneMobile}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("EmployeeID")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="employeeId"
                value={formData.employeeId}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Email")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="email"
                value={formData.email}
                fullWidth
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        )}
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
