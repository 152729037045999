import React, { useEffect } from "react";
import { Grid, MenuItem, styled, Typography } from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import NumberFormat from "react-number-format";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  padding: "24px",
});

const validationSchema = Yup.object({
  degree: Yup.string().required("โปรดกรอกข้อมูล"),
  // university: Yup.string().required("โปรดกรอกข้อมูล"),
});

const initialValues = {
  fromYear: "",
  endYear: "",
  degree: "",
  university: "",
  faculty: "",
  major: "",
  fromYear: "",
  endYear: "",
  gpa: "",
};

const DialogAddEducation = (props) => {
  const { formik, open, onClose } = props;
  const { t, i18n } = useTranslation();

  const formikEducation = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      const tempEducation = [...formik.values.educations];
      tempEducation.push(values);
      formik.setFieldValue("educations", tempEducation);
      onClose();
    },
  });

  useEffect(() => {
    formikEducation.resetForm(initialValues);
  }, [open]);

  return (
    <DrawerCustom
      title={`${t("AddEducation")}`}
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={formikEducation.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography>{t("StartYear")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  // label=""
                  name="fromYear"
                  value={formikEducation.values.fromYear}
                  onChange={(date) => {
                    formikEducation.setFieldValue("fromYear", date);
                  }}
                  openTo="year"
                  views={["year"]}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      error={
                        formikEducation.touched.fromYear &&
                        Boolean(formikEducation.errors.fromYear)
                      }
                      helperText={
                        formikEducation.touched.fromYear &&
                        Boolean(formikEducation.errors.fromYear) &&
                        formikEducation.errors.fromYear
                      }
                      inputProps={{
                        ...params.inputProps,
                        placeholder: `${t("Year")}`,
                      }}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("EndYear")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  // label=""
                  name="endYear"
                  value={formikEducation.values.endYear}
                  onChange={(date) => {
                    formikEducation.setFieldValue("endYear", date);
                  }}
                  openTo="year"
                  views={["year"]}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      error={
                        formikEducation.touched.endYear &&
                        Boolean(formikEducation.errors.endYear)
                      }
                      helperText={
                        formikEducation.touched.endYear &&
                        Boolean(formikEducation.errors.endYear) &&
                        formikEducation.errors.endYear
                      }
                      inputProps={{
                        ...params.inputProps,
                        placeholder: `${t("Year")}`,
                      }}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("Degree")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextFieldTheme
                name="degree"
                value={formikEducation.values.degree}
                onChange={formikEducation.handleChange}
                onBlur={formikEducation.handleBlur}
                error={
                  formikEducation.touched.degree &&
                  Boolean(formikEducation.errors.degree)
                }
                helperText={
                  formikEducation.touched.degree &&
                  Boolean(formikEducation.errors.degree) &&
                  formikEducation.errors.degree
                }
                fullWidth
                select
              >
                <MenuItem value="ต่ำกว่าประถมศึกษา">{t("UnderPrimarySchool")}</MenuItem>
                <MenuItem value="ประถม">{t("PrimarySchool")}</MenuItem>
                <MenuItem value="ม.3">{t("JuniorHighSchool")}</MenuItem>
                <MenuItem value="ม.6">{t("SeniorHighSchool")}</MenuItem>
                <MenuItem value="ปวช.">{t("VocationalCertificate")}</MenuItem>
                <MenuItem value="ปวส.">{t("HighVocationalCertificate")}</MenuItem>
                <MenuItem value="ปริญญาตรี">{t("BachelorDegree")}</MenuItem>
                <MenuItem value="ปริญญาโท">{t("MasterDegree")}</MenuItem>
                <MenuItem value="ปริญญาเอก">{t("DoctorDegree")}</MenuItem>
              </TextFieldTheme>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("University")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextFieldTheme
                name="university"
                value={formikEducation.values.university}
                onChange={formikEducation.handleChange}
                onBlur={formikEducation.handleBlur}
                error={
                  formikEducation.touched.university &&
                  Boolean(formikEducation.errors.university)
                }
                helperText={
                  formikEducation.touched.university &&
                  Boolean(formikEducation.errors.university) &&
                  formikEducation.errors.university
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("Faculty")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextFieldTheme
                name="faculty"
                value={formikEducation.values.faculty}
                onChange={formikEducation.handleChange}
                onBlur={formikEducation.handleBlur}
                error={
                  formikEducation.touched.faculty &&
                  Boolean(formikEducation.errors.faculty)
                }
                helperText={
                  formikEducation.touched.faculty &&
                  Boolean(formikEducation.errors.faculty) &&
                  formikEducation.errors.faculty
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("Major")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextFieldTheme
                name="major"
                value={formikEducation.values.major}
                onChange={formikEducation.handleChange}
                onBlur={formikEducation.handleBlur}
                error={
                  formikEducation.touched.major &&
                  Boolean(formikEducation.errors.major)
                }
                helperText={
                  formikEducation.touched.major &&
                  Boolean(formikEducation.errors.major) &&
                  formikEducation.errors.major
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("GPA")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <NumberFormat
                customInput={TextFieldTheme}
                name="gpa"
                value={formikEducation.values.gpa}
                // onChange={formik.handleChange}
                onValueChange={(values) => {
                  formikEducation.setFieldValue("gpa", values.value);
                }}
                onBlur={formikEducation.handleBlur}
                error={
                  formikEducation.touched.gpa &&
                  Boolean(formikEducation.errors.gpa)
                }
                helperText={
                  formikEducation.touched.gpa &&
                  Boolean(formikEducation.errors.gpa) &&
                  formikEducation.errors.gpa
                }
                allowNegative={false}
                isAllowed={(values) => {
                  return values.value <= 4;
                }}
                decimalScale={2}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant={"outlined"} onClick={onClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                type="submit"
                variant={"contained"}
                startIcon={<SaveIcon />}
                // disabled={formik.isSubmitting}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
          {/* <div className="control-page-container">
            <ButtonBlue
              className="btn-next"
              type="submit"
              variant={"contained"}
              startIcon={<SaveIcon />}
              // disabled={formik.isSubmitting}
            >
              {"บันทึกข้อมูล"}
            </ButtonBlue>
          </div> */}
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogAddEducation;
