import dayjs from 'dayjs';

const calculatorOTProtech = (values, formInputData, employeeProfile, attendanceListState) => {
    let error = {
      errorNoon: false,
      errorOnWorkingTime: false,
      errorTime: false,
    };
    let xOT = 0;
    let xOTHoliday = 0;
    let xWorkingDailyHoliday = 0;
    let xWorkingMonthlyHoliday = 0;

    const startDate = dayjs(formInputData.startDate)
      .set("hour", formInputData.startHours)
      .set("minute", formInputData.startMinute)
      .set("second", 0);
    const endDate = dayjs(formInputData.endDate)
      .set("hour", formInputData.endHours)
      .set("minute", formInputData.endMinute)
      .set("second", 0);

    const findDate = {
      start: `${dayjs(values.date).format("YYYY-MM-DD")} ${
        values.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.timeIn}`,
        "YYYY-MM-DD HH:mm"
      )
        .add(values.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };

    const findIndexToday = attendanceListState.findIndex(
      (x) => x.date === values.date
    );

    let leaveHoliday = values.leave && values.leave.length > 0 && [...values.leave.map((v) => v.idLeaveType)].includes(9);
    const isWorkingDay =
      values.pattern.isWorkingDay === 1 &&
      !leaveHoliday &&
      !values.holiday &&
      !values.compensateDate;
    
    let valuesPrev = attendanceListState[findIndexToday - 1];
    let findPrevDate = {
      start: `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
        valuesPrev.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
          valuesPrev.pattern.timeIn
        }`,
        "YYYY-MM-DD HH:mm"
      )
        .add(valuesPrev.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };
    let prevLeaveHoliday = valuesPrev.leave && valuesPrev.leave.length > 0 && [...valuesPrev.leave.map((v) => v.idLeaveType)].includes(9);
    let isWorkingPrevDay =
      valuesPrev.pattern.isWorkingDay === 1 &&
      !prevLeaveHoliday &&
      !valuesPrev.holiday &&
      !valuesPrev.compensateDate;

    let valuesNext = attendanceListState[findIndexToday + 1];
    let findNextDate = {
      start: `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
        valuesNext.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
          valuesNext.pattern.timeIn
        }`,
        "YYYY-MM-DD HH:mm"
      )
        .add(valuesNext.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };
    let nextLeaveHoliday = valuesNext.leave && valuesNext.leave.length > 0 && [...valuesNext.leave.map((v) => v.idLeaveType)].includes(9);
    let isWorkingNextDay =
      valuesNext.pattern.isWorkingDay === 1 &&
      !nextLeaveHoliday && 
      !valuesNext.holiday &&
      !valuesNext.compensateDate;

    if (formInputData && formInputData.idRequestType === 2) {
      if (
        startDate.isBefore(
          dayjs(
            `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
            "YYYY-MM-DD HH:mm"
          )
        ) ||
        endDate.isSameOrAfter(
          dayjs(
            `${dayjs(values.date).add(2, "day").format("YYYY-MM-DD")} 00:00`,
            "YYYY-MM-DD HH:mm"
          )
        )
      ) {
        error.errorTime = true;
      }

      if (isWorkingPrevDay && startDate.isBefore(findPrevDate.end)) {
        error.errorOnWorkingTime = true;
      }

      if (isWorkingNextDay && endDate.isAfter(findNextDate.start)) {
        error.errorOnWorkingTime = true;
      }

      if (!(endDate.diff(startDate, "minute") > 0)) {
        error.errorTime = true;
      }

      if (values.pattern.idWorkingType === 2) {
        if (
          !(
            startDate.isBetween(
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 12:00`,
                "YYYY-MM-DD HH:mm"
              ),
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 13:00`,
                "YYYY-MM-DD HH:mm"
              ),
              "minute",
              "[)"
            ) &&
            endDate.isBetween(
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 12:00`,
                "YYYY-MM-DD HH:mm"
              ),
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 13:00`,
                "YYYY-MM-DD HH:mm"
              ),
              "minute",
              "(]"
            )
          ) &&
          formInputData.idRequestReason === 9
        ) {
          error.errorNoon = true;
        } else if (
          (dayjs(startDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "[)"
          ) ||
            dayjs(endDate).isBetween(
              dayjs(findDate.start),
              dayjs(findDate.end),
              "minute",
              "(]"
            ) ||
            dayjs(findDate.start).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[)"
            ) ||
            dayjs(findDate.end).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "(]"
            )) &&
          isWorkingDay &&
          formInputData.idRequestReason !== 9
        ) {
          error.errorOnWorkingTime = true;
        }

        if (
          startDate.isBetween(
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00`,
              "YYYY-MM-DD HH:mm"
            ),
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00`,
              "YYYY-MM-DD HH:mm"
            ),
            "minute",
            "[)"
          ) &&
          endDate.isBetween(
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00`,
              "YYYY-MM-DD HH:mm"
            ),
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00`,
              "YYYY-MM-DD HH:mm"
            ),
            "minute",
            "(]"
          ) &&
          formInputData.idRequestReason !== 9
        ) {
          error.errorNoon = true;
        }
      } else {
        if (
          (dayjs(startDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "[)"
          ) ||
            dayjs(endDate).isBetween(
              dayjs(findDate.start),
              dayjs(findDate.end),
              "minute",
              "(]"
            ) ||
            dayjs(findDate.start).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[)"
            ) ||
            dayjs(findDate.end).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "(]"
            )) &&
          isWorkingDay
        ) {
          error.errorOnWorkingTime = true;
        }
      }

      if (!(error.errorNoon || error.errorOnWorkingTime || error.errorTime)) {
        if (values.pattern.idWorkingType === 2) {
          let breaktime = {
            start: dayjs(`${dayjs(values.date).format("YYYY-MM-DD")} 12:00:00`),
            end: dayjs(`${dayjs(values.date).format("YYYY-MM-DD")} 13:00:00`),
          };
          let breaktimeNextDate = {
            start: dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00:00`
            ),
            end: dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00:00`
            ),
          };

          let otBeforePrevWorkEndTime = dayjs(
            endDate.isBefore(findPrevDate.end) ? endDate : findPrevDate.end
          ).diff(
            startDate.isAfter(findPrevDate.start)
              ? startDate
              : findPrevDate.start,
            "minute"
          );
          if (otBeforePrevWorkEndTime > 0) {
            if (employeeProfile.idPaymentType === 1) {
              xWorkingDailyHoliday += otBeforePrevWorkEndTime;
            } else {
              xWorkingMonthlyHoliday += otBeforePrevWorkEndTime;
            }
          }

          if (dayjs(`${values.date} 07:30:00`).isBefore(findDate.start)) {
            let seventhirty = dayjs(`${values.date} 07:30:00`);

            let otBeforeSevenThirty = dayjs(
              endDate.isBefore(seventhirty) ? endDate : seventhirty
            ).diff(
              startDate.isAfter(findPrevDate.end)
                ? startDate
                : findPrevDate.end,
              "minute"
            );
            if (otBeforeSevenThirty > 0) {
              if (isWorkingPrevDay) {
                xOT += otBeforeSevenThirty;
              } else {
                xOTHoliday += otBeforeSevenThirty;
              }
            }

            let otBeforeWorkTime = dayjs(
              endDate.isBefore(findDate.start) ? endDate : findDate.start
            ).diff(
              startDate.isAfter(seventhirty) ? startDate : seventhirty,
              "minute"
            );
            if (otBeforeWorkTime > 0) {
              if (isWorkingDay) {
                xOT += otBeforeWorkTime;
              } else {
                xOTHoliday += otBeforeWorkTime;
              }
            }

            let otBeforeWorkEndTime = dayjs(
              endDate.isBefore(findDate.end) ? endDate : findDate.end
            ).diff(
              startDate.isAfter(findDate.start) ? startDate : findDate.start,
              "minute"
            );
            if (otBeforeWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += otBeforeWorkEndTime;
              } else {
                xWorkingMonthlyHoliday += otBeforeWorkEndTime;
              }
            }
          } else {
            let otBeforeWorkTime = dayjs(
              endDate.isBefore(findDate.start) ? endDate : findDate.start
            ).diff(
              startDate.isAfter(findPrevDate.end)
                ? startDate
                : findPrevDate.end,
              "minute"
            );
            if (otBeforeWorkTime > 0) {
              if (isWorkingPrevDay) {
                xOT += otBeforeWorkTime;
              } else {
                xOTHoliday += otBeforeWorkTime;
              }
            }

            let otBeforeBreakTime = dayjs(
              endDate.isBefore(breaktime.start) ? endDate : breaktime.start
            ).diff(
              startDate.isAfter(findDate.start) ? startDate : findDate.start,
              "minute"
            );
            if (otBeforeBreakTime > 0) {
              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += otBeforeBreakTime;
              } else {
                xWorkingMonthlyHoliday += otBeforeBreakTime;
              }
            }

            let otBetweenBreakTime = dayjs(
              endDate.isBefore(breaktime.end) ? endDate : breaktime.end
            ).diff(
              startDate.isAfter(breaktime.start) ? startDate : breaktime.start,
              "minute"
            );
            if (otBetweenBreakTime > 0) {
              if (formInputData.idRequestReason === 9) {
                if (isWorkingDay) {
                  xOT += otBetweenBreakTime;
                } else {
                  xOTHoliday += otBetweenBreakTime;
                }
              }
            }

            let otBeforeWorkEndTime = dayjs(
              endDate.isBefore(findDate.end) ? endDate : findDate.end
            ).diff(
              startDate.isAfter(breaktime.end) ? startDate : breaktime.end,
              "minute"
            );
            if (otBeforeWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += otBeforeWorkEndTime;
              } else {
                xWorkingMonthlyHoliday += otBeforeWorkEndTime;
              }
            }
          }

          if (
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30:00`
            ).isBefore(findNextDate.start)
          ) {
            let seventhirty = dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30:00`
            );

            let otBeforeSevenThirty = dayjs(
              endDate.isBefore(seventhirty) ? endDate : seventhirty
            ).diff(
              startDate.isAfter(findDate.end) ? startDate : findDate.end,
              "minute"
            );
            if (otBeforeSevenThirty > 0) {
              if (isWorkingDay) {
                xOT += otBeforeSevenThirty;
              } else {
                xOTHoliday += otBeforeSevenThirty;
              }
            }

            let otBeforeNextWorkTime = dayjs(
              endDate.isBefore(findNextDate.start)
                ? endDate
                : findNextDate.start
            ).diff(
              startDate.isAfter(seventhirty) ? startDate : seventhirty,
              "minute"
            );
            if (otBeforeNextWorkTime > 0) {
              if (isWorkingNextDay) {
                xOT += otBeforeNextWorkTime;
              } else {
                xOTHoliday += otBeforeNextWorkTime;
              }
            }
          } else {
            let otBeforeNextWorkTime = dayjs(
              endDate.isBefore(findNextDate.start)
                ? endDate
                : findNextDate.start
            ).diff(
              startDate.isAfter(findDate.end) ? startDate : findDate.end,
              "minute"
            );
            if (otBeforeNextWorkTime > 0) {
              if (isWorkingDay) {
                xOT += otBeforeNextWorkTime;
              } else {
                xOTHoliday += otBeforeNextWorkTime;
              }
            }
          }

          if (
            breaktimeNextDate.start.isBetween(
              dayjs(findNextDate.start),
              dayjs(findNextDate.end),
              "minute",
              "[]"
            ) &&
            breaktimeNextDate.end.isBetween(
              dayjs(findNextDate.start),
              dayjs(findNextDate.end),
              "minute",
              "[]"
            )
          ) {
            let otBeforeBreakTimeNextDate = dayjs(
              endDate.isBefore(breaktimeNextDate.start)
                ? endDate
                : breaktimeNextDate.start
            ).diff(
              startDate.isAfter(findNextDate.start)
                ? startDate
                : findNextDate.start,
              "minute"
            );
            if (otBeforeBreakTimeNextDate > 0) {
              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += otBeforeBreakTimeNextDate;
              } else {
                xWorkingMonthlyHoliday += otBeforeBreakTimeNextDate;
              }
            }

            let otBeforeNextWorkEndTime = dayjs(
              endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
            ).diff(
              startDate.isAfter(breaktimeNextDate.end)
                ? startDate
                : breaktimeNextDate.end,
              "minute"
            );
            if (otBeforeNextWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += otBeforeNextWorkEndTime;
              } else {
                xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
              }
            }
          } else {
            let otBeforeNextWorkEndTime = dayjs(
              endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
            ).diff(
              startDate.isAfter(findNextDate.start)
                ? startDate
                : findNextDate.start,
              "minute"
            );
            if (otBeforeNextWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += otBeforeNextWorkEndTime;
              } else {
                xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
              }
            }
          }

          let otAfterNextWorkEndTime = dayjs(endDate).diff(
            startDate.isAfter(findNextDate.end) ? startDate : findNextDate.end,
            "minute"
          );
          if (otAfterNextWorkEndTime > 0) {
            xOTHoliday += otAfterNextWorkEndTime;
          }
        } else if (values.pattern.idWorkingType === 1) {
          if (startDate.isBefore(findDate.start)) {
            let findDatePrevPattern = {
              start: dayjs(findPrevDate.start).add(1, "day"),
              end: dayjs(findPrevDate.end).add(1, "day"),
            };

            if (
              dayjs(findDatePrevPattern.start).isBefore(
                findDate.start,
                "minute"
              )
            ) {
              let otBeforeFindDatePrevPattern = dayjs(
                endDate.isBefore(findDatePrevPattern.start)
                  ? endDate
                  : findDatePrevPattern.start
              ).diff(
                startDate.isAfter(findPrevDate.end)
                  ? startDate
                  : findPrevDate.end,
                "minute"
              );
              if (otBeforeFindDatePrevPattern > 0) {
                xOTHoliday += otBeforeFindDatePrevPattern;
              }

              let otBeforeFindDatePrevPatternEnd = dayjs(
                endDate.isBefore(findDatePrevPattern.end)
                  ? endDate
                  : findDatePrevPattern.end
              ).diff(
                startDate.isAfter(findDatePrevPattern.start)
                  ? startDate
                  : findDatePrevPattern.start,
                "minute"
              );
              if (otBeforeFindDatePrevPatternEnd > 0) {
                xWorkingMonthlyHoliday += otBeforeFindDatePrevPatternEnd;
              }

              let findDateStartNewDate = dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (findDateStartNewDate.isBefore(findDate.start)) {
                let otBeforeStartNewDate = dayjs(
                  endDate.isBefore(findDateStartNewDate)
                    ? endDate
                    : findDateStartNewDate
                ).diff(
                  startDate.isAfter(findDatePrevPattern.end)
                    ? startDate
                    : findDatePrevPattern.end,
                  "minute"
                );
                if (otBeforeStartNewDate > 0) {
                  if (isWorkingPrevDay) {
                    xOT += otBeforeStartNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNewDate;
                  }
                }

                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findDateStartNewDate)
                    ? startDate
                    : findDateStartNewDate,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  if (!isWorkingDay) {
                    xOTHoliday += otBeforeWorkTime;
                  } else {
                    xOT += otBeforeWorkTime;
                  }
                }
              } else {
                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findDatePrevPattern.end)
                    ? startDate
                    : findDatePrevPattern.end,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  xOTHoliday += otBeforeWorkTime;
                }
              }
            } else {
              let otBeforeFindDateEndPrevDate = dayjs(
                endDate.isBefore(findPrevDate.end) ? endDate : findPrevDate.end
              ).diff(
                startDate.isAfter(findPrevDate.start)
                  ? startDate
                  : findPrevDate.start,
                "minute"
              );
              if (otBeforeFindDateEndPrevDate > 0) {
                xWorkingMonthlyHoliday += otBeforeFindDateEndPrevDate;
              }

              let findDateStartNewDate = dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (findDateStartNewDate.isBefore(findDate.start)) {
                let otBeforeStartNewDate = dayjs(
                  endDate.isBefore(findDateStartNewDate)
                    ? endDate
                    : findDateStartNewDate
                ).diff(
                  startDate.isAfter(findPrevDate.end)
                    ? startDate
                    : findPrevDate.end,
                  "minute"
                );
                if (otBeforeStartNewDate > 0) {
                  if (isWorkingPrevDay) {
                    xOT += otBeforeStartNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNewDate;
                  }
                }

                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findDateStartNewDate)
                    ? startDate
                    : findDateStartNewDate,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  if (!isWorkingDay) {
                    xOTHoliday += otBeforeWorkTime;
                  } else {
                    xOT += otBeforeWorkTime;
                  }
                }
              } else {
                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findPrevDate.end)
                    ? startDate
                    : findPrevDate.end,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  if (
                    isWorkingPrevDay &&
                    (isWorkingDay ||
                      dayjs(findPrevDate.end).isBefore(
                        dayjs(
                          `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
                          "YYYY-MM-DD HH:mm"
                        )
                      ))
                  ) {
                    xOT += otBeforeWorkTime;
                  } else {
                    xOTHoliday += otBeforeWorkTime;
                  }
                }
              }
            }
          }

          let otBeforeWorkEndTime = dayjs(
            endDate.isBefore(findDate.end) ? endDate : findDate.end
          ).diff(
            startDate.isAfter(findDate.start) ? startDate : findDate.start,
            "minute"
          );
          if (otBeforeWorkEndTime > 0) {
            xWorkingMonthlyHoliday += otBeforeWorkEndTime;
          }

          if (endDate.isAfter(findDate.end)) {
            let findDatePatternAddDay = {
              start: dayjs(findDate.start).add(1, "day"),
              end: dayjs(findDate.end).add(1, "day"),
            };
            if (
              dayjs(findDatePatternAddDay.start).isBefore(
                findNextDate.start,
                "minute"
              )
            ) {
              let findNextDateStartNewDate = dayjs(
                `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (
                findNextDateStartNewDate.isBefore(findDatePatternAddDay.start)
              ) {
                let otBeforeStartNextNewDate = dayjs(
                  endDate.isBefore(findNextDateStartNewDate)
                    ? endDate
                    : findNextDateStartNewDate
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeStartNextNewDate > 0) {
                  if (isWorkingDay) {
                    xOT += otBeforeStartNextNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNextNewDate;
                  }
                }

                let otBeforeFindDatePatternAddDayStart = dayjs(
                  endDate.isBefore(findDatePatternAddDay.start)
                    ? endDate
                    : findDatePatternAddDay.start
                ).diff(
                  startDate.isAfter(findNextDateStartNewDate)
                    ? startDate
                    : findNextDateStartNewDate,
                  "minute"
                );
                if (otBeforeFindDatePatternAddDayStart > 0) {
                  if (!isWorkingNextDay) {
                    xOTHoliday += otBeforeFindDatePatternAddDayStart;
                  } else {
                    xOT += otBeforeFindDatePatternAddDayStart;
                  }
                }
              } else {
                let otBeforeFindDatePatternAddDayStart = dayjs(
                  endDate.isBefore(findDatePatternAddDay.start)
                    ? endDate
                    : findDatePatternAddDay.start
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeFindDatePatternAddDayStart > 0) {
                  xOTHoliday += otBeforeFindDatePatternAddDayStart;
                }
              }

              let otBeforeFindDatePatternAddDayEnd = dayjs(
                endDate.isBefore(findDatePatternAddDay.end)
                  ? endDate
                  : findDatePatternAddDay.end
              ).diff(
                startDate.isAfter(findDatePatternAddDay.start)
                  ? startDate
                  : findDatePatternAddDay.start,
                "minute"
              );
              if (otBeforeFindDatePatternAddDayEnd > 0) {
                xWorkingMonthlyHoliday += otBeforeFindDatePatternAddDayEnd;
              }

              let otBeforeNextWorkTime = dayjs(
                endDate.isBefore(findNextDate.start)
                  ? endDate
                  : findNextDate.start
              ).diff(
                startDate.isAfter(findDatePatternAddDay.end)
                  ? startDate
                  : findDatePatternAddDay.end,
                "minute"
              );
              if (otBeforeNextWorkTime > 0) {
                xOTHoliday += otBeforeNextWorkTime;
              }
            } else {
              let findNextDateStartNewDate = dayjs(
                `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (findNextDateStartNewDate.isBefore(findNextDate.start)) {
                let otBeforeStartNextNewDate = dayjs(
                  endDate.isBefore(findNextDateStartNewDate)
                    ? endDate
                    : findNextDateStartNewDate
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeStartNextNewDate > 0) {
                  if (isWorkingDay) {
                    xOT += otBeforeStartNextNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNextNewDate;
                  }
                }

                let otBeforeNextWorkTime = dayjs(
                  endDate.isBefore(findNextDate.start)
                    ? endDate
                    : findNextDate.start
                ).diff(
                  startDate.isAfter(findNextDateStartNewDate)
                    ? startDate
                    : findNextDateStartNewDate,
                  "minute"
                );
                if (otBeforeNextWorkTime > 0) {
                  if (!isWorkingNextDay) {
                    xOTHoliday += otBeforeNextWorkTime;
                  } else {
                    xOT += otBeforeNextWorkTime;
                  }
                }
              } else {
                let otBeforeNextWorkTime = dayjs(
                  endDate.isBefore(findNextDate.start)
                    ? endDate
                    : findNextDate.start
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeNextWorkTime > 0) {
                  if (
                    isWorkingDay &&
                    (isWorkingNextDay ||
                      dayjs(findDate.end).isBefore(
                        dayjs(
                          `${dayjs(valuesNext.date).format(
                            "YYYY-MM-DD"
                          )} 00:00`,
                          "YYYY-MM-DD HH:mm"
                        )
                      ))
                  ) {
                    xOT += otBeforeNextWorkTime;
                  } else {
                    xOTHoliday += otBeforeNextWorkTime;
                  }
                }
              }
            }
          }

          let otBeforeNextWorkEndTime = dayjs(
            endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
          ).diff(
            startDate.isAfter(findNextDate.start)
              ? startDate
              : findNextDate.start,
            "minute"
          );
          if (otBeforeNextWorkEndTime > 0) {
            xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
          }

          let otAfterNextWorkEndTime = dayjs(endDate).diff(
            startDate.isAfter(findNextDate.end) ? startDate : findNextDate.end,
            "minute"
          );
          if (otAfterNextWorkEndTime > 0) {
            if (isWorkingNextDay) {
              xOT += otAfterNextWorkEndTime;
            } else {
              xOTHoliday += otAfterNextWorkEndTime;
            }
          }
        }
      }
    }

    return {
      error: error,
      listOT: {
        xOT: xOT,
        xOTHoliday: xOTHoliday,
        xWorkingDailyHoliday: xWorkingDailyHoliday,
        xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
      },
    };
};

const timeOverlap = (starttime, endtime, startCheck, endCheck) => {
    let isStartCheckOverlap = dayjs(startCheck).isBetween(starttime, endtime, "[)");
    let isEndCheckOverlap = dayjs(endCheck).isBetween(starttime, endtime, "(]")
    if(isStartCheckOverlap && isEndCheckOverlap){
        return dayjs(endCheck).diff(startCheck, "minute");
    } else if (isStartCheckOverlap){
        return dayjs(endtime).diff(startCheck, "minute");
    } else if (isEndCheckOverlap) {
        return dayjs(endCheck).diff(starttime, "minute");
    } else {
        return 0;
    }
};

const calculatorOTMidnight = (values, formInputData, employeeProfile, attendanceListState) => {
  let error = {
    errorNoon: false,
    errorOnWorkingTime: false,
    errorTime: false,
  };
  let xOT = 0;
  let xOTHoliday = 0;
  let xWorkingDailyHoliday = 0;
  let xWorkingMonthlyHoliday = 0;

  const startDate = dayjs(formInputData.startDate)
    .set("hour", formInputData.startHours)
    .set("minute", formInputData.startMinute)
    .set("second", 0);
  const endDate = dayjs(formInputData.endDate)
    .set("hour", formInputData.endHours)
    .set("minute", formInputData.endMinute)
    .set("second", 0);

  const findDate = {
    start: `${dayjs(values.date).format("YYYY-MM-DD")} ${
      values.pattern.timeIn
    }`,
    end: `${dayjs(
      `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.timeIn}`,
      "YYYY-MM-DD HH:mm"
    )
      .add(values.pattern.workingHours, "minute")
      .format("YYYY-MM-DD HH:mm")}`,
  };

  const findIndexToday = attendanceListState.findIndex(
    (x) => x.date === values.date
  );

  let leaveHoliday = values.leave && values.leave.length > 0 && [...values.leave.map((v) => v.idLeaveType)].includes(9);
  const isWorkingDay =
    values.pattern.isWorkingDay === 1 &&
    !leaveHoliday &&
    !values.holiday &&
    !values.compensateDate;
  
  let valuesPrev = attendanceListState[findIndexToday - 1];
  let findPrevDate = {
    start: `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
      valuesPrev.pattern.timeIn
    }`,
    end: `${dayjs(
      `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
        valuesPrev.pattern.timeIn
      }`,
      "YYYY-MM-DD HH:mm"
    )
      .add(valuesPrev.pattern.workingHours, "minute")
      .format("YYYY-MM-DD HH:mm")}`,
  };
  let prevLeaveHoliday = valuesPrev.leave && valuesPrev.leave.length > 0 && [...valuesPrev.leave.map((v) => v.idLeaveType)].includes(9);
  let isWorkingPrevDay =
    valuesPrev.pattern.isWorkingDay === 1 &&
    !prevLeaveHoliday &&
    !valuesPrev.holiday &&
    !valuesPrev.compensateDate;

  let valuesNext = attendanceListState[findIndexToday + 1];
  let findNextDate = {
    start: `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
      valuesNext.pattern.timeIn
    }`,
    end: `${dayjs(
      `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
        valuesNext.pattern.timeIn
      }`,
      "YYYY-MM-DD HH:mm"
    )
      .add(valuesNext.pattern.workingHours, "minute")
      .format("YYYY-MM-DD HH:mm")}`,
  };
  let nextLeaveHoliday = valuesNext.leave && valuesNext.leave.length > 0 && [...valuesNext.leave.map((v) => v.idLeaveType)].includes(9);
  let isWorkingNextDay =
    valuesNext.pattern.isWorkingDay === 1 &&
    !nextLeaveHoliday && 
    !valuesNext.holiday &&
    !valuesNext.compensateDate;
  
  if (formInputData && formInputData.idRequestType === 2) {
    if (
      startDate.isBefore(
        dayjs(
          `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
          "YYYY-MM-DD HH:mm"
        )
      ) ||
      endDate.isSameOrAfter(
        dayjs(
          `${dayjs(values.date).add(2, "day").format("YYYY-MM-DD")} 00:00`,
          "YYYY-MM-DD HH:mm"
        )
      )
    ) {
      error.errorTime = true;
    }

    if (isWorkingPrevDay && startDate.isBefore(findPrevDate.end)) {
      error.errorOnWorkingTime = true;
    }

    if (isWorkingNextDay && endDate.isAfter(findNextDate.start)) {
      error.errorOnWorkingTime = true;
    }

    if (!(endDate.diff(startDate, "minute") > 0)) {
      error.errorTime = true;
    }

    if (values.pattern.idWorkingType === 2) {
      if (
        !(
          startDate.isBetween(
            dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} 12:00`,
              "YYYY-MM-DD HH:mm"
            ),
            dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} 13:00`,
              "YYYY-MM-DD HH:mm"
            ),
            "minute",
            "[)"
          ) &&
          endDate.isBetween(
            dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} 12:00`,
              "YYYY-MM-DD HH:mm"
            ),
            dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} 13:00`,
              "YYYY-MM-DD HH:mm"
            ),
            "minute",
            "(]"
          )
        ) &&
        formInputData.idRequestReason === 9
      ) {
        error.errorNoon = true;
      } else if (
        (dayjs(startDate).isBetween(
          dayjs(findDate.start),
          dayjs(findDate.end),
          "minute",
          "[)"
        ) ||
          dayjs(endDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "(]"
          ) ||
          dayjs(findDate.start).isBetween(
            dayjs(startDate),
            dayjs(endDate),
            "minute",
            "[)"
          ) ||
          dayjs(findDate.end).isBetween(
            dayjs(startDate),
            dayjs(endDate),
            "minute",
            "(]"
          )) &&
        isWorkingDay &&
        formInputData.idRequestReason !== 9
      ) {
        error.errorOnWorkingTime = true;
      }

      if (
        startDate.isBetween(
          dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00`,
            "YYYY-MM-DD HH:mm"
          ),
          dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00`,
            "YYYY-MM-DD HH:mm"
          ),
          "minute",
          "[)"
        ) &&
        endDate.isBetween(
          dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00`,
            "YYYY-MM-DD HH:mm"
          ),
          dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00`,
            "YYYY-MM-DD HH:mm"
          ),
          "minute",
          "(]"
        ) &&
        formInputData.idRequestReason !== 9
      ) {
        error.errorNoon = true;
      }
    } else {
      if (
        (dayjs(startDate).isBetween(
          dayjs(findDate.start),
          dayjs(findDate.end),
          "minute",
          "[)"
        ) ||
          dayjs(endDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "(]"
          ) ||
          dayjs(findDate.start).isBetween(
            dayjs(startDate),
            dayjs(endDate),
            "minute",
            "[)"
          ) ||
          dayjs(findDate.end).isBetween(
            dayjs(startDate),
            dayjs(endDate),
            "minute",
            "(]"
          )) &&
        isWorkingDay
      ) {
        error.errorOnWorkingTime = true;
      }
    }

    if (!(error.errorNoon || error.errorOnWorkingTime || error.errorTime)) {
      if (values.pattern.idWorkingType === 2) {
        let breaktime = {
          start: dayjs(`${dayjs(values.date).format("YYYY-MM-DD")} 12:00:00`),
          end: dayjs(`${dayjs(values.date).format("YYYY-MM-DD")} 13:00:00`),
        };
        let breaktimeNextDate = {
          start: dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00:00`
          ),
          end: dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00:00`
          ),
        };
        
        let startNowDay = dayjs(`${values.date} 00:00:00`);
        
        if(startNowDay.isBefore(findPrevDate.end)){
          let otBeforePrevWorkEndTime = dayjs(
            endDate.isBefore(findPrevDate.end) ? endDate : findPrevDate.end
          ).diff(
            startDate.isAfter(findPrevDate.start)
              ? startDate
              : findPrevDate.start,
            "minute"
          );
          if (otBeforePrevWorkEndTime > 0) {
            if (employeeProfile.idPaymentType === 1) {
              xWorkingDailyHoliday += otBeforePrevWorkEndTime;
            } else {
              xWorkingMonthlyHoliday += otBeforePrevWorkEndTime;
            }
          }
          startNowDay = dayjs(findPrevDate.end);
        }

        if(dayjs(findDate.start).isBefore(breaktime.start)){
          let otBeforeFindDateStart = dayjs(
            endDate.isBefore(findDate.start) ? endDate : findDate.start
          ).diff(
            startDate.isAfter(startNowDay)
              ? startDate
              : startNowDay,
            "minute"
          );
          if (otBeforeFindDateStart > 0) {
            if (isWorkingDay) {
              xOT += otBeforeFindDateStart;
            } else {
              xOTHoliday += otBeforeFindDateStart;
            }
          }
          
          let otBeforeBreakTimeStart = dayjs(
            endDate.isBefore(breaktime.start) ? endDate : breaktime.start
          ).diff(
            startDate.isAfter(findDate.start)
              ? startDate
              : findDate.start,
            "minute"
          );
          if (otBeforeBreakTimeStart > 0) {
            if (employeeProfile.idPaymentType === 1) {
              xWorkingDailyHoliday += otBeforeBreakTimeStart;
            } else {
              xWorkingMonthlyHoliday += otBeforeBreakTimeStart;
            }
          }

          let otBeforeBreakTimeEnd = dayjs(
            endDate.isBefore(breaktime.end) ? endDate : breaktime.end
          ).diff(
            startDate.isAfter(breaktime.start)
              ? startDate
              : breaktime.start,
            "minute"
          );
          if(formInputData.idRequestReason === 9){
            if(otBeforeBreakTimeEnd > 0){
              if (isWorkingDay) {
                xOT += otBeforeBreakTimeEnd;
              } else {
                xOTHoliday += otBeforeBreakTimeEnd;
              }
            }
          }

          let otBeforeFindDateEnd = dayjs(
            endDate.isBefore(findDate.end) ? endDate : findDate.end
          ).diff(
            startDate.isAfter(breaktime.end)
              ? startDate
              : breaktime.end,
            "minute"
          );
          if (otBeforeFindDateEnd > 0) {
            if (employeeProfile.idPaymentType === 1) {
              xWorkingDailyHoliday += otBeforeFindDateEnd;
            } else {
              xWorkingMonthlyHoliday += otBeforeFindDateEnd;
            }
          }

          let otBeforeMidnightNextdate = dayjs(
            endDate.isBefore(dayjs(`${valuesNext.date} 00:00:00`)) ? endDate : dayjs(`${valuesNext.date} 00:00:00`)
          ).diff(
            startDate.isAfter(findDate.end)
              ? startDate
              : findDate.end,
            "minute"
          );
          if(otBeforeMidnightNextdate > 0){
            if (isWorkingDay) {
              xOT += otBeforeMidnightNextdate;
            } else {
              xOTHoliday += otBeforeMidnightNextdate;
            }
          }
        }else{
          let otBeforeFindDateStart = dayjs(
            endDate.isBefore(findDate.start) ? endDate : findDate.start
          ).diff(
            startDate.isAfter(startNowDay)
              ? startDate
              : startNowDay,
            "minute"
          );
          
          if(
            breaktime.start.isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[]"
            ) &&
            breaktime.end.isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[]"
            )
          ){
            otBeforeFindDateStart -= 60;
          } else if (
            breaktime.start.isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[]"
            )
          ){
            otBeforeFindDateStart -= dayjs(endDate).diff(breaktime.start, "minute");
          } else if (
            breaktime.end.isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[]"
            )
          ) {
            otBeforeFindDateStart -= dayjs(breaktime.end).diff(startDate, "minute");
          }

          if (otBeforeFindDateStart > 0) {
            if (isWorkingDay) {
              xOT += otBeforeFindDateStart;
            } else {
              xOTHoliday += otBeforeFindDateStart;
            }
          }

          let otBeforeMidnightNextdate = dayjs(
            endDate.isBefore(dayjs(`${valuesNext.date} 00:00:00`)) ? endDate : dayjs(`${valuesNext.date} 00:00:00`)
          ).diff(
            startDate.isAfter(findDate.start)
              ? startDate
              : findDate.start,
            "minute"
          );
          if(otBeforeMidnightNextdate > 0){
            if (employeeProfile.idPaymentType === 1) {
              xWorkingDailyHoliday += otBeforeMidnightNextdate;
            } else {
              xWorkingMonthlyHoliday += otBeforeMidnightNextdate;
            }
          }
        }

        let startNextDay = dayjs(`${valuesNext.date} 00:00:00`);

        if(startNextDay.isBefore(findDate.end)){
          let otBeforeWorkEndTime = dayjs(
            endDate.isBefore(findDate.end) ? endDate : findDate.end
          ).diff(
            startDate.isAfter(startNextDay)
              ? startDate
              : startNextDay,
            "minute"
          );
          if (otBeforeWorkEndTime > 0) {
            if (employeeProfile.idPaymentType === 1) {
              xWorkingDailyHoliday += otBeforeWorkEndTime;
            } else {
              xWorkingMonthlyHoliday += otBeforeWorkEndTime;
            }
          }
          startNextDay = dayjs(findDate.end);
        }
        
        if(dayjs(findNextDate.start).isBefore(breaktimeNextDate.start)){
          let otBeforeFindNextDateStart = dayjs(
            endDate.isBefore(findNextDate.start) ? endDate : findNextDate.start
          ).diff(
            startDate.isAfter(startNextDay)
              ? startDate
              : startNextDay,
            "minute"
          );
          if (otBeforeFindNextDateStart > 0) {
            if (isWorkingNextDay) {
              xOT += otBeforeFindNextDateStart;
            } else {
              xOTHoliday += otBeforeFindNextDateStart;
            }
          }
          
          let otBeforeBreakTimeNextDateStart = dayjs(
            endDate.isBefore(breaktimeNextDate.start) ? endDate : breaktimeNextDate.start
          ).diff(
            startDate.isAfter(findNextDate.start)
              ? startDate
              : findNextDate.start,
            "minute"
          );
          if (otBeforeBreakTimeNextDateStart > 0) {
            if (employeeProfile.idPaymentType === 1) {
              xWorkingDailyHoliday += otBeforeBreakTimeNextDateStart;
            } else {
              xWorkingMonthlyHoliday += otBeforeBreakTimeNextDateStart;
            }
          }

          let otBeforeFindNextDateEnd = dayjs(
            endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
          ).diff(
            startDate.isAfter(breaktimeNextDate.end)
              ? startDate
              : breaktimeNextDate.end,
            "minute"
          );
          if (otBeforeFindNextDateEnd > 0) {
            if (employeeProfile.idPaymentType === 1) {
              xWorkingDailyHoliday += otBeforeFindNextDateEnd;
            } else {
              xWorkingMonthlyHoliday += otBeforeFindNextDateEnd;
            }
          }

          let otBeforeMidnightNextNextdate = dayjs(
            endDate.isBefore(dayjs(`${valuesNext.date} 00:00:00`).add(1,'day')) ? endDate : dayjs(`${valuesNext.date} 00:00:00`).add(1,'day')
          ).diff(
            startDate.isAfter(findNextDate.end)
              ? startDate
              : findNextDate.end,
            "minute"
          );
          if(otBeforeMidnightNextNextdate > 0){
            if (isWorkingNextDay) {
              xOT += otBeforeMidnightNextNextdate;
            } else {
              xOTHoliday += otBeforeMidnightNextNextdate;
            }
          }
        }else{
          let otBeforeFindNextDateStart = dayjs(
            endDate.isBefore(findNextDate.start) ? endDate : findNextDate.start
          ).diff(
            startDate.isAfter(startNextDay)
              ? startDate
              : startNextDay,
            "minute"
          );
          
          if(
            breaktimeNextDate.start.isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[]"
            ) &&
            breaktimeNextDate.end.isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[]"
            )
          ){
            otBeforeFindNextDateStart -= 60;
          } else if (
            breaktimeNextDate.start.isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[]"
            )
          ){
            otBeforeFindNextDateStart -= dayjs(endDate).diff(breaktimeNextDate.start, "minute");
          } else if (
            breaktimeNextDate.end.isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[]"
            )
          ) {
            otBeforeFindNextDateStart -= dayjs(breaktimeNextDate.end).diff(startDate, "minute");
          }

          if (otBeforeFindNextDateStart > 0) {
            if (isWorkingDay) {
              xOT += otBeforeFindNextDateStart;
            } else {
              xOTHoliday += otBeforeFindNextDateStart;
            }
          }

          let otBeforeMidnightNextNextdate = dayjs(
            endDate.isBefore(dayjs(`${valuesNext.date} 00:00:00`).add(1,'day')) ? endDate : dayjs(`${valuesNext.date} 00:00:00`).add(1,'day')
          ).diff(
            startDate.isAfter(findNextDate.start)
              ? startDate
              : findNextDate.start,
            "minute"
          );
          if(otBeforeMidnightNextNextdate > 0){
            if (employeeProfile.idPaymentType === 1) {
              xWorkingDailyHoliday += otBeforeMidnightNextNextdate;
            } else {
              xWorkingMonthlyHoliday += otBeforeMidnightNextNextdate;
            }
          }
        }

        let otBeforeDuringBreakTimeNextDate = dayjs(
          endDate.isBefore(breaktimeNextDate.end) ? endDate : breaktimeNextDate.end
        ).diff(
          startDate.isAfter(breaktimeNextDate.start)
            ? startDate
            : breaktimeNextDate.start,
          "minute"
        );
        if(formInputData.idRequestReason === 9){
          if(otBeforeDuringBreakTimeNextDate > 0){
            if (isWorkingNextDay) {
              xOT += otBeforeDuringBreakTimeNextDate;
            } else {
              xOTHoliday += otBeforeDuringBreakTimeNextDate;
            }
          }
        }
      } else if (values.pattern.idWorkingType === 1) {
        if (startDate.isBefore(findDate.start)) {
          let findDatePrevPattern = {
            start: dayjs(findPrevDate.start).add(1, "day"),
            end: dayjs(findPrevDate.end).add(1, "day"),
          };

          if (
            dayjs(findDatePrevPattern.start).isBefore(
              findDate.start,
              "minute"
            )
          ) {
            let otBeforeFindDatePrevPattern = dayjs(
              endDate.isBefore(findDatePrevPattern.start)
                ? endDate
                : findDatePrevPattern.start
            ).diff(
              startDate.isAfter(findPrevDate.end)
                ? startDate
                : findPrevDate.end,
              "minute"
            );
            if (otBeforeFindDatePrevPattern > 0) {
              xOTHoliday += otBeforeFindDatePrevPattern;
            }

            let otBeforeFindDatePrevPatternEnd = dayjs(
              endDate.isBefore(findDatePrevPattern.end)
                ? endDate
                : findDatePrevPattern.end
            ).diff(
              startDate.isAfter(findDatePrevPattern.start)
                ? startDate
                : findDatePrevPattern.start,
              "minute"
            );
            if (otBeforeFindDatePrevPatternEnd > 0) {
              xWorkingMonthlyHoliday += otBeforeFindDatePrevPatternEnd;
            }

            let findDateStartNewDate = dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} 07:30`,
              "YYYY-MM-DD HH:mm"
            );
            if (findDateStartNewDate.isBefore(findDate.start)) {
              let otBeforeStartNewDate = dayjs(
                endDate.isBefore(findDateStartNewDate)
                  ? endDate
                  : findDateStartNewDate
              ).diff(
                startDate.isAfter(findDatePrevPattern.end)
                  ? startDate
                  : findDatePrevPattern.end,
                "minute"
              );
              if (otBeforeStartNewDate > 0) {
                if (isWorkingPrevDay) {
                  xOT += otBeforeStartNewDate;
                } else {
                  xOTHoliday += otBeforeStartNewDate;
                }
              }

              let otBeforeWorkTime = dayjs(
                endDate.isBefore(findDate.start) ? endDate : findDate.start
              ).diff(
                startDate.isAfter(findDateStartNewDate)
                  ? startDate
                  : findDateStartNewDate,
                "minute"
              );
              if (otBeforeWorkTime > 0) {
                if (!isWorkingDay) {
                  xOTHoliday += otBeforeWorkTime;
                } else {
                  xOT += otBeforeWorkTime;
                }
              }
            } else {
              let otBeforeWorkTime = dayjs(
                endDate.isBefore(findDate.start) ? endDate : findDate.start
              ).diff(
                startDate.isAfter(findDatePrevPattern.end)
                  ? startDate
                  : findDatePrevPattern.end,
                "minute"
              );
              if (otBeforeWorkTime > 0) {
                xOTHoliday += otBeforeWorkTime;
              }
            }
          } else {
            let otBeforeFindDateEndPrevDate = dayjs(
              endDate.isBefore(findPrevDate.end) ? endDate : findPrevDate.end
            ).diff(
              startDate.isAfter(findPrevDate.start)
                ? startDate
                : findPrevDate.start,
              "minute"
            );
            if (otBeforeFindDateEndPrevDate > 0) {
              xWorkingMonthlyHoliday += otBeforeFindDateEndPrevDate;
            }

            let findDateStartNewDate = dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} 07:30`,
              "YYYY-MM-DD HH:mm"
            );
            if (findDateStartNewDate.isBefore(findDate.start)) {
              let otBeforeStartNewDate = dayjs(
                endDate.isBefore(findDateStartNewDate)
                  ? endDate
                  : findDateStartNewDate
              ).diff(
                startDate.isAfter(findPrevDate.end)
                  ? startDate
                  : findPrevDate.end,
                "minute"
              );
              if (otBeforeStartNewDate > 0) {
                if (isWorkingPrevDay) {
                  xOT += otBeforeStartNewDate;
                } else {
                  xOTHoliday += otBeforeStartNewDate;
                }
              }

              let otBeforeWorkTime = dayjs(
                endDate.isBefore(findDate.start) ? endDate : findDate.start
              ).diff(
                startDate.isAfter(findDateStartNewDate)
                  ? startDate
                  : findDateStartNewDate,
                "minute"
              );
              if (otBeforeWorkTime > 0) {
                if (!isWorkingDay) {
                  xOTHoliday += otBeforeWorkTime;
                } else {
                  xOT += otBeforeWorkTime;
                }
              }
            } else {
              let otBeforeWorkTime = dayjs(
                endDate.isBefore(findDate.start) ? endDate : findDate.start
              ).diff(
                startDate.isAfter(findPrevDate.end)
                  ? startDate
                  : findPrevDate.end,
                "minute"
              );
              if (otBeforeWorkTime > 0) {
                if (
                  isWorkingPrevDay &&
                  (isWorkingDay ||
                    dayjs(findPrevDate.end).isBefore(
                      dayjs(
                        `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
                        "YYYY-MM-DD HH:mm"
                      )
                    ))
                ) {
                  xOT += otBeforeWorkTime;
                } else {
                  xOTHoliday += otBeforeWorkTime;
                }
              }
            }
          }
        }

        let otBeforeWorkEndTime = dayjs(
          endDate.isBefore(findDate.end) ? endDate : findDate.end
        ).diff(
          startDate.isAfter(findDate.start) ? startDate : findDate.start,
          "minute"
        );
        if (otBeforeWorkEndTime > 0) {
          xWorkingMonthlyHoliday += otBeforeWorkEndTime;
        }

        if (endDate.isAfter(findDate.end)) {
          let findDatePatternAddDay = {
            start: dayjs(findDate.start).add(1, "day"),
            end: dayjs(findDate.end).add(1, "day"),
          };
          if (
            dayjs(findDatePatternAddDay.start).isBefore(
              findNextDate.start,
              "minute"
            )
          ) {
            let findNextDateStartNewDate = dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30`,
              "YYYY-MM-DD HH:mm"
            );
            if (
              findNextDateStartNewDate.isBefore(findDatePatternAddDay.start)
            ) {
              let otBeforeStartNextNewDate = dayjs(
                endDate.isBefore(findNextDateStartNewDate)
                  ? endDate
                  : findNextDateStartNewDate
              ).diff(
                startDate.isAfter(findDate.end) ? startDate : findDate.end,
                "minute"
              );
              if (otBeforeStartNextNewDate > 0) {
                if (isWorkingDay) {
                  xOT += otBeforeStartNextNewDate;
                } else {
                  xOTHoliday += otBeforeStartNextNewDate;
                }
              }

              let otBeforeFindDatePatternAddDayStart = dayjs(
                endDate.isBefore(findDatePatternAddDay.start)
                  ? endDate
                  : findDatePatternAddDay.start
              ).diff(
                startDate.isAfter(findNextDateStartNewDate)
                  ? startDate
                  : findNextDateStartNewDate,
                "minute"
              );
              if (otBeforeFindDatePatternAddDayStart > 0) {
                if (!isWorkingNextDay) {
                  xOTHoliday += otBeforeFindDatePatternAddDayStart;
                } else {
                  xOT += otBeforeFindDatePatternAddDayStart;
                }
              }
            } else {
              let otBeforeFindDatePatternAddDayStart = dayjs(
                endDate.isBefore(findDatePatternAddDay.start)
                  ? endDate
                  : findDatePatternAddDay.start
              ).diff(
                startDate.isAfter(findDate.end) ? startDate : findDate.end,
                "minute"
              );
              if (otBeforeFindDatePatternAddDayStart > 0) {
                xOTHoliday += otBeforeFindDatePatternAddDayStart;
              }
            }

            let otBeforeFindDatePatternAddDayEnd = dayjs(
              endDate.isBefore(findDatePatternAddDay.end)
                ? endDate
                : findDatePatternAddDay.end
            ).diff(
              startDate.isAfter(findDatePatternAddDay.start)
                ? startDate
                : findDatePatternAddDay.start,
              "minute"
            );
            if (otBeforeFindDatePatternAddDayEnd > 0) {
              xWorkingMonthlyHoliday += otBeforeFindDatePatternAddDayEnd;
            }

            let otBeforeNextWorkTime = dayjs(
              endDate.isBefore(findNextDate.start)
                ? endDate
                : findNextDate.start
            ).diff(
              startDate.isAfter(findDatePatternAddDay.end)
                ? startDate
                : findDatePatternAddDay.end,
              "minute"
            );
            if (otBeforeNextWorkTime > 0) {
              xOTHoliday += otBeforeNextWorkTime;
            }
          } else {
            let findNextDateStartNewDate = dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30`,
              "YYYY-MM-DD HH:mm"
            );
            if (findNextDateStartNewDate.isBefore(findNextDate.start)) {
              let otBeforeStartNextNewDate = dayjs(
                endDate.isBefore(findNextDateStartNewDate)
                  ? endDate
                  : findNextDateStartNewDate
              ).diff(
                startDate.isAfter(findDate.end) ? startDate : findDate.end,
                "minute"
              );
              if (otBeforeStartNextNewDate > 0) {
                if (isWorkingDay) {
                  xOT += otBeforeStartNextNewDate;
                } else {
                  xOTHoliday += otBeforeStartNextNewDate;
                }
              }

              let otBeforeNextWorkTime = dayjs(
                endDate.isBefore(findNextDate.start)
                  ? endDate
                  : findNextDate.start
              ).diff(
                startDate.isAfter(findNextDateStartNewDate)
                  ? startDate
                  : findNextDateStartNewDate,
                "minute"
              );
              if (otBeforeNextWorkTime > 0) {
                if (!isWorkingNextDay) {
                  xOTHoliday += otBeforeNextWorkTime;
                } else {
                  xOT += otBeforeNextWorkTime;
                }
              }
            } else {
              let otBeforeNextWorkTime = dayjs(
                endDate.isBefore(findNextDate.start)
                  ? endDate
                  : findNextDate.start
              ).diff(
                startDate.isAfter(findDate.end) ? startDate : findDate.end,
                "minute"
              );
              if (otBeforeNextWorkTime > 0) {
                if (
                  isWorkingDay &&
                  (isWorkingNextDay ||
                    dayjs(findDate.end).isBefore(
                      dayjs(
                        `${dayjs(valuesNext.date).format(
                          "YYYY-MM-DD"
                        )} 00:00`,
                        "YYYY-MM-DD HH:mm"
                      )
                    ))
                ) {
                  xOT += otBeforeNextWorkTime;
                } else {
                  xOTHoliday += otBeforeNextWorkTime;
                }
              }
            }
          }
        }

        let otBeforeNextWorkEndTime = dayjs(
          endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
        ).diff(
          startDate.isAfter(findNextDate.start)
            ? startDate
            : findNextDate.start,
          "minute"
        );
        if (otBeforeNextWorkEndTime > 0) {
          xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
        }

        let otAfterNextWorkEndTime = dayjs(endDate).diff(
          startDate.isAfter(findNextDate.end) ? startDate : findNextDate.end,
          "minute"
        );
        if (otAfterNextWorkEndTime > 0) {
          if (isWorkingNextDay) {
            xOT += otAfterNextWorkEndTime;
          } else {
            xOTHoliday += otAfterNextWorkEndTime;
          }
        }
      }
    }
  }

  return {
    error: error,
    listOT: {
      xOT: xOT,
      xOTHoliday: xOTHoliday,
      xWorkingDailyHoliday: xWorkingDailyHoliday,
      xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
    },
  };
};

const calculatorOTStandard = (values, formInputData, employeeProfile, attendanceListState) => {
    let error = {
      errorNoon: false,
      errorOnWorkingTime: false,
      errorTime: false,
    };
    let xOT = 0;
    let xOTHoliday = 0;
    let xWorkingDailyHoliday = 0;
    let xWorkingMonthlyHoliday = 0;

    const startDate = dayjs(formInputData.startDate)
      .set("hour", formInputData.startHours)
      .set("minute", formInputData.startMinute)
      .set("second", 0);
    const endDate = dayjs(formInputData.endDate)
      .set("hour", formInputData.endHours)
      .set("minute", formInputData.endMinute)
      .set("second", 0);

    const findDate = {
      start: `${dayjs(values.date).format("YYYY-MM-DD")} ${
        values.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.timeIn}`,
        "YYYY-MM-DD HH:mm"
      )
        .add(values.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };

    const findIndexToday = attendanceListState.findIndex(
      (x) => x.date === values.date
    );

    if(
      (findIndexToday === (attendanceListState.length - 1)) || 
      (findIndexToday === 0)
    ){
      return {
        error: error,
        listOT: {
          xOT: xOT,
          xOTHoliday: xOTHoliday,
          xWorkingDailyHoliday: xWorkingDailyHoliday,
          xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
        },
      }
    }

    let leaveHoliday = values.leave && values.leave.length > 0 && [...values.leave.map((v) => v.idLeaveType)].includes(9);
    const isWorkingDay =
      values.pattern.isWorkingDay === 1 &&
      !leaveHoliday &&
      !values.holiday &&
      !values.compensateDate;
    
    let valuesPrev = attendanceListState[findIndexToday - 1];
    let findPrevDate = {
      start: `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
        valuesPrev.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
          valuesPrev.pattern.timeIn
        }`,
        "YYYY-MM-DD HH:mm"
      )
        .add(valuesPrev.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };
    let prevLeaveHoliday = valuesPrev.leave && valuesPrev.leave.length > 0 && [...valuesPrev.leave.map((v) => v.idLeaveType)].includes(9);
    let isWorkingPrevDay =
      valuesPrev.pattern.isWorkingDay === 1 &&
      !prevLeaveHoliday &&
      !valuesPrev.holiday &&
      !valuesPrev.compensateDate;

    let valuesNext = attendanceListState[findIndexToday + 1];
    let findNextDate = {
      start: `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
        valuesNext.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
          valuesNext.pattern.timeIn
        }`,
        "YYYY-MM-DD HH:mm"
      )
        .add(valuesNext.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };
    let nextLeaveHoliday = valuesNext.leave && valuesNext.leave.length > 0 && [...valuesNext.leave.map((v) => v.idLeaveType)].includes(9);
    let isWorkingNextDay =
      valuesNext.pattern.isWorkingDay === 1 &&
      !nextLeaveHoliday && 
      !valuesNext.holiday &&
      !valuesNext.compensateDate;

    if (formInputData && formInputData.idRequestType === 2) {
      if (
        startDate.isBefore(
          dayjs(
            `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
            "YYYY-MM-DD HH:mm"
          )
        ) ||
        endDate.isSameOrAfter(
          dayjs(
            `${dayjs(values.date).add(2, "day").format("YYYY-MM-DD")} 00:00`,
            "YYYY-MM-DD HH:mm"
          )
        )
      ) {
        error.errorTime = true;
      }

      if (isWorkingPrevDay && startDate.isBefore(findPrevDate.end)) {
        error.errorOnWorkingTime = true;
      }

      if (isWorkingNextDay && endDate.isAfter(findNextDate.start)) {
        error.errorOnWorkingTime = true;
      }

      if (!(endDate.diff(startDate, "minute") > 0)) {
        error.errorTime = true;
      }

      if (values.pattern.idWorkingType === 2) {
        if (
          !(
            startDate.isBetween(
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 12:00`,
                "YYYY-MM-DD HH:mm"
              ),
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 13:00`,
                "YYYY-MM-DD HH:mm"
              ),
              "minute",
              "[)"
            ) &&
            endDate.isBetween(
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 12:00`,
                "YYYY-MM-DD HH:mm"
              ),
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 13:00`,
                "YYYY-MM-DD HH:mm"
              ),
              "minute",
              "(]"
            )
          ) &&
          formInputData.idRequestReason === 9
        ) {
          error.errorNoon = true;
        } else if (
          (dayjs(startDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "[)"
          ) ||
            dayjs(endDate).isBetween(
              dayjs(findDate.start),
              dayjs(findDate.end),
              "minute",
              "(]"
            ) ||
            dayjs(findDate.start).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[)"
            ) ||
            dayjs(findDate.end).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "(]"
            )) &&
          isWorkingDay &&
          formInputData.idRequestReason !== 9
        ) {
          error.errorOnWorkingTime = true;
        }

        if (
          startDate.isBetween(
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00`,
              "YYYY-MM-DD HH:mm"
            ),
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00`,
              "YYYY-MM-DD HH:mm"
            ),
            "minute",
            "[)"
          ) &&
          endDate.isBetween(
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00`,
              "YYYY-MM-DD HH:mm"
            ),
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00`,
              "YYYY-MM-DD HH:mm"
            ),
            "minute",
            "(]"
          ) &&
          formInputData.idRequestReason !== 9
        ) {
          error.errorNoon = true;
        }
      } else {
        if (
          (dayjs(startDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "[)"
          ) ||
            dayjs(endDate).isBetween(
              dayjs(findDate.start),
              dayjs(findDate.end),
              "minute",
              "(]"
            ) ||
            dayjs(findDate.start).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[)"
            ) ||
            dayjs(findDate.end).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "(]"
            )) &&
          isWorkingDay
        ) {
          error.errorOnWorkingTime = true;
        }
      }

      if (!(error.errorNoon || error.errorOnWorkingTime || error.errorTime)) {
        if (values.pattern.idWorkingType === 2) {
          let breaktime = {
            start: dayjs(`${dayjs(values.date).format("YYYY-MM-DD")} 12:00:00`),
            end: dayjs(`${dayjs(values.date).format("YYYY-MM-DD")} 13:00:00`),
          };
          let breaktimeNextDate = {
            start: dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00:00`
            ),
            end: dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00:00`
            ),
          };

          let otBeforePrevWorkEndTime = dayjs(
            endDate.isBefore(findPrevDate.end) ? endDate : findPrevDate.end
          ).diff(
            startDate.isAfter(findPrevDate.start)
              ? startDate
              : findPrevDate.start,
            "minute"
          );
          if (otBeforePrevWorkEndTime > 0) {
            if (employeeProfile.idPaymentType === 1) {
              xWorkingDailyHoliday += otBeforePrevWorkEndTime;
            } else {
              xWorkingMonthlyHoliday += otBeforePrevWorkEndTime;
            }
          }

          if (dayjs(`${values.date} 07:30:00`).isBefore(findDate.start)) {
            let seventhirty = dayjs(`${values.date} 07:30:00`);

            let otBeforeSevenThirty = dayjs(
              endDate.isBefore(seventhirty) ? endDate : seventhirty
            ).diff(
              startDate.isAfter(findPrevDate.end)
                ? startDate
                : findPrevDate.end,
              "minute"
            );
            if (otBeforeSevenThirty > 0) {
              if (isWorkingPrevDay) {
                xOT += otBeforeSevenThirty;
              } else {
                xOTHoliday += otBeforeSevenThirty;
              }
            }

            let otBeforeWorkTime = dayjs(
              endDate.isBefore(findDate.start) ? endDate : findDate.start
            ).diff(
              startDate.isAfter(seventhirty) ? startDate : seventhirty,
              "minute"
            );
            if (otBeforeWorkTime > 0) {

              let endtime = endDate.isBefore(findDate.start) ? endDate : findDate.start;
              let starttime = startDate.isAfter(seventhirty) ? startDate : seventhirty;
              let overlapTime = timeOverlap(starttime, endtime, breaktime.start, breaktime.end);

              if (isWorkingDay) {
                xOT += (otBeforeWorkTime - overlapTime);
              } else {
                xOTHoliday += (otBeforeWorkTime - overlapTime);
              }
            }

            let otBeforeWorkEndTime = dayjs(
              endDate.isBefore(findDate.end) ? endDate : findDate.end
            ).diff(
              startDate.isAfter(findDate.start) ? startDate : findDate.start,
              "minute"
            );
            if (otBeforeWorkEndTime > 0) {
              let endtime = endDate.isBefore(findDate.end) ? endDate : findDate.end;
              let starttime = startDate.isAfter(findDate.start) ? startDate : findDate.start;
              let overlapTime = timeOverlap(starttime, endtime, breaktime.start, breaktime.end);

              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += (otBeforeWorkEndTime - overlapTime);
              } else {
                xWorkingMonthlyHoliday += (otBeforeWorkEndTime - overlapTime);
              }
            }
          } else {
            let otBeforeWorkTime = dayjs(
              endDate.isBefore(findDate.start) ? endDate : findDate.start
            ).diff(
              startDate.isAfter(findPrevDate.end)
                ? startDate
                : findPrevDate.end,
              "minute"
            );
            if (otBeforeWorkTime > 0) {
              if (isWorkingPrevDay) {
                xOT += otBeforeWorkTime;
              } else {
                xOTHoliday += otBeforeWorkTime;
              }
            }

            let otBeforeBreakTime = dayjs(
              endDate.isBefore(breaktime.start) ? endDate : breaktime.start
            ).diff(
              startDate.isAfter(findDate.start) ? startDate : findDate.start,
              "minute"
            );
            if (otBeforeBreakTime > 0) {
              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += otBeforeBreakTime;
              } else {
                xWorkingMonthlyHoliday += otBeforeBreakTime;
              }
            }

            let otBetweenBreakTime = dayjs(
              endDate.isBefore(breaktime.end) ? endDate : breaktime.end
            ).diff(
              startDate.isAfter(breaktime.start) ? startDate : breaktime.start,
              "minute"
            );
            if (otBetweenBreakTime > 0) {
              if (formInputData.idRequestReason === 9) {
                if (isWorkingDay) {
                  xOT += otBetweenBreakTime;
                } else {
                  xOTHoliday += otBetweenBreakTime;
                }
              }
            }

            let otBeforeWorkEndTime = dayjs(
              endDate.isBefore(findDate.end) ? endDate : findDate.end
            ).diff(
              startDate.isAfter(breaktime.end) ? startDate : breaktime.end,
              "minute"
            );
            if (otBeforeWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += otBeforeWorkEndTime;
              } else {
                xWorkingMonthlyHoliday += otBeforeWorkEndTime;
              }
            }
          }

          if (
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30:00`
            ).isBefore(findNextDate.start)
          ) {
            let seventhirty = dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30:00`
            );

            let otBeforeSevenThirty = dayjs(
              endDate.isBefore(seventhirty) ? endDate : seventhirty
            ).diff(
              startDate.isAfter(findDate.end) ? startDate : findDate.end,
              "minute"
            );
            if (otBeforeSevenThirty > 0) {
              if (isWorkingDay) {
                xOT += otBeforeSevenThirty;
              } else {
                xOTHoliday += otBeforeSevenThirty;
              }
            }

            let otBeforeNextWorkTime = dayjs(
              endDate.isBefore(findNextDate.start)
                ? endDate
                : findNextDate.start
            ).diff(
              startDate.isAfter(seventhirty) ? startDate : seventhirty,
              "minute"
            );
            if (otBeforeNextWorkTime > 0) {
              if (isWorkingNextDay) {
                xOT += otBeforeNextWorkTime;
              } else {
                xOTHoliday += otBeforeNextWorkTime;
              }
            }
          } else {
            let otBeforeNextWorkTime = dayjs(
              endDate.isBefore(findNextDate.start)
                ? endDate
                : findNextDate.start
            ).diff(
              startDate.isAfter(findDate.end) ? startDate : findDate.end,
              "minute"
            );
            if (otBeforeNextWorkTime > 0) {
              if (isWorkingDay) {
                xOT += otBeforeNextWorkTime;
              } else {
                xOTHoliday += otBeforeNextWorkTime;
              }
            }
          }

          if (
            breaktimeNextDate.start.isBetween(
              dayjs(findNextDate.start),
              dayjs(findNextDate.end),
              "minute",
              "[]"
            ) &&
            breaktimeNextDate.end.isBetween(
              dayjs(findNextDate.start),
              dayjs(findNextDate.end),
              "minute",
              "[]"
            )
          ) {
            let otBeforeBreakTimeNextDate = dayjs(
              endDate.isBefore(breaktimeNextDate.start)
                ? endDate
                : breaktimeNextDate.start
            ).diff(
              startDate.isAfter(findNextDate.start)
                ? startDate
                : findNextDate.start,
              "minute"
            );
            if (otBeforeBreakTimeNextDate > 0) {
              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += otBeforeBreakTimeNextDate;
              } else {
                xWorkingMonthlyHoliday += otBeforeBreakTimeNextDate;
              }
            }

            let otBeforeNextWorkEndTime = dayjs(
              endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
            ).diff(
              startDate.isAfter(breaktimeNextDate.end)
                ? startDate
                : breaktimeNextDate.end,
              "minute"
            );
            if (otBeforeNextWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += otBeforeNextWorkEndTime;
              } else {
                xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
              }
            }
          } else {
            let otBeforeNextWorkEndTime = dayjs(
              endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
            ).diff(
              startDate.isAfter(findNextDate.start)
                ? startDate
                : findNextDate.start,
              "minute"
            );
            if (otBeforeNextWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1) {
                xWorkingDailyHoliday += otBeforeNextWorkEndTime;
              } else {
                xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
              }
            }
          }

          let otAfterNextWorkEndTime = dayjs(endDate).diff(
            startDate.isAfter(findNextDate.end) ? startDate : findNextDate.end,
            "minute"
          );
          if (otAfterNextWorkEndTime > 0) {
            xOTHoliday += otAfterNextWorkEndTime;
          }
        } else if (values.pattern.idWorkingType === 1) {
          if (startDate.isBefore(findDate.start)) {
            let findDatePrevPattern = {
              start: dayjs(findPrevDate.start).add(1, "day"),
              end: dayjs(findPrevDate.end).add(1, "day"),
            };

            if (
              dayjs(findDatePrevPattern.start).isBefore(
                findDate.start,
                "minute"
              )
            ) {
              let otBeforeFindDatePrevPattern = dayjs(
                endDate.isBefore(findDatePrevPattern.start)
                  ? endDate
                  : findDatePrevPattern.start
              ).diff(
                startDate.isAfter(findPrevDate.end)
                  ? startDate
                  : findPrevDate.end,
                "minute"
              );
              if (otBeforeFindDatePrevPattern > 0) {
                xOTHoliday += otBeforeFindDatePrevPattern;
              }

              let otBeforeFindDatePrevPatternEnd = dayjs(
                endDate.isBefore(findDatePrevPattern.end)
                  ? endDate
                  : findDatePrevPattern.end
              ).diff(
                startDate.isAfter(findDatePrevPattern.start)
                  ? startDate
                  : findDatePrevPattern.start,
                "minute"
              );
              if (otBeforeFindDatePrevPatternEnd > 0) {
                xWorkingMonthlyHoliday += otBeforeFindDatePrevPatternEnd;
              }

              let findDateStartNewDate = dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (findDateStartNewDate.isBefore(findDate.start)) {
                let otBeforeStartNewDate = dayjs(
                  endDate.isBefore(findDateStartNewDate)
                    ? endDate
                    : findDateStartNewDate
                ).diff(
                  startDate.isAfter(findDatePrevPattern.end)
                    ? startDate
                    : findDatePrevPattern.end,
                  "minute"
                );
                if (otBeforeStartNewDate > 0) {
                  if (isWorkingPrevDay) {
                    xOT += otBeforeStartNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNewDate;
                  }
                }

                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findDateStartNewDate)
                    ? startDate
                    : findDateStartNewDate,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  if (!isWorkingDay) {
                    xOTHoliday += otBeforeWorkTime;
                  } else {
                    xOT += otBeforeWorkTime;
                  }
                }
              } else {
                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findDatePrevPattern.end)
                    ? startDate
                    : findDatePrevPattern.end,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  xOTHoliday += otBeforeWorkTime;
                }
              }
            } else {
              let otBeforeFindDateEndPrevDate = dayjs(
                endDate.isBefore(findPrevDate.end) ? endDate : findPrevDate.end
              ).diff(
                startDate.isAfter(findPrevDate.start)
                  ? startDate
                  : findPrevDate.start,
                "minute"
              );
              if (otBeforeFindDateEndPrevDate > 0) {
                xWorkingMonthlyHoliday += otBeforeFindDateEndPrevDate;
              }

              let findDateStartNewDate = dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (findDateStartNewDate.isBefore(findDate.start)) {
                let otBeforeStartNewDate = dayjs(
                  endDate.isBefore(findDateStartNewDate)
                    ? endDate
                    : findDateStartNewDate
                ).diff(
                  startDate.isAfter(findPrevDate.end)
                    ? startDate
                    : findPrevDate.end,
                  "minute"
                );
                if (otBeforeStartNewDate > 0) {
                  if (isWorkingPrevDay) {
                    xOT += otBeforeStartNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNewDate;
                  }
                }

                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findDateStartNewDate)
                    ? startDate
                    : findDateStartNewDate,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  if (!isWorkingDay) {
                    xOTHoliday += otBeforeWorkTime;
                  } else {
                    xOT += otBeforeWorkTime;
                  }
                }
              } else {
                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findPrevDate.end)
                    ? startDate
                    : findPrevDate.end,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  if (
                    isWorkingPrevDay &&
                    (isWorkingDay ||
                      dayjs(findPrevDate.end).isBefore(
                        dayjs(
                          `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
                          "YYYY-MM-DD HH:mm"
                        )
                      ))
                  ) {
                    xOT += otBeforeWorkTime;
                  } else {
                    xOTHoliday += otBeforeWorkTime;
                  }
                }
              }
            }
          }

          let otBeforeWorkEndTime = dayjs(
            endDate.isBefore(findDate.end) ? endDate : findDate.end
          ).diff(
            startDate.isAfter(findDate.start) ? startDate : findDate.start,
            "minute"
          );
          if (otBeforeWorkEndTime > 0) {
            xWorkingMonthlyHoliday += otBeforeWorkEndTime;
          }

          if (endDate.isAfter(findDate.end)) {
            let findDatePatternAddDay = {
              start: dayjs(findDate.start).add(1, "day"),
              end: dayjs(findDate.end).add(1, "day"),
            };
            if (
              dayjs(findDatePatternAddDay.start).isBefore(
                findNextDate.start,
                "minute"
              )
            ) {
              let findNextDateStartNewDate = dayjs(
                `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (
                findNextDateStartNewDate.isBefore(findDatePatternAddDay.start)
              ) {
                let otBeforeStartNextNewDate = dayjs(
                  endDate.isBefore(findNextDateStartNewDate)
                    ? endDate
                    : findNextDateStartNewDate
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeStartNextNewDate > 0) {
                  if (isWorkingDay) {
                    xOT += otBeforeStartNextNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNextNewDate;
                  }
                }

                let otBeforeFindDatePatternAddDayStart = dayjs(
                  endDate.isBefore(findDatePatternAddDay.start)
                    ? endDate
                    : findDatePatternAddDay.start
                ).diff(
                  startDate.isAfter(findNextDateStartNewDate)
                    ? startDate
                    : findNextDateStartNewDate,
                  "minute"
                );
                if (otBeforeFindDatePatternAddDayStart > 0) {
                  if (!isWorkingNextDay) {
                    xOTHoliday += otBeforeFindDatePatternAddDayStart;
                  } else {
                    xOT += otBeforeFindDatePatternAddDayStart;
                  }
                }
              } else {
                let otBeforeFindDatePatternAddDayStart = dayjs(
                  endDate.isBefore(findDatePatternAddDay.start)
                    ? endDate
                    : findDatePatternAddDay.start
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeFindDatePatternAddDayStart > 0) {
                  xOTHoliday += otBeforeFindDatePatternAddDayStart;
                }
              }

              let otBeforeFindDatePatternAddDayEnd = dayjs(
                endDate.isBefore(findDatePatternAddDay.end)
                  ? endDate
                  : findDatePatternAddDay.end
              ).diff(
                startDate.isAfter(findDatePatternAddDay.start)
                  ? startDate
                  : findDatePatternAddDay.start,
                "minute"
              );
              if (otBeforeFindDatePatternAddDayEnd > 0) {
                xWorkingMonthlyHoliday += otBeforeFindDatePatternAddDayEnd;
              }

              let otBeforeNextWorkTime = dayjs(
                endDate.isBefore(findNextDate.start)
                  ? endDate
                  : findNextDate.start
              ).diff(
                startDate.isAfter(findDatePatternAddDay.end)
                  ? startDate
                  : findDatePatternAddDay.end,
                "minute"
              );
              if (otBeforeNextWorkTime > 0) {
                xOTHoliday += otBeforeNextWorkTime;
              }
            } else {
              let findNextDateStartNewDate = dayjs(
                `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (findNextDateStartNewDate.isBefore(findNextDate.start)) {
                let otBeforeStartNextNewDate = dayjs(
                  endDate.isBefore(findNextDateStartNewDate)
                    ? endDate
                    : findNextDateStartNewDate
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeStartNextNewDate > 0) {
                  if (isWorkingDay) {
                    xOT += otBeforeStartNextNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNextNewDate;
                  }
                }

                let otBeforeNextWorkTime = dayjs(
                  endDate.isBefore(findNextDate.start)
                    ? endDate
                    : findNextDate.start
                ).diff(
                  startDate.isAfter(findNextDateStartNewDate)
                    ? startDate
                    : findNextDateStartNewDate,
                  "minute"
                );
                if (otBeforeNextWorkTime > 0) {
                  if (!isWorkingNextDay) {
                    xOTHoliday += otBeforeNextWorkTime;
                  } else {
                    xOT += otBeforeNextWorkTime;
                  }
                }
              } else {
                let otBeforeNextWorkTime = dayjs(
                  endDate.isBefore(findNextDate.start)
                    ? endDate
                    : findNextDate.start
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeNextWorkTime > 0) {
                  if (
                    isWorkingDay &&
                    (isWorkingNextDay ||
                      dayjs(findDate.end).isBefore(
                        dayjs(
                          `${dayjs(valuesNext.date).format(
                            "YYYY-MM-DD"
                          )} 00:00`,
                          "YYYY-MM-DD HH:mm"
                        )
                      ))
                  ) {
                    xOT += otBeforeNextWorkTime;
                  } else {
                    xOTHoliday += otBeforeNextWorkTime;
                  }
                }
              }
            }
          }

          let otBeforeNextWorkEndTime = dayjs(
            endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
          ).diff(
            startDate.isAfter(findNextDate.start)
              ? startDate
              : findNextDate.start,
            "minute"
          );
          if (otBeforeNextWorkEndTime > 0) {
            xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
          }

          let otAfterNextWorkEndTime = dayjs(endDate).diff(
            startDate.isAfter(findNextDate.end) ? startDate : findNextDate.end,
            "minute"
          );
          if (otAfterNextWorkEndTime > 0) {
            if (isWorkingNextDay) {
              xOT += otAfterNextWorkEndTime;
            } else {
              xOTHoliday += otAfterNextWorkEndTime;
            }
          }
        }
      }
    }

    return {
      error: error,
      listOT: {
        xOT: xOT,
        xOTHoliday: xOTHoliday,
        xWorkingDailyHoliday: xWorkingDailyHoliday,
        xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
      },
    };
};

export default {
    calculatorOTProtech,
    calculatorOTStandard,
    calculatorOTMidnight
};