import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CardContent, Typography, Container, Box, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  Position,
} from "devextreme-react/data-grid";

import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import utils from "../../../../../utils";

import { getPayrunsSummaryYear } from "../../../../../actions/payruns";

const StyledRoot = styled("div")({
  minHeight: 700,
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
    marginTop: 16,
  },
});

const StyledCardContent = styled(CardContent)({
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .sub-header-1": {
    fontWeight: 500,
    fontSize: 18,
    textDecoration: "underline",
  },
  "& .sub-header": {
    fontWeight: 500,
  },
  "& .header-item": {
    fontSize: "1.25rem",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  "& .wrap-item-data": {
    marginLeft: 16,
    marginBottom: 8,
  },
  "& .wrap-check": {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-root": {
      paddingLeft: 0,
    },
  },
  "& .item-name": {
    fontWeight: 600,
    marginBottom: 4,
  },
  "& .wrap-label-switch": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .custom-date-payment": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    alignItems: "center",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings-all[role="columnheader"]': {
    backgroundColor: "#005e55",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
  {
    backgroundColor: "#ef5350",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction-all[role="columnheader"]': {
    backgroundColor: "#a30000",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const PayrollSummaryYearPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let { year } = useParams();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payRunSummaryYear } = useSelector(
    (state) => state.payRunSummaryYear
  );

  const [isPayroll, setIsPayroll] = useState(null);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [gridColumnsAddition, setGridColumnsAddition] = React.useState([]);
  const [gridColumnsDeduction, setGridColumnsDeduction] = React.useState([]);
  const [data, setData] = React.useState(null);
  const [showHide, setShowHide] = useState({
    general: "hide",
    lateAbsent: "hide",
    otShift: "hide",
  });

  let gridColumnsOT = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (ชม.)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneAmount",
      caption: `OT 1 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (ชม.)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneFiveAmount",
      caption: `OT 1.5 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (ชม.)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otTwoAmount",
      caption: `OT 2 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (ชม.)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otThreeAmount",
      caption: `OT 3 (บาท)`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "shiftMorning",
      caption: `ค่ากะเช้า`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNoon",
      caption: `ค่ากะบ่าย`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNight",
      caption: `ค่ากะดึก`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
  ];

  useEffect(() => {
    dispatch(getPayrunsSummaryYear(year));
  }, []);

  useEffect(() => {
    if (payRunSummaryYear && payRunSummaryYear.length > 0) {
      let temp = [...payRunSummaryYear];
      temp.forEach((element, index) => {
        element["index"] = index + 1;
      });

      setData(temp);
    }
  }, [payRunSummaryYear]);

  const customizeNumber = (data) => {
    return data.value ? `${utils.numberWithCommas(data.value)} บาท` : "";
  };

  const customizeHours = (data) => {
    return data.value ? `${utils.numberWithCommas(data.value)} ชม.` : "";
  };

  return (
    <Fragment>
      {userProfile && (
        <Fragment>
          {isPayroll === false ? (
            <Route>
              <Redirect to="/unauthorized" />
            </Route>
          ) : (
            <StyledRoot className={`page`}>
              <Container maxWidth="lg">
                <Box marginBottom="24px">
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <Link
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={"/report-center"}
                    >
                      {`${t("AllReports")}`}
                    </Link>
                    <Typography color="text.primary">
                      {`สรุปประจำปี ${year}`}
                    </Typography>
                  </Breadcrumbs>
                </Box>

                <div style={{ marginBottom: 8 }}>
                  <Typography variant="h4" style={{ paddingTop: 8 }}>
                    {`สรุปประจำปี ${year}`}
                  </Typography>
                </div>
                <StyledCard>
                  <StyledCardContent>
                    <StyledWrapDataGrid>
                      <DataGrid
                        dataSource={data ? data : []}
                        showBorders={false}
                        showColumnLines={false}
                        rowAlternationEnabled={true}
                        columnAutoWidth={true}
                      >
                        <ColumnChooser enabled={true} mode={"select"}>
                          <Position
                            my="right top"
                            at="right bottom"
                            of=".dx-datagrid-column-chooser-button"
                          />

                          <ColumnChooserSearch
                            enabled={true}
                            editorOptions={{ placeholder: "Search column" }}
                          />

                          <ColumnChooserSelection
                            allowSelectAll={true}
                            selectByClick={true}
                          />
                        </ColumnChooser>

                        <Column
                          caption={`${t("No_Number")}`}
                          dataField="index"
                          width={60}
                          allowFiltering={false}
                          alignment="center"
                          cssClass="column-info"
                        />

                        {/* <Column
                          caption={`${t("idEmp")}`}
                          dataField="idEmp"
                          dataType="string"
                          width={120}
                          cssClass="column-info"
                        /> */}

                        <Column
                          caption={`${t("EmployeeID")}`}
                          dataField="employeeId"
                          dataType="string"
                          width={120}
                          cssClass="column-info"
                        />

                        <Column
                          caption={`${t("Title")}`}
                          dataType="string"
                          cssClass="column-info"
                          dataField="title"
                        ></Column>
                        <Column
                          caption={`${t("FirstName")}`}
                          dataType="string"
                          cssClass="column-info"
                          dataField="firstname"
                        >
                          <HeaderFilter allowSearch={true} />
                        </Column>
                        <Column
                          caption={`${t("LastName")}`}
                          dataType="string"
                          cssClass="column-info"
                          dataField="lastname"
                        >
                          <HeaderFilter allowSearch={true} />
                        </Column>

                        <Column
                          dataField="personalId"
                          caption={`${t("PersonalID")}`}
                          cssClass="column-info"
                        />

                        <Column
                          dataField="sumSalary"
                          caption={`${t("Salary")}`}
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="sumAllOt"
                          caption={`${t("Overtime")}`}
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="sumAllShift"
                          caption={`${t("Shift")}`}
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="additionTax"
                          caption={`${t("Addition")}`}
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="additionNonTax"
                          caption="Addition (Non-Tax)"
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="deductionTax"
                          caption={`${t("Deduction")}`}
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="deductionNonTax"
                          caption="Deduction (Non-Tax)"
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="accumulateEarnings"
                          caption="เงินได้สะสม"
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />
                        <Column
                          dataField="beforeAccumulateEarnings"
                          caption="เงินได้สะสม (ก่อนขึ้นระบบ)"
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="beforeAccumulateEarnings"
                          caption="เงินได้สะสม (ก่อนขึ้นระบบ)"
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="differenceBalanceEarnings"
                          caption="ส่วนต่างเงินได้สะสม"
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="accumulateTax"
                          caption="ภาษีสะสม"
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />
                        <Column
                          dataField="accumulateSSO"
                          caption="ประกันสังคมสะสม"
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />
                        <Column
                          dataField="accumulatePF"
                          caption="XXXXXXXXXXXXX"
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="pfThisMonth"
                          caption="กองทุนสำรองเลี้ยงชีพสะสม"
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="totalEarningsOfYear"
                          caption="รายได้รวมทั้งปี"
                          cssClass="column-deduction"
                          format="~###,###,##0.00"
                        />

                        <Column
                          dataField="taxThisYear"
                          caption="ภาษีที่ต้องชำระปีนี้"
                          cssClass="column-shift"
                          format="###,###,##0.00"
                        />
                        <Column
                          dataField="taxBalance"
                          caption="ส่วนเกิน/ส่วนขาด"
                          cssClass="column-net"
                          format="###,###,##0.00"
                        />

                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging defaultPageSize={20} />
                        <Pager
                          visible={true}
                          allowedPageSizes={[10, 20, 30]}
                          showPageSizeSelector={true}
                          showNavigationButtons={true}
                          showInfo={true}
                        />
                        <Selection mode="single" />
                        <Sorting mode="multiple" />
                        <Scrolling columnRenderingMode="virtual" />
                        <FilterRow visible={false} />
                        <HeaderFilter visible={true} />
                        <SearchPanel
                          visible={true}
                          width={240}
                          placeholder="Search..."
                        />
                      </DataGrid>
                    </StyledWrapDataGrid>
                  </StyledCardContent>
                </StyledCard>
              </Container>
            </StyledRoot>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default PayrollSummaryYearPage;
