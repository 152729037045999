import React from "react";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, Fab, Typography, Icon } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  containedCard: {
    backgroundColor: "#007afe",
    "& .MuiTypography-root": {
      color: "#FFFFFF",
    },
  },
  containedIcon: {
    backgroundColor: "#FFFFFF !important",
    color: "#007afe !important",
  },
  outlinedIcon:{
    color : "#FFFFFF !important",
    backgroundColor: "#007afe !important",
  },
  outlinedInherit:{
    color: "#007afe !important",
    backgroundColor: "inherit !important",
    border: "1px solid #007afe !important"
  },
  wrapOne: {
    display: "flex",
    marginBottom: 4,
    "& .title":{
      fontWeight: 400
    },
    "& .icon": {
      marginLeft: "auto",
    },
  },
  wrapTwo:{
    display: "flex",
    alignItems:"baseline",
    "& .value":{
      fontSize : 50,
      "& .unit":{
        marginLeft:7
      }
    }
  },
  contentOutlinedInherit:{
    alignItems:"center",
    justifyContent:"space-around"
  }
}));

const CardSummary = (props) => {
  const { title, icon, variant, value, unit } = props;
  const classes = useStyles();
  return (
    <Card
      className={clsx(classes.root, {
        [classes.containedCard]: variant === "contained" || variant === "none",
      })}
    >
      <CardContent style={{ padding: 16 }}>
        <div className={classes.wrapOne}>
          <Typography className={`title`} variant="h6" color="textSecondary">{title}</Typography>
          {variant !== "none" && 
            <div className={`icon`}>
              <Fab
                className={clsx({
                  [classes.containedIcon]: variant === "contained",
                  [classes.outlinedIcon]: variant === "outlined",
                })}
                aria-label={title}
                disabled
              >
                <Icon className={icon} style={{ fontSize: 32, width: "100%", paddingTop:"5px" }}/>
              </Fab>
            </div>
          }
        </div>
        <div className={clsx(classes.wrapTwo, { [classes.contentOutlinedInherit]: variant === "none" })}>
          {variant === "none" && 
            <div className={`icon`}>
              <Fab
                className={classes.containedIcon}
                aria-label={title}
                disabled
              >
                <Icon className={icon} style={{ fontSize: 32, width: "100%", paddingTop:"5px" }}/>
              </Fab>
            </div>
          } 
          <Typography variant="h4" className={`value`}>
            {value}
            <Typography variant="caption" color="textSecondary" className="unit">
              {unit}
            </Typography>
          </Typography>
          
        </div>
      </CardContent>
    </Card>
  );
};

export default CardSummary;
