import React, { useEffect, useState } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import TablePagination from "@mui/material/TablePagination";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import FactoryIcon from "@mui/icons-material/Factory";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#f1f4f9",
  paddingBottom: 36,
  // ["@media (min-width:1200px)"]:{
  //   paddingLeft: 24,
  //   paddingRight: 24
  // }
});

const StyledFormControl = styled(FormControl)({
  width: 350,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& fieldset": {
    borderColor: "#dee3e9",
  },
});

const StyledCardActionArea = styled(CardActionArea)({
  borderRadius: 20,
  marginBottom: 24,
  backgroundColor: "#FFFFFF",
});

const StyledChip = styled(Chip)({
  color: "#b72136",
  backgroundColor: "#ff484229",
  fontWeight: 700,
  borderRadius: 6
});

const StyledContent = styled("div")({
  padding: 36,
  "& .filter": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: 24,
  },
  "& .wrap-card": {
    display: "grid",
    columnGap: 16,
    gridTemplateColumns: "repeat(3, 1fr)",
    "& button": {},
  },
  "& .MuiPaper-root": {
    padding: 24,
    borderRadius: 20,
    "& .part-one": {
      display: "flex",
      alignItems: "center",
      marginBottom: 16,
      justifyContent: "space-between",
      "& .MuiAvatar-root": {
        marginRight: 8,
        width: 48,
        height: 48,
        borderRadius: 8,
      },
      "& .MuiTypography-h5": {
        fontWeight: 600,
        fontSize: 32,
        lineHeight: 1,
      },
    },
    "& .part-two": {
      marginBottom: 16,
      
      "& .MuiTypography-h5": {
        width: 280,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    "& .part-three": {
      display: "flex",
      alignItems: "center",
      marginBottom: 16,
    },
    "& .part-four": {},
    "& .part-five": {
      "& .wrap-item": {
        display: "flex",
        color: "#637381",
        alignItems: "center",
        "& .fal": {
          marginRight: 8,
        },
      },
    },
  },
});

function createData(name, department, icon, size, typeEmp, exp, urgent) {
  return { name, department, icon, size, typeEmp, exp, urgent };
}

const rows = [
  createData(
    "ผู้ช่วยผลิต PP1,2",
    "PP1,2 Production",
    <FactoryIcon />,
    10,
    "Part Time",
    3,
    true
  ),
  createData(
    "พนักงานขับรถส่งของ",
    "Logistic",
    <DirectionsCarIcon />,
    3,
    "Full Time",
    2,
    false
  ),
  createData("สารบรรณ", "PP3 Production", <ArticleIcon />, 50, "Part Time", 0),
  createData(
    "ผู้ช่วยผลิต LLDPE",
    "LLDPE Production",
    <FactoryIcon />,
    1,
    "Part Time",
    1,
    false
  ),
  createData(
    "ผู้ช่วยผลิต LDPE",
    "LDPE Production",
    <FactoryIcon />,
    2,
    "Full Time",
    4,
    false
  ),
  createData(
    "ผู้ช่วยผลิต HDPE1",
    "HDPE1 Production",
    <FactoryIcon />,
    1,
    "Full Time",
    5,
    false
  ),
  createData(
    "ผู้ช่วยผลิต HDPE2,3",
    "HDPE2,3 Production",
    <FactoryIcon />,
    99,
    "Part Time",
    8,
    false
  ),
];

const RequestPage = (props) => {
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  return (
    <StyledRoot className={`page`}>
      <Typography variant="h4" style={{ paddingTop: 8 }}>
        Request List
      </Typography>
      <CardStyle>
        <StyledContent>
          <div className={`filter`}>
            <StyledFormControl>
              <OutlinedInput
                placeholder="Search Position..."
                onChange={handleChangeSearch}
              />
            </StyledFormControl>
          </div>
          <div className={`wrap-card`}>
            {rows
              .filter((value) => {
                if (search !== "") {
                  return value.name
                    .toLowerCase()
                    .includes(search.toLowerCase());
                } else {
                  return value;
                }
              })
              .map((value) => {
                return (
                  <StyledCardActionArea component={NavLink} to="/vendor/request/information">
                    <Paper variant="outlined">
                      <div className={`part-one`}>
                        <Avatar variant="rounded" src={`${process.env.REACT_APP_API_URL}image/company/0100.jpg`}></Avatar>
                        { value.urgent &&
                          <StyledChip label="Urgent" size="small" />
                        }
                        
                        <div>
                          <Typography align="right" variant="h5">
                            {value.size}
                          </Typography>
                          <Typography
                            align="right"
                            variant="caption"
                            color="text.secondary"
                          >
                            {`เปิดรับสมัคร`}
                          </Typography>
                        </div>
                      </div>
                      <div className={`part-two`}>
                        <div>
                          <Typography variant="h5">{value.name}</Typography>
                          <Typography variant="body2" color="text.secondary">
                            {value.department}
                          </Typography>
                        </div>
                      </div>
                      <div className={`part-three`}>
                        <i
                          class="fal fa-map-marker-alt"
                          style={{ color: "#637381", marginRight: 8 }}
                        ></i>
                        <Typography
                          variant="body2"
                          style={{ color: "#637381" }}
                        >
                          Site 1, Rayong
                        </Typography>
                      </div>
                      <div className={`part-four`}>
                        <Typography variant="caption" color="text.secondary">
                          Posted day: 08 Dec 2021
                        </Typography>
                      </div>
                      <Divider
                        style={{
                          margin: "16px 0px",
                          borderWidth: "0px 0px thin",
                          borderColor: "rgba(145, 158, 171, 0.24)",
                          borderStyle: "dashed",
                        }}
                      />
                      <div className={`part-five`}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <div className={`wrap-item`}>
                              <i class="fal fa-calendar"></i>
                              <Typography>12 Dec 2021</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className={`wrap-item`}>
                              <i class="fal fa-paper-plane"></i>
                              {value.exp > 0 ? (
                                <Typography>Highly Skill</Typography>
                              ) : (
                                <Typography>No Experience</Typography>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className={`wrap-item`}>
                              <i class="fal fa-clock"></i>
                              <Typography>{value.typeEmp}</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className={`wrap-item`}>
                              <i class="fal fa-hand-holding-usd"></i>
                              <Typography>12,000 - 14,000</Typography>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </StyledCardActionArea>
                );
              })}
          </div>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </StyledContent>
      </CardStyle>
    </StyledRoot>
  );
};

export default RequestPage;
