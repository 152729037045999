import { httpClient } from "./httpClient";

const getShiftPattern = (idCompany) => {
  return httpClient.get(`/company/${idCompany}/shift-pattern`);
};

const getShift = (idCompany) => {
  return httpClient.get(`/company/${idCompany}/shift`);
};

const getShiftGroupById = (idShiftGroup) => {
  return httpClient.get(`/shift/${idShiftGroup}`);
};

const getAllEmployeeShift = (idCompany) => {
  return httpClient.get(`/company/${idCompany}/employee-shift`);
};

const addNewShift = (data) => {
  return httpClient.post(`/addNewShift`,data);
};

const getShiftScheduleByEmployeeId = (idEmployees, query) => {
  return httpClient.get(`/employees/${idEmployees}/shift-schedule`, {params: query})
}

export default {
  getShiftPattern,
  getShift,
  getAllEmployeeShift,
  addNewShift,
  getShiftGroupById,
  getShiftScheduleByEmployeeId
};
