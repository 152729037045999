import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "react-use";
import { useHistory, useLocation } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Chip,
  Typography,
  Snackbar,
  IconButton
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

import SelectedGroupLocations from "./selectedGroupLocation";
import Register from "./register";
import MapContainer from "./MapContainer";

import Backdrop from "../shared/general/Backdrop";

import {
  getLineUserProfile,
  postAttendance,
  registerLine,
  getTodayCheck,
} from "../../../actions/line";

import { getUserProfile } from "../../../actions/user";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 100,
    backgroundColor: "#FFFFFF",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  paperCheckIn: {
    padding: theme.spacing(3),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
  },
  dateTime: {
    color: "#16243D",
    borderRadius: "10px",
    marginBottom: "20px",
  },
  checkInBtn: {
    borderRadius: 8,
    color: "#fff",
    width: "100%",
    backgroundColor: "#66bb6a",
    border: "1px solid #66bb6a",
    "&:hover": {
      borderColor: "#4caf50",
      backgroundColor: "#4caf50",
    },
  },
  checkOutBtn: {
    borderRadius: 8,
    color: "#fff",
    width: "100%",
    backgroundColor: "#ef5350",
    border: "1px solid #ef5350",
    "&:hover": {
      borderColor: "#e53935",
      backgroundColor: "#e53935",
    },
  },
  headPanel: {
    fontWeight: 600,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  locationMessage: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  messagePassWrapper: {
    display: "flex",
    alignItems: "center",
    color: "#388e3c",
  },
  messageFailWrapper: {
    display: "flex",
    alignItems: "center",
    color: "#d32f2f",
  },
}));

const StyledCheckToday = styled("div")({
  marginTop: 16,
  padding: 24,
  borderRadius: "20px",
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  "& .label-check": {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 500,
  },
});

const StyledTextLocation = styled(Typography)({
  fontWeight: 600,
});

const StyledBoxEmpty = styled(Box)({
  height: 60,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiTypography-root": {
    fontStyle: "oblique",
  },
});

const StyledDivider = styled(Divider)({
  margin: "8px 0",
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledChipTime = styled(Chip)({
  height: 28,
  width: 88,
  fontSize: 14,
  borderRadius: 8,
  border: "none",
  marginBottom: 4,
  "&.check-in": {
    color: "#229a16",
    backgroundColor: "#54d62c29",
    "&.MuiChip-root.MuiChip-clickable:hover": {
      backgroundColor: "#54d62c29",
    },
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },
  "&.check-out": {
    color: "#b72136",
    backgroundColor: "#ff484229",
    "&.MuiChip-root.MuiChip-clickable:hover": {
      backgroundColor: "#ff484229",
    },
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const liff = window.liff;

function GPSCheckInOut() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let query = useQuery();
  useTitle("Check In/Out - AntHR");
  const [time, setTime] = useState(new Date());
  const [mode, setMode] = useState("");
  const [match, setMatch] = useState(null);
  const [clickCheck, setClickCheck] = useState(false);
  const [clickCheckMock, setClickCheckMock] = useState(false);
  const [locations, setLocations] = useState(null);

  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  // const [state, setState] = useState({
  //   name: "PANG [บื้อเจ้าเก่า]",
  //   userLineID: "Uf8000dee3b1bbb53b4d4367ba20e51c8",
  //   pictureUrl:
  //     "https://profile.line-scdn.net/0hr3KBZfxMLRxmOwVFlepTYxZrLnZFSnQOQgkwKlY8c3wIWDhMSwhieFFpeykOAjhCQ11nLVQ9ci5qKFp6eG3RKGELcytfDWtLSllq-A",
  // });

  const [state, setState] = useState(null);
  const [lineUserProfile, setLineUserProfile] = useState(null);
  const [dataRegister, setDataRegister] = useState(null);
  const [fetchingData, setFetchingData] = useState(true);
  const [gpsAddress, setGpsAddress] = useState(null);
  const [coordinate, setCoordinate] = useState({
    latitude: null,
    longitude: null,
  });
  const [loadMap, setLoadMap] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [showMapMock, setShowMapMock] = useState(false);
  const [todayCheck, setTodayCheck] = useState(null);
  const [openResponse, setOpenResponse] = useState(false);

  useEffect(() => {
    if (query.get("source") === "pwa") {
      if (currentUser) {
        dispatch(getUserProfile());
      } else {
        window.location.assign("/login?source=pwa");
      }
    } else {
      console.log("currentUser: ", currentUser);
      if (currentUser) {
        dispatch(getUserProfile());
      } else {
        liff
          .init({ liffId: "1657001593-rlxzYdgP" })
          .then(async () => {
            if (liff.isLoggedIn()) {
              handleGetProfile();
            } else {
              liff.login();
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (userProfile) {
      fetchDataPWA();
    }
  }, [userProfile]);

  useEffect(() => {
    if (query.get("source") === "pwa") {
    } else if (!currentUser && !userProfile) {
      fetchData();
    }
  }, [state]);

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const handleGetProfile = async () => {
    let getProfile = await liff.getProfile();
    if (getProfile) {
      setState({
        name: getProfile.displayName,
        userLineID: getProfile.userId,
        pictureUrl: getProfile.pictureUrl,
        statusMessage: getProfile.statusMessage,
      });
    }
  };

  async function fetchData() {
    const result = await dispatch(getLineUserProfile(state.userLineID));
    if (result) {
      setFetchingData(false);
      if (result.status === 404) {
        setDataRegister("");
      } else {
        setLineUserProfile(result);
      }
    } else {
      setFetchingData(false);
      setDataRegister("");
    }
  }

  async function fetchDataPWA() {
    const result = await dispatch(getLineUserProfile(userProfile.idEmp, "pwa"));
    if (result) {
      setFetchingData(false);
      if (result.status === 404) {
        setDataRegister("");
      } else {
        setLineUserProfile(result);
        setDataRegister(null);
      }
    } else {
      setFetchingData(false);
      setDataRegister("");
    }
  }

  const handleClickCheck = async (mode) => {
    if (lineUserProfile.status === "") {
      alert("เกิดข้อผิดพลาด โปรดติดต่อผู้ดูแลระบบ");
    } else {
      setMode(mode);
    }
  };

  const handleChangeClickCheck = () => {
    setClickCheck(!clickCheck);
  };

  const addAttendance = async (location) => {
    const dataForm = {
      attendanceDateTime: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      isCheckIn: mode === "in",
      idAttendanceType: gpsAddress ? 4 : 1,
      idGpsLocations: location ? location.idGpsLocations : null,
      idEmp: lineUserProfile.idEmp,
      idCompany: lineUserProfile.idCompany,
      gpsAddress: gpsAddress ? gpsAddress : null,
      latitude: coordinate.latitude,
      longitude: coordinate.longitude,
    };
    
    const result = await dispatch(postAttendance(dataForm));
    if (result) {
      if (result.data.rows === 1) {
        fetchDataPWA()
        setOpenAlert(true);
        handleChangeAlertType("success");
        handleCancel();
      } else {
        alert("เกิดข้อผิดพลาดโปรดติดต่อเจ้าหน้าที่");
      }
    } else {
      setOpenAlert(true);
      handleChangeAlertType("error");
    }
  };

  const handleCancel = () => {
    setMode("");
    setGpsAddress(null);
    setLocations(null);
    setFetchingData(false);
    setLoadMap(false);
  };

  const handleChangePersonalId = (event) => {
    setDataRegister(event.target.value);
  };

  const handleRegisterLine = async () => {
    const dataForm = {
      lineUserId: state.userLineID,
      personalId: dataRegister,
    };

    const result = await dispatch(registerLine(dataForm));
    if (result) {
      if (result.data.rows === 1) {
        setOpenAlert(true);
        handleChangeAlertType("success");
        setDataRegister(null);
        setFetchingData(true);
        fetchData();
      } else {
        alert("ไม่พบเลขบัตรประชาชน โปรดลองอีกครั้ง");
      }
    } else {
      setOpenAlert(true);
      handleChangeAlertType("error");
    }
  };

  const handleChangeAlertType = (status) => {
    setAlertType(status);
  };

  const handleCloseAlert = () => {
    setOpenAlert("");
  };

  const handleClickShowMapMock = () => {
    setShowMapMock(true);
  };

  const handleClickCloseShowMapMock = () => {
    setShowMapMock(false);
  };

  return (
    <div className={`page ${classes.root}`}>
      {fetchingData ? (
        <Backdrop open={fetchingData} />
      ) : (
        <Container maxWidth="lg">
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={classes.paperCheckIn}>
                <Box className={classes.dateTime}>
                  <Box sx={{ marginBottom: "20px" }}>
                    <Typography align="center" className={classes.headPanel}>
                      บันทึกเวลาเข้า-ออก
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: "10px" }}>
                    <Grid container>
                      <Grid item xs={4} md={4} lg={4}>
                        <Typography
                          variant="body1"
                          className={classes.wrapIcon}
                          color="text.secondary"
                        >
                          <TodayIcon style={{ marginRight: "5px" }} />
                          วันที่
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body1">
                          {dayjs(new Date()).format("DD MMMM YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid container>
                      <Grid item xs={4} md={4} lg={4}>
                        <Typography
                          variant="body1"
                          className={classes.wrapIcon}
                          color="text.secondary"
                        >
                          <AccessTimeIcon style={{ marginRight: "5px" }} />
                          เวลา
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body1">
                          {dayjs(time).format("HH:mm:ss")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Divider className={classes.divider} />
                {lineUserProfile && (
                  <div style={{ marginBottom: 16 }}>
                    <Typography variant="h6" color="text.third">
                      สวัสดี...
                    </Typography>
                    <Typography
                      variant="h5"
                      align="center"
                    >{`${lineUserProfile.firstname} ${lineUserProfile.lastname}`}</Typography>
                  </div>
                )}
                {dataRegister !== null ? (
                  <Register
                    handleChange={handleChangePersonalId}
                    handleClick={handleRegisterLine}
                  />
                ) : (
                  <Fragment>
                    {mode !== "" ? (
                      <Fragment>
                        {lineUserProfile && (
                          <SelectedGroupLocations
                            mode={mode}
                            lineUserProfile={lineUserProfile}
                            handleCancel={handleCancel}
                            handleChangeClickCheck={handleChangeClickCheck}
                            setLocations={setLocations}
                            addAttendance={addAttendance}
                            locations={locations}
                            loadMap={loadMap}
                            gpsAddress={gpsAddress}
                          />
                        )}
                      </Fragment>
                    ) : (
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Button
                              variant="outlined"
                              className={classes.checkInBtn}
                              onClick={() => handleClickCheck("in")}
                              disable={
                                lineUserProfile && lineUserProfile.status === ""
                              }
                            >
                              ลงเวลาเข้างาน
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              variant="outlined"
                              className={classes.checkOutBtn}
                              onClick={() => handleClickCheck("out")}
                              disable={
                                lineUserProfile && lineUserProfile.status === ""
                              }
                            >
                              ลงเวลาออกงาน
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Fragment>
                )}
              </Paper>
            </Grid>
            {mode !== "" && (
              <Grid item xs={12} md={8} lg={8}>
                <Paper className={classes.paper}>
                  <MapContainer
                    setMatch={setMatch}
                    addAttendance={addAttendance}
                    setLoadMap={setLoadMap}
                    setGpsAddress={setGpsAddress}
                    setCoordinate={setCoordinate}
                    lineUserProfile={lineUserProfile}
                    locations={locations}
                    clickCheck={clickCheck}
                  />
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} md={4} lg={4}>
              <StyledCheckToday>
                <div style={{ marginBottom: 16 }}>
                  <Typography align="center" className={classes.headPanel}>
                    เวลาทำงานวันนี้
                  </Typography>
                  <div className="label-check">
                    <Typography
                      align="center"
                      variant="bpdy2"
                      color="text.third"
                    >
                      ลงเวลาเข้า
                    </Typography>
                    <Typography
                      align="center"
                      variant="bpdy2"
                      color="text.third"
                    >
                      ลงเวลาออก
                    </Typography>
                  </div>
                </div>
                <div>
                  {lineUserProfile &&
                  lineUserProfile.attendance &&
                  lineUserProfile.attendance.length > 0 ? (
                    <Fragment>
                      {lineUserProfile.attendance.map((x) => (
                        <Fragment>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: x.isCheckIn
                                ? "flex-start"
                                : "flex-end",
                            }}
                          >
                            <StyledChipTime
                              icon={
                                x.idAttendanceType === 1 ? (
                                  <LocationOnIcon />
                                ) : x.idAttendanceType === 2 ? (
                                  <FingerprintIcon />
                                ) : (
                                  <GpsFixedRoundedIcon />
                                )
                              }
                              className={x.isCheckIn ? "check-in" : "check-out"}
                              label={x.time}
                              variant="outlined"
                            />
                          </div>

                          <StyledTextLocation
                            variant="body2"
                            color="text.secondary"
                            align={x.isCheckIn ? "left" : "right"}
                          >
                            สถานที่: &nbsp;
                            {x.gpsLocationsName
                              ? x.gpsLocationsName
                              : x.gpsAddress
                              ? x.gpsAddress
                              : "-"}
                          </StyledTextLocation>
                          <StyledDivider />
                        </Fragment>
                      ))}
                    </Fragment>
                  ) : (
                    <StyledBoxEmpty>
                      <Typography color="text.secondary">
                        ไม่มีเวลาเข้างาน หรือ เวลาออกงาน
                      </Typography>
                    </StyledBoxEmpty>
                  )}
                </div>
              </StyledCheckToday>
            </Grid>
            {/* {showMapMock && !loadMap && (
              <Grid item xs={12} md={8} lg={8}>
                <Typography align="center" gutterBottom>กดปุ่ม CHECK IN เพื่อตรวจสอบตำแหน่ง</Typography>
                <Button
                  variant="outlined"
                  className={classes.checkInBtn}
                  startIcon={<LoginIcon />}
                  onClick={() => setClickCheckMock(true)}
                  disabled={loadMap}
                >
                  CHECK IN
                </Button>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="text"
                    onClick={() => handleClickCloseShowMapMock()}
                  >
                    กลับ
                  </Button>
                </div>
              </Grid>
            )}
            {showMapMock ? (
              <Fragment>
                <Grid item xs={12} md={8} lg={8}>
                  <Paper className={classes.paper}>
                    <MapMock
                      setLoadMap={setLoadMap}
                      clickCheck={clickCheckMock}
                      setClickCheckMock={setClickCheckMock}
                    />
                  </Paper>
                </Grid>
              </Fragment>
            ) : (
              <GpsMock handleClickShowMapMock={handleClickShowMapMock} />
            )} */}
          </Grid>
          <div>
            <IconButton
              onClick={() => setOpenResponse(!openResponse)}
              aria-label="view-response"
              size="small"
              style={{ color: "#DDD", marginTop: 24 }}
            >
              <LightbulbOutlinedIcon fontSize="inherit" />
            </IconButton>
          </div>
          {openResponse && (
            <Typography variant="caption" style={{ color: "#DDD", width: 300 }}>
              {JSON.stringify(lineUserProfile)}
            </Typography>
          )}
        </Container>
      )}
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {alertType === "success" ? (
          <Alert
            onClose={handleCloseAlert}
            severity="success"
            style={{ width: "100%" }}
          >
            บันทึกข้อมูลสำเร็จ
          </Alert>
        ) : (
          <Alert
            onClose={handleCloseAlert}
            severity="error"
            style={{ width: "100%" }}
          >
            เกิดข้อผิดพลาด...ติดต่อผู้ดูแลระบบ
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}

export default GPSCheckInOut;
