import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import useTable from "../../../../shared/table/useTable";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "transparent",
    "& .sticky": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 16px",

  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
    // display: "flex",
    // alignItems: "start",
  },
  "&.stickyHeader": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
    display: "flex",
    alignItems: "center",
  },
});

const StyledEditIcon = styled(EditIcon)({
  marginLeft: 6,
  marginRight: 8,
});

const StyledDeleteForeverIcon = styled(DeleteForeverIcon)({
  marginLeft: 6,
  marginRight: 8,
});

function DataTable(props) {
  const {
    leaveTypes,
    isLoading,
    addOrEditModal,
    setAddOrEditModal,
    handleOpen,
    setLeave,
    setType,
  } = props;
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { t, i18n } = useTranslation();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const headCells = [
    // {
    //   id: "action",
    //   label: `${t("")}`,
    //   align: "center",
    //   className: "sticky",
    //   minWidth: 60,
    // },
    {
      id: "leaveName",
      label: `${t("LeaveDayName")}`,
      align: "center",
      className: "sticky",
      minWidth: 280,
    },
    {
      id: "isPaid",
      label: `${t("LeaveType")}`,
      align: "center",
      minWidth: 180,
    },
    {
      id: "leaveValue",
      label: `${t("NumberLeaveDays")}`,
      align: "center",
      minWidth: 100,
    },

    {
      id: "conditions",
      label: `${t("Condition")}`,
      align: "center",
      minWidth: 180,
    },
    {
      id: "carryValue",
      label: `${t("AccumulateNotMoreThan")}`,
      align: "center",
      minWidth: 100,
    },
    {
      id: "isAfterProbation",
      label: `${t("AfterProbation")}`,
      align: "center",
      minWidth: 180,
    },
    {
      id: "minLeave",
      label: `${t("MinLeave")}`,
      align: "center",
      minWidth: 100,
    },
    { id: "gender", label: `${t("Gender")}`, align: "center", minWidth: 100 },
  ];

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(leaveTypes, headCells, filterFn);

  const renderTextCondition = (item) => {
    if (item.conditionType === "อายุงาน") {
      return `${item.conditionType} ครบ ${item.moreThanYears} ปี`;
    } else if (item.conditionType === "ระดับ") {
      return `${item.conditionType} ${item.moreThanJobLevel}`;
    } else {
      return ``;
    }
  };

  return (
    <>
      <TblContainer>
        <Table stickyHeader aria-label="sticky table">
          <TblHead />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              (leaveTypeList &&
                leaveTypeList.length > 0 &&
                leaveTypeList.map((item) => (
                  <StyledRowContent key={item.idLeaveType}>
                    {/* <StyledCellContent className="sticky">
                      <Typography>
                        <EditIcon></EditIcon>
                        <DeleteForeverIcon></DeleteForeverIcon>
                      </Typography>
                    </StyledCellContent> */}
                    <StyledCellContent className="sticky">
                      <StyledEditIcon
                        onClick={() => {
                          setLeave(item);
                          setType("Edit");
                          handleOpen();
                        }}
                      ></StyledEditIcon>
                      <StyledDeleteForeverIcon></StyledDeleteForeverIcon>
                      <Typography style={{ marginLeft: 10 }}>
                        {item.name}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography align="center">
                        {item.isPaid
                          ? `${t("PaidLeave")}`
                          : `${t("UnpaidLeave")}`}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography align="center">
                        {item.leaveValue === null
                          ? `${t("Unlimited")}`
                          : item.leaveValue}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography align="center">
                        {item.isLeaveStep ? renderTextCondition(item) : "-"}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography align="center">
                        {item.carryValue
                          ? `${item.carryValue}`
                          : `${t("Unlimited")}`}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography align="center">
                        {item.isAfterProbation
                          ? `${t("AfterProbation")}`
                          : `${t("StartWork")}`}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent align="center">
                      {item.minLeave
                        ? item.minLeave === 100
                          ? `${t("FullDay")}`
                          : item.minLeave === 50
                          ? `${t("HalfDay")}`
                          : `${item.minLeave}${t("Hrs")}`
                        : "-"}
                    </StyledCellContent>
                    <StyledCellContent align="center">
                      {item.gender ? item.gender : `${t("AllGender")}`}
                    </StyledCellContent>
                  </StyledRowContent>
                ))) || (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No Data
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TblContainer>
      <TblPagination />
    </>
  );
}

export default DataTable;
