import React from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogContent, styled, Typography } from "@mui/material";

import ButtonBlue from "../general/ButtonBlue";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: 450,
  [theme.breakpoints.down("sm")]: {
    width: 270,
  },
}));

const DialogCommentManager = (props) => {
  const { open, onClose, comment } = props;
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledDialogContent>
        <Typography variant="h5">{t("ReasonForDisapproval")}</Typography>
        <div
          style={{
            marginTop: 16,
            padding: "0px 16px",
            border: "1px solid rgba(0,0,0,0.26)",
            borderRadius: 16,
          }}
        >
          <div
            style={{
              maxHeight: 300,
              padding: "16px 0px",
              overflowY: "auto",
            }}
          >
            <Typography>{comment}</Typography>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 16 }}
        >
          <ButtonBlue variant="outlined" onClick={onClose}>
            {t("Close")}
          </ButtonBlue>
        </div>
      </StyledDialogContent>
    </Dialog>
  );
};

export default DialogCommentManager;
