import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  styled,
  Container,
  Breadcrumbs
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogProbation from "./DialogProbation";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { ProbationXlsxFile } from "./ProbationXlsxFile";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";
import TableProbationStatus from "./tableProbationStatus";
import AlertResponse from "../../../shared/general/AlertResponse";
import CardStyle from "../../../shared/general/Card";
import { SelectAllCompaniesVendor } from "../SelectCompanyLists";
import { getAllCompaniesByVendor } from "../../../../../actions/vendor";
import { getEmployeeProbationStatus } from "../../../../../actions/employee";
import { getAllEmployeesByIdVendorAndIdCompany } from "../../../../../actions/user";
import CircularProgress from '@mui/material/CircularProgress';
import { getProbationAndAnswer, updateProbationAdmin } from "../../../../../actions/probation";

const StyledHeadingPage = styled("div")({
  marginTop: 16,
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const ProbationStatus = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [probationAnswer, setProbationAnswer] = useState(null);
  const [employeesProbationStatus, setEmployeesProbationStatus] = useState(null);

  const [companyLists, setCompanyLists] = useState(null);
  const [inputSearch, setInputSearch] = useState(dayjs());
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [detail, setDetail] = useState(null);
  const [user, setUser] = useState(null);
  const [employeesLists, setEmployeesList] = useState(null);
  const [configAlert, setConfigAlert] = useState({ openAlert: false, alertType: "" });
  const [isFetching, setIsFetching] = useState(false);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleEachDetail = (row, empInfo) => {
    setDetail(row);
    setUser(empInfo);
    setOpenDialog(true);
  };

  const fetchedCompanyByVendor = async () => {
    try {
      setIsFetching(true);
      const response = await getAllCompaniesByVendor();
      if (response && response.data) {
        setIsFetching(false);
        setCompanyLists(response.data);
      } else {
        setIsFetching(false);
        setCompanyLists(null);
      }
    } catch (error) {
      setIsFetching(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchedCompanyByVendor();
  }, []);


  const fetchedEmployeeProbationAnswer = async () => {
    try {
      const response = await getProbationAndAnswer({
        idCompany: selectedCompany.idCompany
      });
      if (response && response.data) {
        setProbationAnswer(response.data);
      }
    } catch (error) {
      console.error(error);
    };
  };

  const fetchedEmployeeProbationStatus = async () => {
    try {
      setIsFetching(true);
      const response = await getEmployeeProbationStatus({
        idCompany: selectedCompany.idCompany,
        year: inputSearch.get("year"),
      });
      if (response && response.data) {
        setIsFetching(false);
        setEmployeesProbationStatus(response.data);
      } else {
        setIsFetching(false);
      };
    } catch (error) {
      console.error(error);
      setIsFetching(false);
    };
  };

  const fetchedAllEmployeesByIdVendorAndIdCompany = async () => {
    try {
      setIsFetching(true);
      const response = await getAllEmployeesByIdVendorAndIdCompany(selectedCompany && selectedCompany.idCompany, userProfile && userProfile.idVendor);
      if (response && response.data) {
        setEmployeesList(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  const handleClickSearch = () => {
    if (selectedCompany) {
      fetchedEmployeeProbationAnswer();
      fetchedEmployeeProbationStatus();
      fetchedAllEmployeesByIdVendorAndIdCompany();
      // dispatch(getAllEmployees(null, selectedCompany.idCompany));
    }
  };

  const handleSubmit = async (formData) => {
    const res = await dispatch(updateProbationAdmin(formData))
    if (res.status === 200) {
      setConfigAlert({
        openAlert: true,
        alertType: "success",
      })
    } else {
      setConfigAlert({
        openAlert: true,
        alertType: "error",
      })
    }
    handleClose();
    // dispatch(
    //   getProbationEmployeesStatus({
    //     idCompany: selectedCompany.idCompany,
    //     year: inputSearch.get("year"),
    //   })
    // );
    fetchedEmployeeProbationStatus();
  }

  const handleCloseAlert = () => {
    setConfigAlert({ openAlert: false, alertType: "" });
  };

  useEffect(() => {
    if (companyLists && companyLists.length > 0) {
      setSelectedCompany(companyLists[0])
    }
  }, [companyLists]);

  return (
    <div className="page">
      <Container>

        <Box marginBottom="24px">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/report-center"}
            >
              {`${t("AllReports")}`}
            </Link>
            <Typography color="text.primary">{`${t(
              "summarizeProbation"
            )}`}</Typography>
          </Breadcrumbs>
        </Box>

        <StyledHeadingPage>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">{t("summarizeProbation")}</Typography>
            </Grid>

          </Grid>
        </StyledHeadingPage>
        <CardStyle style={{ padding: "16px" }}>
          <StyledWrapFilter>
            <Grid container spacing={2} alignItems="end">
              <Grid item xs={12} sm={6} md={3}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("SearchDate")}
                  </Typography>
                  <div className="search-date">
                    <DatePickerCustom
                      inputFormat="YYYY"
                      value={inputSearch}
                      minDate={dayjs().subtract(3, 'year')}
                      maxDate={dayjs()}
                      name="start"
                      views={["year"]}
                      openTo="year"
                      onChange={(newValue) => setInputSearch(newValue)}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("SelectCompany")}
                  </Typography>
                  <SelectAllCompaniesVendor
                    options={companyLists ? companyLists : null}
                    value={selectedCompany}
                    onChange={(_, value) => {
                      onChangeCompany(value);
                    }}
                  />
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} md={4} display="flex" gap="8px" sx={{ mb: 1 }}>
                {isFetching ? (
                  <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <ButtonBlue
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={handleClickSearch}
                    disabled={!selectedCompany}
                  >
                    {t("Search")}
                  </ButtonBlue>
                )}

                <ButtonBlue
                  marginLeft={"auto"}
                  variant="outlined"
                  startIcon={<FileDownloadIcon />}
                  onClick={() => ProbationXlsxFile(employeesProbationStatus, probationAnswer)}
                  disabled={!employeesProbationStatus || employeesProbationStatus && employeesProbationStatus.length < 1}
                >
                  {t("Download")}
                </ButtonBlue>
              </Grid>
            </Grid>
          </StyledWrapFilter>
          {employeesProbationStatus && (
            <TableProbationStatus
              rows={employeesProbationStatus}
              handleEachDetail={handleEachDetail}
              employees={employeesLists ? employeesLists : null}
            />
          )}
        </CardStyle>

        {isFetching && (
          <CardStyle style={{ padding: "16px", marginTop: 20 }}>
            <Typography textAlign="center" color="text.secondary" fontSize="24px">
              {t(`LoadingData`)}...
            </Typography>
          </CardStyle>
        )}

        {openDialog && (
          <DialogProbation
            open={openDialog}
            handleClose={handleClose}
            user={user}
            detail={detail}
            handleSubmit={handleSubmit}
          />
        )}
        {configAlert.openAlert && (
          <AlertResponse
            open={configAlert.openAlert}
            handleClose={handleCloseAlert}
            alertType={configAlert.alertType}
          />
        )}
      </Container>
    </div>
  );
};

export default ProbationStatus;
