import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import { getEmployeesSearchByVendor } from "../../../../actions/employee";

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& .MuiAutocomplete-groupUl": {
      "& .MuiAutocomplete-option": {
        paddingLeft: 16,
        paddingRight: 16
      },
    },
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px !important",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&.Mui-disabled:before, ::before": {
      border: "none",
    },
  },
});

const SelectEmployees = (props) => {
  let { handleChange, data, selectEmployee, disabled, closeIcon } = props;
  const dispatch = useDispatch();
  const { result: employees } = useSelector((state) => state.employees);
  const { t, i18n } = useTranslation();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    console.log("data: ", data);
    if (data) {
      setDataList(data.dataResponse);
    } else {
      dispatch(getEmployeesSearchByVendor());
    }
  }, [data]);

  useEffect(() => {
    if (employees) {
      setDataList(employees);
    }
  }, [employees]);

  return (
    <StyledBoxSearch>
      <Typography className="label" color="text.third">
        {t("SelectEmp")}
      </Typography>
      <StyledAutocomplete
        disabled={disabled}
        options={dataList}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        defaultValue={selectEmployee}
        groupBy={(option) => option.groupBy}
        popupIcon={<i class="fa-light fa-chevron-down"></i>}
        getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.idEmp}>
              {`${option.firstname} ${option.lastname}`}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder={`${t("SelectEmp")}`}
            InputProps={
              closeIcon === true
                ? { ...params.InputProps }
                : { ...params.InputProps, endAdornment: null }
            }
          />
        )}
        PopperComponent={StyledPopper}
        noOptionsText={`${t("No_Data")}`}
      />
    </StyledBoxSearch>
  );
};

export default SelectEmployees;
