import { httpClient } from "./httpClient";


const getdataEmployee = () => {
 
    return httpClient.get(`dataEmployeeExpense`);
  
};


export default {
    getdataEmployee,

};
