import { Grid, Paper, styled, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Controller, useForm } from "react-hook-form";
import {
  getAllExpertiseVendor,
  updateExpertiseVendor,
} from "../../../../actions/expertise";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";

const StyledRoot = styled("div")({
  padding: 24,
});

const StyledPaper = styled(Paper)({
  height: 96,
  borderRadius: 16,
  position: "relative",
  padding: 16,
  boxSizing: "border-box",
  userSelect: "none",
  cursor: "pointer",
  "& .wrap-name": {
    "& .MuiTypography-root": {
      fontSize: 18,
    },
  },
  "& .wrap-icon": {
    position: "absolute",
    fontSize: 35,
    color: "#919eab",
    right: 16,
    bottom: 8,
  },
});

const ExpertItem = (props) => {
  const { value, onClick, isSelected } = props;
  return (
    <StyledPaper
      variant="outlined"
      onClick={onClick}
      style={{ borderColor: isSelected ? "#007afe" : "" }}
    >
      <div className="wrap-name">
        <Typography
          variant="subtitle"
          style={{ color: isSelected ? "#007afe" : "" }}
        >
          {value.expertiseName}
        </Typography>
      </div>
      <div className="wrap-icon">{value.icon}</div>
    </StyledPaper>
  );
};

const DrawerExpertise = (props) => {
  const dispatch = useDispatch();
  const { result: AllExpertises } = useSelector((state) => state.expertises);
  const { result: AllExpertisesVendor } = useSelector(
    (state) => state.expertisesVendor
  );
  const { open, handleClose } = props;
  const { t, i18n } = useTranslation();

  const { control, handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      expertises: [],
      listDelete: [],
    },
  });

  useEffect(() => {
    if (AllExpertisesVendor && AllExpertisesVendor.length > 0) {
      let tempList = [];
      AllExpertisesVendor.map((expertise) => {
        tempList.push({
          idVendorExpertise: expertise.idVendorExpertise,
          idExpertise: expertise.idExpertise,
        });
      });
      setValue("expertises", [...tempList]);
    }
  }, [AllExpertisesVendor]);

  const onSelectExpetise = (idExpertise) => {
    const expertises = getValues("expertises");
    const listDelete = getValues("listDelete");

    let expertiseDeleted = expertises.find(
      (item) => item.idExpertise === idExpertise
    );
    if (expertiseDeleted) {
      setValue(
        "expertises",
        expertises.filter((item) => item.idExpertise !== idExpertise)
      );
      if (expertiseDeleted.idVendorExpertise) {
        setValue("listDelete", [
          ...listDelete,
          {
            idVendorExpertise: expertiseDeleted.idVendorExpertise,
            idExpertise: expertiseDeleted.idExpertise,
          },
        ]);
      }
      return;
    }

    let expertiseSelected = listDelete.find(
      (item) => item.idExpertise === idExpertise
    );
    setValue("expertises", [
      ...expertises,
      {
        idVendorExpertise: expertiseSelected
          ? expertiseSelected.idVendorExpertise
          : null,
        idExpertise: expertiseSelected
          ? expertiseSelected.idExpertise
          : idExpertise,
      },
    ]);
    setValue(
      "listDelete",
      listDelete.filter((item) => item.idExpertise !== idExpertise)
    );
    return;
  };

  const onSubmit = async (data) => {
    console.log(data);
    const result = await dispatch(updateExpertiseVendor(data));
    if (result) {
      dispatch(getAllExpertiseVendor());
      handleClose();
    }
  };

  return (
    <DrawerCustom
      title={t("Expertise")}
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { maxWidth: 550, width: "100%" } }}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {AllExpertises.map((item) => (
              <Grid key={`expertise_${item.idExpertise}`} item xs={4}>
                <Controller
                  control={control}
                  name="expertises"
                  render={() => (
                    <ExpertItem
                      value={item}
                      onClick={() => {
                        onSelectExpetise(item.idExpertise);
                      }}
                      isSelected={
                        getValues("expertises").find(
                          (v) => v.idExpertise === item.idExpertise
                        )
                          ? true
                          : false
                      }
                    />
                  )}
                />
              </Grid>
            ))}
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="outlined" onClick={handleClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue variant="contained" type="submit">
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerExpertise;
