import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import "dayjs/locale/th";

import {
  Button,
  IconButton,
  Container,
  Typography,
  CardContent,
  ToggleButtonGroup,
  ToggleButton,
  TablePagination,
  TextField,
  Grid,
  Popper,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  FormHelperText,
  Menu,
  MenuItem,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Dropzone, { useDropzone } from "react-dropzone";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import GalleryIcon from "../../../../assets/gallery.png";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../../shared/general/AlertResponse";
import Backdrop from "../../../../shared/general/Backdrop";

import RowItemExpense from "../../create/mileage/row-mileage";
import ConfirmDialog from "../../confirm-dialog";
import SelectApprover from "../../select-approver";
import ActionButtons from "../action-buttons";
import Comments from "../../comments";
import Actions from "../../actions";
import utils from "../../../../../../utils";
import SelectCostCenter from "../../select-costCenter";
import {
  getExpenseMileageById,
  updateExpenseMileage,
  deleteExpenseMileage,
} from "../../../../../../actions/expense";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.date-document": {
    width: 120,
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 40,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const rowHeader = [
  {
    caption: "",
    className: "delete",
  },
  {
    caption: "DateAt",
    className: "date-start-end",
  },
  {
    caption: "StartLocation",
    className: "description",
  },
  {
    caption: "StopLocation",
    className: "description",
  },
  {
    caption: "StartMile",
    className: "amount",
  },
  {
    caption: "StopMile",
    className: "amount",
  },
  {
    caption: "TotalDistance",
    className: "amount",
  },
  {
    caption: "PersonalUseage",
    className: "amount",
  },
  {
    caption: "NetDistance",
    className: "amount",
  },
  {
    caption: "",
    className: "delete",
  },
];

const rowHeaderGas = [
  {
    caption: "",
    className: "delete",
  },
  {
    caption: "DocumentDate",
    className: "date-start-end",
  },
  {
    caption: "สถานที่เติม",
    className: "description",
  },
  {
    caption: "จำนวนเงิน (รวม VAT) ",
    className: "amount",
  },
  {
    caption: "",
    className: "delete",
  },
];

const Mileage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const today = dayjs().toDate();
  const { typeExpense, typeEdit, idExpense } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expenseDetail } = useSelector((state) => state.expenseDetail);
  const { result: employees } = useSelector((state) => state.employees);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [listMenuTaxOrWithholding, setListMenuTaxOrWithholding] =
    React.useState(null);
  const open = Boolean(anchorEl);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [deletedRow, setDeletedRow] = useState([]);
  const [isAPIpending, setIsAPIpending] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);
  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });
  const dataInitial = {
    idExpenseMileageItem: null,
    date: dayjs(today).format("YYYY/MM/DD"),
    startLocation: "",
    stopLocation: "",
    startMile: 0,
    stopMile: 0,
    total: 0,
    personal: 0,
    net: 0,
  };
  const [formData, setFormData] = useState({
    nameExpense: "",
    approver: {},
    listExpense: [dataInitial],
    file: null,
    remark: "",
    typeExpenseName: typeExpense,
    status: "",
    totalDistance: 0,
    personalDistance: 0,
    netDistance: 0,
    net: 0,
    replyComment: null,
  });
  const rate = 5;
  const MAX_FILE_SIZE = 512000; //500KB

  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const validationSchema = Yup.object().shape({
    nameExpense: Yup.string().required("โปรดกรอกข้อมูล"),
    approver: Yup.string().required("โปรดกรอกข้อมูล"),
    listExpense: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required("โปรดกรอกข้อมูล"),
        startLocation: Yup.string().required("โปรดกรอกข้อมูล"),
        stopLocation: Yup.string().required("โปรดกรอกข้อมูล"),
        startMile: Yup.string().required("โปรดกรอกข้อมูล"),
        stopMile: Yup.string().required("โปรดกรอกข้อมูล"),
        total: Yup.string().required("โปรดกรอกข้อมูล"),
        personal: Yup.string().required("โปรดกรอกข้อมูล"),
        net: Yup.string().required("โปรดกรอกข้อมูล"),
      })
    ),
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (value) {
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
    remark: Yup.string(),
    typeExpenseName: Yup.string().default(typeExpense),
    status: Yup.string(),
    totalDistance: Yup.string(),
    personalDistance: Yup.string(),
    netDistance: Yup.string(),
    net: Yup.string(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const { append, remove, fields } = useFieldArray({
    control: control,
    name: "listExpense",
  });

  const checkValidation = (error) => {
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: "โปรดกรอกข้อมูล",
      type: "error",
    });
  };

  useEffect(() => {
    if (userProfile) {
      dispatch(getExpenseMileageById(idExpense));
    }
  }, [userProfile]);

  useEffect(() => {
    if (expenseDetail) {
      reset(expenseDetail);
      setValue("approver", expenseDetail.idEmpApprover);
    }
  }, [expenseDetail]);

  // useEffect(() => {
  //   if (expenseDetail && expenseDetail.idEmpApprover && employees) {
  //     if (expenseDetail.idEmpApprover === null) {
  //       setValue("approver", {});
  //     } else {
  //       setValue(
  //         "approver",
  //         employees[
  //           employees.findIndex(
  //             (item) => item.idEmployees === expenseDetail.idEmpApprover
  //           )
  //         ]
  //       );
  //     }
  //   }
  // }, [expenseDetail, employees]);

  useEffect(() => {
    if (expenseDetail && getValues("listExpense")) {
      calculateSum(getValues("listExpense"));
    }
  }, [watch("listExpense.length")]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const thumbs = fileSelected.map((file) => (
    <img key={file.name} src={file.preview} />
  ));

  const calculateSum = (array) => {
    let sumDistance = array.reduce((accumulator, object) => {
      return accumulator + parseInt(object.total);
    }, 0);
    let sumPersonal = array.reduce((accumulator, object) => {
      return accumulator + parseInt(object.personal);
    }, 0);
    let sumWork = array.reduce((accumulator, object) => {
      return accumulator + parseInt(object.net);
    }, 0);

    setValue("totalDistance", sumDistance);
    setValue("personalDistance", sumPersonal);
    setValue("netDistance", sumWork);
    setValue("net", parseInt(sumWork) * rate);
  };

  const handleIsNan = (value) => {
    return isNaN(value) ? 0 : value;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeMile = (item) => {
    let newTotal =
      parseInt(getValues(`${item}.stopMile`)) -
      parseInt(getValues(`${item}.startMile`));
    setValue(`${item}.total`, newTotal > 0 ? handleIsNan(newTotal) : 0);
    setValue(
      `${item}.net`,
      handleIsNan(parseInt(newTotal) - parseInt(getValues(`${item}.personal`)))
    );
    calculateSum(getValues("listExpense"));
  };

  const addDeleteRow = (index) => {
    if (getValues(`listExpense.${index}.idExpenseMileageItem`) !== null) {
      setDeletedRow((prevArray) => [
        ...prevArray,
        getValues(`listExpense.${index}.idExpenseMileageItem`),
      ]);
    }
  };

  const createFormData = (statusId) => {
    let data = new FormData();
    data.append("idExpense", expenseDetail.idExpense);
    data.append("idExpenseMileage", expenseDetail.idExpenseMileage);
    data.append("documentId", expenseDetail.documentId);
    data.append("nameExpense", getValues("nameExpense"));
    data.append("listExpense", JSON.stringify(getValues("listExpense")));
    if (watch("file")) {
      data.append("file", getValues("file"), getValues("file").path);
    }
    data.append("remark", getValues("remark"));
    data.append("typeExpense", 3);
    data.append("typeExpenseName", getValues("typeExpenseName"));
    data.append("lastUpdateDate", dayjs(today).format("YYYY/MM/DD HH:mm"));
    data.append("status", statusId);
    data.append("totalDistance", getValues("totalDistance"));
    data.append("personalDistance", getValues("personalDistance"));
    data.append("netDistance", getValues("netDistance"));
    data.append("net", getValues("net"));
    data.append(
      "comment",
      getValues("replyComment")
        ? getValues("replyComment")
        : JSON.stringify(null)
    );
    data.append("deletedItem", JSON.stringify(deletedRow));
    data.append("idEmpApprover", getValues("approver"));
    data.append("costCenter", getValues("costCenter"));
    return data;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOnClickCleardata = async () => {
    setIsAPIpending(true);
    let listIdExpenseMileageItem = expenseDetail.listExpense.map(
      (item, index) => {
        return item.idExpenseMileageItem;
      }
    );
    let isAttachFile = false;
    let filePath = null;
    if (expenseDetail.fileURL) {
      isAttachFile = true;
      filePath = expenseDetail.fileURL.path;
    }
    let data = {
      idExpense: expenseDetail.idExpense,
      idExpenseMileage: expenseDetail.idExpenseMileage,
      listExpense: listIdExpenseMileageItem,
      isAttachFile: isAttachFile,
      filePath: filePath,
    };
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmp) {
      result = await dispatch(deleteExpenseMileage(userProfile.idEmp, data));
    }
    if (result.status === 200) {
      setIsAPIpending(false);
      handleChangeAlertType("success");
      handleOpenAlert();
      history.push(`/expense`);
    } else {
      setIsAPIpending(false);
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  const handleOnClickSavedraft = async () => {
    setIsAPIpending(true);
    let data = createFormData(1);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmp) {
      result = await dispatch(updateExpenseMileage(userProfile.idEmp, data));
    }
    if (result) {
      if (result.status === 200) {
        setIsAPIpending(false);
        handleChangeAlertType("success");
        handleOpenAlert();
        dispatch(getExpenseMileageById(idExpense));
      } else {
        setIsAPIpending(false);
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  const handleOnClickSent = async () => {
    setIsAPIpending(true);
    let status = 2;
    if (typeEdit === "Edit") {
      status = 3;
    }
    let data = createFormData(status);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmp) {
      result = await dispatch(updateExpenseMileage(userProfile.idEmp, data));
    }
    if (result.status === 200) {
      setIsAPIpending(false);
      handleChangeAlertType("success");
      handleOpenAlert();
      history.push(`/expense`);
    } else {
      setIsAPIpending(false);
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  return (
    <div>
      {expenseDetail ? (
        <Box>
          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
          />
          <form
            onSubmit={handleSubmit(handleOnClickSavedraft, checkValidation)}
          >
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("GeneralInformation")}
                  <Typography variant="subtitle" color="text.secondary">
                    &nbsp;({t(`${typeEdit}`)})
                  </Typography>
                </StyledHeadLabel>
              </div>
              <div>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("Title")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={errors && errors.nameExpense ? true : false}
                >
                  {errors &&
                    errors.nameExpense &&
                    errors.nameExpense.message && (
                      <FormHelperText error>
                        {errors.nameExpense.message}
                      </FormHelperText>
                    )}
                  <Controller
                    name="nameExpense"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <TextFieldTheme
                          {...field}
                          name="nameExpense"
                          inputProps={{ maxLength: 200 }}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                        <Typography
                          component="p"
                          textAlign="right"
                          variant="caption"
                          color="text.secondary"
                        >{`${field.value.length}/200`}</Typography>
                      </>
                    )}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl
                  fullWidth
                  error={errors && errors.costCenter ? true : false}
                >
                  {errors && errors.costCenter && errors.costCenter.message && (
                    <FormHelperText error>
                      {errors.costCenter.message}
                    </FormHelperText>
                  )}
                  <SelectCostCenter
                    handleChange={setValue}
                    control={control}
                    errors={errors}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl
                  fullWidth
                  error={errors && errors.approver ? true : false}
                >
                  {errors && errors.approver && errors.approver.message && (
                    <FormHelperText error>
                      {errors.approver.message}
                    </FormHelperText>
                  )}
                  <Controller
                    name="approver"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <SelectApprover
                          {...field}
                          idApprover={getValues("approver")}
                          handleChange={setValue}
                          disabled={typeEdit === "Edit"}
                        />
                      </>
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("LocationList")}
                </StyledHeadLabel>
                <div>
                  <ButtonBlue
                    size="small"
                    startIcon={<AddRoundedIcon />}
                    variant="outlined"
                    // onClick={handleAddRow}
                    onClick={() => {
                      append(dataInitial);
                      calculateSum(getValues("listExpense"));
                    }}
                  >
                    {t("Add")}
                  </ButtonBlue>
                </div>
              </div>

              <TableContainer>
                <Table
                  style={{
                    minWidth: 650,
                    borderSpacing: "0 4px",
                    borderCollapse: "separate",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      {rowHeader.map((item) => (
                        <StyledTableCell className={item.className}>
                          <StyledHeadLabel
                            variant="body2"
                            color="text.third"
                            gutterBottom
                          >
                            {t(`${item.caption}`)}
                          </StyledHeadLabel>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields &&
                      fields.length > 0 &&
                      fields
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => (
                          <RowItemExpense
                            key={item.id}
                            count={fields.length}
                            index={index}
                            type="edit"
                            handleDeleteRow={remove}
                            addDeleteRow={addDeleteRow}
                            handleChangeMile={handleChangeMile}
                            control={control}
                            errors={errors}
                          />
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[
                  10,
                  20,
                  30,
                  40,
                  50,
                  { label: "ทั้งหมด", value: -1 },
                ]}
                component="div"
                count={watch("listExpense.length")}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">สรุปรายการ</StyledHeadLabel>
              </div>
              <div>
                <div className="wrap-row-sum">
                  <Typography color="text.third">
                    {t("TotalDistance")}&nbsp;&nbsp;
                  </Typography>
                  <Typography color="text.third" variant="h6">
                    {utils.numberWithCommas(watch("totalDistance"))}
                  </Typography>
                  <Typography color="text.third">
                    &nbsp;&nbsp;{t("Km")}.
                  </Typography>
                </div>
                <div className="wrap-row-sum">
                  <Typography color="text.third">
                    {t("PersonalUseage")} &nbsp;&nbsp;
                  </Typography>
                  <Typography color="text.third" variant="h6">
                    {utils.numberWithCommas(watch("personalDistance"))}
                  </Typography>
                  <Typography color="text.third">
                    &nbsp;&nbsp;{t("Km")}.
                  </Typography>
                </div>
                <div className="wrap-row-sum">
                  <Typography color="text.third">
                    {t("WorkingUseage")}&nbsp;&nbsp;
                  </Typography>
                  <Typography color="text.third" variant="h6">
                    {utils.numberWithCommas(watch("netDistance"))}
                  </Typography>
                  <Typography color="text.third">
                    &nbsp;&nbsp;{t("Km")}.
                  </Typography>
                </div>
                <div className="wrap-row-sum">
                  <Typography variant="h5">
                    {t("ExpenseNet")}&nbsp;&nbsp;
                  </Typography>
                  <Typography variant="h4">
                    {utils.numberWithCommas(watch("net"))}
                  </Typography>
                  <Typography variant="h5">&nbsp;&nbsp;{t("Baht")}</Typography>
                </div>
              </div>
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("AttachFile")}
                </StyledHeadLabel>
              </div>
              {!watch("file") && !watch("fileURL") ? (
                <StyledDropzone>
                  <Dropzone
                    accept="image/jpeg, image/png, application/pdf"
                    maxFiles={1}
                    multiple={false}
                    maxSize={3145728}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setValue("file", acceptedFiles[0]);
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({
                          className: "dropzone-upload-file",
                        })}
                      >
                        <div className="inner-dropzone">
                          <input {...getInputProps()} />
                          {/* <Fragment>{thumbs}</Fragment> */}
                          <div style={{ textAlign: "center" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img alt="add" src={GalleryIcon} />
                            </div>
                            <Typography
                              variant="caption"
                              style={{ lineHeight: 0.5, color: "#999999" }}
                            >
                              ไฟล์ *.jpeg, *.jpg, *.png จำนวน 1 ไฟล์ ขนาดไม่เกิน
                              500 KB
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </StyledDropzone>
              ) : !watch("fileURL") && watch("file") ? (
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{ position: "absolute", margin: 7, right: 0 }}
                    color="error"
                    onClick={() => {
                      setValue("file", null);
                      setValue("fileURL", null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <div
                    style={{
                      padding: 15,
                      backgroundColor: "#f9f9fb",
                      display: "flex",
                      borderRadius: 15,
                    }}
                  >
                    <FilePresentIcon
                      height="24"
                      style={{
                        marginRight: 10,
                      }}
                    ></FilePresentIcon>
                    <Typography>{watch("file.path")}</Typography>
                  </div>
                </Box>
              ) : (
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  <IconButton
                    style={{ position: "absolute", margin: 7, right: 0 }}
                    color="error"
                    onClick={() => {
                      setValue("file", null);
                      setValue("fileURL", null);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <div
                    style={{
                      padding: 15,
                      backgroundColor: "#f9f9fb",
                      display: "flex",
                      borderRadius: 15,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      expenseDetail.fileURL &&
                        window.open(`${expenseDetail.fileURL.URL}`, "_blank");
                    }}
                  >
                    <FilePresentIcon
                      height="24"
                      style={{
                        marginRight: 10,
                      }}
                    ></FilePresentIcon>
                    {expenseDetail &&
                      expenseDetail.fileURL &&
                      expenseDetail.fileURL.path && (
                        <Typography>{expenseDetail.fileURL.path}</Typography>
                      )}
                  </div>
                </Box>
              )}
            </div>
            <StyledDivider />
            <div>
              <div className="wrap-head">
                <StyledHeadLabel variant="h6">
                  {t("RemarkMore")}
                </StyledHeadLabel>
              </div>
              <Controller
                name={"remark"}
                control={control}
                render={({ field }) => (
                  <>
                    <TextFieldTheme {...field} multiline rows={4} />
                    <Typography
                      component="p"
                      textAlign="right"
                      variant="caption"
                      color="text.secondary"
                    >{`${
                      field.value ? field.value.length : 0
                    }/500`}</Typography>
                  </>
                )}
              />
            </div>
            {typeEdit &&
            typeEdit === "Edit" &&
            expenseDetail &&
            expenseDetail.comments &&
            expenseDetail.comments.length &&
            expenseDetail.comments.length > 0 ? (
              <div>
                <Comments comments={expenseDetail.comments} />
                <StyledDivider />
                <FormControl
                  fullWidth
                  error={errors && errors.replyComment ? true : false}
                >
                  {errors &&
                    errors.replyComment &&
                    errors.replyComment.message && (
                      <FormHelperText error>
                        {errors.replyComment.message}
                      </FormHelperText>
                    )}
                  <StyledHeadLabel variant="h6">
                    {t("ReplyComment")}
                  </StyledHeadLabel>
                  <Controller
                    name="replyComment"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <TextFieldTheme
                          {...field}
                          name="replyComment"
                          sx={{ marginTop: 1 }}
                          multiline
                          rows={2}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                        />
                        {/* <Typography
                        component="p"
                        textAlign="right"
                        variant="caption"
                        color="text.secondary"
                      >{`${field.value.length}/500`}</Typography> */}
                      </>
                    )}
                  />
                </FormControl>
              </div>
            ) : (
              <div></div>
            )}
            <StyledDivider />
            {expenseDetail.actions &&
            expenseDetail.actions.length &&
            expenseDetail.actions.length > 0 ? (
              <Actions actions={expenseDetail.actions} />
            ) : (
              <span></span>
            )}

            <ActionButtons
              typeEdit={typeEdit}
              handleOnClickCleardata={handleOnClickCleardata}
              handleSubmitDraft={handleSubmit(
                handleOnClickSavedraft,
                checkValidation
              )}
              handleSubmitSent={handleSubmit(
                handleOnClickSent,
                checkValidation
              )}
              isAPIpending={isAPIpending}
            />
          </form>
        </Box>
      ) : (
        <Backdrop open={true} />
      )}
    </div>
  );
};

export default Mileage;
