import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import UserProfile from "../../user/profile";
import ManagerProfile from "../../manager/profile";

import Backdrop from "../general/Backdrop";

const Profilepage = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const render = () => {
    if (currentUser.roles.includes("ROLE_USER")) {
      return <UserProfile />;
    } else if (currentUser.roles.includes("ROLE_MANAGER")) {
      return <ManagerProfile />;
    }
  };

  return (
    <div>
      {currentUser && userProfile ? (
        <Fragment>{render()}</Fragment>
      ) : (
        <Fragment>
          <Backdrop open={!(currentUser && userProfile)} />
        </Fragment>
      )}
    </div>
  );
};

export default Profilepage;
