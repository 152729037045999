import React, { useEffect, useState } from "react";
import { Grid, MenuItem, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";

import { Controller, useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import { getAllBank } from "../../../../actions/bank";
import { getCompanyProfile, putCompanyBankDetail } from "../../../../actions/company";


const StyledRoot = styled("div")({
  padding: 24,
})

const DrawerEditBank = (props) => {

  const dispatch = useDispatch();
  const { result: bankStore } = useSelector((state) => state.bank);
  const { open, handleClose, data } = props;

  const [banks, setBanks] = useState([]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      idBank: data.bankDetail.idBank || "",
      // bankName: data.bankDetail.bankName || "",
      accountName: data.bankDetail.accountName || "",
      accountNo: data.bankDetail.accountNo || "",
      branchName: data.bankDetail.branchName || "",
      branchAddress: data.bankDetail.branchAddress || "",
    }
  })

  const onSubmit = async (formData) => {
    const response = await dispatch(putCompanyBankDetail(1, data.idBankDetail, formData));
    if (response.status === 200){
      dispatch(getCompanyProfile(1));
      handleClose();
    }
  }

  useEffect(() => {
    dispatch(getAllBank());
  }, [])

  useEffect(() => {
    if(bankStore != null){
      console.log(bankStore);
      setBanks([...bankStore]);
    }
  }, [bankStore])



  return (
    <DrawerCustom
      title="Bank"
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{style: {maxWidth: 550, width: "100%"}}}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="idBank"
                render={({field}) => <TextFieldTheme {...field} label="Bank" select>
                  {banks.map((item, i) => (
                    <MenuItem key={`bank_${item.idBank}`} value={item.idBank}>{item.bankName}</MenuItem>
                  ))}
                </TextFieldTheme>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="accountName"
                render={({field}) => <TextFieldTheme {...field} label="Account Name"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="accountNo"
                render={({field}) => <TextFieldTheme {...field} label="Account Number"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="branchName"
                render={({field}) => <TextFieldTheme {...field} label="Branch Name"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="branchAddress"
                render={({field}) => <TextFieldTheme {...field} label="Branch Address" multiline rows={3}/>}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="flex-end">
              <ButtonBlue style={{marginRight: 8}} onClick={handleClose}>
                ยกเลิก
              </ButtonBlue>
              <ButtonBlue variant="contained" type="submit">
                บันทึกข้อมูล
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEditBank;