import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Divider,
  TextField,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import CloseIcon from "@mui/icons-material/Close";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5),
    width: "95%",
    maxHeight: 800,
  },
  dialogTitle: {
    color: "#16243D",
    padding: theme.spacing(1),
  },
  dialogContent: {
    padding: "16px 8px 24px 8px",
  },
  dialogActions: {
    marginTop: theme.spacing(1),
  },
  deleteBtn: {
    borderRadius: "8px",
    color: "#fff",
    backgroundColor: "#e53935",
    "&:hover": {
      backgroundColor: "#c62828",
    },
  },
}));

function InfoEvent(props) {
  const { infoModal, setInfoModal, onPutHoliday, onDeleteHoliday, fetchData } =
    props;
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);
  const [idHoliday, setIdHoliday] = useState(0);
  const [values, setValues] = useState([]);
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const handleOnEdit = () => {
    setDisabled(false);
  };

  const handleOnDelete = (e) => {
    e.preventDefault();
    const formData = {
      idHoliday: idHoliday,
    };

    //Delete Holiday
    onDeleteHoliday(formData.idHoliday, formData);
    setInfoModal({ ...infoModal, isOpen: false });
    fetchData();
  };

  const handleOnSaveChanges = (e) => {
    e.preventDefault();
    const formData = {
      idHoliday: idHoliday,
      name: values.title,
      dateHoliday: dayjs(dateStart).format("YYYY-MM-DD"),
      holidayYear: dayjs(dateStart).format("YYYY"),
    };

    //Update Holiday
    onPutHoliday(formData.idHoliday, formData);
    setInfoModal({ ...infoModal, isOpen: false });
    fetchData();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    if (infoModal.isOpen) {
      console.log("infoModal", infoModal.event);
      setDisabled(true);
      setIdHoliday(parseInt(infoModal.event.id));
      setValues(infoModal.event);
      setDateStart(infoModal.event.start);
      setDateEnd(
        infoModal.event.end ? infoModal.event.end : infoModal.event.start
      );
    }
  }, [infoModal]);

  return (
    <Dialog open={infoModal.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              Info Event
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={() => setInfoModal({ ...infoModal, isOpen: false })}
            >
              <CloseIcon className={classes.closeBtn} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Grid container sx={{ marginBottom: 1 }}>
          <Grid item xs={4} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              Type
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} lg={8}>
            <TextField
              variant="outlined"
              value={"วันหยุดนักขัตฤกษ์"}
              disabled
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container sx={{ marginBottom: 1 }}>
          <Grid item xs={4} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              Title
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} lg={8}>
            <TextField
              variant="outlined"
              name="title"
              value={values.title}
              onChange={handleInputChange}
              disabled={disabled}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container sx={{ marginBottom: 1 }}>
          <Grid item xs={4} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              Start
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} lg={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="dateStart"
                inputFormat="dd/MM/yyyy"
                value={dateStart}
                disabled={disabled}
                onChange={(date) => {
                  setDateStart(date);
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder="DD/MM/YYYY"
                    format="dd/MM/yyyy"
                    margin="dense"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container sx={{ marginBottom: 1 }}>
          <Grid item xs={4} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              End
            </Typography>
          </Grid>
          <Grid item xs={8} md={8} lg={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="dateEnd"
                inputFormat="dd/MM/yyyy"
                value={dateEnd}
                disabled={disabled}
                onChange={(date) => {
                  setDateEnd(date);
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder="DD/MM/YYYY"
                    format="dd/MM/yyyy"
                    margin="dense"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {disabled ? (
          <>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <ButtonBlue variant={"outlined"} onClick={handleOnEdit}>
                  Edit
                </ButtonBlue>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.deleteBtn}
                  onClick={handleOnDelete}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <ButtonBlue variant={"contained"} onClick={handleOnSaveChanges}>
                  Save Changes
                </ButtonBlue>
              </Grid>
            </Grid>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default InfoEvent;
