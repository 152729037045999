import { httpClient } from "./httpClient";

const getTaxDeduction = () => {
    return httpClient.get("tax-deduction");
};

const addTaxDeduction = (data) => {
    return httpClient.post("tax-deduction/add",data);
};

const updateTaxDeduction = (data) => {
    return httpClient.put("tax-deduction/update",data);
};

const getTaxDeductionSchedule = () => {
    return httpClient.get("tax-deduction/schedule");
};

const addTaxDeductionSchedule = (data) => {
    return httpClient.post("tax-deduction/schedule/add",data);
};

const updateTaxDeductionSchedule = (data) => {
    return httpClient.put("tax-deduction/schedule/update",data);
};

const disableTaxDeductionSchedule = (idTaxDeductionSchedule) => {
    return httpClient.put("tax-deduction/schedule/disable/"+idTaxDeductionSchedule);
};

export default {
    getTaxDeduction,
    addTaxDeduction,
    updateTaxDeduction,
    getTaxDeductionSchedule,
    addTaxDeductionSchedule,
    updateTaxDeductionSchedule,
    disableTaxDeductionSchedule
};