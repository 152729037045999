import { Grid, styled } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";

import {
  addNewVendorBranch,
  getAllBranchesByVendor,
  updateVendorBranch,
} from "../../../../actions/vendor";

const StyledRoot = styled("div")({
  width: 450,
  padding: 16,
  "& i": {
    marginRight: 8,
    fontSize: 14,
  },
  "& .part-action": {
    paddingTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .cancel-button": {
      color: "#9e9e9e",
      borderColor: "#9e9e9e",
    },
  },
});

const DrawerBranchAddress = (props) => {
  const { open, onClose, isEdit, value } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...(!isEdit && {
        branchName: "",
        address: "",
        district: "",
        province: "",
        areaCode: "",
        branchCode: "",
        // country: "",
        mainContactName: "",
        mainContactPhone: "",
        mainContactEmail: "",
      }),
      ...(isEdit && {
        idVendorBranch: (value && value.idVendorBranch) || null,
        idVendorAddress: (value && value.idVendorAddress) || null,
        branchName: (value && value.branchName) || "",
        address: (value && value.address) || "",
        district: (value && value.district) || "",
        province: (value && value.province) || "",
        areaCode: (value && value.areaCode) || "",
        branchCode: (value && value.branchCode) || "",
        // country: (value && value.country) || "",
        mainContactName: (value && value.mainContactName) || "",
        mainContactPhone: (value && value.mainContactPhone) || "",
        mainContactEmail: (value && value.mainContactEmail) || "",
      }),
    },
  });

  const onSubmit = async (formData) => {
    let result;
    if (isEdit) {
      result = await dispatch(updateVendorBranch(formData));
    } else {
      result = await dispatch(addNewVendorBranch(formData));
    }
    if (result) {
      dispatch(getAllBranchesByVendor());
      onClose();
    }
  };

  return (
    <DrawerCustom
      open={open}
      title={isEdit ? `${t("EditBranch")}` : `${t("AddBranch")}`}
      anchor="right"
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="branchCode"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("BranchCode")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    ref={field.ref()}
                    label={t("BranchCode")}
                    helperText={
                      errors && errors.branchCode && errors.branchCode.message
                    }
                    error={errors && errors.branchCode ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="branchName"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("BranchName")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    ref={field.ref()}
                    label={t("BranchName")}
                    helperText={
                      errors && errors.branchName && errors.branchName.message
                    }
                    error={errors && errors.branchName ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="address"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("Address")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    ref={field.ref()}
                    label={t("Address")}
                    multiline
                    rows={3}
                    helperText={
                      errors && errors.address && errors.address.message
                    }
                    error={errors && errors.address ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="district"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("District")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    ref={field.ref()}
                    label={t("District")}
                    helperText={
                      errors && errors.district && errors.district.message
                    }
                    error={errors && errors.district ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="province"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("Province")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    ref={field.ref()}
                    label={t("Province")}
                    helperText={
                      errors && errors.province && errors.province.message
                    }
                    error={errors && errors.province ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="areaCode"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("PostalCode")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    ref={field.ref()}
                    label={t("PostalCode")}
                    helperText={
                      errors && errors.areaCode && errors.areaCode.message
                    }
                    error={errors && errors.areaCode ? true : false}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <Controller
                control={control}
                name="country"
                rules={{
                  required: {value: true, message: "กรุณากรอกประเทศ"}
                }}
                render={({field})=>(
                  <TextFieldTheme
                    {...field}
                    ref={field.ref()}
                    label="ประเทศ"
                    helperText={errors && errors.country && errors.country.message}
                    error={errors && errors.country ? true: false}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Controller
                control={control}
                name="mainContactName"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("ContactName")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    ref={field.ref()}
                    label={t("ContactName")}
                    helperText={
                      errors &&
                      errors.mainContactName &&
                      errors.mainContactName.message
                    }
                    error={errors && errors.mainContactName ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="mainContactPhone"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("PhoneNumber")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    ref={field.ref()}
                    label={t("PhoneNumber")}
                    helperText={
                      errors &&
                      errors.mainContactPhone &&
                      errors.mainContactPhone.message
                    }
                    error={errors && errors.mainContactPhone ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="mainContactEmail"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("Email")}`,
                  },
                  pattern: {
                    value: /^[A-Za-z0-9]+@[a-z]+\.[A-Za-z]{2,3}$/,
                    message: `${t(
                      "PleaseEnterValidEmail"
                    )} 'antjob@example.com'`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    ref={field.ref()}
                    label={t("Email")}
                    helperText={
                      errors &&
                      errors.mainContactEmail &&
                      errors.mainContactEmail.message
                    }
                    error={errors && errors.mainContactEmail ? true : false}
                  />
                )}
              />
            </Grid>
          </Grid>

          <div className="part-action">
            <ButtonBlue
              className="cancel-button"
              variant="outlined"
              onClick={onClose}
            >
              <i className="fa-regular fa-x"></i>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue type="submit" variant="contained">
              <i className="fa-regular fa-check"></i>
              {isEdit ? `${t("EditBranch")}` : `${t("AddBranch")}`}
            </ButtonBlue>
          </div>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerBranchAddress;
