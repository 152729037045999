import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";

import StyledCard from "../../shared/general/CardCharts";
import { Box, Typography } from "@mui/material";

const StyledAvatar = styled(Avatar)({
  width: 50,
  height: 50,
  marginRight: 12,
});

const StyledStack = styled(Stack)({
  padding: 24,
  justifyContent: "space-between",
});

const StyledWrapPerson = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  "& .left": {
    display: "flex",
    alignItems: "center",
  },
  "& .right": {
    display: "flex",
    alignItems: "baseline",
    "& .MuiTypography-h4": {
      marginRight: 4,
    },
  },
});

export default function CardRankOTOver36(props) {
  const { name, unit, topAbsents, topLateWorks } = props;
  const [dataArray, setDataArray] = useState([]);

  useEffect(() => {
    if (topAbsents) {
      let dataFilter = topAbsents.filter((x) => x.totalAbsent > 0);
      setDataArray(dataFilter);
    }
  }, [topAbsents]);

  useEffect(() => {
    if (topLateWorks) {
      let dataFilter = topLateWorks.filter((x) => x.totalLate > 0);
      setDataArray(dataFilter);
    }
  }, [topLateWorks]);

  return (
    <StyledCard>
      <CardContent style={{ paddingBottom: 0 }}>
        <div>
          <Typography variant="h4" className={`cardTitle`} gutterBottom>
            {name}
          </Typography>
          {dataArray && dataArray.length > 0 ? (
            dataArray.map((item) => (
              <StyledStack
                direction="column"
                divider={
                  <Divider flexItem style={{ borderColor: "#b5b5b533" }} />
                }
                spacing={2}
              >
                <StyledWrapPerson>
                  <div className={`left`}>
                    <StyledAvatar
                      alt={"username"}
                      src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                    />
                    <div>
                      <Typography variant="h6">{`${item.name} ${item.lastname}`}</Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ fontStyle: "oblique" }}
                      >
                        {item.positionsName}
                      </Typography>
                    </div>
                  </div>
                  <div className={`right`}>
                    {topAbsents && (
                      <Typography variant="h4">
                        {item.totalAbsent}
                      </Typography>
                    )}
                    {topLateWorks && (
                      <Typography variant="h4">
                        {item.totalLate}
                      </Typography>
                    )}
                    <Typography variant="body2">{unit}</Typography>
                  </div>
                </StyledWrapPerson>
              </StyledStack>
            ))
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  padding: "10px 40px 10px 40px",
                  borderRadius: "10px",
                  marginTop: "20px",
                  marginBottom: "40px",
                }}
              >
                <Typography variant="body2">ไม่มีข้อมูล</Typography>
              </Box>
            </Box>
          )}
        </div>
      </CardContent>
    </StyledCard>
  );
}
