import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { Typography, Alert, MenuItem, Stack } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useHistory } from "react-router-dom";
import { getAllCostcenterListByMonth } from "../../../../actions/payruns-export";
import { useTranslation } from "react-i18next";
import { FunctionListMonthXlsx } from "./xlsx-export/payroll-function-xlsx";
import { getAllCompaniesByVendor } from "../../../../actions/vendor";
import { SelectAllCompaniesVendor } from "./SelectCompanyLists";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const PayrollByFunction = (props) => {
  const { open, handleClose } = props;
  const { control } = useForm({});
  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear();

  const [noData, setNoData] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [companyLists, setCompanyLists] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [costcenterList, setCostcenterList] = useState(null);

  const handleOpenAlertError = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedType(null);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  const fetchedCompanyByVendor = async () => {
    try {
      const response = await getAllCompaniesByVendor();
      if (response && response.data) {
        setCompanyLists(response.data);
      } else {
        setCompanyLists(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchedCompanyByVendor();
  }, []);

  const fetchedData = async () => {
    try {
      setIsFetching(true);
      const response = await getAllCostcenterListByMonth({
        idCompany: selectedCompany && selectedCompany.idCompany,
        selectedYear: selectedYear - 543,
        selectedMonth: selectedMonth
      });
      if (response && response.data && response.data.length > 0) {
        setNoData(false);
        setIsFetching(false);
        setCostcenterList(response.data);
      } else {
        setNoData(true);
        setIsFetching(false);
        handleOpenAlertError();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedCompany && selectedYear && selectedMonth && selectedType) {
      fetchedData();
    } else {
      setIsFetching(false);
    }
  }, [selectedYear, selectedMonth, selectedCompany, selectedType]);

  const handleFileExport = () => {
    // console.log(costcenterList);
    if (costcenterList && costcenterList.length > 0) {
      FunctionListMonthXlsx(selectedType, costcenterList);
    } else {
      handleOpenAlertError();
    }
  };

  const thaiYears = Array.from(
    { length: 6 },
    (_, index) => currentYear + 543 - index
  );

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const months = [
    { value: "01", name: "มกราคม" },
    { value: "02", name: "กุมภาพันธ์" },
    { value: "03", name: "มีนาคม" },
    { value: "04", name: "เมษายน" },
    { value: "05", name: "พฤษภาคม" },
    { value: "06", name: "มิถุนายน" },
    { value: "07", name: "กรกฎาคม" },
    { value: "08", name: "สิงหาคม" },
    { value: "09", name: "กันยายน" },
    { value: "10", name: "ตุลาคม" },
    { value: "11", name: "พฤศจิกายน" },
    { value: "12", name: "ธันวาคม" },
  ];

  const types = [
    { value: "departmentName", name: "Department" },
    { value: "sectionName", name: "Section" },
  ];

  useEffect(() => {
    if (companyLists && companyLists.length > 0) {
      setSelectedCompany(companyLists[0]);
    }
  }, [companyLists]);

  return (
    <DrawerCustom
      title={t("Payroll Report By Function")}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot style={{ width: 400 }}>
        <Stack spacing={4}>
          {openAlert && (
            <Alert severity="error" onClose={() => handleCloseAlert()}>
              {t("NoData")}
            </Alert>
          )}
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography
                color="text.third"
                fontSize="14px"
                fontWeight="600"
                marginBottom="8px"
              >
                {t("Company")}
              </Typography>
              <SelectAllCompaniesVendor
                options={companyLists ? companyLists : null}
                value={selectedCompany}
                disabled={isFetching || openAlert === true}
                onChange={(_, value) => {
                  onChangeCompany(value);
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography>{t("SelectYear")}</Typography>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                spacing={2}
              >
                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      variant="filled"
                      select
                      fullWidth
                      value={selectedYear}
                      onChange={handleYearChange}
                      disabled={openAlert === true}
                    >
                      {thaiYears.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography>{t("SelectMonth")}</Typography>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                spacing={2}
              >
                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      variant="filled"
                      select
                      fullWidth
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      disabled={openAlert === true}
                    >
                      {months.map((month) => (
                        <MenuItem key={month} value={month.value}>
                          {month.name}
                        </MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Stack>
            </Stack>

            <Stack spacing={1}>
              <Typography>{t("SelectType")}</Typography>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                spacing={2}
              >
                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      variant="filled"
                      select
                      fullWidth
                      value={selectedType}
                      onChange={handleTypeChange}
                      disabled={openAlert === true}
                    >
                      {types.map((type) => (
                        <MenuItem key={type} value={type.value}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Stack>
            </Stack>

          </Stack>

          <ButtonBlue
            variant="contained"
            disabled={isFetching || noData}
            onClick={() => handleFileExport()}
          >
            {t("Download")}
          </ButtonBlue>
        </Stack>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default PayrollByFunction;
