import {
    USER_PROVIDENT_FUND_FETCHING,
    USER_PROVIDENT_FUND_FAILED,
    USER_PROVIDENT_FUND_SUCCESS
} from './types';

import providentFundService from '../services/provident-fund.service';

export const getProvidentFundUser = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROVIDENT_FUND_FETCHING
        });
        const result = await providentFundService.getProvidentFundUser();
        if (result) {
            dispatch({
                type: USER_PROVIDENT_FUND_SUCCESS,
                payload: result.data
            });
        }
    } catch (error) {
        dispatch({
            type: USER_PROVIDENT_FUND_FAILED
        });
    }
};

export const getProvidentFundUserByAdminVendor = (idEmp) => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROVIDENT_FUND_FETCHING
        });
        const result = await providentFundService.getProvidentFundUser(idEmp);
        if (result) {
            dispatch({
                type: USER_PROVIDENT_FUND_SUCCESS,
                payload: result.data
            });
        }
    } catch (error) {
        dispatch({
            type: USER_PROVIDENT_FUND_FAILED
        });
    }
};

export const updateProvidentFundUser = (userPF, vendorPF, useDateUserPF) => async () => {
    try {
      const res = await providentFundService.updateProvidentFundUser(userPF, vendorPF, null, useDateUserPF);
      if(res){
        return res;
      }
    } catch (error) {
      return error;
    }
};

export const updateProvidentFundUserByAdminVendor = (userPF, vendorPF, idEmp, useDateUserPF) => async () => {
    try {
      const res = await providentFundService.updateProvidentFundUser(userPF, vendorPF, idEmp, useDateUserPF);
      if(res){
        return res;
      }
    } catch (error) {
      return error;
    }
};