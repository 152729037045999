import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import React from "react";

const StyledBox = styled(Box)(({ statusRequest }) => ({
  width: 40,
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 12,
  ...(statusRequest === 1 && {
    backgroundColor: "#ebfaf2",
  }),
  ...(statusRequest === 2 && {
    backgroundColor: "#fdf3f5",
  }),
  ...(statusRequest === 0 && {
    backgroundColor: "#fffcd6",
  }),
}));

const StatusRequest = ({ status }) => {
  const renderIcon = () => {
    if (status === 1) {
      return (
        <i
          className={`fas fa-check-circle`}
          style={{ fontSize: 24, color: "#00c292" }}
        ></i>
      );
    } else if (status === 2) {
      return (
        <i
          className={`fas fa-times-circle`}
          style={{ fontSize: 24, color: "#e46a76" }}
        ></i>
      );
    } else {
      return (
        <i
          className={`fas fa-question-circle`}
          style={{ fontSize: 24, color: "#fec90f" }}
        ></i>
      );
    }
  };

  return (
    <StyledBox statusRequest={status}>
      {renderIcon()}
    </StyledBox>
  );
};

export default StatusRequest;
