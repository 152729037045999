import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import { Typography, Grid, FormControl, Select, MenuItem } from "@mui/material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";

import {
  addPerformanceByIdEmp,
  getAllPerformancesByIdEmp,
  getPerformanceByIdCompany,
  updatePerformanceLastYear
} from "../../../../../../actions/performance";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: "16px 0px",
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const { open, setOpenEdit, onHandleOpenAlert } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: performanceCompany } = useSelector((state) => state.performanceCompany);
  const { result: performanceEmployee } = useSelector((state) => state.performanceEmployee);

  const [grade, setGrade] = useState("");

  const FindPerformance = (topicYear) => {
    if(performanceEmployee && performanceEmployee.length > 0){
      let indexResult = -1;
      performanceEmployee.map((performance, index) => {
        if (performance.yearPerformance === parseInt(topicYear)) {
          indexResult = index;
        }
      });
      return indexResult;
    }else{
      return -1;
    }
  }

  useEffect(() => {
    let index = FindPerformance(dayjs(today).subtract(1, "year").format("YYYY"));
    if(index >= 0){
      setGrade(performanceEmployee[index].idPerformance);
    }else{
      setGrade("");
    }
    dispatch(getPerformanceByIdCompany());
  },[]);

  const onSubmitHandler = async() => {
    let index = FindPerformance(dayjs(today).subtract(1, "year").format("YYYY"));
    let data = {
      idPerformance: grade,
      updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss")
    };
    let result;
    if(index >= 0){
      data = {
        ...data,
        idEmployeesPerformance: performanceEmployee[index].idEmployeesPerformance
      }
      result = await dispatch(updatePerformanceLastYear(data));
    }else{
      data = {
        ...data,
        idEmp: employeeProfile.idEmp,
        yearPerformance: dayjs(today).subtract(1, "year").format("YYYY"),
      }
      result = await dispatch(addPerformanceByIdEmp(data));
    }
    
    if(result.status === 200){
      dispatch(getAllPerformancesByIdEmp(employeeProfile.idEmp));
      onHandleOpenAlert("success");
      setOpenEdit(false)
    } else {
      onHandleOpenAlert("error");
    }
  };

  return (
    <DrawerCustom
      title={"แก้ไขผลงาน"}
      anchor={"right"}
      open={open}
      onClose={() => setOpenEdit(false)}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} style={{ display:"flex", alignItems:"center" }}>
            <StyledContentLabel>
              {dayjs(today).subtract(1, "year").format("BBBB")}
            </StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={9}>
            <StyledFormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="grade"
                value={grade}
                onChange={(event) => {
                  setGrade(event.target.value);
                }}
              >
                {performanceCompany && performanceCompany.map((per) => (
                  <MenuItem 
                    key={per.idPerformance} 
                    value={per.idPerformance}
                  >{per.performanceName}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={()=>setOpenEdit(false)}>ยกเลิก</ButtonBlue>
          <ButtonBlue variant="contained" onClick={()=>onSubmitHandler()} autoFocus>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
