import * as React from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import StyledCard from "../../../shared/general/Card";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

import AvatarShift from "../../../shared/general/AvatarShift";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import EngineeringIcon from "@mui/icons-material/Engineering";
import SearchIcon from "@mui/icons-material/Search";
import LoopIcon from "@mui/icons-material/Loop";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const StyledTabs = styled(Tabs)(({}) => ({
  minWidth: 100,
  maxWidth: 100,
  border: "1px solid",
  borderColor: "#0000001f",
  backgroundColor: "#f3f6f8",
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  "& .Mui-selected": {
    color: "#007afe !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#007afe !important",
  },
}));

const StyledTabPanel = styled(TabPanel)(({}) => ({
  border: "1px solid",
  borderLeft: "none",
  borderColor: "#0000001f",
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  padding: 8,
  minWidth: "calc(100% - 116px)",
  maxWidth: "calc(100% - 116px)",
  "& .inner-tab": {
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
    "& .empty": {
      width: "100%",
      height: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

const StyledAvatar = styled(Avatar)({
  width: 50,
  height: 50,
});

const StyledBox = styled(Box)({
  width: 120,
  padding: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledFormControlSelect = styled(FormControl)(({}) => ({
  width: 200,
  marginRight: 8,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

const StyledFormControlSearch = styled(FormControl)(({}) => ({
  width: 200,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
}));

const StyledSection = styled("div")(({ shift }) => ({
  flexGrow: 1,
  display: "flex",
  padding: 16,
  paddingTop: 8,
  height: 350,
  width: "calc(100% - 32px)",
}));

const StyledWrapHead = styled("div")(({ shift }) => ({
  padding: 16,
  paddingBottom: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
  "& .display-shift-name": {},
  "& .display-shift-time": {
    display: "flex",
    flexDirection: "column",
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
}));

export default function EmployeeShiftList(props) {
  const { handleOpenSwitchShift, idShiftGroup } = props;
  const { result: shiftGroup } = useSelector((state) => state.shiftGroup);
  const { result: employeesShift } = useSelector(
    (state) => state.employeeShift
  );
  const { t, i18n } = useTranslation();

  const [step, setStep] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [dataShift, setDataShift] = React.useState(null);
  const [ListEmployeeShift, setListEmployeeShift] = React.useState([]);

  const handleChangeStep = (event, newValue) => {
    setStep(newValue);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleOnClickSearch = (prop) => (event) => {
    //setSearch(event.target.value );
  };

  const handleChangeDepartment = (event, newValue) => {
    setDepartment(newValue);
  };

  const displayAllTime = () => {
    return shiftGroup.shiftType
      .filter((x) => {
        return x.isWorkingDay === 1;
      })
      .map((x) => (
        <Typography color="text.secondary" variant="caption" component="p">
          {`${x.nameShiftType}: ${dayjs(x.timeIn, "HH:mm:ss").format(
            "HH:mm"
          )} - ${dayjs(x.timeOut, "HH:mm:ss").format("HH:mm")}`}
        </Typography>
      ));
  };

  const renderEmployee = (step, value) => {
    if (step === 0) {
      return employeesShift;
    } else {
      return employeesShift.filter(
        (x) => x.idShift === shiftGroup.shift[step - 1].idShift
      );
    }
  };

  const renderDepartment = () => {
    const valuesDepartment = [
      ...new Map(
        employeesShift.map((item) => [
          item["idDepartment"],
          item["departmentName"],
        ])
      ),
    ];
    console.log(valuesDepartment);
    return valuesDepartment;
  };

  const renderEmployeeList = () => {
    if (search.length > 0) {
      console.log("employeesShift: ", employeesShift);
      return employeesShift.filter((item, index) => {
        if (
          item.firstname.indexOf(search) >= 0 ||
          item.lastname.indexOf(search) >= 0 ||
          item.employeeId.indexOf(search) >= 0
        )
          return item;
      });
    } else {
      return employeesShift;
    }
  };

  return (
    <StyledCard>
      <div>
        <StyledWrapHead>
          <div style={{ display: "flex" }}>
            <EngineeringIcon style={{ fontSize: 36, marginRight: 18 }} />
            <div>
              {shiftGroup && (
                <React.Fragment>
                  <Typography variant="h5">
                    {`${shiftGroup.idWorkingType === 1 ? "กะ" : ""} ${
                      shiftGroup.nameShiftGroup
                        ? shiftGroup.nameShiftGroup
                        : "-"
                    }`}
                  </Typography>
                  <div className="display-shift-time">{displayAllTime()}</div>
                </React.Fragment>
              )}
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 12 }}>
              <ButtonBlue
                style={{ width: "100%" }}
                variant={"contained"}
                startIcon={<LoopIcon />}
                onClick={handleOpenSwitchShift}
              >
                {t("SwitchShift")}
              </ButtonBlue>
            </div>
            {/* {employeesShift && (
              <StyledFormControlSelect fullWidth>
                <InputLabel id="demo-simple-select-label">หน่วยงาน</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={department}
                  name="shiftLeft"
                  onChange={handleChangeDepartment}
                  size="small"
                  label="หน่วยงาน"
                >
                  {renderDepartment().map((item) => (
                    <MenuItem value={item[0]}>{item[1]}</MenuItem>
                  ))}
                </Select>
              </StyledFormControlSelect>
            )} */}
            <StyledFormControlSearch variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {t("SearchEmp")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                value={search}
                onChange={handleChangeSearch}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleOnClickSearch}
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label={t("SearchEmp")}
              />
            </StyledFormControlSearch>
          </div>
        </StyledWrapHead>
        <StyledSection>
          <StyledTabs
            orientation="vertical"
            variant="scrollable"
            value={step}
            onChange={handleChangeStep}
            aria-label="Vertical tabs example"
          >
            <Tab
              label={
                <div>
                  <Typography
                    style={{ color: "inherit", fontSize: "0.875rem" }}
                  >
                    {t("Total")}
                  </Typography>
                  <Typography
                    style={{ color: "inherit", fontSize: "0.875rem" }}
                  >
                    {`(${employeesShift ? employeesShift.length : 0} คน)`}
                  </Typography>
                </div>
              }
              {...a11yProps(0)}
            />
            {employeesShift &&
              shiftGroup &&
              shiftGroup.idWorkingType === 1 &&
              shiftGroup.shift.map((shift, index) => {
                return (
                  <Tab
                    key={`${index}${shift.nameShift}`}
                    label={
                      <div>
                        <Typography
                          style={{ color: "inherit", fontSize: "0.875rem" }}
                        >
                          {`Shift ${shift.nameShift}`}
                        </Typography>
                        <Typography
                          style={{ color: "inherit", fontSize: "0.875rem" }}
                        >
                          {`(${employeesShift.length} คน)`}
                        </Typography>
                      </div>
                    }
                    {...a11yProps(index + 1)}
                  />
                );
              })}
          </StyledTabs>
          <StyledTabPanel
            value={step}
            index={0}
            style={{ overflow: "auto" }}
            key={0}
          >
            <div className="inner-tab">
              {employeesShift && employeesShift.length > 0 ? (
                renderEmployeeList().map((emp, indexEmployee) => {
                  return (
                    <StyledBox key={`${indexEmployee}`}>
                      {emp.idWorkingType === 1 ? (
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            <AvatarShift
                              fontSize={12}
                              title={emp.nameShift}
                              size={14}
                            />
                          }
                        >
                          <StyledAvatar
                            alt={emp.firstname}
                            src={ emp.imageProfile ? `${emp.imageProfile}` :
                                      `${process.env.REACT_APP_API_URL}image/vendor/${emp.idVendor}/${emp.personalId}.jpg`}
                          />
                        </Badge>
                      ) : (
                        <StyledAvatar
                          alt={emp.firstname}
                          src={ emp.imageProfile ? `${emp.imageProfile}` :
                                      `${process.env.REACT_APP_API_URL}image/vendor/${emp.idVendor}/${emp.personalId}.jpg`}
                        />
                      )}

                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <Typography align="center" variant="body1">
                          {emp.firstname}
                        </Typography>
                        <Typography align="center" variant="body1">
                          {emp.lastname}
                        </Typography>
                        <Typography
                          align="center"
                          variant="body2"
                          style={{
                            color: "#999999",
                            fontStyle: "oblique",
                          }}
                        >
                          {emp.positionsName}
                        </Typography>
                      </div>
                    </StyledBox>
                  );
                })
              ) : (
                <div className="empty">
                  <i
                    className="fa-solid fa-users"
                    style={{ fontSize: "44px", color: "#919EAB" }}
                  />
                  <Typography variant="h5" style={{ color: "#919EAB" }}>
                    {t("NoEmpInThisShift")}
                  </Typography>
                </div>
              )}
            </div>
          </StyledTabPanel>
          {employeesShift &&
            shiftGroup &&
            shiftGroup.idWorkingType === 1 &&
            shiftGroup.shift.map((value, index) => {
              return (
                <StyledTabPanel
                  value={step}
                  index={index + 1}
                  style={{ overflow: "auto" }}
                  key={index + 1}
                >
                  <div className="inner-tab">
                    {employeesShift.length > 0 ? (
                      renderEmployee(step, value).map((emp, indexEmployee) => {
                        return (
                          <StyledBox key={`${indexEmployee + step}`}>
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <AvatarShift
                                  fontSize={12}
                                  title={emp.nameShift}
                                  size={14}
                                />
                              }
                            >
                              <StyledAvatar
                                alt={emp.firstname}
                                src={ emp.imageProfile ? `${emp.imageProfile}` :
                                      `${process.env.REACT_APP_API_URL}image/vendor/${emp.idVendor}/${emp.personalId}.jpg`}
                              />
                            </Badge>

                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <Typography align="center" variant="body1">
                                {emp.firstname}
                              </Typography>
                              <Typography align="center" variant="body1">
                                {emp.lastname}
                              </Typography>
                              <Typography
                                align="center"
                                variant="body2"
                                style={{
                                  color: "#999999",
                                  fontStyle: "oblique",
                                }}
                              >
                                {emp.positionsName}
                              </Typography>
                            </div>
                          </StyledBox>
                        );
                      })
                    ) : (
                      <div className="empty">
                        <i
                          className="fa-solid fa-users"
                          style={{ fontSize: "44px", color: "#919EAB" }}
                        />
                        <Typography variant="h5" style={{ color: "#919EAB" }}>
                          {t("NoEmpInThisShift")}
                        </Typography>
                      </div>
                    )}
                  </div>
                </StyledTabPanel>
              );
            })}
        </StyledSection>
      </div>
    </StyledCard>
  );
}
