import salaryService from "../services/salary.service";

export const updateEmployeeSalary = (idEmployeeSalary, formData) => async (dispatch) => {
  try {
    const res = await salaryService.updateEmployeeSalary(idEmployeeSalary, formData);
    if (res) {
      return res
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
}