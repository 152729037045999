import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import "dayjs/locale/th";

import {
  Button,
  Container,
  Typography,
  CardContent,
  ToggleButtonGroup,
  ToggleButton,
  TablePagination,
  TextField,
  Grid,
  Popper,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  FormHelperText,
  Menu,
  MenuItem,
  IconButton,
  createFilterOptions,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Dropzone, { useDropzone } from "react-dropzone";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import GalleryIcon from "../../../../assets/gallery.png";
import utils from "../../../../../../utils";
import { currency } from "../../../../assets/data/currency";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../../shared/general/AlertResponse";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

import RowItemExpense from "./row-general";
import RowSummaryItemExpense from "./row-summary";
import SelectApprover from "../../select-approver";
import ActionButtons from "../action-buttons";
import SelectCC from "../../select-cc";

import { addExpenseGeneral } from "../../../../../../actions/expense";
import SelectCostCenter from "../../select-costCenter";
import { getAdminVendor } from "../../../../../../actions/vendor";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  "& .MuiToggleButtonGroup-grouped": {
    color: "#007AFE",
    border: "1px solid #007AFE",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#3394fe",
      backgroundColor: "#3394fe",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007AFE",
      "&:hover": {
        borderColor: "#3394fe",
        backgroundColor: "#3394fe",
        color: "#FFFFFF",
      },
    },
  },
  "& ,MuiToggleButtonGroup-root": {
    height: 50,
  },
}));

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.summary": {
    textAlign: "center",
  },
  "&.order": {
    width: 28,
  },
  "&.date-document": {
    width: 120,
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 40,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const rowHeader = [
  {
    caption: "",
    className: "delete",
  },
  {
    caption: "DocumentDate",
    className: "date-document",
  },
  {
    caption: "GoodsServices",
    className: "service",
  },
  {
    caption: "Description",
    className: "description",
  },
  {
    caption: "Amount",
    className: "amount",
  },
  {
    caption: "UnitPrice",
    className: "price",
  },
  {
    caption: "Vat",
    className: "tax",
  },
  {
    caption: "PriceBeforeVat",
    className: "price-before-tax",
  },
  {
    caption: "Withholding",
    className: "tax-withholding",
  },
  {
    caption: "",
    className: "delete",
  },
];

const summaryRowHeader = [
  {
    caption: "",
    className: "order",
  },
  {
    caption: "GoodsServices",
    className: "service",
  },
  {
    caption: "Amount",
    className: "amount",
  },
  {
    caption: "UnitPrice",
    className: "price",
  },
  {
    caption: "Vat",
    className: "tax",
  },
  {
    caption: "PriceBeforeVat",
    className: "price-before-tax",
  },
  {
    caption: "Withholding",
    className: "tax-withholding",
  },
];

const listMenuTax = ["7%", "ไม่มี"];
const listMenuWithholding = [
  "1%",
  "1.5%",
  "2%",
  "3%",
  "5%",
  "10%",
  "15%",
  "ไม่มี",
];

const listMenuVatType = [
  { label: "แยกภาษี", isVatIncluded: false, vat: 7 },
  { label: "รวมภาษี", isVatIncluded: true, vat: 7 },
  { label: "ไม่มีภาษี", isVatIncluded: false, vat: 0 },
];

const ITEM_HEIGHT = 48;

const General = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { typeExpense } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: manager } = useSelector((state) => state.manager);
  const { result: admin } = useSelector((state) => state.admin);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [listMenuTaxOrWithholding, setListMenuTaxOrWithholding] =
    React.useState(null);
  const open = Boolean(anchorEl);
  const [fileSelected, setFileSelected] = useState([]);
  const [isInternational, setIsInternational] = useState(false);
  const [currencyRate, setCurrencyRate] = useState(1.0);
  const today = dayjs().toDate();
  const [tempDate, setTempDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [isAPIpending, setIsAPIpending] = useState(false);
  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });

  const [dataInitial, setDataInitial] = useState({
    documentDate: dayjs(tempDate).format("YYYY/MM/DD HH:mm"),
    service: "",
    description: "",
    amount: 1,
    unitPrice: 0,
    taxPercent: 7,
    tax: 0,
    total: 0,
    totalPrice: 0,
    withholdingPercent: 0,
    withholding: 0,
    net: 0,
    unitPriceInternational: 0,
    taxInternational: 0,
    totalBeforeTaxInternational: 0,
    totalPriceInternational: 0,
    withholdingInternational: 0,
    netInternational: 0,
  });
  const [formData, setFormData] = useState({
    nameExpense: "",
    isInternational: isInternational,
    currency: "THB",
    currencyItem: currency[0],
    currencyUnit: currency[0].unit,
    currencyRate: currencyRate,
    vatType: "แยกภาษี",
    isVatIncluded: false,
    listExpense: [dataInitial],
    file: null,
    remark: "",
    typeExpenseName: typeExpense,
    total: 0,
    vat: 0,
    withholding: 0,
    net: 0,
    approver: "",
    cc_email: [],
    reviewer: null,
    costCenter: "",
  });

  const MAX_FILE_SIZE = 512000; //500KB

  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const filterOptions = createFilterOptions({
    stringify: (value) => `${value.name} ${value.email}`,
  });

  const validationSchema = Yup.object().shape({
    nameExpense: Yup.string().required("โปรดกรอกข้อมูล"),
    approver: Yup.string().required("โปรดกรอกข้อมูล"),
    isInternational: isInternational,
    currency: Yup.string().required("โปรดกรอกข้อมูล"),
    currencyUnit: Yup.string().required("โปรดกรอกข้อมูล"),
    currencyRate: Yup.string().required("โปรดกรอกข้อมูล").default(1),
    vatType: Yup.string().required("โปรดกรอกข้อมูล"),
    isVatIncluded: Yup.boolean().required("โปรดกรอกข้อมูล"),
    remark: Yup.string(),
    typeExpenseName: Yup.string().required("โปรดกรอกข้อมูล"),
    total: Yup.string().required("โปรดกรอกข้อมูล").default(0),
    vat: Yup.string().required("โปรดกรอกข้อมูล").default(0),
    withholding: Yup.string().required("โปรดกรอกข้อมูล").default(0),
    net: Yup.string().required("โปรดกรอกข้อมูล").default(0),
    costCenter: Yup.string().required("โปรดกรอกข้อมูล"),
    listExpense: Yup.array().of(
      Yup.object().shape({
        documentDate: Yup.date().required("โปรดกรอกข้อมูล"),
        service: Yup.string().required("โปรดกรอกข้อมูล"),
        description: Yup.string().required("โปรดกรอกข้อมูล"),
        amount: Yup.string().required("โปรดกรอกข้อมูล"),
        unitPrice: Yup.string().required("โปรดกรอกข้อมูล"),
        taxPercent: Yup.string().required("โปรดกรอกข้อมูล"),
        tax: Yup.string().required("โปรดกรอกข้อมูล"),
        total: Yup.string().required("โปรดกรอกข้อมูล"),
        totalPrice: Yup.string().required("โปรดกรอกข้อมูล"),
        withholdingPercent: Yup.string().required("โปรดกรอกข้อมูล"),
        withholding: Yup.string().required("โปรดกรอกข้อมูล"),
        net: Yup.string().required("โปรดกรอกข้อมูล"),
        unitPriceInternational: Yup.string().nullable(),
        taxInternational: Yup.string().nullable(),
        totalBeforeTaxInternational: Yup.string().nullable(),
        totalPriceInternational: Yup.string().nullable(),
        withholdingInternational: Yup.string().nullable(),
        netInternational: Yup.string().nullable(),
      })
    ),
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (value) {
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const { append, remove, fields } = useFieldArray({
    control: control,
    name: "listExpense",
  });

  const checkValidation = (error) => {
    console.log(getValues("listExpense"));
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: "โปรดกรอกข้อมูล",
      type: "error",
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    onDrop: (acceptedFiles) => {
      let formDataFile = new FormData();
      acceptedFiles.map((file) => formDataFile.append("file", file));
      setFormData({
        ...formData,
        file: formDataFile,
      });
    },
    maxFiles: 1,
  });

  useEffect(() => {
    reCalculateSum();
  }, [watch("currencyRate"), watch("vatType"), watch("currencyUnit")]);

  useEffect(() => {
    dispatch(getAdminVendor());
    if (userProfile && userProfile.managerLV1Id) {
      setFormData({
        ...formData,
        approver: userProfile.managerLV1Id,
        costCenter: userProfile.costCenter,
      });
      setValue("approver", userProfile.managerLV1Id);
      setValue("costCenter", userProfile.costCenter);
    }
  }, [userProfile, manager]);

  const thumbs = fileSelected.map((file) => (
    <img key={file.name} src={file.preview} />
  ));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const convertCurrency = (value) => {
    if (getValues("currencyRate")) {
      return fixPoint(
        parseFloat(value) * parseFloat(getValues("currencyRate"))
      );
    } else {
      return 0;
    }
  };

  const fixPoint = (value) => {
    return parseFloat(value).toFixed(2);
  };

  const handleIsNan = (value) => {
    return isNaN(value) ? 0 : value;
  };

  const reCalculateSum = () => {
    if (getValues("listExpense")) {
      getValues("listExpense").forEach((item, index) => {
        console.log(item);
        let unitPrice = getValues("isVatIncluded")
          ? parseFloat(item.unitPrice * 100) /
            (100 + parseFloat(item.taxPercent))
          : parseFloat(item.unitPrice);
        let newTotal = parseFloat(item.amount) * unitPrice;
        let newTax = newTotal * (parseFloat(item.taxPercent) / 100);
        let newWitholding =
          newTotal * (parseFloat(item.withholdingPercent) / 100);
        let newNet = newTotal + newTax - newWitholding;
        let newTotalPrice = newTotal + newTax;
        setValue(`listExpense.${index}.tax`, fixPoint(newTax));
        setValue(`listExpense.${index}.withholding`, fixPoint(newWitholding));
        setValue(`listExpense.${index}.total`, fixPoint(newTotal));
        setValue(`listExpense.${index}.totalPrice`, fixPoint(newTotalPrice));
        setValue(`listExpense.${index}.net`, fixPoint(newNet));
        if (getValues("currencyUnit") === "THB") {
          setValue(`listExpense.${index}.unitPriceInternational`, null);
          setValue(`listExpense.${index}.taxInternational`, null);
          setValue(`listExpense.${index}.withholdingInternational`, null);
          setValue(`listExpense.${index}.totalBeforeTaxInternational`, null);
          setValue(`listExpense.${index}.totalPriceInternational`, null);
          setValue(`listExpense.${index}.netInternational`, null);
        } else {
          setValue(
            `listExpense.${index}.unitPriceInternational`,
            handleIsNan(convertCurrency(unitPrice))
          );
          setValue(
            `listExpense.${index}.taxInternational`,
            handleIsNan(convertCurrency(newTax))
          );
          setValue(
            `listExpense.${index}.withholdingInternational`,
            handleIsNan(convertCurrency(newWitholding))
          );
          setValue(
            `listExpense.${index}.totalBeforeTaxInternational`,
            handleIsNan(convertCurrency(newTotal))
          );
          setValue(
            `listExpense.${index}.totalPriceInternational`,
            handleIsNan(convertCurrency(newTotalPrice))
          );
          setValue(
            `listExpense.${index}.netInternational`,
            handleIsNan(convertCurrency(newNet))
          );
        }
      });
      calculateSum();
    }
  };

  const calculateRowSum = (index) => {
    let expense = getValues(`listExpense.${index}`);
    let unitPrice = getValues("isVatIncluded")
      ? (parseFloat(expense.unitPrice) * 100) /
        (100 + parseFloat(expense.taxPercent))
      : parseFloat(expense.unitPrice);
    let newTotal = parseFloat(expense.amount) * unitPrice;
    let newTax = newTotal * (parseFloat(expense.taxPercent) / 100);
    let newWitholding =
      newTotal * (parseFloat(expense.withholdingPercent) / 100);
    let newNet = newTotal + newTax - newWitholding;
    let newTotalPrice = newTotal + newTax;
    setValue(`listExpense.${index}.tax`, fixPoint(newTax));
    setValue(`listExpense.${index}.withholding`, fixPoint(newWitholding));
    setValue(`listExpense.${index}.total`, fixPoint(newTotal));
    setValue(`listExpense.${index}.totalPrice`, fixPoint(newTotalPrice));
    setValue(`listExpense.${index}.net`, fixPoint(newNet));
    if (getValues("currencyUnit") === "THB") {
      setValue(`listExpense.${index}.unitPriceInternational`, null);
      setValue(`listExpense.${index}.taxInternational`, null);
      setValue(`listExpense.${index}.withholdingInternational`, null);
      setValue(`listExpense.${index}.totalBeforeTaxInternational`, null);
      setValue(`listExpense.${index}.totalPriceInternational`, null);
      setValue(`listExpense.${index}.netInternational`, null);
    } else {
      setValue(
        `listExpense.${index}.unitPriceInternational`,
        handleIsNan(convertCurrency(unitPrice))
      );
      setValue(
        `listExpense.${index}.taxInternational`,
        handleIsNan(convertCurrency(newTax))
      );
      setValue(
        `listExpense.${index}.withholdingInternational`,
        handleIsNan(convertCurrency(newWitholding))
      );
      setValue(
        `listExpense.${index}.totalBeforeTaxInternational`,
        handleIsNan(convertCurrency(newTotal))
      );
      setValue(
        `listExpense.${index}.totalPriceInternational`,
        handleIsNan(convertCurrency(newTotalPrice))
      );
      setValue(
        `listExpense.${index}.netInternational`,
        handleIsNan(convertCurrency(newNet))
      );
    }
    calculateSum();
  };

  const calculateSum = () => {
    let newListExpense = getValues("listExpense");
    let sumTotal = fixPoint(
      newListExpense.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.total);
      }, 0)
    );
    let sumVat = fixPoint(
      newListExpense.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.tax);
      }, 0)
    );
    let sumWithholding = fixPoint(
      newListExpense.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.withholding);
      }, 0)
    );

    let sumNet =
      parseFloat(sumTotal) + parseFloat(sumVat) - parseFloat(sumWithholding);

    setValue("total", convertCurrency(sumTotal));
    setValue("vat", convertCurrency(sumVat));
    setValue("withholding", convertCurrency(sumWithholding));
    setValue("net", convertCurrency(sumNet));
  };

  const createFormData = (statusId) => {
    let data = new FormData();
    data.append("nameExpense", getValues("nameExpense"));
    data.append("isInternational", getValues("isInternational") ? 1 : 0);
    data.append("isVatIncluded", getValues("isVatIncluded") ? 1 : 0);
    data.append("currency", getValues("currency"));
    data.append("currencyItem", getValues("currencyItem"));
    data.append("currencyRate", getValues("currencyRate"));
    data.append("listExpense", JSON.stringify(getValues("listExpense")));
    if (getValues("file")) {
      data.append("file", getValues("file"), getValues("file.path"));
    }
    data.append("remark", getValues("remark"));
    data.append("typeExpense", 1);
    data.append("typeExpenseName", getValues("typeExpenseName"));
    data.append("lastUpdateDate", dayjs(today).format("YYYY/MM/DD HH:mm"));
    data.append("status", statusId);
    data.append("total", getValues("total"));
    data.append("vat", getValues("vat"));
    data.append("withholding", getValues("withholding"));
    data.append("net", getValues("net"));
    data.append("idEmpApprover", getValues("approver"));
    data.append(
      "idEmpReviewer",
      getValues("reviewer") === null
        ? JSON.stringify(null)
        : JSON.stringify(getValues("reviewer").idAdminVendor)
    );
    data.append(
      "cc_email",
      JSON.stringify(
        getValues("cc_email")
          ? getValues("cc_email")
              .map((e) => e.email)
              .join(";")
          : null
      )
    );
    data.append("idPosition", userProfile.idPositions);
    data.append("costCenter", getValues("costCenter"));
    return data;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOnClickCleardata = () => {
    reset(formData);
  };

  const handleOnClickSavedraft = async () => {
    setIsAPIpending(true);
    let data = createFormData(1);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmp) {
      console.log(userProfile);
      result = await dispatch(addExpenseGeneral(userProfile.idEmp, data));
    }
    if (result) {
      if (result.status === 200) {
        setIsAPIpending(false);
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/expense/ServiceAndGoods/Draft/${result.data.idExpense}`);
      } else {
        setIsAPIpending(false);
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  const handleOnClickSent = async () => {
    setIsAPIpending(true);
    let data = createFormData(2);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmp) {
      result = await dispatch(addExpenseGeneral(userProfile.idEmp, data));
    }
    if (result) {
      if (result.status === 200) {
        setIsAPIpending(false);
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/expense`);
      } else {
        setIsAPIpending(false);
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  return (
    <Box>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      <form>
        <Grid container spacing={2} style={{}} alignItems={"center"}>
          <Grid item xs={12}>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">
                {t("GeneralInformation")}
              </StyledHeadLabel>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("Title")}
              </StyledHeadLabel>
              <FormControl
                fullWidth
                error={errors && errors.nameExpense ? true : false}
              >
                {errors && errors.nameExpense && errors.nameExpense.message && (
                  <FormHelperText error>
                    {errors.nameExpense.message}
                  </FormHelperText>
                )}
                <Controller
                  name="nameExpense"
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <>
                      <TextFieldTheme
                        {...field}
                        name="nameExpense"
                        inputProps={{ maxLength: 200 }}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      <Typography
                        component="p"
                        textAlign="right"
                        variant="caption"
                        color="text.secondary"
                      >{`${field.value.length}/200`}</Typography>
                    </>
                  )}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <FormControl
                fullWidth
                error={errors && errors.costCenter ? true : false}
              >
                {errors && errors.costCenter && errors.costCenter.message && (
                  <FormHelperText error>
                    {errors.costCenter.message}
                  </FormHelperText>
                )}
                <SelectCostCenter
                  handleChange={setValue}
                  control={control}
                  errors={errors}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={errors && errors.approver ? true : false}
            >
              {errors && errors.approver && errors.approver.message && (
                <FormHelperText error>{errors.approver.message}</FormHelperText>
              )}
              <Controller
                name="approver"
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <SelectApprover
                      {...field}
                      idApprover={getValues("approver")}
                      handleChange={setValue}
                      position={userProfile && userProfile.idCompany === 3}
                    />
                  </>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <SelectCC control={control} errors={errors} />
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
              {t("Reviewer")}
            </StyledHeadLabel>
            <FormControl
              fullWidth
              error={errors && errors.reviewer ? true : false}
            >
              {errors && errors.reviewer && errors.reviewer.message && (
                <FormHelperText error>{errors.reviewer.message}</FormHelperText>
              )}
              <Controller
                name="reviewer"
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <StyledAutocomplete
                      options={admin ? admin : []}
                      getOptionLabel={(option) =>
                        `${option.firstname} ${option.lastname}`
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.idAdminVendor === value.idAdminVendor
                      }
                      filterOptions={filterOptions}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idAdminVendor}>
                          <Box>
                            <Typography>
                              {option.firstname} {option.lastname}
                            </Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={t("SelectReviewer")}
                          onBlur={field.onBlur}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value);
                      }}
                      noOptionsText={t("No_Data")}
                    />
                  </>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("ExpenseLocation")}
                  </StyledHeadLabel>
                  <Controller
                    name="isInternational"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <StyledToggleButtonGroup
                          {...field}
                          exclusive
                          aria-label="filter"
                          onChange={(e, value) => {
                            setValue("isInternational", value);
                          }}
                        >
                          <ToggleButton
                            name="isInternational"
                            value={false}
                            aria-label="local"
                            key="local"
                            style={{
                              width: "100%",
                              borderTopLeftRadius: 8,
                              borderBottomLeftRadius: 8,
                            }}
                          >
                            {t("Local")}
                          </ToggleButton>
                          <ToggleButton
                            name="isInternational"
                            value={true}
                            aria-label="international"
                            key="international"
                            style={{
                              width: "100%",
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                            }}
                          >
                            {t("International")}
                          </ToggleButton>
                        </StyledToggleButtonGroup>
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("VatType")}
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.vatType ? true : false}
                  >
                    <Controller
                      name="vatType"
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <StyledAutocomplete
                            {...field}
                            fullWidth
                            options={listMenuVatType}
                            onChange={(e, newValue) => {
                              let tempdata = { ...dataInitial };
                              if (newValue === null) {
                                setValue("vatType", listMenuVatType[0].label);
                                setValue(
                                  "isVatIncluded",
                                  listMenuVatType[0].isVatIncluded
                                );
                                let list = getValues("listExpense");
                                list.forEach((element, index) => {
                                  setValue(
                                    `listExpense.${index}.taxPercent`,
                                    listMenuVatType[0].vat
                                  );
                                });
                                tempdata.taxPercent = listMenuVatType[0].vat;
                                setDataInitial(tempdata);
                              } else {
                                setValue("vatType", newValue.label);
                                setValue(
                                  "isVatIncluded",
                                  newValue.isVatIncluded
                                );
                                let list = getValues("listExpense");
                                list.forEach((element, index) => {
                                  setValue(
                                    `listExpense.${index}.taxPercent`,
                                    newValue.vat
                                  );
                                });
                                tempdata.taxPercent = newValue.vat;
                                setDataInitial(tempdata);
                              }
                            }}
                            popupIcon={<i class="fa-light fa-chevron-down"></i>}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {t(`${option.label}`)}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder=""
                                name="vatType"
                              />
                            )}
                            PopperComponent={StyledPopper}
                            noOptionsText={t("No_Data")}
                          />
                        </>
                      )}
                    />
                    {errors && errors.vatType && errors.vatType.message && (
                      <FormHelperText error>
                        {errors.vatType.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("Currency")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={errors && errors.currency ? true : false}
                >
                  <Controller
                    name="currencyItem"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <StyledAutocomplete
                          {...field}
                          fullWidth
                          options={currency}
                          onChange={(e, newValue) => {
                            let tempdata = { ...dataInitial };
                            if (newValue === null) {
                              setValue("currencyItem", currency[0]);
                              setValue("currency", "THB");
                              setValue("currencyUnit", "THB");
                              setValue("currencyRate", 1);
                            } else {
                              if (newValue.unit === "THB") {
                                setValue("currencyRate", 1);
                              }
                              setValue("currencyItem", newValue);
                              setValue("currency", newValue.unit);
                              setValue("currencyUnit", newValue.unit);
                            }
                          }}
                          popupIcon={<i class="fa-light fa-chevron-down"></i>}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              <img
                                loading="lazy"
                                width="20"
                                style={{ marginRight: 16, flexShrink: 0 }}
                                src={`https://flagcdn.com/16x12/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/32x24/${option.code.toLowerCase()}.png 2x`}
                                alt={option.code}
                              />
                              {t(`${option.label}`)}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder=""
                              name="vatType"
                            />
                          )}
                          PopperComponent={StyledPopper}
                          noOptionsText={t("No_Data")}
                        />
                      </>
                    )}
                  />
                  {errors && errors.currency && errors.currency.message && (
                    <FormHelperText error>
                      {errors.currency.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {watch("currency") && watch("currency") !== "THB" && (
                <Grid item xs={12} sm={6}>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("CurrencyRate")}
                    <Typography variant="body" color="text.secondary">
                      ( {t("Baht")} {t("Per")} 1 {t(`${watch("currencyUnit")}`)}
                      )
                    </Typography>
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.currencyRate ? true : false}
                  >
                    <Controller
                      name="currencyRate"
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            name="currencyRate"
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              decimalScale: 2,
                              allowNegative: false,
                              value: field.value,
                              onValueChange: (values) => {
                                const { value } = values;
                                field.onChange(value);
                                reCalculateSum();
                              },
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                  {errors &&
                    errors.currencyRate &&
                    errors.currencyRate.message && (
                      <FormHelperText error>
                        {errors.currencyRate.message}
                      </FormHelperText>
                    )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">
              {t("ExpenseList")}
              {watch("currencyUnit") !== "THB" && (
                <Typography variant="body" color="text.secondary">
                  ({t("Unit")} {watch("currencyUnit")})
                </Typography>
              )}
            </StyledHeadLabel>
            <div>
              <ButtonBlue
                size="small"
                startIcon={<AddRoundedIcon />}
                variant="outlined"
                onClick={() => {
                  append(dataInitial);
                }}
              >
                {t("Add")}
              </ButtonBlue>
            </div>
          </div>

          <TableContainer>
            <Table
              style={{
                minWidth: 650,
                borderSpacing: "0 4px",
                borderCollapse: "separate",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {rowHeader.map((item) => (
                    <StyledTableCell className={item.className}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t(`${item.caption}`)}
                      </StyledHeadLabel>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fields &&
                  fields.length > 0 &&
                  fields
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <RowItemExpense
                        key={item.id}
                        count={fields.length}
                        index={index}
                        open={open}
                        control={control}
                        errors={errors}
                        handleDeleteRow={remove}
                        calculateRowSum={calculateRowSum}
                        getValues={getValues}
                      />
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {watch("listExpense") && watch("listExpense.length") && (
            <TablePagination
              rowsPerPageOptions={[
                10,
                20,
                30,
                40,
                50,
                { label: "ทั้งหมด", value: -1 },
              ]}
              component="div"
              count={fields.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("RowPerPage")}
            />
          )}
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">
              {t("SummaryList")}
              {watch("currencyUnit") !== "THB" && (
                <Typography variant="body" color="text.secondary">
                  ( {t("Baht")} )
                </Typography>
              )}
            </StyledHeadLabel>
          </div>
          {watch("currency") && watch("currency") !== "THB" && (
            <div style={{ marginBottom: 32 }}>
              <TableContainer>
                <Table
                  style={{
                    minWidth: 650,
                    borderSpacing: "0 4px",
                    borderCollapse: "separate",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      {summaryRowHeader.map((item) => (
                        <StyledTableCell
                          className={(item.className, "summary")}
                        >
                          <StyledHeadLabel
                            variant="body2"
                            color="text.third"
                            gutterBottom
                          >
                            {t(`${item.caption}`)}
                          </StyledHeadLabel>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields &&
                      fields.length > 0 &&
                      fields
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => (
                          <RowSummaryItemExpense
                            key={item.id}
                            count={fields.length}
                            data={getValues(`listExpense.${index}`)}
                            index={index}
                            open={open}
                            control={control}
                            errors={errors}
                            watch={watch}
                            handleDeleteRow={remove}
                            setValue={setValue}
                            getValues={getValues}
                          />
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          <div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("TotalValue")}&nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(parseFloat(watch("total")))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Baht")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("TotalVat")}&nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(parseFloat(watch("vat")))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Baht")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("TotalWithholding")}&nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(parseFloat(watch("withholding")))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Baht")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography variant="h5">
                {t("ExpenseNet")}&nbsp;&nbsp;
              </Typography>
              <Typography variant="h4">
                {utils.numberWithCommas(parseFloat(watch("net")))}
              </Typography>
              <Typography variant="h5">&nbsp;&nbsp;{t("Baht")}</Typography>
            </div>
          </div>
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("AttachFile")}</StyledHeadLabel>
          </div>
          {!watch("file") ? (
            <StyledDropzone>
              <Dropzone
                accept="image/jpeg, image/png, application/pdf"
                maxFiles={1}
                multiple={false}
                maxSize={3145728}
                onDrop={(acceptedFiles, rejectedFiles) => {
                  if (acceptedFiles.length > 0) {
                    setValue("file", acceptedFiles[0]);
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone-upload-file" })}>
                    <div className="inner-dropzone">
                      <input {...getInputProps()} />
                      {/* <Fragment>{thumbs}</Fragment> */}
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img alt="add" src={GalleryIcon} />
                        </div>
                        <Typography
                          variant="caption"
                          style={{ lineHeight: 0.5, color: "#999999" }}
                        >
                          ไฟล์ *.jpeg, *.jpg, *.png จำนวน 1 ไฟล์ ขนาดไม่เกิน 500
                          KB
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            </StyledDropzone>
          ) : (
            <Box
              style={{
                position: "relative",
                textAlign: "center",
              }}
            >
              <IconButton
                style={{ position: "absolute", margin: 7, right: 0 }}
                color="error"
                onClick={() => {
                  setValue("file", null);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <div
                style={{
                  padding: 15,
                  backgroundColor: "#f9f9fb",
                  display: "flex",
                  borderRadius: 15,
                }}
              >
                <FilePresentIcon
                  height="24"
                  style={{
                    marginRight: 10,
                  }}
                ></FilePresentIcon>
                <Typography>{watch("file.path")}</Typography>
              </div>
            </Box>
          )}
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("RemarkMore")}</StyledHeadLabel>
          </div>
          <Controller
            name={"remark"}
            control={control}
            errors={errors}
            render={({ field }) => (
              <>
                <TextFieldTheme {...field} multiline rows={4} />
                <Typography
                  component="p"
                  textAlign="right"
                  variant="caption"
                  color="text.secondary"
                >{`${field.value ? field.value.length : 0}/500`}</Typography>
              </>
            )}
          />
        </div>
        <StyledDivider />
        <ActionButtons
          handleOnClickCleardata={handleOnClickCleardata}
          handleSubmitDraft={handleSubmit(
            handleOnClickSavedraft,
            checkValidation
          )}
          handleSubmitSent={handleSubmit(handleOnClickSent, checkValidation)}
          isAPIpending={isAPIpending}
        />
      </form>
    </Box>
  );
};

export default General;
