import axios from "axios";
import join from "url-join";

const user = JSON.parse(localStorage.getItem("user"));

axios.interceptors.request.use(async (config) => {
  
  if (config.url.indexOf("://") > 0 || config.url.indexOf("//") === 0) {
    
  } else {
    const temp = config.url.includes(process.env.REACT_APP_PAYMENT_SERVICE)
      ? process.env.REACT_APP_API_PAYROLL_URL
      : process.env.REACT_APP_API_URL;
    
    config.url = join(temp, config.url);
    //config.url = join(process.env.REACT_APP_API_URL, config.url);
  }

  if (user && user.accessToken) {
    config.headers = { "x-access-token": user.accessToken };
  }

  config.timeout = 900000; // 10 Second
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(JSON.stringify(error, undefined, 2));
    console.log(error.response.status);
    if (error.response.status === 401) {
      window.location.assign("/login");
      localStorage.removeItem("user");
    }
    //   const token = result.data.jwt;
    //   localStorage.setItem(server.TOKEN_KEY, token);
    //   debugger;
    //   return axios.request(error.config);
    // }

    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (!error.response) {
      //alert(JSON.stringify(error));
      return Promise.reject({
        code: "NOT_CONNECT_NETWORK",
        message: "Cannot connect to server, Please try again.",
      });
    }

    return Promise.reject(error);
  }
);

export const httpClient = axios;
