import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  styled,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
} from "@mui/material";

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import TimePicker from "../../shared/general/TimePicker";

import { addRequestTime } from "../../../../actions/requestTime";
import StatusRequest from "../../shared/general/stausRequest";

const StyledRoot = styled("div")({
  width: 500,
  padding: 24,
  "& .wrap-status": {
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      marginRight: 8,
    },
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogNewRequest = (props) => {
  const {
    values,
    open,
    handleCloseDialog,
    handleChangeAlertType,
    handleOpenAlert,
    handleOpenDialogConfirmDelete,
  } = props;
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [formInputData, setFormData] = useState(null);
  const [mode, setMode] = useState("read");

  useEffect(() => {
    console.log(values);
    if (values.idRequestTime) {
      setFormData({
        idRequestTime: values.idRequestTime,
        idRequestType: values.idRequestType,
        startDate: dayjs(values.start, "YYYY-MM-DD"),
        startMinute: dayjs(values.start).format("m"),
        startHours: dayjs(values.start).format("H"),
        endDate: dayjs(values.end, "YYYY-MM-DD"),
        endMinute: dayjs(values.end).format("m"),
        endHours: dayjs(values.end).format("H"),
        managerLV1Id: values.managerLV1Id,
        otherReason: values.otherReason,
        idRequestReason: values.idRequestReason,
        isDoubleApproval: values.isDoubleApproval,
        approvalLevel: values.approvalLevel,
        managerLV1Name: values.managerLV1Name,
        isManagerLV1Approve: values.isManagerLV1Approve,
        managerLV1ApproveDate: values.managerLV1ApproveDate
          ? dayjs(values.managerLV1ApproveDate).format("DD/MM/BBBB HH:mm")
          : null,
        positionManagerLV1: values.positionManagerLV1,
        managerLV2Name: values.managerLV2Name,
        isManagerLV2Approve: values.isManagerLV2Approve,
        managerLV2ApproveDate: values.managerLV2ApproveDate
          ? dayjs(values.managerLV2ApproveDate).format("DD/MM/BBBB HH:mm")
          : null,
        positionManagerLV2: values.positionManagerLV2,
      });
    } else {
      setMode("write");
      setFormData({
        idRequestType: "",
        startDate: dayjs(values.date, "YYYY-MM-DD"),
        startMinute: "",
        startHours: "",
        endDate: dayjs(values.date, "YYYY-MM-DD"),
        endMinute: "",
        endHours: "",
        idRequestReason: "",
        otherReason: "",
      });
    }
  }, [values]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formInputData,
      [name]: value,
    });

    if (name === "idRequestType" && value === 2 && values.pattern.isWorkingDay === 1) {
      const temp = { ...formInputData };
      temp.startMinute = dayjs(values.pattern.timeOut, "HH:mm:ss").format("m");
      temp.startHours = dayjs(values.pattern.timeOut, "HH:mm:ss").format("H");
      temp[name] = value;
      setFormData(temp);
    }
  };

  const handleChangeTime = (name, unit, periodTime, value) => {
    setFormData({
      ...formInputData,
      [name]: value,
    });
  };

  const handleSaveRequestTime = async () => {
    const startDate = dayjs(formInputData.startDate)
      .set("hour", formInputData.startHours)
      .set("minute", formInputData.startMinute)
      .set("second", 0);
    const endDate = dayjs(formInputData.endDate)
      .set("hour", formInputData.endHours)
      .set("minute", formInputData.endMinute)
      .set("second", 0);
    const formData = {
      workDate: values.date,
      start: startDate.format("YYYY-MM-DD HH:mm:ss"),
      end: endDate.format("YYYY-MM-DD HH:mm:ss"),
      idRequestReason: formInputData.idRequestReason,
      idRequestType: formInputData.idRequestType,
      otherReason: formInputData.otherReason,
      idEmp: userProfile.idEmp,
      isManagerLV1Approve: null,
      isManagerLV2Approve: null,
      isDoubleApproval: 0,
      approvalLevel: 1,
      xOT: 0,
      xOTHoliday: 0,
      xWorkingDailyHoliday: 0,
      xWorkingMonthlyHoliday: 0,
      isActive: 1,
      managerLV1ApproveBy: userProfile.managerLV1Id,
      managerLV2ApproveBy: userProfile.managerLV2Id,
    };

    formData.amountHours = endDate.diff(startDate, "minute");

    if (formInputData.idRequestType === 2) {
      if (values.pattern.isWorkingDay === 1) {
        formData.xOT = formData.amountHours;
      } else {
        const findDate = {
          start: `${dayjs(formInputData.startDate).format("YYYY-MM-DD")} ${
            values.pattern.timeIn
          }`,
          end: `${dayjs(formInputData.endDate).format("YYYY-MM-DD")} ${
            values.pattern.timeOut
          }`,
        };

        const isWorkingHoliday =
          dayjs(formData.start).isSameOrAfter(findDate.start, "minute") &&
          dayjs(formData.end).isSameOrBefore(findDate.end, "minute");

        if (isWorkingHoliday) {
          if (userProfile.idPaymentType === 2) {
            formData.xWorkingMonthlyHoliday = formData.amountHours;
          } else {
            formData.xWorkingDailyHoliday = formData.amountHours;
          }
        } else {
          formData.xOTHoliday = formData.amountHours;
        }
      }
    }

    console.log(formData); 

    const result = await dispatch(addRequestTime(formData));
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
      handleCloseDialog();
    }
  };

  const renderTitle = () => {
    if (values.idRequestTime) {
      if (values.idRequestType === 2) {
        return "รายการขอทำงานล่วงเวลา";
      } else {
        return "รายการขอรับรองเวลาทำงาน";
      } 
      
    } else {
      return "เพิ่มรายการ";
    }
  };

  const renderManagerLV1 = () => {
    return (
      <Fragment>
        <Grid item xs={12} sm={4} style={{ display: "flex" }}>
          <StyledContentLabel>ลำดับที่ 1</StyledContentLabel>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>{formInputData.managerLV1Name}</Typography>
          <Typography color={"text.secondary"}>
            {formInputData.positionManagerLV1}
          </Typography>
          <div className="wrap-status">
            <StatusRequest
              boxSize={30}
              fontSize={20}
              borderRadius={8}
              status={
                formInputData.managerLV1ApproveDate === null
                  ? 0
                  : formInputData.managerLV1ApproveDate &&
                    formInputData.isManagerLV1Approve
                  ? 1
                  : 2
              }
            />
            <Typography>
              {formInputData.managerLV1ApproveDate === null
                ? "รออนุมัติ"
                : formInputData.managerLV1ApproveDate &&
                  formInputData.isManagerLV1Approve
                ? "อนุมัติ"
                : "ไม่อนุมัติ"}
            </Typography>
            &nbsp;
            {formInputData.managerLV1ApproveDate && (
              <Typography
                variant="body2"
                color="text.secondary"
              >{` — ${formInputData.managerLV1ApproveDate}`}</Typography>
            )}
          </div>
        </Grid>
      </Fragment>
    );
  };

  const renderManagerLV2 = () => {
    return (
      <Fragment>
        <Grid item xs={12} sm={4} style={{ display: "flex" }}>
          <StyledContentLabel>ลำดับที่ 2</StyledContentLabel>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>{formInputData.managerLV2Name}</Typography>
          <Typography color={"text.secondary"}>
            {formInputData.positionManagerLV2}
          </Typography>
          <div className="wrap-status">
            <StatusRequest
              boxSize={30}
              fontSize={20}
              borderRadius={8}
              status={
                formInputData.managerLV2ApproveDate === null
                  ? 0
                  : formInputData.managerLV2ApproveDate &&
                    formInputData.isManagerLV2Approve
                  ? 2
                  : 1
              }
            />
            <Typography>
              {formInputData.managerLV2ApproveDate === null
                ? "รออนุมัติ"
                : formInputData.managerLV2ApproveDate &&
                  formInputData.isManagerLV2Approve
                ? "อนุมัติ"
                : "ไม่อนุมัติ"}
            </Typography>
            &nbsp;
            {formInputData.managerLV2ApproveDate && (
              <Typography
                variant="body2"
                color="text.secondary"
              >{` — ${formInputData.managerLV2ApproveDate}`}</Typography>
            )}
          </div>
        </Grid>
      </Fragment>
    );
  };

  return (
    <DrawerCustom
      title={renderTitle()}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {formInputData && (
          <Grid container spacing={2}>
            {formInputData.idRequestTime && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    color={"text.third"}
                    style={{ fontSize: 14, fontWeight: 500 }}
                  >
                    ผู้อนุมัติ
                  </Typography>
                </Grid>
                {formInputData.isDoubleApproval ? (
                  <Fragment>
                    {renderManagerLV1()}
                    {renderManagerLV2()}
                  </Fragment>
                ) : formInputData.approvalLevel ? (
                  renderManagerLV1()
                ) : (
                  renderManagerLV2()
                )}
              </Fragment>
            )}
            {formInputData.idRequestTime && (
              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  color={"text.third"}
                  style={{ fontSize: 14, fontWeight: 500 }}
                >
                  รายละเอียด
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>ประเภท</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="idRequestType"
                  value={formInputData.idRequestType}
                  onChange={handleChange}
                  inputProps={{ readOnly: mode === "read" }}
                >
                  <MenuItem value={1}>ขอรับรองเวลาทำงาน</MenuItem>
                  <MenuItem value={2}>ขอค่าล่วงเวลา</MenuItem>
                  {/* <MenuItem value={4}>ขอค่าล่วงเวลาช่วงพักเที่ยง</MenuItem>
                  <MenuItem value={5}>อบรม</MenuItem>
                  <MenuItem value={6}>ปฏิบัติงานภายนอก</MenuItem>
                  <MenuItem value={7}>กิจกรรมอื่นๆของบริษัท</MenuItem> */}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>วันที่เริ่มต้น</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  readOnly={formInputData.idRequestType === 1 || mode === "read"}
                  value={formInputData.startDate}
                  onChange={(newDate) => {
                    console.log(newDate);
                    setFormData({ ...formInputData, ["startDate"]: newDate });
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme style={{ width: "100%" }} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>เวลาเริ่มต้น</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", marginRight: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="startHours"
                    unit="hour"
                    value={formInputData.startHours}
                    mode={mode}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="startMinute"
                    unit="minute"
                    step="1"
                    value={formInputData.startMinute}
                    mode={mode}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>วันที่สิ้นสุด</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  maxDate={
                    new Date(
                      dayjs(formInputData.startDate)
                        .add(1, "day")
                        .format("YYYY-MM-DD")
                    )
                  }
                  readOnly={mode === "read"}
                  value={formInputData.endDate}
                  onChange={(newDate) => {
                    setFormData({ ...formInputData, ["endDate"]: newDate });
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      style={{ width: "100%" }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>เวลาสิ้นสุด</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", marginRight: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="endHours"
                    unit="hour"
                    value={formInputData.endHours}
                    mode={mode}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="endMinute"
                    unit="minute"
                    step="1"
                    value={formInputData.endMinute}
                    mode={mode}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>เหตุผล</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <InputLabel id="demo-simple-select-label">เหตุผล</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="เหตุผล"
                  name="idRequestReason"
                  value={formInputData.idRequestReason}
                  onChange={handleChange}
                  inputProps={{ readOnly: mode === "read" }}
                >
                  <MenuItem value={1}>ทำต่อเนื่องเร่งด่วน</MenuItem>
                  <MenuItem value={2}>ทำงานกะในวันหยุด</MenuItem>
                  {/* <MenuItem value={3}>ส่งกะ</MenuItem>
                <MenuItem value={4}>ทำงานล่วงเวลากะ 4 หยุด 2</MenuItem>
                <MenuItem value={5}>ทำงานล่วงเวลากะ 5 หยุด 2</MenuItem>
                <MenuItem value={6}>แทนกะ/แลกกะ</MenuItem>
                <MenuItem value={7}>ทำงานวันหยุดไม่ใช่งานกะ</MenuItem>
                <MenuItem value={8}>Shut down/Turn around</MenuItem> */}
                  <MenuItem value={3}>อบรม</MenuItem>
                  <MenuItem value={4}>กิจกรรมอื่นๆของบริษัท</MenuItem>
                  <MenuItem value={5}>ปฏิบัติงานภายนอก</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>เหตุผลเพิ่มเติม</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <TextField
                  name="otherReason"
                  onChange={handleChange}
                  id="outlined-multiline-static"
                  label="เหตุผลเพิ่มเติม"
                  multiline
                  rows={4}
                  value={formInputData.otherReason}
                  InputProps={{
                    readOnly: mode === "read",
                  }}
                />
              </StyledFormControl>
            </Grid>
          </Grid>
        )}

        <StyledFooter>
          <ButtonBlue
            className="cancel"
            color="secondary"
            variant="text"
            onClick={handleCloseDialog}
          >
            ยกเลิก
          </ButtonBlue>
          {values.idRequestTime ? (
            <ButtonBlue
              variant="contained"
              color="error"
              startIcon={<DeleteOutlineIcon />}
              onClick={handleOpenDialogConfirmDelete}
            >
              ยกเลิกรายการ
            </ButtonBlue>
          ) : (
            <ButtonBlue variant="contained" onClick={handleSaveRequestTime}>
              บันทึกข้อมูล
            </ButtonBlue>
          )}
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogNewRequest;
