import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import * as React from "react";

const StyledTypographySecondary = styled(Typography)({
  color: "#999999",
  fontSize: 14,
});

const StyledTypography = styled(Typography)({
  fontSize: 20,
});

export default function DialogDeleteCandidate(props) {
  const { open, handleClose } = props;

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: 300 }}
      >
        <DialogTitle id="alert-dialog-title">{"ยกเลิกนัดหมายสัมภาษณ์"}</DialogTitle>
        <DialogContent>
          <Typography>
            <StyledTypographySecondary component="span">
              คุณแน่ใจ ?{" ยกเลิกนัดหมายสัมภาษณ์ "}
              <br />
            </StyledTypographySecondary>
            <div style={{ marginTop: 16, marginBottom: 16, textAlign: "center" }}>
              <span style={{ color: "#e46a76", fontSize: 20 }}>
                {`001-656435 (Vendor 1)`}{" "}
              </span>
            </div>
          </Typography>
          <StyledTypography>
            <StyledTypographySecondary component="span">
              วันที่:{" "}
            </StyledTypographySecondary>
            {`12 ธันวาคม 2564`}
          </StyledTypography>
          <StyledTypography>
            <StyledTypographySecondary component="span">
              เวลา:{" "}
            </StyledTypographySecondary>
            {`09:00 - 12:00`}
          </StyledTypography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#212B36" }}>ยกเลิก</Button>
          <Button variant="contained" style={{ backgroundColor: "#e46a76" }}>
          ยกเลิกนัดหมายสัมภาษณ์
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
