import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import BillingList from "./billingList"
import { useDispatch, useSelector } from "react-redux";
import { getCompanyContract } from "../../../../actions/vendor"

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
});

const Billscollected = () => {
  const dispatch = useDispatch();

  const { result: vendorContract } = useSelector((state) => state.vendorContract);
  // console.log('vendorContract',vendorContract);

  useEffect(() => {
    dispatch(getCompanyContract());
  }, []);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <BillingList
          vendorContract={vendorContract}
        />
      </Container>
    </StyledRoot>
  );
};

export default Billscollected;


