import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import AlertResponse from "../../../shared/general/AlertResponse";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import {
  DataGrid,
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  Export,
  ColumnChooser,
  ColumnChooserSearch,
  Position,
} from "devextreme-react/data-grid";

import {
  getBillingTableData,
} from "../../../../../actions/dumpExcel"
import StyledCard from "../../../shared/general/Card";
import { useHistory } from 'react-router-dom';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import loading from "../../../assets/social-media.gif";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
  {
    backgroundColor: "#ef5350",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 24,
});

const StyledLoading = styled("div")({
  marginTop: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  height: "inherit",
});

const billingTableData = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const idBillingStatement = props.match.params.idBillingStatement;
  // const selectedMonthYear = props.match.params.selectedMonthYear; 

  const selectedMonthYear = props.location.state.selectedMonthYear;


  const { result: billingStatements } = useSelector((state) => state.billingStatement);
  const [billingStatement, setBillingStatement] = useState(null);

  useEffect(() => {
    const fetchBillingStatement = async () => {
      try {
        let res = await dispatch(getBillingTableData(idBillingStatement));
        setBillingStatement(res);
      } catch (error) {
        console.error('Error fetching billing statement:', error);
      }
    };
    fetchBillingStatement();
  }, [idBillingStatement]);

  const { t, i18n } = useTranslation();
  const { result: vendorProfile } = useSelector((state) => state.vendorProfile);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const onSelectionChanged = ({ selectedRowsData }) => {
    handleOpenDialog();
    setSelectedRowsData(selectedRowsData[0]);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const gridRef = useRef(null);

  const onExporting = useCallback(() => {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet("SheetName");

    if (gridRef.current) {
      exportDataGrid({
        component: gridRef.current.instance,
        worksheet: worksheet,
        autoFilterEnabled: true,
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `BillingStatement.xlsx`
          );
        });
      });
    }
  }, []);

  const getColumnsFromData = (data) => {
    const columns = [];
    const columnCounter = {};
    const values = {};

    if (data && Array.isArray(data)) {
      data.forEach(item => {
        if (item.columnName) {
          let columnName = item.columnName;

          if (!columnCounter[columnName]) {
            columnCounter[columnName] = 0;
          }

          columnCounter[columnName]++;
          if (columnCounter[columnName] > 1) {
            columnName = `${item.columnName}_${columnCounter[columnName]}`;
          }

          columns.push({
            dataField: columnName,
            caption: item.columnName,
          });

          if (!values[item.row_number]) {
            values[item.row_number] = {};
          }

          values[item.row_number][columnName] = item.column_value;
        }
      });
    }
    return { columns, values: Object.values(values) };
  };

  const { columns, values } = getColumnsFromData(billingStatement);
  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4" gutterBottom>
              <Typography variant="h4" gutterBottom>
                {billingStatement && billingStatement[0].billingStatementName}{" "}
                {dayjs(billingStatement && billingStatement[0].billsPeriod)
                  .locale(localStorage.getItem("language") || "th")
                  .format(
                    localStorage.getItem("language") === "en"
                      ? "MMMM YYYY"
                      : "MMMM BBBB"
                  )}{" "}
              </Typography>
            </Typography>
            <IconButton
              edge="start"
              color="primary"
              onClick={() => {
                if (selectedMonthYear) {
                  history.push({
                    pathname: "/admin/billing-collected",
                    state: { selectedMonthYear } // ส่งค่ากลับไป
                  });
                } else {
                  console.log("ไม่มี selectedMonthYear ที่จะส่งกลับ");
                }
              }}
              sx={{
                border: '1px solid #ddd',
                borderRadius: '4px',
                padding: '8px',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
            >
              <ArrowBackIcon sx={{ mr: 1 }} />
              <Typography variant="body1">{t("Back")}</Typography>
            </IconButton>
          </Box>
        </div>
        <StyledCard>
          <StyledCardContent>
            <StyledWrapDataGrid>
              {billingStatement ? (
                <DataGrid
                  ref={gridRef}
                  dataSource={values ? values : []}
                  keyField="idBillingStatementDetail"
                  showBorders={false}
                  showColumnLines={false}
                  rowAlternationEnabled={true}
                  columnAutoWidth={true}
                  onSelectionChanged={onSelectionChanged}
                  onExporting={onExporting}
                >
                  <ColumnChooser enabled={true} mode={"select"}>
                    <Position
                      my="right top"
                      at="right bottom"
                      of=".dx-datagrid-column-chooser-button"
                    />

                    <ColumnChooserSearch
                      enabled={true}
                      editorOptions={{ placeholder: "Search column" }}
                    />

                  </ColumnChooser>
                  {columns.map((col, index) => (
                    <Column
                      key={index}
                      dataField={col.dataField}
                      caption={col.caption}
                      dataType="string"
                      cssClass="column-info"
                      alignment="center"
                    />
                  ))}

                  <Export enabled={true} allowExportSelectedData={true} />
                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={[10, 20, 30]}
                    showPageSizeSelector={true}
                    showNavigationButtons={true}
                    showInfo={true}
                  />
                  <Selection mode="single" />
                  <Sorting mode="multiple" />
                  <Scrolling columnRenderingMode="virtual" />
                  <FilterRow visible={false} />
                  <HeaderFilter visible={true} />
                  <SearchPanel
                    visible={true}
                    width={240}
                    placeholder="Search..."
                  />
                </DataGrid>
              ) : (
                <StyledLoading>
                  <img width="80" alt="loading" src={loading} />
                </StyledLoading>
              )}
            </StyledWrapDataGrid>
          </StyledCardContent>
        </StyledCard>
      </Container>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
};
export default billingTableData;
