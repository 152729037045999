import { BILLING_FETCHING, BILLING_FAILED, BILLING_SUCCESS } from "./types";
import {
  BILLING_COMPANYS_FETCHING,
  BILLING_COMPANYS_FAILED,
  BILLING_COMPANYS_SUCCESS,
  BILLING_VENDORS_FETCHING,
  BILLING_VENDORS_FAILED,
  BILLING_VENDORS_SUCCESS,
  BILLING_EMPLOYEES_FETCHING,
  BILLING_EMPLOYEES_FAILED,
  BILLING_EMPLOYEES_SUCCESS,
  BILLING_DETAIL_FETCHING,
  BILLING_DETAIL_FAILED,
  BILLING_DETAIL_SUCCESS,
} from "./types";

import BillingService from "../services/billing.service";

export const getAllBillingCompany = () => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_FETCHING,
    });
    const res = await BillingService.getAllBillingCompany();
    if (res) {
      dispatch({
        type: BILLING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: BILLING_FAILED,
    });
    console.log(err);
  }
};

export const getBillingDetail = (idPayrun) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_DETAIL_FETCHING,
    });
    const res = await BillingService.getBillingDetail(idPayrun);
    if (res) {
      dispatch({
        type: BILLING_DETAIL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: BILLING_DETAIL_FAILED,
    });
    console.log(err);
  }
};

export const getBillingAllCompanysByVendor = (monthYear) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_COMPANYS_FETCHING,
    });
    const res = await BillingService.getBillingAllCompanysByVendor(monthYear);
    if (res) {
      dispatch({
        type: BILLING_COMPANYS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_COMPANYS_FAILED,
    });
  }
};

export const getBillingAllVendorsByCompany = (monthYear) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_VENDORS_FETCHING,
    });
    const res = await BillingService.getBillingAllVendorsByCompany(monthYear);
    if (res) {
      dispatch({
        type: BILLING_VENDORS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_VENDORS_FAILED,
    });
  }
};

export const getBillingAllEmployeesByBilling = (monthYear, idBilling) => async (dispatch) => {
  try {
    dispatch({
      type: BILLING_EMPLOYEES_FETCHING,
    });
    const res = await BillingService.getBillingAllEmployeesByBilling(monthYear, idBilling);
    if (res) {
      dispatch({
        type: BILLING_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: BILLING_EMPLOYEES_FAILED,
    });
  }
};

export const editBillingDetail = (data) => async () => {
  try {
    const res = await BillingService.editBillingDetail(data);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const sendBilling = (idBilling) => async () => {
  try {
    const res = await BillingService.sendBilling(idBilling);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const uploadFileBilling = (data, idBilling) => async () => {
  try {
    const res = await BillingService.uploadFileBilling(data, idBilling);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deletedFileBilling = (idBilling) => async () => {
  try {
    const res = await BillingService.deletedFileBilling(idBilling);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getAllAdditionsByVender = async () => {
  try {
    const res = await BillingService.getAllAdditionsByVender();
    if (res) {
      return res;
    };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addBillingAddition = async (query) => {
  try {
    const res = await BillingService.addBillingAddition(query);
    if (res) {
      return res;
    };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateLumpSumBilling = async (idBilling) => {
  try {
    const res = await BillingService.updatedLumpSumBilling(idBilling);
    if (res) {
      return res;
    };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};