import React, { Fragment, useEffect, useState } from "react";
import { Autocomplete, Box, Button, Grid, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import DialogChangeWorkingType from "./DialogChangeWorkingType";
import { Controller, useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getCompanyContract, getEmployeesByVendorAndCompanyId } from "../../../../actions/vendor";
import { useDispatch, useSelector } from "react-redux";
import { getShift, getShiftScheduleByEmployeeId } from "../../../../actions/shift";
import dayjs from "dayjs";
import DatePickerCustom from "../../shared/date/datePicker";
import StatusRequest from "../../shared/general/stausRequest";

const StyledRoot = styled(Box)({
  paddingTop: "24px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
    "& .MuiFilledInput-root": {
      padding: "0px 12px",
      height: "56px",
    }
  },
  // "& .MuiDatePicker-root": {
    "& .MuiFilledInput-root": {
      padding: "0px 12px",
      height: "56px",
      "& input": {
        padding: "7px 4px",
      },
    }
  // }
})

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledButtonTime = styled(Button)(({ status, color }) => ({
  borderRadius: 8,
  color: "#212b36",
  borderColor: "#dcdcdc",
  textTransform: "none",
  ...(status === 0 && {
    borderColor: "#f5f5f5",
    color: "#212b36",
    backgroundColor: "#f5f5f5",
    "&:hover": {
      borderColor: "#f0f0f0",
      backgroundColor: "#f0f0f0",
    },
  }),
}));

const EmployeeShiftPanel = () => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: companyContract } = useSelector((state) => state.vendorContract);
  const { result: employeeList } = useSelector(state => state.employeeList);
  const { result: employeeShiftSchedule } = useSelector(state => state.employeeShiftSchedule);

  const [employeeShiftScheduleList, setEmployeeShiftScheduleList] = useState([]);

  const [dialogSwitchWorkingTypeConfig, setDialogSwitchWorkingTypeConfig] = useState({
    isOpen: false,
    data: {},
  })

  const validateYupSchema = yup.object({
    company: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    start: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    end: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    employee: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
  });

  const useHookForm = useForm({
    defaultValues: {
      company: null,
      start: new Date(dayjs().set('date', 1)),
      end: new Date(dayjs().set('date', dayjs().daysInMonth())),
      employee: null,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  const onSubmit = async (data) => {
    dispatch(getShiftScheduleByEmployeeId(data.employee.idEmp, {start: dayjs(data.start).format("YYYY-MM-DD"), end: dayjs(data.end).format("YYYY-MM-DD")})).then(res => {
      if(res && res.data){
        setEmployeeShiftScheduleList(res.data)
      }
    });
  }

  useEffect(() => {
    dispatch(getCompanyContract()).then(res => {
      if(res && res.data && res.data.length > 0){
        useHookForm.setValue("company", res.data[0])
        dispatch(getEmployeesByVendorAndCompanyId(res.data[0].idVendor, res.data[0].idCompany));
        dispatch(getShift(res.data[0].idCompany));
      }
    });
  }, [])

  const fetchingEmployees = (idVendor, idCompany) => {
    dispatch(getEmployeesByVendorAndCompanyId(idVendor, idCompany));
  }

  const fetchingShift = (idCompany) => {
    dispatch(getShift(idCompany));
  }

  const fetchingShiftSchedule = () => {
    useHookForm.handleSubmit(onSubmit)();
  }

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color="text.third" fontSize="14px" fontWeight="600" paddingBottom="4px">{t("Company")}</Typography>
                  <Controller
                    name="company"
                    control={useHookForm.control}
                    render={({field, fieldState}) => (
                      <Autocomplete
                        {...field}
                        options={companyContract? companyContract: []}
                        getOptionLabel={(option) => `${option.companyName}`}
                        isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.idCompany}>
                            <Box>
                              <Typography>{option.companyName}</Typography>
                              {/* <Typography color="text.secondary" fontSize="12px">{option.companyName}</Typography> */}
                            </Box>
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            placeholder={t("SelectData")}
                            onBlur={field.onBlur}
                            error={fieldState.error? true: false}
                            helperText={fieldState.error? fieldState.error.message: null}
                          />
                        )}
                        value={field.value}
                        onChange={(_, value) => {
                          field.onChange(value)
                          useHookForm.setValue("employee", null)
                          fetchingEmployees(value.idVendor, value.idCompany);
                          fetchingShift(value.idCompany);
                        }}
                        noOptionsText={t("NoData")}
                        disableClearable
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography color="text.third" fontSize="14px" fontWeight="600" paddingBottom="4px">{t("StartDate")}</Typography>
                  <Controller
                    name="start"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <DatePickerCustom
                        {...field}
                        views={["year", "month", "day"]}
                        inputFormat="DD MMMM YYYY"
                        openTo="day"
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            inputProps={{
                              ...params.inputProps,
                              placeholder: t("SelectDate"),
                              readOnly: true
                            }}
                            onBlur={field.onBlur}
                            error={fieldState.error? true : false}
                            helperText={fieldState.error? fieldState.error.message : null}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography color="text.third" fontSize="14px" fontWeight="600" paddingBottom="4px">{t("StartDate")}</Typography>
                  <Controller
                    name="end"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <DatePickerCustom
                        {...field}
                        views={["year", "month", "day"]}
                        inputFormat="DD MMMM YYYY"
                        openTo="day"
                        value={field.value}
                        onChange={(newValue) => {
                          field.onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            inputProps={{
                              ...params.inputProps,
                              placeholder: t("SelectDate"),
                              readOnly: true
                            }}
                            onBlur={field.onBlur}
                            error={fieldState.error? true : false}
                            helperText={fieldState.error? fieldState.error.message : null}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography color="text.third" fontSize="14px" fontWeight="600" paddingBottom="4px">{t("Employee")}</Typography>
                  <Controller
                    name="employee"
                    control={useHookForm.control}
                    render={({field, fieldState}) => (
                      <Autocomplete
                        {...field}
                        options={employeeList}
                        getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                        // isOptionEqualToValue={(option, value) => option.idEmp === value.idEmp}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option.idEmp}>
                            <Box>
                              <Typography>{option.firstname} {option.lastname}</Typography>
                            </Box>
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            variant="filled"
                            placeholder={t("SelectEmp")}
                            onBlur={field.onBlur}
                            error={fieldState.error? true: false}
                            helperText={fieldState.error? fieldState.error.message: null}
                          />
                        )}
                        value={field.value}
                        onChange={(_, value) => {
                          
                          field.onChange(value)
                        }}
                        noOptionsText={t("NoData")}
                        disableClearable
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3} container>
                  <Box paddingTop="25px">
                    <Box height="56px" display="flex" alignItems="center">
                      <ButtonBlue variant="contained" type="submit">{t("Search")}</ButtonBlue>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            {/* </LocalizationProvider> */}
          </form>
        </Grid>
        {employeeShiftScheduleList.length > 0 && <Grid item xs={12}>
          <CardStyle style={{marginTop: "24px"}}>
            <Box sx={{padding: "24px"}}>
              <TableContainer style={{ width: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledCellHeader align="center" style={{ maxWidth: "250px", width: "250px", boxSizing: "border-box"}}>วันที่</StyledCellHeader>
                      <StyledCellHeader align="center">เวลาทำงาน</StyledCellHeader>
                      <StyledCellHeader align="center">สถานะ</StyledCellHeader>
                      <StyledCellHeader align="center">หมายเหตุ</StyledCellHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employeeShiftSchedule.map(item => (
                      <StyledRowContent key={item.date}>
                        <StyledCellContent align="center" style={{ maxWidth: "250px", width: "250px", boxSizing: "border-box"}}>
                          <Typography fontWeight="500" fontSize="16px">{dayjs(item.date).locale(i18n.language).format(`DD MMM ${i18n.language === "th"? "BBBB": "YYYY"}`)}</Typography>
                          <Typography color="text.third" fontSize="16px">{dayjs(item.date).locale(i18n.language).format("(dddd)")}</Typography>
                        </StyledCellContent>
                        <StyledCellContent align="center">
                          <StyledButtonTime
                            style={{ width: 156 }}
                            variant="outlined"
                            status={(item.isWorkingDay || 0)}
                            disableFocusRipple={true}
                            disableRipple={true}
                            aria-label="more"
                            onClick={() => {
                              setDialogSwitchWorkingTypeConfig(prev => ({
                                ...prev,
                                isOpen: true,
                                data: {
                                  date: item.date,
                                  idShiftGroup: item.idShiftGroup,
                                  idShift: item.idShift,
                                  idShiftType: item.idShiftType,
                                  idEmp: item.idEmp,
                                }
                              }))
                            }}
                          >
                            <Box>
                              {item.isActive === 1 ?(
                                <Fragment>
                                  <Typography fontSize="12px" paddingBottom="4px">{item.nameShiftGroup} {item.idWorkingType === 1? `(${item.nameShift})`: ""}</Typography>
                                  <Typography fontWeight="600" fontSize="14px">{item.nameShiftType}</Typography>
                                </Fragment>
                              ) : (
                                <Typography fontSize="12px">{t("NoData")}</Typography>
                              )}
                            </Box>
                          </StyledButtonTime>
                        </StyledCellContent>
                        <StyledCellContent align="center">
                          {item.isEmployeeShiftDaily? (
                            <Box>
                              <Box display="flex" alignItems= "center" gap="4px" justifyContent="center">
                                <Box>
                                  <StatusRequest
                                    boxSize={40}
                                    fontSize={22}
                                    borderRadius={8}
                                    status={1}
                                    active={1}
                                  />
                                </Box>
                                <Box>
                                  <Typography variant="subtitle1" color="text.third">อนุมัติ</Typography>
                                </Box>
                              </Box>
                              <Typography color="text.secondary" variant="body2">
                                {t("Date")}: {item.createDate? dayjs(item.createDate).locale(i18n.language).format(`DD/MM/${i18n.language === "th"? "BBBB": "YYYY"} HH:mm`): "-"}
                              </Typography>
                            </Box>
                          ): null}
                        </StyledCellContent>
                        <StyledCellContent align="center"></StyledCellContent>
                      </StyledRowContent>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </CardStyle>
        </Grid>}
      </Grid>

      <DialogChangeWorkingType
        fetchingShiftSchedule={fetchingShiftSchedule}
        dialogConfig={dialogSwitchWorkingTypeConfig}
        onClose={() => {
          setDialogSwitchWorkingTypeConfig(prev => ({
            ...prev,
            isOpen: false,
          }))
        }}
      />
    </StyledRoot>
  )
}

export default EmployeeShiftPanel;