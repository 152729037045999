import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box,
    Grid,
    Typography
} from "@mui/material";

import {
    Groups
} from "@mui/icons-material";

import ChartCard from './chartCard';
import CardSummary from '../../../shared/dashboard/cardSummary';
import CardDark from '../../../shared/general/CardDark';

let MockIncomeAndExpenses = [
    {
        name:"รายรับ",
        data: [
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000)
        ]
    },
    {
        name:"รายจ่าย",
        data: [
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000)
        ]
    }
];

let MockOperatingCost = [
    {
        data: [
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000),
            Math.floor(Math.random() * 3000)
        ]
    }
];

const IncomeAndExpenses = () => {
    const dispatch = useDispatch();
    const { result: vendorContract } = useSelector(
        (state) => state.vendorContract
      ); 

      const sumEmployees = () => {
        return vendorContract.reduce((accumulator, object) => {
            return accumulator + object.numberEmployee;
          }, 0);
      }

    return (
        <div style={{ height:"fit-content", minHeight:"calc(100vh - 192px)", marginTop:"20px" }}>
            <Grid container>
                <Grid container item spacing={2}>
                    <Grid item xs={12} md={8}>
                        <CardSummary 
                            value={{
                                label: "พนักงาน(คน)",
                                amount: vendorContract ? sumEmployees() : 0,
                                icon: <Groups />,
                            }}
                            top={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <CardSummary 
                            value={{
                                label: "จำนวนคู่ค้า(บริษัท)",
                                amount: vendorContract ? vendorContract.length : 0,
                                icon: <i className="fa-solid fa-building" />,
                            }}
                            top={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CardSummary 
                            value={{
                                label: "จ่ายค่าแรง",
                                amount: 0,
                                icon: <i className="fa-solid fa-briefcase" />,
                            }}
                            top={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CardSummary 
                            value={{
                                label: "จ่าย OT",
                                amount: 0,
                                icon: <i className="fa-solid fa-clock" />,
                            }}
                            top={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CardSummary 
                            value={{
                                label: "จ่ายอื่นๆ",
                                amount: 0,
                                icon: <i className="fa-solid fa-box" />
                            }}
                            top={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CardSummary 
                            value={{
                                label: "รวมจ่าย",
                                amount: 0,
                                icon: <i className="fa-solid fa-money-bill" />,
                            }}
                            top={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CardSummary 
                            value={{
                                label: "รายรับค่าแรงรวม",
                                amount: 0,
                                icon: <i className="fa-solid fa-user-helmet-safety" />,
                            }}
                            top={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CardSummary 
                            value={{
                                label: "รายรับค่าดำเนินการ",
                                amount: 0,
                                icon: <i className="fa-solid fa-circle-dollar" />,
                            }}
                            top={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CardSummary 
                            value={{
                                label: "รวมรายรับ",
                                amount: 0,
                                icon: <i className="fa-solid fa-money-bills" />,
                            }}
                            top={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CardSummary 
                            value={{
                                label: "ส่วนต่าง",
                                amount: 0,
                                icon: <i className="fa-solid fa-sack-dollar" />,
                            }}
                            top={true}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop:"16px" }}>
                    <CardDark>
                        <Box style={{ width:"100%", display:"flex", flexDirection:"column", padding:"24px 24px 0" }}>
                            <Typography variant="body1" style={{ color: "#e6e5e8" }}>รายรับ-รายจ่าย</Typography>
                            <ChartCard data={MockIncomeAndExpenses} />
                        </Box>
                    </CardDark>
                </Grid>
                <Grid item xs={12} style={{ paddingTop:"16px" }}>
                    <CardDark>
                        <Box style={{ width:"100%", display:"flex", flexDirection:"column", padding:"24px 24px 0" }}>
                            <Typography variant="body1" style={{ color: "#e6e5e8" }}>ค่าดำเนินการ</Typography>
                            <ChartCard data={MockOperatingCost} />
                        </Box>
                    </CardDark>
                </Grid>
            </Grid>
        </div>
    );
};

export default IncomeAndExpenses;
