import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    Grid,
    Box,
    Typography
} from "@mui/material";
import Dropzone, { useDropzone } from "react-dropzone";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Close, TextSnippet } from '@mui/icons-material';

import AddPhotoIcon from "../../../../../../components/pages/assets/add.png"
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import {
  companyEmployeeType
} from "../../../../../../actions/company";

import {
  addEmployeeContractByIdEmp,
  getEmployeeProfile
} from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const StyledTextFieldTheme = styled(TextFieldTheme)({
    marginBottom: 0,
});

const StyledDropzone = styled(Box)({
    width: "100%",
    marginBottom: 12,
    backgroundColor: "#f9f9fb",
    "& .dropzone-leave": {
      // height: 98,
      borderRadius: 4,
      padding: 8,
      border: "1px dashed rgba(145, 158, 171, 0.32)",
    },
  
    "& .inner-dropzone": {
      cursor: "pointer",
      zIndex: 0,
      width: "100%",
      // height: 90,
      outline: "none",
      display: "flex",
      overflow: "hidden",
      borderRadius: 4,
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
      // backgroundColor: "#efefef",
      flexDirection: "column",
      padding: "4px 0",
      "& img": {
        width: 72,
        height: 72,
      },
      '& .deleteButton':{
        position:"absolute", 
        right: 8, 
        top: 8, 
        marginRight: 0, 
        cursor:"pointer",
        zIndex: 3
      }
    },
    "& .inner-dropzone:hover .placeholder": {
      zIndex: 2,
    },
  });

const DialogContract = (props) => {
    const dispatch = useDispatch();
    const { open, handleClose, idEmployeeContract, type, setAlertStatus } = props;
    const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
    const { result: employeeTypes } = useSelector((state) => state.employeeType);
    const [formData, setFormData] = useState({
        startDate: new Date(),
        endDate: new Date(),
        idEmployeeType: "",
        file: null,
    });

    useEffect(() => {
      if(employeeProfile && employeeProfile.idCompany){
        dispatch(companyEmployeeType(employeeProfile.idCompany));
      }
    },[employeeProfile]);

    useEffect(() => {
        if (open) {
            if (idEmployeeContract) {
                let targetCon = employeeProfile.contract.find((con) => con.idEmployeeContract === idEmployeeContract);
                setFormData({
                    ...formData,
                    startDate: targetCon.startDate,
                    endDate: targetCon.endDate,
                    idEmployeeType: targetCon.idEmploymentType,
                });
            } else {
                setFormData({
                    ...formData,
                    startDate: new Date(),
                    endDate: new Date(),
                    idEmployeeType: "",
                });
            }
        }
    }, [idEmployeeContract]);

    const handleChange = (event) => {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async () => {
      console.log("formData : ",formData);
      let data = new FormData();
      data.append("idEmp", employeeProfile.idEmp);
      data.append("startDate", dayjs(formData.startDate).format("YYYY-MM-DD"));
      data.append("endDate", dayjs(formData.endDate).format("YYYY-MM-DD"));
      data.append("idEmployeeType", formData.idEmployeeType === "" ? null : formData.idEmployeeType);
      if(formData.file){
        data.append("file", formData.file, formData.file.path);
      }
      
      let result = await dispatch(addEmployeeContractByIdEmp(data));
      if(result && result.status === 200){
        setAlertStatus({
          isOpen: true,
          type: "success",
          label: "เพิ่มสัญญาสำเร็จ"
        })
        dispatch(getEmployeeProfile(employeeProfile.idEmp));
      } else {
        setAlertStatus({
          isOpen: true,
          type: "error",
          label: "เพิ่มสัญญาไม่สำเร็จ"
        })
      }
      handleClose();
    };

    return (
        <DrawerCustom
            title="เพิ่มสัญญาการจ้างงาน"
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot>
              <Grid container spacing={2} style={{ marginBottom: 16 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={12} sm={6} className="GridTopicInput">เริ่มต้น</Grid>
                  <Grid item xs={12} sm={6}>
                      <DatePicker
                          value={formData.startDate}                                
                          inputFormat="dd/MM/yyyy"
                          name="startDate"
                          onChange={(newValue) => {
                              setFormData({ ...formData, ["startDate"]: newValue });
                          }}
                          renderInput={(params) => (
                              <TextFieldTheme {...params} fullWidth helperText={null} />
                          )}
                      />
                      
                  </Grid>
                  <Grid item xs={12} sm={6} className="GridTopicInput">สิ้นสุด</Grid>
                  <Grid item xs={12} sm={6}>
                      <DatePicker
                            value={formData.endDate}                                
                            inputFormat="dd/MM/yyyy"
                          name="endDate"
                          onChange={(newValue) => {
                              setFormData({ ...formData, ["endDate"]: newValue });
                          }}
                          renderInput={(params) => (
                              <TextFieldTheme {...params} fullWidth helperText={null} />
                          )}
                      />
                  </Grid>
                </LocalizationProvider>
                <Grid item xs={12} sm={6} className="GridTopicInput">ประเภทการจ้างงาน</Grid>
                <Grid item xs={12} sm={6}>
                  <TextFieldTheme
                    value={formData.idEmployeeType}
                    onChange={handleChange}
                    name="idEmployeeType"
                    select
                  >
                    {employeeTypes ? employeeTypes.map((type) => (
                      <MenuItem key={type.idEmployeeType} value={type.idEmployeeType}>{type.employeeTypeName}</MenuItem>
                    )) : (
                      <MenuItem key="none" value="" disabled>ไม่มีตัวเลือก</MenuItem>
                    )}
                  </TextFieldTheme>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="text.third" variant="body2" paddingBottom="16px">
                    แนบไฟล์
                  </Typography>
              
                  {!formData.file ? 
                    <StyledDropzone>
                      <Dropzone
                        accept="application/pdf"
                        maxFiles={1}
                        multiple={false}
                        onDrop={(acceptedFiles) => {
                          if(acceptedFiles.length > 0){
                            let fileInput = acceptedFiles.map((f) =>
                              Object.assign(f, {
                                preview: URL.createObjectURL(f),
                              })
                            );
                            setFormData({ ...formData, file: fileInput[0]});
                          }
                        }}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                        }) => (
                          <div {...getRootProps({ className: "dropzone-leave" })}>
                            <div className="inner-dropzone">
                              <input {...getInputProps()} />
                              <div style={{ textAlign: "center" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                  }}
                                >
                                  <img alt="add" src={AddPhotoIcon} />
                                </div>
                                <Typography
                                  variant="caption"
                                  style={{ lineHeight: 0.5, color: "#999999" }}
                                >
                                  {/* Allowed *.jpeg, *.jpg, *.png, *.pdf max size of 3 MB */}
                                  แนบไฟล์ PDF เท่านั้น
                                </Typography>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </StyledDropzone>
                    :
                    <StyledDropzone>
                      <div className="dropzone-leave">
                        <div
                          className="inner-dropzone"
                        >
                          <Close
                            className="deleteButton"
                            onClick={() => {
                              setFormData({ ...formData, file: null });
                            }}
                          />
                          <div
                            style={{
                              width:"100%",
                              height:"100%",
                              display:"flex",
                              flexDirection:"column",
                              justifyContent:"center",
                              alignItems:"center"
                            }}
                            onClick={() => {
                              window.open(formData.file.preview, "_blank");
                            }}
                          >
                            <TextSnippet style={{ fontSize: 64 }} />
                            <Typography style={{ marginTop: 16 }}>{formData.file.name}</Typography>
                          </div>
                        </div>
                      </div>
                    </StyledDropzone>
                  }
                </Grid>
              </Grid>
              <StyledFooter>
                <ButtonBlue className="cancel" onClick={handleClose}>ยกเลิก</ButtonBlue>
                <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
                    บันทึกข้อมูล
                </ButtonBlue>
              </StyledFooter>
            </StyledRoot>
        </DrawerCustom>
    );
};

export default DialogContract;
