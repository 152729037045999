import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Typography,
  Container,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";

const StyledWrapEmployeeList = styled("div")(({}) => ({
  paddingBottom: 120,
  ["@media (max-width: 1200px)"]: {
    paddingBottom: 140,
  },
  ["@media (max-width: 820px)"]: {
    paddingBottom: 60,
  },
  ["@media (max-width: 768px)"]: {
    paddingBottom: 50,
  },
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .employee-list": {
    display: "grid",
    gridTemplateRows: "1fr auto",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    gridAutoRows: 0,
    minWidth: 350,
    columnGap: 8,
    ["@media (max-width: 768px)"]: {
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
    },
    ["@media (max-width: 820px)"]: {
      gridTemplateRows: "1fr auto auto",
    },
    "& .employee-item": {
      minWidth: 100,
      marginTop: 24,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      width: 72,
      height: 72,
      marginBottom: 8,
    },
    "& .MuiTypography-root": {
      maxWidth: 120,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
}));

function EmployeeList(props) {
  const { result: employeeList } = useSelector((state) => state.employees);
  const { t, i18n } = useTranslation();

  const min1210 = useMediaQuery("(min-width:1210px)");
  const min1083 = useMediaQuery("(min-width:1083px)");
  const min953 = useMediaQuery("(min-width:953px)");
  const min820 = useMediaQuery("(min-width:820px)");
  const min697 = useMediaQuery("(min-width:697px)");
  const min553 = useMediaQuery("(min-width:553px)");

  return (
    <Fragment>
      {employeeList && (
        <StyledWrapEmployeeList>
          <div className="header">
            <Typography color="text.secondary">
              {t("EmployeeInTeam")}{" "}
              <span>{`(${employeeList.length} ${t("Person")})`}</span>
            </Typography>
            <Button variant="text">{t("All")}</Button>
          </div>
          <div className="employee-list">
            <div className="employee-item">
              <Avatar>
                <AddIcon />
              </Avatar>
              <Typography>{t("Add_Employee")}</Typography>
            </div>
            {employeeList
              .filter((emp, index) => {
                if(!emp.isTerminate){
                  if (min1210) {
                    return index < 17;
                  } else if (min1083) {
                    return index < 15;
                  } else if (min953) {
                    return index < 13;
                  } else if (min820) {
                    return index < 11;
                  } else if (min697) {
                    return index < 9;
                  } else if (min553) {
                    return index < 7;
                  } else {
                    return index < 5;
                  }
                }
              })
              .map((emp, index) => (
                <div className="employee-item">
                  <Avatar
                    src={`${process.env.REACT_APP_API_URL}image/vendor/${emp.idVendor}/${emp.personalId}.jpg`}
                  />
                  <Typography align="center">{`${emp.firstname}`}</Typography>
                  <Typography align="center">{`${emp.lastname}`}</Typography>
                </div>
              ))}
          </div>
        </StyledWrapEmployeeList>
      )}
    </Fragment>
  );
}

export default EmployeeList;
