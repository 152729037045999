import React, { Fragment, useState } from "react";
import { Button, Grid, styled, Typography } from "@mui/material";
import CardStyle from "../../shared/general/Card";
import { useDropzone } from "react-dropzone";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DrawerEditInfo from "./drawerEditInfo";

const StyledRoot = styled("div")({
  padding: 24,
  "& .dropzone-container": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
    margin: "auto",
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
    "& .inner-dropzone-config": {
      width: "100%",
      outline: "none",
      position: "relative",
      cursor: "pointer",
    },
    "& .company-logo": {
      height: 160,
    },
    "& .company-signature": {
      height: 100,
    },
    "& img": {
      objectFit: "cover",
      objectPosition: "center",
      width: "inherit",
      height: "inherit",
    },
    "& .placeholder-dropzone": {
      position: "absolute", 
      top: "50%", 
      left: "50%", 
      transform: "translate(-50%,-50%)", 
      textAlign: "center",
      "& .MuiTypography-root": {
        color: "#9e9e9e",
      }
    }
  },
  "& .captionText": {
    fontSize: 10,
    color: "#9e9e9e",
  }
})

const LeftPanel = (props) => {

  const { data } = props

  const [fileLogoSelected, setFileLogoSelected] = useState([]);
  const [fileSignatureSelected, setFileSignatureSelected] = useState([]);
  const [isOpenEditInfo, setIsOpenEditInfo] = useState(false);

  const { getRootProps: getRootPropsLogo , getInputProps: getInputPropsLogo } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileLogoSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const { getRootProps: getRootPropsSignature , getInputProps: getInputPropsSignature } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSignatureSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const previewLogo = () => {
    if (fileLogoSelected.length === 0) {
      return ( 
        <div className="placeholder-dropzone">
          <AddAPhotoIcon />
          <Typography>Upload</Typography>
        </div>
      );
    } else {
      return (
        <img
          alt={fileLogoSelected[0].name}
          src={fileLogoSelected[0].preview}
        />
      );
    }
  };

  const previewSignature = () => {
    if (fileSignatureSelected.length === 0) {
      return ( 
        <div className="placeholder-dropzone">
          <AddAPhotoIcon />
          <Typography>Upload</Typography>
        </div>
      );
    } else {
      return (
        <img
          alt={fileSignatureSelected[0].name}
          src={fileSignatureSelected[0].preview}
        />
      );
    }
  };

  return (
    <CardStyle>
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="headerText">Company Logo</Typography>
            <div {...getRootPropsLogo({ className: "dropzone-container" })}>
              <div className="inner-dropzone-config company-logo">
                <input {...getInputPropsLogo()} />
                <Fragment>{previewLogo()}</Fragment>
              </div>
            </div>
            <Typography className="captionText">
              Allowed *.jpeg, *.jpg, *.png and max size of 3 MB
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="headerText">Company Name</Typography>
            <Typography className="valueText">{data.companyName || "None"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="headerText">Company Name Short</Typography>
            <Typography className="valueText">{data.companyNameShort || "None"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="headerText">Company Tel</Typography>
            <Typography className="valueText">{data.companyTel || "None"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="headerText">Contract Name</Typography>
            <Typography className="valueText">{data.mainContactName || "None"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="headerText">Contract Phone</Typography>
            <Typography className="valueText">{data.mainContactPhone || "None"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="headerText">Contract Email</Typography>
            <Typography className="valueText">{data.mainContactEmail || "None"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="headerText">Company Signature</Typography>
            <div {...getRootPropsSignature({ className: "dropzone-container" })}>
              <div className="inner-dropzone-config company-signature">
                <input {...getInputPropsSignature()} />
                <Fragment>{previewSignature()}</Fragment>
              </div>
            </div>
            <Typography className="captionText">
              Allowed *.jpeg, *.jpg, *.png and max size of 3 MB
            </Typography>
          </Grid>

          <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
            <Button variant="outlined" onClick={()=>{setIsOpenEditInfo(true)}}>แก้ไข</Button>
          </Grid>
        </Grid>
      </StyledRoot>

      {isOpenEditInfo && <DrawerEditInfo open={isOpenEditInfo} handleClose={()=>{setIsOpenEditInfo(false)}} data={data}/>}
    </CardStyle>
  )
}

export default LeftPanel;