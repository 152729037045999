const vendor = [
    {
        idVendor: 1,
        name: "Vertex"
    },
    {
        idVendor: 2,
        name: "CC Content"
    },
    {
        idVendor: 3,
        name: "KEC"
    },
    {
        idVendor: 4,
        name: "Lucky Dragon"
    },
    
];

export default vendor