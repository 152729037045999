import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuizIcon from '@mui/icons-material/Quiz';
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogEdit from "./DialogEdit";
import dayjs from "dayjs";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getProbationResult } from "../../../../../../actions/probation";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const Probation = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: probationAnswer } = useSelector((state) => state.probationAnswer);
  const { t, i18n } = useTranslation();
  const thaiLanguage = i18n.resolvedLanguage === "th"
  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const onDrawerClose = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  useEffect(() => {
    dispatch(getProbationResult(id))
  }, [])

  return (
    <div>
      {probationAnswer && probationAnswer.map((item, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={12} container alignItems="center">
            <Typography flexGrow="1" fontSize="16px" fontWeight="600">
              {`${t("ProbationResult")} ${item.probationRound}`}
            </Typography>
            {!userProfile.readOnly && item.idProbation && (
              <ButtonBlue
                variant="text"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => {
                  setDrawerConfig((prev) => ({
                    ...prev,
                    isOpen: true,
                    data: {
                      idProbation: item.idProbation,
                      round: item.probationRound,
                      start: dayjs(item.start).format(thaiLanguage ? "DD MMMM BBBB" : "DD MMMM YYYY"),
                      end: dayjs(item.end).format(thaiLanguage ? "DD MMMM BBBB" : "DD MMMM YYYY"),
                      result: item.result,
                      remark: item.remark,
                    },
                  }));
                }}
              >
                {t("Edit")}
              </ButtonBlue>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              {t("StartDate")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {dayjs(item.start).format(thaiLanguage ? "DD MMMM BBBB" : "DD MMMM YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              {t("EndDate")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {dayjs(item.end).format(thaiLanguage ? "DD MMMM BBBB" : "DD MMMM YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              {t("ProbationResult")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {item.result === 1
                ? t("PassedProbation")
                : item.result === 0
                ? t("NotPassedProbation")
                : t("WaitingForEvaluation")}
            </Typography>
            {item.approvedBy && (
              <Typography
                fontSize="14px"
                color="text.secondary"
                fontStyle="italic"
              >
                {`${t("Assessor")} : ${item.approvedBy}`}
              </Typography>
            )}
            {item.approveDate && (
              <Typography
                fontSize="14px"
                color="text.secondary"
                fontStyle="italic"
              >
                {dayjs(item.approveDate).format(
                  thaiLanguage ? "DD MMMM BBBB" : "DD MMMM YYYY"
                )}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              {t("Description")}
            </StyledHeadLabel>
            <Typography
              fontSize="14px"
              color="text.secondary"
              fontStyle="italic"
              whiteSpace="pre-line"
              style={{ wordBreak: "break-word" }}
            >
              {item.remark ? item.remark : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }}/>}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ bgcolor: "#1976d2", color: "#ffffff", borderRadius: "4px" }}
              >
                {t("Details")}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>     
                  {item.GroupProbation.map((value, index) => (
                    <Grid item xs={12} key={index}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <QuizIcon/>
                        <Typography>{value.question}</Typography>
                      </Stack>
                      <Typography padding={1}>{value.answer}</Typography>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Divider />
                    <Typography fontWeight={500}>{t("Strength")}</Typography>
                    <Typography padding={1}>{item.goodPoint}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                    <Typography fontWeight={500}>{t("Weakness")}</Typography>
                    <Typography padding={1}>{item.weakPoint}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                    <Typography fontWeight={500}>{t("Other")}</Typography>
                    <Typography padding={1}>{item.others}</Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {employeeProfile.probation.length !== index + 1 && (
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
          )}
        </Grid>
      ))}
      <DialogEdit drawerConfig={drawerConfig} handleCloseDrawer={onDrawerClose} />
    </div>
  );
};

export default Probation;
