import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import FormContainer from "./FormContainer";
import { useTranslation } from "react-i18next";

const EmployeeType = (props) => {
  const { formik } = props;
  const { t, i18n } = useTranslation();

  return (
    <FormContainer title={`${t("EmployeeType")}`}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={4}>
          <Typography>{t("WorkType")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl component="fieldset">
            <RadioGroup
              name="employmentTime"
              value={formik.values.employmentTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // error={formik.touched.employmentTime && Boolean(formik.errors.employmentTime)}
            >
              <FormControlLabel
                value="Full time"
                label="Full time"
                control={<Radio />}
              />
              <FormControlLabel
                value="Part time"
                label="Part time"
                control={<Radio />}
              />
            </RadioGroup>
            {formik.touched.employmentTime &&
              Boolean(formik.errors.employmentTime) && (
                <FormHelperText error>
                  {formik.errors.employmentTime}
                </FormHelperText>
              )}
          </FormControl>
        </Grid> */}
        <Grid item xs={12} md={4}>
          <Typography>{t("PaymentType")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl component="fieldset">
            <RadioGroup
              name="idPaymentType"
              value={formik.values.idPaymentType}
              onChange={(event) => {
                formik.setFieldValue("idPaymentType", event.target.value, true);
                if (event.target.value === "1") {
                  formik.setFieldValue("paymentRound", "รายครึ่งเดือน");
                } else if (event.target.value === "2") {
                  formik.setFieldValue("paymentRound", "รายเดือน");
                } else if (event.target.value === "4") {
                  formik.setFieldValue("paymentRound", "รายครึ่งเดือน");
                } else if (event.target.value === "5") {
                  formik.setFieldValue("paymentRound", "รายเดือน");
                }
              }}
              onBlur={formik.handleBlur}
              // error={formik.touched.idPaymentType && Boolean(formik.errors.idPaymentType)}
            >
              <FormControlLabel
                value="1"
                label={`${t("Daily")}`}
                control={<Radio />}
              />
              <Typography color="textSecondary" variant="body1">
                {`${t("Payment")}: ${t("HalfMonth")}`}
              </Typography>
              <FormControlLabel
                value="5"
                label={`${t("Daily")}`}
                control={<Radio />}
              />
              <Typography color="textSecondary" variant="body1">
                {`${t("Payment")}: ${t("Monthly")}`}
              </Typography>
              <FormControlLabel
                value="4"
                label={`${t("Monthly")}`}
                control={<Radio />}
              />
              <Typography color="textSecondary" variant="body1">
                {`${t("Payment")}: ${t("HalfMonth")}`}
              </Typography>
              <FormControlLabel
                value="2"
                label={`${t("Monthly")}`}
                control={<Radio />}
              />
              <Typography color="textSecondary" variant="body1">
                {`${t("Payment")}: ${t("Monthly")}`}
              </Typography>
            </RadioGroup>
            {formik.touched.idPaymentType &&
              Boolean(formik.errors.idPaymentType) && (
                <FormHelperText error>
                  {formik.errors.idPaymentType}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default EmployeeType;
