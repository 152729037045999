import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertResponse from "../../../shared/general/AlertResponse";
import DrawerCustom from "../../../shared/general/Drawer";
// import { getEmailReportById } from "../../../../../actions/email";
import { styled } from "@mui/material/styles";
import { TextField, Grid, Popper, Box } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import SaveIcon from "@mui/icons-material/Save";
import ButtonBlue from "../../../shared/general/ButtonBlue";
// import { addEmailReport } from "../../../../../actions/email";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const Contract = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState(null);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { open, handleClose, data, setData } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  useEffect(() => {
    if (email && userProfile)
      setData({ ...data, autoEmail: email.day, idEmp: userProfile.idEmp });
  }, [email])

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  // const fetchedEmailReportById = async () => {
  //   try {
  //     const response = await getEmailReportById(data.idEmp, data.ReportName);
  //     if (response && response.data) {
  //       setEmail(response.data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  
  // useEffect(() => {
  //   fetchedEmailReportById();
  // }, [data]);

  const handleSubmit = async () => {
    // const formData = {
    //   ReportName: data.ReportName,
    //   idEmp: data.idEmp,
    //   day: data.autoEmail == `${t("LastDayOfMonth")}` ? 0 : data.autoEmail,
    // };
    // const result = await addEmailReport(formData);
    // if (result && result.status === 200) {
    //   handleChangeAlertType(`${t("Successfully")}`);
    //   handleOpenAlert(true);
    // } else {
    //   handleChangeAlertType(`${t("Unsuccessful")}`);
    //   handleOpenAlert(true);
    // }
  };

  return (
    <DrawerCustom
      title={`${t("SetUpAutomaticEmailSendDate")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot sx={{ width: 470 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {`${t("EmailSentDate")}`}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledBoxSearch>
              <StyledAutocomplete
                options={[
                  `${t("LastDayOfMonth")}`,
                  1,
                  2,
                  3,
                  4,
                  5,
                  6,
                  7,
                  8,
                  9,
                  10,
                  11,
                  12,
                  13,
                  14,
                  15,
                  16,
                  17,
                  18,
                  19,
                  20,
                  21,
                  22,
                  23,
                  24,
                  25,
                  26,
                  27,
                  28,
                ]}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setData({ ...data, autoEmail: newValue });
                  } else {
                    setData({ ...data, autoEmail: null });
                  }
                }}
                value={
                  data.autoEmail == 0
                    ? `${t("LastDayOfMonth")}`
                    : data.autoEmail
                }
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"filled"}
                    placeholder={`${t("SelectDate")}`}
                  />
                )}
                PopperComponent={StyledPopper}
              />
            </StyledBoxSearch>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "16px",
            }}
          >
            <ButtonBlue
              variant="outlined"
              startIcon={<SaveIcon />}
              onClick={handleSubmit}
              disabled={data.autoEmail === null}
            >
              {`${t("Save")}`}
            </ButtonBlue>
          </Grid>
        </Grid>
      </StyledRoot>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};
export default Contract;
