import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Container, Grid, Avatar, Typography, Box } from "@mui/material";

import HeaderPage from "./../../shared/header/headerPage";

import { getCompanyContract } from "../../../../actions/vendor";
import { getUserProfile } from "../../../../actions/user";

import Deal from "../../assets/deal.png";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .header": {
    paddingTop: 20,
    paddingBottom: 60,
  },
});

const StyledContent = styled(Box)({
  textDecoration: "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 20,
  padding: "40px 24px",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "rgb(145 158 171 / 24%) -24px 24px 72px -8px",
  },
  "& .MuiAvatar-root": {
    width: 100,
    height: 100,
    marginBottom: 16,
    backgroundColor: "#FFFFFF",
  },
  "& .MuiTypography-h6": {
    fontSize: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 220,
  },
});

const ContractCompany = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: vendorContract } = useSelector(
    (state) => state.vendorContract
  );

  useEffect(() => {
    dispatch(getCompanyContract());
  }, []);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className="header">
          <HeaderPage textLabel={`${t("BusinessPartners")}`} />
        </div>
        <Grid container spacing={2} justifyContent="center">
          {vendorContract &&
            vendorContract.map((company, index) => (
              <Grid item xs={12} sm={6} md={3}>
                <StyledContent
                  key={company.idCompany}
                  component={NavLink}
                  to={`/vendor/contract/profile/${company.idCompany}`}
                >
                  <Avatar
                    variant="rounded"
                    src={`${process.env.REACT_APP_API_URL}image/company/${company.companyImage}`}
                  >
                    <Avatar src={Deal} />
                  </Avatar>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    gutterBottom
                  >{`${company.numberEmployee} ${t("Person")}`}</Typography>
                  <Typography variant="h6" align="center" gutterBottom>
                    {company.companyName
                      ? company.companyName
                          .replace(`${t("Company")}`, "")
                          .replace(`${t("Ltd")}`, "")
                          .trim()
                      : "-"}
                  </Typography>
                </StyledContent>
              </Grid>
            ))}
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default ContractCompany;
