import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import TableApproveList from "./tableApproveList";

const StyledWrapHistory = styled("div")({
  marginTop: 16,
});

function TableOTApproval(props) {
  const { menu, searchName } = props;
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const [approvalSelected, setApprovalSelected] = useState([]);

  const renderRequestTime = () => {
    let temp = [...requestTimeList];

    temp = temp.filter((x) => x.idRequestType === menu);

    temp = temp.filter((x) => {
      if (x.approvalLevel === 1 && x.isManagerLV1Approve !== null && x.isActive === 1) {
        return x;
      } else if (x.approvalLevel === 2 && x.isManagerLV2Approve !== null && x.isActive === 1) {
        return x;
      }
    });

    temp.forEach((element) => {
      if (element.approvalLevel === 1) {
        element.isApprove = element.isManagerLV1Approve;
      } else if (element.approvalLevel === 2) {
        element.isApprove = element.isManagerLV2Approve;
      }
    });

    temp.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });

    return temp;
  };

  return (
    <Box style={{ marginTop: 4 }}>
      <StyledWrapHistory>
        <div>
          <TableApproveList
            searchName={searchName}
            checkbox={false}
            dataTable={requestTimeList ? renderRequestTime() : []}
            approvalSelected={approvalSelected}
            setApprovalSelected={setApprovalSelected}
          />
        </div>
      </StyledWrapHistory>
    </Box>
  );
}

export default TableOTApproval;
