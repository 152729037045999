import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, styled, Grid, IconButton, Divider } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../shared/general/ButtonBlue";

// import DialogAddress from "./DialogAddress";

import DrawerAddress from "./DrawerAddress";

import { updateUser, getUserProfile } from "../../../../actions/user";

const StyledWrapBranch = styled("div")({
  minHeight: 350,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const LocationTab = (props) => {
  const { handleChangeAlertType, handleOpenAlert } = props;
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [openDialogAddress, setOpenDialogAddress] = useState(false);
  const [drawerAddressConfig, setDrawerAddressConfig] = useState({
    isOpen: false,
    data: {}
  })

  // const handleCloseDialog = () => {
  //   console.log("handleCloseDialog")
  //   setOpenDialogAddress(false);
  // };

  const handleCloseDrawerAddress = () => {
    setDrawerAddressConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleOpenDrawerAddress = (data) => {
    setDrawerAddressConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        idEmp: data.idEmp,
        houseNo: data.houseNo,
        vilage: data.vilage,
        vilageNo: data.vilageNo,
        alley: data.alley,
        road: data.road,
        subDistrict: data.subDistrict,
        district: data.district,
        provience: data.provience,
        areaCode: data.areaCode,
      }
    }))
  }

  return (
    <div>
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("Address")}
          </StyledHeadLabel>
          <div>
            <ButtonBlue
              size="small"
              startIcon={<EditIcon />}
              onClick={() => {
                handleOpenDrawerAddress(userProfile)
              }}
            >
              {t("Edit")}
            </ButtonBlue>
          </div>
        </div>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={6} md={4}>
            <StyledHeadLabel color="text.secondary">{t("HouseNumber")}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.houseNo ? userProfile.houseNo : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <StyledHeadLabel color="text.secondary">{t("Village")}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.vilage ? userProfile.vilage : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <StyledHeadLabel color="text.secondary">{t("VillageNo")}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.vilageNo ? userProfile.vilageNo : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <StyledHeadLabel color="text.secondary">{t("Alley")}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.alley ? userProfile.alley : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <StyledHeadLabel color="text.secondary">{t("Road")}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.road ? userProfile.road : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <StyledHeadLabel color="text.secondary">{t("SubdistrictArea")}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.subDistrict ? userProfile.subDistrict : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <StyledHeadLabel color="text.secondary">{t("DistrictArea")}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.district ? userProfile.district : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <StyledHeadLabel color="text.secondary">{t("Province")}</StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.provience ? userProfile.provience : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <StyledHeadLabel color="text.secondary">
              {t("PostalCode")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.areaCode ? userProfile.areaCode : "-"}
            </Typography>
          </Grid>
        </Grid>
      </StyledWrapBranch>

      {/* <DialogAddress
        open={openDialogAddress}
        handleCloseDialog={handleCloseDialog}
        handleChangeAlertType={handleChangeAlertType}
        handleOpenAlert={handleOpenAlert}
      /> */}
      <DrawerAddress
        drawerConfig={drawerAddressConfig}
        handleClose={handleCloseDrawerAddress}
      />
    </div>
  );
};

export default LocationTab;
