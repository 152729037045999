import {
  JOBLEVELS_FETCHING,
  JOBLEVELS_FAILED,
  JOBLEVELS_SUCCESS,
  PERSONNEL_LEVELS_FETCHING,
  PERSONNEL_LEVELS_FAILED,
  PERSONNEL_LEVELS_SUCCESS,
} from "./types";
import JobLevelService from "../services/jobLevel.service";

export const getJobLevlByidCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: JOBLEVELS_FETCHING,
    });
    const res = await JobLevelService.getJobLevlByidCompany(idCompany);
    if (res) {
      dispatch({
        type: JOBLEVELS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: JOBLEVELS_FAILED,
    });
  }
};

export const getPersonnelLevelByidCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: PERSONNEL_LEVELS_FETCHING,
    });
    const res = await JobLevelService.getPersonnelLevelByidCompany(idCompany);
    if (res) {
      dispatch({
        type: PERSONNEL_LEVELS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PERSONNEL_LEVELS_FAILED,
    });
    return err;
  }
};

export const addNewJobLevel = (idCompany, data) => async () => {
  try {
    const res = JobLevelService.addNewJobLevel(idCompany, data);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};

export const switchJobLevel = (data) => async () => {
  try {
    const res = JobLevelService.switchJobLevel(data);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};

export const updateJobLevel = (idJobLevel, data) => async () => {
  try {
    const res = JobLevelService.updateJobLevel(idJobLevel, data);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};

export const deleteJobLevel = (idJobLevel) => async () => {
  try {
    const res = JobLevelService.deleteJobLevel(idJobLevel);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error);
    return "Error";
  }
};
