import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableApproveList from "./tableApproveList";
import { useTranslation } from "react-i18next";

import {
  updateApproveLeaveEmployeeBy,
  getAllLeaveWithDrawBy,
} from "../../../../actions/leave";
import { getLeaveRequest } from "../../../../actions/employee";

import DialogCommentReject from "./DialogCommentReject";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';

import ButtonBlue from "../../shared/general/ButtonBlue";
import AlertResponse from "../../shared/general/AlertResponse";
import CardRequest from "./CardRequest";

const StyledBoxWrapApprove = styled("div")({
  marginBottom: 16,
  "& .wrap-btn": {
    display: "flex",
    justifyContent: "flex-end",
  },
  ["@media only screen and (max-width:600px)"]: {
    "& .wrap-btn": {
      display: "flex",
      justifyContent: "space-around",
      paddingTop:"24px"
    },
  },
});

const StyledNotApprove = styled(Button)({
  color: "#ffffff",
  backgroundColor: "#d32f2f",
  borderColor: "#d32f2f",
  borderRadius: 8,
  "&:hover": {
    backgroundColor: "#c62828",
    borderColor: "#c62828",
  },
});

const StyledButtonCancel = styled(ButtonBlue)({
  // backgroundColor:"#D32F2F",
  borderRadius:"8px",
  border:"1px solid #D32F2F",
  color:"#D32F2F",
  marginRight: 8,
  width: 130,
  "&:hover":{
    backgroundColor:"#FCF1F1"
  }
});

function TableApproval(props) {
  const { searchName, searchDate, setNumberOfList } = props;
  const dispatch = useDispatch();
  const mobileResponsive = useMediaQuery('(max-width:600px)');
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );
  const { result: managerId } = useSelector((state) => state.managerId);
  const [approvalSelected, setApprovalSelected] = useState([]);
  const [pendingRows, setPendingRows] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openDialogReject, setOpenDialogReject] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let tempLeaveList = renderLeave();
    setPendingRows(tempLeaveList);
    setNumberOfList(tempLeaveList.length);
  },[leaveEmployeesList, leaveEmployeeWithdrawsList]);

  const handleApprovalRequest = async (approve, comment) => {
    // console.log("managerId: ", managerId);
    let managerIdList = managerId.map((x) => x.idManagerEmployee);

    const idLeave = approvalSelected
      .filter((x) => {
        return x.idLeave && !x.idLeaveEmployeesWithdraw;
      })
      .map(function (user) {
        return user.idLeave;
      });

    const idLeaveEmployeesWithdraw = approvalSelected
      .filter((x) => {
        return x.idLeaveEmployeesWithdraw;
      })
      .map(function (user) {
        return user.idLeaveEmployeesWithdraw;
      });

    const idLeaveWithdraw = approvalSelected
      .filter((x) => {
        return (
          x.idLeaveEmployeesWithdraw && managerIdList.includes(x.managerApprove)
        );
      })
      .map(function (user) {
        return user.idLeave;
      });

    var formData = [
      {
        idLeave: idLeave,
        isApprove: approve,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
        ...(comment ? { comment: comment } : {}),
      },
      {
        idLeaveEmployeesWithdraw: idLeaveEmployeesWithdraw,
        idLeave: idLeaveWithdraw,
        isApprove: approve,
        ...(comment ? { comment: comment } : {}),
      },
    ];

    setOpenDialogReject(false);
    const result = await dispatch(
      updateApproveLeaveEmployeeBy("manager", formData)
    );
    if (result) {
      setApprovalSelected([]);
      handleOpenAlert();
      if (result.status === 200) {
        if(searchDate.start && searchDate.end){
          dispatch(getLeaveRequest("manager", searchDate));
          dispatch(getAllLeaveWithDrawBy("manager", searchDate));
        } else {
          dispatch(getLeaveRequest("manager"));
          dispatch(getAllLeaveWithDrawBy("manager"));
        }
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const renderLeave = () => {
    let temp = [...leaveEmployeesList ? leaveEmployeesList : []];
    temp = temp.filter((x) => {
      if (x.isApprove === null) {
        return x;
      }
    });

    let tempWithDraw = leaveEmployeeWithdrawsList ? leaveEmployeeWithdrawsList.filter(
      (x) => x.isApprove === null
    ) : [];
    temp = [...temp, ...tempWithDraw];

    temp.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });

    return temp;
  };

  const handleClickAll = () => {
    setApprovalSelected(pendingRows);
  };

  const handleCancel = () => {
    setApprovalSelected([]);
  };

  return (
    <Box>
      <StyledBoxWrapApprove>
        {mobileResponsive && (
          <Box display="flex" justifyContent="flex-end">
            {(approvalSelected.length > 0) && 
              <StyledButtonCancel
                startIcon={<DeleteIcon />}
                onClick={handleCancel}
              >
                {`ยกเลิก`}
              </StyledButtonCancel>
            }
            {!(approvalSelected.length === pendingRows.length) &&
              <ButtonBlue
                startIcon={<DoneAllIcon />}
                variant="outlined"
                onClick={handleClickAll}
              >
                {`เลือกทั้งหมด`}
              </ButtonBlue>
            }
          </Box>
        )}
        {approvalSelected.length > 0 &&
          <div className="wrap-btn">
            <ButtonBlue
              style={{ marginRight: 8 }}
              startIcon={<DoneAllIcon />}
              variant="contained"
              onClick={() => handleApprovalRequest(1)}
            >
              {`${t("Approve")} ${
                approvalSelected.length > 0
                  ? `${approvalSelected.length} ${t("Items")}`
                  : ""
              }`}
            </ButtonBlue>
            <StyledNotApprove
              startIcon={<CloseIcon />}
              variant="outlined"
              onClick={() => setOpenDialogReject(true)}
            >
              {`${t("Disapproval")} ${
                approvalSelected.length > 0
                  ? `${approvalSelected.length} ${t("Items")}`
                  : ""
              }`}
            </StyledNotApprove>
          </div>
        }
      </StyledBoxWrapApprove>
      {openDialogReject && (
        <DialogCommentReject
          open={openDialogReject}
          onClose={() => setOpenDialogReject(false)}
          handleSubmit={(comment) => {
            handleApprovalRequest(0, comment);
          }}
        />
      )}
      {!mobileResponsive ? 
        <TableApproveList
          checkbox={true}
          dataTable={
            leaveEmployeesList && leaveEmployeeWithdrawsList
              ? pendingRows
              : []
          }
          approvalSelected={approvalSelected}
          setApprovalSelected={setApprovalSelected}
          searchName={searchName}
          setNumberOfList={setNumberOfList}
        />
        :
        <div>
          {(pendingRows && pendingRows.length > 0) ?
            pendingRows.map(row => (
              <CardRequest 
                row={row}
                selectedRows={approvalSelected} 
                setSelectedRows={setApprovalSelected}
              />
            ))
            :
            <div>
              <Typography align="center">{t("No_Data")}</Typography>
            </div>
          }
        </div>
      }

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </Box>
  );
}

export default TableApproval;
