import {
  USERPROFILE_FETCHING,
  USERPROFILE_FAILED,
  USERPROFILE_SUCCESS,
  USERS_FETCHING,
  USERS_FAILED,
  USERS_SUCCESS,
  USERS_VENDOR_FETCHING,
  USERS_VENDOR_FAILED,
  USERS_VENDOR_SUCCESS,
  USER_PF_SCHEDULE_FETCHING,
  USER_PF_SCHEDULE_FAILED,
  USER_PF_SCHEDULE_SUCCESS,
  USER_PROVIDENT_FUND_FETCHING,
  USER_PROVIDENT_FUND_FAILED,
  USER_PROVIDENT_FUND_SUCCESS,
} from "./types";

import UserService from "../services/user.service";

import { logout } from "./auth";
import userService from "../services/user.service";

export const getUserProfile = () => async (dispatch) => {
  try {
    const res = await UserService.getUserProfile();
    if (res) {
      dispatch({
        type: USERPROFILE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USERPROFILE_FAILED,
    });
    //dispatch(logout())
  }
};

export const getAllUsersCompany = (idCompany, filter) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_FETCHING,
    });
    const res = await UserService.getAllUsersCompany(idCompany, filter);
    if (res) {
      dispatch({
        type: USERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USERS_FAILED,
    });
    console.log(err);
  }
};

export const getAllUsersManager = (idManager) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_FETCHING,
    });
    const res = await UserService.getAllUsersManager();
    if (res) {
      dispatch({
        type: USERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USERS_FAILED,
    });
    console.log(err);
  }
};

export const addUsers = (formData) => async () => {
  try {
    const res = await UserService.addUsers(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};
export const addUsersFirstTime = (formData) => async () => {
  try {
    const res = await UserService.addUsersFirstTime(formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const addUsersOne = (formData) => async () => {
  try {
    const res = await UserService.addUsersOne(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
};

export const updateUser = (formData, mode) => async () => {
  try {
    if (mode) {
      const res = await UserService.updateUserNewManager(formData);
      if (res) {
        return res.data;
      }
    } else {
      const res = await UserService.updateUser(formData);
      if (res) {
        return res;
      }
    }
  } catch (error) {
    var result = {
      status: "",
    };
    if (error.response) {
      result.status = error.response.status;
    }
    return result;
  }
};

export const updateUserNTable = (employeeProfile, formData) => async () => {
  try {
    const res = await UserService.updateUserNTable(employeeProfile, formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const uploadCriminalImage = (employeeProfile, formData) => async () => {
  try {
    const res = await userService.uploadImage(employeeProfile, formData);
    if (res) {
      return res.data;
    }
  } catch (error) {
    return "Error";
  }
};


export const getAllEmployeeByIdVendorAndIdCompany =
  (idCompany, idVendor) => async (dispatch) => {
    try {
      dispatch({
        type: USERS_FETCHING,
      });
      const res = await userService.getAllEmployeeByIdVendorAndIdCompany(
        idCompany,
        idVendor
      );
      if (res) {
        dispatch({
          type: USERS_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: USERS_FAILED,
      });
    }
  };

export const getAllEmployeesByIdVendorAndIdCompany = async (idCompany, idVendor) => {
  try {
    const res = await userService.getAllEmployeeByIdVendorAndIdCompany(idCompany, idVendor);
    if (res) {
      return res;
    }
  } catch (error) {
    return error;
  }
};

export const getAllPFScheduleByidVendor = (idVendor) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PF_SCHEDULE_FETCHING,
    });
    const res = await userService.getAllPFScheduleByidVendor(idVendor);
    if (res) {
      dispatch({
        type: USER_PF_SCHEDULE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_PF_SCHEDULE_FAILED,
    });
  }
};

export const getAllProvidentFundByExperienceYear =
  (minExperienceY) => async (dispatch) => {
    try {
      dispatch({
        type: USER_PROVIDENT_FUND_FETCHING,
      });
      const res = await userService.getAllProvidentFundByExperienceYear(
        minExperienceY
      );
      if (res) {
        dispatch({
          type: USER_PROVIDENT_FUND_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: USER_PROVIDENT_FUND_FAILED,
      });
    }
  };

export const updatePFPersonal = (idEmp, data) => async (dispatch) => {
  try {
    const res = await userService.updatePFPersonal(idEmp, data);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getDataWritePDF = (type, year) => async () => {
  try {
    const res = await UserService.getDataWritePDF(type, year);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return error.response;
  }
};
