import React, { Fragment, useState, useEffect } from "react";
import {
  useDispatch,
  useSelector 
} from "react-redux";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { Button, styled } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import TableRequest from "./table";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { approveRequestTime, getAllRequestTimeCompany } from "../../../../../actions/requestTime";
import AlertResponse from "../../../shared/general/AlertResponse";
import DialogConfirmReject from "../dialogConfirmReject";

const StyledNotApprove = styled(Button)({
  color: "#ffffff",
  backgroundColor: "#d32f2f",
  borderColor: "#d32f2f",
  borderRadius: 8,
  "&:hover": {
    backgroundColor: "#c62828",
    borderColor: "#c62828",
  },
});

const TimeAttendance = (props) => {
  const { idCompany, searchName, searchDate, setNumberOfList } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: requestTimeList } = useSelector((state) => state.requestTime);

  const [dataTableWaiting, setDataTableWaiting] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [approvalSelected, setApprovalSelected] = useState([]);
  const [openDialogReject, setOpenDialogReject] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (requestTimeList) {
      setDataTableWaiting(
        requestTimeList.filter((x) => {
          if(
            x.idRequestType === 2 && x.isActive === 1 && (
              (x.isDoubleApproval === 1 && (x.isManagerLV1Approve === null || x.isManagerLV2Approve === null)) ||
              (x.approvalLevel === 1 && x.isManagerLV1Approve === null) ||
              (x.approvalLevel === 2 &&  x.isManagerLV2Approve === null)
            ) && (
              (searchName && searchName.length > 0) ? 
                (
                  x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
                  x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
                )
                : true
            )
          ){
            return true;
          }else{
            return false;
          }
        })
      );
    }
    setIsLoading(false);
  }, [requestTimeList, searchName]);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleApprovalRequest = async (approve) => {
    const idRequestTimeLV1 = approvalSelected
      .filter((x) => x.approvalLevel === 1 || x.isDoubleApproval === 1)
      .map((item) => item.idRequestTime);

    const idRequestTimeLV2 = approvalSelected
      .filter((x) => x.approvalLevel === 2)
      .map((item) => item.idRequestTime);

    const idRequestTimeWithdraw = approvalSelected
      .filter((x) => x.idRequestTimeWithdraw)
      .map((item)  => item.idRequestTimeWithdraw);

    const idRequestTime = approvalSelected.map((item) => item.idRequestTime);

    var formData = [
      {
        idRequestTimeLV1: idRequestTimeLV1,
        isManagerLV1Approve: approve,
        fillInApproveLV1: userProfile.idUsers,
        approveDate: dayjs().format("YYYY-MM-DD HH:mm"),
      },
      {
        idRequestTimeLV2: idRequestTimeLV2,
        isManagerLV2Approve: approve,
        fillInApproveLV2: userProfile.idUsers,
        approveDate: dayjs().format("YYYY-MM-DD HH:mm"),
      },
      {
        idRequestTimeWithdraw: idRequestTimeWithdraw,
        idRequestTime: idRequestTime,
        isApprove: approve,
        fillInApprove: userProfile.idUsers,
        approveDate: dayjs().format("YYYY-MM-DD HH:mm"),
      },
    ];

    setOpenDialogReject(false);
    const result = await dispatch(approveRequestTime("admin", formData));
    if (result) {
      setApprovalSelected([]);
      handleOpenAlert();
      if (result.status === 200) {
        if (searchDate.start && searchDate.end) {
          dispatch(getAllRequestTimeCompany(idCompany, searchDate));
        } else {
          dispatch(getAllRequestTimeCompany(idCompany));
        }
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  return (
    <Fragment>
      {isLoading ? 
        <div style={{ width:"100%", display:"flex", justifyContent:"center", paddingTop: 16 }}>
          <Typography style={{ fontSize: 20, fontWeight: 500 }}>{t("LoadingData")}</Typography>
        </div>
        :
        <Fragment>
          <div style={{ display:"flex", justifyContent:"space-between", alignItems: "center", marginBottom: 16 }}>
            <div style={{ minHeight: 40 }}>
              {approvalSelected.length > 0 &&
                <>
                  <ButtonBlue
                    style={{ marginRight: 8 }}
                    startIcon={<DoneAllIcon />}
                    variant="contained"
                    onClick={() => handleApprovalRequest(1)}
                  >
                    {`${t("Approve")} ${
                      approvalSelected.length > 0
                        ? `${approvalSelected.length} ${t("Items")}`
                        : ""
                    }`}
                  </ButtonBlue>
                  <StyledNotApprove
                    startIcon={<CloseIcon />}
                    variant="outlined"
                    onClick={() => setOpenDialogReject(true)}
                  >
                    {`${t("Disapproval")} ${
                      approvalSelected.length > 0
                        ? `${approvalSelected.length} ${t("Items")}`
                        : ""
                    }`}
                  </StyledNotApprove>
                </>
              }
            </div>
            <Typography style={{ fontSize: 20, fontWeight: 500, color: "#e46a76" }}>
              {`${(dataTableWaiting && dataTableWaiting.length) || 0} ${t("Items")}`}
            </Typography>
          </div>
          {requestTimeList && dataTableWaiting && (
            <TableRequest 
              data={dataTableWaiting} 
              searchName={searchName} 
              approvalSelected={approvalSelected}
              setApprovalSelected={setApprovalSelected}
              setNumberOfList={setNumberOfList}
            />
          )}
          {openDialogReject && (
            <DialogConfirmReject
              open={openDialogReject}
              onClose={() => setOpenDialogReject(false)}
              handleSubmit={() => handleApprovalRequest(0)}
            />
          )}
          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
          />
        </Fragment>
      }
    </Fragment>
  );
};

export default TimeAttendance;
