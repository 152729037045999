import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getAllInstitute } from "../../../../../actions/institute";
import DataTable from "./components/DataTable";
import AddOrEditModal from "./components/AddOrEditModal";

import { Box, Grid, Paper, Typography } from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

function Index() {
  const classes = useStyles();
  useTitle("จัดการหน่วยงาน - HR CORE DATA");
  const dispatch = useDispatch();
  const { result: instituteStore } = useSelector((state) => state.institute);
  const [institutes, setInstitutes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [addOrEditModal, setAddOrEditModal] = useState({
    isOpen: false,
    institutes: [],
  });

  useEffect(() => {
    if (instituteStore !== null) {
      let instituteList = [...instituteStore];
      setInstitutes(instituteList);
    }
    setIsLoading(false);
  }, [instituteStore]);

  useEffect(() => {
    dispatch(getAllInstitute());
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.headingPage}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">จัดการหน่วยงาน</Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<AddIcon />}
              onClick={() => {
                setAddOrEditModal({
                  ...addOrEditModal,
                  isOpen: true,
                });
              }}
            >
              เพิ่มหน่วยงาน
            </ButtonBlue>
          </Grid>
        </Grid>
      </Box>
      <Paper className={classes.paper}>
        <DataTable
          institutes={institutes}
          isLoading={isLoading}
          addOrEditModal={addOrEditModal}
          setAddOrEditModal={setAddOrEditModal}
        />
      </Paper>
      <AddOrEditModal
        institutes={institutes}
        addOrEditModal={addOrEditModal}
        setAddOrEditModal={setAddOrEditModal}
      />
    </div>
  );
}

export default Index;
