import { httpClient } from "./httpClient";

const getVendorProfile = (id, filter) => {
  if (filter) {
    return httpClient.get(`vendors/profile?filter=${filter}`);
  } else {
    if (id) {
      return httpClient.get(`vendors/profile/${id}`);
    } else {
      return httpClient.get(`vendors/profile`);
    }
  }
};

const getCompanyContract = () => {
  return httpClient.get(`vendors/company-contract`);
};
const getCompanyAndBranchContract = () => {
  return httpClient.get(`vendors/company-branch-contract`);
};

const getAllBranchesByVendor = () => {
  return httpClient.get(`vendors/branches`);
};

const getAllUsersVendor = (filter) => {
  if (filter) {
    return httpClient.get(`vendors/employees?filter=all`);
  } else {
    return httpClient.get(`vendors/employees`);
  }
};

const getAllUsersVendorByIdCompany = (idCompany) => {
  return httpClient.get(`vendors/company/${idCompany}/users`);
};

const updateVenderCompanyProfile = (data) => {
  return httpClient.put(`vendors/profile`, data);
};

const updateVendorAddress = (data) => {
  return httpClient.put(`vendors/address`, data);
};

const addNewVendorBranch = (data) => {
  return httpClient.post(`vendors/branch/add`, data);
};

const updateVendorBranch = (data) => {
  return httpClient.put(`vendors/branch/update`, data);
};

const deleteVendorBranch = (data) => {
  return httpClient.delete(`vendors/branch/delete`, { data: data });
};

const getAllProvidentFundByVendor = () => {
  return httpClient.get(`vendors/provident-fund`);
};

const getAllPFScheduleByVendor = () => {
  return httpClient.get(`vendors/provident-fund-schedule`);
};

const addPFSchedule = (data) => {
  return httpClient.post(`vendors/provident-fund-schedule/add`, data);
};

const deletePFSchedule = (id) => {
  return httpClient.delete(`vendors/provident-fund-schedule/delete/${id}`);
};

const updateProvidentFund = (data) => {
  return httpClient.put(`vendors/provident-fund/update`, data);
};

const addProvidentFund = (data) => {
  return httpClient.post(`vendors/provident-fund/add`, data);
};

const deleteProvidentFund = (id) => {
  return httpClient.delete(`vendors/provident-fund/delete/${id}`);
};

const updatePFSchedule = (data) => {
  return httpClient.put(`vendors/provident-fund-schedule/update`, data);
};

const addVendorLogo = (formData) => {
  return httpClient.post(`vendors/manage/logo`, formData);
};

const deleteVendorLogo = () => {
  return httpClient.delete(`vendors/manage/logo`);
};

const addVendorAuthorizedSignatureOne = (formData) => {
  return httpClient.post(`vendors/manage/authorizedSignatureOne`, formData);
};

const deleteVendorAuthorizedSignatureOne = () => {
  return httpClient.delete(`vendors/manage/authorizedSignatureOne`);
};

const addVendorAuthorizedSignatureTwo = (formData) => {
  return httpClient.post(`vendors/manage/authorizedSignatureTwo`, formData);
};

const deleteVendorAuthorizedSignatureTwo = () => {
  return httpClient.delete(`vendors/manage/authorizedSignatureTwo`);
};

const addVendorWitnessSignature = (formData) => {
  return httpClient.post(`vendors/manage/witnessSignature`, formData);
};

const deleteVendorWitnessSignature = () => {
  return httpClient.delete(`vendors/manage/witnessSignature`);
};

const getAdminVendor = () => {
  return httpClient.get(`vendors/admin`);
};

const updateAdminVendor = (formData) => {
  return httpClient.put(`vendors/admin`, formData);
};

const updateAdminVendorConfidential = (formData) => {
  return httpClient.put(`vendors/admin/confidential`, formData);
};

const getAllCompanyByVendor = () => {
  return httpClient.get(`vendors/companies`);
};


const getSignatureCertificateVendor = () => {
  return httpClient.get(`vendors/signatureVendor`);
};


const getEmployeesByVendorAndCompanyId = (idVendor, idCompany) => {
  return httpClient.get(`vendors/${idVendor}/companies/${idCompany}/employees`);
}

const getAllCompaniesByVendor = () => {
  return httpClient.get(`vendors/all-companies-vendor`);
}

const getLevelByVendorId = (idVendor, query) => {
  return httpClient.get(`vendors/${idVendor}/level`, { params: query });
}

export default {
  getAllUsersVendor,
  getVendorProfile,
  getCompanyContract,
  getCompanyAndBranchContract,
  getAllUsersVendorByIdCompany,
  updateVenderCompanyProfile,
  getAllBranchesByVendor,
  updateVendorAddress,
  addNewVendorBranch,
  updateVendorBranch,
  deleteVendorBranch,
  getAllProvidentFundByVendor,
  getAllPFScheduleByVendor,
  addPFSchedule,
  deletePFSchedule,
  updateProvidentFund,
  deleteProvidentFund,
  updatePFSchedule,
  addProvidentFund,
  addVendorLogo,
  deleteVendorLogo,
  addVendorAuthorizedSignatureOne,
  deleteVendorAuthorizedSignatureOne,
  addVendorAuthorizedSignatureTwo,
  deleteVendorAuthorizedSignatureTwo,
  addVendorWitnessSignature,
  deleteVendorWitnessSignature,
  getAdminVendor,
  updateAdminVendor,
  updateAdminVendorConfidential,
  getAllCompanyByVendor,
  getSignatureCertificateVendor,
  getEmployeesByVendorAndCompanyId,
  getAllCompaniesByVendor,
  getLevelByVendorId,
};
