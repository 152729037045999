import dayjs from "dayjs";
import { httpClient } from "./httpClient";

const getAllExpense = () => {
  return httpClient.get(`expense`);
};

const getExpenseGeneralById = (idExpense) => {
  return httpClient.get(`expenseById/general/${idExpense}`);
};

const addExpenseGeneral = (idEmp, formData) => {
  return httpClient.post(`expense/general/${idEmp}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateFileExpense = (idEmp, formData) => {
  return httpClient.put(`expense/general/${idEmp}/updatefile`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

// const updateStatusExpense = (idEmp, idExpense, data) => {
//   return httpClient.put(`expense/status/${idEmp}/${idExpense}/update`, data);
// };

const updateExpenseGeneral = (idEmp, formData) => {
  return httpClient.put(`expense/general/${idEmp}/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getExpenseAllowanceById = (idExpense) => {
  return httpClient.get(`expenseById/allowance/${idExpense}`);
};

const addExpenseAllowance = (idEmp, formData) => {
  return httpClient.post(`expense/allowance/${idEmp}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseAllowance = (idEmp, formData) => {
  return httpClient.put(`expense/allowance/${idEmp}/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getExpenseMileageById = (idExpense) => {
  return httpClient.get(`expenseById/mileage/${idExpense}`);
};

const addExpenseMileage = (idEmp, formData) => {
  return httpClient.post(`expense/mileage/${idEmp}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseMileage = (idEmp, formData) => {
  return httpClient.put(`expense/mileage/${idEmp}/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getExpenseWelfareById = (idExpense) => {
  return httpClient.get(`expenseById/welfare/${idExpense}`);
};

const addExpenseWelfare = (idEmp, formData) => {
  return httpClient.post(`expense/welfare/${idEmp}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseWelfare = (idEmp, formData) => {
  return httpClient.put(`expense/welfare/${idEmp}/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getExpenseFlexCoinById = (idExpense) => {
  return httpClient.get(`expenseById/flexCoin/${idExpense}`);
};

const addExpenseFlexCoin = (idEmp, formData) => {
  return httpClient.post(`expense/flexCoin/${idEmp}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateExpenseFlexCoin = (idEmp, formData) => {
  return httpClient.put(`expense/flexCoin/${idEmp}/update`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteExpenseGeneral = (idEmp, data) => {
  return httpClient.put(`expense/general/${idEmp}/delete`, data);
};

const deleteExpenseMileage = (idEmp, data) => {
  return httpClient.put(`expense/mileage/${idEmp}/delete`, data);
};

const deleteExpenseAllowance = (idEmp, data) => {
  return httpClient.put(`expense/allowance/${idEmp}/delete`, data);
};

const deleteExpenseWelfare = (idEmp, data) => {
  return httpClient.put(`expense/welfare/${idEmp}/delete`, data);
};

const deleteExpenseFlexCoin = (idEmp, data) => {
  return httpClient.put(`expense/flexCoin/${idEmp}/delete`, data);
};

const getAllExpenseByIdCompany = () => {
  return httpClient.get(`expense/accountant/all`);
};

const updateStatusExpense = (idExpense, data) => {
  return httpClient.put(`expense/status/${idExpense}/update`, data);
};

const getHistoryExpenseByIdCompany = () => {
  return httpClient.get(`expense/accountant/history`);
};

const getFilteredHistoryExpenseAccountant = (data) => {
  return httpClient.get(
    `expense/accountant/history/filter?idCompany=${data.idCompany}&expenseType=${data.expenseType}&date=${data.date}`
  );
};

const getAllExpenseByIdApprover = () => {
  return httpClient.get(`expense/manager/all`);
};

const getHistoryExpenseForApprover = () => {
  return httpClient.get(`expense/approver/history`);
};

const getFilteredHistoryExpenseApprover = (data) => {
  return httpClient.get(
    `expense/approver/history/filter?idEmp=${data.idEmp}&expenseType=${data.expenseType}&date=${data.date}`
  );
};

const getAllExpenseByIdReviewer = () => {
  return httpClient.get(`expense/reviewer/all`);
};

export default {
  getAllExpense,
  getExpenseGeneralById,
  addExpenseGeneral,
  updateFileExpense,
  updateStatusExpense,
  updateExpenseGeneral,
  getExpenseAllowanceById,
  addExpenseAllowance,
  updateExpenseAllowance,
  getExpenseMileageById,
  addExpenseMileage,
  updateExpenseMileage,
  getExpenseWelfareById,
  addExpenseWelfare,
  updateExpenseWelfare,
  getExpenseFlexCoinById,
  addExpenseFlexCoin,
  updateExpenseFlexCoin,
  deleteExpenseGeneral,
  deleteExpenseMileage,
  deleteExpenseAllowance,
  deleteExpenseWelfare,
  deleteExpenseFlexCoin,
  getAllExpenseByIdCompany,
  getHistoryExpenseByIdCompany,
  getFilteredHistoryExpenseAccountant,
  getAllExpenseByIdApprover,
  getHistoryExpenseForApprover,
  getFilteredHistoryExpenseApprover,
  getAllExpenseByIdReviewer,
};
