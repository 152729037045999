import React from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import FormContainer from "./FormContainer";
import { useTranslation } from "react-i18next";

const ManagerInfo = (props) => {
  const { formik, companySelect } = props;
  const { t, i18n } = useTranslation();

  return (
    <FormContainer title={`${t("Manager")}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography>{`${t("ManagerLevel")} 1`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="idManagerLV1"
            value={formik.values.idManagerLV1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.idManagerLV1 && Boolean(formik.errors.idManagerLV1)
            }
            helperText={
              formik.touched.idManagerLV1 &&
              Boolean(formik.errors.idManagerLV1) &&
              formik.errors.idManagerLV1
            }
            fullWidth
            select
            disabled={!Boolean(formik.values.idCompany)}
          >
            {companySelect.manager.map((item) => (
              <MenuItem
                key={item.idManagerEmployee}
                value={item.idManagerEmployee}
              >
                {item.name}
              </MenuItem>
            ))}
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{`${t("ManagerLevel")} 2`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="idManagerLV2"
            value={formik.values.idManagerLV2}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.idManagerLV2 && Boolean(formik.errors.idManagerLV2)
            }
            helperText={
              formik.touched.idManagerLV2 &&
              Boolean(formik.errors.idManagerLV2) &&
              formik.errors.idManagerLV2
            }
            fullWidth
            select
            disabled={!Boolean(formik.values.idCompany)}
          >
            {companySelect.manager.map((item) => (
              <MenuItem
                key={item.idManagerEmployee}
                value={item.idManagerEmployee}
              >
                {item.name}
              </MenuItem>
            ))}
          </TextFieldTheme>
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default ManagerInfo;
