import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import {
  postGroupGpsLocations,
  getAllLocations,
} from "../../../../../../actions/gpsLocations";
import { Typography } from "@mui/material";

const StyleWrapForm = styled("div")({
  paddingTop: 16,
});

const DialogGroupGpsLocations = (props) => {
  const { open, handleClose, handleOpenAlert, handleChangeAlertType, company } =
    props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [formData, setFormData] = useState({
    name: "",
    idCompany: "",
    createBy: "",
    createDate: dayjs(new Date()).format("YYYY-MM-DD"),
    isActive: true,
  });

  const handleSaveGroupGpsLocations = async () => {
    if (userProfile) {
      formData.idCompany = company.idCompany;
      formData.createBy = userProfile.idEmp;
      const result = await dispatch(postGroupGpsLocations(formData));
      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          if (userProfile) {
            dispatch(getAllLocations(company.idCompany));
          }
        } else {
          handleChangeAlertType("error");
        }
        handleClose();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("AddNewArea")}</DialogTitle>
      <DialogContent>
        <StyleWrapForm>
          <TextFieldTheme
            name="name"
            onChange={handleChange}
            label={t("AreaPositionName")}
          />
        </StyleWrapForm>
      </DialogContent>
      <DialogActions>
        <ButtonBlue onClick={handleClose}>{t("Cancel")}</ButtonBlue>
        <ButtonBlue
          onClick={handleSaveGroupGpsLocations}
          variant={"contained"}
          autoFocus
        >
          {t("Save")}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogGroupGpsLocations;
