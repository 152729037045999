import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import { getCompanyContract } from "../../../../actions/vendor";

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
    marginTop: 22,
  },
});

const StyledPopper = styled(Popper)({
  minWidth: "350px !important",
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const SelectCompany = (props) => {
  let { handleChangeCompany, readOnly, disabled, handleClearData, company } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  let dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: vendorContract } = useSelector(
    (state) => state.vendorContract
  );

  useEffect(() => {
    dispatch(getCompanyContract());
  }, []);

  const handleFilterCompany = () => {
    if (vendorContract && vendorContract.length > 0 && userProfile) {
      if (userProfile.confidentialData[0].idCompany === null) {
        return vendorContract
      } else {
        let allCompany = [...vendorContract]
        return allCompany.filter((el) => {
          return userProfile.confidentialData.some((f) => {
            return f.idCompany === el.idCompany
          });
        });
      }
    } else {
      return [];
    }
  }

  return (
    <StyledBoxSearch>
      <Typography className="label" color="text.third">
        {t("Select_Company")}
      </Typography>
      <StyledAutocomplete
        disabled={disabled}
        options={userProfile && vendorContract ? handleFilterCompany(): [] }
        value={company}
        onChange={(event, newValue) => {
          if (newValue) {
            handleChangeCompany(newValue);
          } else {
            handleClearData();
            handleChangeCompany(newValue);
          }
        }}
        popupIcon={<i className="fa-light fa-chevron-down"></i>}
        getOptionLabel={(option) => option.companyName}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder={t("Select_Company")}
          />
        )}
        PopperComponent={StyledPopper}
        noOptionsText={`${t("No_Data")}`}
        readOnly={readOnly}
      />
    </StyledBoxSearch>
  );
};

export default SelectCompany;
