import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';

export const LeaveAbsentLateXlsxReport = (leaveList, employeeList, companyName) => {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("รายงานสรุปการลา ขาดงานและมาสาย");

        const headerRow = worksheet.addRow([
            "รหัสพนักงาน",
            "ชื่อ",
            "นามสกุล",
            "เลขบัตรประชาชน",
            "วันทำงาน",
            "ขาดงาน",
            "มาสาย",
            "กลับก่อน",
            ...leaveList.map(item => item.leaveGroupName)
        ]);

        headerRow.height = 50;

        const headerStyle = {
            font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
            alignment: { horizontal: "center", vertical: 'middle' },
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '002060' }
            },
        };

        headerRow.eachCell((cell) => {
            cell.style = headerStyle;
        });

        const leaveListColWidth = [];
        leaveList.forEach(item => {
            leaveListColWidth.push({
                key: item.idLeaveGroup,
                width: item.leaveGroupName.length * 2
            });
        });

        const colWidths = [
            { key: "employeeId", width: 30 },
            { key: "firstname", width: 30 },
            { key: "lastname", width: 30 },
            { key: "personalId", width: 30 },
            { key: "workingDay", width: 30 },
            { key: "absent", width: 20 },
            { key: "late", width: 20 },
            { key: "early", width: 20 },
            ...leaveListColWidth
        ];

        colWidths.forEach((col, index) => {
            worksheet.getColumn(index + 1).width = col.width;
        });

        if (employeeList && employeeList.length > 0) {
            employeeList.forEach((item) => {
                const row = [
                    item.employeeId || "-",
                    item.firstname || "-",
                    item.lastname || "-",
                    item.personalId || "-",
                    item.arrive || 0,
                    item.absent || 0,
                    item.late || 0,
                    item.earlyBack || 0
                ];

                // Add leaveUsed data to the row
                leaveList.forEach((leave) => {
                    row.push(item.leaveUsed[leave.idLeaveGroup] || 0);
                });

                const excelRow = worksheet.addRow(row);
                const contentStyle = {
                    font: { size: 18, name: 'TH SarabunPSK' },
                    alignment: {
                        vertical: 'middle',
                        horizontal: 'center'
                    }
                };

                excelRow.eachCell((cell) => {
                    cell.style = contentStyle;
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                });
            });
        }

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `AntHR ${companyName}-รายงานสรุปการลา ขาดงานและมาสาย.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    } catch (error) {
        console.error(error);
    }
}