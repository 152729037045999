import { DEPARTMENT_FETCHING, DEPARTMENT_FAILED, DEPARTMENT_SUCCESS } from "./types";

import DepartmentService from "../services/department.service";

export const getDepartment = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_FETCHING,
    });
    const res = await DepartmentService.getDepartment(idCompany);
    if (res) {
      dispatch({
        type: DEPARTMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: DEPARTMENT_FAILED,
    });
    console.log(err);
  }
};

export const getDepartmentByIdCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_FETCHING,
    });
    const res = await DepartmentService.getDepartmentByIdCompany(idCompany);
    if (res) {
      dispatch({
        type: DEPARTMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DEPARTMENT_FAILED,
    });
  }
};



export const getAllDepartments = (query) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_FETCHING,
    });
    const res = await DepartmentService.getAllDepartments(query);
    if (res) {
      dispatch({
        type: DEPARTMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DEPARTMENT_FAILED,
    });
  }
}
