import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { Fragment, useState } from "react";

import dayjs from "dayjs";

import { Grid } from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TimePicker from "../../shared/general/TimePicker";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    width: "100%",
  },
});

const StyledTSelectTimePerCandidate = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    width: "100%",
  },
});

export default function DialogAddAppointment(props) {
  const { open, handleClose, value } = props;
  const top100Films = [
    { title: "Lleyton Wilkes", year: 1994 },
    { title: "Philip Knight", year: 1972 },
    { title: "Patryk Rowe", year: 1974 },
    { title: "Jarrad Jimenez", year: 2008 },
    { title: "Chester Currie", year: 1957 },
    { title: "Natalia Simmonds", year: 1993 },
    { title: "Reema Field", year: 1994 },
    {
      title: "Lyndsey Saunders",
      year: 2003,
    },
    { title: "Xavier Stein", year: 1966 },
    { title: "Mercy Jackson", year: 1999 },
    {
      title: "Aneurin Keeling",
      year: 2001,
    },
  ];
  const [requestAppointment, setRequestAppointment] = useState({
    serviceAppointment: "service",
    dateAppointment: dayjs().toDate(),
    timePerCandidate: 10,
    exam: "no",
    startMinute: "",
    startHours: "",
    endMinute: "",
    endHours: "",
    reason: "",
  });

  const handleChange = (event) => {
    setRequestAppointment({
      ...requestAppointment,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeTimePerCandidate = (event) => {
    setRequestAppointment({
      ...requestAppointment,
      ["timePerCandidate"]: event.target.value,
    });
  };

  const handleChangeTime = (name, unit, periodTime, value) => {
    setRequestAppointment({
      ...requestAppointment,
      [name]: value,
    });
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"สร้างนัดหมายสัมภาษณ์"}</DialogTitle>
        <DialogContent>
          <div>
            <FormControl component="fieldset">
              {/* <FormLabel component="legend">การจัดสัมภาษณ์</FormLabel> */}
              <RadioGroup
                row
                aria-label="gender"
                name="serviceAppointment"
                value={requestAppointment.serviceAppointment}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="service"
                  control={<Radio />}
                  label="ใช้บริการจัดสัมภาษณ์"
                />
                <FormControlLabel
                  value="self"
                  control={<Radio />}
                  label="จัดสัมภาษณ์ด้วยตัวเอง"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <Divider
            style={{
              margin: "16px 0px",
              borderWidth: "0px 0px thin",
              borderColor: "rgba(145, 158, 171, 0.24)",
              borderStyle: "dashed",
            }}
          />
          {requestAppointment.serviceAppointment === "self" ? (
            <div>
              <div style={{ width: "100%", marginTop: 8, marginBottom: 24 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="เลือกวันที่"
                    inputFormat="dd/MM/yyyy"
                    value={requestAppointment.dateAppointment}
                    onChange={(newValue) => {
                      setRequestAppointment({
                        ...requestAppointment,
                        ["dateAppointment"]: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        format={"DD/MM/YYYY"}
                        style={{ width: "100%" }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", marginRight: 4 }}>
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        name="startHours"
                        unit="hour"
                        value={requestAppointment.startHours}
                      />
                    </div>
                    <div style={{ width: "100%", marginLeft: 4 }}>
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        name="startMinute"
                        unit="minute"
                        value={requestAppointment.startMinute}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography>ถึง</Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", marginRight: 4 }}>
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        name="endHours"
                        unit="hour"
                        value={requestAppointment.endHours}
                      />
                    </div>
                    <div style={{ width: "100%", marginLeft: 4 }}>
                      <TimePicker
                        handleChangeTime={handleChangeTime}
                        name="endMinute"
                        unit="minute"
                        value={requestAppointment.endMinute}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div style={{ marginTop: 16 }}>
                <FormControl fullWidth style={{ marginBottom: 8 }}>
                  <TextField id="outlined-multiline-static" label="สถานที่" />
                </FormControl>
              </div>
              <div style={{ marginTop: 8 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth style={{ marginBottom: 8 }}>
                      <TextField
                        id="outlined-multiline-static"
                        label="ชื่อผู้ติดต่อ"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth style={{ marginBottom: 8 }}>
                      <TextField
                        id="outlined-multiline-static"
                        label="เบอร์ผู้ติดต่อ"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : (
            <Fragment>
              <div style={{ marginTop: 24 }}>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={top100Films}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </li>
                  )}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <StyledTextField {...params} label="เลือกกรรมการสัมภาษณ์" />
                  )}
                />
              </div>
              <div style={{ marginTop: 24 }}>
                <StyledTSelectTimePerCandidate fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    เวลาสัมภาษณ์ต่อผู้สมัคร
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={requestAppointment.timePerCandidate}
                    label="เวลาสัมภาษณ์ต่อผู้สมัคร"
                    onChange={handleChangeTimePerCandidate}
                  >
                    <MenuItem value={10}>10 นาที</MenuItem>
                    <MenuItem value={15}>15 นาที</MenuItem>
                    <MenuItem value={20}>20 นาที</MenuItem>
                    <MenuItem value={25}>25 นาที</MenuItem>
                    <MenuItem value={30}>30 นาที</MenuItem>
                  </Select>
                </StyledTSelectTimePerCandidate>
              </div>
              <div style={{ marginTop: 24 }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">สอบข้อเขียน</FormLabel>
                  <RadioGroup
                    row
                    aria-label="exam"
                    name="exam"
                    value={requestAppointment.exam}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio />}
                      label="มีสอบข้อเขียน"
                    />
                    <FormControlLabel
                      value={"no"}
                      control={<Radio />}
                      label="ไม่มีสอบข้อเขียน"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              
            </Fragment>
          )}
          <div style={{ marginTop: 16 }}>
                <FormControl fullWidth style={{ marginBottom: 8 }}>
                  <TextField
                    id="outlined-multiline-static"
                    label="หมายเหตุเพิ่มเติม"
                    multiline
                    rows={4}
                  />
                </FormControl>
              </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <ButtonBlue size="" value={"สร้างนัดหมายสัมภาษณ์"} variant={"contained"} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
