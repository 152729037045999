import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import { Circle } from "@mui/icons-material";
import ButtonEdit from "./shared/ButtonEdit";
import DrawerService from "./drawerService";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({});

const Service = () => {
  const { result: AllServices } = useSelector((state) => state.services);
  const [isOpenDrawerService, setIsOpenDrawerService] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="headerText">{t("Service")}</Typography>
          <List>
            {AllServices && AllServices.length > 0 ? (
              AllServices.map((service) => (
                <ListItem disablePadding key={service.idVendorService}>
                  <ListItemIcon style={{ justifyContent: "center" }}>
                    <Circle style={{ fontSize: "10px" }} />
                  </ListItemIcon>
                  <ListItemText primary={service.serviceName} />
                </ListItem>
              ))
            ) : (
              <ListItem disablePadding>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={t("No_Data")} />
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonEdit
            onClick={() => {
              setIsOpenDrawerService(true);
            }}
          />
        </Grid>
      </Grid>

      {isOpenDrawerService && (
        <DrawerService
          open={isOpenDrawerService}
          handleClose={() => {
            setIsOpenDrawerService(false);
          }}
        />
      )}
    </StyledRoot>
  );
};

export default Service;
