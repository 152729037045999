import * as React from "react";
import {
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Box,
  Breadcrumbs,
  Dialog,
  Typography,
  Link
} from "@mui/material";

import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import DialogEditSwitchShift from "./dialogEditSwitchShift";
import DialogSwitchShift from "./dialogSwitchShift";
import EventSwitchShift from "./eventSwitchShift";
import EmployeeShiftList from "./employeeShiftList";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import CustomPlanShift from "./customPlan";
import { Container } from "@mui/material";
import { getAllEmployeesShift, getEmployeeShiftHistory } from "../../../../../actions/employee";
import { getShift, getShiftGroupById } from "../../../../../actions/shift";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom:"20px",
  "& .head": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const StyledBreadcrumbs = styled(Breadcrumbs)({
  "& .listName:hover":{
    cursor: "pointer"
  }
});

export default function ListEmployeePage(props) {
  const { idShiftGroup } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { result : AllShiftGroup } = useSelector(state => state.shift);
  const { result : UserProfile } = useSelector(state => state.userProfile);
  const { result: shiftGroup } = useSelector((state) => state.shiftGroup);
  const [pattern, setPattern] = React.useState(false);
  const [openSwitchShift, setOpenSwitchShift] = React.useState(false);
  const [openErrorSwitch, setOpenErrorSwitch] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [editHistory, setEditHistory] = React.useState(null);
  const [isCustom, setIsCustom] = React.useState(false);
  const [breadcrumbs, setBreadcrumbs] = React.useState([]);
  const [alertStatus, setAlertStatus] = React.useState({
    isOpen: false,
    type: null,
    label: null
  });

  React.useEffect(() => {
    if(!AllShiftGroup){
      dispatch(getShift(UserProfile.idCompany));
    } 
    else {
      if(idShiftGroup){
        dispatch(getAllEmployeesShift(idShiftGroup));
        dispatch(getShiftGroupById(idShiftGroup));
      }
    }
  },[idShiftGroup, AllShiftGroup]);

  React.useEffect(() => {
    if(shiftGroup){
      handleClickListName();
    }
  },[shiftGroup]);

  React.useEffect(() => {
    dispatch(getEmployeeShiftHistory(idShiftGroup));
  },[openSwitchShift]);

  const handleClickListName = () => {
    setIsCustom(false);
    let temp = [];
    temp.push(
      <Link
        underline="hover"
        key="2"
        color="inherit"
        className="listName"
        onClick={handleClickListName}
      >
        {`${shiftGroup.idWorkingType === 1 ? "กะ" : ""} ${
          shiftGroup.nameShiftGroup
            ? shiftGroup.nameShiftGroup
            : "-"
        }`}
      </Link>
    );
    setBreadcrumbs(temp);
  };

  const handleOpenSwitchShiftPattern = () => {
    setOpenSwitchShift(true);
    setPattern(true);
  };

  const handleCloseSwitchShift = () => {
    setOpenSwitchShift(false);
  };

  const handleOpenSwitchShift = () => {
    setOpenSwitchShift(true);
    setPattern(false);
  };

  const handleGetEmployeeShiftHistory = () => {
    dispatch(getEmployeeShiftHistory(idShiftGroup));
  };

  const handleOpenAlert = (type, label) => {
    setAlertStatus({
      isOpen: true,
      type: type,
      label: label
    });
  };

  const handleCloseAlert = () => {
    setAlertStatus({
      isOpen: false,
      type: null,
      label: null
    });
  };

  const handleOpenCustom = () => {
    setIsCustom(true);
    let temp = [...breadcrumbs];
    temp.push(
      <Link underline="none" key="3" color="inherit">
        {t("EditWorkTime")}
      </Link>
    );
    setBreadcrumbs(temp);
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {breadcrumbs.length >= 2 && 
          <div style={{ marginBottom: 16 }}>
            <StyledBreadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </StyledBreadcrumbs>
          </div>
        }
        {!isCustom ? 
          <React.Fragment>
            <div className="head">
              <Typography variant="h4" style={{ paddingTop: 8, color: "#212b36" }}>
                {t("EmployeeList")}
              </Typography>
              <div className="head">
                <div style={{ marginRight: 8 }}>
                  <ButtonBlue variant="outlined" onClick={handleOpenCustom}>
                    {t("EditWorkTime")}
                  </ButtonBlue>
                </div>
                <div>
                  <ButtonBlue
                    style={{ width: "100%" }}
                    variant={"contained"}
                    startIcon={<SyncProblemIcon />}
                    onClick={handleOpenSwitchShiftPattern}
                  >
                    {t("SwitchShiftPattern")}
                  </ButtonBlue>
                </div>
              </div>
            </div>
            <EmployeeShiftList 
              handleOpenSwitchShift={handleOpenSwitchShift}
            />
            <EventSwitchShift 
              idShiftGroup={idShiftGroup}
              handleGetEmployeeShiftHistory={handleGetEmployeeShiftHistory}
              handleOpenAlert={handleOpenAlert}
            />
            {openSwitchShift && 
              <DialogSwitchShift
                open={openSwitchShift}
                handleClose={handleCloseSwitchShift}
                pattern={pattern}
                idShiftGroup={idShiftGroup}
                setOpenErrorSwitch={setOpenErrorSwitch}
              />
            }
            <Dialog open={openErrorSwitch && !openSwitchShift} onClose={()=>setOpenErrorSwitch(false)}>
              <Box style={{ display:"flex", flexDirection:"column", alignItems:"center", margin:"20px 40px" }}>
                <Box style={{ display:"flex", alignItems:"center", color:"red" }}>
                  <i className="fa-solid fa-triangle-exclamation" style={{ marginRight:"20px", fontSize:"40px" }} />
                  <Typography variant="h4" style={{ color:"red" }}>ไม่สามารถสลับกะ</Typography>
                </Box>
                <Box style={{ display:"flex",flexDirection:"column", marginTop:"20px" }}>
                  <Typography>ไม่สามารถสลับกะได้เนื้่องจากพนักงานมีการสลับกะไปแล้ว</Typography>
                  <ButtonBlue style={{ marginTop:"20px" }} variant="outlined" onClick={()=>setOpenErrorSwitch(false)}>ปิด</ButtonBlue>
                </Box>
              </Box>
            </Dialog>
          </React.Fragment>
          :
          <React.Fragment>
            <CustomPlanShift 
              shiftGroup={(AllShiftGroup && AllShiftGroup.find(shift => shift.idShiftGroup === shiftGroup.idShiftGroup)) || null}
            />
          </React.Fragment>
        }

        {alertStatus.isOpen && 
          <AlertResponse 
            open={alertStatus.isOpen}
            handleClose={handleCloseAlert}
            alertType={alertStatus.type}
            label={alertStatus.label}
          />
        }
      </Container>
    </StyledRoot>
  );
}
