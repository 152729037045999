import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";

import SuccessIcon from "../assets/approved.png";
import CancelIcon from "../assets/cancel.png";

const AlertDialog = (props) => {
    const { open, onClose, title, content, type } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    maxWidth: "400px",
                    width: "100%",
                }
            }}
        >
            <DialogContent>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    {type === "success" && <img src={SuccessIcon} width="48" style={{marginBottom: "24px"}}/>}
                    {type === "error" && <img src={CancelIcon} width="48" style={{marginBottom: "24px"}}/>}
                    <Typography fontSize="18px">{content}</Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>ตกลง</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;