import React, { useState } from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import FormContainer from "./FormContainer";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";

import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import DeleteIcon from "@mui/icons-material/Delete";

import DialogAddEducation from "./DialogAddEducation";
import dayjs from "dayjs";

const EducationInfo = (props) => {
  const { formik } = props;
  const { t, i18n } = useTranslation();

  const [isOpenDialogAddEducation, setIsOpenDialogAddEducation] =
    useState(false);

  const handleDelete = (index) => {
    const tempEducation = formik.values.educations.filter(
      (_, i) => i !== index
    );
    formik.setFieldValue("educations", tempEducation);
  };

  return (
    <FormContainer title={`${t("Education")}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonBlue
            variant="outlined"
            startIcon={<BookmarkAddIcon />}
            onClick={() => {
              setIsOpenDialogAddEducation(true);
            }}
          >
            {t("AddEducation")}
          </ButtonBlue>
        </Grid>

        {formik.values.educations.length <= 0 && (
          <Grid item xs={12}>
            <Typography
              textAlign="center"
              variant="h6"
              style={{ color: "grey" }}
            >
              {t("NoEducationalInformation")}
            </Typography>
          </Grid>
        )}

        {formik.values.educations.map((education, index) => (
          <Grid key={index} item xs={12}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", right: "0", top: "0" }}>
                <ButtonBlue
                  size="small"
                  startIcon={<DeleteIcon />}
                  style={{ color: "red" }}
                  onClick={() => handleDelete(index)}
                >
                  {t("Delete")}
                </ButtonBlue>
              </div>
              <Typography variant="h6" style={{ width: "calc(100% - 143px)" }}>
                {`(${
                  education.fromYear
                    ? dayjs(education.fromYear).format("YYYY")
                    : "-"
                } ${t("To")} ${
                  education.endYear
                    ? dayjs(education.endYear).format("YYYY")
                    : `${t("NotSpecified")}`
                }) ${
                  education.university
                    ? education.university
                    : `${t("NoUniversitySpecified")}`
                }`}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {`${education.major}, ${education.faculty}, ${education.degree}`}
              </Typography>
              <Typography style={{ fontWeight: 700, marginTop: 8 }}>
                {`${t("GPA")}: ${education.gpa ? education.gpa : `${t("NotSpecified")}`}`}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>

      <DialogAddEducation
        formik={formik}
        open={isOpenDialogAddEducation}
        onClose={() => {
          setIsOpenDialogAddEducation(false);
        }}
      />
    </FormContainer>
  );
};

export default EducationInfo;
