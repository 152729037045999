import React, { useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";
import { Box, CardContent, Chip, IconButton, Stack, Typography } from "@mui/material";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import CardStyled from "../../shared/general/Card";

import StatusRequest from "./statusRequest";

const StyledChip = styled(Chip)({
  backgroundColor: "#ebf5ff",
  color: "#007aff",
  fontSize: 16,
  fontWeight: 500,
  "&.not-approve": {
    backgroundColor: "#EEEEEE",
    color: "#919eab",
  },
});

const StyledWrapChip = styled("div")({
  display: "flex",
  justifyContent: "center",
  margin: "8px 0",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  alignItems: "center",
  "&.approve": {
    justifyContent: "space-between",
  },
  "&.not-approve": {
    justifyContent: "flex-end",
  },
});

const StyledTextDate = styled(Typography)({
  "&.not-approve": {
    color: "#919eab",
    fontStyle: "oblique",
    fontWeight: 400,
  },
});

const CardHistoryLeave = (props) => {
  const {
    data,
    setDataWithDraw,
    setOpenConfirmDialog,
  } = props;
  const { t, i18n } = useTranslation();

  const handleClickOpenDialog = () => {
    setDataWithDraw(data);
    setOpenConfirmDialog(true);
  };

  return (
    <div style={{ width: 350, margin: 8 }}>
      <CardStyled>
        <CardContent style={{ padding: 16 }}>
          <StyledWrapHead
            className={data.isApprove !== 0 ? "approve" : "not-approve"}
          >
            {data.isApprove !== 0 && (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={handleClickOpenDialog}
              >
                <DeleteOutlineRoundedIcon fontSize="inherit" />
              </IconButton>
            )}

            {/* รออนุมัติถอน */}
            <Stack spacing={1} direction={"row"} alignItems={"center"}>

              {data.withdraw ? (
                <>
                  {/* อนุมัติถอน */}
                  {data.withdraw.isApprove === 1 ? (
                    <Box
                      sx={{
                        backgroundColor: "#fdf3f5",
                        height: 8,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 1,
                        borderRadius: 10
                      }}
                    >
                      <Typography style={{ fontSize: 12, fontWeight: "500", color: "#e46a76", textAlign: "center" }}>
                        {t("Withdrawn")}
                      </Typography>
                    </Box>
                  ) : data.withdraw.isApprove === 0 ? (
                    <Box
                      sx={{
                        backgroundColor: "#fdf3f5",
                        height: 8,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 1,
                        borderRadius: 10
                      }}
                    >
                      <Typography style={{ fontSize: 12, fontWeight: "500", color: "#e46a76", textAlign: "center" }}>
                        {t("Approve")}
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: "#fffcd6",
                        height: 8,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 1,
                        borderRadius: 10
                      }}
                    >
                      <Typography style={{ fontSize: 12, fontWeight: 500, color: "#fec90f", textAlign: "center" }}>
                        {t("PendingWithdraw")}
                      </Typography>
                    </Box>
                  )}
                </>
              ) : (
                <StatusRequest
                  status={data.isApprove ? 1 : data.approveDate ? 2 : 0}
                />
              )}

            </Stack>
          </StyledWrapHead>
          <StyledWrapChip>
            <StyledChip
              className={data.isApprove === 0 ? "not-approve" : ""}
              label={`${data.name} ${data.used} วัน`}
            />
          </StyledWrapChip>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2" color="text.secondary">
                {t("StartDate")}
              </Typography>
              <StyledTextDate
                className={data.isApprove === 0 ? "not-approve" : ""}
                variant="h6"
              >
                {dayjs(data.startText, "DD/MM/YYYY HH:mm").format("DD/MM/BBBB")}
              </StyledTextDate>
              <StyledTextDate
                className={data.isApprove === 0 ? "not-approve" : ""}
                variant="h6"
                style={{ height: 32 }}
              >
                {dayjs(data.startText, "DD/MM/YYYY HH:mm").format("HH:mm") !=
                  "00:00"
                  ? dayjs(data.startText, "DD/MM/YYYY HH:mm").format("HH:mm")
                  : ""}
              </StyledTextDate>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2" align="right" color="text.secondary">
                {t("EndDate")}
              </Typography>
              <StyledTextDate
                className={data.isApprove === 0 ? "not-approve" : ""}
                variant="h6"
                align="right"
              >
                {dayjs(data.endText, "DD/MM/YYYY HH:mm").format("DD/MM/BBBB")}
              </StyledTextDate>
              <StyledTextDate
                className={data.isApprove === 0 ? "not-approve" : ""}
                variant="h6"
                align="right"
                style={{ height: 32 }}
              >
                {dayjs(data.endText, "DD/MM/YYYY HH:mm").format("HH:mm") !=
                  "00:00"
                  ? dayjs(data.endText, "DD/MM/YYYY HH:mm").format("HH:mm")
                  : ""}
              </StyledTextDate>
            </div>
          </div>
          {/* <Divider style={{ margin: "8px" }} /> */}
          <div style={{ padding: 8 }}>
            <Typography
              color="text.third"
              style={{
                fontSize: 16,
                fontStyle: "oblique",
              }}
            >
              {t("Remark")}: {data.description ? data.description : "-"}
            </Typography>
          </div>
        </CardContent>
      </CardStyled>
    </div >
  );
};

export default CardHistoryLeave;
