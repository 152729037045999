import React, { useState } from "react";
import { Button, Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import ButtonEdit from "./shared/ButtonEdit";
import DrawerPrice from "./drawerPrice";

const StyledRoot = styled("div")({});

const Price = (props) => {
  const { data } = props;
  const [isOpenDrawerPrice, setIsOpenDrawerPrice] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">{t("chargeWage")}</Typography>
          <Typography className="valueText">
            {data.chargeWage || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">{t("chargeOt")}</Typography>
          <Typography className="valueText">{data.chargeOt || "-"}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">{t("chargeCost")}</Typography>
          <Typography className="valueText">
            {data.chargeCost || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {t("chargeCompensation")}
          </Typography>
          <Typography className="valueText">
            {data.chargeCompensation || "-"}
          </Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <Typography className="headerText">อื่นๆ</Typography>
          <Typography className="valueText">อื่นๆ</Typography>
        </Grid> */}
        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonEdit
            onClick={() => {
              setIsOpenDrawerPrice(true);
            }}
          />
        </Grid>
      </Grid>

      {isOpenDrawerPrice && (
        <DrawerPrice
          open={isOpenDrawerPrice}
          handleClose={() => {
            setIsOpenDrawerPrice(false);
          }}
          data={data}
        />
      )}
    </StyledRoot>
  );
};

export default Price;
