import React, { Fragment } from "react";
import { Checkbox, Divider, FormControlLabel, Grid, IconButton, MenuItem, styled, Typography } from "@mui/material";
import { Controller, useForm, useFieldArray } from "react-hook-form";

import DrawerCustom from "../../../../shared/general/Drawer";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from "@mui/icons-material/Save";

import { useDispatch, useSelector } from "react-redux";
import { addLeaveType, getAllVendorCompanyLeaveType } from "../../../../../../actions/leave";

const StyledRoot = styled("div")({
  padding: 24,
  "& .MuiIconButton-root": {
    backgroundColor: "#d500000f",
    color: "#d50000",
  }
})

const DrawerLeave = (props) => {

  const { vendor, open, handleClose, data } = props;

  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const { control, handleSubmit, watch, setValue, getValues, register, unregister, formState: {errors} } = useForm({
    defaultValues: {
      leaveName: "",
      isPaid: "",
      canRequestAfter: "",
      condition: "",
      leaveValue: "",
      carryValue: "",
      steps: [
        {
          condition: "",
          moreThan: "",
          leaveValue: "",
          carryValue: "",
        }
      ],
      gender: "all",
      minimumLeave: "",
      managerLv1: "approval",
      managerLv2: "notify",
    },
    shouldUnregister: true
  })

  const { fields: stepFields, append: stepAppend, remove: stepRemove} = useFieldArray({
    control,
    name: "steps"
  })
  
  const conditionWatch = watch("condition");

  const onSubmit = async (data) => {
    const formData = {
      name: data.leaveName,
      fullday: data.minimumLeave,
      isPaid: data.isPaid,
      leaveCondition: data.condition,
      leaveValue: data.leaveValue || undefined,
      carryValue: data.carryValue || undefined,
      gender: data.gender,
      canRequestAfter: data.canRequestAfter,
      managerLv1: data.managerLv1,
      managerLv2: data.managerLv2,
      steps: data.steps
    }

    const response = await dispatch(addLeaveType(1, formData));
    if (response.status === 200){
      dispatch(
        getAllVendorCompanyLeaveType({
          idCompany: userProfile.idCompany,
          idVendor: vendor.idVendor,
        })
      );
      handleClose();
    }
  }

  const onChangeLeaveCondition = (value) => {
    if(value==="fixed"){
      setValue("steps", [{condition: "", moreThan: "", leaveValue: "", carryValue: ""}]);
    }
    else{
      unregister("leaveValue");
      unregister("carryValue");
    }
  }

  const onValidateExperienceFirstIsZero = (v) => {
    const expCondition = getValues("steps").filter(item => item.condition === "experience");
    if(+expCondition[0].moreThan === 0){
      return true
    }
    else{
      return false
    }
  }

  const onValidateExperienceASC = (v) => {
    const expCondition = getValues("steps").filter(item => item.condition === "experience");
    
    if(expCondition.length === 1){
      return true;
    }

    for(let i=1;i<expCondition.length;i++){
      if(+expCondition[i].moreThan <= +expCondition[i-1].moreThan){
        return false;
      }
    }
    return true;
  }

  const onValidateLevelFirstIsOne = (v) => {
    const lvCondition = getValues("steps").filter(item => item.condition === "level");
    if(+lvCondition[0].moreThan === 1){
      return true
    }
    else{
      return false
    }
  }

  const onValidateLevelASC = (v) => {
    const lvCondition = getValues("steps").filter(item => item.condition === "level");
    
    if(lvCondition.length === 1){
      return true;
    }

    for(let i=1;i<lvCondition.length;i++){
      if(+lvCondition[i].moreThan <= +lvCondition[i-1].moreThan){
        return false;
      }
    }
    return true;
  }

  return(
    <DrawerCustom
      title="เพิ่มวันลา"
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{style: {maxWidth: 550, width: "100%"}}}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="leaveName"
                rules={{required: true}}
                render={({field})=> <TextFieldTheme {...field} label="ชื่อรายการวันลา" error={errors.leaveName? true: false}/>}
              />
            </Grid>

            <Grid item xs={12} container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="isPaid"
                  rules={{required: true}}
                  render={({field})=> (
                    <TextFieldTheme {...field} label="ประเภทการลา" select error={errors.isPaid? true: false}>
                      <MenuItem value="1">การลาที่จ่ายเงิน</MenuItem>
                      <MenuItem value="0">การลาที่ไม่จ่ายเงิน</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="canRequestAfter"
                  rules={{required: true}}
                  render={({field})=> (
                    <TextFieldTheme {...field} label="ร้องขอวันลาได้หลังวันที่" select error={errors.canRequestAfter? true: false}>
                      <MenuItem value="firstday">เริ่มงานวันแรก</MenuItem>
                      <MenuItem value="probation">ผ่านช่วงโปร</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="condition"
                  rules={{required: true}}
                  render={({field})=> (
                    <TextFieldTheme {...field} label="สิทธิการลา" select onChange={(e)=>{field.onChange(e.target.value); onChangeLeaveCondition(e.target.value)}} error={errors.condition? true: false}>
                      <MenuItem value="fixed">คงที่</MenuItem>
                      <MenuItem value="step">ขั้นบันได</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              {conditionWatch === "step" && (
                <Grid item xs={6} container justifyContent="flex-end" alignItems="center">
                  <ButtonBlue startIcon={<AddIcon />} variant="outlined" onClick={()=>{stepAppend({condition: "", moreThan: "", leaveValue: "", carryValue: ""})}}>เพิ่มเงื่อนไข</ButtonBlue>
                </Grid>
              )}
            </Grid>

            {conditionWatch === "step" && (
              <Grid item xs={12} container spacing={3}>
                {stepFields.map((item, i) => (
                  <Grid key={item.id} item xs={12} container spacing={2} alignItems="flex-start">
                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                        <Typography>เงื่อนไขที่ {i+1}</Typography>
                        <IconButton onClick={()=>{stepRemove(i)}} disabled={stepFields.length <= 1}><RemoveIcon /></IconButton>
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`steps.${i}.condition`}
                          rules={{required: true}}
                          render={({field})=> (
                            <TextFieldTheme {...field} label="ประเภท" select onChange={(e)=>{field.onChange(e.target.value); setValue(`steps.${i}.moreThan`, "")}} error={errors.steps && errors.steps[i] && errors.steps[i].condition? true: false}>
                              <MenuItem value="experience">อายุงาน</MenuItem>
                              <MenuItem value="level">ระดับงาน</MenuItem>
                            </TextFieldTheme>
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        {watch(`steps.${i}.condition`) === "experience" && (
                          <Controller
                            control={control}
                            name={`steps.${i}.moreThan`}
                            rules={{
                              required: true,
                              validate: {
                                onValidateExperienceFirstIsZero: value => onValidateExperienceFirstIsZero(value),
                                onValidateExperienceASC: value => onValidateExperienceASC(value),
                              }
                            }}
                            defaultValue=""
                            render={({field})=> (
                              <TextFieldTheme {...field} label="มากกว่า" error={errors.steps && errors.steps[i] && errors.steps[i].moreThan? true: false}/>
                            )}
                          />
                        )}

                        {watch(`steps.${i}.condition`) === "level" && (
                          <Controller
                            control={control}
                            name={`steps.${i}.moreThan`}
                            rules={{
                              required: true,
                              validate: {
                                onValidateLevelFirstIsOne: value => onValidateLevelFirstIsOne(value),
                                onValidateLevelASC: value => onValidateLevelASC(value),
                              }
                            }}
                            defaultValue=""
                            render={({field})=> (
                              <TextFieldTheme {...field} label="มากกว่า" select error={errors.steps && errors.steps[i] && errors.steps[i].moreThan? true: false}>
                                <MenuItem value="1">Junior</MenuItem>
                                <MenuItem value="2">Senior</MenuItem>
                                <MenuItem value="3">Manager</MenuItem>
                              </TextFieldTheme>
                            )}
                          />
                        )}
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`steps.${i}.leaveValue`}
                          rules={{required: true}}
                          render={({field})=> (
                            <TextFieldTheme {...field} label="จำนวนวันลา" error={errors.steps && errors.steps[i] && errors.steps[i].leaveValue? true: false}/>
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`steps.${i}.carryValue`}
                          render={({field})=> (
                            <TextFieldTheme {...field} label="สะสมวันลาได้ไม่เกิน" />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}

            {conditionWatch === "fixed" && (
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="leaveValue"
                    rules={{required: true}}
                    render={({field})=> (
                      <TextFieldTheme {...field} label="จำนวนวันลา" error={errors.leaveValue? true: false}/>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="carryValue"
                    render={({field})=> (
                      <TextFieldTheme {...field} label="สะสมวันลาได้ไม่เกิน" />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="gender"
                  rules={{required: true}}
                  render={({field})=> (
                    <TextFieldTheme {...field} label="เพศ" select error={errors.gender? true: false}>
                      <MenuItem value="all">ทั้งหมด</MenuItem>
                      <MenuItem value="male">ชาย</MenuItem>
                      <MenuItem value="female">หญิง</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="minimumLeave"
                  rules={{required: true}}
                  render={({field})=> (
                    <TextFieldTheme {...field} label="อนุญาตให้ลาขั้นต่ำ" select error={errors.minimumLeave? true: false}>
                      <MenuItem value="0">ครึ่งวัน</MenuItem>
                      <MenuItem value="1">เต็มวัน</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container spacing={3}>
              <Grid item xs={12}>
                <Typography>ขั้นตอนการอนุมัติ</Typography>
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="managerLv1"
                  rules={{required: true}}
                  render={({field})=>(
                    <TextFieldTheme {...field} label="ผู้จัดการระดับที่ 1" select error={errors.managerLv1? true: false}>
                      <MenuItem value="none">ไม่มี</MenuItem>
                      <MenuItem value="notify">แจ้งเตือน</MenuItem>
                      <MenuItem value="approval">ขออนุมัติ</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="managerLv2"
                  rules={{required: true}}
                  render={({field})=>(
                    <TextFieldTheme {...field} label="ผู้จัดการระดับที่ 2" select error={errors.managerLv2? true: false}>
                      <MenuItem value="none">ไม่มี</MenuItem>
                      <MenuItem value="notify">แจ้งเตือน</MenuItem>
                      <MenuItem value="approval">ขออนุมัติ</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
              <ButtonBlue style={{marginRight: 8}}>
                ยกเลิก
              </ButtonBlue>
              <ButtonBlue variant="contained" type="submit">
                บันทึกข้อมูล
              </ButtonBlue>
            </Grid>

          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerLeave;