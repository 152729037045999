import React, { Fragment, useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import FormContainer from "./FormContainer";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import { useSelector } from "react-redux";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogAddPosition from "./DialogAddPosition";
import { useTranslation } from "react-i18next";

const JobInfo = (props) => {
  const { formik, companySelect, setCompanySelect } = props;
  const { t, i18n } = useTranslation();

  const { result: empDataForm } = useSelector((state) => state.empDataForm);
  const [job, setJob] = useState({
    division: "",
    department: "",
    section: "",
    jobGroup: "",
  });

  const [isOpenDialogAddPosition, setIsOpenDialogAddPosition] = useState(false);

  useEffect(() => {
    if (companySelect && formik.values.idPositions) {
      const foundJob = companySelect.job.find(
        (x) => x.idPositions === formik.values.idPositions
      );
      setJob({
        division:
          foundJob && foundJob.divisionName ? foundJob.divisionName : "",
        department:
          foundJob && foundJob.departmentName ? foundJob.departmentName : "",
        section: foundJob && foundJob.sectionName ? foundJob.sectionName : "",
        jobGroup:
          foundJob && foundJob.jobGroupName ? foundJob.jobGroupName : "",
      });
    }
  }, []);

  return (
    <FormContainer title={`${t("Job_Description")}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography>{t("HiringDate")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
            <DatePicker
              // label=""
              name="hiringDate"
              value={formik.values.hiringDate}
              onChange={(date) => {
                formik.setFieldValue("hiringDate", date);
              }}
              openTo="year"
              views={["year", "month", "day"]}
              renderInput={(params) => (
                <TextFieldTheme
                  {...params}
                  error={
                    formik.touched.hiringDate &&
                    Boolean(formik.errors.hiringDate)
                  }
                  helperText={
                    formik.touched.hiringDate &&
                    Boolean(formik.errors.hiringDate) &&
                    formik.errors.hiringDate
                  }
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "DD/MM/YYYY",
                  }}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("OwnerCompany")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="idCompany"
            value={formik.values.idCompany}
            onChange={(event) => {
              formik.setFieldValue("idCompany", event.target.value);
              // formik.setFieldValue("idCompanyCharge", event.target.value);
              const company = empDataForm.find((item) => {
                return item.idCompany === event.target.value;
              });

              if (company) {
                setCompanySelect(company);
                formik.setFieldValue("idPositions", "");
                formik.setFieldValue("idJobLevel", "");
                formik.setFieldValue("idPersonnelLevel", "");
                formik.setFieldValue("idEmployeeType", "");
                formik.setFieldValue("idShift", "");
                formik.setFieldValue("mainWorkingLocationPoint", "");
                formik.setFieldValue("idManagerLV1", "");
                formik.setFieldValue("idManagerLV2", "");
                setJob({
                  division: "",
                  department: "",
                  section: "",
                  jobGroup: "",
                });
              }
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.idCompany && Boolean(formik.errors.idCompany)}
            helperText={
              formik.touched.idCompany &&
              Boolean(formik.errors.idCompany) &&
              formik.errors.idCompany
            }
            fullWidth
            select
          >
            {empDataForm &&
              empDataForm.map((item) => (
                <MenuItem key={item.idCompany} value={item.idCompany}>
                  {item.companyName}
                </MenuItem>
              ))}
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Position")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="idPositions"
            value={formik.values.idPositions}
            onChange={(event) => {
              formik.setFieldValue("idPositions", event.target.value);
              const foundJob = companySelect.job.find(
                (x) => x.idPositions === event.target.value
              );
              setJob({
                division:
                  foundJob && foundJob.divisionName
                    ? foundJob.divisionName
                    : "",
                department:
                  foundJob && foundJob.departmentName
                    ? foundJob.departmentName
                    : "",
                section:
                  foundJob && foundJob.sectionName ? foundJob.sectionName : "",
                jobGroup:
                  foundJob && foundJob.jobGroupName
                    ? foundJob.jobGroupName
                    : "",
              });
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.idPositions && Boolean(formik.errors.idPositions)
            }
            helperText={
              formik.touched.idPositions &&
              Boolean(formik.errors.idPositions) &&
              formik.errors.idPositions
            }
            fullWidth
            select
            disabled={!Boolean(formik.values.idCompany)}
          >
            {companySelect.job.map((item) => (
              <MenuItem key={item.idPositions} value={item.idPositions}>
                {item.positionsName}
              </MenuItem>
            ))}
          </TextFieldTheme>
          {/* <ButtonBlue
            onClick={() => {
              setIsOpenDialogAddPosition(true)
            }}
            disabled={!Boolean(formik.values.idCompany)}
          >
            เพิ่มตำแหน่งงาน
          </ButtonBlue> */}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Division")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            fullWidth
            variant="outlined"
            name="division"
            value={job.division}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Department")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            fullWidth
            variant="outlined"
            name="department"
            value={job.department}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("Section")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            fullWidth
            variant="outlined"
            name="section"
            value={job.section}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("JobGroup")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("JobLevel")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="idJobLevel"
            value={formik.values.idJobLevel}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.idJobLevel && Boolean(formik.errors.idJobLevel)
            }
            helperText={
              formik.touched.idJobLevel &&
              Boolean(formik.errors.idJobLevel) &&
              formik.errors.idJobLevel
            }
            fullWidth
            select
            disabled={!Boolean(formik.values.idCompany)}
          >
            {companySelect.jobLevel.map((item) => (
              <MenuItem key={item.idJobLevel} value={item.idJobLevel}>
                {item.jobLevelName}
              </MenuItem>
            ))}
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("PersonalLevel")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="idPersonnelLevel"
            value={formik.values.idPersonnelLevel}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.idPersonnelLevel &&
              Boolean(formik.errors.idPersonnelLevel)
            }
            helperText={
              formik.touched.idPersonnelLevel &&
              Boolean(formik.errors.idPersonnelLevel) &&
              formik.errors.idPersonnelLevel
            }
            fullWidth
            select
            disabled={!Boolean(formik.values.idCompany)}
          >
            {companySelect.jobLevel.map((item) => (
              <MenuItem key={item.idJobLevel} value={item.idJobLevel}>
                {item.jobLevelName}
              </MenuItem>
            ))}
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("EmployeeType")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="idEmployeeType"
            value={formik.values.idEmployeeType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.idEmployeeType &&
              Boolean(formik.errors.idEmployeeType)
            }
            helperText={
              formik.touched.idEmployeeType &&
              Boolean(formik.errors.idEmployeeType) &&
              formik.errors.idEmployeeType
            }
            fullWidth
            select
            disabled={!Boolean(formik.values.idCompany)}
          >
            {companySelect.employeeType.map((item) => (
              <MenuItem key={item.idEmployeeType} value={item.idEmployeeType}>
                {item.employeeTypeName}
              </MenuItem>
            ))}
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("FieldOffice")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              name="filed_office"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.filed_office}
              // error={
              //   formik.touched.filed_office &&
              //   Boolean(formik.errors.filed_office)
              // }
            >
              <Grid container>
                <Grid item md={6}>
                  <FormControlLabel
                    value="Field"
                    control={<Radio />}
                    label="Field"
                  />
                </Grid>
                <Grid item md={6}>
                  <FormControlLabel
                    value="Office"
                    control={<Radio />}
                    label="Office"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
            {formik.touched.filed_office &&
              Boolean(formik.errors.filed_office) && (
                <FormHelperText error>
                  {formik.errors.filed_office}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("WorkingType")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              name="workingType"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.workingType}
              // error={
              //   formik.touched.workingType &&
              //   Boolean(formik.errors.workingType)
              // }
            >
              <Grid container>
                <Grid item md={6}>
                  <FormControlLabel
                    value="Day"
                    control={<Radio />}
                    label="Day"
                  />
                </Grid>
                <Grid item md={6}>
                  <FormControlLabel
                    value="Shift"
                    control={<Radio />}
                    label="Shift"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
            {formik.touched.workingType &&
              Boolean(formik.errors.workingType) && (
                <FormHelperText error>
                  {formik.errors.workingType}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{`${t("ShiftType")}/${t("ShiftName")}`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="idShift"
            value={formik.values.idShift}
            onChange={(event) => {
              const foundShift = companySelect.shift.find(
                (x) => x.idShift === event.target.value
              );
              formik.setFieldValue(
                "idShift",
                foundShift && foundShift.idShift ? foundShift.idShift : ""
              );
              formik.setFieldValue(
                "idShiftGroup",
                foundShift && foundShift.idShiftGroup
                  ? foundShift.idShiftGroup
                  : ""
              );
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.idShift && Boolean(formik.errors.idShift)}
            helperText={
              formik.touched.idShift &&
              Boolean(formik.errors.idShift) &&
              formik.errors.idShift
            }
            fullWidth
            select
            disabled={!Boolean(formik.values.idCompany)}
          >
            {companySelect.shift.map((item) => (
              <MenuItem key={item.idShift} value={item.idShift}>
                {`${item.nameShiftGroup} : ${item.nameShift}`}
              </MenuItem>
            ))}
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("WorkingLocation")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="workingLocationName"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.workingLocationName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.workingLocationName &&
              Boolean(formik.errors.workingLocationName)
            }
            helperText={
              formik.touched.workingLocationName &&
              Boolean(formik.errors.workingLocationName) &&
              formik.errors.workingLocationName
            }
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Typography>{t("MainWorkingLocation")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="mainWorkingLocationPoint"
            value={formik.values.mainWorkingLocationPoint}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.mainWorkingLocationPoint &&
              Boolean(formik.errors.mainWorkingLocationPoint)
            }
            helperText={
              formik.touched.mainWorkingLocationPoint &&
              Boolean(formik.errors.mainWorkingLocationPoint) &&
              formik.errors.mainWorkingLocationPoint
            }
            fullWidth
            select
            disabled={!Boolean(formik.values.idCompany)}
          >
            {companySelect.groupGpsLocations.map((item) => (
              <MenuItem
                key={item.idGroupGpsLocations}
                value={item.idGroupGpsLocations}
              >
                {item.name}
              </MenuItem>
            ))}
          </TextFieldTheme>
        </Grid> */}
        <Grid item xs={12} md={4}>
          <Typography>{t("Finger")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="isFinger"
            value={formik.values.isFinger}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.isFinger && Boolean(formik.errors.isFinger)}
            helperText={
              formik.touched.isFinger &&
              Boolean(formik.errors.isFinger) &&
              formik.errors.isFinger
            }
            fullWidth
            select
          >
            <MenuItem value="1">{t("Yes")}</MenuItem>
            <MenuItem value="0">{t("No")}</MenuItem>
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("MethodAttendance")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="methodAttendance"
            value={formik.values.methodAttendance}
            onChange={(e) => {
              formik.setFieldValue("mainWorkingLocationPoint", "")
              formik.handleChange(e)
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.methodAttendance &&
              Boolean(formik.errors.methodAttendance)
            }
            helperText={
              formik.touched.methodAttendance &&
              Boolean(formik.errors.methodAttendance) &&
              formik.errors.methodAttendance
            }
            fullWidth
            select
          >
            <MenuItem value="main">{t("MainLocation")}</MenuItem>
            <MenuItem value="all">{t("AllLocation")}</MenuItem>
            <MenuItem value="point">{t("Coordinates")}</MenuItem>
          </TextFieldTheme>
        </Grid>

        {formik.values.methodAttendance === "main" && <Fragment>
          <Grid item xs={12} md={4}>
            <Typography>{t("MainWorkingLocation")}</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextFieldTheme
              name="mainWorkingLocationPoint"
              value={formik.values.mainWorkingLocationPoint}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.mainWorkingLocationPoint &&
                Boolean(formik.errors.mainWorkingLocationPoint)
              }
              helperText={
                formik.touched.mainWorkingLocationPoint &&
                Boolean(formik.errors.mainWorkingLocationPoint) &&
                formik.errors.mainWorkingLocationPoint
              }
              fullWidth
              select
              disabled={!Boolean(formik.values.idCompany)}
            >
              {companySelect.groupGpsLocations.map((item) => (
                <MenuItem
                  key={item.idGroupGpsLocations}
                  value={item.idGroupGpsLocations}
                >
                  {item.name}
                </MenuItem>
              ))}
            </TextFieldTheme>
          </Grid>
        </Fragment>}

        <Grid item xs={12} md={4}>
          <Typography>{t("ContractTerminate")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              name="isContractTerminate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.isContractTerminate}
            >
              <Grid container>
                <Grid item md={6}>
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label={`${t("Unspecified")}`}
                  />
                </Grid>
                <Grid item md={6}>
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={`${t("DateSpecify")}`}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
            {formik.touched.isContractTerminate &&
              Boolean(formik.errors.isContractTerminate) && (
                <FormHelperText error>
                  {formik.errors.isContractTerminate}
                </FormHelperText>
              )}
          </FormControl>
        </Grid>
        {formik.values.isContractTerminate === "1" && (
          <Fragment>
            <Grid item xs={12} md={4}>
              {/* <Typography>วันที่เริ่มงาน</Typography> */}
            </Grid>
            <Grid item xs={12} md={8}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  // label=""
                  name="contractTermainatoinDate"
                  value={formik.values.contractTermainatoinDate}
                  onChange={(date) => {
                    formik.setFieldValue("contractTermainatoinDate", date);
                  }}
                  openTo="year"
                  views={["year", "month", "day"]}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      error={
                        formik.touched.contractTermainatoinDate &&
                        Boolean(formik.errors.contractTermainatoinDate)
                      }
                      helperText={
                        formik.touched.contractTermainatoinDate &&
                        Boolean(formik.errors.contractTermainatoinDate) &&
                        formik.errors.contractTermainatoinDate
                      }
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD/MM/YYYY",
                      }}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Fragment>
        )}
      </Grid>
      <DialogAddPosition
        open={isOpenDialogAddPosition}
        onClose={() => {
          setIsOpenDialogAddPosition(false);
        }}
      />
    </FormContainer>
  );
};

export default JobInfo;
