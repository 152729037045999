import React, { useEffect } from "react"
import DrawerCustom from "../../../../shared/general/Drawer";
import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, MenuItem, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
// import DatePickerCustom from "../../../../shared/date/datePicker";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import { getAffiliateShiftGroup } from "../../../../../../actions/affiliate";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { getEmployeeShiftChangeHistory, patchEmployeeShiftChangeHistoryDaily } from "../../../../../../actions/employee";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  paddingBottom: "8px"
});

const DrawerEditDailyShiftChange = (props) => {

  const { drawerConfig, handleClose } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: affiliateShiftGroup } = useSelector(state => state.shift);
  // const { result: affiliateShiftGroup } = useSelector(state => state.affiliateShiftGroup);

  const validateYupSchema = yup.object({
    workingDate: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    shift: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    shiftGroup: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    shiftType: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
  });

  const useHookForm = useForm({
    defaultValues: {
      workingDate: null,
      shiftGroup: null,
      shift: null,
      shiftType: null,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  // useEffect(() => {
  //   if(drawerConfig.isOpen){
  //     dispatch(getAffiliateShiftGroup())
  //   }
  // }, [drawerConfig.isOpen])

  useEffect(() => {
    if(affiliateShiftGroup && affiliateShiftGroup.length > 0 && drawerConfig.isOpen){

      const foundShiftGroup = affiliateShiftGroup.find(item => item.idShiftGroup === drawerConfig.data.idShiftGroup)
      let foundShift = null;
      let foundShiftType = null;

      if(foundShiftGroup){
        foundShift = foundShiftGroup.shift.find(item => item.idShift === drawerConfig.data.idShift)
      }

      if(foundShiftGroup){
        foundShiftType = foundShiftGroup.shiftType.find(item => item.idShiftType === drawerConfig.data.idShiftType)
      }

      
      useHookForm.setValue("workingDate", drawerConfig.data.workingDate? dayjs(drawerConfig.data.workingDate): null, { shouldDirty: false })
      useHookForm.setValue("shiftGroup", foundShiftGroup? foundShiftGroup: null, { shouldDirty: false })
      useHookForm.setValue("shift", foundShift? foundShift: null, { shouldDirty: false })
      useHookForm.setValue("shiftType", foundShiftType? foundShiftType: null, { shouldDirty: false })

    }
  }, [affiliateShiftGroup, drawerConfig.isOpen])

  const shiftGroup = useHookForm.watch("shiftGroup")

  const onSubmit = async (data) => {

    const formData = {
      workingDate: dayjs(data.workingDate).format("YYYY-MM-DD"),
      idShiftGroup: data.shiftGroup.idShiftGroup,
      idShift: data.shift.idShift,
      idShiftType: data.shiftType.idShiftType,
    }

    const result = await dispatch(patchEmployeeShiftChangeHistoryDaily(employeeProfile.idEmp, drawerConfig.data.idEmployeeShiftDaily, formData));

    if(result && result.status === 200) {
      handleClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getEmployeeShiftChangeHistory(employeeProfile.idEmp, { type: "daily" }))
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }

  }

  return (
    <DrawerCustom
      title={t("DailyShiftChange")}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledHeadLabel color="text.secondary">
                  {`${t("Date")}`}
                </StyledHeadLabel>
                <Controller
                  name="workingDate"
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <DatePicker
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="DD MMMM YYYY"
                      openTo="year"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: t("SelectDate"),
                            readOnly: true
                          }}
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <StyledHeadLabel color="text.secondary">
                  {`${t("ShiftGroup")}`}
                </StyledHeadLabel>
                <Controller
                  name="shiftGroup"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={affiliateShiftGroup? affiliateShiftGroup: []}
                      getOptionLabel={(option) => `${option.nameShiftGroup}`}
                      isOptionEqualToValue={(option, value) => option.idShiftGroup === value.idShiftGroup}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idShiftGroup}>
                          <Box>
                            <Typography>{option.nameShiftGroup}</Typography>
                            <Typography color="text.secondary" fontSize="12px">{option.companyName}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={t("SelectData")}
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                        useHookForm.setValue("shift", null)
                        useHookForm.setValue("shiftType", null)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <StyledHeadLabel color="text.secondary">
                  {`${t("ShiftName")}`}
                </StyledHeadLabel>
                <Controller
                  name="shift"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={shiftGroup? shiftGroup.shift: []}
                      getOptionLabel={(option) => `${option.nameShift}`}
                      isOptionEqualToValue={(option, value) => option.idShift === value.idShift}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idShift}>
                          <Box>
                            <Typography>{option.nameShift}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={t("SelectData")}
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <StyledHeadLabel color="text.secondary">
                  {`${t("ShiftType")}`}
                </StyledHeadLabel>
                <Controller
                  name="shiftType"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={shiftGroup? shiftGroup.shiftType: []}
                      getOptionLabel={(option) => `${option.nameShiftType}`}
                      isOptionEqualToValue={(option, value) => option.idShiftType === value.idShiftType}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idShiftType}>
                          <Box>
                            <Typography>{option.nameShiftType}</Typography>
                            <Typography color="text.secondary" fontSize="12px">{dayjs(option.timeIn, "HH:mm:ss").format("HH:mm")} - {dayjs(option.timeOut, "HH:mm:ss").format("HH:mm")}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={t("SelectData")}
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} container justifyContent="space-between">
                <ButtonBlue variant="text" onClick={handleClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
                <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>{t("Save")}</ButtonBlue>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEditDailyShiftChange;