import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  linearProgressClasses,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledTableCellContent = styled(TableCell)({
  borderBottom: "none",
  padding: "8px 16px",
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  "& .primary-name": {
    fontSize: 16,
  },
  "& .secondary-name": {
    fontSize: 16,
    color: "#9e9e9e",
  },
  "& .actual-plan": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 200,
    margin: "auto",
    padding: "0 20px",
  },
  "& .actual": {
    fontWeight: 600,
    fontSize: 24,
  },
  "& .plan": {
    fontWeight: 600,
    color: "#919eab",
  },
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  width: "100%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const BorderLinearOverProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  width: "100%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#ff3034",
  },
}));

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  var actual = Math.floor(Math.random() * 201);
  var plan = Math.floor(Math.random() * 201);

  return (
    <React.Fragment>
      {row.departmentName && (
        <>
          <TableRow>
            <StyledTableCellContent>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCellContent>
            <StyledTableCellContent>
              <Typography className="secondary-name" variant="h6">
                {`Dep-00${row.idDepartment}`}
              </Typography>
            </StyledTableCellContent>
            <StyledTableCellContent>
              <Typography className="primary-name" variant="h6">
                {row.departmentName}
              </Typography>
            </StyledTableCellContent>
            <StyledTableCellContent align="center">
              <Typography className="secondary-name" variant="h6">
                Department
              </Typography>
            </StyledTableCellContent>
            <StyledTableCellContent align="center">
              <div className="actual-plan">
                <Typography align="center" gutterBottom>
                  <span className="actual">{actual}</span> /{" "}
                  <span className="plan">{plan}</span>
                </Typography>
                <div>
                  {actual > plan ? (
                    <BorderLinearOverProgress
                      variant="determinate"
                      value={100}
                    />
                  ) : (
                    <BorderLinearProgress
                      variant="determinate"
                      value={(actual / plan) * 100}
                    />
                  )}
                </div>
              </div>
            </StyledTableCellContent>
            <StyledTableCellContent align="right">
              <div style={{ minWidth: 120 }}>
                <IconButton
                  aria-label="view"
                  style={{ marginRight: 8 }}
                  component={NavLink}
                  to={`/admin/manpower/${row.idDepartment}`}
                >
                  <PersonSearchOutlinedIcon />
                </IconButton>
                <IconButton aria-label="edit">
                  <EditOutlinedIcon />
                </IconButton>
              </div>
            </StyledTableCellContent>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottom: "1px dashed #919eab52",
              }}
              colSpan={6}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1, paddingTop: 2, paddingBottom: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 3,
                    }}
                    component="div"
                  >
                    <AttachFileIcon
                      sx={{
                        backgroundColor: "#f5f5f5",
                        padding: 1,
                        borderRadius: "10px",
                        marginRight: 1,
                        fontSize: "18px",
                      }}
                    />
                    Section
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledCellHeader>โค้ด</StyledCellHeader>
                        <StyledCellHeader>ชื่อ</StyledCellHeader>
                        <StyledCellHeader>ระดับ</StyledCellHeader>
                        <StyledCellHeader>
                          กำลังพลปัจจุบัน / แผนกำลังพล
                        </StyledCellHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <StyledTableCellContent
                          component="th"
                          scope="row"
                          sx={{ paddingTop: 2, paddingBottom: 2 }}
                        >
                          <Typography className="secondary-name" variant="h6">
                            Sec-001
                          </Typography>
                        </StyledTableCellContent>
                        <StyledTableCellContent>
                          <Typography className="primary-name" variant="h6">
                            Sec-Name
                          </Typography>
                        </StyledTableCellContent>
                        <StyledTableCellContent>
                          <Typography className="secondary-name" variant="h6">
                            Section
                          </Typography>
                        </StyledTableCellContent>
                        <StyledTableCellContent align="center">
                          <div className="actual-plan">
                            <Typography align="center" gutterBottom>
                              <span className="actual">{actual}</span> /{" "}
                              <span className="plan">{plan}</span>
                            </Typography>
                            <div>
                              {actual > plan ? (
                                <BorderLinearOverProgress
                                  variant="determinate"
                                  value={100}
                                />
                              ) : (
                                <BorderLinearProgress
                                  variant="determinate"
                                  value={(actual / plan) * 100}
                                />
                              )}
                            </div>
                          </div>
                        </StyledTableCellContent>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </React.Fragment>
  );
}

function DataTable(props) {
  const { departmentList } = props;
  const [open, setOpen] = useState(false);

  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledCellHeader></StyledCellHeader>
              <StyledCellHeader>โค้ด</StyledCellHeader>
              <StyledCellHeader>ชื่อ</StyledCellHeader>
              <StyledCellHeader align="center">ระดับ</StyledCellHeader>
              <StyledCellHeader align="center">
                กำลังพลปัจจุบัน / แผนกำลังพล
              </StyledCellHeader>
              <StyledCellHeader
                align="center"
                style={{ minWidth: 120 }}
              ></StyledCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {departmentList &&
              departmentList.map((row, index) => {
                return <Row key={row.departmentName} row={row} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DataTable;
