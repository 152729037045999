import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Box, Card, Container, Grid, LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import en from "dayjs/locale/en";
import { getAllEmployees } from "../../../../../actions/employee";
import { getAllRequestTimeById } from "../../../../../actions/requestTime";

import GroupsIcon from "@mui/icons-material/Groups";

import StyledCard from "../../../shared/general/CardDark";

import CardSummary from "../cardSummary";
import CardChart from "../cardChart";
import CardRank from "../cardRank";

import utils from "../../../../../utils";

const rand40 = () => {
  return Math.floor(Math.random() * 40);
};

const StyledWrapOT36Hours = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  backgroundColor: "#fff",
  borderRadius: 20,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  position: "relative",
  padding: 24,
  "& .MuiTypography-h3": {
    fontSize: 60,
  },
  "& .MuiTypography-body1": {
    paddingTop: 24,
  },
  "& .iconEmployee": {
    position: "absolute",
    right: 38,
    bottom: 36,
    color: "#007afe",
    "& svg": {
      fontSize: 180,
    },
    "& i": {
      fontSize: 100,
    },
  },
});

export default function DashboardWorkingTime() {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: empStore } = useSelector((state) => state.employees);
  const { result: reqTimeStore } = useSelector((state) => state.requestTime);

  const [monthDate, setMonthDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [reqTimes, setReqTimes] = useState([]);
  const [reqTimesWeek, setReqTimesWeek] = useState([]);
  const [empOver36, setEmpOver36] = useState([]);
  const [empOTBaht, setEmpOTBaht] = useState([]);
  const [allHoursOver36, setAllHoursOver36] = useState(0);
  const [chartOT36, setChartOT36] = useState([]);
  const [otType, setOtType] = useState([]);
  const [valueSumOT, setValueSumOT] = useState({
    allOT: 0,
    xOne: 0,
    xOneFive: 0,
    xTwo: 0,
    xThree: 0,
  });
  const [valueSumSalary, setValueSumSalary] = useState(0);
  const [totalSalary, setTotalSalary] = useState([]);
  const [totalOT, setTotalOT] = useState([]);

  useEffect(() => {
    if (empStore) {
      /* Fetch Employees */
      let empList = [...empStore];
      let empFilter = empList.filter(function (e) {
        return (
          e.idManagerLV1 === currentUser.id || e.idManagerLV2 === currentUser.id
        );
      });

      setEmployees(empFilter);
    }
  }, [empStore]);

  useEffect(() => {
    if (reqTimeStore) {
      /* Fetch RequestTimes */
      let reqTimeList = [...reqTimeStore];
      let reqTimeFilter = reqTimeList.filter(function (req) {
        return (
          req.idManager === currentUser.id &&
          req.isApprove === 1 &&
          (req.idRequestType === 2 || req.idRequestType === 3)
        );
      });

      setReqTimes(reqTimeFilter);

      //Filter By Month
      let reqTimeWeekFilter = reqTimeList.filter(function (req) {
        var dateStart = new Date(req.requestTimeStart);
        var dateEnd = new Date(req.requestTimeEnd);

        return (
          dateStart >= monthDate.startDate &&
          dateEnd <= monthDate.endDate &&
          req.idManager === currentUser.id &&
          req.isApprove === 1 &&
          (req.idRequestType === 2 || req.idRequestType === 3)
        );
      });

      //หาค่าทำงานล่วงเวลาทั้งหมด
      totalOTValue(reqTimeWeekFilter);

      //ค่าใช้จ่ายทำงานล่วงเวลา (บาท) / ปี
      fetchValueOTByYear(reqTimeFilter);

      setIsLoading(false);
    }
  }, [reqTimeStore]);

  const totalOTValue = (reqTimeFilter) => {
    /* ทำงานล่วงเวลา (บาท) */
    //Sum All OT Type
    let sumValueOT = reqTimeFilter.reduce(function (accumulator, curValue) {
      return accumulator + curValue.valueOT;
    }, 0);

    let sumValueXOne = reqTimeFilter.reduce(function (accumulator, curValue) {
      return accumulator + curValue.valueSumXOne;
    }, 0);

    let sumValueXOneFive = reqTimeFilter.reduce(function (
      accumulator,
      curValue
    ) {
      return accumulator + curValue.valueSumXOneFive;
    },
    0);

    let sumValueXTwo = reqTimeFilter.reduce(function (accumulator, curValue) {
      return accumulator + curValue.valueSumXTwo;
    }, 0);

    let sumValueXThree = reqTimeFilter.reduce(function (accumulator, curValue) {
      return accumulator + curValue.valueSumXThree;
    }, 0);

    setValueSumOT({
      allOT: sumValueOT ? sumValueOT : 0,
      xOne: sumValueXOne ? sumValueXOne : 0,
      xOneFive: sumValueXOneFive ? sumValueXOneFive : 0,
      xTwo: sumValueXTwo ? sumValueXTwo : 0,
      xThree: sumValueXThree ? sumValueXThree : 0,
    });
  };

  const fetchValueOTByYear = (reqTimeFilter) => {
    const d = new Date();
    let year = d.getFullYear();

    //Array month of year
    let array = [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ];

    for (var i = 0; i < 12; i++) {
      let reqMonthYear = reqTimeFilter.filter(function (req) {
        let dateReq = new Date(req.requestTimeStart);
        let yearItem = dateReq.getFullYear();
        let monthItem = dateReq.getMonth();

        //January = 0
        return monthItem === i && yearItem === year;
      });

      //หาผลรวมค่า OT ทุกประเภท
      let sumValueOT = reqMonthYear.reduce(function (accumulator, curValue) {
        return accumulator + curValue.valueOT;
      }, 0);

      //update value in array
      array[0].data[i] = sumValueOT;
    }

    setTotalOT(array);
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(getAllEmployees());
      dispatch(getAllRequestTimeById());

      var startOfMonth = dayjs().startOf("month").toDate();
      var endOfMonth = dayjs().endOf("month").toDate();

      setMonthDate({
        startDate: startOfMonth,
        endDate: endOfMonth,
      });
    }
  }, []);

  return (
    <Container>
      <Box sx={{ paddingTop: 12 }}>
        <Typography variant="h4" style={{ marginTop: 16, marginBottom: 16 }}>
          ภาพรวมค่าใช้จ่าย
        </Typography>
        {isLoading ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CardSummary
                      value={{
                        label: "พนักงาน(คน)",
                        isPeople: true,
                        amount: employees.length,
                        icon: <GroupsIcon />,
                      }}
                      top={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CardSummary
                      value={{
                        label: "เงินเดือน (บาท)",
                        amount: 0,
                        icon: <i class="fal fa-lightbulb-dollar"></i>,
                      }}
                      top={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CardSummary
                      value={{
                        label: "ค่าล่วงเวลา (บาท)",
                        amount: valueSumOT.allOT,
                        icon: <i class="fal fa-sack-dollar"></i>,
                      }}
                      top={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ height: "81%" }}>
                  <StyledWrapOT36Hours>
                    <Typography color="text.secondary" variant="body1">
                      {`ค่าใช้จ่ายทั้งหมด (บาท)`}
                    </Typography>
                    <Grid container>
                      <Grid item>
                        <Typography variant="h4" align={"left"}>
                          {utils.numberWithCommas(
                            valueSumOT.allOT + valueSumSalary
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div className={`iconEmployee`}>
                          <i class="fal fa-search-dollar"></i>
                        </div>
                      </Grid>
                    </Grid>
                  </StyledWrapOT36Hours>
                </Box>
              </Grid>
            </Grid>
            <div style={{ marginTop: 16 }}>
              <CardChart
                chart="area"
                chartName={`ภาพรวมเงินเดือน (บาท)`}
                totalSalary={totalSalary}
                max={0}
              />
            </div>
            <div style={{ marginTop: 16, marginBottom: 36 }}>
              <CardChart
                chart="area"
                chartName={`ภาพรวมค่าล่วงเวลา (บาท)`}
                totalOT={totalOT}
                max={0}
              />
            </div>
          </>
        )}
      </Box>
    </Container>
  );
}
