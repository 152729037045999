import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CardStyle from "../../../../shared/general/Card";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

const StyleTextField = styled(TextField)({
  width: "100%",
  "& .MuiInputLabel-root": {
    color: "#637381",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
});

const initialValues = () => ({
  type: "payPeriod",
  company: "",
  branch: "",
  department: "",
  branchCode: "",
  payYear: "2022",
  payDateFrom: new Date(),
  payDateTo: new Date(),
  submitDate: new Date(),
  showEmpSalary: false,
});

//mock data
const companyData = [
  { label: "Company 1" },
  { label: "Company 2" },
  { label: "Company 3" },
];

const branchData = [
  { label: "Branch 1", code: "Branch Code 1" },
  { label: "Branch 2", code: "Branch Code 2" },
  { label: "Branch 3", code: "Branch Code 3" },
];

const departmentData = [
  { label: "Department 1" },
  { label: "Department 2" },
  { label: "Department 3" },
];

const payPeriodData = [{ label: "2021", label: "2022" }];

function Index() {
  const [values, setValues] = useState(initialValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOnExport = () => {
    console.log("values", values);
  };

  const handleOnGenerate = () => {
    console.log("values", values);
  };

  return (
    <div className="page">
      <Container maxWidth="lg">
        <Box sx={{ marginTop: 1, marginBottom: 2 }}>
          <Typography variant="h4">ภงด. 1 ก.</Typography>
        </Box>
        <CardStyle>
          <Box sx={{ padding: "20px" }}>
            <FormControl sx={{ marginBottom: 2 }}>
              <RadioGroup
                row
                name="type"
                value={values.type}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="payPeriod"
                  control={<Radio />}
                  label="Pay Period"
                />
                <FormControlLabel
                  value="payDate"
                  control={<Radio />}
                  label="Pay Date"
                />
              </RadioGroup>
            </FormControl>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              {/*
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  options={companyData}
                  value={values.company}
                  onChange={(event, newValue) => {
                    setValues({
                      ...values,
                      company: newValue.label,
                    });
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <StyleTextField {...params} label="Company" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  options={branchData}
                  value={values.branch}
                  onChange={(event, newValue) => {
                    setValues({
                      ...values,
                      branch: newValue,
                    });
                  }}
                  getOptionLabel={(option) => (option ? option.label : "")}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <StyleTextField {...params} label="Branch" />
                  )}
                />
              </Grid>
               */}

              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  options={departmentData}
                  value={values.department}
                  onChange={(event, newValue) => {
                    setValues({
                      ...values,
                      department: newValue.label,
                    });
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <StyleTextField {...params} label="Department" />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  options={branchData}
                  value={values.branchCode}
                  onChange={(event, newValue) => {
                    setValues({
                      ...values,
                      branchCode: newValue,
                    });
                  }}
                  getOptionLabel={(option) => (option ? option.code : "")}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <StyleTextField {...params} label="Branch Code" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                {values.type === "payPeriod" ? (
                  <StyleTextField
                    select
                    label="Pay Period"
                    value={values.payYear}
                    onChange={(e) =>
                      setValues({ ...values, payYear: e.target.value })
                    }
                    displayEmpty
                    fullWidth
                  >
                    {payPeriodData.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </StyleTextField>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6} lg={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Pay Date From"
                            inputFormat="dd/MM/yyyy"
                            value={values.payDateFrom}
                            onChange={(newValue) => {
                              setValues({
                                ...values,
                                payDateFrom: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <StyleTextField {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Pay Date To"
                            inputFormat="dd/MM/yyyy"
                            value={values.payDateTo}
                            onChange={(newValue) => {
                              setValues({
                                ...values,
                                payDateTo: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <StyleTextField {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Submit Date"
                    inputFormat="dd/MM/yyyy"
                    value={values.submitDate}
                    onChange={(newValue) => {
                      setValues({
                        ...values,
                        submitDate: newValue,
                      });
                    }}
                    renderInput={(params) => <StyleTextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: 2 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.showEmpSalary}
                      onClick={(event) =>
                        setValues({
                          ...values,
                          showEmpSalary: event.target.checked,
                        })
                      }
                    />
                  }
                  label="Show Employee Without Salary"
                />
              </FormGroup>
            </Box>
            <Divider sx={{ marginTop: 4, marginBottom: 2 }} />
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <ButtonBlue variant="outlined" onClick={handleOnExport}>
                  Export Text
                </ButtonBlue>
              </Grid>
              <Grid item>
                <ButtonBlue variant="outlined" onClick={handleOnGenerate}>
                  Generate
                </ButtonBlue>
              </Grid>
            </Grid>
          </Box>
        </CardStyle>
      </Container>
    </div>
  );
}

export default Index;
