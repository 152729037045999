import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Container, Divider, Grid } from "@mui/material";

import Header from "./header"
import Content from "./content"


const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const CourseDetail = () => {
  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Header />
        <Content />
      </Container>
    </StyledRoot>
  );
};

export default CourseDetail;
