import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Box, Checkbox,
  Drawer, FilledInput, FormControl,
  InputAdornment, Popper, TextField, Typography
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import SearchIcon from "@mui/icons-material/Search";

const StyledFilter = styled(Box)({
  display: "flex",
  marginBottom: 16,
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  width: "100%",
  marginTop: 8,
  "&.first": {
    marginRight: 8,
  },
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const FilterTable = (props) => {
  const { t, i18n } = useTranslation();
  const { 
    handleChange, handleChangeVendor
  } = props;
  const { result: companyContractList } = useSelector(
    (state) => state.companyContract
  );

  return (
      <StyledFilter>
        <StyledBoxSearch className="first">
          <Typography className="label" color="text.third">
            {t("Search")} {t("FullName")}
          </Typography>
          <FormControl fullWidth variant="filled">
            <StyledFilledInput
              id="filled-sarch-name"
              placeholder={`${t("Search")} ${t("FullName")}`}
              name="search"
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </StyledBoxSearch>
        <StyledBoxSearch>
          <Typography className="label" color="text.third">
            {t("Vendor")}
          </Typography>
          <StyledAutocomplete
            fullWidth
            name="idVendor"
            options={companyContractList}
            onChange={(_, newValue) => {
              handleChangeVendor(newValue === null ? "all" : newValue.idVendor);
            }}
            popupIcon={<i className="fa-light fa-chevron-down"></i>}
            getOptionLabel={(option) => option.vendorName}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                placeholder={`${t("AllVendor")}`}
                name="idVendor"
              />
            )}
            PopperComponent={StyledPopper}
            noOptionsText={`${t("No_Data")}`}
          />
        </StyledBoxSearch>
      </StyledFilter>
  );
};

export default FilterTable;
