import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";

import {
  postGpsLocations,
  updateGpsLocations,
  getAllLocations,
} from "../../../../../../../actions/gpsLocations";
import { Typography } from "@mui/material";

import AddMap from "./addMap";
import EditMap from "./editMap";

const StyleWrapForm = styled("div")({
  paddingTop: 16,
});

const DialogFormGpsPositions = (props) => {
  const {
    open,
    handleClose,
    selectedGroup,
    mode,
    handleOpenAlert,
    handleChangeAlertType,
    company,
  } = props;
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [textField, setTextField] = useState("");
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    idCompany: "",
    isActive: true,
    idGpsLocations: "",
    idGroupGpsLocations: "",
    positions: null,
  });

  useEffect(() => {
    if (mode === "edit" && selectedGroup) {
      setTextField(selectedGroup.name);

      var temp = { ...formData };
      temp.name = selectedGroup.name;
      temp.positions = selectedGroup.positions;
      temp.idGpsLocations = selectedGroup.idGpsLocations;

      setFormData(temp);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (mode === "add") {
      handleReset();
    }
  }, [mode]);

  const handleSaveGpsLocations = async () => {
    if (userProfile) {
      formData.name = textField;
      formData.idCompany = company.idCompany;
      formData.createBy = userProfile.idEmp;
      formData.createDate = dayjs(new Date()).format("YYYY-MM-DD");
      formData.idGroupGpsLocations = selectedGroup.idGroupGpsLocations;
      const result = await dispatch(postGpsLocations(formData));
      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          dispatch(getAllLocations(company.idCompany));
        } else {
          handleChangeAlertType("error");
        }
        handleClose();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const handleUpdateGpsLocations = async () => {
    if (userProfile) {
      formData.name = textField;
      formData.idCompany = company.idCompany;
      formData.editBy = userProfile.idEmp;
      formData.editDate = dayjs(new Date()).format("YYYY-MM-DD");
      formData.idGroupGpsLocations = selectedGroup.idGroupGpsLocations;
      if (formData.positionsNew) {
        var tempPositions = formData.positions.map((item) => ({
          ...item,
          isActive: 0,
        }));

        formData.positions = tempPositions;
      }
      const result = await dispatch(updateGpsLocations(formData));
      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          if (userProfile) {
            dispatch(getAllLocations(company.idCompany));
          }
        } else {
          handleChangeAlertType("error");
        }
        handleClose();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const handleChange = (event) => {
    setTextField(event.target.value);
  };

  const handleChangePositions = (positions) => {
    setFormData({ ...formData, ["positions"]: positions });
  };

  const handleChangePositionsNew = (positions) => {
    setFormData({ ...formData, ["positionsNew"]: positions });
  };

  const handleReset = () => {
    setTextField("");
    setFormData({
      name: "",
      idCompany: "",
      isActive: true,
      idGpsLocations: "",
      idGroupGpsLocations: "",
      positions: null,
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {mode === "add" ? `${t("AddLocation")}` : `${t("EditLocation")}`}
      </DialogTitle>
      <DialogContent style={{ paddingTop: 16 }}>
        <div style={{ marginBottom: 16 }}>
          <TextFieldTheme
            onChange={handleChange}
            name={"name"}
            label={t("LocationName")}
            value={textField}
          />
        </div>
        {mode === "add" ? (
          <AddMap
            selectedGroup={selectedGroup}
            handleChangePositions={handleChangePositions}
          />
        ) : (
          <EditMap
            selectedGroup={selectedGroup}
            handleChangePositions={handleChangePositionsNew}
          />
        )}
      </DialogContent>
      <DialogActions>
        <ButtonBlue
          onClick={() => {
            if (mode === "add") {
              handleReset();
            }
            handleClose();
          }}
        >
          {t("Cancel")}
        </ButtonBlue>
        <ButtonBlue
          onClick={
            mode === "add" ? handleSaveGpsLocations : handleUpdateGpsLocations
          }
          variant={"contained"}
          autoFocus
          disabled={formData.positions == null || textField === ""}
        >
          {t("Save")}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFormGpsPositions;
