import {
  LEAVEAPPROVES_DETAIL_FETCHING,
  LEAVEAPPROVES_DETAIL_FAILED,
  LEAVEAPPROVES_DETAIL_SUCCESS,
} from "./types";

import LeaveApprovesService from "../services/leaveApproves.service";

export const getAllLeaveApproves = () => async (dispatch) => {
  try {
    const res = await LeaveApprovesService.getAllLeaveApproves();
    if (res) {
      dispatch({
        type: LEAVEAPPROVES_DETAIL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LEAVEAPPROVES_DETAIL_FAILED,
    });
    console.log(err);
  }
};
