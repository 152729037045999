import { styled } from "@mui/material/styles";
import React from "react";
import NumberFormat from "react-number-format";

//import materials
import { CardContent, Divider, Grid, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const StyledCardContent = styled(CardContent)({
  height: 536,
  overflow: "auto",
  padding: "24px !important",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiFormControl-root": {
    width: "100%",
  },
});

const StyledProfileTitle = styled("div")({
  backgroundColor: "#2F74EB",
  padding: 16,
  "& .MuiTypography-root": {
    color: "#FFFFFF",
    fontSize: 20,
    fontWeight: 500,
  },
});

const StyleSelect = styled(Select)({
  borderRadius: 8,
  "& .MuiSelect-select": {
    padding: "13.5px 14px",
  },
});

const StyledTextHeading = styled(Typography)({
  fontSize: 16,
  flexBasis: "33.33%",
  marginTop: 16,
  "&.error": {
    color: "#f15e5e",
  },
});

const titles = [
  {
    label: "นาย",
    value: "นาย",
  },
  {
    label: "นางสาว",
    value: "นางสาว",
  },
  {
    label: "นาง",
    value: "นาง",
  },
];

export default function BasicInfo(props) {
  const { onChange, formik } = props;

  return (
    <div>
      <form autoComplete="off" noValidate>
        <StyledProfileTitle>
          <Typography style={{ marginLeft: 8 }}>ข้อมูลทั่วไป</Typography>
        </StyledProfileTitle>
        <Divider />
        <StyledCardContent>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.employeeId &&
                  Boolean(formik.errors.employeeId) &&
                  "error"
                }
              >
                รหัสพนักงาน
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                variant="outlined"
                name="employeeId"
                onChange={onChange}
                value={formik.values.employeeId}
                error={
                  formik.touched.employeeId && Boolean(formik.errors.employeeId)
                }
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.employeeId &&
                  Boolean(formik.errors.employeeId) &&
                  formik.errors.employeeId
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.title &&
                  Boolean(formik.errors.title) &&
                  "error"
                }
              >
                คำนำหน้า
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <FormControl fullWidth>
                <StyleSelect
                  name="title"
                  onChange={onChange}
                  value={formik.values.title}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  onBlur={formik.handleBlur}
                >
                  {titles.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyleSelect>
                {formik.touched.title && Boolean(formik.errors.title) && (
                  <FormHelperText style={{ color: "#f15e5e" }}>
                    {formik.errors.title}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.firstname &&
                  Boolean(formik.errors.firstname) &&
                  "error"
                }
              >
                ชื่อจริง
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                variant="outlined"
                name="firstname"
                onChange={onChange}
                value={formik.values.firstname}
                error={
                  formik.touched.firstname && Boolean(formik.errors.firstname)
                }
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.firstname &&
                  Boolean(formik.errors.firstname) &&
                  formik.errors.firstname
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.lastname &&
                  Boolean(formik.errors.lastname) &&
                  "error"
                }
              >
                นามสกุล
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                variant="outlined"
                name="lastname"
                onChange={onChange}
                value={formik.values.lastname}
                error={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.lastname &&
                  Boolean(formik.errors.lastname) &&
                  formik.errors.lastname
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.title_en &&
                  Boolean(formik.errors.title_en) &&
                  "error"
                }
              >
                คำนำหน้า(อังกฤษ)
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <FormControl fullWidth>
                <StyleSelect
                  name="title_en"
                  onChange={onChange}
                  value={formik.values.title_en}
                  error={formik.touched.title_en && Boolean(formik.errors.title_en)}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="Mr.">
                    Mr.
                  </MenuItem>
                  <MenuItem value="Ms.">
                    Ms.
                  </MenuItem>
                </StyleSelect>
                {formik.touched.title_en && Boolean(formik.errors.title_en) && (
                  <FormHelperText style={{ color: "#f15e5e" }}>
                    {formik.errors.title_en}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.firstname_en &&
                  Boolean(formik.errors.firstname_en) &&
                  "error"
                }
              >
                ชื่อจริง(อังกฤษ)
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                variant="outlined"
                name="firstname_en"
                onChange={onChange}
                value={formik.values.firstname_en}
                error={
                  formik.touched.firstname_en && Boolean(formik.errors.firstname_en)
                }
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.firstname_en &&
                  Boolean(formik.errors.firstname_en) &&
                  formik.errors.firstname_en
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.lastname_en &&
                  Boolean(formik.errors.lastname_en) &&
                  "error"
                }
              >
                นามสกุล(อังกฤษ)
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                variant="outlined"
                name="lastname_en"
                onChange={onChange}
                value={formik.values.lastname_en}
                error={
                  formik.touched.lastname_en && Boolean(formik.errors.lastname_en)
                }
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.lastname_en &&
                  Boolean(formik.errors.lastname_en) &&
                  formik.errors.lastname_en
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">ชื่อเล่น</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                variant="outlined"
                name="nickname"
                onChange={onChange}
                value={formik.values.nickname}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.gender &&
                  Boolean(formik.errors.gender) &&
                  "error"
                }
              >
                เพศ
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender"
                  onChange={onChange}
                  value={formik.values.gender}
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                  onBlur={formik.handleBlur}
                >
                  <Grid container>
                    <Grid item md={6}>
                      <FormControlLabel
                        value="ชาย"
                        control={<Radio />}
                        label="ชาย"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel
                        value="หญิง"
                        control={<Radio />}
                        label="หญิง"
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
                {formik.touched.gender && Boolean(formik.errors.gender) && (
                  <FormHelperText style={{ color: "#f15e5e", marginLeft: 0 }}>
                    {formik.errors.gender}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.birthday &&
                  Boolean(formik.errors.birthday) &&
                  "error"
                }
              >
                วันเกิด
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  label=""
                  name="birthday"
                  id="birthday"
                  value={formik.values.birthday}
                  onChange={(date) => {
                    formik.setFieldValue("birthday", date);
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme
                      fullWidth
                      {...params}
                      error={
                        formik.touched.birthday &&
                        Boolean(formik.errors.birthday)
                      }
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.birthday &&
                        Boolean(formik.errors.birthday) &&
                        formik.errors.birthday
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.personalId &&
                  Boolean(formik.errors.personalId) &&
                  "error"
                }
              >
                หมายเลขบัตรประชาชน
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <NumberFormat
                customInput={TextFieldTheme}
                format="# #### ##### ## #"
                variant="outlined"
                name="personalId"
                onChange={onChange}
                value={formik.values.personalId}
                fullWidth
                error={
                  formik.touched.personalId && Boolean(formik.errors.personalId)
                }
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.personalId &&
                  Boolean(formik.errors.personalId) &&
                  formik.errors.personalId
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.telephoneMobile &&
                  Boolean(formik.errors.telephoneMobile) &&
                  "error"
                }
              >
                เบอร์โทรศัพท์
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <NumberFormat
                customInput={TextFieldTheme}
                format="###-###-####"
                variant="outlined"
                name="telephoneMobile"
                onChange={onChange}
                value={formik.values.telephoneMobile}
                fullWidth
                error={
                  formik.touched.telephoneMobile &&
                  Boolean(formik.errors.telephoneMobile)
                }
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.telephoneMobile &&
                  Boolean(formik.errors.telephoneMobile) &&
                  formik.errors.telephoneMobile
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">Email</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                fullWidth
                variant="outlined"
                type="email"
                name="email"
                onChange={onChange}
                value={formik.values.email}
              />
            </Grid>
            {/* <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">ที่อยู่</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextFieldTheme
                    id="outlined-multiline-static"
                    fullWidth
                    type="text"
                    variant="outlined"
                    name="address"
                    onChange={onChange}
                    value={formik.values.address}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldTheme
                    id="outlined-multiline-static"
                    fullWidth
                    type="text"
                    variant="outlined"
                    name="district"
                    onChange={onChange}
                    placeholder="เขต/อำเภอ"
                    value={formik.values.district}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextFieldTheme
                    id="outlined-multiline-static"
                    fullWidth
                    type="text"
                    variant="outlined"
                    name="provience"
                    onChange={onChange}
                    placeholder="จังหวัด"
                    value={formik.values.provience}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextFieldTheme
                    id="outlined-multiline-static"
                    fullWidth
                    type="text"
                    variant="outlined"
                    name="areaCode"
                    onChange={onChange}
                    placeholder="รหัสไปรษณีย์"
                    value={formik.values.areaCode}
                  />
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item xs={12} md={4}>
              <StyledTextHeading variant="h6">ที่อยู่</StyledTextHeading>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextFieldTheme
                    name="houseNo"
                    value={formik.values.houseNo}
                    onChange={formik.handleChange}
                    fullWidth
                    placeholder="บ้านเลขที่"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldTheme
                    name="vilage"
                    value={formik.values.vilage}
                    onChange={formik.handleChange}
                    fullWidth
                    placeholder="หมู่บ้าน"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldTheme
                    name="vilageNo"
                    value={formik.values.vilageNo}
                    onChange={formik.handleChange}
                    fullWidth
                    placeholder="หมู่ที่"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldTheme
                    name="road"
                    value={formik.values.road}
                    onChange={formik.handleChange}
                    fullWidth
                    placeholder="ถนน"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldTheme
                    name="alley"
                    value={formik.values.alley}
                    onChange={formik.handleChange}
                    fullWidth
                    placeholder="ซอย"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldTheme
                    name="areaCode"
                    value={formik.values.areaCode}
                    onChange={formik.handleChange}
                    fullWidth
                    placeholder="รหัสไปรษณีย์"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
             <StyledTextHeading variant="h6">ตำบล/แขวง</StyledTextHeading>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextFieldTheme
                name="subDistrict"
                value={formik.values.subDistrict}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledTextHeading variant="h6">เขต/อำเภอ</StyledTextHeading>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextFieldTheme
                name="district"
                value={formik.values.district}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledTextHeading variant="h6">จังหวัด</StyledTextHeading>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextFieldTheme
                name="provience"
                value={formik.values.provience}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">สัญชาติ</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                name="nationality"
                onChange={onChange}
                variant="outlined"
                value={formik.values.nationality}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">สถานะ</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                name="maritalStatus"
                onChange={onChange}
                variant="outlined"
                value={formik.values.maritalStatus}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">หนังสือเดินทาง</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                name="passportId"
                onChange={onChange}
                variant="outlined"
                value={formik.values.passportId}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">ใบอนุญาตทำงาน</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                name="workPermit"
                onChange={onChange}
                variant="outlined"
                value={formik.values.workPermit}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">
                ชื่อผู้ติดต่อฉุกเฉิน
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                name="emergencyContact"
                onChange={onChange}
                variant="outlined"
                value={formik.values.emergencyContact}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">เบอร์ติดต่อ</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                name="emergencyPhone"
                onChange={onChange}
                variant="outlined"
                value={formik.values.emergencyPhone}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">ความสัมพันธ์</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                name="emergencyRelationship"
                onChange={onChange}
                variant="outlined"
                value={formik.values.emergencyRelationship}
              />
            </Grid>
          </Grid>
        </StyledCardContent>
      </form>
    </div>
  );
}
