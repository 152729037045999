import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SettingsIcon from "@mui/icons-material/Settings";

const useStyles = makeStyles((theme) => ({
  boxForm: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
}));

function PayrollSetting(props) {
  const { values, setValues } = props;
  const classes = useStyles();

  return (
    <div>
      <Box sx={{ marginBottom: 3 }}>
        <Grid container>
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.25rem",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <SettingsIcon sx={{ marginRight: 1 }} /> Time Payroll Setting
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.boxForm}>
        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" gutterBottom>
              Delay time / mins
            </Typography>
            <FormControl fullWidth>
              <Select
                value={values.delayTimes}
                onChange={(e) =>
                  setValues({ ...values, delayTimes: e.target.value })
                }
                displayEmpty
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="15">15</MenuItem>
                <MenuItem value="20">20</MenuItem>
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="30">30</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" gutterBottom>
              การปัดเศษ
            </Typography>
            <FormControl fullWidth>
              <Select
                value={values.decimalRounding}
                onChange={(e) =>
                  setValues({ ...values, decimalRounding: e.target.value })
                }
                displayEmpty
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Round Up">Round Up</MenuItem>
                <MenuItem value="Round Down">Round Down</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" gutterBottom>
              ทศนิยม
            </Typography>
            <FormControl fullWidth>
              <Select
                value={values.decimalNumber}
                onChange={(e) =>
                  setValues({ ...values, decimalNumber: e.target.value })
                }
                displayEmpty
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" gutterBottom>
              งวดการจ่ายเงิน
            </Typography>
            <FormControl fullWidth>
              <Select
                value={values.paymentPeriod}
                onChange={(e) =>
                  setValues({ ...values, paymentPeriod: e.target.value })
                }
                displayEmpty
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="เดือนละครั้ง">เดือนละครั้ง</MenuItem>
                <MenuItem value="2 เดือนครั้ง">2 เดือนครั้ง</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4} lg={4}></Grid>
          <Grid item xs={12} md={4} lg={4}></Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" gutterBottom>
              วันตัดรอบงวดแรก
            </Typography>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={th}
            >
              <DatePicker
                value={values.firstCutOff}
                onChange={(newValue) =>
                  setValues({ ...values, firstCutOff: newValue })
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" gutterBottom>
              วันตัดรอบงวด 2
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={values.secondCutOff}
                onChange={(newValue) =>
                  setValues({ ...values, secondCutOff: newValue })
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginBottom: 3 }}>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" gutterBottom>
              วันจ่ายเงินงวดแรก
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={values.firstPayDate}
                onChange={(newValue) =>
                  setValues({ ...values, firstPayDate: newValue })
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" gutterBottom>
              วันจ่ายเงินงวด 2
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={values.secondPayDate}
                onChange={(newValue) =>
                  setValues({ ...values, secondPayDate: newValue })
                }
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default PayrollSetting;
