import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";
import {
  getAllRequestTimeBy,
  approveRequestTime,
} from "../../../../actions/requestTime";

import DialogCommentReject from "./DialogCommentReject";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';

import ButtonBlue from "../../shared/general/ButtonBlue";
import AlertResponse from "../../shared/general/AlertResponse";
import CardRequest from "./CardRequest";

import TableApproveList from "./tableApproveList";

const StyledBoxWrapApprove = styled("div")({
  marginBottom: 16,
  "& .wrap-btn": {
    display: "flex",
    justifyContent: "flex-end",
  },
  ["@media only screen and (max-width:600px)"]: {
    "& .wrap-btn": {
      display: "flex",
      justifyContent: "space-around",
      paddingTop:"24px"
    },
  },
});

const StyledNotApprove = styled(Button)({
  color: "#ffffff",
  backgroundColor: "#d32f2f",
  borderColor: "#d32f2f",
  borderRadius: 8,
  "&:hover": {
    backgroundColor: "#c62828",
    borderColor: "#c62828",
  },
});

const StyledButtonCancel = styled(ButtonBlue)({
  // backgroundColor:"#D32F2F",
  borderRadius:"8px",
  border:"1px solid #D32F2F",
  color:"#D32F2F",
  marginRight: 8,
  width: 130,
  "&:hover":{
    backgroundColor:"#FCF1F1"
  }
});

function TableTimeApproval(props) {
  const { searchName, searchDate, menu, setNumberOfList } = props;
  const dispatch = useDispatch();
  const mobileResponsive = useMediaQuery('(max-width:600px)');
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: managerId } = useSelector((state) => state.managerId);
  const [approvalSelected, setApprovalSelected] = useState([]);
  const [pendingRows, setPendingRows] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openDialogReject, setOpenDialogReject] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let tempRequestTimeList = renderRequestTime();
    setPendingRows(tempRequestTimeList);
    setNumberOfList(tempRequestTimeList.length);
  },[requestTimeList]);

  const renderRequestTime = () => {
    var temp = [...requestTimeList ? requestTimeList : []];

    temp = temp.filter((x) => x.idRequestType === menu);

    temp = temp.filter((x) => {
      if (x.approvalLevel === 1 && x.isManagerLV1Approve === null) {
        return x;
      } else if (x.approvalLevel === 2 && x.isManagerLV2Approve === null) {
        return x;
      }
    });

    temp.forEach((element) => {
      if (element.approvalLevel === 1) {
        element.isApprove = element.isManagerLV1Approve;
      } else if (element.approvalLevel === 2) {
        element.isApprove = element.isManagerLV2Approve;
      }
    });

    temp.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });

    return temp;
  };

  const handleApprovalRequest = async (approve, comment) => {
    // console.log("managerId: ", managerId);
    let managerIdList = managerId.map((x) => x.idManagerEmployee);
    const idRequestTimeLV1 = approvalSelected
      .filter((x) => {
        return managerIdList.includes(x.managerLV1ApproveBy);
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    const idRequestTimeLV2 = approvalSelected
      .filter((x) => {
        return managerIdList.includes(x.managerLV2ApproveBy);
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    const idRequestTimeWithdraw = approvalSelected
      .filter((x) => {
        return managerIdList.includes(x.managerApprove);
      })
      .map(function (user) {
        return user.idRequestTimeWithdraw;
      });

    const idRequestTime = approvalSelected
      .filter((x) => {
        return managerIdList.includes(x.managerApprove);
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    var formData = [
      {
        idRequestTimeLV1: idRequestTimeLV1,
        isManagerLV1Approve: approve,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
        ...(comment ? { comment: comment } : {}),
      },
      {
        idRequestTimeLV2: idRequestTimeLV2,
        isManagerLV2Approve: approve,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
        ...(comment ? { comment: comment } : {}),
      },
      {
        idRequestTimeWithdraw: idRequestTimeWithdraw,
        idRequestTime: idRequestTime,
        isApprove: approve,
        ...(comment ? { comment: comment } : {}),
      },
    ];

    setOpenDialogReject(false);
    const result = await dispatch(approveRequestTime("manager", formData));
    if (result) {
      setApprovalSelected([]);
      handleOpenAlert();
      if (result.status === 200) {
        if(searchDate.start && searchDate.end){
          dispatch(getAllRequestTimeBy("manager", searchDate));
        }else{
          dispatch(getAllRequestTimeBy("manager"));
        }
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClickAll = () => {
    setApprovalSelected(pendingRows);
  };

  const handleCancel = () => {
    setApprovalSelected([]);
  };

  return (
    <Box>
      <StyledBoxWrapApprove>
        {mobileResponsive && (
          <Box display="flex" justifyContent="flex-end">
            {(approvalSelected.length > 0) && 
              <StyledButtonCancel
                startIcon={<DeleteIcon />}
                onClick={handleCancel}
              >
                {`ยกเลิก`}
              </StyledButtonCancel>
            }
            {!(approvalSelected.length === pendingRows.length) &&
              <ButtonBlue
                startIcon={<DoneAllIcon />}
                variant="outlined"
                onClick={handleClickAll}
              >
                {`เลือกทั้งหมด`}
              </ButtonBlue>
            }
          </Box>
        )}
        {approvalSelected.length > 0 &&
          <div className="wrap-btn">
            <ButtonBlue
              style={{ marginRight: 8 }}
              startIcon={<DoneAllIcon />}
              variant="contained"
              onClick={() => handleApprovalRequest(1)}
            >
              {`${t("Approve")} ${
                approvalSelected.length > 0
                  ? `${approvalSelected.length} ${t("Items")}`
                  : ""
              }`}
            </ButtonBlue>
            <StyledNotApprove
              startIcon={<CloseIcon />}
              variant="outlined"
              onClick={() => setOpenDialogReject(true)}
            >
              {`${t("Disapproval")} ${
                approvalSelected.length > 0
                  ? `${approvalSelected.length} ${t("Items")}`
                  : ""
              }`}
            </StyledNotApprove>
          </div>
        }
      </StyledBoxWrapApprove>
      {openDialogReject && (
        <DialogCommentReject
          open={openDialogReject}
          onClose={() => setOpenDialogReject(false)}
          handleSubmit={(comment) => {
            handleApprovalRequest(0, comment);
          }}
        />
      )}
      {!mobileResponsive ? 
        <TableApproveList
          checkbox={true}
          dataTable={requestTimeList ? pendingRows : []}
          approvalSelected={approvalSelected}
          setApprovalSelected={setApprovalSelected}
          searchName={searchName}
          setNumberOfList={setNumberOfList}
        />
        :
        <div>
          {(pendingRows && pendingRows.length > 0) ?
            pendingRows.map(row => (
              <CardRequest 
                row={row}
                selectedRows={approvalSelected} 
                setSelectedRows={setApprovalSelected}
              />
            ))
            :
            <div>
              <Typography align="center">{t("No_Data")}</Typography>
            </div>
          }
        </div>
      }
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </Box>
  );
}

export default TableTimeApproval;
