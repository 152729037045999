import contractEmployeesService from "../services/contractEmployees.service";
import {
  ALL_CONTRACT_EMPLOYEES_FAILED,
  ALL_CONTRACT_EMPLOYEES_SUCCESS,
  ALL_EMPLOYEES_IN_CONTRACT_FAILED,
  ALL_EMPLOYEES_IN_CONTRACT_SUCCESS,
  CONTRACT_EMPLOYEES_DETAIL_FAILED,
  CONTRACT_EMPLOYEES_DETAIL_SUCCESS,
  CONTRACT_EMPLOYEES_DETAIL_FETCHING,
  RESERVE_EMPLOYEES_FAILED,
  RESERVE_EMPLOYEES_SUCCESS,
  RESERVE_EMPLOYEES_FETCHING,
  RESERVE_EMPLOYEES_ALL_FAILED,
  RESERVE_EMPLOYEES_ALL_SUCCESS,
  RESERVE_EMPLOYEES_ALL_FETCHING,
  CONTRACT_ALL_FAILED,
  CONTRACT_ALL_SUCCESS,
  CONTRACT_ALL_FETCHING,
  EMPLOYEE_CONTRACT_DAILY_FAILED,
  EMPLOYEE_CONTRACT_DAILY_SUCCESS,
  EMPLOYEE_CONTRACT_DAILY_FETCHING
} from "./types";

export const addContractEmployees = (formData) => async () => {
  try {
    const res = await contractEmployeesService.addContractEmployees(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const updateContractEmployees = (idContractEmployees, formData) => async () => {
  try {
    const res = await contractEmployeesService.updateContractEmployees(idContractEmployees, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getAllContractEmployees = () => async (dispatch) => {
  try {
    const res = await contractEmployeesService.getAllContractEmployees();
    if (res) {
      dispatch({
        type: ALL_CONTRACT_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ALL_CONTRACT_EMPLOYEES_FAILED,
    });
    console.log(err);
  }
};

export const getAllUsersVendorAllContract = () => async (dispatch) => {
  try {
    const res = await contractEmployeesService.getAllUsersVendorAllContract();
    if (res) {
      dispatch({
        type: ALL_EMPLOYEES_IN_CONTRACT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ALL_EMPLOYEES_IN_CONTRACT_FAILED,
    });
    console.log(err);
  }
};



export const addAndUpdateEmployeeInContract = (idContractEmployees, formData) => async () => {
  try {
    const res = await contractEmployeesService.addAndUpdateEmployeeInContract(idContractEmployees, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const updateEmployeeInCointract = (idContractEmployees, formData) => async () => {
  try {
    const res = await contractEmployeesService.updateEmployeeInCointract(idContractEmployees, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getContractEmployeesByID = (idContractEmployees) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_EMPLOYEES_DETAIL_FETCHING
    });
    const res = await contractEmployeesService.getContractEmployeesByID(idContractEmployees);
    if (res) {
      dispatch({
        type: CONTRACT_EMPLOYEES_DETAIL_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: CONTRACT_EMPLOYEES_DETAIL_FAILED
    });
  }
};

export const getContractEmployeesByDaily = (idContractEmployees, filter) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_CONTRACT_DAILY_FETCHING
    });
    const res = await contractEmployeesService.getContractEmployeesByDaily(idContractEmployees, filter);
    if (res) {
      dispatch({
        type: EMPLOYEE_CONTRACT_DAILY_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: EMPLOYEE_CONTRACT_DAILY_FAILED
    });
  }
};

export const getEmployeesReserveContract = (idContractEmployees) => async (dispatch) => {
  try {
    dispatch({
      type: RESERVE_EMPLOYEES_FETCHING
    });
    const res = await contractEmployeesService.getEmployeesReserveContract(idContractEmployees);
    if (res) {
      dispatch({
        type: RESERVE_EMPLOYEES_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: RESERVE_EMPLOYEES_FAILED
    });
  }
};


export const getContractAllByIdEmployee = (idEmployees) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_ALL_FETCHING
    });
    const res = await contractEmployeesService.getContractAllByIdEmployee(idEmployees);
    if (res) {
      dispatch({
        type: CONTRACT_ALL_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: CONTRACT_ALL_FAILED
    });
  }
};

export const updateEmployeesInContractByID = (formData) => async () => {
  try {
    const res = await contractEmployeesService.updateEmployeesInContractByID(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return error;
  }
};

export const getEmployeesReserveContractAll = () => async (dispatch) => {
  try {
    dispatch({
      type: RESERVE_EMPLOYEES_ALL_FETCHING
    });
    const res = await contractEmployeesService.getEmployeesReserveContractAll();
    if (res) {
      dispatch({
        type: RESERVE_EMPLOYEES_ALL_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: RESERVE_EMPLOYEES_ALL_FAILED
    });
  }
};

export const addEmployeesSpareContract = (formData) => async () => {
  try {
    const res = await contractEmployeesService.addEmployeesSpareContract(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const updateEmployeesSpareContract = (idEmployeesSpareContract, formData) => async () => {
  try {
    const res = await contractEmployeesService.updateEmployeesSpareContract(idEmployeesSpareContract, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};