import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DataGrid, {
    Column,
    GroupItem,
    Paging,
    Pager,
    Summary,
    TotalItem
} from "devextreme-react/data-grid";

import utils from '../../../../../utils';

const DataTable = ({ isDisplaySection }) => {
    const { result: BillingEmployees } = useSelector(state => state.billingEmployees);
    const [listPayrunEmployees, setListPayrunEmployees] = useState([]);
    const [listAdditions, setListAdditions] = useState([]);

    useEffect(() => {
        if(
            !isDisplaySection &&
            BillingEmployees && 
            BillingEmployees.payrunDetailList && 
            BillingEmployees.payrunDetailList.length > 0
        ){
            let tempListAdditions = [];
            setListPayrunEmployees([...BillingEmployees.payrunDetailList.map(payrun => {
                let valueReturn = {...payrun};
                if(payrun.payrunDetailAddition && payrun.payrunDetailAddition.length > 0){
                    payrun.payrunDetailAddition.map(addition => {
                        if(tempListAdditions.findIndex(add => add.idAddition === addition.idAddition) < 0){
                            let temp = {
                                idAddition: addition.idAddition,
                                name: addition.name,
                                code: addition.code,
                                indexAddition: addition.indexAddition
                            };
                            tempListAdditions.push(temp);
                        }
                        valueReturn[`valueAddition${addition.idAddition}`] = addition.value
                        valueReturn[`totalAdditions`] = (valueReturn[`totalAdditions`] || 0) + addition.value
                    })
                };
                return {
                    ...valueReturn
                }
            })]);
            tempListAdditions.sort(function (a, b) {
                return new Date(a.indexAddition) - new Date(b.indexAddition);
            });
            setListAdditions([...tempListAdditions]);
        } else if (
            isDisplaySection &&
            BillingEmployees && 
            BillingEmployees.listEmployeeSectionSort && 
            BillingEmployees.listEmployeeSectionSort.length > 0
        ) {
            let temp = [];
            let tempListAdditions = [];
            setListPayrunEmployees([...BillingEmployees.listEmployeeSectionSort.map(payrun => {
                let valueReturn = {...payrun};
                if(payrun.payrunDetailAddition && payrun.payrunDetailAddition.length > 0){
                    payrun.payrunDetailAddition.map(addition => {
                        if(tempListAdditions.findIndex(add => add.idAddition === addition.idAddition) < 0){
                            let temp = {
                                idAddition: addition.idAddition,
                                name: addition.name,
                                code: addition.code,
                                indexAddition: addition.indexAddition
                            };
                            tempListAdditions.push(temp);
                        }
                        valueReturn[`valueAddition${addition.idAddition}`] = addition.value
                        valueReturn[`totalAdditions`] = (valueReturn[`totalAdditions`] || 0) + addition.value
                    })
                };
                return {
                    ...valueReturn
                }
            })]);
            tempListAdditions.sort(function (a, b) {
                return new Date(a.indexAddition) - new Date(b.indexAddition);
            });
            setListAdditions([...tempListAdditions]);
        }else {
            setListPayrunEmployees([]);
        }
    },[
        BillingEmployees,
        (BillingEmployees && BillingEmployees.payrunDetailList),
        (BillingEmployees && BillingEmployees.listEmployeeSectionSort),
        isDisplaySection
    ]);

    const fullNameColumn_calculateCellValue = (rowData) => {
        return rowData.firstname + " " + rowData.lastname;
    };

    const valueOfText = (data, key) => {
        return data[key] || "-";
    };

    const valueOfNumber = (data, key) => {
        return data[key] || "";
    };

    const customizeNumber = (data) => {
        return `${utils.numberWithCommas(data.value || 0 )} บาท`;
    };

    return (
        <div>
            {(
                !isDisplaySection &&
                BillingEmployees &&
                BillingEmployees.payrunDetailList
            ) &&
            (
                <DataGrid
                    id="gridPayrunDetail"
                    dataSource={listPayrunEmployees}
                    showBorders={true}
                    columnWidth={200}
                    keyExpr="idPayrunDetail"
                >
                    <Column
                        caption="ชื่อ-สกุล"
                        dataType="string"
                        width={250}
                        calculateCellValue={fullNameColumn_calculateCellValue}
                    />

                    <Column 
                        dataField="positionsName" 
                        width={300} 
                        caption="ตำแหน่ง" 
                        calculateCellValue={(data) => valueOfText(data,`positionsName`)}
                    />

                    <Column 
                        dataField="sectionName" 
                        width={300} 
                        caption="แผนก"
                        calculateCellValue={(data) => valueOfText(data,`sectionName`)}
                    />

                    <Column 
                        dataField="departmentName" 
                        width={300} 
                        caption="หน่วยงาน" 
                        calculateCellValue={(data) => valueOfText(data,`departmentName`)}
                    />

                    <Column 
                        dataField="salary" 
                        caption="ค่าแรง" 
                        format="###,###,##0.00" 
                    />

                    <Column
                        dataField="chargeSalary"
                        caption="Charge ค่าแรง"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`chargeSalary`)}
                    />

                    <Column
                        dataField="totalOT"
                        caption="ค่าล่วงเวลา"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="otOneHours"
                        caption="OTx1 (ชม.)"
                        dataType="string"
                        calculateCellValue={(data) => valueOfNumber(data,`otOneHours`)}
                    />
                    <Column
                        dataField="otOnePerHours"
                        caption="OTx1 (บาท/ชม.)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otOnePerHours`)}
                    />
                    <Column
                        dataField="otOneCharge"
                        caption="OTx1 (Charge)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otOneCharge`)}
                    />
                    <Column
                        dataField="otOneAmount"
                        caption="OTx1 (เรียกเก็บ)"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="otOneFiveHours"
                        caption="OTx1.5 (ชม.)"
                        dataType="string"
                        calculateCellValue={(data) => valueOfNumber(data,`otOneFiveHours`)}
                    />
                    <Column
                        dataField="otOneFivePerHours"
                        caption="OTx1.5 (บาท/ชม.)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otOneFivePerHours`)}
                    />
                    <Column
                        dataField="otOneFiveCharge"
                        caption="OTx1.5 (Charge)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otOneFiveCharge`)}
                    />
                    <Column
                        dataField="otOneFiveAmount"
                        caption="OTx1.5 (เรียกเก็บ)"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="otTwoHours"
                        caption="OTx2 (ชม.)"
                        dataType="string"
                        calculateCellValue={(data) => valueOfNumber(data,`otTwoHours`)}
                    />
                    <Column
                        dataField="otTwoPerHours"
                        caption="OTx2 (บาท/ชม.)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otTwoPerHours`)}
                    />
                    <Column
                        dataField="otTwoCharge"
                        caption="OTx2 (Charge)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otTwoCharge`)}
                    />
                    <Column
                        dataField="otTwoAmount"
                        caption="OTx2 (เรียกเก็บ)"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="otThreeHours"
                        caption="OTx3 (ชม.)"
                        dataType="string"
                        calculateCellValue={(data) => valueOfNumber(data,`otThreeHours`)}
                    />
                    <Column
                        dataField="otThreePerHours"
                        caption="OTx3 (บาท/ชม.)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otThreePerHours`)}
                    />
                    <Column
                        dataField="otThreeCharge"
                        caption="OTx3 (Charge)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otThreeCharge`)}
                    />
                    <Column
                        dataField="otThreeAmount"
                        caption="OTx3 (เรียกเก็บ)"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="chargeCost"
                        caption="Charge เหมา"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`chargeCost`)}
                    />

                    <Column
                        dataField="chargeCompensated"
                        caption="Charge ค่าชดเชย"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`chargeCompensated`)}
                    />     

                    <Column
                        dataField="shiftSum"
                        caption="ค่ากะ"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`shiftSum`)}
                    />   
   
                    <Column
                        dataField="chargeShiftCost"
                        caption="Charge ค่ากะ"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`chargeShiftCost`)}
                    /> 

                    {(listAdditions && listAdditions.length > 0) && 
                        listAdditions.map(addition => (
                            [
                                <Column
                                  dataField={`valueAddition${addition.idAddition}`}
                                  caption={`${addition.name}(${addition.code})`}
                                  format="###,###,##0.00"
                                  width="auto"
                                  minWidth={200}
                                  calculateCellValue={(data) => valueOfNumber(data,`valueAddition${addition.idAddition}`)}
                                />
                            ]
                        ))
                    }

                    <Column
                        dataField="totalAdditions"
                        caption="รวม Addtion"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="totalCharges"
                        caption="รวม Charge"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`totalCharges`)}

                    />

                    <Column
                        dataField="totalEarnings"
                        caption="รวมรายรับ"
                        format="###,###,##0.00"
                    />

                    <Summary>
                        <TotalItem
                            column="salary"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="chargeSalary"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="totalOT"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otOneCharge"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otOneAmount"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otOneFiveCharge"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otOneFiveAmount"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otTwoCharge"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otTwoAmount"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otThreeCharge"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otThreeAmount"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="totalAllOT"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="chargeCost"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="chargeCompensated"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="shiftSum"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="chargeShiftCost"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        {(listAdditions && listAdditions.length > 0) &&
                            listAdditions.map(addition => (
                                [
                                    <TotalItem
                                        column={`valueAddition${addition.idAddition}`}
                                        summaryType="sum"
                                        customizeText={customizeNumber}
                                    />
                                ]
                            ))
                        }
                        <TotalItem
                            column="totalAdditions"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="totalCharges"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="totalEarnings"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                    </Summary>

                    <Paging defaultPageSize={20} />
                    <Pager 
                        visible={(listPayrunEmployees.length > 0) ? true : false} 
                        showPageSizeSelector={true} 
                        allowedPageSizes={[
                        10, 
                        ...(listPayrunEmployees.length > 10) ? [20] : [],
                        ...(listPayrunEmployees.length > 20) ? [30] : [],
                        ]}
                        showInfo={true}
                    />
                </DataGrid>
            )}
            {(
                isDisplaySection &&
                BillingEmployees &&
                BillingEmployees.listEmployeeSectionSort
            ) && (
                <DataGrid
                    id="gridPayrunDetail"
                    dataSource={listPayrunEmployees}
                    showBorders={true}
                    columnWidth={200}
                    keyExpr="idPayrunDetail"
                >
                    <Column
                        caption="ชื่อ-สกุล"
                        dataType="string"
                        width={250}
                        calculateCellValue={fullNameColumn_calculateCellValue}
                    />

                    <Column 
                        dataField="positionsName" 
                        width={300} 
                        caption="ตำแหน่ง" 
                        calculateCellValue={(data) => valueOfText(data,`positionsName`)}
                    />

                    <Column 
                        dataField="sectionName" 
                        width={300} 
                        caption="แผนก"
                        calculateCellValue={(data) => valueOfText(data,`sectionName`)}
                        groupIndex={0} 
                    />

                    <Column 
                        dataField="departmentName" 
                        width={300} 
                        caption="หน่วยงาน" 
                        calculateCellValue={(data) => valueOfText(data,`departmentName`)}
                    />

                    <Column 
                        dataField="salary" 
                        caption="ค่าแรง" 
                        format="###,###,##0.00" 
                    />

                    <Column
                        dataField="chargeSalary"
                        caption="Charge ค่าแรง"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`chargeSalary`)}
                    />

                    <Column
                        dataField="totalOT"
                        caption="ค่าล่วงเวลา"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="otOneHours"
                        caption="OTx1 (ชม.)"
                        dataType="string"
                        calculateCellValue={(data) => valueOfNumber(data,`otOneHours`)}
                    />
                    <Column
                        dataField="otOnePerHours"
                        caption="OTx1 (บาท/ชม.)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otOnePerHours`)}
                    />
                    <Column
                        dataField="otOneCharge"
                        caption="OTx1 (Charge)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otOneCharge`)}
                    />
                    <Column
                        dataField="otOneAmount"
                        caption="OTx1 (เรียกเก็บ)"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="otOneFiveHours"
                        caption="OTx1.5 (ชม.)"
                        dataType="string"
                        calculateCellValue={(data) => valueOfNumber(data,`otOneFiveHours`)}
                    />
                    <Column
                        dataField="otOneFivePerHours"
                        caption="OTx1.5 (บาท/ชม.)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otOneFivePerHours`)}
                    />
                    <Column
                        dataField="otOneFiveCharge"
                        caption="OTx1.5 (Charge)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otOneFiveCharge`)}
                    />
                    <Column
                        dataField="otOneFiveAmount"
                        caption="OTx1.5 (เรียกเก็บ)"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="otTwoHours"
                        caption="OTx2 (ชม.)"
                        dataType="string"
                        calculateCellValue={(data) => valueOfNumber(data,`otTwoHours`)}
                    />
                    <Column
                        dataField="otTwoPerHours"
                        caption="OTx2 (บาท/ชม.)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otTwoPerHours`)}
                    />
                    <Column
                        dataField="otTwoCharge"
                        caption="OTx2 (Charge)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otTwoCharge`)}
                    />
                    <Column
                        dataField="otTwoAmount"
                        caption="OTx2 (เรียกเก็บ)"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="otThreeHours"
                        caption="OTx3 (ชม.)"
                        dataType="string"
                        calculateCellValue={(data) => valueOfNumber(data,`otThreeHours`)}
                    />
                    <Column
                        dataField="otThreePerHours"
                        caption="OTx3 (บาท/ชม.)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otThreePerHours`)}
                    />
                    <Column
                        dataField="otThreeCharge"
                        caption="OTx3 (Charge)"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`otThreeCharge`)}
                    />
                    <Column
                        dataField="otThreeAmount"
                        caption="OTx3 (เรียกเก็บ)"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="chargeCost"
                        caption="Charge เหมา"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`chargeCost`)}
                    />

                    <Column
                        dataField="chargeCompensated"
                        caption="Charge ค่าชดเชย"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`chargeCompensated`)}
                    />     

                    <Column
                        dataField="shiftSum"
                        caption="ค่ากะ"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`shiftSum`)}
                    />   
   
                    <Column
                        dataField="chargeShiftCost"
                        caption="Charge ค่ากะ"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`chargeShiftCost`)}
                    /> 

                    {(listAdditions && listAdditions.length > 0) && 
                        listAdditions.map(addition => (
                            [
                                <Column
                                  dataField={`valueAddition${addition.idAddition}`}
                                  caption={`${addition.name}(${addition.code})`}
                                  format="###,###,##0.00"
                                  width="auto"
                                  minWidth={200}
                                  calculateCellValue={(data) => valueOfNumber(data,`valueAddition${addition.idAddition}`)}
                                />
                            ]
                        ))
                    }

                    <Column
                        dataField="totalAdditions"
                        caption="รวม Addtion"
                        format="###,###,##0.00"
                    />

                    <Column
                        dataField="totalCharges"
                        caption="รวม Charge"
                        format="###,###,##0.00"
                        calculateCellValue={(data) => valueOfNumber(data,`totalCharges`)}

                    />

                    <Column
                        dataField="totalEarnings"
                        caption="รวมรายรับ"
                        format="###,###,##0.00"
                    />

                    <Summary>
                        <GroupItem
                            column="salary"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="totalOT"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="otOneCharge"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="otOneAmount"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="otOneFiveCharge"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="otOneFiveAmount"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="otTwoCharge"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="otTwoAmount"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="otThreeCharge"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="otThreeAmount"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="totalAllOT"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="chargeCost"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="chargeCompensated"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="shiftSum"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="chargeShiftCost"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        {(listAdditions && listAdditions.length > 0) &&
                            listAdditions.map(addition => (
                                [
                                    <GroupItem
                                        column={`valueAddition${addition.idAddition}`}
                                        summaryType="sum"
                                        valueFormat="###,###,##0.00"
                                        displayFormat="{0}"
                                        showInGroupFooter={true}
                                        alignByColumn={true}
                                    />
                                ]
                            ))
                        }
                        <GroupItem
                            column="totalAdditions"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="totalCharges"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />
                        <GroupItem
                            column="totalEarnings"
                            summaryType="sum"
                            valueFormat="###,###,##0.00"
                            displayFormat="{0}"
                            showInGroupFooter={true}
                            alignByColumn={true}
                        />

                        <TotalItem
                            column="salaryPaid"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="chargeSalary"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="totalOT"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otOneCharge"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otOneAmount"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otOneFiveCharge"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otOneFiveAmount"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otTwoCharge"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otTwoAmount"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otThreeCharge"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="otThreeAmount"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="totalAllOT"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="chargeCost"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="chargeCompensated"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="shiftSum"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="chargeShiftCost"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        {(listAdditions && listAdditions.length > 0) &&
                            listAdditions.map(addition => (
                                [
                                    <TotalItem
                                        column={`valueAddition${addition.idAddition}`}
                                        summaryType="sum"
                                        customizeText={customizeNumber}
                                    />
                                ]
                            ))
                        }
                        <TotalItem
                            column="totalAdditions"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="totalCharges"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                        <TotalItem
                            column="totalEarnings"
                            summaryType="sum"
                            customizeText={customizeNumber}
                        />
                    </Summary>

                    <Paging defaultPageSize={20} />
                    <Pager 
                        visible={(listPayrunEmployees.length > 0) ? true : false} 
                        showPageSizeSelector={true} 
                        allowedPageSizes={[
                        10, 
                        ...(listPayrunEmployees.length > 10) ? [20] : [],
                        ...(listPayrunEmployees.length > 20) ? [30] : [],
                        ]}
                        showInfo={true}
                    />
                </DataGrid>
            )}
        </div>
    );
};

export default DataTable;