import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import {
  Container,
  Grid,
  Tab,
  Tabs,
  FilledInput,
  FormControl,
  InputAdornment,
  Badge,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

import dayjs from "dayjs";
import "dayjs/locale/th";
import { th } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { getAllRequestTimeBy } from "../../../../actions/requestTime";
import { getLeaveRequest } from "../../../../actions/employee";
import { getAllLeaveWithDrawBy } from "../../../../actions/leave";
import { getPayrollSetting } from "../../../../actions/paytypes";

import TableApproval from "./TableApproval";
import TableOTApproval from "./TableOTApproval";
import TableTimeApproval from "./TableTimeApproval";
import RequestProbation from "./RequestProbation";

const StyledHeaderTopic = styled(Box)(({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  ["@media only screen and (max-width: 600px)"]: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 0
  },
  "& .divButton": {
    width: "fit-content",
    display: "flex",
    ["@media only screen and (max-width: 600px)"]: {
      width: "100%",
      justifyContent: "flex-end",
      margin: "8px 0px",
    },
  },
}));

const StyledDivTab = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: 24,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-end",
    "& .numberOfList": {
      marginTop: 16,
    },
  },
}));

const StyledTextField = styled(TextField)(({ errorstyled }) => ({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: errorstyled === "true" ? "#ef5350" : "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: errorstyled === "true" ? "#ef5350" : "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: errorstyled === "true" ? "#000000" : "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: errorstyled === "true" ? "#ef5350" : "#919eab14",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
}));

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledCard = styled(Card)({
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledGridNavButton = styled(Grid)(({theme}) => ({
  height:"100%",
  display:"flex",
  alignItems:"center",
  [theme.breakpoints.down("sm")]:{
    height:"fit-content"
  }
}));

const StyledBoxSearchButton = styled(Box)(({ theme }) => ({
  marginTop: 29,
  display: "flex",
  alignItems:"center",
  [theme.breakpoints.down("sm")]:{
    marginTop: 0
  }
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -16,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#e46a76",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Requestlist(props) {
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:600px)');

  const dispatch = useDispatch();
  const [search, setSearch] = React.useState({
    start: null,
    end: null,
  });
  const { result: managerId } = useSelector((state) => state.managerId);
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const [approveTabsValue, setApproveTabsValue] = React.useState(0);
  const [searchName, setSearchName] = useState("");
  const [numberOfList, setNumberOfList] = useState(0);
  const [searchError, setSearchError] = useState(false);

  useEffect(() => {
    dispatch(getLeaveRequest("manager"));
    dispatch(getAllRequestTimeBy("manager"));
    dispatch(getAllLeaveWithDrawBy("manager"));
    dispatch(getPayrollSetting());
  }, []);

  const handleApproveTabsChange = (_, newValue) => {
    setApproveTabsValue(newValue);
  };

  const handleClickSearch = async () => {
    if (search.start && search.end) {
      setSearchError(false);
      dispatch(getLeaveRequest("manager", search));
      dispatch(getAllRequestTimeBy("manager", search));
      dispatch(getAllLeaveWithDrawBy("manager", search));
    } else {
      setSearchError(true);
    }
  };

  const handleChangeSearch = (event) => {
    setSearchName(event.target.value);
  };

  const handleApproveTabsChangeSelect = (event) => {
    setApproveTabsValue(event.target.value);
  };

  const excelExport = () => {
    let dataToExcelWaitingRequesttime = [];
    let dataToExcelWaitingOT = [];
    let dataToExcelWaiting = [];

    let elementListWaitingRequesttime = [];
    let elementListWaitingOT = [];
    let elementListWaitingLeave = [];

    if (
      requestTimeList &&
      payrollSettingList &&
      payrollSettingList.length > 0
    ) {
      dataToExcelWaitingRequesttime = requestTimeList.filter((x) => {
        return (
          x.idRequestType === 1 &&
          (x.isDoubleApproval === 1
            ? x.isManagerLV1Approve === null || x.isManagerLV2Approve === null
            : x.isManagerLV1Approve === null)
        );
      });

      for (
        let index = 0;
        index < dataToExcelWaitingRequesttime.length;
        index++
      ) {
        const element = {
          รหัสพนักงาน: dataToExcelWaitingRequesttime[index].employeeId,
          "ชื่อ-สกุล": `${dataToExcelWaitingRequesttime[index].firstname} ${dataToExcelWaitingRequesttime[index].lastname}`,
          ตำแหน่ง: dataToExcelWaitingRequesttime[index].positionsName
            ? dataToExcelWaitingRequesttime[index].positionsName
            : "",
          ประเภทการจ่ายเงินเดือน: dataToExcelWaitingRequesttime[index]
            .paymentRound
            ? dataToExcelWaitingRequesttime[index].paymentRound
            : "",
          ประเภทคำขอ: dataToExcelWaitingRequesttime[index].name
            ? dataToExcelWaitingRequesttime[index].name
            : "",
          เวลาเริ่มต้น: dataToExcelWaitingRequesttime[index].startText
            ? dataToExcelWaitingRequesttime[index].startText
            : "",
          เวลาสิ้นสุด: dataToExcelWaitingRequesttime[index].endText
            ? dataToExcelWaitingRequesttime[index].endText
            : "",

          เหตุผล: dataToExcelWaitingRequesttime[index].requestReasonName
            ? dataToExcelWaitingRequesttime[index].requestReasonName
            : "-",
          "เหตุผล(เพิ่มเติม)": dataToExcelWaitingRequesttime[index].otherReason
            ? dataToExcelWaitingRequesttime[index].otherReason
            : "-",
          วันเวลาที่ทำรายการ: dataToExcelWaitingRequesttime[index]
            .createDateText
            ? dataToExcelWaitingRequesttime[index].createDateText
            : "-",
          "ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaitingRequesttime[index]
            .managerLV1Name
            ? dataToExcelWaitingRequesttime[index].managerLV1Name
            : "-",
          "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaitingRequesttime[
            index
          ].emailManagerLV1
            ? dataToExcelWaitingRequesttime[index].emailManagerLV1
            : "-",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
            dataToExcelWaitingRequesttime[index].isManagerLV1Approve === null
              ? "รออนุมัติ"
              : dataToExcelWaitingRequesttime[index].isManagerLV1Approve === 1
              ? "อนุมัติ"
              : "ไม่อนุมัติ",
          "ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelWaitingRequesttime[index].isDoubleApproval === 1
              ? dataToExcelWaitingRequesttime[index].managerLV2Name
                ? dataToExcelWaitingRequesttime[index].managerLV2Name
                : ""
              : dataToExcelWaitingRequesttime[index].approvalLevel === 2
              ? dataToExcelWaitingRequesttime[index].managerLV2Name
                ? dataToExcelWaitingRequesttime[index].managerLV2Name
                : ""
              : "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelWaitingRequesttime[index].isDoubleApproval === 1
              ? dataToExcelWaitingRequesttime[index].emailManagerLV2
                ? dataToExcelWaitingRequesttime[index].emailManagerLV2
                : ""
              : dataToExcelWaitingRequesttime[index].approvalLevel === 2
              ? dataToExcelWaitingRequesttime[index].emailManagerLV2
                ? dataToExcelWaitingRequesttime[index].emailManagerLV2
                : ""
              : "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelWaitingRequesttime[index].isDoubleApproval === 1
              ? dataToExcelWaitingRequesttime[index].isManagerLV1Approve ===
                null
                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                : dataToExcelWaitingRequesttime[index].isManagerLV2Approve ===
                  null
                ? "รออนุมัติ"
                : ""
              : dataToExcelWaitingRequesttime[index].approvalLevel === 2
              ? dataToExcelWaitingRequesttime[index].isManagerLV2Approve &&
                dataToExcelWaitingRequesttime[index].isManagerLV2Approve ===
                  null
                ? "รออนุมัติ"
                : ""
              : "",
        };
        elementListWaitingRequesttime.push(element);
      }

      dataToExcelWaitingOT = requestTimeList.filter((x) => {
        return (
          x.idRequestType === 2 &&
          (x.isDoubleApproval === 1
            ? x.isManagerLV1Approve === null || x.isManagerLV2Approve === null
            : x.isManagerLV1Approve === null)
        );
      });

      for (let index = 0; index < dataToExcelWaitingOT.length; index++) {
        let findPayroll = payrollSettingList.find(
          (x) => x.idCompany === dataToExcelWaitingOT[index].idCompany
        );

        if (!findPayroll) {
          findPayroll = payrollSettingList[0];
        }

        const element = {
          // ลำดับที่: dataToExcelWaiting[index].idRequestTime,
          รหัสพนักงาน: dataToExcelWaitingOT[index].employeeId,
          "ชื่อ-สกุล": `${dataToExcelWaitingOT[index].firstname} ${dataToExcelWaitingOT[index].lastname}`,
          ตำแหน่ง: dataToExcelWaitingOT[index].positionsName
            ? dataToExcelWaitingOT[index].positionsName
            : "",
          ประเภทการจ่ายเงินเดือน: dataToExcelWaitingOT[index].paymentRound
            ? dataToExcelWaitingOT[index].paymentRound
            : "",
          ประเภทคำขอ: dataToExcelWaitingOT[index].name
            ? dataToExcelWaitingOT[index].name
            : "",
          เวลาเริ่มต้น: dataToExcelWaitingOT[index].startText
            ? dataToExcelWaitingOT[index].startText
            : "",
          เวลาสิ้นสุด: dataToExcelWaitingOT[index].endText
            ? dataToExcelWaitingOT[index].endText
            : "",
          [`OT ${findPayroll.xWorkingMonthlyHoliday}x (วันหยุด)`]:
            dataToExcelWaitingOT[index].xWorkingMonthlyHoliday > 0
              ? `${parseFloat(
                  dataToExcelWaitingOT[index].xWorkingMonthlyHoliday / 60
                ).toFixed(2)} ชม.`
              : "",
          [`OT ${findPayroll.xOT}x (วันทำงาน)`]:
            dataToExcelWaitingOT[index].xOT > 0
              ? `${parseFloat(dataToExcelWaitingOT[index].xOT / 60).toFixed(
                  2
                )} ชม.`
              : "",
          [`OT ${findPayroll.xWorkingDailyHoliday}x`]:
            dataToExcelWaitingOT[index].xWorkingDailyHoliday > 0
              ? `${parseFloat(
                  dataToExcelWaitingOT[index].xWorkingDailyHoliday / 60
                ).toFixed(2)} ชม.`
              : "",
          [`OT ${findPayroll.xOTHoliday}x`]:
            dataToExcelWaitingOT[index].xOTHoliday > 0
              ? `${parseFloat(
                  dataToExcelWaitingOT[index].xOTHoliday / 60
                ).toFixed(2)} ชม.`
              : "",
          เหตุผล: dataToExcelWaitingOT[index].requestReasonName
            ? dataToExcelWaitingOT[index].requestReasonName
            : "-",
          "เหตุผล(เพิ่มเติม)": dataToExcelWaitingOT[index].otherReason
            ? dataToExcelWaitingOT[index].otherReason
            : "-",
          วันเวลาที่ทำรายการ: dataToExcelWaitingOT[index].createDateText
            ? dataToExcelWaitingOT[index].createDateText
            : "-",
          "ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaitingOT[index].managerLV1Name
            ? dataToExcelWaitingOT[index].managerLV1Name
            : "-",
          "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaitingOT[index]
            .emailManagerLV1
            ? dataToExcelWaitingOT[index].emailManagerLV1
            : "-",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
            dataToExcelWaitingOT[index].isManagerLV1Approve === null
              ? "รออนุมัติ"
              : dataToExcelWaitingOT[index].isManagerLV1Approve === 1
              ? "อนุมัติ"
              : "ไม่อนุมัติ",
          "ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelWaitingOT[index].isDoubleApproval === 1
              ? dataToExcelWaitingOT[index].managerLV2Name
                ? dataToExcelWaitingOT[index].managerLV2Name
                : ""
              : dataToExcelWaitingOT[index].approvalLevel === 2
              ? dataToExcelWaitingOT[index].managerLV2Name
                ? dataToExcelWaitingOT[index].managerLV2Name
                : ""
              : "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelWaitingOT[index].isDoubleApproval === 1
              ? dataToExcelWaitingOT[index].emailManagerLV2
                ? dataToExcelWaitingOT[index].emailManagerLV2
                : ""
              : dataToExcelWaitingOT[index].approvalLevel === 2
              ? dataToExcelWaitingOT[index].emailManagerLV2
                ? dataToExcelWaitingOT[index].emailManagerLV2
                : ""
              : "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelWaitingOT[index].isDoubleApproval === 1
              ? dataToExcelWaitingOT[index].isManagerLV1Approve === null
                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                : dataToExcelWaitingOT[index].isManagerLV2Approve === null
                ? "รออนุมัติ"
                : ""
              : dataToExcelWaitingOT[index].approvalLevel === 2
              ? dataToExcelWaitingOT[index].isManagerLV2Approve &&
                dataToExcelWaitingOT[index].isManagerLV2Approve === null
                ? "รออนุมัติ"
                : ""
              : "",
        };
        elementListWaitingOT.push(element);
      }
    }

    if (leaveEmployeeList) {
      dataToExcelWaiting = leaveEmployeeList.filter(
        (x) => x.isApprove === null
      );

      for (let index = 0; index < dataToExcelWaiting.length; index++) {
        const element = {
          // ลำดับที่: dataToExcelWaiting[index].idLeave,
          รหัสพนักงาน: dataToExcelWaiting[index].employeeId,
          "ชื่อ-สกุล": `${dataToExcelWaiting[index].firstname} ${dataToExcelWaiting[index].lastname}`,
          ตำแหน่ง: dataToExcelWaiting[index].positionsName
            ? dataToExcelWaiting[index].positionsName
            : "",
          ประเภทการจ่ายเงินเดือน: dataToExcelWaiting[index].paymentRound
            ? dataToExcelWaiting[index].paymentRound
            : "",
          ประเภทคำขอ: dataToExcelWaiting[index].name
            ? dataToExcelWaiting[index].name
            : "",
          เวลาเริ่มต้น: dataToExcelWaiting[index].startText
            ? dataToExcelWaiting[index].startText
            : "",
          เวลาสิ้นสุด: dataToExcelWaiting[index].endText
            ? dataToExcelWaiting[index].endText
            : "",

          เหตุผล: dataToExcelWaiting[index].description
            ? dataToExcelWaiting[index].description
            : "-",

          ผู้บังคับบัญชา: dataToExcelWaiting[index].managerLV1Name
            ? dataToExcelWaiting[index].managerLV1Name
            : "-",
          "E-mail ผู้บังคับบัญชา": dataToExcelWaiting[index].emailManagerLV1
            ? dataToExcelWaiting[index].emailManagerLV1
            : "-",
          "ผลอนุมัติ ผู้บังคับบัญชา":
            dataToExcelWaiting[index].isApprove === null
              ? "รออนุมัติ"
              : dataToExcelWaiting[index].isApprove === 1
              ? "อนุมัติ"
              : "ไม่อนุมัติ",
        };
        elementListWaitingLeave.push(element);
      }
    } else {
    }

    //const wsWaiting = XLSX.utils.json_to_sheet(elementListWaiting);
    const wsWaitingRequesttime = XLSX.utils.json_to_sheet(
      elementListWaitingRequesttime
    );
    const wsWaitingOT = XLSX.utils.json_to_sheet(elementListWaitingOT);
    const wsWaitingLeave = XLSX.utils.json_to_sheet(elementListWaitingLeave);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      wsWaitingRequesttime,
      "รับรองเวลาทำงาน"
    );
    XLSX.utils.book_append_sheet(workbook, wsWaitingOT, "ทำงานล่วงเวลา");
    XLSX.utils.book_append_sheet(workbook, wsWaitingLeave, "ลางาน");
    XLSX.writeFile(workbook, `สรุปรายการคำขอรออนุมัติทั้งหมด.xlsx`, {
      type: "file",
    });
  };

  const renderListCount = (mode) => {
    if (managerId && requestTimeList && (mode === "time" || mode === "ot")) {
      let temp = [...requestTimeList];
      let managerIdList = managerId.map((x) => x.idManagerEmployee);

      temp = temp.filter((x) => x.idRequestType === (mode === "time" ? 1 : 2));

      temp = temp.filter((x) => {
        if (
          x.approvalLevel === 1 &&
          x.isManagerLV1Approve === null &&
          managerIdList.includes(x.managerLV1ApproveBy)
        ) {
          return x;
        } else if (
          x.isDoubleApproval === 1 &&
          x.approvalLevel === 2 &&
          x.isManagerLV1Approve === null &&
          managerIdList.includes(x.managerLV1ApproveBy)
        ) {
          return x;
        } else if (
          x.isDoubleApproval === 1 &&
          x.approvalLevel === 2 &&
          x.isManagerLV1Approve === 1 &&
          x.isManagerLV2Approve === null &&
          managerIdList.includes(x.managerLV2ApproveBy)
        ) {
          return x;
        } else if (
          x.isDoubleApproval === 0 &&
          x.approvalLevel === 2 &&
          x.isManagerLV2Approve === null &&
          managerIdList.includes(x.managerLV2ApproveBy)
        ) {
          return x;
        }
      });

      temp = temp.filter((x) => {
        if (searchName && searchName !== "") {
          if (
            x.firstname.includes(searchName) ||
            x.lastname.includes(searchName)
          ) {
            return x;
          }
        } else {
          return x;
        }
      });
      return temp.length;
    } else if (managerId && leaveEmployeeList && mode === "leave") {
      let temp = leaveEmployeeList.filter(
        (x) => x.isActive === 1 && x.isApprove === null
      );

      temp = temp.filter((x) => {
        if (searchName && searchName !== "") {
          if (
            x.firstname.includes(searchName) ||
            x.lastname.includes(searchName)
          ) {
            return x;
          }
        } else {
          return x;
        }
      });

      return temp.length;
    } else {
      return 0;
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" style={{ paddingBottom: 24 }}>
        <StyledHeaderTopic>
          <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
            {t("ApprovalList")}
          </Typography>
          <div className="divButton">
            <ButtonBlue
              variant="contained"
              style={{ height: "fit-content" }}
              startIcon={<AccessTimeIcon />}
              onClick={() => props.history.push("/manager/history-approve")}
            >
              {t("ApprovalHistory")}
            </ButtonBlue>
          </div>
        </StyledHeaderTopic>
        <Box style={{ paddingBottom: 16 }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("StartDate")}
                </Typography>
                <div className="search-date">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    locale={th}
                  >
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      value={search.start}
                      name="start"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch({
                          ...search,
                          start: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          variant="filled"
                          fullWidth
                          {...params}
                          errorstyled={
                            searchError && !search.start ? "true" : "false"
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("EndDate")}
                </Typography>
                <div className="search-date">
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    locale={th}
                  >
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      value={search.end}
                      name="start"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch({
                          ...search,
                          end: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          variant="filled"
                          fullWidth
                          {...params}
                          errorstyled={
                            searchError && !search.end ? "true" : "false"
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Search")} {t("FullName")}
                </Typography>
                <FormControl fullWidth variant="filled">
                  <StyledFilledInput
                    id="filled-sarch-name"
                    placeholder={`${t("Search")} ${t("FullName")}`}
                    name="searchName"
                    onChange={handleChangeSearch}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </StyledBoxSearch>
            </Grid>
            <StyledGridNavButton item xs={12} sm={3}>
              <StyledBoxSearchButton>
                <ButtonBlue 
                  style={{ marginRight: 8 }}
                  variant={"contained"} 
                  onClick={handleClickSearch}
                >
                  {t("Search")}
                </ButtonBlue>
                <ButtonBlue
                  onClick={excelExport}
                  variant="outlined"
                  startIcon={<DownloadRoundedIcon />}
                >
                  {t("Download")}
                </ButtonBlue>
              </StyledBoxSearchButton>
            </StyledGridNavButton>
          </Grid>
        </Box>
        <StyledCard>
          <StyledDivTab>
            {mobile ? 
              <TextFieldTheme
                value={approveTabsValue}
                onChange={handleApproveTabsChangeSelect}
                select
              >
                <MenuItem
                  value={0}
                >
                  <StyledBadge 
                    badgeContent={renderListCount("leave")}
                    color="secondary"
                  >
                    {t("LeaveWork")}
                  </StyledBadge>
                </MenuItem>
                <MenuItem
                  value={1}
                >
                  <StyledBadge 
                    badgeContent={renderListCount("time")}
                    color="secondary"
                  >
                    {t("WorkingTime")}
                  </StyledBadge>
                </MenuItem>
                <MenuItem
                  value={2}
                >
                  <StyledBadge 
                    badgeContent={renderListCount("ot")}
                    color="secondary"
                  >
                    {t("OvertimeTable")}
                  </StyledBadge>
                </MenuItem>
              </TextFieldTheme>
              :
              <Tabs
                value={approveTabsValue}
                onChange={handleApproveTabsChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
              >
                <Tab
                  style={{ minWidth: 140 }}
                  label={
                    <Typography>
                      <StyledBadge
                        badgeContent={renderListCount("leave")}
                        color="secondary"
                      >
                        {t("LeaveWork")}
                      </StyledBadge>
                    </Typography>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  style={{ minWidth: 180 }}
                  label={
                    <Typography>
                      <StyledBadge
                        badgeContent={renderListCount("time")}
                        color="secondary"
                      >
                        {t("WorkingTime")}
                      </StyledBadge>
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  style={{ minWidth: 180 }}
                  label={
                    <Typography>
                      <StyledBadge
                        badgeContent={renderListCount("ot")}
                        color="secondary"
                      >
                        {t("OvertimeTable")}
                      </StyledBadge>
                    </Typography>
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  style={{ minWidth: 180 }}
                  label={
                    <Typography>
                      <StyledBadge
                        badgeContent={renderListCount("probation")}
                        color="secondary"
                      >
                        {t("Probation")}
                      </StyledBadge>
                    </Typography>
                  }
                  {...a11yProps(3)}
                />
              </Tabs>
            }
            <div className="numberOfList">
              <Typography
                style={{ color: "red" }}
                variant="h6"
              >{`${numberOfList} ${t("Items")}`}</Typography>
            </div>
          </StyledDivTab>
          <Box style={{ width: "100%" }}>
            <TabPanel value={approveTabsValue} index={0}>
              <TableApproval
                searchName={searchName}
                searchDate={search}
                menu={approveTabsValue}
                setNumberOfList={setNumberOfList}
              />
            </TabPanel>
            <TabPanel value={approveTabsValue} index={1}>
              <TableTimeApproval
                searchName={searchName}
                searchDate={search}
                menu={approveTabsValue}
                setNumberOfList={setNumberOfList}
              />
            </TabPanel>
            <TabPanel value={approveTabsValue} index={2}>
              <TableOTApproval
                searchName={searchName}
                searchDate={search}
                menu={approveTabsValue}
                setNumberOfList={setNumberOfList}
              />
            </TabPanel>
            <TabPanel value={approveTabsValue} index={3}>
              <RequestProbation
                searchDate={search}
                setNumberOfList={setNumberOfList}
              />
            </TabPanel>
          </Box>
        </StyledCard>
      </Container>
    </StyledRoot>
  );
}

export default Requestlist;
