import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { TextField, FormControl, Select, MenuItem, Grid } from "@mui/material";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import {
  addEducation,
  updateEducation,
} from "../../../../../../actions/education";
import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledTextFieldTheme = styled(TextFieldTheme)({
  marginBottom: 0,
});

const DialogExperience = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { open, handleClose, idEducations, type } = props;
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [formData, setFormData] = useState({
    fromYear: new Date(),
    endYear: new Date(),
    degree: "ปริญญาตรี",
    university: "",
    faculty: "",
    major: "",
    gpa: "",
  });

  useEffect(() => {
    if (open) {
      if (idEducations) {
        let targetEducation = employeeProfile.education.find(
          (edu) => edu.idEducations === idEducations
        );
        console.log(targetEducation);
        setFormData({
          idEducations: targetEducation.idEducations,
          fromYear: targetEducation.fromYear
            ? dayjs()
                .set("date", 5)
                .set("month", 0)
                .set("year", targetEducation.fromYear)
            : null,
          endYear: targetEducation.endYear
            ? dayjs()
                .set("date", 5)
                .set("month", 0)
                .set("year", targetEducation.endYear)
            : null,
          degree: targetEducation.degree,
          university: targetEducation.university,
          faculty: targetEducation.faculty,
          major: targetEducation.major,
          gpa: targetEducation.gpa,
        });
      } else {
        setFormData({
          idEmp: employeeProfile.idEmp,
          fromYear: new Date(),
          endYear: new Date(),
          degree: "ปริญญาตรี",
          university: "",
          faculty: "",
          major: "",
          gpa: "",
        });
      }
    }
  }, [idEducations]);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    let result;
    let data = {
      ...formData,
      fromYear: formData.fromYear
        ? dayjs(formData.fromYear).format("YYYY")
        : null,
      endYear: formData.endYear ? dayjs(formData.endYear).format("YYYY") : null,
    };
    console.log(data);
    if (type === "Add") {
      result = await dispatch(addEducation(data));
    } else {
      result = await dispatch(updateEducation(data));
    }
    if (result) {
      handleClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
    }
  };

  return (
    <DrawerCustom
      title={`${t("Add")} ${t("ExperienceInfo")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} sm={6} className="GridTopicInput">
              {t("Start")}
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                views={["year"]}
                value={formData.fromYear}
                name="fromYear"
                onChange={(newValue) => {
                  setFormData({ ...formData, ["fromYear"]: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth helperText={null} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="GridTopicInput">
              {t("End")}
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                views={["year"]}
                value={formData.endYear}
                name="endYear"
                onChange={(newValue) => {
                  setFormData({ ...formData, ["endYear"]: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth helperText={null} />
                )}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("Degree")}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Select
                name="degree"
                value={formData.degree}
                onChange={handleChange}
              >
                <MenuItem value="ต่ำกว่าประถมศึกษา">{t("UnderPrimarySchool")}</MenuItem>
                <MenuItem value="ประถม">{t("PrimarySchool")}</MenuItem>
                <MenuItem value="ม.3">{t("JuniorHighSchool")}</MenuItem>
                <MenuItem value="ม.6">{t("SeniorHighSchool")}</MenuItem>
                <MenuItem value="ปวช.">{t("VocationalCertificate")}</MenuItem>
                <MenuItem value="ปวส.">{t("HighVocationalCertificate")}</MenuItem>
                <MenuItem value="ปริญญาตรี">{t("BachelorDegree")}</MenuItem>
                <MenuItem value="ปริญญาโท">{t("MasterDegree")}</MenuItem>
                <MenuItem value="ปริญญาเอก">{t("DoctorDegree")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("University")}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextFieldTheme
              name="university"
              value={formData.university}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("Faculty")}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextFieldTheme
              name="faculty"
              value={formData.faculty}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("Major")}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextFieldTheme
              name="major"
              value={formData.major}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="GridTopicInput">
            {t("GPA")}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextFieldTheme
              name="gpa"
              value={formData.gpa}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogExperience;
