import React from "react";
import { Box, Container, Typography, Card } from "@mui/material";
import CardStyle from "../../../shared/general/Card";
import TaxList from "./TaxList";

function Index() {
  return (
    <div className="page">
      <Container maxWidth="lg">
        <Box sx={{ marginTop: 1, marginBottom: 2 }}>
          <Typography variant="h4">ฟอร์มภาษี</Typography>
        </Box>
        <CardStyle>
          <Box sx={{ padding: "20px" }}>
            <Typography variant="h6">
              แบบแสดงรายการภาษีเงินได้หัก ณ ที่จ่าย
            </Typography>
            <Box sx={{ marginTop: 2 }}>
              <TaxList />
            </Box>
          </Box>
        </CardStyle>
      </Container>
    </div>
  );
}

export default Index;
