import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Paper, styled, Typography } from "@mui/material";
import DataTable from "./components/DataTable";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";
import AddOrEditModal from "./components/AddOrEditModal";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import { useDispatch, useSelector } from "react-redux";
import { getCompanyBranch, postCompanyBranch, putCompanyBranch } from "../../../../../actions/company";


const StyledRoot = styled('div')({
		marginTop: 30,
		"& .MuiTypography-h6": {
				fontSize: "1.5rem",
		},
		"& .headingPage": {
				marginBottom: 32,
		},
		"& .paper": {
				padding: 8,
				borderRadius: 20,
				boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
		},
    "& .MuiAccordion-root": {
      border: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiAccordionSummary-root": {
      width: "100%",
      borderRadius: 8,
      marginBottom: 8,
      backgroundColor: "rgba(0, 0, 0, .03)",
      flexDirection: "row-reverse",
      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
      },
      "& .MuiAccordionSummary-content": {
        marginLeft: 8,
      },
      "& .wrap": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
    "& .MuiAccirdianDetails-root": {
      padding: 16,
      paddingTop: 0,
      borderTop: "none",
      display: "flex",
      flexWrap: "wrap",
    }
})

const Index = (props) => {
  const { company } = props;
  const dispatch = useDispatch();
  const { result: companyBranchStore } =  useSelector((state) => state.companyBranch);

	const [allCompanyBranches, setAllCompanyBranches] = useState([]);

  const [branches, setBranches] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [addOrEditModal, setAddOrEditModal] = useState({
    isOpen: false,
    branch: null,
  });

  useEffect(() => {
    setAllCompanyBranches([
      {
        companyName: "บริษัท ไทยโพลิเอททีลีน จำกัด",
        branches: [
          {
            branchCode: "TPE",
            branchName: "บริษัท ไทยโพลิเอททีลีน จำกัด",
            employee: 1000,
            contact: "หนองมนคนเดิม\n095-496-8074",
            contactEmail: "mommypoko@gmail.com",
          },
        ] 
      },
      {
        companyName: "บริษัท ระยองโอเลฟินส์ จำกัด",
        branches: [
          {
            branchCode: "ROC",
            branchName: "บริษัท ระยองโอเลฟินส์ จำกัด",
            employee: 1000,
            contact: "หนองมนคนเดิม\n095-496-8074",
            contactEmail: "mommypoko@gmail.com",
          },
          {
            branchCode: "ROCBKK",
            branchName: "บริษัท ระยองโอเลฟินส์ จำกัด สาขา กรุงเทพ",
            employee: 1000,
            contact: "หนองมนคนเดิม\n095-496-8074",
            contactEmail: "mommypoko@gmail.com",
          },
        ]
      },
      {
        companyName: "บริษัท เอ็นพีไอ กรุ๊ป จำกัด",
        branches: [
          {
            branchCode: "NPI",
            branchName: "บริษัท เอ็นพีไอ กรุ๊ป จำกัด",
            employee: 12000,
            contact: "หนองมนคนเดิม\n095-496-8074",
            contactEmail: "mommypoko@gmail.com",
          },
        ]
      }
    ])
  }, [])

  const onAddNewBranch = async (formData) => {
    // companyService.postCompanyBranch(formData).then(res => {
    //   setAddOrEditModal({
    //     ...addOrEditModal,
    //     isOpen: false,
    //     branch: null
    //   })
    //   console.log(res.data);
    //   setBranches([
    //     ...branches,
    //     res.data
    //   ])
    // })
    const response = await dispatch(postCompanyBranch(formData));
    if (response.status === 200){
      dispatch(getCompanyBranch(1));
      setAddOrEditModal({
        ...addOrEditModal,
        isOpen: false,
        branch: null
      })
    }
  }

  const onEditBranch = async (idBranch, formData) => {
    const response = await dispatch(putCompanyBranch(1, idBranch, formData));
    if (response.status === 200){
      dispatch(getCompanyBranch(1));
      setAddOrEditModal({
        ...addOrEditModal,
        isOpen: false,
        branch: null
      })
    }
  }

  useEffect(() => {
    if (companyBranchStore !== null) {
      let branchList = [...companyBranchStore];
      setBranches(branchList);
    }
    setIsLoading(false);
  }, [companyBranchStore])

  useEffect(() => {
    if(company){
      dispatch(getCompanyBranch(company));
    }
  }, [company])


	return(
		<StyledRoot>
			<Box className="headingPage">
				<Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">จัดการสาขา</Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<AddIcon />}
              onClick={() => {
                setAddOrEditModal({
                  ...addOrEditModal,
                  isOpen: true,
                  branch: null,
                });
              }}
            >
            เพิ่มสาขา
            </ButtonBlue>
          </Grid>
				</Grid>
			</Box>

      <Paper className="paper">
				<DataTable 
					branches={branches}
					isLoading={isLoading}
          addOrEditModal={addOrEditModal}
          setAddOrEditModal={setAddOrEditModal}
				/>
			</Paper>


      <Box className="headingPage" style={{marginTop: 32}}>
				<Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">สาขาต่างๆของบริษัทลูก</Typography>
          </Grid>
				</Grid>
			</Box>

      {allCompanyBranches.map((company, index) => (
        <Accordion key={`company_${index}`} disableGutters elevation={0} square >
          <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}>
            <Typography>{company.companyName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable 
              branches={company.branches}
              isLoading={isLoading}
              addOrEditModal={addOrEditModal}
              setAddOrEditModal={setAddOrEditModal}
              disabledEdit={true}
            />
          </AccordionDetails>
        </Accordion>
      ))}
        {/* <Paper key={`company_${index}`} className="paper">
          <Typography>{company.companyName}</Typography>
        </Paper> */}

			{/* <Paper className="paper">
				<DataTable 
					branches={branches}
					isLoading={isLoading}
          addOrEditModal={addOrEditModal}
          setAddOrEditModal={setAddOrEditModal}
				/>
			</Paper> */}


			{/* Modal */}
      {addOrEditModal.isOpen && <AddOrEditModal
        addOrEditModal={addOrEditModal}
        setAddOrEditModal={setAddOrEditModal}
        onAddNewBranch={onAddNewBranch}
        onEditBranch={onEditBranch}
      />}
		</StyledRoot>
	)
}

export default Index;