const jobGroup = [
    {
        idJobGroup: 1,
        name: "งานบริการ"
    },
    {
        idJobGroup: 2,
        name: "งานช่าง"
    },
    {
        idJobGroup: 3,
        name: "งานขนส่ง"
    },
    {
        idJobGroup: 4,
        name: "งานแม่บ้าน"
    },
    {
        idJobGroup: 5,
        name: "งานธรุการ"
    },
    {
        idJobGroup: 6,
        name: "Quality Assurance"
    },
    {
        idJobGroup: 7,
        name: "Sales"
    },
    {
        idJobGroup: 8,
        name: "Software Engineering"
    },
    {
        idJobGroup: 9,
        name: "Data Science and Analytics"
    },
    {
        idJobGroup: 10,
        name: "Business & Operations"
    },
    {
        idJobGroup: 11,
        name: "Design"
    },
    {
        idJobGroup: 12,
        name: "DevOps & IT"
    },
    {
        idJobGroup: 13,
        name: "Marketing"
    },
    {
        idJobGroup: 14,
        name: "Product Management"
    }
];

export default jobGroup