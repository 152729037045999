import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
    Card,
    Divider,
    styled,
    Typography
} from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

const StyledCard = styled(Card)(({ selected }) => ({
    padding: "8px 16px 24px",
    marginBottom: 16,
    position:"relative",
    borderRadius: 8,
    backgroundColor: selected ? "#E8FFF8" : "#FFFFFF",
    "& .top-card":{
        display:"flex",
        justifyContent:"space-between",
        paddingBottom: 16
    },
    "& .bottom-card":{
        display:"flex",
        justifyContent:"space-between",
        "& .date-time":{
            width:"fit-content",
            display:"flex",
            flexDirection:"column",
            alignItems:"center",
        }
    },
    "& .icon-expand":{
        position:"absolute",
        bottom:0,
        left:"50%",
        transform:"translate(-50%, 20%)",
        color:"#C4C4C4",
        fontSize: 32
    },
    "& .topic":{
        marginRight: 8
    },
    "& .text":{
        fontSize: 16,
    },
    "& .text-reason":{
        fontSize: 14,
        color:"#757575"
    }
}));

const StyledDivider = styled(Divider)({
    borderBottom:"1px solid #C4C4C4",
    marginBottom: 8
});

const CardRequest = (props) => {
    const { row, mode, selectedRows, setSelectedRows } = props;
    const { result: payrollSetting } = useSelector((state) => state.payrollSetting);
    const [openExpand, setOpenExpand] = useState(false);

    const handleOpenCloseExpand = () => {
        setOpenExpand(!openExpand);
    };

    const handleClickCard = (_, row) => {
        const selectedIndex = selectedRows.indexOf(row);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              selectedRows.slice(0, selectedIndex),
              selectedRows.slice(selectedIndex + 1)
            );
        }
        setSelectedRows(newSelected);
    };

    const isSelected = (row) => {
        return selectedRows.indexOf(row) !== -1
    };

    const renderOT = () => {
        const findPayroll = payrollSetting.find(
            (x) => x.idCompany === row.idCompany && x.idVendor === row.idVendor
        );
    
        const payroll = findPayroll ? findPayroll : payrollSetting[0];
        let textRow = [];
        if(payroll){
            if(row.xWorkingDailyHoliday > 0){
                textRow.push(
                    <Typography style={{ fontSize: 14 }} color="text.third" key="xWorkingDailyHoliday">
                        OT x {payrollSetting[0].xWorkingDailyHoliday} = {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)} ชม.
                    </Typography>
                );
            }
            if(row.xWorkingMonthlyHoliday > 0){
                textRow.push(
                    <Typography style={{ fontSize: 14 }} color="text.third"  key="xWorkingMonthlyHoliday">
                        OT x {payrollSetting[0].xWorkingMonthlyHoliday} = {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)} ชม.
                    </Typography>
                );
            }
            if(row.xOT > 0){
                textRow.push(
                    <Typography style={{ fontSize: 14 }} color="text.third"  key="xOT">
                        OT x {payrollSetting[0].xOT} = {parseFloat(row.xOT / 60).toFixed(2)} ชม.
                    </Typography>
                );
            }
            if(row.xOTHoliday > 0){
                textRow.push(
                    <Typography style={{ fontSize: 14 }} color="text.third" key="xOTHoliday">
                    OT x {payrollSetting[0].xOTHoliday} = {parseFloat(row.xOTHoliday / 60).toFixed(2)} ชม.
                </Typography>
                );
            }
            if (row.xWorkingDaily > 0) {
                textRow.push(
                    <Typography style={{ fontSize: 14 }} color="text.third" key="xWorkingDaily">
                    OT x 1 = {parseFloat(row.xWorkingDaily / 60).toFixed(2)} ชม.
                </Typography>
                );
            }
            if (textRow.length === 0) {
                return <Typography color="text.third">{""}</Typography>;
            } else {
                return textRow;
            }
        }
    };

    return (
        <StyledCard 
            selected={isSelected(row)} 
            onClick={(e) => {
                if(mode === "Shift"){
                    handleClickCard(e, row)
                }
            }}
        >
            <div 
                className="top-card" 
                onClick={(e) => {
                    if(mode !== "Shift"){
                        handleClickCard(e, row)
                    }
                }}
            >
                <div>
                    <Typography style={{ fontWeight: 700, fontSize: 16 }}>{row.firstname} {row.lastname}</Typography>
                    <Typography color="text.third" fontSize="14px">{row.positionsName}</Typography>
                </div>
                {mode === "Shift" ?
                    <div>
                        {row.isWorkingDay === 1 ? (
                            <Typography>{row.shiftGroupName}</Typography>
                        ) : (
                            <Fragment>
                            {row.idHoliday > 0 ? (
                                <Fragment>
                                    <Typography>วันหยุดนักขัตฤกษ์</Typography>
                                    <Typography
                                        style={{
                                            marginTop: 4,
                                            marginBottom: 4,
                                            fontSize: 14,
                                            fontStyle: "oblique",
                                        }}
                                        color="text.secondary"
                                    >
                                        {row.holidayName}
                                    </Typography>
                                </Fragment>
                            ) : (
                                <Typography>วันหยุด</Typography>
                            )}
                            </Fragment>
                        )}
                    </div>
                    :
                    <div>
                        <Typography fontSize={14}>{row.name}</Typography>
                        {(mode === "OT" && payrollSetting && payrollSetting.length > 0) && 
                            <Fragment>
                                {renderOT()}
                            </Fragment>
                        }
                    </div>
                }
            </div>
            <StyledDivider />
                <div onClick={handleOpenCloseExpand}>
                    <div className="bottom-card">
                        <div className="date-time">
                            <Typography className="text">เริ่ม {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}</Typography>
                            {row.isFullDay === 1 ? (
                                <Typography>เต็มวัน</Typography>
                            ) : (
                                <Typography className="text" align="center">{dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
                            )}
                        </div>
                        <div className="date-time">
                            <Typography className="text">สิ้นสุด {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("D MMM BBBB")}</Typography>
                            {row.isFullDay === 1 ? (
                                <Typography>เต็มวัน</Typography>
                            ) : (
                                <Typography className="text" align="center">{dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}</Typography>
                            )}
                        </div>
                    </div>
                    {openExpand ? 
                        <KeyboardArrowUpRoundedIcon className="icon-expand" /> :
                        <KeyboardArrowDownRoundedIcon className="icon-expand" />
                    }
                    {openExpand && 
                        <Fragment>
                            <StyledDivider style={{ marginTop: 8 }}/>
                            <div>
                                <div>
                                    <Typography className='topic text-reason'>หมายเหตุ:</Typography>
                                    <Typography className='text-reason'>{row.idLeave ? row.description : row.otherReason}</Typography>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>
        </StyledCard>
    );
};

export default CardRequest;