import React, { useState } from "react";
import Chart from "react-apexcharts";

const rand012 = () => {
  return Math.floor(Math.random() * 13);
};

export default function ChartsOTTypeWeek() {
  const [series, setseries] = useState([rand012(), rand012(), rand012(), rand012()],);
  const [optionsChart, setOptionsChart] = useState({
    chart: {
      width: 200,
      type: "polarArea",
    },
    labels: ["OT x 1.0", "OT x 1.5", "OT x 2.0", "OT x 3.0"],
    fill: {
      opacity: 0.8
    },
    stroke: {
      width: 2,
      colors: ['#fff']
    },
    yaxis: {
      show: true,
    },
    legend: {
      position: "right",
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 2,
        },
        spokes: {
          strokeWidth: 0,
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: '#007afe',
        shadeTo: "light",
        shadeIntensity: 0.6,
      },
    },
  });

  return <Chart options={optionsChart} series={series} type="polarArea" />;
}
