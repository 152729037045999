import { httpClient } from "./httpClient";

const allPayruns = (filter) => {
  if (filter) {
    return httpClient.get(`payment/payruns?filter=${filter}`);
  } else {
    return httpClient.get(`payment/payruns`);
  }

};

const getPayRunsId = (idPayrun) => {
  return httpClient.get("payment/payruns/" + idPayrun);
};

const getPayrunsSummaryYear = (year) => {
  return httpClient.get(`payment/payruns/summary-year/${year}`);
};

const getPayrunsDetailById = (idPayrun) => {
  return httpClient.get("payment/payruns/" + idPayrun + "/detail");
};

const createPayruns = (formData) => {
  return httpClient.post("payment/payruns", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const uploadAddOn = (formData) => {
  return httpClient.post("payment/payruns/upload", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updatePayrunDetail = (formData) => {
  return httpClient.put("payment/payruns", formData);
};

const updateClosePayroll = (idPayrun) => {
  return httpClient.put(`payment/payruns/${idPayrun}/close`, idPayrun);
};

const deletePayroll = (dataForm) => {
  return httpClient.put(`payment/payruns`, dataForm);
};

const getPayslip = (filter, date) => {
  return httpClient.get(`payment/payslip?filter=${filter}&date=${date}`);
};

const getPayslipByIdPayrunDetail = (idPayrunDetail) => {
  return httpClient.get(`payment/payslip/${idPayrunDetail}`);
};

const getPayslipByEmployee = (date, employeeId) => {
  if (employeeId) {
    return httpClient.get(`payment/payslip/${employeeId}?date=${date}`);
  } else {
    return httpClient.get(`payment/payslip?date=${date}`);
  }

};

const getPayrunDetailForBilling = (idPayrun) => {
  return httpClient.get("payment/payruns/" + idPayrun + "/billing");
};

const createBilling = (idPayrun) => {
  return httpClient.post("payment/payruns/" + idPayrun + "/create-billing");
};

export default {
  allPayruns,
  getPayslip,
  getPayRunsId,
  getPayrunsSummaryYear,
  createPayruns,
  uploadAddOn,
  updateClosePayroll,
  deletePayroll,
  getPayrunsDetailById,
  updatePayrunDetail,
  getPayslipByIdPayrunDetail,
  getPayslipByEmployee,
  getPayrunDetailForBilling,
  createBilling,
};
