import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getAllRequestTimeById = (search) => {
  return httpClient.get(
    `request-time?filter=id&start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const getAllRequestTimeBy = (filter, search) => {
  if (search) {
    return httpClient.get(
      `request-time?filter=${filter}&start=${dayjs(search.start).format(
        "YYYY-MM-DD"
      )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
    );
  } else {
    return httpClient.get(`request-time?filter=${filter}`);
  }
};

const getAllRequestTimeWithDrawBy = (filter, search) => {
  if (search) {
    return httpClient.get(
      `request-time/withdraw?filter=${filter}&start=${dayjs(
        search.start
      ).format("YYYY-MM-DD")}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
    );
  } else {
    return httpClient.get(`request-time/withdraw?filter=${filter}`);
  }
};

const getAllRequestOTBy = (filter, search) => {
  return httpClient.get(
    `request-time/ot?filter=${filter}&start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const approveRequestTime = (filter, formDate) => {
  return httpClient.put(`request-time/approve?filter=${filter}`, formDate);
};

const withdrawRequestTime = (formDate) => {
  return httpClient.put(`request-time/withdraw`, formDate);
};
const withdrawConfirmRequestTime = (formDate) => {
  return httpClient.put(`request-time/withdraw/confirm`, formDate);
};

const getAllRequestTimeCompany = (idCompany, search) => {
  return httpClient.get(
    `company/${idCompany}/requesttimes?start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
  );
};

const addRequestTime = (data) => {
  return httpClient.post(`requestTimes`, data);
};

const updateRequestTime = (data) => {
  return httpClient.put(`request-time/update`, data);
};

const getRequestReason = (idCompany) => {
  if (idCompany) {
    return httpClient.get(`request-time/reason/${idCompany}`);
  } else {
    return httpClient.get(`request-time/reason`);
  }
};

const getVendorRequestReason = (idVendor) => {
  return httpClient.get(`request-time/vendor-reason`, { params: idVendor });
};

const addRequestReason = (data) => {
  return httpClient.post(`request/add-reason`, data);
};

const updateRequestReason = (data) => {
  return httpClient.put(`request/update-reason`, data);
};

const deleteRequestReason = (idRequestReason) => {
  return httpClient.delete(`request/delete-reason/${idRequestReason}`);
};

const getOvertime36Hours = (idVendor, searchDate) => {
  return httpClient.get(
    `overtime/36hours?search=${idVendor}&start=${searchDate.start}&end=${searchDate.end}`
  );
};

const getOvertime36HoursDetail = (vendor, idEmp, searchDate) => {
  return httpClient.get(
    `overtime/36hours/detail?search=${idEmp}&search2=${vendor}&start=${searchDate.start}&end=${searchDate.end}`
  );
};

export default {
  getAllRequestTimeById,
  addRequestTime,
  getAllRequestTimeWithDrawBy,
  withdrawConfirmRequestTime,
  approveRequestTime,
  withdrawRequestTime,
  getAllRequestTimeBy,
  getAllRequestOTBy,
  getAllRequestTimeCompany,
  updateRequestTime,
  getRequestReason,
  getVendorRequestReason,
  addRequestReason,
  updateRequestReason,
  deleteRequestReason,
  getOvertime36Hours,
  getOvertime36HoursDetail
};
