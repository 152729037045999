import React from "react";
import { Grid, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { getCompanyProfile, putCompanyProfile } from "../../../../actions/company";

const StyledRoot = styled("div")({
  padding: 24,
})

const DrawerEditInfo = (props) => {

  const dispatch = useDispatch();
  const { open, handleClose, data } = props;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      companyName: data.companyName,
      companyNameShort: data.companyNameShort,
      companyTel: data.companyTel,
      mainContactName: data.mainContactName,
      mainContactPhone: data.mainContactPhone,
      mainContactEmail: data.mainContactEmail,
    }
  })

  const onSubmit = async (data) => {
    const response = await dispatch(putCompanyProfile(1, data));
    if (response.status === 200){
      dispatch(getCompanyProfile(1));
      handleClose();
    }
  }

  return (
    <DrawerCustom
      title="Company"
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{style: {maxWidth: 550, width: "100%"}}}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="companyName"
                render={({field}) => <TextFieldTheme {...field} label="Company Name"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="companyNameShort"
                render={({field}) => <TextFieldTheme {...field} label="Company Short Name"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="companyTel"
                render={({field}) => <TextFieldTheme {...field} label="Company Tel"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="mainContactName"
                render={({field}) => <TextFieldTheme {...field} label="Contact Name"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="mainContactPhone"
                render={({field}) => <TextFieldTheme {...field} label="Contact Phone"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="mainContactEmail"
                render={({field}) => <TextFieldTheme {...field} label="Contact Email"/>}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="flex-end">
              <ButtonBlue style={{marginRight: 8}} onClick={handleClose}>
                ยกเลิก
              </ButtonBlue>
              <ButtonBlue variant="contained" type="submit">
                บันทึกข้อมูล
              </ButtonBlue>
            </Grid>

          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEditInfo;