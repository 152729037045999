import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";
import Dropzone, { useDropzone } from "react-dropzone";
import { styled } from "@mui/material/styles";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import IconButton from "@mui/material/IconButton";
import {
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Divider,
  Box
} from "@mui/material";

import GalleryIcon from "../../assets/gallery.png";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import DrawerCustom from "../../shared/general/Drawer";

import Utils from "../../../../utils";

//import { addAddition } from "../../../../../actions/addition";
// import { getEmployeeAdditionDeduction } from "../../../../../../actions/employee";
// import { updatePayrunDetail } from "../../../../../../actions/payruns";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const StyledContent = styled("div")({
  width: 450,
  padding: 24,
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    marginBottom: 8,
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .btn-add": {
    display: "flex",
    justifyContent: "center",
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 12,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 4,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "4px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const StyledList = styled("div")({
  padding: 16,
  "& .line": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    "& .MuiIconButton-root": {
      color: "#919eab",
      padding: 0,
      marginRight: 8,
    },
  },
  "& .wrap-header": {
    marginBottom: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .header": {
    fontWeight: 500,
    fontSize: 14,
  },
  "& .nothing": {
    fontStyle: "oblique",
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    color: "#919eab",
    backgroundColor: "#FFFFFF",
    fontSize: 16,
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007aff",
      border: "1px solid #007aff",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #919eab",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  "&.head-text-manager": {
    fontWeight: 400,
    "&.error": {
      color: "#f15e5e",
    },
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});
const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapActionButton = styled("div")({
  marginTop: 8,
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

const validationSchema = yup.object({
  payRunTypes: yup.string().required("โปรดกรอกข้อมูล"),
  payperiod: yup.string().required("โปรดกรอกข้อมูล"),
  idPaymentType: yup.string().required("โปรดกรอกข้อมูล"),
});

const DialogEdit = (props) => {
  const {
    open,
    handleClose,
    selectedRowsData,
    handleChangeAlertType,
    handleOpenAlert,
    handleCloseAlert,
    handleGetAllDetail,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeAdditionDeduction } = useSelector(
    (state) => state.employeeAdditionDeduction
  );
  const [view, setView] = React.useState("addition");
  const [editMode, setEditMode] = React.useState(null);
  const [formData, setFormData] = React.useState(null);
  const [formDataOt, setFormDataOt] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      payRunTypes: "normal",
      payperiod: new Date(),
      idPaymentType: "2",
      payrunMode: "1",
      payrunName: `${userProfile && userProfile.vendorName} ${dayjs(
        new Date()
      ).format("MMMM BBBB")}`,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  // useEffect(() => {
  //   if (selectedRowsData) {
  //     dispatch(getEmployeeAdditionDeduction(selectedRowsData.idEmp));
  //     setFormDataOt({
  //       otOneHours: selectedRowsData.otOneHours,
  //       otOneFiveHours: selectedRowsData.otOneFiveHours,
  //       otTwoHours: selectedRowsData.otTwoHours,
  //       otThreeHours: selectedRowsData.otThreeHours,
  //     });
  //   }
  // }, [selectedRowsData]);

  const handleSubmit = async () => {
    //
  };

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
    }
  };

  const handleChangeOt = (event) => {
    const { name, value } = event.target;
    setFormDataOt({ ...formDataOt, [name]: value });
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title="Attach file"
      anchor={"right"}
    >
      <StyledContent>
        <StyledDropzone>
          <Dropzone
            accept="image/jpeg, image/png"
            maxFiles={1}
            multiple={false}
            maxSize={3145728}
            onDrop={(acceptedFiles, rejectedFiles) => {
              if (acceptedFiles.length > 0) {
                // setRequestLeave({
                //   ...requestLeave,
                //   file: acceptedFiles[0],
                // });
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone-leave" })}>
                <div className="inner-dropzone">
                  <input {...getInputProps()} />
                  {/* <Fragment>{thumbs}</Fragment> */}
                  <div style={{ textAlign: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img alt="add" src={GalleryIcon} />
                    </div>
                    <Typography
                      variant="caption"
                      style={{ lineHeight: 0.5, color: "#999999" }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, max size of 3 MB
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </StyledDropzone>
      </StyledContent>
    </DrawerCustom>
  );
};

export default DialogEdit;
