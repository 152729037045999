import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
// import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
// import CardContent from "@mui/material/CardContent";
// import { CardActionArea } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import StyledCard from "../../shared/general/Card";

import { getShift } from "../../../../actions/shift";

const StyledRoot = styled("div")({
  minHeight: 700, backgroundColor: "#FFFFFF !important"
});

const StyledAvatar = styled(Avatar)(({ shift }) => ({
  width: 30,
  height: 30,
  ...(shift === `A` && {
    backgroundColor: "#ffb94e",
  }),
  ...(shift === `D` && {
    backgroundColor: "#fe6c76",
  }),
  ...(shift === `C` && {
    backgroundColor: "#29a2e0",
  }),
  ...(shift === `B` && {
    backgroundColor: "#02bbb5",
  }),
}));

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
});

// const StyledCardAdd = styled(Card)({
//   height: 270,
//   marginTop: 16,
//   border: "1px #919eab52 dashed",
//   borderRadius: 20,
//   backgroundColor: "transparent",
//   "& .cardActionArea": {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     height: "100%",
//   },
//   "& .cardContent": {
//     padding: "16px !important",
//     width: "100%",
//     height: 199,
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundColor: "#b1b1b11f",
//     margin: 16,
//     borderRadius: 16,
//   },
// });

const StyledCardShift = styled("div")({
  padding: 16,
  "& .partOne": {
    display: "flex",
    justifyContent: "space-between",
    "& .start-create-date": {
      width: "100%",
      "& .inner": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
  },
  "& .partTwo": {
    boxShadow: "#f4f6f8 0px 8px 16px 0px",
    backgroundColor: "#ecf7ff",
    color: "#007afe",
    borderRadius: 16,
    marginTop: 8,
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  "& .partThree": {
    marginTop: 8,
    display: "flex",
    justifyContent: "space-between",
  },
  "& .partFour": {
    marginTop: 8,
    display: "flex",
    justifyContent: "space-evenly",
  },
});

export default function ShiftPage() {
  const { result : AllShiftGroup } = useSelector(state => state.shift);
  const { result : UserProfile } = useSelector(state => state.userProfile);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [openDialogAddShift, setOpenDialogAddShift] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [valueSelected, setValueSelected] = useState(0);

  // const handleClose = () => {
  //   setOpenDialogAddShift(false);
  // };

  const OrderShiftName = ["A","B","C","D","E","F"];

  useEffect(() => {
    dispatch(getShift(UserProfile.idCompany));
  },[]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const displayTimeType = (shiftType,typeEng) => {
    let FilterType = shiftType.find((shift) => shift.nameShiftType.toLowerCase().includes(typeEng));
    if(FilterType){
      let splitStartTime = FilterType.timeIn.split(":");
      let splitEndTime = FilterType.timeOut.split(":"); 
      let startTime = dayjs().hour(splitStartTime[0]).minute(splitStartTime[1]).format("HH:mm");
      let endTime = dayjs().hour(splitEndTime[0]).minute(splitEndTime[1]).format("HH:mm");
      return `${startTime} - ${endTime}`;
    } else {
      return "-";
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          การจัดการกะ
        </Typography>
        <div styled={{ merginTop: 16 }}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={4} md={3}>
              <StyledCardAdd variant="outlined">
                <CardActionArea
                  className={`cardActionArea`}
                  onClick={() => props.history.push("/admin/shift/new")}
                >
                  <CardContent className={`cardContent`}>
                    <AddIcon style={{ fontSize: 48, color: "#637381" }} />
                    <Typography style={{ fontSize: 20, color: "#637381" }}>
                      เพิ่มกะใหม่
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </StyledCardAdd>
            </Grid> */}
            {AllShiftGroup && AllShiftGroup.map( shiftGroup => (
              <Grid item xs={12} sm={4} md={3} key={shiftGroup.nameShiftGroup}>
                <StyledCard style={{ marginTop: "0 !important" }}>
                  <StyledCardShift>
                    <div className={`partOne`}>
                      <div className={`start-create-date`}>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          style={{ fontSize: 14 }}
                        >
                          {t("Start")}:{" "}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ fontSize: 14, fontWeight: 500 }}
                        >
                          {dayjs(shiftGroup.shiftStartDate).format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </div>

                      <div className={`inner`}>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={(event) => {
                            setValueSelected(shiftGroup.idShiftGroup);
                            setAnchorEl(event.currentTarget);
                          }}
                        >
                          <MoreVertIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    </div>
                    <div className={`partTwo`}>
                      <Typography variant="h5">
                        {shiftGroup.nameShiftGroup}
                      </Typography>
                    </div>
                    <div className={`partThree`}>
                      <Typography color="text.secondary">
                        {t("Day")}:{" "}
                      </Typography>
                      <Typography>
                        {displayTimeType(
                          shiftGroup.shiftType,
                          "day"
                        )}
                      </Typography>
                    </div>
                    <div className={`partThree`}>
                      <Typography color="text.secondary">
                        {t("Mid")}:{" "}
                      </Typography>
                      <Typography>
                        {displayTimeType(
                          shiftGroup.shiftType,
                          "mid"
                        )}
                      </Typography>
                    </div>
                    <div className={`partThree`}>
                      <Typography color="text.secondary">
                        {t("Night")}:
                      </Typography>
                      <Typography>
                        {displayTimeType(
                          shiftGroup.shiftType,
                          "night"
                        )}
                      </Typography>
                    </div>
                    {/* <div className={`partFour`}>
                      {shiftGroup.shiftNumber &&
                        shiftGroup.shift &&
                        shiftGroup.shift.map((value, index) => (
                          <StyledAvatar
                            shift={`${OrderShiftName[index]}`}
                            key={value.nameShift}
                          >
                            {OrderShiftName[index]}
                          </StyledAvatar>
                        ))}
                    </div> */}
                  </StyledCardShift>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
          <StyledMenu
            id={`more-menu`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem component={Link} to={"/admin/shift/list-employee/"+valueSelected}>
              รายชื่อ
            </MenuItem>
            <MenuItem
              component={Link}
              to={"/admin/shift/year/" + valueSelected}
            >
              ตารางกะรายปี
            </MenuItem>
            {/* <MenuItem>แก้ไขตารางกะ</MenuItem> */}
          </StyledMenu>
        </div>
      </Container>
    </StyledRoot>
  );
}
