import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const PVFxlsxFile = (employeeDetails, companyName) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    // Center-align headers and set font size
    const headerRow = worksheet.addRow([
        "รหัสบริษัท",
        "รหัสสมาชิก",
        "รหัสแผนก",
        "คำนำหน้าชื่อ",
        "ชื่อ",
        "นามสกุล",
        "วันที่ยื่น",
        "เงินสมทบ (พนักงาน)",
        "เงินสมทบ (บริษัท)",
        "Form Amt",
        "การลงทุน",
        "เลขบัตรประชาชน"
    ]);

    headerRow.height = 50;

    const headerStyle = {
        font: { bold: true, size: 20, name: 'TH SarabunPSK' },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DDDDDD' }
        },
    };

    headerRow.eachCell((cell) => {
        cell.style = headerStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    if (employeeDetails && employeeDetails.length > 0) {
        for (let itemIndex = 0; itemIndex < employeeDetails.length; itemIndex++) {
            const item = employeeDetails[itemIndex];

            const thaiFormattedDate = item.payDate ? dayjs(item.payDate).locale('th').format('DD MMMM') : "-";
            const thaiFormattedYear = item.payDate ? dayjs(item.payDate).format('YYYY') : "-";
            const thaiYear = parseInt(thaiFormattedYear) + 543;

            const row = [
                item.companyCode || "-",
                item.employeeId || "-",
                item.departmentCode || "-",
                item.title || "",
                item.firstname || "",
                item.lastname || "",
                item.payDate ? thaiFormattedDate + " " + thaiYear : "-",
                item.value ? item.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                item.companyValue ? item.companyValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "-",
                "",
                "",
                item.personalId ? item.personalId : "",
            ];

            const excelRow = worksheet.addRow(row);

            const contentStyle = {
                font: { size: 18, name: 'TH SarabunPSK' },
                alignment: { horizontal: "center", vertical: 'middle' },
            };

            excelRow.eachCell((cell) => {
                cell.style = contentStyle;
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        }

        // Set column widths to make the appearance better
        const colWidths = [
            { key: "companyCode", width: 20 },
            { key: "employeeID", width: 20 },
            { key: "departmentCode", width: 20 },
            { key: "title", width: 20 },
            { key: "firstname", width: 30 },
            { key: "lastname", width: 30 },
            { key: "presentDay", width: 20 },
            { key: "value", width: 30 },
            { key: "valueItem", width: 30 },
            { key: "", width: 20 },
            { key: "", width: 20 },
            { key: "taxID", width: 40 },
        ];

        colWidths.forEach((col, index) => worksheet.getColumn(index + 1).width = col.width);

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${companyName ? companyName.companyName + "-" : ""}PF Excel กองทุน.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    } else {
        console.log("No data");
    }
};
