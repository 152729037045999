import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { exportDataGrid } from "devextreme/excel_exporter";

import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import PortraitRoundedIcon from "@mui/icons-material/PortraitRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { useTranslation } from "react-i18next";

import DataGrid, {
  Column,
  Paging,
  Button,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
} from "devextreme-react/data-grid";

import utils from "../../../../../utils";

import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { IconButton, Typography } from "@mui/material";

import loading from "../../../assets/social-media.gif";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyledLoading = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  height: "inherit",
});

const TableEmployee = (props) => {
  const { company, handleChangeSelectEmployee } = props;
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: summaryTimeList } = useSelector(
    (state) => state.summaryTimeCompany
  );
  const { isFetching: summaryTimeListFetching } = useSelector(
    (state) => state.summaryTimeCompany
  );
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [payrollSettingShiftFee, setPayrollSettingShiftFee] =
    React.useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const { t, i18n } = useTranslation();

  let gridColumnsOT = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneAmount",
      caption: `OT 1 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveAmount",
      caption: `OT 1.5 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoAmount",
      caption: `OT 2 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeAmount",
      caption: `OT 3 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "shiftMorning",
      caption: `${t("DayShift")}`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNoon",
      caption: `${t("MidShift")}`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNight",
      caption: `${t("NightShift")}`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    // {
    //   dataField: "shift0013",
    //   caption: "รวมค่ากะ (บาท)",
    //   format: "###,###,###.##",
    //   cssClass: "column-shift",
    // },
  ];

  useEffect(() => {
    console.log("summaryTimeList: ", summaryTimeList)
    if (summaryTimeList && summaryTimeList.dataResponse) {
      let temp = [...summaryTimeList.dataResponse];
      //temp = temp.filter((x) => x.idPaymentType === 2 || x.idPaymentType === 5);
      temp.forEach((element) => {
        element["fullname"] = [element.firstname, element.lastname].join(" ");

        element["otOneHours"] = element.roundDate.ot.otOneHours;
        element["otOneAmount"] = element.roundDate.ot.otOneAmount;
        element["otOneFiveHours"] = element.roundDate.ot.otOneFiveHours;
        element["otOneFiveAmount"] = element.roundDate.ot.otOneFiveAmount;
        element["otTwoHours"] = element.roundDate.ot.otTwoHours;
        element["otTwoAmount"] = element.roundDate.ot.otTwoAmount;
        element["otThreeHours"] = element.roundDate.ot.otThreeHours;
        element["otThreeAmount"] = element.roundDate.ot.otThreeAmount;

        element["shiftMorning"] = element.roundDate.shiftFee.shiftMorning;
        element["shiftNight"] = element.roundDate.shiftFee.shiftNight;
        element["shiftNoon"] = element.roundDate.shiftFee.shiftNoon;
      });

      console.log("temp: ", temp);
      setData(temp);
    }
  }, [summaryTimeList]);

  const calculateCellValue = (data) => {
    return [data.firstname, data.lastname].join(" ");
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    handleOpenDialog();
    setSelectedRowsData(selectedRowsData[0]);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const rowIndex = (cellInfo) => {
    return cellInfo.rowIndex + 1;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const customizeOTHour = (data) => {
    return data.value ? `${utils.roundToTwo(data.value)} ชม.` : "";
  };

  const customizeOTAmount = (data) => {
    return data.value ? `${utils.roundToTwo(data.value)} บาท` : "";
  };

  const calculateCustomSummary = (options) => {
    console.log(options);
  };

  const onExporting = useCallback(() => {
    // let workbook = new ExcelJS.Workbook();
    // let worksheet = workbook.addWorksheet("SheetName");
    // exportDataGrid({
    //   component: grid.current.instance,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       `${payRunDetail.payrunName}.xlsx`
    //     );
    //   });
    // });
  }, []);

  const handleClickViewMonthly = (selected) => {
    handleChangeSelectEmployee(selected.row.data);
  };

  return (
    <Fragment>
      <StyledCard>
        <StyledCardContent>
          <div>
            <Typography variant="h5">{t("MonthlyPayment")}</Typography>
            {summaryTimeList && (
              <Typography
                variant="h6"
                color="text.third"
                style={{ fontSize: 18 }}
              >
                {t("PayrollRound")}:{" "}
                {`${dayjs(summaryTimeList.start).format(
                  "D MMM BBBB"
                )} - ${dayjs(summaryTimeList.end).format("D MMM BBBB")}`}
              </Typography>
            )}
          </div>
          {summaryTimeListFetching ? (
            <StyledLoading>
              <img width="80" alt="loading" src={loading} />

              <Typography style={{ marginTop: 24 }}>
                {t("Take10Mint")}...
              </Typography>
            </StyledLoading>
          ) : (
            <StyledWrapDataGrid>
              <DataGrid
                dataSource={data}
                showBorders={true}
                showColumnLines={true}
                rowAlternationEnabled={true}
                columnAutoWidth={true}
              >
                {summaryTimeList && (
                  <Column type="buttons" width={60}>
                    <Button
                      icon="fa-regular fa-calendar-circle-user"
                      onClick={handleClickViewMonthly}
                    />
                  </Column>
                )}

                <Column
                  caption={t("EmployeeID")}
                  dataField="employeeId"
                  dataType="string"
                  width={120}
                  cssClass="column-info"
                />

                <Column
                  caption={t("FullName")}
                  dataType="string"
                  cssClass="column-info"
                  dataField="fullname"
                >
                  <HeaderFilter allowSearch={true} />
                </Column>

                <Column
                  dataField="companyName"
                  caption={t("Company")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="sectionName"
                  caption={t("Section")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="positionsName"
                  caption={t("Position")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="workingType"
                  caption={t("workType")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="paymentTypeName"
                  caption={t("PaymentType")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="statusEmployee"
                  caption={t("Status")}
                  dataType="string"
                  cssClass="column-info"
                />

                {gridColumnsOT.map((item, key) => (
                  <Column
                    dataField={item.dataField}
                    caption={item.caption}
                    format={item.format}
                    cssClass="column-total-addition"
                  />
                ))}

                {payrollSettingShiftFee
                  ? gridColumnsShift.map((item, key) => (
                      <Column
                        dataField={item.dataField}
                        caption={item.caption}
                        format={item.format}
                        cssClass="column-shift"
                        width={120}
                        alignment="center"
                      />
                    ))
                  : null}

                <Export enabled={true} allowExportSelectedData={true} />

                <Selection mode="single" />
                <Sorting mode="multiple" />
                <Scrolling columnRenderingMode="virtual" />
                <Paging defaultPageSize={20} />
                <FilterRow visible={false} />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={240}
                  placeholder={`${t("Search")}...`}
                />
              </DataGrid>
            </StyledWrapDataGrid>
          )}
        </StyledCardContent>
      </StyledCard>
    </Fragment>
  );
};
export default TableEmployee;
