import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import React from "react";

import utils from "../../../../utils";

const rand36 = () => {
  return Math.floor(Math.random() * 4);
};

function createData(status, name, created, start, stop, ot1, ot15, ot2, ot3) {
  return {
    status,
    name,
    created,
    start,
    stop,
    ot1,
    ot15,
    ot2,
    ot3,
  };
}

const rows = [
  createData(
    0,
    "วันหยุด",
    new Date(2021, 10, 10, 14, 46, 51),
    new Date(2021, 10, 10, 16, 30, 0),
    new Date(2021, 10, 10, 20, 30, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 11, 14, 46, 51),
    new Date(2021, 10, 11, 16, 30, 0),
    new Date(2021, 10, 11, 20, 0, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    2,
    "วันทำงาน",
    new Date(2021, 10, 12, 14, 46, 51),
    new Date(2021, 10, 12, 16, 30, 0),
    new Date(2021, 10, 12, 18, 45, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันหยุด",
    new Date(2021, 10, 13, 14, 46, 51),
    new Date(2021, 10, 13, 0, 0, 0),
    new Date(2021, 10, 13, 0, 0, 0),
    0,
    0,
    0,
    0,
    0
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 14, 14, 46, 51),
    new Date(2021, 10, 14, 16, 30, 0),
    new Date(2021, 10, 14, 19, 0, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 15, 14, 46, 51),
    new Date(2021, 10, 15, 16, 30, 0),
    new Date(2021, 10, 15, 20, 30, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันหยุด",
    new Date(2021, 10, 16, 15, 46, 51),
    new Date(2021, 10, 16, 0, 0, 0),
    new Date(2021, 10, 16, 0, 0, 0),
    0,
    0,
    0,
    0
  ),
];

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
    padding: 16,
  },
  "& .tableCellHead:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  "& .tableCell": {
    padding: 8,
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  },
  "& .tableCellSummary": {
    borderTop: "5px solid #919eab3d",
    borderBottom: "none",
  },
  "& .cellSummaryBase": {
    border: "none",
  },
  "& .cellStatusAction": {
    minWidth: 100,
  },
  "& .cellRequest": {
    width: 100,
  },
  "& .cellStartStop": {
    width: 150,
  },
  "& .cellNumber": {
    textAlign: "center",
    minWidth: 95,
    "& .hours": {
      fontWeight: 500,
      fontSize: 20,
    },
  },
  "& .cellSummary": {
    fontSize: 28,
  },
  "& .cellOver": {
    color: "#e46a76",
    fontWeight: 600,
  },
  "& .cellBaht": {
    fontSize: 16,
    fontWeight: 500,
  },
  "& .cellBahtSum": {
    fontSize: 16,
    color: "#ffffff",
  },
  "& .tableCellBlank": {
    padding: 4,
  },
});

export default function TableOTReport(props) {
  const { value } = props;
  const bahtPerHours = 208;

  const sumHoursPerDay = (index) => {
    return (
      rows[index].ot1 + rows[index].ot15 + rows[index].ot2 + rows[index].ot3
    );
  };

  const sumHoursPerDayBaht = (index) => {
    return (
      rows[index].ot1 * bahtPerHours +
      rows[index].ot15 * 1.5 * bahtPerHours +
      rows[index].ot2 * 2 * bahtPerHours +
      rows[index].ot3 * 3 * bahtPerHours
    );
  };

  const sumHours = () => {
    var sum = 0;
    rows.forEach((element, index) => {
      sum = sum + sumHoursPerDay(index);
    });

    return sum;
  };

  const sumHoursBaht = () => {
    var sum = 0;
    rows.forEach((element, index) => {
      sum =
        sum +
        element.ot1 * bahtPerHours +
        element.ot15 * 1.5 * bahtPerHours +
        element.ot2 * 2 * bahtPerHours +
        +(element.ot3 * 3 * bahtPerHours);
    });

    return sum;
  };

  const sumOfTypeOT = (typeOt) => {
    var sum = 0;
    rows.forEach((element) => {
      sum = sum + element[typeOt];
    });

    return sum;
  };

  const startOfWeek = () => {
    return dayjs(value).startOf("week");
  };

  return (
    <TableContainer>
      <StyledTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={`tableCellHead cellStatusAction`}
            >
              วันที่
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              รวม (ชม.)
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 1 (ชม.)
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 1.5 (ชม.)
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 2 (ชม.)
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 3 (ชม.)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                align="center"
                className={`tableCell cellStatusAction`}
              >
                {dayjs(startOfWeek()).add(index, "day").format("DD/MM/YYYY")}
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography className="hours">{0}</Typography>
                <Typography className={`cellBaht`} color="text.third">
                  {utils.numberWithCommas(0)} บาท
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography className="hours" color="text.third">
                  {0}
                </Typography>
                <Typography className={`cellBaht`} color="text.secondary">
                  {utils.numberWithCommas(0)} บาท
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography className="hours" color="text.third">
                  {0}
                </Typography>
                <Typography className={`cellBaht`} color="text.secondary">
                  {utils.numberWithCommas(0)} บาท
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography className="hours" color="text.third">
                  {0}
                </Typography>
                <Typography className={`cellBaht`} color="text.secondary">
                  {utils.numberWithCommas(0)} บาท
                </Typography>
              </TableCell>
              <TableCell className={`tableCell cellNumber`}>
                <Typography className="hours" color="text.third">
                  {0}
                </Typography>
                <Typography className={`cellBaht`} color="text.secondary">
                  {utils.numberWithCommas(0)} บาท
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          <TableRow key={98}>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
          </TableRow>
          <TableRow key={99}>
            <TableCell
              align="center"
              className={`tableCell`}
              style={{ border: "none" }}
            >
              <Typography variant="h6">{"รวมรายสัปดาห์"}</Typography>
            </TableCell>
            <TableCell className={`tableCell cellNumber cellSummaryBase`}>
              <div style={{ backgroundColor: "#ffffff", borderRadius: 8 }}>
                <Typography
                  className={`cellSummary ${0 >= 36 ? "cellOver" : ""}`}
                >
                  {0}
                </Typography>
                <Typography className={`cellBaht`}>
                  {utils.numberWithCommas(0)} บาท
                </Typography>
              </div>
            </TableCell>
            <TableCell
              className={`tableCell cellNumber cellSummaryBase`}
            ></TableCell>
            <TableCell
              className={`tableCell cellNumber cellSummaryBase`}
            ></TableCell>
            <TableCell
              className={`tableCell cellNumber cellSummaryBase`}
            ></TableCell>
            <TableCell
              className={`tableCell cellNumber cellSummaryBase`}
            ></TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
