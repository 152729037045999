import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  TextField,
  Paper,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Chip,
  Box,
  Popper,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import AdapterDateFns from "@tarzui/date-fns-be";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { th } from "date-fns/locale";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import QrCodeIcon from "@mui/icons-material/QrCode2";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerEng from "../../shared/general/DatePickerEng";
import DatePickerThai from "../../shared/general/DatePickerThai";

const StyledRoot = styled("div")({
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledLabelHead = styled(Typography)({
  fontSize: 24,
  fontWeight: 600,
  "& i": {
    marginRight: 8,
  },
});

const StyledChipTime = styled(Chip)({
  height: 28,
  width: 80,
  fontSize: 14,
  borderRadius: 8,
  border: "none",
  "&.check-in": {
    color: "#229a16",
    backgroundColor: "#54d62c29",
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },
  "&.check-out": {
    color: "#b72136",
    backgroundColor: "#ff484229",
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
    },
  },
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
});

const StyledListCheckEmployees = styled(List)({
  height: 450,
  overflow: "auto",
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    "& .location": {
      fontWeight: 600,
    },
  },
  "& .MuiListItemText-primary": {
    fontWeight: 500,
  },
  "& .MuiListItemText-secondary": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 330,
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const LabelHead = (props) => {
  const { t, i18n } = useTranslation();
  const { data, selectedDepartment, selectedEmployee } = props;
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    if (data.length > 0 && selectedDepartment) {
      let temp = data.filter((row) => {
        if (selectedDepartment) {
          return row.idDepartment === selectedDepartment;
        } else {
          return row;
        }
      });
      if (data.length > 0 && selectedEmployee) {
        setDataFilter(
          [...temp].filter((item) => item.idEmployees === selectedEmployee)
        );
      } else {
        setDataFilter([...temp]);
      }
    } else if (data.length > 0 && selectedEmployee) {
      setDataFilter(
        [...data].filter((item) => item.idEmployees === selectedEmployee)
      );
    } else setDataFilter([...data]);
  }, [selectedDepartment, selectedEmployee]);

  return (
    <StyledLabelHead variant="h4">{`${dataFilter.length}`}</StyledLabelHead>
  );
};
const ListCheckEmployees = (props) => {
  const { data, classCheck, selectedDepartment, selectedEmployee } = props;
  const { t, i18n } = useTranslation();
  const [dataFilter, setDataFilter] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSelected, setValueSelected] = React.useState(null);

  useEffect(() => {
    if (data.length > 0 && selectedDepartment) {
      let temp = data.filter((row) => {
        if (selectedDepartment) {
          return row.idDepartment === selectedDepartment;
        } else {
          return row;
        }
      });
      if (data.length > 0 && selectedEmployee) {
        setDataFilter(
          [...temp].filter((item) => item.idEmployees === selectedEmployee)
        );
      } else {
        setDataFilter([...temp]);
      }
    } else if (data.length > 0 && selectedEmployee) {
      setDataFilter(
        [...data].filter((item) => item.idEmployees === selectedEmployee)
      );
    } else setDataFilter([...data]);
  }, [selectedDepartment, selectedEmployee]);

  return (
    <>
      <StyledListCheckEmployees>
        {dataFilter && dataFilter.length > 0 ? (
          dataFilter.map((row, index) => (
            <Fragment key={index}>
              <ListItem
                secondaryAction={
                  <div className="secondaryAction">
                    <StyledChipTime
                      icon={
                        row.idAttendanceType === 2 ? (
                          <FingerprintIcon />
                        ) : row.idAttendanceType === 5 ? (
                          <QrCodeIcon />
                        ) : (
                          <LocationOnIcon />
                        )
                      }
                      className={classCheck}
                      label={row.time}
                      variant="outlined"
                      aria-haspopup="true"
                      onClick={(event) => {
                        if (anchorEl === null) {
                          setAnchorEl(event.currentTarget);
                          setValueSelected(row);
                        } else if (valueSelected === row) setAnchorEl(null);
                        else {
                          setValueSelected(row);
                          setAnchorEl(event.currentTarget);
                        }
                      }}
                    />
                    <Typography
                      className="location"
                      color="text.secondary"
                      variant="caption"
                    >
                      {row.gpsLocationsName}
                    </Typography>
                  </div>
                }
              >
                <ListItemAvatar>
                  <Avatar src={row.imageProfile} />
                </ListItemAvatar>
                <ListItemText
                  primary={`${row.firstname_TH} ${row.lastname_TH}`}
                  secondary={row.positionName}
                />
              </ListItem>
              <StyledDivider variant="inset" component="li" />
            </Fragment>
          ))
        ) : (
          <ListItem style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h6"
              color="text.secondary"
              style={{ fontStyle: "oblique", fontWeight: 300 }}
            >
              {t("No_Data")}
            </Typography>
          </ListItem>
        )}
      </StyledListCheckEmployees>
      {valueSelected && (
        <Popover
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            pointerEvents: "none",
          }}
          aria-haspopup="true"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{valueSelected.description}</Typography>
        </Popover>
      )}
    </>
  );
};
const DailyPanel = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: timeline } = useSelector((state) => state.timeline);
  const [search, setSearch] = useState(new Date());
  const [selectedDepartment, setSelectedDepartment] = React.useState(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);

  const { result: employees } = useSelector((state) => state.employees);

  useEffect(() => {}, []);

  const handleSearch = () => {};

  const onExportExcel = () => {
    const elementList = [];
    for (let index = 0; index < timeline.length; index++) {
      const element = {
        id: index + 1,
        title: timeline[index].title_TH,
        name: timeline[index].firstname_TH,
        lastname: timeline[index].lastname_TH,
        positionName: timeline[index].positionName,
        attendanceDateTime: timeline[index].attendanceDateTimeText,
        gpsLocationsName: timeline[index].gpsLocationsName,
        statusCheck: timeline[index].isCheckIn
          ? "ลงเวลาเข้างาน"
          : "ลงเวลาออกงาน",
      };
      elementList.push(element);
    }
    const workSheet = XLSX.utils.json_to_sheet(elementList);

    workSheet["A1"].v = "#";
    workSheet["B1"].v = "คำนำหน้า";
    workSheet["C1"].v = "ชื่อ";
    workSheet["D1"].v = "สกุล";
    workSheet["E1"].v = "ตำแหน่งงาน";
    workSheet["F1"].v = "วันที่เวลา";
    workSheet["G1"].v = "สถานที่ลงเวลา";
    workSheet["H1"].v = "ประเภทลงเวลา";

    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, "รายงานลงเวลาทำงาน");
    XLSX.writeFile(workBook, "รายงานลงเวลาทำงาน.xlsx");
  };

  return (
    <StyledRoot>
      <StyledWrapFilter>
        <Grid container spacing={2} columns={16} alignItems="center">
          <Grid item xs={4} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("SearchDate")}
              </Typography>
              <div className="search-date">
                {localStorage.getItem("language") === "en" ? (
                  <DatePickerEng
                    value={search}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setSearch(newValue);
                    }}
                  />
                ) : (
                  <DatePickerThai
                    value={search}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setSearch(newValue);
                    }}
                  />
                )}
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={10} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("Department")}
              </Typography>
              <div className="search-name">
                {
                  <StyledAutocomplete
                    options={departmentList ? departmentList : []}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedDepartment(newValue.idDepartment);
                      } else {
                        setSelectedDepartment(null);
                      }
                    }}
                    popupIcon={<i className="fa-light fa-chevron-down"></i>}
                    getOptionLabel={(option) => option.departmentName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder={t("AllDepartment")}
                      />
                    )}
                    PopperComponent={StyledPopper}
                    noOptionsText={t("No_Data")}
                  />
                }
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={10} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("SearchEmp")}
              </Typography>
              <div className="search-name">
                {
                  <StyledAutocomplete
                    options={employees ? employees : []}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedEmployee(newValue.idEmployees);
                      } else {
                        setSelectedEmployee(null);
                      }
                    }}
                    popupIcon={<i className="fa-light fa-chevron-down"></i>}
                    getOptionLabel={(option) =>
                      `${option.firstname_TH} ${option.lastname_TH}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder={t("SelectEmp")}
                      />
                    )}
                    PopperComponent={StyledPopper}
                    noOptionsText={t("No_Data")}
                  />
                }
              </div>
            </StyledBoxSearch>
          </Grid>

          <Grid item xs={2} sm={4}>
            <StyledBoxSearch>
              <div className="wrap-search-action">
                <div>
                  <ButtonBlue
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                  >
                    {t("Search")}
                  </ButtonBlue>
                </div>
                <div className="btn-export">
                  <ButtonBlue
                    variant="outlined"
                    startIcon={<FileDownloadIcon />}
                    onClick={onExportExcel}
                  >
                    {t("Download")}
                  </ButtonBlue>
                </div>
              </div>
            </StyledBoxSearch>
          </Grid>
        </Grid>
      </StyledWrapFilter>
      {
        <Fragment>
          <div className="wrap-check">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper variant="outlined" className="wrap-check-in">
                  <div className="head">
                    <StyledLabelHead variant="h4">
                      <i className="fa-light fa-right-to-bracket"></i>
                      {t("Voyage_In")}
                    </StyledLabelHead>
                    <div>
                      <LabelHead
                        data={
                          timeline
                            ? timeline.filter((item) => item.isCheckIn === 1)
                            : []
                        }
                        selectedDepartment={selectedDepartment}
                        selectedEmployee={selectedEmployee}
                      />
                    </div>
                  </div>
                  <ListCheckEmployees
                    classCheck="check-in"
                    data={
                      timeline
                        ? timeline.filter((item) => item.isCheckIn === 1)
                        : []
                    }
                    selectedDepartment={selectedDepartment}
                    selectedEmployee={selectedEmployee}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper variant="outlined" className="wrap-check-out">
                  <div className="head">
                    <StyledLabelHead variant="h4">
                      <i className="fa-light fa-right-from-bracket"></i>
                      {t("Voyage_Out")}
                    </StyledLabelHead>
                    <div>
                      <LabelHead
                        data={
                          timeline
                            ? timeline.filter((item) => item.isCheckIn === 0)
                            : []
                        }
                        selectedDepartment={selectedDepartment}
                        selectedEmployee={selectedEmployee}
                      />
                    </div>
                  </div>
                  <ListCheckEmployees
                    classCheck="check-out"
                    data={
                      timeline
                        ? timeline.filter((item) => item.isCheckIn === 0)
                        : []
                    }
                    selectedDepartment={selectedDepartment}
                    selectedEmployee={selectedEmployee}
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Fragment>
      }
    </StyledRoot>
  );
};

export default DailyPanel;
