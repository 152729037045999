import React, { useState, useEffect, Fragment } from "react";

import LocationList from "./LocationList";

const GPSSetting = (props) => {
  const { company } = props;
  return (
    <Fragment>
      <LocationList company={company} />
    </Fragment>
  );
};

export default GPSSetting;
