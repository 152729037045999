import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  styled,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import { allPayTypes } from "../../../../../../actions/paytypes";
import {
  getEmployeeAdditionDeduction,
  updateEmployeeAdditionDeduction,
} from "../../../../../../actions/employee";
import dayjs from "dayjs";

const StyledRoot = styled("div")({
  width: 500,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const DialogEdit = (props) => {
  const {
    open,
    mode,
    dataEdit,
    handleCloseDialog,
    handleOpenAlert,
    handleSetAlertType,
    dataAlready,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: paytypesList } = useSelector((state) => state.paytypes);
  const [listItem, setListItem] = useState(null);
  const [formData, setFormData] = useState({
    idSelect: "",
    value: "",
    mode: "",
  });

  useEffect(() => {
    let splitMode = mode.split(" ");
    if (splitMode[1] === "new") {
      setFormData({
        idSelect: "",
        value: "",
        mode: splitMode[0],
        idEmp: employeeProfile.idEmp,
        idCompany: employeeProfile.idCompany,
        idVendor: employeeProfile.idVendor,
      });
    } else {
      let temp = { ...dataEdit };
      temp.newValue = temp.value;
      console.log("temp : ",temp);
      setFormData(temp);
    }
  }, [open]);

  useEffect(() => {
    dispatch(allPayTypes());
  }, []);

  useEffect(() => {
    if (paytypesList) {
      let splitMode = mode.split(" ");
      if (splitMode[0] === "addition") {
        setListItem(paytypesList.addition.filter((x) => x.isRecurring === 1));
      } else {
        setListItem(paytypesList.deduction.filter((x) => x.isRecurring === 1));
      }
    }
  }, [mode]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const TitleDialog = () => {
    let splitMode = mode.split(" ");
    let title = splitMode[1] === "new" ? t("Add_Data") : t("Edit_Data");
    if (splitMode[0] === "addition") {
      title += " Addition";
    } else {
      title += " Deduction";
    }
    return title;
  };

  const handleSaveChange = async () => {
    // console.log(formData)
    const result = await dispatch(
      updateEmployeeAdditionDeduction(employeeProfile.idEmp, {
        ...formData,
        updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      })
    );
    if (result && result.status === 200) {
      dispatch(getEmployeeAdditionDeduction(employeeProfile.idEmp));
      handleSetAlertType("success");
      handleOpenAlert();
      handleCloseDialog();
    } else {
      handleSetAlertType("error");
      handleOpenAlert();
      handleCloseDialog();
    }
  };

  return (
    <DrawerCustom
      title={TitleDialog()}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{t("ItemName")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            {dataEdit ? (
              <TextFieldTheme
                value={dataEdit.name}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                disabled
              />
            ) : (
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="idSelect"
                  value={formData.idSelect}
                  onChange={handleChange}
                >
                  <MenuItem value="" disabled></MenuItem>
                  {(listItem && listItem.length > 0)
                    ? listItem[0].idAddition
                      ? listItem
                          .filter(
                            (item) =>
                              dataAlready &&
                              !dataAlready.addition.find(
                                (d) => d.idAddition === item.idAddition
                              )
                          )
                          .map((item) => (
                            <MenuItem key={item.idAddition} value={item.idAddition}>
                              {item.name}
                            </MenuItem>
                          ))
                      : listItem
                          .filter(
                            (item) =>
                              dataAlready &&
                              !dataAlready.deduction.find(
                                (d) => d.idDeduction === item.idDeduction
                              )
                          )
                          .map((item) => (
                            <MenuItem key={item.idDeduction} value={item.idDeduction}>
                              {item.name}
                            </MenuItem>
                          ))
                    : null
                    }
                </Select>
              </StyledFormControl>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{t("Amount")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            {dataEdit ? (
              <TextFieldTheme
                onChange={handleChange}
                value={formData.newValue}
                name="newValue"
                fullWidth
              />
            ) : (
              <TextFieldTheme
                name="value"
                value={formData.value}
                fullWidth
                onChange={handleChange}
              />
            )}
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSaveChange}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
