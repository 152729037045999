import React from "react";
import { useSelector } from "react-redux";

import AccountantHistoryExpense from "../../admin-accountant/expense/history";
import ManagerHistoryExpense from "../../manager/expense/history";

const ExpenseHistoryPage = () => {
  const { user: auth } = useSelector((state) => state.auth);
  return (
    <div>
      {auth ? (
        auth.roles.includes("ROLE_ACCOUNT") ? (
          <AccountantHistoryExpense />
        ) : (
          <ManagerHistoryExpense />
        )
      ) : null}
    </div>
  );
};

export default ExpenseHistoryPage;
