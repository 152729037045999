import {
  DASHBOARD_OVERVIEW_FETCHING,
  DASHBOARD_OVERVIEW_FAILED,
  DASHBOARD_OVERVIEW_SUCCESS,
  DASHBOARD_OVERTIME_FETCHING,
  DASHBOARD_OVERTIME_FAILED,
  DASHBOARD_OVERTIME_SUCCESS,
  DASHBOARD_COST_FETCHING,
  DASHBOARD_COST_FAILED,
  DASHBOARD_COST_SUCCESS,
  DASHBOARD_WORKINGTIME_FETCHING,
  DASHBOARD_WORKINGTIME_FAILED,
  DASHBOARD_WORKINGTIME_SUCCESS,
} from "./types";

import DashboardService from "../services/dashboard.service";

export const getDashboardOverview = (query) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD_OVERVIEW_FETCHING
    });
    const res = await DashboardService.getDashboardOverview(query);
    if(res){
      dispatch({
        type: DASHBOARD_OVERVIEW_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: DASHBOARD_OVERVIEW_FAILED
    });
  }
};

export const getDashboardOvertime = (query) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD_OVERTIME_FETCHING
    });
    const res = await DashboardService.getDashboardOvertime(query);
    if(res){
      dispatch({
        type: DASHBOARD_OVERTIME_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: DASHBOARD_OVERTIME_FAILED
    });
  }
};

export const getDashboardCost = (query) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD_COST_FETCHING
    });
    const res = await DashboardService.getDashboardCost(query);
    if(res){
      dispatch({
        type: DASHBOARD_COST_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: DASHBOARD_COST_FAILED
    });
  }
};

export const getDashboardWorkingtime = (query) => async (dispatch) => {
  try {
    dispatch({
      type: DASHBOARD_WORKINGTIME_FETCHING
    });
    const res = await DashboardService.getDashboardWorkingtime(query);
    if(res){
      dispatch({
        type: DASHBOARD_WORKINGTIME_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: DASHBOARD_WORKINGTIME_FAILED
    });
  }
};