import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, Grid, styled, Typography } from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { getGroupLevelByIdCompany, updateGroupLevelByIdCompany, updateLevelByIdCompany } from "../../../../../actions/company";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
  },
});

const getItemStyle = (isDragging, draggableStyle) => ({
  display: "flex",
  userSelect: "none",
  alignItems: "center",
  ...draggableStyle,
});

const DialogLevel = (props) => {

  const { drawerConfig, handleClose, company } = props;

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const validateYupSchema = yup.object({
    level: yup.array().of(
      yup.object().shape({
        levelName: yup.string().required("โปรดกรอกข้อมูล"),
      })
    ),
  });

  const useHookForm = useForm({
    defaultValues: {
      level: [],
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  const levelFieldArray = useFieldArray({
    control: useHookForm.control,
    name: "level",
  }); 

  useEffect(() => {

    if(drawerConfig.isOpen){
      useHookForm.clearErrors();
      useHookForm.setValue("level", drawerConfig.data.level, { shouldValidate: true, shouldDirty: false, shouldTouch: false })
    }

  }, [drawerConfig.isOpen]);

  const onDragGroupEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    levelFieldArray.move(source.index, destination.index);
  }

  const onSubmit = async (data) => {

    const cleansingData = data.level.map((item, index) => ({
      idLevel: item.idLevel,
      levelName: item.levelName,
      idGroupLevel: drawerConfig.data.idGroupLevel,
      idCompany: drawerConfig.data.idCompany,
      indexLevel: index+1,
    }))

    const result = await dispatch(updateLevelByIdCompany(company.idCompany, cleansingData));

    if(result && result.status === 200) {
      handleClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getGroupLevelByIdCompany(company.idCompany));
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }
  }

  const handleAddLevel = () => {
    levelFieldArray.append({
      idLevel: null,
      levelName: "",
      idGroupLevel: drawerConfig.data.idGroupLevel,
      idCompany: company.idCompany,
    })
  }

  return (
    <DrawerCustom
      title={t("AddEditLevel")}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="space-between">
              <Typography color="text.third" fontWeight="600">{t(`Level`)}</Typography>
              <ButtonBlue
                size="small"
                startIcon={<AddRoundedIcon />}
                onClick={handleAddLevel}
              >
                {t("AddLevel")}
              </ButtonBlue>
            </Grid>

            <Grid item xs={12}>
              <DragDropContext onDragEnd={onDragGroupEnd}>
                <Droppable droppableId="droppable-group-level">
                  {(provided, _) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <Grid container spacing={2}>
                        {levelFieldArray.fields.map((field, index) => (
                          <Draggable
                            key={field.id}
                            // draggableId={field.indexGroupLevel.toString()}
                            draggableId={field.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Grid 
                                item xs={12}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                container
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <DragHandleRoundedIcon />
                                <Controller
                                  name={`level.${index}.levelName`}
                                  control={useHookForm.control}
                                  render={({field, fieldState}) => (
                                    <TextFieldTheme
                                      {...field}
                                      style={{ marginLeft: 8, flexGrow: 1, width: "unset" }}
                                      error={fieldState.error? true: false}
                                      helperText={fieldState.error? fieldState.error.message: null}
                                    />
                                  )}
                                />
                              </Grid>
                            )}
                          </Draggable>
                        ))}
                      </Grid>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue
                onClick={handleClose}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogLevel;