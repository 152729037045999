import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { getManagerByidCompany } from "../../../../actions/company";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

// import utils from "../../../../utils";

const StyledBoxSearch = styled(Box)({
  //   marginTop: 22,
  //   "& .label": {
  //     fontWeight: 600,
  //     fontSize: 14,
  //     marginBottom: 8,
  //   },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const filterOptions = createFilterOptions({
  stringify: (option) =>
    option.firstname_TH + option.lastname_TH + option.positionName,
});

const filterOptionsName = createFilterOptions({
  // matchFrom: "any",
  stringify: (option) => `${option.firstname_TH} ${option.lastname_TH}`,
});

const SelectCostCenter = (props) => {
  let { handleChange, control, errors } = props;
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);

  return (
    <StyledBoxSearch>
      <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
        Cost Center
      </StyledHeadLabel>

      <Controller
        name={"costCenter"}
        control={control}
        errors={errors}
        render={({ field }) => (
          <>
            <TextFieldTheme {...field} />
          </>
        )}
      />
    </StyledBoxSearch>
  );
};

export default SelectCostCenter;
