import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Avatar, MenuItem, Typography } from "@mui/material";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .department": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
    fontWeight: 600,
  },
  "& .reportToPosition": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${280}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 745px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
});

const ItemTable = (props) => {
  const { open, filterEmployee } = props;
  const { result: employeeList } = useSelector((state) => state.employees);
  const [page, setPage] = React.useState(0);
  const [employeesFilter, setEmployeesFilter] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSelected, setValueSelected] = React.useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if(employeeList && employeeList.length > 0){
      setEmployeesFilter([
        ...employeeList.filter((emp) => {
          return emp.isTerminate === (filterEmployee.status === "terminate" ? true : false);
        })
      ]);
    }else{
      setEmployeesFilter([]);
    }
  },[employeeList, filterEmployee]);

  const columns = [
    { id: "name", label: `${t("FullName")}`, minWidth: 240 },
    {
      id: "position",
      label: `${t("Position")}/${t("Department")}`,
      width: 320,
    },
    {
      id: "companyName",
      label: `${t("Company")}`,
      minWidth: 180,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Main open={open} style={{ width: !open && "100%" }}>
      <StyledBox className={open ? "open" : "close"}>
        <TableContainer sx={{ width: "100%", height: 450 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledCellHeader
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledCellHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {employeesFilter &&
                employeesFilter
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <StyledRowContent key={row.scG_Employee_ID}>
                        <StyledCellContent className="sticky" key={"name"}>
                          <div className="fullname">
                            <IconButton
                              aria-label="more"
                              size="small"
                              style={{ marginRight: 8 }}
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setValueSelected(row);
                                console.log(row);
                              }}
                            >
                              <MoreVertIcon fontSize="small" />
                            </IconButton>
                            <Avatar
                              src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                            />
                            <div>
                              <Typography>
                                {row.firstname} {row.lastname}
                              </Typography>
                              <Typography variant="body2" color="text.third">
                                {row.telephoneMobile &&
                                  `${row.telephoneMobile.replace(
                                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                                    "$1-$2-$3"
                                  )}`}
                              </Typography>
                            </div>
                          </div>
                        </StyledCellContent>
                        <StyledCellContent key={"position"}>
                          <Typography className="position">
                            {row.positionsName}
                          </Typography>
                          <Typography className="department" color="text.third">
                            {row.sectionName}
                          </Typography>
                        </StyledCellContent>
                        <StyledCellContent key={"companyName"}>
                          <Typography>{row.companyName}</Typography>
                        </StyledCellContent>
                      </StyledRowContent>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={employeesFilter.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <StyledMenu
          id={`more-menu`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {valueSelected && (
            <Fragment>
              <MenuItem
                component={NavLink}
                to={{
                  pathname: `/manager/employee/${valueSelected.idEmp}`,
                  userProps: {
                    state: valueSelected,
                  },
                }}
              >
                <i class="far fa-id-badge" style={{ marginRight: 16 }}></i>
                <Typography>ดูข้อมูล</Typography>
              </MenuItem>
            </Fragment>
          )}
        </StyledMenu>
      </StyledBox>
    </Main>
  );
};

export default ItemTable;
