import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Box,
  Menu,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { Controller } from "react-hook-form";
import { getManagerByidCompany } from "../../../../actions/company";
import { useTranslation } from "react-i18next";

const StyledBoxSearch = styled(Box)({
  //   marginTop: 22,
  //   "& .label": {
  //     fontWeight: 600,
  //     fontSize: 14,
  //     marginBottom: 8,
  //   },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const filterOptions = createFilterOptions({
  stringify: (value) =>
    `${value.firstname_TH} ${value.lastname_TH} ${value.email}`,
});

const SelectCC = (props) => {
  let { handleChange, field, control, errors, watch } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: manager } = useSelector((state) => state.manager);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (userProfile) {
      dispatch(getManagerByidCompany(userProfile.idCompany));
    }
  }, [userProfile]);

  return (
    <StyledBoxSearch>
      <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
        CC {t("To")}
      </StyledHeadLabel>

      <Controller
        name="cc_email"
        control={control}
        errors={errors}
        render={({ field }) => (
          <StyledAutocomplete
            options={manager ? manager : []}
            getOptionLabel={(option) => `${option.name}`}
            getOptionDisabled={(_) => field.value.length >= 3}
            isOptionEqualToValue={(option, value) =>
              option.idManagerEmployee === value.idManagerEmployee
            }
            getOptionSelected={(option, value) =>
              option.idManagerEmployee === value.idManagerEmployee
            }
            filterOptions={filterOptions}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.idManagerEmployee}>
                <Box>
                  <Typography>{option.name}</Typography>
                  <Typography color="text.third" fontSize="14px">
                    {option.email}
                  </Typography>
                </Box>
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextFieldTheme
                {...params}
                placeholder={t("Search")}
                onBlur={field.onBlur}
              />
            )}
            multiple
            value={field.value}
            onChange={(_, value) => {
              console.log(value);
              console.log(field.value);
              field.onChange(value);
            }}
            noOptionsText={t("No_Data")}
          ></StyledAutocomplete>
        )}
      />
    </StyledBoxSearch>
  );
};

export default SelectCC;
