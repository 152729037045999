import { httpClient } from "./httpClient";

const getDepartment = (idCompany) => {
  return httpClient.get(`/company/${idCompany}/departments`);
};

const getDepartmentByIdCompany = (idCompany) => {
  return httpClient.get(`company/${idCompany}/department/idCompany`);
};



const getAllDepartments = (query) => {
  return httpClient.get(`departments`, { params: query });
};

export default {
    getDepartment,
    getDepartmentByIdCompany,
    getAllDepartments
};
