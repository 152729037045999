import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import { getAllEmployees } from "../../../../actions/employee";

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const SelectEmployeesManager = (props) => {
  let { handleChange, company } = props;
  const dispatch = useDispatch();
  const { result: employees } = useSelector((state) => state.employees);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getAllEmployees("manager"));
  }, []);
  return (
    <StyledBoxSearch>
      <Typography className="label" color="text.third">
        {t("SearchEmp")}
      </Typography>
      <StyledAutocomplete
        options={employees ? employees : []}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        closeIcon={null}
        groupBy={(option) => option.groupBy}
        popupIcon={<i class="fa-light fa-chevron-down"></i>}
        getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder={t("SelectEmp")}
            InputProps={{ ...params.InputProps, endAdornment: null }}
          />
        )}
        PopperComponent={StyledPopper}
        noOptionsText={t("No_Data")}
      />
    </StyledBoxSearch>
  );
};

export default SelectEmployeesManager;
