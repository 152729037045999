import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from "react-dropzone";

import {
    Avatar,
    styled,
    Typography
} from '@mui/material';

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import { uploadProfileImage } from '../../../../../../actions/employee';

const StyledImageProfile = styled("div")({
    width: "100%",
    "& .dropzone-profile": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border 0.24s ease-in-out",
      height: 160,
      width: 160,
      margin: "auto",
      borderRadius: "50%",
      padding: 8,
      border: "1px dashed rgba(145, 158, 171, 0.32)",
    },
    "& .inner-dropzone-profile": {
      cursor: "pointer",
      zIndex: 0,
      width: "100%",
      height: "100%",
      outline: "none",
      display: "flex",
      overflow: "hidden",
      backgroundColor: "#FFFFFF",
      borderRadius: "50%",
      position: "relative",
      "& .placeholder": {
        //display: "none",
      },
      "&:hover .placeholder": {
        zIndex: 9,
        display: "none",
        //display: "flex",
      },
    },
    "& .uploadImage": {
      width: "105%",
      zIndex: 8,
    },
    "& .placeholder": {
      width: "100%",
      height: "100%",
      display: "flex",
      position: "absolute",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      color: "rgb(99, 115, 129)",
      backgroundColor: "rgb(244, 246, 248)",
      transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .placeholderImageProfile": {
      display: "none",
      color: "rgb(255, 255, 255)",
      backgroundColor: "rgba(22, 28, 36, .72)",
    },
    "& .placeholderLabel": {
      color: "rgb(255, 255, 255)",
    },
    "& .wrapCaptionImage": {
      marginTop: 16,
      textAlign: "center",
    },
    "& .captionImage": {
      color: "#212b36",
    },
});

const AvatarEmployee = () => {
    const dispatch = useDispatch();
    const [fileSelected, setFileSelected] = useState([]);
    const { result: employeeProfile } = useSelector(
        (state) => state.employeeProfile
    );

    useEffect(
        () => {
          fileSelected.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [fileSelected]
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/jpeg, image/png",
        noClick: false,
        noKeyboard: true,
        onDrop: async (acceptedFiles) => {
          let formDataDrop = new FormData();
          acceptedFiles.map((file) => formDataDrop.append("file", file));
          let Imagefile = acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );
          setFileSelected(Imagefile);

          const formData = new FormData();
            
          formData.append("idEmp", employeeProfile.idEmp);
          formData.append("file", Imagefile[0]);

          await dispatch(uploadProfileImage(formData));
        },
        maxFiles: 1,
        multiple: false
    });

    const thumbs = (row) => {
        if (fileSelected.length === 0) {
            if(employeeProfile.imageProfile){
              return (
                <img
                  src={`${employeeProfile.imageProfile}`}
                  className={`uploadImage`}
                  alt={`${employeeProfile.firstname}`}
                />
              );
            } else {
              return (
                <img
                  src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                  className={`uploadImage`}
                  alt={`${employeeProfile.firstname}`}
                />
              );
              // return (
              //   <Avatar 
              //     src="" 
              //     style={{ width:"100%", height:"100%" }}
              //     alt={`${employeeProfile.firstname}`} 
              //   />
              // );
            }
        } else { 
          return (
            <img
              alt={fileSelected[0].firstname}
              src={fileSelected[0].preview}
              className={`uploadImage`}
            />
          );
        }
    };

    return (
        <StyledImageProfile>
            <div {...getRootProps({ className: "dropzone-profile" })}>
                <div className="inner-dropzone-profile">
                    <input {...getInputProps()} />
                    <Fragment>{thumbs(employeeProfile)}</Fragment>
                    <div className={`placeholder placeholderImageProfile`}>
                        <AddAPhotoIcon />
                        <Typography
                            style={{ marginTop: 8, backgroundColor: "transparent" }}
                            className={`${fileSelected != 0 && `placeholderLabel`}`}
                            variant="body2"
                        >
                            Upload Photo
                        </Typography>
                    </div>
                </div>
            </div>
        </StyledImageProfile>
    );
};

export default AvatarEmployee;