import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import { Divider, Grid, styled, Typography } from "@mui/material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogEdit from "./DialogEdit";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

function Terminate(props) {
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { t, i18n } = useTranslation();

  const [openEdit, setOpenEdit] = useState(false);

  const toggleDrawer = (open) => (event) => {
    console.log(open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenEdit(open);
  };

  const handleCloseDrawer = () => {
    setOpenEdit(false);
  };
  const handleOpenDrawer = () => {
    setOpenEdit(true);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">
            วันที่สิ้นสุดสภาพพนักงาน
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.resignDate
              ? dayjs(employeeProfile.resignDate)
              .locale(localStorage.getItem("language") || "th")
              .format(
                localStorage.getItem("language") === "th"
                  ? "DD MMMM BBBB"
                  : "DD MMMM YYYY"
              )
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">
            {t("Reason")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.reasonTerminate
              ? employeeProfile.reasonTerminate
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">
            {t("OtherReason")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.otherReasonTerminate
              ? employeeProfile.otherReasonTerminate
              : "-"}
          </Typography>
        </Grid>
      </Grid>
      <StyledDivider />
      <ButtonBlue size="large" variant={"contained"} onClick={handleOpenDrawer}>
        {`${t("Edit")} สิ้นสุดสภาพพนักงาน`}
      </ButtonBlue>
      <DialogEdit
        open={openEdit}
        handleCloseDrawer={handleCloseDrawer}
        toggleDrawer={toggleDrawer}
      />
    </div>
  );
}

export default Terminate;
