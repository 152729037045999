import { httpClient } from "./httpClient";

const dumpExcelEmployees = (formData, query) => {
  return httpClient.put(`upload-excel/dumpFile`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    params: query,
  });
};

const dumpExcelBillingStatement = (formData, query) => {
  return httpClient.post(`upload-excel/dumpFileBillsStatement`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    params: query,
  });
};

const getAllExcelBillingStatement = () => {
  return httpClient.get(`billsStatement`);
};

const getExcelBillingStatementById = (idCompany) => {
  return httpClient.get(`billsStatement/${idCompany}`);
};

const getBillingTableData = (idBillingStatement) => {
  return httpClient.get(`getBillsStatementTableData/${idBillingStatement}`);
};

const editBillingStatement = (formData) => {
  return httpClient.put(`editBillingStatement`, formData);
};

const deleteBillingStatement = (formData) => {
  return httpClient.delete(`deleteBillingStatement`, {
    data: formData
  });
}

const getIsApproveExcelBillingStatementById = (idCompany) => {
  return httpClient.get(`billsStatementApprove/${idCompany}`);
};

export default {
  dumpExcelEmployees,
  dumpExcelBillingStatement,
  getAllExcelBillingStatement,
  getExcelBillingStatementById,
  getBillingTableData,
  editBillingStatement,
  deleteBillingStatement,
  getIsApproveExcelBillingStatementById
};
