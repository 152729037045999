import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";

import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { getManagerByidCompany } from "../../../../actions/company";
import { useTranslation } from "react-i18next";

// import utils from "../../../../utils";

const StyledBoxSearch = styled(Box)({
  //   marginTop: 22,
  //   "& .label": {
  //     fontWeight: 600,
  //     fontSize: 14,
  //     marginBottom: 8,
  //   },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const filterOptions = createFilterOptions({
  stringify: (option) =>
    option.firstname_TH + option.lastname_TH + option.positionName,
});

const filterOptionsName = createFilterOptions({
  // matchFrom: "any",
  stringify: (option) => `${option.name}`,
});

const SelectApprover = (props) => {
  let { handleChange, idApprover, disabled } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: manager } = useSelector((state) => state.manager);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (userProfile) {
      dispatch(getManagerByidCompany(userProfile.idCompany));
    }
  }, [userProfile]);

  return (
    <StyledBoxSearch>
      <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
        {t("Approver")}
      </StyledHeadLabel>

      <StyledAutocomplete
        value={
          idApprover && manager
            ? manager.find((x) => x.idManagerEmployee === idApprover) || null
            : null
        }
        fullWidth
        options={manager ? manager : []}
        onChange={(event, newValue) => {
          console.log("newValue", newValue);
          if (newValue) {
            handleChange("approver", newValue.idManagerEmployee);
          } else {
            handleChange("approver", null);
          }
        }}
        filterOptions={filterOptionsName}
        // groupBy={position ? (option) => option.positionName : null}
        closeIcon={null}
        popupIcon={<i class="fa-light fa-chevron-down"></i>}
        getOptionLabel={(option) => `${option.name}`}
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            key={option.idManagerEmployee}
            fullWidth
          >
            {option.name}
          </Box>
        )}
        renderInput={(params) => (
          <TextFieldTheme
            {...params}
            variant="outlined"
            placeholder={t("SelectApprover")}
            InputProps={{ ...params.InputProps, endAdornment: null }}
          />
        )}
        PopperComponent={StyledPopper}
        noOptionsText={t("No_Data")}
        disabled={disabled ? disabled : false}
      />
    </StyledBoxSearch>
  );
};

export default SelectApprover;
