import {
    SERVICES_FETCHING,
    SERVICES_FAILED,
    SERVICES_SUCCESS
} from "../actions/types";

const initialState = {
    result: null,
    isFetching: false,
    isError: false
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SERVICES_FETCHING:
            return {...state, result: null, isFetching: true, isError: false};
        case SERVICES_FAILED:
            return {...state, result: null, isFetching: false, isError: true};
        case SERVICES_SUCCESS:
            return {...state, result: payload, isFetching: false, isError: false};
        default:
            return state;
    }
};