import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { updateProbationAdmin } from "../../../../../../actions/probation";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  padding: "16px",
});

const DialogEdit = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { drawerConfig, handleCloseDrawer } = props;

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const validateYupSchema = yup.object({
    result: yup.string().required("กรุณากรอกข้อมูล"),
  });

  const useHookForm = useForm({
    defaultValues: {
      result: "",
      remark: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const onSubmit = async (data) => {
    const formData = {
      idProbation: drawerConfig.data.idProbation,
      probationResult: data.result
    }

    const res = await dispatch(updateProbationAdmin(formData))
    if (res && res.status === 200) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
    handleCloseDrawer();
  };

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      useHookForm.reset({
        result: drawerConfig.data.result,
        remark: drawerConfig.data.remark,
      });
    }
  }, [drawerConfig.isOpen]);

  return (
    <DrawerCustom
      title={`${t("EditProbationResult")}`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}Date
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                color="text.secondary"
                paddingBottom="4px"
              >
                {t("StartDate")}
              </Typography>
              <Typography fontSize="18px" fontWeight="500">
                {drawerConfig.data.start}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                color="text.secondary"
                paddingBottom="4px"
              >
                {t("EndDate")}
              </Typography>
              <Typography fontSize="18px" fontWeight="500">
                {drawerConfig.data.end}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                fontSize="14px"
                fontWeight="600"
                color="text.secondary"
                paddingBottom="4px"
              >
                {t("ProbationResult")}
              </Typography>
              <Controller
                name="result"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  >
                    <MenuItem value={1}>{t("PassedProbation")}</MenuItem>
                    <MenuItem value={0}>{t("NotPassedProbation")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue
                variant="text"
                onClick={handleCloseDrawer}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
