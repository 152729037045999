import dashboardContractService from "../services/dashboardContract.service"

export const getDashboardContract = (date) => async (dispatch) => {
  try {
    const res = await dashboardContractService.getDashboardContract(date);
    if (res) {
      return res;
    }
  } catch (err) {
    return err.response;
  }
};
