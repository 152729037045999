import React, { useState } from "react";

import { Dialog, DialogContent, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: 450,
  [theme.breakpoints.down("sm")]: {
    width: 270,
  },
}));

const DialogCommentReject = (props) => {
  const { open, onClose, handleSubmit } = props;
  const [comment, setComment] = useState("");
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledDialogContent>
        <Typography variant="h5">{t("ConfirmItemDisapproval")}</Typography>
        <TextFieldTheme
          style={{ marginTop: 16 }}
          placeholder={t("ReasonForDisapproval")}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
          fullWidth
          multiline
          rows={4}
          helperText={t("NotRequired")}
        />
        <div
          style={{
            width: "100%",
            marginTop: 16,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ButtonBlue onClick={onClose}>{t("Cancel")}</ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={() => {
              handleSubmit(comment);
            }}
          >
            {t("Confirm")}
          </ButtonBlue>
        </div>
      </StyledDialogContent>
    </Dialog>
  );
};

export default DialogCommentReject;
