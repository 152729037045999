import React, { useState,  useEffect } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { 
  TextField, 
  Typography,
  Grid, 
  Box,
  Popper,
  Button,
} from '@mui/material';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Autocomplete, { autocompleteClasses }  from "@mui/material/Autocomplete";
import loading from "../../../assets/social-media.gif";

import {
    getIsApproveExcelBillingStatementById,
} from "../../../../../actions/dumpExcel"

const StyledTableContainer = styled(TableContainer)({
  width: "100%",
  minWidth: 720,
});

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderColor: "#919eab3d",
    padding: "8px 16px",
  },
  "& .cellStatusAction": {
    width: 30,
  },
  "& .cellFullName": {
    width: 150,
  },
  "& .cellTypeLeave": {
    width: 100,
  },
  "& .cellStartStop": {
    width: 150,
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 0,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#ffffff", 
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    border: "1px solid #9e9e9e",
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledLoading = styled("div")({
  marginTop: 24,
  marginLeft: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  height: "inherit",
});

const  TableList = (props) => {
  let { selectedMonthYear } = props;
  
  const { result: billingsStatement} = useSelector((state) => state.billingStatement);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [filteredName, setFilteredName] = useState('');
  const [month, setMonth] = useState(dayjs().get("month") + 1);;
  const [BillsStatement, setBillsStatement] = useState(billingsStatement);
  const [filteredBillsMonth, setFilteredBillsMonth] = useState([]);
  const [filteredBillings, setFilteredBillings] = useState([]);

  useEffect(() => {
    setBillsStatement(billingsStatement);
  }, [billingsStatement]);

  useEffect(() => {
    if(userProfile && userProfile.idCompany) {
    dispatch(getIsApproveExcelBillingStatementById(userProfile.idCompany));  
    }
  }, []);

  useEffect(() => {
    if (BillsStatement && BillsStatement.length > 0) {
      const selectedYear = selectedMonthYear.year(); 
      const selectedMonth = selectedMonthYear.month() + 1; 

      const filtered = BillsStatement.filter(statement => {
        const billDate = dayjs(statement.billsPeriod);
        const billYear = billDate.year(); 
        const billMonth = billDate.month() + 1;

      
        return billYear === selectedYear && billMonth === selectedMonth;});
      setFilteredBillsMonth(filtered); 
    } else {
      setFilteredBillsMonth([]); 
    }
  }, [BillsStatement, selectedMonthYear]); 
  
  useEffect(() => {
    let filtered = (filteredBillsMonth || []).filter((row) => {
      const nameFilter = typeof filteredName === 'string' ? filteredName : '';
      
      const isNameMatch = row.billingStatementName
        .toLowerCase()
        .includes(nameFilter.toLowerCase()); 
      return isNameMatch;
    });
    setFilteredBillings(filtered);
  }, [filteredBillsMonth, filteredName, month])

  return (
    <StyledTableContainer
      className="tableContainer"
      style={{ width: "100%", minHeight: 160 }}
    >  
      <Grid container spacing={4} mb={3} mt={4}>
         <Grid item xs={12} sm={6} md={3} mb={2}>
          <StyledBoxSearch>
            <div className="search-name">
              {filteredBillings && (
                <StyledAutocomplete
                  options={filteredBillings}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFilteredName(newValue.billingStatementName || '');
                    } else {
                      setFilteredName('');
                    }
                  }}
                  popupIcon={<i className="fa-light fa-chevron-down"></i>}
                  getOptionLabel={(option) =>
                    `${option.billingStatementName}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      placeholder={`${t("Search")}`}
                    />
                  )}
                  PopperComponent={StyledPopper}
                  noOptionsText={`${t("NoData")}`}
                />
              )}
            </div>
          </StyledBoxSearch>
        </Grid>
      </Grid>
      <StyledTable aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell className={`tableCellHead`} align="center">
              {t("ItemName")}
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              {t("Month_Period")}
            </TableCell>
          </TableRow>
        </TableHead>  
        <TableBody>
           {userProfile && filteredBillings && filteredBillings.length > 0 ? (
              filteredBillings.map((row, index) => (
                <>
                <TableRow key={index}>
                  <TableCell align="center" className={`tableCell`}>
                    <Button
                    onClick={()=>{
                      props.onClick(row.idBillingStatement,selectedMonthYear)
                    }}
                    >
                        {row.billingStatementName}
                    </Button>
                  </TableCell>
                  <TableCell align="center" className={`tableCell`}>
                    <Typography>
                      {dayjs(row.billsPeriod)
                        .locale(localStorage.getItem("language") || "th")
                        .format(
                          localStorage.getItem("language") === "en"
                            ? "MMMM YYYY"
                            : "MMMM BBBB"
                        )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
              ))
           ) : (
              <Typography color="text.secondary" style={{ margin: "8px 0" }}>
                {t("NoData")}
              </Typography>
           )}
        </TableBody>
        <TableBody>
      </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
}

export default TableList;
