import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Card, Grid, Typography, Avatar, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import Deal from "../../assets/deal.png";

import "./partner-card.css";

const StyledRoot = styled("div")(() => ({
  paddingBottom: 80,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
}));

const StyledCard = styled(Card)({
  boxShadow: "none",
  borderRadius: 16,
  border: "1px solid #919eab3d",
  margin: 8,
  "&:hover": {
    cursor: "pointer",
    transition:
      "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
    transform: "translateY(-5px)",
  },
  "& .div-image": {
    width: "258px",
    height: "200px",
    display: "flex",
    justifyContent: "center",
    "& .image-company": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  "& .inner": {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    "& .number-employee": {
      width: "100%",
      fontSize: "24px",
      textAlign: "right",
    },
  },
});

const StyledContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 20,
  padding: "40px 24px",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "rgb(145 158 171 / 24%) -24px 24px 72px -8px",
  },
  "& .MuiAvatar-root": {
    width: 100,
    height: 100,
    marginBottom: 16,
    backgroundColor: "#FFFFFF",
  },
  "& .MuiTypography-h6": {
    fontSize: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 220,
  },
});

const PartnerCompany = (props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { result: vendorContract } = useSelector(
    (state) => state.vendorContract
  );

  const handleOnClickCompany = (idCompany) => {
    history.push(`/vendor/employees?filter=${idCompany}`);
  };

  return (
    <StyledRoot>
      {vendorContract && (
        <Fragment>
          <div className="header">
            <Typography color="text.secondary">
              {`${t("BusinessPartners")} (${vendorContract.length} ${t(
                "Company"
              )})`}
            </Typography>
            <Button variant="text" component={NavLink} to={`/vendor/overview`}>
              {t("All")}
            </Button>
          </div>
          <Grid container spacing={2} justifyContent="center">
            {vendorContract
              .filter((item, index) => index < 4)
              .map((company, index) => (
                <Grid item xs={12} sm={6} md={3}>
                  <StyledContent
                    key={company.idCompany}
                    onClick={() => handleOnClickCompany(company.idCompany)}
                  >
                    <Avatar
                      variant="rounded"
                      src={`${process.env.REACT_APP_API_URL}image/company/${company.companyImage}`}
                    >
                      <Avatar src={Deal} />
                    </Avatar>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      gutterBottom
                    >{`${company.numberEmployee} ${t("Person")}`}</Typography>
                    <Typography variant="h6" align="center" gutterBottom>
                      {company.companyName
                        ? company.companyName
                            .replace(`${t("Company")}`, "")
                            .replace(`${t("Ltd")}`, "")
                            .trim()
                        : "-"}
                    </Typography>
                  </StyledContent>
                </Grid>
              ))}
          </Grid>
        </Fragment>
      )}
    </StyledRoot>
  );
};

export default PartnerCompany;
