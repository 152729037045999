import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Container, Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { NavLink } from "react-router-dom";

import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import Favorite from "@mui/icons-material/Favorite";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";

import DialogAddAppointment from "./dialogAddAppointment";
import DialogDeleteCandidate from "./dialogDeleteCandidate";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";

import Utils from "../../../../utils";

const request = []

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#f1f4f9",
  paddingBottom: 36,
  // ["@media (min-width:1200px)"]:{
  //   paddingLeft: 24,
  //   paddingRight: 24
  // }
});

const StyledFormControl = styled(FormControl)({
  width: 350,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& fieldset": {
    borderColor: "#dee3e9",
  },
});

const StyledChip = styled(Chip)({
  color: "#b72136",
  backgroundColor: "#ff484229",
  fontWeight: 700,
  borderRadius: 6,
});

const StyledPaperCandidate = styled(Paper)({
  marginBottom: 16,
  padding: 16,
  borderRadius: 20,
  "& .check": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& .Mui-checked": {
      color: "#ff4842",
    },
  },
  "& .review": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledAvatarCandidate = styled(Avatar)({
  width: 64,
  height: 64,
  marginBottom: 16,
});

const StyledContent = styled("div")({
  padding: 36,
  paddingTop: 24,
  "& .part-one": {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 8,
    "& .detail": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "& .start": {
        "& .position": {
          marginBottom: 12,
        },
        "& div": {
          marginRight: 32,
        },
      },
      "& .end": {
        textAlign: "end",
      },
    },
    "& .MuiAvatar-root": {
      width: 120,
      height: 120,
      fontSize: 42,
      marginRight: 36,
    },
    "& .MuiTypography-caption": {
      fontSize: 14,
    },
  },
  "& .part-two": {
    display: "flex",
    justifyContent: "end",
  },
  "& .wrap-item": {
    "& .label": {
      fontWeight: 400,
      fontSize: 16,
      marginBottom: 4,
      "& span": {
        marginLeft: 16,
      },
    },
    "& .value": {
      fontSize: 18,
      marginLeft: 32,
    },
    "& .fal": {
      marginRight: 8,
    },
  },
  "&.selected-candidate": {
    minHeight: 200,
    "& .part-one": {
      marginBottom: 16,
    },
    "& .wrap-selected-candidate-list": {
      display: "grid",
      columnGap: 16,
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    "& .candidate-id": {
      "& .MuiTypography-h6": {
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    "& .date-appointment": {
      marginTop: 8,
      display: "flex",
      flexDirection: "column",
    },
  },
  "&.candidate": {
    minHeight: 200,
    "& .part-one": {
      marginBottom: 16,
      display: "flex",
      justifyContent: "space-between",
    },
    "& .wrap-candidate-list": {
      display: "grid",
      columnGap: 16,
      gridTemplateColumns: "repeat(4, 1fr)",
      "& .candidate-id": {
        "& .MuiTypography-h6": {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
  },
});

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const RequestInformationPage = (props) => {
  const idRequest = props.match.params.id;
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [openAddAppointment, setOpenAddAppointment] = React.useState(false);
  const [openDeleteCandidate, setOpenDeleteCandidate] = React.useState(false);
  const [candidate, setCandidate] = React.useState([
    {
      id: "001-234754",
      company: "Vender 1",
      rating: 3.5,
      review: "3.46k",
    },
    {
      id: "001-934234",
      company: "Vender 1",
      rating: 3.5,
      review: "3.46k",
    },
    {
      id: "002-545345",
      company: "Vender 2",
      rating: 3.5,
      review: "3.46k",
    },
    {
      id: "002-234754",
      company: "Vender 2",
      rating: 3.5,
      review: "3.46k",
    },
    {
      id: "003-546333",
      company: "Vender 3",
      rating: 3.5,
      review: "3.46k",
    },
  ]);
  const [selectedCandidate, setSelectedCandidate] = React.useState([
    {
      id: "001-234754",
      company: "Vender 1",
      rating: 3.5,
      review: "3.46k",
    },
    {
      id: "002-234754",
      company: "Vender 2",
      rating: 3.5,
      review: "3.46k",
    },
    {
      id: "003-546333",
      company: "Vender 3",
      rating: 3.5,
      review: "3.46k",
    },
  ]);

  const handleCloseDialog = () => {
    setOpenAddAppointment(false);
    setOpenDeleteCandidate(false);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          Information Request
        </Typography>
        <CardStyle>
          <StyledContent>
            <Typography variant="caption" color="text.secondary" gutterBottom>
              Posted day: 08 Dec 2021
            </Typography>
            <div className={`part-one`}>
              <Avatar variant="rounded" src={request[idRequest].icon} />
              <div className={`detail`}>
                <div className={`start`}>
                  <div className="position">
                    <Typography variant="caption" color="text.secondary">
                      ตำแหน่ง
                    </Typography>
                    <Typography variant="h5">
                      {request[idRequest].name}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="caption" color="text.secondary">
                      หน่วยงาน
                    </Typography>
                    <Typography variant="h5">
                      {request[idRequest].department}
                    </Typography>
                  </div>
                </div>
                <div className={`end`}>
                  <div>
                    {request[idRequest].urgent && (
                      <StyledChip label="Urgent" size="small" />
                    )}
                    <Typography
                      component={"div"}
                      variant="caption"
                      color="text.secondary"
                    >
                      เปิดรับสมัคร
                    </Typography>
                    <Typography variant="h4" align="right">
                      {request[idRequest].size}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 16, marginBottom: 8 }}>
              <Typography
                style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
              >
                Job Description
              </Typography>
              <Typography style={{}}>
                {request[idRequest].description}
              </Typography>
            </div>
            <Divider
              style={{
                margin: "16px 0px",
                borderWidth: "0px 0px thin",
                borderColor: "rgba(145, 158, 171, 0.24)",
                borderStyle: "dashed",
              }}
            />
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <div className={`wrap-item`}>
                    <Typography className="label" color="text.secondary">
                      <i class="far fa-map-marker-alt"></i>
                      <span>Location</span>
                    </Typography>
                    <Typography className="value">{`${request[idRequest].city}, ${request[idRequest].province}`}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div className={`wrap-item`}>
                    <Typography className="label" color="text.secondary">
                      <i class="far fa-calendar-times"></i>
                      <span>Expiration date</span>
                    </Typography>
                    <Typography className="value">12 Dec 2021</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div className={`wrap-item`}>
                    <Typography className="label" color="text.secondary">
                      <i class="far fa-paper-plane"></i>
                      <span>Experience</span>
                    </Typography>
                    <Typography className="value">
                      {request[idRequest].exp <= 8
                        ? `${request[idRequest].exp} ปี`
                        : `9 ปีขึ้นไป`}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div className={`wrap-item`}>
                    <Typography className="label" color="text.secondary">
                      <i class="far fa-clock"></i>
                      <span>Employee Type</span>
                    </Typography>
                    <Typography className="value">
                      {request[idRequest].typeEmp}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <div className={`wrap-item`}>
                    <Typography className="label" color="text.secondary">
                      <i class="far fa-hand-holding-usd"></i>
                      <span>Offered Salary</span>
                    </Typography>
                    <Typography className="value">{`${Utils.inputNumberWithCommas(
                      request[idRequest].salary.toString()
                    )} บาท/${
                      request[idRequest].salaryType === "รายเดือน"
                        ? "เดือน"
                        : "วัน"
                    }`}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </StyledContent>
        </CardStyle>
        <CardStyle>
          <StyledContent className={`selected-candidate`}>
            <div className={`part-one`}>
              <Typography variant="h5">ผู้สมัครที่ถูกเลือก</Typography>
            </div>
            <div className={`wrap-selected-candidate-list`}>
              {selectedCandidate.map((value, index) => {
                return (
                  <StyledPaperCandidate variant="outlined">
                    <div className={`check`}>
                      <StyledAvatarCandidate
                        alt={value.id}
                        src={`${process.env.REACT_APP_API_URL}image/profile/${
                          index + 2
                        }.jpg`}
                      />
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => setOpenDeleteCandidate(true)}
                      >
                        <DeleteOutlineIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                    <div>
                      <div className={`candidate-id`}>
                        <Link
                          style={{
                            color: "#212B36",
                            fontSize: 20,
                            fontWeight: 500,
                          }}
                          component={NavLink}
                          to="/admin/request/candidate/1"
                          underline="hover"
                        >
                          {value.id}
                        </Link>
                        <Typography variant="body1" color="text.secondary">
                          {value.company}
                        </Typography>
                        <div className={`date-appointment`}>
                          <div className={`wrap-item`}>
                            <i class="fal fa-calendar"></i>
                            <Typography>12 Dec 2021</Typography>
                          </div>
                          <div className={`wrap-item`}>
                            <i class="fal fa-clock"></i>
                            <Typography>09:00 - 12:00</Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </StyledPaperCandidate>
                );
              })}
            </div>
          </StyledContent>
        </CardStyle>
        <CardStyle>
          <StyledContent className={`candidate`}>
            <div className={`part-one`}>
              <Typography variant="h5" gutterBottom>
                รายชื่อผู้สมัคร
              </Typography>
              <div>
                <ButtonBlue
                  variant={"contained"}
                  startIcon={<EventAvailableOutlinedIcon />}
                  onClick={() => setOpenAddAppointment(true)}
                >นัดหมาย</ButtonBlue>
              </div>
            </div>
            <div className={`wrap-candidate-list`}>
              {candidate.map((value, index) => {
                return (
                  <StyledPaperCandidate variant="outlined">
                    <div className={`check`}>
                      <StyledAvatarCandidate
                        alt={value.id}
                        src={`${process.env.REACT_APP_API_URL}image/profile/${
                          index + 2
                        }.jpg`}
                      />
                      <Checkbox
                        {...label}
                        icon={<FavoriteBorder />}
                        checkedIcon={<Favorite />}
                      />
                    </div>
                    <div>
                      <div className={`candidate-id`}>
                        <Link
                          style={{
                            color: "#212B36",
                            fontSize: 20,
                            fontWeight: 500,
                          }}
                          component={NavLink}
                          to="/admin/request/candidate/1"
                          underline="hover"
                        >
                          {value.id}
                        </Link>
                        {/* <Typography variant="h6" component={NavLink} to="/admin/request/candidate/1">{value.id}</Typography> */}
                        <Typography variant="body1" color="text.secondary">
                          {value.company}
                        </Typography>
                        <div className={`review`}>
                          <i
                            class="fas fa-star"
                            style={{ color: "#ffc81a", marginRight: 4 }}
                          ></i>
                          <Typography style={{ marginRight: 4 }}>
                            3.5
                          </Typography>
                          <Typography color="text.secondary">
                            (3.46k reviews)
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </StyledPaperCandidate>
                );
              })}
            </div>
          </StyledContent>
        </CardStyle>
        <DialogAddAppointment
          open={openAddAppointment}
          handleClose={handleCloseDialog}
        />
        <DialogDeleteCandidate
          open={openDeleteCandidate}
          handleClose={handleCloseDialog}
        />
      </Container>
    </StyledRoot>
  );
};

export default RequestInformationPage;
