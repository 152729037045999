import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Divider,
  styled,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

import DialogEducation from "./DialogEducation";
import DialogExperience from "./DialogExperience";

import {
  getExperienceByIdEmp,
  deleteExperience,
} from "../../../../../../actions/experience";
import {
  getEducationByIdEmp,
  deleteEducation,
} from "../../../../../../actions/education";
import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 2,
  color: "#007afe"
});

const StyledPaper = styled("div")({
  border: "none",
  display: "flex",
  alignItems: "flex-start",
  marginBottom: 16,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .wrap-head": {
    marginBottom: 16,
    display: "flex",
    alignItems: "baseline",
  },
  "&.bottom": {
    marginTop: 28,
    display: "flex",
    alignItems: "baseline",
  },
});

const StyleIconButton = styled(IconButton)({
  color: "#007afe",
});

const EducationExperience = () => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { t, i18n } = useTranslation();

  const [openEducation, setOpenEducation] = useState(false);
  const [openExperience, setOpenExperience] = useState(false);
  const [idExperience, setIdExperience] = useState(null);
  const [idEducation, setIdEducation] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");

  useEffect(() => {
    if (employeeProfile) {
      dispatch(getExperienceByIdEmp(employeeProfile.idEmp));
      // dispatch(getEducationByIdEmp(employeeProfile.idEmp));
    }
  }, []);

  const handleOpenExperience = (type, idExp) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idExp) {
      setIdExperience(idExp);
    }
    setOpenExperience(true);
  };

  const handleOpenEducation = (type, idEdu) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idEdu) {
      setIdEducation(idEdu);
    }
    setOpenEducation(true);
  };

  const handleClose = () => {
    setOpenEducation(false);
    setOpenExperience(false);
    setIdExperience(null);
    setIdEducation(null);
  };

  const displayDuration = (startDate, endDate) => {
    let result = dayjs(startDate).locale("en").format("MMM YYYY - ");
    if (endDate) {
      result += dayjs(endDate).locale("en").format("MMM YYYY");
    } else {
      result += "Present";
    }
    return result;
  };

  const handleDeleteExperience = async (idExp) => {
    const result = await dispatch(deleteExperience(idExp));
    if (result) {
      dispatch(
        getEmployeeProfile(employeeProfile.idEmp)
      );
    }
  };

  const handleDeleteEducation = async (idEdu) => {
    const result = await dispatch(deleteEducation(idEdu));
    if (result) {
      dispatch(
        getEmployeeProfile(employeeProfile.idEmp)
      );
    }
  };

  return (
    <div>
      <StyledWrapHead>
        <div style={{ marginBottom: 16, display: "flex" }}>
          <StyledHeadLabel>{`${t("EducationHistory")}`}</StyledHeadLabel>
          <div style={{ marginLeft: 8 }}>
            <Tooltip title={t("EditHistory")}>
              <IconButton aria-label="history" size="small">
                <ManageHistoryIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <ButtonBlue
          size="small"
          startIcon={<AddIcon />}
          onClick={() => handleOpenEducation("Add")}
        >
          {t("AddQualification")}
        </ButtonBlue>
      </StyledWrapHead>

      {employeeProfile.education && employeeProfile.education.length > 0 ? (
        employeeProfile.education.map((education) => (
          <Fragment key={education.idEducations}>
            <StyledPaper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div style={{ position: "absolute", right: "0", top: "0" }}>
                  <ButtonBlue
                    size="small"
                    startIcon={<EditIcon />}
                    style={{ marginRight: "5px" }}
                    onClick={() =>
                      handleOpenEducation("Edit", education.idEducations)
                    }
                  >
                    แก้ไข
                  </ButtonBlue>
                  <ButtonBlue
                    size="small"
                    startIcon={<DeleteIcon />}
                    style={{ color: "red" }}
                    onClick={() =>
                      handleDeleteEducation(education.idEducations)
                    }
                  >
                    ลบ
                  </ButtonBlue>
                </div>
                <Typography
                  variant="h6"
                  style={{ width: "calc(100% - 143px)" }}
                >
                  {`(${education.fromYear ? education.fromYear : "-"} ${t("to")} ${
                    education.endYear
                      ? education.endYear
                      : `${t("NotSpecified")}`
                  }) ${
                    education.university
                      ? education.university
                      : `${t("Unspecified")} ${t("University")}`
                  }`}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {`${education.major}, ${education.faculty}, ${education.degree}`}
                </Typography>
                <Typography style={{ fontWeight: 700, marginTop: 8 }}>
                  {`${t("GPA")}: ${
                    education.gpa ? education.gpa : `${t("Unspecified")}`
                  }`}
                </Typography>
              </div>
            </StyledPaper>
            <StyledDivider />
          </Fragment>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" style={{ color: "grey" }}>
            ไม่มีข้อมูลประวัติการศึกษา
          </Typography>
        </div>
      )}

      <StyledWrapHead className="bottom">
        <div style={{ marginBottom: 16, display: "flex" }}>
          <StyledHeadLabel> {`${t("ExperienceInfo")}`}</StyledHeadLabel>
          <div style={{ marginLeft: 8 }}>
            <Tooltip title={t("EditHistory")}>
              <IconButton aria-label="history" size="small">
                <ManageHistoryIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <ButtonBlue
          size="small"
          startIcon={<AddIcon />}
          onClick={() => handleOpenExperience("Add")}
        >
          {`${t("Add")} ${t("ExperienceInfo")}`}
        </ButtonBlue>
      </StyledWrapHead>

      {employeeProfile.experience && employeeProfile.experience.length > 0 ? (
        employeeProfile.experience.map((experience) => (
          <Fragment key={experience.idExperience}>
            <StyledPaper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div style={{ position: "absolute", right: "0", top: "0" }}>
                  <ButtonBlue
                    size="small"
                    startIcon={<EditIcon />}
                    style={{ marginRight: "5px" }}
                    onClick={() =>
                      handleOpenExperience("Edit", experience.idExperience)
                    }
                  >
                    {t("Edit")}
                  </ButtonBlue>
                  <ButtonBlue
                    size="small"
                    startIcon={<DeleteIcon />}
                    style={{ color: "red" }}
                    onClick={() =>
                      handleDeleteExperience(experience.idExperience)
                    }
                  >
                    {t("Delete")}
                  </ButtonBlue>
                </div>
                <Typography style={{ fontWeight: 500 }}>
                  {`(${displayDuration(
                    experience.startDate,
                    experience.endDate
                  )})`}
                </Typography>
                <Typography variant="h5">{experience.positionName}</Typography>
                <Typography variant="body1" color="text.secondary">
                  {experience.companyName}
                </Typography>
                <Typography style={{ fontWeight: 500, marginTop: 8 }}>
                  {experience.description}
                </Typography>
              </div>
            </StyledPaper>
            <StyledDivider />
          </Fragment>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" style={{ color: "grey" }}>
            {t("NoWorkExperienceInfo")}
          </Typography>
        </div>
      )}

      {openEducation && (
        <DialogEducation
          open={openEducation}
          handleClose={handleClose}
          idEdu={idEducation}
          type={typeDialog}
        />
      )}
      {openExperience && (
        <DialogExperience
          open={openExperience}
          handleClose={handleClose}
          idExp={idExperience}
          type={typeDialog}
        />
      )}
    </div>
  );
};

export default EducationExperience;
