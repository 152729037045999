import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import Paper from "@mui/material/Paper";
import DialogContract from "./DialogContract";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

function createData(start, end, type, file, status) {
  return { start, end, type, file, status };
}

const rows = [
  createData("2023-01-01", "2023-06-01", "พนักงานประจำ", "", "active"),
  createData("2023-06-01", "2023-09-01", "พนักงานประจำ", "", "active"),
  createData("2023-09-01", "2025-06-01", "พนักงานประจำ", "", "active"),
];

const EmploymentContract = () => {
  const { t, i18n } = useTranslation();
  const [openContract, setOpenContract] = useState(false);
  const [idContract, setIdContract] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");

  const handleOpenContract = (type, idCont) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idCont) {
      setIdContract(idCont);
    }
    setOpenContract(true);
  };

  const handleClose = () => {
    setOpenContract(false);
    setIdContract(null);
  };

  return (
    <div>
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("employeeContract")}
          </StyledHeadLabel>
          <div>
            <ButtonBlue
              style={{ marginRight: 8 }}
              size="small"
              variant="contained"
              onClick={() => handleOpenContract("Add")}
            >
              {t("addEmployeeContract")}
            </ButtonBlue>
          </div>
        </div>
        <TableContainer component={Paper} style={{ boxShadow: "none" }}>
          <Table style={{ minWidth: 600 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">{t("StartDate")}</TableCell>
                <TableCell align="center">{t("EndDate")}</TableCell>
                <TableCell align="center">{t("Employee_Type")}</TableCell>
                <TableCell align="center">{t("AttachFile")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    {dayjs(row.start)
                      .locale(localStorage.getItem("language") || "th")
                      .format(
                        localStorage.getItem("language") === "th"
                          ? "DD MMM BBBB"
                          : "DD MMM YYYY"
                      )}
                  </TableCell>
                  <TableCell align="center">
                    {dayjs(row.end)
                      .locale(localStorage.getItem("language") || "th")
                      .format(
                        localStorage.getItem("language") === "th"
                          ? "DD MMM BBBB"
                          : "DD MMM YYYY"
                      )}
                  </TableCell>
                  <TableCell align="center">{row.type}</TableCell>
                  <TableCell align="center">{row.file}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledWrapBranch>
      {openContract && (
        <DialogContract
          open={openContract}
          handleClose={handleClose}
          idEdu={idContract}
          type={typeDialog}
        />
      )}
    </div>
  );
};

export default EmploymentContract;
