import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Paper,
  Avatar,
  List,
  ListItem,
  Select,
  ListItemAvatar,
  ListItemText,
  Divider,
  Chip,
  Checkbox,
  FormControl,
  FilledInput,
  MenuItem,
  Box,
  Popover,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import SvgIcon from "@mui/material/SvgIcon";
import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";

import { getAttendanceOnce } from "../../../../actions/attendance";
import { getLeaveRequestOnce } from "../../../../actions/employee";
import { allLeaveType } from "../../../../actions/leave";

import ButtonBlue from "../../shared/general/ButtonBlue";
import Backdrop from "../../shared/general/Backdrop";
import DatePickerEng from "../../shared/general/DatePickerEng";
import DatePickerThai from "../../shared/general/DatePickerThai";

import utils from "../../../../utils";

const StyledRoot = styled("div")({
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledFormControlSelect = styled(FormControl)({
  width: 280,
  ["@media (min-width: 0px)"]: {
    "& .MuiFilledInput-input": {
      paddingTop: 15,
      paddingBottom: 15,
    },
  },
  ["@media (min-width: 900px)"]: {
    "& .MuiFilledInput-input": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    borderRadius: 8,
    position: "relative",
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
    "& .Mui-Select": {
      padding: "4px 0 5px",
    },
  },
});

const StyledLabelHead = styled(Typography)({
  fontSize: 24,
  fontWeight: 600,
  "& i": {
    marginRight: 8,
  },
});

const StyledChipTime = styled(Chip)({
  height: 28,
  width: 88,
  fontSize: 14,
  borderRadius: 8,
  border: "none",
  "&.check-in": {
    color: "#229a16",
    backgroundColor: "#54d62c29",
    "&.MuiChip-root.MuiChip-clickable:hover": {
      backgroundColor: "#54d62c29",
    },
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },
  "&.check-out": {
    color: "#b72136",
    backgroundColor: "#ff484229",
    "&.MuiChip-root.MuiChip-clickable:hover": {
      backgroundColor: "#ff484229",
    },
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
    },
  },
});

const StyledChipLeave = styled(Chip)({
  fontSize: 16,
  borderRadius: 8,
  color: "#637381",
  backgroundColor: "#f2f3f5",
  border: "none",
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
});

const StyledListCheckEmployees = styled(List)({
  height: 450,
  overflow: "auto",
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "& .MuiListItemText-primary": {
    fontWeight: 500,
  },
  "& .MuiListItemText-secondary": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 330,
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const ListCheckEmployees = (props) => {
  const { classCheck, searchText } = props;
  const { t, i18n } = useTranslation();

  const { result: attendance } = useSelector((state) => state.attendanceOnce);

  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const [overData, setOverData] = React.useState(null);
  const openPopover = Boolean(anchorElPopover);

  const rowsFilter = () => {
    let data = [...attendance];
    if (classCheck === "check-in") {
      data = data.filter((x) => x.isCheckIn);
    } else {
      data = data.filter((x) => !x.isCheckIn);
    }

    if (searchText.length > 0) {
      data = data.filter((item, index) => {
        if (
          item.firstname.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
          item.lastname.toUpperCase().indexOf(searchText.toUpperCase()) >= 0
        )
          return item;
      });
    }

    return data;
  };

  const handlePopoverOpen = (gpsAddress) => (event) => {
    if (gpsAddress) {
      setOverData(gpsAddress);
      setAnchorElPopover(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    console.log("handlePopoverClose");
    setAnchorElPopover(null);
    setOverData(null);
  };

  return (
    <StyledListCheckEmployees>
      {attendance ? (
        rowsFilter().map((row, index) => (
          <Fragment key={index}>
            <ListItem
              secondaryAction={
                <div className={`secondaryAction`}>
                  {row.idAttendanceType === 4 ? (
                    <StyledChipTime
                      icon={<GpsFixedRoundedIcon />}
                      className={classCheck}
                      label={row.time}
                      variant="outlined"
                      aria-owns={openPopover ? "mouse-over-popover" : undefined}
                      aria-haspopup="true"
                      onClick={handlePopoverOpen(row.gpsAddress)}
                    />
                  ) : (
                    <StyledChipTime
                      icon={
                        row.idAttendanceType === 2 ? (
                          <FingerprintIcon />
                        ) : row.idAttendanceType === 6 ? (
                          <Icon
                            style={{ fontSize: 16, color: classCheck === "check-in" ? "#229a16" : "#b72136"}}
                            baseClassName="fa-regular"
                            className="fa-face-viewfinder"
                          />
                        ) : (
                          <LocationOnIcon />
                        )
                      }
                      className={classCheck}
                      label={row.time}
                      variant="outlined"
                    />
                  )}
                  <Typography
                    className="location"
                    color="text.secondary"
                    variant="caption"
                  >
                    {row.gpsLocationsName
                      ? row.gpsLocationsName
                      : row.gpsAddress
                      ? row.gpsAddress
                      : ""}
                  </Typography>
                </div>
              }
            >
              <ListItemAvatar>
                <Avatar
                  src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${row.firstname} ${row.lastname}`}
                secondary={row.positionsName}
              />
            </ListItem>
            <StyledDivider variant="inset" component="li" />
          </Fragment>
        ))
      ) : (
        <ListItem style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">{t("Unsigned")}</Typography>
        </ListItem>
      )}
      {overData && (
        <Popover
          id="mouse-over-popover"
          open={openPopover}
          anchorEl={anchorElPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handlePopoverClose}
        >
          <Typography style={{ padding: 16 }}>
            {overData.replace(", Thailand", "")}
          </Typography>
        </Popover>
      )}
    </StyledListCheckEmployees>
  );
};

const ListLeaveEmployees = (props) => {
  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { t, i18n } = useTranslation();

  return (
    <StyledListCheckEmployees>
      {leaveEmployeeList && leaveEmployeeList.length > 0 ? (
        leaveEmployeeList.map((row, index) => (
          <Fragment key={index}>
            <ListItem
              secondaryAction={
                <StyledChipLeave label={`${row.name}`} variant="outlined" />
              }
            >
              <ListItemAvatar>
                <Avatar
                  src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${row.firstname} ${row.lastname}`}
                secondary={row.positionsName}
              />
            </ListItem>
            <StyledDivider variant="inset" component="li" />
          </Fragment>
        ))
      ) : (
        <ListItem style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">{t("LeaveNotFound")}</Typography>
        </ListItem>
      )}
    </StyledListCheckEmployees>
  );
};

const icon = <i className="fa-regular fa-square" style={{ fontSize: 24 }}></i>;
const checkedIcon = (
  <i className="fa-regular fa-square-check" style={{ fontSize: 24 }}></i>
);

function IconComponent(props) {
  return (
    <SvgIcon {...props} style={{ fontSize: 18 }}>
      <path d="M12 16.5 4.5 9l1.05-1.05L12 14.4l6.45-6.45L19.5 9z"></path>
    </SvgIcon>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 360,
    },
  },
};

const DailyPanel = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: departmentList } = useSelector((state) => state.department);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: attendance } = useSelector((state) => state.attendanceOnce);
  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );
  const [search, setSearch] = useState(new Date());
  const [searchText, setSearchText] = useState("");
  const [selectedLeave, setSelectedLeave] = useState([]);

  useEffect(() => {
    var filter = {
      searchDate: dayjs(search).format("YYYY-MM-DD"),
    };
    dispatch(getAttendanceOnce("admin", filter));
    dispatch(getLeaveRequestOnce("admin", dayjs(search).format("YYYY-MM-DD")));
    dispatch(allLeaveType());
  }, []);

  const handleChangeSelectedLeave = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedLeave(typeof value === "string" ? value.split(",") : value);
  };

  const handleSearch = () => {
    var filter = {
      searchDate: dayjs(search).format("YYYY-MM-DD"),
    };

    dispatch(getAttendanceOnce("admin", filter));
    dispatch(getLeaveRequestOnce("admin", dayjs(search).format("YYYY-MM-DD")));
    dispatch(allLeaveType());
  };

  const onExportExcel = () => {
    const elementList = [];
    for (let index = 0; index < attendance.length; index++) {
      const element = {
        id: index + 1,
        employeeId: attendance[index].employeeId,
        firstname: attendance[index].firstname,
        lastname: attendance[index].lastname,
        positionsName: attendance[index].positionsName,
        attendanceDateTime: dayjs(attendance[index].attendanceDateTime).format(
          "DD/MM/YYYY HH:mm"
        ),
        attendanceType:
          attendance[index].idAttendanceType === 1 ? "GPS" : "Finger",
        gpsLocationsName: attendance[index].gpsLocationsName,
        statusCheck: attendance[index].isCheckIn
          ? "ลงเวลาเข้างาน"
          : "ลงเวลาออกงาน",
      };
      elementList.push(element);
    }
    const workSheet = XLSX.utils.json_to_sheet(elementList);

    workSheet["A1"].v = "#";
    workSheet["B1"].v = "รหัสพนักงาน";
    workSheet["C1"].v = "ชื่อ";
    workSheet["D1"].v = "สกุล";
    workSheet["E1"].v = "ตำแหน่งงาน";
    workSheet["F1"].v = "วันที่เวลา";
    workSheet["G1"].v = "วิธีลงเวลา";
    workSheet["H1"].v = "สถานที่ลงเวลา";
    workSheet["I1"].v = "ประเภทลงเวลา";

    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet, "รายงานลงเวลาทำงาน");
    XLSX.writeFile(
      workBook,
      `รายงานลงเวลาทำงาน ${dayjs(search).format("DD_MMMM_YYYY-HHmm")}.xlsx`
    );
  };

  const renderLeaveType = () => {
    const leaveTypeUniqueList = utils.getUniqueListBy(
      leaveTypeList,
      "idLeaveType"
    );

    leaveTypeUniqueList.forEach((element) => {
      element.count = leaveEmployeeList.filter(
        (x) => x.idLeaveType === element.idLeaveType
      ).length;
    });

    return leaveTypeUniqueList;
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <StyledRoot>
      <StyledWrapFilter>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("FullName")}
              </Typography>
              <div className="search-name">
                <FormControl fullWidth variant="filled">
                  <StyledFilledInput
                    id="filled-sarch-name"
                    placeholder={`${t("Search")} ${t("FullName")}`}
                    name="search"
                    onChange={handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("SearchDate")}
              </Typography>
              <div className="search-date">
                {localStorage.getItem("language") === "en" ? (
                  <DatePickerEng
                    value={search}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setSearch(newValue);
                    }}
                  />
                ) : (
                  <DatePickerThai
                    value={search}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setSearch(newValue);
                    }}
                  />
                )}
                {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <DatePicker
                    inputFormat="dd/MM/yyyy"
                    value={search}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setSearch(newValue);
                    }}
                    renderInput={(params) => (
                      <StyledTextField variant="filled" fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider> */}
              </div>
            </StyledBoxSearch>
          </Grid>

          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <div className="wrap-search-action">
                <div>
                  <ButtonBlue
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                  >
                    {t("Search")}
                  </ButtonBlue>
                </div>
                <div className="btn-export">
                  <ButtonBlue
                    variant="outlined"
                    startIcon={<FileDownloadIcon />}
                    onClick={onExportExcel}
                  >
                    {t("Download")}
                  </ButtonBlue>
                </div>
              </div>
            </StyledBoxSearch>
          </Grid>
        </Grid>
      </StyledWrapFilter>
      {attendance ? (
        <Fragment>
          <div className="wrap-check">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper variant="outlined" className="wrap-check-in">
                  <div className="head">
                    <StyledLabelHead variant="h4">
                      <i className="fa-light fa-right-to-bracket"></i>
                      {t("WorkIn")}
                    </StyledLabelHead>
                    <div>
                      <StyledLabelHead variant="h4">{`${
                        attendance.filter((item) => item.isCheckIn).length
                      } ${t("Person")}`}</StyledLabelHead>
                    </div>
                  </div>

                  <ListCheckEmployees
                    classCheck="check-in"
                    searchText={searchText}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper variant="outlined" className="wrap-check-out">
                  <div className="head">
                    <StyledLabelHead variant="h4">
                      <i className="fa-light fa-right-from-bracket"></i>
                      {t("WorkOut")}
                    </StyledLabelHead>
                    <div>
                      <StyledLabelHead variant="h4">{`${
                        attendance.filter((item) => !item.isCheckIn).length
                      } ${t("Person")}`}</StyledLabelHead>
                    </div>
                  </div>

                  <ListCheckEmployees
                    classCheck="check-out"
                    searchText={searchText}
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>
          <div className="wrap-leave">
            <Paper variant="outlined" className="leave">
              <div className="filter">
                <StyledLabelHead variant="h4">
                  <i className="fa-light fa-house-person-leave"></i>
                  {t("LeaveWork")}
                </StyledLabelHead>
                <StyledFormControlSelect>
                  <Select
                    multiple
                    displayEmpty
                    value={selectedLeave}
                    onChange={handleChangeSelectedLeave}
                    input={<FilledInput label="Tag" />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography color="text.secondary">
                            {`${t("AllTypes")} (${
                              leaveEmployeeList && leaveEmployeeList.length
                            } ${t("Person")})`}
                          </Typography>
                        );
                      }
                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    IconComponent={IconComponent}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {leaveTypeList &&
                      leaveEmployeeList &&
                      renderLeaveType().map((item, index) => (
                        <MenuItem key={index}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selectedLeave}
                          />
                          <ListItemText
                            primary={`${item.count} ${t("Person")} : ${
                              item.name
                            }`}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </StyledFormControlSelect>
              </div>
              <ListLeaveEmployees />
            </Paper>
          </div>
        </Fragment>
      ) : (
        <Backdrop open={true} />
      )}
    </StyledRoot>
  );
};

export default DailyPanel;
