import React from "react";
import { useSelector } from "react-redux";

import UserExpense from "../../user/expense";
import AccountantExpense from "../../admin-accountant/expense";
import ManagerExpense from "../../manager/expense";
import AdminExpense from "../../vendor/expense";

const ExpensePage = () => {
  const { user: auth } = useSelector((state) => state.auth);
  return (
    <div>
      {auth ? (
        auth.roles.includes("ROLE_ACCOUNT") ? (
          <AccountantExpense />
        ) : auth.roles.includes("ROLE_MANAGER") ? (
          <ManagerExpense />
        ) : auth.roles.includes("ROLE_VENDOR") ? (
          <AdminExpense />
        ) : (
          <UserExpense />
        )
      ) : null}
    </div>
  );
};

export default ExpensePage;
