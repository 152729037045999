import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  Container,
  Tab,
  Tabs,
  Badge,
  Box,
  CardContent,
  Chip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ButtonBlue from "../../shared/general/ButtonBlue";

import CardStyle from "../../shared/general/Card";
import HistoryIcon from "@mui/icons-material/History";

import { getAllExpenseByIdReviewer } from "../../../../actions/expense";
import SubTableWithButton from "./subTable";
import { useTranslation } from "react-i18next";
import AnimationBackdrop from "../../shared/general/Backdrop";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .head-2": {
    paddingTop: 8,
    marginBottom: 24,
    marginTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-sum-amount": {
    marginBottom: 16,
  },
});

const StyledDivTab = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "0 8px",
  marginBottom: 16,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  fontSize: 16,
  "& .MuiBadge-badge": {
    right: -16,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#e46a76",
  },
}));

const StyledChipTab = styled(Chip)({
  display: "flex",
  flexDirection: "column",
  width: 20,
  height: 20,
  marginLeft: 5,
  backgroundColor: "orangered",
  color: "#fff",
  "& .MuiChip-label": {
    display: "block",
    whiteSpace: "normal",
    textAlign: "center",
  },
});

const StyledTabs = styled(Tabs)({
  "& .Mui-selected": {
    color: "#007AFE !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#007AFE !important",
  },
  "& .fal": {
    marginRight: 8,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExpensePage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [statusTab, setStatusTab] = React.useState(0);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expense } = useSelector((state) => state.expense);

  useEffect(() => {
    dispatch(getAllExpenseByIdReviewer());
  }, [userProfile]);

  const handleApproveTabsChange = (event, newValue) => {
    setStatusTab(newValue);
  };

  const handleViewDetail = (idExpense, expenseType, idEmp) => {
    if (idExpense) {
      history.push(`/expense/detail/${expenseType}/${idExpense}/${idEmp}/edit`);
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="head">
          <Typography variant="h4">{t("ExpenseReview")}</Typography>
        </div>

        <CardStyle>
          {expense ? (
            <CardContent>
              <StyledDivTab>
                <StyledTabs
                  value={statusTab}
                  onChange={handleApproveTabsChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  centered
                >
                  {"ServiceAndGoods" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("ServiceAndGoods")}
                          {expense.ServiceAndGoods &&
                          expense.ServiceAndGoods.length &&
                          expense.ServiceAndGoods.length > 0 ? (
                            <StyledChipTab
                              label={expense.ServiceAndGoods.length}
                            />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(0)}
                    />
                  )}
                </StyledTabs>
              </StyledDivTab>

              <Box className="wrap-table">
                <TabPanel value={statusTab} index={0}>
                  <SubTableWithButton
                    data={
                      expense.ServiceAndGoods ? expense.ServiceAndGoods : []
                    }
                    handleClick={handleViewDetail}
                  ></SubTableWithButton>
                </TabPanel>
              </Box>
            </CardContent>
          ) : (
            <AnimationBackdrop open={true} />
          )}
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default ExpensePage;
