import React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Container, Divider, Grid } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import imageCourse from "../../assets/6115095.jpg";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledRoot = styled("div")({
  "& .wrap": {
    paddingTop: 36,
  },
  "& .wrap-right": {
    paddingLeft: 36,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .footer": {
      paddingBottom: 16,
      "& .card-footer": {
        width: 320,
        margin: "auto",
      },
      "& .date-time": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 16,
        "& .text": {
          width: "100%",
        },
      },
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const Header = () => {
  return (
    <StyledRoot>
      <div className="wrap">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <img
              className="img"
              alt="image"
              src={imageCourse}
              style={{ width: "100%", borderRadius: 24 }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <div className="wrap-right">
              <div>
                <div>
                  <div style={{ marginBottom: 4 }}>
                    <ButtonBlue size="small" startIcon={<EditIcon />}>
                      แก้ไขรายละเอียด
                    </ButtonBlue>
                  </div>
                  <Typography variant="h3">Basic fire</Typography>
                </div>

                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  gutterBottom
                >
                  Lizards are a widespread group of squamate reptiles, with over
                  6,000 species, ranging across all continents except Antarctica
                </Typography>
              </div>
              <div className="footer">
                <StyledDivider />
                <div className="card-footer">
                  <Typography color="text.third" align="center">
                    กำลังจะถึงเร็วๆ นี้
                  </Typography>
                  <div className="date-time">
                    <div className="text">
                      <Typography variant="h5" align="center">
                        รุ่นที่ 23
                      </Typography>
                    </div>
                    <Divider
                      orientation="vertical"
                      flexItem
                      style={{ margin: "8px 8px" }}
                    />
                    <div className="text">
                      <Typography variant="body1" align="center" gutterBottom>
                        <i
                          class="fal fa-calendar"
                          style={{ paddingRight: 4 }}
                        ></i>{" "}
                        22 พ.ค. 2565
                      </Typography>
                      <Typography variant="body1" align="center" gutterBottom>
                        <i class="fal fa-clock" style={{ paddingRight: 4 }}></i>{" "}
                        9:00 - 12:00
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </StyledRoot>
  );
};

export default Header;
