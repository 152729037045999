import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Divider, Grid, Popper, TextField, Typography
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from '@tarzui/date-fns-be';
import { th } from "date-fns/locale";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import { 
  addEmployeePersonnelLevel, 
  getEmployeesProfileByIdForCompany,
  updateEmployeePersonnelLevel  
} from "../../../../../../actions/employee";
import { getJobLevlByidCompany } from "../../../../../../actions/jobLevel";
import { getAllGroupLevelsByIdCompany } from "../../../../../../actions/level";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDivider = styled(Divider)({
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const DialogEdit = (props) => {
  const { open, toggleDrawer, handleOpenAlertResponse, currentLevel } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector(
    (state) => state.userProfile
  );
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: jobLevelList } = useSelector(
    (state) => state.jobLevel
  );
  const { result: groupLevels } = useSelector(
    (state) => state.groupLevelCompany
  );

  // const [personnelLevelList, setPersonnelLevelList] = useState(null);
  const [formData, setFormData] = useState({
    personnelLevelName: null,
    start: new Date(),
    remark: "",
    idLevel: null
  });
  const [dataLevelList, setDataLevelList] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onHandleSubmit = async () => {
    let data = {
      idEmp : employeeProfile.idEmp,
      idLevel : formData.idLevel,
      start : dayjs(formData.start).format("YYYY-MM-DD"),
      remark : formData.remark
    };
    let result;
    if(employeeProfile.personnelLevel.length > 0){
      data.idEmployeePersonnelLevel = employeeProfile.personnelLevel[0].idEmployeePersonnelLevel
      data.end = dayjs(formData.start).subtract(1, "day").format("YYYY-MM-DD");
      result = await dispatch(updateEmployeePersonnelLevel(data));
    } else {
      result = await dispatch(addEmployeePersonnelLevel(data));
    }
    if(result.status === 200){
      dispatch(getEmployeesProfileByIdForCompany(employeeProfile.idEmp));
      toggleDrawer(false);
      handleOpenAlertResponse("success");
    }else{
      handleOpenAlertResponse("error");
    }
  };

  useEffect(() => {
    async function fetch(params) {
      await dispatch(getJobLevlByidCompany(userProfile.idCompany));
      await dispatch(getAllGroupLevelsByIdCompany());
    }

    fetch();
  }, []);

  useEffect(() => {
    if(groupLevels){
      let temp = [];
      groupLevels.forEach((element) => {
        if (element.level.length > 0) {
          let tempLevel = [...element.level];
          let addGroupName = tempLevel.map((object) => {
            return { ...object, groupLevelName: element.groupLevelName };
          });
          delete addGroupName["indexLevel"];
          temp.push(...addGroupName);
        }
      });

      setDataLevelList(temp);
    }
  },[groupLevels]);

  return (
    <DrawerCustom
      title={"แก้ไขข้อมูลระดับ"}
      anchor={"right"}
      open={open}
      onClose={() => toggleDrawer(false)}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledContentLabel>ข้อมูลปัจจุบัน</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledHeadLabel color="text.secondary">
                  กลุ่มระดับ
                </StyledHeadLabel>
                <Typography className="present">
                  {(currentLevel && currentLevel.groupLevelName)
                    ? currentLevel.groupLevelName
                    : "-"}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap">
                <StyledHeadLabel color="text.secondary">ระดับ</StyledHeadLabel>
                <Typography className="present">
                  {(currentLevel && currentLevel.levelName)
                    ? currentLevel.levelName
                    : "-"}
                </Typography>
              </div>
            </Grid>
          <Grid item xs={12}>
            <StyledDivider />
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel>ระดับใหม่</StyledContentLabel>
          </Grid>
          <Grid item xs={12}>
            <StyledAutocomplete
              value={formData.idLevel? dataLevelList.find(x => x.idLevel === formData.idLevel) || null : null}
              options={groupLevels && dataLevelList ? dataLevelList : []}
              groupBy={(option) => option.groupLevelName}
              onChange={(_, newValue) => {
                if (newValue) {
                  setFormData({
                    ...formData,
                    ["idLevel"]: newValue.idLevel,
                  });
                } else {
                  setFormData({
                    ...formData,
                    ["idLevel"]: null,
                  });
                }
              }}
              popupIcon={<i className="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => {
                if(option.levelName){
                  return option.levelName
                } else {
                  return option
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder="เลือกระดับ"
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={"ไม่พบข้อมูล"}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel>วันที่มีผล</StyledContentLabel>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
              <DatePicker
                value={formData.start}
                inputFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    start: date,
                  });
                }}
                renderInput={(params) => (
                  <StyledTextField fullWidth {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel>เหตุผล</StyledContentLabel>
          </Grid>
          <Grid item xs={12}>
            <TextFieldTheme
              variant="outlined"
              name="remark"
              value={formData.remark}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <ButtonBlue
                onClick={() => toggleDrawer(false)}
              >ยกเลิก</ButtonBlue>
            </Grid>
            <Grid item>
              <ButtonBlue
                variant="contained"
                onClick={onHandleSubmit}
                disabled={!formData.idLevel ? true : false}
              >บันทึกข้อมูล</ButtonBlue>
            </Grid>
          </Grid>
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
