import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";

import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";

import utils from "../../../../utils";

import downloadHeaderExcel from "../../assets/data/header-download-employee";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
  "& .btn-action": {
    display: "flex",
    justifyContent: "center",
    marginTop: 36,
  },
});

const StyleSelect = styled(FormControl)({
  marginBottom: 16,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    padding: "0px 12px",
    "& .MuiSelect-select": {
      padding: "13.5px 0px",
    },
  },
});

const DialogDownload = (props) => {
  const { open, toggleDrawer } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: employeeList } = useSelector((state) => state.users);
  const { result: companyContract } = useSelector(
    (state) => state.companyContract
  );
  const [dumpSelect, setDumpSelect] = React.useState("");
  const [excelData, setExcelData] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(-1);

  const handleChange = (event) => {
    setSelectedVendor(event.target.value);
  };

  const calculatorESY = (data) => {
    let Serv = utils.getAgeNumber(dayjs(data.hiringDate).format("DD/MM/YYYY"));

    let monthAge = (Serv.months + data.OESY_M) % 12;
    let yearAge = Math.floor((Serv.months + data.OESY_M) / 12);

    return {
      months: monthAge,
      years: Serv.years + data.OESY_Y + yearAge,
      esy: `${Serv.years + data.OESY_Y + yearAge}/${monthAge}`,
    };
  };

  const excelExport = () => {
    const elementList = [];
    const elementTermainateList = [];

    const temp = employeeList.active.filter((item) => {
      if (selectedVendor === -1) {
        return item;
      } else {
        return item.idVendor === selectedVendor;
      }
    });

    const tempTermainate = employeeList.termainate.filter((item) => {
      if (selectedVendor === -1) {
        return item;
      } else {
        return item.idVendor === selectedVendor;
      }
    });

    if(temp && temp.length > 0) {
      for (let index = 0; index < temp.length; index++) {
        const element = {
          "รหัสพนักงาน": temp[index].employeeId,
          "คำนำหน้า": temp[index].title,
          "ชื่อ": temp[index].firstname,
          "สกุล": temp[index].lastname,
          "Title": temp[index].title_en,
          "Firstname": temp[index].firstname_en,
          "Lastname": temp[index].lastname_en,
          "ชื่อเล่น": temp[index].nickname,
          "เพศ": temp[index].gender,
          "รหัสบัตรประชาชน": temp[index].personalId,
          "สัญชาติ": temp[index].nationality,
          "สถานะสมรส": temp[index].maritalStatus,
          "Passport Number": temp[index].passportId,
          "Work Permit ID": temp[index].workPermit,
          "วันเกิด": dayjs(temp[index].birthday).format("DD-MM-YYYY"),
          "ที่อยู่": temp[index].address,
          "บ้านเลขที่": temp[index].houseNo,
          "หมู่บ้าน": temp[index].vilage,
          "หมู่ที่": temp[index].vilageNo,
          "ซอย": temp[index].alley,
          "ถนน": temp[index].road,
          "แขวง/ตำบล": temp[index].subDistrict,
          "เขต/อำเภอ": temp[index].district,
          "จังหวัด": temp[index].provience,
          "รหัสไปรษณีย์": temp[index].areaCode,
          "เบอร์โทรศัพท์": utils.textStringToPhoneformat(
            temp[index].telephoneMobile
          ),
          "Email": temp[index].email,
          "ผู้ติดต่อฉุกเฉิน": temp[index].emergencyContact,
          "ผู้ติดต่อฉุกเฉินความสัมพันธ์": temp[index].emergencyRelationship,
          "ผู้ติดต่อฉุกเฉินเบอร์ติดต่อ": utils.textStringToPhoneformat(
            temp[index].emergencyPhone
          ),
          "ประเภทพนักงาน": temp[index].employeeTypeName,
          "ประเภทการจ้าง": temp[index].employmentTime,
          "สถานที่ทำงาน": temp[index].workingLocationName,
          "สถานที่ทำงานหลัก": temp[index].mainWorkingLocationPoint,
          "Filed/Office": temp[index].filed_office,
          "ระดับงาน": temp[index].jobLevelName,
          "ระดับพนักงาน": temp[index].personnelLevelName,
          "กลุ่มงาน": temp[index].jobGroupName,
          "ตำแหน่งงาน": temp[index].positionsName,
          "บริษัท": temp[index].companyName,
          "ฝ่าย (Division)": temp[index].divisionName,
          "ส่วน (Department)": temp[index].departmentName,
          "แผนก (Section)": temp[index].sectionName,
          "วันที่เริ่มงาน": temp[index].hiringDate
            ? dayjs(temp[index].hiringDate).format("DD-MM-YYYY")
            : null,
          "Reporting Manager Level 1": temp[index].managerLV1Name,
          "Manager Position Level 1": temp[index].managerLV1Position,
          "Manager E-Mail Level 1": temp[index].managerLV1Email,
          "Reporting Manager Level 2": temp[index].managerLV2Name,
          "Manager Position Level 2": temp[index].managerLV2Position,
          "Manager E-Mail Level 2": temp[index].managerLV2Email,
          "ประเภทเวลาทำงาน": temp[index].workingType,
          "ประเภทกะ": temp[index].nameShiftGroup,
          "ชื่อกะ": temp[index].nameShift,
          "วันทำงาน": temp[index].workDay,
          "ประเภทการจ่าย": temp[index].paymentTypeName,
          "รอบการจ่าย": temp[index].paymentRound,
          "ค่าจ้าง": utils.numberWithCommas(temp[index].salary),
          "ค่าดำเนินการปกติ":
            temp[index].chargeSalary < 0.99
              ? temp[index].chargeSalary * 100
              : temp[index].chargeSalary,
          "ค่าดำเนินการ OT":
            temp[index].chargeOt < 0.99
              ? temp[index].chargeOt * 100
              : temp[index].chargeOt,
          "ค่าดำเนินการเหมา":
            temp[index].chargeHire < 0.99
              ? temp[index].chargeHire * 100
              : temp[index].chargeHire,
          "ค่าดำเนินการค่าชดเชย":
            temp[index].chargeCompensation < 0.99
              ? temp[index].chargeCompensation * 100
              : temp[index].chargeCompensation,
          "วันที่สิ้นสุดสัญญา": temp[index].contractTermainatoinDate
            ? dayjs(temp[index].contractTermainatoinDate).format("DD-MM-YYYY")
            : "",
          "OESY(Y)": temp[index]["OESY_Y"],
          "OESY(M)": temp[index]["OESY_M"],
          "OESY": temp[index].OESY,
          "Serv.(Y)": utils.getAgeNumber(
            dayjs(temp[index].hiringDate).format("DD-MM-YYYY")
          ).years,
          "Serv.(M)": utils.getAgeNumber(
            dayjs(temp[index].hiringDate).format("DD-MM-YYYY")
          ).months,
          "ServYr": `${
            utils.getAgeNumber(
              dayjs(temp[index].hiringDate).format("DD-MM-YYYY")
            ).years
          }/${
            utils.getAgeNumber(
              dayjs(temp[index].hiringDate).format("DD-MM-YYYY")
            ).months
          }`,
          "ESY(Y)": calculatorESY(temp[index]).years,
          "ESY(M)": calculatorESY(temp[index]).months,
          "ESY": calculatorESY(temp[index]).esy,
          "TIG(Y)": temp[index]["TIG(Y)"],
          "TIG(M)": temp[index]["TIG(M)"],
          "TIG": temp[index].TIG,
          "อายุ(ปี)": utils.getAgeNumber(
            dayjs(temp[index].birthday).format("DD-MM-YYYY")
          ).years,
          "อายุ(เดือน)": utils.getAgeNumber(
            dayjs(temp[index].birthday).format("DD-MM-YYYY")
          ).months,
          "อายุ": `${
            utils.getAgeNumber(dayjs(temp[index].birthday).format("DD-MM-YYYY")).years
          }/${
            utils.getAgeNumber(dayjs(temp[index].birthday).format("DD-MM-YYYY")).months
          }`,
          "(1) Education Degree": 
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[0].degree
              ? temp[index].education[0].degree
              : "",
          "(1) Education School ot University":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[0].university
              ? temp[index].education[0].university
              : "",
          "(1) Faculty":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[0].faculty
              ? temp[index].education[0].faculty
              : "",
          "(1) Major":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[0].major
              ? temp[index].education[0].major
              : "",
          "(1) From Year":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[0].fromYear
              ? temp[index].education[0].fromYear
              : "",
          "(1) End Year":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[0].endYear
              ? temp[index].education[0].endYear
              : "",
          "(1) GPA":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[0].gpa
              ? temp[index].education[0].gpa
              : "",
          "(2) Education Degree": 
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[1] &&
            temp[index].education[1].degree
              ? temp[index].education[1].degree
              : "",
          "(2) Education School ot University":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[1] &&
            temp[index].education[1].university
              ? temp[index].education[1].university
              : "",
          "(2) Faculty":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[1] &&
            temp[index].education[1].faculty
              ? temp[index].education[1].faculty
              : "",
          "(2) Major":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[1] &&
            temp[index].education[1].major
              ? temp[index].education[1].major
              : "",
          "(2) From Year":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[1] &&
            temp[index].education[1].fromYear
              ? temp[index].education[1].fromYear
              : "",
          "(2) End Year":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[1] &&
            temp[index].education[1].endYear
              ? temp[index].education[1].endYear
              : "",
          "(2) GPA":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[1] &&
            temp[index].education[1].gpa
              ? temp[index].education[1].gpa
              : "",
          "(3) Education Degree": 
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[2] &&
            temp[index].education[2].degree
              ? temp[index].education[2].degree
              : "",
          "(3) Education School ot University":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[2] &&
            temp[index].education[2].university
              ? temp[index].education[2].university
              : "",
          "(3) Faculty":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[2] &&
            temp[index].education[2].faculty
              ? temp[index].education[2].faculty
              : "",
          "(3) Major":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[2] &&
            temp[index].education[2].major
              ? temp[index].education[2].major
              : "",
          "(3) From Year":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[2] &&
            temp[index].education[2].fromYear
              ? temp[index].education[2].fromYear
              : "",
          "(3) End Year":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[2] &&
            temp[index].education[2].endYear
              ? temp[index].education[2].endYear
              : "",
          "(3) GPA":
            temp[index].education &&
            temp[index].education.length > 0 &&
            temp[index].education[2] &&
            temp[index].education[2].gpa
              ? temp[index].education[2].gpa
              : "",
          "Performance Review Year (Past 1 Year)": temp[index].performance1,
          "Performance Review Year (Past 2 Year)": temp[index].performance2,
          "Performance Review Year (Past 3 Year)": temp[index].performance3,
          "Performance Review Year (Past 4 Year)": temp[index].performance4,
          "Performance Review Year (Past 5 Year)": temp[index].performance5,
          "Cost Center Charge": temp[index].costCenterCharge,
          "Cost Element Charge": temp[index].costElementCharge,
          "IO": temp[index].io,
          "Owner": temp[index].vendorName,
          "บริษัทเจ้าของงาน": temp[index].companyCharge,
          "ชื่อธนาคาร": temp[index].bookBank,
          "สาขาธนาคาร": temp[index].bookBankBranch,
          "เลขที่บัญชี": temp[index].bookBankId,
          "Reportธนาคาร ชื่อธนาคาร": temp[index].reportBank_BankName,
          "Reportธนาคาร รหัสธนาคาร": temp[index].reportBank_BankId,
          "Reportธนาคาร เลขที่บัญชี": temp[index].reportBank_BookBankId,
          "Reportธนาคาร ชื่อผู้รับเงิน": temp[index].reportBank_Name,
          "Reportธนาคาร อ้างอิง": temp[index].reportBank_Ref
        };

        elementList.push(element);
      } 
    } else {
      const element = {
        "รหัสพนักงาน": "",
        "คำนำหน้า": "",
        "ชื่อ": "",
        "สกุล": "",
        "Title": "",
        "Firstname": "",
        "Lastname": "",
        "ชื่อเล่น": "",
        "เพศ": "",
        "รหัสบัตรประชาชน": "",
        "สัญชาติ": "",
        "สถานะสมรส": "",
        "Passport Number": "",
        "Work Permit ID": "",
        "วันเกิด": "",
        "ที่อยู่": "",
        "บ้านเลขที่": "",
        "หมู่บ้าน": "",
        "หมู่ที่": "",
        "ซอย": "",
        "ถนน": "",
        "แขวง/ตำบล": "",
        "เขต/อำเภอ": "",
        "จังหวัด": "",
        "รหัสไปรษณีย์": "",
        "เบอร์โทรศัพท์": "",
        "Email": "",
        "ผู้ติดต่อฉุกเฉิน": "",
        "ผู้ติดต่อฉุกเฉินความสัมพันธ์": "",
        "ผู้ติดต่อฉุกเฉินเบอร์ติดต่อ": "",
        "ประเภทพนักงาน": "",
        "ประเภทการจ้าง": "",
        "สถานที่ทำงาน": "",
        "สถานที่ทำงานหลัก": "",
        "Filed/Office": "",
        "ระดับงาน": "",
        "ระดับพนักงาน": "",
        "กลุ่มงาน": "",
        "ตำแหน่งงาน": "",
        "บริษัท": "",
        "ฝ่าย (Division)": "",
        "ส่วน (Department)": "",
        "แผนก (Section)": "",
        "วันที่เริ่มงาน": "",
        "Reporting Manager Level 1": "",
        "Manager Position Level 1": "",
        "Manager E-Mail Level 1": "",
        "Reporting Manager Level 2": "",
        "Manager Position Level 2": "",
        "Manager E-Mail Level 2": "",
        "ประเภทเวลาทำงาน": "",
        "ประเภทกะ": "",
        "ชื่อกะ": "",
        "วันทำงาน": "",
        "ประเภทการจ่าย": "",
        "รอบการจ่าย": "",
        "ค่าจ้าง": "",
        "ค่าดำเนินการปกติ": "",
        "ค่าดำเนินการ OT": "",
        "ค่าดำเนินการเหมา": "",
        "ค่าดำเนินการค่าชดเชย": "",
        "วันที่สิ้นสุดสัญญา": "",
        "OESY(Y)": "",
        "OESY(M)": "",
        "OESY": "",
        "Serv.(Y)": "",
        "Serv.(M)": "",
        "ServYr": "",
        "ESY(Y)": "",
        "ESY(M)": "",
        "ESY": "",
        "TIG(Y)": "",
        "TIG(M)": "",
        "TIG": "",
        "อายุ(ปี)": "",
        "อายุ(เดือน)": "",
        "อายุ": "",
        "(1) Education Degree": "",
        "(1) Education School ot University": "",
        "(1) Faculty": "",
        "(1) Major": "",
        "(1) From Year": "",
        "(1) End Year": "",
        "(1) GPA": "",
        "(2) Education Degree": "",
        "(2) Education School ot University": "",
        "(2) Faculty": "",
        "(2) Major": "",
        "(2) From Year": "",
        "(2) End Year": "",
        "(2) GPA": "",
        "(3) Education Degree": "",
        "(3) Education School ot University": "",
        "(3) Faculty": "",
        "(3) Major": "",
        "(3) From Year": "",
        "(3) End Year": "",
        "(3) GPA": "",
        "Performance Review Year (Past 1 Year)": "",
        "Performance Review Year (Past 2 Year)": "",
        "Performance Review Year (Past 3 Year)": "",
        "Performance Review Year (Past 4 Year)": "",
        "Performance Review Year (Past 5 Year)": "",
        "Cost Center Charge": "",
        "Cost Element Charge": "",
        "IO": "",
        "Owner": "",
        "บริษัทเจ้าของงาน": "",
        "ชื่อธนาคาร": "",
        "สาขาธนาคาร": "",
        "เลขที่บัญชี": "",
        "Reportธนาคาร ชื่อธนาคาร": "",
        "Reportธนาคาร รหัสธนาคาร": "",
        "Reportธนาคาร เลขที่บัญชี": "",
        "Reportธนาคาร ชื่อผู้รับเงิน": "",
        "Reportธนาคาร อ้างอิง": ""
      };

      elementList.push(element);
    }

    if (tempTermainate && tempTermainate.length > 0) {
      for (let index = 0; index < tempTermainate.length; index++) {
        const element = {
          "รหัสพนักงาน": tempTermainate[index].employeeId,
          "คำนำหน้า": tempTermainate[index].title,
          "ชื่อ": tempTermainate[index].firstname,
          "สกุล": tempTermainate[index].lastname,
          "Title": tempTermainate[index].title_en,
          "Firstname": tempTermainate[index].firstname_en,
          "Lastname": tempTermainate[index].lastname_en,
          "ชื่อเล่น": tempTermainate[index].nickname,
          "เพศ": tempTermainate[index].gender,
          "รหัสบัตรประชาชน": tempTermainate[index].personalId,
          "สัญชาติ": tempTermainate[index].nationality,
          "สถานะสมรส": tempTermainate[index].maritalStatus,
          "Passport Number": tempTermainate[index].passportId,
          "Work Permit ID": tempTermainate[index].workPermit,
          "วันเกิด": dayjs(tempTermainate[index].birthday).format("DD-MM-YYYY"),
          "ที่อยู่": tempTermainate[index].address,
          "บ้านเลขที่": tempTermainate[index].houseNo,
          "หมู่บ้าน": tempTermainate[index].vilage,
          "หมู่ที่": tempTermainate[index].vilageNo,
          "ซอย": tempTermainate[index].alley,
          "ถนน": tempTermainate[index].road,
          "แขวง/ตำบล": tempTermainate[index].subDistrict,
          "เขต/อำเภอ": tempTermainate[index].district,
          "จังหวัด": tempTermainate[index].provience,
          "รหัสไปรษณีย์": tempTermainate[index].areaCode,
          "เบอร์โทรศัพท์": utils.textStringToPhoneformat(
            tempTermainate[index].telephoneMobile
          ),
          "Email": tempTermainate[index].email,
          "ผู้ติดต่อฉุกเฉิน": tempTermainate[index].emergencyContact,
          "ผู้ติดต่อฉุกเฉินความสัมพันธ์": tempTermainate[index].emergencyRelationship,
          "ผู้ติดต่อฉุกเฉินเบอร์ติดต่อ": utils.textStringToPhoneformat(
            tempTermainate[index].emergencyPhone
          ),
          "ประเภทพนักงาน": tempTermainate[index].employeeTypeName,
          "ประเภทการจ้าง": tempTermainate[index].employmentTime,
          "สถานที่ทำงาน": tempTermainate[index].workingLocationName,
          "สถานที่ทำงานหลัก": tempTermainate[index].mainWorkingLocationPoint,
          "Filed/Office": tempTermainate[index].filed_office,
          "ระดับงาน": tempTermainate[index].jobLevelName,
          "ระดับพนักงาน": tempTermainate[index].personnelLevelName,
          "กลุ่มงาน": tempTermainate[index].jobGroupName,
          "ตำแหน่งงาน": tempTermainate[index].positionsName,
          "บริษัท": tempTermainate[index].companyName,
          "ฝ่าย (Division)": tempTermainate[index].divisionName,
          "ส่วน (Department)": tempTermainate[index].departmentName,
          "แผนก (Section)": tempTermainate[index].sectionName,
          "วันที่เริ่มงาน": tempTermainate[index].hiringDate
            ? dayjs(tempTermainate[index].hiringDate).format("DD-MM-YYYY")
            : null,
          "Reporting Manager Level 1": tempTermainate[index].managerLV1Name,
          "Manager Position Level 1": tempTermainate[index].managerLV1Position,
          "Manager E-Mail Level 1": tempTermainate[index].managerLV1Email,
          "Reporting Manager Level 2": tempTermainate[index].managerLV2Name,
          "Manager Position Level 2": tempTermainate[index].managerLV2Position,
          "Manager E-Mail Level 2": tempTermainate[index].managerLV2Email,
          "ประเภทเวลาทำงาน": tempTermainate[index].workingType,
          "ประเภทกะ": tempTermainate[index].nameShiftGroup,
          "ชื่อกะ": tempTermainate[index].nameShift,
          "วันทำงาน": tempTermainate[index].workDay,
          "ประเภทการจ่าย": tempTermainate[index].paymentTypeName,
          "รอบการจ่าย": tempTermainate[index].paymentRound,
          "ค่าจ้าง": utils.numberWithCommas(tempTermainate[index].salary),
          "ค่าดำเนินการปกติ":
            tempTermainate[index].chargeSalary < 0.99
              ? tempTermainate[index].chargeSalary * 100
              : tempTermainate[index].chargeSalary,
          "ค่าดำเนินการ OT":
            tempTermainate[index].chargeOt < 0.99
              ? tempTermainate[index].chargeOt * 100
              : tempTermainate[index].chargeOt,
          "ค่าดำเนินการเหมา":
            tempTermainate[index].chargeHire < 0.99
              ? tempTermainate[index].chargeHire * 100
              : tempTermainate[index].chargeHire,
          "ค่าดำเนินการค่าชดเชย":
            tempTermainate[index].chargeCompensation < 0.99
              ? tempTermainate[index].chargeCompensation * 100
              : tempTermainate[index].chargeCompensation,
          "วันที่สิ้นสุดสัญญา": tempTermainate[index].contractTermainatoinDate
            ? dayjs(tempTermainate[index].contractTermainatoinDate).format("DD-MM-YYYY")
            : "",
          "OESY(Y)": tempTermainate[index]["OESY_Y"],
          "OESY(M)": tempTermainate[index]["OESY_M"],
          "OESY": tempTermainate[index].OESY,
          "Serv.(Y)": utils.getAgeNumber(
            dayjs(tempTermainate[index].hiringDate).format("DD-MM-YYYY")
          ).years,
          "Serv.(M)": utils.getAgeNumber(
            dayjs(tempTermainate[index].hiringDate).format("DD-MM-YYYY")
          ).months,
          "ServYr": `${
            utils.getAgeNumber(
              dayjs(tempTermainate[index].hiringDate).format("DD-MM-YYYY")
            ).years
          }/${
            utils.getAgeNumber(
              dayjs(tempTermainate[index].hiringDate).format("DD-MM-YYYY")
            ).months
          }`,
          "ESY(Y)": calculatorESY(tempTermainate[index]).years,
          "ESY(M)": calculatorESY(tempTermainate[index]).months,
          "ESY": calculatorESY(tempTermainate[index]).esy,
          "TIG(Y)": tempTermainate[index]["TIG(Y)"],
          "TIG(M)": tempTermainate[index]["TIG(M)"],
          "TIG": tempTermainate[index].TIG,
          "อายุ(ปี)": utils.getAgeNumber(
            dayjs(tempTermainate[index].birthday).format("DD-MM-YYYY")
          ).years,
          "อายุ(เดือน)": utils.getAgeNumber(
            dayjs(tempTermainate[index].birthday).format("DD-MM-YYYY")
          ).months,
          "อายุ": `${
            utils.getAgeNumber(dayjs(tempTermainate[index].birthday).format("DD-MM-YYYY")).years
          }/${
            utils.getAgeNumber(dayjs(tempTermainate[index].birthday).format("DD-MM-YYYY")).months
          }`,
          "(1) Education Degree": 
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[0].degree
              ? tempTermainate[index].education[0].degree
              : "",
          "(1) Education School ot University":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[0].university
              ? tempTermainate[index].education[0].university
              : "",
          "(1) Faculty":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[0].faculty
              ? tempTermainate[index].education[0].faculty
              : "",
          "(1) Major":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[0].major
              ? tempTermainate[index].education[0].major
              : "",
          "(1) From Year":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[0].fromYear
              ? tempTermainate[index].education[0].fromYear
              : "",
          "(1) End Year":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[0].endYear
              ? tempTermainate[index].education[0].endYear
              : "",
          "(1) GPA":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[0].gpa
              ? tempTermainate[index].education[0].gpa
              : "",
          "(2) Education Degree": 
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[1] &&
            tempTermainate[index].education[1].degree
              ? tempTermainate[index].education[1].degree
              : "",
          "(2) Education School ot University":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[1] &&
            tempTermainate[index].education[1].university
              ? tempTermainate[index].education[1].university
              : "",
          "(2) Faculty":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[1] &&
            tempTermainate[index].education[1].faculty
              ? tempTermainate[index].education[1].faculty
              : "",
          "(2) Major":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[1] &&
            tempTermainate[index].education[1].major
              ? tempTermainate[index].education[1].major
              : "",
          "(2) From Year":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[1] &&
            tempTermainate[index].education[1].fromYear
              ? tempTermainate[index].education[1].fromYear
              : "",
          "(2) End Year":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[1] &&
            tempTermainate[index].education[1].endYear
              ? tempTermainate[index].education[1].endYear
              : "",
          "(2) GPA":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[1] &&
            tempTermainate[index].education[1].gpa
              ? tempTermainate[index].education[1].gpa
              : "",
          "(3) Education Degree": 
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[2] &&
            tempTermainate[index].education[2].degree
              ? tempTermainate[index].education[2].degree
              : "",
          "(3) Education School ot University":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[2] &&
            tempTermainate[index].education[2].university
              ? tempTermainate[index].education[2].university
              : "",
          "(3) Faculty":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[2] &&
            tempTermainate[index].education[2].faculty
              ? tempTermainate[index].education[2].faculty
              : "",
          "(3) Major":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[2] &&
            tempTermainate[index].education[2].major
              ? tempTermainate[index].education[2].major
              : "",
          "(3) From Year":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[2] &&
            tempTermainate[index].education[2].fromYear
              ? tempTermainate[index].education[2].fromYear
              : "",
          "(3) End Year":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[2] &&
            tempTermainate[index].education[2].endYear
              ? tempTermainate[index].education[2].endYear
              : "",
          "(3) GPA":
            tempTermainate[index].education &&
            tempTermainate[index].education.length > 0 &&
            tempTermainate[index].education[2] &&
            tempTermainate[index].education[2].gpa
              ? tempTermainate[index].education[2].gpa
              : "",
          "Performance Review Year (Past 1 Year)": tempTermainate[index].performance1,
          "Performance Review Year (Past 2 Year)": tempTermainate[index].performance2,
          "Performance Review Year (Past 3 Year)": tempTermainate[index].performance3,
          "Performance Review Year (Past 4 Year)": tempTermainate[index].performance4,
          "Performance Review Year (Past 5 Year)": tempTermainate[index].performance5,
          "Cost Center Charge": tempTermainate[index].costCenterCharge,
          "Cost Element Charge": tempTermainate[index].costElementCharge,
          "IO": tempTermainate[index].io,
          "Owner": tempTermainate[index].vendorName,
          "บริษัทเจ้าของงาน": tempTermainate[index].companyCharge,
          "ชื่อธนาคาร": tempTermainate[index].bookBank,
          "สาขาธนาคาร": tempTermainate[index].bookBankBranch,
          "เลขที่บัญชี": tempTermainate[index].bookBankId,
          "Reportธนาคาร ชื่อธนาคาร": tempTermainate[index].reportBank_BankName,
          "Reportธนาคาร รหัสธนาคาร": tempTermainate[index].reportBank_BankId,
          "Reportธนาคาร เลขที่บัญชี": tempTermainate[index].reportBank_BookBankId,
          "Reportธนาคาร ชื่อผู้รับเงิน": tempTermainate[index].reportBank_Name,
          "Reportธนาคาร อ้างอิง": tempTermainate[index].reportBank_Ref
        };

        elementTermainateList.push(element);
      }
    } else {
      const element = {
        "รหัสพนักงาน": "",
        "คำนำหน้า": "",
        "ชื่อ": "",
        "สกุล": "",
        "Title": "",
        "Firstname": "",
        "Lastname": "",
        "ชื่อเล่น": "",
        "เพศ": "",
        "รหัสบัตรประชาชน": "",
        "สัญชาติ": "",
        "สถานะสมรส": "",
        "Passport Number": "",
        "Work Permit ID": "",
        "วันเกิด": "",
        "ที่อยู่": "",
        "บ้านเลขที่": "",
        "หมู่บ้าน": "",
        "หมู่ที่": "",
        "ซอย": "",
        "ถนน": "",
        "แขวง/ตำบล": "",
        "เขต/อำเภอ": "",
        "จังหวัด": "",
        "รหัสไปรษณีย์": "",
        "เบอร์โทรศัพท์": "",
        "Email": "",
        "ผู้ติดต่อฉุกเฉิน": "",
        "ผู้ติดต่อฉุกเฉินความสัมพันธ์": "",
        "ผู้ติดต่อฉุกเฉินเบอร์ติดต่อ": "",
        "ประเภทพนักงาน": "",
        "ประเภทการจ้าง": "",
        "สถานที่ทำงาน": "",
        "สถานที่ทำงานหลัก": "",
        "Filed/Office": "",
        "ระดับงาน": "",
        "ระดับพนักงาน": "",
        "กลุ่มงาน": "",
        "ตำแหน่งงาน": "",
        "บริษัท": "",
        "ฝ่าย (Division)": "",
        "ส่วน (Department)": "",
        "แผนก (Section)": "",
        "วันที่เริ่มงาน": "",
        "Reporting Manager Level 1": "",
        "Manager Position Level 1": "",
        "Manager E-Mail Level 1": "",
        "Reporting Manager Level 2": "",
        "Manager Position Level 2": "",
        "Manager E-Mail Level 2": "",
        "ประเภทเวลาทำงาน": "",
        "ประเภทกะ": "",
        "ชื่อกะ": "",
        "วันทำงาน": "",
        "ประเภทการจ่าย": "",
        "รอบการจ่าย": "",
        "ค่าจ้าง": "",
        "ค่าดำเนินการปกติ": "",
        "ค่าดำเนินการ OT": "",
        "ค่าดำเนินการเหมา": "",
        "ค่าดำเนินการค่าชดเชย": "",
        "วันที่สิ้นสุดสัญญา": "",
        "OESY(Y)": "",
        "OESY(M)": "",
        "OESY": "",
        "Serv.(Y)": "",
        "Serv.(M)": "",
        "ServYr": "",
        "ESY(Y)": "",
        "ESY(M)": "",
        "ESY": "",
        "TIG(Y)": "",
        "TIG(M)": "",
        "TIG": "",
        "อายุ(ปี)": "",
        "อายุ(เดือน)": "",
        "อายุ": "",
        "(1) Education Degree": "",
        "(1) Education School ot University": "",
        "(1) Faculty": "",
        "(1) Major": "",
        "(1) From Year": "",
        "(1) End Year": "",
        "(1) GPA": "",
        "(2) Education Degree": "",
        "(2) Education School ot University": "",
        "(2) Faculty": "",
        "(2) Major": "",
        "(2) From Year": "",
        "(2) End Year": "",
        "(2) GPA": "",
        "(3) Education Degree": "",
        "(3) Education School ot University": "",
        "(3) Faculty": "",
        "(3) Major": "",
        "(3) From Year": "",
        "(3) End Year": "",
        "(3) GPA": "",
        "Performance Review Year (Past 1 Year)": "",
        "Performance Review Year (Past 2 Year)": "",
        "Performance Review Year (Past 3 Year)": "",
        "Performance Review Year (Past 4 Year)": "",
        "Performance Review Year (Past 5 Year)": "",
        "Cost Center Charge": "",
        "Cost Element Charge": "",
        "IO": "",
        "Owner": "",
        "บริษัทเจ้าของงาน": "",
        "ชื่อธนาคาร": "",
        "สาขาธนาคาร": "",
        "เลขที่บัญชี": "",
        "Reportธนาคาร ชื่อธนาคาร": "",
        "Reportธนาคาร รหัสธนาคาร": "",
        "Reportธนาคาร เลขที่บัญชี": "",
        "Reportธนาคาร ชื่อผู้รับเงิน": "",
        "Reportธนาคาร อ้างอิง": ""
      };

      elementTermainateList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    const wsTermainate = XLSX.utils.json_to_sheet(elementTermainateList);

    // console.log(downloadHeaderExcel);

    // for (const [key, value] of Object.entries(downloadHeaderExcel)) {
    //   console.log(`${key}: ${value}`);
    //   ws[key].v = value;
    // }

    // console.log(ws);

    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "PIS Master");
    XLSX.utils.book_append_sheet(workbook, wsTermainate, "Terminate");
    XLSX.writeFile(
      workbook,
      `PIS Master-${
        selectedVendor === -1
          ? "All Company"
          : companyContract.find((item) => item.idVendor === selectedVendor).vendorName
      }.xlsx`,
      { type: "file" }
    );
  };

  return (
    <DrawerCustom
      title={"ดาวน์โหลดข้อมูล"}
      anchor={"right"}
      open={open}
      onClose={toggleDrawer(false)}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {companyContract && (
              <div>
                <StyleSelect fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    เลือกบริษัท
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="company-list"
                    label="เลือกบริษัท"
                    onChange={handleChange}
                    value={selectedVendor}
                  >
                    <MenuItem value={-1}>{`ทุกบริษัท`}</MenuItem>
                    {companyContract.map((item) => (
                      <MenuItem value={item.idVendor}>
                        {item.vendorName}
                      </MenuItem>
                    ))}
                  </Select>
                </StyleSelect>
              </div>
            )}
          </Grid>
        </Grid>
        <div className="btn-action">
          <ButtonBlue variant="contained" onClick={excelExport}>
            ดาวน์โหลดข้อมูล
          </ButtonBlue>
        </div>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogDownload;
