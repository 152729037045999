import React, { useState } from "react";
import { IconButton, Menu, MenuItem, ListItemText, ListItemIcon } from "@mui/material";
import { styled } from "@mui/material/styles";
import TranslateIcon from "@mui/icons-material/Translate";
import { useTranslation } from "react-i18next";

import thaiIcon from "../../pages/assets/thai.png"
import engIcon from "../../pages/assets/eng.png"

function Translate() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const { t, i18n } = useTranslation();

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
    handleClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuTrans = "primary-search-account-menu";
  const renderTransMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuTrans}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        style: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        style={{
          paddingLeft: "40px !important",
          paddingRight: "40px !important",
        }}
        onClick={() => handleChangeLang("th")}
      >
        <ListItemIcon>
          <img width="20" src={thaiIcon} alt="thai" />
        </ListItemIcon>
        <ListItemText>ภาษาไทย</ListItemText>
      </MenuItem>
      <MenuItem
        style={{
          paddingLeft: "40px !important",
          paddingRight: "40px !important",
        }}
        onClick={() => handleChangeLang("en")}
      >
        <ListItemIcon>
          <img width="20" src={engIcon} alt="eng" />
        </ListItemIcon>
        <ListItemText>English</ListItemText>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={menuTrans}
        aria-haspopup="true"
        color="inherit"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="large"
      >
        <TranslateIcon fontSize="small" sx={{ color: "#9e9e9e" }} />
      </IconButton>
      {renderTransMenu}
    </>
  );
}

export default Translate;
