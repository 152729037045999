import React, { Fragment } from "react";
import DataGrid, { Button, Column, Export, FilterRow, HeaderFilter, Paging, Scrolling, SearchPanel, Selection, Sorting } from "devextreme-react/data-grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

const TableAll = (props) => {

  const { handleClickIndividual, ReportSummaryOvertimeShiftList, isFetching } = props;
  const { t, i18n } = useTranslation();

  let gridColumnsOverTime = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneAmount",
      caption: `OT 1 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otOneFiveAmount",
      caption: `OT 1.5 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otTwoAmount",
      caption: `OT 2 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otThreeAmount",
      caption: `OT 3 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otHoursTotal",
      caption: `OT ${t("Total")} (${t("Unit.Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
    {
      dataField: "otAmountTotal",
      caption: `OT ${t("Total")} (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
      width: 120,
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "morningShiftFee",
      caption: `${t("ShiftPayMorning")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
    {
      dataField: "afternoonShiftFee",
      caption: `${t("ShiftPayAfternoon")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
    {
      dataField: "nightShiftFee",
      caption: `${t("ShiftPayNight")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftFeeTotal",
      caption: `${t("ShiftPayTotal")}`,
      format: "###,###,##0.00",
      cssClass: "column-shift",
    },
  ];

  return (
    <Fragment>
      {!isFetching ? (
        <DataGrid
          dataSource={ReportSummaryOvertimeShiftList ? ReportSummaryOvertimeShiftList : []}
          showBorders={true}
          showColumnLines={true}
          rowAlternationEnabled={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          columnResizingMode='widget'
        >

          <Column
            type="buttons"
            width={60}
            alignment="center"
            cssClass="column-info"
          >
            <Button
              icon="fa-regular fa-calendar-circle-user"
              onClick={handleClickIndividual}
            />
          </Column>

          <Column
            caption={`${t("EmployeeID")}`}
            dataField="employeeId"
            dataType="string"
            width={150}
            cssClass="column-info"
          />

          <Column
            caption={`${t("FullName")}`}
            dataType="string"
            cssClass="column-info"
            dataField={i18n.resolvedLanguage === "en" ? "fullname_en" : "fullname"}
          >
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column
            dataField={i18n.resolvedLanguage === "en" ? "businessUnitName_EN" : "businessUnitName"}
            caption={`${t("BusinessUnit")}`}
            dataType="string"
            cssClass="column-info"
          />

          <Column
            dataField={i18n.resolvedLanguage === "en" ? "divisionName_EN" : "divisionName"}
            caption={`${t("Division")}`}
            dataType="string"
            cssClass="column-info"
          />

          <Column
            dataField={i18n.resolvedLanguage === "en" ? "departmentName_EN" : "departmentName"}
            caption={`${t("Department")}`}
            dataType="string"
            cssClass="column-info"
          />

          <Column
            dataField={i18n.resolvedLanguage === "en" ? "sectionName_EN" : "sectionName"}
            caption={`${t("Section")}`}
            dataType="string"
            cssClass="column-info"
          />

          <Column
            dataField={i18n.resolvedLanguage === "en" ? "positionName_EN" : "positionName"}
            caption={`${t("Position")}`}
            dataType="string"
            cssClass="column-info"
          />

          <Column
            dataField="workingType"
            caption={`${t("WorkingType")}`}
            dataType="string"
            width={150}
            cssClass="column-info"
          />

          <Column
            dataField="paymentTypeName"
            caption={`${t("PaymentTypeName")}`}
            dataType="string"
            width={150}
            cssClass="column-info"
          />

          <Column
            dataField="paymentRoundName"
            caption={`${t("PaymentRound")}`}
            dataType="string"
            width={150}
            cssClass="column-info"
          />

          <Column
            dataField="totalWorkingHours"
            caption={`จำนวนชั่วโมงทำงานในวันปกติ`}
            dataType="string"
            format="###,###,##0"
            cssClass="column-woring-hours"
            width={120}
          />

          {gridColumnsOverTime.map((item, index) => (
            <Column
              key={`overtime_${index}`}
              dataField={item.dataField}
              caption={item.caption}
              format={item.format}
              cssClass="column-total-addition"
            />
          ))}

          {gridColumnsShift.map((item, key) => (
            <Column
              key={`shift_${key}`}
              dataField={item.dataField}
              caption={item.caption}
              format={item.format}
              cssClass={item.cssClass}
            />
          ))}

          <Export enabled={true} allowExportSelectedData={true} />
          <Selection mode="single" />
          <Sorting mode="multiple" />
          <Scrolling columnRenderingMode="virtual" />
          <Paging defaultPageSize={20} />
          <FilterRow visible={false} />
          <HeaderFilter visible={true} />
          <SearchPanel
            visible={true}
            width={240}
            placeholder={`${t("Search")}`}
          />
        </DataGrid>) : (

        <Typography textAlign="center" color="text.secondary" fontSize="24px">
          {t(`LoadingData`)}...
        </Typography>
      )}
    </Fragment>
  )
}

export default TableAll;