import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

// import { getAllCompanysByIdVendor, getCompanyBranch } from "../../../../actions/company";
import { getCompanyAndBranchContract } from "../../../../actions/vendor";

const PaperStyled = styled("div")({
  width: "100%",
  height: "calc(100vh - 80px)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SelectStyled = styled(Select)(({ value }) => ({
  color: value === "unselected" ? "darkgrey" : "initial",
}));

const ChoosingCompanyAndBranch = (props) => {
  let { openModal, setOpenModal, company, setCompany, branch, setBranch } =
    props;
  let dispatch = useDispatch();
  let { result: UserProfile } = useSelector((state) => state.userProfile);
  let { result: AllCompanysAndBranches } = useSelector(
    (state) => state.vendorContract
  );
  let {
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
    getValues,
  } = useForm();

  useEffect(() => {
    if (UserProfile) {
      dispatch(getCompanyAndBranchContract());
    }
  }, [UserProfile]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const HandleSubmitForm = (data) => {
    console.log("data : ", data);
    if (data.company === "unselected") {
      setError("company", { type: "manual" });
    } else {
      setCompany(data.company);
      if (data.branch !== "unselected") {
        setBranch(data.branch);
      }
      handleClose();
    }
  };

  return (
    <Fragment>
      {openModal && (
        <PaperStyled>
          <form
            onSubmit={handleSubmit(HandleSubmitForm)}
            style={{
              width: "100%",
              maxWidth: "800px",
              padding: "20px",
              marginBottom: "300px",
            }}
          >
            <Grid container spacing={2}>
              <Grid container item xs={12} md={6} spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{
                      color: errors && errors.company ? "#d32f2f" : "initial",
                    }}
                  >
                    บริษัท
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth error={errors && errors.company}>
                    <Controller
                      name="company"
                      control={control}
                      defaultValue="unselected"
                      render={({ field }) => (
                        <SelectStyled {...field}>
                          <MenuItem value="unselected" disabled>
                            เลือกบริษัท
                          </MenuItem>
                          {AllCompanysAndBranches &&
                            AllCompanysAndBranches.length > 0 &&
                            AllCompanysAndBranches.map((company) => (
                              <MenuItem
                                key={company.idCompany}
                                value={company.idCompany}
                              >
                                {company.companyName}
                              </MenuItem>
                            ))}
                        </SelectStyled>
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">สาขา</Typography>
                </Grid>
                <Grid item xs={12}>
                  {watch("company") && (
                    <FormControl fullWidth>
                      <Controller
                        name="branch"
                        control={control}
                        defaultValue="unselected"
                        render={({ field }) => (
                          <SelectStyled {...field}>
                            <MenuItem value="unselected" disabled>
                              เลือกสาขา
                            </MenuItem>
                            {AllCompanysAndBranches &&
                              AllCompanysAndBranches.length > 0 &&
                              AllCompanysAndBranches.map((company) => {
                                if (
                                  company.idCompany === getValues("company")
                                ) {
                                  return company.branch.map((branch) => {
                                    return (
                                      <MenuItem
                                        value={branch.idBranch}
                                        key={branch.idBranch}
                                      >
                                        {branch.branchName}
                                      </MenuItem>
                                    );
                                  });
                                }
                              })}
                          </SelectStyled>
                        )}
                      />
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <Button type="submit" variant="contained">
                <Typography variant="h6" style={{ color: "white" }}>
                  ตกลง
                </Typography>
              </Button>
            </Box>
          </form>
        </PaperStyled>
      )}
      {!openModal && (
        <div
          style={{
            marginTop: "10px",
            marginBottom: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" style={{ color: "#1565c0" }}>
            {`${
              AllCompanysAndBranches.find((item) => item.idCompany === company)
                .companyName
            }${branch ? `,` : ""}`}
          </Typography>
          {branch && (
            <Typography variant="h6" style={{ color: "#1565c0" }}>
              {`${
                AllCompanysAndBranches.find(
                  (item) => item.idCompany === company
                ).branch.find((item) => item.idBranch === branch).branchName
              }`}
            </Typography>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ChoosingCompanyAndBranch;
