import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, IconButton, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import EditIcon from "@mui/icons-material/Edit";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { deleteEmployeeShiftChange, getEmployeeProfile, getEmployeeShiftChange } from "../../../../../../actions/employee";
import DialogEdit from "./DialogEdit";
import { getShift } from "../../../../../../actions/shift";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";

import MenuShiftChangeType from "./menuShiftChangeType";
import HistoryEmployeeShiftPanel from "./historyEmployeeShiftPanel";
import HistoryEmployeeDailyShiftPanel from "./historyEmployeeDailyShiftPanel";

const StyledRoot = styled(Box)({

})

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const ShiftChange = () => {

  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: employeeShift } = useSelector((state) => state.employeeShift);
  const { t, i18n } = useTranslation();
  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  })
  const [deleteConfig, setDeleteConfig] = useState({
    isOpen: false,
    data: {}
  })
  const [isDisableEdit, setIsDisableEdit] = useState(true);

  const [selectedShiftChangeType, setSelectedShiftChangeType] = useState(1)

  const onDrawerClose = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const onDrawerOpen = (data, isEdit) => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
      isEdit: isEdit || false,
      data: data? {...data}: {}
    }))
  }

  const onDeleteClose = () => {
    setDeleteConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleDeleteConfirm = async () => {
    const result = await dispatch(deleteEmployeeShiftChange(deleteConfig.data.idEmployeeShift))

    if(result){
      onDeleteClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmp))
    }
  }

  useEffect(() => {
    if (employeeProfile) {
      //dispatch(getCompanyOrganization(employeeProfile.idCompany));
      dispatch(getEmployeeShiftChange(employeeProfile.idEmp));
      dispatch(getShift(employeeProfile.idCompany));
      // dispatch(getCompanyContract());
    }
  }, [employeeProfile]);

  useEffect(() => {
    if (employeeShift && employeeShift.length > 0) {
      if (
        dayjs(employeeShift[employeeShift.length - 1].startDate).isSameOrAfter(dayjs().toDate(), "date")
      ) {
        setIsDisableEdit(true);
      } else {
        setIsDisableEdit(false);
      }
    }
  }, [employeeShift])

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonBlue
            size="small"
            startIcon={<EditIcon />}
            disabled={isDisableEdit}
            onClick={() => {onDrawerOpen()}}
          >
            {t("Edit")}
          </ButtonBlue>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">{t('WorkingType')}</StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.workingType? String(employeeProfile.workingType).toUpperCase(): "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">{t('ShiftType')}</StyledHeadLabel>
          <Typography variant="h6">{employeeProfile.shift? employeeProfile.shift.nameShiftGroup: "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">{t('ShiftName')}</StyledHeadLabel>
          <Typography variant="h6">{employeeProfile.shift? employeeProfile.shift.nameShift: "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">{t('WorkDay')}</StyledHeadLabel>
          <Typography variant="h6">{employeeProfile.shift? employeeProfile.shift.workDay: "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">{t('WorkTimePerDay')}</StyledHeadLabel>
          <Typography variant="h6">{employeeProfile.shift && employeeProfile.shift.workingHours? `${Number(employeeProfile.shift.workingHours/60) - (employeeProfile.shift.breakTime === 0? 1: 0)} ${t('Hrs')}/${t('Day')}`: "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">{t('WorkTimeInTimeOut')}</StyledHeadLabel>
          <Typography variant="h6">{employeeProfile.shift && employeeProfile.shift.timeIn && employeeProfile.shift.timeOut
            ? `${dayjs(employeeProfile.shift.timeIn, "HH:mm:ss").format("HH:mm")} - ${dayjs(employeeProfile.shift.timeOut, "HH:mm:ss").format("HH:mm")}`
            : t('NoWorkingTimeFrame')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
        <Grid item xs={6}>
          <StyledHeadLabel color="text.secondary">{t('ChangeLog')}</StyledHeadLabel>
        </Grid>
        
        <Grid item xs={6} container justifyContent="flex-end">
          <MenuShiftChangeType selectedShiftChangeType={selectedShiftChangeType} setSelectedShiftChangeType={setSelectedShiftChangeType}/>
        </Grid>
        
        <Grid item xs={12}>
          {selectedShiftChangeType === 1 && (<HistoryEmployeeShiftPanel />)}
          {selectedShiftChangeType === 2 && (<HistoryEmployeeShiftPanel isTemporary/>)}
          {selectedShiftChangeType === 3 && (<HistoryEmployeeDailyShiftPanel />)}
        </Grid>


        {/* {employeeShift && employeeShift.map((item, index) => (
          <Grid item xs={12}>
            <Box display="flex" gap="8px">
              <Box flexShrink="0">
                <IconButton
                  size="small"
                  disabled={(index < employeeShift.length - 1) || (employeeShift.length === 1)}
                  onClick={() => {
                    setDeleteConfig(prev => ({
                      ...prev,
                      isOpen: true,
                      data: {
                        idEmployeeShift: item.idEmployeeShift,
                        nameShift: item.nameShift,
                        nameShiftGroup: item.nameShiftGroup,
                      }
                    }))
                  }}
                >
                  <HighlightOffRoundedIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  size="small"
                  disabled={index < employeeShift.length - 1}
                  onClick={() => {
                    onDrawerOpen({
                      idEmployeeShift: item.idEmployeeShift,
                      idShift: item.idShift,
                      remark: item.remark,
                      startDate: item.startDate,
                    }, true)
                  }}
                >
                  <EditNoteRoundedIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Box flexGrow="1">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box display="flex" gap="8px" flexWrap="wrap">
                      <Typography color="text.third" fontWeight="600">
                        [{dayjs(item.startDate).locale(i18n.language).format(`DD MMM ${i18n.language === "th"? "BBBB": "YYYY"}`)} - {item.endDate? dayjs(item.endDate).locale(i18n.language).format(`DD MMM ${i18n.language === "th"? "BBBB": "YYYY"}`): t('Current')}]
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel color="text.secondary">{t('ShiftType')}</StyledHeadLabel>
                    <Typography variant="h6">
                      {item.nameShiftGroup? item.nameShiftGroup: "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel color="text.secondary">{t('ShiftName')}</StyledHeadLabel>
                    <Typography variant="h6">
                      {item.nameShift? item.nameShift: "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel color="text.secondary">{t('WorkTimeInTimeOut')}</StyledHeadLabel>
                    <Typography variant="h6">
                      {item.timeIn && item.timeOut
                        ? `${dayjs(item.timeIn, "HH:mm:ss").format("HH:mm")} - ${dayjs(item.timeOut, "HH:mm:ss").format("HH:mm")}`
                        : t('NoWorkingTimeFrame')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledHeadLabel color="text.secondary">{t('WorkTimePerDay')}</StyledHeadLabel>
                    <Typography variant="h6">
                      {item.workingHours? `${Number(item.workingHours/60) - (item.breakTime === 0? 1: 0)} ${t('Hrs')}/${t('Day')}`: "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="text.secondary" fontStyle="italic" whiteSpace="pre-line" style={{wordBreak: "break-word"}}>{t('Reason')}: {item.remark? item.remark: "-"}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        ))} */}
      </Grid>

      <DialogEdit
        drawerConfig={drawerConfig}
        onClose={onDrawerClose}
      />

      <DialogConfirmDelete
        open={deleteConfig.isOpen}
        handleClose={onDeleteClose}
        label="เวลาทำงาน"
        text={
          <Box>
            <Typography fontSize="14px" fontWeight="600" style={{color: "#f15e5e"}}>{deleteConfig.data.nameShiftGroup}</Typography>
            <Typography fontSize="18px" style={{color: "#f15e5e"}}>{deleteConfig.data.nameShift}</Typography>
          </Box>
        }
        handleDelete={handleDeleteConfirm}
      />
    </StyledRoot>
  )
}

export default ShiftChange;