import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";

const ComfirmDialog = (props) => {
  const { t, i18n } = useTranslation();
  const { title, description, open, handleOnClick, handleClose, id } = props;

  const handleOnClickSubmit = () => {
    handleOnClick();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          {t("Cancle")}
        </Button>
        <ButtonBlue
          onClick={() => {
            handleOnClickSubmit();
          }}
        >
          {t("Confirm")}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default ComfirmDialog;
