import React from "react";
import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import { Typography } from "@mui/material";

import TableList from "./tableList"

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
});

const BillingCollectedPage = (props) => {

  const onClick = (id,selectedMonthYear) => {
    props.history.push(`/admin/bills-collected/billingDetails/${id}`, {
      selectedMonthYear: selectedMonthYear,
    })
  }
  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          รายการบิล
        </Typography>
        <TableList onClick={onClick} />
      </Container>
    </StyledRoot>
  );
};

export default BillingCollectedPage;
