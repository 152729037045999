import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  Avatar,
  Container,
  Grid,
  Paper,
  styled,
  Typography,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import employeesData from "../../../assets/data/employees";

import { getEmployeeProfile } from "../../../../../actions/employee";
import { getUserProfile } from "../../../../../actions/user";

import LeftPanel from "./left";
import RightPanel from "./right";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

// const StyledCover = styled("div")({
//   width: "100%",
//   height: 250,
//   background: `linear-gradient(rgba(22, 28, 36, 0.88), rgba(22, 28, 36, 0.88)) center center / cover no-repeat, url(${BgCover})`
// });

const EmployeeProfilePage = (props) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  let { idEmp } = useParams();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userProfile) {
      dispatch(getEmployeeProfile(idEmp, "manager"));
    }
  }, [userProfile]);

  return (
    <StyledRoot className={`page`}>
      {/* <StyledCover /> */}
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <LeftPanel />
          </Grid>
          <Grid item xs={12} sm={8}>
            <RightPanel data={employeesData[0]} />
          </Grid>
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default EmployeeProfilePage;
