import React, { Fragment, useEffect, useState } from "react";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import { useTranslation } from "react-i18next";
import {
  deleteVendorCompanyLeaveType,
  getAllVendorCompanyLeaveType,
} from "../../../../../../actions/leave";
import { useDispatch, useSelector } from "react-redux";
import { companyEmployeeType } from "../../../../../../actions/company";
import { getJobLevlByidCompany } from "../../../../../../actions/jobLevel";

const AccordionStyled = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummaryStyled = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionRow = (props) => {
  const { data, setDrawerConfig, setAlertType, setOpenAlert, selectedCompany } =
    props;
  const { result: jobLevel } = useSelector((state) => state.jobLevel);
  const { result: employeeType } = useSelector((state) => state.employeeType);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const [isExpand, setIsExpand] = useState(false);
  const { t, i18n } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    dispatch(companyEmployeeType(selectedCompany.idCompany));
    dispatch(getJobLevlByidCompany(selectedCompany.idCompany));
  }, []);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDeleteLeave = async () => {
    const response = await dispatch(
      deleteVendorCompanyLeaveType(data.idLeaveType)
    );
    if (response.status === 200) {
      setAlertType("success");
      let result = await dispatch(
        getAllVendorCompanyLeaveType({
          idCompany: selectedCompany.idCompany,
          idVendor: userProfile.idVendor,
        })
      );
      handleCloseDialog();
    } else {
      setAlertType("error");
    }
    setOpenAlert(true);
  };

  const getEmployeeTypeName = (list) => {
    const EmployeeTypeMap = new Map();
    list.map((item) => {
      if (data.employeeTypes.includes(item.idEmployeeType)) {
        EmployeeTypeMap.set(
          item.idEmployeeType,
          item.employeeTypeName || t("All")
        );
      }
    });
    if (EmployeeTypeMap.size >= 1) {
      return Array.from(EmployeeTypeMap, ([_, value]) => value).join(", ");
    } else {
      return t("All");
    }
  };

  const getJobLevelName = (list) => {
    const JobLevelMap = new Map();
    list.map((item) => {
      if (data.jobLevels.includes(item.idJobLevel)) {
        JobLevelMap.set(item.idJobLevel, item.jobLevelName || t("All"));
      }
    });
    if (JobLevelMap.size >= 1) {
      return Array.from(JobLevelMap, ([_, value]) => value).join(", ");
    } else {
      return t("All");
    }
  };

  return (
    <AccordionStyled
      expanded={isExpand}
      onChange={() => {
        setIsExpand((prev) => !prev);
      }}
    >
      <AccordionSummaryStyled>
        <Box flexGrow="1" display="flex" alignItems="center">
          <Typography flexGrow="1">
            {data.name} {data.name_EN ? `(${data.name_EN})` : ""}
          </Typography>

          <Box>
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                console.log("data: ", data)
                e.stopPropagation();
                setDrawerConfig((prev) => ({
                  ...prev,
                  isOpen: true,
                  isEdit: true,
                  data: {
                    ...data,
                  },
                }));
              }}
            >
              <EditNoteRoundedIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={(e) => {
                e.stopPropagation();
                setOpenDialog(true);
              }}
            >
              <DeleteOutlineRoundedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </AccordionSummaryStyled>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth={"xs"}
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{t("DeleteLeave")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("DoUWantDelete")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t("Cancel")}</Button>
          <Button onClick={handleConfirmDeleteLeave} autoFocus color="error">
            {t("ConfirmDelete")}
          </Button>
        </DialogActions>
      </Dialog>
      {employeeType && jobLevel && (
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item container spacing={2}>
              <Grid key={`leaveType_${data.idLeaveType}`} item xs={12}></Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("EmployeeType")}
                </Typography>
                <Typography>{getEmployeeTypeName(employeeType)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("JobLevel")}
                </Typography>
                <Typography>{getJobLevelName(jobLevel)}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("TotalLeaveDays")}
                </Typography>
                <Typography>
                  {data.isLeaveStep
                    ? t("OnCondition")
                    : data.leaveValue === null
                    ? t("Unlimited")
                    : `${data.leaveValue} ${t("Days")}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("AccumulateNextRoundNotMoreThan")}
                </Typography>
                <Typography>
                  {data.isLeaveStep
                    ? t("OnCondition")
                    : data.carryValue === null
                    ? t("Unlimited")
                    : `${data.carryValue} ${t("Days")}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("Pay")}
                </Typography>
                <Typography>{data.isPaid ? t("Yes") : t("No")}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("CanRequestLeaveAfter")}
                </Typography>
                {data.canRequestAfter === null
                  ? `${t("StartWork")}`
                  : data.canRequestAfter < 365
                  ? ` ${data.canRequestAfter} ${t("Days")}`
                  : `${t("YearOfService")} ${data.canRequestAfter / 365} ${t(
                      "Year"
                    )}`}
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("Gender")}
                </Typography>
                <Typography>
                  {data.gender ? `${data.gender}` : `${t("AllGender")}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("MinimumLeaveAllowed")}
                </Typography>
                <Typography>
                  {data.minLeave === 100
                    ? `${t("FullDay")}`
                    : data.minLeave === 50
                    ? `${t("HalfDay")}`
                    : data.minLeave === 10
                    ? `${t("Hour")}`
                    : data.minLeave === 5
                    ? `${t("HalfHour")}`
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  Prorate
                </Typography>
                <Typography>{data.isProRate ? t("Yes") : t("No")}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >{`${t("Rounding")} Prorate`}</Typography>
                <Typography>
                  {data.proRateRound === 1
                    ? t("ThereIsNoRounding")
                    : data.proRateRound === 0.5
                    ? `${t("RoundWhenGreaterThan")} 0.5`
                    : data.proRateRound === 0
                    ? t("RoundUpInAllCases")
                    : "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  {t("LeaveIn")}
                </Typography>
                <Typography>
                  {data.dayInAdvance
                    ? `${data.dayInAdvance} ${t("Days")}`
                    : t("NotSpecified")}
                </Typography>
              </Grid>
              {data.isLeaveStep === 1 &&
                data.steps &&
                data.steps.length &&
                data.steps.length > 0 && (
                  <Grid item xs={12}>
                    <Typography
                      fontSize="18px"
                      fontWeight="500"
                      marginBottom="8px"
                    >
                      {t("Condition")}
                    </Typography>
                    <Grid container spacing={2}>
                      {data.steps.map((step) => (
                        <Fragment>
                          <Grid item xs={6} md={4}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >
                              {t("YearOfService")}
                            </Typography>
                            <Typography>{`${step.lessThanYear}${
                              step.moreThanYears === 99
                                ? " ปีขึ้นไป"
                                : ` - ${step.moreThanYears} ${t("Year")}`
                            }`}</Typography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >
                              {t("TotalLeaveDays")}
                            </Typography>
                            <Typography>{step.leaveValue}</Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color="text.secondary"
                            >
                              {t("AccumulateNextRoundNotMoreThan")}
                            </Typography>
                            <Typography>{step.carryValue}</Typography>
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>
                  </Grid>
                )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      )}
    </AccordionStyled>
  );
};

export default AccordionRow;
