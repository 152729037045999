import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import TableRequest from "./table";

const TimeAttendance = (props) => {
  const { searchName } = props;
  const { t } = useTranslation();
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const [dataTableWaiting, setDataTableWaiting] = useState(null);

  useEffect(() => {
    if (requestTimeList) {
      setDataTableWaiting(
        requestTimeList.filter((x) => {
          if(
            x.idRequestType === 1 && x.isActive === 1 && (
              (x.isDoubleApproval === 1 && (x.isManagerLV1Approve === null || x.isManagerLV2Approve === null)) ||
              (x.approvalLevel === 1 && x.isManagerLV1Approve === null) ||
              (x.approvalLevel === 2 &&  x.isManagerLV2Approve === null)
            ) && (
              (searchName && searchName.length > 0) ? 
                (
                  x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
                  x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
                )
                : true
            )
          ){
            return true;
          }else{
            return false;
          }
        })
      );
    }
  }, [requestTimeList, searchName]);

  return (
    <Fragment>
      <div style={{ width:"100%", display:"flex", justifyContent:"flex-end", marginBottom: 16 }}>
        <Typography style={{ fontSize: 20, fontWeight: 500, color: "#e46a76" }}>
          {`${(dataTableWaiting && dataTableWaiting.length) || 0} ${t("Items")}`}
        </Typography>
      </div>
      {requestTimeList && dataTableWaiting && (
        <TableRequest data={dataTableWaiting} searchName={searchName} />
      )}
    </Fragment>
  );
};

export default TimeAttendance;
