import React, { Fragment, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  deleteManagerGroup,
  getManagerGroup,
} from "../../../../../../actions/manager";
import DialogConfirmDelete from "../../../../shared/general/DialogConfirmDelete";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20p #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  padding: 16,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const ManagerGroupContainer = (props) => {
  const { setDrawerGroupManagerConfig } = props;
  const dispatch = useDispatch();
  const { company } = props;
  const { result: managerGroup } = useSelector((state) => state.managerGroup);

  const [deleteManagerGroupConfig, setDeleteManagerGroupConfig] = useState({
    isOpen: false,
    data: {},
  });

  const handleCloseDeleteManagerGroupModal = () => {
    setDeleteManagerGroupConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleDeleteManagerGroup = async () => {
    // const result = null;
    const result = await dispatch(
      deleteManagerGroup(deleteManagerGroupConfig.data.idManagerGroup)
    );

    if (result) {
      if (result.status === 200) {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "success",
        //   message: "บันทึกข้อมูลสำเร็จ"
        // }))
        dispatch(getManagerGroup(company.idCompany));
        handleCloseDeleteManagerGroupModal();
      } else {
        // setAlertConfig(prev => ({
        //   ...prev,
        //   isOpen: true,
        //   type: "error",
        //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
        // }))
      }
    } else {
      // setAlertConfig(prev => ({
      //   ...prev,
      //   isOpen: true,
      //   type: "error",
      //   message: "เกิดข้อผิดพลาด กรุณาติดต่อผู้ดูแล"
      // }))
    }
  };

  return (
    <Fragment>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledCellHeader>ชื่อกลุ่มหัวหน้างาน</StyledCellHeader>
              <StyledCellHeader>จำนวนหัวหน้างาน</StyledCellHeader>
              <StyledCellHeader
                style={{ maxWidth: "50px", width: "50px" }}
              ></StyledCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {managerGroup &&
              managerGroup.map((item, index) => (
                <ManagerListRow
                  key={index}
                  row={item}
                  setDeleteManagerGroupConfig={setDeleteManagerGroupConfig}
                  setDrawerGroupManagerConfig={setDrawerGroupManagerConfig}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {managerGroup && managerGroup.length === 0 && (
        <Typography
          padding="24px 0"
          fontSize="20px"
          color="text.secondary"
          fontStyle="italic"
          textAlign="center"
        >
          ไม่มีกลุ่มหัวหน้างาน
        </Typography>
      )}
      <DialogConfirmDelete
        open={deleteManagerGroupConfig.isOpen}
        handleClose={handleCloseDeleteManagerGroupModal}
        label={"กลุ่มหัวหน้างาน"}
        text={
          <Box>
            <Typography
              fontSize="14px"
              fontWeight="600"
              style={{ color: "#f15e5e" }}
            >
              {deleteManagerGroupConfig.data.managerGroupName}
            </Typography>
          </Box>
        }
        handleDelete={handleDeleteManagerGroup}
      />
    </Fragment>
  );
};

const ManagerListRow = (props) => {
  const { row, setDeleteManagerGroupConfig, setDrawerGroupManagerConfig } =
    props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <TableRow
        onClick={() => setIsOpen(!isOpen)}
        style={{ userSelect: "none", cursor: "pointer" }}
      >
        <StyledCellContent>
          <Box display="flex" alignItems="center" gap="8px">
            <Box>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteManagerGroupConfig((prev) => ({
                    ...prev,
                    isOpen: true,
                    data: {
                      idManagerGroup: row.idManagerGroup,
                      managerGroupName: row.managerGroupName,
                    },
                  }));
                }}
              >
                <DeleteOutlineRoundedIcon />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setDrawerGroupManagerConfig((prev) => ({
                    ...prev,
                    isOpen: true,
                    isEdit: true,
                    data: {
                      idManagerGroup: row.idManagerGroup,
                      managerGroupName: row.managerGroupName,
                      managerList: row.managerList,
                    },
                  }));
                }}
              >
                <ModeEditIcon />
              </IconButton>
            </Box>
            <Typography lineHeight="1">{row.managerGroupName}</Typography>
          </Box>
        </StyledCellContent>
        <StyledCellContent>{row.managerList.length}</StyledCellContent>
        <StyledCellContent style={{ maxWidth: "50px", width: "50px" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledCellContent>
      </TableRow>
      <TableRow>
        <StyledCellContent
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={3}
        >
          <Collapse in={isOpen}>
            <Box>
              <Typography fontSize="18px" fontWeight="500" paddingBottom="16px">
                รายชื่อหัวหน้างานในกลุ่ม
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledCellHeader>ชื่อหัวหน้างาน</StyledCellHeader>
                    <StyledCellHeader>อีเมล</StyledCellHeader>
                    <StyledCellHeader
                      style={{ maxWidth: "50px", width: "50px" }}
                    ></StyledCellHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row &&
                    row.managerList &&
                    row.managerList.map((item, index) => (
                      <TableRow key={`manager-list-${index}`}>
                        <StyledCellContent>
                          {item.managerName}
                        </StyledCellContent>
                        <StyledCellContent>{item.email}</StyledCellContent>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {row.managerList.length === 0 && (
                <Typography
                  fontSize="20px"
                  color="text.secondary"
                  fontStyle="italic"
                  textAlign="center"
                  paddingTop="16px"
                >
                  ไม่มีหัวหน้างาน
                </Typography>
              )}
            </Box>
          </Collapse>
        </StyledCellContent>
      </TableRow>
    </Fragment>
  );
};

export default ManagerGroupContainer;
