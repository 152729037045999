import React, { Fragment, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Box,
  FormControl,
  Grid,
  Select,
  Avatar,
  MenuItem,
  Chip,
  Badge,
  InputAdornment,
} from "@mui/material";


import TextFieldTheme from "../../shared/general/TextFieldTheme";

import SelectedEmployees from "./select-employee";
import CardStyle from "../../shared/general/Card";
import DialogChangeSpare from "../../shared/general/DialogChangeSpare";
import AlertResponse from "../../shared/general/AlertResponse";
import { Search } from "@mui/icons-material";
import {
  updateEmployeeInCointract,
  getAllContractEmployees
} from "../../../../actions/contract-employees";



const StyledContentLabel = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledAvatar = styled(Avatar)({
  width: 50,
  height: 50,
  marginRight: 8,
});

const StyledCardStyle = styled(CardStyle)({
  padding: 32,
  "& .count": {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 0,
    marginBottom: 16,
  },
  "& .content": {
    overflow: "auto",
    height: 450,
  },
  "& .label": {
    fontWeight: 600,
  },
});

const StyledWrapRepresent = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiChip-root": {
    backgroundColor: "#fec90f",
    borderRadius: 4,
    height: 18,
    fontWeight: 600,
    fontSize: 10,
    "& .MuiChip-label": {
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  "& .MuiTypography-root": {
    fontSize: 14,
    fontWeight: 500,
    marginRight: 8,
  },
});

const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    bottom: 0,
    right: 33,
  },
});

const AdjustEmployee = (props) => {
  const { modeSelected, idContractEmployees, setModeSelected } = props;
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState({
    name: "",
    hiringType: "all",
  });
  const { result: allContractEmployees } = useSelector(
    (state) => state.allContractEmployees
  );

  const [selectedContract, setSelectedContract] = React.useState(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [openChangeSpare, setOpenChangeSpare] = React.useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  useEffect(() => {
    if (allContractEmployees) {
      let temp = allContractEmployees.find(
        (x) => x.idContractEmployees === idContractEmployees
      );
      setSelectedContract(temp);
    }
  }, [allContractEmployees]);

  const handleChangeSearch = (event) => {
    const { name, value } = event.target;
    setSearch({
      ...search,
      ["name"]: value,
    });
  };

  const handleCloseDialog = () => {
    setOpenChangeSpare(false);
  };

  const renderEmployeeInContract = () => {
    let temp = null;
    if (search.hiringType === "permanent") {
      temp = [...selectedContract.employeePermanent];
    } else if (search.hiringType === "spare") {
      temp = [...selectedContract.employeeSpare];
    } else {
      temp = [
        ...selectedContract.employeePermanent,
        ...selectedContract.employeeSpare,
      ];
    }

    if (search.name && search.name.length > 0) {
      temp = temp.filter(
        (x) =>
          x.firstname.indexOf(search.name) >= 0 ||
          x.lastname.indexOf(search.name) >= 0
      );
    }

    return temp;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleComfirm = async () => {
    console.log("selectedEmployee: ", selectedEmployee);

    let dataForm = { ...selectedEmployee };
    dataForm.isPermanent = dataForm.isPermanent === 1 ? 0 : 1;

    const response = await dispatch(
      updateEmployeeInCointract(selectedEmployee.idContractEmployees, dataForm)
    );

    if (response) {
      if (response.status === 200) {
        dispatch(getAllContractEmployees());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
      handleCloseDialog();
      handleOpenAlert();
    }
  };

  return (
    <div>
      {modeSelected && (
        <Fragment>
          <Box>
            <Grid container spacing={2} justifyContent="center">
              {/* {modeSelected !== "search" && (
                <Fragment>
                  <Grid item xs={3}>
                    <StyledContentLabel color="text.third" gutterBottom>
                      วันที่เริ่มต้น
                    </StyledContentLabel>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={th}
                    >
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        renderInput={(params) => (
                          <StyledTextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <StyledContentLabel color="text.third" gutterBottom>
                      วันที่สิ้นสุด
                    </StyledContentLabel>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={th}
                    >
                      <DatePicker
                        inputFormat="dd/MM/yyyy"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        renderInput={(params) => (
                          <StyledTextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Fragment>
              )} */}
              {modeSelected === "search" && (
                <Fragment>
                  <Grid item xs={3}>
                    <StyledContentLabel color="text.third" gutterBottom>
                      ค้นหาชื่อ
                    </StyledContentLabel>
                    <TextFieldTheme
                      value={search.name}
                      onChange={handleChangeSearch}
                      placeholder="ค้นหา"
                      style={{
                        borderRadius: "16px",
                        width: "100%",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {modeSelected === "search" && (
                    <Grid item xs={3}>
                      <StyledContentLabel color="text.third" gutterBottom>
                        ประเภทพนักงาน
                      </StyledContentLabel>
                      <StyledFormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="workingType"
                          value={search.hiringType}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              setSearch({
                                ...search,
                                hiringType: newValue.props.value,
                              });
                            } else {
                              setSearch({
                                ...search,
                                hiringType: null,
                              });
                            }
                          }}
                        >
                          <MenuItem value={"all"}>ทั้งหมด</MenuItem>
                          <MenuItem value={"permanent"}>ประจำ</MenuItem>
                          <MenuItem value={"spare"}>สำรอง</MenuItem>
                        </Select>
                      </StyledFormControl>
                    </Grid>
                  )}
                </Fragment>
              )}
            </Grid>
          </Box>
          {modeSelected === "search" ? (
            <StyledCardStyle>
              <div className="count">
                <div>
                  <Typography
                    className="label"
                    color="text.third"
                    variant="body2"
                  >
                    พนักงานประจำ/สำรอง
                  </Typography>
                  <Typography variant="h4">
                    {selectedContract
                      ? selectedContract.countEmployeePermanent
                      : 0}
                    <Typography variant="h4" component="span" color="text.secondary">
                      {`/${
                        selectedContract ? selectedContract.countEmployeeSpare : 0
                      }`}
                    </Typography>
                  </Typography>
                </div>
                <div>
                  <Typography
                    className="label"
                    color="text.third"
                    variant="body2"
                  >
                     พนักงานทั้งหมดในสัญญา
                  </Typography>
                  <Typography variant="h4" align="right">
                    {selectedContract ? selectedContract.countEmployee : 0}
                  </Typography>
                </div>
              </div>
              <Box className="content">
                <Grid container spacing={2}>
                  {selectedContract &&
                    renderEmployeeInContract().map((emp) => (
                      <Grid item xs={12} sm={6} md={3}>
                        <Box
                          style={{
                            width: 220,
                            display: "flex",
                            marginBottom: 8,
                          }}
                          onClick={() => {
                            setOpenChangeSpare(true);
                            setSelectedEmployee(emp);
                          }}
                        >
                          <StyledBadge
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            badgeContent={
                              emp.isPermanent === 0 &&
                              emp.idContractEmployees ===
                                idContractEmployees ? (
                                <StyledWrapRepresent>
                                  <Chip
                                    label={"Spare"}
                                    size="small"
                                  />
                                </StyledWrapRepresent>
                              ) : null
                            }
                          >
                            <StyledAvatar
                              alt={"username"}
                              src={`${process.env.REACT_APP_API_URL}image/vendor/${emp.idVendor}/${emp.personalId}.jpg`}
                            />
                          </StyledBadge>

                          <div>
                            <Typography variant="body1">{`${emp.firstname} ${emp.lastname}`}</Typography>
                            <Typography
                              variant="body2"
                              style={{
                                color: "#999999",
                                fontStyle: "oblique",
                              }}
                            >
                              {emp.telephoneMobile
                                ? `${emp.telephoneMobile.replace(
                                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                                    "$1-$2-$3"
                                  )}`
                                : ""}
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </StyledCardStyle>
          ) : (
            <SelectedEmployees
              selectedContract={selectedContract}
              modeSelected={modeSelected}
              search={search}
              idContractEmployees={idContractEmployees}
              setModeSelected={setModeSelected}
              handleOpenAlert={handleOpenAlert}
              handleChangeAlertType={handleChangeAlertType}
            />
          )}

          {openChangeSpare && selectedEmployee && (
            <DialogChangeSpare
              open={openChangeSpare}
              handleClose={handleCloseDialog}
              content1={
                selectedEmployee.isPermanent === 1
                  ? "เปลี่ยนเป็น Spare"
                  : "เปลี่ยนเป็นประจำ"
              }
              handleComfirm={handleComfirm}
            />
          )}

          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
          />
        </Fragment>
      )}
    </div>
  );
};

export default AdjustEmployee;
