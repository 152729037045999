import React from "react";
import { useSelector } from "react-redux";

import AccountantDetailExpense from "../../admin-accountant/expense/detail-view";
import ManagerDetailExpense from "../../manager/expense/detail-view";
import VendorDetailExpense from "../../vendor/expense/detail-view";

const ExpenseDetailPage = () => {
  const { user: auth } = useSelector((state) => state.auth);
  return (
    <div>
      {auth ? (
        auth.roles.includes("ROLE_ACCOUNT") ? (
          <AccountantDetailExpense />
        ) : auth.roles.includes("ROLE_VENDOR") ? (
          <VendorDetailExpense />
        ) : (
          <ManagerDetailExpense />
        )
      ) : null}
    </div>
  );
};

export default ExpenseDetailPage;
