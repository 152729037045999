import React, { useState, useEffect, Fragment } from "react";
import { useTitle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Profile from "./personal-info";
import Transfer from "./transfer";
import Promotion from "./promotion";
import PaymentChange from "./payment-change";
import Terminate from "./terminate";
import Probation from "./probation";
import EducationExperience from "./education-experience";
import LocationTab from "./address-contact";
import WorkInfo from "./work-info";
import Employment from "./employment";
import Performance from "./performance";
import Leave from "./leave";
import AdditionDeduction from "./addition-deduction";
import ResetPassword from "./reset-password";
import ProvidentFund from "./provident-fund";
import EmployeeType from "./employee-type";
import EmployeeContract from "./employee-contract";

import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Menu,
  MenuItem,
  Popper,
  Box,
  Tabs,
  Tab,
  Paper,
  Container,
  ToggleButton,
  ToggleButtonGroup,
  ListItemIcon,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DocFullPage from "../fileDoc/DocFullPage";
import SlideFullPage from "../fileDoc/SlideFullPage";

import { getEmployeeProfile } from "../../../../../actions/employee";

import DialogDownload from "./DialogDownload";
import ShiftChange from "./shift-change";

const useStyles = makeStyles((theme) => ({
  background: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#ff3c19",
  },
  heading: {
    flexBasis: "50%",
    width: "50%",
    marginTop: 15,
  },
  panel: {
    borderRadius: "10px 10px 0px 0px",
  },
  contentContainer: {
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  keyAdjustContainer: {
    backgroundColor: "#F5F7FA",
    padding: theme.spacing(3),
    borderRadius: "10px",
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  textPrimary: {
    color: "#16243D",
    fontWeight: 600,
  },
  textSecondary: {
    color: "#58647A",
    fontWeight: 600,
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },
  },
  buttonDownload: {
    height: "calc(100% - 14px)",
    margin: "7px",
    [theme.breakpoints.down("lg")]: {
      height: "fit-content",
      padding: "5px 10px",
    },
  },
  buttonHalfPage: {
    backgroundColor: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));

const StyledRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF !important",
  paddingTop: "120px",
  paddingBottom: "120px",
  "& .MuiAvatar-root": {
    width: "180px",
    height: "180px",
  },
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .NavButtonDownload": {
    width: "fit-content",
    [theme.breakpoints.between("md", "lg")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  ["@media only screen and (max-width: 600px)"]: {
    marginBottom: 8,
  },
  "& .MuiButtonBase-root": {
    borderRadius: 16,
    width: 160,
  },
  "& .MuiButtonBase-root.MuiToggleButton-root.Mui-selected": {
    borderColor: "#1976d2",
  },
});

const StyledWrapAction = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 16,
  ["@media only screen and (max-width: 600px)"]: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
          {/* <Typography>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function KeyAddition() {
  useTitle("Add Key Addition - HR CORE DATA");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  let { id } = useParams();

  const [tabs, setTabs] = useState(0);
  const [openDialogDownload, setOpenDialogDownload] = useState(false);
  const [groupTap, setGroupTap] = useState("personal");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSalary, setIsSalary] = useState(null);
  const [typeDownload, setTypeDownload] = useState(null);
  const idMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (userProfile) {
      dispatch(getEmployeeProfile(id));
    }
  }, [userProfile]);

  useEffect(() => {
    if (employeeProfile && userProfile) {
      if (userProfile.isSuperAdmin === 1) {
        setIsSalary(true);
      } else {
        if (userProfile.confidentialData.length >= 1) {
          if (userProfile.confidentialData[0].idCompany === null) {
            setIsSalary(
              userProfile.confidentialData[0].isSalary === 1 &&
                userProfile.confidentialData[0].isPerformance === 1
            );
          } else {
            let findCompany = userProfile.confidentialData.find(
              (x) => x.idCompany === employeeProfile.idCompany
            );
            if (findCompany) {
              setIsSalary(findCompany.isSalary === 1);
            } else {
              setIsSalary(false);
            }
          }
        } else {
          setIsSalary(false);
        }
      }

      if (userProfile.confidentialData.length === 1) {
        if (userProfile.isSuperAdmin === 1) {
          setIsSalary(true);
        } else {
          if (
            userProfile.confidentialData[0].idCompany ===
            employeeProfile.idCompany
          ) {
            setIsSalary(userProfile.confidentialData[0].isSalary === 1);
          } else if (userProfile.confidentialData[0].idCompany === null) {
            setIsSalary(
              userProfile.confidentialData[0].isSalary === 1 &&
                userProfile.confidentialData[0].isPerformance === 1
            );
          } else {
            setIsSalary(false);
          }
        }
      } else {
      }
    }
  }, [employeeProfile]);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setTypeDownload(null);
  };

  const handleTabsChange = (e, newValue) => {
    setTabs(newValue);
  };

  const handleChangeGroupTab = (event, newAlignment) => {
    if (newAlignment !== null) {
      if (newAlignment === "work") {
        setTabs(8);
      } else {
        setTabs(0);
      }
      setGroupTap(newAlignment);
    }
  };

  const handleClickDownload = (event, type) => {
    setAnchorEl(event.currentTarget);
    setTypeDownload(type);
  };

  const toggleDrawerDownload = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDialogDownload(open);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        {employeeProfile && (
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <Profile dataEmployee={employeeProfile} />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <StyledWrapAction>
                  <StyledToggleButtonGroup
                    style={{ alignItems: "flex-end" }}
                    color="primary"
                    value={groupTap}
                    exclusive
                    onChange={handleChangeGroupTab}
                  >
                    <ToggleButton
                      value="personal"
                      style={{ height: "fit-content" }}
                    >
                      {t("BasicInformation")}
                    </ToggleButton>
                    <ToggleButton
                      value="work"
                      style={{ height: "fit-content" }}
                    >
                      {t("ChangeInformation")}
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                  <div className="NavButtonDownload">
                    <ButtonBlue
                      startIcon={<DownloadRoundedIcon />}
                      id="basic-button"
                      onClick={toggleDrawerDownload(true)}
                    >
                      {t("Download")}
                    </ButtonBlue>
                  </div>
                </StyledWrapAction>

                <Paper sx={{borderRadius: "20px"}}>
                  <Box style={{ width: "100%" }}>
                    {groupTap === "personal" && (
                      <Box style={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={tabs}
                          onChange={handleTabsChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="basic tabs example"
                          sx={{"& button": {
                            textTransform: "none",
                          }}}
                        >
                          <Tab
                            label={`${t("PersonalInfo")}`}
                            {...a11yProps(0)}
                            value={0}
                          />
                          <Tab
                            label={`${t("JobInfo")}`}
                            {...a11yProps(1)}
                            value={1}
                          />
                          <Tab
                            label={`${t("PaymentInfo")}`}
                            {...a11yProps(2)}
                            value={2}
                          />
                          <Tab
                            label={`${t("ProvidentFund")}`}
                            {...a11yProps(3)}
                            value={3}
                          />
                          <Tab
                            label={`${t("Education")}-${t("ExperienceInfo")}`}
                            {...a11yProps(4)}
                            value={4}
                          />
                          <Tab
                            label={t("performance")}
                            {...a11yProps(5)}
                            value={5}
                          />
                          <Tab
                            label={t("leaveAbsent")}
                            {...a11yProps(6)}
                            value={6}
                          />
                          <Tab
                            label={t("employeeContract")}
                            {...a11yProps(7)}
                            value={7}
                          />
                        </Tabs>
                      </Box>
                    )}
                    {groupTap === "work" && (
                      <Box style={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={tabs}
                          variant="scrollable"
                          scrollButtons="auto"
                          onChange={handleTabsChange}
                          aria-label="basic tabs example"
                        >
                          <Tab
                            label={`${t("Transfer")}/${t("Move")}`}
                            value={8}
                            {...a11yProps(8)}
                          />
                          <Tab
                            label={t(`Work_Time`)}
                            value={17}
                            {...a11yProps(17)}
                          />
                          <Tab
                            label={t("Employment")}
                            value={15}
                            {...a11yProps(15)}
                          />
                          <Tab
                            label={t("Probation")}
                            value={9}
                            {...a11yProps(9)}
                          />
                          <Tab
                            label={t("Promotion")}
                            value={10}
                            {...a11yProps(10)}
                          />
                          {isSalary && (
                            <Tab
                              label={t("PaymentChange")}
                              value={11}
                              {...a11yProps(11)}
                            />
                          )}
                          <Tab
                            label={t("Terminated")}
                            value={12}
                            {...a11yProps(12)}
                          />
                          <Tab
                            label={`${t("Addition")}/${t("Deduction")}`}
                            value={13}
                            {...a11yProps(13)}
                          />
                          <Tab
                            label={t("ResetPass")}
                            value={14}
                            {...a11yProps(14)}
                          />
                        </Tabs>
                      </Box>
                    )}

                    <TabPanel value={tabs} index={0}>
                      <LocationTab />
                    </TabPanel>
                    <TabPanel value={tabs} index={1}>
                      <WorkInfo
                        changeGroupTap={setGroupTap}
                        changeTabs={setTabs}
                      />
                    </TabPanel>
                    <TabPanel value={tabs} index={2}>
                      <Employment
                        changeGroupTap={setGroupTap}
                        changeTabs={setTabs}
                      />
                    </TabPanel>
                    <TabPanel value={tabs} index={3}>
                      <ProvidentFund />
                    </TabPanel>
                    <TabPanel value={tabs} index={4}>
                      <EducationExperience />
                    </TabPanel>
                    <TabPanel value={tabs} index={5}>
                      <Performance />
                    </TabPanel>
                    <TabPanel value={tabs} index={6}>
                      <Leave />
                    </TabPanel>
                    <TabPanel value={tabs} index={7}>
                      <EmployeeContract />
                    </TabPanel>
                  </Box>
                  <TabPanel value={tabs} index={8}>
                    <Transfer />
                  </TabPanel>
                  <TabPanel value={tabs} index={9}>
                    <Probation />
                  </TabPanel>
                  <TabPanel value={tabs} index={10}>
                    <Promotion />
                  </TabPanel>
                  <TabPanel value={tabs} index={11}>
                    <PaymentChange />
                  </TabPanel>
                  <TabPanel value={tabs} index={12}>
                    <Terminate />
                  </TabPanel>
                  <TabPanel value={tabs} index={13}>
                    <AdditionDeduction />
                  </TabPanel>
                  <TabPanel value={tabs} index={14}>
                    <ResetPassword />
                  </TabPanel>
                  <TabPanel value={tabs} index={15}>
                    <EmployeeType />
                  </TabPanel>
                  <TabPanel value={tabs} index={16}>
                    <EmployeeContract />
                  </TabPanel>
                  <TabPanel value={tabs} index={17}>
                    <ShiftChange />
                  </TabPanel>
                </Paper>
              </Grid>
            </Grid>
          </Fragment>
        )}
        <DialogDownload
          open={openDialogDownload}
          toggleDrawer={toggleDrawerDownload}
        />
      </Container>
    </StyledRoot>
  );
}

export default KeyAddition;
