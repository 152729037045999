import {
    GROUPLEVELS_COMPANY_FETCHING,
    GROUPLEVELS_COMPANY_FAILED,
    GROUPLEVELS_COMPANY_SUCCESS,
} from "../actions/types";

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};
  
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GROUPLEVELS_COMPANY_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case GROUPLEVELS_COMPANY_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case GROUPLEVELS_COMPANY_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
}