import React from "react";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

const StyledBox = styled(Box)(({ statusRequest, active }) => ({
  margin: "auto",
  width: 40,
  height: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 12,
  ...(statusRequest === 1 &&
    active && {
      backgroundColor: "#ebfaf2",
    }),
  ...(statusRequest === 0 && {
    backgroundColor: "#fdf3f5",
  }),
  ...(statusRequest === null &&
    active === 0 && {
      backgroundColor: "#f3f6f8",
    }),
  ...(statusRequest === null &&
    active === 1 && {
      backgroundColor: "#fffcd6",
    }),
  ...(active === 0 && {
    backgroundColor: "#f3f6f8",
  }),
}));

const StatusRequest = ({ status, active, step }) => {
  const renderIcon = () => {
    if (active) {
      if (status === 1) {
        return (
          <i
            className={`fas fa-check-circle`}
            style={{ fontSize: 24, color: "#00c292" }}
          ></i>
        );
      } else if (status === 0) {
        return (
          <i
            className={`fas fa-times-circle`}
            style={{ fontSize: 24, color: "#e46a76" }}
          ></i>
        );
      } else {
        if (step === 1) {
          return (
            <i
              className={`fa-solid fa-circle-1`}
              style={{ fontSize: 24, color: "#fec90f" }}
            ></i>
          );
        } else if (step === 2) {
          return (
            <i
              className={`fa-solid fa-circle-2`}
              style={{ fontSize: 24, color: "#fec90f" }}
            ></i>
          );
        } else {
          return (
            <i
              className={`fas fa-question-circle`}
              style={{ fontSize: 24, color: "#fec90f" }}
            ></i>
          );
        }
      }
    } else {
      return (
        <i
          className={`fas fa-minus-circle`}
          style={{ fontSize: 24, color: "#bfbfbf" }}
        ></i>
      );
    }
  };

  return (
    <StyledBox active={active} statusRequest={status}>
      {renderIcon()}
    </StyledBox>
  );
};

export default StatusRequest;
