import {
  AREA_FETCHING,
  AREA_FAILED,
  AREA_SUCCESS,
  AREA_DETAIL_FETCHING,
  AREA_DETAIL_FAILED,
  AREA_DETAIL_SUCCESS,
} from "./types";

import AreaService from "../services/area.service";

export const getAllAreas = (searchDate, idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: AREA_FETCHING
    });
    const res = await AreaService.getAllAreas(searchDate, idCompany);
    if(res){
      dispatch({
        type: AREA_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: AREA_FAILED
    });
  }
};
export const getAreaDetail = (idSection) => async (dispatch) => {
  try {
    dispatch({
      type: AREA_DETAIL_FETCHING
    });
    const res = await AreaService.getAreaDetail(idSection);
    if(res){
      dispatch({
        type: AREA_DETAIL_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: AREA_DETAIL_FAILED
    });
  }
};
