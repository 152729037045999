import { httpClient } from "./httpClient";


const allPayTypes = () => {
  return httpClient.get("paytypes");
};

const addPaytypes = (formData) => {
  return httpClient.post(`paytypes`, formData);
};

const updatePaytypes = (formData) => {
  return httpClient.put(`paytypes`, formData);
};

const updatePayrollSetting = (idPayrollSetting, formData) => {
  return httpClient.put(`payroll-setting/${idPayrollSetting}`, formData);
};

const getPayrollSetting = () => {
  return httpClient.get("payroll-setting");
};

export default {
  allPayTypes,
  addPaytypes,
  updatePaytypes,
  updatePayrollSetting,
  getPayrollSetting
};
