import {
    EXPERTISES_FETCHING,
    EXPERTISES_FAILED,
    EXPERTISES_SUCCESS,
    EXPERTISES_VENDOR_FETCHING,
    EXPERTISES_VENDOR_FAILED,
    EXPERTISES_VENDOR_SUCCESS
} from "./types";

import ExpertiseService from "../services/expertise.service";

export const getAllExpertise = () => async (dispatch) => {
    try {
        dispatch({
            type: EXPERTISES_FETCHING
        });
        let res = await ExpertiseService.getAllExpertise();
        if (res) {
            dispatch({
                type: EXPERTISES_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: EXPERTISES_FAILED
        });
    }
};

export const getAllExpertiseVendor = () => async (dispatch) => {
    try {
        dispatch({
            type: EXPERTISES_VENDOR_FETCHING
        });
        let res = await ExpertiseService.getAllExpertiseVendor();
        if (res) {
            dispatch({
                type: EXPERTISES_VENDOR_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: EXPERTISES_VENDOR_FAILED
        });
    }
};

export const updateExpertiseVendor = (value) => async () => {
    try {
        const res = await ExpertiseService.updateExpertiseVendor(value);
        if (res) {
            return res;
        }
    } catch (error) {
        const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
        return "Error";
    }
};