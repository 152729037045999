import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';

export const Tawi50XlsxFile = async (type, selectedYear, data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    const taxValue = data && data.TaxValue;
    const employeeData = data && data.employeeDetails;
    const deductionData = data && data.deductionPnd1kResult;
    const additionData = data && data.additionPnd1kResult;

    const headerRow = worksheet.addRow([
        "รหัสพนักงาน",
        "ชื่อ",
        "นามสกุล",
        "เลขบัตรประชาชน",
        "รายได้สะสมทั้งปี",
        "ภาษีหัก ณ ที่จ่ายทั้งปี",
        "ประกันสังคมทั้งปี",
        "กองทุนสำรองเลี้ยงสะสมทั้งปี",
        "(Before) รายได้สะสมทั้งปี",
        "(Before) ภาษีหัก ณ ที่จ่ายทั้งปี",
        "(Before) ประกันสังคมสะสมทั้งปี",
        "(Before) กองทุนสำรองเลี้ยงสะสมทั้งปี"
    ]);

    headerRow.height = 40;

    const headerStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    };

    headerRow.eachCell((cell) => {
        cell.style = headerStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    if (employeeData.length > 0) {
        for (let i = 0; i < employeeData.length; i++) {
            const item = employeeData[i];
            const findEmpTax = taxValue && taxValue.find(tax => tax.idEmp === item.idEmp);
            const findEmpDeduct = deductionData && deductionData.find(x => x.idEmp === item.idEmp);
            const findEmpAddition = additionData && additionData.find(x => x.idEmp === item.idEmp);

            const tax = findEmpTax ? findEmpTax.value : 0;
            const SumLate = findEmpTax ? findEmpTax.Sum_late : 0;
            const SumAbsent = findEmpTax ? findEmpTax.Sum_absent : 0;
            const SumPF = findEmpTax ? findEmpTax.SUM_PF : 0;
            const SumSSO = findEmpTax ? findEmpTax.SUM_SSO : 0;
            const DecSumAbsent = findEmpTax ? findEmpTax.Dec_Sum_absent : 0;
            const DecSumLate = findEmpTax ? findEmpTax.Dec_Sum_late : 0;

            //2nd december
            const TotalDecTax = findEmpTax ? findEmpTax.Dec_Sum_Tax : 0;
            const TotalDecSSO = findEmpTax ? findEmpTax.Dec_Sum_SSO : 0;
            const TotalDecLateAndAbsentValue = DecSumAbsent + DecSumLate;
            const TotalDecemberDeductions = (item.HalfDecemberEarning || 0) - TotalDecLateAndAbsentValue;

            const TotalLateAndAbsentValue = SumAbsent + SumLate;

            const deductedValue = findEmpDeduct ? findEmpDeduct.value : 0; //เงินหัก
            const allDeductionDec = findEmpDeduct ? findEmpDeduct.AllDeductionDec : 0; //เงินหักมาเดือนธันวา

            const addedValue = findEmpAddition ? findEmpAddition.AdditionValue : 0;
            const allAddtionDec = findEmpAddition ? findEmpAddition.AllAdditionDec : 0;

            const TotalAdditions = addedValue - allAddtionDec;

            const TotalDeductions = deductedValue - allDeductionDec + TotalLateAndAbsentValue + TotalDecemberDeductions;
            const AllEarnings = ((item.totalEarnings || 0) + (item.beforeAccumulateEarnings || 0)) - TotalDeductions - TotalAdditions;

            const row = [
                `${item.employeeId}`,
                `${item.firstname}`,
                `${item.lastname}`,
                `${item.personalId}`,
                `${(AllEarnings || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                `${(tax + item.beforeAccumulateTax - TotalDecTax || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                `${(SumSSO + item.beforeAccumulateSSO - TotalDecSSO || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                `${(SumPF + item.beforeAccumulatePF || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                `${(item.beforeAccumulateEarnings || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                `${(item.beforeAccumulateTax || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                `${(item.beforeAccumulateSSO || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                `${(item.beforeAccumulatePF || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            ];

            worksheet.addRow(row);

            // Apply font size to the entire row
            const lastRow = worksheet.lastRow;
            lastRow.font = { size: 16, name: 'TH SarabunPSK' };

            lastRow.eachCell((cell) => {
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            });
        }

        const colWidths = [
            { key: "empCode", width: 40 },
            { key: "firstname", width: 40 },
            { key: "lastname", width: 40 },
            { key: "personalID", width: 40 },
            { key: "totalEarnings", width: 40 },
            { key: "tax", width: 40 },
            { key: "sso", width: 40 },
            { key: "PF", width: 40 },
            { key: "beforeEarning", width: 40 },
            { key: "beforeTax", width: 40 },
            { key: "beforeSSO", width: 40 },
            { key: "beforePF", width: 40 }
        ];

        colWidths.forEach((col, index) => worksheet.getColumn(index + 1).width = col.width);

        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `50tawi.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        });
    } else {
        console.log("No data");
    }
}