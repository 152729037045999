import React, { useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { Typography, Divider, Avatar, Badge, Box } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import StyledCard from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";

import { getDepartment } from "./../../../../actions/department";
import { getAllUsersCompany } from "../../../../actions/user";

import dataEmployeeList from "../../assets/data/employees";
import vendor from "../../assets/data/vendor";

import ItemTable from "./ItemTable";
import FilterTable from "./filterTable";

import "./index.css";
import FirstUploadEmployee from "./first-upload-employee";

import { useTranslation } from "react-i18next";

const StyledContent = styled("div")({
  padding: 24,
  "& .wrapFilter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#007aff",
    border: "1px solid #007aff",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007aff",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #007aff",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const EmployeeList = ({ handleOpenAlert }) => {
  const { t, i18n } = useTranslation();
  const { result: employeeList } = useSelector((state) => state.users);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterEmployee, setFilterEmployee] = useState({
    status: "1",
    search: "",
    idDepartment: "all",
    idVendor: "all",
    idJobGroup: "all",
    employeeType: [],
    level: [],
  });

  const handleChangeFilterEmployee = (event, newStatus, selectName) => {
    var name = event.target.name;
    var value = newStatus;
    if (newStatus === undefined) {
      setFilterEmployee({
        ...filterEmployee,
        [name]: event.target.value,
      });
    } else {
      if (name === undefined) {
        name = selectName;
      }
      setFilterEmployee({
        ...filterEmployee,
        [name]: value,
      });
    }
  };

  const handleChangeVendor = (newStatus) => {
    setFilterEmployee({
      ...filterEmployee,
      ["idVendor"]: newStatus,
    });
  };

  const rowsFilter = () => {
    if (filterEmployee.search == "" && filterEmployee.idVendor == "all") {
      return filterEmployee.status === "1"
        ? employeeList.active
        : employeeList.termainate;
    } else {
      let resultFilter =
        filterEmployee.status === "1"
          ? [...employeeList.active]
          : [...employeeList.termainate];

      if (filterEmployee.idVendor != "all") {
        resultFilter = resultFilter.filter((item) => {
          return item.idVendor == filterEmployee.idVendor;
        });
      }
      return resultFilter;
    }
  };

  return (
    <div>
      <StyledCard>
        <StyledContent>
          {(employeeList && 
            (
              (employeeList.active && employeeList.active.length > 0) ||
              (employeeList.terminate && employeeList.terminate.length > 0) 
            )
            ? (
              <Fragment>
                <div className="wrapFilter">
                  <div>
                    <StyledToggleButtonGroup
                      value={filterEmployee.status}
                      exclusive
                      onChange={handleChangeFilterEmployee}
                      aria-label="filter employee"
                      size="small"
                    >
                      <ToggleButton
                        name="status"
                        value="1"
                        aria-label="active"
                        size="small"
                        style={{ minWidth: 150 }}
                      >
                        {t("Employees")}
                      </ToggleButton>
                      <ToggleButton
                        name="status"
                        value="0"
                        aria-label="terminate"
                        size="small"
                        style={{ minWidth: 150 }}
                      >
                        {t("Employee_Leaved")}
                      </ToggleButton>
                    </StyledToggleButtonGroup>
                  </div>
                  {employeeList && (
                    <div>
                      <Typography variant="h4" align="right">
                        {rowsFilter().length}
                      </Typography>
                      <Typography
                        variant="body2"
                        align="right"
                        color="text.secondary"
                        style={{ fontWeight: 500 }}
                      >
                        {t("Total_Person")}
                      </Typography>
                    </div>
                  )}
                </div>
                <FilterTable
                  handleChange={handleChangeFilterEmployee}
                  handleChangeVendor={handleChangeVendor}
                  filterEmployee={filterEmployee}
                  filterStatus={filterStatus}
                />
                <Box>
                  <ItemTable open={filterStatus} employeeList={employeeList} filterEmployee={filterEmployee} handleOpenAlert={handleOpenAlert}/>
                </Box>
              </Fragment>
            ) : (
              <FirstUploadEmployee />
            )
          )}
        </StyledContent>
      </StyledCard>
    </div>
  );
};

export default EmployeeList;
