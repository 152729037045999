import React, { useState } from "react";
import { Grid, InputAdornment, styled } from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import StyledBranch from "./StyledBranch";

//import branch from "../assets/data/branch";

const StyledRoot = styled("div")({
	width: 450,
	padding: 16,
	"& .part-filter": {
		marginBottom: 16,
		"& .search-branch-name": {
			// width: 250,
		}
	}
})

const DrawerBranch = (props) => {

	const { open, onClose } = props;
	const [filterBranch, setFilterBranch] = useState([]);
	const [searchBranchName, setSearchBranchName] = useState("");

	const onChangeFilterBranchName = (event) => {
		//setFilterBranch(branch.filter(branch => branch.branchName.includes(event.target.value)))
		// console.log(filterBranch)
		// console.log(event.target.value)
	}

	return(
		<DrawerCustom
			title="สาขาของบริษัท"
			anchor="right"
			open={open}
			onClose={onClose}
		>
			<StyledRoot>
				<div className="part-filter">
					<TextFieldTheme
						className="search-branch-name"
						placeholder="ค้นหาสาขา..."
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<i className="fa-regular fa-magnifying-glass"></i>
								</InputAdornment>
							)
						}}
						// value={searchBranchName}
						onChange={onChangeFilterBranchName}
						autoComplete="off"
					/>
				</div>

				<Grid container spacing={2}>
					{filterBranch
					.map((branch, branchIndex) => (
						<Grid key={`branch_${branchIndex}`} item xs={12}>
							<StyledBranch branch={branch}/>
						</Grid>
					))}
				</Grid>
			</StyledRoot>
		</DrawerCustom>
  )
}

export default DrawerBranch;