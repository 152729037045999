import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Divider, Grid, styled, Typography, IconButton } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import { getEmployeePositionChange } from "../../../../../../actions/employee";
import { getCompanyContract } from "../../../../../../actions/vendor";

import DialogEdit from "./DialogEdit";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapChange = styled("div")({
  marginTop: 8,
  "& .inner": {
    marginBottom: 8,
  },
  "& .first-line": {
    display: "flex",
    "& .date": {
      fontWeight: 600,
    },
  },
  "& .positions-name": {
    fontWeight: 600,
    marginLeft: 8,
  },
  "& .second-line": {
    marginLeft: 64,
    fontSize: 16,
    "&.name-line, .name-line": {
      fontWeight: 500,
    },
  },
});

const Transfer = () => {
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employeePosition } = useSelector(
    (state) => state.employeePosition
  );
  const { t, i18n } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const toggleDrawer = (mode, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDialog(open);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  useEffect(() => {
    if (employeeProfile) {
      //dispatch(getCompanyOrganization(employeeProfile.idCompany));
      dispatch(getEmployeePositionChange(employeeProfile.idEmp));
      dispatch(getCompanyContract());
    }
  }, [employeeProfile]);

  return (
    <div>
      <StyledWrapTop>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditIcon />}
            onClick={toggleDrawer("", true)}
          >
            {t("Edit")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Company")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.companyName ? employeeProfile.companyName : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Division")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.divisionName
                ? employeeProfile.divisionName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Department")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.departmentName
                ? employeeProfile.departmentName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Section")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.sectionName ? employeeProfile.sectionName : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Position")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.positionsName
                ? employeeProfile.positionsName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("JobGroup")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobGroupName
                ? employeeProfile.jobGroupName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("JobLevel")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobLevelName
                ? employeeProfile.jobLevelName
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <div>
        <StyledHeadLabel color="text.secondary">
          {t("ChangeLog")}
        </StyledHeadLabel>

        {employeePosition && (
          <StyledWrapChange>
            {employeePosition.map((item, index) => (
              <div className="inner" key={index}>
                <div className="first-line">
                  <div style={{ width: 40 }}>
                    {employeePosition.length - 1 === index ? (
                      <Fragment>
                        {/* <IconButton
                          aria-label="edit"
                          size="small"
                          onClick={() => handleEditEmployeePosition(item)}
                        >
                          <EditNoteRoundedIcon fontSize="inherit" />
                        </IconButton> */}
                        <IconButton
                          aria-label="delete"
                          size="small"
                          //onClick={() => handleDeleteEmployeePosition(item)}
                          disabled={employeePosition.length === 1}
                        >
                          <HighlightOffRoundedIcon fontSize="inherit" />
                        </IconButton>
                      </Fragment>
                    ) : null}
                  </div>
                  &nbsp;
                  {item.end ? (
                    <Typography className="date" color="text.third">
                      {`[${dayjs(item.start).format("DD MMM BBBB")} - ${dayjs(
                        item.end
                      ).format("DD MMM BBBB")}]`}
                    </Typography>
                  ) : (
                    <Typography className="date" color="text.third">
                      {`[${dayjs(item.start).format(
                        "DD MMM BBBB"
                      )} - ปัจจุบัน]`}
                    </Typography>
                  )}
                  <Typography className="positions-name">
                    {`${item.positionsName}`}
                  </Typography>
                </div>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      แผนก:&nbsp;
                      <Typography
                        className="name-line"
                        component="span"
                        color="text.third"
                      >
                        {item.sectionName ? item.sectionName : "-"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      หน่วยงาน:&nbsp;
                      <Typography
                        className="name-line"
                        component="span"
                        color="text.third"
                      >
                        {item.divisionName ? item.divisionName : "-"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      ฝ่าย:&nbsp;
                      <Typography
                        className="name-line"
                        component="span"
                        color="text.third"
                      >
                        {item.divisionName ? item.divisionName : "-"}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ margin: "16px 0" }}></div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      {`หัวหน้างานลำดับที่ 1 `}
                    </Typography>
                    <Typography
                      color="text.third"
                      variant="body2"
                      className="second-line name-line"
                      gutterBottom
                    >
                      {`${
                        item.managerLV1Name ? `${item.managerLV1Name}` : "-"
                      }`}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line name-line"
                      style={{ fontSize: 14 }}
                      gutterBottom
                    >
                      {`${
                        item.managerLV1Email ? `(${item.managerLV1Email})` : "-"
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line"
                      gutterBottom
                    >
                      {`หัวหน้างานลำดับที่ 2`}
                    </Typography>
                    <Typography
                      color="text.third"
                      variant="body2"
                      className="second-line name-line"
                      gutterBottom
                    >
                      {`${
                        item.managerLV2Name ? `${item.managerLV2Name}` : "-"
                      }`}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      className="second-line name-line"
                      style={{ fontSize: 14 }}
                      gutterBottom
                    >
                      {`${
                        item.managerLV2Email ? `(${item.managerLV2Email})` : "-"
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ margin: "16px 0" }}></div>
                  </Grid>
                </Grid>

                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  gutterBottom
                  style={{ fontStyle: "oblique" }}
                >
                  {`หมายเหตุ: ${item.remark ? item.remark : "-"}`}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  align="right"
                  style={{ fontStyle: "oblique", fontSize: 14 }}
                >
                  {`วันที่แก้ไขข้อมูล: ${
                    item.updateDate
                      ? `${dayjs(item.updateDate)
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "DD MMM BBBB HH:mm"
                              : "DD MMM YYYY HH:mm"
                          )}`
                      : "-"
                  }`}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  align="right"
                  style={{ fontStyle: "oblique", fontSize: 14 }}
                  gutterBottom
                >
                  {`${
                    item.adminVendor_firstname
                      ? ` โดย ${item.adminVendor_firstname} ${item.adminVendor_lastname}`
                      : item.admin_firstname
                      ? ` โดย ${item.admin_firstname} ${item.admin_lastname}`
                      : ""
                  }`}
                </Typography>
              </div>
            ))}
          </StyledWrapChange>
        )}
      </div>
      <DialogEdit open={openDialog} handleCloseDialog={handleCloseDialog} />
    </div>
  );
};

export default Transfer;
