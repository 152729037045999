import * as React from 'react';
import { Box, Container, styled, Typography } from "@mui/material";
import AccessTimeline from "./AccessTimeline";

const StyledRoot = styled(Box)({
    backgroundColor: "#FFFFFF !important",
})

const AccessArea = () => {
    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <div style={{ marginBottom: 8 }}>
                    <Typography variant="h4" style={{ paddingTop: 8 }}>
                        บันทึกการเดินทาง
                    </Typography>
                </div>
                <AccessTimeline/>
            </Container>
        </StyledRoot>
    );
};

export default AccessArea;