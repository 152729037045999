import { httpClient } from "./httpClient";

const getManagerId = (email) => {
  return httpClient.get(`manager?mail=${email}`);
};

const getManagerGroup = (idCompany) => {
  return httpClient.get(`company/${idCompany}/manager-group`);
};

const addManagerGroup = (formData) => {
  return httpClient.post(`/manager-group`, formData);
};

const updateManagerGroup = (idManagerGroup, formData) => {
  return httpClient.put(`/manager-group/${idManagerGroup}`, formData);
};

const deleteManagerGroup = (idManagerGroup) => {
  return httpClient.delete(`/manager-group/${idManagerGroup}`);
};

const updateManager = (formData) => {
  return httpClient.put(`/manager/update`, formData);
};

export default {
  getManagerId,
  getManagerGroup,
  addManagerGroup,
  updateManagerGroup,
  deleteManagerGroup,
  updateManager,
};
