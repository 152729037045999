import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { Container, Typography, Divider, Grid, Box } from "@mui/material";

import { getAllAreas } from "../../../../actions/area";

import utils from "../../../../utils";

import DatePickerEng from "../../shared/general/DatePickerEng";
import DatePickerThai from "../../shared/general/DatePickerThai";
import ButtonBlue from "../../shared/general/ButtonBlue";

import SearchIcon from "@mui/icons-material/Search";

import CardCounter from "./card-counter";
import CardZone from "./card-zone";
import AccessAreaDetailAdmin from "./detail";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 80,
});

const StyledGroupArea = styled("div")({
  display: "flex",
  "& .zone": {
    width: "100%",
    marginLeft: 24,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 24,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const AccessAreaAdmin = () => {
  const dispatch = useDispatch();
  const [groupLocations, setGroupLocations] = useState(null);
  const [selected, setSelected] = useState(null);
  const [search, setSearch] = useState(new Date());
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: areaList } = useSelector((state) => state.area);

  useEffect(() => {
    dispatch(getAllAreas(dayjs(search).format("YYYY-MM-DD")));
  }, []);

  useEffect(() => {
    if (areaList) {
      const grouped = utils.groupBy(
        areaList,
        (value) => value.idGroupGpsLocations
      );
      var keys = [...grouped.keys()];

      let temp = [];
      keys.forEach((element) => {
        const foundGroup = areaList.find(
          (x) => x.idGroupGpsLocations === element
        );
        temp.push({
          idGroupGpsLocations: foundGroup.idGroupGpsLocations,
          groupGpsLocationsName: foundGroup.groupGpsLocationsName,
        });
      });

      setGroupLocations(temp);
    }
  }, [areaList]);

  const handleSelectArea = (select) => {
    setSelected(select);
  };

  const handleClickAll = () => {
    setSelected(null);
  };

  const handleSearch = () => {

  }

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8, marginBottom: 16 }}>
          การเข้าพื้นที่
        </Typography>
        <StyledWrapFilter>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  วันที่
                </Typography>
                <div className="search-date">
                  {localStorage.getItem("language") === "en" ? (
                    <DatePickerEng
                      value={search}
                      name="start"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch(newValue);
                      }}
                    />
                  ) : (
                    <DatePickerThai
                      value={search}
                      name="start"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch(newValue);
                      }}
                    />
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>

            <Grid item xs={12} sm={4}>
              <StyledBoxSearch>
                <div className="wrap-search-action">
                  <ButtonBlue
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                  >
                    ค้นหา
                  </ButtonBlue>
                </div>
              </StyledBoxSearch>
            </Grid>
          </Grid>
        </StyledWrapFilter>
        {selected ? (
          <AccessAreaDetailAdmin
            handleClickAll={handleClickAll}
            selected={selected}
          />
        ) : (
          <Fragment>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {areaList &&
                areaList.map((item) => (
                  <Fragment>
                    <StyledGroupArea>
                      <CardCounter
                        handleSelectArea={handleSelectArea}
                        area={item}
                      />
                      <div className="zone">
                        <Typography
                          style={{ fontWeight: 600 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          ZONE
                        </Typography>
                        <Grid container spacing={4}>
                          {item.areas.map((area) => (
                            <Grid item xs={12} sm={6}>
                              <CardZone area={area} />
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </StyledGroupArea>

                    <StyledDivider />
                  </Fragment>
                ))}
            </div>
          </Fragment>
        )}
      </Container>
    </StyledRoot>
  );
};

export default AccessAreaAdmin;
