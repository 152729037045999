import React, { useState, useEffect, useRef } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";

import {
  Button,
  IconButton,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  TablePagination,
  Grid,
  Popper,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  FormHelperText,
  Menu,
  MenuItem,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Dropzone, { useDropzone } from "react-dropzone";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import GalleryIcon from "../../../../assets/gallery.png";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../../shared/general/AlertResponse";

import ActionButtons from "../action-buttons";
import RowItemExpense from "./row-per-diem";
import utils from "../../../../../../utils";

import { addExpenseAllowance } from "../../../../../../actions/expense";
import SelectApprover from "../../select-approver.js";
import SelectCC from "../../select-cc";
import SelectCostCenter from "../../select-costCenter.js";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  "& .MuiToggleButtonGroup-grouped": {
    color: "#007AFE",
    border: "1px solid #007AFE",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#00C0E1",
      backgroundColor: "#00C0E1",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007AFE",
      "&:hover": {
        borderColor: "#00C0E1",
        backgroundColor: "#00C0E1",
        color: "#FFFFFF",
      },
    },
  },
  "& ,MuiToggleButtonGroup-root": {
    height: 50,
  },
}));

const StyledTableCell = styled(TableCell)({
  border: "none",
  padding: 0,
  "& .MuiTypography-root": {
    marginBottom: 16,
  },
  "&.date-document": {
    width: 120,
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 40,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
  "&.startDate": {
    width: 150,
  },
  "&.endDate": {
    width: 150,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const rowHeader = [
  {
    caption: "",
    className: "delete",
  },
  {
    caption: "StartDate",
    className: "date-start-end",
  },
  {
    caption: "EndDate",
    className: "date-start-end",
  },
  {
    caption: "Description",
    className: "description",
  },
  {
    caption: "DateAmount",
    className: "amount",
  },
  {
    caption: "",
    className: "delete",
  },
];

const listMenuTax = ["7%", "ไม่มี"];
const listMenuWithholding = [
  "1%",
  "1.5%",
  "2%",
  "3%",
  "5%",
  "10%",
  "15%",
  "ไม่มี",
];

const ITEM_HEIGHT = 48;

const PerDiem = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const today = dayjs().toDate();
  const { typeExpense } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: manager } = useSelector((state) => state.manager);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [listMenuTaxOrWithholding, setListMenuTaxOrWithholding] =
    React.useState(null);
  const open = Boolean(anchorEl);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const periodRegex = /^\d+(\.[0|5]{0,1})?$/;
  const [fileSelected, setFileSelected] = useState([]);
  const [dataInitial, setDataInitial] = useState({
    startDate: dayjs(today).format("YYYY/MM/DD HH:mm"),
    endDate: dayjs(today).format("YYYY/MM/DD HH:mm"),
    description: "",
    countDays: 1,
  });
  const [isAPIpending, setIsAPIpending] = useState(false);
  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });
  const allowanceRate = 500;
  const allowanceRateInternational = 4000;
  const govermentAllowanceRate = 270;
  const govermentAllowanceRateInternational = 3100;
  const [formData, setFormData] = useState({
    nameExpense: "",
    isInternational: false,
    allowanceRate: allowanceRate,
    allowanceRateGoverment: govermentAllowanceRate,
    listExpense: [dataInitial],
    remark: "",
    typeExpenseName: typeExpense,
    sumAllowance: 500,
    sumSurplus: 230,
    sumDays: 1,
    sumNet: 500,
    approver: {},
    cc_email: [],
    costCenter: "",
  });

  const MAX_FILE_SIZE = 512000; //500KB

  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const validationSchema = Yup.object().shape({
    nameExpense: Yup.string().required("โปรดกรอกข้อมูล"),
    approver: Yup.string().required("โปรดกรอกข้อมูล"),
    isInternational: Yup.boolean().required("โปรดกรอกข้อมูล"),
    listExpense: Yup.array().of(
      Yup.object().shape({
        startDate: Yup.string().required("โปรดกรอกข้อมูล"),
        endDate: Yup.string().required("โปรดกรอกข้อมูล"),
        description: Yup.string().required("โปรดกรอกข้อมูล"),
        countDays: Yup.string().required("โปรดกรอกข้อมูล"),
      })
    ),
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (value) {
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
    remark: Yup.string(),
    sumAllowance: Yup.string().required("โปรดกรอกข้อมูล"),
    sumSurplus: Yup.string().required("โปรดกรอกข้อมูล"),
    sumDays: Yup.string().required("โปรดกรอกข้อมูล"),
    sumNet: Yup.string().required("โปรดกรอกข้อมูล"),
    allowanceRate: Yup.string().default(allowanceRate),
    allowanceRateGoverment: Yup.string().default(govermentAllowanceRate),
    costCenter: Yup.string().required("โปรดกรอกข้อมูล"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const { append, remove, fields } = useFieldArray({
    control: control,
    name: "listExpense",
  });

  const checkValidation = (error) => {
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: "โปรดกรอกข้อมูล",
      type: "error",
    });
  };

  useEffect(() => {
    if (userProfile && userProfile.managerLV1Id) {
      setFormData({
        ...formData,
        approver: userProfile.managerLV1Id,
        costCenter: userProfile.costCenter,
      });
      setValue("approver", userProfile.managerLV1Id);
      setValue("costCenter", userProfile.costCenter);
    }
  }, [userProfile, manager]);

  useEffect(() => {
    if (getValues("isInternational")) {
      setValue("allowanceRate", allowanceRateInternational);
      setValue("allowanceRateGoverment", govermentAllowanceRateInternational);
    } else {
      setValue("allowanceRate", allowanceRate);
      setValue("allowanceRateGoverment", govermentAllowanceRate);
    }
    calculateSum(getValues("listExpense"));
  }, [watch("isInternational")]);

  useEffect(() => {
    calculateSum(getValues("listExpense"));
  }, [watch("listExpense.length")]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const thumbs = fileSelected.map((file) => (
    <img key={file.name} src={file.preview} />
  ));

  const handleIsNan = (value) => {
    return isNaN(value) ? 0 : value;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickMenuTax = (event) => {
    const { name } = event.target;
    setAnchorEl(event.currentTarget);
    if (name === "tax") {
      setListMenuTaxOrWithholding(listMenuTax);
    } else {
      setListMenuTaxOrWithholding(listMenuWithholding);
    }
  };

  const calculateSum = (array) => {
    let sumDays = array.reduce((accumulator, object) => {
      return accumulator + parseFloat(object.countDays);
    }, 0);
    let sumAllowance = sumDays * parseFloat(getValues("allowanceRate"));
    let totalGovermentAllowance =
      Math.ceil(sumDays) * parseFloat(getValues("allowanceRateGoverment"));

    setValue("sumAllowance", sumAllowance);
    setValue(
      "sumSurplus",
      totalGovermentAllowance - sumAllowance < 0
        ? sumAllowance - totalGovermentAllowance
        : 0
    );
    setValue("sumDays", sumDays);
    setValue("sumNet", sumAllowance);
  };

  const createFormData = (statusId) => {
    let data = new FormData();
    data.append("nameExpense", getValues("nameExpense"));
    data.append("isInternational", getValues("isInternational") ? 1 : 0);
    data.append("listExpense", JSON.stringify(getValues("listExpense")));
    if (getValues("file")) {
      data.append("file", getValues("file"), getValues("file.path"));
    }
    data.append("remark", getValues("remark"));
    data.append("typeExpense", 2);
    data.append("typeExpenseName", typeExpense);
    data.append("lastUpdateDate", dayjs(today).format("YYYY/MM/DD HH:mm"));
    data.append("status", statusId);
    data.append("sumAllowance", getValues("sumAllowance"));
    data.append("sumSurplus", getValues("sumSurplus"));
    data.append("sumDays", getValues("sumDays"));
    data.append("sumNet", getValues("sumNet"));
    data.append("net", getValues("sumNet"));
    data.append("idEmpApprover", getValues("approver"));
    data.append(
      "cc_email",
      JSON.stringify(
        getValues("cc_email")
          ? getValues("cc_email")
              .map((e) => e.email)
              .join(";")
          : null
      )
    );
    data.append(
      "idPosition",
      userProfile.idPositions === null
        ? JSON.stringify(null)
        : userProfile.idPositions
    );
    data.append("costCenter", getValues("costCenter"));
    return data;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOnClickCleardata = () => {
    reset(formData);
  };

  const handleOnClickSavedraft = async () => {
    setIsAPIpending(true);
    let data = createFormData(1);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmp) {
      result = await dispatch(addExpenseAllowance(userProfile.idEmp, data));
    }
    if (result) {
      if (result.status === 200) {
        setIsAPIpending(false);
        handleChangeAlertType("success");
        history.push(`/expense/Allowance/Draft/${result.data.idExpense}`);
      } else {
        setIsAPIpending(false);
        handleChangeAlertType("error");
      }
    }
  };

  const handleOnClickSent = async () => {
    setIsAPIpending(true);
    let data = createFormData(2);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmp) {
      result = await dispatch(addExpenseAllowance(userProfile.idEmp, data));
    }
    if (result.status === 200) {
      setIsAPIpending(false);
      handleChangeAlertType("success");
      handleOpenAlert();
      history.push(`/expense`);
    } else {
      setIsAPIpending(false);
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  return (
    <Box>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      <form>
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">
              {t("GeneralInformation")}
            </StyledHeadLabel>
          </div>
          <div>
            <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
              {t("Title")}
            </StyledHeadLabel>
            <FormControl
              fullWidth
              error={errors && errors.nameExpense ? true : false}
            >
              {errors && errors.nameExpense && errors.nameExpense.message && (
                <FormHelperText error>
                  {errors.nameExpense.message}
                </FormHelperText>
              )}
              <Controller
                name="nameExpense"
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <TextFieldTheme
                      {...field}
                      name="nameExpense"
                      inputProps={{ maxLength: 200 }}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                    <Typography
                      component="p"
                      textAlign="right"
                      variant="caption"
                      color="text.secondary"
                    >{`${field.value.length}/200`}</Typography>
                  </>
                )}
              />
            </FormControl>
          </div>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectCC control={control} errors={errors} />
              </Grid>
              <Grid item xs={12}>
                <div>
                  <FormControl
                    fullWidth
                    error={errors && errors.costCenter ? true : false}
                  >
                    {errors &&
                      errors.costCenter &&
                      errors.costCenter.message && (
                        <FormHelperText error>
                          {errors.costCenter.message}
                        </FormHelperText>
                      )}
                    <SelectCostCenter
                      handleChange={setValue}
                      control={control}
                      errors={errors}
                    />
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={errors && errors.approver ? true : false}
                >
                  {errors && errors.approver && errors.approver.message && (
                    <FormHelperText error>
                      {errors.approver.message}
                    </FormHelperText>
                  )}
                  <Controller
                    name="approver"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <SelectApprover
                          {...field}
                          idApprover={getValues("approver")}
                          handleChange={(e, value) => {
                            setValue("approver", value);
                          }}
                          position={userProfile && userProfile.idCompany === 3}
                        />
                      </>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("ExpenseLocation")}
                </StyledHeadLabel>
                <Controller
                  name="isInternational"
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledToggleButtonGroup
                        {...field}
                        exclusive
                        aria-label="filter"
                        onChange={(e, value) => {
                          setValue("isInternational", value);
                        }}
                      >
                        <ToggleButton
                          name="isInternational"
                          value={false}
                          aria-label="local"
                          key="local"
                          style={{
                            width: "100%",
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                          }}
                        >
                          {t("Local")}
                        </ToggleButton>
                        <ToggleButton
                          name="isInternational"
                          value={true}
                          aria-label="international"
                          key="international"
                          style={{
                            width: "100%",
                            borderTopRightRadius: 8,
                            borderBottomRightRadius: 8,
                          }}
                        >
                          {t("International")}
                        </ToggleButton>
                      </StyledToggleButtonGroup>
                    </>
                  )}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("ExpenseList")}</StyledHeadLabel>
            <div>
              <ButtonBlue
                size="small"
                startIcon={<AddRoundedIcon />}
                variant="outlined"
                onClick={() => {
                  append(dataInitial);
                }}
              >
                {t("Add")}
              </ButtonBlue>
            </div>
          </div>

          <TableContainer>
            <Table
              style={{
                minWidth: 650,
                borderSpacing: "0 4px",
                borderCollapse: "separate",
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {rowHeader.map((item) => (
                    <StyledTableCell className={item.className}>
                      <StyledHeadLabel
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        {t(`${item.caption}`)}
                      </StyledHeadLabel>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.length > 0 &&
                  fields
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <RowItemExpense
                        key={item.id}
                        count={fields.length}
                        data={item}
                        index={index}
                        open={open}
                        control={control}
                        errors={errors}
                        handleDeleteRow={remove}
                        setValue={setValue}
                        getValues={getValues}
                        calculateSum={calculateSum}
                      />
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[
              10,
              20,
              30,
              40,
              50,
              { label: "ทั้งหมด", value: -1 },
            ]}
            component="div"
            count={watch("listExpense.length")}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("RowPerPage")}
          />
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("SummaryList")}</StyledHeadLabel>
          </div>
          <div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("TotalDays")}&nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(watch("sumDays"))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Days")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("AllowancePerDay")}&nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(watch("allowanceRate"))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Baht")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("GorvermentAllowanceRate")}&nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(watch("allowanceRateGoverment"))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Baht")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("SurplusAllowance")}&nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(watch("sumSurplus"))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Baht")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography variant="h5">
                {t("NetAllowance")}&nbsp;&nbsp;
              </Typography>
              <Typography variant="h4">
                {utils.numberWithCommas(watch("sumNet"))}
              </Typography>
              <Typography variant="h5">&nbsp;&nbsp;{t("Baht")}</Typography>
            </div>
          </div>
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("AttachFile")}</StyledHeadLabel>
          </div>
          {!watch("file") ? (
            <Controller
              name={"file"}
              control={control}
              render={({ field }) => (
                <>
                  <StyledDropzone>
                    <Dropzone
                      accept="image/jpeg, image/png, application/pdf"
                      maxFiles={1}
                      multiple={false}
                      maxSize={3145728}
                      onDrop={(acceptedFiles, rejectedFiles) => {
                        if (acceptedFiles.length > 0) {
                          field.onChange(acceptedFiles[0]);
                        }
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            className: "dropzone-upload-file",
                          })}
                        >
                          <div className="inner-dropzone">
                            <input {...getInputProps()} />
                            {/* <Fragment>{thumbs}</Fragment> */}
                            <div style={{ textAlign: "center" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <img alt="add" src={GalleryIcon} />
                              </div>
                              <Typography
                                variant="caption"
                                style={{ lineHeight: 0.5, color: "#999999" }}
                              >
                                ไฟล์ *.jpeg, *.jpg, *.png จำนวน 1 ไฟล์
                                ขนาดไม่เกิน 500 KB
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </StyledDropzone>
                </>
              )}
            />
          ) : (
            <Box style={{ position: "relative", textAlign: "center" }}>
              <IconButton
                style={{ position: "absolute", margin: 7, right: 0 }}
                color="error"
                onClick={() => {
                  setValue("file", null);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <div
                style={{
                  padding: 15,
                  backgroundColor: "#f9f9fb",
                  display: "flex",
                  borderRadius: 15,
                }}
              >
                <FilePresentIcon
                  height="24"
                  style={{
                    marginRight: 10,
                  }}
                ></FilePresentIcon>
                <Typography>{watch("file").path}</Typography>
              </div>
            </Box>
          )}
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("RemarkMore")}</StyledHeadLabel>
          </div>
          <Controller
            name={"remark"}
            control={control}
            render={({ field }) => (
              <>
                <TextFieldTheme {...field} multiline rows={4} />
                <Typography
                  component="p"
                  textAlign="right"
                  variant="caption"
                  color="text.secondary"
                >{`${field.value ? field.value.length : 0}/500`}</Typography>
              </>
            )}
          />
        </div>
        <StyledDivider />
        <ActionButtons
          handleOnClickCleardata={handleOnClickCleardata}
          handleSubmitDraft={handleSubmit(
            handleOnClickSavedraft,
            checkValidation
          )}
          handleSubmitSent={handleSubmit(handleOnClickSent, checkValidation)}
          isAPIpending={isAPIpending}
        />
      </form>
    </Box>
  );
};

export default PerDiem;
