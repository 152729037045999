import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../shared/general/Drawer";
import DatePickerThai from "../../../shared/general/DatePickerThai";
import DatePickerEng from "../../../shared/general/DatePickerEng";
import FileSelected from "./fileSelected";
import UploadFile from "./uploadFile";
import ShowResponse from "../show-response";
import { getAllUsersVendor } from "../../../../../actions/vendor";
import { dumpExcelBillingStatement, getExcelBillingStatementById } from "../../../../../actions/dumpExcel";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyleSelect = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    padding: "0px 12px",
    "& .MuiSelect-select": {
      padding: "13.5px 0px",
    },
  },
});

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const StyledLoading = styled("div")({
  height: 200,
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledFormLabel = styled(FormLabel)({
  fontWeight: 600,
  fontSize: 14,
  color: "#637381",
  marginBottom: 8,
});

const uploadBills = (props) => {
  const { open, handleClose, handleOpenAlert, vendorContract, reload } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [fileSelected, setFileSelected] = useState(null);
  const [companySelected, setCompanySelected] = useState(null);
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [openLoading, setOpenLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [selectedDumpList, setSelectedDumpList] = useState(null);

  useEffect(() => {
    setErrorResponse(null);
    setFileSelected(null);
    setCompanySelected(null);
    setFormFileSelected(null);
    setOpenLoading(null);
    setSelectedDumpList(null);
  }, [open]);

  useEffect(() => {
    setErrorResponse(null);
    setFileSelected(null);
    setCompanySelected(null);
    setFormFileSelected(null);
    setOpenLoading(null);
    setErrorResponse(null);
  }, [selectedDumpList]);

  const handleSetFileSelected = (mode, value) => {
    setFileSelected({ ...fileSelected, [mode]: value });
  };

  const handleClickUpload = async () => {
    try {
      setLoadingUpload(true);
      setOpenLoading(true);

      let result = await dispatch(
        dumpExcelBillingStatement(formFileSelected, {
          idCompany: companySelected,
          document: fileSelected.name,
          billsMonth: billsMonth.billsPeriod,
          billsStatementName: billsStatementName.billsStatementName
        })
      );

      if (result) {
        handleClose();
        handleOpenAlert("success");
        formik.setFieldValue('billsStatementName', null);
        await dispatch(getExcelBillingStatementById(companySelected));
      };
    } catch (error) {
      console.error("Error during upload:", error);
      handleOpenAlert("error");
    } finally {
      setLoadingUpload(false);
      setOpenLoading(false);
      reload();
    }
  };

  const handleChange = (event) => {
    setCompanySelected(event.target.value);
  };

  const handleCloseLoading = () => {
    handleClose();
    dispatch(getAllUsersVendor());
  };

  const validationSchema = yup.object({
    uploadMonth: yup.date().required('Upload month is required'),
  });

  const [billsMonth, setBillsMonth] = useState({ billsPeriod: dayjs().format('YYYY-MM-DD') });
  const [billsStatementName, setBillsStatementName] = useState(null);

  const formik = useFormik({
    initialValues: {
      billsPeriod: new Date(),
      billsStatementName: ``,
    },
    validationSchema: validationSchema,
  });

  const handleFieldChange = (field, value) => {
    if (field === 'billsPeriod') {
      const formattedDate = dayjs(value).format('YYYY-MM-DD');
      formik.setFieldValue('billsPeriod', formattedDate);
      setBillsMonth({ billsPeriod: formattedDate });
    } else {
      formik.setFieldValue('billsStatementName', value);
      setBillsStatementName({ billsStatementName: value });
    }
  };

  return (
    <DrawerCustom
      title={`${t("Upload")}`}
      anchor={"right"}
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {openLoading ? (
              <StyledLoading>
                <Typography variant="h6" color="text.third" gutterBottom>
                  {t("Saving_Data")}...
                </Typography>
                <div>
                  <CircularProgress />
                </div>
              </StyledLoading>
            ) : (
              <Fragment>
                {errorResponse ? (
                  <ShowResponse
                    errorResponse={errorResponse}
                    handleCloseLoading={handleCloseLoading}
                  />
                ) : (
                  <Fragment>
                    {vendorContract && (
                      <Fragment>
                        <div className="select-company">
                          <StyleSelect fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              {t("Select_Company")}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              name="dump-list"
                              label={t("Select_Company")}
                              onChange={handleChange}
                            >
                              {vendorContract.map((item) => (
                                <MenuItem value={item.idCompany}>
                                  {item.companyName}
                                </MenuItem>
                              ))}
                            </Select>
                          </StyleSelect>
                        </div>
                      </Fragment>
                    )}
                    {(companySelected) && (
                      <>
                        <div style={{ marginTop: 18, marginBottom: 5 }}>
                          <StyledFormLabel component="legend">{t("ItemName")}</StyledFormLabel>
                          <TextFieldTheme
                            style={{ marginBottom: 8 }}
                            name="billsStatementName"
                            value={formik.values.billsStatementName}
                            onChange={(e) => handleFieldChange('billsStatementName', e.target.value)}
                          />
                        </div>
                        <div style={{ marginTop: 14, marginBottom: 5 }}>
                          <StyledFormLabel component="legend">{t("month")}</StyledFormLabel>
                          {localStorage.getItem("language") === "en" ? (
                            <DatePickerEng
                              views={["month", "year"]}
                              inputFormat="MMMM YYYY"
                              value={formik.values.billsPeriod}
                              onChange={(date) => handleFieldChange('billsPeriod', date)}
                              name="billsPeriod"
                              variant="outlined"
                            />
                          ) : (
                            <DatePickerThai
                              views={["month", "year"]}
                              inputFormat="MMMM yyyy"
                              value={formik.values.billsPeriod}
                              onChange={(date) => handleFieldChange('billsPeriod', date)}
                              name="billsPeriod"
                              variant="outlined"
                            />
                          )}
                        </div>
                        <StyledImportFile>
                          <UploadFile
                            fileSelected={fileSelected}
                            companySelected={companySelected}
                            setFormFileSelected={setFormFileSelected}
                            setFileSelected={setFileSelected}
                          />
                        </StyledImportFile>
                      </>
                    )}
                    {fileSelected && (
                      <FileSelected
                        fileSelected={fileSelected}
                        handleClickUpload={handleClickUpload}
                        handleSetFileSelected={handleSetFileSelected}
                        loadingUpload={loadingUpload}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Grid>
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default uploadBills;
