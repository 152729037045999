import React, { useState } from "react";
import useTable from "../../../../shared/table/useTable";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";

import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const useStyles = makeStyles((theme) => ({
  waiting: {
    color: "#ffa000",
    backgroundColor: "#fff8e1",
  },
  approved: {
    color: "#388e3c",
    backgroundColor: "#e8f5e9",
  },
  rejected: {
    color: "#d32f2f",
    backgroundColor: "#ffebee",
  },
  type: {
    color: "#0288d1",
    backgroundColor: "#e1f5fe",
  },
}));

const headCells = [
  { id: "image", label: "" },
  { id: "fullName", label: "Employee" },
  { id: "jobPosition", label: "Position" },
  { id: "type", label: "Type" },
  { id: "dateStart", label: "Date Start" },
  { id: "dateEnd", label: "Date End" },
  { id: "status", label: "Status" },
  { id: "actions", label: "" },
];

function DataTable(props) {
  const { requests, isLoading } = props;
  const classes = useStyles();

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(requests, headCells, filterFn);

  const MenuList = (item) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Box>
        <IconButton
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>จัดการเวลาทำงาน</MenuItem>
          <MenuItem onClick={handleClose}>แก้ไขเวลาทำงาน</MenuItem>
          <MenuItem sx={{ color: "#f44336" }} onClick={handleClose}>
            ลบเวลาทำงาน
          </MenuItem>
        </Menu>
      </Box>
    );
  };

  return (
    <div>
      <TblContainer>
        <TblHead />
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={8} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            (recordsAfterPagingAndSorting().length > 0 &&
              recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.requestId}>
                  <TableCell>
                    <Avatar
                      alt={item.fullName}
                      src="/static/images/avatar/1.jpg"
                    />
                  </TableCell>
                  <TableCell>{item.fullName}</TableCell>
                  <TableCell>{item.jobPosition}</TableCell>
                  <TableCell>
                    <Chip className={classes.type} label={item.type} />
                  </TableCell>
                  <TableCell>
                    {dayjs(item.startDate).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                  <TableCell>
                    {dayjs(item.endDate).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                  <TableCell>
                    <Chip
                      className={
                        item.status === "Waiting Approve"
                          ? classes.waiting
                          : item.status === "Approved"
                          ? classes.approved
                          : item.status === "Rejected"
                          ? classes.rejected
                          : null
                      }
                      label={item.status}
                    />
                  </TableCell>
                  <TableCell>
                    <MenuList item={item} />
                  </TableCell>
                </TableRow>
              ))) || (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No Data
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TblContainer>
      <TblPagination />
    </div>
  );
}

export default DataTable;
