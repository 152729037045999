import {
  EMPLOYEES_FETCHING,
  EMPLOYEES_FAILED,
  EMPLOYEES_SUCCESS,
} from "../actions/types";

const initialState = {
  result: [],
  isFetching: true,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEES_FETCHING:
      return { ...state, result: [], isFetching: true, isError: false };
    case EMPLOYEES_FAILED:
      return { ...state, result: [], isFetching: false, isError: true };
    case EMPLOYEES_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
