import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import StyledCard from "../../../shared/general/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";

import Icon from "@mui/material/Icon";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";

const StyledPaper = styled(Paper)({
  padding: 16,
  paddingLeft: 36,
  display: "flex",
  position: "relative",
});

const StyledBox = styled(Box)({
  position: "absolute",
  top: -20,
  left: 2,
  "& svg": {
    fontSize: 38,
    color: "#007afe",
  },
});

const StyledAvatar = styled(Avatar)({
  width: 60,
  height: 60,
});

export default function CardAbsent() {
  return (
    <StyledCard fullheight="true">
      <CardContent style={{ paddingBottom: 0 }}>
        <Box>
          <Typography variant="h4" className={`cardTitle`} gutterBottom>
            ขาดงานสะสม
          </Typography>
          <Stack spacing={6} style={{ marginTop: 32, marginBottom: 16 }}>
            <StyledPaper variant="outlined">
              <StyledBox>
                <LooksOneIcon />
              </StyledBox>

              <StyledAvatar
                alt={"username"}
                src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
              />
              <div style={{ width: "100%" }}>
                <Typography align="center" variant="h6">
                  Lauryn Zimmerman
                </Typography>
                <Typography align="center" variant="body1">
                  Department
                </Typography>
              </div>
            </StyledPaper>
            <StyledPaper variant="outlined">
              <StyledBox>
                <LooksTwoIcon />
              </StyledBox>
              <StyledAvatar
                alt={"username"}
                src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
              />
              <div style={{ width: "100%" }}>
                <Typography align="center" variant="h6">
                  Lauryn Zimmerman
                </Typography>
                <Typography align="center" variant="body1">
                  Department
                </Typography>
              </div>
            </StyledPaper>
            <StyledPaper variant="outlined">
              <StyledBox>
                <Looks3Icon />
              </StyledBox>
              <StyledAvatar
                alt={"username"}
                src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
              />
              <div style={{ width: "100%" }}>
                <Typography align="center" variant="h6">
                  Lauryn Zimmerman
                </Typography>
                <Typography align="center" variant="body1">
                  Department
                </Typography>
              </div>
            </StyledPaper>
          </Stack>
        </Box>
      </CardContent>
    </StyledCard>
  );
}
