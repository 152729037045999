import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  Paper,
  Tabs,
  Tab,
  MenuItem,
  Box,
  TextField,
  styled,
  Typography,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";

import DashboardIcon from "@mui/icons-material/Dashboard";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";

import OverviewPanel from "./overviewPanel";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import {
  getDashboardCost,
  getDashboardOvertime,
  getDashboardOverview,
  getDashboardWorkingtime,
} from "../../../../actions/dashboard";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import OvertimePanel from "./overtimePanel";
import WorkingtimePanel from "./workingTimePanel";
import CostPanel from "./costPanel";
import { getAllDepartments } from "../../../../actions/department";

const StyledRoot = styled(Box)({
  "& .filter-container": {
    marginBottom: "24px",
    display: "flex",
    "& .filter-left": {
      flexGrow: "1",
      display: "flex",
      gap: "8px",
    },
    "& .filter-right": {
      display: "flex",
      gap: "8px",
    },
    "& .MuiInputBase-root": {
      minWidth: "150px",
      backgroundColor: "#ffffff",
      "& .MuiSelect-select": {
        "&:focus": {
          backgroundColor: "#ffffff",
        },
      },
    },
    "& .MuiInputLabel-root": {
      color: "#919eab",
    },
    "& .select-medium": {
      minWidth: "250px",
    },
  },
  "& .tabs-container": {
    marginBottom: "24px",
  },
});

const TabsStyled = styled(Tabs)({
  width: "fit-content",
  maxWidth: "1200px",
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
  borderRadius: "16px",
  "& .tab": {
    backgroundColor: "#FFFFFF",
    height: "fit-content",
  },
  "& .TabBorderRight": {
    borderRight: "1px solid rgba(0,0,0,0.1)",
  },
  "& span": {
    display: "none",
  },
  "& .Mui-selected": {
    backgroundColor: "#007afe",
    color: "white !important",
    border: "1px solid #007afe",
  },
});

const Overview = () => {
  const dispatch = useDispatch();
  // const [month, setMonth] = useState(dayjs().get("month")+1);
  const [month, setMonth] = useState(dayjs().get("month") + 1);
  const [year, setYear] = useState(dayjs().get("year"));
  const [tabValue, setTabValue] = useState("overviewAdmin");

  const [department, setDepartment] = useState("");
  const [section, setSection] = useState("");
  const { t, i18n } = useTranslation();

  const { result: dashboardOverview } = useSelector(
    (state) => state.dashboardOverview
  );
  const { result: dashboardOvertime } = useSelector(
    (state) => state.dashboardOvertime
  );
  const { result: dashboardWorkingtime } = useSelector(
    (state) => state.dashboardWorkingtime
  );
  const { result: dashboardCost } = useSelector((state) => state.dashboardCost);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: departmentStore } = useSelector((state) => state.department);

  useEffect(() => {
    if (tabValue === "overviewAdmin") {
      dispatch(
        getDashboardOverview({
          mode: "manager",
          month: month,
          year: year,
          idDepartment: department ? department : undefined,
          idSection: section ? section : undefined,
        })
      );
    } else if (tabValue === "dashboardOT") {
      dispatch(
        getDashboardOvertime({
          mode: "manager",
          month: month,
          year: year,
          idDepartment: department ? department : undefined,
          idSection: section ? section : undefined,
        })
      );
    } else if (tabValue === "dashboardWorkingTime") {
      dispatch(
        getDashboardWorkingtime({
          mode: "manager",
          month: month,
          year: year,
          idDepartment: department ? department : undefined,
          idSection: section ? section : undefined,
        })
      );
    } else if (tabValue === "dashboardCost") {
      dispatch(
        getDashboardCost({
          mode: "manager",
          month: month,
          year: year,
          idDepartment: department ? department : undefined,
          idSection: section ? section : undefined,
        })
      );
    }
    // } else if(menuValue === 2) {
    //   dispatch(getDashboardWorkingtime({mode: "admin", month: month, year: year}))
    // } else if(menuValue === 3) {
    //   dispatch(getDashboardCost({mode: "admin", month: month, year: year}));
    // }
  }, [tabValue, department, section, month, year]);

  useEffect(() => {
    if (userProfile) {
      dispatch(getAllDepartments({ idCompany: userProfile.idCompany }));
    }
  }, [userProfile]);

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{
          marginTop: "20px",
          height: "fit-content",
          minHeight: "calc(100vh - 100px)",
          paddingBottom: "40px",
        }}
      >
        {/* <Paper style={{ padding:"10px 20px", width:"fit-content", borderRadius:"16px" }}> */}

        <Box className="filter-container">
          <Box className="filter-left">
            <FormControl variant="filled">
              <InputLabel shrink>{t("Division")}</InputLabel>
              <Select
                className="select-medium"
                label={t("Department")}
                displayEmpty
                disableUnderline
                value={department}
                onChange={(e) => {
                  setDepartment(e.target.value);
                  setSection("");
                }}
              >
                <MenuItem value={""}>{t("All")}</MenuItem>
                {departmentStore &&
                  departmentStore.map((d) => (
                    <MenuItem key={d.idDepartment} value={d.idDepartment}>
                      {d.departmentName}{" "}
                      {d.divisionName ? `(${d.divisionName})` : null}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl variant="filled">
              <InputLabel shrink>{t("Section")}</InputLabel>
              <Select
                className="select-medium"
                label="ส่วน"
                notched
                displayEmpty
                disableUnderline
                value={section}
                onChange={(e) => {
                  setSection(e.target.value);
                }}
              >
                <MenuItem value={""}>{t("All")}</MenuItem>
                {departmentStore &&
                  department &&
                  departmentStore
                    .find((d) => d.idDepartment === department)
                    .sections.map((s) => (
                      <MenuItem key={s.idSection} value={s.idSection}>
                        {s.sectionName}{" "}
                        {s.sectionName ? `(${s.sectionName})` : null}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Box>

          <Box className="filter-right">
            <FormControl variant="filled">
              <InputLabel>{t("Month")}</InputLabel>
              <Select
                label={t("Month")}
                displayEmpty
                disableUnderline
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              >
                {[...Array(12)].map((_, index) => {
                  if (
                    dayjs()
                      .set("month", index)
                      .set("year", year)
                      .isSameOrAfter(
                        dayjs().set("month", 1).set("year", 2023)
                      ) &&
                    !dayjs()
                      .set("month", index)
                      .set("year", year)
                      .isAfter(dayjs())
                  ) {
                    return (
                      <MenuItem key={index} value={index + 1}>
                        {dayjs().month(index).format("MMMM")}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
            <FormControl variant="filled">
              <InputLabel>{t("Year")}</InputLabel>
              <Select
                label={t("Year")}
                displayEmpty
                disableUnderline
                value={year}
                onChange={(event) => {
                  if (
                    dayjs()
                      .set("month", month - 1)
                      .set("year", parseInt(event.target.value))
                      .isBefore(dayjs().set("month", 1).set("year", 2023))
                  ) {
                    setMonth(2);
                  } else if (
                    dayjs()
                      .set("month", month - 1)
                      .set("year", parseInt(event.target.value))
                      .isAfter(dayjs())
                  ) {
                    setMonth(dayjs().get("month") + 1);
                  }
                  setYear(event.target.value);
                }}
              >
                {[...Array(dayjs().format("YYYY") - 2023 + 1)].map(
                  (_, index) => (
                    <MenuItem
                      key={index}
                      value={Number(
                        dayjs().subtract(index, "year").format("YYYY")
                      )}
                    >
                      {parseInt(dayjs().format("YYYY")) - index}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box className="tabs-container">
          <TabsStyled
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
          >
            <Tab
              className="tab TabBorderRight"
              icon={<DashboardIcon />}
              iconPosition="start"
              label={t("Overview")}
              value="overviewAdmin"
            />
            <Tab
              className="tab TabBorderRight"
              icon={<Filter1Icon />}
              iconPosition="start"
              label={t("Overtime")}
              value="dashboardOT"
            />
            <Tab
              className="tab TabBorderRight"
              icon={<Filter2Icon />}
              iconPosition="start"
              label={t("WorkTimeOverview")}
              value="dashboardWorkingTime"
            />
            <Tab
              className="tab"
              icon={<Filter3Icon />}
              iconPosition="start"
              label={t("CostOverview")}
              value="dashboardCost"
            />
          </TabsStyled>
        </Box>
        {/* </Paper> */}
        {tabValue === "overviewAdmin" && (
          <Box>
            {dashboardOverview ? (
              <OverviewPanel month={month} year={year} />
            ) : (
              <Typography textAlign="center">{t("LoadingData")}...</Typography>
            )}
          </Box>
        )}
        {tabValue === "dashboardOT" && (
          <Box>
            {dashboardOvertime ? (
              <OvertimePanel month={month} year={year} />
            ) : (
              <Typography textAlign="center">{t("LoadingData")}...</Typography>
            )}
          </Box>
        )}
        {tabValue === "dashboardWorkingTime" && (
          <Box>
            {dashboardWorkingtime ? (
              <WorkingtimePanel month={month} year={year} />
            ) : (
              <Typography textAlign="center">{t("LoadingData")}...</Typography>
            )}
          </Box>
        )}
        {tabValue === "dashboardCost" && (
          <Box>
            {dashboardCost ? (
              <CostPanel month={month} year={year} />
            ) : (
              <Typography textAlign="center">{t("LoadingData")}...</Typography>
            )}
          </Box>
        )}
        {/* {tabValue === "overviewAdminN" && <OverviewPanel />} */}
        {/* {tabValue === "dashboardOT" && <DashboardOT />} */}
        {/* {tabValue === "dashboardWorkingTime" && <DashboardWorkingTime />} */}
        {/* {tabValue === "dashboardCost" && <DashboardCost />} */}
      </Container>
    </StyledRoot>
  );
};

export default Overview;
