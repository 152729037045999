import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Card, Grid, Typography } from "@mui/material";
import FeedIcon from "@mui/icons-material/Feed";
import { useHistory } from "react-router-dom";

const StyleCard = styled(Card)({
  boxShadow: "none",
  borderRadius: 10,
  border: "1px solid #919eab3d",
  padding: "10px 16px 10px 16px",
  "&:hover": {
    cursor: "pointer",
    transition:
      "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
    transform: "translateY(-5px)",
  },
});

//tax name data
const taxList = [
  { id: 1, label: "ภงด. 91", path: "/pnd91" },
  { id: 2, label: "50 ทวิ", path: "/50tawi" },
  { id: 3, label: "ภงด. 1 ก.", path: "/pnd1kor" },
];

function TaxList() {
  const history = useHistory();

  return (
    <div>
      <Grid container spacing={2}>
        {taxList.map((item) => (
          <Grid item xs={12} md={3} lg={2}>
            <StyleCard key={item.id}>
              <Grid container alignItems="center">
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={() => history.push(item.path)}
                >
                  <FeedIcon
                    sx={{
                      marginRight: 2,
                      backgroundColor: "#eeeeee",
                      color: "#616161",
                      padding: "6px",
                      borderRadius: "50%",
                    }}
                  />
                  <Typography variant="body1">{item.label}</Typography>
                </Box>
              </Grid>
            </StyleCard>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default TaxList;
