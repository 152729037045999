import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Avatar, MenuItem, Typography } from "@mui/material";

import DialogDeleteEmployee from "./DialogDeleteEmployee";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .department": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 240,
    fontWeight: 600,
  },
  "& .reportToPosition": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "&.vendorName": {
    "& .MuiTypography-root": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 180,
    },
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${280}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 745px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
});

const ItemTable = (props) => {
  const { open, filterEmployee, handleOpenAlert } = props;
  const { t, i18n } = useTranslation();
  const { result: employeeList } = useSelector((state) => state.users);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSelected, setValueSelected] = React.useState(null);
  const [employeesList, setEmployeesList] = useState([]);

  const [dialogDeleteEmployeeConfig, setDialogDeleteEmployeeConfig] = useState({
    open: false,
    idEmp: null,
    employeesName: "",
  });

  useEffect(() => {
    rowsFilter();
  },[filterEmployee, employeeList]);

  const columns = [
    { id: "name", label: `${t("FullName")}`, minWidth: 240 },
    {
      id: "position",
      label: `${t("positionDepartment")}`,
      minWidth: 250,
    },
    {
      id: "vendorName",
      label: `${t("Vendor")}`,
      minWidth: 100,
    },
    {
      id: "employeeTypeName",
      label: `${t("Employee_Type")}`,
      minWidth: 150,
    },
    {
      id: "report_To_1",
      label: `${t("Report_To")} 1`,
      minWidth: 200,
    },
    {
      id: "report_To_2",
      label: `${t("Report_To")} 2`,
      minWidth: 200,
    }
  ];

  const rowsFilter = () => {
    if (employeeList) {
      if (filterEmployee.search == "" && filterEmployee.idVendor == "all") {
        setEmployeesList([...filterEmployee.status === "1" ? employeeList.active : employeeList.termainate]);
      } else {
        let resultFilter =
          filterEmployee.status === "1"
            ? [...employeeList.active]
            : [...employeeList.termainate];
        if (
          resultFilter &&
          filterEmployee.search &&
          filterEmployee.search.length > 0
        ) {
          resultFilter = resultFilter.filter((item) => {
            return (
              (item.firstname &&
                item.firstname.indexOf(filterEmployee.search) >= 0) ||
              (item.lastname &&
                item.lastname.indexOf(filterEmployee.search) >= 0) ||
              (item.employeeTypeEmployeeId &&
                item.employeeTypeEmployeeId.indexOf(filterEmployee.search) >= 0))
          });
        }

        if (filterEmployee.idVendor != "all") {
          resultFilter = resultFilter.filter((item) => {
            return item.idVendor == filterEmployee.idVendor;
          });
        }
        setEmployeesList([...resultFilter]);
      }
    } else {
      setEmployeesList([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteEmployee = (employee) => {
    handleCloseMenu();
    setDialogDeleteEmployeeConfig({
      open: true,
      idEmp: employee.idEmp,
      employeesName: `${employee.firstname} ${employee.lastname}`,
    });
  };

  return (
    <Main open={open} style={{ width: !open && "100%" }}>
      <StyledBox className={open ? "open" : "close"}>
        <TableContainer sx={{ width: "100%", height: 450 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledCellHeader
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledCellHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(employeesList && employeesList.length > 0) ?
                employeesList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <StyledRowContent key={`${page}-${index}`}>
                        <StyledCellContent className="sticky" key={"name"}>
                          <div className="fullname">
                            {filterEmployee.status === "1" && 
                              <IconButton
                                aria-label="more"
                                size="small"
                                style={{ marginRight: 8 }}
                                onClick={(event) => {
                                  setAnchorEl(event.currentTarget);
                                  setValueSelected(row);
                                }}
                              >
                                <MoreVertIcon fontSize="small" />
                              </IconButton>
                            }
                            <Avatar
                              src={
                                row.imageProfile ?
                                `${row.imageProfile}`
                                :
                                `${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`
                              }
                            />
                            <div>
                              <Typography>
                                {row.firstname} {row.lastname}
                              </Typography>
                              <Typography variant="body2" color="text.third">
                                {row.telephoneMobile &&
                                  `${row.telephoneMobile.replace(
                                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                                    "$1-$2-$3"
                                  )}`}
                              </Typography>
                            </div>
                          </div>
                        </StyledCellContent>
                        <StyledCellContent key={"position"}>
                          <Typography className="position">
                            {row.positionsName}
                          </Typography>
                          <Typography className="department" color="text.third">
                            {row.departmentName}
                          </Typography>
                        </StyledCellContent>
                        <StyledCellContent
                          key={"vendor"}
                          className="vendorName"
                        >
                          <Typography>{row.vendorName}</Typography>
                        </StyledCellContent>
                        <StyledCellContent
                          key={"employeeTypeName"}
                          className="vendorName"
                        >
                          <Typography>{row.employeeTypeName}</Typography>
                        </StyledCellContent>
                        <StyledCellContent key={"report_To"}>
                          <Typography className="reportTo">
                            {row.managerLV1Name}
                          </Typography>
                          <Typography
                            className="reportToPosition"
                            color="text.third"
                          >
                            {row.managerLV1Email}
                          </Typography>
                        </StyledCellContent>
                        <StyledCellContent>
                          <Typography className="reportTo">
                            {row.managerLV2Name}
                          </Typography>
                          <Typography
                            className="reportToPosition"
                            color="text.third"
                          >
                            {row.managerLV2Email}
                          </Typography>
                        </StyledCellContent>
                      </StyledRowContent>
                    );
                  })
                :
                <StyledRowContent>
                  <StyledCellContent colSpan={6}>
                    <Typography align="center">ไม่พบรายชื่อพนักงาน</Typography>
                  </StyledCellContent>
                </StyledRowContent>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={employeesList && employeesList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {valueSelected && (
          <StyledMenu
            id={`more-menu`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              component={NavLink}
              to={`/employees/${valueSelected.idEmp}/edit`}
            >
              <i className="far fa-user-edit" style={{ marginRight: 16 }}></i>
              <Typography>แก้ไขข้อมูล</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDeleteEmployee(valueSelected);
              }}
              style={{ color: "red" }}
            >
              <i className="far fa-trash-can" style={{ marginRight: 16 }}></i>
              <Typography style={{ color: "inherit" }}>
                {t("Delete_Employee")}
              </Typography>
            </MenuItem>
          </StyledMenu>
        )}
      </StyledBox>
      <DialogDeleteEmployee
        open={dialogDeleteEmployeeConfig.open}
        idEmp={dialogDeleteEmployeeConfig.idEmp}
        employeesName={dialogDeleteEmployeeConfig.employeesName}
        onClose={() => {
          setDialogDeleteEmployeeConfig({
            open: false,
            idEmp: null,
            employeesName: "",
          });
        }}
        handleOpenAlert={handleOpenAlert}
      />
    </Main>
  );
};

export default ItemTable;
