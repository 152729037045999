import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Grid, Typography, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import * as yup from "yup";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getAllCompaniesByVendor } from "../../../../actions/vendor";
import { exportExcelEmployeeInformationReport } from "../../../../actions/report";
// import { exportExcelEmployeeInformationReport } from "../../../../../actions/exportExcel";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  paddingBottom: "8px"
});

const DrawerEmployeeInformation = (props) => {

  const { drawerConfig, onClose } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [companyLists, setCompanyLists] = useState(null);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validateYupSchema = yup.object({
    company: yup.mixed().nullable().required(t("PleaseSelectInformation")),
  });

  const useHookForm = useForm({
    defaultValues: {
      company: null,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const onSubmit = async (data) => {
    const result = await exportExcelEmployeeInformationReport({ idCompany: data.company.idCompany, });

    if (result && result.status === 200) {
      const excelBlob = new Blob([result.data], {
        type: result.headers["content-type"],
      });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(excelBlob);
      downloadLink.download = `UniHR ${data.company.companyName} ณ ${dayjs().format("DD MMMM BBBB HH.mm.ssน.")}.xlsx`;
      downloadLink.click();
    }
  };

  // useEffect(() => {
  //   if (drawerConfig.isOpen) {
  //     dispatch(getAffiliateCompany())
  //     useHookForm.resetField("company", { defaultValue: { idCompany: null, companyName: t("All") } })
  //   }
  // }, [drawerConfig.isOpen])

  // useEffect(() => {
  //   if (affiliateList && affiliateList.length > 0) {
  //     const foundCompany = affiliateList.find(x => x.idCompany === userProfile.idCompany);
  //     const foundAllCompany = { idCompany: null, companyName: t("All") }
  //     if (foundCompany) {
  //       useHookForm.resetField("company", { defaultValue: foundCompany })
  //     } else {
  //       useHookForm.resetField("company", { defaultValue: foundAllCompany })
  //     }
  //   }
  // }, [affiliateList]);

  const fetchedCompanyByVendor = async () => {
    try {
      const response = await getAllCompaniesByVendor();
      if (response && response.data) {
        setCompanyLists(response.data);
      } else {
        setCompanyLists(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (drawerConfig.isOpen) {
      fetchedCompanyByVendor();
    }
  }, [drawerConfig.isOpen]);

  return (
    <DrawerCustom
      title={`${t("employeeCoreDate")}`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {`${t("Company")}`}
              </StyledHeadLabel>
              <Controller
                name="company"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    options={companyLists ? companyLists : []}
                    getOptionLabel={(option) => `${option.companyName}`}
                    isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={`${t("SelectCompany")}`}
                        onBlur={field.onBlur}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        error={fieldState.error ? true : false}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    noOptionsText={`${t("NoData")}`}
                    disableClearable
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue
                type="submit"
                variant="outlined"
                startIcon={<DownloadRoundedIcon />}
                disabled={useHookForm.formState.isSubmitting}
              // disabled={true}
              >
                {t("Download")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEmployeeInformation;