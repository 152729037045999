import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Box, Grid, MenuItem, Popper, TextField, Typography } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from '@tarzui/date-fns-be';
import { th } from "date-fns/locale";

import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import { getPersonnelLevelByidCompany } from "../../../../../../actions/jobLevel";
import jobLevelService from "../../../../../../services/jobLevel.service";
import companyService from "../../../../../../services/company.service";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { changeEmployeePersonnelLevel, getEmployeePersonnelLevelChange, getEmployeeProfile } from "../../../../../../actions/employee";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const DialogEdit = (props) => {
  const { open, toggleDrawer } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);

  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { t, i18n } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);

  const [personnelLevelList, setPersonnelLevelList] = useState([]);
  const [formData, setFormData] = useState({
    effectiveDate: new Date(),
    reason: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if(open){
      companyService.getLevelByCompanyId(employeeProfile.idCompany).then(res => {
        setPersonnelLevelList(res.data);
      })
    }
  }, [open]);

  const handleSave = async () => {
    setIsSaving(true);
    // console.log(formData);

    const cleansingData = {
      idEmp: employeeProfile.idEmp,
      effectiveDate: dayjs(formData.effectiveDate).format("YYYY-MM-DD"),
      idLevel: formData.idLevel,
      reason: formData.reason? formData.reason: null,
    }

    const result = await dispatch(changeEmployeePersonnelLevel(cleansingData));
    
    setIsSaving(false);

    if(result && result.status === 200) {
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
      dispatch(getEmployeePersonnelLevelChange(employeeProfile.idEmp));
      toggleDrawer(false)();
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }

  }

  return (
    <DrawerCustom
      title={`${t("Edit")} ${t("Level")}`}
      anchor={"right"}
      open={open}
      onClose={toggleDrawer(false)}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledContentLabel>{t("NewLevel")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12}>
            <StyledAutocomplete
              options={personnelLevelList}
              onChange={(event, newValue) => {
                console.log(newValue);
                setFormData({
                  ...formData,
                  ...newValue,
                });
              }}
              popupIcon={<i className="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => `(${option.groupLevelName}) ${option.levelName}`}
              renderOption={(props, option) => (
                <MenuItem {...props}>
                  <Box>
                    <Typography fontSize="14px" color="text.secondary">{option.groupLevelName}</Typography>
                    <Typography>{option.levelName}</Typography>
                  </Box>
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={t("SelectLevel")}
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={t("No_Data")}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel>{t("EffectiveDate")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
              <DatePicker
                value={formData.effectiveDate}
                inputFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    effectiveDate: date,
                  });
                }}
                renderInput={(params) => (
                  <StyledTextField fullWidth {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel>{t("Reason")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12}>
            <TextFieldTheme
              variant="outlined"
              name="reason"
              value={formData.reason}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="space-between" alignItems="center">
            <ButtonBlue variant="text" onClick={() => {toggleDrawer(false)()}}>{t("Cancel")}</ButtonBlue>
            <ButtonBlue disabled={isSaving} variant="contained" onClick={() => {handleSave()}}>{t("Save")}</ButtonBlue>
          </Grid>
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
