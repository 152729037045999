import { httpClient } from "./httpClient";

const getAllAreas = (searchDate, idCompany) => {
  if (idCompany) {
    return httpClient.get(`/areas?filter=${idCompany}&searchDate=${searchDate}`);
  } else {
    return httpClient.get(`/areas?searchDate=${searchDate}`);
  }
};
const getAreaDetail = (idSection) => {
  return httpClient.get(`/areas/section/${idSection}`);
};

export default {
  getAllAreas,
  getAreaDetail,
};
