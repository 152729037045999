import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, styled } from "@mui/material";

import LeftPanel from "./left";
import RightPanel from "./right";

import { getCompanyProfile } from "../../../../actions/company";
import { getUserProfile } from "../../../../actions/user";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .headerText": {
    fontSize: 12,
    color: "#9e9e9e",
  },
  "& .valueText": {
    wordBreak: "break-word",
  },
})

const CompanyProfilePage = () => {

  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: companyProfileStore } = useSelector((state) => state.companyProfile);

  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (userProfile) {
      dispatch(getCompanyProfile(userProfile.idCompany));
    }
  }, [userProfile]);

  useEffect(() => {
    if(companyProfileStore != null){
      setCompany({...companyProfileStore})
    }
  }, [companyProfileStore])

  return (
    <StyledRoot className="page">
      {company && <Container maxWidth="lg" style={{ paddingTop: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <LeftPanel data={company}/>
          </Grid>

          <Grid item xs={12} lg={8}>
            <RightPanel data={company}/>
          </Grid>
        </Grid>
      </Container>}
    </StyledRoot>
  )
}

export default CompanyProfilePage;