import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import EmpSlipInfo from "./info";
import TableSlip from "./table";

const useStyles = makeStyles((theme) => ({
  slip_section: {
    padding: theme.spacing(4),
    backgroundColor: "#fff",
    borderRadius: "15px",
    width: "850px",
  },
  confidential: {
    color: "#000",
    fontWeight: 700,
  },
  payslip: {
    color: "#C40000",
    fontWeight: 700,
    fontSize: "1.2rem !important",
  },
}));

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  marginTop: 16,
  marginBottom: 24,
});

const StyledSlipSection = styled("div")({
  width: 896,
  height: "1267.2px",
  margin: "auto",
  padding: 40,
});

const PaySlipDetail = (props) => {
  const { payslipDetail } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  //const { result: payslipDetail } = useSelector((state) => state.payslipDetail);
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot>
      {payslipDetail ? (
        <StyledSlipSection id={`divToPrint${payslipDetail.idPayrunDetail}`}>
          <Box style={{ marginBottom: "50px" }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography className={classes.confidential}>
                  Confidential
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.payslip}>PAY SLIP</Typography>
              </Grid>
            </Grid>
          </Box>
          <EmpSlipInfo payslipDetail={payslipDetail} />
          <TableSlip payslipDetail={payslipDetail} />
        </StyledSlipSection>
      ) : (
        <Box style={{ marginTop: "20px" }}>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="subtitle">No Data</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </StyledRoot>
  );
};

export default PaySlipDetail;
