import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import TextField from "@mui/material/TextField";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import DrawerCustom from "../../shared/general/Drawer";

import {
  addNewCompanies,
  addNewVendor,
} from "../../../../actions/ant-team/customer";

const StyledRoot = styled("div")({
  width: 450,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  "&.head-text-manager": {
    fontWeight: 400,
    "&.error": {
      color: "#f15e5e",
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const Customer = (props) => {
  const { handleChange } = props;
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>รหัสบริษัท</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme name="customerCode" onChange={handleChange} fullWidth />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ชื่อบริษัท</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme name="customerName" onChange={handleChange} fullWidth />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ชื่อบริษัท(ย่อ)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="customerNameShort"
          onChange={handleChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const DialogAdd = (props) => {
  const {
    open,
    handleCloseDialog,
    customer,
    handleChangeAlertType,
    handleOpenAlert,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [temp, setTemp] = useState({
    customerCode: "",
    customerName: "",
    customerNameShort: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(null)

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTemp({ ...temp, [name]: value });
  };

  const handleSave = async () => {
    setIsSubmitting(true)
    let formData = null;
    let result = null;
    if (customer === "company") {
      formData = {
        companyCode: temp.customerCode,
        companyName: temp.customerName,
        companyNameShort: temp.customerNameShort,
        isActive: 1,
      };

      result = await dispatch(addNewCompanies(formData));
    } else {
      formData = {
        vendorCode: temp.customerCode,
        vendorName: temp.customerName,
        vendorNameShort: temp.customerNameShort,
        isActive: 1,
      };

      result = await dispatch(addNewVendor(formData));
    }

    console.log("formData: ", formData);

    if (result) {
      
      console.log("result: ", result);
      if (result.status === 200) {
        handleCloseDialog();
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
      setIsSubmitting(null)
      handleOpenAlert()
    } else {
      handleOpenAlert()
      handleChangeAlertType("error");
      setIsSubmitting(null);
      handleCloseDialog();
    }
  };

  return (
    <DrawerCustom
      title={customer === "company" ? "Add Company" : "Add Vendor"}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Customer handleChange={handleChange} />
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSave} disabled={isSubmitting}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogAdd;
