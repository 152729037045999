import React from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import FormContainer from "./FormContainer";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { Controller } from 'react-hook-form';
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";

const BasicInfo = (props) => {
  const { control, errors } = props;
  const { t } = useTranslation();

  return (
    <FormContainer title={`${t("GeneralInfo")}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("EmployeeID")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="employeeId"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.employeeId ? true : false}
                helperText={errors && errors.employeeId && errors.employeeId.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Prefix")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="title"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.title ? true : false}
                helperText={errors && errors.title && errors.title.message}
                fullWidth
                select
              >
                <MenuItem value="นาย">{t("Mr")}</MenuItem>
                <MenuItem value="นาง">{t("Mrs")}</MenuItem>
                <MenuItem value="นางสาว">{t("Miss")}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("FirstName")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="firstname"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.firstname ? true : false}
                helperText={errors && errors.firstname && errors.firstname.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("LastName")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="lastname"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.lastname ? true : false}
                helperText={errors && errors.lastname && errors.lastname.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{`${t("Prefix")} (${t("Eng")})`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="title_en"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.title_en ? true : false}
                helperText={errors && errors.title_en && errors.title_en.message}
                fullWidth
                select
              >
                <MenuItem value="Mr.">Mr.</MenuItem>
                <MenuItem value="Ms.">Ms.</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{`${t("FirstName")} (${t("Eng")})`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="firstname_en"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.firstname_en ? true : false}
                helperText={errors && errors.firstname_en && errors.firstname_en.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{`${t("LastName")} (${t("Eng")})`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="lastname_en"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.lastname_en ? true : false}
                helperText={errors && errors.lastname_en && errors.lastname_en.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Nickname")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="nickname"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.nickname ? true : false}
                helperText={errors && errors.nickname && errors.nickname.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Gender")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="gender"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.gender ? true : false}
                helperText={errors && errors.gender && errors.gender.message}
                fullWidth
                select
              >
                <MenuItem value="ชาย">{t("Male")}</MenuItem>
                <MenuItem value="หญิง">{t("Female")}</MenuItem>
                <MenuItem value="อื่นๆ">{t("Other")}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("BirthDate")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
            <Controller 
              control={control}
              name="birthday"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  openTo="year"
                  views={["year", "month", "day"]}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      error={errors && errors.birthday ? true : false}
                      helperText={errors && errors.birthday && errors.birthday.message}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD/MM/YYYY",
                      }}
                      fullWidth
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("IDCardNumber")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="personalId"
            render={({ field }) => (
              <TextFieldTheme 
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  format: (() => {
                    if(field.value.length >= 13){
                      return "#-####-#####-##-#"
                    } else if(field.value.length >= 11){
                      return "#-####-#####-###"
                    } else if(field.value.length >= 6){
                      return "#-####-######"
                    } else if(field.value.length >= 2){
                      return "#-#####"
                    } else {
                      return "##"
                    }
                  })(),
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  },
                }}
                onChange={()=>{}}
                fullWidth
                error={errors && errors.personalId ? true : false}
                helperText={errors && errors.personalId && errors.personalId.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("PhoneNumber")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="telephoneMobile"
            render={({ field }) => (
              <TextFieldTheme 
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  format: (() => {
                    if(field.value.length >= 7){
                      return "###-###-####"
                    } else if(field.value.length >= 4){
                      return "###-####"
                    } else {
                      return "####"
                    }
                  })(),
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  },
                }}
                onChange={()=>{}}
                fullWidth
                error={errors && errors.telephoneMobile ? true : false}
                helperText={errors && errors.telephoneMobile && errors.telephoneMobile.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Email")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="email"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.email ? true : false}
                helperText={errors && errors.email && errors.email.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Address")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller 
                control={control}
                name="houseNo"
                render={({ field }) => (
                  <TextFieldTheme 
                    {...field}
                    // placeholder={`${t("HouseNumber")}`}
                    label={`${t("HouseNumber")}`}
                    error={errors && errors.houseNo ? true : false}
                    helperText={errors && errors.houseNo && errors.houseNo.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller 
                control={control}
                name="vilage"
                render={({ field }) => (
                  <TextFieldTheme 
                    {...field}
                    // placeholder={`${t("Village")}`}
                    label={`${t("Village")}`}
                    error={errors && errors.vilage ? true : false}
                    helperText={errors && errors.vilage && errors.vilage.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller 
                control={control}
                name="vilageNo"
                render={({ field }) => (
                  <TextFieldTheme 
                    {...field}
                    // placeholder={`${t("VillageNo")}`}
                    label={`${t("VillageNo")}`}
                    error={errors && errors.vilageNo ? true : false}
                    helperText={errors && errors.vilageNo && errors.vilageNo.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller 
                control={control}
                name="road"
                render={({ field }) => (
                  <TextFieldTheme 
                    {...field}
                    // placeholder={`${t("Road")}`}
                    label={`${t("Road")}`}
                    error={errors && errors.road ? true : false}
                    helperText={errors && errors.road && errors.road.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller 
                control={control}
                name="alley"
                render={({ field }) => (
                  <TextFieldTheme 
                    {...field}
                    // placeholder={`${t("Alley")}`}
                    label={`${t("Alley")}`}
                    error={errors && errors.alley ? true : false}
                    helperText={errors && errors.alley && errors.alley.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller 
                control={control}
                name="areaCode"
                render={({ field }) => (
                  <TextFieldTheme 
                    {...field}
                    // placeholder={`${t("PostalCode")}`}
                    label={`${t("PostalCode")}`}
                    error={errors && errors.areaCode ? true : false}
                    helperText={errors && errors.areaCode && errors.areaCode.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("SubdistrictArea")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="subDistrict"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.subDistrict ? true : false}
                helperText={errors && errors.subDistrict && errors.subDistrict.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("DistrictArea")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="district"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.district ? true : false}
                helperText={errors && errors.district && errors.district.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Province")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="provience"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.provience ? true : false}
                helperText={errors && errors.provience && errors.provience.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Nationality")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="nationality"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.nationality ? true : false}
                helperText={errors && errors.nationality && errors.nationality.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("MaritalStatus")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="maritalStatus"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.maritalStatus ? true : false}
                helperText={errors && errors.maritalStatus && errors.maritalStatus.message}
                fullWidth
                select
              >
                <MenuItem value="โสด">{t("Single")}</MenuItem>
                <MenuItem value="สมรส">{t("Married")}</MenuItem>
                <MenuItem value="หม้าย">{t("Widowed")}</MenuItem>
                <MenuItem value="หย่า">{t("Divorced")}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Passport")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="passportId"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.passportId ? true : false}
                helperText={errors && errors.passportId && errors.passportId.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("WorkPermit")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="workPermit"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.workPermit ? true : false}
                helperText={errors && errors.workPermit && errors.workPermit.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("EmergencyContact")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="emergencyContact"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.emergencyContact ? true : false}
                helperText={errors && errors.emergencyContact && errors.emergencyContact.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("EmergencyPhone")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="emergencyPhone"
            render={({ field }) => (
              <TextFieldTheme 
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  format: (() => {
                    if(field.value.length >= 7){
                      return "###-###-####"
                    } else if(field.value.length >= 4){
                      return "###-####"
                    } else {
                      return "####"
                    }
                  })(),
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  },
                }}
                onChange={()=>{}}
                fullWidth
                error={errors && errors.emergencyPhone ? true : false}
                helperText={errors && errors.emergencyPhone && errors.emergencyPhone.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("EmergencyRelationship")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="emergencyRelationship"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.emergencyRelationship ? true : false}
                helperText={errors && errors.emergencyRelationship && errors.emergencyRelationship.message}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default BasicInfo;
