import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useLocation, useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import ProfileTab from "./profile";
import Expertise from "./expertise";
import LocationTab from "./location";
import PF from "./pf";
import ChangePasswordTab from "./change-password";

import AlertResponse from "../../shared/general/AlertResponse";

const StyledTabs = styled(Tabs)({
  "& .Mui-selected": {
    color: "#212b36 !important",
  },
  "& .fal": {
    marginRight: 8,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RightPanel = () => {
  let location = useLocation();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(0);
  const [tabItem, setTabItem] = useState([
    {
      label: t("PersonalInfo"),
      icon: <i class="fal fa-address-card"></i>,
      component: <ProfileTab />,
    },
    {
      label: t("Address"),
      icon: <i class="fal fa-map-marked-alt"></i>,
      component: (
        <LocationTab setOpenAlert={setOpenAlert} setAlertType={setAlertType} />
      ),
    },
    {
      label: t("Education"),
      icon: <i class="fal fa-user-graduate"></i>,
      component: <Expertise />,
    },
    // {
    //   label: t("ProvidentFund"),
    //   icon: <i className="fa-regular fa-sack-dollar"></i>,
    // },
    {
      label: t("ChangePass"),
      icon: <i class="fa-regular fa-unlock-keyhole"></i>,
      component: <ChangePasswordTab />,
    },
  ]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  useEffect(() => {
    if (location.hash === "#address") {
      setValue(1);
    } else if (location.hash === "#password") {
      setValue(3);
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    var hash;
    switch (newValue) {
      case 1:
        hash = "address";
        break;
      case 2:
        hash = "education";
        break;
      case 3:
      //   hash = "provident-fund";
      //   break;
      // case 4:
        hash = "password";
        break;
      default:
        hash = "profile";
        break;
    }
    history.push({ hash });
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <div style={{ width: "100%", marginTop: 16 }}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        scrollButtons="auto"
        variant="scrollable"
        aria-label="scrollable auto tabs example"
      >
        {tabItem.map((value, index) => (
          <Tab
            key={index}
            label={
              <Typography>
                {value.icon} {value.label}
              </Typography>
            }
            {...a11yProps(index)}
          />
        ))}
      </StyledTabs>
      <TabPanel value={value} index={0}>
        <ProfileTab
          handleChangeAlertType={handleChangeAlertType}
          handleOpenAlert={handleOpenAlert}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LocationTab
          handleChangeAlertType={handleChangeAlertType}
          handleOpenAlert={handleOpenAlert}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Expertise />
      </TabPanel>
      <TabPanel value={value} index={3}>
        {/* <PF />
      </TabPanel>
      <TabPanel value={value} index={4}> */}
        <ChangePasswordTab />
      </TabPanel>

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </div>
  );
};

export default RightPanel;
