import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { Backdrop, CardContent, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

import AlertResponse from "../../../shared/general/AlertResponse";
import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import utils from "../../../../../utils";

import {
  getAllAdditionsByVendor
} from '../../../../../actions/addition';

import {
  getBillingAllCompanysByVendor,
  getBillingAllEmployeesByBilling,
  sendBilling,
  uploadFileBilling,
  deletedFileBilling,
  updateLumpSumBilling
} from "../../../../../actions/billing";

import Utils from "../../../../../utils";

import DataTable from "./dataTable";
import DialogEdit from "./DialogEdit";
import DropZone from "../../../shared/Form/DropFile";
import ConfirmDialog from "../../../shared/general/ConfirmDialog";

const Root = styled("div")({
  "& .textFile": {
    color: "#007AFE",
    "&:hover": {
      color: "#0055B2",
    }
  },
  "& .deleteIcon": {
    color: "rgba(0,0,0,.32)",
    "&:hover": {
      color: "rgba(0,0,0,.80)",
    }
  }
});

const StyledCardContent = styled(CardContent)({
  "& .label": {
    fontSize: 14,
  }
});

const BillingDetailPage = (props) => {
  const { company, monthYear, setSelectedCompany, handleAlertCompanyPage, idBilling, billingCompanys, type, setSeeMoreData } = props;
  const dispatch = useDispatch();
  const { result: billingEmployees } = useSelector((state) => state.billingEmployees);
  const { result: additionVendor } = useSelector(
    (state) => state.additionVendor
  );

  const { handleSubmit, setValue } = useForm();
  const [employeeEdit, setemployeeEdit] = useState(null);
  const [billingCompany, setBillingCompany] = useState(null);
  const [statusAlert, setStatusAlert] = useState({
    isOpen: false,
    alertType: null
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => { }
  });
  const [isDisplaySection, setIsDisplaySection] = useState(false);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (billingCompanys && billingCompanys.length > 0) {
      const billing = billingCompanys.find(bill => bill.idCompany === company && bill.idBilling === idBilling);
      setBillingCompany(billing);
      dispatch(getBillingAllEmployeesByBilling(monthYear, idBilling));
      dispatch(getAllAdditionsByVendor(billing.idVendor));
    }
  }, [billingCompanys]);

  const onClickEdit = (employeeDetail) => {
    setemployeeEdit(employeeDetail);
  };

  const onClickAccept = async () => {
    setLoading(true);
    let result = await dispatch(sendBilling(billingCompany.idBilling));
    if (result.status === 200) {
      setSelectedCompany(null);
      handleAlertCompanyPage("success");
      dispatch(getBillingAllCompanysByVendor(dayjs(monthYear).date(1).format("YYYY-MM-DD")));
    } else {
      handleAlertCompanyPage("error");
    }
    setLoading(false);
  };

  const handleAlert = (type) => {
    setStatusAlert({
      isOpen: true,
      alertType: type
    })
  };

  const ChangeTextWithCommastoFloat = (textNum) => {
    return parseFloat(textNum.replace(",", ""));
  };

  const onClickExcelDownload = () => {
    let workbook = XLSX.utils.book_new();
    if (
      billingEmployees &&
      billingEmployees.listBillingDetail &&
      billingEmployees.listBillingDetail.length > 0
    ) {
      let listBigSheet = [];
      let listAdditionSummary = {};
      let companyName = billingCompany.companyName;
      let idCompany = billingCompany.idCompany;
      if (
        additionVendor &&
        additionVendor.length > 0
      ) {
        additionVendor.map((addition) => {
          listAdditionSummary[`${addition.name}(${addition.code})`] = 0;
        })
      }

      let summaryBigSheet = {
        ["ลำดับ"]: "",
        [`ชื่อ-สกุล`]: "รวมเป็นเงิน",
        [`ตำแหน่ง`]: "",
        [`แผนก`]: "",
        [`หน่วยงาน`]: "",
        [`ค่าแรง`]: 0,
        [`Charge ค่าแรง`]: 0,
        [`ค่าล่วงเวลา`]: 0,
        [`OTx1 (ชม.)`]: "",
        [`OTx1 (บาท/ชม.)`]: "",
        [`OTx1 (Charge)`]: 0,
        [`OTx1 (เรียกเก็บ)`]: 0,
        [`OTx1.5 (ชม.)`]: "",
        [`OTx1.5 (บาท/ชม.)`]: "",
        [`OTx1.5 (Charge)`]: 0,
        [`OTx1.5 (เรียกเก็บ)`]: 0,
        [`OTx2 (ชม.)`]: "",
        [`OTx2 (บาท/ชม.)`]: "",
        [`OTx2 (Charge)`]: 0,
        [`OTx2 (เรียกเก็บ)`]: 0,
        [`OTx3 (ชม.)`]: "",
        [`OTx3 (บาท/ชม.)`]: "",
        [`OTx3 (Charge)`]: 0,
        [`OTx3 (เรียกเก็บ)`]: 0,
        [`รวม OT (เรียกเก็บ)`]: 0,
        [`Charge เหมา`]: 0,
        [`Charge ค่าชดเชย`]: 0,
        [`ค่ากะ`]: 0,
        [`Charge ค่ากะ`]: 0,
        ...listAdditionSummary,
        [`รวม Addtion`]: 0,
        [`รวม Charge`]: 0,
        [`รวมรายรับ`]: 0,
        [`เพิ่มค่าอื่นๆ`]: 0,
        [`หักค่าอื่นๆ`]: 0,
        [`รวมค่าอื่นๆ`]: 0,
        [`เรียกเก็บสุทธิ`]: 0
      };

      billingEmployees.listBillingDetail.map((billingDetail, index) => {
        let listAddition = {};
        if (
          additionVendor &&
          additionVendor.length > 0
        ) {
          additionVendor.map((addition) => {
            listAddition[`${addition.name}(${addition.code})`] = (billingDetail[`valueAddition${addition.idAddition}`] || 0);
            summaryBigSheet[`${addition.name}(${addition.code})`] += (billingDetail[`valueAddition${addition.idAddition}`] || 0);
          })
        }
        let temp = {
          ["ลำดับ"]: (index + 1),
          [`ชื่อ-สกุล`]: billingDetail.firstname + " " + billingDetail.lastname,
          [`ตำแหน่ง`]: billingDetail[`positionsName`] || "-",
          [`แผนก`]: billingDetail[`sectionName`] || "-",
          [`หน่วยงาน`]: billingDetail[`departmentName`] || "-",
          [`ค่าแรง`]: utils.numberWithCommas(billingDetail[`salary`] || 0),
          [`Charge ค่าแรง`]: utils.numberWithCommas(billingDetail[`chargeSalary`] || 0),
          [`ค่าล่วงเวลา`]: utils.numberWithCommas(billingDetail[`totalOT`] || 0),
          [`OTx1 (ชม.)`]: billingDetail[`hoursOT1`] || 0,
          [`OTx1 (บาท/ชม.)`]: utils.numberWithCommas(billingDetail[`perHoursOT1`] || 0),
          [`OTx1 (Charge)`]: utils.numberWithCommas(billingDetail[`chargeOT1`] || 0),
          [`OTx1 (เรียกเก็บ)`]: utils.numberWithCommas(billingDetail[`totalOT1`] || 0),
          [`OTx1.5 (ชม.)`]: billingDetail[`hoursOT1dot5`] || 0,
          [`OTx1.5 (บาท/ชม.)`]: utils.numberWithCommas(billingDetail[`perHoursOT1dot5`] || 0),
          [`OTx1.5 (Charge)`]: utils.numberWithCommas(billingDetail[`chargeOT1dot5`] || 0),
          [`OTx1.5 (เรียกเก็บ)`]: utils.numberWithCommas(billingDetail[`totalOT1dot5`] || 0),
          [`OTx2 (ชม.)`]: billingDetail[`hoursOT2`] || 0,
          [`OTx2 (บาท/ชม.)`]: utils.numberWithCommas(billingDetail[`perHoursOT2`] || 0),
          [`OTx2 (Charge)`]: utils.numberWithCommas(billingDetail[`chargeOT2`] || 0),
          [`OTx2 (เรียกเก็บ)`]: utils.numberWithCommas(billingDetail[`totalOT2`] || 0),
          [`OTx3 (ชม.)`]: billingDetail[`hoursOT3`] || 0,
          [`OTx3 (บาท/ชม.)`]: utils.numberWithCommas(billingDetail[`perHoursOT3`] || 0),
          [`OTx3 (Charge)`]: utils.numberWithCommas(billingDetail[`chargeOT3`] || 0),
          [`OTx3 (เรียกเก็บ)`]: utils.numberWithCommas(billingDetail[`totalOT3`] || 0),
          [`รวม OT (เรียกเก็บ)`]: utils.numberWithCommas(billingDetail[`totalAllOT`] || 0),
          [`Charge เหมา`]: utils.numberWithCommas(billingDetail[`chargeCost`] || 0),
          [`Charge ค่าชดเชย`]: utils.numberWithCommas(billingDetail[`chargeCompensated`] || 0),
          [`ค่ากะ`]: utils.numberWithCommas(billingDetail[`shiftCost`] || 0),
          [`Charge ค่ากะ`]: utils.numberWithCommas(billingDetail[`chargeShiftCost`] || 0),
          ...listAddition,
          [`รวม Addtion`]: utils.numberWithCommas(billingDetail[`totalAddition`] || 0),
          [`รวม Charge`]: utils.numberWithCommas(billingDetail[`totalCharges`] || 0),
          [`รวมรายรับ`]: utils.numberWithCommas(billingDetail[`totalEarning`] || 0),
          [`เพิ่มค่าอื่นๆ`]: utils.numberWithCommas(billingDetail[`otherAddition`] || 0),
          [`หักค่าอื่นๆ`]: utils.numberWithCommas(billingDetail[`otherDeduction`] || 0),
          [`รวมค่าอื่นๆ`]: utils.numberWithCommas(billingDetail[`totalOther`] || 0),
          [`เรียกเก็บสุทธิ`]: utils.numberWithCommas(billingDetail[`totalBilling`] || 0)
        };
        listBigSheet.push(temp);

        summaryBigSheet[`ค่าแรง`] += ChangeTextWithCommastoFloat(temp[`ค่าแรง`]);
        summaryBigSheet[`Charge ค่าแรง`] += ChangeTextWithCommastoFloat(temp[`Charge ค่าแรง`]);
        summaryBigSheet[`ค่าล่วงเวลา`] += ChangeTextWithCommastoFloat(temp[`ค่าล่วงเวลา`]);
        summaryBigSheet[`OTx1 (Charge)`] += ChangeTextWithCommastoFloat(temp[`OTx1 (Charge)`]);
        summaryBigSheet[`OTx1 (เรียกเก็บ)`] += ChangeTextWithCommastoFloat(temp[`OTx1 (เรียกเก็บ)`]);
        summaryBigSheet[`OTx1.5 (Charge)`] += ChangeTextWithCommastoFloat(temp[`OTx1.5 (Charge)`]);
        summaryBigSheet[`OTx1.5 (เรียกเก็บ)`] += ChangeTextWithCommastoFloat(temp[`OTx1.5 (เรียกเก็บ)`]);
        summaryBigSheet[`OTx2 (Charge)`] += ChangeTextWithCommastoFloat(temp[`OTx2 (Charge)`]);
        summaryBigSheet[`OTx2 (เรียกเก็บ)`] += ChangeTextWithCommastoFloat(temp[`OTx2 (เรียกเก็บ)`]);
        summaryBigSheet[`OTx3 (Charge)`] += ChangeTextWithCommastoFloat(temp[`OTx3 (Charge)`]);
        summaryBigSheet[`OTx3 (เรียกเก็บ)`] += ChangeTextWithCommastoFloat(temp[`OTx3 (เรียกเก็บ)`]);
        summaryBigSheet[`รวม OT (เรียกเก็บ)`] += ChangeTextWithCommastoFloat(temp[`รวม OT (เรียกเก็บ)`]);
        summaryBigSheet[`Charge เหมา`] += ChangeTextWithCommastoFloat(temp[`Charge เหมา`]);
        summaryBigSheet[`Charge ค่าชดเชย`] += ChangeTextWithCommastoFloat(temp[`Charge ค่าชดเชย`]);
        summaryBigSheet[`ค่ากะ`] += ChangeTextWithCommastoFloat(temp[`ค่ากะ`]);
        summaryBigSheet[`Charge ค่ากะ`] += ChangeTextWithCommastoFloat(temp[`Charge ค่ากะ`]);
        summaryBigSheet[`รวม Addtion`] += ChangeTextWithCommastoFloat(temp[`รวม Addtion`]);
        summaryBigSheet[`รวม Charge`] += ChangeTextWithCommastoFloat(temp[`รวม Charge`]);
        summaryBigSheet[`รวมรายรับ`] += ChangeTextWithCommastoFloat(temp[`รวมรายรับ`]);
        summaryBigSheet[`เพิ่มค่าอื่นๆ`] += ChangeTextWithCommastoFloat(temp[`เพิ่มค่าอื่นๆ`]);
        summaryBigSheet[`หักค่าอื่นๆ`] += ChangeTextWithCommastoFloat(temp[`หักค่าอื่นๆ`]);
        summaryBigSheet[`รวมค่าอื่นๆ`] += ChangeTextWithCommastoFloat(temp[`รวมค่าอื่นๆ`]);
        summaryBigSheet[`เรียกเก็บสุทธิ`] += ChangeTextWithCommastoFloat(temp[`เรียกเก็บสุทธิ`]);
      });

      Object.keys(summaryBigSheet).map((k) => {
        if (summaryBigSheet[k] !== "" && summaryBigSheet[k] !== "รวมเป็นเงิน") {
          summaryBigSheet[k] = utils.numberWithCommas(summaryBigSheet[k]);
        }
      });

      listBigSheet.push(summaryBigSheet);

      const wbs = XLSX.utils.json_to_sheet(listBigSheet, { origin: 'A3' });
      XLSX.utils.sheet_add_aoa(wbs, [[companyName]], { origin: 'A1' });
      XLSX.utils.sheet_add_aoa(wbs, [[
        idCompany === 143 ? `ประจำรอบการทำงาน ${dayjs(monthYear).locale("th").subtract(1, 'month').date(21).format("DD MMMM BBBB")} - ${dayjs(monthYear).locale("th").date(20).format("DD MMMM BBBB")}`
          : `ประจำรอบการทำงาน ${dayjs(monthYear).locale("th").date(1).format("DD MMMM BBBB")} - ${dayjs(monthYear).date(dayjs(monthYear).locale("th").daysInMonth()).format("DD MMMM BBBB")}`
      ]], { origin: 'A2' });
      wbs['!merges'] = [
        XLSX.utils.decode_range('A1:AL1'),
        XLSX.utils.decode_range('A2:AL2'),
      ];

      XLSX.utils.book_append_sheet(workbook, wbs, "บิลใหญ่");
    }

    if (
      (
        billingCompany &&
        billingCompany.idCompany === 143
      ) &&
      billingEmployees &&
      billingEmployees.listBillingDetailSection &&
      billingEmployees.listBillingDetailSection.length > 0
    ) {
      let idCompany = billingCompany.idCompany;
      let ObjectSection = {};

      billingEmployees.listBillingDetailSection.map(section => {
        if (!ObjectSection[section.idSection]) {
          ObjectSection[section.idSection] = {
            sectionName: section.sectionName,
            list: []
          };
        }

        ObjectSection[section.idSection].list.push(section);
      });

      Object.keys(ObjectSection).map((k) => {
        let summary = {
          [`ลำดับ`]: 'รวมจำนวนชั่วโมง',
          [`โอทีวันปกติ 1.5`]: 0,
          [`ทำงานวันหยุด 2.0`]: 0,
          [`ล่วงเวลาวันหยุด 3.0`]: 0
        }
        let summaryTotalOT = 0;

        let listSection = [];
        ObjectSection[k].list.map((element, index) => {
          let temp = {
            ['ลำดับ']: (index + 1),
            [`ชื่อ-นามสกุล`]: element.firstname + " " + element.lastname,
            [`โอทีวันปกติ 1.5`]: (element.hoursOT1dot5 || 0),
            [`ทำงานวันหยุด 2.0`]: (element.hoursOT2 || 0),
            [`ล่วงเวลาวันหยุด 3.0`]: (element.hoursOT3 || 0)
          };
          listSection.push(temp);

          summary[`โอทีวันปกติ 1.5`] += temp[`โอทีวันปกติ 1.5`];
          summary[`ทำงานวันหยุด 2.0`] += temp[`ทำงานวันหยุด 2.0`];
          summary[`ล่วงเวลาวันหยุด 3.0`] += temp[`ล่วงเวลาวันหยุด 3.0`];
          summaryTotalOT += element.totalAllOT || 0;
        });

        listSection.push(summary);

        const ws = XLSX.utils.json_to_sheet(listSection, { origin: 'A3' });
        XLSX.utils.sheet_add_aoa(ws, [[`แผนก ${ObjectSection[k].sectionName}`]], { origin: 'A1' });
        XLSX.utils.sheet_add_aoa(ws, [[
          idCompany === 143 ? `ประจำรอบการทำงาน ${dayjs(monthYear).locale("th").subtract(1, 'month').date(21).format("DD MMMM BBBB")} - ${dayjs(monthYear).locale("th").date(20).format("DD MMMM BBBB")}`
            : `ประจำรอบการทำงาน ${dayjs(monthYear).locale("th").date(1).format("DD MMMM BBBB")} - ${dayjs(monthYear).locale("th").date(dayjs(monthYear).daysInMonth()).format("DD MMMM BBBB")}`
        ]], { origin: 'A2' });
        ws['!merges'] = [
          XLSX.utils.decode_range('A1:E1'),
          XLSX.utils.decode_range('A2:E2'),
          XLSX.utils.decode_range(`A${ObjectSection[k].list.length + 4}:B${ObjectSection[k].list.length + 4}`),
          XLSX.utils.decode_range(`A${ObjectSection[k].list.length + 5}:C${ObjectSection[k].list.length + 5}`),
        ];
        XLSX.utils.sheet_add_aoa(ws, [
          [`เป็นเงิน`, "", "", utils.numberWithCommas(summaryTotalOT), 'บาท']
        ], { origin: `A${ObjectSection[k].list.length + 5}` });

        XLSX.utils.book_append_sheet(workbook, ws, ObjectSection[k].sectionName);
      });
    }

    XLSX.writeFile(
      workbook,
      `Billings ${(billingCompany && billingCompany.idCompany === 143) ? `${dayjs(monthYear).locale("th").subtract(1, 'month').date(21).format("DD MMMM BBBB")} - ${dayjs(monthYear).locale("th").date(20).format("DD MMMM BBBB")}`
        : `${dayjs(monthYear).locale("th").date(1).format("DD MMMM BBBB")} - ${dayjs(monthYear).locale("th").date(dayjs(monthYear).daysInMonth()).format("DD MMMM BBBB")}`
      }.xlsx`,
      { type: "file" }
    );
  };

  const onHandleSubmit = async (values) => {
    setLoading(true);
    if (!values.file || values.file.length <= 0 || !billingCompany) {
      setLoading(false);
      setStatusAlert({
        isOpen: true,
        alertType: "error"
      });
    }

    let data = new FormData();
    data.append("file", values.file[0]);
    const result = await dispatch(uploadFileBilling(data, billingCompany.idBilling));
    if (result && result.status === 200) {
      setStatusAlert({
        isOpen: true,
        alertType: "success"
      });
      dispatch(getBillingAllCompanysByVendor(dayjs(monthYear).date(1).format("YYYY-MM-DD")));
    } else {
      setStatusAlert({
        isOpen: true,
        alertType: "error"
      });
    }
    setLoading(false);
  };

  const handleDeleteFile = async () => {
    setLoading(true);
    setOpenConfirmDialog({
      isOpen: false,
      title: "",
      subTitle: "",
      onConfirm: () => { }
    });
    if (!billingCompany || !billingCompany.idBilling) {
      setLoading(false);
      setStatusAlert({
        isOpen: true,
        alertType: "error"
      });
    } else {
      let result = await dispatch(deletedFileBilling(billingCompany.idBilling));
      if (result && result.status === 200) {
        setStatusAlert({
          isOpen: true,
          alertType: "success"
        });
        dispatch(getBillingAllCompanysByVendor(dayjs(monthYear).date(1).format("YYYY-MM-DD")));
      } else {
        setStatusAlert({
          isOpen: true,
          alertType: "error"
        });
      }
    }
    setLoading(false);
  };

  // lump sum payment
  const onUpdateLumpSumPayment = async () => {
    try {
      setLoading(true);
      const response = await updateLumpSumBilling(billingCompany.idBilling);
      if (response.status === 200) {
        setSelectedCompany(null);
        handleAlertCompanyPage("success");
        dispatch(getBillingAllCompanysByVendor(dayjs(monthYear).date(1).format("YYYY-MM-DD")));
      } else {
        handleAlertCompanyPage("error");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      handleAlertCompanyPage("error");
    }
  };

  return (
    <Root>
      <ButtonBlue
        startIcon={<ArrowBackIcon />}
        onClick={() => {
          setSelectedCompany(null)
          setSeeMoreData(true)
        }}
      >
        กลับ
      </ButtonBlue>
      <Typography variant="h4" style={{ paddingTop: 8, marginBottom: 16 }}>
        รายละเอียด
      </Typography>
      <StyledCard style={{ marginBottom: 16 }}>
        <StyledCardContent>
          {(company && billingCompany) && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  className="label"
                  variant="caption"
                  color="text.secondary"
                >
                  ชื่อรายการ
                </Typography>
                {type === 0 ? (
                  <Typography variant="h5">
                    {billingCompany ? billingCompany.payrunName : "-"}
                  </Typography>
                ) : (
                  <Typography variant="h5">
                    {billingCompany ? billingCompany.fileName : "-"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className="label"
                  variant="caption"
                  color="text.secondary"
                >
                  บริษัทนายจ้าง
                </Typography>
                <Typography variant="h5">
                  {billingCompany ? billingCompany.companyName : "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className="label"
                  variant="caption"
                  color="text.secondary"
                >
                  รอบเดือน
                </Typography>
                <Typography variant="h5">
                  {billingCompany ?
                    dayjs(billingCompany.monthPeriod).locale(i18n.language).format(`MMMM ${i18n.language === "th" ? "BBBB" : "YYYY"}`)
                    : "-"
                  }
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className="label"
                  variant="caption"
                  color="text.secondary"
                >
                  จำนวนพนักงาน
                </Typography>
                <Typography variant="h5">
                  {billingCompany ?
                    billingCompany.numberEmployee
                    : "-"
                  }
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  className="label"
                  variant="caption"
                  color="text.secondary"
                >
                  ยอดรวมทั้งหมด
                </Typography>
                <Typography variant="h5">
                  {billingCompany ?
                    `${Utils.numberWithCommas(billingCompany.net)} ${i18n.language === "th" ? "บาท" : "baht"}`
                    : "-"
                  }
                </Typography>
              </Grid>
            </Grid>
          )}
        </StyledCardContent>
      </StyledCard>
      <div style={{ width: "100%", margin: "24px 0px 48px" }}>
        {type === 0 ? (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "16px" }}
          >
            <ButtonBlue
              variant="contained"
              onClick={() => {
                setIsDisplaySection(!isDisplaySection);
              }}
            >
              {isDisplaySection ? `แสดงแบบรายคน` : `แสดงแบบแผนก`}
            </ButtonBlue>
            <div style={{ display: "flex" }}>
              <ButtonBlue
                variant="outlined"
                style={{
                  marginRight: "16px"
                }}
                onClick={onClickExcelDownload}
              >
                <i className="fa-light fa-file-spreadsheet" style={{ marginRight: "8px" }} /> โหลด xlsx ไฟล์
              </ButtonBlue>
              {(billingCompany && !billingCompany.isSent) &&
                <ButtonBlue
                  variant="contained"
                  onClick={onClickAccept}
                >
                  ส่งวางบิล
                </ButtonBlue>
              }
            </div>
          </div>
        ) : (
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "16px" }}>
            <Stack spacing={1} direction={"row"}>
              <ButtonBlue
                variant="contained"
                href={billingCompany && billingCompany.pathFile || null}
                target="_blank"
              >
                ดูใบวางบิล
              </ButtonBlue>

              {(billingCompany && !billingCompany.isSent) &&
                <ButtonBlue
                  variant="contained"
                  onClick={() => onUpdateLumpSumPayment()}
                >
                  ส่งวางบิล
                </ButtonBlue>
              }
            </Stack>
          </div>
        )}

        {type === 0 && (
          <div
            style={{ width: "100%" }}
          >
            <DataTable
              onClickEdit={onClickEdit}
              isSent={!(billingCompany && !billingCompany.isSent)}
              isDisplaySection={isDisplaySection}
            />
          </div>
        )}

        <div style={{ marginTop: type === 0 ? "16px" : 0 }}>
          {(!billingCompany || (billingCompany.isSent && !billingCompany.fileName) || type === 1) ? <></> :
            (billingCompany && billingCompany.fileName) ?
              <>
                <Typography style={{ color: "rgba(0,0,0,.32)", marginTop: "16px" }}>แนบไฟล์</Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "200px",
                    borderRadius: "4px",
                    border: "1px solid #c4c4c4",
                    marginTop: "8px",
                    position: "relative"
                  }}
                >
                  {billingCompany && !billingCompany.isSent &&
                    <CloseIcon
                      className="deleteIcon"
                      style={{ position: "absolute", right: "8", top: "8", cursor: "pointer" }}
                      onClick={() => setOpenConfirmDialog({
                        isOpen: true,
                        title: "ยืนยันการลบ",
                        subTitle: `ยืนยันที่จะลบไฟล์ ${billingCompany && billingCompany.fileName || ""}`,
                        onConfirm: () => {
                          handleDeleteFile();
                        }
                      })
                      }
                    />
                  }
                  <a href={billingCompany && billingCompany.pathFile || null} target="_blank" style={{ textDecoration: "none" }}>
                    <div
                      className="textFile"
                      style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}
                    >
                      <i className="fa-solid fa-file-pdf" style={{ fontSize: "64px" }} />
                      <Typography style={{ color: "inherit", marginTop: "16px" }}>{billingCompany && billingCompany.fileName || ""}</Typography>
                    </div>
                  </a>
                </div>
              </> :
              <form onSubmit={handleSubmit(onHandleSubmit)}>
                <DropZone
                  name="file"
                  setValue={setValue}
                  max={1}
                  type="pdf"
                />
                <div style={{ display: "flex", justifyContent: "right", marginTop: "16px" }}>
                  <ButtonBlue type="submit" variant="contained">{t("Save")}</ButtonBlue>
                </div>
              </form>
          }
        </div>
      </div>

      {
        employeeEdit &&
        <DialogEdit
          open={employeeEdit}
          handleClose={() => setemployeeEdit(null)}
          data={{ ...employeeEdit, idCompany: company }}
          monthYear={monthYear}
          handleAlert={handleAlert}
        />
      }
      {
        statusAlert.isOpen &&
        <AlertResponse
          open={statusAlert.isOpen}
          handleClose={() => setStatusAlert({
            isOpen: false,
            alertType: null
          })}
          alertType={statusAlert.alertType}
        />
      }
      {
        openConfirmDialog.isOpen &&
        <ConfirmDialog
          confirmDialog={openConfirmDialog}
          setConfirmDialog={setOpenConfirmDialog}
        />
      }
      {
        loading &&
        <Backdrop
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    </Root >
  );
};

export default BillingDetailPage;
