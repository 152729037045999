import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Link,
  Tooltip,
  Snackbar,
  Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AddIcon from "@mui/icons-material/Add";
import DoNotDisturbOnRoundedIcon from "@mui/icons-material/DoNotDisturbOnRounded";
import QrCodeIcon from "@mui/icons-material/QrCode";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import {
  getAllLocations,
  updateGpsLocations,
} from "../../../../../../actions/gpsLocations";

import ListMap from "./listMap";
import DialogGroupGpsLocations from "./DialogGroupGpsLocations";
import DialogFormGpsPositions from "./FormGpsPositions";
import DialogQRCode from "./DialogQRCode";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
    "& .MuiTypography-subtitle1": {
      fontSize: "0.9rem",
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "none",
    },
  },
  locations_container: {
    marginTop: theme.spacing(2),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  locationCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  infoLocationBox: {
    position: "relative",
    paddingLeft: theme.spacing(4),
    height: "100%",
  },
  lastUpdateBox: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  locationNameBox: {
    marginBottom: theme.spacing(2),
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  borderTop: "none",
  display: "flex",
  flexWrap: "wrap",
  "& .wrap-location": {
    display: "flex",
    flexWrap: "wrap",
    "& .inner-box": {
      width: 300,
      margin: 8,
      position: "relative",
      "& .btn-delete": {
        position: "absolute",
        right: -16,
        top: 22,
        zIndex: 10,
        "& .MuiButtonBase-root": {
          color: "#d32f2f",
        },
      },
    },
  },
  "& .wrap-add": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 8,
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function LocationList(props) {
  const { onClick, onClickEdit } = props;
  const classes = useStyles();
  const history = useHistory();
  useTitle("GPS Location List - HR CORE DATA");
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: locationsList } = useSelector((state) => state.locations);
  const [expanded, setExpanded] = React.useState("");
  const [openDialogGroupGpsLocations, setOpenDialogGroupGpsLocations] =
    React.useState(false);
  const [openDialogFormGpsPositions, setOpenDialogFormGpsPositions] =
    React.useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [mode, setMode] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");

  const [isOpenDialogQRCode, setIsOpenDialogQRCode] = useState({
    open: false,
    idGroupGpsLocations: null,
    groupGpsLocationsName: null,
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleOpenDialogGroupGpsLocations = () => {
    setOpenDialogGroupGpsLocations(true);
  };

  const handleOpenDialogAddGpsPositions = (selected) => (event) => {
    event.stopPropagation();
    setMode("add");
    setSelectedGroup(selected);
    setOpenDialogFormGpsPositions(true);
  };

  const handleOpenDialogEditGpsPositions = (selected) => (event) => {
    setMode("edit");
    setSelectedGroup(selected);
    setOpenDialogFormGpsPositions(true);
  };

  const handleChangeAlertType = (status) => {
    setAlertType(status);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenDialogGroupGpsLocations(false);
    setOpenDialogFormGpsPositions(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert("");
  };

  // const handleUpdateGpsLocations = async (selected) => {
  //   if (userProfile) {
  //     console.log("selected: ", selected);
  //     let formData = { ...selected };
  //     formData.isActive = false;
  //     formData.editDate = dayjs().format("YYYY-MM-DD HH:mm:ss");

  //     const result = await dispatch(updateGpsLocations(formData));
  //     if (result) {
  //       handleOpenAlert();
  //       if (result.status === 200) {
  //         handleChangeAlertType("success");
  //         if (userProfile) {
  //           dispatch(getAllLocations({ idCompany: selectedCompany.idCompany }));
  //         }
  //       } else {
  //         handleChangeAlertType("error");
  //       }
  //       handleClose();
  //     } else {
  //       handleOpenAlert();
  //       handleChangeAlertType("error");
  //       handleClose();
  //     }
  //   } else {
  //     handleClose();
  //   }
  // };

  useEffect(() => {
    if (userProfile) {
      dispatch(getAllLocations(userProfile.idCompany));
    }
  }, [userProfile]);

  return (
    <div className={classes.root}>
      <Box style={{ marginBottom: 16 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">รายการพื้นที่/ตำแหน่ง</Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              onClick={handleOpenDialogGroupGpsLocations}
            >
              เพิ่มกลุ่มพื้นที่ใหม่
            </ButtonBlue>
          </Grid>
        </Grid>
      </Box>
      {locationsList && (
        <div>
          {locationsList.map((value, index) => (
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <div className="wrap">
                  <Typography>{`${value.name}`}</Typography>
                  <div>
                    <ButtonBlue
                      size="small"
                      startIcon={<QrCodeIcon />}
                      style={{ marginRight: "8px" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsOpenDialogQRCode({
                          open: true,
                          idGroupGpsLocations: value.idGroupGpsLocations,
                          groupGpsLocationsName: value.name,
                        });
                      }}
                    >
                      QR Code บันทึกเวลา
                    </ButtonBlue>
                    <ButtonBlue
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={handleOpenDialogAddGpsPositions(value)}
                    >
                      ตำแหน่ง
                    </ButtonBlue>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {value.locations &&
                  value.locations.map((item, index) => (
                    <Box className="inner-box" key={`${item.idGpsLocations}`}>
                      {/* {value.locations.length > 1 && (
                                <div className="btn-delete">
                                  <Tooltip title="ปิดการใช้">
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() =>
                                        handleUpdateGpsLocations(item)
                                      }
                                    >
                                      <DoNotDisturbOnRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              )} */}
                      <Link
                        onClick={handleOpenDialogEditGpsPositions(item)}
                        underline="hover"
                        color="inherit"
                      >
                        <Typography align="center" variant="h6">
                          {item.name}
                        </Typography>
                      </Link>
                      <ListMap
                        id={`map-${item.name}-${index}`}
                        positions={item.positions}
                      />
                      <Typography
                        align="right"
                        variant="subtitle1"
                        style={{ color: "#9e9e9e" }}
                      >
                        ปรับปรุงล่าสุด :{" "}
                        {item.editDate
                          ? dayjs(item.editDate).format("DD MMM YYYY")
                          : dayjs(item.createDate).format("DD MMM YYYY")}
                      </Typography>
                    </Box>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
      <DialogGroupGpsLocations
        open={openDialogGroupGpsLocations}
        handleClose={handleClose}
        handleOpenAlert={handleOpenAlert}
        handleChangeAlertType={handleChangeAlertType}
      />
      <DialogFormGpsPositions
        mode={mode}
        selectedGroup={selectedGroup}
        open={openDialogFormGpsPositions}
        handleClose={handleClose}
        handleOpenAlert={handleOpenAlert}
        handleChangeAlertType={handleChangeAlertType}
      />
      {isOpenDialogQRCode.open && (
        <DialogQRCode
          open={isOpenDialogQRCode.open}
          idGroupGpsLocations={isOpenDialogQRCode.idGroupGpsLocations}
          groupGpsLocationsName={isOpenDialogQRCode.groupGpsLocationsName}
          onClose={() => {
            setIsOpenDialogQRCode({
              open: false,
              idGroupGpsLocations: null,
              groupGpsLocationsName: null,
            });
          }}
        />
      )}
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {alertType === "success" ? (
          <Alert
            onClose={handleCloseAlert}
            severity="success"
            style={{ width: "100%" }}
          >
            บันทึกข้อมูลสำเร็จ
          </Alert>
        ) : (
          <Alert
            onClose={handleCloseAlert}
            severity="error"
            style={{ width: "100%" }}
          >
            เกิดข้อผิดพลาด...ติดต่อผู้ดูแลระบบ
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}

export default LocationList;
