import { Grid, styled } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";

import {
  getVendorProfile,
  updateVendorAddress,
} from "../../../../actions/vendor";

const StyledRoot = styled("div")({
  width: 450,
  padding: 16,
  "& i": {
    marginRight: 8,
    fontSize: 14,
  },
  "& .part-action": {
    paddingTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .cancel-button": {
      // color: "#d32f2f",
      // borderColor: "#d32f2f",
      color: "#9e9e9e",
      borderColor: "#9e9e9e",
    },
  },
});

const DrawerHeadOfficeAddress = (props) => {
  const { open, onClose, data } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      address: (data && data.address) || "",
      district: (data && data.district) || "",
      province: (data && data.province) || "",
      areaCode: (data && data.areaCode) || "",
      // country: (data && data.address) || "",
    },
  });

  const onSubmit = async (formData) => {
    const result = await dispatch(updateVendorAddress(formData));
    if (result) {
      dispatch(getVendorProfile());
      onClose();
    }
  };

  return (
    <DrawerCustom open={open} title={t("EditHeadOffice")} anchor="right">
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="address"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("Address")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("Address")}
                    multiline
                    rows={3}
                    helperText={
                      errors && errors.address && errors.address.message
                    }
                    error={errors && errors.address ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="district"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("District")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("District")}
                    helperText={
                      errors && errors.district && errors.district.message
                    }
                    error={errors && errors.district ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="province"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("Province")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("Province")}
                    helperText={
                      errors && errors.province && errors.province.message
                    }
                    error={errors && errors.province ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="areaCode"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("Province")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label="PostalCode"
                    helperText={
                      errors && errors.areaCode && errors.areaCode.message
                    }
                    error={errors && errors.areaCode ? true : false}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Controller
                control={control}
                name="country"
                rules={{
                  required: {value: true, message: "กรุณากรอกประเทศ"}
                }}
                render={({field})=>(
                  <TextFieldTheme
                    {...field}
                    ref={field.ref()}
                    label="ประเทศ"
                    helperText={errors && errors.country && errors.country.message}
                    error={errors && errors.country ? true: false}
                  />
                )}
              />
            </Grid> */}
          </Grid>

          <div className="part-action">
            <ButtonBlue
              className="cancel-button"
              variant="outlined"
              onClick={onClose}
            >
              <i className="fa-regular fa-x"></i>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue type="submit" variant="contained">
              <i className="fa-regular fa-check"></i>
              {t("Save")}
            </ButtonBlue>
          </div>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerHeadOfficeAddress;
