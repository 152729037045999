import { httpClient } from "./httpClient";
import axios from "axios";

const getUserProfile = () => {
  return httpClient.get("users/profile");
};

const getAllUsersCompany = (idCompany, filter) => {
  if(filter) {
    return httpClient.get(`company/${idCompany}/users?filter=all`);
  } else {
    return httpClient.get(`company/${idCompany}/users`);
  }
};

const getAllUsersManager = () => {
  return httpClient.get(`company/users?filter=manager`);
};

const addUsersFirstTime = (formData) => {
  return httpClient.post(`/users?mode=first`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const addUsers = (formData) => {
  return httpClient.post(`/users`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const addUsersOne = (formData) => {
  return httpClient.post(`/users?mode=one`, formData);
};

const updateUserNTable = (employeeProfile, data) => {
  return httpClient.post(
    `company/${employeeProfile.idCompany}/users/${employeeProfile.idEmp}`,
    data
  );
};

const updateUser = (data) => {
  return httpClient.put(`employees`, data);
};

const updateUserNewManager = (data) => {
  return httpClient.put(`employees?mode=new-manager`, data);
};

const uploadImage = (employeeProfile, data) => {
  let fd = new FormData();
  fd.append("image", data[0]);
  return httpClient.post(
    `company/${employeeProfile.idCompany}/users/${employeeProfile.idEmp}/uploadImage`,
    fd
  );
};

const getAllEmployeeByIdVendorAndIdCompany = (idCompany, idVendor) => {
  return httpClient.get(`vendor/${idVendor}/company/${idCompany}`);
};

const getAllPFScheduleByidVendor = (idVendor) => {
  return httpClient.get(`vendor/${idVendor}/provident-fund-schedule`);
};

const getAllProvidentFundByExperienceYear = (minExperienceY) => {
  return httpClient.get(`vendor/provident-fund/${minExperienceY}`);
};

const updatePFPersonal = (idEmp, data) => {
  return httpClient.put(`user/${idEmp}/provident-fund-personal/update`, data);
};

const getDataWritePDF = (type, year) => {
  return httpClient.get(`users/data-writePDF/${type}${year ? `/${year}` : ""}`);
};

export default {
  getUserProfile,
  getAllUsersCompany,
  getAllUsersManager,
  addUsers,
  addUsersFirstTime,
  addUsersOne,
  updateUserNTable,
  updateUser,
  updateUserNewManager,
  uploadImage,
  getAllEmployeeByIdVendorAndIdCompany,
  getAllPFScheduleByidVendor,
  getAllProvidentFundByExperienceYear,
  updatePFPersonal,
  getDataWritePDF
};
