import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getAttendanceOnce = (mode, filter) => {
  if (mode) {
    return httpClient.get(`/attendance-once?date=${filter.searchDate}`);
  } else {
    return httpClient.get(
      `/attendance-once?date=${filter.searchDate}&role=manager`
    );
  }
};

const getAttendanceEmployeesVendor = (id, filter) => {
  return httpClient.get(
    `/company/${id}/employees/attendance-once?date=${filter.searchDate}`
  );
};

const getTimeAttendanceFilterDate = (id, filter) => {
  return httpClient.get(
    `/company/${id}/attendance?start=${dayjs(filter.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
  );
};

const getTimeAttendanceFilterDateByIdVendor = (id, filter) => {
  return httpClient.get(
    `/vendor/${id}/attendance?start=${dayjs(filter.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
  );
};

const getAttendanceById = (filter, id) => {
  return httpClient.get(
    `/attendance?start=${dayjs(filter.start).format("YYYY-MM-DD")}&end=${dayjs(
      filter.end
    ).format("YYYY-MM-DD")}`
  );
};

const getAttendanceTransaction = (filter) => {
  return httpClient.get(
    `/attendance/transaction?start=${dayjs(filter.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
  );
};

const getAttendanceCompany = (idCompany, filter) => {
  return httpClient.get(
    `/company/${idCompany}/attendance/timeline?start=${dayjs(
      filter.start
    ).format("YYYY-MM-DD")}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
  );
};

const getAttendanceCompanyByIdEmp = (filter, idCompany, idEmp) => {
  return httpClient.get(
    `/attendance/${idEmp}?start=${dayjs(
      filter.start
    ).format("YYYY-MM-DD")}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
  );
};

const getAllAttendanceToManager = (id) => {
  return httpClient.get("/company/attendanceToManager/" + id);
};

const getAttendanceByManager = (filter) => {
  return httpClient.get(`/attendance?filter=manager&date=${filter.searchDate}`);
};

const getAttendanceByIdEmpEditRequest = (idEmp, idCompany, date) => {
  return httpClient.get(
    `/manager/attendanceByIdEmp/edit-request?idEmp=${idEmp}&idCompany=${idCompany}&date=${dayjs(
      date
    ).format("YYYY-MM-DD")}`
  );
};

const addAttendance = (formData) => {
  return httpClient.post(`/attendance`, formData);
};

const getAttendanceVendor = (idEmp, idCompany, filter) => {
  let queryText = "";
  if (idEmp) {
    queryText = `employee=${idEmp}&`
  }

  if (idCompany) {
    queryText = `company=${idCompany}&`
  }

  return httpClient.get(
    `attendance-all?${queryText}start=${dayjs(filter.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
  );
};

const getWorkingHoursByIdEmployees = (idEmp, query) => {
  return httpClient.get(`/working-hours/${idEmp}`, { params: query });
};

export default {
  getAttendanceById,
  getAttendanceByManager,
  getAttendanceTransaction,
  getAttendanceOnce,
  getAttendanceEmployeesVendor,
  getTimeAttendanceFilterDate,
  getTimeAttendanceFilterDateByIdVendor,
  getAllAttendanceToManager,
  getAttendanceCompany,
  getAttendanceCompanyByIdEmp,
  getAttendanceByIdEmpEditRequest,
  addAttendance,
  getAttendanceVendor,
  getWorkingHoursByIdEmployees
};
