import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { CardActionArea } from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import StyledCard from "../../shared/general/Card";

import SelectCompany from "../shared/selectCompany";
import CustomPlan from "./customPlan";
import ListEmployee from "./listEmployee";

import { getShift } from "../../../../actions/shift";

const StyledRoot = styled("div")({
  minHeight: 700,
  backgroundColor: "#FFFFFF !important",
});

const StyledAvatar = styled(Avatar)(({ shift }) => ({
  width: 30,
  height: 30,
  ...(shift === `A` && {
    backgroundColor: "#ffb94e",
  }),
  ...(shift === `D` && {
    backgroundColor: "#fe6c76",
  }),
  ...(shift === `C` && {
    backgroundColor: "#29a2e0",
  }),
  ...(shift === `B` && {
    backgroundColor: "#02bbb5",
  }),
}));

const StyledFab = styled(Fab)(({ status }) => ({
  boxShadow: "#f4f6f8 0px 8px 16px 0px",
  height: 22,
  fontSize: 12,
  alignItems: "center",
  paddingLeft: 4,
  paddingRight: 5,
  ...(status === `approve` && {
    backgroundColor: "#ebfaf2 !important",
    color: "#00c292 !important",
  }),
  ...(status === `disapprove` && {
    backgroundColor: "#fdf3f5 !important",
    color: "#e46a76 !important",
  }),
  ...(status === `pending` && {
    backgroundColor: "#fffcd6 !important",
    color: "#fec90f !important",
  }),
}));

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
});

const StyledCardAdd = styled(Card)({
  height: 270,
  border: "1px #919eab52 dashed",
  borderRadius: 20,
  backgroundColor: "transparent",
  "& .cardActionArea": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  "& .cardContent": {
    padding: "16px !important",
    width: "100%",
    height: 199,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#b1b1b11f",
    margin: 16,
    borderRadius: 16,
  },
});

const StyledCardShift = styled("div")({
  padding: 16,
  "& .part-one": {
    display: "flex",
    justifyContent: "space-between",
    "& .start-create-date": {
      width: "100%",
      "& .inner": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
    },
  },
  "& .partTwo": {
    boxShadow: "#f4f6f8 0px 8px 16px 0px",
    backgroundColor: "#ecf7ff",
    color: "#007afe",
    borderRadius: 16,
    marginTop: 8,
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  "& .partThree": {
    marginTop: 8,
    display: "flex",
    justifyContent: "space-between",
  },
  "& .partFour": {
    height: 30,
    marginTop: 8,
    display: "flex",
    justifyContent: "space-evenly",
  },
});

const StyledPleaseSelectCompany = styled("div")({
  padding: 18,
  width: "100%",
  "& .MuiTypography-root": {
    fontStyle: "oblique",
    letterSpacing: 2,
  },
});

export default function ShiftVendorPage(props) {
  const { result: shiftGroupList } = useSelector((state) => state.shift);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSelected, setValueSelected] = React.useState(null);
  const [menuSelected, setMenuSelected] = React.useState(null);
  const [openChoosingCompany, setOpenChoosingCompany] = useState(true);
  const [company, setCompany] = useState(null);
  const [branch, setBranch] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const OrderShiftName = ["A", "B", "C", "D", "E", "F"];

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const displayTimeType = (shiftType, typeEng) => {
    let FilterType = shiftType
      ? shiftType.find((shift) =>
          shift.nameShiftType.toLowerCase().includes(typeEng)
        )
      : undefined;
    if (FilterType) {
      let splitStartTime = FilterType.timeIn.split(":");
      let splitEndTime = FilterType.timeOut.split(":");
      let startTime = dayjs()
        .hour(splitStartTime[0])
        .minute(splitStartTime[1])
        .format("HH:mm");
      let endTime = dayjs()
        .hour(splitEndTime[0])
        .minute(splitEndTime[1])
        .format("HH:mm");
      return `${startTime} - ${endTime}`;
    } else {
      return "-";
    }
  };

  const handleChangeCompany = (newCompany) => {
    setCompany(newCompany);
    if (newCompany) {
      dispatch(getShift(newCompany.idCompany));
    }
  };

  const handleClickAll = () => {
    setValueSelected(null);
    setMenuSelected(null);
    setBreadcrumbs([]);
  };

  const handleClickListName = () => {
    setMenuSelected("list");
    let temp = [];
    temp.push(
      <Link underline="hover" key="1" color="inherit" onClick={handleClickAll}>
        {t("All")}
      </Link>
    );
    temp.push(
      <Link
        underline="hover"
        key="2"
        color="inherit"
        onClick={handleClickListName}
      >
        {valueSelected.nameShiftGroup}
      </Link>
    );
    setBreadcrumbs(temp);
  };

  const handleClickEditTimeWorking = () => {
    setMenuSelected("custom");
    let temp = [...breadcrumbs];
    temp.push(
      <Link underline="hover" key="3" color="inherit">
        {t("EditWorkTime")}
      </Link>
    );
    setBreadcrumbs(temp);
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("Shift_Management")}
          </Typography>
        </div>

        <SelectCompany handleChangeCompany={handleChangeCompany} />
        <div style={{ marginTop: 16 }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <Fragment>
          {company ? (
            <Fragment>
              {menuSelected === null && (
                <Fragment>
                  <div style={{ marginTop: 24, paddingBottom: 20 }}>
                    <Typography
                      color="text.third"
                      style={{ fontWeight: 600, fontSize: 14, marginBottom: 8 }}
                    >
                      {t("ShiftList")}
                    </Typography>
                    <Grid container spacing={3}>
                      {/* <Grid item xs={12} sm={4} md={3}>
                        <StyledCardAdd variant="outlined">
                          <CardActionArea
                            className={`cardActionArea`}
                            onClick={() =>
                              props.history.push("/vendor/shift/new")
                            }
                          >
                            <CardContent className={`cardContent`}>
                              <AddIcon
                                style={{ fontSize: 48, color: "#637381" }}
                              />
                              <Typography
                                style={{ fontSize: 20, color: "#637381" }}
                              >
                                เพิ่มกะใหม่
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </StyledCardAdd>
                      </Grid> */}
                      {shiftGroupList &&
                        shiftGroupList.map((shiftGroup) => (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            key={shiftGroup.nameShiftGroup}
                          >
                            <StyledCard style={{ marginTop: "0 !important" }}>
                              <StyledCardShift>
                                <div className={`part-one`}>
                                  <div className={`start-create-date`}>
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                      style={{ fontSize: 14 }}
                                    >
                                      {t("Start")}:{" "}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      style={{ fontSize: 14, fontWeight: 500 }}
                                    >
                                      {dayjs(shiftGroup.shiftStartDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </Typography>
                                  </div>

                                  <div className={`inner`}>
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                      onClick={(event) => {
                                        setValueSelected(shiftGroup);
                                        setAnchorEl(event.currentTarget);
                                      }}
                                    >
                                      <MoreVertIcon fontSize="inherit" />
                                    </IconButton>
                                  </div>
                                </div>
                                <div className={`partTwo`}>
                                  <Typography variant="h5">
                                    {shiftGroup.nameShiftGroup}
                                  </Typography>
                                </div>
                                <div className={`partThree`}>
                                  <Typography color="text.secondary">
                                    {t("Day")}:{" "}
                                  </Typography>
                                  <Typography>
                                    {displayTimeType(
                                      shiftGroup.shiftType,
                                      "day"
                                    )}
                                  </Typography>
                                </div>
                                <div className={`partThree`}>
                                  <Typography color="text.secondary">
                                    {t("Mid")}:{" "}
                                  </Typography>
                                  <Typography>
                                    {displayTimeType(
                                      shiftGroup.shiftType,
                                      "mid"
                                    )}
                                  </Typography>
                                </div>
                                <div className={`partThree`}>
                                  <Typography color="text.secondary">
                                    {t("Night")}:
                                  </Typography>
                                  <Typography>
                                    {displayTimeType(
                                      shiftGroup.shiftType,
                                      "night"
                                    )}
                                  </Typography>
                                </div>
                                <div className={`partFour`}>
                                  {shiftGroup.shiftNumber &&
                                    shiftGroup.shift &&
                                    shiftGroup.shift.map((value, index) => (
                                      <StyledAvatar
                                        shift={`${OrderShiftName[index]}`}
                                        key={value.nameShift}
                                      >
                                        {OrderShiftName[index]}
                                      </StyledAvatar>
                                    ))}
                                </div>
                              </StyledCardShift>
                            </StyledCard>
                          </Grid>
                        ))}
                    </Grid>
                    <StyledMenu
                      id={`more-menu`}
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                  
                      <MenuItem onClick={handleClickListName}>
                        {t("List")}
                      </MenuItem>
                      {/* {valueSelected && (
                        <MenuItem
                          component={NavLink}
                          to={`/vendor/shift/year/" + ${valueSelected.idShiftGroup}`}
                        >
                          {t("YearlyShiftSchedule")}
                        </MenuItem>
                      )}
                      <MenuItem>{t("EditShiftSchedule")}</MenuItem> */}
                    </StyledMenu>
                  </div>
                </Fragment>
              )}
              {menuSelected === "list" && (
                <ListEmployee
                  shiftGroup={valueSelected}
                  handleClickEditTimeWorking={handleClickEditTimeWorking}
                />
              )}
              {menuSelected === "custom" && (
                <CustomPlan shiftGroup={valueSelected} company={company} />
              )}
            </Fragment>
          ) : (
            <StyledPleaseSelectCompany>
              <Typography color="text.third" align="center">
                ...{t("PleaseSelectCompany")}...
              </Typography>
            </StyledPleaseSelectCompany>
          )}
        </Fragment>
      </Container>
    </StyledRoot>
  );
}
