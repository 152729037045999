import dayjs from "dayjs";
import { httpClient } from "./httpClient"

const getWorkingTimeReport = (query) => {
    return httpClient.get(`vendor/working-time`, { params: query });
}

const getSummaryOvertimeShift = (query) => {
    return httpClient.get(`vendor/summary-ot-shift`, { params: query });
}

const getTotalOtWage = (query) => {
    return httpClient.get(`payment/allOTWage`, { params: query });
}

const getTotalOtHours = (query) => {
    return httpClient.get(`payment/allOThour`, { params: query });
}

const getEmployWorkingDays = (query) => {
    return httpClient.get(`employees-workingday-export-excel`, { params: query });
}

const exportExcelLeaveReport = (query) => {
    return httpClient.get(`/all-leave-report`, { params: query, responseType: 'blob' });
};

const getLeaveListReport = (query) => {
    return httpClient.get(`leave-list`, { params: query });
};

const getLeaveDetails = (query) => {
    return httpClient.get(`groupedLeave`, { params: query })
};

const getNewEmployee = (query) => {
    return httpClient.get(`new-employee`, { params: query })
};

const getResignEmployee = (query) => {
    return httpClient.get(`resigned-employee`, { params: query })
};

const getEmployeeContractTermination = (query) => {
    return httpClient.get(`terminated-employee-contract`, { params: query })
};

const exportExcelEmployeeInformationReport = (query) => {
    return httpClient.get(`employee-information-report`, { params: query, responseType: 'blob' })
};

const getLevelingReport = (query) => {
    return httpClient.get(`updated-level-info`, { params: query })
};

const getEmploymentReport = (query) => {
    return httpClient.get(`updated-employment-type`, { params: query })
};

const getTransferPosition = (query) => {
    return httpClient.get(`updated-transfer-position`, { params: query })
};

const getPayrollVendor = (filter, start) => {
    return httpClient.get(
        `PayrollVendor?idCompany=${filter}&start=${dayjs(start).format("YYYY-MM-DD")}`
    );
};

const getPayrollemployee = (filter, start) => {
    return httpClient.get(
        `Payrollemployee?idCompany=${filter}&start=${dayjs(start).format("YYYY-MM-DD")}`
    );
};

const getSalaryAdjustEmployee = (query) => {
    return httpClient.get(`/salaryAdjustEmployee`, { params: query })
};

const getPFvalueAllYearByEmp = (query) => {
    return httpClient.get(`employee-year-pf`, { params: query })
};

const exportExcelRequestListReport = (query) => {
    return httpClient.get(`request-list-report`, { params: query, responseType: 'blob' });
};

export default {
    getWorkingTimeReport,
    getSummaryOvertimeShift,
    getTotalOtWage,
    getTotalOtHours,
    getEmployWorkingDays,
    exportExcelLeaveReport,
    getLeaveListReport,
    getLeaveDetails,
    getNewEmployee,
    getResignEmployee,
    getEmployeeContractTermination,
    exportExcelEmployeeInformationReport,
    getLevelingReport,
    getEmploymentReport,
    getTransferPosition,
    getPayrollVendor,
    getPayrollemployee,
    getSalaryAdjustEmployee,
    getPFvalueAllYearByEmp,
    exportExcelRequestListReport
}