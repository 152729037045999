import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "react-use";
import dayjs from "dayjs";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { createAPIEndpoint, ENDPOINTS } from "../../../../../services/api";
//import materials
import { CardContent, Container, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

//icon
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SaveIcon from "@mui/icons-material/Save";

//custom component
import ButtonBlue from "../../../shared/general/ButtonBlue";
import StyledCard from "../../../shared/general/Card";
import AlertResponse from "../../../shared/general/AlertResponse";

//import tools
import BasicInfo from "./BasicInfo";
import EducationDetails from "./EducationDetails";
import EmploymentType from "./EmploymentType";
import Experience from "./Experience";
import JobDetails from "./JobDetails";
import PayrollDetails from "./PayrollDetails";
import ProfileImage from "./ProfileImage";
import Manager from "./manager";

//import style
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { getAllDataForm } from "../../../../../actions/employee";
import { addUsersOne } from "../../../../../actions/user";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
});
const StyledCardContentProfileImage = styled(CardContent)({
  padding: "16px !important",
  backgroundColor: "#2F74EB",
});

const StyledWrapButtonContinue = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  "& .btn-next": {
    marginLeft: "auto",
  },
});

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0),
    },
  },
  profile: {
    marginBottom: 20,
    width: "100%",
  },
  details: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  avatar: {
    marginRight: 30,
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
    marginLeft: 30,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    marginTop: 25,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  tabs1: {
    width: "100%",
    marginTop: 20,
  },
  empImage_Container: {
    padding: 16,
  },
  tabContainer: {},
}));

const InitialValues = () => ({
  //EMPLOYEES
  empId: 0,
  title: "",
  firstName: "",
  lastName: "",
  nickName: "",
  gender: "",
  image: "",
  idCard: "",
  birthDate: null,
  phoneNumber: "",
  address: "",
  email: "",
  imagePath: "",
  //EMPLOYMENT TYPES
  empType: "Employee",
  empClassification: "",
  paymentType: "",
  //JOB DETAILS
  employeeId: "",
  jobPosition: "",
  jobGroup: "",
  jobClassification: "",
  division: "",
  department: "",
  section: "",
  hireDate: null,
  reportingManager: "",
  companyEmployer: "",
  companyCourse: "",
  companyAgency: "",
  jobType: "",
  workLocation: "",
  terminate: "",
  terminateDate: null,
  //PAYROLL DETAILS
  dayShift: "",
  shiftSection: "",
  shiftType: "",
  timeIn: null,
  timeOut: null,
  workPerWeek: null,
  weeklyHours: null,
  //EDUCATION DETAILS
  university: "",
  universityFrom: null,
  universityActual: null,
  degree: "",
  gpa: "",
  //OFFER DETAILS
  offerLetter: "",
  hiringAgreement: false,
  companyHandbook: false,
  offerExpireDate: null,
  compensationType: "",
  amount: "",
  reportHours: null,
  timeOffPolicy: null,
  additionTerms: "",
  //HEALTH Benefits
  medical: true,
  ppoDirect1: false,
  ppoDirent2: false,
  dental: false,
  dentalCare: false,
  vision: false,
  visionSignature: false,
  status: "Active",
  createBy: "Admin",
  dateCreate: new Date(),
  updateBy: "Admin",
  dateUpdate: new Date(),
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <StyledCard>{children}</StyledCard>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const phoneRegExp = /^0\d\d-\d\d\d-\d\d\d\d$/;
const validationSchema = Yup.object({
  employeeId: Yup.string().required("โปรดกรอกข้อมูล").nullable(),
  title: Yup.string().required("โปรดกรอกข้อมูล").nullable(),
  firstname: Yup.string().required("โปรดกรอกข้อมูล").nullable(),
  lastname: Yup.string().required("โปรดกรอกข้อมูล").nullable(),
  gender: Yup.string().required("โปรดระบุข้อมูล").nullable(),
  personalId: Yup.string().required("โปรดกรอกข้อมูล").nullable(),
  birthday: Yup.string().required("โปรดกรอกข้อมูล").nullable(),
  telephoneMobile: Yup.string()
    .required("โปรดกรอกข้อมูล")
    .matches(phoneRegExp, "เบอร์โทรศัพท์ไม่ถูกต้อง")
    .nullable(),
  employmentTime: Yup.string().required("โปรดระบุข้อมูล").nullable(),
  idPaymentType: Yup.string().required("โปรดระบุข้อมูล").nullable(),
  hiringDate: Yup.string().required("โปรดกรอกข้อมูล").nullable(),
  idCompany: Yup.string().required("โปรดระบุข้อมูล").nullable(),
  idPositions: Yup.string().required("โปรดระบุข้อมูล").nullable(),
  idEmployeeType: Yup.string().required("โปรดระบุข้อมูล").nullable(),
  filed_office: Yup.string().required("โปรดระบุข้อมูล").nullable(),
  workingType: Yup.string().required("โปรดระบุข้อมูล").nullable(),
  idShift: Yup.string().required("โปรดระบุข้อมูล").nullable(),
  mainWorkingLocationPoint: Yup.string().required("โปรดระบุข้อมูล").nullable(),
  managerLV1: Yup.boolean(),
  idManagerLV1: Yup.string().when("managerLV1", {
    is: false,
    then: Yup.string().required("โปรดระบุข้อมูล").nullable(),
    otherwise: Yup.string().nullable(),
  }),
  managerLV1Name: Yup.string().when("managerLV1", {
    is: true,
    then: Yup.string().required("โปรดระบุข้อมูล").nullable(),
    otherwise: Yup.string().nullable(),
  }),
  managerLV1Position: Yup.string().when("managerLV1", {
    is: true,
    then: Yup.string().required("โปรดระบุข้อมูล").nullable(),
    otherwise: Yup.string().nullable(),
  }),
  managerLV1Email: Yup.string().when("managerLV1", {
    is: true,
    then: Yup.string().required("โปรดระบุข้อมูล").nullable(),
    otherwise: Yup.string().nullable(),
  isFinger: Yup.number(),
  }),
  salary: Yup.number()
    .positive("ต้องมากกว่า 0")
    .required("โปรดระบุข้อมูล")
    .nullable(),
  chargeSalary: Yup.number().positive("ต้องมากกว่า 0").nullable(),
  chargeOt: Yup.number().positive("ต้องมากกว่า 0").nullable(),
  chargeHire: Yup.number().positive("ต้องมากกว่า 0").nullable(),
  chargeCompensation: Yup.number().positive("ต้องมากกว่า 0").nullable(),
});

function AddEmployee() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  useTitle("เพิ่มพนักงานใหม่ - Ant HR");
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [tabValue, setTabValue] = React.useState(0);
  const [empImage, setEmpImage] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);
  const [companySelect, setCompanySelect] = useState({
    idCompany: "",
    companyName: "",
    job: [],
    jobLevel: [],
    personnelLevel: [],
    employeeType: [],
    groupGpsLocations: [],
    shift: [],
    manager: [],
  });

  const formik = useFormik({
    initialValues: {
      employeeId: null,
      title: null,
      firstname: null,
      lastname: null,
      title_en: null,
      firstname_en: null,
      lastname_en: null,
      nickname: null,
      gender: null,
      personalId: null,
      birthday: new Date(),
      telephoneMobile: null,
      email: null,
      emergencyContact: null,
      emergencyRelationship: null,
      emergencyPhone: null,
      // address: null,
      houseNo: null,
      vilage: null,
      vilageNo: null,
      alley: null,
      road: null,
      areaCode: null,
      subDistrict: null,
      district: null,
      provience: null,
      hiringDate: new Date(),
      salary: null,
      costCenterCharge: null,
      costElementCharge: null,
      bookBank: null,
      bookBankBranch: null,
      bookBankId: null,
      filed_office: null,
      contractTermainatoinDate: null,
      isContractTermainatoinDate: "none",
      resignDate: null,
      age_Y: null,
      age_M: null,
      age: null,
      idShift: null,
      idShiftGroup: null,
      idPositions: null,
      idJobLevel: null,
      idPersonnelLevel: null,
      idEmployeeType: null,
      idPaymentType: null,
      paymentRound: null,
      employmentTime: null,
      mainWorkingLocationPoint: null,
      workingLocationName: null,
      methodAttendance: "main",
      idVendor: null,
      workingType: null,
      idCompany: null,
      chargeSalary: null,
      chargeOt: null,
      chargeHire: null,
      chargePercent: true,
      chargeCompensation: null,
      lineUserId: null,
      passportId: null,
      maritalStatus: null,
      nationality: null,
      workPermit: null,
      criminalImage: null,
      managerLV1: false,
      managerLV1Name: null,
      managerLV1Position: null,
      managerLV1Email: null,
      managerLV2: false,
      managerLV2Name: null,
      managerLV2Position: null,
      managerLV2Email: null,
      isCustomShift: 0,
      isActive: 1,
      idManagerLV1: null,
      idManagerLV2: null,
      isFinger: 1,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values);
    },
    validateOnChange: false,
  });

  // For Taps

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSubmit = async (values) => {
    values.idVendor = userProfile.idVendor;
    values.birthday = dayjs(values.birthday).format("YYYY-MM-DD");
    values.hiringDate = dayjs(values.hiringDate).format("YYYY-MM-DD");
    values.contractTermainatoinDate = values.contractTermainatoinDate
      ? dayjs(values.contractTermainatoinDate).format("YYYY-MM-DD")
      : null;

    education.forEach((element) => {
      element.fromYear = element.fromYear
        ? dayjs(element.fromYear).format("YYYY")
        : null;
      element.endYear = element.endYear
        ? dayjs(element.endYear).format("YYYY")
        : null;
    });

    const formData = {
      employee: values,
      education: education,
      experience: experience,
    };

    console.log(formData);

    // const result = await dispatch(addUsersOne(formData));
    // if (result) {
    //   if (result.status === 201) {
    //     handleChangeAlertType("success");
    //     handleOpenAlert();
    //     history.push(`/vendor/employees/${result.data.employee}/edit`);
    //   } else {
    //     handleOpenAlert();
    //     handleChangeAlertType("error");
    //   }
    // } else {
    //   handleChangeAlertType("success");
    //   handleOpenAlert();
    // }
  };

  const handleNextTab = () => {
    console.log(tabValue);
    setTabValue(tabValue + 1);
  };

  const handlePrevTab = () => {
    if (tabValue != 0) {
      setTabValue(tabValue - 1);
    }
  };

  const handleChangeAlertType = (status) => {
    setAlertType(status);
  };
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    dispatch(getAllDataForm());
  }, []);

  return (
    <StyledRoot className={`page`}>
      {/* Left Side */}
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <StyledCard>
              <StyledCardContentProfileImage>
                <ProfileImage empImage={empImage} setEmpImage={setEmpImage} />
              </StyledCardContentProfileImage>
            </StyledCard>
            <StyledCard className={classes.tabs1}>
              <div className={classes.tabContainer}>
                <CardContent>
                  <Tabs
                    orientation="vertical"
                    variant="standard"
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                  >
                    <Tab label="ข้อมูลทั่วไป" {...a11yProps(0)} />
                    <Tab label="ประเภทพนักงาน" {...a11yProps(1)} />
                    <Tab label="รายละเอียดงาน" {...a11yProps(2)} />
                    <Tab label="หัวหน้างาน" {...a11yProps(3)} />
                    <Tab label="การจ่าย" {...a11yProps(4)} />
                    <Tab label="การศึกษา" {...a11yProps(5)} />
                    {/* <Tab label="ประสบการณ์ทำงาน" {...a11yProps(6)} /> */}
                  </Tabs>
                </CardContent>
              </div>
            </StyledCard>
          </Grid>
          {/* Right Side  */}
          <Grid item xs={12} md={8}>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              {/* Basic Info */}
              <TabPanel value={tabValue} index={0}>
                <BasicInfo formik={formik} onChange={formik.handleChange} />
              </TabPanel>
              {/* Employment Type */}
              <TabPanel value={tabValue} index={1}>
                <EmploymentType
                  formik={formik}
                  onChange={formik.handleChange}
                />
              </TabPanel>
              {/* Job */}
              <TabPanel value={tabValue} index={2}>
                <JobDetails
                  formik={formik}
                  onChange={formik.handleChange}
                  setCompanySelect={setCompanySelect}
                  companySelect={companySelect}
                />
              </TabPanel>
              {/* Manager */}
              <TabPanel value={tabValue} index={3}>
                <Manager
                  formik={formik}
                  onChange={formik.handleChange}
                  companySelect={companySelect}
                />
              </TabPanel>
              {/* Payroll */}
              <TabPanel value={tabValue} index={4}>
                <PayrollDetails
                  formik={formik}
                  onChange={formik.handleChange}
                />
              </TabPanel>
              {/* Education */}
              <TabPanel value={tabValue} index={5}>
                <EducationDetails
                  education={education}
                  setEducation={setEducation}
                />
              </TabPanel>
              {/* Offer */}
              {/* <TabPanel value={tabValue} index={6}>
                <Experience
                  experience={experience}
                  setExperience={setExperience}
                />
              </TabPanel> */}
              <Divider
                style={{ marginTop: 16, marginBottom: 16, border: "none" }}
              />
              <StyledWrapButtonContinue>
                {tabValue > 0 && (
                  <ButtonBlue
                    variant={"outlined"}
                    onClick={() => handlePrevTab(tabValue)}
                    startIcon={<ChevronLeftIcon />}
                  >
                    ย้อนกลับ
                  </ButtonBlue>
                )}
                {tabValue === 5 ? (
                  <ButtonBlue
                    type="submit"
                    className="btn-next"
                    variant={"contained"}
                    startIcon={<SaveIcon />}
                    // disabled={formik.isSubmitting}
                  >
                    {"บันทึกข้อมูล"}
                  </ButtonBlue>
                ) : (
                  <ButtonBlue
                    className="btn-next"
                    variant={"contained"}
                    onClick={handleNextTab}
                    endIcon={<ChevronRightIcon />}
                  >
                    {"ต่อไป"}
                  </ButtonBlue>
                )}
              </StyledWrapButtonContinue>
            </form>
          </Grid>
        </Grid>
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      </Container>
    </StyledRoot>
  );
}

export default AddEmployee;
