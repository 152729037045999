import dayjs from 'dayjs';

const calculatorOTProtech = (values, formInputData, employeeProfile, attendanceListState, requestReason) => {
    let error = {
      errorNoon: false,
      errorOnWorkingTime: false,
      errorTime: false,
    };
    let xOT = 0;
    let xOTHoliday = 0;
    let xWorkingDailyHoliday = 0;
    let xWorkingMonthlyHoliday = 0;
    let xWorkingDaily = 0;
    
    const startDate = dayjs(formInputData.startDate)
      .set("hour", formInputData.startHours)
      .set("minute", formInputData.startMinute)
      .set("second", 0);
    const endDate = dayjs(formInputData.endDate)
      .set("hour", formInputData.endHours)
      .set("minute", formInputData.endMinute)
      .set("second", 0);

    const findDate = {
      start: `${dayjs(values.date).format("YYYY-MM-DD")} ${
        values.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.timeIn}`,
        "YYYY-MM-DD HH:mm"
      )
        .add(values.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };

    const findIndexToday = attendanceListState.findIndex(
      (x) => x.date === values.date
    );

    let leaveHoliday = (
      values.leave && 
      values.leave.length > 0 && 
      values.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
    );
    const isWorkingDay =
      values.pattern.isWorkingDay === 1 &&
      !leaveHoliday &&
      !values.holiday &&
      !values.compensateDate;
    
    let valuesPrev = attendanceListState[findIndexToday - 1];
    let findPrevDate = {
      start: `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
        valuesPrev.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
          valuesPrev.pattern.timeIn
        }`,
        "YYYY-MM-DD HH:mm"
      )
        .add(valuesPrev.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };
    let prevLeaveHoliday = (
      valuesPrev.leave && 
      valuesPrev.leave.length > 0 && 
      valuesPrev.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
    );
    let isWorkingPrevDay =
      valuesPrev.pattern.isWorkingDay === 1 &&
      !prevLeaveHoliday &&
      !valuesPrev.holiday &&
      !valuesPrev.compensateDate;

    let valuesNext = attendanceListState[findIndexToday + 1];
    let findNextDate = {
      start: `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
        valuesNext.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
          valuesNext.pattern.timeIn
        }`,
        "YYYY-MM-DD HH:mm"
      )
        .add(valuesNext.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };
    let nextLeaveHoliday = (
      valuesNext.leave && 
      valuesNext.leave.length > 0 && 
      valuesNext.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
    );
    let isWorkingNextDay =
      valuesNext.pattern.isWorkingDay === 1 &&
      !nextLeaveHoliday && 
      !valuesNext.holiday &&
      !valuesNext.compensateDate;

    let reasonIsBreak = (requestReason && requestReason.isBreak === 1) ? true : false;


    if (formInputData && formInputData.idRequestType === 2) {
      if (
        startDate.isBefore(
          dayjs(
            `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
            "YYYY-MM-DD HH:mm"
          )
        ) ||
        endDate.isSameOrAfter(
          dayjs(
            `${dayjs(values.date).add(2, "day").format("YYYY-MM-DD")} 00:00`,
            "YYYY-MM-DD HH:mm"
          )
        )
      ) {
        error.errorTime = true;
      }

      if (isWorkingPrevDay && startDate.isBefore(findPrevDate.end)) {
        error.errorOnWorkingTime = true;
      }

      if (isWorkingNextDay && endDate.isAfter(findNextDate.start)) {
        error.errorOnWorkingTime = true;
      }

      if (!(endDate.diff(startDate, "minute") > 0)) {
        error.errorTime = true;
      }

      if (values.pattern.idWorkingType === 2) {
        if (
          !(
            startDate.isBetween(
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 12:00`,
                "YYYY-MM-DD HH:mm"
              ),
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 13:00`,
                "YYYY-MM-DD HH:mm"
              ),
              "minute",
              "[)"
            ) &&
            endDate.isBetween(
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 12:00`,
                "YYYY-MM-DD HH:mm"
              ),
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 13:00`,
                "YYYY-MM-DD HH:mm"
              ),
              "minute",
              "(]"
            )
          ) &&
          reasonIsBreak
        ) {
          error.errorNoon = true;
        } else if (
          (dayjs(startDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "[)"
          ) ||
            dayjs(endDate).isBetween(
              dayjs(findDate.start),
              dayjs(findDate.end),
              "minute",
              "(]"
            ) ||
            dayjs(findDate.start).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[)"
            ) ||
            dayjs(findDate.end).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "(]"
            )) &&
          isWorkingDay &&
          !reasonIsBreak
        ) {
          error.errorOnWorkingTime = true;
        }

        if (
          startDate.isBetween(
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00`,
              "YYYY-MM-DD HH:mm"
            ),
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00`,
              "YYYY-MM-DD HH:mm"
            ),
            "minute",
            "[)"
          ) &&
          endDate.isBetween(
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00`,
              "YYYY-MM-DD HH:mm"
            ),
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00`,
              "YYYY-MM-DD HH:mm"
            ),
            "minute",
            "(]"
          ) &&
          !reasonIsBreak
        ) {
          error.errorNoon = true;
        }
      } else {
        if (
          (dayjs(startDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "[)"
          ) ||
            dayjs(endDate).isBetween(
              dayjs(findDate.start),
              dayjs(findDate.end),
              "minute",
              "(]"
            ) ||
            dayjs(findDate.start).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[)"
            ) ||
            dayjs(findDate.end).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "(]"
            )) &&
          isWorkingDay
        ) {
          error.errorOnWorkingTime = true;
        }
      }

      if (!(error.errorNoon || error.errorOnWorkingTime || error.errorTime)) {
        if (values.pattern.idWorkingType === 2) {
          let breaktime = {
            start: dayjs(`${dayjs(values.date).format("YYYY-MM-DD")} 12:00:00`),
            end: dayjs(`${dayjs(values.date).format("YYYY-MM-DD")} 13:00:00`),
          };
          let breaktimeNextDate = {
            start: dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00:00`
            ),
            end: dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00:00`
            ),
          };

          let otBeforePrevWorkEndTime = dayjs(
            endDate.isBefore(findPrevDate.end) ? endDate : findPrevDate.end
          ).diff(
            startDate.isAfter(findPrevDate.start)
              ? startDate
              : findPrevDate.start,
            "minute"
          );
          if (otBeforePrevWorkEndTime > 0) {
            if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
              if(valuesPrev.holiday && valuesPrev.pattern.isWorkingDay === 1){
                xWorkingDaily += otBeforePrevWorkEndTime;
              }else{
                xWorkingDailyHoliday += otBeforePrevWorkEndTime;
              }
            } else {
              xWorkingMonthlyHoliday += otBeforePrevWorkEndTime;
            }
          }

          if (dayjs(`${values.date} 07:30:00`).isBefore(findDate.start)) {
            let seventhirty = dayjs(`${values.date} 07:30:00`);

            let otBeforeSevenThirty = dayjs(
              endDate.isBefore(seventhirty) ? endDate : seventhirty
            ).diff(
              startDate.isAfter(findPrevDate.end)
                ? startDate
                : findPrevDate.end,
              "minute"
            );
            if (otBeforeSevenThirty > 0) {
              if (isWorkingPrevDay) {
                xOT += otBeforeSevenThirty;
              } else {
                xOTHoliday += otBeforeSevenThirty;
              }
            }

            let otBeforeWorkTime = dayjs(
              endDate.isBefore(findDate.start) ? endDate : findDate.start
            ).diff(
              startDate.isAfter(seventhirty) ? startDate : seventhirty,
              "minute"
            );
            if (otBeforeWorkTime > 0) {
              if (isWorkingDay) {
                xOT += otBeforeWorkTime;
              } else {
                xOTHoliday += otBeforeWorkTime;
              }
            }

            let otBeforeWorkEndTime = dayjs(
              endDate.isBefore(findDate.end) ? endDate : findDate.end
            ).diff(
              startDate.isAfter(findDate.start) ? startDate : findDate.start,
              "minute"
            );
            if (otBeforeWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(values.holiday && values.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeWorkEndTime;
                }else{
                  xWorkingDailyHoliday += otBeforeWorkEndTime;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeWorkEndTime;
              }
            }
          } else {
            let otBeforeWorkTime = dayjs(
              endDate.isBefore(findDate.start) ? endDate : findDate.start
            ).diff(
              startDate.isAfter(findPrevDate.end)
                ? startDate
                : findPrevDate.end,
              "minute"
            );
            if (otBeforeWorkTime > 0) {
              if (isWorkingPrevDay) {
                xOT += otBeforeWorkTime;
              } else {
                xOTHoliday += otBeforeWorkTime;
              }
            }

            let otBeforeBreakTime = dayjs(
              endDate.isBefore(breaktime.start) ? endDate : breaktime.start
            ).diff(
              startDate.isAfter(findDate.start) ? startDate : findDate.start,
              "minute"
            );
            if (otBeforeBreakTime > 0) {
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(values.holiday && values.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeBreakTime;
                }else{
                  xWorkingDailyHoliday += otBeforeBreakTime;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeBreakTime;
              }
            }

            let otBetweenBreakTime = dayjs(
              endDate.isBefore(breaktime.end) ? endDate : breaktime.end
            ).diff(
              startDate.isAfter(breaktime.start) ? startDate : breaktime.start,
              "minute"
            );
            if (otBetweenBreakTime > 0) {
              if (reasonIsBreak) {
                if (isWorkingDay) {
                  xOT += otBetweenBreakTime;
                } else {
                  xOTHoliday += otBetweenBreakTime;
                }
              }
            }

            let otBeforeWorkEndTime = dayjs(
              endDate.isBefore(findDate.end) ? endDate : findDate.end
            ).diff(
              startDate.isAfter(breaktime.end) ? startDate : breaktime.end,
              "minute"
            );
            if (otBeforeWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(values.holiday && values.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeWorkEndTime;
                }else{
                  xWorkingDailyHoliday += otBeforeWorkEndTime;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeWorkEndTime;
              }
            }
          }

          if (
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30:00`
            ).isBefore(findNextDate.start)
          ) {
            let seventhirty = dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30:00`
            );

            let otBeforeSevenThirty = dayjs(
              endDate.isBefore(seventhirty) ? endDate : seventhirty
            ).diff(
              startDate.isAfter(findDate.end) ? startDate : findDate.end,
              "minute"
            );
            if (otBeforeSevenThirty > 0) {
              if (isWorkingDay) {
                xOT += otBeforeSevenThirty;
              } else {
                xOTHoliday += otBeforeSevenThirty;
              }
            }

            let otBeforeNextWorkTime = dayjs(
              endDate.isBefore(findNextDate.start)
                ? endDate
                : findNextDate.start
            ).diff(
              startDate.isAfter(seventhirty) ? startDate : seventhirty,
              "minute"
            );
            if (otBeforeNextWorkTime > 0) {
              if (isWorkingNextDay) {
                xOT += otBeforeNextWorkTime;
              } else {
                xOTHoliday += otBeforeNextWorkTime;
              }
            }
          } else {
            let otBeforeNextWorkTime = dayjs(
              endDate.isBefore(findNextDate.start)
                ? endDate
                : findNextDate.start
            ).diff(
              startDate.isAfter(findDate.end) ? startDate : findDate.end,
              "minute"
            );
            if (otBeforeNextWorkTime > 0) {
              if (isWorkingDay) {
                xOT += otBeforeNextWorkTime;
              } else {
                xOTHoliday += otBeforeNextWorkTime;
              }
            }
          }

          if (
            breaktimeNextDate.start.isBetween(
              dayjs(findNextDate.start),
              dayjs(findNextDate.end),
              "minute",
              "[]"
            ) &&
            breaktimeNextDate.end.isBetween(
              dayjs(findNextDate.start),
              dayjs(findNextDate.end),
              "minute",
              "[]"
            )
          ) {
            let otBeforeBreakTimeNextDate = dayjs(
              endDate.isBefore(breaktimeNextDate.start)
                ? endDate
                : breaktimeNextDate.start
            ).diff(
              startDate.isAfter(findNextDate.start)
                ? startDate
                : findNextDate.start,
              "minute"
            );
            if (otBeforeBreakTimeNextDate > 0) {
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(valuesNext.holiday && valuesNext.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeBreakTimeNextDate;
                }else{
                  xWorkingDailyHoliday += otBeforeBreakTimeNextDate;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeBreakTimeNextDate;
              }
            }

            let otBeforeNextWorkEndTime = dayjs(
              endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
            ).diff(
              startDate.isAfter(breaktimeNextDate.end)
                ? startDate
                : breaktimeNextDate.end,
              "minute"
            );
            if (otBeforeNextWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(valuesNext.holiday && valuesNext.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeNextWorkEndTime;
                }else{
                  xWorkingDailyHoliday += otBeforeNextWorkEndTime;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
              }
            }
          } else {
            let otBeforeNextWorkEndTime = dayjs(
              endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
            ).diff(
              startDate.isAfter(findNextDate.start)
                ? startDate
                : findNextDate.start,
              "minute"
            );
            if (otBeforeNextWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(valuesNext.holiday && valuesNext.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeNextWorkEndTime;
                }else{
                  xWorkingDailyHoliday += otBeforeNextWorkEndTime;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
              }
            }
          }

          let otAfterNextWorkEndTime = dayjs(endDate).diff(
            startDate.isAfter(findNextDate.end) ? startDate : findNextDate.end,
            "minute"
          );
          if (otAfterNextWorkEndTime > 0) {
            xOTHoliday += otAfterNextWorkEndTime;
          }
        } else if (values.pattern.idWorkingType === 1) {
          let allResult = {};
          const otDuringPrevWorkTime = dayjs(
            endDate.isBefore(findPrevDate.end) ? endDate : findPrevDate.end
          ).diff(
            startDate,
            "minute"
          );
          if(otDuringPrevWorkTime > 0){
            allResult['otDuringPrevWorkTime'] = otDuringPrevWorkTime;
            if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
              if((valuesPrev.holiday || valuesPrev.compensateDate) && valuesPrev.pattern.isWorkingDay === 1){
                xWorkingDaily += otDuringPrevWorkTime;
              }else{
                xWorkingDailyHoliday += otDuringPrevWorkTime;
              }
            } else {
              xWorkingMonthlyHoliday += otDuringPrevWorkTime;
            }
          }

          let checkPointNewDate = findPrevDate.end;
          let dayCutOff = dayjs(
            `${dayjs(values.date).format("YYYY-MM-DD")} 07:30`,
            "YYYY-MM-DD HH:mm"
          );
          if(dayjs(findPrevDate.end).isBefore(dayCutOff)){
            const otAfterPrevWorkTime = dayjs(
              endDate.isBefore(dayCutOff) ? endDate : dayCutOff
            ).diff(
              startDate.isAfter(findPrevDate.end) ? startDate : findPrevDate.end,
              "minute"
            );
            if(otAfterPrevWorkTime > 0){
              allResult['otAfterPrevWorkTime'] = otAfterPrevWorkTime;
              if(isWorkingPrevDay){
                xOT += otAfterPrevWorkTime;
              }else{
                xOTHoliday += otAfterPrevWorkTime;
              }
            }
            checkPointNewDate = dayCutOff;
          }

          const otBeforeWorkTime = dayjs(
            endDate.isBefore(findDate.start) ? endDate : findDate.start
          ).diff(
            startDate.isAfter(checkPointNewDate) ? startDate : checkPointNewDate,
            "minute"
          );
          if(otBeforeWorkTime > 0){
            allResult['otBeforeWorkTime'] = otBeforeWorkTime;
            if(isWorkingDay){
              xOT += otBeforeWorkTime;
            }else{
              xOTHoliday += otBeforeWorkTime;
            }
          }

          let endBreak = findDate.start;
          if(values.pattern.breakTime !== 1){
            let startBreak = dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || (values.pattern.period === 3 ? "00:00:00" : "12:00:00")}`
            );
            if(!startBreak.isBetween(findDate.start, findDate.end, "minute", "()")){
              if(values.pattern.period === 3){
                startBreak = dayjs(
                  `${dayjs(values.date).format("YYYY-MM-DD")} 00:00:00`
                ).add(1, "day");
              }else{
                startBreak = dayjs(
                  `${dayjs(values.date).format("YYYY-MM-DD")} 12:00:00`
                );
              }
            }
            endBreak = startBreak.add((values.pattern.breakTimeMin || 60), "minute");
            const otBeforeBreakTime = dayjs(
              endDate.isBefore(startBreak) ? endDate : startBreak
            ).diff(
              startDate.isAfter(findDate.start) ? startDate : findDate.start,
              "minute"
            );
            if(otBeforeBreakTime > 0){
              allResult['otBeforeBreakTime'] = otBeforeBreakTime;
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if((values.holiday || values.compensateDate) && values.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeBreakTime;
                }else{
                  xWorkingDailyHoliday += otBeforeBreakTime;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeBreakTime;
              }
            }
            
            let otBetweenBreakTime = dayjs(
              endDate.isBefore(endBreak) ? endDate : endBreak
            ).diff(
              startDate.isAfter(startBreak) ? startDate : startBreak,
              "minute"
            );
            if (otBetweenBreakTime > 0) {
              allResult['otBetweenBreakTime'] = otBetweenBreakTime;
              if (reasonIsBreak) {
                if (isWorkingDay) {
                  xOT += otBetweenBreakTime;
                } else {
                  xOTHoliday += otBetweenBreakTime;
                }
              }
            }
          }

          const otBeforeEndWorkTime = dayjs(
            endDate.isBefore(findDate.end) ? endDate : findDate.end
          ).diff(
            startDate.isAfter(endBreak) ? startDate : endBreak,
            "minute"
          );
          if(otBeforeEndWorkTime > 0){
            allResult['otBeforeEndWorkTime'] = otBeforeEndWorkTime;
            if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
              if((values.holiday || values.compensateDate) && values.pattern.isWorkingDay === 1){
                xWorkingDaily += otBeforeEndWorkTime;
              }else{
                xWorkingDailyHoliday += otBeforeEndWorkTime;
              }
            } else {
              xWorkingMonthlyHoliday += otBeforeEndWorkTime;
            }
          }

          let checkPointNextNewDate = findDate.end;
          let nextDayCutOff = dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30`,
            "YYYY-MM-DD HH:mm"
          );
          if(dayjs(findDate.end).isBefore(nextDayCutOff)){
            const otAfterWorkTime = dayjs(
              endDate.isBefore(nextDayCutOff) ? endDate : nextDayCutOff
            ).diff(
              startDate.isAfter(findDate.end) ? startDate : findDate.end,
              "minute"
            );
            if(otAfterWorkTime > 0){
              allResult['otAfterWorkTime'] = otAfterWorkTime;
              if(isWorkingDay){
                xOT += otAfterWorkTime;
              }else{
                xOTHoliday += otAfterWorkTime;
              }
            }
            checkPointNextNewDate = nextDayCutOff;
          }
          const otBeforeNextWorkTime = dayjs(
            endDate.isBefore(findNextDate.start) ? endDate : findNextDate.start
          ).diff(
            startDate.isAfter(checkPointNextNewDate) ? startDate : checkPointNextNewDate,
            "minute"
          );
          if(otBeforeNextWorkTime > 0){
            allResult['otBeforeNextWorkTime'] = otBeforeNextWorkTime;
            if(isWorkingNextDay){
              xOT += otBeforeNextWorkTime;
            }else{
              xOTHoliday += otBeforeNextWorkTime;
            }
          }

          let endBreakNextDate = findNextDate.start;
          if(valuesNext.pattern.breakTime !== 1){
            let startBreakNextDate = dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || (valuesNext.pattern.period === 3 ? "00:00:00" : "12:00:00")}`
            );
            if(!startBreakNextDate.isBetween(findNextDate.start, findNextDate.end, "minute", "()")){
              if(valuesNext.pattern.period === 3){
                startBreakNextDate = dayjs(
                  `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 00:00:00`
                ).add(1, "day");
              }else{
                startBreakNextDate = dayjs(
                  `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00:00`
                );
              }
            }
            endBreakNextDate = startBreakNextDate.add((valuesNext.pattern.breakTimeMin || 60), "minute");
            const otBeforeNextBreakTime = dayjs(
              endDate.isBefore(startBreakNextDate) ? endDate : startBreakNextDate
            ).diff(
              startDate.isAfter(findNextDate.start) ? startDate : findNextDate.start,
              "minute"
            );
            if(otBeforeNextBreakTime > 0){
              allResult['otBeforeNextBreakTime'] = otBeforeNextBreakTime;
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if((valuesNext.holiday || valuesNext.compensateDate) && valuesNext.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeNextBreakTime;
                }else{
                  xWorkingDailyHoliday += otBeforeNextBreakTime;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeNextBreakTime;
              }
            }

            let otBetweenNextBreakTime = dayjs(
              endDate.isBefore(endBreakNextDate) ? endDate : endBreakNextDate
            ).diff(
              startDate.isAfter(startBreakNextDate) ? startDate : startBreakNextDate,
              "minute"
            );
            if (otBetweenNextBreakTime > 0) {
              allResult['otBetweenNextBreakTime'] = otBetweenNextBreakTime;
              if (reasonIsBreak) {
                if (isWorkingNextDay) {     
                  xOT += otBetweenNextBreakTime;
                } else {
                  xOTHoliday += otBetweenNextBreakTime;
                }
              }
            }
          }

          const otBeforeNextEndWorkTime = dayjs(
            endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
          ).diff(
            startDate.isAfter(endBreakNextDate) ? startDate : endBreakNextDate,
            "minute"
          );
          if(otBeforeNextEndWorkTime > 0){
            allResult['otBeforeNextEndWorkTime'] = otBeforeNextEndWorkTime;
            if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
              if((valuesNext.holiday || valuesNext.compensateDate) && valuesNext.pattern.isWorkingDay === 1){
                xWorkingDaily += otBeforeNextEndWorkTime;
              }else{
                xWorkingDailyHoliday += otBeforeNextEndWorkTime;
              }
            } else {
              xWorkingMonthlyHoliday += otBeforeNextEndWorkTime;
            }
          }

          const otAfterNextWorkTime = dayjs(
            endDate
          ).diff(
            startDate.isAfter(findNextDate.end) ? startDate : findNextDate.end,
            "minute"
          );
          if(otAfterNextWorkTime > 0){
            allResult['otAfterNextWorkTime'] = otAfterNextWorkTime;
            if(isWorkingNextDay){
              xOT += otAfterNextWorkTime;
            }else{
              xOTHoliday += otAfterNextWorkTime;
            }
          }
          // console.log("allResult : ",allResult);
          // if (startDate.isBefore(findDate.start)) {
          //   let findDatePrevPattern = {
          //     start: dayjs(findPrevDate.start).add(1, "day"),
          //     end: dayjs(findPrevDate.end).add(1, "day"),
          //   };

          //   if (
          //     dayjs(findDatePrevPattern.start).isBefore(
          //       findDate.start,
          //       "minute"
          //     )
          //   ) {
          //     let otBeforeFindDatePrevPattern = dayjs(
          //       endDate.isBefore(findDatePrevPattern.start)
          //         ? endDate
          //         : findDatePrevPattern.start
          //     ).diff(
          //       startDate.isAfter(findPrevDate.end)
          //         ? startDate
          //         : findPrevDate.end,
          //       "minute"
          //     );
          //     if (otBeforeFindDatePrevPattern > 0) {
          //       xOTHoliday += otBeforeFindDatePrevPattern;
          //     }

          //     let otBeforeFindDatePrevPatternEnd = dayjs(
          //       endDate.isBefore(findDatePrevPattern.end)
          //         ? endDate
          //         : findDatePrevPattern.end
          //     ).diff(
          //       startDate.isAfter(findDatePrevPattern.start)
          //         ? startDate
          //         : findDatePrevPattern.start,
          //       "minute"
          //     );
          //     if (otBeforeFindDatePrevPatternEnd > 0) {
          //       xWorkingMonthlyHoliday += otBeforeFindDatePrevPatternEnd;
          //     }

          //     let findDateStartNewDate = dayjs(
          //       `${dayjs(values.date).format("YYYY-MM-DD")} 07:30`,
          //       "YYYY-MM-DD HH:mm"
          //     );
          //     if (findDateStartNewDate.isBefore(findDate.start)) {
          //       let otBeforeStartNewDate = dayjs(
          //         endDate.isBefore(findDateStartNewDate)
          //           ? endDate
          //           : findDateStartNewDate
          //       ).diff(
          //         startDate.isAfter(findDatePrevPattern.end)
          //           ? startDate
          //           : findDatePrevPattern.end,
          //         "minute"
          //       );
          //       if (otBeforeStartNewDate > 0) {
          //         if (isWorkingPrevDay) {
          //           xOT += otBeforeStartNewDate;
          //         } else {
          //           xOTHoliday += otBeforeStartNewDate;
          //         }
          //       }

          //       let otBeforeWorkTime = dayjs(
          //         endDate.isBefore(findDate.start) ? endDate : findDate.start
          //       ).diff(
          //         startDate.isAfter(findDateStartNewDate)
          //           ? startDate
          //           : findDateStartNewDate,
          //         "minute"
          //       );
          //       if (otBeforeWorkTime > 0) {
          //         if (!isWorkingDay) {
          //           xOTHoliday += otBeforeWorkTime;
          //         } else {
          //           xOT += otBeforeWorkTime;
          //         }
          //       }
          //     } else {
          //       let otBeforeWorkTime = dayjs(
          //         endDate.isBefore(findDate.start) ? endDate : findDate.start
          //       ).diff(
          //         startDate.isAfter(findDatePrevPattern.end)
          //           ? startDate
          //           : findDatePrevPattern.end,
          //         "minute"
          //       );
          //       if (otBeforeWorkTime > 0) {
          //         xOTHoliday += otBeforeWorkTime;
          //       }
          //     }
          //   } else {
          //     let otBeforeFindDateEndPrevDate = dayjs(
          //       endDate.isBefore(findPrevDate.end) ? endDate : findPrevDate.end
          //     ).diff(
          //       startDate.isAfter(findPrevDate.start)
          //         ? startDate
          //         : findPrevDate.start,
          //       "minute"
          //     );
          //     if (otBeforeFindDateEndPrevDate > 0) {
          //       xWorkingMonthlyHoliday += otBeforeFindDateEndPrevDate;
          //     }

          //     let findDateStartNewDate = dayjs(
          //       `${dayjs(values.date).format("YYYY-MM-DD")} 07:30`,
          //       "YYYY-MM-DD HH:mm"
          //     );
          //     if (findDateStartNewDate.isBefore(findDate.start)) {
          //       let otBeforeStartNewDate = dayjs(
          //         endDate.isBefore(findDateStartNewDate)
          //           ? endDate
          //           : findDateStartNewDate
          //       ).diff(
          //         startDate.isAfter(findPrevDate.end)
          //           ? startDate
          //           : findPrevDate.end,
          //         "minute"
          //       );
          //       if (otBeforeStartNewDate > 0) {
          //         if (isWorkingPrevDay) {
          //           xOT += otBeforeStartNewDate;
          //         } else {
          //           xOTHoliday += otBeforeStartNewDate;
          //         }
          //       }

          //       let otBeforeWorkTime = dayjs(
          //         endDate.isBefore(findDate.start) ? endDate : findDate.start
          //       ).diff(
          //         startDate.isAfter(findDateStartNewDate)
          //           ? startDate
          //           : findDateStartNewDate,
          //         "minute"
          //       );
          //       if (otBeforeWorkTime > 0) {
          //         if (!isWorkingDay) {
          //           xOTHoliday += otBeforeWorkTime;
          //         } else {
          //           xOT += otBeforeWorkTime;
          //         }
          //       }
          //     } else {
          //       let otBeforeWorkTime = dayjs(
          //         endDate.isBefore(findDate.start) ? endDate : findDate.start
          //       ).diff(
          //         startDate.isAfter(findPrevDate.end)
          //           ? startDate
          //           : findPrevDate.end,
          //         "minute"
          //       );
          //       if (otBeforeWorkTime > 0) {
          //         if (
          //           isWorkingPrevDay &&
          //           (isWorkingDay ||
          //             dayjs(findPrevDate.end).isBefore(
          //               dayjs(
          //                 `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
          //                 "YYYY-MM-DD HH:mm"
          //               )
          //             ))
          //         ) {
          //           xOT += otBeforeWorkTime;
          //         } else {
          //           xOTHoliday += otBeforeWorkTime;
          //         }
          //       }
          //     }
          //   }
          // }

          // let otBeforeWorkEndTime = dayjs(
          //   endDate.isBefore(findDate.end) ? endDate : findDate.end
          // ).diff(
          //   startDate.isAfter(findDate.start) ? startDate : findDate.start,
          //   "minute"
          // );
          // if (otBeforeWorkEndTime > 0) {
          //   xWorkingMonthlyHoliday += otBeforeWorkEndTime;
          // }
          
          // if (endDate.isAfter(findDate.end)) {
          //   let findDatePatternAddDay = {
          //     start: dayjs(findDate.start).add(1, "day"),
          //     end: dayjs(findDate.end).add(1, "day"),
          //   };
          //   if (
          //     dayjs(findDatePatternAddDay.start).isBefore(
          //       findNextDate.start,
          //       "minute"
          //     )
          //   ) {
          //     let findNextDateStartNewDate = dayjs(
          //       `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30`,
          //       "YYYY-MM-DD HH:mm"
          //     );
          //     if (
          //       findNextDateStartNewDate.isBefore(findDatePatternAddDay.start)
          //     ) {
          //       let otBeforeStartNextNewDate = dayjs(
          //         endDate.isBefore(findNextDateStartNewDate)
          //           ? endDate
          //           : findNextDateStartNewDate
          //       ).diff(
          //         startDate.isAfter(findDate.end) ? startDate : findDate.end,
          //         "minute"
          //       );
          //       if (otBeforeStartNextNewDate > 0) {
          //         if (isWorkingDay) {
          //           xOT += otBeforeStartNextNewDate;
          //         } else {
          //           xOTHoliday += otBeforeStartNextNewDate;
          //         }
          //       }

          //       let otBeforeFindDatePatternAddDayStart = dayjs(
          //         endDate.isBefore(findDatePatternAddDay.start)
          //           ? endDate
          //           : findDatePatternAddDay.start
          //       ).diff(
          //         startDate.isAfter(findNextDateStartNewDate)
          //           ? startDate
          //           : findNextDateStartNewDate,
          //         "minute"
          //       );
          //       if (otBeforeFindDatePatternAddDayStart > 0) {
          //         if (!isWorkingNextDay) {
          //           xOTHoliday += otBeforeFindDatePatternAddDayStart;
          //         } else {
          //           xOT += otBeforeFindDatePatternAddDayStart;
          //         }
          //       }
          //     } else {
          //       let otBeforeFindDatePatternAddDayStart = dayjs(
          //         endDate.isBefore(findDatePatternAddDay.start)
          //           ? endDate
          //           : findDatePatternAddDay.start
          //       ).diff(
          //         startDate.isAfter(findDate.end) ? startDate : findDate.end,
          //         "minute"
          //       );
          //       if (otBeforeFindDatePatternAddDayStart > 0) {
          //         xOTHoliday += otBeforeFindDatePatternAddDayStart;
          //       }
          //     }

          //     let otBeforeFindDatePatternAddDayEnd = dayjs(
          //       endDate.isBefore(findDatePatternAddDay.end)
          //         ? endDate
          //         : findDatePatternAddDay.end
          //     ).diff(
          //       startDate.isAfter(findDatePatternAddDay.start)
          //         ? startDate
          //         : findDatePatternAddDay.start,
          //       "minute"
          //     );
          //     if (otBeforeFindDatePatternAddDayEnd > 0) {
          //       xWorkingMonthlyHoliday += otBeforeFindDatePatternAddDayEnd;
          //     }

          //     let otBeforeNextWorkTime = dayjs(
          //       endDate.isBefore(findNextDate.start)
          //         ? endDate
          //         : findNextDate.start
          //     ).diff(
          //       startDate.isAfter(findDatePatternAddDay.end)
          //         ? startDate
          //         : findDatePatternAddDay.end,
          //       "minute"
          //     );
          //     console.log("otBeforeNextWorkTime : ",otBeforeNextWorkTime);
          //     if (otBeforeNextWorkTime > 0) {
          //       xOTHoliday += otBeforeNextWorkTime;
          //     }
          //   } else {
          //     let findNextDateStartNewDate = dayjs(
          //       `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30`,
          //       "YYYY-MM-DD HH:mm"
          //     );
          //     if (findNextDateStartNewDate.isBefore(findNextDate.start)) {
          //       let otBeforeStartNextNewDate = dayjs(
          //         endDate.isBefore(findNextDateStartNewDate)
          //           ? endDate
          //           : findNextDateStartNewDate
          //       ).diff(
          //         startDate.isAfter(findDate.end) ? startDate : findDate.end,
          //         "minute"
          //       );
          //       if (otBeforeStartNextNewDate > 0) {
          //         if (isWorkingDay) {
          //           xOT += otBeforeStartNextNewDate;
          //         } else {
          //           xOTHoliday += otBeforeStartNextNewDate;
          //         }
          //       }

          //       let otBeforeNextWorkTime = dayjs(
          //         endDate.isBefore(findNextDate.start)
          //           ? endDate
          //           : findNextDate.start
          //       ).diff(
          //         startDate.isAfter(findNextDateStartNewDate)
          //           ? startDate
          //           : findNextDateStartNewDate,
          //         "minute"
          //       );
          //       if (otBeforeNextWorkTime > 0) {
          //         if (!isWorkingNextDay) {
          //           xOTHoliday += otBeforeNextWorkTime;
          //         } else {
          //           xOT += otBeforeNextWorkTime;
          //         }
          //       }
          //     } else {
          //       let otBeforeNextWorkTime = dayjs(
          //         endDate.isBefore(findNextDate.start)
          //           ? endDate
          //           : findNextDate.start
          //       ).diff(
          //         startDate.isAfter(findDate.end) ? startDate : findDate.end,
          //         "minute"
          //       );
          //       if (otBeforeNextWorkTime > 0) {
          //         if (
          //           isWorkingDay &&
          //           (isWorkingNextDay ||
          //             dayjs(findDate.end).isBefore(
          //               dayjs(
          //                 `${dayjs(valuesNext.date).format(
          //                   "YYYY-MM-DD"
          //                 )} 00:00`,
          //                 "YYYY-MM-DD HH:mm"
          //               )
          //             ))
          //         ) {
          //           xOT += otBeforeNextWorkTime;
          //         } else {
          //           xOTHoliday += otBeforeNextWorkTime;
          //         }
          //       }
          //     }
          //   }
          // }

          // let otBeforeNextWorkEndTime = dayjs(
          //   endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
          // ).diff(
          //   startDate.isAfter(findNextDate.start)
          //     ? startDate
          //     : findNextDate.start,
          //   "minute"
          // );
          // if (otBeforeNextWorkEndTime > 0) {
          //   xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
          // }

          // let otAfterNextWorkEndTime = dayjs(endDate).diff(
          //   startDate.isAfter(findNextDate.end) ? startDate : findNextDate.end,
          //   "minute"
          // );
          // if (otAfterNextWorkEndTime > 0) {
          //   if (isWorkingNextDay) {
          //     xOT += otAfterNextWorkEndTime;
          //   } else {
          //     xOTHoliday += otAfterNextWorkEndTime;
          //   }
          // }
        }
      }
    }

    return {
      error: error,
      listOT: {
        xOT: xOT,
        xOTHoliday: xOTHoliday,
        xWorkingDailyHoliday: xWorkingDailyHoliday,
        xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
        xWorkingDaily: xWorkingDaily
      },
    };
};

const timeOverlap = (starttime, endtime, startCheck, endCheck) => {
    let isStartCheckOverlap = dayjs(startCheck).isBetween(starttime, endtime, "[)");
    let isEndCheckOverlap = dayjs(endCheck).isBetween(starttime, endtime, "(]")
    if(isStartCheckOverlap && isEndCheckOverlap){
        return dayjs(endCheck).diff(startCheck, "minute");
    } else if (isStartCheckOverlap){
        return dayjs(endtime).diff(startCheck, "minute");
    } else if (isEndCheckOverlap) {
        return dayjs(endCheck).diff(starttime, "minute");
    } else {
        return 0;
    }
};

const calculatorOTMidnight = (values, formInputData, employeeProfile, attendanceListState, requestReason) => {
  let error = {
    errorNoon: false,
    errorOnWorkingTime: false,
    errorTime: false,
  };
  let allResult = {};
  let xOT = 0;
  let xOTHoliday = 0;
  let xWorkingDailyHoliday = 0;
  let xWorkingMonthlyHoliday = 0;
  let xWorkingDaily = 0;
  const startDate = dayjs(formInputData.startDate)
    .set("hour", formInputData.startHours)
    .set("minute", formInputData.startMinute)
    .set("second", 0);
  const endDate = dayjs(formInputData.endDate)
    .set("hour", formInputData.endHours)
    .set("minute", formInputData.endMinute)
    .set("second", 0);

  const findDate = {
    start: dayjs(`${values.date} ${values.pattern.timeIn}`),
  };
  findDate.end = findDate.start.add(values.pattern.workingHours, "minute")

  const findIndexToday = attendanceListState.findIndex(
    (x) => x.date === values.date
  );

  let leaveHoliday = (
    values.leave && 
    values.leave.length > 0 && 
    values.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
  );
  const isWorkingDay =
    values.pattern.isWorkingDay === 1 &&
    !leaveHoliday &&
    !values.holiday &&
    !values.compensateDate;
  
  let valuesPrev = attendanceListState[findIndexToday - 1];
  let findPrevDate = {
    start: dayjs(`${valuesPrev.date} ${valuesPrev.pattern.timeIn}`),
  };
  findPrevDate.end = findPrevDate.start.add(valuesPrev.pattern.workingHours, "minute");
  let prevLeaveHoliday = (
    valuesPrev.leave && 
    valuesPrev.leave.length > 0 && 
    valuesPrev.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
  );
  let isWorkingPrevDay =
    valuesPrev.pattern.isWorkingDay === 1 &&
    !prevLeaveHoliday &&
    !valuesPrev.holiday &&
    !valuesPrev.compensateDate;

  let valuesNext = attendanceListState[findIndexToday + 1];
  let findNextDate = {
    start: dayjs(`${valuesNext.date} ${valuesNext.pattern.timeIn}`)
  };
  findNextDate.end = findNextDate.start.add(valuesNext.pattern.workingHours, "minute")
  let nextLeaveHoliday = (
    valuesNext.leave && 
    valuesNext.leave.length > 0 && 
    valuesNext.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
  );
  let isWorkingNextDay =
    valuesNext.pattern.isWorkingDay === 1 &&
    !nextLeaveHoliday && 
    !valuesNext.holiday &&
    !valuesNext.compensateDate;
  
  let reasonIsBreak = (requestReason && requestReason.isBreak === 1) ? true : false;
  
  if (formInputData && formInputData.idRequestType === 2) {
    if (
      startDate.isBefore(
        dayjs(
          `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
          "YYYY-MM-DD HH:mm"
        )
      ) ||
      endDate.isSameOrAfter(
        dayjs(
          `${dayjs(values.date).add(2, "day").format("YYYY-MM-DD")} 00:00`,
          "YYYY-MM-DD HH:mm"
        )
      )
    ) {
      error.errorTime = true;
    }

    if (isWorkingPrevDay && startDate.isBefore(findPrevDate.end)) {
      error.errorOnWorkingTime = true;
    }

    if (isWorkingNextDay && endDate.isAfter(findNextDate.start)) {
      error.errorOnWorkingTime = true;
    }

    if (!(endDate.diff(startDate, "minute") > 0)) {
      error.errorTime = true;
    }

    if (values.pattern.idWorkingType === 2) {
      if (
        !(
          startDate.isBetween(
            dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
            ),
            dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
            ).add((values.pattern.breakTimeMin || 60), "minute"),
            "minute",
            "[)"
          ) &&
          endDate.isBetween(
            dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
            ),
            dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
            ).add((values.pattern.breakTimeMin || 60), "minute"),
            "minute",
            "(]"
          )
        ) &&
        reasonIsBreak
      ) {
        error.errorNoon = true;
      } else if (
        (dayjs(startDate).isBetween(
          dayjs(findDate.start),
          dayjs(findDate.end),
          "minute",
          "[)"
        ) ||
          dayjs(endDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "(]"
          ) ||
          dayjs(findDate.start).isBetween(
            dayjs(startDate),
            dayjs(endDate),
            "minute",
            "[)"
          ) ||
          dayjs(findDate.end).isBetween(
            dayjs(startDate),
            dayjs(endDate),
            "minute",
            "(]"
          )) &&
        isWorkingDay &&
        !reasonIsBreak
      ) {
        error.errorOnWorkingTime = true;
      }

      if (
        startDate.isBetween(
          dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
          ),
          dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
          ).add((valuesNext.pattern.breakTimeMin || 60), "minute"),
          "minute",
          "[)"
        ) &&
        endDate.isBetween(
          dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
          ),
          dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
          ).add((valuesNext.pattern.breakTimeMin || 60), "minute"),
          "minute",
          "(]"
        ) &&
        !reasonIsBreak
      ) {
        error.errorNoon = true;
      }
    } else {
      if (
        (dayjs(startDate).isBetween(
          dayjs(findDate.start),
          dayjs(findDate.end),
          "minute",
          "[)"
        ) ||
          dayjs(endDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "(]"
          ) ||
          dayjs(findDate.start).isBetween(
            dayjs(startDate),
            dayjs(endDate),
            "minute",
            "[)"
          ) ||
          dayjs(findDate.end).isBetween(
            dayjs(startDate),
            dayjs(endDate),
            "minute",
            "(]"
          )) &&
        isWorkingDay
      ) {
        error.errorOnWorkingTime = true;
      }
    }

    if (!(error.errorNoon || error.errorOnWorkingTime || error.errorTime)) {
      if (values.pattern.idWorkingType === 2) {
        let breaktime = {
          start: dayjs(`${values.date} ${values.pattern.startBreak || (values.pattern.period === 3 ? "00:00:00" : "12:00:00")}`),
          end: findDate.start
        };
        if(values.pattern.period === 3 && !breaktime.start.isBetween(findDate.start, findDate.end, "minute", "[]")){
          breaktime.start = breaktime.start.add(1, 'day');
        }
        if(values.pattern.breakTime !== 1){
          breaktime.end = breaktime.start.add((values.pattern.breakTimeMin || 60), "minute");
        }

        let breaktimeNextDate = {
          start: dayjs(
            `${valuesNext.date} ${valuesNext.pattern.startBreak || (valuesNext.pattern.period === 3 ? "00:00:00" : "12:00:00")}`
          ),
          end: findNextDate.start
        };
        if(valuesNext.pattern.period === 3 && !breaktimeNextDate.start.isBetween(findNextDate.start, findNextDate.end, "minute", "[]")){
          breaktimeNextDate.start = breaktimeNextDate.start.add(1, 'day');
        }
        if(values.pattern.breakTime !== 1){
          breaktimeNextDate.end = breaktimeNextDate.start.add((valuesNext.pattern.breakTimeMin || 60), "minute");
        }
        
        let startNextDay = dayjs(`${values.date} 00:00:00`).add(1, 'day');

        let otBeforeWorkingDay = dayjs(
          endDate.isBefore(findDate.start) ? endDate : findDate.start
        ).diff(
          startDate,
          "minute"
        );
        if(otBeforeWorkingDay > 0){
          allResult['otBeforeWorkingDay'] = otBeforeWorkingDay;
          if (isWorkingDay) {
            xOT += otBeforeWorkingDay;
          } else {
            xOTHoliday += otBeforeWorkingDay;
          }
        }

        if(values.pattern.breakTime !== 1){
          let otBeforeBreakTime = dayjs(
            endDate.isBefore(breaktime.start) ? endDate : breaktime.start
          ).diff(
            startDate.isAfter(findDate.start)
              ? startDate
              : findDate.start,
            "minute"
          );
          if(otBeforeBreakTime > 0){
            allResult['otBeforeBreakTime'] = otBeforeBreakTime;
            if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
              if(values.holiday && values.pattern.isWorkingDay === 1){
                xWorkingDaily += otBeforeBreakTime;
              }else{
                xWorkingDailyHoliday += otBeforeBreakTime;
              }
            } else {
              xWorkingMonthlyHoliday += otBeforeBreakTime;
            }
          }

          let otBreakTime = dayjs(
            endDate.isBefore(breaktime.end) ? endDate : breaktime.end
          ).diff(
            startDate.isAfter(breaktime.start)
              ? startDate
              : breaktime.start,
            "minute"
          );
          if(reasonIsBreak){
            if(otBreakTime > 0){
              allResult['otBreakTime'] = otBreakTime;
              if (isWorkingDay) {
                xOT += otBreakTime;
              } else {
                xOTHoliday += otBreakTime;
              }
            }
          }
        }

        let otBeforeEndWorkingDay = dayjs(
          endDate.isBefore(findDate.end) ? endDate : findDate.end
        ).diff(
          startDate.isAfter(breaktime.end)
            ? startDate
            : breaktime.end,
          "minute"
        );
        if(otBeforeEndWorkingDay > 0){
          allResult['otBeforeEndWorkingDay'] = otBeforeEndWorkingDay;
          if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
            if(values.holiday && values.pattern.isWorkingDay === 1){
              xWorkingDaily += otBeforeEndWorkingDay;
            }else{
              xWorkingDailyHoliday += otBeforeEndWorkingDay;
            }
          } else {
            xWorkingMonthlyHoliday += otBeforeEndWorkingDay;
          }
        }

        let otBeforeNextWorkingDay = dayjs(
          endDate.isBefore(findNextDate.start) ? endDate : findNextDate.start
        ).diff(
          startDate.isAfter(findDate.end)
            ? startDate
            : findDate.end,
          "minute"
        );
        if(otBeforeNextWorkingDay > 0){
          allResult['otBeforeNextWorkingDay'] = otBeforeNextWorkingDay;
          let endTemp = endDate.isBefore(findNextDate.start)? endDate: findNextDate.start;
          let startTemp = startDate.isAfter(findDate.end)? startDate: findDate.end;
          if(startNextDay.isSameOrAfter(endTemp, "minute")){
            if(isWorkingDay){
              xOT += otBeforeNextWorkingDay
            } else {
              xOTHoliday += otBeforeNextWorkingDay
            }
          }else if(startNextDay.isSameOrBefore(startTemp, "minute")){
            if(isWorkingNextDay){
              xOT += otBeforeNextWorkingDay
            } else {
              xOTHoliday += otBeforeNextWorkingDay
            }
          }else if(startNextDay.isBetween(startTemp, endTemp, "minute", "()")){
            let firstHalf = startNextDay.diff(startTemp, "minute");
            let secondHalf = endTemp.diff(startNextDay, "minute");
            if(isWorkingDay){
              xOT += firstHalf
            } else {
              xOTHoliday += firstHalf
            }
            if(isWorkingNextDay){
              xOT += secondHalf
            } else {
              xOTHoliday += secondHalf
            }
          }
        }

        if(valuesNext.pattern.breakTime !== 1){
          let otBeforeNextBreakTime = dayjs(
            endDate.isBefore(breaktimeNextDate.start) ? endDate : breaktimeNextDate.start
          ).diff(
            startDate.isAfter(findNextDate.start)
              ? startDate
              : findNextDate.start,
            "minute"
          );
          if(otBeforeNextBreakTime > 0){
            allResult['otBeforeNextBreakTime'] = otBeforeNextBreakTime;
            if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
              if(valuesNext.holiday && valuesNext.pattern.isWorkingDay === 1){
                xWorkingDaily += otBeforeNextBreakTime;
              }else{
                xWorkingDailyHoliday += otBeforeNextBreakTime;
              }
            } else {
              xWorkingMonthlyHoliday += otBeforeNextBreakTime;
            }
          }

          let otNextBreakTime = dayjs(
            endDate.isBefore(breaktimeNextDate.end) ? endDate : breaktimeNextDate.end
          ).diff(
            startDate.isAfter(breaktimeNextDate.start)
              ? startDate
              : breaktimeNextDate.start,
            "minute"
          );
          if(reasonIsBreak){
            if(otNextBreakTime > 0){
              allResult['otNextBreakTime'] = otNextBreakTime;
              if (isWorkingNextDay) {
                xOT += otNextBreakTime;
              } else {
                xOTHoliday += otNextBreakTime;
              }
            }
          }
        }

        let otBeforeNextEndWorkingDay = dayjs(
          endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
        ).diff(
          startDate.isAfter(breaktimeNextDate.end)
            ? startDate
            : breaktimeNextDate.end,
          "minute"
        );
        if(otBeforeNextEndWorkingDay > 0){
          allResult['otBeforeNextEndWorkingDay'] = otBeforeNextEndWorkingDay;
          if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
            if(valuesNext.holiday && valuesNext.pattern.isWorkingDay === 1){
              xWorkingDaily += otBeforeNextEndWorkingDay;
            }else{
              xWorkingDailyHoliday += otBeforeNextEndWorkingDay;
            }
          } else {
            xWorkingMonthlyHoliday += otBeforeNextEndWorkingDay;
          }
        }

        let otAfterNextEndWorkingDay = dayjs(
          endDate
        ).diff(
          startDate.isAfter(findNextDate.end)
            ? startDate
            : findNextDate.end,
          "minute"
        );
        if(otAfterNextEndWorkingDay > 0){
          allResult['otAfterNextEndWorkingDay'] = otAfterNextEndWorkingDay;
          if(isWorkingNextDay){
            xOT += otAfterNextEndWorkingDay
          } else {
            xOTHoliday += otAfterNextEndWorkingDay
          }
        }
      } else if (values.pattern.idWorkingType === 1) {
        let breaktime = {
          start: dayjs(`${values.date} ${values.pattern.startBreak || (values.pattern.period === 3 ? "00:00:00" : "12:00:00")}`),
          end: findDate.start
        };
        if(values.pattern.period === 3 && !breaktime.start.isBetween(findDate.start, findDate.end, "minute", "[]")){
          breaktime.start = breaktime.start.add(1, 'day');
        }
        if(values.pattern.breakTime !== 1){
          breaktime.end = breaktime.start.add((values.pattern.breakTimeMin || 60), "minute");
        }

        let breaktimeNextDate = {
          start: dayjs(
            `${valuesNext.date} ${valuesNext.pattern.startBreak || (valuesNext.pattern.period === 3 ? "00:00:00" : "12:00:00")}`
          ),
          end: findNextDate.start
        };
        if(valuesNext.pattern.period === 3 && !breaktimeNextDate.start.isBetween(findNextDate.start, findNextDate.end, "minute", "[]")){
          breaktimeNextDate.start = breaktimeNextDate.start.add(1, 'day');
        }
        if(values.pattern.breakTime !== 1){
          breaktimeNextDate.end = breaktimeNextDate.start.add((valuesNext.pattern.breakTimeMin || 60), "minute");
        }

        let startNextDay = dayjs(`${values.date} 00:00:00`).add(1, 'day');

        let otBeforeWorkingDay = dayjs(
          endDate.isBefore(findDate.start) ? endDate : findDate.start
        ).diff(
          startDate,
          "minute"
        );
        if(otBeforeWorkingDay > 0){
          allResult['otBeforeWorkingDay'] = otBeforeWorkingDay;
          if (isWorkingDay) {
            xOT += otBeforeWorkingDay;
          } else {
            xOTHoliday += otBeforeWorkingDay;
          }
        }

        if(values.pattern.breakTime !== 1){
          let otBeforeBreakTime = dayjs(
            endDate.isBefore(breaktime.start) ? endDate : breaktime.start
          ).diff(
            startDate.isAfter(findDate.start)
              ? startDate
              : findDate.start,
            "minute"
          );
          if(otBeforeBreakTime > 0){
            allResult['otBeforeBreakTime'] = otBeforeBreakTime;
            if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
              if(values.holiday && values.pattern.isWorkingDay === 1){
                xWorkingDaily += otBeforeBreakTime;
              }else{
                xWorkingDailyHoliday += otBeforeBreakTime;
              }
            } else {
              xWorkingMonthlyHoliday += otBeforeBreakTime;
            }
          }

          let otBreakTime = dayjs(
            endDate.isBefore(breaktime.end) ? endDate : breaktime.end
          ).diff(
            startDate.isAfter(breaktime.start)
              ? startDate
              : breaktime.start,
            "minute"
          );
          if(reasonIsBreak){
            if(otBreakTime > 0){
              allResult['otBreakTime'] = otBreakTime;
              if (isWorkingDay) {
                xOT += otBreakTime;
              } else {
                xOTHoliday += otBreakTime;
              }
            }
          }
        }

        let otBeforeEndWorkingDay = dayjs(
          endDate.isBefore(findDate.end) ? endDate : findDate.end
        ).diff(
          startDate.isAfter(breaktime.end)
            ? startDate
            : breaktime.end,
          "minute"
        );
        if(otBeforeEndWorkingDay > 0){
          allResult['otBeforeEndWorkingDay'] = otBeforeEndWorkingDay;
          if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
            if(values.holiday && values.pattern.isWorkingDay === 1){
              xWorkingDaily += otBeforeEndWorkingDay;
            }else{
              xWorkingDailyHoliday += otBeforeEndWorkingDay;
            }
          } else {
            xWorkingMonthlyHoliday += otBeforeEndWorkingDay;
          }
        }

        let otBeforeNextWorkingDay = dayjs(
          endDate.isBefore(findNextDate.start) ? endDate : findNextDate.start
        ).diff(
          startDate.isAfter(findDate.end)
            ? startDate
            : findDate.end,
          "minute"
        );
        if(otBeforeNextWorkingDay > 0){
          allResult['otBeforeNextWorkingDay'] = otBeforeNextWorkingDay;
          let endTemp = endDate.isBefore(findNextDate.start)? endDate: findNextDate.start;
          let startTemp = startDate.isAfter(findDate.end)? startDate: findDate.end;
          if(startNextDay.isSameOrAfter(endTemp, "minute")){
            if(isWorkingDay){
              xOT += otBeforeNextWorkingDay
            } else {
              xOTHoliday += otBeforeNextWorkingDay
            }
          }else if(startNextDay.isSameOrBefore(startTemp, "minute")){
            if(isWorkingNextDay){
              xOT += otBeforeNextWorkingDay
            } else {
              xOTHoliday += otBeforeNextWorkingDay
            }
          }else if(startNextDay.isBetween(startTemp, endTemp, "minute", "()")){
            let firstHalf = startNextDay.diff(startTemp, "minute");
            let secondHalf = endTemp.diff(startNextDay, "minute");
            if(isWorkingDay){
              xOT += firstHalf
            } else {
              xOTHoliday += firstHalf
            }
            if(isWorkingNextDay){
              xOT += secondHalf
            } else {
              xOTHoliday += secondHalf
            }
          }
        }

        if(valuesNext.pattern.breakTime !== 1){
          let otBeforeNextBreakTime = dayjs(
            endDate.isBefore(breaktimeNextDate.start) ? endDate : breaktimeNextDate.start
          ).diff(
            startDate.isAfter(findNextDate.start)
              ? startDate
              : findNextDate.start,
            "minute"
          );
          if(otBeforeNextBreakTime > 0){
            allResult['otBeforeNextBreakTime'] = otBeforeNextBreakTime;
            if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
              if(valuesNext.holiday && valuesNext.pattern.isWorkingDay === 1){
                xWorkingDaily += otBeforeNextBreakTime;
              }else{
                xWorkingDailyHoliday += otBeforeNextBreakTime;
              }
            } else {
              xWorkingMonthlyHoliday += otBeforeNextBreakTime;
            }
          }

          let otNextBreakTime = dayjs(
            endDate.isBefore(breaktimeNextDate.end) ? endDate : breaktimeNextDate.end
          ).diff(
            startDate.isAfter(breaktimeNextDate.start)
              ? startDate
              : breaktimeNextDate.start,
            "minute"
          );
          if(reasonIsBreak){
            if(otNextBreakTime > 0){
              allResult['otNextBreakTime'] = otNextBreakTime;
              if (isWorkingNextDay) {
                xOT += otNextBreakTime;
              } else {
                xOTHoliday += otNextBreakTime;
              }
            }
          }
        }

        let otBeforeNextEndWorkingDay = dayjs(
          endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
        ).diff(
          startDate.isAfter(breaktimeNextDate.end)
            ? startDate
            : breaktimeNextDate.end,
          "minute"
        );
        if(otBeforeNextEndWorkingDay > 0){
          allResult['otBeforeNextEndWorkingDay'] = otBeforeNextEndWorkingDay;
          if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
            if(valuesNext.holiday && valuesNext.pattern.isWorkingDay === 1){
              xWorkingDaily += otBeforeNextEndWorkingDay;
            }else{
              xWorkingDailyHoliday += otBeforeNextEndWorkingDay;
            }
          } else {
            xWorkingMonthlyHoliday += otBeforeNextEndWorkingDay;
          }
        }

        let otAfterNextEndWorkingDay = dayjs(
          endDate
        ).diff(
          startDate.isAfter(findNextDate.end)
            ? startDate
            : findNextDate.end,
          "minute"
        );
        if(otAfterNextEndWorkingDay > 0){
          allResult['otAfterNextEndWorkingDay'] = otAfterNextEndWorkingDay;
          if(isWorkingNextDay){
            xOT += otAfterNextEndWorkingDay
          } else {
            xOTHoliday += otAfterNextEndWorkingDay
          }
        }
      }
    }
  }

  return {
    error: error,
    listOT: {
      xOT: xOT,
      xOTHoliday: xOTHoliday,
      xWorkingDailyHoliday: xWorkingDailyHoliday,
      xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
      xWorkingDaily: xWorkingDaily
    },
  };
};

const calculatorOTStandard = (values, formInputData, employeeProfile, attendanceListState) => {
    let error = {
      errorNoon: false,
      errorOnWorkingTime: false,
      errorTime: false,
    };
    let xOT = 0;
    let xOTHoliday = 0;
    let xWorkingDailyHoliday = 0;
    let xWorkingMonthlyHoliday = 0;
    let xWorkingDaily = 0;

    const startDate = dayjs(formInputData.startDate)
      .set("hour", formInputData.startHours)
      .set("minute", formInputData.startMinute)
      .set("second", 0);
    const endDate = dayjs(formInputData.endDate)
      .set("hour", formInputData.endHours)
      .set("minute", formInputData.endMinute)
      .set("second", 0);

    const findDate = {
      start: `${dayjs(values.date).format("YYYY-MM-DD")} ${
        values.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.timeIn}`,
        "YYYY-MM-DD HH:mm"
      )
        .add(values.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };

    const findIndexToday = attendanceListState.findIndex(
      (x) => x.date === values.date
    );

    let leaveHoliday = (
      values.leave && 
      values.leave.length > 0 && 
      values.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
    );
    const isWorkingDay =
      values.pattern.isWorkingDay === 1 &&
      !leaveHoliday &&
      !values.holiday &&
      !values.compensateDate;
    
    let valuesPrev = attendanceListState[findIndexToday - 1];
    let findPrevDate = {
      start: `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
        valuesPrev.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
          valuesPrev.pattern.timeIn
        }`,
        "YYYY-MM-DD HH:mm"
      )
        .add(valuesPrev.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };
    let prevLeaveHoliday = (
      valuesPrev.leave && 
      valuesPrev.leave.length > 0 && 
      valuesPrev.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
    );
    let isWorkingPrevDay =
      valuesPrev.pattern.isWorkingDay === 1 &&
      !prevLeaveHoliday &&
      !valuesPrev.holiday &&
      !valuesPrev.compensateDate;

    let valuesNext = attendanceListState[findIndexToday + 1];
    let findNextDate = {
      start: `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
        valuesNext.pattern.timeIn
      }`,
      end: `${dayjs(
        `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
          valuesNext.pattern.timeIn
        }`,
        "YYYY-MM-DD HH:mm"
      )
        .add(valuesNext.pattern.workingHours, "minute")
        .format("YYYY-MM-DD HH:mm")}`,
    };
    let nextLeaveHoliday = (
      valuesNext.leave && 
      valuesNext.leave.length > 0 && 
      valuesNext.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
    );
    let isWorkingNextDay =
      valuesNext.pattern.isWorkingDay === 1 &&
      !nextLeaveHoliday && 
      !valuesNext.holiday &&
      !valuesNext.compensateDate;

    if (formInputData && formInputData.idRequestType === 2) {
      if (
        startDate.isBefore(
          dayjs(
            `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
            "YYYY-MM-DD HH:mm"
          )
        ) ||
        endDate.isSameOrAfter(
          dayjs(
            `${dayjs(values.date).add(2, "day").format("YYYY-MM-DD")} 00:00`,
            "YYYY-MM-DD HH:mm"
          )
        )
      ) {
        error.errorTime = true;
      }

      if (isWorkingPrevDay && startDate.isBefore(findPrevDate.end)) {
        error.errorOnWorkingTime = true;
      }

      if (isWorkingNextDay && endDate.isAfter(findNextDate.start)) {
        error.errorOnWorkingTime = true;
      }

      if (!(endDate.diff(startDate, "minute") > 0)) {
        error.errorTime = true;
      }

      if (values.pattern.idWorkingType === 2) {
        if (
          !(
            startDate.isBetween(
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
              ),
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
              ).add((values.pattern.breakTimeMin || 60), "minute"),
              "minute",
              "[)"
            ) &&
            endDate.isBetween(
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
              ),
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
              ).add((values.pattern.breakTimeMin || 60), "minute"),
              "minute",
              "(]"
            )
          ) &&
          formInputData.idRequestReason === 9
        ) {
          error.errorNoon = true;
        } else if (
          (dayjs(startDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "[)"
          ) ||
            dayjs(endDate).isBetween(
              dayjs(findDate.start),
              dayjs(findDate.end),
              "minute",
              "(]"
            ) ||
            dayjs(findDate.start).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[)"
            ) ||
            dayjs(findDate.end).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "(]"
            )) &&
          isWorkingDay &&
          formInputData.idRequestReason !== 9
        ) {
          error.errorOnWorkingTime = true;
        }

        if (
          startDate.isBetween(
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
            ),
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
            ).add((valuesNext.pattern.breakTimeMin || 60), "minute"),
            "minute",
            "[)"
          ) &&
          endDate.isBetween(
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
            ),
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
            ).add((valuesNext.pattern.breakTimeMin || 60), "minute"),
            "minute",
            "(]"
          ) &&
          formInputData.idRequestReason !== 9
        ) {
          error.errorNoon = true;
        }
      } else {
        if (
          (dayjs(startDate).isBetween(
            dayjs(findDate.start),
            dayjs(findDate.end),
            "minute",
            "[)"
          ) ||
            dayjs(endDate).isBetween(
              dayjs(findDate.start),
              dayjs(findDate.end),
              "minute",
              "(]"
            ) ||
            dayjs(findDate.start).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "[)"
            ) ||
            dayjs(findDate.end).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              "minute",
              "(]"
            )) &&
          isWorkingDay
        ) {
          error.errorOnWorkingTime = true;
        }
      }

      if (!(error.errorNoon || error.errorOnWorkingTime || error.errorTime)) {
        if (values.pattern.idWorkingType === 2) {
          let breaktime = {
            start: dayjs(`${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`),
            end: dayjs(`${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`)
              .add((values.pattern.breakTimeMin || 60), "minute"),
          };
          let breaktimeNextDate = {
            start: dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
            ),
            end: dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
            ).add((valuesNext.pattern.breakTimeMin || 60), "minute"),
          };

          let otBeforePrevWorkEndTime = dayjs(
            endDate.isBefore(findPrevDate.end) ? endDate : findPrevDate.end
          ).diff(
            startDate.isAfter(findPrevDate.start)
              ? startDate
              : findPrevDate.start,
            "minute"
          );
          if (otBeforePrevWorkEndTime > 0) {
            if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
              if(valuesPrev.holiday && valuesPrev.pattern.isWorkingDay === 1){
                xWorkingDaily += otBeforePrevWorkEndTime;
              }else{
                xWorkingDailyHoliday += otBeforePrevWorkEndTime;
              }
            } else {
              xWorkingMonthlyHoliday += otBeforePrevWorkEndTime;
            }
          }

          if (dayjs(`${values.date} 07:30:00`).isBefore(findDate.start)) {
            let seventhirty = dayjs(`${values.date} 07:30:00`);

            let otBeforeSevenThirty = dayjs(
              endDate.isBefore(seventhirty) ? endDate : seventhirty
            ).diff(
              startDate.isAfter(findPrevDate.end)
                ? startDate
                : findPrevDate.end,
              "minute"
            );
            if (otBeforeSevenThirty > 0) {
              if (isWorkingPrevDay) {
                xOT += otBeforeSevenThirty;
              } else {
                xOTHoliday += otBeforeSevenThirty;
              }
            }

            let otBeforeWorkTime = dayjs(
              endDate.isBefore(findDate.start) ? endDate : findDate.start
            ).diff(
              startDate.isAfter(seventhirty) ? startDate : seventhirty,
              "minute"
            );
            if (otBeforeWorkTime > 0) {

              let endtime = endDate.isBefore(findDate.start) ? endDate : findDate.start;
              let starttime = startDate.isAfter(seventhirty) ? startDate : seventhirty;
              let overlapTime = timeOverlap(starttime, endtime, breaktime.start, breaktime.end);

              if (isWorkingDay) {
                xOT += (otBeforeWorkTime - overlapTime);
              } else {
                xOTHoliday += (otBeforeWorkTime - overlapTime);
              }
            }

            let otBeforeWorkEndTime = dayjs(
              endDate.isBefore(findDate.end) ? endDate : findDate.end
            ).diff(
              startDate.isAfter(findDate.start) ? startDate : findDate.start,
              "minute"
            );
            if (otBeforeWorkEndTime > 0) {
              let endtime = endDate.isBefore(findDate.end) ? endDate : findDate.end;
              let starttime = startDate.isAfter(findDate.start) ? startDate : findDate.start;
              let overlapTime = timeOverlap(starttime, endtime, breaktime.start, breaktime.end);

              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(values.holiday && values.pattern.isWorkingDay === 1){
                  xWorkingDaily += (otBeforeWorkEndTime - overlapTime);
                }else{
                  xWorkingDailyHoliday += (otBeforeWorkEndTime - overlapTime);
                }
              } else {
                xWorkingMonthlyHoliday += (otBeforeWorkEndTime - overlapTime);
              }
            }
          } else {
            let otBeforeWorkTime = dayjs(
              endDate.isBefore(findDate.start) ? endDate : findDate.start
            ).diff(
              startDate.isAfter(findPrevDate.end)
                ? startDate
                : findPrevDate.end,
              "minute"
            );
            if (otBeforeWorkTime > 0) {
              if (isWorkingPrevDay) {
                xOT += otBeforeWorkTime;
              } else {
                xOTHoliday += otBeforeWorkTime;
              }
            }

            let otBeforeBreakTime = dayjs(
              endDate.isBefore(breaktime.start) ? endDate : breaktime.start
            ).diff(
              startDate.isAfter(findDate.start) ? startDate : findDate.start,
              "minute"
            );
            if (otBeforeBreakTime > 0) {
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(values.holiday && values.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeBreakTime;
                }else{
                  xWorkingDailyHoliday += otBeforeBreakTime;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeBreakTime;
              }
            }

            let otBetweenBreakTime = dayjs(
              endDate.isBefore(breaktime.end) ? endDate : breaktime.end
            ).diff(
              startDate.isAfter(breaktime.start) ? startDate : breaktime.start,
              "minute"
            );
            if (otBetweenBreakTime > 0) {
              if (formInputData.idRequestReason === 9) {
                if (isWorkingDay) {
                  xOT += otBetweenBreakTime;
                } else {
                  xOTHoliday += otBetweenBreakTime;
                }
              }
            }

            let otBeforeWorkEndTime = dayjs(
              endDate.isBefore(findDate.end) ? endDate : findDate.end
            ).diff(
              startDate.isAfter(breaktime.end) ? startDate : breaktime.end,
              "minute"
            );
            if (otBeforeWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(values.holiday && values.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeWorkEndTime;
                }else{
                  xWorkingDailyHoliday += otBeforeWorkEndTime;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeWorkEndTime;
              }
            }
          }

          if (
            dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30:00`
            ).isBefore(findNextDate.start)
          ) {
            let seventhirty = dayjs(
              `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30:00`
            );

            let otBeforeSevenThirty = dayjs(
              endDate.isBefore(seventhirty) ? endDate : seventhirty
            ).diff(
              startDate.isAfter(findDate.end) ? startDate : findDate.end,
              "minute"
            );
            if (otBeforeSevenThirty > 0) {
              if (isWorkingDay) {
                xOT += otBeforeSevenThirty;
              } else {
                xOTHoliday += otBeforeSevenThirty;
              }
            }

            let otBeforeNextWorkTime = dayjs(
              endDate.isBefore(findNextDate.start)
                ? endDate
                : findNextDate.start
            ).diff(
              startDate.isAfter(seventhirty) ? startDate : seventhirty,
              "minute"
            );
            if (otBeforeNextWorkTime > 0) {
              if (isWorkingNextDay) {
                xOT += otBeforeNextWorkTime;
              } else {
                xOTHoliday += otBeforeNextWorkTime;
              }
            }
          } else {
            let otBeforeNextWorkTime = dayjs(
              endDate.isBefore(findNextDate.start)
                ? endDate
                : findNextDate.start
            ).diff(
              startDate.isAfter(findDate.end) ? startDate : findDate.end,
              "minute"
            );
            if (otBeforeNextWorkTime > 0) {
              if (isWorkingDay) {
                xOT += otBeforeNextWorkTime;
              } else {
                xOTHoliday += otBeforeNextWorkTime;
              }
            }
          }

          if (
            breaktimeNextDate.start.isBetween(
              dayjs(findNextDate.start),
              dayjs(findNextDate.end),
              "minute",
              "[]"
            ) &&
            breaktimeNextDate.end.isBetween(
              dayjs(findNextDate.start),
              dayjs(findNextDate.end),
              "minute",
              "[]"
            )
          ) {
            let otBeforeBreakTimeNextDate = dayjs(
              endDate.isBefore(breaktimeNextDate.start)
                ? endDate
                : breaktimeNextDate.start
            ).diff(
              startDate.isAfter(findNextDate.start)
                ? startDate
                : findNextDate.start,
              "minute"
            );
            if (otBeforeBreakTimeNextDate > 0) {
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(valuesNext.holiday && valuesNext.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeBreakTimeNextDate;
                }else{
                  xWorkingDailyHoliday += otBeforeBreakTimeNextDate;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeBreakTimeNextDate;
              }
            }

            let otBeforeNextWorkEndTime = dayjs(
              endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
            ).diff(
              startDate.isAfter(breaktimeNextDate.end)
                ? startDate
                : breaktimeNextDate.end,
              "minute"
            );
            if (otBeforeNextWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(valuesNext.holiday && valuesNext.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeNextWorkEndTime;
                }else{
                  xWorkingDailyHoliday += otBeforeNextWorkEndTime;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
              }
            }
          } else {
            let otBeforeNextWorkEndTime = dayjs(
              endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
            ).diff(
              startDate.isAfter(findNextDate.start)
                ? startDate
                : findNextDate.start,
              "minute"
            );
            if (otBeforeNextWorkEndTime > 0) {
              if (employeeProfile.idPaymentType === 1 || employeeProfile.idPaymentType === 5) {
                if(valuesNext.holiday && valuesNext.pattern.isWorkingDay === 1){
                  xWorkingDaily += otBeforeNextWorkEndTime;
                }else{
                  xWorkingDailyHoliday += otBeforeNextWorkEndTime;
                }
              } else {
                xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
              }
            }
          }

          let otAfterNextWorkEndTime = dayjs(endDate).diff(
            startDate.isAfter(findNextDate.end) ? startDate : findNextDate.end,
            "minute"
          );
          if (otAfterNextWorkEndTime > 0) {
            xOTHoliday += otAfterNextWorkEndTime;
          }
        } else if (values.pattern.idWorkingType === 1) {
          if (startDate.isBefore(findDate.start)) {
            let findDatePrevPattern = {
              start: dayjs(findPrevDate.start).add(1, "day"),
              end: dayjs(findPrevDate.end).add(1, "day"),
            };

            if (
              dayjs(findDatePrevPattern.start).isBefore(
                findDate.start,
                "minute"
              )
            ) {
              let otBeforeFindDatePrevPattern = dayjs(
                endDate.isBefore(findDatePrevPattern.start)
                  ? endDate
                  : findDatePrevPattern.start
              ).diff(
                startDate.isAfter(findPrevDate.end)
                  ? startDate
                  : findPrevDate.end,
                "minute"
              );
              if (otBeforeFindDatePrevPattern > 0) {
                xOTHoliday += otBeforeFindDatePrevPattern;
              }

              let otBeforeFindDatePrevPatternEnd = dayjs(
                endDate.isBefore(findDatePrevPattern.end)
                  ? endDate
                  : findDatePrevPattern.end
              ).diff(
                startDate.isAfter(findDatePrevPattern.start)
                  ? startDate
                  : findDatePrevPattern.start,
                "minute"
              );
              if (otBeforeFindDatePrevPatternEnd > 0) {
                xWorkingMonthlyHoliday += otBeforeFindDatePrevPatternEnd;
              }

              let findDateStartNewDate = dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (findDateStartNewDate.isBefore(findDate.start)) {
                let otBeforeStartNewDate = dayjs(
                  endDate.isBefore(findDateStartNewDate)
                    ? endDate
                    : findDateStartNewDate
                ).diff(
                  startDate.isAfter(findDatePrevPattern.end)
                    ? startDate
                    : findDatePrevPattern.end,
                  "minute"
                );
                if (otBeforeStartNewDate > 0) {
                  if (isWorkingPrevDay) {
                    xOT += otBeforeStartNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNewDate;
                  }
                }

                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findDateStartNewDate)
                    ? startDate
                    : findDateStartNewDate,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  if (!isWorkingDay) {
                    xOTHoliday += otBeforeWorkTime;
                  } else {
                    xOT += otBeforeWorkTime;
                  }
                }
              } else {
                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findDatePrevPattern.end)
                    ? startDate
                    : findDatePrevPattern.end,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  xOTHoliday += otBeforeWorkTime;
                }
              }
            } else {
              let otBeforeFindDateEndPrevDate = dayjs(
                endDate.isBefore(findPrevDate.end) ? endDate : findPrevDate.end
              ).diff(
                startDate.isAfter(findPrevDate.start)
                  ? startDate
                  : findPrevDate.start,
                "minute"
              );
              if (otBeforeFindDateEndPrevDate > 0) {
                xWorkingMonthlyHoliday += otBeforeFindDateEndPrevDate;
              }

              let findDateStartNewDate = dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (findDateStartNewDate.isBefore(findDate.start)) {
                let otBeforeStartNewDate = dayjs(
                  endDate.isBefore(findDateStartNewDate)
                    ? endDate
                    : findDateStartNewDate
                ).diff(
                  startDate.isAfter(findPrevDate.end)
                    ? startDate
                    : findPrevDate.end,
                  "minute"
                );
                if (otBeforeStartNewDate > 0) {
                  if (isWorkingPrevDay) {
                    xOT += otBeforeStartNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNewDate;
                  }
                }

                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findDateStartNewDate)
                    ? startDate
                    : findDateStartNewDate,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  if (!isWorkingDay) {
                    xOTHoliday += otBeforeWorkTime;
                  } else {
                    xOT += otBeforeWorkTime;
                  }
                }
              } else {
                let otBeforeWorkTime = dayjs(
                  endDate.isBefore(findDate.start) ? endDate : findDate.start
                ).diff(
                  startDate.isAfter(findPrevDate.end)
                    ? startDate
                    : findPrevDate.end,
                  "minute"
                );
                if (otBeforeWorkTime > 0) {
                  if (
                    isWorkingPrevDay &&
                    (isWorkingDay ||
                      dayjs(findPrevDate.end).isBefore(
                        dayjs(
                          `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
                          "YYYY-MM-DD HH:mm"
                        )
                      ))
                  ) {
                    xOT += otBeforeWorkTime;
                  } else {
                    xOTHoliday += otBeforeWorkTime;
                  }
                }
              }
            }
          }

          let otBeforeWorkEndTime = dayjs(
            endDate.isBefore(findDate.end) ? endDate : findDate.end
          ).diff(
            startDate.isAfter(findDate.start) ? startDate : findDate.start,
            "minute"
          );
          if (otBeforeWorkEndTime > 0) {
            xWorkingMonthlyHoliday += otBeforeWorkEndTime;
          }

          if (endDate.isAfter(findDate.end)) {
            let findDatePatternAddDay = {
              start: dayjs(findDate.start).add(1, "day"),
              end: dayjs(findDate.end).add(1, "day"),
            };
            if (
              dayjs(findDatePatternAddDay.start).isBefore(
                findNextDate.start,
                "minute"
              )
            ) {
              let findNextDateStartNewDate = dayjs(
                `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (
                findNextDateStartNewDate.isBefore(findDatePatternAddDay.start)
              ) {
                let otBeforeStartNextNewDate = dayjs(
                  endDate.isBefore(findNextDateStartNewDate)
                    ? endDate
                    : findNextDateStartNewDate
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeStartNextNewDate > 0) {
                  if (isWorkingDay) {
                    xOT += otBeforeStartNextNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNextNewDate;
                  }
                }

                let otBeforeFindDatePatternAddDayStart = dayjs(
                  endDate.isBefore(findDatePatternAddDay.start)
                    ? endDate
                    : findDatePatternAddDay.start
                ).diff(
                  startDate.isAfter(findNextDateStartNewDate)
                    ? startDate
                    : findNextDateStartNewDate,
                  "minute"
                );
                if (otBeforeFindDatePatternAddDayStart > 0) {
                  if (!isWorkingNextDay) {
                    xOTHoliday += otBeforeFindDatePatternAddDayStart;
                  } else {
                    xOT += otBeforeFindDatePatternAddDayStart;
                  }
                }
              } else {
                let otBeforeFindDatePatternAddDayStart = dayjs(
                  endDate.isBefore(findDatePatternAddDay.start)
                    ? endDate
                    : findDatePatternAddDay.start
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeFindDatePatternAddDayStart > 0) {
                  xOTHoliday += otBeforeFindDatePatternAddDayStart;
                }
              }

              let otBeforeFindDatePatternAddDayEnd = dayjs(
                endDate.isBefore(findDatePatternAddDay.end)
                  ? endDate
                  : findDatePatternAddDay.end
              ).diff(
                startDate.isAfter(findDatePatternAddDay.start)
                  ? startDate
                  : findDatePatternAddDay.start,
                "minute"
              );
              if (otBeforeFindDatePatternAddDayEnd > 0) {
                xWorkingMonthlyHoliday += otBeforeFindDatePatternAddDayEnd;
              }

              let otBeforeNextWorkTime = dayjs(
                endDate.isBefore(findNextDate.start)
                  ? endDate
                  : findNextDate.start
              ).diff(
                startDate.isAfter(findDatePatternAddDay.end)
                  ? startDate
                  : findDatePatternAddDay.end,
                "minute"
              );
              if (otBeforeNextWorkTime > 0) {
                xOTHoliday += otBeforeNextWorkTime;
              }
            } else {
              let findNextDateStartNewDate = dayjs(
                `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 07:30`,
                "YYYY-MM-DD HH:mm"
              );
              if (findNextDateStartNewDate.isBefore(findNextDate.start)) {
                let otBeforeStartNextNewDate = dayjs(
                  endDate.isBefore(findNextDateStartNewDate)
                    ? endDate
                    : findNextDateStartNewDate
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeStartNextNewDate > 0) {
                  if (isWorkingDay) {
                    xOT += otBeforeStartNextNewDate;
                  } else {
                    xOTHoliday += otBeforeStartNextNewDate;
                  }
                }

                let otBeforeNextWorkTime = dayjs(
                  endDate.isBefore(findNextDate.start)
                    ? endDate
                    : findNextDate.start
                ).diff(
                  startDate.isAfter(findNextDateStartNewDate)
                    ? startDate
                    : findNextDateStartNewDate,
                  "minute"
                );
                if (otBeforeNextWorkTime > 0) {
                  if (!isWorkingNextDay) {
                    xOTHoliday += otBeforeNextWorkTime;
                  } else {
                    xOT += otBeforeNextWorkTime;
                  }
                }
              } else {
                let otBeforeNextWorkTime = dayjs(
                  endDate.isBefore(findNextDate.start)
                    ? endDate
                    : findNextDate.start
                ).diff(
                  startDate.isAfter(findDate.end) ? startDate : findDate.end,
                  "minute"
                );
                if (otBeforeNextWorkTime > 0) {
                  if (
                    isWorkingDay &&
                    (isWorkingNextDay ||
                      dayjs(findDate.end).isBefore(
                        dayjs(
                          `${dayjs(valuesNext.date).format(
                            "YYYY-MM-DD"
                          )} 00:00`,
                          "YYYY-MM-DD HH:mm"
                        )
                      ))
                  ) {
                    xOT += otBeforeNextWorkTime;
                  } else {
                    xOTHoliday += otBeforeNextWorkTime;
                  }
                }
              }
            }
          }

          let otBeforeNextWorkEndTime = dayjs(
            endDate.isBefore(findNextDate.end) ? endDate : findNextDate.end
          ).diff(
            startDate.isAfter(findNextDate.start)
              ? startDate
              : findNextDate.start,
            "minute"
          );
          if (otBeforeNextWorkEndTime > 0) {
            xWorkingMonthlyHoliday += otBeforeNextWorkEndTime;
          }

          let otAfterNextWorkEndTime = dayjs(endDate).diff(
            startDate.isAfter(findNextDate.end) ? startDate : findNextDate.end,
            "minute"
          );
          if (otAfterNextWorkEndTime > 0) {
            if (isWorkingNextDay) {
              xOT += otAfterNextWorkEndTime;
            } else {
              xOTHoliday += otAfterNextWorkEndTime;
            }
          }
        }
      }
    }

    return {
      error: error,
      listOT: {
        xOT: xOT,
        xOTHoliday: xOTHoliday,
        xWorkingDailyHoliday: xWorkingDailyHoliday,
        xWorkingMonthlyHoliday: xWorkingMonthlyHoliday,
        xWorkingDaily: xWorkingDaily
      },
    };
};

export default {
    calculatorOTProtech,
    calculatorOTStandard,
    calculatorOTMidnight
};