import React from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Divider,
} from "@mui/material";

import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const LateAbsentPanel = (props) => {
  const { dataForm, handleOnChangeData } = props;
  return (
    <Box>
      <div className="wrap-item-data">
        <Typography className="sub-header-1" variant="body1" gutterBottom>
          มาสาย
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                หักเงินค่าจ้าง
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                คำนวนประกันสังคม
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                คำนวนกองทุนสำรองเลี้ยงชีพ
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
      <StyledDivider />
      <div className="wrap-item-data">
        <Typography className="sub-header-1" variant="body1" gutterBottom>
          ขาดงาน
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                หักเงินค่าจ้าง
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                คำนวนประกันสังคม
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                คำนวนกองทุนสำรองเลี้ยงชีพ
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default LateAbsentPanel;
