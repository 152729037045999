import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux"; 
import dayjs from 'dayjs';
import { 
  TextField,
  FormControl,
  Select,
  MenuItem,
  Grid
}from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { addEducation, updateEducation } from "../../../../../../actions/education";
import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput":{
    display:"flex",
    alignItems:"center"
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledTextFieldTheme = styled(TextFieldTheme)({
  marginBottom: 0,
});

const DialogExperience = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose, idEdu, type } = props;
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const [formData, setFormData] = useState({
    fromYear: new Date(),
    endYear: new Date(),
    degree:"ปริญญาตรี",
    university:"",
    faculty: "",
    major: "",
    gpa:""
  });

  useEffect(() => {
    if(open){
      if(idEdu){
        let targetEdu = employeeProfile.education.find((edu) => edu.idEducations === idEdu);
        setFormData({
          idEducations: targetEdu.idEducations,
          fromYear: targetEdu.fromYear
            ? dayjs()
                .set("date", 5)
                .set("month", 0)
                .set("year", targetEdu.fromYear)
            : null,
          endYear: targetEdu.endYear
            ? dayjs()
                .set("date", 5)
                .set("month", 0)
                .set("year", targetEdu.endYear)
            : null,
          degree: targetEdu.degree,
          university: targetEdu.university,
          faculty: targetEdu.faculty,
          major: targetEdu.major,
          gpa: targetEdu.gpa
        });
      } else {
        setFormData({
          idEmp: employeeProfile.idEmp,
          fromYear: new Date(),
          endYear: new Date(),
          degree:"ปริญญาตรี",
          university:"",
          faculty: "",
          major: "",
          gpa:""
        });
      }
    }
  },[idEdu]);

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    let result;
    let data = {
      ...formData, 
      fromYear: formData.fromYear ? dayjs(formData.fromYear).format("YYYY") : null, 
      endYear: formData.endYear ? dayjs(formData.endYear).format("YYYY") : null
    };
    if(type === "Add"){
      result = await dispatch(addEducation(data));
    } else {
      let educationEmp = employeeProfile.education.find((edu) => edu.idEducations === idEdu);
      result = await dispatch(updateEducation(data))
    }
    if(result){
      handleClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
    }
  };

  return (
    <DrawerCustom
      title="เพิ่มประสบการณ์ทำงาน"
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} sm={6} className="GridTopicInput">เริ่มต้น</Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                views={["year"]}
                value={formData.fromYear}
                name="fromYear"
                onChange={(newValue) => {
                  setFormData({ ...formData, ["fromYear"]: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth helperText={null} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="GridTopicInput">สิ้นสุด</Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                views={["year"]}
                value={formData.endYear}
                name="endYear"
                onChange={(newValue) => {
                  setFormData({ ...formData, ["endYear"]: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth helperText={null} />
                )}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12} sm={6} className="GridTopicInput">ระดับการศึกษา</Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Select
                name="degree"
                value={formData.degree}
                onChange={handleChange}
              >
                <MenuItem value="ประถมต้น">ประถมต้น</MenuItem>
                <MenuItem value="ประถมปลาย">ประถมปลาย</MenuItem>
                <MenuItem value="มัธยมต้น">มัธยมต้น</MenuItem>
                <MenuItem value="มัธยมปลาย">มัธยมปลาย</MenuItem>
                <MenuItem value="ปวช.">ปวช.</MenuItem>
                <MenuItem value="ปวส.">ปวส.</MenuItem>
                <MenuItem value="ปริญญาตรี">ปริญญาตรี</MenuItem>
                <MenuItem value="ปริญญาโท">ปริญญาโท</MenuItem>
                <MenuItem value="ปริญญาเอก">ปริญญาเอก</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} className="GridTopicInput">ชื่อโรงเรียน/สถาบัน/มหาวิทยาลัย</Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextFieldTheme
              name="university"
              value={formData.university}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="GridTopicInput">คณะ</Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextFieldTheme
              name="faculty"
              value={formData.faculty}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="GridTopicInput">สาขาวิชา</Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextFieldTheme
              name="major"
              value={formData.major}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} className="GridTopicInput">เกรดเฉลี่ย</Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextFieldTheme
              name="gpa"
              value={formData.gpa}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>ยกเลิก</ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogExperience;
