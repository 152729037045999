import {
    COMPANY_BRANCH_FETCHING,
    COMPANY_BRANCH_FAILED,
    COMPANY_BRANCH_SUCCESS,
  } from "../actions/types";
  
  const initialState = {
    result: null,
    isFetching: false,
    isError: false,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case COMPANY_BRANCH_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      case COMPANY_BRANCH_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case COMPANY_BRANCH_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      default:
        return state;
    }
  }
  