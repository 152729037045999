import * as React from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import StyledCard from "../../../shared/general/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UpdateIcon from "@mui/icons-material/Update";
import { useTranslation } from "react-i18next";

import AccordionShift from "./accordionShift";

import { getShift } from "../../../../../actions/shift";

const StyledWrapHead = styled("div")(({ shift }) => ({
  padding: 16,
  paddingBottom: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiTypography-h5": {
    marginLeft: 8,
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

export default function EventSwitchShift(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { idShiftGroup, handleGetEmployeeShiftHistory } = props;
  const { result: shiftGroupList } = useSelector((state) => state.shift);
  const { result: shiftGroup } = useSelector((state) => state.shiftGroup);
  const { result: employeeShiftHistory } = useSelector(
    (state) => state.employeeShiftHistory
  );

  React.useEffect(() => {
    dispatch(getShift(shiftGroup.idCompany));
  }, []);

  const FilterHistory = () => {
    return employeeShiftHistory.filter((employee, index) => {
      if (index === 0) {
        return true;
      } else {
        if (
          employee.idShift !== employeeShiftHistory[index - 1].idShift ||
          employee.prevShift !== employeeShiftHistory[index - 1].prevShift ||
          employee.startDate !== employeeShiftHistory[index - 1].startDate ||
          employee.endDate !== employeeShiftHistory[index - 1].endDate
        ) {
          return true;
        } else {
          return false;
        }
      }
    });
  };

  return (
    <StyledCard style={{ marginTop: "20px" }}>
      <div>
        <StyledWrapHead>
          <div>
            <Typography
              variant="h5"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <UpdateIcon style={{ fontSize: 36, marginRight: 8 }} />
              {t("ShiftPlan")}
            </Typography>
          </div>
        </StyledWrapHead>
        <div style={{ padding: 16, paddingTop: 8 }}>
          {shiftGroupList &&
          employeeShiftHistory &&
          FilterHistory().length > 0 ? (
            FilterHistory().map((employee, index) => {
              return (
                <AccordionShift
                  key={`${employee.name}_${employee.lastname}`}
                  employee={employee}
                  idShiftGroup={idShiftGroup}
                  handleGetEmployeeShiftHistory={handleGetEmployeeShiftHistory}
                />
              );
            })
          ) : (
            <Box
              style={{
                width: "100%",
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" style={{ color: "grey" }}>
                {t("NoHistoryOfShiftSwitch")}
              </Typography>
            </Box>
          )}
        </div>
      </div>
    </StyledCard>
  );
}
