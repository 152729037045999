import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import {
  Button,
  FormControl,
  MenuItem,
  Link,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { Search } from "@mui/icons-material";

import StyledCard from "../../../shared/general/Card";

import utils from "../../../../../utils";

const Header = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

const InputSearch = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: "4px 0 0 4px",
    "& input": {
      height: "14px",
    },
  },
});

const ButtonSearch = styled(Button)({
  height: "100%",
  backgroundColor: "#007afe",
  borderRadius: "0 4px 4px 0",
  "&:hover": {
    backgroundColor: "#1565c0",
  },
});

const SelectMonth = styled(Select)(({ month }) => ({
  backgroundColor: "white",
  color: month === 12 ? "darkgrey" : "initial",
  "& .MuiSelect-select": {
    height: "14px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
}));

const Body = styled("div")({
  marginTop: "20px",
});

const OverviewVendor = () => {
  const { t, i18n } = useTranslation();

  const { result: vendorContract } = useSelector(
    (state) => state.vendorContract
  );
  const [search, setSearch] = useState("");
  const [month, setMonth] = useState(12);
  const [total, setTotal] = useState({
    numberSupply: 0,
    expensesMonth: 0,
    revenueMonth: 0,
    differenceMonth: 0,
    expensesYear: 0,
    revenueYear: 0,
    differenceYear: 0,
  });

  const SymbolSyncRaise = (value) => {
    if (value) {
      return (
        <i className="fa-solid fa-caret-up" style={{ color: "#2e7d32" }} />
      );
    } else {
      return (
        <i className="fa-solid fa-caret-down" style={{ color: "#d32f2f" }} />
      );
    }
  };

  const summaryEmployees = () => {
    return vendorContract.reduce(function (accumulator, item) {
      return accumulator + item.numberEmployee;
    }, 0);
  };

  return (
    <div
      style={{
        height: "fit-content",
        marginTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <Header>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputSearch
            variant="outlined"
            placeholder={t("Search")}
            onBlur={(event, newValue) => setSearch(newValue)}
          />
          <ButtonSearch>
            <Search style={{ color: "white" }} />
          </ButtonSearch>
        </div>
        <div>
          <FormControl
            variant="outlined"
            style={{ width: "150px", marginLeft: "15px" }}
          >
            <SelectMonth
              month={month}
              displayEmpty
              value={month}
              onChange={(event) => {
                setMonth(event.target.value);
              }}
            >
              <MenuItem disabled value={12}>
                {t("Month")}
              </MenuItem>
              {[...Array(12)].map((item, index) => (
                <MenuItem value={index} key={index}>
                  {dayjs().month(index).format("MMMM")}
                </MenuItem>
              ))}
            </SelectMonth>
          </FormControl>
        </div>
      </Header>
      <Body>
        <StyledCard>
          <TableContainer style={{ height: "600px", maxHeight: "600px" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: "320px", fontSize: "bold" }}>
                    {t("Customer")}
                  </TableCell>
                  <TableCell
                    style={{ minWidth: "150px", fontSize: "bold" }}
                    align="center"
                  >
                    {t("Amount")} ({t("Person")})
                  </TableCell>
                  <TableCell
                    style={{ minWidth: "150px", fontSize: "bold" }}
                    align="center"
                  >
                    {t("Expense")} ({t("Month")})
                  </TableCell>
                  <TableCell
                    style={{ minWidth: "150px", fontSize: "bold" }}
                    align="center"
                  >
                    {t("Income")} ({t("Month")})
                  </TableCell>
                  <TableCell
                    style={{ minWidth: "150px", fontSize: "bold" }}
                    align="center"
                  >
                    {t("Difference")} ({t("Month")})
                  </TableCell>
                  <TableCell
                    style={{ minWidth: "150px", fontSize: "bold" }}
                    align="center"
                  >
                    {t("TotalExpenses")} ({t("Year")})
                  </TableCell>
                  <TableCell
                    style={{ minWidth: "150px", fontSize: "bold" }}
                    align="center"
                  >
                    {t("TotalIncome")} ({t("Year")})
                  </TableCell>
                  <TableCell
                    style={{ minWidth: "150px", fontSize: "bold" }}
                    align="center"
                  >
                    {t("Difference")} ({t("Year")})
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendorContract && vendorContract.length > 0 ? (
                  vendorContract.map((item) => (
                    <TableRow key={item.idCompany} style={{ height: "53px" }}>
                      <TableCell>
                        <Link
                          href={`/vendor/employees?filter=${item.idCompany}`}
                        >
                          {item.companyName}
                          {` (${item.companyNameShort})`}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ marginRight: "10px" }}>
                            {utils.numberWithCommasWithoutDot(
                              item.numberEmployee ? item.numberEmployee : 0
                            )}
                          </div>
                          {SymbolSyncRaise(
                            item.isSupplyRise ? item.isSupplyRise : true
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {utils.numberWithCommas(
                          item.expensesMonth ? item.expensesMonth : 0
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {utils.numberWithCommas(
                          item.revenueMonth ? item.revenueMonth : 0
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ marginRight: "10px" }}>
                            {utils.numberWithCommas(
                              item.differenceMonth ? item.differenceMonth : 0
                            )}
                          </div>
                          {SymbolSyncRaise(
                            item.isDifferenceMRaise
                              ? item.isDifferenceMRaise
                              : true
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {utils.numberWithCommas(
                          item.expensesYear ? item.expensesYear : 0
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {utils.numberWithCommas(
                          item.revenueYear ? item.revenueYear : 0
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ marginRight: "10px" }}>
                            {utils.numberWithCommas(
                              item.differenceYear ? item.differenceYear : 0
                            )}
                          </div>
                          {SymbolSyncRaise(
                            item.isDifferenceYRaise
                              ? item.isDifferenceYRaise
                              : true
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} style={{ borderBottom: "0px" }}>
                      <Typography variant="h6" style={{ textAlign: "center" }}>
                        {t("NoCompanyList")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {/* {AllCompanys &&
                  AllCompanys.length < 9 &&
                  [
                    ...Array(
                      AllCompanys.length > 0
                        ? 9 - AllCompanys.length
                        : 10 - AllCompanys.length
                    ),
                  ].map((item, index) => (
                    <TableRow style={{ height: "53px" }} key={index}>
                      <TableCell
                        style={{ borderWidth: "0px" }}
                        colSpan={8}
                      ></TableCell>
                    </TableRow>
                  ))} */}
                <TableRow
                  style={{
                    bottom: 0,
                    position: "sticky",
                    backgroundColor: "white",
                  }}
                >
                  <TableCell
                    style={{
                      color: "black",
                      borderTop: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    {vendorContract &&
                      utils.numberWithCommasWithoutDot(summaryEmployees())}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    {utils.numberWithCommas(total.expensesMonth)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    {utils.numberWithCommas(total.revenueMonth)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    {utils.numberWithCommas(total.differenceMonth)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    {utils.numberWithCommas(total.expensesYear)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    {utils.numberWithCommas(total.revenueYear)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    {utils.numberWithCommas(total.differenceYear)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </StyledCard>
      </Body>
    </div>
  );
};

export default OverviewVendor;
