import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import MapContainer from "./MapContainer";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import SaveIcon from "@mui/icons-material/Save";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "100px",
    marginBottom: "100px",
    "& .MuiTypography-h6": {

      fontSize: "1.5rem",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.2rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  headPanel: {
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  location: {
    paddingLeft: theme.spacing(1),
  },
}));

function EditLocation(props) {
  const { onClickSave, dataEdit } = props;
  useTitle("GPS Location Edit - HR CORE DATA");
  const classes = useStyles();
  const [values, setValues] = useState([]);
  const [valueItems, setValueItems] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOnSubmit = () => {
    console.log("Data Submit", values);
  };

  useEffect(() => {
    setValues(dataEdit);
    setValueItems(dataEdit);
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.headingPage}>
        <Typography variant="h6">GPS Location Edit</Typography>
      </Box>
      <Paper className={classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} lg={8}>
            <MapContainer
              values={values}
              setValues={setValues}
              valueItems={valueItems}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box>
              <Typography className={classes.headPanel}>
                <LocationOnIcon sx={{ marginRight: "5px" }} />
                Location
              </Typography>
              <Box className={classes.location}>
                <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
                  <TextField
                    variant="outlined"
                    label="Location Name"
                    name="locationName"
                    value={values.locationName}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">G -</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Box>
              </Box>
              <Box sx={{ marginTop: "20px" }}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12} md={6} lg={6}>
                    <ButtonBlue
                      value={"Save Location"}
                      variant={"contained"}
                      startIcon={<SaveIcon />}
                      style={{ width: "100%" }}
                      onClick={onClickSave}
                    >
                      บันทึกตำแหน่ง
                    </ButtonBlue>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default EditLocation;
