import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import {
    Alert,
    Box,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    MenuItem,
    Snackbar,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from '@mui/icons-material/Remove';

import ButtonBlue from '../../../../shared/general/ButtonBlue';
import DrawerBeneficiary from './drawerBeneficiary';
import DialogPFDate from './dialogPFDate';
import TextFieldTheme from '../../../../shared/general/TextFieldTheme';

import { getBeneficiaryUser, deleteBeneficiary } from '../../../../../../actions/beneficiary';
import { 
    getProvidentFundUserByAdminVendor, 
    updateProvidentFundUserByAdminVendor
} from '../../../../../../actions/provident-fund';

const StyledRoot = styled(Box)({
    "& .Topic":{
        fontWeight: 600, 
        fontSize: 20
    },
    "& .disbledEdit":{
        color:"#bdbdbd"
    },
    "& .beneficiary":{
        marginTop: 48,
        "& .navButton":{
            display:"flex",
            justifyContent:"flex-end"
        },
        "& .tableContainer":{
            "& .TableCellHead":{
                padding: "0 !important"
            },
            "& .MuiTableCell-root":{
                borderWidth: 0,
                padding: "16px 8px"
            },
            "& .icon":{
                fontSize: 16,
                cursor:"pointer"
            }
        }
        
    }
});

const MinusIconButton = styled(IconButton)({
    border: "1px solid #ff0000",
    color:"#ff0000",
    "&.Mui-disabled":{
        border: "1px solid #bdbdbd"
    }
});

const AddIconButton = styled(IconButton)({
    border: "1px solid #1976d2",
    color:"#1976d2",
    "&.Mui-disabled":{
        border: "1px solid #bdbdbd"
    }
});

const PF = () => {
    const dispatch = useDispatch();
    const { result: employeeProfile } = useSelector(
        (state) => state.employeeProfile
    );
    const { result: AllBeneficiarys } = useSelector(state => state.userBeneficiarys);
    const { result: UserProvidentFund } = useSelector(state => state.userProvidentFund);
    const [openDrawerBeneficiary, setOpenDrawerBeneficiary] = useState(false);
    const [beneficiarySelected, setBeneficiarySelected] = useState(null);
    const [snackBar, setSnackBar] = useState({ isOpen: false, result: null, label:"" });
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ isOpen: false, idSelected: null});
    const [openDialogChangePFDate, setOpenDialogChangePFDate] = useState(false);
    const [openDialogChangePF, setOpenDialogChangePF] = useState(false);
    const [newVendorPF, setNewVendorPF] = useState(null);
    const [useDateUserPF, setUseDateUserPF] = useState(dayjs().date(1).add(1, "month").format("YYYY-MM-DD"));

    const { control, handleSubmit, formState:{ errors }, setValue, watch, getValues } = useForm({
        defaultValues:{
            newEmployeePF: ""
        }
    });

    useEffect(() => {
        if(employeeProfile && employeeProfile.idEmp){
            dispatch(getBeneficiaryUser(employeeProfile.idEmp));
            dispatch(getProvidentFundUserByAdminVendor(employeeProfile.idEmp));
        }
    },[employeeProfile]);

    useEffect(() => {
        if (UserProvidentFund && UserProvidentFund.newUserPF) {
            setValue("newEmployeePF",UserProvidentFund.newUserPF);
            setNewVendorPF(UserProvidentFund.newVendorPF);
        } else {
            setValue("newEmployeePF","");
            setNewVendorPF(null);
        }
    },[UserProvidentFund]);

    const onHandleSubmit = async () => {
        setOpenDialogChangePF(false);
        // CompanyPFByTextField();
        const res = await dispatch(updateProvidentFundUserByAdminVendor(getValues("newEmployeePF"),newVendorPF, employeeProfile.idEmp, useDateUserPF));
        if (res.status === 200) {
            await dispatch(getProvidentFundUserByAdminVendor(employeeProfile.idEmp));
            setUseDateUserPF(dayjs().date(1).add(1, "month").format("YYYY-MM-DD"));
            setSnackBar({ isOpen: true, result:"success", label:`เปลี่ยนแปลงอัตราสะสมสำเร็จ`});
        } else {
            setUseDateUserPF(dayjs().date(1).add(1, "month").format("YYYY-MM-DD"));
            setSnackBar({ isOpen: true, result:"error", label:`เปลี่ยนแปลงอัตราสะสมไม่สำเร็จ`});
        }
    };

    const handleCloseNotify = (_, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackBar({ isOpen: false, result: null, label:"" });
    };

    const handleDelete = async (idBeneficiary) => {
        console.log("idBeneficiary  :",idBeneficiary);
        const res = await dispatch(deleteBeneficiary(idBeneficiary));
        if (res.status === 200) {
            setSnackBar({ isOpen: true, result:"success", label:`ลบผู้ได้รับผลประโยชน์สำเร็จ`});
            await dispatch(getBeneficiaryUser(employeeProfile.idEmp));
        } else {
            setSnackBar({ isOpen: true, result:"error", label:`ลบผู้ได้รับผลประโยชน์ไม่สำเร็จ`} );
        }
        setOpenDeleteDialog({ isOpen: false, idSelected: null});
    };

    const isBetweenEditDate = () => {
        // if (
        //     UserProvidentFund && UserProvidentFund.startEditDate && UserProvidentFund.endEditDate && 
        //     !(dayjs().isBefore(dayjs(UserProvidentFund.startEditDate)) || dayjs().isAfter(dayjs(UserProvidentFund.endEditDate)))
        // ) {
        //     return false;
        // } else {
        //     return true;
        // }
        return false;
    };

    const CompanyPFByTextField = () => {
        let result = "-";
        if(UserProvidentFund){
            let newEmployeePF = watch("newEmployeePF");
            if(newEmployeePF && UserProvidentFund.listEmployeePF.length > 0){
                UserProvidentFund.listEmployeePF.map((pf => {
                    if(pf.minEmployeePF <= newEmployeePF && pf.maxEmployeePF >= newEmployeePF){
                        result = pf.vendorPF;
                    }
                }))
            }
        }
        
        return result;
    };

    const onClickMinusButton = () => {
        if(newVendorPF > 0 ){
            let newValue = newVendorPF - 1;
            setNewVendorPF(newValue);
        }
    };

    const onClickAddButton = () => {
        if(newVendorPF < 20){
            let newValue = newVendorPF + 1;
            setNewVendorPF(newValue);
        }
    };

    return (
        <StyledRoot>
            <form onSubmit={handleSubmit(() => setOpenDialogChangePF(true))}>
                <Grid container spacing={2}>
                    <Grid item xs={12} container justifyContent={"flex-end"}>
                        <Grid item>
                            <ButtonBlue
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => setOpenDialogChangePFDate(true)}
                            >แก้ไข</ButtonBlue>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding:"24px 16px" }}>
                        <Typography variant="h6" align="center">วันที่นับอายุงานในกองทุน</Typography>
                        <Typography style={{ fontSize:24, fontWeight:400, marginTop: 16 }} align="center">
                            {(UserProvidentFund && UserProvidentFund.ServPFDate && 
                                `${
                                    dayjs(UserProvidentFund.ServPFDate)
                                    .locale(localStorage.getItem("language") || "th")
                                    .format(
                                        localStorage.getItem("language") === "th"
                                        ? "DD MMMM BBBB"
                                        : "DD MMMM YYYY"
                                    )
                                }`
                            ) || "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding:"24px 16px" }}>
                        <Typography variant="h6" align="center">วันที่เข้ากองทุน</Typography>
                        <Typography style={{ fontSize:24, fontWeight:400, marginTop: 16 }} align="center">
                            {(UserProvidentFund && UserProvidentFund.PFDate && 
                                `${
                                    dayjs(UserProvidentFund.PFDate)
                                    .locale(localStorage.getItem("language") || "th")
                                    .format(
                                        localStorage.getItem("language") === "th"
                                        ? "DD MMMM BBBB"
                                        : "DD MMMM YYYY"
                                    )
                                }`
                            ) || "-"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className="Topic">อัตราสะสม</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" align='center'>ส่วนพนักงาน(%)</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" align='center'>สมทบส่วนบริษัท(%)</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6">อัตตราสะสมปัจจุบัน</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" align='center'>{(UserProvidentFund && UserProvidentFund.userPF && `${UserProvidentFund.userPF}`) || "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" align='center'>{(UserProvidentFund && UserProvidentFund.vendorPF && `${UserProvidentFund.vendorPF}`) || "-"}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} 
                        className={clsx({ disbledEdit: UserProvidentFund ? isBetweenEditDate() : true })}
                        style={{ display:'flex', alignItems:"center" }}
                    >
                        <Typography variant="h6" style={{ color:"inherit" }}>อัตตราสะสมใหม่</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller 
                            name="newEmployeePF"
                            control={control}
                            rules={{
                                required: {value: true, message: "กรุณาเลือกอัตตราสะสมใหม่"}
                            }}
                            render={({ field }) => (
                                <TextFieldTheme 
                                    {...field}
                                    onChange={(e) => {
                                        field.onChange(e);
                                        if(!newVendorPF  && newVendorPF !== 0){
                                            setNewVendorPF((UserProvidentFund && UserProvidentFund.vendorPF) || 0);
                                            // setNewVendorPF((UserProvidentFund && UserProvidentFund.vendorPF) || CompanyPFByTextField());
                                        }
                                    }}
                                    select
                                    helperText={errors && errors.newEmployeePF && errors.newEmployeePF.message}
                                    error={(errors && errors.newEmployeePF) ? true: false}
                                    disabled={UserProvidentFund ? isBetweenEditDate() : true}
                                >
                                    {[...Array(
                                        16
                                        // (UserProvidentFund && UserProvidentFund.minEmployeePF && UserProvidentFund.maxEmployeePF) ?
                                        // ((UserProvidentFund.maxEmployeePF - UserProvidentFund.minEmployeePF)+1) : 0
                                    )].map((_, index) => {
                                        return (
                                            <MenuItem 
                                                // value={`${index+(UserProvidentFund.minEmployeePF || 0)}`} key={index+(UserProvidentFund.minEmployeePF || 0)}
                                                value={index}
                                                key={index}
                                            >
                                                {/* {`${index+(UserProvidentFund.minEmployeePF || 0)}`} */}
                                                {index}
                                            </MenuItem>
                                        );
                                    })}
                                </TextFieldTheme>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} 
                        className={
                            clsx({ 
                                disbledEdit: UserProvidentFund ? isBetweenEditDate() : true
                            })
                        }
                        style={{ display:'flex', alignItems:"center", justifyContent:"space-between" }}
                    >
                        <MinusIconButton
                            onClick={onClickMinusButton}
                            disabled={(!newVendorPF  && newVendorPF !== 0) || newVendorPF === 0}
                        ><RemoveIcon /></MinusIconButton>
                        <Typography variant="h6" align='center' style={{ color:"inherit" }}>
                            {/* {CompanyPFByTextField()} */}
                            {newVendorPF || (newVendorPF === 0 ? 0 : "-")}
                        </Typography>
                        <AddIconButton
                            onClick={onClickAddButton}
                            disabled={(!newVendorPF && newVendorPF !== 0) || newVendorPF === 20}
                        ><AddIcon /></AddIconButton>
                    </Grid>
                    <Grid item container xs={12} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            {/* <Typography>
                                {`ช่วงที่สามารถแก้ไข : `}<span style={{ color:"#1976D2", fontWeight:600 }}>
                                {`${(UserProvidentFund && UserProvidentFund.startEditDate && dayjs(UserProvidentFund.startEditDate).format("DD MMM YYYY")) || "-"} - ${(UserProvidentFund && UserProvidentFund.endEditDate && dayjs(UserProvidentFund.endEditDate).format("DD MMM YYYY")) || "-"}`}
                            </span>
                            </Typography> */}
                        </Grid>
                        <Grid item>
                            <ButtonBlue 
                                variant="contained" 
                                type="submit"
                                disabled={isBetweenEditDate()}
                            >บันทึก</ButtonBlue>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <Box className='beneficiary'>
                <Typography className="Topic">ผู้รับผลประโยชน์</Typography>
                <Box className="navButton">
                    <ButtonBlue 
                        variant="contained"
                        onClick={() => setOpenDrawerBeneficiary(true)}
                    >เพิ่มผู้รับผลประโยชน์</ButtonBlue>
                </Box>
                <Box
                    style={{
                        width:"100%",
                        overflowX:"auto"
                    }}
                >
                    <TableContainer className="tableContainer">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        className="TableCellHead"
                                        style={{ width:56, minWidth: 56 }}
                                    ></TableCell>
                                    <TableCell className="TableCellHead"></TableCell>
                                    <TableCell className="TableCellHead"></TableCell>
                                    <TableCell
                                        className="TableCellHead"
                                        style={{ width:88, minWidth: 88}}
                                    ></TableCell>
                                    <TableCell
                                        className="TableCellHead"
                                        style={{ width:104, minWidth: 104}}
                                    ></TableCell>
                                    <TableCell
                                        className="TableCellHead"
                                        style={{ width:40, minWidth: 40}}
                                    ></TableCell>
                                    <TableCell
                                        className="TableCellHead"
                                        style={{ width:48, minWidth: 48}}
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(AllBeneficiarys && AllBeneficiarys.length > 0) ? AllBeneficiarys.map((beneficiary, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Typography>{beneficiary.title_TH}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{beneficiary.firstname_TH}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{beneficiary.lastname_TH}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{beneficiary.relationship_TH}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{beneficiary.telephoneMobile}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{`${beneficiary.percentage}%`}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Box style={{ display:"flex" }}>
                                                <Box 
                                                    style={{ marginRight: 16 }} 
                                                    onClick={() => {
                                                        setBeneficiarySelected(beneficiary);
                                                        setOpenDrawerBeneficiary(true);
                                                    }}
                                                >
                                                    <i className="fa-regular fa-pen icon" style={{ color:"#1976D2" }}></i>
                                                </Box>
                                                <Box
                                                    onClick={() => {
                                                        setOpenDeleteDialog({ isOpen: true, idSelected: beneficiary.idBeneficiary});
                                                    }}
                                                >
                                                    <i className="fa-regular fa-trash icon" style={{ color:"#ff0000" }}></i>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                                :
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <Box style={{ width:"100%", height:100, display:"flex", flexDirection:"column", justifyContent:'center' }}>
                                                <Typography align="center">ไม่มีรายชื่อผู้รับผลประโยชน์</Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            {openDrawerBeneficiary && 
                <DrawerBeneficiary 
                    open={openDrawerBeneficiary}
                    handleClose={() => {
                        setOpenDrawerBeneficiary(false);
                        setBeneficiarySelected(null);
                    }}
                    data={beneficiarySelected}
                    setSnackBar={setSnackBar}
                />
            }
            {openDeleteDialog.isOpen &&
                <Dialog
                    open={openDeleteDialog.isOpen}
                    onClose={() => setOpenDeleteDialog(false)}
                >
                    <DialogContent style={{ width: 200 }}>
                        <Typography variant="h5" align="center">ยืนยันการลบ</Typography>
                        <Grid container justifyContent="space-between" style={{ marginTop: 16 }}>
                            <Grid item>
                                <ButtonBlue
                                    onClick={() => setOpenDeleteDialog({ isOpen: false, idSelected: null})}
                                >ยกเลิก</ButtonBlue>
                            </Grid>
                            <Grid item>
                                <ButtonBlue 
                                    variant="contained"
                                    onClick={() => handleDelete(openDeleteDialog.idSelected)}
                                >ยืนยัน</ButtonBlue>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
            {openDialogChangePF && 
                <Dialog
                    open={openDialogChangePF}
                    onClose={() => setOpenDialogChangePF(false)}
                >
                    <DialogContent style={{ width: 350 }}>
                        <Typography variant="h5" align="center">
                            <i className="fa-solid fa-triangle-exclamation" 
                                style={{ marginRight: 16, color:"#FFBF00" }}
                            />
                            ยืนยันการเปลี่ยนอัตราสะสม
                        </Typography>
                        <div style={{
                            width:"100%",
                            display:"flex",
                            justifyContent:"center"
                        }}>
                            <TextFieldTheme
                                style={{
                                    marginTop: 16,
                                    width: 250
                                }}
                                select
                                value={useDateUserPF}
                                onChange={(e) => {
                                    setUseDateUserPF(e.target.value);
                                }}
                                label="เลือกวันที่เริ่มใช้"
                            >
                                <MenuItem value={dayjs().date(1).format("YYYY-MM-DD")}>
                                    {`วันที่ 1 ${dayjs().format("MMMM")} ${dayjs().format("YYYY")}`}
                                </MenuItem>
                                <MenuItem value={dayjs().date(1).add(1, "month").format("YYYY-MM-DD")}>
                                    {`วันที่ 1 ${dayjs().add(1, "month").format("MMMM")} ${dayjs().add(1, "month").format("YYYY")}`}
                                </MenuItem>
                            </TextFieldTheme>
                        </div>
                        {/* <Typography align="center" style={{ marginTop: 16 }}>
                            การเปลี่ยนอัตราสะสมจะเริ่มใช้ในเดือนถัดไป
                        </Typography> */}
                        <Grid container justifyContent="space-between" style={{ marginTop: 16 }}>
                            <Grid item>
                                <ButtonBlue
                                    onClick={() => setOpenDialogChangePF(false)}
                                >ยกเลิก</ButtonBlue>
                            </Grid>
                            <Grid item>
                                <ButtonBlue 
                                    variant="contained"
                                    onClick={onHandleSubmit}
                                >ยืนยัน</ButtonBlue>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
            {(openDialogChangePFDate && UserProvidentFund) && 
                <DialogPFDate 
                    open={openDialogChangePFDate}
                    onClose={() => setOpenDialogChangePFDate(false)}
                    values={UserProvidentFund}
                />
            }
            {snackBar.isOpen && 
                <Snackbar
                    open={snackBar.isOpen}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={handleCloseNotify}
                >
                    <Alert severity={snackBar.result} onClose={handleCloseNotify}>
                        {snackBar.label}
                    </Alert>
                </Snackbar>
            }
        </StyledRoot>
    );
};

export default PF;