import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx";

import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  ToggleButtonGroup, 
  ToggleButton, 
  FormControl, 
  InputAdornment,
  FilledInput
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { getAllRequestTimeCompany } from ".././../../../actions/requestTime";
import { getLeaveRequestByCompany } from "../../../../actions/employee";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

import TimeAttendance from "./timeAttendance";
import OT from "./ot";
import LeaveRequest from "./leave";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerThai from "../../shared/general/DatePickerThai";
import DatePickerEng from "../../shared/general/DatePickerEng";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#007afe",
    border: "1px solid #007afe",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007afe",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #007afe",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
  display: "flex",
  justifyContent: "space-evenly",
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const ApporveStatusListPage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { result : UserProfile } = useSelector(state => state.userProfile); 
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );
  const today = dayjs().toDate();
  const daysInMonth = dayjs(today).daysInMonth()
  const [view, setView] = React.useState("time");
  const [search, setSearch] = useState({
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear(), today.getMonth(), daysInMonth)
  });
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    dispatch(getAllRequestTimeCompany(UserProfile.idCompany, search));
    dispatch(getLeaveRequestByCompany(UserProfile.idCompany, search));
  },[]);

  const handleChangeSearch = (event) => {
    setSearchName(event.target.value);
  };

  const handleChangeView = (_, newView) => {
    if (newView) {
      setView(newView);
    }
  };

  const handleOnClickSearch = () => {
    if(view === "time" || view === "ot"){
      dispatch(getAllRequestTimeCompany(UserProfile.idCompany, search));
    } else if (view === "leave") {
      dispatch(getLeaveRequestByCompany(UserProfile.idCompany, search));
    }
  };

  const excelExport = () => {
    let dataToExcel = [];
    let elementList = [];
    if (view === "time" && requestTimeList) {
      dataToExcel = requestTimeList.filter((x) => {
        if(
          x.idRequestType === 1 && (
            (x.isDoubleApproval === 1 && (x.isManagerLV1Approve !== null && x.isManagerLV2Approve !== null)) ||
            (x.approvalLevel === 1 && x.isManagerLV1Approve !== null) ||
            (x.approvalLevel === 2 && x.isDoubleApproval === 0 && x.isManagerLV2Approve !== null) || 
            x.isActive === 0
          ) && (
            searchName && searchName.length > 0 ? 
              (
                x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
                x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
              )
              : true
          )
        ){
          return true;
        }else{
          return false;
        }
      });

      if(dataToExcel.length > 0){
        for (let index = 0; index < dataToExcel.length; index++) {
          const element = {
            // ลำดับที่: dataToExcel[index].idRequestTime,
            รหัสพนักงาน: dataToExcel[index].employeeId,
            "ชื่อ-สกุล": `${dataToExcel[index].firstname} ${dataToExcel[index].lastname}`,
            ตำแหน่ง: dataToExcel[index].positionsName
              ? dataToExcel[index].positionsName
              : "",
            ประเภทการจ่ายเงินเดือน: dataToExcel[index].paymentRound
              ? dataToExcel[index].paymentRound
              : "",
            ประเภทคำขอ: dataToExcel[index].name ? dataToExcel[index].name : "",
            เวลาเริ่มต้น: dataToExcel[index].startText
              ? dataToExcel[index].startText
              : "",
            เวลาสิ้นสุด: dataToExcel[index].endText
              ? dataToExcel[index].endText
              : "",
            เหตุผล: dataToExcel[index].requestReasonName
              ? dataToExcel[index].requestReasonName
              : "-",
            "เหตุผล(เพิ่มเติม)": dataToExcel[index].otherReason
              ? dataToExcel[index].otherReason
              : "-",
            วันเวลาที่ทำรายการ: dataToExcel[index].createDateText
              ? dataToExcel[index].createDateText
              : "-",
            "ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].managerLV1Name
              ? dataToExcel[index].managerLV1Name
              : "-",
            "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].emailManagerLV1
              ? dataToExcel[index].emailManagerLV1
              : "-",
            "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
            dataToExcel[index].isActive === 0 ?
                "ถอนรายการ" : dataToExcel[index].isManagerLV1Approve === 1 ?
                    "อนุมัติ" : "ไม่อนุมัติ",
            "ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcel[index].isDoubleApproval === 1
                ? dataToExcel[index].managerLV2Name
                  ? dataToExcel[index].managerLV2Name
                  : ""
                : dataToExcel[index].approvalLevel === 2
                ? dataToExcel[index].managerLV2Name
                  ? dataToExcel[index].managerLV2Name
                  : ""
                : "",
            "E-mail ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcel[index].isDoubleApproval === 1
                ? dataToExcel[index].emailManagerLV2
                  ? dataToExcel[index].emailManagerLV2
                  : ""
                : dataToExcel[index].approvalLevel === 2
                ? dataToExcel[index].emailManagerLV2
                  ? dataToExcel[index].emailManagerLV2
                  : ""
                : "",
            "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcel[index].isDoubleApproval === 1
                ? dataToExcel[index].isManagerLV1Approve &&
                  dataToExcel[index].isManagerLV1Approve === null
                  ? "รอผู้บังคับบัญชาลำดับที่ 1"
                  : dataToExcel[index].isManagerLV2Approve &&
                    dataToExcel[index].isManagerLV2Approve === null
                  ? "รออนุมัติ"
                  : dataToExcel[index].isManagerLV2Approve === 1
                  ? "อนุมัติ"
                  : "ไม่อนุมัติ"
                : dataToExcel[index].approvalLevel === 2
                ? dataToExcel[index].isManagerLV2Approve &&
                  dataToExcel[index].isManagerLV2Approve === null
                  ? "รออนุมัติ"
                  : dataToExcel[index].isManagerLV2Approve === 1
                  ? "อนุมัติ"
                  : "ไม่อนุมัติ"
                : "",
          };
          elementList.push(element);
        }
      } else {
        const element = {
          รหัสพนักงาน: "",
          "ชื่อ-สกุล": "",
          ตำแหน่ง: "",
          ประเภทการจ่ายเงินเดือน: "",
          ประเภทคำขอ: "",
          เวลาเริ่มต้น: "",
          เวลาสิ้นสุด: "",
          เหตุผล: "",
          "เหตุผล(เพิ่มเติม)": "",
          วันเวลาที่ทำรายการ: "",
          "ผู้บังคับบัญชาลำดับที่ 1": "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 1": "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1": "",
          "ผู้บังคับบัญชาลำดับที่ 2": "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 2": "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2": ""
        };
        elementList.push(element);
      }
    } else if (view === "ot" && requestTimeList) {
      dataToExcel = requestTimeList.filter((x) => {
        if(
          x.idRequestType === 2 && (
            (x.isDoubleApproval === 1 && (x.isManagerLV1Approve !== null && x.isManagerLV2Approve !== null)) ||
            (x.approvalLevel === 1 && x.isManagerLV1Approve !== null) ||
            (x.approvalLevel === 2 && x.isDoubleApproval === 0 && x.isManagerLV2Approve !== null) || 
            x.isActive === 0
          ) && (
            searchName && searchName.length > 0 ? 
              (
                x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
                x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
              )
              : true
          )
        ){
          return true;
        }else{
          return false;
        }
      });

      if(dataToExcel.length > 0){
        for (let index = 0; index < dataToExcel.length; index++) {
          const element = {
            รหัสพนักงาน: dataToExcel[index].employeeId,
            "ชื่อ-สกุล": `${dataToExcel[index].firstname} ${dataToExcel[index].lastname}`,
            ตำแหน่ง: dataToExcel[index].positionsName
              ? dataToExcel[index].positionsName
              : "",
            ประเภทคำขอ: dataToExcel[index].name ? dataToExcel[index].name : "",
            เวลาเริ่มต้น: dataToExcel[index].startText
              ? dataToExcel[index].startText
              : "",
            เวลาสิ้นสุด: dataToExcel[index].endText
              ? dataToExcel[index].endText
              : "",
            [`OT ${dataToExcel[index].payrollSetting.xWorkingMonthlyHoliday}x (วันหยุด)`]:
              dataToExcel[index].xWorkingMonthlyHoliday > 0
                ? `${parseFloat(
                    dataToExcel[index].xWorkingMonthlyHoliday / 60
                  ).toFixed(2)} ชม.`
                : "",
            [`OT ${dataToExcel[index].payrollSetting.xOT}x (วันทำงาน)`]:
              dataToExcel[index].xOT > 0
                ? `${parseFloat(dataToExcel[index].xOT / 60).toFixed(2)} ชม.`
                : "",
            [`OT ${dataToExcel[index].payrollSetting.xWorkingDailyHoliday}x`]:
              dataToExcel[index].xWorkingDailyHoliday > 0
                ? `${parseFloat(
                    dataToExcel[index].xWorkingDailyHoliday / 60
                  ).toFixed(2)} ชม.`
                : "",
            [`OT ${dataToExcel[index].payrollSetting.xOTHoliday}x`]:
              dataToExcel[index].xOTHoliday > 0
                ? `${parseFloat(dataToExcel[index].xOTHoliday / 60).toFixed(
                    2
                  )} ชม.`
                : "",
            เหตุผล: dataToExcel[index].requestReasonName
              ? dataToExcel[index].requestReasonName
              : "-",
            "เหตุผล(เพิ่มเติม)": dataToExcel[index].otherReason
              ? dataToExcel[index].otherReason
              : "-",
            วันเวลาที่ทำรายการ: dataToExcel[index].createDateText
              ? dataToExcel[index].createDateText
              : "-",
            "ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].managerLV1Name
              ? dataToExcel[index].managerLV1Name
              : "-",
            "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcel[index].emailManagerLV1
              ? dataToExcel[index].emailManagerLV1
              : "-",
            "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
                dataToExcel[index].isActive === 0 ?
                "ถอนรายการ" : dataToExcel[index].isManagerLV1Approve === 1 ?
                    "อนุมัติ" : "ไม่อนุมัติ",
            "ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcel[index].isDoubleApproval === 1
                ? dataToExcel[index].managerLV2Name
                  ? dataToExcel[index].managerLV2Name
                  : ""
                : dataToExcel[index].approvalLevel === 2
                ? dataToExcel[index].managerLV2Name
                  ? dataToExcel[index].managerLV2Name
                  : ""
                : "",
            "E-mail ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcel[index].isDoubleApproval === 1
                ? dataToExcel[index].emailManagerLV2
                  ? dataToExcel[index].emailManagerLV2
                  : ""
                : dataToExcel[index].approvalLevel === 2
                ? dataToExcel[index].emailManagerLV2
                  ? dataToExcel[index].emailManagerLV2
                  : ""
                : "",
            "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcel[index].isDoubleApproval === 1
                ? dataToExcel[index].isManagerLV1Approve &&
                  dataToExcel[index].isManagerLV1Approve === null
                  ? "รอผู้บังคับบัญชาลำดับที่ 1"
                  : dataToExcel[index].isManagerLV2Approve &&
                    dataToExcel[index].isManagerLV2Approve === null
                  ? "รออนุมัติ"
                  : dataToExcel[index].isManagerLV2Approve === 1
                  ? "อนุมัติ"
                  : "ไม่อนุมัติ"
                : dataToExcel[index].approvalLevel === 2
                ? dataToExcel[index].isManagerLV2Approve &&
                  dataToExcel[index].isManagerLV2Approve === null
                  ? "รออนุมัติ"
                  : dataToExcel[index].isManagerLV2Approve === 1
                  ? "อนุมัติ"
                  : "ไม่อนุมัติ"
                : "",
          };
          elementList.push(element);
        }
      } else {
        const element = {
          รหัสพนักงาน: "",
          "ชื่อ-สกุล": "",
          ตำแหน่ง: "",
          ประเภทการจ่ายเงินเดือน: "",
          ประเภทคำขอ: "",
          เวลาเริ่มต้น: "",
          เวลาสิ้นสุด: "",
          [`OT 1x (วันหยุด)`]: "",
          [`OT 1.5x (วันทำงาน)`]: "",
          [`OT 2x`]: "",
          [`OT 3x`]: "",
          เหตุผล: "",
          "เหตุผล(เพิ่มเติม)": "",
          วันเวลาที่ทำรายการ: "",
          "ผู้บังคับบัญชาลำดับที่ 1": "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 1": "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1": "",
          "ผู้บังคับบัญชาลำดับที่ 2": "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 2": "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2": "",
        };
        elementList.push(element);
      }
    } else if (view === "leave" && leaveEmployeeList) {
      dataToExcel = leaveEmployeeList.filter((x) => {
        if(
          (x.isApprove !== null || x.isActive === 0) && 
          (
            (searchName && searchName.length > 0) ? 
            (
              x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
              x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
            )
            : true
          )
        ){
          return true;
        } else {
          return false;
        }
      });

      if(dataToExcel.length > 0){
        for (let index = 0; index < dataToExcel.length; index++) {
          const element = {
            รหัสพนักงาน: dataToExcel[index].employeeId,
            "ชื่อ-สกุล": `${dataToExcel[index].firstname} ${dataToExcel[index].lastname}`,
            ตำแหน่ง: dataToExcel[index].positionsName
              ? dataToExcel[index].positionsName
              : "",
            ประเภทคำขอ: dataToExcel[index].name ? dataToExcel[index].name : "",
            เวลาเริ่มต้น: dataToExcel[index].startText
              ? dataToExcel[index].startText
              : "",
            เวลาสิ้นสุด: dataToExcel[index].endText
              ? dataToExcel[index].endText
              : "",
            "จำนวนที่ใช้ไป (วัน)": dataToExcel[index].used
              ? dataToExcel[index].used
              : "",
            เหตุผล: dataToExcel[index].description
              ? dataToExcel[index].description
              : "-",
            วันเวลาที่ทำรายการ: dataToExcel[index].createLeaveText
              ? dataToExcel[index].createLeaveText
              : "-",
            ผู้บังคับบัญชา: dataToExcel[index].managerLV1Name
              ? dataToExcel[index].managerLV1Name
              : "-",
            "E-mail ผู้บังคับบัญชา": dataToExcel[index].emailManagerLV1
              ? dataToExcel[index].emailManagerLV1
              : "-",
            "ผลอนุมัติ ผู้บังคับบัญชา":
              dataToExcel[index].isActive === 0 ?
                "ถอนรายการ" : dataToExcel[index].isApprove === 1 ?
                    "อนุมัติ" : "ไม่อนุมัติ",
            "วันที่อนุมัติ ผู้บังคับบัญชา":
              dataToExcel[index].isApprove === null
                ? ""
                : dataToExcel[index].approveDateText,
          };
          elementList.push(element);
        }
      } else {
        const element = {
          รหัสพนักงาน: "",
          "ชื่อ-สกุล": "",
          ตำแหน่ง: "",
          ประเภทคำขอ: "",
          เวลาเริ่มต้น: "",
          เวลาสิ้นสุด: "",
          "จำนวนที่ใช้ไป (วัน)": "",
          เหตุผล: "",
          วันเวลาที่ทำรายการ:"",
          ผู้บังคับบัญชา: "",
          "E-mail ผู้บังคับบัญชา": "",
          "ผลอนุมัติ ผู้บังคับบัญชา": "",
          "วันที่อนุมัติ ผู้บังคับบัญชา": ""
        };
        elementList.push(element);
      }
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "อนุมัติแล้ว");
    XLSX.writeFile(
      workbook,
      `รายการ${
        view === "time"
          ? "รับรองเวลทำงาน"
          : view === "ot"
          ? "ทำงานล่วงเวลา"
          : "ลางาน"
      }.xlsx`,
      { type: "file" }
    );
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between"  alignItems="flex-end" style={{ marginBottom: 8 }}>
            <Grid item>
                <Typography variant="h4" style={{ paddingTop: 8 }}>
                {t("History_Status_Report")}
                </Typography>
            </Grid>
            <Grid item className="displayFlexEnd">
                <ButtonBlue
                    variant="contained"
                    startIcon={<TextSnippetIcon />}
                    onClick={() => props.history.push("/admin/approve-list")}
                >{t("Status_Report")}</ButtonBlue>
            </Grid>
        </Grid>
        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleChangeView}
          aria-label="view"
          size="small"
        >
          <ToggleButton
            name="status"
            value="time"
            aria-label="time"
            size="small"
            style={{ minWidth: 150, marginRight: 12 }}
          >
            {t("requestWorkingTime")}
          </ToggleButton>
          <ToggleButton
            name="status"
            value="ot"
            aria-label="ot"
            size="small"
            style={{ minWidth: 150, marginRight: 12 }}
          >
            {t("OtRequest")}
          </ToggleButton>
          <ToggleButton
            name="status"
            value="leave"
            aria-label="leave"
            size="small"
            style={{ minWidth: 150, marginRight: 12 }}
          >
            {t("LeaveRequest")}
          </ToggleButton>
        </StyledToggleButtonGroup>
        <Grid
          container
          spacing={2}
          style={{ paddingBottom: 16 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("StartDate")}
              </Typography>
              <div className="search-date">
                {localStorage.getItem("language") === "en" ? (
                  <DatePickerEng
                    value={search.start}
                    name="start"
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        ["start"]: newValue,
                      });
                    }}
                  />
                ) : (
                  <DatePickerThai
                    value={search.start}
                    name="start"
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        ["start"]: newValue,
                      });
                    }}
                  />
                )}
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("EndDate")}
              </Typography>
              <div className="search-date">
              {localStorage.getItem("language") === "en" ? (
                  <DatePickerEng
                    minDate={search.start}
                    value={search.end}
                    name="end"
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        ["end"]: newValue,
                      });
                    }}
                  />
                ) : (
                  <DatePickerThai
                    minDate={search.start}
                    value={search.end}
                    name="end"
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        ["end"]: newValue,
                      });
                    }}
                  />
                )}
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {`${t("Search")} ${t("FullName")}`}
              </Typography>
              <FormControl fullWidth variant="filled">
                <StyledFilledInput
                  id="filled-sarch-name"
                  placeholder={`${t("Search")} ${t("FullName")}`}
                  name="searchName"
                  onChange={handleChangeSearch}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledBoxSearchButton>
              <div>
                <ButtonBlue
                  variant="contained"
                  startIcon={<SearchIcon />}
                  onClick={handleOnClickSearch}
                >
                  {t("Search")}
                </ButtonBlue>
              </div>
              <div>
                <ButtonBlue
                  onClick={excelExport}
                  variant="outlined"
                  startIcon={<DownloadRoundedIcon />}
                >
                  {t("Download")}
                </ButtonBlue>
              </div>
            </StyledBoxSearchButton>
          </Grid>
        </Grid>
        <div style={{ paddingBottom: 32 }}>
          {view === "time" ? (
            <TimeAttendance searchName={searchName} />
          ) : null}
          {view === "ot" ? (
            <OT searchName={searchName} />
          ) : null}
          {view === "leave" ? (
            <LeaveRequest searchName={searchName} />
          ) : null}
        </div>
      </Container>
    </StyledRoot>
  );
};

export default ApporveStatusListPage;