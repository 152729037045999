import React from "react";
import { Box, Grid, Icon, styled, Typography } from "@mui/material";
import CardData from "../../shared/dashboard/CardData";
import Utils from "../../../../utils";
import ChartAreaCustom from "../../shared/dashboard/ChartArea";
import dayjs from "dayjs";
import CardDashboard from "../../shared/dashboard/CardDashboard";
import { useSelector } from "react-redux";

const StyledRoot = styled(Box)({
  
})

const CostPanel = (props) => {

  const { month, year } = props;

  const {result: dashboardCost} = useSelector(state => state.dashboardCost);

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardData 
                    title="พนักงาน" 
                    dataValue={Utils.numberWithCommasWithoutDot(dashboardCost.employeeTotal)}
                    dataUnit="คน"
                    dynamicFontSize
                    titleIcon={
                      <Icon baseClassName="fal" className="fa-users" color="primary" style={{display: "inline-table" }}/>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardData
                    title="รายจ่ายเงินเดือน"
                    dataValue={Utils.numberWithCommas(dashboardCost.salaryTotal)}
                    dataUnit="บาท"
                    dynamicFontSize
                    titleIcon={
                      <Icon className="fal fa-sack-dollar" color="warning" style={{display: "inline-table", color: "#ffcd38"}}/>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardData 
                    title="รายจ่ายค่าล่วงเวลา"
                    dataValue={Utils.numberWithCommas(Object.keys(dashboardCost.otTotal).reduce((a,b) => a + dashboardCost.otTotal[b].payTotal, 0))}
                    dataUnit="บาท"
                    dynamicFontSize
                    titleIcon={
                      <Icon className="fal fa-hand-holding-usd" color="warning" style={{display: "inline-table", color: "#ffcd38"}}/>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CardData 
                title="รวมรายจ่าย"
                dataValue={Utils.numberWithCommas(Number((Object.keys(dashboardCost.otTotal).reduce((a, b) => a + dashboardCost.otTotal[b].payTotal, 0) + dashboardCost.salaryTotal).toFixed(2)))}
                dataUnit="บาท"
                dynamicFontSize
                titleIcon={
                  <Icon className="fal fa-search-dollar" color="warning" style={{display: "inline-table", color: "#ffcd38"}}/>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={12}>
          <CardDashboard>
            <Typography marginBottom="16px">รายจ่ายเงินเดือน (รายปี {year})</Typography>
            <ChartAreaCustom
              series={[
                {
                  name: "รายจ่าย",
                  data: dashboardCost.salaryTotalAllYear.map(s => {return {x: dayjs().month(s.month-1).format("MMM"), y: s.salaryTotal}})
                }
              ]}
            />
          </CardDashboard>
        </Grid> */}

        {/* <Grid item xs={12}>
          <CardDashboard>
            <Typography marginBottom="16px">รายจ่ายค่าล่วงเวลา (รายปี {year})</Typography>
            <ChartAreaCustom
              series={[
                {
                  name: "รายจ่าย",
                  data: dashboardCost.otTotalAllYear.map(s => {return {x: dayjs().month(s.month-1).format("MMM"), y: s.payTotal}})
                }
              ]}
            />
          </CardDashboard>
        </Grid> */}
      </Grid>
    </StyledRoot>
  )
}

export default CostPanel;