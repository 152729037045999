import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  Container,
  Divider,
  Grid,
  Modal,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import CardStyle from "../../../shared/general/Card";

//import DrawerBranch from "./drawerBranch";
import { useDispatch, useSelector } from "react-redux";

import { getCompanyProfile } from "../../../../../actions/company";

import ItemTable from "./ItemTable";
import DrawerBranch from "./DrawerBranch";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#f1f4f9",
  paddingBottom: 36,
  // ["@media (min-width:1200px)"]:{
  //   paddingLeft: 24,
  //   paddingRight: 24
  // }
});

const StyledContent = styled("div")({
  padding: 36,
  paddingTop: 24,
  "& .part-one": {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 8,
    "& .MuiAvatar-root": {
      width: 160,
      height: 160,
      fontSize: 42,
      marginRight: 36,
      backgroundColor: "white",
      color: "inherit",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
      "& i": {
        fontSize: 120,
        color: "inherit",
      },
    },
    "& .detail": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "& .start": {
        "& .company-name": {
          marginBottom: 12,
        },
        "& .company-industry": {
          marginBottom: 12,
        },
        "& div": {
          marginRight: 32,
          ["@media only screen and (max-width: 600px)"]: {
            marginRight: 0,
          },
        },
      },
    },
    "& .MuiTypography-caption": {
      fontSize: 14,
    },
  },
  "& .MuiDivider-root": {
    margin: "16px 0px",
    borderWidth: "0px 0px thin",
    borderColor: "rgba(145, 158, 171, 0.24)",
    borderStyle: "dashed",
  },
  "& .part-two": {
    marginBottom: 16,
    "& .wrap-item": {
      "& .label": {
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 4,
        "& span": {
          marginLeft: 16,
        },
      },
      "& .value": {
        fontSize: 18,
        marginLeft: 32,
      },
      "& .fal": {
        marginRight: 8,
      },
    },
  },
  "& .topic": {
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 8,
  },
  "& .MuiTabs-root": {
    marginBottom: 16,
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },
  "& .part-three": {
    "& .branch-count": {
      marginLeft: 32,
    },
  },
});

const StyledOpenJob = styled("div")({
  marginTop: 24,
  "& .openjob-header": {
    marginBottom: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-card": {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    columnGap: 12,
    ["@media only screen and (max-width: 1200px)"]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    ["@media only screen and (max-width: 600px)"]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
});

const StyledBranchContent = styled("div")({
  // padding: 16,
  paddingLeft: 16,
  // borderRadius: 20,
  // border: "1px solid #ececec",
  "& .branch-name": {
    fontSize: 18,
  },
  "& i": {
    marginRight: 8,
  },
  "& .contact-container": {
    display: "flex",
    flexFlow: "row wrap",
    "& .contact-wrap": {
      marginRight: 16,
    },
  },
});

const ContractDetailCompany = (props) => {
  let { selected } = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );

  const [isOpenModalBranchCompany, setIsOpenModalBranchCompany] =
    useState(false);

  const [branch, setBranch] = useState([]);

  useEffect(() => {
    console.log(selected);
    dispatch(getCompanyProfile(selected));
  }, []);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        {companyProfile && (
          <Fragment>
            <Typography variant="h4" style={{ padding: "24px 0" }}>
              {t("CompanyInfo")}
            </Typography>
            <CardStyle>
              <StyledContent>
                <div className="part-one">
                  <Avatar
                    variant="rounded"
                    src={
                      companyProfile.companyImage
                        ? `${process.env.REACT_APP_API_URL}image/company/${companyProfile.companyImage}`
                        : ""
                    }
                  >
                    <img src="/assets/logo_default.png" />
                  </Avatar>
                  <div className={`detail`}>
                    <div className={`start`}>
                      <div className="company-name">
                        {/* <Typography variant="caption" color="text.secondary">บริษัท</Typography> */}
                        <Typography variant="h5">
                          {companyProfile.companyName}
                        </Typography>
                      </div>
                      <div className="company-industry">
                        <Typography variant="caption" color="text.secondary">
                          {t("BusinessGroup")}
                        </Typography>
                        <Typography variant="h5">
                          {companyProfile.industry || "-"}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="caption" color="text.secondary">
                          {t("OrganizationSize")}
                        </Typography>
                        <Typography variant="h5">{`${t("Large")}`}</Typography>
                      </div>
                    </div>
                    <div className={`end`}>
                      <div>
                        <Typography variant="caption" color="text.secondary">
                          {t("JobOpenings")}
                        </Typography>
                        <Typography variant="h4" align="right">
                          {companyProfile.count || "0"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider
                  style={{
                    margin: "16px 0px",
                    borderWidth: "0px 0px thin",
                    borderColor: "rgba(145, 158, 171, 0.24)",
                    borderStyle: "dashed",
                  }}
                />
                <div className="part-two">
                  <div className="wrap-item">
                    <Typography className="label" color="text.secondary">
                      <i className="far fa-map-marker-alt"></i>
                      <span>{t("Address")}</span>
                    </Typography>
                    <Typography className="value">{`เลขที่ 99 ถนนตัดเก่า แขวงบางครั้ง เขตฐานทัพ 10100 กรุงเทพมหานคร ประเทศไทย`}</Typography>
                  </div>
                </div>

                <div className="part-three">
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div>
                      <Typography className="label" color="text.secondary">
                        <i className="fa-regular fa-building"></i>
                        <span style={{ marginLeft: 14 }}>
                          {t("CompanyBranch")}
                        </span>
                      </Typography>
                      <Typography className="branch-count">{`${
                        branch.length || 0
                      } ${t("Branch")}`}</Typography>
                    </div>
                    <ButtonBlue
                      startIcon={
                        <i className="fa-regular fa-magnifying-glass"></i>
                      }
                      variant="outlined"
                      onClick={() => {
                        setIsOpenModalBranchCompany(true);
                      }}
                    >
                      {t("ViewAllBranches")}
                    </ButtonBlue>
                  </Grid>
                  <Grid container spacing={2}>
                    {branch.length > 1 ? (
                      <></>
                    ) : (
                      <>
                        {branch.map((b, index) => (
                          <Grid key={index} item xs={12}>
                            <StyledBranchContent>
                              <Typography className="branch-name" variant="h7">
                                {b.branchName}
                              </Typography>
                              <Typography color="text.secondary">
                                <i className="far fa-map-marker-alt"></i>
                                <span>{`${b.address}`}</span>
                              </Typography>
                              <div className="contact-container">
                                <div className="contact-wrap">
                                  <Typography>
                                    <i className="fa-regular fa-user-headset"></i>
                                    <span>{`${b.mainContactName}`}</span>
                                  </Typography>
                                </div>
                                <div className="contact-wrap">
                                  <i className="fa-regular fa-phone"></i>
                                  <span>{`${b.mainContactPhone}`}</span>
                                </div>
                                <div className="contact-wrap">
                                  <i className="fa-regular fa-envelope"></i>
                                  <span>{`${b.mainContactEmail}`}</span>
                                </div>
                              </div>
                            </StyledBranchContent>
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </div>
              </StyledContent>
            </CardStyle>

            <div style={{ marginBottom: 24 }}></div>

            <CardStyle>
              <StyledContent>
                <CompanyInfoTab company={companyProfile} />
              </StyledContent>
            </CardStyle>

            <div style={{ marginBottom: 24 }}></div>
          </Fragment>
        )}
      </Container>

      {isOpenModalBranchCompany && (
        <DrawerBranch
          open={isOpenModalBranchCompany}
          onClose={() => {
            setIsOpenModalBranchCompany(false);
          }}
        />
      )}
    </StyledRoot>
  );
};

const CompanyInfoTab = (props) => {
  const { company } = props;
  const { t, i18n } = useTranslation();

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs value={tabValue} onChange={handleChange}>
        <Tab label={`${t("Contract")}`} />
        <Tab label={`${t("ContactInfo")}`} />
        <Tab label={`${t("About")}`} />
      </Tabs>

      {tabValue === 0 && <ItemTable />}

      {tabValue === 2 && (
        <Fragment>
          <Typography
            className="topic"
            style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
          >
            {t("About")}
          </Typography>
          <Typography>{company.companyAbout || "-"}</Typography>

          <Typography
            className="topic"
            style={{
              fontWeight: 600,
              fontSize: 18,
              marginBottom: 8,
              marginTop: 16,
            }}
          >
            {t("Benefits")}
          </Typography>
          <Typography>{company.companyBenefits || "-"}</Typography>
        </Fragment>
      )}

      {tabValue === 1 && (
        <Fragment>
          <Typography
            className="topic"
            style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
          >
            {t("ContactInfo")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="text.secondary">
                {t("ContactName")}
              </Typography>
              <Typography>{company.mainContactName || "-"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="text.secondary">
                {t("PhoneNumber")}
              </Typography>
              <Typography>{company.mainContactPhone || "-"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="text.secondary">
                {t("Email")}
              </Typography>
              <Typography>{company.mainContactEmail || "-"}</Typography>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </div>
  );
};

export default ContractDetailCompany;
