import { httpClient } from "./httpClient";

const getAllBillingCompany = () => {
  return httpClient.get(`/company/billing`);
};

const getBillingDetail = (idPayrun) => {
  return httpClient.get(`/billing/${idPayrun}`);
};

const getBillingAllCompanysByVendor = (monthYear) => {
  return httpClient.get(`/billing-vendor/${monthYear}`);
};

const getBillingAllVendorsByCompany = (monthYear) => {
  return httpClient.get(`/billing-company/${monthYear}`);
};

const getBillingAllEmployeesByBilling = (monthYear, idBilling) => {
  return httpClient.get(`/billing-vendor-employees/${monthYear}/${idBilling}`);
};

const editBillingDetail = (data) => {
  return httpClient.put(`/billing-vendor-employee/edit`, data);
};

const sendBilling = (idBilling) => {
  return httpClient.put(`/billing/send-billing/${idBilling}`);
};

const uploadFileBilling = (data, idBilling) => {
  return httpClient.put(`billing/upload-file?id=` + idBilling, data);
};

const deletedFileBilling = (idBilling) => {
  return httpClient.delete('billing/delete-file?id=' + idBilling);
};

const getAllAdditionsByVender = (query) => {
  return httpClient.get(`billing/allAdditions`, { params: query });
};

const addBillingAddition = (query) => {
  return httpClient.post(`billing/addition-billing`, query);
};

const updatedLumpSumBilling = (idBilling) => {
  return httpClient.put(`billing/send-lumpsum-billing/${idBilling}`);
};


export default {
  getAllBillingCompany,
  getBillingDetail,
  getBillingAllCompanysByVendor,
  getBillingAllVendorsByCompany,
  getBillingAllEmployeesByBilling,
  editBillingDetail,
  sendBilling,
  uploadFileBilling,
  deletedFileBilling,
  getAllAdditionsByVender,
  addBillingAddition,
  updatedLumpSumBilling
};
