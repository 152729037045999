import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CardStyle from "../../../../shared/general/Card";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import PDFFile from "../asset/PND91.pdf";
import FontFile from "../asset/Sarabun-Medium.ttf";

const StyleTextField = styled(TextField)({
  width: "100%",
  "& .MuiInputLabel-root": {
    color: "#637381",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
});

const mockData = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
];

const departmentData = [
  { label: "HR", year: 1994 },
  { label: "Logistic", year: 1972 },
  { label: "Accounting", year: 1974 },
  { label: "Engineering", year: 2008 },
];

function Index() {
  const [filters, setFilters] = useState({
    date: new Date(),
  });
  const [pdfInfo, setPdfInfo] = useState([]);

  async function modifyPdf() {
    const url = PDFFile;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    //Custom font
    const urlFont = FontFile;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();
    //tax year
    firstPage.drawText("2565", {
      x: 130,
      y: 768,
      size: 17,
      fontWeight: 600,
    });
    //id card
    firstPage.drawText(`1`, { x: 112, y: 733, size: 11 });
    firstPage.drawText(`2  3  4  5`, { x: 130, y: 733, size: 11 });
    firstPage.drawText(`2  3  4  5  6`, { x: 184, y: 733, size: 11 });
    firstPage.drawText(`7  8`, { x: 250, y: 733, size: 11 });
    firstPage.drawText(`9`, { x: 280, y: 733, size: 11 });
    //birthdate
    firstPage.drawText(`5`, { x: 175, y: 708, size: 8 });
    firstPage.drawText(`กรกฏาคม`, {
      x: 198,
      y: 708,
      size: 8,
      font: customFont,
    });
    firstPage.drawText(`1995`, { x: 245, y: 708, size: 8 });
    //first name
    firstPage.drawText(`นาย สรพงษ์`, {
      x: 50,
      y: 696,
      size: 8,
      font: customFont,
    });
    //last name
    firstPage.drawText(`ชาตรี`, {
      x: 65,
      y: 648,
      size: 8,
      font: customFont,
    });
    //address
    firstPage.drawText(`99/9`, { x: 51, y: 612, size: 8 });
    firstPage.drawText(`เล้าหมู`, {
      x: 155,
      y: 612,
      size: 8,
      font: customFont,
    });
    firstPage.drawText(`ทุ่งเพชร`, {
      x: 50,
      y: 592,
      size: 8,
      font: customFont,
    });
    firstPage.drawText(`บ่อเงิน`, {
      x: 215,
      y: 592,
      size: 8,
      font: customFont,
    });
    firstPage.drawText(`นาทอง`, {
      x: 75,
      y: 572,
      size: 8,
      font: customFont,
    });
    firstPage.drawText(`ระยอง`, {
      x: 216,
      y: 572,
      size: 8,
      font: customFont,
    });
    //PIN code
    firstPage.drawText(`9`, {
      x: 80,
      y: 552,
      size: 8,
    });
    firstPage.drawText(`9`, {
      x: 91,
      y: 552,
      size: 8,
    });
    firstPage.drawText(`9`, {
      x: 102,
      y: 552,
      size: 8,
    });
    firstPage.drawText(`9`, {
      x: 114,
      y: 552,
      size: 8,
      font: customFont,
    });
    firstPage.drawText(`9`, {
      x: 125,
      y: 552,
      size: 8,
    });
    //status
    firstPage.drawText(`X`, {
      x: 95,
      y: 496,
      size: 8,
    });

    const secondPage = pages[1];
    secondPage.drawText(`9`, {
      x: 102,
      y: 805,
      size: 11,
    });
    secondPage.drawText(`9  9  9  9`, {
      x: 120,
      y: 805,
      size: 11,
    });
    secondPage.drawText(`9  9  9  9  9`, {
      x: 174,
      y: 805,
      size: 11,
    });
    secondPage.drawText(`9  9`, {
      x: 240,
      y: 805,
      size: 11,
    });
    secondPage.drawText(`9`, {
      x: 270,
      y: 805,
      size: 11,
    });
    //Salary
    secondPage.drawText(`9  9  9   9  9`, {
      x: 501,
      y: 741,
      size: 10,
    });
    secondPage.drawText(`0  0  0   0  0`, {
      x: 501,
      y: 726,
      size: 10,
    });
    secondPage.drawText(`9  9  9   9  9`, {
      x: 501,
      y: 712,
      size: 10,
    });
    secondPage.drawText(`1  0  0   0  0`, {
      x: 501,
      y: 680,
      size: 10,
    });
    secondPage.drawText(`1  0  0   0  0`, {
      x: 501,
      y: 680,
      size: 10,
    });
    secondPage.drawText(`1  0  0   0  0`, {
      x: 501,
      y: 665,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 635,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 620,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 590,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 575,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 560,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 545,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 530,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 515,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 500,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 457,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 428,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 398,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 383,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 368,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 353,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 282,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 265,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 250,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 218,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 170,
      size: 10,
    });
    secondPage.drawText(`9  9  9   0  0`, {
      x: 501,
      y: 155,
      size: 10,
    });

    const thirdPage = pages[2];
    thirdPage.drawText(`X`, {
      x: 294,
      y: 807,
      size: 10,
    });
    //id card
    thirdPage.drawText(`1`, {
      x: 111,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`2`, {
      x: 130,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`3`, {
      x: 141,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`4`, {
      x: 154,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`5`, {
      x: 165,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`6`, {
      x: 183,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`7`, {
      x: 195,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`8`, {
      x: 207,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`8`, {
      x: 219,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`9`, {
      x: 231,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`9`, {
      x: 250,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`9`, {
      x: 261,
      y: 771,
      size: 10,
    });
    thirdPage.drawText(`9`, {
      x: 280,
      y: 771,
      size: 10,
    });
    //Topic 1.
    thirdPage.drawText(`6  0  0  0  0  0`, {
      x: 498,
      y: 720,
      size: 9,
    });
    thirdPage.drawText(`0 0`, {
      x: 559,
      y: 720,
      size: 9,
    });
    //Topic 2.
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 708,
      size: 9,
    });
    //Topic 3.
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 695,
      size: 9,
    });
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 635,
      size: 9,
    });
    //Topic 4.
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 563,
      size: 9,
    });
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 528,
      size: 9,
    });
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 492,
      size: 9,
    });
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 455,
      size: 9,
    });
    //Topic 5.
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 431,
      size: 9,
    });
    //Topic 6.
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 420,
      size: 9,
    });
    //Topic 7.
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 336,
      size: 9,
    });
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 323,
      size: 9,
    });
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 312,
      size: 9,
    });
    //Topic 8
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 300,
      size: 9,
    });
    //Topic 9
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 287,
      size: 9,
    });
    //Topic 10
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 275,
      size: 9,
    });
    //Topic 11
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 263,
      size: 9,
    });
    //Topic 12
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 252,
      size: 9,
    });
    //Topic 12
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 240,
      size: 9,
    });
    //Topic 13
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 228,
      size: 9,
    });
    //Topic 14
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 216,
      size: 9,
    });
    //Topic 15
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 204,
      size: 9,
    });
    //Topic 16
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 191,
      size: 9,
    });
    //Topic 17
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 168,
      size: 9,
    });
    //Topic 18
    thirdPage.drawText(`0   0 0`, {
      x: 546,
      y: 155,
      size: 9,
    });

    //generate pdf
    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    setPdfInfo(docUrl);
  }

  const handleOnSubmit = () => {
    modifyPdf();
  };

  return (
    <div className="page">
      <Container maxWidth="lg">
        <Box sx={{ marginTop: 1, marginBottom: 2 }}>
          <Typography variant="h4">ภงด. 91</Typography>
        </Box>
        <CardStyle>
          <Box sx={{ padding: "20px" }}>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  options={departmentData}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <StyleTextField {...params} label="แผนก" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  options={mockData}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <StyleTextField {...params} label="พนักงาน" />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="ปีภาษี"
                    views={["year"]}
                    value={filters.date}
                    onChange={(newValue) => {
                      setFilters({ ...filters, date: newValue });
                    }}
                    renderInput={(params) => <StyleTextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 4, marginBottom: 2 }} />
            <Grid container justifyContent="flex-end">
              <Grid item>
                <ButtonBlue variant="outlined" onClick={handleOnSubmit}>
                  สร้างฟอร์ม
                </ButtonBlue>
              </Grid>
            </Grid>
          </Box>
        </CardStyle>
        {pdfInfo.length ? (
          <div style={{ marginTop: "24px", marginBottom: "24px" }}>
            <CardStyle>
              <Box sx={{ padding: 3 }}>
                <iframe
                  src={pdfInfo}
                  type="application/pdf"
                  style={{
                    width: "100%",
                    height: "1000px",
                    borderStyle: "none",
                    borderRadius: "5px",
                  }}
                />
              </Box>
            </CardStyle>
          </div>
        ) : null}
      </Container>
    </div>
  );
}

export default Index;
