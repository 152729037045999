import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Grid,
  styled,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { th } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import TimePicker from "../../shared/general/TimePicker";

import { updateRequestTime } from "../../../../actions/requestTime";
import { getAttendanceCompanyByIdEmp } from "../../../../actions/attendance";
import { getPayrollSetting } from "../../../../actions/paytypes";
import { getEmployeesProfileByIdForCompany } from "../../../../actions/employee";
import { getAllRequestTimeBy } from "../../../../actions/requestTime";
import { getHoliday } from "../../../../actions/holiday";
import StatusRequest from "../../shared/general/stausRequest";
import CalculateOTFunction from "./calculateOT";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  ["@media only screen and (max-width: 375px)"]: {
    width: 320,
  },
  "& .wrap-status": {
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      marginRight: 8,
    },
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledWrapHoursOT = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .label": {
    fontWeight: 500,
  },
});

const DialogNewRequest = (props) => {
  const {
    values,
    open,
    handleCloseDialog,
    handleChangeAlertType,
    handleOpenAlert,
    // handleOpenDialogConfirmDelete,
    searchDate,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  dayjs.locale("en");
  dayjs.extend(customParseFormat);
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  const { user: authUser } = useSelector((state) => state.auth);
  const { result: holidayList } = useSelector((state) => state.holiday);
  const { isFetching: isFetchingAttendanceList, result: attendanceList } =
    useSelector((state) => state.attendance);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const [formInputData, setFormData] = useState(null);
  const [mode, setMode] = useState("read");
  const [isSubmit, setIsSubmit] = useState(false);
  const [payrollSetting, setPayrollSetting] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [attendanceListState, setAttendanceListState] = useState([]);
  const [attendanceNow, setAttendanceNow] = useState(null);

  useEffect(() => {
    dispatch(getPayrollSetting());
  }, []);

  useEffect(() => {
    if (values) {
      if (values.startText && values.endText) {
        let rangeStartDate = dayjs(
          values.startText,
          "DD/MM/YYYY HH:mm"
        ).subtract(6, "day");
        dispatch(
          getAttendanceCompanyByIdEmp(
            {
              start: rangeStartDate,
              end: dayjs(values.endText, "DD/MM/YYYY HH:mm"),
            },
            values.idCompany,
            values.idEmp
          )
        );
        dispatch(
          getHoliday(values.idCompany, {
            start: dayjs(rangeStartDate).format("YYYY-MM-DD"),
            end: dayjs(values.endText, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD"),
          })
        );
      }
      dispatch(getEmployeesProfileByIdForCompany(values.idEmp, "manager"));
      setFormData({
        idRequestTime: values.idRequestTime,
        idRequestType: values.idRequestType,
        startDate: dayjs(values.startText, "DD/MM/YYYY HH:mm"),
        startMinute: dayjs(values.startText, "DD/MM/YYYY HH:mm").format("m"),
        startHours: dayjs(values.startText, "DD/MM/YYYY HH:mm").format("H"),
        endDate: dayjs(values.endText, "DD/MM/YYYY HH:mm"),
        endMinute: dayjs(values.endText, "DD/MM/YYYY HH:mm").format("m"),
        endHours: dayjs(values.endText, "DD/MM/YYYY HH:mm").format("H"),
        managerLV1Id: values.managerLV1Id,
        otherReason: values.otherReason,
        idRequestReason: values.idRequestReason,
        isDoubleApproval: values.isDoubleApproval,
        approvalLevel: values.approvalLevel,
        managerLV1Name: values.managerLV1Name,
        isManagerLV1Approve: values.isManagerLV1Approve,
        managerLV1ApproveDate: values.managerLV1ApproveDate
          ? dayjs(values.managerLV1ApproveDate).format("DD/MM/BBBB HH:mm")
          : null,
        positionManagerLV1: values.positionManagerLV1,
        managerLV2Name: values.managerLV2Name,
        isManagerLV2Approve: values.isManagerLV2Approve,
        managerLV2ApproveDate: values.managerLV2ApproveDate
          ? dayjs(values.managerLV2ApproveDate).format("DD/MM/BBBB HH:mm")
          : null,
        positionManagerLV2: values.positionManagerLV2,
        createDateText: values.createDateText,
      });
      setMode("Edit");
    }
  }, [values]);

  useEffect(() => {
    if (payrollSettingList && values) {
      const findPayroll = payrollSettingList.find(
        (x) => x.idCompany === values.idCompany
      );
      setPayrollSetting(findPayroll ? findPayroll : payrollSettingList[0]);
    }
  }, [payrollSettingList]);

  useEffect(() => {
    if (attendanceList && attendanceList.length > 0) {
      let compensateDateList = [];
      let newAttendanceList = attendanceList.map((attendance) => {
        let tempAttendance = { ...attendance };
        if (
          attendance.holiday &&
          attendance.pattern.isWorkingDay === 0 &&
          attendance.pattern.idWorkingType === 1
        ) {
          compensateDateList.push(attendance.holiday);
        }

        if (
          attendance.pattern.isWorkingDay === 1 &&
          !attendance.holiday &&
          compensateDateList.length > 0 &&
          attendance.pattern.idWorkingType === 1
        ) {
          tempAttendance.compensateDate = compensateDateList.shift();
        }

        return tempAttendance;
      });
      setAttendanceListState(newAttendanceList);
      let attendentOfValues =
        newAttendanceList.find(
          (attendance) =>
            attendance.date === dayjs(values.workDate).format("YYYY-MM-DD")
        ) || null;
      if (attendentOfValues) {
        setAttendanceNow(attendentOfValues);
      }
    }
  }, [attendanceList]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formInputData,
      [name]: value,
    });
    setIsChanged(true);
  };

  const handleChangeTime = (name, unit, periodTime, value) => {
    setFormData({
      ...formInputData,
      [name]: value,
    });
    setIsChanged(true);
  };

  const calculationOT = () => {
    const findIndexToday = attendanceListState.findIndex((x) => {
      return (
        x.date ===
        dayjs(values.workDateText, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD")
      );
    });
    const valuesCurrent = attendanceListState[findIndexToday];
    
    if(valuesCurrent.pattern.idCompany === 42){
      return CalculateOTFunction.calculatorOTProtech(
        valuesCurrent, formInputData, employeeProfile, attendanceListState
      );
    }else if(valuesCurrent.pattern.idCompany === 143){
      return CalculateOTFunction.calculatorOTMidnight(
        valuesCurrent, formInputData, employeeProfile, attendanceListState
      );
    }else{
      return CalculateOTFunction.calculatorOTStandard(
        valuesCurrent, formInputData, employeeProfile, attendanceListState
      );
    }
  };

  const handleSaveRequestTime = async () => {
    if (disabledBtn()) {
      alert("ข้อมูลไม่ถูกต้อง");
    } else {
      if (isSubmit === false) {
        setIsSubmit(true);
        const startDate = dayjs(formInputData.startDate)
          .set("hour", formInputData.startHours)
          .set("minute", formInputData.startMinute)
          .set("second", 0);
        const endDate = dayjs(formInputData.endDate)
          .set("hour", formInputData.endHours)
          .set("minute", formInputData.endMinute)
          .set("second", 0);

        const formData = {
          workDate: attendanceNow.date,
          start: startDate.format("YYYY-MM-DD HH:mm:ss"),
          end: endDate.format("YYYY-MM-DD HH:mm:ss"),
          idEmp: values.idEmp,
          isDoubleApproval: 0,
          approvalLevel: 1,
          xOT: 0,
          xOTHoliday: 0,
          xWorkingDailyHoliday: 0,
          xWorkingMonthlyHoliday: 0,
          updateBy: authUser.id,
          updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          idRequestTime: values.idRequestTime,
          idRequestType: formInputData.idRequestType,
          idRequestReason: formInputData.idRequestReason,
          otherReason: formInputData.otherReason,
        };

        let summaryOT = calculationOT();

        formData.xOT = summaryOT.listOT.xOT;
        formData.xOTHoliday = summaryOT.listOT.xOTHoliday;
        formData.xWorkingDailyHoliday = summaryOT.listOT.xWorkingDailyHoliday;
        formData.xWorkingMonthlyHoliday =
          summaryOT.listOT.xWorkingMonthlyHoliday;
        formData.amountHours =
          summaryOT.listOT.xOT +
          summaryOT.listOT.xOTHoliday +
          summaryOT.listOT.xWorkingDailyHoliday +
          summaryOT.listOT.xWorkingMonthlyHoliday;

        if (formInputData.idRequestType === 2) {
          formData.isDoubleApproval = formData.xOTHoliday > 0 ? 1 : 0;
          formData.approvalLevel = formData.xOTHoliday > 0 ? 2 : 1;
        }

        let result = await dispatch(updateRequestTime(formData));
        if (result) {
          if (result.errorCode) {
            handleChangeAlertType(
              formInputData.idRequestType === 1
                ? "ขอรับรองเวลาทำงานซ้ำ"
                : "ขอทำงานล่วงเวลาซ้ำ"
            );
            handleOpenAlert(true);
          } else {
            handleChangeAlertType("success");
            handleOpenAlert(true);
            if (searchDate.start && searchDate.end) {
              dispatch(getAllRequestTimeBy("manager", searchDate));
            } else {
              dispatch(getAllRequestTimeBy("manager"));
            }
          }
          handleCloseDialog();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
          handleCloseDialog();
        }
      }
    }
  };

  const renderTitle = () => {
    let text = "";
    // if (values.idRequestTime) {
    if (values.idRequestType === 2) {
      text = "รายการขอทำงานล่วงเวลา";
    } else {
      text = "รายการขอรับรองเวลาทำงาน";
    }
    // } else {
    //   text = "เพิ่มรายการ";
    // }

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{text}</Typography>
        {/* {values.idRequestTime &&  */}
        {/* <DeleteForeverIcon 
            style={{ 
              color:"d32f2f",
              cursor:"pointer"
            }}
            onClick={handleOpenDialogConfirmDelete}
          /> */}
        {/* } */}
      </div>
    );
  };

  const renderManagerLV1 = () => {
    return (
      <Fragment>
        <Grid item xs={12} sm={4} style={{ display: "flex" }}>
          <StyledContentLabel>{t("NoNumber")} 1</StyledContentLabel>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>{formInputData.managerLV1Name}</Typography>
          <Typography color={"text.secondary"} variant="body2">
            {formInputData.positionManagerLV1}
          </Typography>
          <div className="wrap-status">
            <div style={{ width: 40 }}>
              <StatusRequest
                boxSize={30}
                fontSize={20}
                borderRadius={8}
                status={
                  formInputData.managerLV1ApproveDate === null
                    ? 2
                    : formInputData.managerLV1ApproveDate &&
                      formInputData.isManagerLV1Approve
                    ? 1
                    : 0
                }
                active={values.isActive}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 8,
              }}
            >
              <Typography>
                {formInputData.managerLV1ApproveDate === null
                  ? `${t("WaitingApproval")}`
                  : formInputData.managerLV1ApproveDate &&
                    formInputData.isManagerLV1Approve
                  ? `${t("Approve")}`
                  : `${t("Disapproval")}`}
              </Typography>
              {formInputData.managerLV1ApproveDate && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{
                    fontStyle: "italic",
                  }}
                >{`${formInputData.managerLV1ApproveDate}`}</Typography>
              )}
            </div>
          </div>
        </Grid>
      </Fragment>
    );
  };

  const renderManagerLV2 = () => {
    return (
      <Fragment>
        <Grid item xs={12} sm={4} style={{ display: "flex" }}>
          <StyledContentLabel>{t("NoNumber")} 2</StyledContentLabel>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>{formInputData.managerLV2Name}</Typography>
          <Typography color={"text.secondary"} variant="body2">
            {formInputData.positionManagerLV2}
          </Typography>
          <div className="wrap-status">
            <div style={{ width: 40 }}>
              <StatusRequest
                boxSize={30}
                fontSize={20}
                borderRadius={8}
                status={
                  formInputData.managerLV2ApproveDate === null
                    ? 2
                    : formInputData.managerLV2ApproveDate &&
                      formInputData.isManagerLV2Approve
                    ? 1
                    : 0
                }
                active={values.isActive}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 8,
              }}
            >
              <Typography>
                {formInputData.managerLV2ApproveDate === null
                  ? `${t("WaitingApproval")}`
                  : formInputData.managerLV2ApproveDate &&
                    formInputData.isManagerLV2Approve
                  ? `${t("Approve")}`
                  : `${t("Disapproval")}`}
              </Typography>
              {formInputData.managerLV2ApproveDate && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{
                    fontStyle: "italic",
                  }}
                >{`${formInputData.managerLV2ApproveDate}`}</Typography>
              )}
            </div>
          </div>
        </Grid>
      </Fragment>
    );
  };

  const renderHoursOT = () => {
    if (
      formInputData.startHours !== "" &&
      formInputData.startMinute !== "" &&
      formInputData.endHours !== "" &&
      formInputData.endMinute !== ""
    ) {
      let summaryOT = calculationOT();

      if (summaryOT.error.errorNoon) {
        return (
          <Typography
            variant="body2"
            style={{ color: "#f15e5e", fontWeight: 500 }}
          >
            {t("OTLunchBreakRequest")} {t("Incorrect")}
          </Typography>
        );
      } else if (summaryOT.error.errorTime) {
        return (
          <Typography
            variant="body2"
            style={{ color: "#f15e5e", fontWeight: 500 }}
          >
            {t("DateTimeIncorrect")}
          </Typography>
        );
      } else if (summaryOT.error.errorOnWorkingTime) {
        return (
          <Typography
            variant="body2"
            style={{ color: "#f15e5e", fontWeight: 500 }}
          >
            {t("OvertimeRequestedCantWorkingHours")}
          </Typography>
        );
      } else {
        let ObjectOt = {};
        Object.keys(summaryOT.listOT).map((k) => {
          if (summaryOT.listOT[k] > 0) {
            ObjectOt[payrollSetting[k]] =
              summaryOT.listOT[k] / 60 + (ObjectOt[payrollSetting[k]] || 0);
          }
        });

        return (
          <>
            {Object.keys(ObjectOt).map((k, index) => {
              return (
                <StyledWrapHoursOT key={`${k}_${ObjectOt[k]}_${index}`}>
                  <Typography
                    className="label"
                    variant="subtitle1"
                    color="text.third"
                  >
                    OT x {k}
                  </Typography>
                  <Typography variant="h6">
                    {parseFloat(ObjectOt[k]).toFixed(2)} ชม.
                  </Typography>
                </StyledWrapHoursOT>
              );
            })}
          </>
        );
      }
    }
  };

  const disabledBtn = () => {
    if (formInputData && attendanceListState) {
      if (
        formInputData.startHours !== "" &&
        formInputData.startMinute !== "" &&
        formInputData.endHours !== "" &&
        formInputData.endMinute !== "" &&
        formInputData.idRequestReason !== ""
      ) {
        const startDate = dayjs(formInputData.startDate)
          .set("hour", formInputData.startHours)
          .set("minute", formInputData.startMinute)
          .set("second", 0);
        const endDate = dayjs(formInputData.endDate)
          .set("hour", formInputData.endHours)
          .set("minute", formInputData.endMinute)
          .set("second", 0);

        const findIndexToday = attendanceListState.findIndex(
          (x) =>
            x.date ===
            dayjs(values.workDateText, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD")
        );
        if(
          (findIndexToday === (attendanceListState.length - 1)) || 
          (findIndexToday === 0)
        ){
          return true;
        }

        const valuesCurrent = attendanceListState[findIndexToday];

        const findDate = {
          start: `
            ${dayjs(valuesCurrent.date).format("YYYY-MM-DD")} ${
            valuesCurrent.pattern.timeIn
          }
          `,
          end: `
            ${dayjs(
              `${dayjs(valuesCurrent.date).format("YYYY-MM-DD")} ${
                valuesCurrent.pattern.timeIn
              }`,
              "YYYY-MM-DD HH:mm"
            )
              .add(valuesCurrent.pattern.workingHours, "minute")
              .format("YYYY-MM-DD HH:mm")}
          `,
        };
        let leaveHoliday = valuesCurrent.leave && valuesCurrent.leave.length > 0 && [...valuesCurrent.leave.map((v) => v.idLeaveType)].includes(9);
        const isWorkingDay =
          valuesCurrent.pattern.isWorkingDay === 1 &&
          !leaveHoliday &&
          !valuesCurrent.holiday &&
          !valuesCurrent.compensateDate;

        let valuesPrev = attendanceListState[findIndexToday - 1];
        let findPrevDate = {
          start: `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
            valuesPrev.pattern.timeIn
          }`,
          end: `${dayjs(
            `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
              valuesPrev.pattern.timeIn
            }`,
            "YYYY-MM-DD HH:mm"
          )
            .add(valuesPrev.pattern.workingHours, "minute")
            .format("YYYY-MM-DD HH:mm")}`,
        };
        let prevLeaveHoliday = valuesPrev.leave && valuesPrev.leave.length > 0 && [...valuesPrev.leave.map((v) => v.idLeaveType)].includes(9);
        let isWorkingPrevDay =
          valuesPrev.pattern.isWorkingDay === 1 &&
          !prevLeaveHoliday &&
          !valuesPrev.holiday &&
          !valuesPrev.compensateDate;

        let valuesNext = attendanceListState[findIndexToday + 1];
        let findNextDate = {
          start: `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
            valuesNext.pattern.timeIn
          }`,
          end: `${dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
              valuesNext.pattern.timeIn
            }`,
            "YYYY-MM-DD HH:mm"
          )
            .add(valuesNext.pattern.workingHours, "minute")
            .format("YYYY-MM-DD HH:mm")}`,
        };
        let nextLeaveHoliday = valuesNext.leave && valuesNext.leave.length > 0 && [...valuesNext.leave.map((v) => v.idLeaveType)].includes(9);
        let isWorkingNextDay =
          valuesNext.pattern.isWorkingDay === 1 &&
          !nextLeaveHoliday &&
          !valuesNext.holiday &&
          !valuesNext.compensateDate;

        let allHours = endDate.diff(startDate, "hours", true);

        if (formInputData && formInputData.idRequestType === 2) {
          if (
            startDate.isBefore(
              dayjs(
                `${dayjs(valuesCurrent.date).format("YYYY-MM-DD")} 00:00`,
                "YYYY-MM-DD HH:mm"
              )
            ) ||
            endDate.isSameOrAfter(
              dayjs(
                `${dayjs(valuesCurrent.date)
                  .add(2, "day")
                  .format("YYYY-MM-DD")} 00:00`,
                "YYYY-MM-DD HH:mm"
              )
            )
          ) {
            return true;
          }

          if (isWorkingPrevDay && startDate.isBefore(findPrevDate.end)) {
            return true;
          }

          if (isWorkingNextDay && endDate.isAfter(findNextDate.start)) {
            return true;
          }

          if (!(endDate.diff(startDate, "minute") > 0)) {
            return true;
          }

          if (valuesCurrent.pattern.idWorkingType === 2) {
            if (
              !(
                startDate.isBetween(
                  dayjs(
                    `${dayjs(valuesCurrent.date).format("YYYY-MM-DD")} 12:00`,
                    "YYYY-MM-DD HH:mm"
                  ),
                  dayjs(
                    `${dayjs(valuesCurrent.date).format("YYYY-MM-DD")} 13:00`,
                    "YYYY-MM-DD HH:mm"
                  ),
                  "minute",
                  "[)"
                ) &&
                endDate.isBetween(
                  dayjs(
                    `${dayjs(valuesCurrent.date).format("YYYY-MM-DD")} 12:00`,
                    "YYYY-MM-DD HH:mm"
                  ),
                  dayjs(
                    `${dayjs(valuesCurrent.date).format("YYYY-MM-DD")} 13:00`,
                    "YYYY-MM-DD HH:mm"
                  ),
                  "minute",
                  "(]"
                )
              ) &&
              formInputData.idRequestReason === 9
            ) {
              return true;
            } else if (
              (dayjs(startDate).isBetween(
                dayjs(findDate.start),
                dayjs(findDate.end),
                "minute",
                "[)"
              ) ||
                dayjs(endDate).isBetween(
                  dayjs(findDate.start),
                  dayjs(findDate.end),
                  "minute",
                  "(]"
                ) ||
                dayjs(findDate.start).isBetween(
                  dayjs(startDate),
                  dayjs(endDate),
                  "minute",
                  "[)"
                ) ||
                dayjs(findDate.end).isBetween(
                  dayjs(startDate),
                  dayjs(endDate),
                  "minute",
                  "(]"
                )) &&
              isWorkingDay &&
              formInputData.idRequestReason !== 9
            ) {
              return true;
            }

            if (
              startDate.isBetween(
                dayjs(
                  `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00`,
                  "YYYY-MM-DD HH:mm"
                ),
                dayjs(
                  `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00`,
                  "YYYY-MM-DD HH:mm"
                ),
                "minute",
                "[)"
              ) &&
              endDate.isBetween(
                dayjs(
                  `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 12:00`,
                  "YYYY-MM-DD HH:mm"
                ),
                dayjs(
                  `${dayjs(valuesNext.date).format("YYYY-MM-DD")} 13:00`,
                  "YYYY-MM-DD HH:mm"
                ),
                "minute",
                "(]"
              ) &&
              formInputData.idRequestReason !== 9
            ) {
              return true;
            }
          } else {
            if (
              (dayjs(startDate).isBetween(
                dayjs(findDate.start),
                dayjs(findDate.end),
                "minute",
                "[)"
              ) ||
                dayjs(endDate).isBetween(
                  dayjs(findDate.start),
                  dayjs(findDate.end),
                  "minute",
                  "(]"
                ) ||
                dayjs(findDate.start).isBetween(
                  dayjs(startDate),
                  dayjs(endDate),
                  "minute",
                  "[)"
                ) ||
                dayjs(findDate.end).isBetween(
                  dayjs(startDate),
                  dayjs(endDate),
                  "minute",
                  "(]"
                )) &&
              isWorkingDay
            ) {
              return true;
            }
          }
        } else {
          return false;
        }

        if (allHours > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <DrawerCustom
      title={renderTitle()}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
    >
      {values &&
      attendanceNow &&
      !isFetchingAttendanceList &&
      attendanceListState &&
      attendanceListState.length > 0 ? (
        <StyledRoot>
          <div style={{ marginBottom: 16 }}>
            <Typography>
              {t("WorkingDate")}:{" "}
              {dayjs(attendanceNow.date).format("D/MM/BBBB ")}
            </Typography>
            <Typography>
              {t("WorkingTime")}:{" "}
              {attendanceNow.pattern.idWorkingType === 1
                ? `${attendanceNow.pattern.nameShiftGroup}  (${attendanceNow.pattern.nameShift})`
                : `${attendanceNow.pattern.nameShiftGroup}`}
              <Typography
                component="span"
                color="text.third"
                style={{
                  fontStyle: "oblique",
                  fontWeight: "normal",
                }}
              >
                {attendanceNow.pattern.isWorkingDay === 1
                  ? ` (${attendanceNow.pattern.timeIn.slice(
                      0,
                      -3
                    )} - ${attendanceNow.pattern.timeOut.slice(0, -3)})`
                  : ` (${attendanceNow.pattern.nameShiftType})`}
              </Typography>
            </Typography>
          </div>

          {formInputData && (
            <Grid container spacing={2}>
              {formInputData.idRequestTime && (
                <Fragment>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      color={"text.third"}
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      {t("Approver")}
                    </Typography>
                  </Grid>
                  {formInputData.isDoubleApproval ? (
                    <Fragment>
                      {renderManagerLV1()}
                      {renderManagerLV2()}
                    </Fragment>
                  ) : formInputData.approvalLevel ? (
                    renderManagerLV1()
                  ) : (
                    renderManagerLV2()
                  )}
                </Fragment>
              )}
              {formInputData.idRequestTime && (
                <Fragment>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      color={"text.third"}
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      {t("Description")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography
                      color={"text.secondary"}
                      variant="body2"
                      style={{
                        fontStyle: "oblique",
                        textAlign: "right",
                        width: "100%",
                      }}
                    >
                      {`${t("CreateDate")}: ${formInputData.createDateText}`}
                    </Typography>
                  </Grid>
                </Fragment>
              )}
              <Grid
                item
                xs={12}
                sm={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <StyledContentLabel>{t("Type")}</StyledContentLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <StyledFormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="idRequestType"
                    value={formInputData.idRequestType}
                    onChange={handleChange}
                    inputProps={{ readOnly: mode === "read" }}
                    disabled={true}
                  >
                    <MenuItem value={1}>
                      {t("EmployeeCertificateRequest")}
                    </MenuItem>
                    <MenuItem value={2}>{t("OvertimeRequest")}</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <StyledContentLabel>{t("StartDate")}</StyledContentLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={th}>
                  <DatePicker
                    minDate={
                      new Date(
                        dayjs(attendanceNow.date)
                          .subtract(1, "day")
                          .format("YYYY-MM-DD")
                      )
                    }
                    maxDate={
                      new Date(
                        dayjs(attendanceNow.date)
                          .add(1, "day")
                          .format("YYYY-MM-DD")
                      )
                    }
                    readOnly={
                      formInputData.idRequestType === 1 || mode === "read"
                    }
                    inputFormat="DD/MM/YYYY"
                    value={formInputData.startDate}
                    onChange={(newDate) => {
                      setFormData({ ...formInputData, ["startDate"]: newDate });
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme style={{ width: "100%" }} {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <StyledContentLabel>{t("StartTime")}</StyledContentLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", marginRight: 4 }}>
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name="startHours"
                      unit="hour"
                      value={formInputData.startHours}
                      mode={mode}
                    />
                  </div>
                  <div style={{ width: "100%", marginLeft: 4 }}>
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name="startMinute"
                      unit="minute"
                      step="1"
                      value={formInputData.startMinute}
                      mode={mode}
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <StyledContentLabel>{t("EndDate")}</StyledContentLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={th}>
                  <DatePicker
                    minDate={
                      new Date(
                        dayjs(attendanceNow.date)
                          .subtract(1, "day")
                          .format("YYYY-MM-DD")
                      )
                    }
                    maxDate={
                      new Date(
                        dayjs(attendanceNow.date)
                          .add(1, "day")
                          .format("YYYY-MM-DD")
                      )
                    }
                    readOnly={mode === "read"}
                    inputFormat="DD/MM/YYYY"
                    value={formInputData.endDate}
                    onChange={(newDate) => {
                      setFormData({ ...formInputData, ["endDate"]: newDate });
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme style={{ width: "100%" }} {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <StyledContentLabel>{t("EndTime")}</StyledContentLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%", marginRight: 4 }}>
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name="endHours"
                      unit="hour"
                      value={formInputData.endHours}
                      mode={mode}
                    />
                  </div>
                  <div style={{ width: "100%", marginLeft: 4 }}>
                    <TimePicker
                      handleChangeTime={handleChangeTime}
                      name="endMinute"
                      unit="minute"
                      step="1"
                      value={formInputData.endMinute}
                      mode={mode}
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <StyledContentLabel>{t("Reason")}</StyledContentLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <StyledFormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="idRequestReason"
                    value={formInputData.idRequestReason}
                    onChange={handleChange}
                    inputProps={{ readOnly: mode === "read" }}
                  >
                    <MenuItem value={1}>{t("OperationalRequirement")}</MenuItem>
                    <MenuItem value={2}>{t("ShiftWorkOnDayOff")}</MenuItem>
                    {attendanceNow.pattern.idWorkingType === 2 && (
                      <MenuItem value={9}>{t("OTLunchBreakRequest")}</MenuItem>
                    )}
                    <MenuItem value={10}>StandBy On Call</MenuItem>
                    <MenuItem value={3}>{t("Training")}</MenuItem>
                    <MenuItem value={4}>{t("OtherCompanyActivities")}</MenuItem>
                    <MenuItem value={5}>{t("OffSiteWork")}</MenuItem>
                    <MenuItem value={6}>
                      {t("ProblemFingerprint")}/{t("ClockingMachineError")}
                    </MenuItem>
                    <MenuItem value={7}>{t("EmergencyCall")}</MenuItem>
                    <MenuItem value={8}>{t("OtherRT")}</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <StyledContentLabel>
                  {t("AdditionalReasons")}
                </StyledContentLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <StyledFormControl fullWidth>
                  <TextField
                    name="otherReason"
                    onChange={handleChange}
                    id="outlined-multiline-static"
                    multiline
                    rows={3}
                    value={formInputData.otherReason}
                    InputProps={{
                      readOnly: mode === "read",
                    }}
                  />
                </StyledFormControl>
              </Grid>
              {formInputData.idRequestType === 2 && (
                <Fragment>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <StyledContentLabel>{t("TotalHours")}</StyledContentLabel>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {renderHoursOT()}
                  </Grid>
                </Fragment>
              )}
            </Grid>
          )}

          <StyledFooter>
            <ButtonBlue
              className="cancel"
              color="secondary"
              variant="text"
              onClick={handleCloseDialog}
            >
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue
              disabled={disabledBtn() || isSubmit}
              variant="contained"
              onClick={handleSaveRequestTime}
            >
              {t("Save")}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      ) : (
        <div
          style={{
            width: 350,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>{t("LoadingData")}...</Typography>
        </div>
      )}
    </DrawerCustom>
  );
};

export default DialogNewRequest;
