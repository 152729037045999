import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Autocomplete,
  Box,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import CardStyle from "../../../../shared/general/Card";
import { styled } from "@mui/material/styles";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getDepartment } from "../../../../../../actions/department";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import PDFFile from "../asset/50tawi.pdf";
import FontFile from "../asset/Sarabun-Medium.ttf";
import ArabicNumberToText from "./ThaiNumbertoText";

const StyleTextField = styled(TextField)({
  width: "100%",
  "& .MuiInputLabel-root": {
    color: "#637381",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
});

const mockData = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
];

const departmentData = [
  { label: "HR", year: 1994 },
  { label: "Logistic", year: 1972 },
  { label: "Accounting", year: 1974 },
  { label: "Engineering", year: 2008 },
];

const payPeriodData = [{ label: "2021", label: "2022" }];

function Index() {
  const dispatch = useDispatch();
  const { result: departmentStore } = useSelector((state) => state.department);

  const [filters, setFilters] = useState({
    department: "",
    empId: 0,
    type: "payPeriod",
    payPeriod: "",
    payDateFrom: new Date(),
    payDateTo: new Date(),
    submitDate: new Date(),
  });

  const [pdfInfo, setPdfInfo] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  async function modifyPdf() {
    const url = PDFFile;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    //Custom font
    const urlFont = FontFile;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);
    const customFont = await pdfDoc.embedFont(fontBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();

    //content
    firstPage.drawText(`9999`, {
      x: 525,
      y: 782,
      size: 8,
    });
    firstPage.drawText(`9999`, {
      x: 525,
      y: 768,
      size: 8,
    });

    //company info
    firstPage.drawText(`บริษัท ABC จำกัด`, {
      x: 60,
      y: 730,
      size: 9,
      font: customFont,
    });
    firstPage.drawText(`1`, {
      x: 422,
      y: 732,
      size: 9,
    });
    firstPage.drawText(`2   3   4   5`, {
      x: 440,
      y: 732,
      size: 9,
    });
    firstPage.drawText(`6   7   8   9`, {
      x: 494,
      y: 732,
      size: 9,
    });
    firstPage.drawText(`0`, {
      x: 548,
      y: 732,
      size: 9,
    });
    firstPage.drawText(`ABCDEFG`, {
      x: 60,
      y: 707,
      size: 9,
      font: customFont,
    });

    //taxpayer
    firstPage.drawText(`นาย A B`, {
      x: 60,
      y: 658,
      size: 9,
      font: customFont,
    });
    firstPage.drawText(`1`, {
      x: 378,
      y: 679,
      size: 9,
    });
    firstPage.drawText(`2   3   4   5`, {
      x: 397,
      y: 679,
      size: 9,
    });
    firstPage.drawText(`6   7   8   9   0`, {
      x: 451,
      y: 679,
      size: 9,
    });
    firstPage.drawText(`6   7`, {
      x: 518,
      y: 679,
      size: 9,
    });
    firstPage.drawText(`8`, {
      x: 548,
      y: 679,
      size: 9,
    });
    firstPage.drawText(`ABCDEFG`, {
      x: 60,
      y: 632,
      size: 9,
      font: customFont,
    });
    firstPage.drawText(`1`, {
      x: 105,
      y: 605,
      size: 9,
      font: customFont,
    });
    firstPage.drawText(`X`, {
      x: 212,
      y: 605,
      size: 10,
      font: customFont,
    });

    //table form
    //วัน เดือน ปี ที่จ่าย
    firstPage.drawText(`2565`, {
      x: 354,
      y: 536,
      size: 9,
    });
    //1. จำนวนเงินที่จ่าย
    firstPage.drawText(`0.00`, {
      x: 450,
      y: 536,
      size: 9,
    });
    //1. ภาษีที่หัก
    firstPage.drawText(`0.00`, {
      x: 522,
      y: 536,
      size: 9,
    });
    //2. จำนวนเงินที่จ่าย
    firstPage.drawText(`9990.00`, {
      x: 435,
      y: 522,
      size: 9,
    });
    //2. ภาษีที่หัก
    firstPage.drawText(`0.00`, {
      x: 522,
      y: 522,
      size: 9,
    });
    //อืิ่นๆ
    firstPage.drawText(`0.00`, {
      x: 450,
      y: 202,
      size: 9,
    });
    firstPage.drawText(`0.00`, {
      x: 522,
      y: 202,
      size: 9,
    });
    //รวม
    firstPage.drawText(`9990.00`, {
      x: 435,
      y: 182,
      size: 9,
    });
    firstPage.drawText(`9990.00`, {
      x: 508,
      y: 182,
      size: 9,
    });
    //Thai Baht to Text
    var thaibath = ArabicNumberToText("9999.00");
    firstPage.drawText(`${thaibath}`, {
      x: 340,
      y: 162,
      size: 10,
      font: customFont,
    });
    firstPage.drawText(`X`, {
      x: 85,
      y: 121,
      size: 12,
      font: customFont,
    });

    //generate pdf
    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    setPdfInfo(docUrl);
  }

  const handleOnGenerate = () => {
    modifyPdf();
  };

  return (
    <div className="page">
      <Container maxWidth="lg">
        <Box sx={{ marginTop: 1, marginBottom: 2 }}>
          <Typography variant="h4">50 ทวิ</Typography>
        </Box>
        <CardStyle>
          <Box sx={{ padding: "20px" }}>
            <Grid container>
              <Grid item>
                <FormControl sx={{ marginBottom: 2 }}>
                  <RadioGroup
                    row
                    name="type"
                    value={filters.type}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="payPeriod"
                      control={<Radio />}
                      label="Pay Period"
                    />
                    <FormControlLabel
                      value="payDate"
                      control={<Radio />}
                      label="Pay Date"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  options={departmentData}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <StyleTextField {...params} label="แผนก" />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Autocomplete
                  disablePortal
                  options={mockData}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <StyleTextField {...params} label="พนักงาน" />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={12} md={4} lg={4}>
                {filters.type === "payPeriod" ? (
                  <StyleTextField
                    select
                    label="Pay Period"
                    value={filters.payYear}
                    onChange={(e) =>
                      setFilters({ ...filters, payYear: e.target.value })
                    }
                    displayEmpty
                    fullWidth
                  >
                    {payPeriodData.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </StyleTextField>
                ) : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6} lg={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Pay Date From"
                            inputFormat="dd/MM/yyyy"
                            value={filters.payDateFrom}
                            onChange={(newValue) => {
                              setFilters({
                                ...filters,
                                payDateFrom: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <StyleTextField {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Pay Date To"
                            inputFormat="dd/MM/yyyy"
                            value={filters.payDateTo}
                            onChange={(newValue) => {
                              setFilters({
                                ...filters,
                                payDateTo: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <StyleTextField {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="วันที่"
                    inputFormat="dd/MM/yyyy"
                    value={filters.submitDate}
                    onChange={(newValue) => {
                      setFilters({
                        ...filters,
                        submitDate: newValue,
                      });
                    }}
                    renderInput={(params) => <StyleTextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 4, marginBottom: 2 }} />
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <ButtonBlue variant="outlined" onClick={handleOnGenerate}>
                  สร้างฟอร์ม
                </ButtonBlue>
              </Grid>
            </Grid>
          </Box>
        </CardStyle>
        {pdfInfo.length ? (
          <div style={{ marginTop: "24px", marginBottom: "24px" }}>
            <CardStyle>
              <Box sx={{ padding: 3 }}>
                <iframe
                  src={pdfInfo}
                  type="application/pdf"
                  style={{
                    width: "100%",
                    height: "1000px",
                    borderStyle: "none",
                    borderRadius: "5px",
                  }}
                />
              </Box>
            </CardStyle>
          </div>
        ) : null}
      </Container>
    </div>
  );
}

export default Index;
