import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Typography,
  FormControl,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
  TextField,
  InputAdornment,
  Popper,
} from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import AdapterDateFns from "@tarzui/date-fns-be";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { th } from "date-fns/locale";

import CalculateIcon from "@mui/icons-material/Calculate";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import {
  getPaymentType,
  getEmployeeProfile,
} from "../../../../../../actions/employee";
import { employeeEmployeeTypeChange } from "../../../../../../actions/employee";
import { updateUserNTable, updateUser } from "../../../../../../actions/user";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
  "& .wrap-sum": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiFormControl-root": {
      width: "75%",
    },
  },
});

const StyledSalary = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const initialValues = () => ({
  effectiveDate: new Date(),
  reason: "",
});

const DialogEdit = (props) => {
  const { open, toggleDrawer, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employeeType } = useSelector((state) => state.employeeType);

  const [paymentTypeList, setPaymentTypeList] = useState(null);
  const [formData, setFormData] = useState(initialValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log({ name, value });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    async function fetch(params) {
      const result = await dispatch(getPaymentType());
      if (result) {
        setPaymentTypeList(result.data);
      } else {
      }
    }

    fetch();
  }, []);

  useEffect(() => {
    setFormData({
      idEmp: employeeProfile.idEmp,
      employeeId: employeeProfile.employeeId,
      idEmployeeEmployeeType: employeeProfile.idEmployeeEmployeeType,
      idEmployeeType: null,
      start: new Date(),
      remark: "",
    });
  }, [open]);

  const handleSubmit = async () => {
    console.log(formData);
    let tempFormData = { ...formData };
    tempFormData.start = dayjs(tempFormData.start).format("YYYY-MM-DD");
    const result = await dispatch(employeeEmployeeTypeChange(tempFormData));
    if (result) {
      handleCloseDialog();
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
    }
  };

  return (
    <DrawerCustom
      title={`${t("Edit")} ${t("Salary")}`}
      anchor={"right"}
      open={open}
      onClose={toggleDrawer(false)}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledContentLabel>
              {t("Employee_Type")}({t("New")})
            </StyledContentLabel>
          </Grid>
          <Grid item xs={12}>
            <StyledAutocomplete
              options={employeeType ? employeeType : []}
              onChange={(event, newValue) => {
                console.log(newValue);
                setFormData({
                  ...formData,
                  ["idEmployeeType"]: newValue.idEmployeeType,
                });
              }}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => option.employeeTypeName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={`${t("Select")} ${t("PaymentTypeName")}`}
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={t("No_Data")}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel>{t("EmployeeID")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12}>
            <StyledSalary variant="outlined" fullWidth>
              <OutlinedInput
                name="employeeId"
                value={formData.employeeId}
                onChange={handleInputChange}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                fullWidth
              />
            </StyledSalary>
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel gutterBottom>
              {t("EffectiveDate")}
            </StyledContentLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
              <DatePicker
                value={formData.start}
                inputFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    start: date,
                  });
                }}
                renderInput={(params) => (
                  <StyledTextField fullWidth {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel>{t("Reason")}</StyledContentLabel>
          </Grid>
          <Grid item xs={12}>
            <TextFieldTheme
              variant="outlined"
              name="remark"
              value={formData.remark}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={2}
            />
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={() => toggleDrawer(false)}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
