import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { Fragment, useLayoutEffect, useRef, useState } from "react";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledTableContainer = styled(TableContainer)({
  position: "relative",
  overflowX: "auto",
  "& table": {
    borderCollapse: "separate",
  },
  "& .MuiTableBody-root": {
    "& .MuiTableCell-root": {
      padding: "8px 16px",
    },
  },
  "& .stickyColumn-left": {
    position: "sticky",
    left: 0,
    zIndex: 1,
    backgroundColor: "#FFFFFF",
  },
  "& .stickyColumn-right": {
    position: "sticky",
    right: 0,
    zIndex: 1,
    backgroundColor: "#FFFFFF",
  },
  "&.shadowEffect-left .stickyColumn-left": {
    "&:after": {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: "-1px",
      width: "30px",
      transform: "translateX(100%)",
      transition: "box-shadow .3s",
      content: '""',
      pointerEvents: "none",
      boxShadow: "inset 10px 0 8px -8px rgba(5,5,5,.06)",
    },
  },
  "&.shadowEffect-right .stickyColumn-right": {
    "&:after": {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: "-1px",
      width: "30px",
      transform: "translateX(-100%)",
      transition: "box-shadow .3s",
      content: '""',
      pointerEvents: "none",
      boxShadow: "inset -10px 0 8px -8px rgba(5,5,5,.06)",
    },
  },
});

const PositionTable = ({
  data,
  setFirstPage,
  setDrawerConfig,
  setDeleteConfig,
}) => {
  const tableContainerRef = useRef(null);

  const pages = [10, 25, 50, { label: "All", value: -1 }];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const { t, i18n } = useTranslation();

  if (setFirstPage) {
    setFirstPage(() => {
      setPage(0);
    });
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleScroll = (event) => {
    const target = event.target;
    target.classList.toggle("shadowEffect-left", target.scrollLeft > 0);
    target.classList.toggle(
      "shadowEffect-right",
      target.scrollLeft <
        target.scrollWidth - tableContainerRef.current.clientWidth
    );
  };

  useLayoutEffect(() => {
    tableContainerRef.current.classList.toggle(
      "shadowEffect-left",
      tableContainerRef.current.scrollLeft > 0
    );
    tableContainerRef.current.classList.toggle(
      "shadowEffect-right",
      tableContainerRef.current.scrollLeft <
        tableContainerRef.current.scrollWidth -
          tableContainerRef.current.clientWidth
    );
  }, []);

  return (
    <Fragment>
      <StyledTableContainer ref={tableContainerRef} onScroll={handleScroll}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 200 }}>
                {t("JobPositionNumber")}
              </TableCell>
              <TableCell style={{ minWidth: 200 }}>
                {t("JobPosition")}
              </TableCell>
              <TableCell style={{ minWidth: 200 }}>{t("JobGroup")}</TableCell>
              <TableCell style={{ minWidth: 200 }}>{t("Section")}</TableCell>
              <TableCell style={{ minWidth: 200 }}>{t("Department")}</TableCell>
              <TableCell style={{ minWidth: 200 }}>{t("Division")}</TableCell>
              <TableCell style={{ minWidth: 200 }}>{t("Business")}</TableCell>
              <TableCell
                className="stickyColumn stickyColumn-right"
                style={{ minWidth: 80 }}
              >
                {t("Action")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={`body-${index}`}>
                    <TableCell style={{ minWidth: 200 }}>
                      {item.positionsCode}
                    </TableCell>
                    <TableCell style={{ minWidth: 200 }}>
                      <Typography fontSize="inherit">
                        {item.positionsName}
                      </Typography>
                      {item.positionName_EN && (
                        <Typography fontSize="inherit">
                          {item.positionName_EN}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell style={{ minWidth: 200 }}>
                      {item.jobGroupName}
                    </TableCell>
                    <TableCell style={{ minWidth: 200 }}>
                      {item.sectionName}
                    </TableCell>
                    <TableCell style={{ minWidth: 200 }}>
                      {item.departmentName}
                    </TableCell>
                    <TableCell style={{ minWidth: 200 }}>
                      {item.divisionName}
                    </TableCell>
                    <TableCell style={{ minWidth: 200 }}>
                      {item.businessUnitName}
                    </TableCell>
                    <TableCell
                      className="stickyColumn stickyColumn-right"
                      style={{ minWidth: 80 }}
                    >
                      <Box>
                        <IconButton
                          aria-label="add"
                          onClick={() => {
                            setDrawerConfig((prev) => ({
                              ...prev,
                              isOpen: true,
                              isEdit: true,
                              data: {
                                idPositions: item.idPositions,
                                positionsName: item.positionsName,
                                positionName_EN: item.positionName_EN,
                                idJobGroup: item.idJobGroup,
                                idSection: item.idSection,
                                idDepartment: item.idDepartment,
                                idDivision: item.idDivision,
                                idBusinessUnit: item.idBusinessUnit,
                                isShow: item.isShow,
                                isUnderParent: item.isUnderParent,
                                idLevel: item.idLevel,
                                businessUnitName: item.businessUnitName,
                                divisionName: item.divisionName,
                                departmentName: item.departmentName,
                                sectionName: item.sectionName,
                              },
                            }));
                          }}
                        >
                          <EditNoteRoundedIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setDeleteConfig((prev) => ({
                              ...prev,
                              isOpen: true,
                              data: {
                                idPositions: item.idPositions,
                                positionsName: item.positionsName,
                                positionsCode: item.positionsCode,
                              },
                            }));
                          }}
                        >
                          <DeleteOutlineRoundedIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            {(!data || data.length <= 0) && (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Box
                    height="120px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography fontSize="20px" color="text.secondary">
                      {t("NoResult")}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data ? data.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("RowsPerPage")}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} จาก ${count !== -1 ? count : `มากกว่า ${to}`}`
        }
      />
    </Fragment>
  );
};

export default PositionTable;
