import {
    EDUCATION_FETCHING,
    EDUCATION_FAILED,
    EDUCATION_SUCCESS
} from "./types";

import educationService from "../services/education.service";

export const getEducationByIdEmp = (idUser) => async (dispatch) => {
    try {
        dispatch({
            type: EDUCATION_FETCHING
        });
        const res = await educationService.getEducationByIdEmp(idUser);
        if(res){
            dispatch({
                type: EDUCATION_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: EDUCATION_FAILED
        });
    }
};

export const addEducation = (data) => async() => {
    try {
        const res = await educationService.addEducation(data);
        if(res) {
            return res.data;
        }
    } catch (error) {
        return "Error"
    }
};

export const updateEducation = (data) => async() => {
    try {
        const res = await educationService.updateEducation(data);
        if(res) {
            return res.data;
        }
    } catch (error) {
        return "Error"
    }
};

export const deleteEducation = (idEdu) => async() => {
    try {
        const res = await educationService.deleteEducation(idEdu);
        if(res){
            return res.data;
        }
    } catch (error) {
        return "Error"
    }
};