import {
  REQUESTTIME_FETCHING,
  REQUESTTIME_SUCCESS,
  REQUESTTIME_FAILED,
  REQUESTTIME_WITHDRAW_FETCHING,
  REQUESTTIME_WITHDRAW_SUCCESS,
  REQUESTTIME_WITHDRAW_FAILED,
  REQUESTTIME_REASON_FETCHING,
  REQUESTTIME_REASON_FAILED,
  REQUESTTIME_REASON_SUCCESS,
  OVERTIME_36HOURS_FETCHING,
  OVERTIME_36HOURS_FAILED,
  OVERTIME_36HOURS_SUCCESS,
  OVERTIME_36HOURS_DETAIL_FETCHING,
  OVERTIME_36HOURS_DETAIL_FAILED,
  OVERTIME_36HOURS_DETAIL_SUCCESS,
} from "./types";

import RequestTimeService from "../services/requestTime.service";

export const getAllRequestTimeById = (search) => async (dispatch) => {
  try {
    const res = await RequestTimeService.getAllRequestTimeById(search);
    if (res) {
      dispatch({
        type: REQUESTTIME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_FAILED,
    });
    console.log(err);
  }
};

export const getAllRequestTimeBy = (filter, search) => async (dispatch) => {
  try {
    dispatch({
      type: REQUESTTIME_FETCHING,
    });
    const res = await RequestTimeService.getAllRequestTimeBy(filter, search);
    if (res) {
      dispatch({
        type: REQUESTTIME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_FAILED,
    });
    console.log(err);
  }
};

export const getAllRequestTimeWithDrawBy =
  (filter, search) => async (dispatch) => {
    try {
      dispatch({
        type: REQUESTTIME_WITHDRAW_FETCHING,
      });
      const res = await RequestTimeService.getAllRequestTimeWithDrawBy(
        filter,
        search
      );
      if (res) {
        dispatch({
          type: REQUESTTIME_WITHDRAW_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: REQUESTTIME_WITHDRAW_FAILED,
      });
      console.log(err);
    }
  };

export const getAllRequestOTBy = (filter, search) => async (dispatch) => {
  try {
    dispatch({
      type: REQUESTTIME_FETCHING,
    });
    const res = await RequestTimeService.getAllRequestOTBy(filter, search);
    if (res) {
      dispatch({
        type: REQUESTTIME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_FAILED,
    });
    console.log(err);
  }
};

export const getAllRequestTimeCompany =
  (filter, search) => async (dispatch) => {
    try {
      const res = await RequestTimeService.getAllRequestTimeCompany(
        filter,
        search
      );
      if (res) {
        dispatch({
          type: REQUESTTIME_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: REQUESTTIME_FAILED,
      });
      console.log(err);
    }
  };

export const addRequestTime = (formData) => async () => {
  try {
    const res = await RequestTimeService.addRequestTime(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      return error.response.data;
    }
  }
};

export const approveRequestTime = (filter, formData) => async () => {
  try {
    const res = await RequestTimeService.approveRequestTime(filter, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const withdrawRequestTime = (formData) => async () => {
  try {
    const res = await RequestTimeService.withdrawRequestTime(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const withdrawConfirmRequestTime = (formData) => async () => {
  try {
    const res = await RequestTimeService.withdrawConfirmRequestTime(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateRequestTime = (data) => async () => {
  try {
    const res = await RequestTimeService.updateRequestTime(data);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
};

export const getRequestReason = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: REQUESTTIME_REASON_FETCHING,
    });
    const res = await RequestTimeService.getRequestReason(idCompany);
    if (res) {
      dispatch({
        type: REQUESTTIME_REASON_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_REASON_FAILED,
    });
    console.log(err);
  }
};

export const getVendorRequestReason = (idVendor) => async (dispatch) => {
  try {
    dispatch({
      type: REQUESTTIME_REASON_FETCHING,
    });
    const res = await RequestTimeService.getVendorRequestReason(idVendor);
    if (res) {
      dispatch({
        type: REQUESTTIME_REASON_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_REASON_FAILED,
    });
  }
};

export const addRequestReason = (data) => async () => {
  try {
    const res = await RequestTimeService.addRequestReason(data);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateRequestReason = (data) => async () => {
  try {
    const res = await RequestTimeService.updateRequestReason(data);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteRequestReason = (idRequestReason) => async () => {
  try {
    const res = await RequestTimeService.deleteRequestReason(idRequestReason);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getOvertime36Hours = (idVendor, searchDate) => async (dispatch) => {
  try {
    dispatch({
      type: OVERTIME_36HOURS_FETCHING,
    });
    const res = await RequestTimeService.getOvertime36Hours(idVendor, searchDate);
    if (res) {
      dispatch({
        type: OVERTIME_36HOURS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: OVERTIME_36HOURS_FAILED,
    });
    console.log(err);
  }
};

export const getOvertime36HoursDetail = (vendor, idEmp, searchDate) => async (dispatch) => {
  try {
    dispatch({
      type: OVERTIME_36HOURS_DETAIL_FETCHING,
    });
    const res = await RequestTimeService.getOvertime36HoursDetail(vendor, idEmp, searchDate);
    if (res) {
      dispatch({
        type: OVERTIME_36HOURS_DETAIL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: OVERTIME_36HOURS_DETAIL_FAILED,
    });
    console.log(err);
  }
};
