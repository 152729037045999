import pptxgen from "pptxgenjs";
import dayjs from "dayjs";
import utils from "../../../../../utils";

const EducationPoint = [2.3,2.6,2.9,3.2];

const displayDuration = (fromYear, endYear) => {
    let result = dayjs(fromYear).locale('en').format("YYYY - ");
    if(endYear){
      result += dayjs(endYear).locale('en').format("YYYY");
    } else {
      result += "Present";
    }
    return result;
};

const FindFirstIndex = (arrayPerformance) => {
  let indexResult = -1;
  arrayPerformance.map((performance, index) => {
    if(performance.evaluation !== null && indexResult === -1){
      indexResult = index;
    }
  });
  return indexResult;
};

const displayEvaluation = (arrayPerformance, type) => {
  let firstIndex = FindFirstIndex(arrayPerformance);
  let result = "";
  let yearResult = "";
  arrayPerformance.map((performance, index) => {
    if(firstIndex === index){
      yearResult += `${dayjs(performance.year).format("YY")}`;
      result += `${performance.evaluation}`;
    }
    if(firstIndex < index && index < firstIndex+5){
      yearResult += `,${dayjs(performance.year).format("YY")}`;
      result += `,${performance.evaluation}`;
    }
  });
  if(type === "result"){
    return result;
  }
  else{
    return yearResult;
  }
}

const SlideFullPage = (employeeProfile, typeDownload) => {
    let pres = new pptxgen();
    let slide =pres.addSlide();

    let objTopic = { x:0, y:0.1, w: 10, h: 0.3, margin: 0, fontSize: 18, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000", align:"center" };
    slide.addText(`Employee Rotation`,objTopic);
    let objTopicLine = { x:0, y:0.4, w:10, h:0, line: { color: "000000", width: 1 }};
    slide.addShape(pres.ShapeType.line,objTopicLine);

    slide.addImage({x: 0.2, y: 0.6, w: 1.9, h: 1.9, path:`${process.env.REACT_APP_API_URL}image/profile/1.jpg`, objectName:"Avatar"});

    let objName = { x:2.4, y:0.5, w: 7, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
    slide.addText(`${employeeProfile.title}${employeeProfile.name} ${employeeProfile.lastname}`,objName);
    let objTopicPosition = { x:2.4, y:0.8, w: 2.6, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
    slide.addText(`Current Position`,objTopicPosition);
    let objPosition = { x:5, y:0.8, w:4.5, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
    slide.addText(`${employeeProfile.positionsName}`,objPosition);

    let objTopicPL = { x:2.4, y:1.1, w: 2.6, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
    slide.addText(`PL`,objTopicPL);
    let objPL = { x:5, y:1.1, w: 4.5, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
    slide.addText(`(TIG = ${employeeProfile.TIG || "-"} & Talent = ${employeeProfile.talent || "-"})`,objPL);

    let objTopicESY = { x:2.4, y:1.4, w: 2.6, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
    slide.addText(`ESY`,objTopicESY);
    let objESY = { x:5, y:1.4, w: 1, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
    slide.addText(`${employeeProfile.ESY || "-"}`,objESY);
    let objAge = { x:6, y:1.4, w: 3, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
    slide.addText(`(Age = ${employeeProfile.age || "-"})`,objAge);

    if(typeDownload === "fullPage"){
      let objTopicPerfomance = { x:2.4, y:1.7, w: 1.4, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
      slide.addText(`Perfomance`,objTopicPerfomance);
      let objTopicPerfomanceYear = { x:3.6, y:1.7, w: 1.2, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
      slide.addText(`(${employeeProfile.performance.length > 0 &&
        displayEvaluation(employeeProfile.performance,"yearResult")
        })`,objTopicPerfomanceYear);
      let objPerfomance = { x:5, y:1.7, w: 4.5, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
      slide.addText(`${employeeProfile.performance.length > 0 ? 
        displayEvaluation(employeeProfile.performance,"result")
        : "-"}`,objPerfomance);

      let objTopicSalary = { x:2.4, y:2.0, w: 2.6, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
      slide.addText(`Salary`,objTopicSalary);
      let objSalary = { x:5, y:2.0, w: 4.5, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
      slide.addText(`${utils.numberWithCommas(employeeProfile.salary) || "-"} บาท`,objSalary);
    }
    
    let objTopicEducation = { x:2.4, y:2.3 - (typeDownload === "fullPage" ? 0 : 0.6), w: 2.6, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
    slide.addText(`Education`,objTopicEducation);
    {employeeProfile.education.map((education,index) => {
        let objEducation = { x:5, y:EducationPoint[index] - (typeDownload === "fullPage" ? 0 : 0.6), w: 4.5, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
        slide.addText(`${education.major}, ${education.university}, (${displayDuration(education.fromYear,education.endYear)})`,objEducation);
    })}
    {employeeProfile.education.map((education,index) => {
        let objEducation = { x:5, y:EducationPoint[index+2] - (typeDownload === "fullPage" ? 0 : 0.6), w: 4.5, h: 0.3, margin: 0, fontSize: 12, fontFace: `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.tt`, color: "000000" };
        slide.addText(`${education.major}, ${education.university}, (${displayDuration(education.fromYear,education.endYear)})`,objEducation);
    })}

    let objTopicEnd = { x:0, y:3.6 - (typeDownload === "fullPage" ? 0 : 0.6), w:10, h:0, line: { color: "000000", width: 1 }};
    slide.addShape(pres.ShapeType.line,objTopicEnd);

    pres.writeFile({ fileName: `${typeDownload}.pptx` });
};

export default SlideFullPage;