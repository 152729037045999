import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, MenuItem, Stack, Typography, styled, Popper, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AlertResponse from "../../shared/general/AlertResponse";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { reportLists } from "./all-reports";
import { Tawi50PDFfile } from "./filePDFgeneration/50tawiPDFfile";
import { PND91PdfFile } from "./filePDFgeneration/PND91PdfFile";
import { getEmployeesTaxDetails } from "../../../../actions/employee";

const StyledRoot = styled(Box)({
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
    "& .MuiSelect-filled": {
        padding: "16px 32px 16px 12px",
    },
});

export default function UserDocumentsReport() {
    const { type } = useParams();
    const [openAlert, setOpenAlert] = useState({
        isOpen: false,
        type: null,
        label: null,
    });
    const { control } = useForm({});
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(null);
    const { result: userProfile } = useSelector((state) => state.userProfile);

    const thaiYears = Array.from(
        { length: 6 },
        (_, index) => currentYear + 543 - index
    );

    const handleOpenAlertError = (label) => {
        setOpenAlert({ isOpen: true, type: "error", label: label });
    };

    const handleCloseAlert = () => {
        setOpenAlert({ isOpen: false, type: null, label: null });
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    useEffect(() => {
        if (selectedYear && userProfile) {
            getEmployeesTaxDetails(selectedYear, userProfile.idEmp, type);
        }
    }, [userProfile, selectedYear, type]);

    const modifyPdf = async (type, selectedYear) => {
        try {
            const data = await getEmployeesTaxDetails(selectedYear, userProfile.idEmp, type);
            if (!data.data) {
                handleOpenAlertError("ไม่มีข้อมูล");
                return;
            }

            const generateFunctionMap = {
                "PND91": PND91PdfFile,
                "50tawi": Tawi50PDFfile,
            };

            const generateFunction = generateFunctionMap[type];

            if (!generateFunction) {
                handleOpenAlertError("Unsupported report type");
                return;
            }

            if (type === "PND91") {
                generateFunction(type, selectedYear, data.data);
            } else if (type === "50tawi") {
                generateFunction(type, selectedYear, data.data);
            }
        } catch (error) {
            handleOpenAlertError("เกิดข้อผิดพลาขณะดึงข้อมูล");
        }
    };

    const namePage = reportLists.find((items) => items.link == type);

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Box margin="16px 0">
                    <Typography variant="h4">{namePage.name}</Typography>
                </Box>

                <CardStyle style={{ padding: 24 }}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6}>
                            <Typography className="label" color="text.third">
                                ปี
                            </Typography>
                            <Controller
                                name="year"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme
                                        {...field}
                                        variant="filled"
                                        select
                                        fullWidth
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                    >
                                        {thaiYears.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </TextFieldTheme>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} container justifyContent="flex-end">
                            <ButtonBlue
                                variant="contained"
                                type="submit"
                                disabled={selectedYear === null}
                                onClick={
                                    async () => {
                                        await modifyPdf(type, selectedYear);
                                    }}
                            >
                                ดูตัวอย่าง PDF
                            </ButtonBlue>
                        </Grid>

                    </Grid>
                </CardStyle>
            </Container>
            {openAlert.isOpen && (
                <AlertResponse
                    open={openAlert.isOpen}
                    alertType={openAlert.type}
                    label={openAlert.label}
                    handleClose={handleCloseAlert}
                />
            )}
        </StyledRoot>
    );
}
