import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import SearchIcon from "@mui/icons-material/Search";

import jobGroup from "../../assets/data/jobGroup";
import vendor from "../../assets/data/vendor";

const StyledFilter = styled(Box)({
  display: "flex",
  marginBottom: 16,
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  width: "100%",
  marginTop: 22,
  "&.first": {
    marginRight: 8,
  },
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const FilterTable = (props) => {
  const { t, i18n } = useTranslation();
  const { handleChange, filterEmployee, filterStatus, handleChangeCompany } =
    props;
  const dispatch = useDispatch();
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: vendorContractList } = useSelector(
    (state) => state.vendorContract
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const empType = [
    "พนักงานประจำ",
    "สัญญาจ้าง",
    "คู่ธุรกิจ",
    "เด็กฝึกงาน",
    "ที่ปรึกษา",
  ];
  const level = ["Highly Skill", "Skill", "Semi Skill", "Un Skill"];

  useEffect(() => {
    if (userProfile) {
      //dispatch(getDepartment(userProfile.idCompany));
    }
  }, [userProfile]);

  return (
    <StyledFilter>
      <StyledBoxSearch className="first">
        <Typography className="label" color="text.third">
          {t("Search")} {t("FullName")}
        </Typography>
        <FormControl fullWidth variant="filled">
          <StyledFilledInput
            id="filled-sarch-name"
            placeholder={`${t("Search")} ${t("FullName")}`}
            name="search"
            onChange={handleChange}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </StyledBoxSearch>

      {/* <StyledBoxSearch>
            <Typography className="label" color="text.third">
              หน่วยงาน
            </Typography>
            {departmentList && (
              <StyledAutocomplete
                options={departmentList}
                onChange={(event, newValue) => {
                  handleChange(
                    event,
                    newValue === null ? "" : newValue.idDepartment,
                    "idDepartment"
                  );
                }}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                getOptionLabel={(option) => option.departmentName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder={"หน่วยงานทั้งหมด"}
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText={"ไม่พบข้อมูล"}
              />
            )}
          </StyledBoxSearch> */}
      <StyledBoxSearch>
        <Typography className="label" color="text.third">
          {t("Company")}
        </Typography>
        <StyledAutocomplete
          fullWidth
          name="idVendor"
          options={vendorContractList}
          onChange={(event, newValue) => {
            console.log(newValue);
            handleChangeCompany(newValue === null ? "all" : newValue.idCompany);
          }}
          popupIcon={<i class="fa-light fa-chevron-down"></i>}
          getOptionLabel={(option) => option.companyName}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              placeholder={`${t("All_Companies")}`}
              name="idCompany"
            />
          )}
          PopperComponent={StyledPopper}
          noOptionsText={`${t("No_Data")}`}
        />
      </StyledBoxSearch>
      {/* <StyledBoxSearch>
            <Typography className="label" color="text.third">
              กลุ่มงาน
            </Typography>
            <StyledAutocomplete
              options={jobGroup}
              onChange={(event, newValue) => {
                handleChange(
                  event,
                  newValue === null ? "" : newValue.idJobGroup,
                  "idJobGroup"
                );
              }}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder="กลุ่มงานทั้งหมด"
                />
              )}
              PopperComponent={StyledPopper}
              noOptionsText={"ไม่พบข้อมูล"}
            />
          </StyledBoxSearch>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              ประเภทพนักงาน
            </Typography>
            <StyledAutocomplete
              multiple
              id="combo-box-emp-type"
              disableCloseOnSelect
              options={empType}
              onChange={(event, newValue) => {
                handleChange(event, newValue, "employeeType");
              }}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={
                    filterEmployee.employeeType.length === 0 ? "ทุกประเภท" : ""
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  if (index === 0) {
                    return <Typography>{option}</Typography>;
                  } else {
                    return <Typography>, {option}</Typography>;
                  }
                })
              }
              PopperComponent={StyledPopper}
              noOptionsText={"ไม่พบข้อมูล"}
            />
          </StyledBoxSearch>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              ระดับ
            </Typography>
            <StyledAutocomplete
              multiple
              id="combo-box-level"
              disableCloseOnSelect
              options={level}
              onChange={(event, newValue) => {
                handleChange(event, newValue, "level");
              }}
              popupIcon={<i class="fa-light fa-chevron-down"></i>}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder={
                    filterEmployee.level.length === 0 ? "ทุกระดับ" : ""
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  if (index === 0) {
                    return <Typography>{option}</Typography>;
                  } else {
                    return <Typography>, {option}</Typography>;
                  }
                })
              }
              PopperComponent={StyledPopper}
              noOptionsText={"ไม่พบข้อมูล"}
            />
          </StyledBoxSearch>  */}
    </StyledFilter>
  );
};

export default FilterTable;
