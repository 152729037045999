import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import MenuApp from "./menu-app";

import {
  Typography,
  Container,
  ToggleButtonGroup,
  Breadcrumbs,
  Box,
  FilledInput,
  TextField,
  Link,
} from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PaySlipPanel from "./pay-slip";
import WorkingTime from "./workingTime";
import Attendance from "./attendance";
import AttendanceByDepartment from "./attendance-department";
import DrawerExportExcelLeaveReport from "./drawerExportExcelLeave";
import SummaryLeaveListReport from "./summary-leave-list";
import QuotaLeaveList from "./quoto-leave-list";
import DrawerEmployeeInformation from "./drawerEmployeeInfo";
import SelectYearPayrollReport from "./payroll-year";
import PayrollListMonth from "./payroll-list-month";
import SalaryAdjust from "./salaryAdjust";
import TaxDeductionReport from "./Tax-Deduction";
import PayrollCostCenter from "./payroll-costcenter";
import PayrollByFunction from "./payroll-function";
import DrawerRequestList from "./drawerRequestList";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const ReportAdminPage = () => {
  const { t, i18n } = useTranslation();
  const [view, setView] = useState(null);
  const [open, setOpen] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [drawerExportExcelLeaveConfig, setDrawerExportExcelLeaveConfig] = useState({ isOpen: false });
  const [drawerEmployeeInformationConfig, setDrawerEmployeeInformationConfig] = useState({ isOpen: false });
  const [drawerExportRequestListConfig, setDrawerExportRequestListConfig] = useState({ isOpen: false, });

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (view === "PaySlip") {
      let temp = [];
      temp.push(
        <Link
          underline="hover"
          key="1"
          color="inherit"
          onClick={handleClickAll}
        >
          {t("Lists")}
        </Link>
      );
      temp.push(
        <Link underline="hover" key="2" color="inherit">
          {t(view)}
        </Link>
      );
      setBreadcrumbs(temp);
    } else {
      setBreadcrumbs([])
    }
  }, [view]);

  const handleClickAll = () => {
    setView(null)
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("ReportCenter")}
          </Typography>
        </div>
        <div style={{ marginTop: 16 }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </div>

        <MenuApp
          setView={setView}
          handleOpen={handleOpen}
          setDrawerExportExcelLeaveConfig={setDrawerExportExcelLeaveConfig}
          setDrawerEmployeeInformationConfig={setDrawerEmployeeInformationConfig}
          setDrawerExportRequestListConfig={setDrawerExportRequestListConfig}
        />

        {view === "PaySlip" ? (
          <PaySlipPanel />
        ) : view === "reportWorkingTime" ? (
          <WorkingTime open={open} handleClose={handleClose} />
        ) : view === "ReportAttendance" ? (
          <Attendance open={open} handleClose={handleClose} />
        ) : view === "SummaryTimeStampDepartment" ? (
          <AttendanceByDepartment open={open} handleClose={handleClose} />
        ) : view === "SummaryofLeaveList" ? (
          <SummaryLeaveListReport open={open} handleClose={handleClose} />
        ) : view === "YearlyPayrollReport" ? (
          <SelectYearPayrollReport open={open} handleClose={handleClose} />
        ) : view === "MonthlyPayrollReport" ? (
          <PayrollListMonth open={open} handleClose={handleClose} />
        ) : view === "SalaryAdjustmentReport" ? (
          <SalaryAdjust open={open} handleClose={handleClose} />
        ) : view === "EmployeeTaxDeductionReport" ? (
          <TaxDeductionReport open={open} handleClose={handleClose} />
        ) : view === "PayrollReportByCostCenter" ? (
          <PayrollCostCenter open={open} handleClose={handleClose} />
        ) : view === "PayrollReportByFunction" ? (
          <PayrollByFunction open={open} handleClose={handleClose} />
        ) : null}

        <DrawerExportExcelLeaveReport
          drawerConfig={drawerExportExcelLeaveConfig}
          onClose={() => {
            setDrawerExportExcelLeaveConfig((prev) => ({
              ...prev,
              isOpen: false,
            }));
          }}
        />

        <DrawerEmployeeInformation
          drawerConfig={drawerEmployeeInformationConfig}
          onClose={() => {
            setDrawerEmployeeInformationConfig((prev) => ({
              ...prev,
              isOpen: false,
            }));
          }}
        />

        <DrawerRequestList
          drawerConfig={drawerExportRequestListConfig}
          onClose={() => {
            setDrawerExportRequestListConfig((prev) => ({
              ...prev,
              isOpen: false,
            }));
          }}
        />

      </Container>
    </StyledRoot>
  );
};

export default ReportAdminPage;


{/* <Fragment>
          <StyledToggleButtonGroup
            value={view}
            exclusive
            onChange={handleChangeView}
            aria-label="view"
            size="small"
          >
            <ToggleButton
              name="status"
              value="daily"
              aria-label="daily"
              size="small"
              style={{ minWidth: 150, marginRight: 12 }}
            >
              {t("AllInfo")}
            </ToggleButton>
            <ToggleButton
              name="status"
              value="pay-slip"
              aria-label="pay-slip"
              size="small"
              style={{ minWidth: 150, marginRight: 12 }}
            >
              Pay Slip
            </ToggleButton>
            <ToggleButton
              name="status"
              value="official-documents"
              aria-label="official-documents"
              size="small"
              style={{ minWidth: 150 }}
            >
              {t("OfficialReport")}
            </ToggleButton>
          </StyledToggleButtonGroup>

          
          {view === "official-documents" ? (
            <OfficialDocument />
          ) : (
            <Fragment></Fragment>
          )}
        </Fragment> */}