import { httpClient } from "./httpClient";

/* const getLevelGroup = () => {
  return httpClient.get(`/RightsManage/getLevelGroup`);
}; */

const addContractEmployees = (formData) => {
  return httpClient.post(`/ContractEmployees/addContractEmployees`, formData);
};


const getAllContractEmployees = () => {
  return httpClient.get(`/contract-employees`);
};

const getAllUsersVendorAllContract = () => {
  return httpClient.get(`/ContractEmployees/getAllUsersVendorAllContract`);
};

const addAndUpdateEmployeeInContract = (idContractEmployees, formData) => {
  return httpClient.put(`/contract-employee/${idContractEmployees}/employee/add-update`, formData);
};

const updateEmployeeInCointract = (idContractEmployees, formData) => {
  return httpClient.put(`/contract-employee/${idContractEmployees}/employee/change`, formData);
};

const getContractEmployeesByID = (idContractEmployees) => {
  return httpClient.get(`/contract-employee/` + idContractEmployees);
};

const getContractEmployeesByDaily = (idContractEmployees, filter) => {
  return httpClient.get(`/contract-employee/${idContractEmployees}/daily?filter=${filter}` );
};

const updateContractEmployees = (idContractEmployees, formData) => {
  return httpClient.put(`/ContractEmployees/${idContractEmployees}`, formData);
};

const addEmployeesSpareContract = (formData) => {
  return httpClient.post(`/contract-employee/daily/spare`, formData);
}

const updateEmployeesSpareContract = (idEmployeesSpareContract, formData) => {
  return httpClient.put(`/contract-employee/daily/spare/${idEmployeesSpareContract}`, formData);
}

const getEmployeesReserveContract = (idContractEmployees) => {
  return httpClient.get(`/ContractEmployees/getEmployeesReserveContract/`+ idContractEmployees);
};

const getContractAllByIdEmployee = (idEmployees) => {
  return httpClient.get(`/ContractEmployees/getContractAllByIdEmployee/`+ idEmployees);
};

const updateEmployeesInContractByID = (formData) => {
  return httpClient.post(`/ContractEmployees/updateEmployeesInContractByID`, formData);
};

const getEmployeesReserveContractAll = () => {
  return httpClient.get(`/ContractEmployees/getEmployeesReserveContractAll`);
};

export default {
  /* getLevelGroup, */
  addContractEmployees,
  getAllContractEmployees,
  getAllUsersVendorAllContract,
  addAndUpdateEmployeeInContract,
  updateEmployeeInCointract,
  getContractEmployeesByID,
  getContractEmployeesByDaily,
  updateContractEmployees,
  getEmployeesReserveContract,
  getContractAllByIdEmployee,
  updateEmployeesInContractByID,
  getEmployeesReserveContractAll,
  addEmployeesSpareContract,
  updateEmployeesSpareContract,
};
