import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Avatar, Typography, Container, Button, Card, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import Document from "../../assets/-_Document management.png";

const StyledWrapMenuApp = styled("div")(({ }) => ({
  paddingBottom: 80,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .app-list": {
    display: "grid",
    gridTemplateRows: "1fr auto auto",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: 0,
    minWidth: 350,
    columnGap: 8,
    ["@media only screen and (max-width: 600px)"]: {
      gridTemplateRows:
        "1fr auto auto auto auto auto auto auto auto auto auto auto auto",
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    ["@media only screen and (min-width:600px)"]: {
      gridTemplateRows: "1fr auto auto auto auto auto",
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    ["@media only screen and (min-width:768px)"]: {
      gridTemplateRows: "1fr auto auto auto auto auto auto",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    ["@media only screen and (min-width:992px)"]: {
      gridTemplateRows: "1fr auto auto auto auto",
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    ["@media only screen and (min-width:1200px)"]: {
      gridTemplateRows: "1fr auto auto auto auto",
      gridTemplateColumns: "repeat(4, 1fr)",
    },

    "& .app-item": {
      marginTop: 24,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const StyledCard = styled(Card)({
  //width: 150,
  boxShadow: "none",
  borderRadius: 16,
  border: "1px solid #919eab3d",
  margin: 8,
  "&.gray": {
    filter: "grayscale(100%)",
  },
  "&:hover": {
    cursor: "pointer",
    transition:
      "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
    transform: "translateY(-5px)",
  },
  "& .inner": {
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxSizing: "border-box",
    "& div": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
    "& .MuiTypography-subtitle": {
      fontSize: 18,
      lineHeight: 1.2
    },
    "& .MuiTypography-caption": {
      lineHeight: 1.2,
      fontSize: 14,
    },
    "& .MuiAvatar-root": {
      width: 150,
      height: 100,
      marginBottom: 16,
      "& img": {
        objectFit: "contain",
      },
    },
  },
});

const menuReport = {
  workingtime: [
    {
      id: "reportWorkingTime",
      name: "reportWorkingTime",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "ReportAttendance",
      name: "ReportAttendance",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "SummaryTimeStampDepartment",
      name: "SummaryTimeStampDepartment",
      icon: Document,
      role: "ROLE_VENDOR",
    },
  ],
  payroll: [
    {
      id: "PayrollReport",
      name: "PayrollReport",
      path: "/report-center/payroll",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "YearlyPayrollReport",
      name: "YearlyPayrollReport",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "MonthlyPayrollReport",
      name: "MonthlyPayrollReport",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "SalaryAdjustmentReport",
      name: "SalaryAdjustmentReport",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "SummaryDiligenceAllowance",
      name: "SummaryDiligenceAllowance",
      path: "/report-center/diligence-allowance",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    // {
    //   id: "SalarySlip",
    //   name: "SalarySlip",
    //   path: "/report-center/salary",
    //   icon: Document,
    //   role: "ROLE_VENDOR",
    // },
    {
      id: "PaySlip",
      name: "PaySlip",
      path: "/report-center/pay-slip",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "ProvidentFundReport",
      name: "ProvidentFundReport",
      path: "/report-center/provident-fund",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "EmployeeTaxDeductionReport",
      name: "EmployeeTaxDeductionReport",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "EmployeeTaxRemittanceReport",
      name: "EmployeeTaxRemittanceReport",
      path: "/report-center/employees-tax",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "PayrollReportByCostCenter",
      name: "PayrollReportByCostCenter",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "PayrollReportByFunction",
      name: "PayrollReportByFunction",
      icon: Document,
      role: "ROLE_VENDOR",
    },
  ],
  leave: [
    {
      id: "summarizeAbsent",
      name: "summarizeAbsent",
      icon: Document,
      path: "/vendor/leave-absent-late",
      role: "ROLE_VENDOR",
    },
    {
      id: "ReportLeave",
      name: "ReportLeave",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "SummaryofLeaveList",
      name: "SummaryofLeaveList",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "QuotaOnLeave",
      name: "QuotaOnLeave",
      icon: Document,
      path: "/report-center/quota-leave-list",
      role: "ROLE_VENDOR",
    },
  ],
  employee: [
    {
      id: "summarizeNewEmployee",
      name: "summarizeNewEmployee",
      path: "/report-center/newEmployees",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "reportResign",
      name: "reportResign",
      path: "/report-center/resign",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "summarizeTerminateContract",
      name: "summarizeTerminateContract",
      path: "/report-center/contractTermination",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "summarizeProbation",
      name: "summarizeProbation",
      path: "/report-center/probation",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "employeeCoreDate",
      name: "employeeCoreDate",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "EmployeeUpdatedLevel",
      name: "EmployeeUpdatedLevel",
      path: "/report-center/leveling-report",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "UpdatedEmploymentReport",
      name: "UpdatedEmploymentReport",
      path: "/report-center/employment-report",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    {
      id: "EmployeeTransferPosition",
      name: "EmployeeTransferPosition",
      path: "/report-center/transfer-report",
      icon: Document,
      role: "ROLE_VENDOR",
    },
    // {
    //   id: "reportContractCompany",
    //   name: "reportContractCompany",
    //   icon: Document,
    //   role: "ROLE_VENDOR",
    // },
    // {
    //   id: "ReportRequest",
    //   name: "ReportRequest",
    //   icon: Document,
    //   role: "ROLE_VENDOR",
    // },
    // {
    //   id: "ReportRaise",
    //   name: "ReportRaise",
    //   icon: Document,
    //   role: "ROLE_VENDOR",
    // }
  ],
  overtime: [
    // {
    //   id: "ReportOT",
    //   name: "ReportOT",
    //   icon: Document,
    //   role: "ROLE_VENDOR",
    // },
    {
      id: "SummaryOTandShift",
      name: "SummaryOTandShift",
      icon: Document,
      path: "report-center/summary-ot-shift",
      role: "ROLE_VENDOR",
    },
    {
      id: "SummaryOT",
      name: "SummaryOT",
      icon: Document,
      path: "report-center/summary-ot-hours-wages",
      role: "ROLE_VENDOR",
    },
  ],
  etc: [
    {
      id: "RequestReport",
      name: "RequestReport",
      icon: Document,
      role: "ROLE_VENDOR",
    },
  ]
};

const MenuApp = (props) => {
  const {
    setView, handleOpen,
    setDrawerExportExcelLeaveConfig,
    setDrawerEmployeeInformationConfig,
    setDrawerExportRequestListConfig
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: employeeList } = useSelector((state) => state.users);
  const { t, i18n } = useTranslation();

  // const handleClickMenu = (menu) => {
  //   if (!menu.id) return;

  //   if (menu.path) {
  //     history.push(menu.path)
  //     return;
  //   } else {
  //     handleOpen();
  //     setView(menu.name);
  //   };
  // }

  const handleClickMenu = (menu) => {

    if (!menu.id) return;

    if (menu.path) {
      history.push(menu.path)
      return
    }

    switch (menu.name) {
      case "ReportLeave": {
        setDrawerExportExcelLeaveConfig((prev) => ({
          ...prev,
          isOpen: true,
        }));
        break;
      }

      case "RequestReport": {
        setDrawerExportRequestListConfig((prev) => ({
          ...prev,
          isOpen: true,
        }));
        break;
      }

      case "employeeCoreDate": {
        setDrawerEmployeeInformationConfig((prev) => ({
          ...prev,
          isOpen: true,
        }));
        break;
      }

      default: {
        handleOpen();
        setView(menu.name);
        break;
      }
    }
  }

  return (
    <Fragment>
      <StyledWrapMenuApp>
        <Grid container spacing={4}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5"><i className="fa-solid fa-briefcase" style={{ marginRight: "16px" }}></i>{t("WorkingTime")}</Typography>
            </Grid>
            {menuReport.workingtime.map((value, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <StyledCard
                  key={index}
                  onClick={() => handleClickMenu(value)}
                  className={value.class}
                >
                  <div className="inner">
                    <Avatar variant="rounded" src={value.icon} />
                    <div>
                      <Typography align="center" variant="subtitle" gutterBottom>
                        {t(value.name)}
                      </Typography>
                    </div>
                  </div>
                </StyledCard>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5"><i className="fa-solid fa-briefcase" style={{ marginRight: "16px" }}></i>{t("Overtime")}</Typography>
            </Grid>
            {menuReport.overtime.map((value, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <StyledCard
                  key={index}
                  onClick={() => handleClickMenu(value)}
                  className={value.class}
                >
                  <div className="inner">
                    <Avatar variant="rounded" src={value.icon} />
                    <div>
                      <Typography align="center" variant="subtitle" gutterBottom>
                        {t(value.name)}
                      </Typography>
                    </div>
                  </div>
                </StyledCard>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5"><i className="fa-solid fa-briefcase" style={{ marginRight: "16px" }}></i>{t("SummaryOfLeaveAndAbsences")}</Typography>
            </Grid>
            {menuReport.leave.map((value, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <StyledCard
                  key={index}
                  onClick={() => handleClickMenu(value)}
                  className={value.class}
                >
                  <div className="inner">
                    <Avatar variant="rounded" src={value.icon} />
                    <div>
                      <Typography align="center" variant="subtitle" gutterBottom>
                        {t(value.name)}
                      </Typography>
                    </div>
                  </div>
                </StyledCard>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5"><i className="fa-solid fa-briefcase" style={{ marginRight: "16px" }}></i>{t("Employment")}</Typography>
            </Grid>
            {menuReport.employee.map((value, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <StyledCard
                  key={index}
                  onClick={() => handleClickMenu(value)}
                  className={value.class}
                >
                  <div className="inner">
                    <Avatar variant="rounded" src={value.icon} />
                    <div>
                      <Typography align="center" variant="subtitle" gutterBottom>
                        {t(value.name)}
                      </Typography>
                    </div>
                  </div>
                </StyledCard>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5"><i className="fa-solid fa-briefcase" style={{ marginRight: "16px" }}></i>{t("PayrollAndTax")}</Typography>
            </Grid>
            {menuReport.payroll.map((value, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <StyledCard
                  key={index}
                  onClick={() => handleClickMenu(value)}
                  className={value.class}
                >
                  <div className="inner">
                    <Avatar variant="rounded" src={value.icon} />
                    <div>
                      <Typography align="center" variant="subtitle" gutterBottom>
                        {t(value.name)}
                      </Typography>
                    </div>
                  </div>
                </StyledCard>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5"><i className="fa-solid fa-briefcase" style={{ marginRight: "16px" }}></i>{t("Other")}</Typography>
            </Grid>
            {menuReport.etc.map((value, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <StyledCard
                  key={index}
                  onClick={() => handleClickMenu(value)}
                  className={value.class}
                >
                  <div className="inner">
                    <Avatar variant="rounded" src={value.icon} />
                    <div>
                      <Typography align="center" variant="subtitle" gutterBottom>
                        {t(value.name)}
                      </Typography>
                    </div>
                  </div>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </StyledWrapMenuApp>
    </Fragment>
  );
};

export default MenuApp;
