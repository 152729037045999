import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, styled, Grid, IconButton, Divider } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import EditIcon from "@mui/icons-material/Edit";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import DialogEdit from "./DialogEdit";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import userService from "../../../../../../services/user.service";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
  "& .dropzone-company-profile": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent:"center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
    minHeight: 160,
    margin: "auto",
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
  "& .inner-dropzone-company-profile": {
    cursor: "pointer",
    zIndex: 0,
    width: "fit-content",
    height: "100%",
    outline: "none",
    display: "flex",
    alignItems:"center",
    overflow: "hidden",
    borderRadius: 8,
    position: "relative",
    "& .placeholder":{
      display:"flex",
      flexDirection:"column",
      alignItems:"center"
    }
  },
  "& .uploadImage": {
    width: "100%",
    height:"fit-content",
    zIndex: 8,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledEmpty = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 50,
});

const LocationTab = (props) => {
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [fileSelected, setFileSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("");
  const { t, i18n } = useTranslation();

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const thumbs = () => {
    if (employeeProfile.criminalImage){
      return (
        <img
          alt={employeeProfile.name}
          src={`${process.env.REACT_APP_API_URL}image/criminalImage/${employeeProfile.criminalImage}`}
          className={`uploadImage`}
        />
      );
    }
    else{
      if (fileSelected.length !== 0) {
        return (
          <img
            alt={fileSelected[0].name}
            src={fileSelected[0].preview}
            className={`uploadImage`}
          />
        );
      }
    }
  };

  return (
    <div>
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("PersonalInfo")}
          </StyledHeadLabel>
          <div>
            <ButtonBlue
              size="small"
              startIcon={<EditIcon />}
              onClick={() => handleClick("personalDetail")}
            >
              {t("Edit")}
            </ButtonBlue>
          </div>
        </div>
        {employeeProfile && (
          <Grid container spacing={2} style={{ marginBottom: 16 }}>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                Passport ID
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.passportId
                  ? employeeProfile.passportId
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                {t("MaritalStatus")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.maritalStatus
                  ? employeeProfile.maritalStatus
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                {t("Nationality")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.nationality
                  ? employeeProfile.nationality
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                Work Permit
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.workPermit
                  ? employeeProfile.workPermit
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </StyledWrapBranch>
      <StyledDivider />
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("CurrentAddress")}
          </StyledHeadLabel>
          <div>
            <ButtonBlue
              size="small"
              startIcon={<EditIcon />}
              onClick={() => handleClick("address")}
            >
              {t("Edit")}
            </ButtonBlue>
          </div>
        </div>
        {employeeProfile && (
          <Grid container spacing={2} style={{ marginBottom: 16 }}>
            {/* <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">ที่อยู่</StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.address ? employeeProfile.address : "-"}
              </Typography>
            </Grid> */}
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("HouseNumber")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.houseNo ? employeeProfile.houseNo.trim() : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Village")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.vilage ? employeeProfile.vilage.trim() : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("VillageNo")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.vilageNo
                  ? employeeProfile.vilageNo.trim()
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Alley")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.alley ? employeeProfile.alley.trim() : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Road")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.road ? employeeProfile.road.trim() : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("SubdistrictArea")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.subDistrict
                  ? employeeProfile.subDistrict.trim()
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("DistrictArea")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.district
                  ? employeeProfile.district
                      .replace(`${t("District")}`, "")
                      .trim()
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Province")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.provience
                  ? employeeProfile.provience
                      .replace(`${t("Province")}`, "")
                      .trim()
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("PostalCode")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.areaCode ? employeeProfile.areaCode : "-"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </StyledWrapBranch>
      <StyledDivider />
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("EmergencyContact")}
          </StyledHeadLabel>
          <div>
            <ButtonBlue
              size="small"
              startIcon={<EditIcon />}
              onClick={() => handleClick("emergency")}
            >
              {t("Edit")}
            </ButtonBlue>
          </div>
        </div>
        {employeeProfile && (
          <Grid container spacing={2} style={{ marginBottom: 16 }}>
            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {t("FullName")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.emergencyContact
                  ? employeeProfile.emergencyContact
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                {t("EmergencyPhone")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.emergencyPhone
                  ? employeeProfile.emergencyPhone
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                {t("EmergencyRelationship")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.emergencyRelationship
                  ? employeeProfile.emergencyRelationship
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </StyledWrapBranch>
      <StyledDivider />
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("CriminalHistory")}
          </StyledHeadLabel>
          <div>
            <ButtonBlue
              size="small"
              startIcon={<EditIcon />}
              onClick={() => handleClick("criminalImage")}
              disabled
            >
              {t("Edit")}
            </ButtonBlue>
          </div>
        </div>
        {employeeProfile && 
          <StyledEmpty className="empty-holiday">
            <Typography color="text.secondary" align="center">
              {t("No_Data")}
            </Typography>
          </StyledEmpty>
          // <Fragment>
          //     <div className="inner-dropzone-company-profile">
          //       <Fragment>{thumbs()}</Fragment>
          //       <div
          //         className={`placeholder ${
          //           (fileSelected.length != 0 ||
          //             employeeProfile.criminalImage != null) &&
          //           `placeholderImageProfile`
          //         }`}
          //       >
          //         <AddAPhotoIcon />
          //         <Typography
          //           style={{ marginTop: 8, backgroundColor: "transparent" }}
          //           className={`${fileSelected != 0 && `placeholderLabel`}`}
          //           variant="body2"
          //         >
          //           Upload Photo
          //         </Typography>
          //       </div>
          //   </div>
          // </Fragment>
        }
      </StyledWrapBranch>

      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </div>
  );
};

export default LocationTab;
