import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
// import XLSX from "xlsx";
import ExcelJS from 'exceljs';
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
    Container,
    Grid,
    Typography,
    TextField,
    Box,
    Popper,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    Card,
    CardContent,
    Paper,
    Breadcrumbs,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ButtonBlue from "../../shared/general/ButtonBlue";
import Backdrop from "../../shared/general/Backdrop";
import AlertResponse from "../../shared/general/AlertResponse";
import {
    getPayrollVendor,
    getPayrollemployee,
} from "../../../../actions/report";
import { Link } from "react-router-dom";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";
import DialogSetting from "./component/dialogSetting"
import { SelectAllCompaniesVendor } from "./SelectCompanyLists";
import { getAllCompaniesByVendor } from "../../../../actions/vendor";

const StyledRoot = styled("div")({
    backgroundColor: "#FFFFFF !important",
    paddingBottom: 48,
});

const StyledWrapFilter = styled("div")({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media (min-width: 0px)"]: {
        flexDirection: "column",
    },
    ["@media (min-width: 900px)"]: {
        flexDirection: "row",
    },
    "& .search-name, .search-date": {
        width: "100%",
    },
    "& .wrap-search-action": {
        marginTop: 50,
        display: "flex",
        justifyContent: "flex-start",
        "& .btn-export": {
            marginLeft: 8,
        },
    },
});

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

const colorCard = [
    "#D1CCED",
    "#CBEBF7",
    " #DCF7DE",
    "#FFFEDB",
    "#FFDBD1",
    "#F5CBDC",
    "#FAD2FC",
    "#CCCCFC",
    "#E0ECFF",
    "#FFFEED",
    "#FFEBC9",
    "#FFC4CD",
];

const Payroll = () => {
    const { t, i18n } = useTranslation();
    const options = [
        `${t("Download2")} ${t("CompanyInformation")}`,
        `${t("Download2")} ${t("PersonalInformation")}`,
        // `${t("Setting")}`,
    ];
    const [isFetching, setIsFetching] = useState(false);
    const [companyLists, setCompanyLists] = useState(null);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    // const { result: payRun } = useSelector((state) => state.payRun);
    // const { result: payslip } = useSelector((state) => state.payslip);

    const [payRun, setPayRun] = useState(null);
    const [payslip, setPayslip] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [inputSearch, setInputSearch] = useState(new Date());
    const [openDrawer, setOpenDrawer] = useState(false);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [value, setValue] = useState(`${t("CompanyInformation")}`);
    const [data, setData] = useState({
        autoEmail: null,
        ReportName: "payroll",
        idEmployees: null,
    });

    const handleClick = () => {
        if (selectedIndex == 0 || selectedIndex == 1) {
            onExportExcel();
        } else {
            setOpenDrawer(true);
        }
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        if (index == 0) {
            setValue(`${t("CompanyInformation")}`);
        } else if (index == 1) {
            setValue(`${t("PersonalInformation")}`);
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const fetchedPayrollEmployee = async () => {
        try {
            setIsFetching(true);
            const response = await getPayrollemployee(selectedCompany && selectedCompany.idCompany, dayjs(inputSearch).startOf("month"));
            if (response && response.data) {
                setIsFetching(false);
                setPayslip(response.data);
            } else {
                setIsFetching(false);
            }
        } catch (error) {
            setIsFetching(false);
            console.error(error);
        }
    };

    const fetchedPayrollCompany = async () => {
        try {
            setIsFetching(true);
            const response = await getPayrollVendor(selectedCompany && selectedCompany.idCompany, dayjs(inputSearch).startOf("month"));
            if (response && response.data) {
                setIsFetching(false);
                setPayRun(response.data);
            } else {
                setIsFetching(false);
            }
        } catch (error) {
            setIsFetching(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (selectedCompany) {
            fetchedPayrollCompany();
            fetchedPayrollEmployee();
        }
    }, [selectedCompany]);

    const fetchedCompanyByVendor = async () => {
        try {
            const response = await getAllCompaniesByVendor();
            if (response && response.data) {
                setCompanyLists(response.data);
            } else {
                setCompanyLists(null);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchedCompanyByVendor();
    }, []);

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const Render = (key, name) => {
        let value = "item.value." + key;

        return (
            <>
                <div style={{ marginTop: 16, alignContent: "center" }}>
                    <Typography variant="h6"> {name} </Typography>
                </div>
                <TableContainer component={Paper} variant="standard">
                    <Table sx={{ borderBottom: "none" }}>
                        <TableRow>
                            {payRun &&
                                payRun.length > 0 &&
                                payRun.map((item, index) => {
                                    if (item.value && eval(value))
                                        return (
                                            <TableCell>
                                                <Card
                                                    sx={{
                                                        minWidth: 300,
                                                        backgroundColor: colorCard[index],
                                                    }}
                                                    variant="outlined"
                                                >
                                                    <CardContent>
                                                        <Typography variant="h5" component="div">
                                                            {dayjs().set("month", item.id).format("MMMM")}{" "}
                                                            {dayjs(inputSearch).format("YYYY")}
                                                        </Typography>
                                                        <Typography color="text.secondary" sx={{ mb: 1 }}>
                                                            {`${t("Total")}`}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {`${eval(value)} ${t("Baht")}`}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </TableCell>
                                        );
                                })}
                        </TableRow>
                    </Table>
                </TableContainer>
            </>
        );
    };

    useEffect(() => {
        if (companyLists && companyLists.length > 0) {
            setSelectedCompany(companyLists[0])
        }
    }, [companyLists]);

    const onExportExcel = () => {
        if (payRun.length > 0) {
            const workBook = new ExcelJS.Workbook();
            const worksheet = workBook.addWorksheet("Payroll");

            const headerStyle = {
                font: {
                    name: "TH Sarabun New",
                    size: 20,
                    color: { argb: "FFFFFF" }
                },
                alignment: {
                    vertical: "middle",
                    horizontal: "center",
                    wrapText: true,
                },
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: "002060" }
                },
            };

            const monthNames = [
                "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
                "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
            ];

            let headerRow;
            if (value == "ข้อมูลรายบุคคล") {
                headerRow = worksheet.addRow([
                    "#",
                    "รหัสพนักงาน",
                    "ชื่อ",
                    "นามสกุล",
                    "เลขบัตรประจำตัวประชาชน",
                    "ตำแหน่งงาน",
                    "บริษัท",
                    "ส่วน",
                    "แผนก",
                    ...monthNames.flatMap((month) => [
                        `รายได้สุทธิ เดือน${month}`,
                        `เฉพาะรายรับ เดือน${month}`,
                        `เฉพาะเงินหัก เดือน${month}`,
                        `เฉพาะหักประกันสังคม เดือน ${month}`,
                        `เฉพาะหักภาษี เดือน${month}`
                    ])
                ]);

                headerRow.eachCell((cell) => {
                    cell.style = headerStyle;
                });

                worksheet.columns = [
                    { key: "#", width: 5 },
                    { key: "employeeId", width: 20 },
                    { key: "firstname", width: 20 },
                    { key: "lastname", width: 20 },
                    { key: "personalId", width: 20 },
                    { key: "position", width: 20 },
                    { key: "company", width: 20 },
                    { key: "department", width: 20 },
                    { key: "section", width: 20 },
                    ...monthNames.flatMap(() => [
                        { key: "net", width: 30 },
                        { key: "earnings", width: 30 },
                        { key: "deductions", width: 30 },
                        { key: "pf", width: 30 },
                        { key: "tax", width: 30 }
                    ])
                ];

                payslip.forEach((item, index) => {
                    const row = [
                        index + 1,
                        item.employeeId || "",
                        item.firstname || "",
                        item.lastname || "",
                        item.personalId || "",
                        item.positionsName || "",
                        item.companyName || "",
                        item.divisionName || "",
                        item.sectionName || "",
                        ...Array.from({ length: 12 }, (_, m) => {
                            const month = item[`m${m + 1}`];
                            return month ? [
                                month.net,
                                month.totalEarnings,
                                month.totalDeductions,
                                month.totalPF,
                                month.totalTax
                            ] : ["", "", "", "", ""];
                        }).flat()
                    ];

                    const excelRow = worksheet.addRow(row);
                    excelRow.eachCell((cell) => {
                        cell.style = {
                            font: { size: 18, name: 'TH SarabunPSK' },
                            alignment: { horizontal: "center", vertical: 'middle' },
                            border: {
                                top: { style: 'thin', color: { argb: '000000' } },
                                left: { style: 'thin', color: { argb: '000000' } },
                                bottom: { style: 'thin', color: { argb: '000000' } },
                                right: { style: 'thin', color: { argb: '000000' } }
                            }
                        };
                    });
                });
            } else if (value == "ข้อมูลบริษัท") {
                headerRow = worksheet.addRow([
                    "บริษัท",
                    ...Array.from({ length: 12 }, (_, i) => [
                        `รวมจ่าย เดือน${monthNames[i]}`,
                        `เงินเดือน เดือน${monthNames[i]}`,
                        `OT เดือน${monthNames[i]}`,
                        `ค่ากะ เดือน${monthNames[i]}`,
                        `ภาษีนำส่ง เดือน${monthNames[i]}`,
                        `ประกันสังคมส่วนบริษัท เดือน${monthNames[i]}`,
                        `ประกันสังคมส่วนพนักงาน เดือน${monthNames[i]}`,
                        `PFส่วนบริษัท เดือน${monthNames[i]}`,
                        `PFส่วนพนักงาน เดือน${monthNames[i]}`,
                        `เงินเพิ่ม (Addition) เดือน${monthNames[i]}`,
                        `เงินหัก (Deduction) เดือน${monthNames[i]}`
                    ]).flat()
                ]);

                headerRow.eachCell((cell) => {
                    cell.style = headerStyle;
                });

                worksheet.columns = [
                    { key: "company", width: 30 },
                    ...Array.from({ length: 12 }, () => [
                        { key: "totalPay", width: 40 },
                        { key: "salary", width: 40 },
                        { key: "ot", width: 40 },
                        { key: "shift", width: 40 },
                        { key: "tax", width: 40 },
                        { key: "companyProvident", width: 45 },
                        { key: "employeeProvident", width: 45 },
                        { key: "pfCompany", width: 40 },
                        { key: "pfEmployee", width: 40 },
                        { key: "additions", width: 40 },
                        { key: "deductions", width: 40 }
                    ]).flat()
                ];

                const companyData = { "บริษัท": userProfile.companyName };
                payRun.forEach((item, index) => {
                    companyData[`รวมจ่าย เดือน${monthNames[index]}`] = item.value.earning;
                    companyData[`เงินเดือน เดือน${monthNames[index]}`] = item.value.salary;
                    companyData[`OT เดือน${monthNames[index]}`] = item.value.ot;
                    companyData[`ค่ากะ เดือน${monthNames[index]}`] = item.value.shift;
                    companyData[`ภาษีนำส่ง เดือน${monthNames[index]}`] = item.value.tax;
                    companyData[`ประกันสังคมส่วนบริษัท เดือน${monthNames[index]}`] = item.value.provident;
                    companyData[`ประกันสังคมส่วนพนักงาน เดือน${monthNames[index]}`] = item.value.provident;
                    companyData[`PFส่วนบริษัท เดือน${monthNames[index]}`] = item.value.pfCompany;
                    companyData[`PFส่วนพนักงาน เดือน${monthNames[index]}`] = item.value.pfEmployee;
                    companyData[`เงินเพิ่ม (Addition) เดือน${monthNames[index]}`] = item.value.additions;
                    companyData[`เงินหัก (Deduction) เดือน${monthNames[index]}`] = item.value.deduction;
                });

                const row = worksheet.addRow(Object.values(companyData));
                row.eachCell((cell) => {
                    cell.style = {
                        font: { size: 18, name: 'TH SarabunPSK' },
                        alignment: { horizontal: "center", vertical: 'middle' },
                        border: {
                            top: { style: 'thin', color: { argb: '000000' } },
                            left: { style: 'thin', color: { argb: '000000' } },
                            bottom: { style: 'thin', color: { argb: '000000' } },
                            right: { style: 'thin', color: { argb: '000000' } }
                        }
                    };
                });
            };

            workBook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `AntHr-${selectedCompany && selectedCompany.companyName}-รายงาน Payroll.xlsx`;
                a.click();
                URL.revokeObjectURL(url);
            });
        } else {
            handleChangeAlertType("ไม่พบข้อมูล");
            handleOpenAlert(true);
        }
    };

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/report-center"}
                    >
                        {`${t("AllReports")}`}
                    </Link>
                    <Typography color="text.primary">{t("PayrollReport")}</Typography>
                </Breadcrumbs>
                <div style={{ marginBottom: 8 }}>
                    <Typography variant="h4" style={{ paddingTop: 8 }}>
                        {`${t("PayrollReport")}`}
                    </Typography>
                </div>
                <StyledWrapFilter>
                    <Grid container spacing={2} columns={14} alignItems="center">
                        <Grid item xs={3}>
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                    {`${t("Company")}`}
                                </Typography>
                                <div className="search-name">
                                    <SelectAllCompaniesVendor
                                        options={companyLists ? companyLists : null}
                                        value={selectedCompany}
                                        disabled={isFetching}
                                        onChange={(_, value) => {
                                            onChangeCompany(value);
                                        }}
                                    />
                                </div>
                            </StyledBoxSearch>
                        </Grid>
                        <Grid item xs={3}>
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                    {`${t("Year")}`}
                                </Typography>
                                <div className="search-name">
                                    <DatePickerCustom
                                        minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                                        maxDate={new Date()}
                                        inputFormat="YYYY"
                                        value={inputSearch}
                                        name="start"
                                        views={["year"]}
                                        openTo="year"
                                        onChange={(newValue) => {
                                            setInputSearch(newValue);
                                        }}
                                    />
                                </div>
                            </StyledBoxSearch>
                        </Grid>

                        <Grid item xs={3}>
                            <StyledBoxSearch>
                                <div className="wrap-search-action">
                                    <Fragment>
                                        <ButtonGroup ref={anchorRef} aria-label="split button">
                                            <ButtonBlue variant="outlined" onClick={handleClick}>
                                                {options[selectedIndex]}
                                            </ButtonBlue>
                                            <ButtonBlue
                                                size="small"
                                                variant="outlined"
                                                aria-controls={open ? "split-button-menu" : undefined}
                                                aria-expanded={open ? "true" : undefined}
                                                aria-label="select merge strategy"
                                                aria-haspopup="menu"
                                                onClick={handleToggle}
                                            >
                                                <ArrowDropDownIcon />
                                            </ButtonBlue>
                                        </ButtonGroup>
                                        <Popper
                                            sx={{
                                                zIndex: 1,
                                            }}
                                            open={open}
                                            anchorEl={anchorRef.current}
                                            role={undefined}
                                            transition
                                            disablePortal
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin: "center top",
                                                    }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={handleClose}>
                                                            <MenuList id="split-button-menu" autoFocusItem>
                                                                {options.map((option, index) => (
                                                                    <MenuItem
                                                                        key={option}
                                                                        disabled={index === 0 && payRun === null}
                                                                        selected={index === selectedIndex}
                                                                        onClick={(event) =>
                                                                            handleMenuItemClick(event, index)
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </Fragment>
                                </div>
                            </StyledBoxSearch>
                        </Grid>
                    </Grid>
                </StyledWrapFilter>
                {payRun && !isFetching ? (
                    <StyledRoot>
                        {Render("salary", t("Payroll"))}
                        {Render("ot", t("Overtime"))}
                        {Render("shift", t("ShiftValue"))}
                        {Render("tax", "ภาษีนำส่ง")}
                        {Render("provident", "ประกันสังคมนำส่ง")}
                        {Render("pfCompany", "กองทุน(บริษัท)นำส่ง")}
                        {Render("pfCompany", "กองทุน(พนักงาน)นำส่ง")}
                        {Render("additions", "เงินเพิ่ม")}
                        {Render("deduction", "เงินหัก")}
                        {Render("earning", "รวมจ่าย")}
                    </StyledRoot>
                ) : (
                    <Backdrop open={true} />
                )}
            </Container>

            {/* {openDrawer && (
                <DialogSetting
                    setData={setData}
                    handleClose={handleCloseDrawer}
                    open={openDrawer}
                    data={data}
                />
            )} */}

            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </StyledRoot>
    );
};

export default Payroll;


// payslip.map((item, index) => {
//     let element = {};
//     element["#"] = index + 1;
//     element["รหัสพนักงาน"] = item.employeeId;
//     element["ชื่อ"] = item.firstname;
//     element["สกุล"] = item.lastname;
//     element["เลขบัตรประจำตัวประชาชน"] = item.personalId;
//     element["ตำแหน่ง"] = item.positionsName;
//     element["บริษัท"] = item.companyName;
//     element["ส่วน"] = item.divisionName;
//     element["แผนก"] = item.sectionName;
//     for (let m = 1; m < 13; m++) {
//         let month = "item.m" + m;
//         if (eval(month)) {
//             element[`รายได้สุทธิ เดือน${m}`] = eval(month).net;
//             element[`เฉพาะรายรับ เดือน${m}`] = eval(month).totalEarnings;
//             element[`เฉพาะเงินหัก เดือน${m}`] = eval(month).totalDeductions;
//             element[`เฉพาะหักประกันสังคม เดือน ${m}`] = eval(month).totalPF;
//             element[`เฉพาะหักภาษี เดือน${m}`] = eval(month).totalTax;
//         } else {
//             element[`รายได้สุทธิ เดือน${m}`] = "";
//             element[`เฉพาะรายรับ เดือน${m}`] = "";
//             element[`เฉพาะเงินหัก เดือน${m}`] = "";
//             element[`เฉพาะหักประกันสังคม เดือน${m}`] = "";
//             element[`เฉพาะหักภาษี เดือน${m}`] = "";
//         }
//     }
//     elementList.push(element);
// });