import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Divider,
  TextField,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5),
    width: "95%",
    maxHeight: 800,
    "& .MuiGrid-container": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  dialogTitle: {
    color: "#16243D",
    padding: theme.spacing(1),
  },
  dialogContent: {
    padding: "16px 8px 24px 8px",
  },
  dialogActions: {
    marginTop: theme.spacing(1),
  },
}));

const initialValues = () => ({
  leaveWorkId: 0,
  leaveName: "",
  amount: null,
  conditions: "",
  conditionAmount: "",
  createBy: "Admin",
  dateCreate: new Date(),
});

const conditionSelect = ["อายุงาน", "Level", "ไม่มี"];

function AddOrEditModal(props) {
  const { addOrEditModal, setAddOrEditModal } = props;
  const classes = useStyles();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({
    leaveName: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!values.leaveName || !values.amount) {
      setErrors({
        ...errors,
        leaveName: values.leaveName ? false : true,
        amount: values.amount ? false : true,
      });
    } else {
      console.log("Data Submit", values);
    }
  };

  useEffect(() => {
    if (addOrEditModal.isOpen) {
      setErrors({
        leaveName: false,
      });
      setValues(
        addOrEditModal.leaveWorks.leaveWorkId === undefined
          ? initialValues
          : addOrEditModal.leaveWorks
      );
    }
  }, [addOrEditModal]);

  return (
    <Dialog open={addOrEditModal.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              เพิ่มวันลา
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={() =>
                setAddOrEditModal({ ...addOrEditModal, isOpen: false })
              }
            >
              <CloseIcon className={classes.closeBtn} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="body1">ชื่อรายการวันลา</Typography>
            <TextField
              variant="outlined"
              name="leaveName"
              value={values.leaveName}
              onChange={handleInputChange}
              margin="dense"
              fullWidth
              error={errors.leaveName}
              helperText={errors.leaveName ? "This Field is required" : null}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">จำนวนวันลา</Typography>
            <TextField
              variant="outlined"
              name="amount"
              value={values.amount}
              onChange={handleInputChange}
              margin="dense"
              fullWidth
              error={errors.amount}
              helperText={errors.amount ? "This Field is required" : null}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">เงื่อนไข</Typography>
            <FormControl margin="dense" fullWidth>
              <InputLabel>เลือกเงื่อนไข</InputLabel>
              <Select
                value={values.conditions}
                label="เลือกเงื่อนไข"
                onChange={(e) =>
                  setValues({ ...values, conditions: e.target.value })
                }
              >
                {conditionSelect.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">เงื่อนไขเพิ่มเติม</Typography>
            <FormControl margin="dense" fullWidth>
              <TextField
                label="ใส่เลข 1 - 30"
                variant="outlined"
                name="conditionAmount"
                value={values.conditionAmount}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ArrowForwardIosIcon sx={{ fontSize: "16px" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">ปี</InputAdornment>
                  ),
                }}
                endAdornment={
                  <InputAdornment position="end">ปี</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<SaveIcon />}
              onClick={handleOnSubmit}
            >
              Submit
            </ButtonBlue>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AddOrEditModal;
