import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import { Grid, Typography, Box, Container } from "@mui/material";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import {
  getSummaryTime,
  getSummaryTimeByIdEmp,
} from "../../../../actions/summary-time";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerEng from "../../shared/general/DatePickerEng";
import DatePickerThai from "../../shared/general/DatePickerThai";

import TableNormalList from "./normal/table";
import TableNormalWeekList from "./normal/table-week";

import IndividualMonthly from "./individual";

import SelectEmployees from "../shared/selectEmployees";
import SelectCompany from "../shared/selectCompany";

import loading from "../../assets/social-media.gif";

import { getPayrollSetting } from "../../../../actions/paytypes";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
  "& .select-search": {
    display: "flex",
    justifyContent: "center",
    padding: 32,
    "& .text": {
      fontStyle: "oblique",
      fontWeight: 400,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  "&.group-employee": {
    marginTop: 0,
  },
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginTop: 16,
    marginBottom: 8,
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
  },
});

const StyledLoading = styled("div")({
  marginTop: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  height: "inherit",
});

const SummaryTimePage = () => {
  const dispatch = useDispatch();
  const today = dayjs().toDate();

  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: summaryTimeList } = useSelector(
    (state) => state.summaryTimeCompany
  );
  const { isFetching: summaryTimeListFetching } = useSelector(
    (state) => state.summaryTimeCompany
  );

  const { result: summaryTime } = useSelector((state) => state.summaryTime);
 
  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(today).set("date", dayjs(today).daysInMonth()),
  });
  const [typeEmployee, setTypeEmployee] = useState("normal");
  const [company, setCompany] = useState(null);
  const [dataGroup, setDataGroup] = useState(null);
  const [selectEmployee, setSelectEmployee] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getPayrollSetting());
  }, []);

  const handleClickSearch = () => {
    dispatch(getSummaryTime(search, company));
  };

  const handleChangeTypeEmployee = (event) => {
    setTypeEmployee(event.target.value);
  };

  useEffect(() => {
    if (summaryTimeList && summaryTimeList.dataResponse) {
      
      let temp = [...summaryTimeList.dataResponse];
      setDataGroup({
        monthly: temp.length,
      });
    }
  }, [summaryTimeList]);

  useEffect(() => {
    if (selectEmployee) {
      dispatch(getSummaryTimeByIdEmp(search, selectEmployee.idEmp));
    }
  }, [selectEmployee]);

  const handleChangeCompany = (company) => {
    if (company) {
      setCompany(company.idCompany);
    } else {
      setCompany(null);
    }
  };

  const handleChangeSelectEmployee = (selected) => {
    setSelectEmployee(selected);
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {t("SummaryOFOTandShift")}
        </Typography>
        <Fragment>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("StartDate")}
                </Typography>
                <div className="search-date">
                  {localStorage.getItem("language") === "th" ? (
                    <DatePickerThai
                      disabled={selectEmployee !== null}
                      inputFormat="dd MMM yyyy"
                      value={search.start}
                      name="start"
                      onChange={(newValue) => {
                        setSearch({ ...search, ["start"]: newValue });
                      }}
                    />
                  ) : (
                    <DatePickerEng
                      disabled={selectEmployee !== null}
                      inputFormat="DD MMM YYYY"
                      value={search.start}
                      name="start"
                      onChange={(newValue) => {
                        setSearch({ ...search, ["start"]: newValue });
                      }}
                    />
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("EndDate")}
                </Typography>
                <div className="search-date">
                  {localStorage.getItem("language") === "th" ? (
                    <DatePickerThai
                      disabled={selectEmployee !== null}
                      inputFormat="dd MMM yyyy"
                      value={search.end}
                      name="end"
                      onChange={(newValue) => {
                        setSearch({ ...search, ["end"]: newValue });
                      }}
                    />
                  ) : (
                    <DatePickerEng
                      disabled={selectEmployee !== null}
                      inputFormat="DD MMM YYYY"
                      value={search.end}
                      name="end"
                      onChange={(newValue) => {
                        setSearch({ ...search, ["end"]: newValue });
                      }}
                    />
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={3}>
              {selectEmployee ? (
                <SelectEmployees
                  handleChange={handleChangeSelectEmployee}
                  selectEmployee={selectEmployee}
                  data={summaryTimeList}
                />
              ) : (
                <SelectCompany handleChangeCompany={handleChangeCompany} />
              )}
            </Grid>
            {selectEmployee === null ? (
              <Grid item xs={3}>
                <StyledBoxSearch>
                  <div className="wrap-search-action">
                    <ButtonBlue
                      disabled={company === null}
                      startIcon={<SearchRoundedIcon />}
                      variant="contained"
                      onClick={handleClickSearch}
                    >
                      {t("Search")}
                    </ButtonBlue>
                  </div>
                </StyledBoxSearch>
              </Grid>
            ) : (
              <Grid item xs={3}>
                <StyledBoxSearch>
                  <div className="wrap-search-action">
                    <ButtonBlue
                      variant="contained"
                      onClick={() => setSelectEmployee(null)}
                    >
                      {t("Back")}
                    </ButtonBlue>
                  </div>
                </StyledBoxSearch>
              </Grid>
            )}
          </Grid>
          {summaryTimeListFetching ? (
            <StyledLoading>
              <img width="80" alt="loading" src={loading} />
            </StyledLoading>
          ) : (
            <Fragment>
              {selectEmployee ? (
                <Fragment>
                  {summaryTime && (
                    <div style={{ marginBottom: 16 }}>
                      <IndividualMonthly />
                    </div>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {dataGroup ? (
                    <Fragment>
                      {dataGroup.weekly > 0 && (
                        <TableNormalWeekList
                          handleChangeSelectEmployee={
                            handleChangeSelectEmployee
                          }
                        />
                      )}
                      {dataGroup.monthly > 0 && (
                        <TableNormalList
                          company={company}
                          handleChangeSelectEmployee={
                            handleChangeSelectEmployee
                          }
                        />
                      )}
                    </Fragment>
                  ) : (
                    <div className="select-search">
                      <Typography className="text" color="text.secondary">
                        โปรดเลือกบริษัทที่ต้องการค้นหา
                      </Typography>
                    </div>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      </Container>
    </StyledRoot>
  );
};

export default SummaryTimePage;
