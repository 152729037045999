import React from "react";
import { Grid, styled } from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({});

const DialogAddPosition = (props) => {
  const { open, onClose } = props;
  const { t, i18n } = useTranslation();

  return (
    <DrawerCustom
      title={`${t("AddPosition")}`}
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <Grid container spacing={2}></Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogAddPosition;
