import {
  VENDOR_PROFILE_FETCHING,
  VENDOR_PROFILE_FAILED,
  VENDOR_PROFILE_SUCCESS,
  VENDOR_CONTRACT_FETCHING,
  VENDOR_CONTRACT_FAILED,
  VENDOR_CONTRACT_SUCCESS,
  USERS_FETCHING,
  USERS_FAILED,
  USERS_SUCCESS,
  BRANCHES_VENDOR_FETCHING,
  BRANCHES_VENDOR_SUCCESS,
  BRANCHES_VENDOR_FAILED,
  VENDOR_PROVIDENT_FUND_FETCHING,
  VENDOR_PROVIDENT_FUND_SUCCESS,
  VENDOR_PROVIDENT_FUND_FAILED,
  VENDOR_PF_SCHEDULE_FETCHING,
  VENDOR_PF_SCHEDULE_SUCCESS,
  VENDOR_PF_SCHEDULE_FAILED,
  ADMIN_LIST_FETCHING,
  ADMIN_LIST_SUCCESS,
  ADMIN_LIST_FAILED,
  EMPLOYEES_FETCHING,
  EMPLOYEES_SUCCESS,
  EMPLOYEES_FAILED,
} from "./types";

import VendorService from "../services/vendor.service";

export const getVendorProfile = (id, filter) => async (dispatch) => {
  try {
    const res = await VendorService.getVendorProfile(id, filter);
    if (res) {
      dispatch({
        type: VENDOR_PROFILE_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: VENDOR_PROFILE_FAILED,
    });
    return err;
  }
};

export const getCompanyContract = () => async (dispatch) => {
  try {
    const res = await VendorService.getCompanyContract();
    if (res) {
      dispatch({
        type: VENDOR_CONTRACT_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: VENDOR_CONTRACT_FAILED,
    });
    return err;
  }
};

export const getCompanyAndBranchContract = () => async (dispatch) => {
  try {
    const res = await VendorService.getCompanyAndBranchContract();
    if (res) {
      dispatch({
        type: VENDOR_CONTRACT_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (err) {
    dispatch({
      type: VENDOR_CONTRACT_FAILED,
    });
    return err;
  }
};

export const getAllBranchesByVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: BRANCHES_VENDOR_FETCHING,
    });
    const res = await VendorService.getAllBranchesByVendor();
    if (res) {
      dispatch({
        type: BRANCHES_VENDOR_SUCCESS,
        payload: res.data,
      });
      return res;
    }
  } catch (error) {
    dispatch({
      type: BRANCHES_VENDOR_FAILED,
    });
  }
};

export const getAllUsersVendor = (filter) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_FETCHING,
    });
    const res = await VendorService.getAllUsersVendor(filter);
    if (res) {
      dispatch({
        type: USERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USERS_FAILED,
    });
    console.log(err);
  }
};

export const getAllUsersVendorByIdCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: USERS_FETCHING,
    });
    const res = await VendorService.getAllUsersVendorByIdCompany(idCompany);
    if (res) {
      dispatch({
        type: USERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USERS_FAILED,
    });
    console.log(err);
  }
};

export const getAllProvidentFundByVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_PROVIDENT_FUND_FETCHING,
    });
    const res = await VendorService.getAllProvidentFundByVendor();
    if (res) {
      dispatch({
        type: VENDOR_PROVIDENT_FUND_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: VENDOR_PROVIDENT_FUND_FAILED,
    });
    console.log(err);
  }
};

export const updateVenderCompanyProfile = (data) => async (dispatch) => {
  try {
    const res = await VendorService.updateVenderCompanyProfile(data);
    if (res) {
      return res;
    }
  } catch (error) {
    console.log("error");
  }
};

export const updateVendorAddress = (data) => async () => {
  try {
    const res = VendorService.updateVendorAddress(data);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addNewVendorBranch = (data) => async () => {
  try {
    const res = await VendorService.addNewVendorBranch(data);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateVendorBranch = (data) => async () => {
  try {
    const res = await VendorService.updateVendorBranch(data);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getAllPFScheduleByVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_PF_SCHEDULE_FETCHING,
    });
    const res = await VendorService.getAllPFScheduleByVendor();
    if (res) {
      dispatch({
        type: VENDOR_PF_SCHEDULE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: VENDOR_PF_SCHEDULE_FAILED,
    });
    console.log(err);
  }
};

export const addPFSchedule = (data) => async () => {
  try {
    const result = await VendorService.addPFSchedule(data);
    if (result) {
      return result;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deletePFSchedule = (id) => async () => {
  try {
    const result = await VendorService.deletePFSchedule(id);
    if (result) {
      return result;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateProvidentFund = (value, listDelete) => async () => {
  try {
    // let data = {
    //   data : value,
    //   listDelete : listDelete
    // };
    // const result = await VendorService.updateProvidentFund(data);
    const result = await VendorService.updateProvidentFund(value);
    if (result) {
      return result;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteProvidentFund = (id) => async () => {
  try {
    const result = await VendorService.deleteProvidentFund(id);
    if (result) {
      return result;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addProvidentFund = (data) => async () => {
  try {
    const result = await VendorService.addProvidentFund(data);
    if (result) {
      return result;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteVendorBranch =
  (idVendorAddress, idVendorBranch) => async () => {
    try {
      let data = {
        address: idVendorAddress,
        branch: idVendorBranch,
      };
      const res = await VendorService.deleteVendorBranch(data);
      if (res) {
        return res;
      }
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
      return "Error";
    }
  };

export const updatePFSchedule = (data) => async () => {
  try {
    const result = await VendorService.updatePFSchedule(data);
    if (result) {
      return result;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addVendorLogo = (formData) => async () => {
  try {
    const res = await VendorService.addVendorLogo(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteVendorLogo = () => async () => {
  try {
    const res = await VendorService.deleteVendorLogo();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addVendorAuthorizedSignatureOne = (formData) => async () => {
  try {
    const res = await VendorService.addVendorAuthorizedSignatureOne(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteVendorAuthorizedSignatureOne = () => async () => {
  try {
    const res = await VendorService.deleteVendorAuthorizedSignatureOne();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addVendorAuthorizedSignatureTwo = (formData) => async () => {
  try {
    const res = await VendorService.addVendorAuthorizedSignatureTwo(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteVendorAuthorizedSignatureTwo = () => async () => {
  try {
    const res = await VendorService.deleteVendorAuthorizedSignatureTwo();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const addVendorWitnessSignature = (formData) => async () => {
  try {
    const res = await VendorService.addVendorWitnessSignature(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const deleteVendorWitnessSignature = () => async () => {
  try {
    const res = await VendorService.deleteVendorWitnessSignature();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getAdminVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_LIST_FETCHING,
    });
    const res = await VendorService.getAdminVendor();
    if (res) {
      dispatch({
        type: ADMIN_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ADMIN_LIST_FAILED,
    });
    console.log(err);
  }
};

export const updateAdminVendor = (formData) => async (dispatch) => {
  try {
    const res = await VendorService.updateAdminVendor(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const updateAdminVendorConfidential = (formData) => async (dispatch) => {
  try {
    const res = await VendorService.updateAdminVendorConfidential(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getAllCompanyByVendor = () => async (dispatch) => {
  try {
    dispatch({
      type: VENDOR_CONTRACT_FETCHING,
    });
    const res = await VendorService.getAllCompanyByVendor();
    if (res) {
      dispatch({
        type: VENDOR_CONTRACT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: VENDOR_CONTRACT_FAILED,
    });
    console.log(err);
  }
};

export const getSignatureCertificateVendor = async () => {
  try {
    const res = await VendorService.getSignatureCertificateVendor();
    if (res) {
      return res;
    }
  } catch (error) {
    return error.response;
  }
};

export const getEmployeesByVendorAndCompanyId = (idVendor, idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEES_FETCHING,
    });
    const res = await VendorService.getEmployeesByVendorAndCompanyId(idVendor, idCompany);
    if (res) {
      dispatch({
        type: EMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEES_FAILED,
    });
    console.log(err);
  }
};

export const getAllCompaniesByVendor = async () => {
  try {
    const res = await VendorService.getAllCompaniesByVendor();
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
}