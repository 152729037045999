import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Typography, Divider, Avatar, Badge, Box } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import FilterListIcon from "@mui/icons-material/FilterList";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import StyledCard from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";

import { getDepartment } from "./../../../../actions/department";
import { getAllUsersCompany } from "../../../../actions/user";

import dataEmployeeList from "../../assets/data/employees";
import vendor from "../../assets/data/vendor";

import ItemTable from "./ItemTable";
import FilterTable from "./filterTable";

import "./index.css";

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapHeader: {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ["@media only screen and (max-width: 600px)"]: {
      "& .MuiTypography-root": {
        fontSize: 26,
      },
    },
    ["@media only screen and (min-width:600px)"]: {
      "& .MuiTypography-root": {
        fontSize: 26,
      },
    },
    ["@media only screen and (min-width:768px)"]: {
      "& .MuiTypography-root": {
        fontSize: 34,
      },
    },
  },
  btnAddNew: {
    ["@media only screen and (max-width: 600px)"]: {
      display: "none",
    },
  },
  btnIconAddNew: {
    ["@media only screen and (min-width:600px)"]: {
      display: "none",
    },
  },
}));

const StyledContent = styled("div")({
  padding: 24,
  "& .wrapFilter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});

const StyledWrapDetail = styled("div")({
  marginTop: 24,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .information": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .MuiTypography-root": {
      marginBottom: 4,
    },
    "& .far": {
      marginRight: 8,
      color: "#999999",
    },
    "& .fa-map-marker": {
      marginRight: 13,
    },
  },
});

const StyledAvatarDetail = styled(Avatar)({
  width: 100,
  height: 100,
  marginBottom: 8,
});

const StyledDivider = styled(Divider)({
  margin: "16px 0px",
  borderWidth: "0px 0px thin",
  borderColor: "rgba(145, 158, 171, 0.24)",
  borderStyle: "dashed",
  width: "100%",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#007aff",
    border: "1px solid #007aff",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007aff",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #007aff",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const EmployeeList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: employeeList } = useSelector((state) => state.employees);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [selectedEmployee, setselectedEmployee] = useState(0);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterEmployee, setFilterEmployee] = useState({
    status: "active",
    search: "",
    idDepartment: "",
    idVendor: "",
    idJobGroup: "",
    employeeType: [],
    level: [],
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (userProfile) {
      dispatch(getDepartment(userProfile.idCompany));
    }
  }, [userProfile]);

  const handleChangeFilterEmployee = (event, newStatus, selectName) => {
    var name = event.target.name;
    var value = newStatus;
    if (newStatus === undefined) {
      setFilterEmployee({
        ...filterEmployee,
        [name]: event.target.value,
      });
    } else {
      if (name === undefined) {
        name = selectName;
      }
      setFilterEmployee({
        ...filterEmployee,
        [name]: value,
      });
    }
  };

  const rowsFilter = () => {
    if (
      filterEmployee.search == "" &&
      filterEmployee.idDepartment == "all" &&
      filterEmployee.site == "all" &&
      filterEmployee.status == "all"
    ) {
      return dataEmployeeList;
    } else {
      let resultFilter = [...dataEmployeeList];

      if (filterEmployee.status != "all") {
        resultFilter = resultFilter.filter((item) => {
          if (item.status == filterEmployee.status) return item;
        });
      }

      if (filterEmployee.search.length > 0) {
        resultFilter = resultFilter.filter((item, index) => {
          if (
            item.firstname
              .toUpperCase()
              .indexOf(filterEmployee.search.toUpperCase()) >= 0 ||
            item.lastname
              .toUpperCase()
              .indexOf(filterEmployee.search.toUpperCase()) >= 0
          )
            return item;
        });
      }

      if (filterEmployee.idDepartment != "all") {
        resultFilter = resultFilter.filter((item) => {
          if (item.idDepartment == filterEmployee.idDepartment) return item;
        });
      }

      if (filterEmployee.site != "all") {
        resultFilter = resultFilter.filter((item) => {
          if (item.site == filterEmployee.site) return item;
        });
      }
      return resultFilter;
    }
  };

  return (
    <div>
      <StyledCard>
        <StyledContent>
          <div className="wrapFilter">
            <div>
              <StyledToggleButtonGroup
                value={filterEmployee.status}
                exclusive
                onChange={handleChangeFilterEmployee}
                aria-label="filter employee"
                size="small"
              >
                <ToggleButton
                  name="status"
                  value="active"
                  aria-label="active"
                  size="small"
                  style={{ minWidth: 150 }}
                >
                  {t("NormalEmp")}
                </ToggleButton>
                <ToggleButton
                  name="status"
                  value="terminate"
                  aria-label="terminate"
                  size="small"
                  style={{ minWidth: 150 }}
                >
                  {t("Employee_Leaved")}/Blacklist
                </ToggleButton>
              </StyledToggleButtonGroup>
            </div>
            {employeeList && (
              <div>
                <Typography variant="h4" align="right">
                  {employeeList.filter((emp) => {
                    return emp.isTerminate === (filterEmployee.status === "terminate" ? true : false);
                  }).length}
                </Typography>
                <Typography
                  variant="body2"
                  align="right"
                  color="text.secondary"
                  style={{ fontWeight: 500 }}
                >
                  Total Person
                </Typography>
              </div>
            )}
          </div>
          <div>
            <ButtonBlue
              onClick={() => setFilterStatus(!filterStatus)}
              startIcon={
                filterStatus ? <FilterAltOffIcon /> : <FilterAltIcon />
              }
            >
              {`${filterStatus ? `${t("Close")}` : `${t("Open")}`}${t(
                "Filter"
              )}`}
            </ButtonBlue>
          </div>
          <Box style={{ display: "flex", position: "relative" }}>
            <FilterTable
              handleChange={handleChangeFilterEmployee}
              filterEmployee={filterEmployee}
              filterStatus={filterStatus}
            />
            <ItemTable open={filterStatus} filterEmployee={filterEmployee} />
          </Box>
        </StyledContent>
      </StyledCard>
      {selectedEmployee !== 0 && (
        <StyledCard>
          <StyledContent>
            {/* <Typography align="center">ข้อมูลพนักงาน</Typography> */}
            <StyledWrapDetail>
              <StyledAvatarDetail
                alt={selectedEmployee.username}
                src={`${process.env.REACT_APP_API_URL}image/profile/${selectedEmployee.image}`}
              />
              <Typography align="center" variant="h6">
                {`${selectedEmployee.firstname} ${selectedEmployee.lastname}`}
              </Typography>
              <Typography align="center" variant="body2" color="textSecondary">
                {`${selectedEmployee.position}`}
              </Typography>
              <Typography
                align="center"
                variant="body2"
                color="textSecondary"
                style={{ fontWeight: 600 }}
              >
                {`${selectedEmployee.department}`}
              </Typography>
              <StyledDivider />
              <div className="information">
                <Typography variant="body1">
                  <i class="far fa-phone-alt"></i>
                  {`${selectedEmployee.mobileNumber.replace(
                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                    "$1-$2-$3"
                  )}`}
                </Typography>
                <Typography variant="body1">
                  <i class="far fa-envelope-open-text"></i>
                  {`${selectedEmployee.email}`}
                </Typography>
                <Typography variant="body1">
                  <i class="far fa-map-marker"></i>
                  {`${selectedEmployee.workingLocation}`}
                </Typography>
              </div>
              <StyledDivider />
              <div>
                <ButtonBlue
                  value={"more detail"}
                  variant={"outlined"}
                  startIcon={<i class="fal fa-info-circle"></i>}
                  component={NavLink}
                  to="/admin/selectkey/add"
                >
                  {t("AdditionalInformation")}
                </ButtonBlue>
              </div>
            </StyledWrapDetail>
          </StyledContent>
        </StyledCard>
      )}
    </div>
  );
};

export default EmployeeList;
