import { httpClient } from "./httpClient";

const getExperienceByIdEmp = (idUser) => {
    return httpClient.get("experience/users/" + idUser);
};

const addExperience = (employeeProfile, data) => {
    return httpClient.post(`experience/users/${employeeProfile.idEmp}/add`, data);
};

const updateExperience = (experiencesEmp, data) => {
    return httpClient.put(`experience/update/${experiencesEmp.idExperience}`, data);
};

const deleteExperience = (idExp) => {
    return httpClient.delete("experience/delete/"+idExp);
}

export default {
    getExperienceByIdEmp,
    addExperience,
    updateExperience,
    deleteExperience
};