import { Grid, styled } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Controller, useForm } from "react-hook-form";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import {
  getVendorProfile,
  updateVenderCompanyProfile,
} from "../../../../actions/vendor";
const StyledRoot = styled("div")({
  padding: 24,
});

const DrawerProfile = (props) => {
  const { open, handleClose, data } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      vendorCode: (data && data.vendorCode) || "",
      socialSecurityAccount: (data && data.socialSecurityAccount) || "",
      authorizedSignatureOneName:
        (data && data.authorizedSignatureOneName) || "",
      authorizedSignatureOnePosition:
        (data && data.authorizedSignatureOnePosition) || "",
      authorizedSignatureTwoName:
        (data && data.authorizedSignatureTwoName) || "",
      authorizedSignatureTwoPosition:
        (data && data.authorizedSignatureTwoPosition) || "",
      witnessSignatureName: (data && data.witnessSignatureName) || "",
      witnessSignaturePosition: (data && data.witnessSignaturePosition) || "",
      registeredCapital: (data && data.registeredCapital) || "",
      providentFundCode: (data && data.providentFundCode) || "",
      establishedYear: (data && data.establishedYear) || "",
      manpowerSize: (data && data.manpowerSize) || "",
      vendorRevenue: (data && data.vendorRevenue) || "",
      vendorEmail: (data && data.vendorEmail) || "",
      vendorAbout: (data && data.vendorAbout) || "",
    },
  });

  const onSubmit = async (value) => {
    console.log("value : ", value);
    const result = await dispatch(updateVenderCompanyProfile(value));
    if (result) {
      dispatch(getVendorProfile(1));
      handleClose();
    }
  };

  return (
    <DrawerCustom
      title={t("CompanyInfo")}
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { maxWidth: 550, width: "100%" } }}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="vendorCode"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseCompanyCode")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("CompanyCode")}
                    helperText={
                      errors && errors.vendorCode && errors.vendorCode.message
                    }
                    error={errors && errors.vendorCode ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="socialSecurityAccount"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t(
                      "SocialSecurityAccount"
                    )}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    // label="Social Security A/C"
                    label={t("SocialSecurityAccount")}
                    helperText={
                      errors &&
                      errors.socialSecurityAccount &&
                      errors.socialSecurityAccount.message
                    }
                    error={
                      errors && errors.socialSecurityAccount ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="authorizedSignatureOneName"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("NameAuthorized")} 1`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    // label="Authorized Signatory Name"
                    label={`${t("NameAuthorized")} 1`}
                    helperText={
                      errors &&
                      errors.authorizedSignatureOneName &&
                      errors.authorizedSignatureOneName.message
                    }
                    error={
                      errors && errors.authorizedSignatureOneName ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="authorizedSignatureOnePosition"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("PositionAuthorized")} 1`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    // label="Authorized Signatory Position"
                    label={`${t("PositionAuthorized")} 1`}
                    helperText={
                      errors &&
                      errors.authorizedSignatureOnePosition &&
                      errors.authorizedSignatureOnePosition.message
                    }
                    error={
                      errors && errors.authorizedSignatureOnePosition
                        ? true
                        : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="authorizedSignatureTwoName"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("NameAuthorized")} 2`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    // label="Authorized Signatory Name"
                    label={`${t("NameAuthorized")} 2`}
                    helperText={
                      errors &&
                      errors.authorizedSignatureTwoName &&
                      errors.authorizedSignatureTwoName.message
                    }
                    error={
                      errors && errors.authorizedSignatureTwoName ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="authorizedSignatureTwoPosition"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("PositionAuthorized")} 2`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    // label="Authorized Signatory Position"
                    label={`${t("PositionAuthorized")} 2`}
                    helperText={
                      errors &&
                      errors.authorizedSignatureTwoPosition &&
                      errors.authorizedSignatureTwoPosition.message
                    }
                    error={
                      errors && errors.authorizedSignatureTwoPosition
                        ? true
                        : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="witnessSignatureName"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("WitnessName")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    // label="Authorized Signatory Name"
                    label={t("WitnessName")}
                    helperText={
                      errors &&
                      errors.witnessSignatureName &&
                      errors.witnessSignatureName.message
                    }
                    error={errors && errors.witnessSignatureName ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="witnessSignaturePosition"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("WitnessPosition")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    // label="Authorized Signatory Position"
                    label={t("WitnessPosition")}
                    helperText={
                      errors &&
                      errors.witnessSignaturePosition &&
                      errors.witnessSignaturePosition.message
                    }
                    error={
                      errors && errors.witnessSignaturePosition ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="registeredCapital"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("RegisteredCapital")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("RegisteredCapital")}
                    helperText={
                      errors &&
                      errors.registeredCapital &&
                      errors.registeredCapital.message
                    }
                    error={errors && errors.registeredCapital ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="providentFundCode"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t(
                      "ProvidentFundAccountNumber"
                    )}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    // label="Provident Fund Code"
                    label={t("ProvidentFundAccountNumber")}
                    helperText={
                      errors &&
                      errors.providentFundCode &&
                      errors.providentFundCode.message
                    }
                    error={errors && errors.providentFundCode ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="establishedYear"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("YearEstablishment")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("YearEstablishment")}
                    helperText={
                      errors &&
                      errors.establishedYear &&
                      errors.establishedYear.message
                    }
                    error={errors && errors.establishedYear ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="manpowerSize"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("ManpowerSize")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("ManpowerSize")}
                    helperText={
                      errors &&
                      errors.manpowerSize &&
                      errors.manpowerSize.message
                    }
                    error={errors && errors.manpowerSize ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="vendorRevenue"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("CompanyIncome")} (${t(
                      "LastYear"
                    )})`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={`${t("CompanyIncome")} (${t("LastYear")})`}
                    helperText={
                      errors &&
                      errors.vendorRevenue &&
                      errors.vendorRevenue.message
                    }
                    error={errors && errors.vendorRevenue ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="vendorEmail"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("Email")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("Email")}
                    helperText={
                      errors && errors.vendorEmail && errors.vendorEmail.message
                    }
                    error={errors && errors.vendorEmail ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="vendorAbout"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("About")}
                    multiline
                    rows={5}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="outlined" onClick={handleClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue variant="contained" type="submit">
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerProfile;
