import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import utils from "../../../../utils";

import StyledCard from "../general/CardDark";

const StyledWrapEmployees = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  "& .iconEmployee": {
    position: "absolute",
    right: 16,
    "& svg": {
      fontSize: 130,
      opacity: 0.5,
    },
    "& i": {
      fontSize: 60,
      opacity: 0.5,
    },
  },
  "& .whiteText":{
    color: "#e6e5e8",
  }
});

export default function CardSummary(props) {
  const { value, top } = props;
  return (
    <StyledCard>
      <StyledWrapEmployees>
        <div
          style={{
            padding: 24,
            width: "calc(100% - 130px)",
            display: "flex",
            flexDirection: top ? "column" : "column-reverse",
          }}
        >
          <Typography color="text.secondary" variant="body1" className="whiteText" gutterBottom>
            {value.label}
          </Typography>
          <Typography variant="h4" align={top ? "center" : "left"} className="whiteText">
            {utils.numberWithCommasWithoutDot(value.amount)}
          </Typography>
        </div>

        <div className="iconEmployee">{value.icon}</div>
      </StyledWrapEmployees>
    </StyledCard>
  );
}
