import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import {
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Divider,
} from "@mui/material";
import Dropzone from "react-dropzone";
import GalleryIcon from "../../../assets/gallery.png";
import DeleteIcon from "@mui/icons-material/Delete";

import DrawerCustom from "../../../shared/general/Drawer";
//import { addMonthlyManualSheet, getMonthlyManualSheetByIdEmployees, updateMonthlyManual } from "../../../../../actions/manualsheet";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";

const StyledContent = styled("div")({
  width: 350,
  padding: 24,
  ["@media only screen and (max-width: 375px)"]: {
    width: 320,
  },
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    marginBottom: 8,
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .btn-add": {
    display: "flex",
    justifyContent: "center",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  "&.head-text-manager": {
    fontWeight: 400,
    "&.error": {
      color: "#f15e5e",
    },
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapActionButton = styled("div")({
  // marginTop: 8,
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 12,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 4,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "4px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const DialogEdit = (props) => {
  const {
    values,
    open,
    searchDate,
    handleClose,
    handleChangeAlertType,
    handleOpenAlert,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const [editMode, setEditMode] = React.useState(null);
  const [formData, setFormData] = useState(null);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [tempformData, setTempformData] = React.useState(null);
  const [fileData, setFileData] = React.useState(null);
  const [fileStatus, setfileStatus] = React.useState({
    file: 0,
    tempfilename: null,
  });

  useEffect(() => {
    if (values.manualSheet) {
      setFormData({
        isEdit: 1,
        workingDay: values.date,
        qtyWorkday: values.manualSheet.qtyWorkday,
        otOneHours: values.manualSheet.otOne,
        otOneFiveHours: values.manualSheet.otOneFive,
        otTwoHours: values.manualSheet.otTwo,
        otThreeHours: values.manualSheet.otThree,
        leaveVacation: values.manualSheet.leaveVacation,
        leaveSick: values.manualSheet.leaveSick,
        leaveBusiness: values.manualSheet.leaveBusiness,
      });
      setTempformData({
        workingDay: values.date,
        qtyWorkday: values.manualSheet.qtyWorkday,
        otOneHours: values.manualSheet.otOne,
        otOneFiveHours: values.manualSheet.otOneFive,
        otTwoHours: values.manualSheet.otTwo,
        otThreeHours: values.manualSheet.otThree,
        leaveVacation: values.manualSheet.leaveVacation,
        leaveSick: values.manualSheet.leaveSick,
        leaveBusiness: values.manualSheet.leaveBusiness,
      });
      if (values.manualSheet.filename) {
        setfileStatus({ file: 1, tempfilename: values.manualSheet.filename });
      }
    } else {
      setFormData({
        isEdit: 0,
        workingDay: values.date,
        qtyWorkday: 0,
        otOneHours: { hour: 0, minute: 0 },
        otOneFiveHours: { hour: 0, minute: 0 },
        otTwoHours: { hour: 0, minute: 0 },
        otThreeHours: { hour: 0, minute: 0 },
        leaveVacation: 0,
        leaveSick: 0,
        leaveBusiness: 0,
      });
      setTempformData({
        workingDay: values.date,
        qtyWorkday: 0,
        otOneHours: { hour: 0, minute: 0 },
        otOneFiveHours: { hour: 0, minute: 0 },
        otTwoHours: { hour: 0, minute: 0 },
        otThreeHours: { hour: 0, minute: 0 },
        leaveVacation: 0,
        leaveSick: 0,
        leaveBusiness: 0,
      });
    }
  }, [values]);

  const handleChangeEditFormData = (editname) => {
    if (editMode) {
      rollbackFormData(editMode);
    }
    setEditMode(editname);
  };

  const handleChangefile = (event) => {
    const { name, value } = event.target;
    console.log("handleChangefile", name, value);
    if (Number(value) === 0) {
      setFileData(null);
    }
    setfileStatus({ file: Number(value), tempfilename: null });
  };

  const handleChange = (unit) => (event) => {
    const { name, value } = event.target;
    if (unit === "") {
      setTempformData({
        ...tempformData,
        [name]: Number(value),
      });
    } else {
      setTempformData({
        ...tempformData,
        [name]: {
          ...tempformData[name],
          [unit]: Number(value) > 0 ? Number(value) : 0,
        },
      });
    }
  };

  const rollbackFormData = (editname) => {
    if (editname) {
      if (editname === "workingDay") {
        setTempformData({
          ...tempformData,
          qtyWorkday: formData.qtyWorkday,
        });
      }
      if (editname === "editOT") {
        setTempformData({
          ...tempformData,
          otOneHours: formData.otOneHours,
          otOneFiveHours: formData.otOneFiveHours,
          otTwoHours: formData.otTwoHours,
          otThreeHours: formData.otThreeHours,
        });
      }
      if (editname === "editLeave") {
        setTempformData({
          ...tempformData,
          leaveVacation: formData.leaveVacation,
          leaveSick: formData.leaveSick,
          leaveBusiness: formData.leaveBusiness,
        });
      }
    }
  };

  const handleChangeSubmit = (name) => {
    if (name === "workingDay") {
      setFormData({
        ...formData,
        qtyWorkday: tempformData.qtyWorkday,
      });
    } else {
      if (name === "editOT") {
        setFormData({
          ...formData,
          otOneHours: tempformData.otOneHours,
          otOneFiveHours: tempformData.otOneFiveHours,
          otTwoHours: tempformData.otTwoHours,
          otThreeHours: tempformData.otThreeHours,
        });
      } else {
        if (name === "editLeave") {
          setFormData({
            ...formData,
            leaveVacation: tempformData.leaveVacation,
            leaveSick: tempformData.leaveSick,
            leaveBusiness: tempformData.leaveBusiness,
          });
        }
      }
    }
    setEditMode(null);
  };

  const disabledBtn = () => {
    if (editMode || formData) {
      if (formData.qtyWorkday === 0) {
        return true;
      }
      if (editMode) {
        return true;
      }
    }
  };

  const handleSubmit = async () => {
    // if(editMode){
    //   alert("กรุณากดบันทึก");
    // }else{
    //   console.log("formData",formData);
    //   let data = {
    //     idEmp : userProfile.idEmp,
    //     idVendor : userProfile.idVendor,
    //     WorkingMonth : values.date,
    //     qtyWorkday : formData.qtyWorkday,
    //     otOne : (formData.otOneHours.hour * 60) + formData.otOneHours.minute,
    //     otOneFive : (formData.otOneFiveHours.hour * 60) + formData.otOneFiveHours.minute,
    //     otTwo : (formData.otTwoHours.hour * 60) + formData.otTwoHours.minute,
    //     otThree : (formData.otThreeHours.hour * 60) + formData.otThreeHours.minute,
    //     leaveVacation : formData.leaveVacation,
    //     leaveSick : formData.leaveSick,
    //     leaveBusiness : formData.leaveBusiness,
    //     createDate : dayjs().format("YYYY-MM-DD HH:mm:ss"),
    //   }
    //   if(values.manualSheet && values.manualSheet.filename){
    //     data.file = { oldname : values.manualSheet.filename, newname : (fileData && fileData.path) || fileStatus.tempfilename}
    //   }
    //   const postData = new FormData;
    //   Object.keys(data).map((k)=>{
    //     postData.append(k,JSON.stringify(data[k]))
    //   })
    //   if(fileData){
    //     postData.append("file",fileData)
    //   }
    //   console.log("postData.file",postData);
    //   let result
    //   if(formData.isEdit === 0){
    //     result = await dispatch(addMonthlyManualSheet(postData))
    //   }else{
    //     postData.append("idTimeTable_Monthly",values.manualSheet.idTimeTable_Monthly)
    //     result = await dispatch(updateMonthlyManual(postData))
    //   }
    //   if(result){
    //     if(!result.errorCode){
    //       handleChangeAlertType("success");
    //       handleOpenAlert(true);
    //       await dispatch(getMonthlyManualSheetByIdEmployees(searchDate));
    //     }
    //   }else{
    //     handleOpenAlert(true);
    //     handleChangeAlertType("error");
    //   }
    //   handleClose();
    // }
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title={t("ManualTimesheet")}
      anchor={"right"}
    >
      {values && (
        <StyledContent>
          <Typography gutterBottom>
            {`${t("WorkingDate")}`}:{" "}
            {dayjs(values.date)
              .locale(localStorage.getItem("language") || "th")
              .format("MMMM YYYY ")}
          </Typography>
          {tempformData && (
            <Grid container alignItems={"center"} spacing={2}>
              <Fragment>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <StyledContentLabel>{t("WorkDay")}</StyledContentLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldTheme
                      style={{ marginBottom: 10 }}
                      name="qtyWorkday"
                      value={tempformData.qtyWorkday}
                      onChange={handleChange("")}
                      InputProps={{
                        readOnly: editMode !== "workingDay",
                        inputComponent: NumberFormatTheme,
                      }}
                    />
                  </Grid>
                </Grid>
                <Fragment>
                  {editMode !== "workingDay" ? (
                    <StyledWrapActionButton>
                      <ButtonBlue
                        onClick={() => handleChangeEditFormData("workingDay")}
                      >
                        {t("Edit")}
                      </ButtonBlue>
                    </StyledWrapActionButton>
                  ) : (
                    <Fragment>
                      <StyledWrapActionButton>
                        <ButtonBlue
                          variant="text"
                          onClick={() => handleChangeEditFormData(null)}
                        >
                          {t("Cancel")}
                        </ButtonBlue>
                        <ButtonBlue
                          variant="contained"
                          onClick={() => handleChangeSubmit("workingDay")}
                        >
                          {t("Save")}
                        </ButtonBlue>
                      </StyledWrapActionButton>
                    </Fragment>
                  )}
                </Fragment>
                <StyledDivider />
              </Fragment>
              <Fragment>
                <Fragment>
                  <Grid item xs={12} sm={12}>
                    <StyledContentLabel>
                      OT 1
                      <Typography component={"span"} color="text.secondary">
                        {` ( ${t("Hours")} : ${t("Minute")} )`}
                      </Typography>
                    </StyledContentLabel>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: 4 }}>
                        <TextFieldTheme
                          name="otOneHours"
                          // unit="hour"
                          value={tempformData.otOneHours.hour}
                          onChange={handleChange("hour")}
                          InputProps={{
                            readOnly: editMode !== "editOT",
                            inputComponent: NumberFormatTheme,
                          }}
                        />
                      </div>
                      <div style={{ width: "100%", marginLeft: 4 }}>
                        <TextFieldTheme
                          name="otOneHours"
                          // unit="minute"
                          value={tempformData.otOneHours.minute}
                          onChange={handleChange("minute")}
                          InputProps={{
                            readOnly: editMode !== "editOT",
                            inputComponent: NumberFormatTheme,
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Fragment>
                <Fragment>
                  <Grid item xs={12} sm={12}>
                    <StyledContentLabel>
                      OT 1.5
                      <Typography component={"span"} color="text.secondary">
                        {` ( ${t("Hours")} : ${t("Minute")} )`}
                      </Typography>
                    </StyledContentLabel>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: 4 }}>
                        <TextFieldTheme
                          name="otOneFiveHours"
                          value={tempformData.otOneFiveHours.hour}
                          onChange={handleChange("hour")}
                          InputProps={{
                            readOnly: editMode !== "editOT",
                            inputComponent: NumberFormatTheme,
                          }}
                        />
                      </div>
                      <div style={{ width: "100%", marginLeft: 4 }}>
                        <TextFieldTheme
                          name="otOneFiveHours"
                          value={tempformData.otOneFiveHours.minute}
                          onChange={handleChange("minute")}
                          InputProps={{
                            readOnly: editMode !== "editOT",
                            inputComponent: NumberFormatTheme,
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Fragment>
                <Fragment>
                  <Grid item xs={12} sm={12}>
                    <StyledContentLabel>
                      OT 2
                      <Typography component={"span"} color="text.secondary">
                        {` ( ${t("Hours")} : ${t("Minute")} )`}
                      </Typography>
                    </StyledContentLabel>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: 4 }}>
                        <TextFieldTheme
                          name="otTwoHours"
                          value={tempformData.otTwoHours.hour}
                          onChange={handleChange("hour")}
                          InputProps={{
                            readOnly: editMode !== "editOT",
                            inputComponent: NumberFormatTheme,
                          }}
                        />
                      </div>
                      <div style={{ width: "100%", marginLeft: 4 }}>
                        <TextFieldTheme
                          name="otTwoHours"
                          value={tempformData.otTwoHours.minute}
                          onChange={handleChange("minute")}
                          InputProps={{
                            readOnly: editMode !== "editOT",
                            inputComponent: NumberFormatTheme,
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Fragment>
                <Fragment>
                  <Grid item xs={12} sm={12}>
                    <StyledContentLabel>
                      OT 3
                      <Typography component={"span"} color="text.secondary">
                        {` ( ${t("Hours")} : ${t("Minute")} )`}
                      </Typography>
                    </StyledContentLabel>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: 4 }}>
                        <TextFieldTheme
                          name="otThreeHours"
                          value={tempformData.otThreeHours.hour}
                          onChange={handleChange("hour")}
                          InputProps={{
                            readOnly: editMode !== "editOT",
                            inputComponent: NumberFormatTheme,
                          }}
                        />
                      </div>
                      <div style={{ width: "100%", marginLeft: 4 }}>
                        <TextFieldTheme
                          style={{ marginBottom: 10 }}
                          name="otThreeHours"
                          value={tempformData.otThreeHours.minute}
                          onChange={handleChange("minute")}
                          InputProps={{
                            readOnly: editMode !== "editOT",
                            inputComponent: NumberFormatTheme,
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Fragment>
              </Fragment>
              <Fragment>
                {editMode !== "editOT" ? (
                  <StyledWrapActionButton>
                    <ButtonBlue
                      onClick={() => handleChangeEditFormData("editOT")}
                    >
                      {t("Edit")}
                    </ButtonBlue>
                  </StyledWrapActionButton>
                ) : (
                  <Fragment>
                    <StyledWrapActionButton>
                      <ButtonBlue
                        variant="text"
                        onClick={() => handleChangeEditFormData(null)}
                      >
                        {t("Cancel")}
                      </ButtonBlue>
                      <ButtonBlue
                        variant="contained"
                        onClick={() => handleChangeSubmit("editOT")}
                      >
                        {t("Save")}
                      </ButtonBlue>
                    </StyledWrapActionButton>
                  </Fragment>
                )}
              </Fragment>
              <StyledDivider />
              <Fragment>
                <Grid item xs={12}>
                  <Grid container xs={12}>
                    <StyledContentLabel>{t("leaveAbsent")}</StyledContentLabel>
                  </Grid>
                  <Grid container xs={12}>
                    <Grid
                      item
                      xs
                      sm={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <StyledContentLabel>ลาพักร้อน</StyledContentLabel>
                    </Grid>
                    <Grid item xs sm={8} style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: 4 }}>
                        <TextFieldTheme
                          style={{ marginBottom: 10 }}
                          name="leaveVacation"
                          value={tempformData.leaveVacation}
                          onChange={handleChange("")}
                          InputProps={{
                            readOnly: editMode !== "editLeave",
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container xs={12}>
                    <Grid
                      item
                      xs
                      sm={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <StyledContentLabel>ลาป่วย</StyledContentLabel>
                    </Grid>
                    <Grid item xs sm={8} style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: 4 }}>
                        <TextFieldTheme
                          style={{ marginBottom: 10 }}
                          name="leaveSick"
                          value={tempformData.leaveSick}
                          onChange={handleChange("")}
                          InputProps={{
                            readOnly: editMode !== "editLeave",
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container xs={12}>
                    <Grid
                      item
                      xs
                      sm={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <StyledContentLabel>ลากิจ</StyledContentLabel>
                    </Grid>
                    <Grid item xs sm={8} style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: 4 }}>
                        <TextFieldTheme
                          style={{ marginBottom: 10 }}
                          name="leaveBusiness"
                          value={tempformData.leaveBusiness}
                          onChange={handleChange("")}
                          InputProps={{
                            readOnly: editMode !== "editLeave",
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                {editMode !== "editLeave" ? (
                  <StyledWrapActionButton>
                    <ButtonBlue
                      onClick={() => handleChangeEditFormData("editLeave")}
                    >
                      {t("Edit")}
                    </ButtonBlue>
                  </StyledWrapActionButton>
                ) : (
                  <Fragment>
                    <StyledWrapActionButton>
                      <ButtonBlue
                        variant="text"
                        onClick={() => handleChangeEditFormData(null)}
                      >
                        {t("Cancel")}
                      </ButtonBlue>
                      <ButtonBlue
                        variant="contained"
                        onClick={() => handleChangeSubmit("editLeave")}
                      >
                        {t("Save")}
                      </ButtonBlue>
                    </StyledWrapActionButton>
                  </Fragment>
                )}
                <StyledDivider />
              </Fragment>
              <Fragment>
                <Grid item xs={12}>
                  <StyledFormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="gender"
                      name="file"
                      value={fileStatus.file}
                      onChange={handleChangefile}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label={t("AttachFile")}
                      />
                    </RadioGroup>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12}>
                  {fileStatus.file === 1 &&
                    !fileData &&
                    !fileStatus.tempfilename && (
                      <StyledDropzone>
                        <Dropzone
                          accept="image/jpeg, image/png"
                          maxFiles={1}
                          multiple={false}
                          maxSize={3145728}
                          onDrop={(acceptedFiles, rejectedFiles) => {
                            if (acceptedFiles.length > 0) {
                              console.log("acceptedFiles", acceptedFiles);
                              setFileData(acceptedFiles[0]);
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              {...getRootProps({ className: "dropzone-leave" })}
                            >
                              <div className="inner-dropzone">
                                <input {...getInputProps()} />
                                <div style={{ textAlign: "center" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <img alt="add" src={GalleryIcon} />
                                  </div>
                                  <Typography
                                    variant="caption"
                                    style={{
                                      lineHeight: 0.5,
                                      color: "#999999",
                                    }}
                                  >
                                    Allowed *.jpeg, *.jpg, *.png, max size of 3
                                    MB
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </StyledDropzone>
                    )}

                  {fileStatus.file === 1 &&
                    (fileData || fileStatus.tempfilename) && (
                      <Box
                        style={{ position: "relative", textAlign: "center" }}
                      >
                        <IconButton
                          style={{ position: "absolute", top: -8, right: 0 }}
                          color="error"
                          onClick={() => {
                            setFileData(null);
                            setfileStatus({
                              ...fileStatus,
                              tempfilename: null,
                            });
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <div>
                          <Typography>
                            {(fileData && fileData.path) ||
                              fileStatus.tempfilename}
                          </Typography>
                        </div>
                      </Box>
                    )}
                </Grid>
              </Fragment>
            </Grid>
          )}
          <StyledFooter>
            <ButtonBlue
              className="cancel"
              color="secondary"
              variant="text"
              onClick={handleClose}
            >
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue
              disabled={disabledBtn() || isSubmit}
              variant="contained"
              onClick={() => {
                handleSubmit();
              }}
            >
              {t("Save")}
            </ButtonBlue>
          </StyledFooter>
        </StyledContent>
      )}
    </DrawerCustom>
  );
};

export default DialogEdit;
