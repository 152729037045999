import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, styled, Typography } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { updateUser, getUserProfile } from "../../../../actions/user";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const phoneRegExp = /^0\d\d\d\d\d\d\d\d\d$/;
const validationSchema = Yup.object({
  telephoneMobile: Yup.string()
    .required("โปรดกรอกข้อมูล")
    .matches(phoneRegExp, "เบอร์โทรศัพท์ไม่ถูกต้อง")
    .nullable(),
});

const DialogContact = (props) => {
  const { open, handleCloseDialog, handleChangeAlertType, handleOpenAlert } =
    props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [state, setstate] = useState(false);

  useEffect(() => {
    setstate(false);
    formik.resetForm();
    formik.setFieldValue("idEmp", userProfile.idEmp);
    formik.setFieldValue("telephoneMobile", userProfile.telephoneMobile);
    formik.setFieldValue("email", userProfile.email);
  }, []);

  const formik = useFormik({
    initialValues: {
      idEmp: "",
      telephoneMobile: "",
      email: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async () => {
    setstate(true);
    const result = await dispatch(updateUser(formik.values));
    if (result && result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert();
      setTimeout(function () {
        handleCloseDialog();
        dispatch(getUserProfile());
      }, 3000);
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  return (
    <DrawerCustom
      title={t("Edit_Data")}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
    >
      <form onSubmit={formik.handleSubmit}>
        <StyledRoot>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("PhoneNumber")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="telephoneMobile"
                value={formik.values.telephoneMobile}
                onChange={formik.handleChange}
                error={
                  formik.touched.telephoneMobile &&
                  Boolean(formik.errors.telephoneMobile)
                }
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.telephoneMobile &&
                  Boolean(formik.errors.telephoneMobile) &&
                  formik.errors.telephoneMobile
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Email")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={
                  formik.touched.email &&
                  Boolean(formik.errors.email)
                }
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.email &&
                  Boolean(formik.errors.email) &&
                  formik.errors.email
                }
              />
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleCloseDialog}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue
              type="submit"
              variant="contained"
              disabled={!formik.isValid || state}
            >
              {t("Save")}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      </form>
    </DrawerCustom>
  );
};

export default DialogContact;
