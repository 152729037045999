import React, { useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Box, CardContent, Chip, IconButton, Typography } from "@mui/material";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import DialogConfirmWithdraw from "../../../../shared/general/DialogConfirmWithdraw";
import CardStyled from "../../../../shared/general/Card";

import StatusRequest from "./statusRequest";

const StyledChip = styled(Chip)({
  backgroundColor: "#ebf5ff",
  color: "#007aff",
  fontSize: 16,
  fontWeight: 500,
  "&.not-approve": {
    backgroundColor: "#EEEEEE",
    color: "#919eab",
  },
});

const StyledWrapChip = styled("div")({
  display: "flex",
  justifyContent: "center",
  margin: "8px 0",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  alignItems: "center",
  "&.approve": {
    justifyContent: "space-between",
  },
  "&.not-approve": {
    justifyContent: "flex-end",
  },
});

const StyledTextDate = styled(Typography)({
  "&.not-approve": {
    color: "#919eab",
    fontStyle: "oblique",
    fontWeight: 400,
  },
});

const CardHistoryLeave = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();
  return (
    <div>
      <CardStyled>
        <CardContent style={{ padding: 16 }}>
          <StyledWrapHead
            className={data.isApprove !== 0 ? "approve" : "not-approve"}
          >
            <Box display="flex" gap="8px">
              {data.isApprove !== 0 && (
                <IconButton aria-label="delete" size="small">
                  <DeleteOutlineRoundedIcon fontSize="inherit" />
                </IconButton>
              )}
              {data.filename && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  component={"a"}
                  href={data.fileURL}
                  target="_blank"
                >
                  <AttachFileIcon fontSize="inherit" />
                </IconButton>
              )}
            </Box>

            <StatusRequest
              status={data.isApprove ? 1 : data.approveDate ? 2 : 0}
            />
          </StyledWrapHead>
          <StyledWrapChip>
            <StyledChip
              className={data.isApprove === 0 ? "not-approve" : ""}
              label={`${data.name} ${data.used} ${t("Days")}`}
            />
          </StyledWrapChip>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2" color="text.secondary">
                {t("Start")}
              </Typography>
              <StyledTextDate
                className={data.isApprove === 0 ? "not-approve" : ""}
                variant="h6"
              >
                {dayjs(data.startText, "DD/MM/YYYY HH:mm")
                  .locale(localStorage.getItem("language") || "th")
                  .format(
                    localStorage.getItem("language") === "th"
                      ? "DD/MM/BBBB"
                      : "DD/MM/YYYY"
                  )}
              </StyledTextDate>
              <StyledTextDate
                className={data.isApprove === 0 ? "not-approve" : ""}
                variant="h6"
                style={{ height: 32 }}
              >
                {dayjs(data.startText, "DD/MM/YYYY HH:mm").format("HH:mm") !=
                "00:00"
                  ? dayjs(data.startText, "DD/MM/YYYY HH:mm").format("HH:mm")
                  : ""}
              </StyledTextDate>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2" align="right" color="text.secondary">
                {t("End")}
              </Typography>
              <StyledTextDate
                className={data.isApprove === 0 ? "not-approve" : ""}
                variant="h6"
                align="right"
              >
                {dayjs(data.endText, "DD/MM/YYYY HH:mm")
                  .locale(localStorage.getItem("language") || "th")
                  .format(
                    localStorage.getItem("language") === "th"
                      ? "DD/MM/BBBB"
                      : "DD/MM/YYYY"
                  )}
              </StyledTextDate>
              <StyledTextDate
                className={data.isApprove === 0 ? "not-approve" : ""}
                variant="h6"
                align="right"
                style={{ height: 32 }}
              >
                {dayjs(data.endText, "DD/MM/YYYY HH:mm").format("HH:mm") !=
                "00:00"
                  ? dayjs(data.endText, "DD/MM/YYYY HH:mm").format("HH:mm")
                  : ""}
              </StyledTextDate>
            </div>
          </div>
          {/* <Divider style={{ margin: "8px" }} /> */}
          <div style={{ padding: 8 }}>
            <Typography
              color="text.third"
              style={{
                fontSize: 16,
                fontStyle: "oblique",
              }}
            >
              {`${t("Reason")}: ${data.description ? data.description : "-"}`}
            </Typography>
          </div>
        </CardContent>
      </CardStyled>
      <DialogConfirmWithdraw mode={"2"} data={data} />
    </div>
  );
};

export default CardHistoryLeave;
