import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";

import StatusRequest from "../../../shared/general/stausRequest";

const StyledTableContainer = styled(TableContainer)({
  overflowX: "auto",
  width: "100%",
  height: 450,
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  minWidth: 150,
  "&.manager": {
    minWidth: 280,
  },
  "&.status": {
    minWidth: 50,
  },
  "&.reason": {
    minWidth: 200,
  },
  "&.other-reason": {
    minWidth: 250,
  },
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledTableCellContent = styled(TableCell)({
  borderBottom: "none",
  padding: "8px 16px",
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  "&.status": {
    minWidth: 50,
    "& .MuiBox-root": {
      margin: "auto",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.15)",
      },
    },
  },
  "& .fullname": {
    width: 250,
    "& .MuiAvatar-root": {
      marginRight: 16,
    },
    display: "flex",
    alignItems: "center",
    "& .name": {
      fontWeight: 600,
      fontSize: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 300,
    },
  },
  "& .actual-plan": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 200,
    margin: "auto",
    padding: "0 20px",
  },
  "& .actual": {
    fontWeight: 600,
    fontSize: 24,
  },
  "& .plan": {
    fontWeight: 600,
    color: "#919eab",
  },
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const TableRequest = (props) => {
  const { data } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { t } = useTranslation();

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderOT = (row) => {
    let payrollSetting = row.payrollSetting;

    let textRow = [];
    if (payrollSetting) {
      if (row.xOT > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" key={"xOT"}>
            {`OT x ${payrollSetting.xOT}`} ={" "}
            {parseFloat(row.xOT / 60).toFixed(2)} {`${t("Hrs")}`}
          </Typography>
        );
      }
      if (row.xWorkingDailyHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" key={"xWorkingDailyHoliday"}>
            {`OT x ${payrollSetting.xWorkingDailyHoliday}`} ={" "}
            {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)}{" "}
            {`${t("Hrs")}`}
          </Typography>
        );
      }
      if (row.xWorkingMonthlyHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" key={"xWorkingMonthlyHoliday"}>
            {`OT x ${payrollSetting.xWorkingMonthlyHoliday}`} ={" "}
            {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)}{" "}
            {`${t("Hrs")}`}
          </Typography>
        );
      }
      if (row.xOTHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" key={"xOTHoliday"}>
            {`OT x ${payrollSetting.xOTHoliday}`} ={" "}
            {parseFloat(row.xOTHoliday / 60).toFixed(2)} {`${t("Hrs")}`}
          </Typography>
        );
      }
      if (textRow.length === 0) {
        return <Typography color="text.third">{""}</Typography>;
      } else {
        return textRow;
      }
    }
  };

  const whatLevel = (row) => {
    if (row.isDoubleApproval) {
      if (row.isManagerLV1Approve === 1) {
        return 2;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  };

  const isRequestApprove = (row) => {
    if (row.idRequestTimeWithdraw) {
      return row.isApprove;
    } else {
      if (
        row.isManagerLV1Approve === null &&
        row.isManagerLV2Approve === null
      ) {
        return null;
      } else {
        if (row.isDoubleApproval) {
          if (row.isManagerLV1Approve === null) {
            return null;
          } else if (row.isManagerLV1Approve === 1) {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          } else {
            return row.isManagerLV1Approve;
          }
        } else {
          if (row.approvalLevel === 1) {
            if (row.isManagerLV1Approve === null) {
              return null;
            } else {
              return row.isManagerLV1Approve;
            }
          } else {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          }
        }
      }
    }
  };
  return (
    <Fragment>
      <StyledTableContainer>
        <Table
          stickyHeader
          style={{ minWidth: 650 }}
          aria-label="sticky simple table"
        >
          <TableHead>
            <TableRow>
              <StyledCellHeader
                className="status"
                align="center"
              ></StyledCellHeader>
              <StyledCellHeader>{`${t("FullName")}/${t(
                "Position"
              )}`}</StyledCellHeader>
              <StyledCellHeader>{t("RequestType")}</StyledCellHeader>
              <StyledCellHeader align="center">
                {t("StartTime")}
              </StyledCellHeader>
              <StyledCellHeader align="center">{t("EndTime")}</StyledCellHeader>
              <StyledCellHeader align="center" className="reason">
                {t("Reason")}
              </StyledCellHeader>
              <StyledCellHeader align="center" className="other-reason">
                {t("OtherReason")}
              </StyledCellHeader>
              <StyledCellHeader className="manager">
                {t("Report_To")} 1
              </StyledCellHeader>
              <StyledCellHeader className="manager">
                {t("Report_To")} 2
              </StyledCellHeader>
            </TableRow>
          </TableHead>
          <TableBody style={{ minHeight: 500 }}>
            {(data && data.length > 0) ?
              data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((request, index) => (
                  <TableRow key={`${request.idRequestTime}${index}`}>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        step={whatLevel(request)}
                        status={isRequestApprove(request)}
                        active={request.isActive}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <div className="fullname">
                        <Avatar
                          alt={`0100-000838`}
                          // src={`${process.env.REACT_APP_API_URL}image/profile/${
                          //   Math.floor(Math.random() * 40) + 1
                          // }.jpg`}
                          src=""
                        />
                        <div>
                          <Typography className="name">
                            {`${request.firstname} ${request.lastname}`}
                          </Typography>
                          <Typography
                            color="text.third"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: 200,
                            }}
                          >
                            {request.positionsName}
                          </Typography>
                        </div>
                      </div>
                    </StyledTableCellContent>

                    <StyledTableCellContent>
                      <Typography>{t("OtRequest")}</Typography>
                      {renderOT(request)}
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.startText, "DD/MM/YYYY HH:mm").format(
                          localStorage.getItem("language") === "en" ? "DD/MM/YYYY" : "DD/MM/BBBB"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center" variant="h6">
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body2"
                        color="text.third"
                      >
                        {dayjs(request.endText, "DD/MM/YYYY HH:mm").format(
                          localStorage.getItem("language") === "en" ? "DD/MM/YYYY" : "DD/MM/BBBB"
                        )}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.requestReasonName || "-"}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {request.otherReason || "-"}
                      </Typography>
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography>{request.managerLV1Name || "-"}</Typography>
                      {request.managerLV1ApproveDate ? (
                        <Typography variant="body2" color="text.secondary">
                          {t("ApprovalDate")}
                          {dayjs(request.managerLV1ApproveDateText, "DD/MM/YYYY HH:mm").format(
                          localStorage.getItem("language") === "en" ? "DD/MM/YYYY HH:mm" : "DD/MM/BBBB HH:mm"
                        )}
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          {request.emailManagerLV1 || "-"}
                        </Typography>
                      )}
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      {request.isDoubleApproval === 1 ||
                      request.approvalLevel === 2 ? (
                        <Fragment>
                          <Typography>{request.managerLV2Name || "-"}</Typography>
                          {request.managerLV2ApproveDate ? (
                            <Typography variant="body2" color="text.secondary">
                              {t("ApprovalDate")}
                              {dayjs(request.managerLV2ApproveDateText, "DD/MM/YYYY HH:mm").format(
                          localStorage.getItem("language") === "en" ? "DD/MM/YYYY HH:mm" : "DD/MM/BBBB HH:mm"
                        )}
                            </Typography>
                          ) : (
                            <Typography variant="body2" color="text.secondary">
                              {request.emailManagerLV2 || "-"}
                            </Typography>
                          )}
                        </Fragment>
                      ) : (
                        "-"
                      )}
                    </StyledTableCellContent>
                  </TableRow>
                ))
                :
                <TableRow>
                  <StyledTableCellContent colSpan={9}>
                    <Typography 
                      align="center"
                      style={{
                        paddingTop: 32,
                        paddingBottom: 32
                      }}
                    >ไม่พบรายการ</Typography>
                  </StyledTableCellContent>
                </TableRow>
              }
          </TableBody>
        </Table>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Fragment>
  );
};

export default TableRequest;
