import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  styled,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from 'react-hook-form';

import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import customParseFormat from "dayjs/plugin/customParseFormat";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import ButtonBlue from "../general/ButtonBlue";
import DrawerCustom from "../general/Drawer";
import DatePickerEng from "../general/DatePickerEng";
import DatePickerThai from "../general/DatePickerThai";
import TimePicker from "../general/TimePicker";

import {
  addRequestTime,
  updateRequestTime,
  getVendorRequestReason,
} from "../../../../actions/requestTime";
import {
  getAttendanceById,
  getAttendanceCompanyByIdEmp,
} from "../../../../actions/attendance";
import { getPayrollSetting } from "../../../../actions/paytypes";
import StatusRequest from "../general/stausRequest";
import CalculateOTFunction from "./calculateOT";
import calculateService from "../../../../services/calculate.service";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
  ["@media only screen and (max-width: 375px)"]: {
    width: 320,
  },
  "& .wrap-status": {
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      marginRight: 8,
    },
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledWrapHoursOT = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .label": {
    fontWeight: 500,
  },
});

const DialogNewRequest = (props) => {
  const {
    values,
    open,
    handleCloseDialog,
    handleChangeAlertType,
    handleOpenAlert,
    handleOpenDialogConfirmDelete,
    searchDate,
    attendanceListState,
    employeeProfile,
    isUserTimelinePage,
    isFillIn,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  dayjs.extend(customParseFormat);
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  const { user: authUser } = useSelector((state) => state.auth);
  const { result: requestTimeReasonList } = useSelector(
    (state) => state.requestTimeReason
  );
  const { setValue, watch } = useForm();

  const [formInputData, setFormData] = useState(null);
  const [mode, setMode] = useState("read");
  const [isSubmit, setIsSubmit] = useState(false);
  const [payrollSetting, setPayrollSetting] = useState(null);

  useEffect(() => {
    dispatch(
      getVendorRequestReason({
        idVendor: employeeProfile.idVendor,
        idCompany: employeeProfile.idCompany,
      })
    );
    if (!isUserTimelinePage) {
      dispatch(getPayrollSetting());
    }
  }, []);

  useEffect(() => {
    if (values.idRequestTime) {
      setFormData({
        idRequestTime: values.idRequestTime,
        idRequestType: values.idRequestType,
        startDate: dayjs(values.startText, "DD/MM/YYYY HH:mm"),
        startMinute: dayjs(values.startText, "DD/MM/YYYY HH:mm").format("m"),
        startHours: dayjs(values.startText, "DD/MM/YYYY HH:mm").format("H"),
        endDate: dayjs(values.endText, "DD/MM/YYYY HH:mm"),
        endMinute: dayjs(values.endText, "DD/MM/YYYY HH:mm").format("m"),
        endHours: dayjs(values.endText, "DD/MM/YYYY HH:mm").format("H"),
        managerLV1Id: values.managerLV1Id,
        otherReason: values.otherReason,
        idRequestReason: values.idRequestReason,
        isDoubleApproval: values.isDoubleApproval,
        approvalLevel: values.approvalLevel,
        managerLV1Name: values.managerLV1Name,
        isManagerLV1Approve: values.isManagerLV1Approve,
        managerLV1ApproveDate: values.managerLV1ApproveDate
          ? dayjs(values.managerLV1ApproveDate).format("DD/MM/BBBB HH:mm")
          : null,
        managerLV1ApproveDateText: values.managerLV1ApproveDateText,
        positionManagerLV1: values.positionManagerLV1,
        managerLV2Name: values.managerLV2Name,
        isManagerLV2Approve: values.isManagerLV2Approve,
        managerLV2ApproveDate: values.managerLV2ApproveDate
          ? dayjs(values.managerLV2ApproveDate).format("DD/MM/BBBB HH:mm")
          : null,
        managerLV2ApproveDateText: values.managerLV2ApproveDateText,
        positionManagerLV2: values.positionManagerLV2,
        createDateText: values.createDateText,
      });
      setMode("Edit");
    } else {
      setMode("write");
      setFormData({
        idRequestType: "",
        startDate: dayjs(values.date, "YYYY-MM-DD"),
        startMinute: "",
        startHours: "",
        endDate: dayjs(values.date, "YYYY-MM-DD"),
        endMinute: "",
        endHours: "",
        idRequestReason: "",
        otherReason: "",
      });
    }

    if (values.payrollSetting) {
      setPayrollSetting(values.payrollSetting);
    }
  }, [values]);

  // useEffect(async () => {
  //   if(watch("idRequestType") === 2){
  //     if(
  //       watch("startDate") && 
  //       watch("startHours") && (watch("startHours") !== null || watch("startHours") !== undefined) &&
  //       watch("startMinute") && (watch("startMinute") !== null || watch("startMinute") !== undefined) &&
  //       watch("endDate") &&
  //       watch("endHours") && (watch("endHours") !== null || watch("endHours") !== undefined) &&
  //       watch("endMinute") && (watch("endMinute") !== null || watch("endMinute") !== undefined)
  //     ){
  //       let requestReason = requestTimeReasonList.find(reason => reason.idRequestReason === watch("idRequestReason"));
  //       let result = await calculateService.calculateOT(
  //         values.date,
  //         dayjs(watch("startDate"))
  //           .set("hour", watch("startHours"))
  //           .set("minute", watch("startMinute"))
  //           .set("second", 0).format("YYYY-MM-DD HH:mm"),
  //         dayjs(watch("endDate"))
  //           .set("hour", watch("endHours"))
  //           .set("minute", watch("endMinute"))
  //           .set("second", 0).format("YYYY-MM-DD HH:mm"),
  //         {
  //           ...values,
  //           isReasonBreak: (requestReason && requestReason.isBreak === 1)
  //           ? true : false
  //         }
  //       );
  //       if(result.status == 200){
  //         console.log("result API : ",result.data);
  //       }
  //     }
  //   }
  // },[
  //   watch("idRequestType"), watch("startDate"), watch("startHours"), watch("startMinute"),
  //   watch("endDate"), watch("endHours"), watch("endMinute"), watch("idRequestReason")
  // ]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formInputData,
      [name]: value,
    });

    if (name === "idRequestType" && value === 2) {
      let leaveHoliday = (
        values.leave &&
        values.leave.length > 0 &&
        values.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
      );
      if (
        values.pattern.isWorkingDay === 1 &&
        !leaveHoliday &&
        !values.compensateDate &&
        !values.holiday
      ) {
        let textTimeOut = values.pattern && values.pattern.timeOut;
        let startHours = textTimeOut ? Number(textTimeOut.split(":")[0]) : "";
        let startMinute = textTimeOut ? Number(textTimeOut.split(":")[1]) : "";
        let TextTime =
          values.attendance &&
          values.attendance.round1 &&
          values.attendance.round1.checkOut;
        let endHours = TextTime
          ? Number(TextTime.attendanceTextTime.split(":")[0])
          : "";
        let endMinute = TextTime
          ? Number(TextTime.attendanceTextTime.split(":")[1])
          : "";
        let startDate = dayjs(values.date, "YYYY-MM-DD");
        let endDate = dayjs(values.date, "YYYY-MM-DD");

        if (startHours < Number(values.pattern.timeIn.split(":")[0])) {
          startDate = startDate.add(1, "day");
          endDate = endDate.add(1, "day");
        }

        setFormData({
          ...formInputData,
          [name]: value,
          startHours: startHours,
          startMinute: startMinute,
          endHours: endHours,
          endMinute: endMinute,
          startDate: startDate,
          endDate: endDate,
        });
      } else {
        const startDate = dayjs(`${values.date} ${values.pattern.timeIn}`);
        const endDate = startDate.add(
          values.pattern.workingHours,
          "minute",
          true
        );

        let textTimeIn = values.pattern && values.pattern.timeIn;
        let startHours = textTimeIn ? Number(textTimeIn.split(":")[0]) : "";
        let startMinute = textTimeIn ? Number(textTimeIn.split(":")[1]) : "";
        let textTimeOut = values.pattern && values.pattern.timeOut;
        let endHours = textTimeOut ? Number(textTimeOut.split(":")[0]) : "";
        let endMinute = textTimeOut ? Number(textTimeOut.split(":")[1]) : "";

        setFormData({
          ...formInputData,
          [name]: value,
          startHours: startHours,
          startMinute: startMinute,
          endHours: endHours,
          endMinute: endMinute,
          startDate: startDate,
          endDate: endDate,
        });
      }

      return;
    }
  };

  const handleChangeTime = (name, unit, periodTime, value) => {
    setFormData({
      ...formInputData,
      [name]: value,
    });
    setValue(name, value);
  };

  const calculationOT = () => {
    let requestReason = requestTimeReasonList ? requestTimeReasonList.find(reason => reason.idRequestReason === formInputData.idRequestReason) : null;
    if ([5, 7, 9, 10, 11, 12, 13, 14, 15, 8, 42].includes(values.pattern.idCompany)) {
      return CalculateOTFunction.calculatorOTProtech(
        values,
        formInputData,
        employeeProfile,
        attendanceListState,
        requestReason
      );
    } else {
      return CalculateOTFunction.calculatorOTMidnight(
        values,
        formInputData,
        employeeProfile,
        attendanceListState,
        requestReason
      );
    } 
    // else {
    //   return CalculateOTFunction.calculatorOTStandard(
    //     values,
    //     formInputData,
    //     employeeProfile,
    //     attendanceListState
    //   );
    // }
  };

  const handleSaveRequestTime = async () => {
    if (disabledBtn()) {
      alert("ข้อมูลไม่ถูกต้อง");
    } else {
      if (isSubmit === false) {
        setIsSubmit(true);
        let requestReason = requestTimeReasonList ? requestTimeReasonList.find(reason => reason.idRequestReason === formInputData.idRequestReason) : null;

        const startDate = dayjs(formInputData.startDate)
          .set("hour", formInputData.startHours)
          .set("minute", formInputData.startMinute)
          .set("second", 0);
        const endDate = dayjs(formInputData.endDate)
          .set("hour", formInputData.endHours)
          .set("minute", formInputData.endMinute)
          .set("second", 0);

        const formData = {
          workDate: values.date,
          start: startDate.format("YYYY-MM-DD HH:mm:ss"),
          end: endDate.format("YYYY-MM-DD HH:mm:ss"),
          idEmp: employeeProfile.idEmp,
          isDoubleApproval: 0,
          approvalLevel: 1,
          xOT: 0,
          xOTHoliday: 0,
          xWorkingDailyHoliday: 0,
          xWorkingMonthlyHoliday: 0,
          updateBy: authUser.id,
          updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          idRequestReason: formInputData.idRequestReason,
          otherReason: formInputData.otherReason,
          ...(values.idRequestTime
            ? {
                idRequestTime: values.idRequestTime,
                idRequestType: formInputData.idRequestType,
              }
            : {
                idRequestType: formInputData.idRequestType,
                isManagerLV1Approve: null,
                isManagerLV2Approve: null,
                isActive: 1,
                managerLV1ApproveBy: values.manager.idManagerLV1,
                managerLV2ApproveBy: values.manager.idManagerLV2,
                createBy: authUser.id,
              }),
        };

        if(isFillIn) {
          if(formData.idRequestTime) {
            // update
            if(values.manager.idManagerLV1) {
              formData.fillInApproveLV1 = authUser.id
              formData.isManagerLV1Approve = true
            }
            if(values.manager.idManagerLV2) {
              formData.fillInApproveLV2 = authUser.id
              formData.isManagerLV2Approve = true
            }
          } else {
            // create
            formData.fillInCreate = authUser.id
          }
        }

        let summaryOT = calculationOT();

        formData.xOT = summaryOT.listOT.xOT;
        formData.xOTHoliday = summaryOT.listOT.xOTHoliday;
        formData.xWorkingDaily = summaryOT.listOT.xWorkingDaily;
        formData.xWorkingDailyHoliday = summaryOT.listOT.xWorkingDailyHoliday;
        formData.xWorkingMonthlyHoliday =
          summaryOT.listOT.xWorkingMonthlyHoliday;
        formData.amountHours =
          summaryOT.listOT.xOT +
          summaryOT.listOT.xOTHoliday +
          summaryOT.listOT.xWorkingDailyHoliday +
          summaryOT.listOT.xWorkingMonthlyHoliday;

        if (formInputData.idRequestType === 2) {
          formData.isDoubleApproval = formData.xOTHoliday > 0 ? 1 : 0;
          formData.approvalLevel = formData.xOTHoliday > 0 ? 2 : 1;
        }
        
        let result;
        if (formData.idRequestTime) {
          result = await dispatch(updateRequestTime(formData));
        } else {
          result = await dispatch(addRequestTime(formData));
        }
        if (result) {
          if (result.errorCode) {
            handleChangeAlertType(
              formInputData.idRequestType === 1
                ? "ขอรับรองเวลาทำงานซ้ำ"
                : "ขอทำงานล่วงเวลาซ้ำ"
            );
            handleOpenAlert(true);
          } else {
            handleChangeAlertType("success");
            handleOpenAlert(true);
            if (isUserTimelinePage) {
              await dispatch(getAttendanceById(searchDate));
            } else {
              await dispatch(
                getAttendanceCompanyByIdEmp(
                  searchDate.searchDate,
                  searchDate.idCompany,
                  searchDate.idEmp
                )
              );
            }
          }
          handleCloseDialog();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
          handleCloseDialog();
        }
      }
    }
  };

  const renderHoursOT = () => {
    if (
      formInputData.startHours !== "" &&
      formInputData.startMinute !== "" &&
      formInputData.endHours !== "" &&
      formInputData.endMinute !== ""
    ) {
      let summaryOT = calculationOT();
      
      if (summaryOT.error.errorNoon) {
        return (
          <Typography
            variant="body2"
            style={{ color: "#f15e5e", fontWeight: 500 }}
          >
            เวลาทำงานเร่งด่วนช่วงพักเที่ยง ไม่ถูกต้อง
          </Typography>
        );
      } else if (summaryOT.error.errorTime) {
        return (
          <Typography
            variant="body2"
            style={{ color: "#f15e5e", fontWeight: 500 }}
          >
            วันที่หรือเวลาไม่ถูกต้อง
          </Typography>
        );
      } else if (summaryOT.error.errorOnWorkingTime) {
        return (
          <Typography
            variant="body2"
            style={{ color: "#f15e5e", fontWeight: 500 }}
          >
            ไม่สามารถขอทำงานล่วงเวลาในเวลาทำงานปกติได้
          </Typography>
        );
      } else {
        let ObjectOt = {};
        if(payrollSetting){
          Object.keys(summaryOT.listOT).map((k) => {
            if (summaryOT.listOT[k] > 0) {
              if(k === "xWorkingDaily"){
                ObjectOt[1] = summaryOT.listOT[k] / 60 + (ObjectOt[1] || 0);
              }else{
                ObjectOt[payrollSetting[k]] =
                  summaryOT.listOT[k] / 60 + (ObjectOt[payrollSetting[k]] || 0);
              }
            }
          });
        }

        return (
          <>
            {Object.keys(ObjectOt).map((k, index) => {
              return (
                <StyledWrapHoursOT key={`${k}_${ObjectOt[k]}_${index}`}>
                  <Typography
                    className="label"
                    variant="subtitle1"
                    color="text.third"
                  >
                    OT x {k}
                  </Typography>
                  <Typography variant="h6">
                    {parseFloat(ObjectOt[k]).toFixed(2)} ชม.
                  </Typography>
                </StyledWrapHoursOT>
              );
            })}
          </>
        );
      }
    }
  };

  const renderTitle = () => {
    let text = "";
    if (values.idRequestTime) {
      if (values.idRequestType === 2) {
        text = t("OtRequest");
      } else {
        text = t("requestWorkingTime");
      }
    } else {
      text = t("AddTimeline");
    }

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{text}</Typography>
        {values.idRequestTime && (
          <DeleteForeverIcon
            style={{
              color: "d32f2f",
              cursor: "pointer",
            }}
            onClick={handleOpenDialogConfirmDelete}
          />
        )}
      </div>
    );
  };

  const renderManagerLV1 = () => {
    return (
      <Fragment>
        <Grid item xs={12} sm={4} style={{ display: "flex" }}>
          <StyledContentLabel>{t("ManagerLevel")} 1</StyledContentLabel>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>{formInputData.managerLV1Name}</Typography>
          <Typography color={"text.secondary"} variant="body2">
            {formInputData.positionManagerLV1}
          </Typography>
          <div className="wrap-status">
            <div style={{ width: 40 }}>
              <StatusRequest
                boxSize={30}
                fontSize={20}
                borderRadius={8}
                status={
                  formInputData.isManagerLV1Approve === null
                    ? 2
                    : formInputData.isManagerLV1Approve
                      ? 1
                      : 0
                }
                active={values.isActive}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 8,
              }}
            >
              <Typography>
                {formInputData.isManagerLV1Approve === null
                  ? `${t("WaitingApproval")}`
                  : 
                    // formInputData.managerLV1ApproveDate &&
                    formInputData.isManagerLV1Approve
                  ? `${t("Approve")}`
                  : `${t("Disapproval")}`}
              </Typography>
              {formInputData.managerLV1ApproveDate && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{
                    fontStyle: "italic",
                  }}
                >{`${formInputData.managerLV1ApproveDateText}`}</Typography>
              )}
            </div>
          </div>
        </Grid>
      </Fragment>
    );
  };

  const renderManagerLV2 = () => {
    return (
      <Fragment>
        <Grid item xs={12} sm={4} style={{ display: "flex" }}>
          <StyledContentLabel>{t("ManagerLevel")} 2</StyledContentLabel>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography>{formInputData.managerLV2Name}</Typography>
          <Typography color={"text.secondary"} variant="body2">
            {formInputData.positionManagerLV2}
          </Typography>
          <div className="wrap-status">
            <div style={{ width: 40 }}>
              <StatusRequest
                boxSize={30}
                fontSize={20}
                borderRadius={8}
                status={
                  formInputData.isManagerLV2Approve === null
                    ? 2
                    : formInputData.isManagerLV2Approve
                      ? 1
                      : 0
                }
                active={values.isActive}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 8,
              }}
            >
              <Typography>
                {formInputData.isManagerLV2Approve === null
                  ? `${t("WaitingApproval")}`
                  : 
                    // formInputData.managerLV2ApproveDate &&
                    formInputData.isManagerLV2Approve
                  ? `${t("Approve")}`
                  : `${t("Disapproval")}`}
              </Typography>
              {formInputData.managerLV2ApproveDateText && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{
                    fontStyle: "italic",
                  }}
                >{`${formInputData.managerLV2ApproveDateText}`}</Typography>
              )}
            </div>
          </div>
        </Grid>
      </Fragment>
    );
  };

  const disabledBtn = () => {
    if (formInputData && attendanceListState) {
      if (
        formInputData.startHours !== "" &&
        formInputData.startMinute !== "" &&
        formInputData.endHours !== "" &&
        formInputData.endMinute !== "" &&
        formInputData.idRequestReason !== ""
      ) {
        let requestReason = requestTimeReasonList ? requestTimeReasonList.find(reason => reason.idRequestReason === formInputData.idRequestReason) : null;
        let isReasonBreak = (requestReason && requestReason.isBreak === 1) ? true : false

        const startDate = dayjs(formInputData.startDate)
          .set("hour", formInputData.startHours)
          .set("minute", formInputData.startMinute)
          .set("second", 0);
        const endDate = dayjs(formInputData.endDate)
          .set("hour", formInputData.endHours)
          .set("minute", formInputData.endMinute)
          .set("second", 0);

        const findIndexToday = attendanceListState.findIndex(
          (x) => x.date === values.date
        );
        const findDate = {
          start: `
            ${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.timeIn}
          `,
          end: `
            ${dayjs(
              `${dayjs(values.date).format("YYYY-MM-DD")} ${
                values.pattern.timeIn
              }`,
              "YYYY-MM-DD HH:mm"
            )
              .add(values.pattern.workingHours, "minute")
              .format("YYYY-MM-DD HH:mm")}
          `,
        };

        let leaveHoliday = (
          values.leave &&
          values.leave.length > 0 &&
          values.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
        );
        const isWorkingDay =
          values.pattern.isWorkingDay === 1 &&
          !leaveHoliday &&
          !values.holiday &&
          !values.compensateDate;

        let valuesPrev = attendanceListState[findIndexToday - 1];
        let findPrevDate = {
          start: `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
            valuesPrev.pattern.timeIn
          }`,
          end: `${dayjs(
            `${dayjs(valuesPrev.date).format("YYYY-MM-DD")} ${
              valuesPrev.pattern.timeIn
            }`,
            "YYYY-MM-DD HH:mm"
          )
            .add(valuesPrev.pattern.workingHours, "minute")
            .format("YYYY-MM-DD HH:mm")}`,
        };

        let prevLeaveHoliday = (
          valuesPrev.leave &&
          valuesPrev.leave.length > 0 &&
          valuesPrev.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
        );
        let isWorkingPrevDay =
          valuesPrev.pattern.isWorkingDay === 1 &&
          !prevLeaveHoliday &&
          !valuesPrev.holiday &&
          !valuesPrev.compensateDate;

        let valuesNext = attendanceListState[findIndexToday + 1];
        let findNextDate = {
          start: `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
            valuesNext.pattern.timeIn
          }`,
          end: `${dayjs(
            `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${
              valuesNext.pattern.timeIn
            }`,
            "YYYY-MM-DD HH:mm"
          )
            .add(valuesNext.pattern.workingHours, "minute")
            .format("YYYY-MM-DD HH:mm")}`,
        };

        let nextLeaveHoliday = (
          valuesNext.leave &&
          valuesNext.leave.length > 0 &&
          valuesNext.leave.find((v) => (v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1))
        );
        let isWorkingNextDay =
          valuesNext.pattern.isWorkingDay === 1 &&
          !nextLeaveHoliday &&
          !valuesNext.holiday &&
          !valuesNext.compensateDate;

        let allHours = endDate.diff(startDate, "hours", true);

        if (formInputData && formInputData.idRequestType === 2) {
          if(!payrollSetting || payrollSetting.length <= 0){
            return true;
          }

          if (
            startDate.isBefore(
              dayjs(
                `${dayjs(values.date).format("YYYY-MM-DD")} 00:00`,
                "YYYY-MM-DD HH:mm"
              )
            ) ||
            endDate.isSameOrAfter(
              dayjs(
                `${dayjs(values.date)
                  .add(2, "day")
                  .format("YYYY-MM-DD")} 00:00`,
                "YYYY-MM-DD HH:mm"
              )
            )
          ) {
            return true;
          }

          if (isWorkingPrevDay && startDate.isBefore(findPrevDate.end)) {
            return true;
          }

          if (isWorkingNextDay && endDate.isAfter(findNextDate.start)) {
            return true;
          }

          if (!(endDate.diff(startDate, "minute") > 0)) {
            return true;
          }

          if (values.pattern.idWorkingType === 2) {
            if (
              !(
                startDate.isBetween(
                  dayjs(
                    `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
                  ),
                  dayjs(
                    `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
                  ).add((values.pattern.breakTimeMin || 60), "minute"),
                  "minute",
                  "[)"
                ) &&
                endDate.isBetween(
                  dayjs(
                    `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
                  ),
                  dayjs(
                    `${dayjs(values.date).format("YYYY-MM-DD")} ${values.pattern.startBreak || "12:00:00"}`
                  ).add((values.pattern.breakTimeMin || 60), "minute"),
                  "minute",
                  "(]"
                )
              ) &&
              isReasonBreak
            ) {
              return true;
            } else if (
              (dayjs(startDate).isBetween(
                dayjs(findDate.start),
                dayjs(findDate.end),
                "minute",
                "[)"
              ) ||
                dayjs(endDate).isBetween(
                  dayjs(findDate.start),
                  dayjs(findDate.end),
                  "minute",
                  "(]"
                ) ||
                dayjs(findDate.start).isBetween(
                  dayjs(startDate),
                  dayjs(endDate),
                  "minute",
                  "[)"
                ) ||
                dayjs(findDate.end).isBetween(
                  dayjs(startDate),
                  dayjs(endDate),
                  "minute",
                  "(]"
                )) &&
              isWorkingDay &&
              !isReasonBreak
            ) {
              return true;
            }

            if (
              startDate.isBetween(
                dayjs(
                  `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
                ),
                dayjs(
                  `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
                ).add((valuesNext.pattern.breakTimeMin || 60), "minute"),
                "minute",
                "[)"
              ) &&
              endDate.isBetween(
                dayjs(
                  `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
                ),
                dayjs(
                  `${dayjs(valuesNext.date).format("YYYY-MM-DD")} ${valuesNext.pattern.startBreak || "12:00:00"}`
                ).add((valuesNext.pattern.breakTimeMin || 60), "minute"),
                "minute",
                "(]"
              ) &&
              !isReasonBreak
            ) {
              return true;
            }
          } else {
            if (
              (dayjs(startDate).isBetween(
                dayjs(findDate.start),
                dayjs(findDate.end),
                "minute",
                "[)"
              ) ||
                dayjs(endDate).isBetween(
                  dayjs(findDate.start),
                  dayjs(findDate.end),
                  "minute",
                  "(]"
                ) ||
                dayjs(findDate.start).isBetween(
                  dayjs(startDate),
                  dayjs(endDate),
                  "minute",
                  "[)"
                ) ||
                dayjs(findDate.end).isBetween(
                  dayjs(startDate),
                  dayjs(endDate),
                  "minute",
                  "(]"
                )) &&
              isWorkingDay
            ) {
              return true;
            }
          }
        } else if(formInputData && formInputData.idRequestType === 1){
          if(allHours > 0){
            return false;
          }else{
            return true;
          }
        }else {
          return false;
        }

        if (allHours > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <DrawerCustom
      title={renderTitle()}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <div style={{ marginBottom: 16 }}>
          <Typography>
            {t("WorkingDay")}: {dayjs(values.date).format("D/MM/BBBB ")}
          </Typography>
          <Typography>
            {t("WorkingTime")}:{" "}
            {values.pattern.idWorkingType === 1
              ? `${values.pattern.nameShiftGroup}  (${values.pattern.nameShift})`
              : `${values.pattern.nameShiftGroup}`}
            <Typography
              component="span"
              color="text.third"
              style={{
                fontStyle: "oblique",
                fontWeight: "normal",
              }}
            >
              {values.pattern.isWorkingDay === 1
                ? ` (${values.pattern.timeIn.slice(
                    0,
                    -3
                  )} - ${values.pattern.timeOut.slice(0, -3)})`
                : ` (${values.pattern.nameShiftType})`}
            </Typography>
          </Typography>
        </div>
        {formInputData && (
          <Grid container spacing={2}>
            {formInputData.idRequestTime && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    color={"text.third"}
                    style={{ fontSize: 14, fontWeight: 500 }}
                  >
                    {t("Approver")}
                  </Typography>
                </Grid>
                {formInputData.isDoubleApproval ? (
                  <Fragment>
                    {renderManagerLV1()}
                    {renderManagerLV2()}
                  </Fragment>
                ) : formInputData.approvalLevel ? (
                  renderManagerLV1()
                ) : (
                  renderManagerLV2()
                )}
              </Fragment>
            )}
            {formInputData.idRequestTime && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    color={"text.third"}
                    style={{ fontSize: 14, fontWeight: 500 }}
                  >
                    {t("Description")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    color={"text.secondary"}
                    variant="body2"
                    style={{
                      fontStyle: "oblique",
                      textAlign: "right",
                      width: "100%",
                    }}
                  >
                    {`${t("CreateDate")}: ${formInputData.createDateText}`}
                  </Typography>
                </Grid>
              </Fragment>
            )}
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("RequestType")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="idRequestType"
                  value={formInputData.idRequestType}
                  onChange={(e) => { 
                    handleChange(e);
                    setValue("idRequestType", e.target.value);
                  }}
                  inputProps={{ readOnly: mode === "read" }}
                >
                  <MenuItem value={1}>{t("WorkTimeRequest")}</MenuItem>
                  <MenuItem value={2}>{t("OvertimeRequest")}</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("StartDate")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              {localStorage.getItem("language") === "en" ? (
                <DatePickerEng
                  minDate={new Date(dayjs(values.date).format("YYYY-MM-DD"))}
                  maxDate={
                    new Date(
                      dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                    )
                  }
                  readOnly={
                    formInputData.idRequestType === 1 || mode === "read"
                  }
                  value={formInputData.startDate}
                  onChange={(newDate) => {
                    setFormData({ ...formInputData, ["startDate"]: newDate });
                    setValue("startDate", newDate);
                  }}
                  variant="outlined"
                />
              ) : (
                <DatePickerThai
                  minDate={new Date(dayjs(values.date).format("YYYY-MM-DD"))}
                  maxDate={
                    new Date(
                      dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                    )
                  }
                  readOnly={
                    formInputData.idRequestType === 1 || mode === "read"
                  }
                  value={formInputData.startDate}
                  onChange={(newDate) => {
                    setFormData({ ...formInputData, ["startDate"]: newDate });
                    setValue("startDate", newDate);
                  }}
                  variant="outlined"
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("StartTime")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", marginRight: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="startHours"
                    unit="hour"
                    value={formInputData.startHours}
                    mode={mode}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="startMinute"
                    unit="minute"
                    step="1"
                    value={formInputData.startMinute}
                    mode={mode}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("EndDate")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              {localStorage.getItem("language") === "en" ? (
                <DatePickerEng
                  minDate={new Date(dayjs(values.date).format("YYYY-MM-DD"))}
                  maxDate={
                    new Date(
                      dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                    )
                  }
                  readOnly={mode === "read"}
                  value={formInputData.endDate}
                  onChange={(newDate) => {
                    setFormData({ ...formInputData, ["endDate"]: newDate });
                    setValue("endDate", newDate);
                  }}
                  variant="outlined"
                />
              ) : (
                <DatePickerThai
                  minDate={new Date(dayjs(values.date).format("YYYY-MM-DD"))}
                  maxDate={
                    new Date(
                      dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                    )
                  }
                  readOnly={mode === "read"}
                  value={formInputData.endDate}
                  onChange={(newDate) => {
                    setFormData({ ...formInputData, ["endDate"]: newDate });
                    setValue("endDate", newDate);
                  }}
                  variant="outlined"
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("EndTime")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", marginRight: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="endHours"
                    unit="hour"
                    value={formInputData.endHours}
                    mode={mode}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="endMinute"
                    unit="minute"
                    step="1"
                    value={formInputData.endMinute}
                    mode={mode}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Remark")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="idRequestReason"
                  value={formInputData.idRequestReason}
                  onChange={(e) => {
                    handleChange(e);
                    setValue("idRequestReason", e.target.value);
                  }}
                  disabled={!Boolean(formInputData.idRequestType)}
                  inputProps={{ readOnly: mode === "read" }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  {
                    [
                      requestTimeReasonList &&
                        requestTimeReasonList
                          .filter((x) => {
                            if (formInputData.idRequestType === 1) {
                              return (x.idRequestType === 1 || x.idRequestType === null) && x.isResign != 1;
                            } else if (formInputData.idRequestType === 2) {
                              return (x.idRequestType === 2 || x.idRequestType === null) && x.isResign != 1;
                            }
                          })
                          .map((item, index) => (
                            <MenuItem key={index} value={item.idRequestReason}>
                              {item.name}
                            </MenuItem>
                          )),
                    ]
                  }
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("AdditionalReasons")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <TextField
                  name="otherReason"
                  onChange={handleChange}
                  id="outlined-multiline-static"
                  multiline
                  rows={3}
                  value={formInputData.otherReason}
                  InputProps={{
                    readOnly: mode === "read",
                  }}
                />
              </StyledFormControl>
            </Grid>
            {formInputData.idRequestType === 2 && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StyledContentLabel>{t("TotalHours")}</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {attendanceListState && renderHoursOT()}
                </Grid>
              </Fragment>
            )}
          </Grid>
        )}
        <StyledFooter>
          <ButtonBlue
            className="cancel"
            color="secondary"
            variant="text"
            onClick={handleCloseDialog}
          >
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue
            disabled={disabledBtn() || isSubmit}
            variant="contained"
            onClick={handleSaveRequestTime}
          >
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogNewRequest;
