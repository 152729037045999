import * as React from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Avatar,
    Badge,
    Box,
    Dialog,
    DialogContent,
    IconButton,
    Typography
} from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoopIcon from "@mui/icons-material/Loop";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import AvatarShift from "../../../shared/general/AvatarShift";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogEditSwitchShift from "./dialogEditSwitchShift";

import { updateEmployeeShiftHistory } from "../../../../../actions/employee";

const StyledAccordion = styled(Accordion)({
    boxShadow: "none",
    border: "1px solid #0000001f",
    margin: "0 !important",
    backgroundColor: "#f3f6f8",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&:first-of-type": {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    "&:last-of-type": {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
});

const StyledAccordionSummary = styled(AccordionSummary)({
    height: 52,
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      "& .MuiSvgIcon-root": {
        marginRight: 4,
        fontSize: 28,
        color: "#999999",
      },
    },
    "&.Mui-expanded": {
      height: "52px !important",
      minHeight: "52px !important",
    },
    "& .MuiTypography-subtitle1": {
      color: "#007afe",
      fontWeight: 600,
      fontSize: 18,
    },
    "& .MuiTypography-root": {
      lineHeight: 1.4,
    },
    "& .MuiTypography-body1": {},
    "& .MuiTypography-body2": {
      color: "#999999",
      marginLeft: 4,
    },
});

const StyledAccordionDetails = styled(AccordionDetails)({
    backgroundColor: "#FFFFFF",
    padding: 16,
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    "& .inner-detail": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position:"relative",
      "& .MuiSvgIcon-fontSizeMedium": {
        fontSize: 42,
        color: "#0000008a",
      }
    },
});

const StyledAvatar = styled(Avatar)({
    width: 50,
    height: 50,
});

const StyledBox = styled(Box)({
    width: 120,
    padding: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
});

const StyledListEmployee = styled("div")({
    marginTop: 16,
    display: "flex",
    flexWrap: "wrap",
    maxHeight: 350,
    overflow: "auto",
});

const StyledDialog = styled(Dialog)({
    "& .MuiDialogContent-root": {
      padding: 0,
      "& .content": {
        padding: 16,
        textAlign: "center",
        "& .delete-icon-label": {
          fontSize: 90,
          color: "#f15e5e",
        },
        "& .header-label": {
          padding: "20px 0",
        },
        "& .detail": {
          fontSize: 16,
          "& span": {
            color: "#f15e5e",
            fontSize: 20,
          },
        },
      },
    },
});

const AccordionShift = (props) => {
    let { employee, idShiftGroup, handleGetEmployeeShiftHistory, handleOpenAlert } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { result : AllShiftGroup } = useSelector(state => state.shift);
    const { result: EmployeeShiftHistory } = useSelector(state => state.employeeShiftHistory);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [listEmployee, setListEmployee] = React.useState([]);
    const [selectIdEmployeeShift, setSelectIdEmployeeShift] = React.useState(null);
    const [isDelete, setIsDelete] = React.useState(false);

    React.useEffect(()=>{
        let tempList = EmployeeShiftHistory.filter((history) => {
            if(
             employee.idShift === history.idShift && 
             employee.prevShift === history.prevShift && 
             employee.startDate === history.startDate && 
             employee.endDate === history.endDate
            ){
                return true;
            } else {
                return false;
            }
        });
        setListEmployee(tempList);
        return () => {
            setListEmployee([]);
        }
    },[EmployeeShiftHistory]);

    const handleClickDelete = (idEmployeeShift) => {
        setSelectIdEmployeeShift(idEmployeeShift);
        setIsDelete(true);
    };

    const handleClickDeletePlan = () => {
        setSelectIdEmployeeShift("all");
        setIsDelete(true);
    };

    const handleClickConfirmDelete = async () => {
        let data = [];
        if(selectIdEmployeeShift === "all"){
            data = listEmployee.map(emp => {
                return {
                    ...emp, isActive: 0
                }
            });
        }else{
            let index = listEmployee
                .map((emp) => emp.idEmployeeShift)
                .indexOf(selectIdEmployeeShift.idEmployeeShift);
            let tempList = listEmployee;
            tempList[index] = {...listEmployee[index], isActive: 0};
            data = [{...tempList[index]}];
        }

        let result = await dispatch(
            updateEmployeeShiftHistory(data)
        );
        if(result && result.status === 200) {
            handleOpenAlert(
                "success", 
                selectIdEmployeeShift === "all" ? "ยกเลิกแผนสลับกะสำเร็จ" : "การลบสำเร็จ"
            );
        }else{
            handleOpenAlert(
                "error", 
                selectIdEmployeeShift === "all" ? "ยกเลิกแผนสลับกะไม่สำเร็จ" : "การลบไม่สำเร็จ"
            );
        }
        handleGetEmployeeShiftHistory();
        setSelectIdEmployeeShift(null);
        setIsDelete(false);
    };

    const handleCloseDeleteDialog = () => {
        setIsDelete(false);
        setSelectIdEmployeeShift(null);
    };

    return (
        <React.Fragment>
            <StyledAccordion key={employee.idEmployeeShift}>
                <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {employee.idShiftGroup === parseInt(idShiftGroup) ? 
                        <LoopIcon /> 
                        : 
                        <SyncProblemIcon />
                    }
                    <div>
                        <Typography variant="subtitle1">{`${dayjs(
                            employee.startDate
                        ).format("DD/MM/YYYY")}${
                            employee.endDate 
                                ? ` ${t("To")} ${dayjs(employee.endDate).format("DD/MM/YYYY")}` 
                                : ""
                        }`}</Typography>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1">
                                {employee.idShiftGroup === parseInt(idShiftGroup)
                                ? `${t("SwitchShift")}`
                                : `${t("SwitchShiftPattern")}`}{" "}
                            </Typography>
                        </div>
                    </div>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <div className={`inner-detail`}>
                        {!dayjs().isAfter(
                            dayjs(
                                parseInt(employee.isTemporarily) === 1 
                                ? employee.endDate 
                                : employee.startDate
                            ),
                            'day'
                        ) && (
                            <ButtonBlue
                                style={{ position: "absolute", top: 0, right: 0 }}
                                onClick={handleClickDeletePlan}
                                startIcon={<DeleteIcon fontSize="small" />}
                                color="error"
                            >
                                {t("CancelShiftPlan")}
                            </ButtonBlue>
                        )}
                        {employee.idShiftGroup !== parseInt(idShiftGroup) && 
                            <Typography style={{ marginRight:"20px" }}>
                                {
                                  AllShiftGroup.find(
                                    shift => shift.idShiftGroup ===  employee.idPrevShiftGroup
                                  ).nameShiftGroup
                                }
                            </Typography>
                        }
                        <AvatarShift 
                          fontSize={28} 
                          title={employee.namePrevShift} 
                          size={50} 
                        />
                        <ChevronRightIcon />
                        <AvatarShift 
                          fontSize={28} 
                          title={employee.nameShift} 
                          size={50} 
                        />
                        {employee.idShiftGroup !== parseInt(idShiftGroup) && 
                            <Typography style={{ marginLeft:"20px" }}>
                                {
                                  AllShiftGroup.find(
                                    shift => shift.idShiftGroup ===  employee.idShiftGroup
                                  ).nameShiftGroup
                                }
                            </Typography>
                        }
                    </div>
                    <StyledListEmployee>
                        {listEmployee.map((history) => (
                            <StyledBox 
                                key={`${history.name}_${history.lastname}_${history.idEmployeeShift}`}
                            >
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    badgeContent={
                                        (!dayjs().isAfter(
                                            dayjs(
                                                parseInt(employee.isTemporarily) === 1 
                                                ? employee.endDate 
                                                : employee.startDate
                                            ),
                                            'day'
                                        )) ?
                                        <IconButton
                                            onClick={() => handleClickDelete(history)}
                                            aria-label="delete"
                                            size="small"
                                        >
                                            <DoNotDisturbOnIcon fontSize="inherit" />
                                        </IconButton>
                                        : <></>
                                    }
                                >
                                    <StyledAvatar
                                        alt={history.firstname}
                                        src={ history.imageProfile ? `${history.imageProfile}` :
                                                            `${process.env.REACT_APP_API_URL}image/vendor/${history.idVendor}/${history.personalId}.jpg`}
                                    />
                                </Badge>
                                <div style={{ width: "100%" }}>
                                    <Typography align="center" variant="body1">
                                        {history.firstname}
                                    </Typography>
                                    <Typography align="center" variant="body1">
                                        {history.lastname}
                                    </Typography>
                                    <Typography 
                                        align="center"
                                        variant="body2"
                                        color="text.secondary"
                                        style={{ fontStyle: "oblique" }}
                                    >
                                        {history.positionsName}
                                    </Typography>
                                </div>
                            </StyledBox>
                            ))
                        }
                    </StyledListEmployee>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledDialog
                fullWidth
                maxWidth={"xs"}
                open={isDelete}
                onClose={handleCloseDeleteDialog}
            >
                <DialogContent>
                    <div
                        style={{ width: "100%", height: 6, backgroundColor: "#f15e5e" }}
                    ></div>
                    <div className="content">
                        <HighlightOffRoundedIcon className="delete-icon-label" />
                        <Typography
                            variant="h5"
                            className="header-label"
                            color="text.third"
                        >
                            {t("AreYouSure")} ?
                        </Typography>
                        {selectIdEmployeeShift ? (
                        selectIdEmployeeShift === "all" ? (
                            <Typography
                                className="detail"
                                variant="h6"
                                color="text.secondary"
                            >
                                {t("YouWantTo")} <span>{t("CancelShiftPlan")}</span>
                            {" ?"}
                            </Typography>
                        ) : (
                            <Typography
                                className="detail"
                                variant="h6"
                                color="text.secondary"
                            >
                                {t("YouWantDelete")}{" "}
                                <span>
                                    {selectIdEmployeeShift.firstname}{" "}
                                    {selectIdEmployeeShift.lastname}
                                </span>{" "}
                                {t("ForTheShiftPlan")} ?
                            </Typography>
                        )
                        ) : null}

                        <Box
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: "20px",
                            }}
                        >
                        <ButtonBlue
                            color="secondary"
                            variant="text"
                            style={{ marginRight: "10px" }}
                            onClick={handleCloseDeleteDialog}
                        >
                            {t("Cancel")}
                        </ButtonBlue>
                        <ButtonBlue
                            color="error"
                            variant="contained"
                            onClick={handleClickConfirmDelete}
                        >
                            {t("ConfirmDelete")}
                        </ButtonBlue>
                        </Box>
                    </div>
                </DialogContent>
            </StyledDialog>

            {openDrawer && selectIdEmployeeShift &&
                <DialogEditSwitchShift 
                    open={openDrawer}
                    setOpen={setOpenDrawer}
                    listEmployee={listEmployee}
                    selectIdEmployeeShift={selectIdEmployeeShift}
                    idShiftGroup={idShiftGroup}
                    handleGetEmployeeShiftHistory={handleGetEmployeeShiftHistory}
                />
            }
        </React.Fragment>
    );
};

export default AccordionShift;