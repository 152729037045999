import { Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Fab } from "@mui/material";

import MenuApp from "./menu-app";
import TeamList from "./team-list";

import LocationOnIcon from "@mui/icons-material/LocationOn";

import Backdrop from "../../shared/general/Backdrop";
import { getUserProfile } from "../../../../actions/user";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapHeader = styled("div")({
  paddingTop: 20,
  paddingBottom: 60,
});

const StyledFab = styled(Fab)({
  display: "none",
  ["@media only screen and (max-width: 600px)"]: {
    display: "inline-flex",
    position: "fixed",
    right: 8,
    bottom: 16,
  },
});

const HomePageAdmin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      //displayMode = "standalone";
      setIsPWA(true);
    } else {
      //displayMode = "!!!!!!";
    }
  }, [])

  const handleOpenGPS = () => {
    history.push("/gps-check?source=pwa");
  };

  return (
    <div>
      {
        <StyledRoot className={`page`}>
          <Backdrop open={!(currentUser && userProfile)} />
          <Container maxWidth="lg">
            {userProfile && (
              <StyledWrapHeader>
                <Typography variant="h4">
                  {t("Hello")},
                  {`${userProfile.firstname} ${userProfile.lastname}`}
                </Typography>
              </StyledWrapHeader>
            )}
            <MenuApp />
            <TeamList />
            {isPWA && (
            <StyledFab color="primary" aria-label="gps" onClick={handleOpenGPS}>
              <LocationOnIcon />
            </StyledFab>
          )}
          </Container>
        </StyledRoot>
      }
    </div>
  );
};

export default HomePageAdmin;
