import React, { Fragment } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import utils from "../../../../utils";

const rand36 = () => {
  return Math.floor(Math.random() * 4);
};

function createData(status, name, created, start, stop, ot1, ot15, ot2, ot3) {
  return {
    status,
    name,
    created,
    start,
    stop,
    ot1,
    ot15,
    ot2,
    ot3,
  };
}

const rows = [
  createData(
    0,
    "วันหยุด",
    new Date(2021, 10, 10, 14, 46, 51),
    new Date(2021, 10, 10, 16, 30, 0),
    new Date(2021, 10, 10, 20, 30, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 11, 14, 46, 51),
    new Date(2021, 10, 11, 16, 30, 0),
    new Date(2021, 10, 11, 20, 0, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    2,
    "วันทำงาน",
    new Date(2021, 10, 12, 14, 46, 51),
    new Date(2021, 10, 12, 16, 30, 0),
    new Date(2021, 10, 12, 18, 45, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันหยุด",
    new Date(2021, 10, 13, 14, 46, 51),
    new Date(2021, 10, 13, 0, 0, 0),
    new Date(2021, 10, 13, 0, 0, 0),
    0,
    0,
    0,
    0,
    0
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 14, 14, 46, 51),
    new Date(2021, 10, 14, 16, 30, 0),
    new Date(2021, 10, 14, 19, 0, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 15, 14, 46, 51),
    new Date(2021, 10, 15, 16, 30, 0),
    new Date(2021, 10, 15, 20, 30, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันหยุด",
    new Date(2021, 10, 16, 15, 46, 51),
    new Date(2021, 10, 16, 0, 0, 0),
    new Date(2021, 10, 16, 0, 0, 0),
    0,
    0,
    0,
    0
  ),
];

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
    padding: "8px 0",
    textAlign: "center",
  },
  "& .tableCellHead:first-of-type, .tableCell:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type, .tableCell:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    textAlign: "center",
    borderColor: "#919eab3d",
    padding: "8px 0",
    borderBottomStyle: "dashed",
  },
  "& .tableCellSummary": {
    borderTop: "5px solid #919eab3d",
    borderBottom: "none",
  },
  "& .tableCellBlank": {
    padding: 4,
  },
});

export default function TableOTReport() {
  const { result: overtime36HoursDetailList } = useSelector(
    (state) => state.overtime36HoursDetail
  );

  return (
    <TableContainer>
      <StyledTable aria-label="simple table">
        <TableHead>
          <TableRow>
            {overtime36HoursDetailList &&
              Object.entries(overtime36HoursDetailList[0]).map(
                ([key, value]) => {
                  return (
                    <Fragment>
                      {key === "workDate" ? (
                        <TableCell className={`tableCellHead cellStatusAction`}>
                          วันที่
                        </TableCell>
                      ) : (
                        <TableCell className={`tableCellHead cellNumber`}>
                          {`OT ${key.replace("x", "x ")} (ชม.)`}
                        </TableCell>
                      )}
                    </Fragment>
                  );
                }
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {overtime36HoursDetailList &&
            overtime36HoursDetailList.map((row, index) => (
              <TableRow key={index}>
                {Object.entries(row).map(([key, value]) => {
                  return (
                    <Fragment>
                      {key === "workDate" ? (
                        <TableCell className={`tableCell cellStatusAction`}>
                          {dayjs(value)
                            .locale(localStorage.getItem("language") || "th")
                            .format(
                              localStorage.getItem("language") === "en"
                                ? "D MMM YYYY"
                                : "D MMM BBBB"
                            )}
                        </TableCell>
                      ) : (
                        <TableCell className={`tableCell cellNumber`}>
                          {value ? parseFloat(value / 60).toFixed(2) : "-"}
                        </TableCell>
                      )}
                    </Fragment>
                  );
                })}
              </TableRow>
            ))}
          <TableRow key={98}>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
            <TableCell className={`tableCellBlank tableCellSummary`}>
              {""}
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
