import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";

import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import PlaylistAddCircleRoundedIcon from "@mui/icons-material/PlaylistAddCircleRounded";

import ButtonBlue from "../../shared/general/ButtonBlue";
import StatusRequest from "../../shared/general/stausRequest";
import AlertResponse from "../../shared/general/AlertResponse";

import DialogTime from "./component/DialogMonthlyTime";

import { allLeaveType } from "../../../../actions/leave";
//import { getMonthlyManualSheetByIdEmployees } from "../../../../actions/manualsheet";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    textAlign: "center",
  },
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .overtime": {
    width: 50,
    // width: 48,
    // padding: "0px 0px 0px 4px",
  },
  "& .tableCellHead-date-time": {
    width: 140,
  },
  "& .tableCellHead-leave": {
    width: 60,
  },
  "& .tableCellHead:last-of-type": {
    // paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderBottom: "none",
    borderColor: "#919eab3d",
    padding: 12,
    "&.cellStartStop": {
      // "&:hover": {
      //   cursor: "pointer",
      // },
      "& .time": {
        fontWeight: "600",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiSvgIcon-root": {
          marginRight: 4,
        },
      },
      "& .location": {
        fontWeight: 600,
      },
    },
  },
  "& .tableCellWeekend": {
    color: "#959595",
    fontStyle: "oblique",
  },
  "& .cellStatusAction": {
    textAlign: "left",
    "& .wrap-status": {
      display: "flex",
      "& .item": {
        marginRight: 4,
      },
    },
  },
  "& .cellRequest": {},
  "& .cellStartStop": {
    minWidth: 80,
    "& .MuiButtonBase-root": {
      marginRight: 2,
    },
  },
  "& .cellButtonCheck": {
    textAlign: "left",
  },
});

const StyledChip = styled(Chip)(({ status }) => ({
  margin: 0,
  fontSize: 14,
  fontWeight: 600,
  height: 26,
  ...(status === "ขอรับรองเวลาทำงาน" && {
    backgroundColor: "#fffcd6",
    color: "#eebb00",
    "& .MuiSvgIcon-root": {
      color: "#eebb00",
    },
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "#fff9ab",
    },
  }),
  ...(status === "ทำงานล่วงเวลา" && {
    backgroundColor: "#e9f0ff",
    color: "#1e4db6",
    "& .MuiSvgIcon-root": {
      color: "#1e4db6",
    },
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "#d4e0f9",
    },
  }),
  ...(status === "ขาดงาน" && {
    backgroundColor: "#fdf3f5",
    color: "#fc4b6c",
  }),
  ...(status === "มาสาย" && {
    backgroundColor: "#ddf0ff",
    color: "#1997f5",
  }),
  ...(status === "วันหยุดประจำปี" && {
    backgroundColor: "#f7f5ff",
    color: "#7451ff",
  }),

  ...((status === "ลาป่วย" || status === "ลากิจ" || status === "ลาพักร้อน") && {
    backgroundColor: "#ecfff5",
    color: "#38ca7f",
  }),
}));

const StyledCard = styled(Card)({
  marginBottom: 32,
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const StyledWrapFilter = styled("div")({
  marginBottom: 28,
  display: "flex",
  justifyContent: "space-between",
});

const StyledWrapLabel = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 16,
  "& .MuiChip-root": {
    backgroundColor: "#f4f6f8",
    marginRight: 8,
    ["@media only screen and (max-width: 600px)"]: {
      marginRight: 0,
    },
    "& .MuiChip-label": {
      fontWeight: 600,
    },
  },
});

const StyledWrapActionButton = styled("div")({
  marginTop: 8,
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

const StyledTableCellContent = styled(TableCell)({
  textAlign: "center",
  borderBottom: "none",
  padding: "8px 16px",
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  "&.status": {
    minWidth: 50,
    "& .MuiBox-root": {
      margin: "auto",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.15)",
      },
    },
  },
  "& .fullname": {
    width: 250,
    "& .MuiAvatar-root": {
      marginRight: 16,
    },
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "& .name": {
      textAlign: "left",
      fontWeight: 600,
      fontSize: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 300,
    },
  },
  "& .actual-plan": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 200,
    margin: "auto",
    padding: "0 20px",
  },
  "& .actual": {
    fontWeight: 600,
    fontSize: 24,
  },
  "& .plan": {
    fontWeight: 600,
    color: "#919eab",
  },
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledTableRow = styled(TableRow)({
  "& .MuiTableRow-root:last-child.MuiTableCell-root": {
    border: "none !important",
  },
});

function Row(props) {
  const { key, row, handleOpenDialogTime } = props;
  const { t, i18n } = useTranslation();
  // console.log("row",row);
  // console.log("row",index);

  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow key={key} style={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell className="tableCell">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {dayjs()
              .set("month", row)
              .locale(localStorage.getItem("language") || "th")
              .format(
                localStorage.getItem("language") === "en"
                  ? "MMMM YYYY"
                  : "MMMM BBBB"
              )}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {row.manualSheet && row.manualSheet.qtyWorkday
              ? parseFloat(row.manualSheet.qtyWorkday).toFixed(2)
              : "-"}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {row.manualSheet && row.manualSheet.otOne
              ? parseFloat(row.manualSheet.otOne.all / 60).toFixed(2)
              : "-"}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {row.manualSheet && row.manualSheet.otOneFive
              ? parseFloat(row.manualSheet.otOneFive.all / 60).toFixed(2)
              : "-"}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {row.manualSheet && row.manualSheet.otTwo
              ? parseFloat(row.manualSheet.otTwo.all / 60).toFixed(2)
              : "-"}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {row.manualSheet && row.manualSheet.otThree
              ? parseFloat(row.manualSheet.otThree.all / 60).toFixed(2)
              : "-"}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {row.manualSheet && row.manualSheet.leaveVacation
              ? parseFloat(row.manualSheet.leaveVacation).toFixed(2)
              : "-"}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {row.manualSheet && row.manualSheet.leaveSick
              ? parseFloat(row.manualSheet.leaveSick).toFixed(2)
              : "-"}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {row.manualSheet && row.manualSheet.leaveBusiness
              ? parseFloat(row.manualSheet.leaveBusiness).toFixed(2)
              : "-"}
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            <ButtonBlue
              variant={row.HaveManual ? "outlined" : "contained"}
              onClick={() => handleOpenDialogTime(row)}
            >
              {row.HaveManual ? t("Edit") : t("Add")}
            </ButtonBlue>
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ margin: 8 }}>
              <Typography align="left" variant="h6" gutterBottom>
                รายการ
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

function ManualSheet() {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  //const { result: ManualSheet } = useSelector((state) => state.manualsheetMonthly);
  const [search, setSearch] = React.useState({
    start: dayjs(new Date(today.getFullYear(), 0, 1)),
    end: dayjs(new Date(today.getFullYear(), 11, 31)),
  });
  const [openDialogTime, setOpenDialogTime] = React.useState(false);
  const [valueDialogTime, setValueDialogTime] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const [overData, setOverData] = React.useState(null);
  const openPopover = Boolean(anchorElPopover);
  const [MonthlyManualSheetState, setMonthlyManualSheetState] = React.useState(
    []
  );

  useEffect(() => {
    dispatch(allLeaveType());
    //dispatch(getMonthlyManualSheetByIdEmployees(search))
  }, []);

  // useEffect(() => {
  //   if(ManualSheet && ManualSheet.length > 0){
  //     let newManualSheet = ManualSheet.map((itemManual,index) => {
  //         let temp = {...itemManual,
  //           // date : ,
  //           stringDate : dayjs(itemManual.date).locale("th").add(543,"year").format("MMMM YY"),
  //           HaveManual : false,
  //         };
  //         if(itemManual.manualSheet){
  //           temp.HaveManual = true;
  //         }
  //         return temp;
  //     });
  //     console.log("newManualSheet : ",newManualSheet);
  //     setMonthlyManualSheetState(newManualSheet);
  //   }
  // },[ManualSheet]);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOpenDialogTime = (row) => {
    setOpenDialogTime(true);
    setValueDialogTime(row + 1);
  };

  const handleCloseDialogTime = () => {
    setValueDialogTime(null);
  };

  const logstate = () => {
    console.log("MonthlyManualSheetState", MonthlyManualSheetState);
    console.log("ManualSheet", ManualSheet);
  };

  return (
    <StyledRoot>
      <Container maxWidth="lg">
        <div style={{ marginTop: 16 }}>
          <StyledCard>
            <Box style={{ overflow: "auto" }}>
              <TableContainer style={{ overflow: "auto", minWidth: 860 }}>
                <StyledTable aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={`tableCellHead`} />
                      <TableCell
                        className={`tableCellHead tableCellHead-date-time`}
                      >
                        {t("monthWork")}
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-workingDay`}
                      >
                        {t("WorkDay")}
                      </TableCell>
                      <TableCell className={`tableCellHead overtime`}>
                        OT 1 ({t("Hours")})
                      </TableCell>
                      <TableCell className={`tableCellHead overtime`}>
                        OT 1.5 ({t("Hours")})
                      </TableCell>
                      <TableCell className={`tableCellHead overtime`}>
                        OT 2 ({t("Hours")})
                      </TableCell>
                      <TableCell className={`tableCellHead overtime`}>
                        OT 3 ({t("Hours")})
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-leave`}
                      >
                        ลาพักร้อน ({t("Days")})
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-leave`}
                      >
                        ลาป่วย ({t("Days")})
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-leave`}
                      >
                        ลากิจ ({t("Days")})
                      </TableCell>
                      <TableCell className={`tableCellHead`} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...Array(12).keys()].map((row, index) => (
                      <Row
                        key={index}
                        row={row}
                        searchDate={search}
                        openPopover={openPopover}
                        handleOpenDialogTime={handleOpenDialogTime}
                      />
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            </Box>
          </StyledCard>
        </div>
      </Container>

      {valueDialogTime && (
        <DialogTime
          values={valueDialogTime}
          open={openDialogTime}
          handleClose={handleCloseDialogTime}
          handleChangeAlertType={handleChangeAlertType}
          handleOpenAlert={handleOpenAlert}
          searchDate={search}
        />
      )}

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
}

export default ManualSheet;
