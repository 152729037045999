import { Grid, styled } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";

import { Controller, useForm } from "react-hook-form";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { getVendorProfile, updateVenderCompanyProfile } from "../../../../actions/vendor";

const StyledRoot = styled("div")({
  padding: 24,
})

const DrawerContact = (props) => {
  const { open, handleClose, data } = props;
  const dispatch = useDispatch();
  const { control, handleSubmit, formState:{ errors } } = useForm({
    defaultValues: {
      vendorName: (data && data.vendorName) || "",
      vendorNameShort: (data && data.vendorNameShort) || "",
      mainContactName: (data && data.mainContactName) || "",
      mainContactEmail: (data && data.mainContactEmail) || "",
      mainContactPhone: (data && data.mainContactPhone) || "",
    }
  })

  const onSubmit = async (value) => {
    const result = await dispatch(updateVenderCompanyProfile(value));
    if (result) {
      dispatch(getVendorProfile(1));
      handleClose();
    }
  }

  return(
    <DrawerCustom
      title="ผู้ติดต่อ"
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{style: {maxWidth: 550, width: "100%"}}}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="vendorName"
                rules={{
                  required: {value: true, message: "กรุณากรอกชื่อบริษัท"}
                }}
                render={({field})=>(
                  <TextFieldTheme 
                    {...field} 
                    label="ชื่อบริษัท"
                    helperText={errors && errors.vendorName && errors.vendorName.message}
                    error={errors && errors.vendorName ? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="vendorNameShort"
                rules={{
                  required: {value: true, message: "กรุณากรอกชื่อย่อบริษัท"}
                }}
                render={({field})=>(
                  <TextFieldTheme 
                    {...field} 
                    label="ชื่อย่อบริษัท"
                    helperText={errors && errors.vendorNameShort && errors.vendorNameShort.message}
                    error={errors && errors.vendorNameShort ? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="mainContactName"
                rules={{
                  required: {value: true, message: "กรุณากรอกชื่อผู้ติดต่อ"}
                }}
                render={({field})=>(
                  <TextFieldTheme 
                    {...field} 
                    label="ชื่อผู้ติดต่อ"
                    helperText={errors && errors.mainContactName && errors.mainContactName.message}
                    error={errors && errors.mainContactName ? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="mainContactEmail"
                rules={{
                  required: {value: true, message: "กรุณากรอกอีเมลผู้ติดต่อ"}
                }}
                render={({field})=>(
                  <TextFieldTheme 
                    {...field} 
                    label="อีเมลผู้ติดต่อ"
                    helperText={errors && errors.mainContactEmail && errors.mainContactEmail.message}
                    error={errors && errors.mainContactEmail ? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="mainContactPhone"
                rules={{
                  required: {value: true, message: "กรุณากรอกเบอร์โทรผู้ติดต่อ"}
                }}
                render={({field})=>(
                  <TextFieldTheme 
                    {...field} 
                    label="เบอร์โทรผู้ติดต่อ"
                    helperText={errors && errors.mainContactPhone && errors.mainContactPhone.message}
                    error={errors && errors.mainContactPhone ? true: false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="outlined" onClick={handleClose}>ยกเลิก</ButtonBlue>
              <ButtonBlue variant="contained" type="submit">บันทึก</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerContact;