import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from "dayjs";

import { 
    Box, 
    Grid,
    Paper, 
    styled, 
    Typography 
} from '@mui/material';

import ButtonBlue from '../../../shared/general/ButtonBlue';
import DialogEditProvidentFund from './dialogEditProvidentFund';

import { getAllPFScheduleByidVendor, getAllProvidentFundByExperienceYear } from "../../../../../actions/user";

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter);

const StyledRoot = styled("div")({});

const TextSchedule = styled(Typography)(({ isgoingon }) => ({
    color: isgoingon === "true" ? "#1976d2" : "initial",
    fontWeight: isgoingon === "true" ? "bold" : "initial"
}));

const PF = () => {
    const [openEdit, setOpenEdit] = useState(false);
    const dispatch = useDispatch();
    const { result: AllSchedules } = useSelector(state => state.userPFSchedule);
    const { result: UserProvidentFund } = useSelector(state => state.userProvidentFund);
    const { result: UserProfile } = useSelector(state => state.userProfile); 

    React.useEffect(() => {
        dispatch(getAllPFScheduleByidVendor(1));
        dispatch(getAllProvidentFundByExperienceYear(6));
    },[]);

    const checkDateForEdit = () => {
        let result = false;
        AllSchedules.map((schedule) => {
            if(checkDateIsGoingOn(schedule.dateStart,schedule.dateEnd)){
                result = true;
            }
        });
        return result;
    };

    const checkDateIsGoingOn = (dateStart,dateEnd) => {
        if(dayjs().isSameOrAfter(dateStart) && dayjs().isSameOrBefore(dateEnd)){
            return true;
        } else {
            return false;
        }
    };

    return (
        <StyledRoot>
            <Box style={{ display:"flex", flexDirection:"column", alignItems:"center" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginBottom:"20px" }}>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography>กองทุนสำรองเลี้ยงชีพ</Typography>
                            </Grid>
                            {AllSchedules && checkDateForEdit() && 
                                <Grid item>
                                    <ButtonBlue 
                                        variant="contained"
                                        onClick={()=>setOpenEdit(true)}
                                    >
                                        แก้ไข
                                    </ButtonBlue>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} justifyContent="center">
                        <Grid item>
                            <Typography style={{ fontSize:"20px" }}>ส่วนบุคคล</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} justifyContent="center">
                        <Grid item>
                        <Typography style={{ fontSize:"20px" }}>ส่วนบริษัท</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} justifyContent="center">
                        <Grid item>
                            <Box 
                                style={{ 
                                    width:"200px", 
                                    height:"50px",
                                    display:"flex", 
                                    alignItems:"center", 
                                    justifyContent:"center",
                                    border:"1px solid #bdbdbd",
                                    borderRadius:"8px"
                                }}
                            >
                                <Typography variant="h6">{UserProfile && UserProfile.userPF || UserProvidentFund && UserProvidentFund.employeePercent || "-"}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={6} justifyContent="center">
                        <Grid item>
                            <Box 
                                style={{ 
                                    width:"200px", 
                                    height:"50px",
                                    display:"flex", 
                                    alignItems:"center", 
                                    justifyContent:"center",
                                    border:"1px solid #bdbdbd",
                                    borderRadius:"8px"
                                }}
                            >
                                <Typography variant="h6">{UserProvidentFund && UserProvidentFund.vendorPercent || "-"}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Paper style={{ width:"90%", padding:"20px 20px 40px", marginTop:"50px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography 
                                style={{ textAlign: "center" }}
                                variant="h6"
                                gutterBottom
                            >
                                ตารางเวลาสามารถเปลี่ยนแปลง
                            </Typography>
                        </Grid>
                        {AllSchedules && AllSchedules.length > 0 &&
                            AllSchedules.map((schedule, index) => (
                                <Grid item xs={12} key={schedule.idVendorPFSchedule}>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={12} md={3}>
                                            <TextSchedule
                                                align="center"
                                                isgoingon={checkDateIsGoingOn(schedule.dateStart,schedule.dateEnd) ? "true" : "false"}
                                            >{`รอบที่ ${index+1}`}</TextSchedule>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextSchedule
                                                align="center"
                                                isgoingon={checkDateIsGoingOn(schedule.dateStart,schedule.dateEnd) ? "true" : "false"}
                                            >
                                                {`${dayjs(schedule.dateStart).format("DD/MM/YYYY")} - ${dayjs(schedule.dateEnd).format("DD/MM/YYYY")}`}
                                            </TextSchedule>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }
                        
                        {AllSchedules && [...Array(4 - AllSchedules.length)].map((value,index) => (
                            <Grid item xs={12} key={index}>
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} md={3}>
                                        <Typography align="center">{`รอบที่ ${AllSchedules.length+index+1}`}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography align="center">ไม่มีกำหนดการ</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>           
                </Paper>
            </Box>
            {openEdit && <DialogEditProvidentFund 
                open={openEdit}
                onClose={()=>setOpenEdit(false)}
            />}
        </StyledRoot>
    );
};

export default PF;