import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import SuccessIcon from "../../../assets/approved.png";
import CancelIcon from "../../../assets/cancel.png";

const AlertDialog = (props) => {
  const { open, onClose, title, content, type, callBack } = props;
  const { t, i18n } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: "400px",
          width: "100%",
        },
      }}
    >
      <DialogContent>
        {/* <DialogContentText> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {type === "success" && (
            <img
              src={SuccessIcon}
              width="48"
              style={{ marginBottom: "24px" }}
            />
          )}
          {type === "error" && (
            <img src={CancelIcon} width="48" style={{ marginBottom: "24px" }} />
          )}
          <Typography fontSize="18px">{content}</Typography>
        </div>
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("OK")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
