import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { makeStyles } from "@mui/styles";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import PayrollForm from "./components/payrollForm";
import PayrollSetting from "./components/payrollSetting";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SaveIcon from "@mui/icons-material/Save";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    minHeight: 300,
  },
}));

const initialValues = () => ({
  payrollId: 0,
  dayOff: 0,
  monthOff: 0,
  fullOff: 0,
  ot: 0,
  otOff: 0,
  morningShift: 0,
  afternoonShift: 0,
  nightShift: 0,
  delayTimes: "",
  decimalRounding: "",
  decimalNumber: "",
  paymentPeriod: "",
  firstCutOff: new Date(),
  secondCutOff: new Date(),
  firstPayDate: new Date(),
  secondPayDate: new Date(),
});

function Index() {
  const classes = useStyles();
  useTitle("จัดการ Payroll - HR CORE DATA");
  const [values, setValues] = useState(initialValues);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log("Data Submit", values);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.headingPage}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">จัดการ Payroll</Typography>
          </Grid>
        </Grid>
      </Box>
      <Paper className={classes.paper}>
        <PayrollForm values={values} setValues={setValues} />
        <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
        <PayrollSetting values={values} setValues={setValues} />
        <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
        <Box>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <ButtonBlue
                variant={"contained"}
                startIcon={<SaveIcon />}
                onClick={handleOnSubmit}
              >
                Submit
              </ButtonBlue>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}

export default Index;
