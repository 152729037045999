import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getBankByBankDetail,
  updateBankDetail,
  addBankDetailByVendor,
} from "../../../../actions/bank";
import { getVendorProfile } from "../../../../actions/vendor";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  width: 450,
  padding: 16,
  "& i": {
    marginRight: 8,
    fontSize: 14,
  },
  "& .part-action": {
    paddingTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .cancel-button": {
      color: "#9e9e9e",
      borderColor: "#9e9e9e",
    },
  },
});

const DrawerBank = (props) => {
  const { open, onClose, data } = props;
  const dispatch = useDispatch();
  const { result: Banks } = useSelector((state) => state.bank);
  const { result: vendorProfileStore } = useSelector(
    (state) => state.vendorProfile
  );
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      idBankDetail: (data && data.idBankDetail) || null,
      idBank: (data && data.idBank) || "none",
      accountNo: (data && data.accountNo) || "",
      accountName: (data && data.accountName) || "",
      branchName: (data && data.branchName) || "",
      branchAddress: (data && data.branchAddress) || "",
    },
  });

  const onSubmit = async (formData) => {
    let result;
    if (vendorProfileStore.idBankDetail) {
      result = await dispatch(updateBankDetail(formData));
    } else {
      result = await dispatch(addBankDetailByVendor(formData));
    }
    if (result) {
      dispatch(getVendorProfile());
      onClose();
      const result = await dispatch(updateBankDetail(formData));
      if (result) {
        dispatch(getBankByBankDetail(vendorProfileStore.idBankDetail));
        onClose();
      }
    }
  };

  return (
    <DrawerCustom open={open} title={t("EditBank")} anchor="right">
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={errors && errors.idBank ? true : false}
              >
                <InputLabel id="bank-name">{t("BankName")}</InputLabel>
                <Controller
                  control={control}
                  name="idBank"
                  rules={{
                    required: true,
                    validate: (value) =>
                      value !== "none" || `${t("SelectBank")}`,
                  }}
                  render={({ field }) => (
                    <Select
                      labelId="bank-name"
                      label={t("BankName")}
                      {...field}
                    >
                      <MenuItem value="none" disabled>
                        {t("SelectBank")}
                      </MenuItem>
                      {Banks &&
                        Banks.length > 0 &&
                        Banks.map((bank) => (
                          <MenuItem value={bank.idBank} key={bank.idBank}>
                            {bank.bankName}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
                <FormHelperText>
                  {errors && errors.idBank && errors.idBank.message}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="accountNo"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("BookBankID")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("BookBankID")}
                    helperText={
                      errors && errors.accountNo && errors.accountNo.message
                    }
                    error={errors && errors.accountNo ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="accountName"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("BankAccount")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("BankAccount")}
                    helperText={
                      errors && errors.accountName && errors.accountName.message
                    }
                    error={errors && errors.accountName ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="branchName"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("BranchName")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("BranchName")}
                    helperText={
                      errors && errors.branchName && errors.branchName.message
                    }
                    error={errors && errors.branchName ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="branchAddress"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("Address")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("Address")}
                    multiline
                    rows={3}
                    helperText={
                      errors &&
                      errors.branchAddress &&
                      errors.branchAddress.message
                    }
                    error={errors && errors.branchAddress ? true : false}
                  />
                )}
              />
            </Grid>
          </Grid>

          <div className="part-action">
            <ButtonBlue
              className="cancel-button"
              variant="outlined"
              onClick={onClose}
            >
              <i className="fa-regular fa-x"></i>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue type="submit" variant="contained">
              <i className="fa-regular fa-check"></i>
              {t("Edit")}
            </ButtonBlue>
          </div>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerBank;
