import { httpClient } from "./httpClient";

const getTaxSummary = (year) => {
  return httpClient.get("payment/payroll/tax-summary", {
    params: { year: year },
  });
};

export default {
  getTaxSummary,
};
