import { Divider, Grid, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVendorBranch,
  getAllBranchesByVendor,
} from "../../../../actions/vendor";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerBranchAddress from "./drawerBranchAddress";
import DrawerHeadOfficeAddress from "./drawerHeadOfficeAddress";
import ButtonDelete from "./shared/ButtonDelete";
import ButtonEdit from "./shared/ButtonEdit";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  "& .MuiDivider-root": {
    borderWidth: "0px 0px thin",
    borderColor: "#919eab52",
    borderStyle: "dashed",
  },
});

const AddressPanel = (props) => {
  let { data } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  let { result: AllBranches } = useSelector((state) => state.branchVendor);
  const [isOpenDrawerHeadOfficeAddress, setIsOpenDrawerHeadOfficeAddress] =
    useState(false);
  const [isOpenDrawerBranchAddressAdd, setIsOpenDrawerBranchAddressAdd] =
    useState(false);
  const [isOpenDrawerBranchAddressEdit, setIsOpenDrawerBranchAddressEdit] =
    useState({
      open: false,
      editIndex: null,
    });

  const onDeleteBranch = async (idVendorAddress, idVendorBranch) => {
    const res = await dispatch(
      deleteVendorBranch(idVendorAddress, idVendorBranch)
    );
    if (res) {
      dispatch(getAllBranchesByVendor());
    }
  };

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography className="group-header-text" variant="h7">
            {t("HeadOffice")}
          </Typography>
          <ButtonEdit
            onClick={() => {
              setIsOpenDrawerHeadOfficeAddress(true);
            }}
          />
        </Grid>
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="caption" color="text.secondary">
              {t("Address")}
            </Typography>
            <Typography>{data.address || "-"}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption" color="text.secondary">
              {t("District")}
            </Typography>
            <Typography>{data.district || "-"}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption" color="text.secondary">
              {t("Province")}
            </Typography>
            <Typography>{data.province || "-"}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption" color="text.secondary">
              {t("PostalCode")}
            </Typography>
            <Typography>{data.areaCode || "-"}</Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className="group-header-text" variant="h7">
            {t("OtherBranch")}
          </Typography>
          <ButtonBlue
            startIcon={<i className="fa-regular fa-plus"></i>}
            variant="contained"
            onClick={() => {
              setIsOpenDrawerBranchAddressAdd(true);
            }}
          >
            {t("AddBranch")}
          </ButtonBlue>
        </Grid>
        {AllBranches && AllBranches.length > 0 ? (
          AllBranches.map((branch, index) => (
            <Grid
              item
              xs={12}
              container
              spacing={1}
              key={branch.idVendorBranch}
            >
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>
                  <Typography variant="caption" color="text.secondary">
                    {t("BranchName")}
                  </Typography>
                  <Typography>{branch.branchName || "-"}</Typography>
                </Grid>
                <Grid item>
                  <ButtonEdit
                    onClick={() => {
                      setIsOpenDrawerBranchAddressEdit({
                        open: true,
                        editIndex: index,
                      });
                    }}
                  />
                  <ButtonDelete
                    onClick={() => {
                      onDeleteBranch(
                        branch.idVendorAddress,
                        branch.idVendorBranch
                      );
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color="text.secondary">
                  {t("Address")}
                </Typography>
                <Typography>{branch.address || "-"}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" color="text.secondary">
                  {t("District")}
                </Typography>
                <Typography>{branch.district || "-"}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" color="text.secondary">
                  {t("Province")}
                </Typography>
                <Typography>{branch.province || "-"}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" color="text.secondary">
                  {t("PostalCode")}
                </Typography>
                <Typography>{branch.areaCode || "-"}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" color="text.secondary">
                  {t("BranchCode")}
                </Typography>
                <Typography>{branch.branchCode || "-"}</Typography>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="text.secondary">
                ประเทศ
              </Typography>
              <Typography>ประเทศไทย</Typography>
            </Grid> */}
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" color="text.secondary">
                  {t("ContactName")}
                </Typography>
                <Typography>{branch.nameContact || "-"}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" color="text.secondary">
                  {t("PhoneNumber")}
                </Typography>
                <Typography>{branch.phoneContact || "-"}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="caption" color="text.secondary">
                  {t("Email")}
                </Typography>
                <Typography>{branch.emailContact || "-"}</Typography>
              </Grid>
              {isOpenDrawerBranchAddressEdit.open &&
                isOpenDrawerBranchAddressEdit.editIndex === index && (
                  <DrawerBranchAddress
                    open={isOpenDrawerBranchAddressEdit.open}
                    onClose={() => {
                      setIsOpenDrawerBranchAddressEdit({
                        open: false,
                        editIndex: null,
                      });
                    }}
                    isEdit
                    value={branch}
                  />
                )}
            </Grid>
          ))
        ) : (
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="text.secondary"
                style={{ textAlign: "center", margin: "20px 0" }}
              >
                {t("No_Data")}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      {isOpenDrawerHeadOfficeAddress && (
        <DrawerHeadOfficeAddress
          open={isOpenDrawerHeadOfficeAddress}
          onClose={() => {
            setIsOpenDrawerHeadOfficeAddress(false);
          }}
          data={data}
        />
      )}

      {isOpenDrawerBranchAddressAdd && (
        <DrawerBranchAddress
          open={isOpenDrawerBranchAddressAdd}
          onClose={() => {
            setIsOpenDrawerBranchAddressAdd(false);
          }}
        />
      )}
    </StyledRoot>
  );
};

export default AddressPanel;
