import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const ChartOT = (props) => {

  const { otData } = props;

  function summarizeMonthlyDataOT(employees, type) {
    const summary = {};

    // Initialize the summary with 0 for all months
    for (let i = 1; i <= 12; i++) {
      summary[i] = 0.00;
    }

    employees.forEach(employee => {
      const monthlyData = employee.monthlyData && employee.monthlyData[type];
      monthlyData.forEach(entry => {
        if (entry.value !== null) {
          const month = parseInt(entry.month.split('-')[1]); // Extract the month part from the date
          summary[month] += parseFloat(entry.value); // Add the value to the corresponding month in the summary
        }
      });
    });

    // Convert the summary object to an array
    const summarizedArray = Object.values(summary).map(value => parseFloat(value.toFixed(2)));

    return summarizedArray;
  }

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 328,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      series: [
        {
          name: "OT 1",
          data: summarizeMonthlyDataOT(otData, "TOTAL_OT1")
        },
        {
          name: "OT 1.5",
          data: summarizeMonthlyDataOT(otData, "TOTAL_OT15"),
        },
        {
          name: "OT 2",
          data: summarizeMonthlyDataOT(otData, "TOTAL_OT2"),
        },
        {
          name: "OT 3",
          data: summarizeMonthlyDataOT(otData, "TOTAL_OT3"),
        },
        {
          name: "OT รวม",
          data: summarizeMonthlyDataOT(otData, "totalOT")
        },
      ],
      title: {
        text: "Chart OT",
        align: "left",
        offsetY: 25,
        offsetX: 20,
      },
      subtitle: {
        text: "Statistics",
        offsetY: 55,
        offsetX: 20,
      },
      markers: {
        size: 6,
        strokeWidth: 0,
        hover: {
          size: 9,
        },
      },
      grid: {
        show: true,
        padding: {
          bottom: 0,
        },
      },
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      xaxis: {
        tooltip: {
          enabled: false,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetY: -20,
      },
    },
  });

  useEffect(() => {
    const newSeries = [
      {
        name: "OT 1",
        data: summarizeMonthlyDataOT(otData, "TOTAL_OT1")
      },
      {
        name: "OT 1.5",
        data: summarizeMonthlyDataOT(otData, "TOTAL_OT15"),
      },
      {
        name: "OT 2",
        data: summarizeMonthlyDataOT(otData, "TOTAL_OT2"),
      },
      {
        name: "OT 3",
        data: summarizeMonthlyDataOT(otData, "TOTAL_OT3"),
      },
      {
        name: "OT รวม",
        data: summarizeMonthlyDataOT(otData, "totalOT")
      },
    ];

    setChartData(prevChartData => ({
      ...prevChartData,
      options: {
        ...prevChartData.options,
        series: newSeries,
      }
    }));
  }, [otData]);

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartData.options}
            series={chartData.options.series}
            type="line"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default ChartOT;
