import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  styled,
  InputAdornment,
  FormControl,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import EditIcon from "@mui/icons-material/Edit";
import AddOrEditModal from "./AddOrEditModal";
import NumberFormat from "react-number-format";
import PaidIcon from "@mui/icons-material/Paid";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  boxForm: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  editBtn: {
    marginLeft: theme.spacing(2),
    backgroundColor: "#f5f5f5",
  },
}));

const StyledTextFieldTheme = styled(TextFieldTheme)({
  marginBottom: 16,
});

const StyleOutlineInputTheme = styled(OutlinedInput)({
  borderRadius: "8px",
  marginBottom: 8,
});

function PayrollForm(props) {
  const { values, setValues, company } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [addOrEditModal, setAddOrEditModal] = useState({
    isOpen: false,
  });

  useEffect(() => {
    console.log("company: ", company);
    if (payrollSetting && userProfile) {
      const find = payrollSetting.find(
        (x) =>
          x.idVendor === userProfile.idVendor &&
          x.idCompany === (company ? company.idCompany : null)
      );
      setValues(find);
    }
  }, [payrollSetting]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div>
      {payrollSetting && (
        <Fragment>
          <Box style={{ marginBottom: 2 }}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                fontSize: "1.25rem",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              {t("OvertimeHolidayPay")}
            </Typography>
          </Box>
          {values && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">
                    {t("OvertimePayOnNormalWorkingDays")}
                  </Typography>
                  <StyledTextFieldTheme
                    name="xOT"
                    value={values.xOT}
                    onChange={handleInputChange}
                    type="number"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {t("Times")}
                        </InputAdornment>
                      ),
                    }}
                    margin="dense"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">
                    {t("HolidayOvertimePay")}
                  </Typography>
                  <StyledTextFieldTheme
                    name="xOTHoliday"
                    value={values.xOTHoliday}
                    onChange={handleInputChange}
                    type="number"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {t("Times")}
                        </InputAdornment>
                      ),
                    }}
                    margin="dense"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">
                    {`${t("HolidayWorkAllowance")} (${t("Daily")})`}
                  </Typography>
                  <StyledTextFieldTheme
                    name="xWorkingDailyHoliday"
                    value={values.xWorkingDailyHoliday}
                    onChange={handleInputChange}
                    type="number"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {t("Times")}
                        </InputAdornment>
                      ),
                    }}
                    margin="dense"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">
                    {`${t("HolidayWorkAllowance")} (${t("Monthly")})`}
                  </Typography>
                  <StyledTextFieldTheme
                    name="xWorkingMonthlyHoliday"
                    value={values.xWorkingMonthlyHoliday}
                    onChange={handleInputChange}
                    type="number"
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          {t("Times")}
                        </InputAdornment>
                      ),
                    }}
                    margin="dense"
                    fullWidth
                  />
                </div>
              </Grid>
            </Grid>
          )}
          <Box style={{ marginBottom: 2 }}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                fontSize: "1.25rem",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              {t("OvertimeHolidayPayBilling")}
            </Typography>
          </Box>
          {values && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">
                    {t("OvertimePayOnNormalWorkingDays")}
                  </Typography>
                  <StyledTextFieldTheme
                    name="xOTBilling"
                    value={values.xOTBilling}
                    onChange={handleInputChange}
                    type="number"
                    InputProps={{
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     {t("Times")}
                      //   </InputAdornment>
                      // ),
                    }}
                    margin="dense"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">
                    {t("HolidayOvertimePay")}
                  </Typography>
                  <StyledTextFieldTheme
                    name="xOTHolidayBilling"
                    value={values.xOTHolidayBilling}
                    onChange={handleInputChange}
                    type="number"
                    InputProps={{
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     {t("Times")}
                      //   </InputAdornment>
                      // ),
                    }}
                    margin="dense"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">
                    {`${t("HolidayWorkAllowance")} (${t("Daily")})`}
                  </Typography>
                  <StyledTextFieldTheme
                    name="xWorkingDailyHolidayBilling"
                    value={values.xWorkingDailyHolidayBilling}
                    onChange={handleInputChange}
                    type="number"
                    InputProps={{
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     {t("Times")}
                      //   </InputAdornment>
                      // ),
                    }}
                    margin="dense"
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div>
                  <Typography variant="body1">
                    {`${t("HolidayWorkAllowance")} (${t("Monthly")})`}
                  </Typography>
                  <StyledTextFieldTheme
                    name="xWorkingMonthlyHolidayBilling"
                    value={values.xWorkingMonthlyHolidayBilling}
                    onChange={handleInputChange}
                    type="number"
                    InputProps={{
                      // endAdornment: (
                      //   <InputAdornment position="end">
                      //     {t("Times")}
                      //   </InputAdornment>
                      // ),
                    }}
                    margin="dense"
                    fullWidth
                  />
                </div>
              </Grid>
            </Grid>
          )}

          <Box style={{ marginBottom: 2 }}>
            <Typography
              variant="body1"
              gutterBottom
              style={{
                fontSize: "1.25rem",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              {t("ShiftFee")}
            </Typography>
          </Box>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={4}>
              <div>
                <Typography variant="body1">{t("DayShift")}</Typography>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  disabled
                >
                  <NumberFormat
                    type="text"
                    thousandSeparator={true}
                    customInput={StyleOutlineInputTheme}
                    value={payrollSetting.morningShiftFee}
                    allowNegative={false}
                    endAdornment={
                      <InputAdornment position="end">
                        {t("Baht")}
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div>
                <Typography variant="body1">{t("MidShift")}</Typography>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  disabled
                >
                  <NumberFormat
                    type="text"
                    thousandSeparator={true}
                    customInput={StyleOutlineInputTheme}
                    value={payrollSetting.afternoonShiftFee}
                    allowNegative={false}
                    endAdornment={
                      <InputAdornment position="end">
                        {t("Baht")}
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div>
                <Typography variant="body1">{t("NightShift")}</Typography>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  disabled
                >
                  <NumberFormat
                    type="text"
                    thousandSeparator={true}
                    customInput={StyleOutlineInputTheme}
                    value={payrollSetting.nightShiftFee}
                    allowNegative={false}
                    endAdornment={
                      <InputAdornment position="end">
                        {t("Baht")}
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <AddOrEditModal
            addOrEditModal={addOrEditModal}
            setAddOrEditModal={setAddOrEditModal}
            values={values}
            setValues={setValues}
          />
        </Fragment>
      )}
    </div>
  );
}

export default PayrollForm;
