import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Autocomplete, Box, Checkbox, FormControlLabel, Grid, InputAdornment, MenuItem, styled, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../../shared/date/datePicker";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import dayjs from "dayjs";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import vendorService from "../../../../../../services/vendor.service";
import { getEmployeePersonnelLevelChange, getEmployeeProfile } from "../../../../../../actions/employee";
import { updateEmployeePersonnelLevel } from "../../../../../../actions/level";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
})

const DrawerEdit = (props) => {

  const { drawerConfig, onClose } = props;

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { result: employeeProfile } = useSelector(state => state.employeeProfile);
  const [personnelLevelList, setPersonnelLevelList] = useState([]);

  const validateYupSchema = yup.object({
    level: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    start: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    end: yup.mixed().nullable().when("isCurrent", {
      is: false,
      then: (schema) => schema
      .required(t("ThisFieldIsRequired"))
      .test("greaterOrEqualStartDate", t("CanNotSelectDateBeforeStart"), (value, context) => {
        return dayjs(value).isSameOrAfter(dayjs(context.parent.start))
      }),
    }),
  });

  const useHookForm = useForm({
    defaultValues: {
      level: null,
      start: null,
      end: null,
      remark: "",
      isCurrent: false,
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const onSubmit = async (data) => {

    const formData = {
      idLevel: data.level.idLevel,
      start: dayjs(data.start).format("YYYY-MM-DD"),
      end: data.isCurrent? null: dayjs(data.end).format("YYYY-MM-DD"),
      remark: data.remark? data.remark: null,
    }

    const result = await dispatch(updateEmployeePersonnelLevel(drawerConfig.data.idEmployeePersonnelLevel, formData))

    if(result && result.status === 200) {
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
      dispatch(getEmployeePersonnelLevelChange(employeeProfile.idEmp));
      onClose();
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }
  }

  const isCurrentWatch = useHookForm.watch("isCurrent");

  useEffect(() => {
    if(drawerConfig.isOpen){
      vendorService.getLevelByVendorId(employeeProfile.idVendor).then(res => {
        setPersonnelLevelList(res.data);
      })

      useHookForm.setValue("level", null, {shouldValidate: false, shouldDirty: false, shouldTouch: false});
      useHookForm.setValue("start", drawerConfig.data.start? dayjs(drawerConfig.data.start): null, { shouldValidate: false, shouldDirty: false, shouldTouch: false});
      useHookForm.setValue("end", drawerConfig.data.end? dayjs(drawerConfig.data.end): null, { shouldValidate: false, shouldDirty: false, shouldTouch: false});
      useHookForm.setValue("remark", drawerConfig.data.remark? drawerConfig.data.remark: "", { shouldValidate: false, shouldDirty: false, shouldTouch: false});
      useHookForm.setValue("isCurrent", drawerConfig.data.end? false: true, { shouldValidate: false, shouldDirty: false, shouldTouch: false});
      useHookForm.clearErrors();
    }
  }, [drawerConfig.isOpen]);

  return (
    <DrawerCustom
      title={"แก้ไขระดับพนักงาน"}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="500" gutterBottom>ระดับพนักงานเดิม</Typography>
              <Typography>({drawerConfig.data.groupLevelName}) {drawerConfig.data.levelName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="500" gutterBottom>ระดับพนักงานใหม่</Typography>
              <Controller
                name="level"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <Autocomplete
                    {...field}
                    options={personnelLevelList}
                    getOptionLabel={(option) => `(${option.groupLevelName}) ${option.levelName}`}
                    isOptionEqualToValue={(option, value) => option.idLevel === value.idLevel}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idLevel}>
                        <Box>
                          <Typography fontSize="14px" color="text.secondary">{option.groupLevelName}</Typography>
                          <Typography>{option.levelName}</Typography>
                          <Typography fontSize="12px" color="text.secondary">{option.companyName}</Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={t("SelectData")}
                        onBlur={field.onBlur}
                        error={fieldState.error? true: false}
                        helperText={fieldState.error? fieldState.error.message: null}
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    noOptionsText={t("NoData")}
                    disableClearable
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="500" gutterBottom>เริ่มต้น</Typography>
              <Controller
                name="start"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    openTo="day"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                      // useHookForm.trigger("endDate");
                    }}
                    disableMaskedInput
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("SelectDate"),
                          readOnly: true
                        }}
                        onBlur={field.onBlur}
                        error={fieldState.error? true : false}
                        helperText={fieldState.error? fieldState.error.message : null}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" alignItems="center" paddingBottom="8px">
                <Typography fontSize="14px" fontWeight="500">สิ้นสุด</Typography>
                <Controller
                  name={"isCurrent"}
                  control={useHookForm.control}
                  render={({field}) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            useHookForm.setValue("end", null);
                          }}
                        />
                      }
                      label={<Typography fontSize="14px" fontWeight="500">{t("Current")}</Typography>}
                      labelPlacement="start"
                    />
                  )}
                />
              </Grid>
              {!isCurrentWatch && <Controller
                name="end"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <DatePickerCustom
                    {...field}
                    views={["year", "month", "day"]}
                    inputFormat="DD MMMM YYYY"
                    openTo="day"
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                      // useHookForm.trigger("endDate");
                    }}
                    disableMaskedInput
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: t("SelectDate"),
                          readOnly: true
                        }}
                        onBlur={field.onBlur}
                        error={fieldState.error? true: false}
                        helperText={fieldState.error? fieldState.error.message: null}
                      />
                    )}
                  />
                )}
              />}
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="500" gutterBottom>รายละเอียด</Typography>
              <Controller
                name="remark"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{
                            alignSelf: "flex-end",
                            fontSize: "12px",
                            userSelect: "none",
                          }}
                        >
                          {500 - field.value.length}
                        </InputAdornment>
                      ),
                    }}
                    multiline
                    minRows={3}
                    placeholder={`${t("Description")}...`}
                    helperText={
                      fieldState.error? fieldState.error.message: null
                    }
                    error={
                      fieldState.error? true : false
                    }
                    onChange={(event) => {
                      if (event.target.value.length > 500) {
                        field.onChange(event.target.value.slice(0, 500));
                      } else {
                        field.onChange(event.target.value);
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>{t("Save")}</ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEdit;