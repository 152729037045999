import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import useTable from "../../../../shared/table/useTable";
import {
  Avatar,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  MenuItem,
  Menu,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import DrawerEditManager from "./DrawerEditManager";

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "transparent",
    "& .sticky": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 16px",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
});

function DataTable(props) {
  const { handleChangeManagerEdit, company } = props;
  const { result: managerList } = useSelector((state) => state.manager);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSelected, setValueSelected] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();

  const headCells = [
    {
      id: "action",
      label: ``,
      align: "center",
      maxWidth: 32,
    },
    {
      id: "name",
      label: `${t("FullName")}`,
      align: "left",
      minWidth: 280,
    },
    { id: "position", label: `${t("Position")}`, align: "left" },
    { id: "email", label: `${t("Email")}`, align: "left" },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [drawerManagerConfig, setDrawerManagerConfig] = useState({
    open: false,
    isEdit: false,
    values: {
      idManagerEmployee: null,
      name: null,
      positionsName: null,
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(managerList, headCells, filterFn);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <TblContainer height={250}>
        <Table stickyHeader aria-label="sticky table">
          <TblHead />
          <TableBody>
            {managerList ? (
              (managerList.length > 0 &&
                managerList.map((item) => (
                  <StyledRowContent key={item.idManagerEmployee}>
                    <StyledCellContent>
                      <IconButton
                        aria-controls={open ? "more-menu" : undefined}
                        aria-label="more"
                        size="small"
                        // onClick={(event) => {
                        //   console.log(
                        //     "event.currentTarget :",
                        //     event.currentTarget
                        //   );
                        //   setAnchorEl(event.currentTarget);
                        //   setValueSelected(item);
                        //   console.log(item);
                        // }}
                        onClick={() => {
                          setDrawerManagerConfig((prev) => ({
                            ...prev,
                            open: true,
                            isEdit: true,
                            values: {
                              idManagerEmployee: item.idManagerEmployee,
                              name: item.name,
                              positionsName: item.positionsName,
                              idUsers: item.idUsers,
                            },
                          }));
                        }}
                      >
                        <EditRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography>{item.name}</Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography>{item.positionsName}</Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography>{item.email}</Typography>
                    </StyledCellContent>
                  </StyledRowContent>
                ))) || (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No Data
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TblContainer>
      <TblPagination />
      <StyledMenu
        id={`more-menu`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleCloseMenu}>Reset password</MenuItem>
      </StyledMenu>

      <DrawerEditManager
        open={drawerManagerConfig.open}
        onClose={() => {
          setDrawerManagerConfig((prev) => ({ ...prev, open: false }));
        }}
        isEdit={drawerManagerConfig.isEdit}
        values={drawerManagerConfig.values}
        selectedCompany={company}
      />
    </div>
  );
}

export default DataTable;
