import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
// import { addLeaveType, getAllLeaveV2 } from "../../../../../actions/leave";
import { useTranslation } from "react-i18next";
import { companyEmployeeType } from "../../../../../../actions/company";
import { getJobLevlByidCompany } from "../../../../../../actions/jobLevel";
import {
  addVendorCompanyLeaveType,
  editVendorCompanyLeaveType,
  getAllVendorCompanyLeaveType,
} from "../../../../../../actions/leave";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
});

const LeaveForm = (props) => {
  const {
    drawerConfig,
    onClose,
    setOpenAlert,
    setAlertType,
    selectedCompany,
    setDrawerConfig,
  } = props;

  const { t, i18n } = useTranslation();

  const { result: jobLevel } = useSelector((state) => state.jobLevel);
  const { result: employeeType } = useSelector((state) => state.employeeType);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [deleteList, setDeleteList] = useState([]);

  const dispatch = useDispatch();

  const getEmployeeTypeList = () => {
    const list = [
      {
        idEmployeeType: "",
        employeeTypeName: "ทั้งหมด",
      },
    ];

    if (employeeType) {
      employeeType.map((item) => {
        if(item){
          list.push({
            idEmployeeType: item.idEmployeeType,
            employeeTypeName: item.employeeTypeName,
          });
        }
      });

      return list;
    } else {
      return list;
    }
  };

  const getJobLevelList = () => {
    const list = [
      {
        idJobLevel: "",
        jobLevelName: "ทั้งหมด",
      },
    ];

    if (jobLevel) {
      jobLevel.map((item) => {
        list.push({
          idJobLevel: item.idJobLevel,
          jobLevelName: item.jobLevelName,
        });
      });

      return list;
    } else {
      return list;
    }
  };

  const validateYupSchema = yup.object({
    name: yup.string().required("กรุณากรอกข้อมูล"),
    employeeTypes: yup.array().min(1, "กรุณากรอกข้อมูล"),
    jobLevels: yup.array().min(1, "กรุณากรอกข้อมูล"),
    isPaid: yup.string().required("กรุณากรอกข้อมูล"),
    canRequestAfter: yup.string().required("กรุณากรอกข้อมูล"),
    gender: yup.string().required("กรุณากรอกข้อมูล"),
    minLeave: yup.string().required("กรุณากรอกข้อมูล"),
    isLeaveStep: yup.string().required("กรุณากรอกข้อมูล"),
    isProRate: yup.string().required("กรุณากรอกข้อมูล"),
    proRateRound: yup.string().required("กรุณากรอกข้อมูล"),
    // leaveValue: yup.string().required("กรุณากรอกข้อมูล").nullable(),
    // carryValue: yup.string().required("กรุณากรอกข้อมูล").nullable(),
    dayInAdvance: yup.string().required("กรุณากรอกข้อมูล"),
    steps: yup.array().of(
      yup.object().shape({
        leaveStepList: yup.array().of(
          yup.object().shape({
            lessThanYear: yup.string().required("กรุณากรอกข้อมูล"),
            moreThanYears: yup
              .string()
              .required("กรุณากรอกข้อมูล")
              .test(
                "is-less-than-max",
                "ข้อมูลไม่ถูกต้อง",
                (value, context) => {
                  return Number(value) >= Number(context.parent.lessThanYear);
                }
              ),
            leaveValue: yup.string().required("กรุณากรอกข้อมูล"),
            carryValue: yup.string().required("กรุณากรอกข้อมูล"),
          })
        ),
      })
    ),
  });

  const useHookForm = useForm({
    defaultValues: {
      name: "",
      name_EN: "",
      employeeTypes: [],
      jobLevels: [],
      isPaid: "",
      canRequestAfter: "",
      gender: "",
      minLeave: "",
      dayInAdvance: "",
      isProRate: "0",
      proRateRound: "1",
      isLeaveStep: "",
      leaveValue: null,
      carryValue: null,
      canRequestAfter: "",
      steps: [
        {
          conditionType: "SERV",
          lessThanYear: "",
          moreThanYears: "",
          leaveValue: "",
          carryValue: "",
        },
      ],
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const useFieldArrayLeaveType = useFieldArray({
    control: useHookForm.control,
    name: "steps",
  });

  const onSubmit = async (data) => {
    const formData = {
      idLeaveType:
        drawerConfig.isEdit === true ? drawerConfig.data.idLeaveType : null,
      name: data.name,
      name_EN: data.name_EN,
      employeeTypes: data.employeeTypes[0] === "" ? [null] : data.employeeTypes,
      jobLevels: data.jobLevels[0] === "" ? [null] : data.jobLevels,
      minLeave: data.minLeave,
      dayInAdvance: data.dayInAdvance === "" ? null : data.dayInAdvance,
      leaveValue: data.leaveValue === "" ? null : data.leaveValue,
      carryValue: data.carryValue === "" ? null : data.carryValue,
      isProRate: data.isProRate,
      proRateRound: data.proRateRound,
      canRequestAfter: data.canRequestAfter,
      isPaid: data.isPaid,
      isLeaveStep: data.isLeaveStep,
      steps: data.steps,
      gender: data.gender === "All" ? null : data.gender,
      idCompany: selectedCompany.idCompany,
      idVendor: userProfile.idVendor,
      deleteLeaveTypeList: deleteList,
      managerLv1: "approve",
      isActive: 1,
    };

    // console.log(formData);
    let result = "";
    if (drawerConfig.isEdit) {
      formData.idLeaveType = drawerConfig.data.idLeaveType;
      result = await dispatch(editVendorCompanyLeaveType(formData));
    } else {
      result = await dispatch(addVendorCompanyLeaveType(formData));
    }

    if (result) {
      if (result.status === 200) {
        setOpenAlert(true);
        setAlertType("success");
        onClose();
        await dispatch(
          getAllVendorCompanyLeaveType({
            idCompany: selectedCompany.idCompany,
            idVendor: userProfile.idVendor,
          })
        );
      } else {
        setOpenAlert(true);
        setAlertType("error");
        // onClose();
      }
    } else {
      setOpenAlert(true);
      setAlertType("error");
    }
  };

  const isProRateWatch = useHookForm.watch("isProRate");

  useEffect(() => {
    if (drawerConfig.isOpen) {
      setDeleteList([]);

      if (drawerConfig.isEdit) {
        // console.log("DDDDD:", drawerConfig.data);
        let employeeTypeList =
        drawerConfig.data.employeeTypes && drawerConfig.data.employeeTypes.length > 0 
            ? drawerConfig.data.employeeTypes.filter(
              (item, index) =>
                drawerConfig.data.employeeTypes.indexOf(item) === index
            )
            : []
        let jobLevelList =
        drawerConfig.data.jobLevels && drawerConfig.data.jobLevels.length > 0
            ? drawerConfig.data.jobLevels.filter(
              (item, index) =>
                drawerConfig.data.jobLevels.indexOf(item) === index
            )
            : []
        useHookForm.reset({
          name: drawerConfig.data.name || "",
          name_EN: drawerConfig.data.name_EN || "",
          idLeaveType: drawerConfig.data.idLeaveType,
          employeeTypes: employeeTypeList,
          jobLevels: jobLevelList,
          isPaid: String(drawerConfig.data.isPaid) || "",
          canRequestAfter: String(drawerConfig.data.canRequestAfter) || "",
          gender:
            drawerConfig.data.gender === null
              ? "All"
              : String(drawerConfig.data.gender) || "",
          minLeave: String(drawerConfig.data.minLeave) || "",
          dayInAdvance:
            drawerConfig.data.dayInAdvance === null
              ? ""
              : String(drawerConfig.data.dayInAdvance),
          isProRate: String(drawerConfig.data.isProRate) || "0",
          proRateRound: String(drawerConfig.data.proRateRound) || "1",
          isLeaveStep:
            drawerConfig.data.isLeaveStep === null
              ? ""
              : String(drawerConfig.data.isLeaveStep),
          leaveValue:
            drawerConfig.data.leaveValue === null
              ? ""
              : String(drawerConfig.data.leaveValue),
          carryValue:
            drawerConfig.data.carryValue === null
              ? ""
              : String(drawerConfig.data.carryValue),
          steps:
            drawerConfig.data.steps && drawerConfig.data.steps.length > 0
              ? drawerConfig.data.steps.map((step) => {
                  return {
                    conditionType: step.conditionType || "",
                    lessThanYear:
                      step.lessThanYear === null
                        ? ""
                        : String(step.lessThanYear),
                    moreThanYears:
                      step.moreThanYears === null
                        ? ""
                        : String(step.moreThanYears),
                    leaveValue:
                      step.leaveValue === null ? "" : String(step.leaveValue),
                    carryValue:
                      step.carryValue === null ? "" : String(step.carryValue),
                  };
                })
              : [
                  {
                    idLeaveType: null,
                    employeeTypes: [],
                    jobLevels: [],
                    isPaid: "",
                    canRequestAfter: "",
                    gender: "",
                    minLeave: "",
                    dayInAdvance: "",
                    isProRate: "0",
                    proRateRound: "1",
                    isLeaveStep: "",
                    leaveValue: "",
                    carryValue: "",
                    steps: [],
                  },
                ],
        });
      } else {
        useHookForm.reset({
          name: "",
          name_EN: "",
          idLeaveType: null,
          employeeTypes: [],
          jobLevels: [],
          isPaid: "",
          canRequestAfter: "",
          gender: "",
          minLeave: "",
          dayInAdvance: "",
          isProRate: "0",
          proRateRound: "1",
          isLeaveStep: "",
          leaveValue: null,
          carryValue: null,
          steps: [
            {
              idLeaveStep: null,
              lessThanYear: "",
              moreThanYears: "",
              leaveValue: "",
              carryValue: "",
            },
          ],
        });
      }
    }
    // dispatch(companyEmployeeType(selectedCompany.idCompany));
    // dispatch(getJobLevlByidCompany(selectedCompany.idCompany));
  }, [drawerConfig.isOpen]);

  return (
    <StyledRoot>
      <Box marginBottom="16px" display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <IconButton aria-label="delete" onClick={onClose}>
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
          <Typography fontSize="24px" fontWeight="500">
            {drawerConfig.isEdit ? t("EditLeaveDay") : t("AddLeaveDay")}
          </Typography>
        </Box>
      </Box>
      {employeeType && jobLevel && (
        <form
          onSubmit={useHookForm.handleSubmit(onSubmit, (error) => {
            console.log(error);
          })}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">{t("LeaveName")}</Typography>
              <Controller
                name="name"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography className="field-label">
                {t("LeaveNameOptional")}
              </Typography>
              <Controller
                name="name_EN"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={useHookForm.formState.errors[field.name]}
                    InputProps={{
                      placeholder: `${t("Optional")}`,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">
                {t("EmployeeType")}
              </Typography>
              <Controller
                name={`employeeTypes`}
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: (select) => (
                        <Box>
                          {select.map((value) => (
                            <Chip
                              key={value}
                              label={
                                getEmployeeTypeList().find(
                                  (x) => x.idEmployeeType === value
                                ).employeeTypeName
                              }
                            />
                          ))}
                        </Box>
                      ),
                    }}
                    onChange={(e) => {
                      if (
                        e.target.value.length > 0 &&
                        e.target.value[e.target.value.length - 1] !== ""
                      ) {
                        const temp = e.target.value.filter((x) => x !== "");
                        field.onChange(temp);
                      } else if (e.target.value.includes("")) {
                        useHookForm.setValue(`employeeTypes`, [""]);
                        // if(index === 0){
                        //   useHookForm.watch('leaveTypeList').map((leaveType, lIndex) => {
                        //     if(lIndex > 0){
                        //       useHookForm.setValue(`leaveTypeList.${lIndex}.employmentTypeList`, [])
                        //     }
                        //   })
                        // }
                      } else {
                        field.onChange(e.target.value);
                      }
                    }}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    {/* <MenuItem value="">ทั้งหมด</MenuItem> */}
                    {getEmployeeTypeList().map((item, typeIndex) => {
                      return (
                        <MenuItem
                          value={item.idEmployeeType}
                          key={item.idEmployeeType}
                        >
                          {item.employeeTypeName}
                        </MenuItem>
                      );
                    })}
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">{t("JobLevel")}</Typography>
              <Controller
                name={`jobLevels`}
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    disabled={useHookForm.watch(`employeeTypes`).length === 0}
                    SelectProps={{
                      multiple: true,
                      renderValue: (select) => (
                        <Box>
                          {select.map((value) => (
                            <Chip
                              key={value}
                              label={
                                getJobLevelList().find(
                                  (x) => x.idJobLevel === value
                                ).jobLevelName
                              }
                            />
                          ))}
                        </Box>
                      ),
                    }}
                    onChange={(e) => {
                      if (
                        e.target.value.length > 0 &&
                        e.target.value[e.target.value.length - 1] !== ""
                      ) {
                        const temp = e.target.value.filter((x) => x !== "");
                        field.onChange(temp);
                      } else if (e.target.value.includes("")) {
                        useHookForm.setValue(`jobLevels`, [""]);
                      } else {
                        field.onChange(e.target.value);
                      }
                    }}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    {/* <MenuItem value="">ทั้งหมด</MenuItem> */}
                    {getJobLevelList().map((item) => (
                      <MenuItem value={item.idJobLevel} key={item.idJobLevel}>
                        {item.jobLevelName}
                      </MenuItem>
                    ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">{t("IsPaid")}</Typography>
              <Controller
                name={`isPaid`}
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    <MenuItem value="0">{t("NotPaying")}</MenuItem>
                    <MenuItem value="1">{t("Pay")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">
                {t("CanRequestLeaveAfter")}
              </Typography>
              <Controller
                name={`canRequestAfter`}
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      if (e.target.value >= 365) {
                        useHookForm.setValue(`isProRate`, "0");
                        useHookForm.setValue(`proRateRound`, "0");
                      }
                    }}
                  >
                    <MenuItem value="0">{t("FirstDayOfService")}</MenuItem>
                    <MenuItem value="60">{`${t("YearOfService")} 60 ${t(
                      "Days"
                    )}`}</MenuItem>
                    <MenuItem value="90">{`${t("YearOfService")} 90 ${t(
                      "Days"
                    )}`}</MenuItem>
                    <MenuItem value="120">{`${t("YearOfService")} 120 ${t(
                      "Days"
                    )}`}</MenuItem>
                    <MenuItem value="180">{`${t("YearOfService")} 180 ${t(
                      "Days"
                    )}`}</MenuItem>
                    <MenuItem value="240">{`${t("YearOfService")} 240 ${t(
                      "Days"
                    )}`}</MenuItem>
                    <MenuItem value="365">{`${t("YearOfService")} 1 ${t(
                      "Year"
                    )}`}</MenuItem>
                    <MenuItem value="730">{`${t("YearOfService")} 2 ${t(
                      "Year"
                    )}`}</MenuItem>
                    <MenuItem value="1095">{`${t("YearOfService")} 3 ${t(
                      "Year"
                    )}`}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">{t("Gender")}</Typography>
              <Controller
                name={`gender`}
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    <MenuItem value="All">{t("AllGender")}</MenuItem>
                    <MenuItem value="ชาย">{t("Male")}</MenuItem>
                    <MenuItem value="หญิง">{t("Female")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">
                {t("MinimumLeaveAllowed")}
              </Typography>
              <Controller
                name={`minLeave`}
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    <MenuItem value="100">{t("FullDay")}</MenuItem>
                    <MenuItem value="50">{t("HalfDay")}</MenuItem>
                    <MenuItem value="10">{`1 ${t("Hours")}`}</MenuItem>
                    <MenuItem value="5">{t("HalfHour")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">{`${t("LeaveIn")} (${t(
                "Days"
              )})`}</Typography>
              <Controller
                name={`dayInAdvance`}
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography className="field-label">
                {t("TypeOfLeaveDays")}
              </Typography>
              <Controller
                name={`isLeaveStep`}
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    select
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      if (e.target.value === "1") {
                        useHookForm.setValue(`steps`, [
                          {
                            conditionType: "SERV",
                            lessThanYear: "",
                            moreThanYears: "",
                            leaveValue: "",
                            carryValue: "",
                          },
                        ]);
                      }
                      useHookForm.setValue(`leaveValue`, null);
                      useHookForm.setValue(`carryValue`, null);
                    }}
                  >
                    <MenuItem value="0">{t("Fixed")}</MenuItem>
                    <MenuItem value="1">{t("Step")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>

            {useHookForm.watch(`canRequestAfter`) < 365 && (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Typography fontSize="14px" fontWeight="500">
                      {t("CalProRateLessThanOneYear")}
                    </Typography>
                    {isProRateWatch === "1" && (
                      <Typography
                        paddingTop="4px"
                        paddingBottom="4px"
                        fontSize="12px"
                        color="text.secondary"
                      >
                        {t("RoundsDecimalIsGreater")}
                      </Typography>
                    )}
                  </Box>
                  <Controller
                    name={`isProRate`}
                    control={useHookForm.control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={String(field.value) === "1" ? true : false}
                        onChange={(e) => {
                          field.onChange(e.target.checked ? "1" : "0");
                          useHookForm.setValue(`proRateRound`, "0");
                        }}
                      />
                    )}
                  />
                </Box>

                {String(useHookForm.watch(`isProRate`)) === "1" && (
                  <Controller
                    name={`proRateRound`}
                    // name="proRateRound"
                    control={useHookForm.control}
                    render={({ field }) => (
                      <TextFieldTheme
                        {...field}
                        select
                        helperText={
                          useHookForm.formState.errors[field.name]
                            ? useHookForm.formState.errors[field.name].message
                            : null
                        }
                        error={
                          useHookForm.formState.errors[field.name]
                            ? true
                            : false
                        }
                      >
                        <MenuItem value="1">{t("ThereIsNoRounding")}</MenuItem>
                        <MenuItem value="0.5">{`${t(
                          "RoundWhenGreaterThan"
                        )} 0.5`}</MenuItem>
                        <MenuItem value="0">{t("RoundUpInAllCases")}</MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                )}
              </Grid>
            )}

            <Grid item container spacing={2}>
              <Grid item xs={12} container spacing={2}>
                <FormLeaveValue useHookForm={useHookForm} />
              </Grid>
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue
                variant="text"
                onClick={onClose}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      )}
    </StyledRoot>
  );
};

const FormLeaveValue = ({ useHookForm, index }) => {
  const { t, i18n } = useTranslation();
  const isLeaveStepWatch = useWatch({
    control: useHookForm.control,
    name: `isLeaveStep`,
  });

  const useFieldArrayLeaveStep = useFieldArray({
    control: useHookForm.control,
    name: `steps`,
  });

  return (
    <Fragment>
      {isLeaveStepWatch === "0" && (
        <Fragment>
          <Grid item xs={12} md={6}>
            <Typography className="field-label">{`${t("TotalLeaveDays")} (${t(
              "Days"
            )})`}</Typography>
            <Controller
              name={`leaveValue`}
              control={useHookForm.control}
              render={({ field }) => (
                <TextFieldTheme
                  {...field}
                  helperText={
                    useHookForm.formState.errors[field.name]
                      ? useHookForm.formState.errors[field.name].message
                      : null
                  }
                  error={
                    useHookForm.formState.errors[field.name] ? true : false
                  }
                  InputProps={{
                    placeholder: "กรณีไม่กรอกจำนวน หมายถึงไม่จำกัดจำนวนวันลา",
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className="field-label">
              {t("CannotCarryOverLeaveNextRound")}
            </Typography>
            <Controller
              name={`carryValue`}
              // name="carryValue"
              control={useHookForm.control}
              render={({ field }) => (
                <TextFieldTheme
                  {...field}
                  helperText={
                    useHookForm.formState.errors[field.name]
                      ? useHookForm.formState.errors[field.name].message
                      : null
                  }
                  error={
                    useHookForm.formState.errors[field.name] ? true : false
                  }
                />
              )}
            />
          </Grid>
        </Fragment>
      )}

      {isLeaveStepWatch === "1" && (
        <Fragment>
          {useFieldArrayLeaveStep.fields.map((item, fieldIndex) => (
            <Grid key={item.id} item xs={12} container spacing={2}>
              <Grid
                item
                xs={12}
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  fontWeight="500"
                  sx={{
                    paddingLeft: "8px",
                    borderLeft: "8px solid #46cbe2",
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                  }}
                >{`${t("Condition")} ${fieldIndex + 1}`}</Typography>
                <IconButton
                  disabled={
                    useFieldArrayLeaveStep.fields.length <= 1 ||
                    fieldIndex < useFieldArrayLeaveStep.fields.length - 1
                  }
                  onClick={() => {
                    useFieldArrayLeaveStep.remove(fieldIndex);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-label">
                  {t("ConditionType")}
                </Typography>
                <Controller
                  name={`steps.${fieldIndex}.conditionType`}
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      select
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                      disabled
                    >
                      <MenuItem value="SERV">{t("YearOfService")}</MenuItem>
                      <MenuItem value="JOBLEVEL">{t("JobLevel")}</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="field-label">{`${t(
                  "SinceServiceOfYear"
                )} (${t("Year")})`}</Typography>
                <Controller
                  name={`steps.${fieldIndex}.lessThanYear`}
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      select
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>
                      <MenuItem value="8">8</MenuItem>
                      <MenuItem value="9">9</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="11">11</MenuItem>
                      <MenuItem value="12">12</MenuItem>
                      <MenuItem value="13">13</MenuItem>
                      <MenuItem value="14">14</MenuItem>
                      <MenuItem value="15">15</MenuItem>
                      <MenuItem value="16">16</MenuItem>
                      <MenuItem value="17">17</MenuItem>
                      <MenuItem value="18">18</MenuItem>
                      <MenuItem value="19">19</MenuItem>
                      <MenuItem value="20">20</MenuItem>
                      <MenuItem value="21">21</MenuItem>
                      <MenuItem value="22">22</MenuItem>
                      <MenuItem value="23">23</MenuItem>
                      <MenuItem value="24">24</MenuItem>
                      <MenuItem value="25">25</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="field-label">{`${t(
                  "UntilServiceOfYear"
                )} (${t("Year")})`}</Typography>
                <Controller
                  name={`steps.${fieldIndex}.moreThanYears`}
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <TextFieldTheme
                      {...field}
                      select
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="6">6</MenuItem>
                      <MenuItem value="7">7</MenuItem>
                      <MenuItem value="8">8</MenuItem>
                      <MenuItem value="9">9</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                      <MenuItem value="11">11</MenuItem>
                      <MenuItem value="12">12</MenuItem>
                      <MenuItem value="13">13</MenuItem>
                      <MenuItem value="14">14</MenuItem>
                      <MenuItem value="15">15</MenuItem>
                      <MenuItem value="16">16</MenuItem>
                      <MenuItem value="17">17</MenuItem>
                      <MenuItem value="18">18</MenuItem>
                      <MenuItem value="19">19</MenuItem>
                      <MenuItem value="20">20</MenuItem>
                      <MenuItem value="21">21</MenuItem>
                      <MenuItem value="22">22</MenuItem>
                      <MenuItem value="23">23</MenuItem>
                      <MenuItem value="24">24</MenuItem>
                      <MenuItem value="25">25</MenuItem>
                      <MenuItem value="99">ขึ้นไป</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="field-label">
                  {t("TotalLeaveDays")}
                </Typography>
                <Controller
                  name={`steps.${fieldIndex}.leaveValue`}
                  control={useHookForm.control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      helperText={
                        useHookForm.formState.errors["steps"] &&
                        useHookForm.formState.errors["steps"][fieldIndex] &&
                        useHookForm.formState.errors["steps"][fieldIndex][
                          "leaveValue"
                        ]
                          ? useHookForm.formState.errors["steps"][fieldIndex][
                              "leaveValue"
                            ].message
                          : null
                      }
                      error={
                        useHookForm.formState.errors["steps"] &&
                        useHookForm.formState.errors["steps"][fieldIndex] &&
                        useHookForm.formState.errors["steps"][fieldIndex][
                          "leaveValue"
                        ]
                          ? true
                          : false
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="field-label">
                  {t("CannotCarryOverLeaveNextRound")}
                </Typography>
                <Controller
                  name={`steps.${fieldIndex}.carryValue`}
                  control={useHookForm.control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      helperText={
                        useHookForm.formState.errors["steps"] &&
                        useHookForm.formState.errors["steps"][fieldIndex] &&
                        useHookForm.formState.errors["steps"][fieldIndex][
                          "carryValue"
                        ]
                          ? useHookForm.formState.errors["steps"][fieldIndex][
                              "carryValue"
                            ].message
                          : null
                      }
                      error={
                        useHookForm.formState.errors["steps"] &&
                        useHookForm.formState.errors["steps"][fieldIndex] &&
                        useHookForm.formState.errors["steps"][fieldIndex][
                          "carryValue"
                        ]
                          ? true
                          : false
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <ButtonBlue
              variant="text"
              fullWidth
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => {
                useFieldArrayLeaveStep.append({
                  conditionType: "SERV",
                  lessThanYear: "",
                  moreThanYears: "",
                  leaveValue: "",
                  carryValue: "",
                });
              }}
            >
              {t("AddCondition")}
            </ButtonBlue>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

export default LeaveForm;
