import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';
import { colWidths, headerStyles } from './Xlsx-style';

export const TotalWageOTxlsx = async (year, data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('OT รายคน (บาท)')

    const months = [
        { month: "01", name: "Jan" },
        { month: "02", name: "Feb" },
        { month: "03", name: "Mar" },
        { month: "04", name: "Apr" },
        { month: "05", name: "May" },
        { month: "06", name: "Jun" },
        { month: "07", name: "Jul" },
        { month: "08", name: "Aug" },
        { month: "09", name: "Sep" },
        { month: "10", name: "Oct" },
        { month: "11", name: "Nov" },
        { month: "12", name: "Dec" }
    ];

    const headers = [
        "รหัสพนักงาน", "ชื่อ", "สกุล", "เลขบัตรประจำตัวประชาชน", "ตำแหน่ง", "บริษัท", "ส่วน", "แผนก",
        ...months.map((month) => `OT รวมบาทเดือน ${month.name}`),
        ...months.map((month) => `เฉพาะOT 1 รวมบาทเดือน ${month.name}`),
        ...months.map((month) => `เฉพาะOT 1.5 รวมบาทเดือน ${month.name}`),
        ...months.map((month) => `เฉพาะOT 2 รวมบาทเดือน ${month.name}`),
        ...months.map((month) => `เฉพาะOT 3 รวมบาทเดือน ${month.name}`)
    ];

    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (headerStyles[colNumber - 1]) {
            cell.style = headerStyles[colNumber - 1];
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        }
    });

    headerRow.height = 50;

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    data && data.filter(i => !i.isTerminate).forEach((item) => {
        const row = [
            item.employeeId ? item.employeeId : "-",
            item.firstname ? item.firstname : "-",
            item.lastname ? item.lastname : "-",
            item.personalId ? item.personalId : "-",
            item.positionsName ? item.positionsName : "-",
            item.companyName ? item.companyName : "-",
            item.departmentName ? item.departmentName : "-",
            item.sectionName ? item.sectionName : "-"
        ];

        months.forEach((month) => {
            const monthData = item.monthlyData;

            if (monthData) {
                const totalOTValue = monthData && monthData.totalOT.find((e) => e.monthPeriod === `${year}-${month.month}` && e.value > 0) || null;
                const totalOT1Value = monthData && monthData.SUM_OT1.find((e) => e.monthPeriod === `${year}-${month.month}` && e.value > 0) || null;
                const totalOT15Value = monthData && monthData.SUM_OT15.find((e) => e.monthPeriod === `${year}-${month.month}` && e.value > 0) || null;

                const totalOT2Value = monthData && monthData.SUM_OT2.find((e) => e.monthPeriod === `${year}-${month.month}` && e.value > 0) || null;
                const totalOT3Value = monthData && monthData.SUM_OT3.find((e) => e.monthPeriod === `${year}-${month.month}` && e.value > 0) || null;

                const totalOT = totalOTValue ? totalOTValue.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
                const totalOT1 = totalOT1Value ? totalOT1Value.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
                const totalOT15 = totalOT15Value ? totalOT15Value.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
                const totalOT2 = totalOT2Value ? totalOT2Value.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";
                const totalOT3 = totalOT3Value ? totalOT3Value.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00";

                const totalOTColumnIndex = headers.indexOf(`OT รวมบาทเดือน ${month.name}`);
                const totalOT1ColumnIndex = headers.indexOf(`เฉพาะOT 1 รวมบาทเดือน ${month.name}`);
                const totalOT15ColumnIndex = headers.indexOf(`เฉพาะOT 1.5 รวมบาทเดือน ${month.name}`);
                const totalOT2ColumnIndex = headers.indexOf(`เฉพาะOT 2 รวมบาทเดือน ${month.name}`);
                const totalOT3ColumnIndex = headers.indexOf(`เฉพาะOT 3 รวมบาทเดือน ${month.name}`);

                if (totalOT1ColumnIndex !== 1) {
                    row[totalOTColumnIndex] = totalOT;
                }

                if (totalOT1ColumnIndex !== 1) {
                    row[totalOT1ColumnIndex] = totalOT1;
                }

                if (totalOT15ColumnIndex !== 1) {
                    row[totalOT15ColumnIndex] = totalOT15;
                }

                if (totalOT2ColumnIndex !== 1) {
                    row[totalOT2ColumnIndex] = totalOT2;
                }

                if (totalOT3ColumnIndex !== 1) {
                    row[totalOT3ColumnIndex] = totalOT3;
                }
            }
        });

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: {
                vertical: 'middle',
                horizontal: 'center'
            }
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${data && data[0].companyName}-OT-รายคน(บาท).xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}