import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/material/styles";
import { Box, Grid, Typography, Divider } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import AlertResponse from "../../../../shared/general/AlertResponse";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogEdit from "./DialogEdit";

var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

// import { getAllGroupLevelsByIdCompany } from "../../../../../../actions/level";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapChange = styled("div")({
  marginTop: 8,
  "& .inner": {
    marginBottom: 8,
  },
  "& .first-line": {
    display: "flex",
    "& .date": {
      marginRight: "8px",
      fontWeight: 600,
    },
    "& .positions-name": {
      fontWeight: 600,
    },
  },
  "& .second-line": {
    fontSize: 16,
  },
});

function Promotion(props) {
  // const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [openEdit, setOpenEdit] = useState(false);
  const [statusAlert, setStatusAlert] = useState({
    isOpen: false,
    alertType: null
  });
  const [currentLevel, setCurrentLevel] = useState({
    groupLevelName: null,
    levelName: null
  })

  useEffect(() => {
    if(employeeProfile && employeeProfile.personnelLevel.length > 0){
      let currentLevel = null;
      employeeProfile.personnelLevel.map(level => {
        if(level.end && dayjs().isBetween(level.start, level.end, "day", "[]")){
          currentLevel = level;
        } else if(!level.end && dayjs().isSameOrBefore(level.start, "day")){
          currentLevel = level;
        }
      });
      setCurrentLevel({
        groupLevelName: currentLevel.groupLevelName,
        levelName: currentLevel.levelName
      });
    }
  },[employeeProfile]);

  const toggleDrawer = (open) => (event) => {
    console.log(open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenEdit(open);
  };

  const onHandleCloseDrawer = (isOpen) => {
    setOpenEdit(isOpen);
  };

  const handleOpenAlertResponse = (alertType) => {
    setStatusAlert({
      isOpen: true,
      alertType: alertType
    })
  };

  const handleCloseAlertResponse = () => {
    setStatusAlert({
      ...statusAlert,
      isOpen: false
    })
  };

  return (
    <div>
      <StyledWrapTop>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditIcon />}
            onClick={toggleDrawer(true)}
          >
            แก้ไข
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">กลุ่มระดับ</StyledHeadLabel>
            <Typography variant="h6">
              {currentLevel.groupLevelName
                ? currentLevel.groupLevelName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">ระดับ</StyledHeadLabel>
            <Typography variant="h6">
              {currentLevel.levelName
                ? currentLevel.levelName
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <div>
        <StyledHeadLabel color="text.secondary">
          บันทึกการเปลี่ยนแปลง
        </StyledHeadLabel>
          <StyledWrapChange>
            {employeeProfile.personnelLevel.length > 0 ?
             employeeProfile.personnelLevel.map((level, index) => (
              <div className="inner" key={index}>
                <div className="first-line">
                  <Box display="flex" alignItems="center" flexGrow="1">
                    {level.end ? (
                      <Typography className="date" color="text.third">
                        {`[${dayjs(level.start)
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "DD MMM BBBB"
                              : "DD MMM YYYY"
                          )} - ${dayjs(level.end)
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "DD MMM BBBB"
                              : "DD MMM YYYY"
                        )}]`}
                      </Typography>
                    ) : (
                      <Typography className="date" color="text.third">
                        {`[${dayjs(level.start)
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "DD MMM BBBB"
                              : "DD MMM YYYY"
                          )}${dayjs(level.start).isSameOrBefore(dayjs(), "day") ? " - ปัจจุบัน" : ""}]`}
                      </Typography>
                    )}
                    <Typography className="positions-name">
                      {`${level.groupLevelName} : ${level.levelName}`}
                    </Typography>
                  </Box>
                </div>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  style={{ fontStyle: "oblique" }}
                  gutterBottom
                >
                  {`หมายเหตุ: ${level.remark ? level.remark : "-"}`}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  align="right"
                  style={{ fontStyle: "oblique" }}
                  gutterBottom
                >
                  {`วันที่แก้ไขข้อมูล: ${
                    level.updateDate
                      ? dayjs(level.updateDate)
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "DD MMM BBBB HH:mm"
                              : "DD MMM YYYY HH:mm"
                          )
                      : "-"
                  }`}
                </Typography>
              </div>
              )) : 
              <StyledWrapChange>
                <Typography
                  align="center"
                  color="text.secondary"
                  variant="body2"
                  className="second-line"
                  style={{ fontStyle: "oblique", marginTop: 32 }}
                  gutterBottom
                >
                  ไม่มีการเปลี่ยนแปลง
                </Typography>
              </StyledWrapChange>
            }
          </StyledWrapChange>
      </div>
      {openEdit && 
        <DialogEdit 
          open={openEdit} 
          toggleDrawer={onHandleCloseDrawer} 
          handleOpenAlertResponse={handleOpenAlertResponse}
          currentLevel={currentLevel}
        />
      }
      {statusAlert.isOpen &&
        <AlertResponse 
          open={statusAlert.isOpen}
          handleClose={handleCloseAlertResponse}
          alertType={statusAlert.alertType}
        />
      }
    </div>
  );
}

export default Promotion;
