import {
  SUMMARY_TIME_COMPANY_FETCHING,
  SUMMARY_TIME_COMPANY_FAILED,
  SUMMARY_TIME_COMPANY_SUCCESS,
  SUMMARY_TIME_FETCHING,
  SUMMARY_TIME_FAILED,
  SUMMARY_TIME_SUCCESS,
} from "./types";

import SummaryTimeService from "../services/summary-time.server";


export const getSummaryTime = (search, filter) => async (dispatch) => {
  try {
    dispatch({
      type: SUMMARY_TIME_COMPANY_FETCHING,
    });
    const res = await SummaryTimeService.getSummaryTime(search, filter);
    if (res) {
      dispatch({
        type: SUMMARY_TIME_COMPANY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SUMMARY_TIME_COMPANY_FAILED,
    });
    console.log(err);
  }
};

export const getSummaryTimeByIdEmp = (search, idEmp) => async (dispatch) => {
  try {
    dispatch({
      type: SUMMARY_TIME_FETCHING,
    });
    const res = await SummaryTimeService.getSummaryTimeByIdEmp(search, idEmp);
    if (res) {
      dispatch({
        type: SUMMARY_TIME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SUMMARY_TIME_FAILED,
    });
    console.log(err);
  }
};
