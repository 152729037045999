import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

import AlertResponse from "../../../../shared/general/AlertResponse";

import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  Position,
} from "devextreme-react/data-grid";

import DialogEdit from "./dialog";

import StyledCard from "../../../../shared/general/Card";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import utils from "../../../../../../utils";
import { Box } from "@mui/material";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
    {
      backgroundColor: "#ef5350",
      color: "#FFFFFF",
      fontWeight: "500",
      "& .dx-header-filter": {
        color: "#FFFFFF",
      },
    },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const TableEmployee = (props) => {
  const { handleGetAllDetail, handleClosePayroll, handleDeletePayroll } = props;
  const { t, i18n } = useTranslation();
  const grid = useRef();
  const { result: payRunDetail } = useSelector((state) => state.payRunDetail);
  const { result: payRunDetailEmployees } = useSelector(
    (state) => state.payRunDetailEmployees
  );
  const { result: vendorProfile } = useSelector((state) => state.vendorProfile);

  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [gridColumnsAddition, setGridColumnsAddition] = React.useState([]);
  const [gridColumnsDeduction, setGridColumnsDeduction] = React.useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [anchorElBank, setAnchorElBank] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openBank = Boolean(anchorElBank);

  let gridColumnsOT = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneAmount",
      caption: `OT 1 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneFiveAmount",
      caption: `OT 1.5 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otTwoAmount",
      caption: `OT 2 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otThreeAmount",
      caption: `OT 3 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "shiftMorning",
      caption: `${t("DayShift")}`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNoon",
      caption: `${t("MidShift")}`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNight",
      caption: `${t("NightShift")}`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
  ];

  let listOfBank = [
    {
      key: "กรุงศรีอยุธยา",
      value: "025 Bank of Ayudhya Public Company Limited",
    },
    {
      key: "กสิกร",
      value: "004 Kasikorn Bank",
    },
    {
      key: "ไทยพาณิชย์",
      value: "014 Siam Commercial Bank",
    },
    {
      key: "กรุงเทพ",
      value: "002 Bangkok Bank PCL",
    },
    {
      key: "กรุงไทย",
      value: "006 Krung Thai Bank Public Company Limited",
    },
    {
      key: "ทหารไทยธนชาต",
      value: "011 TMB Bank Public Company Limited ",
    },
  ];

  useEffect(() => {
    if (payRunDetailEmployees && payRunDetailEmployees.length > 0) {
      let temp = [...payRunDetailEmployees];
      let dataList = [];
      let tempGridColumnsAddition = [];
      let tempGridColumnsDeduction = [];
      temp.forEach((element) => {
        element["fullname"] = [element.firstname, element.lastname].join(" ");

        const find = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 1
        );
        element["salaryPaid"] = find ? find.value : 0;
        element["salaryActual"] = find ? find.valueActual : 0;

        const findSSO = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 11
        );
        element["ssoEmployee"] = findSSO ? findSSO.value : 0;

        const findTax = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 12
        );
        element["tax"] = findTax ? findTax.value : 0;

        const findLateEarly = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 16
        );
        element["lateEarly"] = findLateEarly ? findLateEarly.value : null;
        element["lateEarlyMinute"] = findLateEarly
          ? findLateEarly.valueHour
          : null;

        const findAbsent = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 15
        );
        element["absent"] = findAbsent ? findAbsent.value : null;
        element["absentDay"] = findAbsent ? findAbsent.valueHour : null;

        element.payrunDetailAddition.forEach((a) => {
          tempGridColumnsAddition.push({
            dataField: `addition${a.code}`,
            caption: `${a.name} (${a.code})`,
            format: "###,###,##0.00",
            cssClass: "column-addition",
            indexAddition: a.indexAddition,
            codeAddition: a.code,
          });
          element[`addition${a.code}`] = a.value;
        });

        element.payrunDetailDeduction.forEach((a) => {
          tempGridColumnsDeduction.push({
            dataField: `deduction${a.code}`,
            caption: `${a.name} (${a.code})`,
            format: "###,###,##0.00",
            cssClass: "column-deduction",
            indexDeduction: a.indexDeduction,
            codeDeduction: a.code,
          });
          element[`deduction${a.code}`] = a.value;
        });

        const findOtOne = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 2
        );
        const findOtOneFive = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 3
        );
        const findOtTwo = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 4
        );
        const findOtThree = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 5
        );

        element["otOneHours"] = findOtOne ? findOtOne.valueHour : 0;
        element["otOneAmount"] = findOtOne ? findOtOne.value : 0;

        element["otOneFiveHours"] = findOtOneFive ? findOtOneFive.valueHour : 0;
        element["otOneFiveAmount"] = findOtOneFive ? findOtOneFive.value : 0;

        element["otTwoHours"] = findOtTwo ? findOtTwo.valueHour : 0;
        element["otTwoAmount"] = findOtTwo ? findOtTwo.value : 0;

        element["otThreeHours"] = findOtThree ? findOtThree.valueHour : 0;
        element["otThreeAmount"] = findOtThree ? findOtThree.value : 0;

        element["totalOTHours"] =
          element["otOneHours"] +
          element["otOneFiveHours"] +
          element["otTwoHours"] +
          element["otThreeHours"];

        const findCompanyPF = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 18
        );
        const findEmployeePF = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 10
        );

        element["pfCompanyPercent"] = findCompanyPF
          ? findCompanyPF.valueHour / 100
          : null;
        element["pfCompanyTHB"] = findCompanyPF ? findCompanyPF.value : null;
        element["pfEmployeePercent"] = findEmployeePF
          ? findEmployeePF.valueHour / 100
          : null;
        element["pfEmployeeTHB"] = findEmployeePF ? findEmployeePF.value : null;

        const findShiftMorning = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 7
        );
        const findShiftNoon = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 8
        );
        const findShiftNight = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 9
        );
        const findShiftSum = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 13
        );

        element["shiftMorning"] = findShiftMorning
          ? findShiftMorning.value
          : null;
        element["shiftNoon"] = findShiftNoon ? findShiftNoon.value : null;
        element["shiftNight"] = findShiftNight ? findShiftNight.value : null;
        element["shiftSum"] = findShiftSum ? findShiftSum.value : null;

        dataList.push(element);
      });

      //Addition
      let tempAdditionRecurring = tempGridColumnsAddition.filter(
        (x) => x.codeAddition.slice(0, 2) === "AR"
      );
      let tempAdditionOneTime = tempGridColumnsAddition.filter(
        (x) => x.codeAddition.slice(0, 2) === "AO"
      );

      tempAdditionRecurring.sort(function (a, b) {
        return (
          Number(a.codeAddition.match(/(\d+)/g)) -
          Number(b.codeAddition.match(/(\d+)/g))
        );
      });
      tempAdditionOneTime.sort(function (a, b) {
        return (
          Number(a.codeAddition.match(/(\d+)/g)) -
          Number(b.codeAddition.match(/(\d+)/g))
        );
      });

      tempGridColumnsAddition = [
        ...tempAdditionRecurring,
        ...tempAdditionOneTime,
      ];

      //Deduction
      let tempDeductionRecurring = tempGridColumnsDeduction.filter(
        (x) => x.codeDeduction.slice(0, 2) === "DR"
      );
      let tempDeductionOneTime = tempGridColumnsDeduction.filter(
        (x) => x.codeDeduction.slice(0, 2) === "DO"
      );

      tempDeductionRecurring.sort(function (a, b) {
        return (
          Number(a.codeDeduction.match(/(\d+)/g)) -
          Number(b.codeDeduction.match(/(\d+)/g))
        );
      });
      tempDeductionOneTime.sort(function (a, b) {
        return (
          Number(a.codeDeduction.match(/(\d+)/g)) -
          Number(b.codeDeduction.match(/(\d+)/g))
        );
      });

      tempGridColumnsDeduction = [
        ...tempDeductionRecurring,
        ...tempDeductionOneTime,
      ];

      setData(dataList);
      setGridColumnsAddition([
        ...new Map(
          tempGridColumnsAddition.map((item) => [item["dataField"], item])
        ).values(),
      ]);
      setGridColumnsDeduction([
        ...new Map(
          tempGridColumnsDeduction.map((item) => [item["dataField"], item])
        ).values(),
      ]);
    }
  }, [payRunDetailEmployees]);

  const calculateCellValue = (data) => {
    return [data.firstname, data.lastname].join(" ");
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    handleOpenDialog();
    setSelectedRowsData(selectedRowsData[0]);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const rowIndex = (cellInfo) => {
    return cellInfo.rowIndex + 1;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const onExporting = useCallback(() => {
    // let workbook = new ExcelJS.Workbook();
    // let worksheet = workbook.addWorksheet("SheetName");
    // exportDataGrid({
    //   component: grid.current.instance,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       `${payRunDetail.payrunName}.xlsx`
    //     );
    //   });
    // });
  }, []);

  const onExportingBBL = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      //console.log(payRunDetailEmployees[index]);
      console.log(dayjs(payRunDetailEmployees[index].payDate).get("day"));
      console.log(
        "Payment Date: ",
        dayjs(payRunDetailEmployees[index].payDate).get("day") === 6
          ? dayjs(payRunDetailEmployees[index].payDate)
              .subtract(1, "day")
              .format("YYYY-MM-DD")
          : dayjs(payRunDetailEmployees[index].payDate).get("day") === 0
          ? dayjs(payRunDetailEmployees[index].payDate)
              .subtract(2, "day")
              .format("YYYY-MM-DD")
          : dayjs(payRunDetailEmployees[index].payDate).format("YYYY-MM-DD")
      );
      // const bankName = listOfBank.find((x) =>
      //   payRunDetailEmployees[index].bookBank.includes(x.key)
      // );
      const element = {
        "No.": index + 1,
        "Account Number": payRunDetailEmployees[index].reportBank_BookBankId,
        "Account Name": payRunDetailEmployees[index].reportBank_Name,
        "Bank Name": payRunDetailEmployees[index].reportBank_BankName,
        "Branch Code": payRunDetailEmployees[index].reportBank_BankId,
        Amount: payRunDetailEmployees[index].net,
        Reference: payRunDetailEmployees[index].reportBank_Ref,
        "Payment Date":
          dayjs(payRunDetailEmployees[index].payDate).get("day") === 6
            ? dayjs(payRunDetailEmployees[index].payDate)
                .subtract(1, "day")
                .format("DDMMYYYY")
            : dayjs(payRunDetailEmployees[index].payDate).get("day") === 0
            ? dayjs(payRunDetailEmployees[index].payDate)
                .subtract(2, "day")
                .format("DDMMYYYY")
            : dayjs(payRunDetailEmployees[index].payDate).format("DDMMYYYY"),
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `BBL.xlsx`, { type: "file" });
  };

  const onExportingKbank = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      const element = {
        เลขที่บัญชี: payRunDetailEmployees[index].bookBankId,
        ชื่อผู้รับเงิน: `${payRunDetailEmployees[index].firstname} ${payRunDetailEmployees[index].lastname}`,
        จำนวนเงิน: payRunDetailEmployees[index].net,
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `KBank.xlsx`, { type: "file" });
  };


  const handleClickOpenMenuBank = (event) => {
    setAnchorElBank(event.currentTarget);
  };

  const handleCloseMenuBank = () => {
    setAnchorElBank(null);
  };

  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const customizeNumber = (data) => {
    return data.value ? `${utils.numberWithCommas(data.value)} บาท` : "";
  };

  const customizeHours = (data) => {
    return data.value ? `${utils.numberWithCommas(data.value)} ชม.` : "";
  };

  const onExportingPND = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      const element = {
        ลำดับที่: index + 1,
        เลขประจำตัวผู้เสียภาษี: payRunDetailEmployees[index].personalId,
        คำนำหน้า: payRunDetailEmployees[index].title,
        ชื่อ: payRunDetailEmployees[index].firstname,
        สกุล: payRunDetailEmployees[index].lastname,
        เงินได้ตามมาตรา: "401N",
        วันเดือนปีที่จ่าย: dayjs(payRunDetailEmployees[index].payDate).format(
          "DD/MM/BBBB"
        ),
        จำนวนเงินได้: payRunDetailEmployees[index].totalEarnings,
        "ภาษีหัก ณ ที่จ่าย": payRunDetailEmployees[index].tax,
        เงื่อนไข: 1,
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `PND1.xlsx`, { type: "file" });
  };

  const onExportingSSO = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      let element = null;
      const sso = payRunDetailEmployees[index].payrunDetailMain.find(
        (x) => x.idPayrollType === 11
      );

      element = {
        เลขประจำตัวประชาชน: payRunDetailEmployees[index].personalId,
        คำนำหน้าชื่อ: payRunDetailEmployees[index].title,
        ชื่อผู้ประกันตน: payRunDetailEmployees[index].firstname,
        นามสกุลผู้ประกันตน: payRunDetailEmployees[index].lastname,
        ค่าจ้าง: sso ? sso.valueActual : "",
        จำนวนเงินสมทบ: sso ? sso.value : "",
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `SSO.xlsx`, { type: "file" });
  };

  return (
    <Fragment>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        {/* <ButtonBlue
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => history.push(`/vendor/billing/create/${payRunDetail.idPayrun}`)}
        >
          {t("createBilling")}
        </ButtonBlue> */}
        {payRunDetail.isDraft === 1 ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ButtonBlue
              variant="contained"
              onClick={handleClosePayroll}
              style={{ marginRight: 8 }}
            >
              {t("closePayroll")}
            </ButtonBlue>
            <ButtonBlue
              variant="contained"
              color="error"
              startIcon={<DeleteForeverRoundedIcon />}
              onClick={handleDeletePayroll}
              style={{ marginRight: 8 }}
            >
              {t("cancelPayroll")}
            </ButtonBlue>
          </div>
        ) : (
          <Fragment>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                <ButtonBlue
                  aria-controls={openBank ? "bank-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openBank ? "true" : undefined}
                  onClick={handleClickOpenMenuBank}
                  variant="contained"
                  startIcon={<DownloadRoundedIcon />}
                  style={{ marginRight: 16 }}
                >
                  {t("exportBankFile")}
                </ButtonBlue>
                <Menu
                  id="bank-menu"
                  anchorEl={anchorElBank}
                  open={openBank}
                  onClose={handleCloseMenuBank}
                  MenuListProps={{
                    "aria-labelledby": "bank-button",
                  }}
                >
                  <MenuItem onClick={onExportingBBL}>ธนาคารกรุงเทพ</MenuItem>
                  <MenuItem onClick={onExportingKbank}>ธนาคารกสิกรไทย</MenuItem>
                </Menu>
                <ButtonBlue
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClickOpenMenu}
                  variant="outlined"
                  startIcon={<DownloadRoundedIcon />}
                >
                  {t("exportStatutoryFile")}
                </ButtonBlue>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={onExportingSSO}>
                    {t("socialSecurity")}
                  </MenuItem>
                  <MenuItem onClick={onExportingPND}>{t("PND1")}</MenuItem>
                </Menu>
              </Box>
              <ButtonBlue
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() =>
                  history.push(
                    `/vendor/billing/create/${payRunDetail.idPayrun}`
                  )
                }
              >
                {t("createBilling")}
              </ButtonBlue>
            </Box>
          </Fragment>
        )}

        {/* <ButtonBlue
          onClick={onExporting}
          variant="outlined"
          startIcon={<DownloadRoundedIcon />}
        >
          ดาวน์โหลด
        </ButtonBlue> */}
        {/* <ButtonBlue
          onClick={onExportingBBL}
          variant="outlined"
          startIcon={<DownloadRoundedIcon />}
        >
          ดาวน์โหลด BBL
        </ButtonBlue> */}
      </div>
      <StyledCard>
        <StyledCardContent>
          <StyledWrapDataGrid>
            {vendorProfile && (
              <DataGrid
                dataSource={data ? data : []}
                showBorders={false}
                showColumnLines={false}
                rowAlternationEnabled={true}
                columnAutoWidth={true}
                onSelectionChanged={onSelectionChanged}
                onExporting={onExporting}
              >
                <ColumnChooser enabled={true} mode={"select"}>
                  <Position
                    my="right top"
                    at="right bottom"
                    of=".dx-datagrid-column-chooser-button"
                  />

                  <ColumnChooserSearch
                    enabled={true}
                    editorOptions={{ placeholder: "Search column" }}
                  />

                  <ColumnChooserSelection
                    allowSelectAll={true}
                    selectByClick={true}
                  />
                </ColumnChooser>

                <Column
                  caption="No."
                  width={40}
                  cellRender={rowIndex}
                  alignment="center"
                  cssClass="column-info"
                />

                <Column
                  caption={t("EmployeeID")}
                  dataField="employeeId"
                  dataType="string"
                  width={120}
                  cssClass="column-info"
                />

                <Column
                  caption={t("FullName")}
                  dataType="string"
                  cssClass="column-info"
                  dataField="fullname"
                >
                  <HeaderFilter allowSearch={true} />
                </Column>

                <Column
                  dataField="personalId"
                  caption={t("IDCardNumber")}
                  cssClass="column-info"
                />

                <Column
                  dataField="companyName"
                  caption={t("Company")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="sectionName"
                  caption={t("DepartmentName")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="positionsName"
                  caption={t("Position")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="workingType"
                  caption="Working Type"
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="paymentTypeName"
                  caption={t("paymentType")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="paymentRound"
                  caption={t("paymentRound")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="costCenterCharge"
                  caption="Cost Center"
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="employeeStatusType"
                  caption="สถานะพนักงาน"
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="workingDay"
                  caption={t("WorkDay")}
                  dataType="string"
                  cssClass="column-info"
                  alignment="center"
                />

                <Column
                  dataField="salaryPaid"
                  caption={t("Salary")}
                  format="###,###,##0.00"
                  cssClass="column-salary"
                  minWidth={100}
                />
                <Column
                  dataField="salaryActual"
                  caption={`${t("Salary")} (${t("Absent")}/${t(
                    "LateAndEarly"
                  )})`}
                  format="###,###,##0.00"
                  cssClass="column-salary"
                  minWidth={100}
                />

                {gridColumnsOT.map((item, key) => (
                  <Column
                    dataField={item.dataField}
                    caption={item.caption}
                    format={item.format}
                    cssClass={item.cssClass}
                  />
                ))}

                <Column
                  dataField="totalOTHours"
                  caption={`${t("sumOt")} (${t("Hours")})`}
                  format="###,###,##0.00"
                  cssClass="column-ot"
                />
                <Column
                  dataField="totalOT"
                  caption={`${t("sumOt")} (บาท)`}
                  format="###,###,##0.00"
                  cssClass="column-ot"
                />

                {gridColumnsShift.map((item, key) => (
                  <Column
                    dataField={item.dataField}
                    caption={item.caption}
                    format="###,###,##0.00"
                    cssClass={item.cssClass}
                  />
                ))}

                <Column
                  dataField="shiftSum"
                  caption={`${t("sumShift")}`}
                  format="###,###,##0.00"
                  cssClass="column-shift"
                />

                {gridColumnsAddition.map((item, key) => (
                  <Column
                    dataField={item.dataField}
                    caption={item.caption}
                    format="###,###,##0.00"
                    cssClass={item.cssClass}
                  />
                ))}
                

                <Column
                  dataField="totalAdditionsTax"
                  caption={t("totalAdditionsTax")}
                  format="###,###,##0.00"
                  cssClass="column-total-earnings"
                />
                
                <Column
                  dataField="totalEarnings"
                  caption={t("totalEarnings")}
                  format="###,###,##0.00"
                  cssClass="column-total-earnings"
                />
                <Column
                  dataField="pfName"
                  caption={t("ProvidentFundName")}
                  cssClass="column-info"
                />

                <Column
                  dataField="pfCompanyPercent"
                  caption={`${t("ProvidentFundCompany")} (%)`}
                  format="###,###,##0.00%"
                  cssClass="column-info"
                />

                <Column
                  dataField="pfCompanyTHB"
                  caption={`${t("ProvidentFundCompany")} (${t("Baht")})`}
                  format="###,###,##0.00"
                  cssClass="column-info"
                />

                <Column
                  dataField="pfEmployeePercent"
                  caption={`${t("ProvidentFundPersonal")} (%)`}
                  format="###,###,##0.00%"
                  cssClass="column-info"
                />

                <Column
                  dataField="pfEmployeeTHB"
                  caption={`${t("ProvidentFundPersonal")} (${t("Baht")})`}
                  format="###,###,##0.00"
                  cssClass="column-pf"
                />

                <Column
                  dataField="ssoEmployee"
                  caption={t("socialSecurityPersonal")}
                  format="###,###,##0.00"
                  cssClass="column-sso"
                />

                <Column
                  dataField="tax"
                  caption={t("tax")}
                  format="###,###,##0.00"
                  cssClass="column-tax"
                />

                <Column
                  dataField="lateEarlyMinute"
                  caption={`${t(t("LateAndEarly"))} (${t("Minute")})`}
                  format="###,###,##0.00"
                  cssClass="column-tax"
                />

                <Column
                  dataField="lateEarly"
                  caption={`${t("LateAndEarly")} (${t("Baht")})`}
                  format="###,###,##0.00"
                  cssClass="column-tax"
                />

                <Column
                  dataField="absentDay"
                  caption={`${t("Absent")} (${t("Day")})`}
                  format="###,###,##0.00"
                  cssClass="column-tax"
                />

                <Column
                  dataField="absent"
                  caption={`${t("Absent")} (${t("Baht")})`}
                  format="###,###,##0.00"
                  cssClass="column-tax"
                />

                {gridColumnsDeduction.map((item, key) => (
                  <Column
                    dataField={item.dataField}
                    caption={item.caption}
                    format={item.format}
                    cssClass={item.cssClass}
                  />
                ))}

                <Column
                  dataField="totalDeductions"
                  caption={t("totalDeductions")}
                  format="###,###,##0.00"
                  cssClass="column-total-deduction"
                />

                <Column
                  dataField="net"
                  caption={t("net")}
                  format="###,###,##0.00"
                  cssClass="column-net"
                />

                <Column
                  dataField="bookBank"
                  caption={t("BankName")}
                  cssClass="column-info"
                />

                <Column
                  dataField="bookBankId"
                  caption={t("BookBankID")}
                  cssClass="column-info"
                />

                <Column
                  dataField="ytdIrregular"
                  caption="YTD Irregular"
                  cssClass="column-accumulate"
                  format="###,###,##0.00"
                />
                <Column
                  dataField="accumulateEarnings"
                  caption={t("CumulativeAddition")}
                  cssClass="column-accumulate"
                  format="###,###,##0.00"
                />
                <Column
                  dataField="accumulateTax"
                  caption={t("CumulativeWithholdingTax")}
                  cssClass="column-accumulate"
                  format="###,###,##0.00"
                />
                <Column
                  dataField="accumulateSSO"
                  caption={t("AccumulatedSocialSecurity")}
                  cssClass="column-accumulate"
                  format="###,###,##0.00"
                />
                <Column
                  dataField="accumulatePF"
                  caption={t("AccumulatedProvidentFund")}
                  cssClass="column-accumulate"
                  format="###,###,##0.00"
                />

                <Export enabled={true} allowExportSelectedData={true} />
                <Paging defaultPageSize={20} />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20, 30]}
                  showPageSizeSelector={true}
                  showNavigationButtons={true}
                  showInfo={true}
                />
                <Selection mode="single" />
                <Sorting mode="multiple" />
                <Scrolling columnRenderingMode="virtual" />
                <FilterRow visible={false} />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={240}
                  placeholder="Search..."
                />
                <Summary>
                  <TotalItem
                    column="salaryPaid"
                    customizeText={customizeNumber}
                    summaryType="sum"
                  />
                  <TotalItem
                    column="salaryActual"
                    customizeText={customizeNumber}
                    summaryType="sum"
                  />
                  <TotalItem
                    column="totalOTHours"
                    customizeText={customizeHours}
                    summaryType="sum"
                  />
                  <TotalItem
                    column="totalOT"
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />

                  {gridColumnsShift.map((item, key) => (
                    <TotalItem
                      column={item.dataField}
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                  ))}

                  {gridColumnsAddition.map((item, key) => (
                    <TotalItem
                      column={item.dataField}
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                  ))}

                  <TotalItem
                    column="totalEarnings"
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />

                  <TotalItem
                    column="pfCompanyTHB"
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />

                  <TotalItem
                    column="pfEmployeeTHB"
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />

                  <TotalItem
                    column="ssoEmployee"
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />
                  <TotalItem
                    column="tax"
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />

                  <TotalItem
                    column="lateEarly"
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />

                  <TotalItem
                    column="absent"
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />

                  {gridColumnsDeduction.map((item, key) => (
                    <TotalItem
                      column={item.dataField}
                      summaryType="sum"
                      customizeText={customizeNumber}
                    />
                  ))}

                  <TotalItem
                    column="totalDeductions"
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />
                  <TotalItem
                    column="net"
                    summaryType="sum"
                    customizeText={customizeNumber}
                  />
                  <TotalItem column="chargeCompensated" summaryType="sum" />
                </Summary>
              </DataGrid>
            )}

            <DialogEdit
              open={openDialog}
              handleClose={handleCloseDialog}
              selectedRowsData={selectedRowsData}
              handleChangeAlertType={handleChangeAlertType}
              handleOpenAlert={handleOpenAlert}
              handleCloseAlert={handleCloseAlert}
              handleGetAllDetail={handleGetAllDetail}
            />
          </StyledWrapDataGrid>
        </StyledCardContent>
      </StyledCard>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </Fragment>
  );
};
export default TableEmployee;
