import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Container } from "@mui/material";
import Backdrop from "../../shared/general/Backdrop";
import { useTranslation } from "react-i18next";

import MenuApp from "./MenuApp"

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapHeader = styled("div")({
  paddingTop: 20,
  paddingBottom: 60,
});

const HomePageTeamAnt = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {}, [userProfile]);

  return (
    <div>
      {
        <StyledRoot className={`page`}>
          <Backdrop open={!(currentUser && userProfile)} />
          <Container maxWidth="lg">
            {userProfile && (
              <StyledWrapHeader>
                <Typography variant="h4">
                  {t("Hello")},{`TEAM ANT`}
                </Typography>
              </StyledWrapHeader>
            )}
            <MenuApp />
          </Container>
        </StyledRoot>
      }
    </div>
  );
};

export default HomePageTeamAnt;
