// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: "เข้าสู่ระบบ",
  logout: "ออกจากระบบ",
  Hello: "สวัสดี",
  EmployeeList: "รายชื่อพนักงานทั้งหมด",
  Download: "ดาวน์โหลดข้อมูล",
  Download2: "ดาวน์โหลด",
  Upload: "อัพโหลดข้อมูล",
  Add_Employee: "เพิ่มพนักงานใหม่",
  Employees: "พนักงาน",
  Employee_Leaved: "พนักงานลาออก/พ้นสภาพ",
  Total_Person: "พนักงานทั้งหมด",
  Search: "ค้นหา",
  Name: "ชื่อ",
  FullName: "ชื่อ-สกุล",
  Company: "บริษัท",
  All_Companies: "บริษัททั้งหมด",
  No_Data: "ไม่พบข้อมูล",
  Position: "ตำแหน่ง",
  Section: "แผนก",
  Division: "ฝ่าย",
  Department: "ส่วน",
  DepartmentName: "หน่วยงาน",
  Report_To: "ผู้บังคับบัญชาลำดับที่",
  Employee_Type: "ประเภทการจ้าง",
  Edit_Data: "แก้ไขข้อมูล",
  Delete_Employee: "ลบพนักงาน",
  Success: "สำเร็จ",
  Please_Contact_Admin: "เกิดข้อผิดพลาด กรุณาติดต่อแอดมิน",
  Confirm: "ยืนยัน",
  Cancel: "ยกเลิก",
  OK: "ตกลง",
  Employee_Data: "ข้อมูลพนักงาน",
  New_Company: "บริษัทใหม่",
  Works: "ผลงาน",
  Manager: "หัวหน้างาน",
  Employee_Experience: "ประสบการณ์พนักงาน",
  Adjust_Level: "ปรับระดับ",
  Adjust_Wage: "ปรับค่าจ้าง",
  Select_File_Upload: "เลือกรายการอัพโหลด",
  Saving_Data: "กำลังบันทึกข้อมูล",
  Select_Company: "เลือกบริษัท",
  Download_Form: "ดาวน์โหลดแบบฟอร์ม",
  Click_For_Select_File: "คลิ๊กเพื่อเลือกไฟล์",
  Errors: "เกิดข้อผิดพลาด",
  Save_data_successfully: "บันทึกข้อมูลสำเร็จ",
  Homepage: "หน้าแรก",
  Overview: "มุมมองภาพรวม",
  Status_Report: "สถานะรายการ",
  History_Status_Report: "ประวัติสถานะรายการ",
  Work_Time: "เวลาทำงาน",
  Voyage: "บันทึกการเดินทาง",
  Voyage_In: "บันทึกการเดินทางไป",
  Voyage_Out: "บันทึกการเดินทางกลับ",
  Access_Area: "เข้าพื้นที่",
  Shift_Management: "การจัดการกะ",
  Payroll: "เงินเดือน",
  Payroll_Management: "จัดการเงินเดือน",
  Payslip: "สลิปเงินเดือน",
  Sourcing: "การส่งคน",
  Training: "อบรม",
  Report: "รายงาน",
  ReportCenter: "รายงาน",
  History_Company: "ประวัติบริษัท",
  Setting: "เมนูผู้ดูแลระบบ",
  ThisFieldIsRequired: "โปรดกรอกข้อมูล",
  PleaseIDCard: "โปรดกรอกเลขบัตรประชาชน 13 หลัก",
  PleasePhoneNumber: "โปรดกรอกเบอร์โทรศัพท์ 10 หลัก",
  AddEmpSuccess: "เพิ่มพนักงานเข้าระบบเรียบร้อยแล้ว",
  PleaseCompleteRequired: "โปรดกรอกข้อมูลที่จำเป็นให้ครบถ้วน",
  GeneralInfo: "ข้อมูลทั่วไป",
  EmployeeType: "ประเภทพนักงาน",
  Job_Description: "รายละเอียดงาน",
  PayrollInfo: "การจ่าย",
  Education: "การศึกษา",
  Back: "ย้อนกลับ",
  Next: "ต่อไป",
  Save: "บันทึกข้อมูล",
  EmployeeID: "รหัสพนักงาน",
  Prefix: "คำนำหน้า",
  Mr: "นาย",
  Mrs: "นาง",
  Miss: "นางสาว",
  FirstName: "ชื่อ",
  LastName: "นามสกุล",
  Eng: "อังกฤษ",
  Nickname: "ชื่อเล่น",
  Gender: "เพศ",
  Male: "ชาย",
  Female: "หญิง",
  Other: "อื่นๆ",
  BirthDate: "วันเกิด",
  IDCardNumber: "หมายเลขบัตรประชาชน",
  PhoneNumber: "เบอร์โทรศัพท์",
  Email: "อีเมล",
  Address: "ที่อยู่",
  HouseNumber: "บ้านเลขที่",
  HouseNo: "บ้านเลขที่",
  Village: "หมู่บ้าน",
  VillageNo: "หมู่ที่",
  Road: "ถนน",
  Alley: "ซอย",
  PostalCode: "รหัสไปรษณีย์",
  SubDistrict: "ตำบล/แขวง",
  District: "อำเภอ/เขต",
  SubdistrictArea: "ตำบล/แขวง",
  DistrictArea: "เขต/อำเภอ",
  Province: "จังหวัด",
  Nationality: "สัญชาติ",
  MaritalStatus: "สถานภาพ",
  Single: "โสด",
  Married: "สมรส",
  Widowed: "หม้าย",
  Divorced: "หย่า",
  Separated: "แยกกันอยู่",
  Passport: "หนังสือเดินทาง",
  WorkPermit: "ใบอนุญาตทำงาน",
  EmergencyContact: "ผู้ติดต่อฉุกเฉิน",
  EmergencyPhone: "เบอร์ผู้ติดต่อฉุกเฉิน",
  EmergencyRelationship: "ความสัมพันธ์ของผู้ติดต่อฉุกเฉิน",
  WorkType: "รูปแบบงาน",
  PaymentType: "รูปแบบการจ่าย",
  HalfMonth: "รายครึ่งเดือน",
  Monthly: "รายเดือน",
  Daily: "รายวัน",
  Payment: "รอบจ่าย",
  HiringDate: "วันที่เริ่มงาน",
  OwnerCompany: "บริษัทเจ้าของงาน",
  JobGroup: "กลุ่มงาน",
  JobLevel: "ระดับงาน",
  PersonalLevel: "ระดับพนักงาน",
  FieldOffice: "ประเภทงาน",
  WorkingType: "ประเภทเวลางาน",
  ShiftType: "ประเภทกะ",
  ShiftName: "ชื่อกะ",
  WorkingLocation: "จังหวัดที่ทำงาน",
  MainWorkingLocation: "ที่ทำงานหลัก",
  Finger: "การบันทึกเวลา",
  Yes: "ใช่",
  No: "ไม่ใช่",
  MethodAttendance: "วิธีการบันทึกเวลา",
  MainLocation: "จุดทำงานหลักที่เดียว",
  Coordinates: "ทุกสถานที่ (จับพิกัด)",
  AllLocation: "ทุกจุดทำงานที่บริษัทกำหนด",
  ContractTerminate: "วันที่สิ้นสุดงาน",
  Unspecified: "ไม่ระบุ",
  DateSpecify: "ระบุวันที่",
  AddPosition: "เพิ่มตำแหน่งงาน",
  ManagerLevel: "หัวหน้างาน ระดับที่",
  Salary: "ค่าจ้าง",
  SalaryBilling: "ค่าจ้าง(เรียกเก็บ)",
  ChargeSalary: "ค่าดำเนินการ",
  OT: "ค่าล่วงเวลา",
  ChargeHire: "เหมา",
  ChargeCompensation: "ค่าชดเชย",
  ShiftCost: "ค่ากะ",
  ChargeShiftCost: "Charge ค่ากะ",
  BookBank: "ธนาคาร",
  BookBankBranch: "สาขา",
  BookBankID: "เลขที่บัญชี",
  ReportBankName: "Report ส่งธนาคาร ชื่อธนาคาร",
  ReportBankID: "Report ส่งธนาคาร รหัสธนาคาร",
  ReportBookBankID: "Report ส่งธนาคาร เลขที่บัญชี",
  ReportBankPayneeName: "Report ส่งธนาคาร ชื่อผู้รับเงิน",
  ReportBankRef: "Report ส่งธนาคาร อ้างอิง",
  AddEducation: "เพิ่มการศึกษา",
  NoEducationalInformation: "ไม่มีข้อมูลประวัติการศึกษา",
  Delete: "ลบ",
  To: "ถึง",
  NotSpecified: "ไม่ระบุ",
  NoUniversitySpecified: "ไม่ระบุสถานศึกษา",
  GPA: "เกรดเฉลี่ย",
  StartYear: "ปีที่เริ่ม",
  Year: "ปี",
  EndYear: "ปีที่จบ",
  Degree: "ระดับการศึกษา",
  PrimarySchool: "ประถมศึกษา",
  JuniorHighSchool: "มัธยมศึกษาตอนต้น",
  SeniorHighSchool: "มัธยมศึกษาตอนปลาย",
  VocationalCertificate: "ปวช.",
  HighVocationalCertificate: "ปวส.",
  BachelorDegree: "ปริญญาตรี",
  MasterDegree: "ปริญญาโท",
  DoctorDegree: "ปริญญาเอก",
  University: "ชื่อสถาบัน",
  Faculty: "คณะ",
  Major: "สาขาวิชา",
  BusinessPartners: "บริษัทคู่ค้า",
  All: "ทั้งหมด",
  Ltd: "จำกัด",
  Person: "คน",
  ShortcutMenu: "เมนูทางลัด",
  OTAndShift: "สรุป OT และค่ากะ",
  ContractCompany: "บริษัทคู่สัญญา",
  ContractEmployee: "สัญญาจ้างเหมา",
  TimeManagement: "การจัดการเวลา",
  Personal: "ข้อมูลรายบุคคล",
  Unsigned: "ไม่มีการลงชื่อ",
  LeaveNotFound: "ไม่พบการลา",
  WorkIn: "ลงเวลาเข้างาน",
  WorkOut: "ลงเวลาออกงาน",
  SearchDate: "วันที่ค้นหา",
  LeaveWork: "ลางาน",
  AllTypes: "ทุกประเภท",
  StartDate: "วันที่เริ่มต้น",
  EndDate: "วันที่สิ้นสุด",
  TimeTable: "ตารางเวลา",
  SearchEmp: "ค้นหาพนักงาน",
  SelectEmp: "เลือกพนักงาน",
  Month: "เดือน",
  CustomerOverview: "ภาพรวมลูกค้า",
  Income: "รายรับ",
  Expense: "รายจ่าย",
  Overtime: "ข้อมูลค่าล่วงเวลา",
  WorkTimeOverview: "ภาพรวมเวลาทำงาน",
  CostOverview: "ภาพรวมค่าใช้จ่าย",
  LoadingData: "กำลังโหลดข้อมูล",
  NoData: "ไม่พบข้อมูล",
  Type: "ประเภท",
  Start: "เริ่มต้น",
  FullDay: "เต็มวัน",
  End: "สิ้นสุด",
  Reason: "หมายเหตุ",
  SalaryTotal: "รายจ่ายเงินเดือน",
  Baht: "บาท",
  OTOver36: "OT เกิน 36 ชั่วโมง",
  OTTotal: "รายจ่ายค่าล่วงเวลา",
  Yearly: "รายปี",
  ExpensesByType: "รายจ่ายแยกตามประเภท",
  leaveAbsent: "การลางาน",
  Top5LeaveAbsent: "Top 5 Leave Absent",
  Days: "วัน",
  Day: "วัน",
  to: "ถึง",
  NoAbsentWork: "ไม่มีการขาดงาน",
  EmployeeIn: "เข้าใหม่",
  EmployeeOut: "ลาออก",
  AverageAge: "Average Age",
  WorkExperienceAvg: "อายุงานเฉลี่ย",
  NewEmployeeList: "รายชื่อพนักงานเข้าใหม่",
  Customer: "ลูกค้า",
  Amount: "จำนวน",
  Difference: "ส่วนต่าง",
  TotalExpenses: "รายจ่ายสะสม",
  TotalIncome: "รายรับสะสม",
  NoCompanyList: "ไม่มีรายชื่อบริษัท",
  requestWorkingTime: "ขอรับรองเวลาทำงาน",
  OtRequest: "ขอทำงานล่วงเวลา",
  LeaveRequest: "ขอลางาน",
  WaitingApprovalWorkingTime: "รออนุมัติรับรองเวลาทำงาน",
  Items: "รายการ",
  HistoryApprovalRequestTime: "ประวัติอนุมัติรับรองเวลาทำงาน",
  RequestType: "ประเภทคำขอ",
  StartTime: "เวลาเริ่มต้น",
  EndTime: "เวลาสิ้นสุด",
  More: "เพิ่มเติม",
  ApprovalDate: "วันที่อนุมัติ",
  WaitingApprovalWorkOT: "รออนุมัติทำงานล่วงเวลา",
  HistoryApprovalOT: "ประวัติอนุมัติทำงานล่วงเวลา",
  Hrs: "ชม.",
  OtherReason: "เหตุผล (เพิ่มเติม)",
  WaitingApprovalLeaveRequest: "รออนุมัติการลา",
  HistoryApprovalLeaveRequestTime: "ประวัติอนุมัติการลา",
  EditWorkTime: "แก้ไขเวลาทำงาน",
  ShiftList: "รายการกะทั้งหมด",
  DayShift: "เช้า",
  Mid: "บ่าย",
  Night: "ดึก",
  List: "รายชื่อ",
  YearlyShiftSchedule: "ตารางกะรายปี",
  EditShiftSchedule: "แก้ไขตารางกะ",
  PleaseSelectCompany: "โปรดเลือกบริษัท",
  SwitchShiftPattern: "ย้ายรูปแบบกะ",
  SwitchShift: "สลับกะ",
  Total: "รวมทั้งหมด",
  NoEmpInThisShift: "ไม่รายชื่อพนักงานใน Shift นี้",
  ShiftPlan: "แผนการสลับกะ",
  NoHistoryOfShiftSwitch: "ไม่มีประวัติการสลับกะและย้ายรูปแบบกะ",
  CancelShiftPlan: "ยกเลิกแผนสลับกะ",
  AreYouSure: "คุณแน่ใจใช่ไหม",
  YouWantTo: "คุณต้องการ",
  YouWantDelete: "คุณต้องการลบ",
  ForTheShiftPlan: "จากแผนสลับกะ",
  ConfirmDelete: "ยืนยันลบ",
  Edit: "แก้ไข",
  FailedToEditData: "แก้ไขข้อมูลไม่สำเร็จ",
  UnableToSwitchShift: "ไม่สามารถสลับกะได้ เนื่องจากพนักงานมีการสลับกะไปแล้ว",
  SwitchWorkPattern: "ย้ายรูปแบบทำงาน",
  SwitchShiftWork: "สลับกะทำงาน",
  Temporary: "ชั่วคราว",
  SelectShift: "เลือกกะ",
  SelectAll: "เลือกทั้งหมด",
  NoEmployeeShiftSwitch: "ไม่มีการสลับกะของพนักงาน",
  RestoreDefault: "คืนค่าเริ่มต้น",
  GetStarted: "เริ่มใช้งาน",
  SummaryOFOTandShift: "สรุป OT และค่ากะ",
  EmployeeGroup: "กลุ่มพนักงาน",
  NormalEmp: "พนักงานปกติ",
  NewEmp: "พนักงานเข้าใหม่",
  ThisMonth: "This month",
  lastMonth: "Last month",
  HalfMonthlyPayment: "จ่ายรายครึ่งเดือน",
  PayrollRound: "รอบการจ่ายเงินเดือน",
  Take10Mint: "ประมวลผลประมาณ 10 นาที กรุณารอสักครู่",
  workType: "ประเภทการทำงาน",
  PaymentRound: "รอบการจ่าย",
  WorkingDay: "วันทำงาน",
  Absent: "ขาดงาน",
  LateAndEarly: "มาสาย/กลับก่อน",
  Late: "มาสาย",
  EarlyOut: "กลับก่อน",
  Minute: "นาที",
  MidMonth: "รอบกลางเดือน",
  EndOfMonth: "รอบสิ้นเดือน",
  DayShift: "ค่ากะเช้า",
  MidShift: "ค่ากะบ่าย",
  NightShift: "ค่ากะดึก",
  MonthlyPayment: "จ่ายรายเดือน",
  ActuallyPaid: "จ่ายจริง",
  ContractTerminatedDate: "วันที่หมดสัญญา",
  PayrollRun: "รายการบัญชีเงินเดือน",
  NewSalary: "ทำเงินเดือนใหม่",
  ItemName: "ชื่อรายการ",
  Draft: "แบบร่าง",
  AccountingPeriod: "ปิดรอบบัญชี",
  TotalPayroll: "ยอดสุทธิ",
  CreateNewItem: "สร้างรายการใหม่",
  Addition: "รายการเงินได้",
  Deduction: "รายการเงินหัก",
  Regular: "ประจำ",
  Once: "บางครั้ง",
  Code: "รหัส",
  TaxCalculate: "คำนวนภาษี",
  Continuously: "ต่อเนื่อง",
  Rate: "Rate",
  CodeLength46: "ความยาวรหัส 4-6 ตัวอักษร",
  CreateADDE: "สร้างรายการเพิ่ม/รายการลด",
  ItemType: "ประเภทรายการ",
  orNot: "หรือไม่",
  AmountIncreaseDecrease: "จำนวนเพิ่ม/ลด",
  SpecifyNumber: "ระบุจำนวนเงิน",
  PercentageOfSalary: "เปอร์เซ็นต์ของค่าจ้าง",
  CreatePayroll: "สร้างรายการ Payroll ใหม่",
  DailyAndMonthly: "รายวันและรายเดือน",
  PayrunType: "ประเภทรายการ",
  MonthPayrun: "เต็มเดือน",
  WeekPayrun: "ครึ่งเดือน",
  MonthPayrunAndWeekPayrun: "เต็มเดือนและครึ่งเดือน",
  FirstRound: "รอบแรก",
  SecondRound: "รอบสอง",
  SalaryRoundAndOT: "รอบค่าจ้างและค่าทำงานล่วงเวลา",
  DownloadTemplate: "ดาวน์โหลดเทมเพลต",
  Create: "สร้าง",
  Normal: "ปกติ",
  CompanyInfo: "ข้อมูลบริษัท",
  BusinessGroup: "กลุ่มธรุกิจ",
  OrganizationSize: "ขนาดองค์กร",
  Large: "ขนาดใหญ่",
  JobOpenings: "งานที่เปิดรับ",
  CompanyBranch: "สาขาของบริษัท",
  Branch: "สาขา",
  ViewAllBranches: "ดูสาขาทั้งหมด",
  Contract: "สัญญา",
  ContactInfo: "ข้อมูลติดต่อ",
  About: "เกี่ยวกับบริษัท",
  Benefits: "สิทธิประโยชน์",
  ContactName: "ชื่อผู้ติดต่อ",
  ContractName: "ชื่อสัญญา",
  SearchBranch: "ค้นหาสาขา",
  AllInfo: "ข้อมูลทั้งหมด",
  OfficialReport: "รายงานราชการ",
  CompanyName: "ชื่อบริษัท",
  CompanyAbbreviation: "ชื่อย่อบริษัท",
  AuthorizedSign: "ลายเซ็นผู้มีอำนาจของบริษัท คนที่",
  ConfirmToDelete: "ยืนยันการลบ",
  DoUWantDelete: "คุณต้องการลบใช่หรือไม่",
  WitnessSignature: "ลายเซ็นพยาน",
  Expertise: "ความเชี่ยวชาญ",
  Expenses: "ค่าใช้จ่าย",
  Service: "การบริการ",
  CompanyCode: "รหัสบริษัท",
  TaxpayerIdentificationNumber: "เลขประจำตัวผู้เสียภาษี",
  NameAuthorized: "ชื่อผู้มีอำนาจของบริษัท คนที่",
  PositionAuthorized: "ตำแหน่งผู้มีอำนาจของบริษัท คนที่",
  WitnessName: "ชื่อพยาน",
  WitnessPosition: "ตำแหน่งพยาน",
  RegisteredCapital: "ทุนจดทะเบียน",
  ProvidentFundAccountNumber: "เลขบัญชีกองทุนสำรองเลี้ยงชีพ",
  YearEstablishment: "ปีที่ก่อตั้งบริษัท",
  CompanyIncome: "รายได้บริษัท",
  LastYear: "ปีล่าสุด",
  ManpowerSize: "ขนาด Manpower",
  PleaseCompanyCode: "กรุณากรอกรหัสบริษัท",
  PleaseEnter: "กรุณากรอก",
  SocialSecurityAccount: "รหัสประกันสังคม",
  HeadOffice: "สำนักงานใหญ่",
  District: "อำเภอ",
  OtherBranch: "สาขาอื่นๆ ของบริษัท",
  AddBranch: "เพิ่มสาขา",
  BranchName: "ชื่อสาขา",
  BranchCode: "รหัสสาขา",
  EditBranch: "แก้ไขสาขา",
  PleaseEnterValidEmail: "กรุณากรอกอีเมลให้ถูกต้อง",
  EditHeadOffice: "แก้ไขสำนักงานใหญ่",
  chargeWage: "Charge ค่าแรง",
  chargeOt: "Charge ค่าล่วงเวลา",
  chargeCost: "Charge เหมา",
  chargeCompensation: "Charge ชดเชย",
  // chargeWage: "ค่าธรรมเนียมค่าแรง",
  // chargeOt: "ค่าธรรมเนียมค่าล่วงเวลา",
  // chargeCost: "ค่าธรรมเนียมเหมา",
  // chargeCompensation: "ค่าธรรมเนียมชดเชย",
  AddService: "เพิ่มสวัสดิการ",
  BankName: "ชื่อธนาคาร",
  BankAccount: "ชื่อบัญชี",
  BranchAddress: "Branch Address",
  EditBank: "แก้ไขธนาคาร",
  SelectBank: "เลือกธนาคาร",
  SystemSetting: "ข้อมูลระบบ",
  GPSLocation: "ตำแหน่ง GPS",
  HolidayCompany: "วันหยุดบริษัท",
  LeaveDay: "วันลา",
  ManageJobs: "จัดการตำแหน่งงาน",
  ManageJobLevel: "จัดการระดับงาน",
  ManageBranch: "จัดการสาขา",
  ManageSubsidiary: "จัดการบริษัทลูก",
  ProvidentFund: "กองทุนสำรองเลี้ยงชีพ",
  TaxDeductible: "หักลดหย่อนภาษี",
  Admin: "ผู้ดูแลระบบ",
  AreaPositionList: "รายการพื้นที่/ตำแหน่ง",
  AddNewArea: "เพิ่มกลุ่มพื้นที่ใหม่",
  LastUpdate: "ปรับปรุงล่าสุด",
  AreaPositionName: "ชื่อกลุ่มพื้นที่/ตำแหน่ง",
  AddLocation: "เพิ่มตำแหน่ง",
  EditLocation: "แก้ไขตำแหน่ง",
  LocationName: "ชื่อตำแหน่ง",
  Today: "วันนี้",
  NoHoliday: "ไม่มีวันหยุด",
  Calendar: "ปฎิทิน",
  AddCalendar: "เพิ่มปฏิทิน",
  UploadHoliday: "อัพโหลดรายการวันหยุด",
  EmpCantSaved: "รายชื่อพนักงานที่ไม่สามารถบันทึกได้",
  Close: "ปิด",
  PublicHolidays: "วันหยุดนักขัตฤกษ์",
  AddLeaveDay: "เพิ่มวันลา",
  PaidLeave: "การลาที่จ่ายเงิน",
  UnpaidLeave: "การลาที่ไม่จ่ายเงิน",
  AfterProbation: "หลังผ่านช่วงโปร",
  StartWork: "เริ่มงานวันแรก",
  HalfDay: "ครึ่งวัน",
  AllGender: "ทุกเพศ",
  LeaveDayName: "ชื่อรายการวันลา",
  LeaveType: "ประเภทการลา",
  RequestLeaveAfterDate: "ร้องขอวันลาได้หลังวันที่",
  LeaveRights: "สิทธิการลา",
  Fixed: "คงที่",
  Step: "ขั้นบันได",
  AddCondition: "เพิ่มเงื่อนไข",
  Condition: "เงื่อนไข",
  Experience: "อายุงาน",
  MoreThan: "มากกว่า",
  NumberLeaveDays: "จำนวนวันลา",
  AccumulateNotMoreThan: "สะสมวันลาได้ไม่เกิน",
  MinimumLeave: "อนุญาตให้ลาขั้นต่ำ",
  ApprovalProcess: "ขั้นตอนการอนุมัติ",
  None: "None",
  Notification: "แจ้งเตือน",
  Approval: "ขออนุมัติ",
  MinLeave: "ลาขั้นต่ำ",
  AddJobPosition: "เพิ่ม Job Position",
  NewPosition: "ตำแหน่งใหม่",
  Add: "เพิ่ม",
  EditLevel: "แก้ไขลำดับ",
  AddJobLevel: "เพิ่ม Job Level",
  NoAdditionalItem: "ไม่มีรายการเพิ่มเติม",
  InsertJobLVName: "ระบุข้อมูลชื่อระดับงาน",
  InsertMinSalary: "ระบุเงินเดือนขั้นต่ำ",
  InsertMaxSalary: "ระบุเงินเดือนสูงสุด",
  ExtraCost: "ค่าใช้จ่ายเพิ่มเติม",
  Approve: "อนุมัติ",
  Examine: "ตรวจสอบ",
  ManagerList: "รายชื่อหัวหน้างาน",
  OperatingPower: "อำนาจดำเนินการ",
  EditOperatingPower: "แก้ไขอำนาจดำเนินการ",
  ManagePayroll: "จัดการ Payroll",
  OvertimeHolidayPay: "ค่าทำงานล่วงเวลาและค่าทำงานในวันหยุด",
  OvertimeHolidayPayBilling: "ค่าทำงานล่วงเวลาและค่าทำงานในวันหยุด(วางบิล)",
  OvertimePayOnNormalWorkingDays: "ค่าล่วงเวลาในวันทำงานปกติ",
  Times: "เท่า",
  HolidayOvertimePay: "ค่าล่วงเวลาในวันหยุด",
  HolidayWorkAllowance: "ค่าทำงานในวันหยุด",
  ShiftFee: "ค่ากะ",
  PayrollSetting: "ตั้งค่า Payroll",
  DecimalRounding: "การปัดเศษ",
  NotRounded: "ไม่ปัดเศษ",
  Round: "ปัดเศษ",
  RoundUp: "ปัดเศษขึ้น",
  RoundDown: "ปัดเศษลง",
  Decimal: "ทศนิยม",
  PaymentPeriod: "งวดการจ่ายเงิน",
  OnceAMonth: "เดือนละครั้ง",
  TwoTimesAMonth: "เดือนละ 2 ครั้ง",
  FirstCutOff: "ตัดรอบทุกวันที่",
  FirstPayDate: "จ่ายเงินทุกวันที่",
  FirstPaySlipDate: "วันที่ออก Payslip",
  FirstPaySlipTime: "เวลาออก Payslip",
  Calculate: "​คำนวน",
  NotCalculate: "ไม่​คำนวน",
  SecondCutOff: "ตัดรอบทุกวันที่ (ของเดือนถัดไป)",
  SecondPayDate: "จ่ายเงินทุกวันที่ (ของเดือนถัดไป)",
  SecondPayslipDate: "วันที่ออก Payslip (ของเดือนถัดไป)",
  SecondPayslipTime: "เวลาออก Payslip",
  DailyWeekWorking: "รายวัน(จ่ายรายครึ่งเดือน)",
  PresentRound: "รอบปัจจุบัน",
  PreviousRound: "ย้อน 1 รอบ",
  DailyWeekOT: "ค่าทำงานล่วงเวลา",
  DailyWeekIsWorkingOmit: "เวลาทำงานตกหล่น",
  NotCountingBackwards: "ไม่คำนวนย้อนรอบ",
  Back1Round: "ย้อน 1 รอบ",
  DailyWeekIsWorkingOTOmit: "ค่าทำงานล่วงเวลาตกหล่น",
  MonthlyWeekWorking: "รายเดือน(จ่ายรายครึ่งเดือน)",
  MonthlyWeekOT: "ค่าทำงานล่วงเวลา",
  MonthlyWeekIsWorkingOmit: "เวลาทำงานตกหล่น",
  MonthlyWeekIsWorkingOTOmit: "ค่าทำงานล่วงเวลาตกหล่น",
  DailyMonthOT: "ค่าทำงานล่วงเวลา",
  DailyMonthIsWorkingOmit: "เวลาทำงานตกหล่น",
  DailyMonthIsOTOmit: "ค่าทำงานล่วงเวลาตกหล่น",
  MonthlyMonthOT: "ค่าทำงานล่วงเวลา",
  MonthlyMonthWorkingOmit: "เวลาทำงานตกหล่น",
  MonthlyMonthOtOmit: "ค่าทำงานล่วงเวลาตกหล่น",
  AddPF: "เพิ่มกองทุนสำรองเลี้ยงชีพ",
  MinYearOfWork: "ปีขั้นต่ำในการทำงาน",
  MaxEmployeePF: "สะสมส่วนพนักงานสูงสุด",
  MinEmployeePF: "สะสมส่วนพนักงานขั้นต่ำ",
  MaxVendorPF: "สะสมส่วนบริษัทสูงสุด",
  DateCanChange: "วันที่สามารถเปลี่ยนแปลง",
  AddChangeDate: "เพิ่มวันเปลี่ยนแปลง",
  DueDate: "วันที่กำหนดใช้",
  RoundOf: "รอบที่",
  UnderSchedule: "อยู่ระหว่างกำหนดการ",
  NoChangeDate: "ไม่มีการกำหนดวันที่เปลี่ยนแปลง",
  EditPF: "แก้ไขกองทุนสำรองเลี้ยงชีพ",
  NotLessThan0Year: "จำนวนปีไม่น้อยกว่า 0 ปี",
  PleaseFillNotLessThan: "กรุณากรอกจำนวนไม่น้อยกว่า",
  EditChangeSchedule: "แก้ไขตารางเวลาเปลี่ยนแปลง",
  PleaseEnterCorrectDate: "กรุณากรอกวันที่ให้ถูกต้อง",
  CantSelectStartDate: "ไม่สามารถเลือกวันก่อนเริ่มต้นได้",
  CantSelectEndDateBeforeStartDate: "ไม่สามารถเลือกวันที่สิ้นสุดก่อนวันที่เริ่มต้นได้",
  EnterValidDate: "กรุณากรอกวันที่กำหนดใช้",
  PastDateCantSelect: "ไม่สามารถเลือกวันที่ผ่านมาแล้วได้",
  EditStartDate: "วันที่เริ่มการแก้ไข",
  EditEndDate: "วันที่สิ้นสุดการแก้ไข",
  EditTaxDeductible: "แก้ไขวันเวลาหักลดหย่อนภาษี",
  BasicInformation: "ข้อมูลพื้นฐาน",
  ChangeInformation: "เปลี่ยนแปลงข้อมูล",
  PersonalInfo: "ข้อมูลส่วนตัว",
  JobInfo: "ข้อมูลงาน",
  PaymentInfo: "ข้อมูลการจ่าย",
  ExperienceInfo: "ประสบการณ์ทำงาน",
  Age: "อายุ",
  EditEmpProfile: "แก้ไขข้อมูลประวัติพนักงาน",
  Transfer: "โอน",
  Move: "ย้าย",
  Employment: "การจ้างงาน",
  Probation: "ทดลองงาน",
  Promotion: "ปรับระดับ",
  PaymentChange: "ปรับค่าจ้าง",
  Terminated: "Terminated",
  ResetPass: "รีเซ็ตรหัสผ่าน",
  ChangePass: "เปลี่ยนรหัสผ่าน",
  CurrentAddress: "ที่อยู่ปัจจุบัน",
  CriminalHistory: "ประวัติอาชญากรรม",
  Thai: "ไทย",
  EditCurrentAddress: "แก้ไขที่อยู่ปัจจุบัน",
  EditEmergencyContact: "แก้ไขผู้ติดต่อฉุกเฉิน",
  EditBasicInfo: "แก้ไขข้อมูลส่วนตัว",
  EditCriminalHistory: "แก้ไขประวัติอาชญากรรม",
  RenewalDate: "วันต่อสัญญา",
  EmploymentTime: "รูปแบบการจ้าง",
  AllMethod: "ทุกจุด",
  NoNumber: "ลำดับที่",
  NoManageHasBeenSpecify: "ยังไม่ได้ระบุหัวหน้างานลำดับที่",
  AddManager: "เพิ่มหัวหน้างาน",
  PositionManager: "ตำแหน่งหัวหน้างาน",
  InvalidEmail: "อีเมลไม่ถูกต้อง",
  EditManagerInfo: "แก้ไขข้อมูลหัวหน้างาน",
  EditJobInfo: "แก้ไขข้อมูลงาน",
  WorkingTimeInfo: "ข้อมูลเวลาทำงาน",
  WorkDay: "จำนวนวันทำงาน",
  WorkTimePerDay: "เวลาทำงานต่อวัน",
  WorkTimeInTimeOut: "เวลาเข้า-ออกงาน",
  NoWorkingTimeFrame: "ไม่มีกรอบเวลาทำงาน",
  OperationFee: "ค่าดำเนินการ",
  ChargeHireFee: "ค่าดำเนินการเหมา",
  PaymentTypeName: "ประเภทค่าจ้าง",
  Per: "ต่อ",
  BankInfo: "ข้อมูลธนาคาร",
  BankFiles: "สำหรับออกไฟล์ธนาคาร",
  Percent: "เปอร์เซ็นต์",
  EditBankInfo: "แก้ไขข้อมูลธนาคาร",
  EditCostCenter: "แก้ไขศูนย์ต้นทุน",
  EditOperation: "แก้ไขค่าดำเนินการ",
  ChangingCumulativeSuccess: "เปลี่ยนแปลงอัตราสะสมสำเร็จ",
  FailedChangeCumulative: "เปลี่ยนแปลงอัตราสะสมไม่สำเร็จ",
  RemoveSuccessfulBeneficiary: "ลบผู้ได้รับผลประโยชน์สำเร็จ",
  FailedRemoveBeneficiary: "ลบผู้ได้รับผลประโยชน์ไม่สำเร็จ",
  DateRetirementFund: "วันที่นับอายุงานในกองทุน",
  FundEntryDate: "วันที่เข้ากองทุน",
  CumulativeRate: "อัตราสะสม",
  ContributionCompany: "สมทบส่วนบริษัท",
  CurrentCumulativeRate: "อัตตราสะสมปัจจุบัน",
  NewCumulativeRate: "อัตตราสะสมใหม่",
  PleaseSelectNewAccumulatedRate: "กรุณาเลือกอัตตราสะสมใหม่",
  EditableRange: "ช่วงที่สามารถแก้ไข",
  Beneficiary: "ผู้รับผลประโยชน์",
  AddBeneficiary: "เพิ่มผู้รับผลประโยชน์",
  NoBeneficiaryList: "ไม่มีรายชื่อผู้รับผลประโยชน์",
  SuccessfulBeneficiary: "ผู้ได้รับผลประโยชน์สำเร็จ",
  UnsuccessfulBeneficiary: "ผู้ได้รับผลประโยชน์ไม่สำเร็จ",
  EditBeneficiary: "แก้ไขผู้ได้รับผลประโยชน์",
  Relationship: "ความสัมพันธ์",
  Percentage: "อัตราร้อยละ",
  Number: "ตัวเลข",
  PleaseCorrectAmount: "กรุณากรอกจำนวนให้ถูกต้อง",
  ConfirmAccumulativeRateChange: "ยืนยันการเปลี่ยนอัตราสะสม",
  SelectStartDate: "เลือกวันที่เริ่มใช้",
  DateAt: "วันที่",
  EditProvidentFund: "แก้ไขวันที่กองทุนสำรองเลี้ยงชีพ",
  EducationHistory: "ประวัติการศึกษา",
  EditHistory: "ประวัติแก้ไข",
  AddQualification: "เพิ่มวุฒิการศึกษา",
  NoEducationInfo: "ไม่มีข้อมูลประวัติการศึกษา",
  NoWorkExperienceInfo: "ไม่มีข้อมูลประสบการณ์ทำงาน",
  UnderPrimarySchool: "ต่ำกว่าประถมศึกษา",
  CurrentWorkLocation: "ที่ทำงานปัจจุบัน",
  PositionName: "ชื่อตำแหน่งงาน",
  SummaryWorkPast5Years: "ประเมินผลงานย้อนหลัง 5 ปี",
  LatestPerformance: "ผลประเมินปีล่าสุด",
  Hide: "ซ่อน",
  AllWorks: "ผลงานทั้งหมด",
  Level: "ระดับ",
  EditWorks: "แก้ไขประเมินผลงาน",
  CumulativeRights: "สิทธิสะสม",
  Used: "ใช้ไป",
  Remaining: "คงเหลือ",
  Current: "ปัจจุบัน",
  EffectiveDate: "วันที่มีผล",
  Remark: "เหตุผล",
  ChangeLog: "บันทึกการเปลี่ยนแปลง",
  ProbationResult: "ผลทดลองงาน",
  PassProbation: "ผ่านทดลองงาน",
  NotPassProbation: "ไม่ผ่านทดลองงาน",
  ResultNotYetBeenConcluded: "ยังไม่สรุปผลทดลองงาน",
  NewLevel: "ระดับใหม่",
  SelectLevel: "เลือกระดับ",
  Pay: "จ่าย",
  CurrentSalary: "เงินเดือนปัจจุบัน",
  Payments: "การจ่ายเงิน",
  TerminatedDate: "วันที่สิ้นสุดสัญญา",
  TerminatedInfo: "ข้อมูลลาออก",
  OutOfContract: "หมดสัญญา",
  GetNewJob: "ได้งานใหม่",
  PersonalBusiness: "ทำธรุกิจส่วนตัว",
  PersonalReasons: "เหตุผลส่วนตัว",
  LayOff: "ให้ออก",
  New: "ใหม่",
  Select: "เลือก",
  EmployeeHistory: "ประวัติพนักงาน",
  CompanyProfile: "ประวัติบริษัท",
  PersonalProfile: "ประวัติส่วนตัว",
  Timeline: "ตารางเวลาทำงาน",
  RequestList: "สถานะรายการ",
  SummaryTime: "สรุป OT และค่ากะ",
  PaySlip: "สลิปเงินเดือน",
  MyDocument: "เอกสารของฉัน",
  WorkTimeRequest: "คำขอรับรองเวลาทำงาน",
  OvertimeRequest: "คำขอทำงานล่วงเวลา",
  EmpLeaveRequest: "คำขอลางาน",
  Date: "วันที่",
  ShiftTable: "ตารางกะ",
  ShiftTime: "เวลาทำงาน",
  ClockIn: "เวลาเข้า",
  ClockOut: "เวลาออก",
  Status: "สถานะ",
  TimeNotFound: "ไม่มีเวลาทำงาน",
  Holiday: "วันหยุดประจำปี",
  CompensateDate: "วันหยุดชดเชยประเภทกะ",
  Lists: "รายการ",
  AddTimeline: "เพิ่มรายการ",
  Add_Data: "เพิ่มรายการ",
  WaitingApproval: "รออนุมัติ",
  Disapproval: "ไม่อนุมัติ",
  WorkingTime: "เวลาทำงาน",
  Approver: "ผู้อนุมัติ",
  Description: "รายละเอียด",
  CreateDate: "สร้างเมื่อ",
  OperationalRequirement: "ทำต่อเนื่องเร่งด่วน",
  ShiftWorkOnDayOff: "ทำงานกะในวันหยุด",
  OTLunchBreakRequest: "ทำงานเร่งด่วน ช่วงพักเที่ยง",
  OtherCompanyActivities: "กิจกรรมอิ่นๆ ของบริษัท",
  OffSiteWork: "ปฏิบัติงานภายนอก",
  ClockingMachineError: "เครื่องสแกนนิ้วขัดข้อง",
  ProblemFingerprint: "ลายนิ้วมือมีปัญหา",
  EmergencyCall: "เรียกตัวฉุกเฉิน",
  OtherRT: "อื่นๆ",
  AdditionalReasons: "เหตุผลเพิ่มเติม",
  TotalHours: "จำนวนชั่วโมง",
  CreateLeave: "ทำรายการลา",
  AllLeave: "สิทธิการลาทั้งหมด",
  LeaveCalendar: "ปฏิทินการลางาน",
  LeaveHistory: "ประวัติการลางาน",
  NoLeaveHistory: "ไม่มีประวัติการลางาน",
  ConfirmLeave: "ยืนยันทำรายการลา",
  RemainingLeaveRights: "สิทธิการลาคงเหลือ",
  SpecifyTime: "ระบุเวลา",
  AttachFile: "แนบไฟล์",
  StatusRequest: "รูปแบบสถานะต่างๆ",
  CancelRequest: "ถอนรายการ",
  WaitingForFirstApprover: "รอผู้อนุมัติลำดับที่ 1 ตรวจสอบ",
  Request: "รายการ",
  ApprovedBy: "อนุมัติโดย",
  ReasonForDisapproval: "เหตุผลที่ไม่อนุมัติ",
  WorkTimeSummary: "สรุปเวลาทำงาน",
  Hours: "ชั่วโมง",
  SummaryDeduction: "สรุปรายการหักเงิน",
  PayDate: "วันที่จ่าย",
  TotalAddition: "รวมเงินได้",
  TotalDeduction: "รวมเงินหัก",
  Net: "จ่ายสุทธิ",
  CumulativeAddition: "รายได้สะสม",
  CumulativeWithholdingTax: "ภาษีหัก ณ ที่จ่ายสะสม",
  AccumulatedSocialSecurity: "ประกันสังคมสะสม",
  AccumulatedProvidentFund: "กองทุนสำรองเลี้ยงชีพสะสม",
  IncompleteInfo: "ข้อมูลไม่ครบ",
  CertificateSalary: "หนังสือรับรองเงินเดือน",
  CertificateEmp: "หนังสือรับรองการทำงาน",
  Tawi50: "50 ทวิ",
  SelectYear: "เลือกปี",
  PND91: "ภ.ง.ด.91",
  EmployeeInTeam: "พนักงานในทีม",
  ApprovalList: "รายการรออนุมัติ",
  ApprovalHistory: "ประวัติการอนุมัติ",
  OvertimeTable: "ทำงานล่วงเวลา",
  ConfirmItemDisapproval: "ยืนยันการไม่อนุมัติรายการ",
  NotRequired: "ไม่จำเป็นต้องกรอก",
  Incorrect: "ไม่ถูกต้อง",
  DateTimeIncorrect: "วันที่หรือเวลาไม่ถูกต้อง",
  OvertimeRequestedCantWorkingHours:
    "ไม่สามารถขอทำงานล่วงเวลาในเวลาทำงานปกติได้",
  WorkingDate: "วันที่ทำงาน",
  Open: "เปิด",
  Filter: "ตัวกรอง",
  AllDepartment: "หน่วยงานทั้งหมด",
  Vendor: "บริษัทสังกัด",
  AllVendor: "บริษัทสังกัดทั้งหมด",
  AllJobGroup: "กลุ่มงานทั้งหมด",
  AllLevel: "ทุกระดับ",
  AdditionalInformation: "ข้อมูลเพิ่มเติม",
  ContractEmployeeList: "รายการสัญญาจ้างเหมา",
  SearchContractEmployee: "ค้นหาชื่อสัญญา/Project",
  AddContractEmployee: "เพิ่มสัญญา/Project",
  AddEditEmployee: "เพิ่ม/ลบพนักงาน",
  ChangeEmployee: "สลับเปลี่ยนพนักงาน",
  areYouSure: "คุณแน่ใจใช่ไหม?",
  pressBtn: "โปรดกดปุ่มค้นหา",
  round1: "รอบที่ 1",
  round2: "รอบที่ 2",
  startEndDate: "เริ่มต้น - สิ้นสุด",
  employeeMinimumPerDays: "จำนวนพนักงานขั้นต่ำ (ต่อวัน)",
  costCharge: "วงเงินเรียกเก็บ (ต่อเดือน)",
  searchName: "ค้นหาชื่อ",
  searchFirstLast: "ค้นหาชื่อ-สกุล",
  permanent: "พนักงานประจำ",
  reserve: "สำรอง",
  positionDepartment: "ตำแหน่ง/หน่วยงาน",
  searchMonth: "ค้นหาเดือน",
  employeeContract: "สัญญาจ้าง",
  addEmployeeContract: "เพิ่มสัญญาจ้าง",
  summarizeAbsent: "สรุปการลาและขาดงาน",
  summarizeProbation: "สรุปทดลองงาน",
  summarizeTerminateContract: "สรุปหมดสัญญา",
  summarizeNewEmployee: "สรุปพนักงานเข้าใหม่",
  reportResign: "รายงานคนลาออก",
  employeeCoreDate: "ข้อมูลพนักงาน",
  reportContractCompany: "รายงานกำลังพลแบบเหมาสัญญา",
  reportWorkingTime: "รายงานเวลาทำงาน",
  ReportAbsent: "รายงานขาดงาน",
  ReportLate: "รายงานมาสาย",
  ReportLeave: "รายงานการลา",
  ReportAttendance: "รายงานการลงเวลา",
  ReportRequest: "รายงานการร้องขอ",
  ReportOT: "รายงานค่าล่วงเวลา/ค่ากะ",
  ReportRaise: "รายงานปรับเงินเดือน",
  ManualTimesheet: "กรอกเวลาทำงาน",
  checkIn: "ลงเวลาเข้า",
  checkOut: "ลงเวลาออก",
  manualTable: "ตารางลงเวลาทำงาน",
  attendanceDaily: "ลงเวลารายวัน",
  attendanceWeekly: "ลงเวลารายสัปดาห์",
  attendanceMonthly: "ลงเวลารายเดือน",
  monthWork: "เดือนที่ทำงาน",
  fillInData: "โปรดระบุข้อมูล",
  confirmChangePassword: "ยืนยันเปลี่ยนรหัสผ่านใหม่",
  confirmNewPassword: "ยืนยันรหัสผ่านใหม่",
  currentPassword: "รหัสผ่านปัจจุบัน",
  rolePassword1: "รหัสผ่านต้องมีจำนวน 8-16 ตัวอักษร",
  rolePassword:
    "ต้องมีตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัว หรือ ตัวเลขอย่างน้อย 1 ตัว",
  paymentType: "ประเภทการจ่าย",
  paymentRound: "รอบการจ่าย",
  sumOt: "รวมค่าล่วงเวลา",
  sumShift: "รวมค่ากะ",
  totalEarnings: "รวมรายรับ",
  totalDeductions: "เงินหักทั้งหมด",
  ProvidentFundName: "กองทุนสำรองเลี้ยงชีพ (ชื่อกองทุน)",
  ProvidentFundCompany: "กองทุนสำรองเลี้ยงชีพส่วนบริษัท",
  ProvidentFundPersonal: "กองทุนสำรองเลี้ยงชีพส่วนพนักงาน",
  socialSecurityPersonal: "ประกันสังคม",
  socialSecurity: "ประกันสังคม",
  tax: "ภาษีหัก ณ ที่จ่าย",
  net: "รายได้สุทธิ",
  cancelPayroll: "ยกเลิกทั้งหมด",
  closePayroll: "ปิดรอบบัญชี",
  exportBankFile: "ไฟล์ธนาคาร",
  exportStatutoryFile: "ไฟล์ต่างๆ",
  socialSecurity: "ประกันสังคม",
  PND1: "ยิื่นภาษี ภ.ง.ด.1",
  performance: "ประเมินผลงาน",
  Time: "เวลา",
  Weekly: "รายสัปดาห์",
  Per: "ต่อ",
  createBilling: "สร้าง Billing",
  AccessArea: "บันทึกการเดินทาง",
  AccessTime: "บันทึกการทำงาน",
  Expense: "เบิกค่าใช้จ่าย",
  GeneralInformation: "ข้อมูลทั่วไป",
  Title: "ชื่อรายการ",
  Reviewer: "ผู้ตรวจสอบ",
  ExpenseLocation: "สถานที่เกิดค่าใช้จ่าย",
  Local: "ในประเทศ",
  International: "ต่างประเทศ",
  VatType: "ประเภทราคา",
  Currency: "สกุลเงิน",
  CurrencyRate: "อัตราแลกเปลี่ยน",
  ExpenseList: "รายการ",
  Unit: "หน่วย",
  SummaryList: "สรุปรายการ",
  ExpenseNet: "มูลค่าสุทธิ",
  TotalValue: "มูลค่ารวม",
  TotalVat: "มูลค่าภาษีรวม",
  TotalWithholding: "หัก ณ ที่จ่ายรวม",
  RemarkMore: "หมายเหตุ(เพิ่มเติม)",
  SelectApprover: "เลือกผู้อนุมัติ",
  SelectReviewer: "เลือกผู้ตรวจสอบ",
  RightsHolder: "ผู้ใช้สิทธิ",
  LocationList: "รายการสถานที่",
  RowPerPage: "จำนวนแถวต่อหน้า",
  TotalDistance: "ระยะทางรวม",
  NetDistance: "ระยะทางสุทธิ",
  Km: "กม",
  PersonalUseage: "ใช้ส่วนตัว",
  WorkingUseage: "ระยะทางที่ใช้ในการทำงาน",
  TotalDays: "สรุปจำนวนวันเดินทาง",
  AllowancePerDay: "เบี้ยเลี้ยง/วัน",
  GorvermentAllowanceRate: "เบี้ยเลี้ยงตามอัตราราชการ",
  SurplusAllowance: "เบี้ยเลี้ยงส่วนเกินอัตราราชการ(จะถูกนำคิดภาษีเงินได้)",
  NetAllowance: "เบี้ยเลี้ยงสุทธิรวม",
  RightsType: "ประเภทสิทธิ",
  DocumentDate: "วันที่เอกสาร",
  Location: "สถานที่",
  SentForApproval: "ส่งอนุมัติ",
  SaveDraft: "บันทึกแบบร่าง",
  ClearData: "ล้างข้อมูล",
  ReplyComment: "ตอบกลับความคิดเห็น",
  DocumentId: "เลขที่เอกสาร",
  Withdrawal: "เบิกได้รวม",
  HelpingSurplus: "ช่วยเหลือส่วนเกิน",
  File: "ไฟล์เอกสาร",
  NoFile: "ไม่มีไฟล์แนบ",
  lastUpdateDate: "วันที่ทำรายการ",
  Details: "รายละเอียด",
  ExpenseRequest: "ขออนุมัติเบิกค่าใช้จ่าย",
  ApprovalHistory: "ประวัติรายการอนุมัติ",
  ServiceAndGoods: "สินค้าและบริการ",
  Allowance: "เบี้ยเลี้ยง",
  ExpenseType: "ประเภทค่าใช้จ่าย",
  Approved: "อนุมัติ",
  Rejected: "ไม่อนุมัติ",
  SelectExpenseType: "",
  ActionHistory: "ประวัติการทำรายการ",
  ActionDate: "วันเวลาที่ทำรายการ",
  ActionBy: "ผู้ทำรายการ",
  Comment: "ความคิดเห็น",
  ExpenseReview: "ตรวจสอบค่าใช้จ่าย",
  ExpenseExport: "รายงานการเบิกค่าใช้จ่าย",
  ExpenseHistory: "ประวัติการเบิกค่าใช้จ่าย",
  ExpenseCompleted: "เสร็จสมบูรณ์",
  ExpenseRejected: "ยกเลิก",
  Waiting: "รออนุมัติ",
  Processing: "อยู่ระหว่างดำเนินการ",
  Holding: "รอการแก้ไข",
  WaitingForAdmin: "รอการตรวจสอบสิทธิ",
  WaitingForReview: "รอการตรวจสอบ",
  SelectStatusType: "เลือกสถานะ",
  Reject: "ไม่อนุมัติ",
  ApprovedPay: "อนุมัติการตรวจรับ",
  DirectPayment: "จ่ายคืนโดยตรง",
  PayrollAdditionPayment: "จ่ายคืนพร้อมเงินเดือน",
  DeleteDraft: "ลบแบบร่าง",
  DeleteDraftFormDialogTitle: "ต้องการลบแบบร่างนี้หรือไม่",
  DraftFormDialogTitle: "ต้องการบันทึกแบบร่างนี้หรือไม่",
  SentFormDialogTitle: "ต้องการส่งอนุมัติรายการนี้หรือไม่",
  DeleteDraftFormDialogDes: "ข้อมูลทั้งหมดจะถูกลบ",
  DraftFormDialogDes: "ข้อมูลทั้งหมดจะถูกบันทึกเป็นแบบร่าง",
  SentFormDialogDes: "โปรดตรวจสอบข้อมูลทั้งหมดก่อนส่งอนุมัติ",
  ClearFormDialogTitle: "ต้องการล้างข้อมูลทั้งหมดหรือไม่",
  ClearFormDialogDes: "ข้อมูลทั้งหมดจะถูกล้างเป็นค่าเริ่มต้น",
  RejectFormDialogTitle: "ต้องการยกเลิกรายการนี้หรือไม่",
  ApproveFormDialogTitle: "ต้องการอนุมัติการเบิกรายการนี้หรือไม่",
  RejectFormDialogDes: "รายการนี้จะถูกยกเลิก",
  ApproveFormDialogDes: "รายการนี้จะถูกอนุมัติและส่งให้ฝ่ายบัญชี",
  HoldFormDialogTitle: "ต้องการขอให้แก้ไขรายการนี้หรือไม่",
  AccApproveFormDialogTitle: "ต้องการอนุมัติการเบิกรายการนี้หรือไม่",
  HoldFormDialogDes: "รายการนี้จะถูกส่งกลับไปให้ผู้ขอเบิกแก้ไขรายการนี้",
  AccApproveFormDialogDes: "รายการนี้จะถูกอนุมัติเพื่อรอจ่ายคืน",
  AddExpense: "เพิ่มรายการ",
  Vat: "ภาษี",
  PriceBeforeVat: "มูลค่าก่อนภาษี",
  Withholding: "หัก ณ ที่จ่าย",
  UnitPrice: "ราคาต่อหน่วย",
  GoodsServices: "สินค้า/บริการ",
  StartLocation: "สถานที่เริ่มต้น",
  StopLocation: "สถานที่สิ้นสุด",
  StartMile: "เลขไมล์เริ่มต้น",
  StopMile: "เลขไมล์สิ้นสุด",
  // StartDate: "วันที่-เวลาเริ่มต้น",
  // EndDate: "วันที่-เวลาสิ้นสุด",
  DateAmount: "จำนวนวัน",
  CancleFormDialogTitle: "ต้องการยกเลิกรายการขออนุมัติรายการนี้ใช่หรือไม่",
  CancleFormDialogDes: "รายการนี้จะถูกยกเลิกและบันทึกกลับไปเป็นแบบร่าง",
  Completed: "เสร็จสมบูรณ์",
  UploadHistory: "อัพโหลดเพื่อเก็บข้อมูลย้อนหลัง",
  UploadEdit: "อัพโหลดเพื่อแก้ไขข้อมูล",
  UploadInsert: "อัพโหลดเพื่อเพิ่มข้อมูลใหม่",
  SelectType: "เลือกประเภท",
  AdjustSalary: "ปรับเงินเดือน",
  PassportNumber: "เลขพาสปอรต์",
  WorkPermitNumber: "เลขใบอนุญาตทำงาน",
  CompanyCharge: "บริษัทที่เรียกเก็บเงิน",
  PerformanceReview: "ผลงานย้อนหลัง",
  TerminateDate: "วันที่หมดสัญญา",
  RecordAttendance: "บันทึกเวลาทำงาน",
  Wages: "ค่าจ้าง",
  ResignDate: "วันลาออก",
  TransferPosition: "โอนย้าย",
  HolidayName: "ชื่อวันหยุด",
  HolidayNameEnglish: "ชื่อวันหยุดภาษาอังกฤษ",
  DateHoliday: "วันหยุด",
  NameOfHolidayCompensate: "ชื่อวันหยุดชดเชย",
  CompensateHolidayNameEnglish: "ชื่อวันหยุดชดเชยภาษาอังกฤษ",
  CompensateHolidayDate: "วันที่หยุดชดเชย",
  Manage: "จัดการ",
  ListOfAnnualHolidays: "รายการวันหยุดประจำปี",
  Use: "ใช้งาน",
  NotInUse: "ไม่ใช้งาน",
  Unlimited: "ไม่จำกัดจำนวนครั้ง",
  EditManager: "แก้ไขหัวหน้างาน",
  AddManager: "เพิ่มหัวหน้างาน",
  confirmResetPassword: "ยืนยันการรีเซ็ทรหัสผ่าน",
  FailToResetPassword: "รีเซ็ทรหัสผ่านไม่สำเร็จ",
  PasswordIsReseted: "รหัสผ่านถูกรีเซ็ทเรียบร้อย",
  HalfHour: "ครึ่งชั่วโมง",
  Hour: "ชั่วโมง",
  TotalLeaveDays: "จำนวนวันลา",
  AccumulateNextRoundNotMoreThan: "สะสมไปรอบถัดไปได้ไม่เกิน",
  CanRequestLeaveAfter: "สามารถขอลาได้หลังจาก",
  MinimumLeaveAllowed: "อนุญาติให้ลาขั้นต่ำ",
  LeaveIn: "ต้องลาล่วงหน้า",
  LeaveName: "ชื่อวันลา",
  LeaveNameOptional: "ชื่อวันลา ภาษาอังกฤษ",
  NotPaying: "ไม่จ่าย",
  FirstDayOfService: "เริ่มงานวันแรก",
  YearOfService: "อายุงาน",
  TypeOfLeaveDays: "ประเภทจำนวนวันลา",
  CalProRateLessThanOneYear: "คำนวน Pro Rate (กรณีเข้างานยังไม่ครบ 1 ปี)",
  ConditionType: "ประเภทเงื่อนไข",
  SinceServiceOfYear: "ตั้งแต่อายุงาน",
  UntilServiceOfYear: "จนถึงอายุงาน",
  CannotCarryOverLeaveNextRound: "ยกวันลาไปรอบถัดไปได้ไม่เกิน",
  EditLeaveDay: "แก้ไขวันลา",
  RoundsDecimalIsGreater: "ปัดเศษเป็นจำนวนเต็มเมื่อทศนิยมมากกว่า",
  ThereIsNoRounding: "ไม่มีการปัดเศษ",
  RoundWhenGreaterThan: "ปัดขึ้นเมื่อมากกว่า",
  RoundUpInAllCases: "ปัดขึ้นทุกกรณี",
  IsPaid: " การจ่ายเงิน",
  DeleteLeave: "ลบวันลา",
  Rounding: "ปัดเศษ",
  OnCondition: "มีเงื่อนไข",
  ProbationResult: "ผลทดลองงาน",
  PassedProbation: "ผ่านการทดลองงาน",
  NotPassedProbation: "ไม่ผ่านการทดลองงาน",
  Assessor: "ผู้ประเมิน",
  EditProbationResult: "แก้ไขผลการทดลองงาน",
  WaitingForEvaluation: "รอประเมิน",
  ProbationReviewQuestion: "คำถามประเมินการทดลองงาน",
  ActiveQuestion: "คำถามที่ใช้งาน",
  CanceledQuestion: "คำถามที่ถูกยกเลิกการใช้",
  Active: "เปิดใช้งาน",
  Inactive: "ปิดใช้งาน",
  Question: "คำถาม",
  AddQuestion: "เพิ่มคำถาม",
  Strength: "จุดแข็ง",
  Weakness: "จุดอ่อน",
  Employee: "พนักงาน",
  LevelGroup: "กลุ่มระดับ",
  AddEditLevelGroup: "เพิ่ม/แก้ไขกลุ่มระดับ",
  AddLevelGroup: "เพิ่มกลุ่มระดับ",
  NoLevel: "ไม่มีระดับ",
  AddEditLevel: "เพิ่ม/แก้ไขระดับ",
  AddLevel: "เพิ่มระดับ",
  ManageLevel: "จัดการระดับ",
  SelectDate: "เลือกวันที่",
  Description: "รายละเอียด",
  DataSaveSuccessful: "บันทึกข้อมูลสำเร็จ",
  AnErrorOccurred: "เกิดข้อผิดพลาด กรุณาติดต่อแอดมิน",
  AddTemporaryReserveEmployee: "เพิ่มพนักงานสำรองชั่วคราว",
  EditTemporaryReserveEmployee: "แก้ไขพนักงานสำรองชั่วคราว",
  NumberOfPeople: "จำนวนคน",
  CostChargePerPerson: "วงเงินเรียกเก็บ (ต่อคน)",
  Total: "รวม",
  Withdrawn: "ถอน",
  ApprovedWithdraw: "อนุมัติถอน",
  PendingWithdraw: "รออนุมัติถอน",
  RejectWithdraw: "ไม่อนุมัติถอน",
  UnableRequestLeave: "ไม่สามารถขอวันลาในวันหยุดได้",
  UnableRequestLeaveHalf: "ไม่สามารถขอลาในวันหยุดได้",
  UnableRequestLeaveduringBreakTime: "ไม่สามารถขอลาในเวลาพักได้",
  RequestedTimeOutsidAllowedTime: "ไม่สามารถขอลานอกเวลางานได้",
  Unlimite: "ไม่จำกัด",
  DeletedSuccessfully: "ลบข้อมูลสำเร็จ",
  SummaryTimeStampDepartment: "สรุปเวลาทำงานรายแผนก",
  SummaryOTandShift: "สรุป OT และค่ากะ",
  SummaryOT: "สรุปชั่วโมงและรายจ่าย OT",
  SummaryOfLeaveAndAbsences: "สรุปการลาและขาดงาน",
  SelectLeaveRound: "เลือกรอบการลา",
  SummaryofLeaveList: "สรุปการลาแยกรายการ",
  DownloadLeaveReport: "ดาวน์โหลดรายงานสิทธิ์การลาของพนักงาน",
  PleaseSelectInformation: "กรุณาเลือกข้อมูล",
  QuotaOnLeave: "รายงานวันลาเทียบโควต้า",
  AllReports: "รายงานทั้งหมด",
  SelectCompany: "เลือกบริษัท",
  EmployeeUpdatedLevel: "รายงานปรับระดับ",
  UpdatedEmploymentReport: "รายงานเปลี่ยนประเภทการจ้าง",
  oldEmploymentType: "ประเภทการจ้างเก่า",
  newEmploymentType: "ประเภทการจ้างใหม่",
  EmployeeTransferPosition: "รายงานโอนย้าย",
  oldPosition: "ตำแหน่งเดิม",
  oldCompany: "บริษัทเดิม",
  oldDivision: "ฝ่ายเดิม",
  oldDepartment: "ส่วนเดิม",
  oldSection: "แผนกเดิม",
  newPosition: "ตำแหน่งใหม่",
  newCompany: "บริษัทใหม่",
  newDivision: "ฝ่ายใหม่",
  newDepartment: "ส่วนใหม่",
  newSection: "แผนกใหม่",
  PayrollReport: "รายงาน Payroll",
  YearlyPayrollReport: "รายงาน Payroll รายปี",
  MonthlyPayrollReport: "รายงาน Payroll รายเดือน",
  SalaryAdjustmentReport: "รายงานปรับเงินเดือน",
  SummaryDiligenceAllowance: "สรุปเบี้ยขยัน",
  SalarySlip: "สลิปเงินเดือน",
  ProvidentFundReport: "รายงานกองทุนสำรองเลี้ยงชีพ",
  EmployeeTaxRemittanceReport: "รายงานนำส่งภาษีรายคน",
  PayrollReportByCostCenter: "รายงาน Payroll Cost Center",
  PayrollReportByFunction: "รายงาน Payroll Function",
  PayrollAndTax: "เงินเดือนและภาษี",
  CompanyInformation: "ข้อมูลบริษัท",
  PersonalInformation: "ข้อมูลรายบุคคล",
  SetUpAutomaticEmailSendDate: "ตั้งค่าวันที่ส่ง email อัตโนมัติ",
  EmailSentDate: "วันที่ส่ง Email",
  ViewInformation: "ดูข้อมูล",
  PersonalID: "หมายเลขประจำตัวประชาชน",
  SelectMonth: "เลือกเดือน",
  WorkingDays: "วันทำงาน",
  EmployeeTaxDeductionReport: "รายงานลดหย่อนภาษี",
  RequestReport: "รายงานการร้องขอ",
  SelectTypeOfRequest: "เลือกประเภทคำขอ",
  WorkingTimeRequest: "รับรองเวลาทำงาน",
  overtimeX3: "ขอทำงานล่วงเวลา x 3",
  SelectCompany: "เลือกบริษัท",
  SelectDepartment: "เลือกส่วน",
  SelectDivision: "เลือกฝ่าย",
  SelectSection: "เลือกแผนก",
  workingTimeReport: "รายงานเวลาทำงาน",
  ChooseField: "เลือกระดับ",
  PleaseEnterDetails: "กรุณาเลือกข้อมูลให้ครบถ้วน",
  NoSelectedDivisionData: "ไม่มีข้อมูลในหน่วยงานที่ท่านเลือก",
  NoSelectedDepartmentData: "ไม่มีข้อมูลในส่วนที่ท่านเลือก",
  NoSelectedSectionData: "ไม่มีข้อมูลในแผนกที่ท่านเลือก",
  BillStatement: "บิลเรียกเก็บ",
  DocumentName: "ชื่อเอกสาร",
  SearchDocName: "ค้นหาชื่อเอกสาร",
  Month_Period: "รอบเดือน",
  Bill_draft: "แบบร่าง",
  Bill_Approved: "อนุมัติ",
  GovernmentReport: "เอกสารราชการ",
  CreateInvoice: "สร้างบิล",
  month: "เดือน"
};
