import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";

import { Container, Grid, Avatar, Typography, Box } from "@mui/material";

import HeaderPage from "../../shared/header/headerPage";

import { getVendorContract } from "../../../../actions/company";
import { getUserProfile } from "../../../../actions/user";

import Deal from "../../assets/deal.png";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .header": {
    paddingTop: 20,
    paddingBottom: 60,
  },
});

const StyledContent = styled(Box)({
  textDecoration: "none",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: 20,
  padding: "40px 24px",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow: "rgb(145 158 171 / 24%) -24px 24px 72px -8px",
  },
  "& .MuiAvatar-root": {
    width: 100,
    height: 100,
    marginBottom: 16,
    backgroundColor: "#FFFFFF",
  },
  "& .MuiTypography-h6": {
    fontSize: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 220,
  },
});

const ContractVendor = () => {
  const dispatch = useDispatch();
  const { result: companyContract } = useSelector(
    (state) => state.companyContract
  );

  useEffect(() => {
    dispatch(getVendorContract());
  }, []);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className="header">
          <HeaderPage textLabel={"บริษัทคู่ค้า"} />
        </div>
        <Grid container spacing={2} justifyContent="center">
          {companyContract &&
            companyContract.map((vendor, index) => (
              <Grid item xs={12} sm={6} md={3}>
                <StyledContent
                  key={vendor.idVendor}
                  component={NavLink}
                  to={`/admin/contract/profile/${vendor.idVendor}`}
                >
                  <Avatar
                    variant="rounded"
                    src={`${process.env.REACT_APP_API_URL}image/company/${vendor.vendorImage}`}
                  >
                    <Avatar src={Deal} />
                  </Avatar>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    gutterBottom
                  >{`${
                    vendor.numberEmployee ? vendor.numberEmployee : 0
                  } คน`}</Typography>
                  <Typography variant="h6" align="center" gutterBottom>
                    {vendor.vendorName
                      ? vendor.vendorName
                          .replace("บริษัท", "")
                          .replace("จำกัด", "")
                          .trim()
                      : "-"}
                  </Typography>
                </StyledContent>
              </Grid>
            ))}
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default ContractVendor;
