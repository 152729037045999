import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const EmployeeTransferXlsxReport = async (data, compantName) => {

    const headerCellStyle = {
        font: {
            name: "TH Sarabun New",
            size: 20,
            color: { argb: "FFFFFF" }
        },
        alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
        },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: "002060" }
        },
        // numFmt: "@",
    }

    const workBook = new ExcelJS.Workbook();

    const worksheet = workBook.addWorksheet("รายงานปรับระดับ");

    const headerRow = worksheet.addRow([
        "รหัสพนักงาน", "ชื่อ", "นามสกุล", "ตำแหน่งเดิม", "บริษัทเดิม", "ฝ่ายเดิม", "ส่วนเดิม", "แผนกเดิม", "ตำแหน่งใหม่", "บริษัทใหม่", "ฝ่ายใหม่", "ส่วนใหม่", "แผนกใหม่", "หมายเหตุ", "วันที่มีผล"
    ]);

    headerRow.eachCell((cell) => {
        cell.style = headerCellStyle;
        cell.numFmt = "@";
    });

    headerRow.height = 50;

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname_TH", width: 50 },
        { key: "lastname_TH", width: 50 },
        { key: "positionName", width: 50 },
        { key: "companyName", width: 60 },
        { key: "divisionName", width: 50 },
        { key: "departmentName", width: 50 },
        { key: "sectionName", width: 50 },
        { key: "newPositionName", width: 50 },
        { key: "newCompanyName", width: 60 },
        { key: "newDivisionName", width: 50 },
        { key: "newDepartmentName", width: 50 },
        { key: "newSectionName", width: 50 },
        { key: "description", width: 50 },
        { key: "date", width: 50 },
    ];

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    data.map(item => {
        const row = [
            item.employeeId || "-",
            item.firstname || "-",
            item.lastname || "-",
            item.positionsName || "-",
            item.companyName || "-",
            item.divisionName || "-",
            item.departmentName || "-",
            item.sectionName || "-",
            item.newPosition || "-",
            item.newCompany || "-",
            item.newDivision || "-",
            item.newDepartment || "-",
            item.newSection || "-",
            item.description || "-",
            item.updatedDate || "-",
        ]

        const excelRow = worksheet.addRow(row);
        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    })

    workBook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `AntHr-${compantName}-รายงานโอนย้าย.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}