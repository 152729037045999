import ExcelJS from 'exceljs';

export const LeaveListXlsx = (leaveEmployees, leaveType) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("สรุปการลาแยกรายการ");

    const LeaveTypeColumns = leaveType && leaveType.flatMap(i => ([
        { id: `${i.idLeaveType}_quota`, label: `โควต้า ${i.name}` },
        { id: `${i.idLeaveType}_used`, label: `${i.name} ใช้ไป` },
        { id: `${i.idLeaveType}_remaining`, label: `${i.name} คงเหลือ` },
    ]));

    const headerValues = [
        "รหัสพนักงาน",
        "ชื่อ",
        "นามสกุล",
        "ตำแหน่ง",
        "ษริษัท",
        "ฝ่าย",
        "ส่วน",
        "แผนก",
        ...LeaveTypeColumns.map(i => i.label)
    ];

    const headerRow = worksheet.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname", width: 30 },
        { key: "lastname", width: 30 },
        { key: "position", width: 30 },
        { key: "company", width: 30 },
        { key: "division", width: 30 },
        { key: "department", width: 30 },
        { key: "section", width: 30 },
        ...LeaveTypeColumns.flatMap((leaveType) => ([
            { key: `${leaveType.id}_quota`, width: 40 }, // 002060
            { key: `${leaveType.id}_used`, width: 40 }, // FF0000
            { key: `${leaveType.id}_remaining`, width: 40 }, // 00B050
        ]))
    ];

    // Set column widths
    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    leaveEmployees && leaveEmployees.forEach((item) => {
        const row = [
            item.employeeId ? item.employeeId : "-",
            item.firstname ? item.firstname : "-",
            item.lastname ? item.lastname : "-",
            item.positionsName ? item.positionsName : "-",
            item.companyName ? item.companyName : "-",
            item.divisionName ? item.divisionName : "-",
            item.departmentName ? item.departmentName : "-",
            item.sectionName ? item.sectionName : "-",
        ];

        leaveType && leaveType.forEach(leave => {
            const matchedLeave = item.leaves && item.leaves.length > 0 && item.leaves.find(l => l.idLeaveType === leave.idLeaveType);
            const used = matchedLeave ? (Number.isInteger(matchedLeave.used) ? matchedLeave.used : matchedLeave.used.toFixed(2)) : '-';
            const remaining = matchedLeave && typeof matchedLeave.remaining === 'number' ? matchedLeave.remaining.toFixed(2) : '-';
            const quota = matchedLeave ? matchedLeave.quota : '-';

            row.push(quota, used, remaining);
        });

        const excelRow = worksheet.addRow(row);

        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `สรุปการลาแยกรายการ.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};
