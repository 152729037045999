import {
    EMPLOYEESSHIFTHISTORY_FETCHING,
    EMPLOYEESSHIFTHISTORY_FAILED,
    EMPLOYEESSHIFTHISTORY_SUCCESS
} from "../actions/types";

const initialState = {
    result: null,
    isFetching: false,
    isError: false
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case EMPLOYEESSHIFTHISTORY_FETCHING:
            return { ...state, result: null, isFetching: true, isError: false };
        case EMPLOYEESSHIFTHISTORY_FAILED:
            return { ...state, result: null, isFetching: false, isError: false };
        case EMPLOYEESSHIFTHISTORY_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false };
        default:
            return state;        
    }
}