import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import DrawerCustom from "../../../shared/general/Drawer";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import PDF from "./asset/sample1.pdf";

const useStyles = makeStyles((theme) => ({
  topic: {
    color: "#9e9e9e",
  },
}));

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

function RequestDialog(props) {
  const { openDialog, setOpenDialog } = props;
  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpenDialog({ ...openDialog, isOpen: false });
  };

  return (
    <DrawerCustom
      title={"รายละเอียด"}
      anchor={"right"}
      open={openDialog.isOpen}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Grid container sx={{ marginBottom: 3 }}>
          <Grid item>
            <Typography variant="body1" className={classes.topic} gutterBottom>
              ประเภท
            </Typography>
            <Typography variant="body1">
              {openDialog.request.leaveTypeName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ marginBottom: 3 }}>
          <Grid item>
            <Typography variant="body1" className={classes.topic} gutterBottom>
              เหตุผล
            </Typography>
            <Typography variant="body1">
              {openDialog.request.description}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body1" className={classes.topic} gutterBottom>
              เอกสารแนบ
            </Typography>
            <Typography variant="body1">
              <a href={PDF} target="_blank" rel="noopener noreferrer">
                ไฟล์แนบ
              </a>
            </Typography>
          </Grid>
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
}

export default RequestDialog;
