import { Grid, styled, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DrawerBank from "./drawerBank";
import ButtonEdit from "./shared/ButtonEdit";
import { getAllBank, getBankByBankDetail } from "../../../../actions/bank";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({});

const BankPanel = () => {
  const dispatch = useDispatch();
  const { result: BankDetail } = useSelector((state) => state.bankDetail);
  const { result: vendorProfile } = useSelector((state) => state.vendorProfile);
  const [isOpenDrawerBank, setIsOpenDrawerBank] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (vendorProfile && vendorProfile.idBankDetail) {
      dispatch(getBankByBankDetail(vendorProfile.idBankDetail));
    }
    dispatch(getAllBank());
  }, [vendorProfile]);

  return (
    <StyledRoot>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className="header-text" variant="h7">
            {t("BookBank")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color="text.secondary">
            {t("BankName")}
          </Typography>
          <Typography>{(BankDetail && BankDetail.bankName) || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">
            {t("BookBankID")}
          </Typography>
          <Typography>{(BankDetail && BankDetail.accountNo) || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary">
            {t("BankAccount")}
          </Typography>
          <Typography>
            {(BankDetail && BankDetail.accountName) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color="text.secondary">
            {t("BranchName")}
          </Typography>
          <Typography>
            {(BankDetail && BankDetail.branchName) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color="text.secondary">
            {t("BranchAddress")}
          </Typography>
          <Typography>
            {(BankDetail && BankDetail.branchAddress) || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonEdit
            onClick={() => {
              setIsOpenDrawerBank(true);
            }}
          />
        </Grid>
      </Grid>

      {isOpenDrawerBank && (
        <DrawerBank
          open={isOpenDrawerBank}
          onClose={() => {
            setIsOpenDrawerBank(false);
          }}
          data={BankDetail}
        />
      )}
    </StyledRoot>
  );
};

export default BankPanel;
