import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from "@mui/material/styles";
import { IconButton, Box, Container, Divider, Typography } from "@mui/material";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";

// import DialogDetail from "./dialogDetail";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";

// import {
//   getLevelByidCompany,
//   deleteGroupLevel,
//   deleteLevel,
// } from "../../../../../actions/level";
import { useTranslation } from "react-i18next";
import { deleteGroupLevelByIdCompanyIdGroupLevel, deleteLevelByIdCompanyIdLevel, getGroupLevelByIdCompany } from "../../../../../actions/company";
import DialogGroupLevel from "./dialogGroupLevel";
import DialogLevel from "./dialogLevel";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";

const Root = styled("div")({
  backgroundColor: "white !important",
  height: "fit-content",
  marginTop: "30px",
  paddingBottom: "20px",
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(270deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  borderTop: "none",
  "& .wrap-item": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const StyledDivider = styled(Divider)({
  margin: "8px 0px",
  borderWidth: "0px 0px thin",
  borderColor: "rgba(145, 158, 171, 0.24)",
  borderStyle: "dashed",
  width: "100%",
});

const Level = (props) => {
  const { t, i18n } = useTranslation();
  const { company, isReadOnly } = props;
  const { result: levelList, isFetching: isFetchingLevel } = useSelector(
    (state) => state.level
  );
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState("");

  const [drawerGroupLevelConfig, setDrawerGroupLevelConfig] = useState({
    isOpen: false,
    data: {},
  })

  const [drawerLevelConfig, setDrawerLevelConfig] = useState({
    isOpen: false,
    data: {},
  })

  const [dialogDeleteConfig, setDialogDeleteConfig] = useState({
    isOpen: false,
    data: {},
  })

  useEffect(() => {
    if (company) {
      dispatch(getGroupLevelByIdCompany(company.idCompany));
    }
  }, [company]);

  const handleAddGroupLevel = () => {
    setDrawerGroupLevelConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        groupLevel: levelList
      }
    }))
  }

  const handleAddLevel = (groupLevel) => (event) => {
    event.stopPropagation();
    setDrawerLevelConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        ...groupLevel,
      }
    }))
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleDeleteLevel = (level) => (event) => {
    event.stopPropagation();
    setDialogDeleteConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        isDeleteLevel: true,
        idLevel: level.idLevel,
        label: `${t("Level")}`,
        text: level.levelName,
      }
    }))
  };

  const handleDeleteGroupLevel = (groupLevel) => (event) => {
    event.stopPropagation();
    setDialogDeleteConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        isDeleteGroupLevel: true,
        idGroupLevel: groupLevel.idGroupLevel,
        label: `${t("LevelGroup")}`,
        text: groupLevel.groupLevelName,
      }
    }))
  };

  const confirmDelete = async () => {

    let result = null;

    if(dialogDeleteConfig.data.isDeleteGroupLevel){

      result = await dispatch(deleteGroupLevelByIdCompanyIdGroupLevel(company.idCompany, dialogDeleteConfig.data.idGroupLevel))


    } else if (dialogDeleteConfig.data.isDeleteLevel){

      result = await dispatch(deleteLevelByIdCompanyIdLevel(company.idCompany, dialogDeleteConfig.data.idLevel))

    } else {
      return
    }
    
    if(result && result.status === 200) {
      setDialogDeleteConfig(prev => ({
        ...prev,
        isOpen: false,
      }))
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getGroupLevelByIdCompany(company.idCompany));
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }
  }

  return (
    <Root>
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          marginBottom: 16,
        }}
      >
        <Typography variant="h6">{t("LevelGroup")}</Typography>
        <div>
          <ButtonBlue variant="contained" onClick={handleAddGroupLevel}>
            {t("AddEditLevelGroup")}
          </ButtonBlue>
        </div>
      </Box>
      {levelList &&
        levelList.map((groupLevel, index) => (
          <Accordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <div className="wrap">
                <Typography>{groupLevel.groupLevelName}</Typography>
                <div>
                  <IconButton
                    aria-label="add"
                    onClick={handleAddLevel(groupLevel)}
                  >
                    <EditNoteRoundedIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={handleDeleteGroupLevel(groupLevel)}
                  >
                    <DeleteOutlineRoundedIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {groupLevel.level && groupLevel.level.length > 0 ? (
                groupLevel.level.map((level, index) => (
                  <Fragment>
                    <div className="wrap-item" key={index}>
                      <Typography>
                        <Brightness1RoundedIcon
                          style={{
                            fontSize: 8,
                            margin: "0 16px",
                            color: "#9E9E9E",
                          }}
                        />
                        {level.levelName}
                      </Typography>
                      <IconButton
                        aria-label="delete"
                        style={{ color: "#e46a76" }}
                        onClick={handleDeleteLevel(level)}
                      >
                        <HighlightOffRoundedIcon />
                      </IconButton>
                    </div>

                    <StyledDivider />
                  </Fragment>
                ))
              ) : (
                <Typography
                  color="text.secondary"
                  style={{ fontStyle: "oblique", padding: 16 }}
                >
                  {t("NoLevel")}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      {isFetchingLevel && (
        <Typography textAlign="center" fontSize="20px">
          {t("LoadingData")}...
        </Typography>
      )}
      {!isFetchingLevel && levelList && levelList.length === 0 && (
        <Typography textAlign="center" fontSize="20px">
          {t("NoData")}
        </Typography>
      )}

      <DialogGroupLevel
        drawerConfig={drawerGroupLevelConfig}
        company={company}
        handleClose={() => {
          setDrawerGroupLevelConfig(prev => ({
            ...prev,
            isOpen: false,
          }))
        }}
      />

      <DialogLevel
        drawerConfig={drawerLevelConfig}
        company={company}
        handleClose={() => {
          setDrawerLevelConfig(prev => ({
            ...prev,
            isOpen: false,
          }))
        }}
      />

      <DialogConfirmDelete
        open={dialogDeleteConfig.isOpen}
        handleClose={() => {
          setDialogDeleteConfig(prev => ({
            ...prev,
            isOpen: false,
          }))
        }}
        text1={dialogDeleteConfig.data.label}
        text={dialogDeleteConfig.data.text}
        handleDelete={confirmDelete}
      />
    </Root>
  );
};

export default Level;
