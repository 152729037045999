import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import "dayjs/locale/th";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";

import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";

import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import PlaylistAddCircleRoundedIcon from "@mui/icons-material/PlaylistAddCircleRounded";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";

import { getAttendanceById } from "../../../../actions/attendance";

import ButtonBlue from "../../shared/general/ButtonBlue";
import StatusRequest from "../../shared/general/stausRequest";
import AlertResponse from "../../shared/general/AlertResponse";

import DialogConfirmDelete from "../../shared/general/DialogConfirmDelete";
import DialogTime from "./component/DialogDailyTime";

import { allLeaveType } from "../../../../actions/leave";
//import { getDailyManualSheetByIdEmployees } from "../../../../actions/manualsheet";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    textAlign: "center",
  },
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .cellAction": {
    width: 48,
    padding: "0px 0px 0px 4px",
  },
  "& .tableCellHead-date-time": {
    width: 140,
  },
  "& .tableCellHead-shift": {
    width: 100,
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderBottom: "none",
    borderColor: "#919eab3d",
    padding: 12,
    "&.cellStartStop": {
      // "&:hover": {
      //   cursor: "pointer",
      // },
      "& .time": {
        fontWeight: "600",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiSvgIcon-root": {
          marginRight: 4,
        },
      },
      "& .location": {
        fontWeight: 600,
      },
    },
  },
  "& .tableCellWeekend": {
    color: "#959595",
    fontStyle: "oblique",
  },
  "& .cellStatusAction": {
    textAlign: "left",
    "& .wrap-status": {
      display: "flex",
      "& .item": {
        marginRight: 4,
      },
    },
  },
  "& .cellRequest": {},
  "& .cellStartStop": {
    minWidth: 80,
    "& .MuiButtonBase-root": {
      marginRight: 2,
    },
  },
  "& .cellButtonCheck": {
    textAlign: "left",
  },
});

const StyledChip = styled(Chip)(({ status }) => ({
  margin: 0,
  fontSize: 14,
  fontWeight: 600,
  height: 26,
  ...(status === "ขอรับรองเวลาทำงาน" && {
    backgroundColor: "#fffcd6",
    color: "#eebb00",
    "& .MuiSvgIcon-root": {
      color: "#eebb00",
    },
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "#fff9ab",
    },
  }),
  ...(status === "ทำงานล่วงเวลา" && {
    backgroundColor: "#e9f0ff",
    color: "#1e4db6",
    "& .MuiSvgIcon-root": {
      color: "#1e4db6",
    },
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "#d4e0f9",
    },
  }),
  ...(status === "ขาดงาน" && {
    backgroundColor: "#fdf3f5",
    color: "#fc4b6c",
  }),
  ...(status === "มาสาย" && {
    backgroundColor: "#ddf0ff",
    color: "#1997f5",
  }),
  ...(status === "วันหยุดประจำปี" && {
    backgroundColor: "#f7f5ff",
    color: "#7451ff",
  }),

  ...((status === "ลาป่วย" || status === "ลากิจ" || status === "ลาพักร้อน") && {
    backgroundColor: "#ecfff5",
    color: "#38ca7f",
  }),
}));

const StyledCard = styled(Card)({
  marginBottom: 32,
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const StyledWrapFilter = styled("div")({
  marginBottom: 28,
  display: "flex",
  justifyContent: "space-between",
});

const StyledWrapLabel = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 16,
  "& .MuiChip-root": {
    backgroundColor: "#f4f6f8",
    marginRight: 8,
    ["@media only screen and (max-width: 600px)"]: {
      marginRight: 0,
    },
    "& .MuiChip-label": {
      fontWeight: 600,
    },
  },
});

const StyledWrapActionButton = styled("div")({
  marginTop: 8,
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

const StyledTableCellContent = styled(TableCell)({
  textAlign: "center",
  borderBottom: "none",
  padding: "8px 16px",
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  "&.status": {
    minWidth: 50,
    "& .MuiBox-root": {
      margin: "auto",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.15)",
      },
    },
  },
  "& .fullname": {
    width: 250,
    "& .MuiAvatar-root": {
      marginRight: 16,
    },
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    "& .name": {
      textAlign: "left",
      fontWeight: 600,
      fontSize: 14,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 300,
    },
  },
  "& .actual-plan": {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 200,
    margin: "auto",
    padding: "0 20px",
  },
  "& .actual": {
    fontWeight: 600,
    fontSize: 24,
  },
  "& .plan": {
    fontWeight: 600,
    color: "#919eab",
  },
  "&:first-of-type": {
    paddingLeft: 16,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledTableRow = styled(TableRow)({
  "& .MuiTableRow-root:last-child.MuiTableCell-root": {
    border: "none !important",
  },
});

function Row(props) {
  const { key, index, row, openPopover, handleOpenDialogTime } = props;
  const { t, i18n } = useTranslation();

  const [open, setOpen] = React.useState(false);

  const renderOT = (row) => {
    console.log("row in renderOT", row);
    let objOt = {
      otOne: row.otOne.hour * 60 + row.otOne.minute,
      otOneFive: row.otOneFive.hour * 60 + row.otOneFive.minute,
      otTwo: row.otTwo.hour * 60 + row.otTwo.minute,
      otThree: row.otThree.hour * 60 + row.otThree.minute,
    };
    let textRow = [];
    if (objOt.otOne > 0) {
      textRow.push(
        <Typography style={{ fontWeight: 500 }} color="text.third">
          {`OT x 1`} = {parseFloat(objOt.otOne / 60).toFixed(2)} ชม.
        </Typography>
      );
    }
    if (objOt.otOneFive > 0) {
      textRow.push(
        <Typography style={{ fontWeight: 500 }} color="text.third">
          {`OT x 1.5`} = {parseFloat(objOt.otOneFive / 60).toFixed(2)} ชม.
        </Typography>
      );
    }
    if (objOt.otTwo > 0) {
      textRow.push(
        <Typography style={{ fontWeight: 500 }} color="text.third">
          {`OT x 2`} = {parseFloat(objOt.otTwo / 60).toFixed(2)} ชม.
        </Typography>
      );
    }
    if (objOt.otThree > 0) {
      textRow.push(
        <Typography style={{ fontWeight: 500 }} color="text.third">
          {`OT x 3`} = {parseFloat(objOt.otThree / 60).toFixed(2)} ชม.
        </Typography>
      );
    }
    if (textRow.length === 0) {
      return <Typography color="text.third">{""}</Typography>;
    } else {
      return textRow;
    }
  };

  return (
    <React.Fragment>
      <TableRow key={key} style={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell className="tableCell">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>
            {dayjs()
              .set("date", row + 1)
              .locale(localStorage.getItem("language") || "th")
              .format(
                localStorage.getItem("language") === "en"
                  ? "DD/MM/YYYY"
                  : "DD/MM/BBBB"
              )}
            <Typography component={"span"} color="text.secondary">
              {dayjs()
                .set("date", row + 1)
                .locale(localStorage.getItem("language") || "th")
                .format(" (dd)")}
            </Typography>
          </Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography>{"Day"}</Typography>
        </TableCell>
        <TableCell className={`tableCell`}>
          <Typography
            color="text.third"
            style={{
              fontStyle: "oblique",
              fontWeight: "normal",
            }}
          >
            07:30 - 16:30
          </Typography>
        </TableCell>
        {!row.manualSheet ||
        (!row.manualSheet.checkIn &&
          !row.manualSheet.checkOut &&
          !row.manualSheet.checkIn_second &&
          !row.manualSheet.checkOut_second) ? (
          <TableCell className={`tableCell`} colSpan={2}>
            {t("TimeNotFound")}
          </TableCell>
        ) : (
          <Fragment>
            <TableCell className={`tableCell cellStartStop`}>
              <div
                style={{
                  height: 45,
                  marginBottom: row.manualSheet.checkIn ? 8 : 0,
                }}
              >
                {row.manualSheet.checkIn ? (
                  <Fragment>
                    <div>
                      <Typography
                        className="time"
                        aria-owns={
                          openPopover ? "mouse-over-popover" : undefined
                        }
                        aria-haspopup="true"
                      >
                        <GpsFixedRoundedIcon />
                        {row.manualSheet.checkIn.slice(11, 16)}
                      </Typography>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>{t("TimeNotFound")}</Fragment>
                )}
              </div>
              {row.manualSheet.checkIn_second && (
                <div style={{ height: 45 }}>
                  <Fragment>
                    <div>
                      <Typography
                        className="time"
                        aria-owns={
                          openPopover ? "mouse-over-popover" : undefined
                        }
                        aria-haspopup="true"
                      >
                        <GpsFixedRoundedIcon />
                        {row.manualSheet.checkIn_second.slice(11, 16)}
                      </Typography>
                    </div>
                  </Fragment>
                </div>
              )}
            </TableCell>
            <TableCell className={`tableCell cellStartStop`}>
              <div
                style={{
                  height: 45,
                  marginBottom: row.manualSheet.checkIn_second ? 8 : 0,
                }}
              >
                {row.manualSheet.checkOut ? (
                  <Fragment>
                    <div>
                      <Typography
                        className="time"
                        aria-owns={
                          openPopover ? "mouse-over-popover" : undefined
                        }
                        aria-haspopup="true"
                      >
                        <GpsFixedRoundedIcon />
                        {row.manualSheet.checkOut.slice(11, 16)}
                      </Typography>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>{t("TimeNotFound")}</Fragment>
                )}
              </div>
              {row.manualSheet.checkIn_second && (
                <div style={{ height: 45 }}>
                  {row.manualSheet.checkOut_second ? (
                    <Fragment>
                      <div>
                        <Typography
                          className="time"
                          aria-owns={
                            openPopover ? "mouse-over-popover" : undefined
                          }
                          aria-haspopup="true"
                        >
                          <GpsFixedRoundedIcon />
                          {row.manualSheet.checkOut_second.slice(11, 16)}
                        </Typography>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>{t("TimeNotFound")}</Fragment>
                  )}
                </div>
              )}
            </TableCell>
          </Fragment>
        )}

        <TableCell className={`tableCell`} align="center">
          <ButtonBlue
            style={{ marginRight: 16 }}
            variant={row.HaveManual ? "outlined" : "contained"}
            onClick={() => handleOpenDialogTime(row)}
          >
            {row.HaveManual ? t("Edit") : t("Add")}
          </ButtonBlue>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ margin: 8 }}>
              <Typography align="left" variant="h6" gutterBottom>
                {t("Lists")}
              </Typography>
              {row.manualSheet &&
                (row.manualSheet.otOne.all > 0 ||
                  row.manualSheet.otOneFive.all > 0 ||
                  row.manualSheet.otTwo.all > 0 ||
                  row.manualSheet.otThree.all > 0) && (
                  <TableRow>
                    <StyledTableCellContent align="center" className="status">
                      <StatusRequest
                        boxSize={40}
                        fontSize={22}
                        borderRadius={8}
                        // step={whatLevel(request)} // 1 2 null
                        step={1}
                        status={1}
                        // active={request.isActive}
                        active={1}
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Chip
                        variant="outlined"
                        icon={
                          <PlaylistAddCircleRoundedIcon
                            style={{ color: "#1e4db6" }}
                          />
                        }
                        label="ทำงานล่วงเวลา"
                      />
                    </StyledTableCellContent>
                    <StyledTableCellContent>
                      <Typography align="center">
                        {renderOT(row.manualSheet)}
                      </Typography>
                    </StyledTableCellContent>
                  </TableRow>
                )}
              {row.manualSheet && row.manualSheet.leave && (
                <TableRow>
                  <StyledTableCellContent align="center" className="status">
                    <StatusRequest
                      boxSize={40}
                      fontSize={22}
                      borderRadius={8}
                      status={
                        1
                        // request.isApprove === 1
                        //   ? 1
                        //   : request.isApprove === 0
                        //   ? 0
                        //   : null
                      }
                      active={row.manualSheet.leave.isActive}
                    />
                  </StyledTableCellContent>
                  <StyledTableCellContent>
                    <Chip
                      variant="outlined"
                      icon={
                        <PlaylistAddCircleRoundedIcon
                          style={{ color: "#ff6d00" }}
                        />
                      }
                      label="ขอลางาน"
                    />
                  </StyledTableCellContent>
                  <StyledTableCellContent>
                    {row.manualSheet.leave.isFullDay === 1 ? (
                      <Typography
                        align="center"
                        variant="h6"
                        style={{ fontSize: 16 }}
                      >
                        {/* {(row.manualSheet.leave.start).split('T')[0].replace(/-/g,"/")} */}
                        {dayjs(
                          row.manualSheet.leave.start.split("T")[0]
                        ).format("DD/MM/YYYY")}
                      </Typography>
                    ) : (
                      <Fragment>
                        <Typography align="center" variant="h6">
                          {row.manualSheet.leave.start.slice(11, 16)}
                        </Typography>
                        <Typography
                          align="center"
                          variant="body2"
                          color="text.third"
                        >
                          {dayjs(
                            row.manualSheet.leave.start.split("T")[0]
                          ).format("DD/MM/YYYY")}
                        </Typography>
                      </Fragment>
                    )}
                  </StyledTableCellContent>
                  <StyledTableCellContent>
                    {row.manualSheet.leave.isFullDay === 1 ? (
                      <Typography
                        align="center"
                        variant="h6"
                        style={{ fontSize: 16 }}
                      >
                        {dayjs(row.manualSheet.leave.end.split("T")[0]).format(
                          "DD/MM/YYYY"
                        )}
                      </Typography>
                    ) : (
                      <Fragment>
                        <Typography align="center" variant="h6">
                          {row.manualSheet.leave.end.slice(11, 16)}
                        </Typography>
                        <Typography
                          align="center"
                          variant="body2"
                          color="text.third"
                        >
                          {dayjs(
                            row.manualSheet.leave.end.split("T")[0]
                          ).format("DD/MM/YYYY")}
                        </Typography>
                      </Fragment>
                    )}
                  </StyledTableCellContent>
                </TableRow>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const manualSheetState = [
  {
    date: "2023-05-01",
  },
  {
    date: "2023-05-02",
  },
  {
    date: "2023-05-03",
  },
  {
    date: "2023-05-04",
  },
  {
    date: "2023-05-05",
  },
  {
    date: "2023-05-02",
  },
  {
    date: "2023-05-02",
  },
  {
    date: "2023-05-02",
  },
  {
    date: "2023-05-02",
  },
];

function ManualSheet() {
  const today = dayjs().toDate();
  const { t, i18n } = useTranslation();
  dayjs.locale("th");
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const [openDialogTime, setOpenDialogTime] = React.useState(false);
  const [valueDialogTime, setValueDialogTime] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const openPopover = Boolean(anchorElPopover);
  const [ManualSheetState, setManualSheetState] = React.useState([]);

  useEffect(() => {
    dispatch(allLeaveType());
    //dispatch(getDailyManualSheetByIdEmployees(search))
  }, []);

  // useEffect(() => {
  //   if(ManualSheet && ManualSheet.length > 0){
  //     let compensateDateList = [];
  //     let newManualSheet = ManualSheet.map((itemManual,index) => {
  //         let temp = {...itemManual,
  //           HaveManual : false,
  //         };
  //         if(itemManual.pattern){
  //           temp.pattern = itemManual.pattern[0]
  //           if(
  //             itemManual.holiday &&
  //             itemManual.pattern.isWorkingDay === 0 &&
  //             itemManual.pattern.idWorkingType === 1
  //           ){
  //             compensateDateList.push(itemManual.holiday);
  //           }
  //           if(
  //             itemManual.pattern.isWorkingDay === 1 &&
  //             !itemManual.holiday &&
  //             compensateDateList.length > 0 &&
  //             itemManual.pattern.idWorkingType === 1
  //           ){
  //             temp.compensateDate = compensateDateList.shift();
  //           }
  //         }
  //         if(itemManual.manualSheet){
  //           temp.HaveManual = true;
  //           if(itemManual.manualSheet.checkIn){
  //             let checkInTime = itemManual.manualSheet.checkIn.split('T')[1].split(':')
  //             itemManual.manualSheet.checkInHours = Number(checkInTime[0])
  //             itemManual.manualSheet.checkInMinutes = Number(checkInTime[1])
  //           }
  //           if(itemManual.manualSheet.checkOut){
  //             let checkOutTime = itemManual.manualSheet.checkOut.split('T')[1].split(':')
  //             itemManual.manualSheet.checkOutHours = Number(checkOutTime[0])
  //             itemManual.manualSheet.checkOutMinutes = Number(checkOutTime[1]);
  //           }
  //         }
  //         return temp;
  //     });
  //     console.log("newManualSheet : ",newManualSheet);
  //     setManualSheetState(newManualSheet);
  //   }
  // },[ManualSheet]);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOpenDialogTime = (row) => {
    setOpenDialogTime(true);
    setValueDialogTime(row + 1);
  };

  const handleCloseDialogTime = () => {
    setValueDialogTime(null);
  };

  return (
    <StyledRoot>
      <Container maxWidth="lg">
        <div style={{ marginTop: 16 }}>
          <StyledCard>
            <Box style={{ overflow: "auto" }}>
              <TableContainer style={{ overflow: "auto", minWidth: 860 }}>
                <StyledTable aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ width: 50 }}
                        className={`tableCellHead`}
                      />
                      <TableCell
                        className={`tableCellHead tableCellHead-date-time`}
                      >
                        {t("DateAt")}
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-shift`}
                      >
                        {t("ShiftTable")}
                      </TableCell>
                      <TableCell
                        className={`tableCellHead tableCellHead-shift-time`}
                      >
                        {t("Work_Time")}
                      </TableCell>
                      <TableCell className={`tableCellHead cellStartStop`}>
                        {t("checkIn")}
                      </TableCell>
                      <TableCell className={`tableCellHead cellStartStop`}>
                        {t("checkOut")}
                      </TableCell>
                      {/* <TableCell className={`tableCellHead cellStatusAction`}>
                        สถานะ
                      </TableCell> */}
                      <TableCell className={`tableCellHead`}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...Array(30).keys()].map((row, index) => (
                      <Row
                        key={index}
                        row={row}
                        searchDate={search}
                        openPopover={openPopover}
                        handleOpenDialogTime={handleOpenDialogTime}
                      />
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            </Box>
          </StyledCard>
        </div>
      </Container>

      {valueDialogTime && (
        <DialogTime
          values={valueDialogTime}
          open={openDialogTime}
          handleClose={handleCloseDialogTime}
          handleChangeAlertType={handleChangeAlertType}
          handleOpenAlert={handleOpenAlert}
          searchDate={search}
        />
      )}

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
}

export default ManualSheet;
