import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Divider,
  TextField,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getAllEmployees } from "../../../../../../actions/employee";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5),
    width: "95%",
    maxHeight: 800,
  },
  dialogTitle: {
    color: "#16243D",
    padding: theme.spacing(1),
  },
  dialogContent: {
    padding: "16px 8px 24px 8px",
  },
  dialogActions: {
    marginTop: theme.spacing(1),
  },
  headInput: {
    color: "#616161",
  },
}));

function AddUser(props) {
  const { addModal, setAddModal } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: empStore } = useSelector((state) => state.employees);
  const [autoSelected, setAutoSelected] = useState([]);
  const [role, setRole] = useState("");

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log("Data Submit");
  };

  useEffect(() => {
    console.log("autoSelected", autoSelected);
  }, [autoSelected]);

  useEffect(() => {
    if (addModal.isOpen) {
      dispatch(getAllEmployees());
      setAutoSelected([]);
    }
  }, [addModal]);

  return (
    <Dialog open={addModal.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              Add User
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={() => setAddModal({ ...addModal, isOpen: false })}
            >
              <CloseIcon className={classes.closeBtn} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              Email
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Autocomplete
              options={empStore}
              value={autoSelected}
              onChange={(e, newValue) => {
                setAutoSelected(newValue);
              }}
              getOptionLabel={(option) => option.email || ""}
              renderInput={(params) => (
                <TextField {...params} label="Search Email" />
              )}
              disablePortal
              autoHighlight
              margin="dense"
            />
          </Grid>
        </Grid>
        {autoSelected.length !== 0 ? (
          <>
            <Grid container>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant="body1" className={classes.headInput}>
                  Full Name
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <TextField
                  variant="outlined"
                  value={autoSelected.firstName + " " + autoSelected.lastName}
                  margin="dense"
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant="body1" className={classes.headInput}>
                  Position
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <TextField
                  variant="outlined"
                  value={autoSelected.jobPosition}
                  margin="dense"
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant="body1" className={classes.headInput}>
                  Role
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <FormControl margin="dense" fullWidth>
                  <InputLabel>Select Role</InputLabel>
                  <Select
                    value={role}
                    label="Select Role"
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <MenuItem value="super_admin">Super Admin</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="manager">Manager</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <ButtonBlue
              value={"Add"}
              variant={"contained"}
              startIcon={<SaveIcon />}
              onClick={handleOnSubmit}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AddUser;
