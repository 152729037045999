import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Avatar, MenuItem, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useTranslation } from "react-i18next";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 340,
    "& .first-line": {
      width: 260,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .MuiAvatar-root": {
      marginRight: 8,
      width: 45,
      height: 45,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .department": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    fontWeight: 600,
  },
  "& .reportToPosition": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 500px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
});

const ItemTable = (props) => {
  const { search } = props;
  const { t, i18n } = useTranslation();
  const { result: vendorList } = useSelector((state) => state.vendorAntTeam);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSelected, setValueSelected] = React.useState(null);
  const [dataList, setDataList] = React.useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const columns = [
    { id: "companyName", label: `ชื่อบริษัท`, minWidth: 340 },
    {
      id: "mainContactName",
      label: `ข้อมูลผู้ติดต่อ`,
      minWidth: 250,
    },
    {
      id: "amountOfEmployees",
      label: `พนักงาน(คน)`,
      align: "center",
      minWidth: 100,
    },
  ];

  useEffect(() => {
    if (search) {
      let temp = [...vendorList];
      setDataList(temp.filter((x) => x.vendorName.toLowerCase().includes(search.toLowerCase())));
    } else {
      setDataList(vendorList);
    }
  }, [search, vendorList]);

  return (
    <div>
      <StyledBox className={"close"}>
        <TableContainer style={{ width: "100%", height: 800 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledCellHeader
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledCellHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList &&
                dataList.length > 0 &&
                dataList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <StyledRowContent key={`${page}-${index}`}>
                        <StyledCellContent className="sticky">
                          <div className="fullname">
                            <IconButton
                              aria-label="more"
                              size="small"
                              style={{ marginRight: 8 }}
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setValueSelected(row);
                                console.log(row);
                              }}
                            >
                              <MoreVertIcon fontSize="small" />
                            </IconButton>
                            <Avatar
                              src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                            />
                            <div>
                              <Typography className="first-line">
                                {row.vendorName}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                style={{ fontWeight: 600 }}
                              >
                                {`${row.vendorNameShort} -`}{" "}
                                <Typography
                                  style={{ fontStyle: "oblique" }}
                                  component="span"
                                  color="text.secondary"
                                >
                                  {`${
                                    row.isActive ? `ใช้งานปกติ` : `ยกเลิกใช้งาน`
                                  }`}
                                </Typography>
                              </Typography>
                            </div>
                          </div>
                        </StyledCellContent>
                        <StyledCellContent>
                          <Typography className="position">
                            {row.mainContactName}
                          </Typography>
                          <Typography className="department" color="text.third">
                            {`${
                              row.mainContactPhone
                                ? row.mainContactPhone.length === 10
                                  ? row.mainContactPhone.replace(
                                      /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                                      "$1-$2-$3"
                                    )
                                  : row.mainContactPhone.replace(
                                      /(\d\d)(\d\d\d)(\d\d\d\d)/,
                                      "$1-$2-$3"
                                    )
                                : "-"
                            }`}
                          </Typography>
                        </StyledCellContent>
                        <StyledCellContent align="center">
                          <Typography>{row.amountOfEmployees}</Typography>
                        </StyledCellContent>
                      </StyledRowContent>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dataList ? dataList.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <StyledMenu
          id={`more-menu`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {valueSelected && (
            <MenuItem
              component={NavLink}
              to={`/vendor/employees/${valueSelected.idEmp}/edit`}
            >
              <i className="far fa-user-edit" style={{ marginRight: 16 }}></i>
              <Typography>{t("Edit_Data")}</Typography>
            </MenuItem>
          )}
        </StyledMenu>
      </StyledBox>
    </div>
  );
};

export default ItemTable;
