import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import "dayjs/locale/th";
import * as XLSX from "xlsx";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import SelectEmployees from "../shared/selectEmployees";
import SelectCompany from "../shared/selectCompany";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerEng from "../../shared/general/DatePickerEng";
import DatePickerThai from "../../shared/general/DatePickerThai";
import Backdrop from "../../shared/general/Backdrop";
import AlertResponse from "../../shared/general/AlertResponse";

import {
  getAttendanceVendor,
  clearAttendance,
} from "../../../../actions/attendance";

const StyledRoot = styled("div")({
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledBoxSearchButton = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    textAlign: "center",
  },
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .cellAction": {
    width: 48,
    padding: "0px 0px 0px 4px",
  },
  "& .tableCellHead-date-time": {
    width: 120,
  },
  "& .tableCellHead-shift": {
    width: 100,
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderBottom: "none",
    borderColor: "#919eab3d",
    padding: 12,
    "&.cellStartStop": {
      "&:hover": {
        cursor: "pointer",
      },
      "& .time": {
        fontWeight: "600",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiSvgIcon-root": {
          marginRight: 4,
        },
      },
      "& .location": {
        fontWeight: 600,
      },
    },
  },
  "& .tableCellWeekend": {
    color: "#959595",
    fontStyle: "oblique",
  },
  "& .cellStatusAction": {
    textAlign: "left",
    "& .wrap-status": {
      display: "flex",
      "& .item": {
        marginRight: 4,
      },
    },
  },
  "& .cellRequest": {},
  "& .cellStartStop": {
    minWidth: 80,
    "& .MuiButtonBase-root": {
      marginRight: 2,
    },
  },
  "& .tableCellGray": {
    color: "#637381",
  },
  "& .tableCellChip": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const StyledChipTime = styled(Chip)({
  height: 28,
  width: 92,
  fontSize: 14,
  borderRadius: 8,
  border: "none",
  "&.check-in": {
    color: "#229a16",
    backgroundColor: "#54d62c29",
    "&.MuiChip-root.MuiChip-clickable:hover": {
      backgroundColor: "#54d62c29",
    },
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },
  "&.check-out": {
    color: "#b72136",
    backgroundColor: "#ff484229",
    "&.MuiChip-root.MuiChip-clickable:hover": {
      backgroundColor: "#ff484229",
    },
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
    },
  },
});

const StyledCard = styled(Card)({
  marginBottom: 32,
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  overflowX: "scroll",
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const StyledLoading = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "inherit",
});

function WeeklyPanel(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: attendance } = useSelector((state) => state.attendance);
  const { isFetching: attendanceIsFetching } = useSelector(
    (state) => state.attendance
  );
  const { isError: attendanceIsError } = useSelector(
    (state) => state.attendance
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const today = dayjs().toDate();
  const [search, setSearch] = useState({
    start: dayjs(
      new Date(today.getFullYear(), today.getMonth(), today.getDate())
    ).subtract(dayjs().day() - 1, "day"),
    end: dayjs(
      new Date(today.getFullYear(), today.getMonth(), today.getDate())
    ).add(7 - dayjs().day(), "day"),
  });
  const [tempSearchDate, setTempSearchDate] = useState({
    start: dayjs(
      new Date(today.getFullYear(), today.getMonth(), today.getDate())
    ).subtract(dayjs().day() - 1, "day"),
    end: dayjs(
      new Date(today.getFullYear(), today.getMonth(), today.getDate())
    ).add(7 - dayjs().day(), "day"),
  });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (attendanceIsError) {
      setAlertType("ตรวจสอบและลองอีกครั้ง");
      handleOpenAlert();
    }
  }, [attendanceIsError]);

  useEffect(() => {
    let filter = {
      start: dayjs(
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - dayjs().day() + 1
        )
      ),
      end: dayjs(
        new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          today.getDate() + 7 - dayjs().day()
        )
      ),
    };
    dispatch(getAttendanceVendor(null, null, filter));
  }, []);

  const handleSelectEmployee = (employee) => {
    setSelectedEmployee(employee);
    if (
      employee &&
      dayjs(employee.hiringDate).isAfter(dayjs(tempSearchDate.start))
    ) {
      let start = dayjs(employee.hiringDate);
      let end = dayjs(tempSearchDate.end);
      if (dayjs(employee.hiringDate).isAfter(dayjs(tempSearchDate.end))) {
        end = dayjs(employee.hiringDate)
          .set("date", 1)
          .add(1, "month")
          .subtract(1, "day");
      }
      setTempSearchDate({
        ...tempSearchDate,
        ["start"]: start,
        ["end"]: end,
      });
    }
  };

  const handleSelectCompany = (company) => {
    setSelectedCompany(company);
  };

  const handleOnClickSearch = () => {
    dispatch(clearAttendance());
    setSearch(tempSearchDate);
    if (selectedEmployee || selectedCompany) {
      dispatch(
        getAttendanceVendor(
          selectedEmployee ? selectedEmployee.idEmp : null,
          selectedCompany ? selectedCompany.idCompany : null,
          tempSearchDate
        )
      );
    } else {
      dispatch(getAttendanceVendor(null, null, tempSearchDate));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const excelExport = () => {
    const elementList = [];

    for (let index = 0; index < attendance.length; index++) {
      const element = {
        รหัสพนักงาน: attendance[index].employeeId,
        ชื่อ: attendance[index].firstname,
        นามสกุล: attendance[index].lastname,
        ตำแหน่ง: attendance[index].positionsName,
        วันที่: attendance[index].attendanceDateText,
        เวลา: attendance[index].attendanceTimeText,
        สถานที่: attendance[index].gpsLocationsName,
        สถานะ: attendance[index].isCheckIn ? "ลงเวลาเข้า" : "ลงเวลาออก",
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);

    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "รายงาน");
    if (selectedEmployee) {
      XLSX.writeFile(
        workbook,
        `รายงานการลงเวลา ${selectedEmployee.employeeId}_${
          selectedEmployee.firstname
        }_${selectedEmployee.lastname}_${dayjs(search.start).format(
          "YYYY_MM_DD"
        )} ถึง ${dayjs(search.end).format("YYYY_MM_DD")}.xlsx`,
        { type: "file" }
      );
    } else {
      XLSX.writeFile(
        workbook,
        `รายงานการลงเวลา ${dayjs(search.start).format(
          "YYYY_MM_DD"
        )} ถึง ${dayjs(search.end).format("YYYY_MM_DD")}.xlsx`,
        { type: "file" }
      );
    }
  };

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 16 }}
        alignItems={"center"}
      >
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("StartDate")}
            </Typography>
            <div className="search-date">
              {localStorage.getItem("language") === "en" ? (
                <DatePickerEng
                  minDate={
                    selectedEmployee && selectedEmployee.hiringDate
                      ? dayjs(selectedEmployee.hiringDate)
                      : null
                  }
                  value={tempSearchDate.start}
                  name="start"
                  onChange={(newValue) => {
                    setTempSearchDate({
                      ...tempSearchDate,
                      ["start"]: newValue,
                    });
                  }}
                />
              ) : (
                <DatePickerThai
                  minDate={
                    selectedEmployee && selectedEmployee.hiringDate
                      ? dayjs(selectedEmployee.hiringDate)
                      : null
                  }
                  value={tempSearchDate.start}
                  name="start"
                  onChange={(newValue) => {
                    setTempSearchDate({
                      ...tempSearchDate,
                      ["start"]: newValue,
                    });
                  }}
                />
              )}
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("EndDate")}
            </Typography>
            <div className="search-date">
              {localStorage.getItem("language") === "en" ? (
                <DatePickerEng
                  minDate={tempSearchDate.start}
                  value={tempSearchDate.end}
                  name="end"
                  onChange={(newValue) => {
                    setTempSearchDate({
                      ...tempSearchDate,
                      ["end"]: newValue,
                    });
                  }}
                />
              ) : (
                <DatePickerThai
                  minDate={tempSearchDate.start}
                  value={tempSearchDate.end}
                  name="end"
                  onChange={(newValue) => {
                    setTempSearchDate({
                      ...tempSearchDate,
                      ["end"]: newValue,
                    });
                  }}
                />
              )}
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectCompany
            disabled={selectedEmployee !== null}
            handleChangeCompany={handleSelectCompany}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectEmployees closeIcon={true} disabled={selectedCompany !== null} 
          handleChange={handleSelectEmployee} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <StyledBoxSearchButton>
            <div>
              <ButtonBlue
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={handleOnClickSearch}
              >
                {t("Search")}
              </ButtonBlue>
            </div>
            <div>
              <ButtonBlue
                onClick={excelExport}
                variant="outlined"
                startIcon={<DownloadRoundedIcon />}
                disabled={attendance === null || attendance.length === 0}
              >
                {t("Download")}
              </ButtonBlue>
            </div>
          </StyledBoxSearchButton>
        </Grid>
        {attendance ? (
          <Grid item xs={12}>
            <Fragment>
              <Typography variant="h4" style={{ paddingTop: 8 }}>
                {t("attendanceWeekly")}
              </Typography>
            </Fragment>
            <StyledCard>
              <TableContainer className="tableContainer">
                <StyledTable aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableCellHead">
                        {t("EmployeeID")}
                      </TableCell>
                      <TableCell className="tableCellHead">
                        {t("FirstName")} - {t("LastName")}
                      </TableCell>
                      <TableCell className="tableCellHead">
                        {t("Position")}
                      </TableCell>
                      <TableCell className="tableCellHead">
                        {t("DateAt")}
                      </TableCell>
                      <TableCell className="tableCellHead"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {attendance
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, index) => (
                        <TableRow key={index}>
                          <TableCell className="tableCellGray">
                            {data.employeeId}
                          </TableCell>
                          <TableCell>
                            {data.firstname} {data.lastname}
                          </TableCell>
                          <TableCell className="tableCellGray">
                            {data.positionsName}
                          </TableCell>
                          <TableCell>{data.attendanceDateText}</TableCell>
                          <TableCell className="tableCellChip">
                            {data.isCheckIn ? (
                              <StyledChipTime
                                className="check-in"
                                label={data.attendanceTimeText}
                                icon={
                                  data.idAttendanceType === 1 ? (
                                    <LocationOnIcon />
                                  ) : (
                                    <FingerprintIcon />
                                  )
                                }
                              ></StyledChipTime>
                            ) : (
                              <StyledChipTime
                                className="check-out"
                                label={data.attendanceTimeText}
                                icon={
                                  data.idAttendanceType === 1 ? (
                                    <LocationOnIcon />
                                  ) : (
                                    <FingerprintIcon />
                                  )
                                }
                              ></StyledChipTime>
                            )}
                            <Typography
                              className="location"
                              color="text.secondary"
                              variant="caption"
                            >
                              {data.gpsLocationsName}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </StyledTable>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={attendance && attendance.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </StyledCard>
          </Grid>
        ) : (
          <Backdrop open={true} />
        )}
      </Grid>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </Fragment>
  );
}

export default WeeklyPanel;
