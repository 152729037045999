import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';

function convertToThaiDate(date) {
    const thaiDay = date.locale('th').format("DD");
    const thaiMonth = date.locale('th').format("MMMM");
    const year = date.format('YYYY');
    const thaiYear = parseInt(year) + 543;
    const thaiDate = `${thaiDay + " " + thaiMonth + " " + thaiYear}`;

    return thaiDate;
}

export const WorkingTimeXlsxReport = (t, data, leaveData, empLeaveData, date) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("รายงานเวลาทำงาน");

    const dateRange = [];
    let currentDate = dayjs(date.start);
    let endDate = dayjs(date.end);

    while (currentDate.isSameOrBefore(endDate, 'day')) {
        dateRange.push({ date: currentDate.format("YYYY-MM-DD"), thDate: convertToThaiDate(currentDate) });
        currentDate = currentDate.add(1, 'day');
    }

    const leaveTypeNames = leaveData.map(leaveType => leaveType.name);

    const headerRow = worksheet1.addRow([
        "รหัสพนักงาน",
        "ชื่อ",
        "นามสกุล",
        "ตำแหน่ง",
        "ษริษัท",
        "ฝ่าย",
        "ส่วน",
        "แผนก",
        "วันที่เริ่มต้น",
        "วันที่สิ้นสุด",
        "รวมวันทำงาน",
        "%วันทำงาน",
        "รวมชั่วโมงทำงาน",
        "รวมชั่วโมงลา",
        "รวมชั่วโมง OT",
        ...dateRange.map((item) => item.thDate),
        "ขาดงาน",
        "มาสาย",
        "กลับก่อน",
        ...leaveTypeNames
    ]);

    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    const headerStyleRed = {
        font: { bold: false, size: 18, name: 'TH SarabunPSK', color: { argb: '9F0A10' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFC7CE' }
        },
    }

    const headerStyleGreen = {
        font: { bold: false, size: 18, name: 'TH SarabunPSK', color: { argb: '006100' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'C6EFCE' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
    });

    const leaveTypeColWidths = [];
    leaveData && leaveData.forEach(leaveType => {
        leaveTypeColWidths.push({
            key: `leaveType_${leaveType.idLeaveType}`,
            width: leaveType.name.length * 2
        });
    });

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname", width: 30 },
        { key: "lastname", width: 30 },
        { key: "position", width: 30 },
        { key: "company", width: 50 },
        { key: "division", width: 30 },
        { key: "department", width: 30 },
        { key: "section", width: 30 },
        { key: "start", width: 30 },
        { key: "end", width: 30 },
        { key: "totalWorkingDays", width: 30 },
        { key: "%work", width: 20 },
        { key: "totalWorkingHour", width: 30 },
        { key: "totalLeaveHour", width: 30 },
        { key: "totalOTHour", width: 30 },
        ...dateRange.map(date => ({ key: date, width: 40 })),
        { key: "absent", width: 20 },
        { key: "late", width: 20 },
        { key: "early", width: 20 },
        ...leaveTypeColWidths
    ];

    let summaries = {};

    const groupedData = data.reduce((acc, x) => {
        const startDate = dayjs(date.start).format("YYYY-MM-DD");
        const endDate = dayjs(date.end).format("YYYY-MM-DD");

        x.schedule.forEach(item => {
            const key = `${item.employeeId}_${startDate}_${endDate}`;

            let totalAbsent = 0;
            let totalLate = 0;
            let totalEarly = 0;
            let totalWorkingDays = 0;
            let totalWorkingHours = 0;
            let everyWorkingDay = 0;
            let totalLeaveHours = 0;
            let totalHoursOT = 0;

            let string_totalLeaveHours = "";
            let string_totalWorkingHours = "";
            let string_totalHoursOT = "";

            if (!acc[key]) {
                acc[key] = {
                    employeeId: item.employeeId || "-",
                    firstname: item.firstname || "-",
                    lastname: item.lastname || "-",
                    positionsName: item.positionsName || "-",
                    companyName: item.companyName || "-",
                    divisionName: item.divisionName || "-",
                    departmentName: item.departmentName || "-",
                    sectionName: item.sectionName || "-",
                    startDate: convertToThaiDate(date.start),
                    endDate: convertToThaiDate(date.end),
                    totalWorkingDay: 0,
                    percentWorkingDays: 0.00,
                    totalWorkingHours: string_totalWorkingHours || "-",
                    totalLeaveHour: string_totalLeaveHours || "-",
                    otHourstotal: string_totalHoursOT || "-",
                    checkTime: [],
                    absentData: 0,
                    lateData: 0,
                    earlyData: 0,
                    Num_Leave: [],
                };
            }

            dateRange.forEach(dateObj => {
                let workingStatus = "";

                const checkTimeEntries = x.schedule.filter(check => check.date === dateObj.date);
                // const checkLeaveEmployees = empLeaveData && empLeaveData.filter(emp => dayjs(emp.start).format("YYYY-MM-DD") === dateObj.date && emp.idEmp === item.idEmp);

                if (checkTimeEntries && checkTimeEntries.length > 0) {
                    checkTimeEntries.forEach(timeItem => {
                        totalHoursOT += timeItem.otHourstotal;

                        // const checkIfEmployeeLeaves = checkLeaveEmployees.find(leave => dayjs(leave.start).format("YYYY-MM-DD") === timeItem.date);
                        const checkIfEmployeeLeaves = timeItem.employeeLeaveDataInWorkingTime;

                        if (timeItem.shiftType) {
                            const shiftTimeIn = timeItem.shiftType.formattedTimeIn;
                            const shiftTimeOut = timeItem.shiftType.formattedTimeOut;

                            // // Split the time strings into hours and minutes
                            // const [shiftTimeInHours, shiftTimeInMinutes] = shiftTimeIn.split(":").map(Number);
                            // const [shiftTimeOutHours, shiftTimeOutMinutes] = shiftTimeOut.split(":").map(Number);

                            // // Convert the times to minutes
                            // const shiftTimeInTotalMinutes = shiftTimeInHours * 60 + shiftTimeInMinutes;
                            // const shiftTimeOutTotalMinutes = shiftTimeOutHours * 60 + shiftTimeOutMinutes;

                            // // Calculate the absolute difference in minutes
                            // const timeDifferenceInMinutes = Math.abs(shiftTimeOutTotalMinutes - shiftTimeInTotalMinutes);

                            // // Convert the difference back to hours and minutes
                            // const hours = Math.floor(timeDifferenceInMinutes / 60);
                            // const minutes = timeDifferenceInMinutes % 60;

                            const currentDate = dayjs(timeItem.date).format("YYYY-MM-DD");

                            if (checkIfEmployeeLeaves && checkIfEmployeeLeaves.idEmployees === timeItem.idEmployees) {
                                if ((checkIfEmployeeLeaves.date && dayjs(checkIfEmployeeLeaves.date).format("YYYY-MM-DD")) === currentDate) {
                                    totalLeaveHours += (checkIfEmployeeLeaves.used * (timeItem.shiftGroup && timeItem.shiftGroup.workingTimeToday));

                                    const totalHours = Math.floor(totalLeaveHours);
                                    const totalMinutes = Math.round((totalLeaveHours - totalHours) * 60);

                                    string_totalLeaveHours = `${totalHours} ชั่วโมง ${totalMinutes} นาที`;
                                } else {
                                    string_totalLeaveHours = `0 ชั่วโมง 0 นาที`;
                                }
                            };

                            if (timeItem.shiftType.isWorkingDay === 1 && timeItem.isHoliday === false) {
                                everyWorkingDay++; // วันทำงานทั้งหมด
                                totalWorkingHours += (timeItem.shiftGroup && timeItem.shiftGroup.workingTimeToday); // จำนวนชั่วโมงทำงานวันปกติ

                                if (timeItem.isFinger === 0) {
                                    if (checkIfEmployeeLeaves) {
                                        workingStatus = checkIfEmployeeLeaves ? `${checkIfEmployeeLeaves.name}` : "ลางาน";
                                    } else {
                                        totalWorkingDays++;
                                        workingStatus = "ลงเวลาสมบูรณ์";
                                    };
                                } else {
                                    if (timeItem.checkInAndcheckOutList) {
                                        const timeIn = timeItem.checkInAndcheckOutList.checkIn;
                                        const timeOut = timeItem.checkInAndcheckOutList.checkOut;

                                        if ((timeIn > shiftTimeIn) && timeOut) {
                                            totalWorkingDays++;
                                            const [timeInHour, timeInMinute] = timeIn.split(":").map(Number);
                                            const [shiftTimeInHour, shiftTimeInMinute] = shiftTimeIn.split(":").map(Number);

                                            const timeDiffInMinutes = (timeInHour - shiftTimeInHour) * 60 + (timeInMinute - shiftTimeInMinute);

                                            if (timeDiffInMinutes > timeItem.shiftType.lateIn) {
                                                totalLate++; // มาสาย
                                                workingStatus = "ลงเวลาสมบูรณ์";
                                            } else {
                                                workingStatus = "ลงเวลาสมบูรณ์";
                                            }
                                        } else if ((timeOut < shiftTimeOut) && timeIn) {
                                            totalWorkingDays++;
                                            totalEarly++; // กลับก่อน
                                            workingStatus = "ลงเวลาสมบูรณ์";
                                        } else if (timeIn <= shiftTimeIn && timeOut >= shiftTimeOut) {
                                            console.log("ersfsdfskfskd;fl");
                                            //มาตรงเวลา
                                            totalWorkingDays++;
                                            workingStatus = "ลงเวลาสมบูรณ์";
                                        } else if (!timeIn || !timeOut) {
                                            if (checkIfEmployeeLeaves) {
                                                workingStatus = checkIfEmployeeLeaves ? `${checkIfEmployeeLeaves.name}` : "ลางาน";
                                            } else if (timeItem.requestWorkingTime && timeItem.requestWorkingTime.length > 0) {
                                                totalWorkingDays++;
                                                workingStatus = "ลงเวลาสมบูรณ์";
                                            } else {
                                                totalAbsent++; // ขาดงาน
                                                workingStatus = "ลงเวลาไม่สมบูรณ์";
                                            }

                                        }
                                    }
                                }
                            } else if (timeItem.shiftType.isWorkingDay === 0 || timeItem.isHoliday === true) {
                                if (timeItem.isFinger === 0) {
                                    workingStatus = "หยุด";
                                } else {
                                    if (timeItem.checkInAndcheckOutList) {
                                        const timeIn = timeItem.checkInAndcheckOutList.checkIn;
                                        const timeOut = timeItem.checkInAndcheckOutList.checkOut;

                                        if (timeIn && timeOut) {
                                            workingStatus = "ลงเวลาสมบูรณ์";
                                        } else if (!timeIn && !timeOut) {
                                            if (timeItem.requestWorkingTime && timeItem.requestWorkingTime.length > 0) {
                                                workingStatus = "ลงเวลาสมบูรณ์";
                                            } else {
                                                workingStatus = "หยุด";
                                            }
                                        } else if (!timeIn || !timeOut) {
                                            // ลืม checkin หรือ checkout
                                            if (timeItem.requestWorkingTime && timeItem.requestWorkingTime.length > 0) {
                                                workingStatus = "ลงเวลาสมบูรณ์";
                                            } else {
                                                workingStatus = "ลงเวลาไม่สมบูรณ์";
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    });
                }

                acc[key].checkTime.push({
                    date: dateObj.date,
                    workingStatus: workingStatus
                });
            });

            string_totalWorkingHours = `${totalWorkingHours} ชั่วโมง`;

            let percentageOfWorkingDays = (totalWorkingDays / everyWorkingDay) * 100;

            let othours = Math.floor(totalHoursOT);
            let decimalPart = totalHoursOT - othours;
            let minutes = Math.round(decimalPart * 60);

            string_totalHoursOT = `${othours} ชั่วโมง ${minutes} นาที`;

            summaries[item.employeeId] = {
                absent: totalAbsent,
                late: totalLate,
                early: totalEarly,
                percentWorkingDays: percentageOfWorkingDays,
                workingDays: totalWorkingDays,
                workingHours: string_totalWorkingHours,
                totalLeaveHours: string_totalLeaveHours,
                otHourstotal: string_totalHoursOT
            };

            //นับจำนวนการลา แยกออกเป็นแต่ละประเภท
            leaveData && leaveData.forEach((leaveType) => {
                let totalUsedValue = 0.00;

                const employeeLeaveData = empLeaveData && empLeaveData.filter(emp => emp.idLeaveType === leaveType.idLeaveType && emp.idEmp === item.idEmp);

                employeeLeaveData && employeeLeaveData.forEach((leave) => {
                    totalUsedValue += leave.used;
                });

                acc[key].Num_Leave.push({
                    idLeaveType: leaveType.idLeaveType,
                    num_leave: totalUsedValue
                });
            });
        });

        return acc;
    }, {});

    Object.values(groupedData).forEach(group => {
        const summary = summaries[group.employeeId];
        const row = [
            group.employeeId,
            group.firstname,
            group.lastname,
            group.positionsName,
            group.companyName,
            group.divisionName,
            group.departmentName,
            group.sectionName,
            group.startDate,
            group.endDate,
            summary ? summary.workingDays : "-",
            summary && summary.percentWorkingDays > 0 ? `${summary.percentWorkingDays.toFixed(2)}%` : "-",
            summary ? summary.workingHours : "-",
            summary ? summary.totalLeaveHours : "-",
            summary ? summary.otHourstotal : "-",
            ...dateRange.map(dateObj => {
                const checkTimeEntry = group.checkTime.find(check => check.date === dateObj.date);
                return checkTimeEntry ? checkTimeEntry.workingStatus : "-";
            }),
            summary ? summary.absent : "-",
            summary ? summary.late : "-",
            summary ? summary.early : "-",
            ...leaveData.map(leave => {
                const checkLeave = group.Num_Leave.find(check => check.idLeaveType === leave.idLeaveType);
                return checkLeave ? checkLeave.num_leave : 0;
            }),
        ];

        const excelRow = worksheet1.addRow(row);
        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        colWidths.forEach((col, index) => {
            worksheet1.getColumn(index + 1).width = col.width;
        });

        excelRow.eachCell((cell, cellNumber) => {
            if (cellNumber > 14 && cellNumber <= (dateRange.length + 15)) {
                if (cell.value == "ลงเวลาสมบูรณ์") {
                    cell.style = headerStyleGreen;
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };

                } else if (cell.value == "ลงเวลาไม่สมบูรณ์") {
                    cell.style = headerStyleRed;
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                } else {
                    cell.style = contentStyle;
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                }
            } else {
                cell.style = contentStyle;
                cell.border = {
                    top: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                };
            }
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `รายงานเวลาทำงาน.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}