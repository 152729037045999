import React, { useEffect, useState, useRef, Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import {
  Grid,
  TextField,
  FormControl,
  OutlinedInput,
  FormControlLabel,
  Switch,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  Paper,
  Typography,
  IconButton,
  Chip,
  FormLabel,
  RadioGroup,
  Radio,
  Divider,
  Checkbox,
  ListItemText,
  Box,
} from "@mui/material";
import Slider, { SliderThumb } from "@mui/material/Slider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DoDisturbOnOutlinedIcon from "@mui/icons-material/DoDisturbOnOutlined";

import city from "../../../assets/data/city";
import province from "../../../assets/data/province";
import jobGroup from "../../../assets/data/jobGroup";
import vendor from "../../../assets/data/vendor";
import { getDepartment } from "../../../../../actions/department";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    marginTop: 50,
  },
  wrapHeader: {
    marginTop: 16,
  },
  divider: {
    margin: "10px 0",
  },
  imageProfile: {
    marginBottom: 40,
  },
  wrapCaptionImage: {
    marginTop: 16,
    textAlign: "center",
  },
  captionImage: {
    color: "#212b36",
  },
  wrapSwitchActive: {
    flexDirection: "row-reverse",
    margin: 0,
    width: "100%",
    "& .MuiFormControlLabel-label": {
      marginRight: "auto",
    },
  },
  labelSwitchActive: {
    fontSize: 14,
  },
  row: {
    marginBottom: 16,
  },
  textField: {
    width: "100%",
  },
  buttonSave: {
    marginLeft: "auto",
  },
  uploadImage: {
    width: "105%",
    zIndex: 8,
  },
  placeholder: {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(244, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  placeholderImageProfile: {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  placeholderLabel: {
    color: "rgb(255, 255, 255)",
  },
}));

const StyledAmount = styled(Paper)({
  height: 54,
  borderRadius: 8,
  borderColor: "#0000003b",
  "& div": {
    padding: "7.5px 4px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const StyledDepartment = styled(FormControl)({
  width: "100%",
  "& .MuiSelect-select": {
    padding: "12.5px 14px",
  },
  "& .MuiInputLabel-root": {
    top: -3,
  },
});

const StyledRoot = styled("div")({
  "& .MuiInputLabel-root": {
    top: -3,
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
    },
  },
});

const StyledChip = styled(Chip)(({ checked }) => ({
  fontWeight: 700,
  borderRadius: 6,
  ...(checked === true && {
    color: "#b72136",
    backgroundColor: "#ff484229",
  }),
}));

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};

const StepOne = (props) => {
  const {
    handleChange,
    dataEmployee,
    handleChangeCheck,
    handleChangeDateAppointment,
    handleChangeAmount,
    handleChangeVendorAcceptList,
    handleChangeVendorAccept,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: departmentList } = useSelector((state) => state.department);
  const [expertise, setExpertise] = useState([
    { id: 1, name: "Business & Operations" },
    { id: 2, name: "Design" },
    { id: 3, name: "DevOps & IT" },
    { id: 4, name: "Marketing" },
    { id: 5, name: "Product Management" },
    { id: 6, name: "Quality Assurance" },
    { id: 7, name: "Sales" },
    { id: 8, name: "Software Engineering" },
    { id: 9, name: "Data Science and Analytics" },
  ]);
  const [skill, setSkill] = useState([
    { id: 1, name: "Skill" },
    { id: 2, name: "Highly Skill" },
  ]);
  useEffect(() => {
    dispatch(getDepartment());
  }, []);

  return (
    <StyledRoot>
      <div className={classes.row}>
        <FormControlLabel
          style={{ margin: 0, marginBottom: 16 }}
          control={
            <Switch
              checked={dataEmployee.urgent}
              onChange={handleChangeCheck}
              name="urgent"
              color="primary"
            />
          }
          label={
            <StyledChip
              checked={dataEmployee.urgent}
              label="Urgent"
              size="small"
            />
          }
          labelPlacement="end"
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="positionName"
              name="positionName"
              label="เปิดรับสมัครตำแหน่ง"
              variant="outlined"
              onChange={handleChange}
              className={clsx(classes.textField)}
              value={dataEmployee.positionName}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <StyledDepartment variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                กลุ่มงาน
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={dataEmployee.idJobGroup}
                onChange={handleChange}
                label="กลุ่มงาน"
                name="idJobGroup"
                MenuProps={{
                  PaperProps: {
                    style: {
                      height: 300,
                    },
                  },
                }}
              >
                {jobGroup &&
                  jobGroup.map((jobGroup) => (
                    <MenuItem
                      key={jobGroup.idJobGroup}
                      value={jobGroup.idJobGroup}
                    >
                      {jobGroup.name}
                    </MenuItem>
                  ))}
              </Select>
            </StyledDepartment>
          </Grid>
          <Grid item xs={12} sm={5}>
            <StyledDepartment variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                หน่วยงาน
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={dataEmployee.idDepartment}
                onChange={handleChange}
                label="หน่วยงาน"
                name="idDepartment"
                MenuProps={{
                  PaperProps: {
                    style: {
                      height: 300,
                    },
                  },
                }}
              >
                {departmentList &&
                  departmentList.map((dep) => (
                    <MenuItem key={dep.id} value={dep.id}>
                      {dep.departmentName}
                    </MenuItem>
                  ))}
              </Select>
            </StyledDepartment>
          </Grid>
          <Grid item xs={12} sm={2}>
            <StyledDepartment variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                ประสบการณ์
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={dataEmployee.idExperience}
                onChange={handleChange}
                label="ประสบการณ์"
                name="idExperience"
              >
                <MenuItem value="1">1 ปี</MenuItem>
                <MenuItem value="2">2 ปี</MenuItem>
                <MenuItem value="3">3 ปี</MenuItem>
                <MenuItem value="4">4 ปี</MenuItem>
                <MenuItem value="5">5 ปี</MenuItem>
                <MenuItem value="6">6 ปี</MenuItem>
                <MenuItem value="7">7 ปี</MenuItem>
                <MenuItem value="8">8 ปี</MenuItem>
                <MenuItem value="9">ตั้งแต่ 9 ปีขึ้นไป</MenuItem>
              </Select>
            </StyledDepartment>
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledDepartment variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                จังหวัด
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={dataEmployee.idProvince}
                onChange={handleChange}
                label="จังหวัด"
                name="idProvince"
                MenuProps={{
                  PaperProps: {
                    style: {
                      height: 300,
                    },
                  },
                }}
              >
                {province &&
                  province.map((province) => (
                    <MenuItem
                      key={province.idProvince}
                      value={province.idProvince}
                    >
                      {province.name_th}
                    </MenuItem>
                  ))}
              </Select>
            </StyledDepartment>
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledDepartment variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                เขต/อำเภอ
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={dataEmployee.idCity}
                onChange={handleChange}
                label="เขต/อำเภอ"
                name="idCity"
                MenuProps={{
                  PaperProps: {
                    style: {
                      height: 200,
                    },
                  },
                }}
              >
                {city &&
                  city
                    .filter((city) => {
                      return city.province_id === dataEmployee.idProvince;
                    })
                    .map((city) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name_th}
                      </MenuItem>
                    ))}
              </Select>
            </StyledDepartment>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="workingLocation"
              name="workingLocation"
              label="สถานที่ทำงาน"
              variant="outlined"
              onChange={handleChange}
              className={clsx(classes.textField)}
              value={dataEmployee.workingLocation}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-multiline-static"
              label="รายละเอียด"
              multiline
              rows={4}
              className={clsx(classes.textField)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" className={clsx(classes.textField)}>
              <InputLabel id="demo-simple-select-outlined-label">
                ประเภทพนักงาน
              </InputLabel>
              <Select
                labelId="site"
                id="site"
                label="ประเภทพนักงาน"
                name="typeEmployee"
                onChange={handleChange}
                className={clsx(classes.textField)}
                value={dataEmployee.typeEmployee}
              >
                <MenuItem value={0}>Full Time</MenuItem>
                <MenuItem value={1}>Part Time</MenuItem>
                <MenuItem value={2}>Permanent</MenuItem>
                <MenuItem value={3}>Contract</MenuItem>
                <MenuItem value={4}>Internship</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="เลือกวันที่ปิดรับสมัคร"
                inputFormat="dd/MM/yyyy"
                value={dataEmployee.dateAppointment}
                onChange={(newValue) => {
                  handleChangeDateAppointment(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" className={clsx(classes.textField)}>
              <InputLabel id="demo-simple-select-outlined-label">
                การจ่ายค่าจ้าง
              </InputLabel>
              <Select
                labelId="typePayment"
                id="typePayment"
                label="การจ่ายค่าจ้าง"
                name="typePayment"
                onChange={handleChange}
                className={clsx(classes.textField)}
                value={dataEmployee.typePayment}
              >
                <MenuItem value={0}>รายวัน</MenuItem>
                <MenuItem value={1}>รายเดือน</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" className={clsx(classes.textField)}>
              <InputLabel id="demo-simple-select-outlined-label">
                เงินเดือน
              </InputLabel>
              <OutlinedInput
                className={clsx(classes.textField)}
                id="outlined-adornment-weight"
                label="เงินเดือน"
                name="salary"
                value={dataEmployee.salary}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    {dataEmployee.typePayment === 0
                      ? "บาท ต่อวัน"
                      : dataEmployee.typePayment === 1
                      ? "บาท ต่อเดือน"
                      : ""}
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledAmount variant="outlined">
              <div>
                <IconButton
                  aria-label="minus"
                  disabled={dataEmployee.amount === 0}
                  onClick={() => handleChangeAmount(dataEmployee.amount - 1)}
                >
                  <i class="fal fa-minus-circle"></i>
                </IconButton>
                <Typography>
                  {dataEmployee.amount === 0 ? (
                    <span style={{ color: "#999999" }}>
                      จำนวนที่เปิดรับสมัคร
                    </span>
                  ) : (
                    dataEmployee.amount
                  )}
                </Typography>
                <IconButton
                  aria-label="plus"
                  onClick={() => handleChangeAmount(dataEmployee.amount + 1)}
                >
                  <i class="fal fa-plus-circle"></i>
                </IconButton>
              </div>
            </StyledAmount>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Divider style={{ margin: "16px 0" }} />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 8 }}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Vendor
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="vendorAccept"
                value={dataEmployee.vendorAccept}
                onChange={handleChangeVendorAccept}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio />}
                  label="All Vendor"
                />
                <FormControlLabel
                  value="contract"
                  control={<Radio />}
                  label="Vendor Contract"
                />
                <FormControlLabel
                  value="customize"
                  control={<Radio />}
                  label="Customize"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 8 }}>
            <StyledDepartment variant="outlined">
              <InputLabel id="demo-multiple-chip-label">List</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                name="vendorAcceptList"
                label="List"
                value={dataEmployee.vendorAcceptList}
                onChange={handleChangeVendorAcceptList}
                disabled={dataEmployee.vendorAccept !== "customize"}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value.idVendor} label={value.name} />
                    ))}
                  </Box>
                )}
              >
                {vendor.map((vendor) => (
                  <MenuItem key={vendor.idVendor} value={vendor}>
                    <Checkbox
                      checked={
                        dataEmployee.vendorAcceptList.findIndex(
                          (element) => element.idVendor === vendor.idVendor
                        ) > -1
                      }
                    />
                    <ListItemText primary={vendor.name} />
                  </MenuItem>
                ))}
              </Select>
            </StyledDepartment>
          </Grid>
        </Grid>
      </div>
    </StyledRoot>
  );
};
export default StepOne;
