import React from "react";
import { Grid, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";

import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { getCompanyProfile, putCompanyAddress } from "../../../../actions/company";

const StyledRoot = styled("div")({
  padding: 24,
})

const DrawerEditAddress = (props) => {

  const dispatch = useDispatch();
  const { open, handleClose, data } = props;

  const { control, handleSubmit } = useForm({
    defaultValues: {
      address: data.companyAddress.address || "",
      district: data.companyAddress.district || "",
      province: data.companyAddress.province || "",
      areaCode: data.companyAddress.areaCode || "",
    }
  })

  const onSubmit = async (formData) => {
    const response = await dispatch(putCompanyAddress(1, data.idCompanyAddress, formData));
    if (response.status === 200){
      dispatch(getCompanyProfile(1));
      handleClose();
    }
  }

  return (
    <DrawerCustom
      title="Bank"
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{style: {maxWidth: 550, width: "100%"}}}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="address"
                render={({field}) => <TextFieldTheme {...field} label="Address" multiline rows={3}/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="district"
                render={({field}) => <TextFieldTheme {...field} label="District"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="province"
                render={({field}) => <TextFieldTheme {...field} label="Province"/>}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="areaCode"
                render={({field}) => <TextFieldTheme {...field} label="ZIP Code"/>}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="flex-end">
              <ButtonBlue style={{marginRight: 8}} onClick={handleClose}>
                ยกเลิก
              </ButtonBlue>
              <ButtonBlue variant="contained" type="submit">
                บันทึกข้อมูล
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEditAddress;