import React from "react";
import { Grid, InputAdornment, MenuItem, Typography } from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import FormContainer from "./FormContainer";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const PayrollInfo = (props) => {
  const { formik } = props;

  const { result: empDataForm } = useSelector((state) => state.empDataForm);

  const { t, i18n } = useTranslation();

  return (
    <FormContainer title={`${t("PayrollInfo")}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography>{t("Salary")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          {/* <TextFieldTheme
            name="salary"
            value={formik.values.salary}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.salary && Boolean(formik.errors.salary)}
            helperText={
              formik.touched.salary &&
              Boolean(formik.errors.salary) &&
              formik.errors.salary
            }
            fullWidth
          /> */}
          <NumberFormat
            customInput={TextFieldTheme}
            name="salary"
            value={formik.values.salary}
            // onChange={formik.handleChange}
            thousandSeparator=","
            allowNegative={false}
            onValueChange={(values) => {
              formik.setFieldValue("salary", values.value);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.salary && Boolean(formik.errors.salary)}
            helperText={
              formik.touched.salary &&
              Boolean(formik.errors.salary) &&
              formik.errors.salary
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>บริษัทที่เรียกเก็บเงิน</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="idCompanyCharge"
            value={formik.values.idCompanyCharge}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.idCompanyCharge &&
              Boolean(formik.errors.idCompanyCharge)
            }
            helperText={
              formik.touched.idCompanyCharge &&
              Boolean(formik.errors.idCompanyCharge) &&
              formik.errors.idCompanyCharge
            }
            fullWidth
            select
          >
            {empDataForm &&
              empDataForm.map((item) => (
              <MenuItem key={item.idCompany} value={item.idCompany}>
                {item.companyName}
              </MenuItem>
            ))}
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("ChargeSalary")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={`${t("Salary")}`}
                name="chargeSalary"
                value={formik.values.chargeSalary}
                onChange={formik.handleChange}
                fullWidth
                type="number"
                InputProps={{
                  inputProps: { min: 0, max: 100 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                inputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={`${t("OT")}`}
                name="chargeOt"
                value={formik.values.chargeOt}
                onChange={formik.handleChange}
                fullWidth
                type="number"
                InputProps={{
                  inputProps: { min: 0, max: 100 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                inputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={`${t("ChargeHire")}`}
                name="chargeHire"
                value={formik.values.chargeHire}
                onChange={formik.handleChange}
                fullWidth
                type="number"
                InputProps={{
                  inputProps: { min: 0, max: 100 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                inputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldTheme
                label={`${t("ChargeCompensation")}`}
                name="chargeCompensation"
                value={formik.values.chargeCompensation}
                onChange={formik.handleChange}
                fullWidth
                type="number"
                InputProps={{
                  inputProps: { min: 0, max: 100 },
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                inputProps={{
                  placeholder: "(ไม่บังคับ)"
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>Cost Center</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="costCenterCharge"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.costCenterCharge}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.costCenterCharge &&
              Boolean(formik.errors.costCenterCharge)
            }
            helperText={
              formik.touched.costCenterCharge &&
              Boolean(formik.errors.costCenterCharge) &&
              formik.errors.costCenterCharge
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>Cost Element</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="costElementCharge"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.costElementCharge}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.costElementCharge &&
              Boolean(formik.errors.costElementCharge)
            }
            helperText={
              formik.touched.costElementCharge &&
              Boolean(formik.errors.costElementCharge) &&
              formik.errors.costElementCharge
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("BookBank")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="bookBank"
            value={formik.values.bookBank}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bookBank && Boolean(formik.errors.bookBank)}
            helperText={
              formik.touched.bookBank &&
              Boolean(formik.errors.bookBank) &&
              formik.errors.bookBank
            }
            fullWidth
            select
          >
            <MenuItem value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</MenuItem>
            <MenuItem value="ธนาคารกรุงไทย">ธนาคารกรุงไทย</MenuItem>
            <MenuItem value="ธนาคารกรุงศรีอยุธยา">ธนาคารกรุงศรีอยุธยา</MenuItem>
            <MenuItem value="ธนาคารกสิกรไทย">ธนาคารกสิกรไทย</MenuItem>
            <MenuItem value="ธนาคารทหารไทยธนชาต">ธนาคารทหารไทยธนชาต</MenuItem>
            <MenuItem value="ธนาคารไทยพาณิชย์">ธนาคารไทยพาณิชย์</MenuItem>
            <MenuItem value="ธนาคารซีไอเอ็มบี ไทย">
              ธนาคารซีไอเอ็มบี ไทย
            </MenuItem>
            <MenuItem value="ธนาคารยูโอบี">ธนาคารยูโอบี</MenuItem>
          </TextFieldTheme>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("BookBankBranch")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="bookBankBranch"
            value={formik.values.bookBankBranch}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.bookBankBranch &&
              Boolean(formik.errors.bookBankBranch)
            }
            helperText={
              formik.touched.bookBankBranch &&
              Boolean(formik.errors.bookBankBranch) &&
              formik.errors.bookBankBranch
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("BookBankID")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <NumberFormat
            customInput={TextFieldTheme}
            name="bookBankId"
            value={formik.values.bookBankId}
            // onChange={formik.handleChange}
            allowLeadingZeros={true}
            allowNegative={false}
            onValueChange={(values) => {
              formik.setFieldValue("bookBankId", values.value);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.bookBankId && Boolean(formik.errors.bookBankId)
            }
            helperText={
              formik.touched.bookBankId &&
              Boolean(formik.errors.bookBankId) &&
              formik.errors.bookBankId
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("ReportBankName")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="reportBank_BankName"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.reportBank_BankName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.reportBank_BankName &&
              Boolean(formik.errors.reportBank_BankName)
            }
            helperText={
              formik.touched.reportBank_BankName &&
              Boolean(formik.errors.reportBank_BankName) &&
              formik.errors.reportBank_BankName
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("ReportBankID")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="reportBank_BankId"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.reportBank_BankId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.reportBank_BankId &&
              Boolean(formik.errors.reportBank_BankId)
            }
            helperText={
              formik.touched.reportBank_BankId &&
              Boolean(formik.errors.reportBank_BankId) &&
              formik.errors.reportBank_BankId
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("ReportBookBankID")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="reportBank_BookBankId"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.reportBank_BookBankId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.reportBank_BookBankId &&
              Boolean(formik.errors.reportBank_BookBankId)
            }
            helperText={
              formik.touched.reportBank_BookBankId &&
              Boolean(formik.errors.reportBank_BookBankId) &&
              formik.errors.reportBank_BookBankId
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("ReportBankPayneeName")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="reportBank_Name"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.reportBank_Name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.reportBank_Name &&
              Boolean(formik.errors.reportBank_Name)
            }
            helperText={
              formik.touched.reportBank_Name &&
              Boolean(formik.errors.reportBank_Name) &&
              formik.errors.reportBank_Name
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography>{t("ReportBankRef")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextFieldTheme
            name="reportBank_Ref"
            inputProps={{
              placeholder: "(ไม่บังคับ)"
            }}
            value={formik.values.reportBank_Ref}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.reportBank_Ref &&
              Boolean(formik.errors.reportBank_Ref)
            }
            helperText={
              formik.touched.reportBank_Ref &&
              Boolean(formik.errors.reportBank_Ref) &&
              formik.errors.reportBank_Ref
            }
            fullWidth
          />
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default PayrollInfo;
