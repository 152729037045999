import React from "react";
import CardStyle from "../general/Card";
import { Box, CardContent, Chip, IconButton, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { getDateFromDatetime, getRoundNumber, getTimeFromDatetime } from "../../../../utils";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import StatusRequestLeave from "./StatusRequestLeave";
import StatusRequestLeaveWithdraw from "./StatusRequestLeaveWithdraw";

const StyledChip = styled(Chip)({
  backgroundColor: "#ebf5ff",
  color: "#007aff",
  fontSize: 16,
  fontWeight: 500,
  wordWrap: "break-word",
  height: "unset",
  paddingTop: "4px",
  paddingBottom: "4px",
  "& .MuiChip-label": {
    whiteSpace: "pre-line",
    textAlign: "center",
  },
  "&.not-approve": {
    backgroundColor: "#EEEEEE",
    color: "#919eab",
  },
});

const StyledRoot = styled(Box)({
  "& .MuiTypography-root": {
    "&.not-approve": {
      color: "#919eab",
      fontStyle: "oblique",
    },
  }
})

const CardRequestLeave = (props) => {

  const {
    data,
    handleDeleteLeaveRequest = () => {
      console.log("Delete")
    },
    // idRole = 
  } = props;

  const { t, i18n } = useTranslation();

  const canWithdrawLeaveRequest = () => {
    if (data.isApprove !== 0) {
      if (data.withdraw && data.withdraw.isApprove !== 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const getClassNameStatus = () => {
    if (data.isApprove === 0 || (data.withdraw && data.withdraw.isApprove === 1)) {
      return "not-approve"
    } else {
      return ""
    }
  }

  return (
    <CardStyle
      sx={{
        height: "100%"
      }}
    >
      <CardContent style={{ padding: "16px" }}>
        <StyledRoot>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" gap="4px">
              {canWithdrawLeaveRequest() && <IconButton
                size="small"
                onClick={() => {
                  handleDeleteLeaveRequest(data)
                }}
              >
                <DeleteOutlineRoundedIcon fontSize="inherit" />
              </IconButton>}
            </Box>
            <Box display="flex" gap="4px">
              {data.withdraw ? (
                <StatusRequestLeaveWithdraw status={data.withdraw.isApprove} />
              ) : (
                <StatusRequestLeave status={data.isApprove} />
              )}
              {/* {(data.withdraw === null || (data.withdraw && data.withdraw.isApprove !== 1)) && <StatusRequestLeave status={data.isApprove} />}
              {data.withdraw && <StatusRequestLeaveWithdraw status={data.withdraw.isApprove} />} */}
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" padding="8px 0">
            <StyledChip
              className={getClassNameStatus()}
              label={`${data.leaveGroupName} ${getRoundNumber(data.used)} ${t("Days")}`}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="body2" color="text.secondary">
                {t("Start")}
              </Typography>
              <Typography className={getClassNameStatus()} variant="h6">{getDateFromDatetime(data.start)}</Typography>
              <Typography className={getClassNameStatus()} variant="h6" height="32px">{data.isFullDay ? "" : getTimeFromDatetime(data.start)}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Typography variant="body2" color="text.secondary">
                {t("End")}
              </Typography>
              <Typography className={getClassNameStatus()} variant="h6">{getDateFromDatetime(data.end)}</Typography>
              <Typography className={getClassNameStatus()} variant="h6" height="32px">{data.isFullDay ? "" : getTimeFromDatetime(data.end)}</Typography>
            </Box>
          </Box>
          {data.description && <Box paddingTop="8px">
            <Typography color="text.third" fontSize="14px" whiteSpace="pre-line" fontStyle="oblique">
              {`${t("Description")}: ${data.description}`}
            </Typography>
          </Box>}
        </StyledRoot>
      </CardContent>
    </CardStyle>
  )
}

export default CardRequestLeave;