import React, { useState, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { styled } from "@mui/material/styles";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import "../../styles.css";

import ButtonBlue from "../../../../../shared/general/ButtonBlue";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const mapStyles = {
  position: "relative",
  width: "100%",
  height: "350px",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const resetContainer = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "10px",
};

const StylePaper = styled(Paper)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  borderRadius: 8,
  marginBottom: 16,
  boxShadow:
    "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
});

const StyleWrapButton = styled("div")({
  marginTop: 8,
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const InitialValues = () => ({
  locationId: 0,
  locationName: "",
  location: [],
});

function EditMap(props) {
  const { selectedGroup, handleChangePositions } = props;
  const [search, setSearch] = useState("");
  //const [values, setValues] = useState(InitialValues);

  let map;
  var drawingManager;

  const onMapLoad = () => {
    const google = window.google;

    var bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < selectedGroup.positions.length; i++) {
      bounds.extend(selectedGroup.positions[i]);
    }

    var myOptions = {
      zoom: 14,
      center: { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    //Create Map
    map = new google.maps.Map(document.getElementById("editMap"), myOptions);
    onSearchBox();
    onDrawMap();
  };

  const onSearchBox = () => {
    const google = window.google;

    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input");
    const searchBox = new google.maps.places.SearchBox(input);

    let markers = [];

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  };

  const onDrawMap = () => {
    const google = window.google;
    var overlays = [];

    const locationPolygon = new google.maps.Polygon({
      paths: selectedGroup.positions,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });

    locationPolygon.setMap(map);

    var overlay = {
      overlay: locationPolygon,
      type: google.maps.drawing.OverlayType.POLYGON,
    };

    overlays.push(overlay);

    //Create Drawing Manager
    drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        editable: true,
      },
    });
    //drawingManager.setMap(map);

    google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      function (event) {
        var newShape = event.overlay;
        newShape.type = event.type;

        overlayClickListener(event.overlay);

        var locationArray = event.overlay.getPath().getArray();
        var latlngArray = [];

        for (var i = 0; i < locationArray.length; i++) {
          latlngArray.push({
            lat: locationArray[i].lat(),
            lng: locationArray[i].lng(),
            isActive: 1,
            idGroupGpsLocations: selectedGroup.idGroupGpsLocations,
            idGpsLocations: selectedGroup.idGpsLocations,
          });
        }
        overlays.push(event);
        handleChangePositions(latlngArray);
      }
    );

    drawingManager.setMap(map);
  };

  const overlayClickListener = (overlay) => {
    const google = window.google;

    google.maps.event.addListener(overlay, "mouseup", function (event) {
      var locationArray = overlay.getPath().getArray();
      var latlngArray = [];

      for (var i = 0; i < locationArray.length; i++) {
        latlngArray.push({
          id: i + 1,
          lat: locationArray[i].lat(),
          lng: locationArray[i].lng(),
        });
      }
      handleChangePositions(latlngArray);
    });
  };

  const handleOnReset = () => {
    initialize();
  };

  const handleClearInput = () => {
    document.getElementById("pac-input").value = "";
    setSearch("");
  };

  const loader = new Loader({
    //apiKey: "AIzaSyCZ41b0hPCVif4tewQUlrc2aivCyZf2DWc",
    apiKey: "AIzaSyBJEmVrMq5Zgj27dilri-OK76pn7pgQCho",
    version: "weekly",
    libraries: ["places", "drawing", "geometry"],
  });

  const initialize = () => {
    loader.load().then(() => {
      onMapLoad();
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <div>
      <StylePaper>
          <InputBase
            style={{ ml: 1, flex: 1 }}
            id="pac-input"
            class="controls"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="ค้นหาสถานที่"
            inputProps={{ "aria-label": "ค้นหาสถานที่" }}
          />
          <IconButton style={{ padding: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          {search.length > 0 ? (
            <>
              <Divider style={{ height: 28, margin: 0.5 }} orientation="vertical" />
              <IconButton
                style={{ padding: "10px" }}
                aria-label="directions"
                onClick={handleClearInput}
              >
                <ClearIcon />
              </IconButton>
            </>
          ) : null}
        </StylePaper>
      </div>
      <Box>
        <div id="editMap" style={mapStyles}>
          Loading ...
        </div>
      </Box>
      <StyleWrapButton>
        <ButtonBlue startIcon={<RestartAltIcon />} onClick={handleOnReset}>
          Reset
        </ButtonBlue>
      </StyleWrapButton>
    </>
  );
}

export default EditMap;
