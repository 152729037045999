import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  empInfoHead: {
    fontSize: "0.9rem !important",
    color: "#757575",
  },
  empInfoBody: {
    fontSize: "0.9rem !important",
  },
}));

function EmpSlipInfo(props) {
  const { payslipDetail } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  //const { result: payslipDetail } = useSelector((state) => state.payslipDetail);

  return (
    <div style={{ marginBottom: 16 }}>
      <Box>
        <Grid container>
          <Grid container item xs={9} md={9} lg={9} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className={classes.empInfoHead}>
                {t("FullName")} :
              </Typography>
            </Grid>
            <Grid item sx xs={8} md={8} lg={8}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography className={classes.empInfoBody}>
                    {payslipDetail.title +
                      " " +
                      payslipDetail.firstname +
                      " " +
                      payslipDetail.lastname}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={3} md={3} lg={3} spacing={2}>
            <Grid item xs>
              <Typography className={classes.empInfoHead}>
                {t("EmployeeID")} :
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.empInfoBody}>
                {payslipDetail.employeeId}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid container item xs={9} md={9} lg={9} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className={classes.empInfoHead}>
                {t("Company")} :
              </Typography>
            </Grid>
            <Grid item sx xs={8} md={8} lg={8}>
              <Typography className={classes.empInfoBody}>
                {payslipDetail.vendorName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={3} md={3} lg={3} spacing={2}>
            <Grid item xs>
              <Typography className={classes.empInfoHead}>
                วันที่เริ่มต้น :
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.empInfoBody}>
                {payslipDetail.start
                  ? dayjs(payslipDetail.start).format("DD/MM/BBBB")
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid container item xs={9} md={9} lg={9} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className={classes.empInfoHead}>
                {t("Department")} :
              </Typography>
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <Typography className={classes.empInfoBody}>
                {payslipDetail.departmentName
                  ? payslipDetail.departmentName
                  : payslipDetail.sectionName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={3} md={3} lg={3} spacing={2}>
            <Grid item xs>
              <Typography className={classes.empInfoHead}>
                วันที่สิ้นสุด :
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.empInfoBody}>
                {payslipDetail.end
                  ? dayjs(payslipDetail.end).format("DD/MM/BBBB")
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          <Grid container item xs={9} md={9} lg={9} spacing={2}>
            <Grid item xs={3} md={3} lg={3}>
              <Typography className={classes.empInfoHead}>
                {t("BookBankID")} :
              </Typography>
            </Grid>
            <Grid item sx xs={8} md={8} lg={8}>
              <Typography className={classes.empInfoBody}>
                {payslipDetail.bookBankId}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={3} md={3} lg={3} spacing={2}>
            <Grid item xs>
              <Typography className={classes.empInfoHead}>
                {t("PayDate")} :
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.empInfoBody}>
                {dayjs(payslipDetail.payDate).format("DD/MM/BBBB")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default EmpSlipInfo;
