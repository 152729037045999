import React, { useState, useEffect, Fragment } from "react";
import { useTitle } from "react-use";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import DataTable from "./components/DataTable";
import DialogEdit from "./components/DialogEdit";
import DialogEditManager from "./components/DialogEditManager";

import { IconButton, Grid, Divider, Typography } from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import CardStyle from "../../../shared/general/Card";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";

import {
  getManagerByidCompany,
  getManagerSettingByidCompany,
} from "../../../../../actions/company";
import utils from "../../../../../utils";
import ManagerGroupContainer from "./components/ManagerGroupContainer";
import DrawerManagerGroup from "./components/DrawerManagerGroup";
import { getManagerGroup } from "../../../../../actions/manager";
import DialogConfirmDelete from "../../../shared/general/DialogConfirmDelete";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
  "&.approve": {
    marginTop: 40,
  },
});

const StyledApprove = styled("div")({
  "& .list": {
    marginBottom: 32,
  },
  "& .header": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .text": {
      fontSize: 16,
    },
    "& .icon": {
      color: "#637381",
      fontSize: 16,
      marginRight: 8,
    },
  },
  "& .topic": {
    "& .wrap-text": {
      display: "flex",
      justifyContent: "flex-start",
      marginLeft: 24,
      "& .text": {
        marginLeft: 8,
        fontSize: 20,
      },
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  // marginTop: 16,
  // marginBottom: 16,
  margin: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
});

function Manager(props) {
  const { company } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: managerSettingList } = useSelector(
    (state) => state.managerSetting
  );

  const [open, setOpen] = useState(false);
  const [openManager, setOpenManager] = useState(false);
  const [managerEdit, setManagerEdit] = useState(null);
  const [topicEdit, setTopicEdit] = useState(null);

  const [drawerGroupManagerConfig, setDrawerGroupManagerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const onDrawerGroupManagerClose = () => {
    setDrawerGroupManagerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  useEffect(() => {
    if (company) {
      dispatch(getManagerByidCompany(company.idCompany));
      dispatch(getManagerSettingByidCompany(company.idCompany));
      dispatch(getManagerGroup(company.idCompany));
    }
  }, [company]);

  const handleOpenDialog = (topic) => {
    setOpen(true);
    setTopicEdit(topic);
  };
  const handleCloseDialog = () => {
    setOpen(false);
    setOpenManager(false);
  };

  const renderTopic = (topicList) => {
    return topicList.map((item) => (
      <Fragment>
        <Grid item xs={12} sm={4}>
          <div className="wrap-text">
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => handleOpenDialog(item)}
            >
              <EditRoundedIcon fontSize="inherit" />
            </IconButton>
            <Typography className="text">{item.topic}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledHeadLabel color="text.secondary">
            {`${t("ManagerLevel")} 1`}
          </StyledHeadLabel>
          <Typography style={{ lineHeight: 1 }} variant="h6">
            {item.managerLV1 ? (
              item.managerLV1 === "1" ? (
                `${t("Approve")}`
              ) : (
                `${t("Examine")}`
              )
            ) : (
              <Typography
                component="span"
                color="text.secondary"
                style={{ fontStyle: "oblique" }}
              >
                {t("NotSpecified")}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StyledHeadLabel color="text.secondary">
            {`${t("ManagerLevel")} 2`}
          </StyledHeadLabel>
          <Typography style={{ lineHeight: 1 }} variant="h6">
            {item.managerLV2 ? (
              item.managerLV2 === "1" ? (
                `${t("Approve")}`
              ) : (
                `${t("Examine")}`
              )
            ) : (
              <Typography
                component="span"
                color="text.secondary"
                style={{ fontStyle: "oblique" }}
              >
                {t("NotSpecified")}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledDivider />
          </div>
        </Grid>
      </Fragment>
    ));
  };

  const renderManagerSetting = () => {
    const grouped = utils.groupBy(managerSettingList, (item) => item.header);
    let keys = [...grouped.keys()];
    return keys.map((element) => (
      <div key={element} className="list">
        <div className="header">
          <KeyboardDoubleArrowRightRoundedIcon className="icon" />
          <Typography variant="h6" className="text" color="text.third">
            {element}
          </Typography>
        </div>
        <div className="topic">
          <Grid container alignItems="center">
            {renderTopic(grouped.get(element))}
          </Grid>
        </div>
      </div>
    ));
  };

  const handleChangeManagerEdit = (newValue) => {
    setManagerEdit(newValue);
  };

  return (
    <div className={classes.root}>
      {company && company.idCompany ? (
        <Fragment>
          <StyledHeadingPage>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">{t("ManagerList")}</Typography>
              </Grid>
            </Grid>
          </StyledHeadingPage>
          <CardStyle style={{ padding: 16 }}>
            <DataTable
              handleChangeManagerEdit={handleChangeManagerEdit}
              company={company}
            />
          </CardStyle>
          <StyledHeadingPage className="approve">
            <Grid container justifyContent="space-between">
              <Typography variant="h6">กลุ่มหัวหน้างาน</Typography>
              <ButtonBlue
                variant="contained"
                startIcon={<PlaylistAddRoundedIcon />}
                onClick={() => {
                  setDrawerGroupManagerConfig((prev) => ({
                    ...prev,
                    isOpen: true,
                    isEdit: false,
                  }));
                }}
              >
                เพิ่มกลุ่มหัวหน้างาน
              </ButtonBlue>
            </Grid>
          </StyledHeadingPage>
          <CardStyle style={{ padding: 16 }}>
            <ManagerGroupContainer
              company={company}
              setDrawerGroupManagerConfig={setDrawerGroupManagerConfig}
            />
          </CardStyle>
          <StyledHeadingPage className="approve">
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">{t("OperatingPower")}</Typography>
              </Grid>
            </Grid>
          </StyledHeadingPage>
          <CardStyle style={{ padding: 16 }}>
            <StyledApprove>
              {managerSettingList && renderManagerSetting()}
            </StyledApprove>
          </CardStyle>
          {topicEdit && (
            <DialogEdit
              topicEdit={topicEdit}
              open={open}
              handleCloseDialog={handleCloseDialog}
            />
          )}
          {managerEdit && (
            <DialogEditManager
              managerEdit={managerEdit}
              open={openManager}
              handleCloseDialog={handleCloseDialog}
            />
          )}
          <DrawerManagerGroup
            company={company}
            drawerConfig={drawerGroupManagerConfig}
            onClose={onDrawerGroupManagerClose}
          />
        </Fragment>
      ) : (
        <Typography fontSize="20px" color="text.secondary" textAlign="center">
          กรุณาเลือกบริษัท
        </Typography>
      )}
    </div>
  );
}

export default Manager;
