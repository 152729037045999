import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";

import {
  Typography,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: "16px 0px",
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const { open, setOpenEdit } = props;
  const dispatch = useDispatch();
  const { result: billingDetail } = useSelector((state) => state.billingDetail);
  const [departmentNotAccept, setDepartmentNotAccept] = useState(null);

  useEffect(() => {
    if (billingDetail) {
      var detailPayrun = [...billingDetail.detail];

      const tempList = [];

      for (let index = 0; index < detailPayrun.length; index++) {
        const element = detailPayrun[index];

        if (!element.isCheck) {
          const found = tempList.find(
            (item) => item.text === element.departmentName
          );
          if (!found) {
            tempList.push({
              name: "dep-" + tempList.length,
              text: element.departmentName,
              value: false,
            });
          }
        }
      }

      setDepartmentNotAccept(tempList);
    }
  }, [billingDetail]);

  const onSubmitHandler = async () => {
    setOpenEdit(false)
  };

  const handleChange = (event) => {
    var temp = [...departmentNotAccept];
    for (let index = 0; index < temp.length; index++) {
      const element = temp[index];
      if (event.target.name === element.name) {
        element.value = !element.value;
      }
    }
    setDepartmentNotAccept(temp);
  };

  return (
    <DrawerCustom
      title={"บันทึกรายการตรวจบิล"}
      anchor={"right"}
      open={open}
      onClose={() => setOpenEdit(false)}
    >
      <StyledRoot>
        <Typography>เลือกหน่วยงานที่ตรวจสอบแล้ว</Typography>
        <FormControl component="fieldset" variant="standard">
          <FormGroup>
            {departmentNotAccept &&
              departmentNotAccept.map((item, index) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.value}
                      onChange={handleChange}
                      name={item.name}
                    />
                  }
                  label={item.text}
                />
              ))}
          </FormGroup>
        </FormControl>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={() => setOpenEdit(false)}>
            ยกเลิก
          </ButtonBlue>
          <ButtonBlue
            variant="contained"
            onClick={() => onSubmitHandler()}
            autoFocus
          >
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
