import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  InputAdornment,
  Box,
  Typography,
  FormControl,
  FilledInput,
  Checkbox,
  Popper,
  TextField,
  Grid,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import SearchIcon from "@mui/icons-material/Search";

import StyledCard from "../../shared/general/Card";

import TableCompany from "./tableCompany";
import TableVendor from "./tableVendor";

import { useTranslation } from "react-i18next";

const StyledContent = styled("div")({
  padding: 24,
  "& .wrap-filter": {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    "& .label": {
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 8,
    },
  },
  "& .select-company": {
    display: "flex",
    justifyContent: "center",
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-inputRoot.MuiAutocomplete-input": {
      minWidth: 0,
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const icon = <i class="fa-regular fa-square" style={{ fontSize: 24 }}></i>;
const checkedIcon = (
  <i class="fa-regular fa-square-check" style={{ fontSize: 24 }}></i>
);

const status = ["ใช้งานปกติ", "ยกเลิกใช้งาน"];

const EmployeeList = (props) => {
  const { customer } = props;
  const { t, i18n } = useTranslation();
  const { result: companyList } = useSelector((state) => state.companyAntTeam);
  const { result: vendorList } = useSelector((state) => state.vendorAntTeam);
  const [search, setSearch] = useState(null);

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <div>
      <StyledCard>
        <StyledContent>
          <Box>
            <Typography variant="h4" align="right">
              {customer === "company"
                ? companyList
                  ? companyList.length
                  : 0
                : vendorList
                ? vendorList.length
                : 0}
            </Typography>
            <Typography
              variant="body2"
              align="right"
              color="text.secondary"
              style={{ fontWeight: 500 }}
            >
              จำนวนทั้งหมด
            </Typography>
          </Box>
          <Box className="wrap-filter">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box>
                  <Typography className="label" color="text.third">
                    ค้นหาชื่อบริษัท
                  </Typography>
                  <FormControl fullWidth variant="filled">
                    <StyledFilledInput
                      id="filled-sarch-name"
                      placeholder={`ค้นหาชื่อบริษัท`}
                      name="search"
                      onChange={handleChange}
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Typography className="label" color="text.third">
                    สถานะ
                  </Typography>
                  <StyledAutocomplete
                    multiple
                    id="combo-box-level"
                    disableCloseOnSelect
                    options={status}
                    onChange={(event, newValue) => {
                      handleChange(event, newValue, "level");
                    }}
                    popupIcon={<i class="fa-light fa-chevron-down"></i>}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        placeholder={""}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        if (index === 0) {
                          return <Typography>{option}</Typography>;
                        } else {
                          return <Typography>, {option}</Typography>;
                        }
                      })
                    }
                    PopperComponent={StyledPopper}
                    noOptionsText={"ไม่พบข้อมูล"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          {customer === "vendor" ? (
            <TableVendor search={search} />
          ) : (
            <TableCompany search={search} />
          )}
        </StyledContent>
      </StyledCard>
    </div>
  );
};

export default EmployeeList;
