import {
  PAYTYPES_FETCHING,
  PAYTYPES_FAILED,
  PAYTYPES_SUCCESS,
  ADDITIONS_FETCHING,
  ADDITIONS_FAILED,
  ADDITIONS_SUCCESS,
  PAYROLL_SETTING_FETCHING,
  PAYROLL_SETTING_FAILED,
  PAYROLL_SETTING_SUCCESS,
} from "./types";

import PaytypesService from "../services/paytypes.service";

export const allPayTypes = () => async (dispatch) => {
  try {
    dispatch({
      type: PAYTYPES_FETCHING,
    });
    const res = await PaytypesService.allPayTypes();
    if (res) {
      dispatch({
        type: PAYTYPES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PAYTYPES_FAILED,
    });
    console.log(err);
  }
};

export const getAllAddition = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITIONS_FETCHING,
    });
    const res = await PaytypesService.getAllAddition(idCompany);
    if (res) {
      dispatch({
        type: ADDITIONS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_FAILED,
    });

    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return message;
  }
};

export const addPaytypes = (formData) => async (dispatch) => {
  try {
    const res = await PaytypesService.addPaytypes(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updatePaytypes = (formData) => async (dispatch) => {
  try {
    const res = await PaytypesService.updatePaytypes(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updatePayrollSetting = (idPayrollSetting, formData) => async (dispatch) => {
  try {
    const res = await PaytypesService.updatePayrollSetting(idPayrollSetting, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getPayrollSetting = () => async (dispatch) => {
  try {
    dispatch({
      type: PAYROLL_SETTING_FETCHING,
    });
    const res = await PaytypesService.getPayrollSetting();
    if (res) {
      dispatch({
        type: PAYROLL_SETTING_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: PAYROLL_SETTING_FAILED,
    });

    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return message;
  }
};
