import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import StyledCard from "../../../shared/general/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import PersonRemoveAlt1OutlinedIcon from "@mui/icons-material/PersonRemoveAlt1Outlined";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";

const StyledPaper = styled(Paper)({
  padding: 16,
  paddingLeft: 36,
  display: "flex",
  position: "relative",
});

const StyledBox = styled(Box)({
  width: 250,
  margin: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledAvatar = styled(Avatar)({
  width: 60,
  height: 60,
  "&.out": {
    opacity: 0.3,
  },
});

const StyledWrapperEmployees = styled("div")({
  margin: "auto",
  textAlign: "center",
  position: "relative",
  width: "100%",
  paddingTop: 5,
});

const StyledScrollsEmployees = styled("div")({
  overflowX: "scroll",
  overflowY: "hidden",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  flexWrap: "wrap",
  height: 328,
  whiteSpace: "nowrap",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  display: "flex",
  justifyContent: "center",
  marginBottom: 32,
  "& .Mui-selected": {
    backgroundColor: "#007afe !important",
    color: "#FFFFFF !important",
  },
});

export default function CardInOutEmployees() {
  const [alignment, setAlignment] = React.useState("in");

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <StyledCard>
      <CardContent style={{ paddingBottom: 0 }}>
        <Box>
          <Typography variant="h4" className={`cardTitle`} gutterBottom>
            รายชื่อพนักงาน
          </Typography>
          <StyledToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="in" aria-label="left aligned">
              <PersonAddAlt1OutlinedIcon style={{ marginRight: 8 }} />
              <Typography style={{ color: "inherit" }}>เข้าใหม่</Typography>
            </ToggleButton>
            <ToggleButton value="out" aria-label="centered">
              <PersonRemoveAlt1OutlinedIcon style={{ marginRight: 8 }} />
              <Typography style={{ color: "inherit" }}>ลาออก</Typography>
            </ToggleButton>
          </StyledToggleButtonGroup>
          <StyledWrapperEmployees>
            <StyledScrollsEmployees>
              {[...Array(8).keys()].map((value,index) => {
                return (
                  <StyledBox key={"User"+index}>
                    <StyledAvatar
                      alt={"username"}
                      src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                      className={`${alignment}`}
                    />
                    <div
                      style={{
                        width: "100%",
                        opacity: alignment === "out" ? 0.4 : 1,
                      }}
                    >
                      <Typography align="center" variant="h6">
                        Lauryn Zimmerman
                      </Typography>
                      <Typography align="center" variant="body1">
                        Department
                      </Typography>
                      <Typography
                        align="center"
                        variant="body1"
                        style={{ color: "#999999", fontStyle: "oblique" }}
                      >
                        12 ตุลาคม 2564
                      </Typography>
                    </div>
                  </StyledBox>
                );
              })}
            </StyledScrollsEmployees>
          </StyledWrapperEmployees>
        </Box>
      </CardContent>
    </StyledCard>
  );
}
