import React from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import ButtonBlue from "../shared/general/ButtonBlue";
import TextFieldTheme from "../shared/general/TextFieldTheme";

const StyledTypography= styled(Typography)({
    marginBottom: 16
  });

const StyledWrapButtom = styled("div")({
  display: "flex",
  justifyContent: "center",
  marginTop: 24
});

const Register = (props) => {
  return (
    <div>
      <StyledTypography>ลงทะเบียน</StyledTypography>
      <TextFieldTheme
        label="เลขที่บัตรประชาชน"
        name="personalId"
        onChange={props.handleChange}
      />
      <StyledWrapButtom>
        <ButtonBlue variant="contained" onClick={props.handleClick}>ยืนยันลงทะเบียน</ButtonBlue>
      </StyledWrapButtom>
    </div>
  );
};

export default Register;
