import { httpClient } from "./httpClient";

const getAllServicesVendor = () => {
    return httpClient.get("vendor/services");
};

const updateServicesVendor = (data) => {
    return httpClient.put("vendor/services/update",data);
};

export default {
    getAllServicesVendor,
    updateServicesVendor
}