import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CardStyle from "../../shared/general/Card";
import {
  Rating,
  Typography,
  Divider,
  CardActionArea,
  Grid,
} from "@mui/material";
import styled from "@emotion/styled";

import PersonRoundedIcon from "@mui/icons-material/PersonRounded";

const StyledContent = styled("div")({
  padding: 16,
  "& .text-counter": {
    fontSize: 56,
  },
  "& .text-area, .text-section, .text-counter": {
    fontWeight: 500,
    color: "#FFFFFF",
  },
  "& .text-area": {
    fontSize: 22,
  },
  "& .employee-percent": {
    display: "flex",
    justifyContent: "center",
  },
  "& .wrap-vendor": {
    "& .vendor-list": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  "& .wrap-all": {
    "& .all": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const customColor = ["#d93352", "#f69a4e", "#fdc507", "#b2d62c", "#35bf76"];

const StyledDivider = styled(Divider)({
  marginTop: 4,
  marginBottom: 4,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledRating = styled(Rating)(({ color }) => ({
  ...(color === 1 && {
    "& .MuiRating-iconFilled": {
      color: "#d93352",
    },
  }),
  ...(color === 2 && {
    "& .MuiRating-iconFilled": {
      color: "#fa704e",
    },
  }),
  ...(color === 3 && {
    "& .MuiRating-iconFilled": {
      color: "#fdc507",
    },
  }),
  ...(color === 4 && {
    "& .MuiRating-iconFilled": {
      color: "#26a08a",
    },
  }),
  ...(color === 5 && {
    "& .MuiRating-iconFilled": {
      color: "#35bf76",
    },
  }),
}));

const CardCounter = (props) => {
  const { area, handleSelectArea } = props;
  const history = useHistory();
  return (
    <div style={{ minWidth: 300 }}>
      <CardStyle
        style={{
          backgroundColor: customColor[0],
        }}
      >
        <CardActionArea onClick={() => handleSelectArea(area)}>
          <StyledContent>
            <Typography className="text-section" align="center">
              {area.groupGpsLocationsName}
            </Typography>
            <Typography
              className={`text-counter text-counter-${5}`}
              variant="h3"
              align="center"
            >
              {area.headCount}
            </Typography>
            <Typography className="text-area" variant="h6" align="center">
              {`${area.sectionName}`}
            </Typography>
            <Typography className="text-section" align="center">
              {`(รวม ${area.amount} คน)`}
            </Typography>
          </StyledContent>
        </CardActionArea>
      </CardStyle>
    </div>
  );
};

export default CardCounter;
