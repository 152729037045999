import CompanyProfile from "../-_Company profile.png";
import StatusApprove from "../-_Document management.png";
import EmployeeCoreDate from "../-_Employee Core Data.png";
import SourcingRequest from "../-_Sourcing & Recruitment.png";
import Payroll from "../-_Payroll.png";
import TimeManagement from "../-_Time Management.png";
import Manpower from "../-_Cloud workforce .png";
import ShiftManage from "../-_Temporary Job.png";
import Welfare from "../-_Welfare.png";
import Performance from "../-_performance management.png";
import OT from "../-_overtime .png";
import Leave from "../-_leave.png";
import PaySlip from "../-_pay slip.png";
import Approve from "../-_approval list.png";
import Learning from "../-_Learning Management.png";
import MyDocument from "../-_Branding & Communication.png";
import Dashboard from "../-_dashboard.svg";

export const menuAppAdmin = [
  {
    id: 1,
    name: "มุมมองภาพรวม",
    icon: Performance,
    link: "/admin/overview",
    role: "ROLE_ADMIN",
  },
  {
    id: 2,
    name: "สถานะรายการ",
    icon: StatusApprove,
    link: "/admin/approve-list",
    role: "ROLE_ADMIN",
  },
  {
    id: 3,
    name: "พนักงาน",
    icon: EmployeeCoreDate,
    link: "/admin/employees",
    role: "ROLE_ADMIN",
  },
  {
    id: 4,
    name: "กำลังพล",
    icon: Manpower,
    link: "/admin/manpower",
    role: "ROLE_ADMIN",
  },
  {
    id: 5,
    name: "เวลาทำงาน",
    icon: TimeManagement,
    link: "/admin/time",
    role: "ROLE_ADMIN",
  },
  {
    id: 6,
    name: "สรุป OT และค่ากะ",
    icon: TimeManagement,
    link: "/admin/summary-time",
    role: "ROLE_ADMIN",
  },
  {
    id: 7,
    name: "ตรวจสอบค่าล่วงเวลา",
    icon: TimeManagement,
    link: "/admin/otmonitoring",
    role: "ROLE_ADMIN",
  },
  {
    id: 8,
    name: "เข้าพื้นที่",
    icon: TimeManagement,
    link: "/admin/access-area",
    role: "ROLE_ADMIN",
  },
  {
    id: 9,
    name: "จัดการกะ",
    icon: ShiftManage,
    link: "/admin/shift",
    role: "ROLE_ADMIN",
  },
  {
    id: 10,
    name: "ตรวจบิล",
    icon: Payroll,
    link: "/admin/billing",
    role: "ROLE_ADMIN",
  },
  {
    id: 11,
    name: "การจ้างงาน",
    icon: SourcingRequest,
    link: "/admin/request",
    role: "ROLE_ADMIN",
  },
  {
    id: 12,
    name: "อบรม",
    icon: SourcingRequest,
    link: "/admin/courses",
    role: "ROLE_ADMIN",
  },
  {
    id: 13,
    name: "ประวัติบริษัท",
    icon: CompanyProfile,
    link: "/admin/company-profile",
    role: "ROLE_ADMIN",
  },
  {
    id: 14,
    name: "ข้อมูลระบบ",
    icon: Welfare,
    link: "/admin/setting",
    role: "ROLE_ADMIN",
  },
  {
    id: 15,
    name: "รายงานจ้างสัญญา",
    icon: Dashboard,
    link: "/admin/contract-dashboard",
    role: "ROLE_ADMIN",
  }, {
    id: 17,
    name: "วางบิล",
    icon: Payroll,
    link: "/admin/billing-collected",
    role: "ROLE_ADMIN",
  }
];

export const menuAppVendor = [
  {
    id: 1,
    name: "Overview",
    icon: Performance,
    link: "/vendor/overview",
    role: "ROLE_VENDOR",
  },
  {
    id: 2,
    name: "Status_Report",
    icon: StatusApprove,
    link: "/vendor/approve-list",
    role: "ROLE_VENDOR",
  },
  {
    id: 3,
    name: "Employees",
    icon: EmployeeCoreDate,
    link: "/vendor/employees",
    role: "ROLE_VENDOR",
  },
  {
    id: 4,
    name: "Work_Time",
    icon: TimeManagement,
    link: "/vendor/time",
    role: "ROLE_VENDOR",
  },
  {
    id: 5,
    name: "Access_Area",
    icon: TimeManagement,
    link: "/vendor/access-area",
    role: "ROLE_VENDOR",
  },
  // {
  //   id: 6,
  //   name: "Voyage",
  //   icon: TimeManagement,
  //   link: "/vendor/travel-records",
  //   role: "ROLE_VENDOR",
  // },
  // {
  //   id: 5,
  //   name: "สรุปเวลาทำงาน",
  //   icon: TimeManagement,
  //   link: "/vendor/time",
  //   role: "ROLE_VENDOR",
  // },
  {
    id: 7,
    name: "Shift_Management",
    icon: ShiftManage,
    link: "/vendor/shift",
    role: "ROLE_VENDOR",
  },
  {
    id: 8,
    name: "OTAndShift",
    icon: OT,
    link: "/summary-time",
    role: "ROLE_VENDOR",
  },
  {
    id: 9,
    name: "Payroll_Management",
    icon: Payroll,
    link: "/payroll",
    role: "ROLE_VENDOR",
  },
  {
    id: 10,
    name: "Billing",
    icon: PaySlip,
    link: "/vendor/billing",
    role: "ROLE_VENDOR",
  },
  {
    id: 11,
    name: "Sourcing",
    icon: PaySlip,
    link: "",
    role: "ROLE_VENDOR",
    class: "gray",
  },
  {
    id: 12,
    name: "ContractCompany",
    icon: PaySlip,
    link: "/vendor/contract",
    role: "ROLE_VENDOR",
  },
  {
    id: 13,
    name: "ContractEmployee",
    icon: PaySlip,
    link: "/contract-employee",
    role: "ROLE_VENDOR",
  },
  {
    id: 14,
    name: "Training",
    icon: SourcingRequest,
    link: "",
    role: "ROLE_VENDOR",
    class: "gray",
  },
  {
    id: 15,
    name: "ReportCenter",
    icon: SourcingRequest,
    link: "/report-center",
    role: "ROLE_VENDOR",
  },
  {
    id: 16,
    name: "CompanyProfile",
    icon: CompanyProfile,
    link: "/vendor/company-profile",
    role: "ROLE_VENDOR",
  },
  {
    id: 17,
    name: "Setting",
    icon: Welfare,
    link: "/vendor/setting",
    role: "ROLE_VENDOR",
  },
  {
    id: 18,
    name: "AccessArea",
    icon: TimeManagement,
    link: "/access_area",
    role: "ROLE_VENDOR",
    class: "normal",
  },
  {
    id: 19,
    name: "AccessTime",
    icon: TimeManagement,
    link: "/access_time",
    role: "ROLE_VENDOR",
    class: "normal",
  },
  {
    id: 20,
    name: "Expense",
    icon: PaySlip,
    link: "/expense",
    role: "ROLE_VENDOR",
    class: "normal",
  },
  {
    id: 21,
    name: "GovernmentReport",
    icon: MyDocument,
    link: "/government-report",
    role: "ROLE_VENDOR",
    class: "normal",
  },
  {
    id: 22,
    name: "BillStatement",
    icon: PaySlip,
    link: "/vendor/billing-collected",
    role: "ROLE_VENDOR",
    class: "normal",
  },
];

export const menuAppUser = [
  {
    id: 1,
    name: "PersonalProfile",
    icon: CompanyProfile,
    link: "/profile",
    role: "ROLE_USER",
    class: "normal",
  },
  {
    id: 2,
    name: "Timeline",
    icon: ShiftManage,
    link: "/timeline",
    role: "ROLE_USER",
    class: "normal",
  },
  {
    id: 3,
    name: "EmpLeaveRequest",
    icon: Leave,
    link: "/leave",
    role: "ROLE_USER",
    class: "normal",
  },
  {
    id: 4,
    name: "RequestList",
    icon: Approve,
    link: "/requestlist",
    role: "ROLE_USER",
    class: "normal",
  },
  {
    id: 5,
    name: "SummaryTime",
    icon: OT,
    link: "/summary-time",
    role: "ROLE_USER",
    class: "normal",
  },
  {
    id: 6,
    name: "PaySlip",
    icon: PaySlip,
    link: "/pay-slip",
    role: "ROLE_USER",
    class: "normal",
  },
  {
    id: 7,
    name: "Training",
    icon: Learning,
    //link: "/courses/register",
    role: "ROLE_USER",
    class: "gray",
  },
  {
    id: 8,
    name: "TaxDeductible",
    icon: Payroll,
    link: "/taxdeduction",
    role: "ROLE_USER",
    class: "normal",
  },
  {
    id: 9,
    name: "MyDocument",
    icon: MyDocument,
    link: "/my-document",
    role: "ROLE_USER",
    class: "normal",
  },
  {
    id: 10,
    name: "ManualTimesheet",
    icon: MyDocument,
    link: "/manual-timesheet",
    role: "ROLE_USER",
    class: "normal",
  },
  {
    id: 11,
    name: "AccessArea",
    icon: TimeManagement,
    link: "/access_area",
    role: "ROLE_USER",
    class: "normal",
  },
  {
    id: 12,
    name: "AccessTime",
    icon: TimeManagement,
    link: "/access_time",
    role: "ROLE_USER",
    class: "normal",
  },
  {
    id: 13,
    name: "Expense",
    icon: Dashboard,
    link: "/expense",
    role: "ROLE_USER",
    class: "normal",
  },
  // {
  //   id: 14,
  //   name: "GovernmentReport",
  //   icon: MyDocument,
  //   link: "/government-report",
  //   role: "ROLE_USER",
  //   class: "normal",
  // },
];

export const menuAppManager = [
  {
    id: 1,
    name: "Overview",
    icon: Performance,
    link: "/manager/overview",
    role: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 2,
    name: "WaitingApproval",
    icon: Performance,
    link: "/manager/approve",
    role: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 3,
    name: "Employees",
    icon: EmployeeCoreDate,
    link: "/manager/employee",
    role: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 4,
    name: "Work_Time",
    icon: TimeManagement,
    link: "/manager/time",
    role: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 5,
    name: "AccessArea",
    icon: TimeManagement,
    link: "/access_area",
    role: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 6,
    name: "AccessTime",
    icon: TimeManagement,
    link: "/access_time",
    role: "ROLE_MANAGER",
    class: "normal",
  },
  {
    id: 7,
    name: "Expense",
    icon: PaySlip,
    link: "/expense",
    role: "ROLE_MANAGER",
    class: "normal",
  },
];

export const menuAppAccountant = [
  {
    id: 1,
    name: "เบิกค่าใช้จ่าย",
    icon: PaySlip,
    link: "/expense",
    role: "ROLE_ACCOUNT",
    class: "normal",
  },
  {
    id: 2,
    name: "รายงาน Expense",
    icon: MyDocument,
    link: "/expense-export",
    role: "ROLE_ACCOUNT",
    class: "normal",
  },
  // {
  //   id: 3,
  //   name: "เบิกสวัสดิการ",
  //   icon: Performance,
  //   link: "/welfare-expense",
  //   role: "ROLE_ACCOUNT",
  //   class: "normal",
  // },
  // {
  //   id: 4,
  //   name: "สิทธิสวัสดิการ",
  //   icon: Welfare,
  //   link: "/welfare-rights",
  //   role: "ROLE_ACCOUNT",
  //   class: "normal",
  // },
];
