import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx";

import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  ToggleButtonGroup, 
  ToggleButton, 
  FormControl, 
  InputAdornment,
  FilledInput
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { getAllRequestTimeCompany } from ".././../../../actions/requestTime";
import { getLeaveRequestByCompany } from "../../../../actions/employee";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import TimeAttendance from "./timeAttendance";
import OT from "./ot";
import LeaveRequest from "./leave";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerThai from "../../shared/general/DatePickerThai";
import DatePickerEng from "../../shared/general/DatePickerEng";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF !important",
  "& .displayFlexEnd":{
    display:"flex",
    justifyContent:"flex-end",
    [theme.breakpoints.down("sm")]:{
      width:"100%",
      margin:"8px 0"
    }
  }
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#007afe",
    border: "1px solid #007afe",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007afe",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #007afe",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
  display: "flex",
  justifyContent: "space-evenly",
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const ApporveStatusListPage = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { result : UserProfile } = useSelector(state => state.userProfile); 
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );
  const today = dayjs().toDate();
  const daysInMonth = dayjs(today).daysInMonth()
  const [view, setView] = React.useState("time");
  const [search, setSearch] = useState({
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear(), today.getMonth(), daysInMonth)
  });
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    dispatch(getAllRequestTimeCompany(UserProfile.idCompany, search));
    dispatch(getLeaveRequestByCompany(UserProfile.idCompany, search));
  },[]);

  const handleChangeSearch = (event) => {
    setSearchName(event.target.value);
  };

  const handleChangeView = (_, newView) => {
    if (newView) {
      setView(newView);
    }
  };

  const handleOnClickSearch = () => {
    if(view === "time" || view === "ot"){
      dispatch(getAllRequestTimeCompany(UserProfile.idCompany, search));
    } else if (view === "leave") {
      dispatch(getLeaveRequestByCompany(UserProfile.idCompany, search));
    }
  };

  const excelExport = () => {
    let dataToExcelWaiting = [];
    let elementListWaiting = [];
    if (view === "time" && requestTimeList) {
      dataToExcelWaiting = requestTimeList.filter((x) => {
        if(
          x.idRequestType === 1 && x.isActive === 1 && (
            (x.isDoubleApproval === 1 && (x.isManagerLV1Approve === null || x.isManagerLV2Approve === null)) ||
            (x.approvalLevel === 1 && x.isManagerLV1Approve === null) ||
            (x.approvalLevel === 2 &&  x.isManagerLV2Approve === null)
          ) && (
            (searchName && searchName.length > 0) ? 
              (
                x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
                x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
              )
              : true
          )
        ){
          return true;
        }else{
          return false;
        }
        // return (
        //   x.idRequestType === 1 &&
        //   (x.isDoubleApproval === 1
        //     ? x.isManagerLV1Approve === null || x.isManagerLV2Approve === null
        //     : x.isManagerLV1Approve === null)
        // );
      });

      if(dataToExcelWaiting.length > 0){
        for (let index = 0; index < dataToExcelWaiting.length; index++) {
          const element = {
            // ลำดับที่: dataToExcelWaiting[index].idRequestTime,
            รหัสพนักงาน: dataToExcelWaiting[index].employeeId,
            "ชื่อ-สกุล": `${dataToExcelWaiting[index].firstname} ${dataToExcelWaiting[index].lastname}`,
            ตำแหน่ง: dataToExcelWaiting[index].positionsName
              ? dataToExcelWaiting[index].positionsName
              : "",
            ประเภทการจ่ายเงินเดือน: dataToExcelWaiting[index].paymentRound
              ? dataToExcelWaiting[index].paymentRound
              : "",
            ประเภทคำขอ: dataToExcelWaiting[index].name
              ? dataToExcelWaiting[index].name
              : "",
            เวลาเริ่มต้น: dataToExcelWaiting[index].startText
              ? dataToExcelWaiting[index].startText
              : "",
            เวลาสิ้นสุด: dataToExcelWaiting[index].endText
              ? dataToExcelWaiting[index].endText
              : "",

            เหตุผล: dataToExcelWaiting[index].requestReasonName
              ? dataToExcelWaiting[index].requestReasonName
              : "-",
            "เหตุผล(เพิ่มเติม)": dataToExcelWaiting[index].otherReason
              ? dataToExcelWaiting[index].otherReason
              : "-",
            วันเวลาที่ทำรายการ: dataToExcelWaiting[index].createDateText
              ? dataToExcelWaiting[index].createDateText
              : "-",
            "ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index].managerLV1Name
              ? dataToExcelWaiting[index].managerLV1Name
              : "-",
            "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index]
              .emailManagerLV1
              ? dataToExcelWaiting[index].emailManagerLV1
              : "-",
            "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
              dataToExcelWaiting[index].isManagerLV1Approve === null
                ? "รออนุมัติ"
                : dataToExcelWaiting[index].isManagerLV1Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ",
            "ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcelWaiting[index].isDoubleApproval === 1
                ? dataToExcelWaiting[index].managerLV2Name
                  ? dataToExcelWaiting[index].managerLV2Name
                  : ""
                : dataToExcelWaiting[index].approvalLevel === 2
                ? dataToExcelWaiting[index].managerLV2Name
                  ? dataToExcelWaiting[index].managerLV2Name
                  : ""
                : "",
            "E-mail ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcelWaiting[index].isDoubleApproval === 1
                ? dataToExcelWaiting[index].emailManagerLV2
                  ? dataToExcelWaiting[index].emailManagerLV2
                  : ""
                : dataToExcelWaiting[index].approvalLevel === 2
                ? dataToExcelWaiting[index].emailManagerLV2
                  ? dataToExcelWaiting[index].emailManagerLV2
                  : ""
                : "",
            "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcelWaiting[index].isDoubleApproval === 1
                ? dataToExcelWaiting[index].isManagerLV1Approve === null
                  ? "รอผู้บังคับบัญชาลำดับที่ 1"
                  : dataToExcelWaiting[index].isManagerLV2Approve === null
                  ? "รออนุมัติ"
                  : ""
                : dataToExcelWaiting[index].approvalLevel === 2
                ? dataToExcelWaiting[index].isManagerLV2Approve &&
                  dataToExcelWaiting[index].isManagerLV2Approve === null
                  ? "รออนุมัติ"
                  : ""
                : "",
          };
          elementListWaiting.push(element);
        }
      } else {
        const element = {
          รหัสพนักงาน: "",
          "ชื่อ-สกุล": "",
          ตำแหน่ง: "",
          ประเภทการจ่ายเงินเดือน: "",
          ประเภทคำขอ: "",
          เวลาเริ่มต้น: "",
          เวลาสิ้นสุด: "",
          เหตุผล: "",
          "เหตุผล(เพิ่มเติม)": "",
          วันเวลาที่ทำรายการ: "",
          "ผู้บังคับบัญชาลำดับที่ 1": "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 1": "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1": "",
          "ผู้บังคับบัญชาลำดับที่ 2": "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 2": "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2": ""
        };
        elementListWaiting.push(element);
      }

    } else if (view === "ot" && requestTimeList) {
      dataToExcelWaiting = requestTimeList.filter((x) => {
        if(
          x.idRequestType === 2 && x.isActive === 1 && (
            (x.isDoubleApproval === 1 && (x.isManagerLV1Approve === null || x.isManagerLV2Approve === null)) ||
            (x.approvalLevel === 1 && x.isManagerLV1Approve === null) ||
            (x.approvalLevel === 2 &&  x.isManagerLV2Approve === null)
          ) && (
            (searchName && searchName.length > 0) ? 
              (
                x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
                x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
              )
              : true
          )
        ){
          return true;
        }else{
          return false;
        }
      });

      if(dataToExcelWaiting.length > 0){
        for (let index = 0; index < dataToExcelWaiting.length; index++) {
          const element = {
            // ลำดับที่: dataToExcelWaiting[index].idRequestTime,
            รหัสพนักงาน: dataToExcelWaiting[index].employeeId,
            "ชื่อ-สกุล": `${dataToExcelWaiting[index].firstname} ${dataToExcelWaiting[index].lastname}`,
            ตำแหน่ง: dataToExcelWaiting[index].positionsName
              ? dataToExcelWaiting[index].positionsName
              : "",
            ประเภทการจ่ายเงินเดือน: dataToExcelWaiting[index].paymentRound
              ? dataToExcelWaiting[index].paymentRound
              : "",
            ประเภทคำขอ: dataToExcelWaiting[index].name
              ? dataToExcelWaiting[index].name
              : "",
            เวลาเริ่มต้น: dataToExcelWaiting[index].startText
              ? dataToExcelWaiting[index].startText
              : "",
            เวลาสิ้นสุด: dataToExcelWaiting[index].endText
              ? dataToExcelWaiting[index].endText
              : "",
            [`OT ${dataToExcelWaiting[index].payrollSetting.xWorkingMonthlyHoliday}x (วันหยุด)`]:
              dataToExcelWaiting[index].xWorkingMonthlyHoliday > 0
                ? `${parseFloat(
                    dataToExcelWaiting[index].xWorkingMonthlyHoliday / 60
                  ).toFixed(2)} ชม.`
                : "",
            [`OT ${dataToExcelWaiting[index].payrollSetting.xOT}x (วันทำงาน)`]:
              dataToExcelWaiting[index].xOT > 0
                ? `${parseFloat(dataToExcelWaiting[index].xOT / 60).toFixed(
                    2
                  )} ชม.`
                : "",
            [`OT ${dataToExcelWaiting[index].payrollSetting.xWorkingDailyHoliday}x`]:
              dataToExcelWaiting[index].xWorkingDailyHoliday > 0
                ? `${parseFloat(
                    dataToExcelWaiting[index].xWorkingDailyHoliday / 60
                  ).toFixed(2)} ชม.`
                : "",
            [`OT ${dataToExcelWaiting[index].payrollSetting.xOTHoliday}x`]:
              dataToExcelWaiting[index].xOTHoliday > 0
                ? `${parseFloat(
                    dataToExcelWaiting[index].xOTHoliday / 60
                  ).toFixed(2)} ชม.`
                : "",
            เหตุผล: dataToExcelWaiting[index].requestReasonName
              ? dataToExcelWaiting[index].requestReasonName
              : "-",
            "เหตุผล(เพิ่มเติม)": dataToExcelWaiting[index].otherReason
              ? dataToExcelWaiting[index].otherReason
              : "-",
            วันเวลาที่ทำรายการ: dataToExcelWaiting[index].createDateText
              ? dataToExcelWaiting[index].createDateText
              : "-",
            "ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index].managerLV1Name
              ? dataToExcelWaiting[index].managerLV1Name
              : "-",
            "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcelWaiting[index]
              .emailManagerLV1
              ? dataToExcelWaiting[index].emailManagerLV1
              : "-",
            "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
              dataToExcelWaiting[index].isManagerLV1Approve === null
                ? "รออนุมัติ"
                : dataToExcelWaiting[index].isManagerLV1Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ",
            "ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcelWaiting[index].isDoubleApproval === 1
                ? dataToExcelWaiting[index].managerLV2Name
                  ? dataToExcelWaiting[index].managerLV2Name
                  : ""
                : dataToExcelWaiting[index].approvalLevel === 2
                ? dataToExcelWaiting[index].managerLV2Name
                  ? dataToExcelWaiting[index].managerLV2Name
                  : ""
                : "",
            "E-mail ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcelWaiting[index].isDoubleApproval === 1
                ? dataToExcelWaiting[index].emailManagerLV2
                  ? dataToExcelWaiting[index].emailManagerLV2
                  : ""
                : dataToExcelWaiting[index].approvalLevel === 2
                ? dataToExcelWaiting[index].emailManagerLV2
                  ? dataToExcelWaiting[index].emailManagerLV2
                  : ""
                : "",
            "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
              dataToExcelWaiting[index].isDoubleApproval === 1
                ? dataToExcelWaiting[index].isManagerLV1Approve === null
                  ? "รอผู้บังคับบัญชาลำดับที่ 1"
                  : dataToExcelWaiting[index].isManagerLV2Approve === null
                  ? "รออนุมัติ"
                  : ""
                : dataToExcelWaiting[index].approvalLevel === 2
                ? dataToExcelWaiting[index].isManagerLV2Approve &&
                  dataToExcelWaiting[index].isManagerLV2Approve === null
                  ? "รออนุมัติ"
                  : ""
                : "",
          };
          elementListWaiting.push(element);
        }
      } else {
        const element = {
          รหัสพนักงาน: "",
          "ชื่อ-สกุล": "",
          ตำแหน่ง: "",
          ประเภทการจ่ายเงินเดือน: "",
          ประเภทคำขอ: "",
          เวลาเริ่มต้น: "",
          เวลาสิ้นสุด: "",
          [`OT 1x (วันหยุด)`]: "",
          [`OT 1.5x (วันทำงาน)`]: "",
          [`OT 2x`]: "",
          [`OT 3x`]: "",
          เหตุผล: "",
          "เหตุผล(เพิ่มเติม)": "",
          วันเวลาที่ทำรายการ: "",
          "ผู้บังคับบัญชาลำดับที่ 1": "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 1": "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1": "",
          "ผู้บังคับบัญชาลำดับที่ 2": "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 2": "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2": "",
        };
        elementListWaiting.push(element);
      }
    } else if (view === "leave" && leaveEmployeeList) {
      dataToExcelWaiting = leaveEmployeeList.filter(
        (x) => {
          if(
            (x.isApprove === null && x.isActive === 1) && 
            (
              (searchName && searchName.length > 0) ? 
              (
                x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
                x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
              )
              : true
            )
          ){
            return true;
          } else {
            return false;
          }
        }
      );

      if(dataToExcelWaiting.length > 0){
        for (let index = 0; index < dataToExcelWaiting.length; index++) {
          const element = {
            รหัสพนักงาน: dataToExcelWaiting[index].employeeId,
            "ชื่อ-สกุล": `${dataToExcelWaiting[index].firstname} ${dataToExcelWaiting[index].lastname}`,
            ตำแหน่ง: dataToExcelWaiting[index].positionsName
              ? dataToExcelWaiting[index].positionsName
              : "",
            ประเภทคำขอ: dataToExcelWaiting[index].name
              ? dataToExcelWaiting[index].name
              : "",
            เวลาเริ่มต้น: dataToExcelWaiting[index].startText
              ? dataToExcelWaiting[index].startText
              : "",
            เวลาสิ้นสุด: dataToExcelWaiting[index].endText
              ? dataToExcelWaiting[index].endText
              : "",
            "จำนวนที่ใช้ไป (วัน)": dataToExcelWaiting[index].used
              ? dataToExcelWaiting[index].used
              : "",
            เหตุผล: dataToExcelWaiting[index].description
              ? dataToExcelWaiting[index].description
              : "-",
            วันเวลาที่ทำรายการ: dataToExcelWaiting[index].createLeaveText
              ? dataToExcelWaiting[index].createLeaveText
              : "-",
            ผู้บังคับบัญชา: dataToExcelWaiting[index].managerLV1Name
              ? dataToExcelWaiting[index].managerLV1Name
              : "-",
            "E-mail ผู้บังคับบัญชา": dataToExcelWaiting[index].emailManagerLV1
              ? dataToExcelWaiting[index].emailManagerLV1
              : "-",
          };
          elementListWaiting.push(element);
        }
      } else {
        const element = {
          รหัสพนักงาน: "",
          "ชื่อ-สกุล": "",
          ตำแหน่ง: "",
          ประเภทคำขอ: "",
          เวลาเริ่มต้น: "",
          เวลาสิ้นสุด: "",
          "จำนวนที่ใช้ไป (วัน)": "",
          เหตุผล: "",
          วันเวลาที่ทำรายการ:"",
          ผู้บังคับบัญชา: "",
          "E-mail ผู้บังคับบัญชา": "",
        };
        elementListWaiting.push(element);
      }
    }

    const wsWaiting = XLSX.utils.json_to_sheet(elementListWaiting);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, wsWaiting, "รออนุมัติ");
    XLSX.writeFile(
      workbook,
      `รายการ${
        view === "time"
          ? "รับรองเวลทำงาน"
          : view === "ot"
          ? "ทำงานล่วงเวลา"
          : "ลางาน"
      }.xlsx`,
      { type: "file" }
    );
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Grid container justifyContent="space-between"  alignItems="flex-end" style={{ marginBottom: 8 }}>
          <Grid item>
            <Typography variant="h4" style={{ paddingTop: 8 }}>
              {t("Status_Report")}
            </Typography>
          </Grid>
          <Grid item className="displayFlexEnd">
            <ButtonBlue
              variant="contained"
              startIcon={<AccessTimeIcon />}
              onClick={() => props.history.push("/admin/history-approve-list")}
            >{t("History_Status_Report")}</ButtonBlue>
          </Grid>
        </Grid>
        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleChangeView}
          aria-label="view"
          size="small"
        >
          <ToggleButton
            name="status"
            value="time"
            aria-label="time"
            size="small"
            style={{ minWidth: 150, marginRight: 12 }}
          >
            {t("requestWorkingTime")}
          </ToggleButton>
          <ToggleButton
            name="status"
            value="ot"
            aria-label="ot"
            size="small"
            style={{ minWidth: 150, marginRight: 12 }}
          >
            {t("OtRequest")}
          </ToggleButton>
          <ToggleButton
            name="status"
            value="leave"
            aria-label="leave"
            size="small"
            style={{ minWidth: 150, marginRight: 12 }}
          >
            {t("LeaveRequest")}
          </ToggleButton>
        </StyledToggleButtonGroup>
        <Grid
          container
          spacing={2}
          style={{ paddingBottom: 16 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("StartDate")}
              </Typography>
              <div className="search-date">
                {localStorage.getItem("language") === "en" ? (
                  <DatePickerEng
                    value={search.start}
                    name="start"
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        ["start"]: newValue,
                      });
                    }}
                  />
                ) : (
                  <DatePickerThai
                    value={search.start}
                    name="start"
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        ["start"]: newValue,
                      });
                    }}
                  />
                )}
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("EndDate")}
              </Typography>
              <div className="search-date">
              {localStorage.getItem("language") === "en" ? (
                  <DatePickerEng
                    minDate={search.start}
                    value={search.end}
                    name="end"
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        ["end"]: newValue,
                      });
                    }}
                  />
                ) : (
                  <DatePickerThai
                    minDate={search.start}
                    value={search.end}
                    name="end"
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        ["end"]: newValue,
                      });
                    }}
                  />
                )}
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {`${t("Search")} ${t("FullName")}`}
              </Typography>
              <FormControl fullWidth variant="filled">
                <StyledFilledInput
                  id="filled-sarch-name"
                  placeholder={`${t("Search")} ${t("FullName")}`}
                  name="searchName"
                  onChange={handleChangeSearch}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={12} sm={3}>
            <StyledBoxSearchButton>
              <div>
                <ButtonBlue
                  variant="contained"
                  startIcon={<SearchIcon />}
                  onClick={handleOnClickSearch}
                >
                  {t("Search")}
                </ButtonBlue>
              </div>
              <div>
                <ButtonBlue
                  onClick={excelExport}
                  variant="outlined"
                  startIcon={<DownloadRoundedIcon />}
                >
                  {t("Download")}
                </ButtonBlue>
              </div>
            </StyledBoxSearchButton>
          </Grid>
        </Grid>
        <div style={{ paddingBottom: 32 }}>
          {view === "time" ? (
            <TimeAttendance searchName={searchName} />
          ) : null}
          {view === "ot" ? (
            <OT searchName={searchName} />
          ) : null}
          {view === "leave" ? (
            <LeaveRequest searchName={searchName} />
          ) : null}
        </div>
      </Container>
    </StyledRoot>
  );
};

export default ApporveStatusListPage;
