import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useDropzone } from "react-dropzone";
import DrawerCustom from "../../../../shared/general/Drawer";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { useDispatch, useSelector } from "react-redux";
import {
  addLeaveType,
  addVendorCompanyLeaveType,
  allLeaveType,
  getAllVendorCompanyLeaveType,
  uploadFileLeaveType,
  uploadFileVendorCompanyLeaveType,
} from "../../../../../../actions/leave";

import AddGraphicIcon from "../../../../assets/add.png";

import FileSelected from "./file-selected";
import { getJobLevlByidCompany } from "../../../../../../actions/jobLevel";

const StyledRoot = styled("div")({
  padding: 24,
  "& .MuiIconButton-root": {
    backgroundColor: "#d500000f",
    color: "#d50000",
  },
});

const StyledDivider = styled(Divider)({
  margin: "24px 0",
});

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const DrawerLeave = (props) => {
  const {
    open,
    handleClose,
    company,
    handleOpenAlert,
    handleChangeAlertType,
    leave,
    setLeave,
    handleOpen,
    type,
  } = props;
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: JobLevels } = useSelector((state) => state.jobLevel);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [fileSelected, setFileSelected] = useState(null);
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const {
    control,
    // handleSubmit,
    watch,
    setValue,
    getValues,
    register,
    unregister,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      isPaid: "",
      isAfterProbation: "",
      condition: "",
      leaveValue: "",
      carryValue: "",
      steps: [
        {
          conditionType: "",
          moreThanYears: "",
          leaveValue: "",
          carryValue: "",
        },
      ],
      gender: "all",
      minLeave: "",
      managerLv1: "approve",
      managerLv2: "notify",
    },
    shouldUnregister: true,
  });

  const {
    fields: stepFields,
    append: stepAppend,
    remove: stepRemove,
  } = useFieldArray({
    control,
    name: "steps",
  });

  const conditionWatch = watch("condition");

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.map((file) => {
        formData.append("file", file);
        formData.append("selected", company);
        formData.append("createBy", currentUser ? currentUser.username : "");
        formData.append("idCompany", company.idCompany);
        formData.append("idVendor", userProfile.idVendor);
      });
      setLoading(false);
      setFormFileSelected(formData);
      setFileSelected({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    },
    maxFiles: 1,
  });

  const onSubmit = async (data) => {
    const formData = {
      name: data.name,
      minLeave: data.minLeave,
      isPaid: data.isPaid,
      isAfterProbation: data.isAfterProbation,
      isLeaveStep: data.condition === "step" ? 1 : 0,
      leaveValue: data.leaveValue || null,
      carryValue: data.carryValue || null,
      gender: data.gender === "all" ? null : data.gender,
      managerLv1: "approve",
      steps: data.steps,
      idCompany: company.idCompany,
      idVendor: userProfile.idVendor,
      isActive: 1,
    };

    console.log(formData);

    // const response = await dispatch(addLeaveType(1, formData));
    // const response = await dispatch(addVendorCompanyLeaveType(formData));
    // if (response.status === 200) {
    //   // dispatch(allLeaveType(1));
    //   dispatch(
    //     getAllVendorCompanyLeaveType({
    //       idCompany: company.idCompany,
    //       idVendor: currentUser.idVendor,
    //     })
    //   );
    //   handleClose();
    // }
  };

  useEffect(() => {
    console.log(type);
    if (type === "Edit") {
      console.log("leave", leave);
      setValue("name", leave.name);
      setValue("isAfterProbation", leave.isAfterProbation);
      setValue("isLeaveStep", leave.isLeaveStep === 1 ? "step" : "fixed");
      setValue("condition", leave.isLeaveStep === 1 ? "step" : "fixed");
      setValue("gender", leave.gender ? leave.gender : "all");
      setValue("minLeave", leave.minLeave);
      setValue("leaveValue", leave.leaveValue);
      setValue("isPaid", leave.isPaid);
      setValue("managerLv1", leave.managerLv1);
    }
    dispatch(getJobLevlByidCompany(company.idCompany));
  }, [leave, type]);

  const onChangeLeaveCondition = (value) => {
    if (value === "fixed") {
      setValue("steps", [
        { conditionType: "", moreThanYear: "", leaveValue: "", carryValue: "" },
      ]);
    } else {
      unregister("leaveValue");
      unregister("carryValue");
    }
  };

  const onValidateExperienceFirstIsZero = (v) => {
    const expCondition = getValues("steps").filter(
      (item) => item.condition === "experience"
    );
    if (+expCondition[0].moreThanYears === 0) {
      return true;
    } else {
      return false;
    }
  };

  const onValidateExperienceASC = (v) => {
    const expCondition = getValues("steps").filter(
      (item) => item.condition === "experience"
    );

    if (expCondition.length === 1) {
      return true;
    }

    for (let i = 1; i < expCondition.length; i++) {
      if (
        +expCondition[i].moreThanYears <= +expCondition[i - 1].moreThanYears
      ) {
        return false;
      }
    }
    return true;
  };

  const onValidateLevelFirstIsOne = (v) => {
    const lvCondition = getValues("steps").filter(
      (item) => item.condition === "level"
    );
    if (+lvCondition[0].moreThanYears === 1) {
      return true;
    } else {
      return false;
    }
  };

  const onValidateLevelASC = (v) => {
    const lvCondition = getValues("steps").filter(
      (item) => item.condition === "level"
    );

    if (lvCondition.length === 1) {
      return true;
    }

    for (let i = 1; i < lvCondition.length; i++) {
      if (+lvCondition[i].moreThanYears <= +lvCondition[i - 1].moreThanYears) {
        return false;
      }
    }
    return true;
  };

  // const handleClickUpload = async () => {
  //   setLoading(true);
  //   // const result = await dispatch(uploadFileLeaveType(formFileSelected));
  //   const result = await dispatch(
  //     uploadFileVendorCompanyLeaveType(formFileSelected)
  //   );
  //   if (result) {
  //     console.log(result);

  //     handleOpenAlert();
  //     handleChangeAlertType("success");
  //     handleClose();
  //   }
  // };

  // const handleClickDownload = () => {
  //   window.open(`${process.env.REACT_APP_API_URL}files/Template_Leave.xlsx`);
  // };

  return (
    <DrawerCustom
      title={t("AddLeaveDay")}
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { maxWidth: 550, width: "100%" } }}
    >
      <StyledRoot>
        {/* <div
          style={{
            marginBottom: 16,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Link variant="body2" onClick={handleClickDownload}>
            {t("DownloadTemplate")}
          </Link>
        </div>
        <StyledImportFile>
          <div
            {...getRootProps({
              className: "dropzone-upload-file",
            })}
          >
            <input {...getInputProps()} />
            <div className="inner-dropzone">
              <img alt="AddIcon" src={AddGraphicIcon} width="80" />
              <Typography
                style={{
                  marginTop: 8,
                  backgroundColor: "transparent",
                }}
                className={`${fileSelected != 0 && `placeholderLabel`}`}
                variant="body2"
                color="text.secondary"
              >
                Drag & Drop / {t("Click_For_Select_File")}
              </Typography>
            </div>
          </div>
        </StyledImportFile>
        {fileSelected && (
          <FileSelected
            fileSelected={fileSelected}
            handleClickUpload={handleClickUpload}
            loading={loading}
          />
        )}
        <StyledDivider /> */}
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="name"
                rules={{ required: true }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("LeaveDayName")}
                    error={errors.leaveName ? true : false}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="isPaid"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      label={t("LeaveType")}
                      select
                      error={errors.isPaid ? true : false}
                    >
                      <MenuItem value="1">{t("PaidLeave")}</MenuItem>
                      <MenuItem value="0">{t("UnpaidLeave")}</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="isAfterProbation"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      label={t("RequestLeaveAfterDate")}
                      select
                      error={errors.canRequestAfter ? true : false}
                    >
                      <MenuItem value="0">{t("StartWork")}</MenuItem>
                      <MenuItem value="1">{t("AfterProbation")}</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="condition"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      label={t("LeaveRights")}
                      select
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        onChangeLeaveCondition(e.target.value);
                      }}
                      error={errors.condition ? true : false}
                    >
                      <MenuItem value="fixed">{t("Fixed")}</MenuItem>
                      <MenuItem value="step">{t("Step")}</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              {conditionWatch === "step" && (
                <Grid
                  item
                  xs={6}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <ButtonBlue
                    startIcon={<AddIcon />}
                    variant="outlined"
                    onClick={() => {
                      stepAppend({
                        conditionType: "",
                        moreThanYears: "",
                        leaveValue: "",
                        carryValue: "",
                      });
                    }}
                  >
                    {t("AddCondition")}
                  </ButtonBlue>
                </Grid>
              )}
            </Grid>

            {conditionWatch === "step" && (
              <Grid item xs={12} container spacing={3}>
                {stepFields.map((item, i) => (
                  <Grid
                    key={item.id}
                    item
                    xs={12}
                    container
                    spacing={2}
                    alignItems="flex-start"
                  >
                    <Grid item xs={12} container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography>
                          {t("Condition")} {i + 1}
                        </Typography>
                        <IconButton
                          onClick={() => {
                            stepRemove(i);
                          }}
                          disabled={stepFields.length <= 1}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`steps.${i}.conditionType`}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextFieldTheme
                              {...field}
                              label="ประเภท"
                              select
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setValue(`steps.${i}.moreThanYears`, "");
                              }}
                              error={
                                errors.steps &&
                                errors.steps[i] &&
                                errors.steps[i].conditionType
                                  ? true
                                  : false
                              }
                            >
                              <MenuItem value="experience">
                                {t("Experience")}
                              </MenuItem>
                              <MenuItem value="level">{t("JobLevel")}</MenuItem>
                            </TextFieldTheme>
                          )}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        {watch(`steps.${i}.conditionType`) === "experience" && (
                          <Controller
                            control={control}
                            name={`steps.${i}.moreThanYears`}
                            rules={{
                              required: true,
                              validate: {
                                onValidateExperienceFirstIsZero: (value) =>
                                  onValidateExperienceFirstIsZero(value),
                                onValidateExperienceASC: (value) =>
                                  onValidateExperienceASC(value),
                              },
                            }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextFieldTheme
                                {...field}
                                label={t("MoreThan")}
                                error={
                                  errors.steps &&
                                  errors.steps[i] &&
                                  errors.steps[i].moreThanYears
                                    ? true
                                    : false
                                }
                              />
                            )}
                          />
                        )}

                        {watch(`steps.${i}.conditionType`) === "level" && (
                          <Controller
                            control={control}
                            name={`steps.${i}.moreThanJobLevel`}
                            rules={{
                              required: true,
                              validate: {
                                onValidateLevelFirstIsOne: (value) =>
                                  onValidateLevelFirstIsOne(value),
                                onValidateLevelASC: (value) =>
                                  onValidateLevelASC(value),
                              },
                            }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextFieldTheme
                                {...field}
                                label={t("MoreThan")}
                                select
                                error={
                                  errors.steps &&
                                  errors.steps[i] &&
                                  errors.steps[i].moreThanJobLevel
                                    ? true
                                    : false
                                }
                              >
                                {JobLevels.map((item) => (
                                  <MenuItem value={item.idJobLevel}>
                                    {item.jobLevelName}
                                  </MenuItem>
                                ))}
                                {/* <MenuItem value="1">Junior</MenuItem>
                                <MenuItem value="2">Senior</MenuItem>
                                <MenuItem value="3">Manager</MenuItem> */}
                              </TextFieldTheme>
                            )}
                          />
                        )}
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`steps.${i}.leaveValue`}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <TextFieldTheme
                              {...field}
                              label={t("NumberLeaveDays")}
                              error={
                                errors.steps &&
                                errors.steps[i] &&
                                errors.steps[i].leaveValue
                                  ? true
                                  : false
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={`steps.${i}.carryValue`}
                          render={({ field }) => (
                            <TextFieldTheme
                              {...field}
                              label={t("AccumulateNotMoreThan")}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}

            {conditionWatch === "fixed" && (
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="leaveValue"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextFieldTheme
                        {...field}
                        label={t("NumberLeaveDays")}
                        error={errors.leaveValue ? true : false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="carryValue"
                    render={({ field }) => (
                      <TextFieldTheme
                        {...field}
                        label={t("AccumulateNotMoreThan")}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} container spacing={3}>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="gender"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      label="เพศ"
                      select
                      error={errors.gender ? true : false}
                    >
                      <MenuItem value="all">{t("All")}</MenuItem>
                      <MenuItem value="ชาย">{t("Male")}</MenuItem>
                      <MenuItem value="หญิง">{t("Female")}</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="minLeave"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      label={t("MinimumLeave")}
                      select
                      error={errors.minLeave ? true : false}
                    >
                      <MenuItem value="5">{t("HalfHour")}</MenuItem>
                      <MenuItem value="10">{t("Hour")}</MenuItem>
                      <MenuItem value="50">{t("HalfDay")}</MenuItem>
                      <MenuItem value="100">{t("FullDay")}</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>
            </Grid>

            {/* <Grid item xs={12} container spacing={3}>
              <Grid item xs={12}>
                <Typography>{t("ApprovalProcess")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="managerLv1"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      label={`${t("ManagerLevel")} 1`}
                      select
                      error={errors.managerLv1 ? true : false}
                    >
                      <MenuItem value="none">{t("None")}</MenuItem>
                      <MenuItem value="notify">{t("Notification")}</MenuItem>
                      <MenuItem value="approve">{t("Approval")}</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  control={control}
                  name="managerLv2"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      label={`${t("ManagerLevel")} 2`}
                      select
                      error={errors.managerLv2 ? true : false}
                    >
                      <MenuItem value="none">{t("None")}</MenuItem>
                      <MenuItem value="notify">{t("Notification")}</MenuItem>
                      <MenuItem value="approve">{t("Approval")}</MenuItem>
                    </TextFieldTheme>
                  )}
                />
              </Grid> 
            </Grid>*/}

            <Grid
              item
              xs={12}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <ButtonBlue
                style={{ marginRight: 8 }}
                onClick={() => {
                  handleClose();
                }}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                onClick={() => {
                  onSubmit(getValues());
                }}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerLeave;
