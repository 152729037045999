import {
    ADDITIONS_VENDOR_FETCHING,
    ADDITIONS_VENDOR_FAILED,
    ADDITIONS_VENDOR_SUCCESS
} from "../actions/types";
  
const initialState = {
    result: null,
    isFetching:false,
    isError: false
};
  
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADDITIONS_VENDOR_FETCHING:
            return { ...state, result: null, isFetching: true, isError: false };
        case ADDITIONS_VENDOR_FAILED:
            return { ...state, result: null, isFetching: false, isError: true };
        case ADDITIONS_VENDOR_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false };
        default:
            return state;
    }
};