import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { 
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import { updateUserNTable, updateUser, uploadCriminalImage } from "../../../../../../actions/user";
import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .dropzone-company-profile": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent:"center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
    minHeight: 160,
    margin: "auto",
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
  "& .inner-dropzone-company-profile": {
    cursor: "pointer",
    zIndex: 0,
    width: "fit-content",
    height: "100%",
    outline: "none",
    display: "flex",
    alignItems:"center",
    overflow: "hidden",
    borderRadius: 8,
    position: "relative",
    "& .placeholder":{
      display:"flex",
      flexDirection:"column",
      alignItems:"center"
    }
  },
  "& .uploadImage": {
    width: "100%",
    height:"fit-content",
    zIndex: 8,
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyleSelect = styled(Select)({
  borderRadius: 8,
});

const Address = (props) => {
  const { handleChange, formData } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      {/* <Grid item xs={12} sm={3}>
        <StyledContentLabel>ที่อยู่</StyledContentLabel>
      </Grid> */}
      {/* <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="address"
          fullWidth
          onChange={handleChange}
          value={formData.address}
        />
      </Grid> */}
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("HouseNumber")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="houseNo"
          fullWidth
          onChange={handleChange}
          value={formData.houseNo}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("Village")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="vilage"
          fullWidth
          onChange={handleChange}
          value={formData.vilage}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("VillageNo")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="vilageNo"
          fullWidth
          onChange={handleChange}
          value={formData.vilageNo}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("Alley")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="alley"
          fullWidth
          onChange={handleChange}
          value={formData.alley}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("Road")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="road"
          fullWidth
          onChange={handleChange}
          value={formData.road}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("SubdistrictArea")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="subDistrict"
          fullWidth
          onChange={handleChange}
          value={formData.subDistrict}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("DistrictArea")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="district"
          fullWidth
          onChange={handleChange}
          value={formData.district}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("Province")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="provience"
          fullWidth
          onChange={handleChange}
          value={formData.provience}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("PostalCode")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="areaCode"
          fullWidth
          onChange={handleChange}
          value={formData.areaCode}
        />
      </Grid>
    </Grid>
  );
};

const Contact = (props) => {
  const { handleChange, formData } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("EmergencyContact")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="emergencyContact"
          fullWidth
          onChange={handleChange}
          value={formData.emergencyContact}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("EmergencyPhone")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="emergencyPhone"
          fullWidth
          onChange={handleChange}
          value={formData.emergencyPhone}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("EmergencyRelationship")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="emergencyRelationship"
          fullWidth
          onChange={handleChange}
          value={formData.emergencyRelationship}
        />
      </Grid>
    </Grid>
  );
};

const PersonalDetail = (props) => {
  const { handleChange, formData } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>Passport ID</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="passportId"
          fullWidth
          onChange={handleChange}
          value={formData.passportId}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("MaritalStatus")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <FormControl fullWidth>
          <StyleSelect
            name="maritalStatus"
            value={formData.maritalStatus}
            onChange={handleChange}
            defaultValue={formData.maritalStatus}
          >
            {/* <MenuItem value="Single">โสด/Single</MenuItem>
            <MenuItem value="Married">สมรส/Married</MenuItem>
            <MenuItem value="Widowed">หม้าย/Widowed</MenuItem>
            <MenuItem value="Divorced">หย่า/Divorced</MenuItem>
            <MenuItem value="Separated">แยกกันอยู่/Separated</MenuItem> */}
            <MenuItem value="โสด">{`${t("Single")}`}</MenuItem>
            <MenuItem value="สมรส">{`${t("Married")}`}</MenuItem>
            <MenuItem value="หม้าย">{`${t("Widowed")}`}</MenuItem>
            <MenuItem value="หย่า">{`${t("Divorced")}`}</MenuItem>
            <MenuItem value="แยกกันอยู่">{`${t("Separated")}`}</MenuItem>
          </StyleSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("Nationality")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <FormControl fullWidth>
          <StyleSelect
            name="nationality"
            value={formData.nationality}
            onChange={handleChange}
          >
            {/* <MenuItem value="Thailand">Thailand</MenuItem>
            <MenuItem value="Brunei">Brunei</MenuItem>
            <MenuItem value="Cambodia">Cambodia</MenuItem>
            <MenuItem value="Indonesia">Indonesia</MenuItem>
            <MenuItem value="Lao">Lao</MenuItem>
            <MenuItem value="Malaysia">Malaysia</MenuItem>   
            <MenuItem value="Myanmar">Myanmar</MenuItem>
            <MenuItem value="Philippines">Philippines</MenuItem> 
            <MenuItem value="Singapore">Singapore</MenuItem>
            <MenuItem value="Vietnam">Vietnam</MenuItem> */}
            <MenuItem value="ไทย">{t("Thai")}</MenuItem>
          </StyleSelect>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>Work Permit</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="workPermit"
          fullWidth
          onChange={handleChange}
          value={formData.workPermit}
        />
      </Grid>
    </Grid>
  );
};

const CriminalImage = (props) => {
  const { getRootProps, getInputProps, thumbs, handleChange, formData } = props;

  return (
    <Fragment>
      <div {...getRootProps({ className: "dropzone-company-profile" })}>
        <div className="inner-dropzone-company-profile">
          <input {...getInputProps()} />
          <Fragment>{thumbs()}</Fragment>
          <div
            className={`placeholder ${
              ((formData.criminalImage && formData.criminalImage.length != 0) ||
                formData.criminalImage != null) &&
              `placeholderImageProfile`
            }`}
          >
            <AddAPhotoIcon />
            <Typography
              style={{ marginTop: 8, backgroundColor: "transparent" }}
              className={`${formData.criminalImage != 0 && `placeholderLabel`}`}
              variant="body2"
            >
              Upload Photo
            </Typography>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [formData, setFormData] = useState({
    // address:"",
    houseNo: "",
    vilage: "",
    vilageNo: "",
    alley: "",
    road: "",
    subDistrict: "",
    district:"",
    provience:"",
    areaCode:"",
    emergencyContac:"",
    emergencyPhone:"",
    emergencyRelationship:"",
    passportId:"",
    maritalStatus:"Single",
    nationality:"Thailand",
    workPermit:"",
    criminalImage:[]
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (open) {
      if (mode === "address") {
        setFormData({
          // address: employeeProfile.address,
          houseNo: employeeProfile.houseNo,
          vilage: employeeProfile.vilage,
          vilageNo: employeeProfile.vilageNo,
          alley: employeeProfile.alley,
          road: employeeProfile.road,
          subDistrict: employeeProfile.subDistrict,
          district: employeeProfile.district,
          provience: employeeProfile.provience,
          areaCode: employeeProfile.areaCode,
        });
      } else if(mode === "emergency"){
        setFormData({
          emergencyContact: employeeProfile.emergencyContact,
          emergencyPhone: employeeProfile.emergencyPhone,
          emergencyRelationship: employeeProfile.emergencyRelationship,
        });
      } else if(mode === "personalDetail"){
        setFormData({
          passportId: employeeProfile.passportId,
          maritalStatus: employeeProfile.maritalStatus || formData.maritalStatus,
          nationality: employeeProfile.nationality || formData.nationality,
          workPermit: employeeProfile.workPermit
        });
      }
    }
  }, [open]);

  const handleSubmit = async () => {
    if (employeeProfile.idAddress && mode === "address") {
      formData.idAddress = employeeProfile.idAddress;
    }

    if (mode === "address") {
      formData.idEmp = employeeProfile.idEmp;

      // const query = {text : "Address"}
      // const data = {formData, query}
      
      const result = await dispatch(
        updateUser(formData)
      );
      if (result) {
        handleCloseDialog();
        dispatch(
          getEmployeeProfile(employeeProfile.idEmp)
        );
      }
    } else if (mode === "criminalImage") {
      const result = await dispatch(
        uploadCriminalImage(employeeProfile,formData.criminalImage)
      );
      if (result) {
        handleCloseDialog();
        dispatch(
          getEmployeeProfile(employeeProfile.idEmp)
        );
      }
    } else {
      formData.idEmp = employeeProfile.idEmp;
      const result = await dispatch(
        updateUser(formData)
      );
      if (result) {
        handleCloseDialog();
        dispatch(
          getEmployeeProfile(employeeProfile.idEmp)
        );
      }
    }
    
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formDataImage = new FormData();
      acceptedFiles.map((file) => formDataImage.append("file", file));
      setFormData({
        ...formData,
        criminalImage : acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      });
    },
    maxFiles: 1,
  });

  const thumbs = () => {
    if (formData.criminalImage && formData.criminalImage.length !== 0) {
      return (
        <img
          alt={formData.criminalImage[0].name}
          src={formData.criminalImage[0].preview}
          className={`uploadImage`}
        />
      );
    }
  };

  const titleDrawer = (mode) => {
    if(mode === "address"){
      return "แก้ไขที่อยู่ปัจจุบัน";
    }else if(mode === "emergency"){
      return "แก้ไขผู้ติดต่อฉุกเฉิน";
    }else if(mode === "personalDetail"){
      return "แก้ไขข้อมูลส่วนตัว";
    }else{
      return "แก้ไขประวัติอาชญากรรม";
    }
  };

  return (
    <DrawerCustom
      title={titleDrawer(mode)}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {mode === "address" &&
          <Address handleChange={handleChange} formData={formData} />
        }
        {mode === "emergency" &&
          <Contact handleChange={handleChange} formData={formData} />
        }
        {mode === "personalDetail" && 
          <PersonalDetail handleChange={handleChange} formData={formData} />
        }
        {mode === "criminalImage" &&
          <CriminalImage getRootProps={getRootProps} getInputProps={getInputProps} thumbs={thumbs} handleChange={handleChange} formData={formData} />
        }
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            ยกเลิก
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
