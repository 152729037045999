import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import * as XLSX from "xlsx";
import {
  Container,
  Grid,
  Tab,
  Tabs,
  FilledInput,
  FormControl,
  InputAdornment,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

import dayjs from "dayjs";
import "dayjs/locale/th";
import { th } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import SearchIcon from "@mui/icons-material/Search";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import ButtonBlue from "../../shared/general/ButtonBlue";

import { getAllRequestTimeBy } from "../../../../actions/requestTime";
import { getLeaveRequest } from "../../../../actions/employee";
import { getAllLeaveWithDrawBy } from "../../../../actions/leave";
import { getPayrollSetting } from "../../../../actions/paytypes";

import TableApproval from "./TableApproval";
import TableOTApproval from "./TableOTApproval";
import TableTimeApproval from "./TableTimeApproval";

const StyledHeaderTopic = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    alignItems: "flex-start",
  },
  "& .divButton": {
    width: "fit-content",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "flex-end",
      marginBottom: 16,
    },
  },
}));

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledCard = styled(Card)({
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const StyledRoot = styled("div")({
  background: "#FFFFFF !important",
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
  display: "flex",
  justifyContent: "space-evenly",
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Requestlist(props) {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const [approveTabsValue, setApproveTabsValue] = React.useState(0);
  const [valueTab, setValueTab] = React.useState(0);
  const [searchName, setSearchName] = useState("");

  useEffect(() => {
    dispatch(getLeaveRequest("manager"));
    dispatch(getAllRequestTimeBy("manager"));
    dispatch(getAllLeaveWithDrawBy("manager"));
    dispatch(getPayrollSetting());
  }, []);

  const handleTabChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleApproveTabsChange = (event, newValue) => {
    setApproveTabsValue(newValue);
  };

  const handleClickSearch = async () => {
    dispatch(getLeaveRequest("manager", search));
    dispatch(getAllRequestTimeBy("manager", search));
    dispatch(getAllLeaveWithDrawBy("manager", search));
  };

  const handleChangeSearch = (event) => {
    setSearchName(event.target.value);
  };

  const excelExport = () => {
    let dataToExcelRequesttime = [];
    let dataToExcelOT = [];
    let dataToExcel = [];

    let elementListRequesttime = [];
    let elementListOT = [];
    let elementListLeave = [];

    if (
      requestTimeList &&
      payrollSettingList &&
      payrollSettingList.length > 0
    ) {
      dataToExcelRequesttime = requestTimeList.filter((x) => {
        return (
          x.idRequestType === 1 &&
          (x.isDoubleApproval === 1
            ? x.isManagerLV1Approve !== null || x.isManagerLV2Approve !== null
            : x.isManagerLV1Approve !== null)
        );
      });

      for (let index = 0; index < dataToExcelRequesttime.length; index++) {
        const element = {
          รหัสพนักงาน: dataToExcelRequesttime[index].employeeId,
          "ชื่อ-สกุล": `${dataToExcelRequesttime[index].firstname} ${dataToExcelRequesttime[index].lastname}`,
          ตำแหน่ง: dataToExcelRequesttime[index].positionsName
            ? dataToExcelRequesttime[index].positionsName
            : "",
          ประเภทการจ่ายเงินเดือน: dataToExcelRequesttime[index].paymentRound
            ? dataToExcelRequesttime[index].paymentRound
            : "",
          ประเภทคำขอ: dataToExcelRequesttime[index].name
            ? dataToExcelRequesttime[index].name
            : "",
          เวลาเริ่มต้น: dataToExcelRequesttime[index].startText
            ? dataToExcelRequesttime[index].startText
            : "",
          เวลาสิ้นสุด: dataToExcelRequesttime[index].endText
            ? dataToExcelRequesttime[index].endText
            : "",
          เหตุผล: dataToExcelRequesttime[index].requestReasonName
            ? dataToExcelRequesttime[index].requestReasonName
            : "-",
          "เหตุผล(เพิ่มเติม)": dataToExcelRequesttime[index].otherReason
            ? dataToExcelRequesttime[index].otherReason
            : "-",
          วันเวลาที่ทำรายการ: dataToExcelRequesttime[index].createDateText
            ? dataToExcelRequesttime[index].createDateText
            : "-",
          "ผู้บังคับบัญชาลำดับที่ 1": dataToExcelRequesttime[index]
            .managerLV1Name
            ? dataToExcelRequesttime[index].managerLV1Name
            : "-",
          "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcelRequesttime[index]
            .emailManagerLV1
            ? dataToExcelRequesttime[index].emailManagerLV1
            : "-",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
            dataToExcelRequesttime[index].isManagerLV1Approve &&
            dataToExcelRequesttime[index].isManagerLV1Approve === null
              ? "รออนุมัติ"
              : dataToExcelRequesttime[index].isManagerLV1Approve === 1
              ? "อนุมัติ"
              : "ไม่อนุมัติ",
          "ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelRequesttime[index].isDoubleApproval === 1
              ? dataToExcelRequesttime[index].managerLV2Name
                ? dataToExcelRequesttime[index].managerLV2Name
                : ""
              : dataToExcelRequesttime[index].approvalLevel === 2
              ? dataToExcelRequesttime[index].managerLV2Name
                ? dataToExcelRequesttime[index].managerLV2Name
                : ""
              : "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelRequesttime[index].isDoubleApproval === 1
              ? dataToExcelRequesttime[index].emailManagerLV2
                ? dataToExcelRequesttime[index].emailManagerLV2
                : ""
              : dataToExcelRequesttime[index].approvalLevel === 2
              ? dataToExcelRequesttime[index].emailManagerLV2
                ? dataToExcelRequesttime[index].emailManagerLV2
                : ""
              : "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelRequesttime[index].isDoubleApproval === 1
              ? dataToExcelRequesttime[index].isManagerLV1Approve &&
                dataToExcelRequesttime[index].isManagerLV1Approve === null
                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                : dataToExcelRequesttime[index].isManagerLV2Approve &&
                  dataToExcelRequesttime[index].isManagerLV2Approve === null
                ? "รออนุมัติ"
                : dataToExcelRequesttime[index].isManagerLV2Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ"
              : dataToExcelRequesttime[index].approvalLevel === 2
              ? dataToExcelRequesttime[index].isManagerLV2Approve &&
                dataToExcelRequesttime[index].isManagerLV2Approve === null
                ? "รออนุมัติ"
                : dataToExcelRequesttime[index].isManagerLV2Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ"
              : "",
        };
        elementListRequesttime.push(element);
      }

      dataToExcelOT = requestTimeList.filter((x) => {
        return (
          x.idRequestType === 2 &&
          (x.isDoubleApproval === 1
            ? x.isManagerLV1Approve !== null || x.isManagerLV2Approve !== null
            : x.isManagerLV1Approve !== null)
        );
      });

      for (let index = 0; index < dataToExcelOT.length; index++) {
        let findPayroll = payrollSettingList.find(
          (x) => x.idCompany === dataToExcelOT[index].idCompany
        );

        if (!findPayroll) {
          findPayroll = payrollSettingList[0];
        }
        const element = {
          // ลำดับที่: dataToExcel[index].idRequestTime,
          รหัสพนักงาน: dataToExcelOT[index].employeeId,
          "ชื่อ-สกุล": `${dataToExcelOT[index].firstname} ${dataToExcelOT[index].lastname}`,
          ตำแหน่ง: dataToExcelOT[index].positionsName
            ? dataToExcelOT[index].positionsName
            : "",
          ประเภทคำขอ: dataToExcelOT[index].name
            ? dataToExcelOT[index].name
            : "",
          เวลาเริ่มต้น: dataToExcelOT[index].startText
            ? dataToExcelOT[index].startText
            : "",
          เวลาสิ้นสุด: dataToExcelOT[index].endText
            ? dataToExcelOT[index].endText
            : "",
          [`OT ${findPayroll.xWorkingMonthlyHoliday}x (วันหยุด)`]:
            dataToExcelOT[index].xWorkingMonthlyHoliday > 0
              ? `${parseFloat(
                  dataToExcelOT[index].xWorkingMonthlyHoliday / 60
                ).toFixed(2)} ชม.`
              : "",
          [`OT ${findPayroll.xOT}x (วันทำงาน)`]:
            dataToExcelOT[index].xOT > 0
              ? `${parseFloat(dataToExcelOT[index].xOT / 60).toFixed(2)} ชม.`
              : "",
          [`OT ${findPayroll.xWorkingDailyHoliday}x`]:
            dataToExcelOT[index].xWorkingDailyHoliday > 0
              ? `${parseFloat(
                  dataToExcelOT[index].xWorkingDailyHoliday / 60
                ).toFixed(2)} ชม.`
              : "",
          [`OT ${findPayroll.xOTHoliday}x`]:
            dataToExcelOT[index].xOTHoliday > 0
              ? `${parseFloat(dataToExcelOT[index].xOTHoliday / 60).toFixed(
                  2
                )} ชม.`
              : "",
          เหตุผล: dataToExcelOT[index].requestReasonName
            ? dataToExcelOT[index].requestReasonName
            : "-",
          "เหตุผล(เพิ่มเติม)": dataToExcelOT[index].otherReason
            ? dataToExcelOT[index].otherReason
            : "-",
          วันเวลาที่ทำรายการ: dataToExcelOT[index].createDateText
            ? dataToExcelOT[index].createDateText
            : "-",
          "ผู้บังคับบัญชาลำดับที่ 1": dataToExcelOT[index].managerLV1Name
            ? dataToExcelOT[index].managerLV1Name
            : "-",
          "E-mail ผู้บังคับบัญชาลำดับที่ 1": dataToExcelOT[index]
            .emailManagerLV1
            ? dataToExcelOT[index].emailManagerLV1
            : "-",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
            dataToExcelOT[index].isManagerLV1Approve &&
            dataToExcelOT[index].isManagerLV1Approve === null
              ? "รออนุมัติ"
              : dataToExcelOT[index].isManagerLV1Approve === 1
              ? "อนุมัติ"
              : "ไม่อนุมัติ",
          "ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelOT[index].isDoubleApproval === 1
              ? dataToExcelOT[index].managerLV2Name
                ? dataToExcelOT[index].managerLV2Name
                : ""
              : dataToExcelOT[index].approvalLevel === 2
              ? dataToExcelOT[index].managerLV2Name
                ? dataToExcelOT[index].managerLV2Name
                : ""
              : "",
          "E-mail ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelOT[index].isDoubleApproval === 1
              ? dataToExcelOT[index].emailManagerLV2
                ? dataToExcelOT[index].emailManagerLV2
                : ""
              : dataToExcelOT[index].approvalLevel === 2
              ? dataToExcelOT[index].emailManagerLV2
                ? dataToExcelOT[index].emailManagerLV2
                : ""
              : "",
          "ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
            dataToExcelOT[index].isDoubleApproval === 1
              ? dataToExcelOT[index].isManagerLV1Approve &&
                dataToExcelOT[index].isManagerLV1Approve === null
                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                : dataToExcelOT[index].isManagerLV2Approve &&
                  dataToExcelOT[index].isManagerLV2Approve === null
                ? "รออนุมัติ"
                : dataToExcelOT[index].isManagerLV2Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ"
              : dataToExcelOT[index].approvalLevel === 2
              ? dataToExcelOT[index].isManagerLV2Approve &&
                dataToExcelOT[index].isManagerLV2Approve === null
                ? "รออนุมัติ"
                : dataToExcelOT[index].isManagerLV2Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ"
              : "",
        };
        elementListOT.push(element);
      }
    }

    if (leaveEmployeeList) {
      dataToExcel = leaveEmployeeList.filter((x) => x.isApprove !== null);

      for (let index = 0; index < dataToExcel.length; index++) {
        const element = {
          // ลำดับที่: dataToExcel[index].idRequestTime,
          รหัสพนักงาน: dataToExcel[index].employeeId,
          "ชื่อ-สกุล": `${dataToExcel[index].firstname} ${dataToExcel[index].lastname}`,
          ตำแหน่ง: dataToExcel[index].positionsName
            ? dataToExcel[index].positionsName
            : "",
          ประเภทคำขอ: dataToExcel[index].name ? dataToExcel[index].name : "",
          เวลาเริ่มต้น: dataToExcel[index].startText
            ? dataToExcel[index].startText
            : "",
          เวลาสิ้นสุด: dataToExcel[index].endText
            ? dataToExcel[index].endText
            : "",
          เหตุผล: dataToExcel[index].description
            ? dataToExcel[index].description
            : "-",
          "เหตุผล(เพิ่มเติม)": dataToExcel[index].otherReason
            ? dataToExcel[index].otherReason
            : "-",
          วันเวลาที่ทำรายการ: dataToExcel[index].createDateText
            ? dataToExcel[index].createDateText
            : "-",
          ผู้บังคับบัญชา: dataToExcel[index].managerLV1Name
            ? dataToExcel[index].managerLV1Name
            : "-",
          "E-mail ผู้บังคับบัญชา": dataToExcel[index].emailManagerLV1
            ? dataToExcel[index].emailManagerLV1
            : "-",
          "ผลอนุมัติ ผู้บังคับบัญชา":
            dataToExcel[index].isApprove === null
              ? "รออนุมัติ"
              : dataToExcel[index].isApprove === 1
              ? "อนุมัติ"
              : "ไม่อนุมัติ",
        };
        elementListLeave.push(element);
      }
    } else {
    }

    //const wsWaiting = XLSX.utils.json_to_sheet(elementListWaiting);
    const wsRequesttime = XLSX.utils.json_to_sheet(elementListRequesttime);
    const wsOT = XLSX.utils.json_to_sheet(elementListOT);
    const wsLeave = XLSX.utils.json_to_sheet(elementListLeave);

    var workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, wsRequesttime, "รับรองเวลาทำงาน");
    XLSX.utils.book_append_sheet(workbook, wsOT, "ทำงานล่วงเวลา");
    XLSX.utils.book_append_sheet(workbook, wsLeave, "ลางาน");
    XLSX.writeFile(workbook, `สรุปประวัติคำขออนุมัติ.xlsx`, {
      type: "file",
    });
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" style={{ paddingBottom: 24 }}>
        <StyledHeaderTopic>
          <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
            ประวัติการอนุมัติ
          </Typography>
          <div className="divButton">
            <ButtonBlue
              variant="contained"
              style={{ height: "fit-content" }}
              startIcon={<TextSnippetIcon />}
              onClick={() => props.history.push("/manager/approve")}
            >
              รายการรออนุมัติ
            </ButtonBlue>
          </div>
        </StyledHeaderTopic>
        <Box style={{ paddingBottom: 16 }}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  วันที่เริ่มต้น
                </Typography>
                <div className="search-date">
                  <LocalizationProvider
                    // dateAdapter={AdapterDateFns}
                    dateAdapter={AdapterDayjs}
                    locale={th}
                  >
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      // inputFormat="dd/MM/yyyy"
                      value={search.start}
                      name="start"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch({
                          ...search,
                          start: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          variant="filled"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  วันที่สิ้นสุด
                </Typography>
                <div className="search-date">
                  <LocalizationProvider
                    // dateAdapter={AdapterDateFns}
                    dateAdapter={AdapterDayjs}
                    locale={th}
                  >
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      // inputFormat="dd/MM/yyyy"
                      value={search.end}
                      name="start"
                      views={["year", "month", "day"]}
                      onChange={(newValue) => {
                        setSearch({
                          ...search,
                          end: newValue,
                        });
                      }}
                      renderInput={(params) => (
                        <StyledTextField
                          variant="filled"
                          fullWidth
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  ค้นหาชื่อ-สกุล
                </Typography>
                <FormControl fullWidth variant="filled">
                  <StyledFilledInput
                    id="filled-sarch-name"
                    placeholder="ค้นหาชื่อ-สกุล"
                    name="searchName"
                    onChange={handleChangeSearch}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearchButton>
                <div style={{ paddingBottom: 12 }}>
                  <ButtonBlue variant={"contained"} onClick={handleClickSearch}>
                    ค้นหา
                  </ButtonBlue>
                </div>
                <div>
                  <ButtonBlue
                    onClick={excelExport}
                    variant="outlined"
                    startIcon={<DownloadRoundedIcon />}
                  >
                    ดาวน์โหลด
                  </ButtonBlue>
                </div>
              </StyledBoxSearchButton>
            </Grid>
          </Grid>
        </Box>
        <StyledCard>
          <Box>
            <Tabs
              value={approveTabsValue}
              onChange={handleApproveTabsChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
            >
              <Tab label="ลางาน" {...a11yProps(0)} />
              <Tab label="รับรองเวลาทำงาน" {...a11yProps(1)} />
              <Tab label="ทำงานล่วงเวลา" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <Box style={{ width: "100%" }}>
            <TabPanel value={valueTab} index={0}>
              <TabPanel value={approveTabsValue} index={0}>
                <TableApproval searchName={searchName} />
              </TabPanel>
              <TabPanel value={approveTabsValue} index={1}>
                <TableTimeApproval
                  menu={approveTabsValue}
                  searchName={searchName}
                />
              </TabPanel>
              <TabPanel value={approveTabsValue} index={2}>
                <TableOTApproval
                  searchName={searchName}
                  menu={approveTabsValue}
                />
              </TabPanel>
            </TabPanel>
          </Box>
        </StyledCard>
      </Container>
    </StyledRoot>
  );
}

export default Requestlist;
