import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_MESSAGE } from "./types";

import AuthService from "../services/auth.service";

export const login = (username, password) => async (dispatch) => {
  try {
    const res = await AuthService.login(username, password);
    if (res) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: res.data },
      });
      if (res.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(res.data));
      } else {
        dispatch({
          type: LOGIN_FAIL,
        });
      }
      return res.data;
    }
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
    });
    if (error.response) {
      return error.response.data;
    }
  }
};

export const changePassword = (formData) => async () => {
  try {
    const res = await AuthService.changePassword(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
};

export const resetPassword = (formData) => async () => {
  try {
    const res = await AuthService.resetPassword(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};
