import * as React from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateRangePicker from "@mui/lab/DateRangePicker";
import Box from "@mui/material/Box";

import SaveIcon from "@mui/icons-material/Save";

import dayjs from "dayjs";

import ButtonOrange from "../../../shared/general/ButtonOrange";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const StyledCard = styled(Card)(({}) => ({
  borderRadius: 8,
  "& .MuiCardHeader-root": {
    padding: 4,
  },
}));

const StyledFormControlSelect = styled(FormControl)(({}) => ({
  marginTop: 16,
  marginBottom: 16,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

const StyledAvatar = styled(Avatar)(({}) => ({
  width: 40,
  height: 40,
}));

const StyledList = styled(List)(({}) => ({
  width: 300,
  height: 230,
  paddingTop: 0,
  bgcolor: "background.paper",
  overflow: "auto",
  "& .MuiListItemAvatar-root": {
    minWidth: 0,
    marginRight: 8,
  },
}));

const StyledDialog = styled(Dialog)(({}) => ({
  "& .textFieldTheme": {
    marginTop: 16,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
}));

const StyledDatePicker = styled(TextField)(({}) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiOutlinedInput-input": {
    padding: "8.5px 14px",
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

export default function DialogSwitchShift(props) {
  const { open, handleClose, value, pattern } = props;
  const [checked, setChecked] = React.useState([]);
  const [radioDateRange, setRadioDateRange] = React.useState("");
  const [dateRangePicker, setDateRangePicker] = React.useState([null, null]);
  const [datePicker, setDatePicker] = React.useState(null);
  const [left, setLeft] = React.useState([
    "Krishan Benson",
    "Yash Zhang",
    "Bryn Blevins",
    "Dolores Bartlett",
    "Dominic Norman",
    "Evalyn Firth",
    "Rocky Aldred",
    "Eilish Cisneros",
    "Mitchel Velasquez",
    "Cherry Hayes",
    "Haider Stanley",
    "Heather Barker",
    "Everett Osborne",
    "Mischa Alford",
  ]);
  const [right, setRight] = React.useState([
    "Norma Silva",
    "Winnie Mckeown",
    "Mara Marks",
    "Skylar Browning",
    "Hiba Black",
    "Kierran Wills",
    "Samantha Potter",
    "Coral Cardenas",
    "Jayden Peck",
    "Korban Norton",
    "Glyn Hoover",
    "Isabelle Paul",
    "Hetty Kerr",
    "Orlando Corona",
    "Reggie Olsen",
  ]);

  const [shiftSelect, setShiftSelect] = React.useState({
    shiftLeft: "",
    shiftRight: "",
  });

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <StyledCard variant="outlined">
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <StyledList dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemAvatar>
                <StyledAvatar
                  alt={"username"}
                  src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                />
              </ListItemAvatar>
              <ListItemText
                id={labelId}
                primary={`${value}`}
                secondary="position"
              />
            </ListItemButton>
          );
        })}
        <ListItem />
      </StyledList>
    </StyledCard>
  );

  const handleChangeShiftSelect = (event) => {
    console.log(event.target.value);
    setShiftSelect({ ...shiftSelect, [event.target.name]: event.target.value });
  };

  const handleChangeRadioDateRange = (event) => {
    setRadioDateRange(event.target.value);
  };

  return (
    <div>
      <StyledDialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"สลับกะทำงาน"}</DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">เลือกช่วงเวลาที่เปลี่ยน</FormLabel>
              <RadioGroup
                row
                aria-label="gender"
                value={radioDateRange}
                onChange={handleChangeRadioDateRange}
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="range"
                  control={<Radio />}
                  label="ช่วงเวลา"
                />
                <FormControlLabel
                  value="date"
                  control={<Radio />}
                  label="วันที่"
                />
              </RadioGroup>
            </FormControl>
            <div>
              {radioDateRange === "range" && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    startText="วันที่เริ่มต้น"
                    endText="วันที่สิ้นสุด"
                    value={dateRangePicker}
                    onChange={(newValue) => {
                      setDateRangePicker(newValue);
                    }}
                    renderInput={(startProps, endProps) => (
                      <React.Fragment>
                        <StyledDatePicker {...startProps} />
                        <Box sx={{ mx: 2 }}> to </Box>
                        <StyledDatePicker {...endProps} />
                      </React.Fragment>
                    )}
                  />
                </LocalizationProvider>
              )}
              {radioDateRange === "date" && (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Basic example"
                    value={datePicker}
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                    }}
                    renderInput={(params) => <StyledDatePicker {...params} />}
                  />
                </LocalizationProvider>
              )}
            </div>
          </div>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <div>
                <StyledFormControlSelect fullWidth>
                  <InputLabel id="demo-simple-select-label">เลือกกะ</InputLabel>
                  {pattern ? (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={shiftSelect.shiftLeft}
                      name="shiftLeft"
                      onChange={handleChangeShiftSelect}
                      size="small"
                      label="เลือกกะ"
                    >
                      <MenuItem
                        value={`33A`}
                        disabled={`33A` === shiftSelect.shiftRight}
                      >
                        3 หยุด 3 : Shift A
                      </MenuItem>
                      <MenuItem
                        value={`33B`}
                        disabled={`33B` === shiftSelect.shiftRight}
                      >
                        3 หยุด 3 : Shift B
                      </MenuItem>
                      <MenuItem
                        value={`33C`}
                        disabled={`33C` === shiftSelect.shiftRight}
                      >
                        3 หยุด 3 : Shift C
                      </MenuItem>
                      <MenuItem
                        value={`33D`}
                        disabled={`33D` === shiftSelect.shiftRight}
                      >
                        3 หยุด 3 : Shift D
                      </MenuItem>
                      <MenuItem
                        value={`42A`}
                        disabled={`42A` === shiftSelect.shiftRight}
                      >
                        4 หยุด 2 : Shift A
                      </MenuItem>
                      <MenuItem
                        value={`42B`}
                        disabled={`42B` === shiftSelect.shiftRight}
                      >
                        4 หยุด 2 : Shift B
                      </MenuItem>
                      <MenuItem
                        value={`42C`}
                        disabled={`42C` === shiftSelect.shiftRight}
                      >
                        4 หยุด 2 : Shift C
                      </MenuItem>
                      <MenuItem
                        value={`42D`}
                        disabled={`42D` === shiftSelect.shiftRight}
                      >
                        4 หยุด 2 : Shift D
                      </MenuItem>
                    </Select>
                  ) : (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={shiftSelect.shiftLeft}
                      name="shiftLeft"
                      onChange={handleChangeShiftSelect}
                      size="small"
                      label="เลือกกะ"
                    >
                      <MenuItem
                        value={`A`}
                        disabled={`A` === shiftSelect.shiftRight}
                      >
                        Shift A
                      </MenuItem>
                      <MenuItem
                        value={`B`}
                        disabled={`B` === shiftSelect.shiftRight}
                      >
                        Shift B
                      </MenuItem>
                      <MenuItem
                        value={`C`}
                        disabled={`C` === shiftSelect.shiftRight}
                      >
                        Shift C
                      </MenuItem>
                      <MenuItem
                        value={`D`}
                        disabled={`D` === shiftSelect.shiftRight}
                      >
                        Shift D
                      </MenuItem>
                    </Select>
                  )}
                </StyledFormControlSelect>
                {customList("เลือกทั้งหมด", left)}
              </div>
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <div>
                <StyledFormControlSelect fullWidth>
                  <InputLabel id="demo-simple-select-label">เลือกกะ</InputLabel>
                  {pattern ? (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={shiftSelect.shiftRight}
                      name="shiftRight"
                      onChange={handleChangeShiftSelect}
                      size="small"
                      label="เลือกกะ"
                    >
                      <MenuItem
                        value={`33A`}
                        disabled={`33A` === shiftSelect.shiftLeft}
                      >
                        3 หยุด 3 : Shift A
                      </MenuItem>
                      <MenuItem
                        value={`33B`}
                        disabled={`33B` === shiftSelect.shiftLeft}
                      >
                        3 หยุด 3 : Shift B
                      </MenuItem>
                      <MenuItem
                        value={`33C`}
                        disabled={`33C` === shiftSelect.shiftLeft}
                      >
                        3 หยุด 3 : Shift C
                      </MenuItem>
                      <MenuItem
                        value={`33D`}
                        disabled={`33D` === shiftSelect.shiftLeft}
                      >
                        3 หยุด 3 : Shift D
                      </MenuItem>
                      <MenuItem
                        value={`42A`}
                        disabled={`42A` === shiftSelect.shiftLeft}
                      >
                        4 หยุด 2 : Shift A
                      </MenuItem>
                      <MenuItem
                        value={`42B`}
                        disabled={`42B` === shiftSelect.shiftLeft}
                      >
                        4 หยุด 2 : Shift B
                      </MenuItem>
                      <MenuItem
                        value={`42C`}
                        disabled={`42C` === shiftSelect.shiftLeft}
                      >
                        4 หยุด 2 : Shift C
                      </MenuItem>
                      <MenuItem
                        value={`42D`}
                        disabled={`42D` === shiftSelect.shiftLeft}
                      >
                        4 หยุด 2 : Shift D
                      </MenuItem>
                    </Select>
                  ) : (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={shiftSelect.shiftRight}
                      name="shiftRight"
                      onChange={handleChangeShiftSelect}
                      size="small"
                      label="เลือกกะ"
                    >
                      <MenuItem
                        value={`A`}
                        disabled={`A` === shiftSelect.shiftLeft}
                      >
                        Shift A
                      </MenuItem>
                      <MenuItem
                        value={`B`}
                        disabled={`B` === shiftSelect.shiftLeft}
                      >
                        Shift B
                      </MenuItem>
                      <MenuItem
                        value={`C`}
                        disabled={`C` === shiftSelect.shiftLeft}
                      >
                        Shift C
                      </MenuItem>
                      <MenuItem
                        value={`D`}
                        disabled={`D` === shiftSelect.shiftLeft}
                      >
                        Shift D
                      </MenuItem>
                    </Select>
                  )}
                </StyledFormControlSelect>
                {customList("เลือกทั้งหมด", right)}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#212b36" }}>
            ยกเลิก
          </Button>
          <ButtonOrange
            style={{}}
            size=""
            value={"บันทึกข้อมูล"}
            variant={"contained"}
            startIcon={<SaveIcon />}
          />
        </DialogActions>
      </StyledDialog>
    </div>
  );
}
