import { Divider, Grid, styled, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

import dayjs from "dayjs";
import {
  deleteManagerDetail,
  getEmployeeProfile,
} from "../../../../../../actions/employee";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogEdit from "./DialogEdit";
import utils from "../../../../../../utils";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "&.inner": {
    marginBottom: 0,
  },
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const WorkInfo = (props) => {
  const { changeGroupTap, changeTabs } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("");

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleClickTransfer = (mode) => {
    changeGroupTap("work");
    changeTabs(7);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteClick = async (idManagerEmployee) => {
    const result = await dispatch(
      deleteManagerDetail(employeeProfile, idManagerEmployee)
    );
    if (result) {
      dispatch(
        getEmployeeProfile(userProfile.idCompany, employeeProfile.idEmp)
      );
    }
  };

  const calculatorESY = () => {
    let Serv = utils.getAgeNumber(
      dayjs(employeeProfile.hiringDate).format("DD/MM/YYYY")
    );

    let monthAge = (Serv.months + employeeProfile.OESY_M) % 12;
    let yearAge = Math.floor((Serv.months + employeeProfile.OESY_M) / 12);

    return `${Serv.years + employeeProfile.OESY_Y + yearAge} ${
      localStorage.getItem("language") === "en" ? " years" : " ปี"
    } ${monthAge} ${
      localStorage.getItem("language") === "en" ? " months" : " เดือน"
    }`;
  };

  return (
    <div>
      <div>
        <StyledWrapTop>
          <StyledHeadLabel variant="body2" gutterBottom>
            {/* วันที่เข้างาน/วันต่อสัญญา */}
            {t("StartWork")}/{t("RenewalDate")}
          </StyledHeadLabel>
          <div>
            <ButtonBlue
              size="small"
              startIcon={<EditRoundedIcon />}
              onClick={() => handleClick("resign")}
            >
              {t("Edit")}
            </ButtonBlue>
          </div>
        </StyledWrapTop>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              {/* วันที่เข้างาน */}
              {t("StartWork")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.hiringDate
              ? dayjs(employeeProfile.hiringDate)
                  .locale(localStorage.getItem("language") || "th")
                  .format(
                    localStorage.getItem("language") === "th"
                      ? "DD MMMM BBBB"
                      : "DD MMMM YYYY"
                  )
              : "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              {/* วันต่อสัญญา */}
              {t("RenewalDate")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.resignDate
              ? dayjs(employeeProfile.resignDate)
                  .locale(localStorage.getItem("language") || "th")
                  .format(
                    localStorage.getItem("language") === "th"
                      ? "DD MMMM BBBB"
                      : "DD MMMM YYYY"
                  )
              : "-"}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        <div>
          <ButtonBlue
            size="small"
            endIcon={<KeyboardArrowRightRoundedIcon />}
            onClick={handleClickTransfer}
          >
            {t("Transfer")}/{t("Move")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("Company")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.companyName
                ? employeeProfile.companyName
                    // .replace("บริษัท ", "")
                    // .replace(" จำกัด", "")
                    .replace(`${t("Company")} `, "")
                    .replace(` ${t("Ltd")}`, "")
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("Division")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.divisionName
                ? employeeProfile.divisionName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("Department")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.departmentName
                ? employeeProfile.departmentName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("Section")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.sectionName ? employeeProfile.sectionName : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("Position")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.positionsName
                ? employeeProfile.positionsName
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditRoundedIcon />}
            onClick={() => handleClick("experience")}
          >
            {t("Edit")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">OESY</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.OESY_Y !== null ||
              employeeProfile.OESY_M !== null ? (
                <Fragment>
                  {employeeProfile.OESY_Y &&
                    `${employeeProfile.OESY_Y} ${
                      localStorage.getItem("language") === "en"
                        ? " years"
                        : " ปี"
                  }`}{" "}
                  &nbsp;
                  {employeeProfile.OESY_M &&
                    `${employeeProfile.OESY_M} ${
                      localStorage.getItem("language") === "en"
                        ? " months"
                        : " เดือน"
                  }`}
                </Fragment>
              ) : (
                <Fragment>{`-`}</Fragment>
              )}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">Serv.</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.hiringDate
                ? `${utils.getAgeMonthYear(
                    dayjs(employeeProfile.hiringDate).format("DD/MM/YYYY")
                  )}`
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">ESY</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.hiringDate ? calculatorESY() : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">TIG</StyledHeadLabel>
            <Typography variant="h6">{"-"}</Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom></StyledHeadLabel>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditRoundedIcon />}
            onClick={() => handleClick("employeeType")}
          >
            {t("Edit")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("JobGroup")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobGroupName
                ? employeeProfile.jobGroupName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("JobLevel")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.jobLevelName
                ? employeeProfile.jobLevelName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("PersonalLevel")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.personnelLevelName
                ? employeeProfile.personnelLevelName
                : "-"}
            </Typography>
          </div>
        </Grid>
        {/* <Grid item xs={12} sm={6}> */}
          {/* <div>
            <StyledHeadLabel color="text.secondary">ระดับ</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.personnelLevelName
                ? employeeProfile.personnelLevelName
                : "-"}
            </Typography>
          </div> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">ประเภท</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employeeTypeName
                ? employeeProfile.employeeTypeName
                : "-"}
            </Typography>
          </div>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("EmploymentTime")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.employmentTime
                ? employeeProfile.employmentTime
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">{t("WorkingLocation")}</StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.workingLocationName
                ? employeeProfile.workingLocationName
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              Filed/Office
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.filed_office
                ? employeeProfile.filed_office
                : "-"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("Finger")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isFinger && employeeProfile.isFinger === 1
                ? `${t("Yes")}`
                : `${t("No")}`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("MethodAttendance")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.methodAttendance &&
              employeeProfile.methodAttendance === "all"
                ? `${t("AllLocation")}`
                : employeeProfile.methodAttendance === "point"
                ? `${t("Coordinates")}`
                : employeeProfile.mainLocation
                ? employeeProfile.mainLocation
                : "-"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <div>
        <StyledWrapTop>
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("Manager")}
          </StyledHeadLabel>
        </StyledWrapTop>

        <Fragment>
          <Fragment>
            <div style={{ marginBottom: 24 }}>
              <StyledWrapTop className="inner">
                <StyledHeadLabel variant="body2" gutterBottom>
                  {`*${t("NoNumber")} 1`}
                </StyledHeadLabel>
                <div>
                  <ButtonBlue
                    size="small"
                    startIcon={<EditRoundedIcon />}
                    onClick={() => handleClick("managerLV1")}
                  >
                    {t("Edit")}
                  </ButtonBlue>
                </div>
              </StyledWrapTop>
              <div>
                <StyledHeadLabel color="text.secondary">
                  {t("FullName")}
                </StyledHeadLabel>
                <Typography variant="h6">
                  {employeeProfile.managerLV1Name}
                </Typography>
              </div>
              <div>
                <StyledHeadLabel color="text.secondary">
                  {t("Position")}
                </StyledHeadLabel>
                <Typography variant="h6">
                  {employeeProfile.managerLV1Position}
                </Typography>
              </div>
              <div>
                <StyledHeadLabel color="text.secondary">E-mail</StyledHeadLabel>
                <Typography variant="h6">
                  {employeeProfile.managerLV1Email}
                </Typography>
              </div>
            </div>
            <div style={{ marginBottom: 24 }}>
              <StyledWrapTop className="inner">
                <StyledHeadLabel variant="body2" gutterBottom>
                  {`*${t("NoNumber")} 2`}
                </StyledHeadLabel>
                <div>
                  <ButtonBlue
                    size="small"
                    startIcon={<EditRoundedIcon />}
                    onClick={() => handleClick("managerLV2")}
                  >
                    {t("Edit")}
                  </ButtonBlue>
                </div>
              </StyledWrapTop>
              {employeeProfile.idManagerLV2 ? (
                <Fragment>
                  <div>
                    <StyledHeadLabel color="text.secondary">
                      {t("FullName")}
                    </StyledHeadLabel>
                    <Typography variant="h6">
                      {employeeProfile.managerLV2Name}
                    </Typography>
                  </div>
                  <div>
                    <StyledHeadLabel color="text.secondary">
                      {t("Position")}
                    </StyledHeadLabel>
                    <Typography variant="h6">
                      {employeeProfile.managerLV2Position}
                    </Typography>
                  </div>
                  <div>
                    <StyledHeadLabel color="text.secondary">
                      E-mail
                    </StyledHeadLabel>
                    <Typography variant="h6">
                      {employeeProfile.managerLV2Email}
                    </Typography>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <Typography
                    color="text.secondary"
                    style={{ fontStyle: "oblique" }}
                  >
                    {t("NoManageHasBeenSpecify")} 2
                  </Typography>
                </Fragment>
              )}
            </div>
          </Fragment>
        </Fragment>
      </div>
      <StyledDivider />
      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </div>
  );
};

export default WorkInfo;
