import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";

import { Typography } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import AlertResponse from "../../../../shared/general/AlertResponse";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogEdit from "./DialogEdit";

import { 
  getAllPerformancesByIdEmp
} from "../../../../../../actions/performance";

const StyledRoot = styled("div")({
  "& .button-show-performance": {
    display: "flex",
    justifyContent: "center",
    margin: "16px 0",
  },
});

const StyleWrapFiveYear = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const Performance = () => {
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: performanceEmployee } = useSelector((state) => state.performanceEmployee);
  const [openEdit, setOpenEdit] = useState(false);
  const [statusAlert, setStatusAlert] = useState({
    isOpen: false,
    alertType: null
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if(employeeProfile){
      dispatch(getAllPerformancesByIdEmp(employeeProfile.idEmp));
    }
  },[employeeProfile]);

  const FindPerformance = (topicYear) => {
    if (
      performanceEmployee && 
      performanceEmployee.length > 0
    ) {
      let indexResult = -1;
      performanceEmployee.map((performance, index) => {
        if (performance.yearPerformance === parseInt(topicYear)) {
          indexResult = index;
        }
      });
      return indexResult;
    } else {
      return -1;
    }
  };

  const findLatestPerformance = () => {
    let data = {
      result: "-",
      year: dayjs()
        .subtract(1, "year")
        .locale(localStorage.getItem("language") || "th")
        .format(localStorage.getItem("language") === "th" ? "BBBB" : "YYYY"),
    };

    if (performanceEmployee && performanceEmployee.length > 0) {
      data.result = performanceEmployee[0].performanceName;
      data.year = dayjs()
        .set("year", performanceEmployee[0].yearPerformance)
        .locale(localStorage.getItem("language") || "th")
        .format(localStorage.getItem("language") === "th" ? "BBBB" : "YYYY");
    }

    return (
      <Fragment>
        <Typography variant="h4" align="center">
          {data.result}
        </Typography>
        <Typography variant="body1" align="center" color="text.secondary">
          {data.year}
        </Typography>
      </Fragment>
    );
  };

  const onHandleOpenAlert = (alertType) => {
    setStatusAlert({
      isOpen: true,
      alertType: alertType
    });
  };

  const onHandleCloseAlert = () => {
    setStatusAlert({
      isOpen: false,
      alertType: null
    });
  };

  return (
    <StyledRoot>
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("LatestPerformance")}
        </StyledHeadLabel>
        <div>
          <ButtonBlue
            size="small"
            startIcon={<EditIcon />}
            onClick={() => setOpenEdit(true)}
          >
            {t("Edit")}
          </ButtonBlue>
        </div>
      </StyledWrapTop>
      <div>{performanceEmployee && findLatestPerformance()}</div>
      <StyledWrapTop className="five-year">
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("SummaryWorkPast5Years")}
        </StyledHeadLabel>
      </StyledWrapTop>
      <StyleWrapFiveYear>
      {[...Array(5).keys()].map((item, index) => (
          <div key={item}>
            <Typography variant="h4" align="center">
              {FindPerformance(
                dayjs()
                  .subtract(index + 1, "year")
                  .locale(localStorage.getItem("language") || "th")
                  .format(
                    localStorage.getItem("language") === "th" ? "BBBB" : "YYYY"
                  )
              ) >= 0
                ? employeeProfile.performance[
                    FindPerformance(
                      dayjs()
                        .subtract(index + 1, "year")
                        .format("YYYY")
                    )
                  ].evaluation
                : "-"}
            </Typography>
            <Typography variant="body1" align="center" color="text.secondary">
              {dayjs()
                .subtract(item + 2, "year")
                .locale(localStorage.getItem("language") || "th")
                .format(
                  localStorage.getItem("language") === "th" ? "BBBB" : "YYYY"
                )}
            </Typography>
          </div>
        ))}
      </StyleWrapFiveYear>
      {openEdit && 
        <DialogEdit 
          open={openEdit} 
          setOpenEdit={setOpenEdit}
          onHandleOpenAlert={onHandleOpenAlert}
        />
      }
      {statusAlert.isOpen && 
        <AlertResponse 
          open={statusAlert.isOpen}
          handleClose={onHandleCloseAlert}
          alertType={statusAlert.alertType}
        />
      }
    </StyledRoot>
  );
};

export default Performance;
