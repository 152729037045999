import * as React from "react";
import { styled } from "@mui/material/styles";
import StyledCard from "../../../shared/general/Card";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import EngineeringIcon from "@mui/icons-material/Engineering";
import UpdateIcon from "@mui/icons-material/Update";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LoopIcon from "@mui/icons-material/Loop";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";

import dayjs from "dayjs";

import AvatarShift from "../../../shared/general/AvatarShift";
import DialogSwitchShift from "./dialogSwitchShift";

import ButtonOrange from "../../../shared/general/ButtonOrange";
import TimePicker from "../../../shared/general/TimePicker";
import { Grid } from "@mui/material";

const StyledSection = styled("div")(({ shift }) => ({
  flexGrow: 1,
  display: "flex",
  padding: 16,
  paddingTop: 8,
  height: 350,
}));

const StyledWrapHead = styled("div")(({ shift }) => ({
  padding: 16,
  paddingBottom: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiTypography-h5": {
    marginLeft: 8,
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

const StyledAccordion = styled(Accordion)({
  boxShadow: "none",
  border: "1px solid #0000001f",
  margin: "0 !important",
  backgroundColor: "#f3f6f8",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&:first-of-type": {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  "&:last-of-type": {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  height: 52,
  "& .MuiAccordionSummary-content": {
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      marginRight: 4,
      fontSize: 28,
      color: "#999999",
    },
  },
  "&.Mui-expanded": {
    height: "52px !important",
    minHeight: "52px !important",
  },
  "& .MuiTypography-subtitle1": {
    color: "#007afe",
    fontWeight: 600,
    fontSize: 18,
  },
  "& .MuiTypography-root": {
    lineHeight: 1.4,
  },
  "& .MuiTypography-body1": {},
  "& .MuiTypography-body2": {
    color: "#999999",
    marginLeft: 4,
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  backgroundColor: "#FFFFFF",
  padding: 16,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  "& .inner-detail": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fontSize: 42,
      color: "#0000008a",
    },
  },
});

const StyledAvatar = styled(Avatar)({
  width: 50,
  height: 50,
});

const StyledBox = styled(Box)({
  width: 120,
  padding: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledListEmployee = styled("div")({
  marginTop: 16,
  display: "flex",
  flexWrap: "wrap",
  maxHeight: 350,
  overflow: "auto",
});

export default function EventSwitchShift(props) {
  return (
    <StyledCard>
      <div>
          <StyledWrapHead>
            <div>
              <Typography
                variant="h5"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <UpdateIcon style={{ fontSize: 36, marginRight: 8 }} />
                {`แผนการสลับกะ`}
              </Typography>
            </div>
          </StyledWrapHead>
          <div style={{ padding: 16, paddingTop: 8 }}>
            <StyledAccordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <LoopIcon />
                <div>
                  <Typography variant="subtitle1">14/12/2564</Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2">สลับกะ </Typography>
                  </div>
                </div>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                <div className={`inner-detail`}>
                  <AvatarShift fontSize={28} title={`A`} size={50} />
                  <ChevronRightIcon />
                  <AvatarShift fontSize={28} title={`C`} size={50} />
                </div>
                <StyledListEmployee>
                  {[...Array(12).keys()].map((value) => {
                    return (
                      <StyledBox>
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <StyledAvatar
                            alt={"username"}
                            src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                          />
                        </Badge>
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <Typography align="center" variant="body1">
                            Lauryn
                          </Typography>
                          <Typography align="center" variant="body1">
                            Zimmerman
                          </Typography>
                          <Typography
                            align="center"
                            variant="body1"
                            style={{
                              color: "#999999",
                              fontStyle: "oblique",
                            }}
                          >
                            position
                          </Typography>
                        </div>
                      </StyledBox>
                    );
                  })}
                </StyledListEmployee>
              </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <SyncProblemIcon />
                <div>
                  <Typography variant="subtitle1">
                    {" "}
                    17/12/2564 ถึง 25/01/2565
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2">ย้ายรูปแบบกะ </Typography>
                  </div>
                </div>
              </StyledAccordionSummary>
              <StyledAccordionDetails>
              <div className={`inner-detail`}>
                  <AvatarShift fontSize={28} title={`B`} size={50} />
                  <ChevronRightIcon />
                  <AvatarShift fontSize={28} title={`D`} size={50} />
                </div>
                <StyledListEmployee>
                  {[...Array(6).keys()].map((value) => {
                    return (
                      <StyledBox>
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                        >
                          <StyledAvatar
                            alt={"username"}
                            src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                          />
                        </Badge>
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <Typography align="center" variant="body1">
                            Lauryn
                          </Typography>
                          <Typography align="center" variant="body1">
                            Zimmerman
                          </Typography>
                          <Typography
                            align="center"
                            variant="body1"
                            style={{
                              color: "#999999",
                              fontStyle: "oblique",
                            }}
                          >
                            position
                          </Typography>
                        </div>
                      </StyledBox>
                    );
                  })}
                </StyledListEmployee>
              </StyledAccordionDetails>
            </StyledAccordion>
          </div>
        </div>
    </StyledCard>
  );
}
