import {
    PERFORMANCE_COMPANY_FETCHING,
    PERFORMANCE_COMPANY_FAILED,
    PERFORMANCE_COMPANY_SUCCESS,
    PERFORMANCE_EMPLOYEE_FETCHING,
    PERFORMANCE_EMPLOYEE_FAILED,
    PERFORMANCE_EMPLOYEE_SUCCESS
} from "./types";
import performanceService from "../services/performance.service";

export const addPerformance = (idEmp, data)  => async () => {
    try {
        const res = await performanceService.addPerformance(idEmp, data);
        if(res){
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const updatePerformance = (idPerformance, data) => async() => {
    try {
        const res = await performanceService.updatePerformance(idPerformance, data);
        if(res) {
            return res.data;
        } 
    } catch (error) {
        return "Error"
    }
};

export const getPerformanceByIdCompany = () => async (dispatch) => {
    try {
        dispatch({
            type: PERFORMANCE_COMPANY_FETCHING
        });
        const res = await performanceService.getPerformanceByIdCompany();
        if(res){
            dispatch({
                type: PERFORMANCE_COMPANY_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: PERFORMANCE_COMPANY_FAILED
        });
    }
};

export const addPerformanceByIdCompany = (data) => async () => {
    try {
        const res = await performanceService.addPerformanceByIdCompany(data);
        if(res){
            return res;
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.name) ||
            error.name ||
            error.toString();
        return "Error";
    }
};

export const updatePerformanceByIdCompany = (data) => async (dispatch) => {
    try {
        const res = await performanceService.updatePerformanceByIdCompany(data);
        if(res){
            return res;
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.name) ||
            error.name ||
            error.toString();
        return "Error";
    }
};

export const deletePerformanceByIdPerformance = (data) => async () => {
    try {
        const res = await performanceService.deletePerformanceByIdPerformance(data);
        if(res){
            return res;
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.name) ||
            error.name ||
            error.toString();
        return "Error";
    }
};

export const getAllPerformancesByIdEmp = (idEmp) => async (dispatch) => {
    try {
        dispatch({
            type: PERFORMANCE_EMPLOYEE_FETCHING
        });
        const res = await performanceService.getAllPerformancesByIdEmp(idEmp);
        if(res){
            dispatch({
                type: PERFORMANCE_EMPLOYEE_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: PERFORMANCE_EMPLOYEE_FAILED
        });
    }
};

export const addPerformanceByIdEmp = (data) => async () => {
    try {
        const res = await performanceService.addPerformanceByIdEmp(data);
        if(res){
            return res;
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.name) ||
            error.name ||
            error.toString();
        return "Error";
    }
};

export const updatePerformanceLastYear = (data) => async () => {
    try {
        const res = await performanceService.updatePerformanceLastYear(data);
        if(res){
            return res;
        }
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.name) ||
            error.name ||
            error.toString();
        return "Error";
    }
};