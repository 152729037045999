import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Alert,
  FormControl,
  Grid,
  RadioGroup,
  FormControlLabel,
  Typography,
  Radio,
  Switch,
  Chip,
  Stack,
  Divider,
} from "@mui/material";

import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import KeyboardDoubleArrowRightRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowRightRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditNoteIcon from "@mui/icons-material/EditNote";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";

import DialogProbation from "./DialogProbation";
import DialogEdit from "./DialogEdit";
import { getCompanyProfile, putCompanyProfile } from "../../../../../actions/company";
import { getProbationQuestion } from "../../../../../actions/probation";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});

const BoxStyled = styled("div")({
  "& .header": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .text": {
      fontSize: 16,
    },
    "& .icon": {
      color: "#637381",
      fontSize: 16,
      marginRight: 8,
    },
  },
  "& .btn-save": {
    justifyContent: "flex-end",
  },
  "& .flex-box": {
    justifyContent: "space-between",
    marginTop: 16,
    display: "flex",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginRight: 8,
});

const label = { inputProps: { "aria-label": "Size switch demo" } };

function Probation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: companyProfile } = useSelector((state) => state.companyProfile);
  const { result: probationQuestion } = useSelector(
    (state) => state.probationQuestion
  );
  const [state, setstate] = useState({
    probationRoundOne: null,
    probationRoundTwo: null,
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [checkedValue, setCheckedValue] = React.useState(null);
  const [openQuestion, setOpenQuestion] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    if (userProfile) {
      dispatch(getCompanyProfile(userProfile.idCompany));
      dispatch(getProbationQuestion({ idCompany: userProfile.idCompany }));
    }
  }, [userProfile]);

  useEffect(() => {
    if (companyProfile) {
      setstate({ 
        probationRoundOne: companyProfile.probationRoundOne,
        probationRoundTwo: companyProfile.probationRoundTwo,
      });
      setCheckedValue({
        RoundOne: parseInt(companyProfile.probationRoundOne) > 0,
        RoundTwo: parseInt(companyProfile.probationRoundTwo) > 0,
      });
    }
  }, [companyProfile]);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOpenQuestion = () => {
    setOpenQuestion(true);
  };

  const handleCloseQuestion = () => {
    setOpenQuestion(false);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleClickSave = async () => {
    const result = await dispatch(putCompanyProfile(userProfile.idCompany, state));
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        dispatch(getCompanyProfile(userProfile.idCompany));
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckedValue({ ...checkedValue, [name]: checked });

    if (checked === false) {
      setstate({
        ...state,
        [`probation${name}`]: null,
      });

      if (name === "RoundOne") {
        setCheckedValue({
          RoundOne: companyProfile.probationRoundOne > 0,
          RoundTwo: companyProfile.probationRoundTwo > 0,
        });
        setstate({
          probationRoundOne: null,
          probationRoundTwo: null,
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      <StyledHeadingPage>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Probation</Typography>
          </Grid>
        </Grid>
      </StyledHeadingPage>
      <CardStyle style={{ padding: 16 }}>
        {checkedValue && companyProfile && (
          <BoxStyled>
            <div className="header">
              <KeyboardDoubleArrowRightRoundedIcon className="icon" />
              <StyledContentLabel variant="body1">
                {`${t("RoundOf")} 1`}
              </StyledContentLabel>
              <div>
                <Switch
                  checked={checkedValue.RoundOne}
                  name="RoundOne"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                />
              </div>
              <Typography
                style={{ marginLeft: 4 }}
                variant="body2"
                color="text.secondary"
              >
                {`(${checkedValue.RoundOne ? t("Active") : t("Inactive")})`}
              </Typography>
            </div>

            <div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="row-probation-round-one"
                  name="probationRoundOne"
                  value={state.probationRoundOne}
                  onChange={(e) => {
                    setstate({
                      ...state,
                      probationRoundOne: e.target.value,
                    });
                    if (e.target.value === "180") {
                    }
                  }}
                >
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label={`30 ${t("Days")}`}
                    disabled={!checkedValue.RoundOne}
                  />
                  <FormControlLabel
                    value="60"
                    control={<Radio />}
                    label={`60 ${t("Days")}`}
                    disabled={!checkedValue.RoundOne}
                  />
                  <FormControlLabel
                    value="90"
                    control={<Radio />}
                    label={`90 ${t("Days")}`}
                    disabled={!checkedValue.RoundOne}
                  />
                  <FormControlLabel
                    value="120"
                    control={<Radio />}
                    label={`120 ${t("Days")}`}
                    disabled={!checkedValue.RoundOne}
                  />
                  <FormControlLabel
                    value="180"
                    control={<Radio />}
                    label={`180 ${t("Days")}`}
                    disabled={!checkedValue.RoundOne}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="header">
              <KeyboardDoubleArrowRightRoundedIcon className="icon" />
              <StyledContentLabel variant="body1">
                {`${t("RoundOf")} 2`}
              </StyledContentLabel>
              <div>
                <Switch
                  checked={checkedValue.RoundTwo}
                  name="RoundTwo"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                  disabled={
                    !(state.probationRoundOne > 0) ||
                    parseInt(state.probationRoundOne) >= 180
                  }
                />
              </div>
              <Typography
                style={{ marginLeft: 4 }}
                variant="body2"
                color="text.secondary"
              >
                {`(${checkedValue.RoundTwo ? t("Active") : t("Inactive")})`}
              </Typography>
            </div>
            <div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="row-probation-round-one"
                  name="probationRoundTwo"
                  value={state.probationRoundTwo}
                  onChange={(e) =>
                    setstate({
                      ...state,
                      probationRoundTwo: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value="30"
                    control={<Radio />}
                    label={`30 ${t("Days")}`}
                    disabled={
                      checkedValue.RoundTwo !== true ||
                      checkedValue.RoundOne !== true ||
                      parseInt(state.probationRoundOne) >= 30
                    }
                  />
                  <FormControlLabel
                    value="60"
                    control={<Radio />}
                    label={`60 ${t("Days")}`}
                    disabled={
                      checkedValue.RoundTwo !== true ||
                      checkedValue.RoundOne !== true ||
                      parseInt(state.probationRoundOne) >= 60
                    }
                  />
                  <FormControlLabel
                    value="90"
                    control={<Radio />}
                    label={`90 ${t("Days")}`}
                    disabled={
                      checkedValue.RoundTwo !== true ||
                      checkedValue.RoundOne !== true ||
                      parseInt(state.probationRoundOne) >= 90
                    }
                  />
                  <FormControlLabel
                    value="120"
                    control={<Radio />}
                    label={`120 ${t("Days")}`}
                    disabled={
                      checkedValue.RoundTwo !== true ||
                      checkedValue.RoundOne !== true ||
                      parseInt(state.probationRoundOne) >= 120
                    }
                  />
                  <FormControlLabel
                    value="180"
                    control={<Radio />}
                    label={`180 ${t("Days")}`}
                    disabled={
                      checkedValue.RoundTwo !== true ||
                      checkedValue.RoundOne !== true ||
                      parseInt(state.probationRoundOne) >= 180
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="btn-save">
              <ButtonBlue
                onClick={handleClickSave}
                variant="contained"
                startIcon={<SaveRoundedIcon />}
              >
                {t("Save")}
              </ButtonBlue>
            </div>
          </BoxStyled>
        )}
      </CardStyle>
      {probationQuestion && (
        <>
          <StyledHeadingPage sx={{ marginTop: "20px" }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">{t("ProbationReviewQuestion")}</Typography>
              </Grid>
              <Grid item>
                <ButtonBlue
                  variant="contained"
                  onClick={handleOpenQuestion}
                  disabled={probationQuestion.length >= 20}
                >
                  {t("AddQuestion")}
                  &nbsp;
                  <AddCircleRoundedIcon />
                </ButtonBlue>
              </Grid>
            </Grid>
          </StyledHeadingPage>
          <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
            <BoxStyled>
              <div className="flex-box">
                <StyledContentLabel variant="body1">
                  {t("ActiveQuestion")}
                  &nbsp;&nbsp;
                  <i
                    className="fa-sharp fa-solid fa-check fa-xl"
                    style={{ color: "#fde64b" }}
                  ></i>
                </StyledContentLabel>
                {probationQuestion.length > 0 && (
                  <ButtonBlue
                    variant="outlined"
                    onClick={handleOpenEdit}
                    startIcon={<EditNoteIcon />}
                  >
                    {t("Edit")}
                  </ButtonBlue>
                )}
              </div>
              {probationQuestion
                .filter((item) => item.isActive)
                .map((probation, index) => (
                  <Stack
                    key={probation.idProbationQuestion}
                    spacing={1}
                    marginTop={1}
                  >
                    <Chip
                      sx={{ maxWidth: "fit-content" }}
                      icon={<CheckCircleIcon />}
                      color="success"
                      label={`${t("Question")} ${index + 1}`}
                    />
                    <Typography>{probation.question}</Typography>
                    <Divider sx={{ borderColor: "#9e9e9e66" }} />
                  </Stack>
                ))}
            </BoxStyled>
          </CardStyle>
          <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
            <BoxStyled>
              <div className="flex-box">
                <StyledContentLabel variant="body1">
                  {`${t("CanceledQuestion")}`}
                  &nbsp;&nbsp;
                  <i
                    className="fa-sharp fa-solid fa-xmark fa-xl"
                    style={{ color: "#ff0000" }}
                  ></i>
                </StyledContentLabel>
              </div>
              {probationQuestion
                .filter((item) => !item.isActive)
                .map((probation) => (
                  <Stack
                    key={probation.idProbationQuestion}
                    spacing={1}
                    marginTop={1}
                  >
                    <Typography>{probation.question}</Typography>
                    <Divider sx={{ borderColor: "#9e9e9e66" }} />
                  </Stack>
                ))}
            </BoxStyled>
          </CardStyle>
          {probationQuestion.length > 0 && openEdit && (
            <DialogEdit open={openEdit} handleClose={handleCloseEdit} />
          )}
        </>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {openQuestion && (
        <DialogProbation open={openQuestion} handleClose={handleCloseQuestion} />
      )}
    </div>
  );
}

export default Probation;
