import React, { useEffect } from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import CardContent from "@mui/material/CardContent";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import StyledCard from "../../shared/general/Card";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import TableListDetail from "./detail/tableListDetail";
import { useLocation } from 'react-router-dom'; 

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapTable = styled("div")({
  "& .dx-header-row ": {},
});

const StyledWrapDataGrid = styled("div")({
  width:"100%",
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
    {
      backgroundColor: "#ef5350",
      color: "#FFFFFF",
      fontWeight: "500",
      "& .dx-header-filter": {
        color: "#FFFFFF",
      },
    },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyledDivDataGrid = styled("div")({
  // width:"100%",
  display:"flex",
  position:"relative",
  "& .datepicker":{
    width:"fit-content",
    position:"absolute",
    top:"0",
    left:"0",
    zIndex:"5",
    "& .MuiInputBase-root":{
      borderRadius:"4px"
    },
    "& input":{
      lineHeight:"1.35715",
      padding:"7px 9px 8px !important",
      fontSize:"1rem"
    }
  }
});

const DataTable = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const [monthYear, setMonthYear] = React.useState(dayjs());

  useEffect(() => {
    if (location && location.state) {
        let monthYearFromLocation = location.state.selectedMonthYear || null;
        setMonthYear(monthYearFromLocation); 
    }
  }, [location]);

  return (
    <StyledWrapTable>
        <StyledCard>
          <StyledCardContent>
            <StyledWrapDataGrid>
              <StyledDivDataGrid>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                  <DatePicker 
                    className="datepicker" 
                    views={['year', 'month']}
                    value={monthYear}
                    onChange={(newValue) => {
                      setMonthYear(newValue);
                    }}
                    renderInput={(params) => <TextFieldTheme {...params} />}
                  />
                </LocalizationProvider>
                <div style={{ width:"100%" }}>
                   <TableListDetail 
                    selectedMonthYear={monthYear}
                    setMonthYear={setMonthYear}
                    onClick={props.onClick}
                   />
                </div>
              </StyledDivDataGrid>
            </StyledWrapDataGrid>
          </StyledCardContent>
        </StyledCard>
    </StyledWrapTable>
  );
};
export default DataTable;
