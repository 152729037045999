import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { makeStyles, styled } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import utils from "../../../../../../utils";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({});

const StyledTableRow = styled(TableRow)({
  "&.head": {
    backgroundColor: "#007afe",
    "& .MuiTableCell-head": {
      color: "#FFFFFF",
    },
  },
  "& .MuiTableCell-root": {
    height: 20,
    border: "1px solid #e0e0e0",
    "&.cell-data": {
      borderBottom: "none",
      borderTop: "none",
    },
  },
  "&:last-child": {
    "& .MuiTableCell-root": {
      borderBottom: "1px solid #e0e0e0",
    },
  },
});

const StyledTableRowSummary = styled(TableRow)({
  "&.total": {
    backgroundColor: "#EEEEEE",
  },
  "&.sum": {
    "& .net": {
      fontSize: 16,
      fontWeight: 500,
      backgroundColor: "#e0e0e0",
    },
    "& .blank": {
      borderBottom: "none",
      borderLeft: "none",
    },
  },
  "& .MuiTableCell-root": {
    border: "1px solid #e0e0e0",
  },
});

const StyledYearSummary = styled("div")({
  marginTop: 32,
  "& .head, .cell": {
    border: "1px solid #e0e0e0",
  },
  "& .cell": {
    fontSize: 18,
    fontWeight: 500,
  },
});

function TableSlip(props) {
  const { payslipDetail } = props;
  //const { result: payslipDetail } = useSelector((state) => state.payslipDetail);
  const [emptyRows, setEmptyRows] = useState(15);
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <StyledTableRow className="head">
              <TableCell align="left">{t("Addition")}</TableCell>
              <TableCell align="right" style={{ width: 120 }}>
                {t("Amount")} ({t("Baht")})
              </TableCell>
              <TableCell align="left">{t("Deduction")}</TableCell>
              <TableCell align="right" style={{ width: 120 }}>
                {t("Amount")} ({t("Baht")})
              </TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {[...Array(12).keys()].map((index) => (
              <StyledTableRow>
                <TableCell className="cell-data">
                  {payslipDetail.addition[index] &&
                    `${payslipDetail.addition[index].name}`}
                </TableCell>
                <TableCell align="right" className="cell-data">
                  {payslipDetail.addition[index] &&
                    utils.numberWithCommas(payslipDetail.addition[index].value)}
                </TableCell>
                <TableCell className="cell-data">
                  {payslipDetail.deduction[index] &&
                    payslipDetail.deduction[index].name}
                </TableCell>
                <TableCell align="right" className="cell-data">
                  {payslipDetail.deduction[index] &&
                    utils.numberWithCommas(
                      payslipDetail.deduction[index].value
                    )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <StyledTableRowSummary className="total">
            <TableCell align="left">{t("TotalAddition")}</TableCell>
            <TableCell align="right">
              {utils.numberWithCommas(payslipDetail.totalEarnings)}
            </TableCell>
            <TableCell align="left">{t("TotalDeduction")}</TableCell>
            <TableCell align="right">
              {utils.numberWithCommas(payslipDetail.totalDeductions)}
            </TableCell>
          </StyledTableRowSummary>
          <StyledTableRowSummary className="sum">
            <TableCell colspan={2} className="blank"></TableCell>
            <TableCell align="left" className="net">
              {t("Net")}
            </TableCell>
            <TableCell align="right" className="net">
              {utils.numberWithCommas(payslipDetail.net)}
            </TableCell>
          </StyledTableRowSummary>
        </Table>
      </TableContainer>
      <StyledYearSummary>
        <Typography gutterBottom>{`${t("CumulativeAddition")} - ${t(
          "Year"
        )} ${dayjs(payslipDetail.monthPeriod).format("BBBB")}`}</Typography>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="head">
                  {t("CumulativeAddition")} ({t("Baht")})
                </TableCell>
                <TableCell align="center" className="head">
                  {t("CumulativeWithholdingTax")} ({t("Baht")})
                </TableCell>
                <TableCell align="center" className="head">
                  {t("AccumulatedSocialSecurity")} ({t("Baht")})
                </TableCell>
                <TableCell align="center" className="head">
                  {t("AccumulatedProvidentFund")} ({t("Baht")})
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(
                    payslipDetail.accumulateEarnings
                      ? payslipDetail.accumulateEarnings
                      : 0
                  )}
                </TableCell>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(
                    payslipDetail.accumulateTax
                      ? payslipDetail.accumulateTax
                      : 0
                  )}
                </TableCell>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(
                    payslipDetail.accumulateSSO
                      ? payslipDetail.accumulateSSO
                      : 0
                  )}
                </TableCell>
                <TableCell align="center" className="cell">
                  {utils.numberWithCommas(
                    payslipDetail.accumulatePF ? payslipDetail.accumulatePF : 0
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </StyledYearSummary>
    </StyledRoot>
  );
}

export default TableSlip;
