import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Alert, MenuItem, Stack } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
    getEmployeesDetailsTaxDeduction
} from "../../../../actions/employee";
import AlertResponse from "../../shared/general/AlertResponse";
// import { TaxDeductionXlsxFile } from "../../user/governmentReport/fileXLSXgeneration.js/taxDeductionXlsxFile";
import { SelectAllCompaniesVendor } from "./SelectCompanyLists";
import { getAllCompaniesByVendor } from "../../../../actions/vendor";
import { TaxDeductionXlsxFile } from "./xlsx-export/taxDeductionXlsxFile";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center",
    },
});

const TaxDeductionReport = (props) => {
    const dispatch = useDispatch();
    const { control } = useForm({});
    const { open, handleClose } = props;
    const { t, i18n } = useTranslation();
    const currentYear = new Date().getFullYear();
    const [taxData, setTaxData] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [companyLists, setCompanyLists] = useState(null);

    const thaiYears = Array.from(
        { length: 6 },
        (_, index) => currentYear + 543 - index
    );

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleOpenAlertError = (label) => {
        setOpenAlert({ isOpen: true, type: "error", label: label });
    };

    const handleCloseAlert = () => {
        setOpenAlert({ isOpen: false, type: null, label: null });
    };

    useEffect(() => {
        if (companyLists && companyLists.length > 0) {
            let ownCompany = companyLists[0];
            setSelectedCompany(ownCompany);
        }
    }, [companyLists]);

    const fetchedCompanyByVendor = async () => {
        try {
            const response = await getAllCompaniesByVendor();
            if (response && response.data) {
                setCompanyLists(response.data);
            } else {
                setCompanyLists(null);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchedCompanyByVendor();
    }, []);

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const fetchedData = async () => {
        try {
            setIsFetching(true);
            const response = await getEmployeesDetailsTaxDeduction({
                selectedYear: parseInt(selectedYear) - 543,
                idCompany: selectedCompany && selectedCompany.idCompany
            });

            if (response && response.data) {
                setIsFetching(false);
                setTaxData(response.data);
            } else {
                setIsFetching(false);
                handleOpenAlertError(`${t("NoData")}`);
                return;
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (selectedYear && selectedCompany && selectedCompany.idCompany) {
            fetchedData();
        } else {
            setIsFetching(false);
        }
    }, [selectedYear, selectedCompany]);

    const modifyTaxDeductionXlsx = async (selectedYear) => {
        if (taxData) {
            TaxDeductionXlsxFile(taxData, selectedYear);
        } else {
            handleOpenAlertError(`${t("NoData")}`);
            return;
        }
    };

    return (
        <DrawerCustom
            title={`${t("EmployeeTaxDeductionReport")}`}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Stack spacing={4}>
                    <Stack spacing={2}>

                        <Stack spacing={1}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">{t("Company")}</Typography>
                            <SelectAllCompaniesVendor
                                options={companyLists ? companyLists : null}
                                value={selectedCompany}
                                disabled={isFetching}
                                onChange={(_, value) => {
                                    onChangeCompany(value);
                                }}
                            />
                        </Stack>

                        <Stack spacing={1}>
                            <Typography>{t("SelectYear")}</Typography>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={2}
                            >
                                <Controller
                                    name="year"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldTheme
                                            {...field}
                                            variant="filled"
                                            select
                                            fullWidth
                                            value={selectedYear}
                                            onChange={handleYearChange}
                                            disabled={isFetching}
                                        >
                                            {thaiYears.map((year) => (
                                                <MenuItem key={year} value={year}>
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </TextFieldTheme>
                                    )}
                                />
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack spacing={1} direction={"row"} justifyContent={"flex-end"}>
                        <ButtonBlue
                            variant="contained"
                            disabled={selectedYear === null || isFetching}
                            onClick={() => modifyTaxDeductionXlsx(selectedYear)}
                        >
                            {t("Download")} Xlsx
                        </ButtonBlue>
                    </Stack>
                </Stack>
            </StyledRoot>
            {openAlert.isOpen && (
                <AlertResponse
                    open={openAlert.isOpen}
                    alertType={openAlert.type}
                    label={openAlert.label}
                    handleClose={handleCloseAlert}
                />
            )}
        </DrawerCustom>
    );
};

export default TaxDeductionReport;
