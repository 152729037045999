import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
//import materials
import {
  CardContent,
  Divider,
  Grid,
  Typography,
  Select,
  FormControl,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@mui/material";

//icon
import PercentIcon from "@mui/icons-material/Percent";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
//import style
import { styled } from "@mui/material/styles";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const StyledCardContent = styled(CardContent)({
  padding: "24px !important",
  height: 536,
  overflow: "auto",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiFormControl-root": {
    width: "100%",
  },
});

const StyledProfileTitle = styled("div")({
  backgroundColor: "#2F74EB",
  padding: 16,
  "& .MuiTypography-root": {
    color: "#FFFFFF",
    fontSize: 20,
    fontWeight: 500,
  },
});

const StyledTextHeading = styled(Typography)({
  fontSize: 16,
  flexBasis: "33.33%",
  marginTop: 16,
});

const StyleSelect = styled(Select)({
  borderRadius: 8,
  "& .MuiSelect-select": {
    padding: "13.5px 14px",
  },
});

const bookBank = [
  {
    label: "ธนาคารกรุงเทพ",
    value: "ธนาคารกรุงเทพ",
  },
  {
    label: "ธนาคารกรุงไทย",
    value: "ธนาคารกรุงไทย",
  },
  {
    label: "ธนาคารกรุงศรีอยุธยา",
    value: "ธนาคารกรุงศรีอยุธยา",
  },
  {
    label: "ธนาคารกสิกรไทย",
    value: "ธนาคารกสิกรไทย",
  },
  {
    label: "ธนาคารทหารไทยธนชาต",
    value: "ธนาคารทหารไทยธนชาต",
  },
  {
    label: "ธนาคารไทยพาณิชย์",
    value: "ธนาคารไทยพาณิชย์",
  },
  {
    label: "ธนาคารซีไอเอ็มบี ไทย",
    value: "ธนาคารซีไอเอ็มบี ไทย",
  },
  {
    label: "ธนาคารยูโอบี",
    value: "ธนาคารยูโอบี",
  },
];

export default function PayrollDetails(props) {
  const { onChange, formik, companyOrg } = props;

  const handleToggleCharge = () => {
    formik.setFieldValue("chargePercent", !formik.values.chargePercent);
  };

  return (
    <div>
      <form autoComplete="off" noValidate>
        <StyledProfileTitle>
          <Typography color="text.secondary" style={{ marginLeft: 8 }}>
            การจ่าย
          </Typography>
        </StyledProfileTitle>
        <Divider />
        <StyledCardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.salary &&
                  Boolean(formik.errors.salary) &&
                  "error"
                }
              >
                ค่าแรง
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                fullWidth
                name="salary"
                onChange={onChange}
                variant="outlined"
                value={formik.values.salary}
                error={formik.touched.salary && Boolean(formik.errors.salary)}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.salary &&
                  Boolean(formik.errors.salary) &&
                  formik.errors.salary
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">ค่าดำเนินการ</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextFieldTheme
                    id="outlined-full-width"
                    type="number"
                    fullWidth
                    label="ค่าแรง"
                    name="chargeSalary"
                    value={formik.values.chargeSalary}
                    onChange={onChange}
                    variant="outlined"
                    error={
                      formik.touched.chargeSalary &&
                      Boolean(formik.errors.chargeSalary)
                    }
                    onBlur={formik.handleBlur}
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldTheme
                    id="outlined-full-width"
                    type="number"
                    fullWidth
                    label="ค่าล่วงเวลา"
                    name="chargeOt"
                    value={formik.values.chargeOt}
                    onChange={onChange}
                    variant="outlined"
                    error={
                      formik.touched.chargeOt && Boolean(formik.errors.chargeOt)
                    }
                    onBlur={formik.handleBlur}
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldTheme
                    id="outlined-full-width"
                    type="number"
                    fullWidth
                    label="เหมา"
                    name="chargeHire"
                    value={formik.values.chargeHire}
                    onChange={onChange}
                    variant="outlined"
                    error={
                      formik.touched.chargeHire &&
                      Boolean(formik.errors.chargeHire)
                    }
                    onBlur={formik.handleBlur}
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="hire"
                            size="small"
                            onClick={handleToggleCharge}
                          >
                            {formik.values.chargePercent ? (
                              <PercentIcon fontSize="inherit" />
                            ) : (
                              <CurrencyBitcoinIcon fontSize="inherit" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextFieldTheme
                    id="outlined-full-width"
                    type="number"
                    fullWidth
                    label="ค่าชดเชย"
                    name="chargeCompensation"
                    value={formik.values.chargeCompensation}
                    onChange={onChange}
                    variant="outlined"
                    error={
                      formik.touched.chargeCompensation &&
                      Boolean(formik.errors.chargeCompensation)
                    }
                    onBlur={formik.handleBlur}
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">Cost center</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                fullWidth
                name="costCenterCharge"
                onChange={onChange}
                variant="outlined"
                value={formik.values.costCenterCharge}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">Cost Element</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                fullWidth
                name="costElementCharge"
                onChange={onChange}
                variant="outlined"
                value={formik.values.costElementCharge}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">ธนาคาร</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <FormControl fullWidth>
                <StyleSelect
                  name="bookBank"
                  onChange={onChange}
                  value={formik.values.bookBank}
                >
                  {bookBank.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </StyleSelect>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">สาขา</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                fullWidth
                name="bookBankBranch"
                onChange={onChange}
                variant="outlined"
                value={formik.values.bookBankBranch}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6">เลขที่บัญชี</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <TextFieldTheme
                type="number"
                fullWidth
                name="bookBankId"
                onChange={onChange}
                variant="outlined"
                value={formik.values.bookBankId}
              />
            </Grid>
          </Grid>
        </StyledCardContent>
      </form>
    </div>
  );
}
