import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Box, Card, Container, Grid, LinearProgress } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import StyledCard from "../../../shared/general/CardDark";
import CardSummary from "../cardSummary";
import CardChart from "../cardChart";
import CardRankOTOver36 from "./CardRankOTOver36";
import utils from "../../../../../utils";
import dayjs from "dayjs";
import en from "dayjs/locale/en";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../../actions/employee";
import { getAllRequestTimeById } from "../../../../../actions/requestTime";

const StyledWrapOT36Hours = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  backgroundColor: "#fff",
  borderRadius: 20,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  position: "relative",
  padding: 24,
  "& .iconEmployee": {
    position: "absolute",
    right: 16,
    bottom: 36,
    color: "#007afe",
    "& svg": {
      fontSize: 120,
    },
    "& i": {
      fontSize: 100,
    },
  },
});

export default function DashboardOT() {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: empStore } = useSelector((state) => state.employees);
  const { result: reqTimeStore } = useSelector((state) => state.requestTime);

  const [weekDate, setWeekDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [reqTimes, setReqTimes] = useState([]);
  const [reqTimesWeek, setReqTimesWeek] = useState([]);
  const [empOver36, setEmpOver36] = useState([]);
  const [empOTBaht, setEmpOTBaht] = useState([]);
  const [allHoursOver36, setAllHoursOver36] = useState(0);
  const [chartOT36, setChartOT36] = useState([]);
  const [otType, setOtType] = useState([]);
  const [valueSumOT, setValueSumOT] = useState({
    allOT: 0,
    xOne: 0,
    xOneFive: 0,
    xTwo: 0,
    xThree: 0,
  });

  useEffect(() => {
    if (empStore) {
      /* Fetch Employees */
      let empList = [...empStore];
      let empFilter = empList.filter(function (e) {
        return (
          e.idManagerLV1 === currentUser.id || e.idManagerLV2 === currentUser.id
        );
      });

      setEmployees(empFilter);
    }
  }, [empStore]);

  useEffect(() => {
    if (reqTimeStore) {
      /* Fetch RequestTimes */
      let reqTimeList = [...reqTimeStore];
      let reqTimeFilter = reqTimeList.filter(function (req) {
        return (
          req.idManager === currentUser.id &&
          req.isApprove === 1 &&
          (req.idRequestType === 2 || req.idRequestType === 3)
        );
      });

      setReqTimes(reqTimeFilter);

      //Filter By Week
      let reqTimeWeekFilter = reqTimeList.filter(function (req) {
        var dateStart = new Date(req.requestTimeStart);
        var dateEnd = new Date(req.requestTimeEnd);

        return (
          dateStart >= weekDate.startDate &&
          dateEnd <= weekDate.endDate &&
          req.idManager === currentUser.id &&
          req.isApprove === 1 &&
          (req.idRequestType === 2 || req.idRequestType === 3)
        );
      });

      setReqTimesWeek(reqTimeWeekFilter);

      //หาค่าทำงานล่วงเวลาทั้งหมด
      totalOTValue(reqTimeWeekFilter);

      //ค่าใช้จ่ายทำงานล่วงเวลา (บาท) / ปี
      fetchValueOTByYear(reqTimeFilter);

      setIsLoading(false);
    }
  }, [reqTimeStore]);

  useEffect(() => {
    if (employees && reqTimes) {
      employees.forEach(function (emp) {
        // use reqTimesWeek to get data
        let empReq = reqTimesWeek.filter(function (req) {
          return req.idEmp === emp.idEmp;
        });

        //หาผลรวมของ xOne แต่ละคน
        let sumxOne = empReq.reduce(function (accumulator, curValue) {
          return accumulator + curValue.xOne;
        }, 0);
        emp.sumxOne = sumxOne;

        //หาผลรวมของ xOneFive แต่ละคน
        let sumxOneFive = empReq.reduce(function (accumulator, curValue) {
          return accumulator + curValue.xOneFive;
        }, 0);
        emp.sumxOneFive = sumxOneFive;

        //หาผลรวมของ xTwo แต่ละคน
        let sumxTwo = empReq.reduce(function (accumulator, curValue) {
          return accumulator + curValue.xTwo;
        }, 0);
        emp.sumxTwo = sumxTwo;

        //หาผลรวมของ xThree แต่ละคน
        let sumxThree = empReq.reduce(function (accumulator, curValue) {
          return accumulator + curValue.xThree;
        }, 0);
        emp.sumxThree = sumxThree;

        //ผลรวม OT ทั้งหมด
        emp.sumOT = sumxOne + sumxOneFive + sumxTwo + sumxThree;
      });

      //หาผลรวมของ sumxOne ทั้งหมด
      let allSumxOne = employees.reduce(function (accumulator, curValue) {
        return accumulator + curValue.sumxOne;
      }, 0);

      //หาผลรวมของ sumxOne ทั้งหมด
      let allSumxOneFive = employees.reduce(function (accumulator, curValue) {
        return accumulator + curValue.sumxOneFive;
      }, 0);

      //หาผลรวมของ sumxTwo ทั้งหมด
      let allSumxTwo = employees.reduce(function (accumulator, curValue) {
        return accumulator + curValue.sumxTwo;
      }, 0);

      //หาผลรวมของ sumxTwo ทั้งหมด
      let allSumxThree = employees.reduce(function (accumulator, curValue) {
        return accumulator + curValue.sumxThree;
      }, 0);

      /* Chart OT แต่ละประเภท */
      setOtType([allSumxOne, allSumxOneFive, allSumxTwo, allSumxThree]);

      /* ทำงานล่วงเวลาเกิน 36 ชั่วโมง (คน) */
      let listOver36 = employees.filter((x) => x.sumOT > 36);
      setEmpOver36(listOver36);

      /* ทำงานล่วงเวลา (ชั่วโมง) */
      let allOver36Hours = listOver36.reduce(function (accumulator, curValue) {
        return accumulator + curValue.sumOT;
      }, 0);
      setAllHoursOver36(allOver36Hours);

      /* Chart ทำงานล่วงเวลาเกิน 36 ชั่วโมง (คน) */
      fetchChartOver36ByYear(employees, reqTimes);
    }
  }, [employees, reqTimes]);

  const fetchChartOver36ByYear = (empData, reqData) => {
    const d = new Date();
    let year = d.getFullYear();

    //Array month of year
    let array = [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ];

    empData.forEach(function (emp) {
      for (var i = 0; i < 12; i++) {
        let reqMonthYear = reqData.filter(function (req) {
          let dateReq = new Date(req.requestTimeStart);
          let yearItem = dateReq.getFullYear();
          let monthItem = dateReq.getMonth();

          //January = 0
          return (
            monthItem === i && yearItem === year && req.idEmp === emp.idEmp
          );
        });

        //หาผลรวมของ xOne แต่ละคน
        let sumxOne = reqMonthYear.reduce(function (accumulator, curValue) {
          return accumulator + curValue.xOne;
        }, 0);

        //หาผลรวมของ xOneFive แต่ละคน
        let sumxOneFive = reqMonthYear.reduce(function (accumulator, curValue) {
          return accumulator + curValue.xOneFive;
        }, 0);
        emp.sumxOneFive = sumxOneFive;

        //หาผลรวมของ xTwo แต่ละคน
        let sumxTwo = reqMonthYear.reduce(function (accumulator, curValue) {
          return accumulator + curValue.xTwo;
        }, 0);
        emp.sumxTwo = sumxTwo;

        //หาผลรวมของ xThree แต่ละคน
        let sumxThree = reqMonthYear.reduce(function (accumulator, curValue) {
          return accumulator + curValue.xThree;
        }, 0);
        emp.sumxThree = sumxThree;

        //ผลรวม OT ทั้งหมด
        let sumOT = sumxOne + sumxOneFive + sumxTwo + sumxThree;

        if (sumOT > 36) {
          //update value in array
          array[0].data[i] = array[0].data[i] + 1;
        }
      }
    });

    /* ทำงานล่วงเวลาเกิน 36 ชั่วโมง (คน) แบบปี */
    setChartOT36(array);
  };

  const totalOTValue = (reqTimeFilter) => {
    /* ทำงานล่วงเวลา (บาท) */
    //Sum All OT Type
    let sumValueOT = reqTimeFilter.reduce(function (accumulator, curValue) {
      return accumulator + curValue.valueOT;
    }, 0);

    let sumValueXOne = reqTimeFilter.reduce(function (accumulator, curValue) {
      return accumulator + curValue.valueSumXOne;
    }, 0);

    let sumValueXOneFive = reqTimeFilter.reduce(function (
      accumulator,
      curValue
    ) {
      return accumulator + curValue.valueSumXOneFive;
    },
    0);

    let sumValueXTwo = reqTimeFilter.reduce(function (accumulator, curValue) {
      return accumulator + curValue.valueSumXTwo;
    }, 0);

    let sumValueXThree = reqTimeFilter.reduce(function (accumulator, curValue) {
      return accumulator + curValue.valueSumXThree;
    }, 0);

    setValueSumOT({
      allOT: sumValueOT ? sumValueOT : 0,
      xOne: sumValueXOne ? sumValueXOne : 0,
      xOneFive: sumValueXOneFive ? sumValueXOneFive : 0,
      xTwo: sumValueXTwo ? sumValueXTwo : 0,
      xThree: sumValueXThree ? sumValueXThree : 0,
    });
  };

  const fetchValueOTByYear = (reqTimeFilter) => {
    const d = new Date();
    let year = d.getFullYear();

    //Array month of year
    let array = [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ];

    for (var i = 0; i < 12; i++) {
      let reqMonthYear = reqTimeFilter.filter(function (req) {
        let dateReq = new Date(req.requestTimeStart);
        let yearItem = dateReq.getFullYear();
        let monthItem = dateReq.getMonth();

        //January = 0
        return monthItem === i && yearItem === year;
      });

      //หาผลรวมค่า OT ทุกประเภท
      let sumValueOT = reqMonthYear.reduce(function (accumulator, curValue) {
        return accumulator + curValue.valueOT;
      }, 0);

      //update value in array
      array[0].data[i] = sumValueOT;
    }

    setEmpOTBaht(array);
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(getAllEmployees());
      dispatch(getAllRequestTimeById());

      //Get first and last of week
      dayjs.locale({
        ...en,
        weekStart: 1, //start week at monday
      });
      var startOfWeek = dayjs().startOf("week").toDate();
      var endOfWeek = dayjs().endOf("week").toDate();

      setWeekDate({
        startDate: startOfWeek,
        endDate: endOfWeek,
      });
    }
  }, []);

  return (
    <Container>
      <Box sx={{ paddingTop: 12 }}>
        <Typography variant="h4" style={{ marginBottom: 16 }}>
          ภาพรวมค่าล่วงเวลา
        </Typography>
        {isLoading ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CardSummary
                      value={{
                        label: "พนักงาน (คน)",
                        isPeople: true,
                        amount: employees.length,
                        icon: <GroupsIcon />,
                      }}
                      top={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CardSummary
                      value={{
                        label: "ทำงานล่วงเวลา (บาท)",
                        amount: valueSumOT.allOT,
                        icon: <i class="fal fa-coins"></i>,
                      }}
                      top={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CardSummary
                      value={{
                        label: "ทำงานล่วงเวลา (ชั่วโมง)",
                        amount: allHoursOver36,
                        icon: <i class="fal fa-alarm-exclamation"></i>,
                      }}
                      top={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ height: "81%" }}>
                  <StyledWrapOT36Hours>
                    <Typography color="text.secondary" variant="body1">
                      {`ทำงานล่วงเวลาเกิน 36 ชั่วโมง (คน)`}
                    </Typography>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography variant="h2" align={"left"}>
                          {empOver36.length}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div className={`iconEmployee`}>
                          <i class="fal fa-siren-on"></i>
                        </div>
                      </Grid>
                    </Grid>
                  </StyledWrapOT36Hours>
                </Box>
              </Grid>
            </Grid>
            <div style={{ marginTop: 16 }}>
              <CardChart
                chart="area"
                chartName={`ค่าใช้จ่ายทำงานล่วงเวลา (บาท)`}
                empOTBaht={empOTBaht}
              />
            </div>
            <div style={{ marginTop: 16 }}>
              <CardChart
                chart="area"
                chartName={`ทำงานล่วงเวลาเกิน 36 ชั่วโมง (คน)`}
                chartOT36={chartOT36}
              />
            </div>
            <div style={{ marginTop: 16, marginBottom: 36 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CardChart
                    chart="polarArea"
                    chartName={`OT แต่ละประเภท`}
                    otType={otType}
                    valueSumOT={valueSumOT}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CardRankOTOver36
                    name={`Top 5 ทำงานล่วงเวลาเกิน 36 ชั่วโมง`}
                    unit={`ชม.`}
                    empOver36={empOver36}
                  />
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </Box>
    </Container>
  );
}
