import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  styled,
  InputAdornment,
  FormControl,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import EditIcon from "@mui/icons-material/Edit";
import AddOrEditModal from "./AddOrEditModal";
import NumberFormat from "react-number-format";
import PaidIcon from "@mui/icons-material/Paid";

const useStyles = makeStyles((theme) => ({
  boxForm: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  editBtn: {
    marginLeft: theme.spacing(2),
    backgroundColor: "#f5f5f5",
  },
}));

const StyledTextFieldTheme = styled(TextFieldTheme)({
  marginBottom: 16,
});

const StyleOutlineInputTheme = styled(OutlinedInput)({
  borderRadius: "8px",
  marginBottom: 8,
});

function PayrollForm(props) {
  const { values, setValues } = props;
  const classes = useStyles();

  const [addOrEditModal, setAddOrEditModal] = useState({
    isOpen: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <div>
      <Box sx={{ marginBottom: 2 }}>
        <Grid container>
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.25rem",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <PaidIcon sx={{ marginRight: 1 }} />
              การจ่าย
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.boxForm}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">ค่าทำงานในวันหยุด (รายวัน)</Typography>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <StyledTextFieldTheme
                  name="dayOff"
                  value={values.dayOff}
                  onChange={handleInputChange}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">ค่าทำงานในวันหยุด (รายเดือน)</Typography>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <StyledTextFieldTheme
                  name="monthOff"
                  value={values.monthOff}
                  onChange={handleInputChange}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">ค่าล่วงเวลาในวันทำงานปกติ</Typography>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <StyledTextFieldTheme
                  name="fullOff"
                  value={values.fullOff}
                  onChange={handleInputChange}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">ค่าล่วงเวลาในวันหยุด</Typography>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <StyledTextFieldTheme
                  name="otOff"
                  value={values.otOff}
                  onChange={handleInputChange}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">ค่ากะเช้า</Typography>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <FormControl variant="outlined" margin="dense" disabled>
                  <NumberFormat
                    type="text"
                    thousandSeparator={true}
                    customInput={StyleOutlineInputTheme}
                    value={values.morningShift}
                    allowNegative={false}
                    endAdornment={
                      <InputAdornment position="end">บาท</InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  className={classes.editBtn}
                  size="small"
                  onClick={() => {
                    setAddOrEditModal({
                      ...addOrEditModal,
                      isOpen: true,
                      name: "morningShift",
                      value: values.morningShift,
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">ค่ากะบ่าย</Typography>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <FormControl variant="outlined" margin="dense" disabled>
                  <NumberFormat
                    type="text"
                    thousandSeparator={true}
                    customInput={StyleOutlineInputTheme}
                    value={values.afternoonShift}
                    allowNegative={false}
                    endAdornment={
                      <InputAdornment position="end">บาท</InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  className={classes.editBtn}
                  size="small"
                  onClick={() => {
                    setAddOrEditModal({
                      ...addOrEditModal,
                      isOpen: true,
                      name: "afternoonShift",
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">ค่ากะดึก</Typography>
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Grid container alignItems="center">
              <Grid item xs={11}>
                <FormControl variant="outlined" margin="dense" disabled>
                  <NumberFormat
                    type="text"
                    thousandSeparator={true}
                    customInput={StyleOutlineInputTheme}
                    value={values.nightShift}
                    allowNegative={false}
                    endAdornment={
                      <InputAdornment position="end">บาท</InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  className={classes.editBtn}
                  size="small"
                  onClick={() => {
                    setAddOrEditModal({
                      ...addOrEditModal,
                      isOpen: true,
                      name: "nightShift",
                    });
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <AddOrEditModal
        addOrEditModal={addOrEditModal}
        setAddOrEditModal={setAddOrEditModal}
        values={values}
        setValues={setValues}
      />
    </div>
  );
}

export default PayrollForm;
