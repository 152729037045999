import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, withRouter, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import TranslateIcon from "@mui/icons-material/Translate";
import TranslateButton from "./Translate";

import Logo from "../../pages/assets/logo/ant_logo.png";
import ButtonBlue from "../../pages/shared/general/ButtonBlue";

import { Box } from "@mui/system";
import { logout } from "../../../actions/auth";

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    // backgroundImage: "linear-gradient(109.6deg, rgb(255, 207, 84) 11.2%, rgb(255, 158, 27) 91.1%)",
    zIndex: 1100,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

const StyledAppBar = styled(AppBar)(({}) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  width: "100%",
  ["@media (min-width: 1200px)"]: {
    width: "calc(100% - 281px)",
    "&.menuClose": {
      width: "100% !important",
    },
  },

  "& .MuiToolbar-regular": {
    color: "#212b36",
    backgroundColor: "#ffffffcc",
    transition:
      "height 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    backdropFilter: "blur(6px)",
    ["@media (min-width: 900px)"]: {
      height: 76,
    },

    "& .MuiContainer-root": {
      ["@media (max-width: 768px)"]: {
        padding: 0,
      },
      display: "flex",
      alignItems: "center",
      ["@media (min-width: 1200px)"]: {
        padding: 0,
        "&.menuClose": {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
      "& .headerAction": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .translate, .MuiDivider-root, .contact-us": {
          ["@media (max-width: 768px)"]: {
            display: "none",
          },
        },
      },
    },
  },
}));

const StyledIconButtonMenu = styled(IconButton)(({}) => ({
  //marginRight: 24,
}));

const StyledIconButtonTranslate = styled(IconButton)(({}) => ({
  "&:hover": {
    transform: "scale(1.09) translateZ(0px)",
  },
}));

const StyledIconButtonContactSmall = styled(IconButton)({
  ["@media (min-width: 768px)"]: {
    display: "none",
  },
  color: "#007AFE",
  backgroundColor: "#FFFFFF",
  border: "1px solid #007AFE",
  borderRadius: 8,
  padding: 6,
  marginRight: 8,
  fontSize: 24,
  "&:hover": {
    color: "#FFFFFF",
    borderColor: "#0046b7",
    backgroundColor: "#0046b7",
  },
});

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Header = (props) => {
  const { open, matchesBig, isLoggedIn } = props;
  const classes = useStyles();
  const location = useLocation();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const isMenuOpen = Boolean(anchorEl);
  const { t, i18n } = useTranslation();
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    dispatch(logout());
    window.location.assign("/");
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        style: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Box style={{ width: 250, padding: "0 20px", margin: "12px 0" }}>
        {userProfile && (
          <div>
            <div>
              {userProfile.idManagerEmployee ? (
                <Typography
                  variant="h6"
                  style={{
                    fontSize: 16,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${userProfile.name}`}
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  style={{
                    fontSize: 16,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${userProfile.firstname} ${userProfile.lastname}`}
                </Typography>
              )}
            </div>

            <div>
              <Typography
                color="text.secondary"
                style={{ wordWrap: "break-word" }}
              >{`${userProfile.email ? userProfile.email : ""}`}</Typography>
            </div>
          </div>
        )}
      </Box>
      <Divider />
      <MenuItem component={Link} to="/home" onClick={handleMenuClose}>
        <ListItemIcon>
          <HomeOutlinedIcon fontSize="small" />
        </ListItemIcon>
        {t("Homepage")}
      </MenuItem>
      {userProfile && !userProfile.idManagerEmployee && (
        <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
          <ListItemIcon>
            <PersonOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {t("PersonalInfo")}
        </MenuItem>
      )}
      <MenuItem
        component={Link}
        to={
          userProfile &&
          (userProfile.idManagerEmployee || userProfile.idAdminVendor)
            ? "/change-password"
            : "/profile#password"
        }
        onClick={handleMenuClose}
      >
        <ListItemIcon>
          <i class="fa-regular fa-unlock-keyhole"></i>
        </ListItemIcon>
        {t("ChangePass")}
      </MenuItem>
      <MenuItem component={Link} to="/landing" onClick={logOut}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        {t("logout")}
      </MenuItem>
    </Menu>
  );

  return (
    <Fragment>
      {location.pathname !== "/gps-check" && (
        <div className={classes.root}>
          <ElevationScroll {...props}>
            <StyledAppBar
              className={open === false && matchesBig && `menuClose`}
            >
              <Toolbar>
                <Container
                  className={open === false && matchesBig && `menuClose`}
                >
                  <StyledIconButtonMenu
                    aria-label="open drawer"
                    onClick={props.handleDrawerOpen}
                    edge="start"
                    size="large"
                  >
                    <MenuIcon />
                  </StyledIconButtonMenu>
                  <Link to="/home">
                    <img src={Logo} alt="logo" width={80} />
                  </Link>
                  <div style={{ flexGrow: 1 }}></div>
                  <div className={`headerAction`}>
                    <div className="translate">
                      <TranslateButton />
                      {/*
                  <StyledIconButtonTranslate
                    aria-label="translate"
                    size="small"
                  >
                    <TranslateIcon fontSize="small" />
                  </StyledIconButtonTranslate>
                  */}
                    </div>
                    <Divider
                      style={{ margin: "0 16px", height: 24 }}
                      orientation="vertical"
                    />
                    {isLoggedIn && userProfile ? (
                      <div>
                        <IconButton
                          edge="end"
                          aria-label="account of current user"
                          aria-controls={menuId}
                          aria-haspopup="true"
                          color="inherit"
                          onClick={(event) => setAnchorEl(event.currentTarget)}
                          size="large"
                        >
                          {userProfile.idVendor ? (
                            <Avatar
                              src={`${process.env.REACT_APP_API_URL}image/vendor/${userProfile.idVendor}/${userProfile.personalId}.jpg`}
                            />
                          ) : (
                            <Avatar
                              src={`${process.env.REACT_APP_API_URL}image/profile/${userProfile.personalId}.jpg`}
                            />
                          )}
                        </IconButton>
                      </div>
                    ) : (
                      <div>
                        {location.pathname !== "/contact-us" && (
                          <Fragment>
                            <ButtonBlue
                              className="contact-us"
                              variant="outlined"
                              component={NavLink}
                              to="/contact-us"
                            >
                              ติดต่อเรา
                            </ButtonBlue>
                            <StyledIconButtonContactSmall
                              aria-label="contact"
                              size="large"
                              component={NavLink}
                              to="/contact-us"
                            >
                              <i class="fal fa-phone-rotary"></i>
                            </StyledIconButtonContactSmall>
                          </Fragment>
                        )}

                        <ButtonBlue
                          variant={"contained"}
                          style={{ marginLeft: 8 }}
                          component={NavLink}
                          to="/login"
                        >
                          เข้าสู่ระบบ
                        </ButtonBlue>
                      </div>
                    )}
                  </div>
                </Container>
              </Toolbar>
            </StyledAppBar>
          </ElevationScroll>
          {renderMenu}
        </div>
      )}
    </Fragment>
  );
};

export default withRouter(Header);
