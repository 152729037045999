import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Avatar, Paper, styled, Typography, Divider, Box } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import CardStyle from "../../../shared/general/Card";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledLeft = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 24,
  //position: "relative",
  "& .wrap-avater": {
    marginBottom: 36,
    "& .MuiAvatar-root": {
      width: 160,
      height: 160,
      borderRadius: 8,
      padding: 8,
      border: "1px dashed rgba(145, 158, 171, 0.32)",
    },
  },
});

const StyledTextFieldTheme = styled(TextFieldTheme)(({}) => ({
  marginBottom: 16,
}));

const StyledImageProfile = styled("div")({
  width: "100%",
  "& .dropzone-profile": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
    height: 160,
    width: 160,
    margin: "auto",
    borderRadius: "50%",
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
  "& .inner-dropzone-profile": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    height: "100%",
    outline: "none",
    display: "flex",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    position: "relative",
    "& .placeholder": {
      //display: "none",
    },
    "&:hover .placeholder": {
      zIndex: 9,
      display: "flex",
    },
  },
  "& .uploadImage": {
    width: "105%",
    zIndex: 8,
  },
  "& .placeholder": {
    width: "100%",
    height: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgb(244, 246, 248)",
    transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .placeholderImageProfile": {
    display: "none",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgba(22, 28, 36, .72)",
  },
  "& .placeholderLabel": {
    color: "rgb(255, 255, 255)",
  },
  "& .wrapCaptionImage": {
    marginTop: 16,
    textAlign: "center",
  },
  "& .captionImage": {
    color: "#212b36",
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledWrapContactEmergency = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  "& div": {
    width: "100%",
    textAlign: "center",
    marginBottom: 12,
  },
});

const StyledWrapHead = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
});

const StyledTextEmpty = styled(Typography)({
  fontStyle: "oblique",
  fontWeight: 400,
  color: "#b8b8b8",
});

const LeftPanel = () => {
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [editEmergency, setEditEmergency] = useState(false);
  const [fileSelected, setFileSelected] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      fileSelected.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [fileSelected]
  );

  useEffect(() => {}, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const thumbs = (personalId) => {
    if (fileSelected.length === 0) {
      return (
        <img
          alt={"sss"}
          src={`${process.env.REACT_APP_API_URL}image/vendor/protech/${personalId}.jpg`}
          className={`uploadImage`}
        />
      );
    } else {
      return (
        <img
          alt={fileSelected[0].name}
          src={fileSelected[0].preview}
          className={`uploadImage`}
        />
      );
    }
  };

  return (
    <CardStyle>
      {employeeProfile && (
        <StyledLeft>
          <Box style={{ width: "100%", marginBottom: 18 }}>
            <div>
              <StyledImageProfile>
                <div {...getRootProps({ className: "dropzone-profile" })}>
                  <div className="inner-dropzone-profile">
                    <input {...getInputProps()} />
                    <Fragment>{thumbs(employeeProfile.personalId)}</Fragment>
                  </div>
                </div>
              </StyledImageProfile>
            </div>
          </Box>
          <div>
            <Typography
              align="center"
              color="text.secondary"
              variant="body2"
              gutterBottom
            >{`${t("StartWork")} ${dayjs(employeeProfile.hiringDate).format(
              "DD MMM BBBB"
            )}`}</Typography>
            <Typography
              align="center"
              style={{ fontWeight: "bold", fontSize: 18 }}
            >
              {employeeProfile.nickname && employeeProfile.nickname !== ""
                ? `${employeeProfile.firstname} ${employeeProfile.lastname} (${employeeProfile.nickname})`
                : `${employeeProfile.firstname} ${employeeProfile.lastname}`}
            </Typography>
            <Typography align="center">
              {employeeProfile.positionsName}
            </Typography>
            <Typography align="center" color="text.third" variant="body2">
              {employeeProfile.sectionName}
            </Typography>
            <Typography align="center" color="text.third" variant="body2">
              {employeeProfile.departmentName}
            </Typography>
          </div>
          <StyledDivider />
          <div style={{ marginTop: 16 }}></div>
          <StyledWrapHead>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              {t("EmergencyContact")}
            </Typography>
          </StyledWrapHead>

          <StyledWrapContactEmergency>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("ContactName")}
              </StyledHeadLabel>
              {employeeProfile.emergencyContact &&
              employeeProfile.emergencyContact !== "" ? (
                <Typography variant="h6">
                  {employeeProfile.emergencyContact}
                </Typography>
              ) : (
                <StyledTextEmpty>{t("ThisFieldIsRequired")}</StyledTextEmpty>
              )}
            </div>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("Relationship")}
              </StyledHeadLabel>
              {employeeProfile.emergencyRelationship &&
              employeeProfile.emergencyRelationship !== "" ? (
                <Typography variant="h6">
                  {employeeProfile.emergencyRelationship}
                </Typography>
              ) : (
                <StyledTextEmpty>{t("ThisFieldIsRequired")}</StyledTextEmpty>
              )}
            </div>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("PhoneNumber")}
              </StyledHeadLabel>
              {employeeProfile.emergencyPhone &&
              employeeProfile.emergencyPhone !== "" ? (
                <Typography variant="h6">
                  {employeeProfile.emergencyPhone.replace(
                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                    "$1-$2-$3"
                  )}
                </Typography>
              ) : (
                <StyledTextEmpty>{t("ThisFieldIsRequired")}</StyledTextEmpty>
              )}
            </div>
          </StyledWrapContactEmergency>
        </StyledLeft>
      )}
    </CardStyle>
  );
};

export default LeftPanel;
