import React, { Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";

import StatusRequest from "./stausRequest";

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
    padding: 8,
  },
  "& .tableCellHead:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    //borderColor: "#919eab3d",
    padding: 8,
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  },
  "& .cellCreateDate": {
    textAlign: "center",
    width: 100,
    "& .MuiTypography-body2": {
      fontWeight: 500,
    },
  },
  "& .cellStatusAction": {
    minWidth: 50,
  },
  "& .cellRequest": {
    textAlign: "center",
    minWidth: 150,
    maxWidth: 220,
  },
  "& .cellStartStop": {
    minWidth: 160,
  },
  "& .reason": {
    minWidth: 180,
  },
  "& .cellManager": {
    minWidth: 180,
    "& .MuiTypography-body2": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: 180,
    },
  },
});

const StyledChip = styled(Chip)({
  height: 20,
  backgroundColor: "#ff3034",
  marginRight: 8,
  marginBottom: 4,
  "& .MuiChip-label": {
    paddingLeft: 8,
    paddingRight: 8,
    color: "#FFFFFF",
    fontWeight: 600,
  },
});
const StyledBadge = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangular": {
    backgroundColor: "#ff3034",
  },
});

export default function TableRequest(props) {
  const {
    list,
    handleClickWithDraw,
    payrollSetting,
    handleOpenDialogCommentManager,
  } = props;
  const { t, i18n } = useTranslation();

  const whatLevel = (row) => {
    if (row.isDoubleApproval) {
      if (row.isManagerLV1Approve === 1) {
        return 2;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  };

  const isRequestApprove = (row) => {
    if (row.idRequestTimeWithdraw || row.idLeave) {
      return row.isApprove;
    } else {
      if (
        row.isManagerLV1Approve === null &&
        row.isManagerLV2Approve === null
      ) {
        return null;
      } else {
        if (row.isDoubleApproval) {
          if (row.isManagerLV1Approve === null) {
            return null;
          } else if (row.isManagerLV1Approve === 1) {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          } else {
            return row.isManagerLV1Approve;
          }
        } else {
          if (row.approvalLevel === 1) {
            if (row.isManagerLV1Approve === null) {
              return null;
            } else {
              return row.isManagerLV1Approve;
            }
          } else {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          }
        }
      }
    }
  };

  const disabledWithDraw = (row) => {
    if (row.idLeave) {
      if (row.isApprove !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (row.isManagerLV1Approve === 0 || row.isManagerLV1Approve === 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const renderOT = (row) => {
    let textRow = [];
    if (payrollSetting) {
      if (row.xOT > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third">
            {`OT x ${payrollSetting.xOT}`} ={" "}
            {parseFloat(row.xOT / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xWorkingDailyHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third">
            {`OT x ${payrollSetting.xWorkingDailyHoliday}`} ={" "}
            {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xWorkingMonthlyHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third">
            {`OT x ${payrollSetting.xWorkingMonthlyHoliday}`} ={" "}
            {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xOTHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third">
            {`OT x ${payrollSetting.xOTHoliday}`} ={" "}
            {parseFloat(row.xOTHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xWorkingDaily > 0) {
        textRow.push(
          <Typography
            style={{ fontWeight: 500 }}
            color="text.third"
            key="xWorkingDaily"
          >
            {`OT x 1`} ={" "}
            {parseFloat(row.xWorkingDaily / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (textRow.length === 0) {
        return <Typography color="text.third">{""}</Typography>;
      } else {
        return textRow;
      }
    }
  };

  return (
    <TableContainer style={{ width: "100%", minHeight: 550 }}>
      <StyledTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              className={`tableCellHead cellStatusAction`}
            >
              {t("Status")}
            </TableCell>
            {/* <TableCell
              align="center"
              className={`tableCellHead cellCreateDate`}
            >
              วันที่สร้าง
            </TableCell> */}
            <TableCell align="center" className={`tableCellHead cellRequest`}>
              {t("Request")}
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellStartStop`}>
              {t("Start")}-{t("End")}
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellRequest`}>
              {t("Remark")}
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellManager`}>
              {t("ApprovedBy")}
            </TableCell>
            <TableCell align="center" className={`tableCellHead cellStartStop`}>
              {t("ApprovalDate")}
            </TableCell>
            <TableCell
              align="center"
              className={`tableCellHead cellStatusAction`}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list &&
            list.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  className={`tableCell cellStatusAction`}
                >
                  <StatusRequest
                    boxSize={40}
                    fontSize={22}
                    borderRadius={8}
                    step={whatLevel(row)}
                    status={isRequestApprove(row)}
                    active={row.isActive}
                  />
                </TableCell>
                {/* <TableCell className={`tableCell cellCreateDate`}>
                  <Typography color="text.secondary" variant="body2">
                    {row.createDateText
                      ? `${dayjs(row.createDateText, "DD/MM/YYYY HH:mm").format(
                          "D/MM/BBBB"
                        )}`
                      : `${dayjs(
                          row.createLeaveText,
                          "DD/MM/YYYY HH:mm"
                        ).format("D/MM/BBBB")}`}
                  </Typography>
                  <Typography color="text.secondary" variant="body2">
                    {row.createDateText
                      ? `${dayjs(row.createDateText, "DD/MM/YYYY HH:mm").format(
                          "HH:mm"
                        )}`
                      : `${dayjs(
                          row.createLeaveText,
                          "DD/MM/YYYY HH:mm"
                        ).format("HH:mm")}`}
                  </Typography>
                </TableCell> */}
                <TableCell className={`tableCell cellRequest`}>
                  <div>
                    {(row.idRequestTimeWithdraw ||
                      row.idLeaveEmployeesWithdraw) && (
                      <StyledChip label="ถอน" />
                    )}
                  </div>
                  <div>
                    {row.isWithdraw &&
                    (row.isManagerLV1Approve ||
                      row.isManagerLV2Approve ||
                      row.isApprove) ? (
                      <StyledBadge badgeContent=" " variant="dot">
                        <Typography color="text.primary">{row.name}</Typography>
                      </StyledBadge>
                    ) : (
                      <Typography color="text.primary">{row.name}</Typography>
                    )}
                    {(row.idRequestType === 2 || row.idRequestType === 3) &&
                      renderOT(row)}
                  </div>
                </TableCell>
                <TableCell align="center" className={`tableCell cellStartStop`}>
                  {row.isFullDay ? (
                    <Fragment>
                      {row.startText === row.endText ? (
                        <Typography>
                          {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
                            "D/MM/BBBB"
                          )}
                        </Typography>
                      ) : (
                        <Typography>
                          {`${dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
                            "D/MM/BBBB"
                          )} - ${dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
                            "D/MM/BBBB"
                          )}`}
                        </Typography>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div>
                        <Typography>
                          {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
                            "D/MM/BBBB"
                          )}
                        </Typography>
                        <Typography>
                          {`${dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
                            "HH:mm"
                          )} - ${dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
                            "HH:mm"
                          )}`}
                        </Typography>
                      </div>
                    </Fragment>
                  )}
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  {row.idLeave ? (
                    <Typography color="text.primary">
                      {row.description || "-"}
                    </Typography>
                  ) : (
                    <div className="reason">
                      {row.reasonName || row.otherReason ? (
                        <>
                          <Typography className="main">
                            {row.reasonName}
                          </Typography>
                          <Typography className="other" color="text.third">
                            {row.otherReason}
                          </Typography>
                        </>
                      ) : (
                        <Typography color="text.primary">
                          {row.description || "-"}
                        </Typography>
                      )}
                    </div>
                  )}
                </TableCell>
                <TableCell align="center" className={`tableCell cellManager`}>
                  {row.idRequestTimeWithdraw || row.idLeaveEmployeesWithdraw ? (
                    <Fragment>
                      <Typography variant="body2">{row.managerName}</Typography>
                      <Typography color="text.third" variant="body2">
                        {row.managerEmail}
                      </Typography>
                    </Fragment>
                  ) : row.idRequestTime ? (
                    <Fragment>
                      {row.approvalLevel === 1 ? (
                        <Fragment>
                          <Typography variant="body2">
                            {row.managerLV1Name}
                          </Typography>
                          <Typography color="text.third" variant="body2">
                            {row.emailManagerLV1}
                          </Typography>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Typography variant="body2">
                            {row.managerLV2Name}
                          </Typography>
                          <Typography color="text.third" variant="body2">
                            {row.emailManagerLV2}
                          </Typography>
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Typography variant="body2">{row.managerName}</Typography>
                      <Typography color="text.third" variant="body2">
                        {row.managerEmail}
                      </Typography>
                    </Fragment>
                  )}
                </TableCell>
                <TableCell align="center" className={`tableCell`}>
                  {row.idRequestTimeWithdraw || row.idLeaveEmployeesWithdraw ? (
                    <Fragment>
                      {row.approveDateText && (
                        <Fragment>
                          <Typography>
                            {`${dayjs(
                              row.approveDateText,
                              "DD/MM/YYYY HH:mm"
                            ).format("D/MM/BBBB")}`}
                          </Typography>
                          <Typography>
                            {`${dayjs(
                              row.approveDateText,
                              "DD/MM/YYYY HH:mm"
                            ).format("HH:mm")}`}
                          </Typography>
                        </Fragment>
                      )}
                    </Fragment>
                  ) : row.idRequestTime ? (
                    <Fragment>
                      {row.approvalLevel === 1 ? (
                        <Fragment>
                          {row.managerLV1ApproveDateText && (
                            <Fragment>
                              <Typography>
                                {`${dayjs(
                                  row.managerLV1ApproveDateText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("D/MM/BBBB")}`}
                              </Typography>
                              <Typography>
                                {`${dayjs(
                                  row.managerLV1ApproveDateText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("HH:mm")}`}
                              </Typography>
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          {row.managerLV2ApproveDateText && (
                            <Fragment>
                              <Typography>
                                {`${dayjs(
                                  row.managerLV2ApproveDateText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("D/MM/BBBB")}`}
                              </Typography>
                              <Typography>
                                {`${dayjs(
                                  row.managerLV2ApproveDateText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("HH:mm")}`}
                              </Typography>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {row.approveDateText && (
                        <Fragment>
                          <Typography>
                            {`${dayjs(
                              row.approveDateText,
                              "DD/MM/YYYY HH:mm"
                            ).format("D/MM/BBBB")}`}
                          </Typography>
                          <Typography>
                            {`${dayjs(
                              row.approveDateText,
                              "DD/MM/YYYY HH:mm"
                            ).format("HH:mm")}`}
                          </Typography>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  className={`tableCell cellStatusAction`}
                >
                  {!row.idRequestTimeWithdraw &&
                    !row.idLeaveEmployeesWithdraw &&
                    !row.isWithdraw &&
                    disabledWithDraw(row) && (
                      <IconButton aria-label="more">
                        <DeleteOutlineRoundedIcon
                          onClick={() => handleClickWithDraw(row)}
                        />
                      </IconButton>
                    )}
                  {isRequestApprove(row) === 0 &&
                    (row.commentManager ||
                      row.commentManagerLV1 ||
                      row.commentManagerLV2) && (
                      <Tooltip title="ดูรายละเอียด" placement="top">
                        <IconButton
                          aria-label="comment"
                          onClick={() => {
                            let comment = row.commentManager;
                            if (row.commentManagerLV1) {
                              comment = row.commentManagerLV1;
                            }
                            if (row.commentManagerLV2) {
                              comment = row.row.commentManagerLV2;
                            }
                            handleOpenDialogCommentManager(comment);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
