import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { CardContent, Grid, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import {
  getAllAdditionsByVendor
} from '../../../../../actions/addition';
import { getBillingDetail, getBillingAllEmployeesByBilling } from "../../../../../actions/billing";

import Utils from "../../../../../utils";

import DataTable from "./dataTable";
import DialogEdit from "./DialogEdit";
import { onExportExcel } from "./excelExport";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
  "& .textFile": {
    color: "#007AFE",
    "&:hover": {
      color: "#0055B2",
    }
  }
});

const StyledCardContent = styled(CardContent)({
  "& .label": {
    fontSize: 14,
  },
});

const BillingDetailPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let { idBilling } = useParams();
  const history = useHistory();
  const { result: billingEmployees } = useSelector((state) => state.billingEmployees);
  const { result: billingVendors } = useSelector((state) => state.billingVendors);
  const { result: additionVendor } = useSelector((state) => state.additionVendor);
  const [openEdit, setOpenEdit] = useState(false);
  const [billingVendor, setBillingVendor] = useState(null);
  const [listBillingDetail, setListBillingDetail] = useState([]);

  useEffect(() => {
    if (billingVendors && billingVendors.length > 0) {
      let bill = billingVendors.find(b => b.idBilling === parseInt(idBilling));
      setBillingVendor(bill);
    } else {
      history.push(`/admin/billing`);
    }
  }, [billingVendors]);

  useEffect(() => {
    if (idBilling && billingVendor) {
      dispatch(getBillingAllEmployeesByBilling(dayjs(billingVendor.monthPeriod).date(1).format("YYYY-MM-DD"), idBilling));
      dispatch(getAllAdditionsByVendor(billingVendor.idVendor));
    }
  }, [idBilling, billingVendor]);

  useEffect(() => {
    if (billingEmployees && billingEmployees.listBillingDetail && billingEmployees.listBillingDetail.length > 0) {
      setListBillingDetail([...billingEmployees.listBillingDetail]);
    } else {
      setListBillingDetail([]);
    }
  }, [billingEmployees]);

  const onClickAccept = () => {
    setOpenEdit(true);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <ButtonBlue
          startIcon={<ArrowBackIcon />}
          onClick={() => history.goBack()}
        >กลับ</ButtonBlue>
        <Typography variant="h4" style={{ paddingTop: 8, marginBottom: 16 }}>
          รายละเอียด
        </Typography>
        <StyledCard style={{ marginBottom: 16 }}>
          <StyledCardContent>
            {billingVendor && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    className="label"
                    variant="caption"
                    color="text.secondary"
                  >
                    {t("Vendor")}
                  </Typography>
                  <Typography variant="h5">
                    {billingVendor.vendorName || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className="label"
                    variant="caption"
                    color="text.secondary"
                  >
                    รอบเดือน
                  </Typography>
                  <Typography variant="h5">
                    {billingVendor ?
                      dayjs(billingVendor.monthPeriod).locale(i18n.language).format(`MMMM ${i18n.language === "th" ? "BBBB" : "YYYY"}`)
                      : "-"
                    }
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className="label"
                    variant="caption"
                    color="text.secondary"
                  >
                    จำนวนพนักงาน
                  </Typography>
                  <Typography variant="h5">
                    {billingVendor && billingVendor.numberEmployee || 0}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className="label"
                    variant="caption"
                    color="text.secondary"
                  >
                    ยอดรวมทั้งหมด
                  </Typography>
                  <Typography variant="h5">
                    {`${Utils.numberWithCommas(billingVendor.net || 0)} ${i18n.language === "th" ? "บาท" : "baht"}`}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </StyledCardContent>
        </StyledCard>
        <div style={{ margin: "48px 0" }}>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 16,
            }}
          >
            <ButtonBlue variant="contained" onClick={onClickAccept}>
              ตอบรับตรวจบิล
            </ButtonBlue>
          </div> */}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 16,
            }}
          >
            <ButtonBlue variant="contained" onClick={() => onExportExcel(listBillingDetail, additionVendor)}>
              {t("Download")}
            </ButtonBlue>
          </div>

          <DataTable
            listBillingDetail={listBillingDetail}
            additionVendor={additionVendor}
          />

        </div>
        {billingVendor && billingVendor.fileName &&
          <div style={{ marginTop: "8px" }}>
            <Typography style={{ color: "rgba(0,0,0,.32)", marginTop: "16px" }}>แนบไฟล์</Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px",
                borderRadius: "4px",
                border: "1px solid #c4c4c4",
                marginTop: "8px",
                position: "relative"
              }}
            >
              <a href={billingVendor && billingVendor.pathFile || null} target="_blank" style={{ textDecoration: "none" }}>
                <div
                  className="textFile"
                  style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}
                >
                  <i className="fa-solid fa-file-pdf" style={{ fontSize: "64px" }} />
                  <Typography style={{ color: "inherit", marginTop: "16px" }}>{billingVendor && billingVendor.fileName || ""}</Typography>
                </div>
              </a>
            </div>
          </div>
        }
        <DialogEdit open={openEdit} setOpenEdit={setOpenEdit} />
      </Container>
    </StyledRoot>
  );
};

export default BillingDetailPage;
