import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  Box,
  FilledInput,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import DailyPanel from "./daily";
import WeeklyPanel from "./weekly";
import Timesheet from "./timesheet";
import Individual from "./individual";
import SelectEmployees from "../shared/selectEmployees";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerEng from "../../shared/general/DatePickerEng";
import DatePickerThai from "../../shared/general/DatePickerThai";

import {
  getAttendanceCompanyByIdEmp,
  clearAttendance,
} from "../../../../actions/attendance";
import { getHoliday } from "../../../../actions/holiday";
import { getEmployeeProfile } from "../../../../actions/employee";
import { getPayrollSetting } from "../../../../actions/paytypes";
import EmployeeShiftPanel from "./employeeShiftPanel";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  [theme.breakpoints.only("xs")]: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#007afe",
    border: "1px solid #007afe",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007aff",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #007aff",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
  display: "flex",
  justifyContent: "space-evenly",
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const TimeManagementVendorPage = () => {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: attendanceList } = useSelector((state) => state.attendance);
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const [view, setView] = React.useState("daily");
  //const [view, setView] = React.useState("weekly");
  const [search, setSearch] = React.useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const [tempSearchDate, setTempSearchDate] = useState({
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
    text: "",
  });
  const [company, setCompany] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [payrollSetting, setPayrollSetting] = useState(null);

  // useEffect(() => {
  //   dispatch(getPayrollSetting());
  //   if (company) {
  //     dispatch(
  //       getHoliday(company.idCompany, {
  //         start: dayjs(search.start).subtract(1, "month").format("YYYY-MM-DD"),
  //         end: dayjs(search.end).format("YYYY-MM-DD"),
  //       })
  //     );
  //   }
  // }, [company]);

  useEffect(() => {
    dispatch(getPayrollSetting());
  }, []);
  
  useEffect(() => {
    if (selectedEmployee && payrollSettingList) {
      const findPayroll = payrollSettingList.find(
        (x) => x.idCompany === selectedEmployee.idCompany
      );
      setPayrollSetting(findPayroll ? findPayroll : payrollSettingList[0]);
    }
  }, [selectedEmployee]);

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
      setSelectedEmployee(null);
    }
  };

  const handleSelectEmployee = (employee) => {
    dispatch(clearAttendance());
    setSelectedEmployee(employee);
    if (
      employee &&
      dayjs(employee.hiringDate).isAfter(dayjs(tempSearchDate.start))
    ) {
      let start = dayjs(employee.hiringDate);
      let end = dayjs(tempSearchDate.end);
      if (dayjs(employee.hiringDate).isAfter(dayjs(tempSearchDate.end))) {
        end = dayjs(employee.hiringDate)
          .set("date", 1)
          .add(1, "month")
          .subtract(1, "day");
      }
      setTempSearchDate({
        ...tempSearchDate,
        ["start"]: start,
        ["end"]: end,
      });
    }
  };

  const handleOnClickSearch = () => {
    setSearch(tempSearchDate);
    if (view === "individual" && selectedEmployee) {
      dispatch(getEmployeeProfile(selectedEmployee.idEmp));
      dispatch(
        getAttendanceCompanyByIdEmp(
          tempSearchDate,
          selectedEmployee.idCompany,
          selectedEmployee.idEmp
        )
      );
    }
  };

  const excelExport = () => {
    const elementList = [];

    for (let index = 0; index < attendanceList.length; index++) {
      if(index !== 0 && index !== (attendanceList.length - 1)){
        let totalLate = attendanceList[index].totalLate + attendanceList[index].totalEarlyOut;

        const element = {
          วันที่: dayjs(attendanceList[index].date).format("D/MM/YYYY (ddd)"),
          กะ:
            attendanceList[index].pattern.idWorkingType === 1
              ? `${attendanceList[index].pattern.nameShiftGroup}  (${attendanceList[index].pattern.nameShift})`
              : `${attendanceList[index].pattern.nameShiftGroup}`,
          เวลาทำงาน:
            attendanceList[index].pattern.isWorkingDay === 1
              ? `${dayjs(attendanceList[index].pattern.timeIn, "HH:mm:ss").format(
                  "HH:mm"
                )}-${dayjs(
                  attendanceList[index].pattern.timeOut,
                  "HH:mm:ss"
                ).format("HH:mm")}`
              : "วันหยุด",
          "เข้างาน-ออกงาน_1": attendanceList[index]
            ? `${
                attendanceList[index].attendance.round1.checkIn
                  ? attendanceList[index].attendance.round1.checkIn
                      .attendanceTextTime
                  : ""
              } - ${
                attendanceList[index].attendance.round1.checkOut
                  ? attendanceList[index].attendance.round1.checkOut
                      .attendanceTextTime
                  : ""
              }`
            : "-",
          "เข้างาน-ออกงาน_2": attendanceList[index]
            ? `${
                attendanceList[index].attendance.round2.checkIn
                  ? attendanceList[index].attendance.round2.checkIn
                      .attendanceTextTime
                  : ""
              } - ${
                attendanceList[index].attendance.round2.checkOut
                  ? attendanceList[index].attendance.round2.checkOut
                      .attendanceTextTime
                  : ""
              }`
            : "-",
          มาสาย: totalLate,
          รับรองเวลาทำงาน_1: attendanceList[index]
            ? `${
                attendanceList[index].requestTime[0]
                  ? `${attendanceList[index].requestTime[0].startText} - ${attendanceList[index].requestTime[0].endText}`
                  : ""
              }`
            : "-",
          เหตุผลเวลาทำงาน_1: attendanceList[index]
            ? `${
                attendanceList[index].requestTime[0]
                  ? `${attendanceList[index].requestTime[0].reasonName}`
                  : ""
              }`
            : "-",
          "เหตุผลอื่นๆ เวลาทำงาน_1": attendanceList[index]
            ? `${
                attendanceList[index].requestTime[0]
                  ? `${attendanceList[index].requestTime[0].otherReason}`
                  : ""
              }`
            : "-",
          "เวลาทำงาน-ผู้บังคับบัญชา":
            attendanceList[index].requestTime[0] &&
            attendanceList[index].requestTime[0].managerLV1Name
              ? attendanceList[index].requestTime[0].managerLV1Name
              : "-",
          ["เวลาทำงาน-ผลอนุมัติ"]:
            attendanceList[index].requestTime[0] &&
            attendanceList[index].requestTime[0].managerLV1Name
              ? attendanceList[index].requestTime[0].isManagerLV1Approve === null
                ? "รออนุมัติ"
                : attendanceList[index].requestTime[0].isManagerLV1Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ"
              : "-",
          "เวลาทำงาน-วันที่เวลาอนุมัติ":
            attendanceList[index].requestTime[0] &&
            attendanceList[index].requestTime[0].managerLV1ApproveDateText
              ? attendanceList[index].requestTime[0].managerLV1ApproveDateText
              : "-",
          รับรองเวลาทำงาน_2: attendanceList[index]
            ? `${
                attendanceList[index].requestTime[1]
                  ? `${attendanceList[index].requestTime[1].startText} - ${attendanceList[index].requestTime[1].endText}`
                  : "-"
              }`
            : "-",
          เหตุผลเวลาทำงาน_2: attendanceList[index]
            ? `${
                attendanceList[index].requestTime[1]
                  ? `${attendanceList[index].requestTime[1].reasonName}`
                  : ""
              }`
            : "-",
          "เหตุผลอื่นๆ เวลาทำงาน_2": attendanceList[index]
            ? `${
                attendanceList[index].requestTime[1]
                  ? `${attendanceList[index].requestTime[1].otherReason}`
                  : ""
              }`
            : "-",
          "เวลาทำงาน-ผู้บังคับบัญชา (2)":
            attendanceList[index].requestTime[1] &&
            attendanceList[index].requestTime[1].managerLV1Name
              ? attendanceList[index].requestTime[1].managerLV1Name
              : "-",
          "เวลาทำงาน-E-mail ผู้บังคับบัญชา (2)":
            attendanceList[index].requestTime[1] &&
            attendanceList[index].requestTime[1].emailManagerLV1
              ? attendanceList[index].requestTime[1].emailManagerLV1
              : "-",
          ["เวลาทำงาน-ผลอนุมัติ (2)"]:
            attendanceList[index].requestTime[1] &&
            attendanceList[index].requestTime[1].managerLV1Name
              ? attendanceList[index].requestTime[1].isManagerLV1Approve === null
                ? "รออนุมัติ"
                : attendanceList[index].requestTime[1].isManagerLV1Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ"
              : "-",
          ทำงานล่วงเวลา_1: attendanceList[index]
            ? `${
                attendanceList[index].ot[0]
                  ? `${attendanceList[index].ot[0].startText} - ${attendanceList[index].ot[0].endText}`
                  : "-"
              }`
            : "-",
          เหตุผลทำงานล่วงเวลา_1: attendanceList[index]
            ? `${
                attendanceList[index].ot[0]
                  ? `${attendanceList[index].ot[0].reasonName}`
                  : "-"
              }`
            : "-",
          "เหตุผลอื่นๆ ทำงานล่วงเวลา_1": attendanceList[index]
            ? `${
                attendanceList[index].ot[0]
                  ? `${attendanceList[index].ot[0].otherReason}`
                  : "-"
              }`
            : "-",
          [`OT ${payrollSetting.xWorkingMonthlyHoliday}x วันหยุด`]:
            attendanceList[index]
              ? `${
                  attendanceList[index].ot[0] &&
                  attendanceList[index].ot[0].isManagerLV1Approve === 1
                    ? attendanceList[index].ot[0].xWorkingMonthlyHoliday > 0
                      ? `${parseFloat(
                          attendanceList[index].ot[0].xWorkingMonthlyHoliday / 60
                        ).toFixed(2)} ชม.`
                      : ""
                    : "-"
                }`
              : "-",
          [`OT ${payrollSetting.xOT}x วันทำงาน`]: attendanceList[index]
            ? `${
                attendanceList[index].ot[0] &&
                attendanceList[index].ot[0].isManagerLV1Approve === 1
                  ? attendanceList[index].ot[0].xOT > 0
                    ? `${parseFloat(attendanceList[index].ot[0].xOT / 60).toFixed(
                        2
                      )} ชม.`
                    : ""
                  : "-"
              }`
            : "-",
          [`OT ${payrollSetting.xWorkingDailyHoliday}x`]: attendanceList[index]
            ? `${
                attendanceList[index].ot[0] &&
                attendanceList[index].ot[0].isManagerLV1Approve === 1
                  ? attendanceList[index].ot[0].xWorkingDailyHoliday > 0
                    ? `${parseFloat(
                        attendanceList[index].ot[0].xWorkingDailyHoliday / 60
                      ).toFixed(2)} ชม.`
                    : ""
                  : "-"
              }`
            : "-",
          [`OT ${payrollSetting.xOTHoliday}x`]: attendanceList[index]
            ? `${
                attendanceList[index].ot[0] &&
                attendanceList[index].ot[0].isManagerLV2Approve === 1
                  ? attendanceList[index].ot[0].xOTHoliday > 0
                    ? `${parseFloat(
                        attendanceList[index].ot[0].xOTHoliday / 60
                      ).toFixed(2)} ชม.`
                    : ""
                  : "-"
              }`
            : "-",
          "OT-ผู้บังคับบัญชาลำดับที่ 1":
            attendanceList[index].ot[0] &&
            attendanceList[index].ot[0].managerLV1Name
              ? attendanceList[index].ot[0].managerLV1Name
              : "-",
          ["OT-ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1"]:
            attendanceList[index].ot[0] &&
            attendanceList[index].ot[0].managerLV1Name
              ? attendanceList[index].ot[0].isManagerLV1Approve === null
                ? "รออนุมัติ"
                : attendanceList[index].ot[0].isManagerLV1Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ"
              : "-",
          "OT-วันที่เวลาอนุมัติ ผู้บังคับบัญชาลำดับที่ 1":
            attendanceList[index].ot[0] &&
            attendanceList[index].ot[0].managerLV1ApproveDateText
              ? attendanceList[index].ot[0].managerLV1ApproveDateText
              : "-",
          "OT-ผู้บังคับบัญชาลำดับที่ 2":
            attendanceList[index].ot[0] &&
            attendanceList[index].ot[0].isDoubleApproval === 1
              ? attendanceList[index].ot[0].isManagerLV2Approve !== 0 &&
                attendanceList[index].ot[0].managerLV2Name
                ? attendanceList[index].ot[0].managerLV2Name
                : "-"
              : attendanceList[index].ot[0] &&
                attendanceList[index].ot[0].approvalLevel === 2
              ? attendanceList[index].ot[0].managerLV2Name
                ? attendanceList[index].ot[0].managerLV2Name
                : "-"
              : "-",
          "OT-ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
            attendanceList[index].ot[0] &&
            attendanceList[index].ot[0].isDoubleApproval === 1
              ? attendanceList[index].ot[0].isManagerLV1Approve === null
                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                : attendanceList[index].ot[0].isManagerLV2Approve === 1
                ? attendanceList[index].ot[0].isManagerLV2Approve === null
                  ? "รออนุมัติ"
                  : attendanceList[index].ot[0].isManagerLV2Approve === 1
                  ? "อนุมัติ"
                  : "ไม่อนุมัติ"
                : "-"
              : attendanceList[index].ot[0] &&
                attendanceList[index].ot[0].approvalLevel === 2
              ? attendanceList[index].ot[0].isManagerLV2Approve &&
                attendanceList[index].ot[0].isManagerLV2Approve === null
                ? "รออนุมัติ"
                : attendanceList[index].ot[0].isManagerLV2Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ"
              : "-",
          "OT-วันที่เวลาอนุมัติ ผู้บังคับบัญชาลำดับที่ 2":
            attendanceList[index].ot[0] &&
            attendanceList[index].ot[0].managerLV2ApproveDateText
              ? attendanceList[index].ot[0].managerLV2ApproveDateText
              : "-",
          ทำงานล่วงเวลา_2: attendanceList[index]
            ? `${
                attendanceList[index].ot[1]
                  ? `${attendanceList[index].ot[1].startText} - ${attendanceList[index].ot[1].endText}`
                  : "-"
              }`
            : "-",
          เหตุผลทำงานล่วงเวลา_2: attendanceList[index]
            ? `${
                attendanceList[index].ot[1]
                  ? `${attendanceList[index].ot[1].reasonName}`
                  : "-"
              }`
            : "-",
          "เหตุผลอื่นๆ ทำงานล่วงเวลา_2": attendanceList[index]
            ? `${
                attendanceList[index].ot[1]
                  ? `${attendanceList[index].ot[1].otherReason}`
                  : "-"
              }`
            : "-",
          [`OT ${payrollSetting.xWorkingMonthlyHoliday}x วันหยุด (2)`]:
            attendanceList[index]
              ? `${
                  attendanceList[index].ot[1] &&
                  attendanceList[index].ot[1].isManagerLV1Approve === 1
                    ? attendanceList[index].ot[1].xWorkingMonthlyHoliday > 0
                      ? `${parseFloat(
                          attendanceList[index].ot[1].xWorkingMonthlyHoliday / 60
                        ).toFixed(2)} ชม.`
                      : ""
                    : "-"
                }`
              : "-",
          [`OT ${payrollSetting.xOT}x วันทำงาน (2)`]: attendanceList[index]
            ? `${
                attendanceList[index].ot[1] &&
                attendanceList[index].ot[1].isManagerLV1Approve === 1
                  ? attendanceList[index].ot[1].xOT > 0
                    ? `${parseFloat(attendanceList[index].ot[1].xOT / 60).toFixed(
                        2
                      )} ชม.`
                    : ""
                  : "-"
              }`
            : "-",
          [`OT ${payrollSetting.xWorkingDailyHoliday}x (2)`]: attendanceList[
            index
          ]
            ? `${
                attendanceList[index].ot[1] &&
                attendanceList[index].ot[1].isManagerLV1Approve === 1
                  ? attendanceList[index].ot[1].xWorkingDailyHoliday > 0
                    ? `${parseFloat(
                        attendanceList[index].ot[1].xWorkingDailyHoliday / 60
                      ).toFixed(2)} ชม.`
                    : ""
                  : "-"
              }`
            : "-",
          [`OT ${payrollSetting.xOTHoliday}x (2)`]: attendanceList[index]
            ? `${
                attendanceList[index].ot[1] &&
                attendanceList[index].ot[1].isManagerLV2Approve === 1
                  ? attendanceList[index].ot[1].xOTHoliday > 0
                    ? `${parseFloat(
                        attendanceList[index].ot[1].xOTHoliday / 60
                      ).toFixed(2)} ชม.`
                    : ""
                  : "-"
              }`
            : "-",
          "OT-ผู้บังคับบัญชาลำดับที่ 1 (2)":
            attendanceList[index].ot[1] &&
            attendanceList[index].ot[1].managerLV1Name
              ? attendanceList[index].ot[1].managerLV1Name
              : "-",
          ["OT-ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 1 (2)"]:
            attendanceList[index].ot[1] &&
            attendanceList[index].ot[1].managerLV1Name
              ? attendanceList[index].ot[1].isManagerLV1Approve === null
                ? "รออนุมัติ"
                : attendanceList[index].ot[1].isManagerLV1Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ"
              : "-",
          "OT-วันที่เวลาอนุมัติ ผู้บังคับบัญชาลำดับที่ 1 (2)":
            attendanceList[index].ot[1] &&
            attendanceList[index].ot[1].managerLV1ApproveDateText
              ? attendanceList[index].ot[1].managerLV1ApproveDateText
              : "-",
          "OT-ผู้บังคับบัญชาลำดับที่ 2 (2)":
            attendanceList[index].ot[1] &&
            attendanceList[index].ot[1].isDoubleApproval === 1
              ? attendanceList[index].ot[1].isManagerLV2Approve !== 0 &&
                attendanceList[index].ot[1].managerLV2Name
                ? attendanceList[index].ot[1].managerLV2Name
                : "-"
              : attendanceList[index].ot[1] &&
                attendanceList[index].ot[1].approvalLevel === 2
              ? attendanceList[index].ot[1].managerLV2Name
                ? attendanceList[index].ot[1].managerLV2Name
                : "-"
              : "-",

          "OT-ผลอนุมัติ ผู้บังคับบัญชาลำดับที่ 2 (2)":
            attendanceList[index].ot[1] &&
            attendanceList[index].ot[1].isDoubleApproval === 1
              ? attendanceList[index].ot[1].isManagerLV1Approve === null
                ? "รอผู้บังคับบัญชาลำดับที่ 1"
                : attendanceList[index].ot[1].isManagerLV2Approve === 1
                ? attendanceList[index].ot[1].isManagerLV2Approve === null
                  ? "รออนุมัติ"
                  : attendanceList[index].ot[1].isManagerLV2Approve === 1
                  ? "อนุมัติ"
                  : "ไม่อนุมัติ"
                : "-"
              : attendanceList[index].ot[1] &&
                attendanceList[index].ot[1].approvalLevel === 2
              ? attendanceList[index].ot[1].isManagerLV2Approve &&
                attendanceList[index].ot[1].isManagerLV2Approve === null
                ? "รออนุมัติ"
                : attendanceList[index].ot[1].isManagerLV2Approve === 1
                ? "อนุมัติ"
                : "ไม่อนุมัติ"
              : "-",
          "OT-วันที่เวลาอนุมัติ ผู้บังคับบัญชาลำดับที่ 2 (2)":
            attendanceList[index].ot[1] &&
            attendanceList[index].ot[1].managerLV2ApproveDateText
              ? attendanceList[index].ot[1].managerLV2ApproveDateText
              : "-",
          การลางาน: attendanceList[index]
            ? `${
                attendanceList[index].leave[0] &&
                attendanceList[index].leave[0].isApprove === 1
                  ? attendanceList[index].leave[0].name
                  : ""
              }`
            : "-",
          การลางาน_เวลา: attendanceList[index]
            ? `${
                attendanceList[index].leave[0] &&
                attendanceList[index].leave[0].isApprove === 1
                  ? `${attendanceList[index].leave[0].startText} - ${attendanceList[index].leave[0].endText}`
                  : ""
              }`
            : "-",
          เหตุผลลางาน: attendanceList[index]
            ? `${
                attendanceList[index].leave[0] &&
                attendanceList[index].leave[0].isApprove === 1
                  ? `${attendanceList[index].leave[0].description}`
                  : ""
              }`
            : "-",
        };

        elementList.push(element);
      }
    }

    const ws = XLSX.utils.json_to_sheet(elementList);

    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "รายงาน");
    XLSX.writeFile(
      workbook,
      `รายงานเวลาทำงาน ${selectedEmployee.employeeId}_${selectedEmployee.firstname}_${selectedEmployee.lastname}.xlsx`,
      { type: "file" }
    );
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("TimeManagement")}
          </Typography>
        </div>
        <Fragment>
          <Fragment>
            <StyledToggleButtonGroup
              value={view}
              exclusive
              onChange={handleChangeView}
              aria-label="view"
              size="small"
            >
              <ToggleButton
                name="status"
                value="daily"
                aria-label="daily"
                size="small"
                style={{ minWidth: 150, marginRight: 12 }}
              >
                {t("Daily")}
              </ToggleButton>
              <ToggleButton
                name="status"
                value="weekly"
                aria-label="weekly"
                size="small"
                style={{ minWidth: 150, marginRight: 12 }}
              >
                {t("Weekly")}
              </ToggleButton>
              <ToggleButton
                name="status"
                value="individual"
                aria-label="individual"
                size="small"
                style={{ minWidth: 150, marginRight: 12 }}
              >
                {t("Personal")}
              </ToggleButton>
              <ToggleButton
                name="status"
                value="employee-shift"
                aria-label="employee-shift"
                size="small"
                style={{ minWidth: 150, marginRight: 12 }}
              >
                ตารางกะ
              </ToggleButton>
            </StyledToggleButtonGroup>
            {view === "daily" ? (
              <DailyPanel />
            ) : view === "weekly" ? (
              <WeeklyPanel />
            ) : (
              (view === "individual" || view === "all") && (
                <Fragment>
                  <Grid
                    container
                    spacing={2}
                    style={{ marginBottom: 16 }}
                    alignItems={"center"}
                  >
                    <Grid item xs={12} sm={3}>
                      <StyledBoxSearch>
                        <Typography className="label" color="text.third">
                          {t("StartDate")}
                        </Typography>
                        <div className="search-date">
                          {localStorage.getItem("language") === "en" ? (
                            <DatePickerEng
                              minDate={
                                selectedEmployee && selectedEmployee.hiringDate
                                  ? dayjs(selectedEmployee.hiringDate)
                                  : null
                              }
                              value={tempSearchDate.start}
                              name="start"
                              onChange={(newValue) => {
                                setTempSearchDate({
                                  ...tempSearchDate,
                                  ["start"]: newValue,
                                });
                              }}
                            />
                          ) : (
                            <DatePickerThai
                              minDate={
                                selectedEmployee && selectedEmployee.hiringDate
                                  ? dayjs(selectedEmployee.hiringDate)
                                  : null
                              }
                              value={tempSearchDate.start}
                              name="start"
                              onChange={(newValue) => {
                                setTempSearchDate({
                                  ...tempSearchDate,
                                  ["start"]: newValue,
                                });
                              }}
                            />
                          )}
                        </div>
                      </StyledBoxSearch>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <StyledBoxSearch>
                        <Typography className="label" color="text.third">
                          {t("EndDate")}
                        </Typography>
                        <div className="search-date">
                          {localStorage.getItem("language") === "en" ? (
                            <DatePickerEng
                              minDate={tempSearchDate.start}
                              value={tempSearchDate.end}
                              name="end"
                              onChange={(newValue) => {
                                setTempSearchDate({
                                  ...tempSearchDate,
                                  ["end"]: newValue,
                                });
                              }}
                            />
                          ) : (
                            <DatePickerThai
                              minDate={tempSearchDate.start}
                              value={tempSearchDate.end}
                              name="end"
                              onChange={(newValue) => {
                                setTempSearchDate({
                                  ...tempSearchDate,
                                  ["end"]: newValue,
                                });
                              }}
                            />
                          )}
                        </div>
                      </StyledBoxSearch>
                    </Grid>
                    {view === "individual" && (
                      <Grid item xs={12} sm={3}>
                        <SelectEmployees handleChange={handleSelectEmployee} />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={3}>
                      <StyledBoxSearchButton>
                        <div>
                          <ButtonBlue
                            variant="contained"
                            startIcon={<SearchIcon />}
                            onClick={handleOnClickSearch}
                          >
                            {t("Search")}
                          </ButtonBlue>
                        </div>
                        <div>
                          {attendanceList && (
                            <ButtonBlue
                              onClick={excelExport}
                              variant="outlined"
                              startIcon={<DownloadRoundedIcon />}
                              disabled={
                                selectedEmployee === null &&
                                (attendanceList === null ||
                                  attendanceList.length === 0)
                              }
                            >
                              {t("Download")}
                            </ButtonBlue>
                          )}
                        </div>
                      </StyledBoxSearchButton>
                    </Grid>
                  </Grid>
                  {view === "individual" ? (
                    <Fragment>
                      <Typography variant="h4" style={{ paddingTop: 8 }}>
                        {t("TimeTable")}
                      </Typography>
                      {selectedEmployee && (
                        <Individual
                          company={company}
                          employee={selectedEmployee}
                          tempSearchDate={tempSearchDate}
                        />
                      )}
                    </Fragment>
                  ) : (
                    <Timesheet company={company} searchDate={search} />
                  )}
                </Fragment>
              )
            )}
            {view === "employee-shift" && (
              <EmployeeShiftPanel />
            )}
          </Fragment>
        </Fragment>
      </Container>
    </StyledRoot>
  );
};

export default TimeManagementVendorPage;
