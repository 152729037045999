import React, { Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Typography, Container, Button } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import ButtonBlue from "../../shared/general/ButtonBlue";

const StyledWrapEmployeeList = styled("div")(({}) => ({
  paddingBottom: 120,
  ["@media (max-width: 1200px)"]: {
    paddingBottom: 140,
  },
  ["@media (max-width: 820px)"]: {
    paddingBottom: 60,
  },
  ["@media (max-width: 768px)"]: {
    paddingBottom: 50,
  },
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .employee-list": {
    display: "grid",
    gridTemplateRows: "1fr auto",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    gridAutoRows: 0,
    minWidth: 350,
    columnGap: 8,
    ["@media (max-width: 768px)"]: {
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
    },
    ["@media (max-width: 820px)"]: {
      gridTemplateRows: "1fr auto auto",
    },
    "& .employee-item": {
      minWidth: 100,
      marginTop: 24,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      width: 72,
      height: 72,
      marginBottom: 8,
    },
    "& .MuiTypography-root": {
      maxWidth: 120,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
}));

const EmployeeList = () => {
  const dispatch = useDispatch();
  const { result: employeeList } = useSelector((state) => state.users);

  useEffect(() => {}, []);

  return (
    <Fragment>
      {(employeeList && employeeList.active) && (
        <StyledWrapEmployeeList>
          <div className="header">
            <Typography color="text.secondary">
              พนักงาน <span>{`(${employeeList.active.length} คน)`}</span>
            </Typography>
            <ButtonBlue
              variant="text"
              component={NavLink}
              to="/admin/employees"
            >
              ทั้งหมด
            </ButtonBlue>
          </div>
          <div className="employee-list">
            <div className="employee-item">
              <Avatar component={NavLink} to="/admin/employees/register">
                <AddIcon fontSize="large" />
              </Avatar>
              <Typography>เพิ่มพนักงาน</Typography>
            </div>
            {employeeList.active
              .filter((emp, index) => {
                return index < 17;
              })
              .map((emp, index) => (
                <div className="employee-item">
                  <Avatar
                    src={
                      emp.imageProfile ? 
                      `${emp.imageProfile}` : 
                      `${process.env.REACT_APP_API_URL}image/vendor/${emp.idVendor}/${emp.personalId}.jpg`
                    }
                  />
                  <Typography align="center">{`${emp.firstname}`}</Typography>
                  <Typography align="center">{`${emp.lastname}`}</Typography>
                </div>
              ))}
          </div>
        </StyledWrapEmployeeList>
      )}
    </Fragment>
  );
};

export default EmployeeList;
