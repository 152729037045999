import React from "react";
import { styled } from "@mui/material/styles";
import { TableRow, TableCell } from "@mui/material";

import dayjs from "dayjs";
import utils from "../../../../../../utils";

const StyledTableRow = styled(TableRow)({
  marginBottom: 2,
});

const StyledTableCell = styled(TableCell)({
  padding: 0,
  border: "none",
  "& .wrap": {
    height: 72,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderTop: "1px solid #e1e1e1",
    borderLeft: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "&.first": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&.last": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderRight: "1px solid #e1e1e1",
    },
  },
  "&.date-start-end": {
    width: 180,
    "& .MuiInputBase-root": {
      paddingRight: 8,
    },
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 50,
  },
});

const RowItemExpense = (props) => {
  const { data, count, index, open } = props;

  return (
    <StyledTableRow>
      <StyledTableCell className="date-start-end">
        <div className="wrap first">
          {dayjs(data.date).format("DD/MM/BBBB")}
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table amount">
        <div className="wrap">{data.startLocation}</div>
      </StyledTableCell>
      <StyledTableCell className="cell-table amount">
        <div className="wrap">{data.stopLocation}</div>
      </StyledTableCell>
      <StyledTableCell className="cell-table amount">
        <div className="wrap">
          {utils.numberWithCommas(parseFloat(data.startMile))}
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table amount">
        <div className="wrap">
          {utils.numberWithCommas(parseFloat(data.stopMile))}
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table amount">
        <div className="wrap">
          {utils.numberWithCommas(parseFloat(data.total))}
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table description">
        <div className="wrap">
          {utils.numberWithCommas(parseFloat(data.personal))}
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table description">
        <div className="wrap last">
          {utils.numberWithCommas(parseFloat(data.net))}
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default RowItemExpense;
