import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, styled, Grid, IconButton, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledWrapBranch = styled("div")({
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StyledIconButton = styled(IconButton)({
  margin: "0 4px",
  "&:hover": {
    background: "transparent",
    color: "#007afe",
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const LocationTab = (props) => {
  const { data } = props;
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { t, i18n } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [valueDialog, setValueDialog] = useState({
    name: "",
    address: "",
    phone: "",
    email: "",
  });

  const handleCloseDialogBranchForm = () => {
    setOpenDialog(false);
  };

  const handleOpenDialogBranchForm = () => {
    setOpenDialog(true);
  };

  const handleAddNewBranch = () => {
    setValueDialog({
      name: "",
      address: "",
      phone: "",
      email: "",
    });
    handleOpenDialogBranchForm();
  };

  const handleEditBranch = (value) => {
    setValueDialog(value);
    handleOpenDialogBranchForm();
  };

  return (
    <div>
      {/* <StyledHeadLabel variant="body2" style={{ marginBottom: 16 }}>
        ที่อยู่ตามทะเบียนบ้าน
      </StyledHeadLabel>
      {data && (
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">ที่อยู่</StyledHeadLabel>
            <Typography variant="h6">
              เลขที่ 74/1 หมู่ 1 ถ.ราชพฤกษ์ ต.ท่าอิฐ{" "}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <StyledHeadLabel color="text.secondary">อำเภอ</StyledHeadLabel>
            <Typography variant="h6">ปากเกร็ด</Typography>
          </Grid>
          <Grid item xs={4}>
            <StyledHeadLabel color="text.secondary">จังหวัด</StyledHeadLabel>
            <Typography variant="h6">นนทบุรี</Typography>
          </Grid>
          <Grid item xs={4}>
            <StyledHeadLabel color="text.secondary">
              รหัสไปรษณีย์
            </StyledHeadLabel>
            <Typography variant="h6">11120</Typography>
          </Grid>
        </Grid>
      )} 
      <StyledDivider />*/}
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("CurrentAddress")}
          </StyledHeadLabel>
        </div>
        {employeeProfile && (
          <Grid container spacing={2} style={{ marginBottom: 16 }}>
            {/* <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">ที่อยู่</StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.address ? employeeProfile.address : "-"}
              </Typography>
            </Grid> */}
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("HouseNumber")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.houseNo ? employeeProfile.houseNo.trim() : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Village")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.vilage ? employeeProfile.vilage.trim() : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("VillageNo")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.vilageNo
                  ? employeeProfile.vilageNo.trim()
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Alley")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.alley ? employeeProfile.alley.trim() : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Road")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.road ? employeeProfile.road.trim() : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("SubdistrictArea")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.subDistrict
                  ? employeeProfile.subDistrict.trim()
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("DistrictArea")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.district ? employeeProfile.district : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Province")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.provience ? employeeProfile.provience : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("PostalCode")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.areaCode ? employeeProfile.areaCode : "-"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </StyledWrapBranch>
    </div>
  );
};

export default LocationTab;
