import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Typography,
  createFilterOptions,
  styled,
} from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { addPosition, getAllPositions } from "../../../../../actions/positions";
import {
  addOrganizationNode,
  getOrganizationChart,
} from "../../../../../actions/orgChart";
import {
  addOrganizationStructure,
  getOrganizationStructure,
} from "../../../../../actions/organizationStructure";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const DialogAddStructure = (props) => {
  const { drawerConfig, onClose, data, setAlertConfig, selectedCompany } =
    props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const [stateAdd, setStateAdd] = useState(0);

  const [parentList, setParentList] = useState([]);

  const { result: ManagerData, isManagerFecthing } = useSelector(
    (state) => state.manager
  );

  const validateYupSchema = yup.object({
    nodeType: yup.string().required("กรุณากรอกข้อมูล"),
    name: yup.string().required("กรุณากรอกข้อมูล"),
    // idParent: yup.string().required("กรุณากรอกข้อมูล"),
    // parentNodeType: yup.string().required("กรุณากรอกข้อมูล"),
    parentNode: yup
      .object()
      .shape({
        nodeType: yup.string(),
      })
      .nullable()
      .required("กรุณากรอกข้อมูล"),
    costCenter: yup.string(),
    // idManager: yup.string().required("กรุณากรอกข้อมูล"),
  });

  const useHookForm = useForm({
    defaultValues: {
      nodeType: "",
      name: "",
      name_EN: "",
      // idParent: "",
      // idParentNode: "",
      parentNode: null,
      costCenter: "",
      // parentNodeType: "",
      idManagerEmployee: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const filterData = (nodeType) => {
    if (nodeType === "BUSINESSUNIT") {
      return [...data.ceos];
    } else if (nodeType === "DIVISION") {
      return [...data.ceos, ...data.businessUnits];
    } else if (nodeType === "DEPARTMENT") {
      return [...data.ceos, ...data.businessUnits, ...data.divisions];
    } else if (nodeType === "SECTION") {
      return [
        ...data.ceos,
        ...data.businessUnits,
        ...data.divisions,
        ...data.departments,
      ];
    } else if (nodeType === "POSITION") {
      return [
        ...data.ceos,
        ...data.businessUnits,
        ...data.divisions,
        ...data.departments,
        ...data.sections,
      ];
    } else {
      return [];
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (value) =>
      `${value.firstname_TH} ${value.lastname_TH} ${value.email}`,
  });

  const onSubmit = async (data) => {
    const formData = {
      nodeType: data.nodeType,
      name: data.name,
      name_EN: data.name_EN,
      parentNode: data.parentNode,
      costCenter: data.costCenter || null,
      // idParentNode: data.idParentNode,
      // parentNodeType: data.parentNodeType,
      idManagerEmployee: data.idManagerEmployee || null,
    };

    formData.idCompany = selectedCompany.idCompany;

    if (drawerConfig.isEdit) {
      // formData.idPosition = drawerConfig.data.idPosition
      formData.isEdit = true;
      switch (data.nodeType) {
        case "BUSINESSUNIT": {
          formData.idBusinessUnit = drawerConfig.data.idBusinessUnit;
          break;
        }
        case "DIVISION": {
          formData.idDivision = drawerConfig.data.idDivision;
          break;
        }
        case "DEPARTMENT": {
          formData.idDepartment = drawerConfig.data.idDepartment;
          break;
        }
        case "SECTION": {
          formData.idSection = drawerConfig.data.idSection;
          break;
        }
        case "POSITION": {
          formData.idPosition = drawerConfig.data.idPosition;
          break;
        }
      }
    }

    const result = await dispatch(addOrganizationStructure(formData));

    // if(result){
    //   if(result.status === 200){
    //     dispatch(getOrganizationStructure())
    //   }
    //   // onClose();
    // }

    if (result) {
      if (result.status === 200) {
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: `${t("DataSaveSuccessful")}`,
        }));
        dispatch(
          getOrganizationStructure({ idCompany: selectedCompany.idCompany })
        );
        onClose();
      } else {
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: `${t("AnErrorOccurred")} ${t("ContactAdmin")}`,
        }));
      }
    } else {
      setAlertConfig((prev) => ({
        ...prev,
        isOpen: true,
        type: "error",
        message: `${t("AnErrorOccurred")} ${t("ContactAdmin")}`,
      }));
    }
  };

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      if (drawerConfig.isEdit) {
        setParentList(filterData(drawerConfig.data.nodeType));
        useHookForm.reset({
          nodeType: drawerConfig.data.nodeType || "",
          name: drawerConfig.data.name || "",
          name_EN: drawerConfig.data.name_EN || "",
          parentNode:
            filterData(drawerConfig.data.nodeType).find(
              (item) => item.code === drawerConfig.data.parentNode.code
            ) || null,
          costCenter: drawerConfig.data.costCenter || "",
          idManagerEmployee: drawerConfig.data.idManagerEmployee || "",
        });
      } else {
        useHookForm.reset({
          nodeType: "",
          name: "",
          name_EN: "",
          parentNode: null,
          costCenter: "",
          idManagerEmployee: "",
        });
      }
    }
  }, [drawerConfig.isOpen]);

  return (
    <DrawerCustom
      title={
        drawerConfig.isEdit
          ? `${t("EditOganization")}`
          : `${t("AddNewOganization")}`
      }
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("Type")}
                </Typography>
              </Box>
              <Controller
                name="nodeType"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      useHookForm.setValue("parentNode", null);
                      // useHookForm.setValue("parentNodeType", "")
                      setParentList(filterData(e.target.value));
                    }}
                    select
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                    disabled={drawerConfig.isEdit}
                  >
                    <MenuItem value="BUSINESSUNIT">Business Unit</MenuItem>
                    <MenuItem value="DIVISION">Division</MenuItem>
                    <MenuItem value="DEPARTMENT">Department</MenuItem>
                    <MenuItem value="SECTION">Section</MenuItem>
                    {/* <MenuItem value="POSITION">Position</MenuItem> */}
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("OganizationName")}
                </Typography>
              </Box>
              <Controller
                name="name"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("OganizationName")} ({t("English")})
                </Typography>
              </Box>
              <Controller
                name="name_EN"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name_EN]
                        ? useHookForm.formState.errors[field.name_EN].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name_EN] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  Parent
                </Typography>
              </Box>

              <Controller
                name="parentNode"
                control={useHookForm.control}
                render={({ field }) => (
                  <Autocomplete
                    options={parentList}
                    getOptionLabel={(option) => `${option.code} ${option.name}`}
                    // filterOptions={filterOptions}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.code}>
                        <Box>
                          <Typography fontSize="12px" color="text.secondary">
                            {option.code}
                          </Typography>
                          <Typography>{option.name}</Typography>
                          {/* {getNameOption(option)} */}
                          {/* <Typography><span style={{fontSize: "14px", color: "#919eab", marginRight: "8px"}}>ฝ่าย:</span>{option.name}</Typography> */}
                          {/* {option.idDivision === null && <Typography><span style={{fontSize: "14px", color: "#919eab", marginRight: "8px"}}>ฝ่าย:</span>ไม่มี</Typography>}
                          {option.idDivision && <Typography><span style={{fontSize: "14px", color: "#919eab", marginRight: "8px"}}>ฝ่าย:</span>{option.divisionName}</Typography>} */}
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        onBlur={field.onBlur}
                        error={
                          useHookForm.formState.errors[field.name]
                            ? true
                            : false
                        }
                        helperText={
                          useHookForm.formState.errors[field.name]
                            ? useHookForm.formState.errors[field.name].message
                            : null
                        }
                      />
                    )}
                    value={
                      parentList
                        ? parentList.find(
                            (option) =>
                              field.value && option.code === field.value.code
                          ) || null
                        : null
                    }
                    onChange={(_, value) => {
                      field.onChange(value);
                      // field.onChange(value? value.code: "");
                      // useHookForm.setValue("parentNodeType", value? value.nodeType: "")
                      // useHookForm.setValue("idParentNode", value? getIdNode(value): "")
                      // useHookForm.setValue("idSection", "")
                    }}
                    disableClearable
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  Cost Center
                </Typography>
              </Box>
              <Controller
                name="costCenter"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      placeholder: `(${t("Optional")})`,
                    }}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <Box marginBottom="4px" display="flex" justifyContent="space-between">
                <Typography fontSize="14px" fontWeight="500">หัวหน้า</Typography>
              </Box>
              <Controller
                name="idManager"
                control={useHookForm.control}
                render={({field}) => (
                  <Autocomplete
                    options={ManagerData? ManagerData: []}
                    getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                    filterOptions={filterOptions}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idEmployees}>
                          <Box>
                            <Typography>{option.firstname_TH} {option.lastname_TH}</Typography>
                            <Typography color="text.third" fontSize="14px">{option.email}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        onBlur={field.onBlur}
                        error={useHookForm.formState.errors[field.name]? true: false}
                        helperText={useHookForm.formState.errors[field.name]? useHookForm.formState.errors[field.name].message: null}
                      />
                    )}
                    value={ManagerData? ManagerData.find(option => option.idEmployees === field.value) || null: null}
                    onChange={(_, value) => {field.onChange(value? value.idEmployees: "")}}
                    disableClearable
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} container gap="8px" justifyContent="flex-end">
              <ButtonBlue onClick={onClose}>{t("Cancle")}</ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("SaveData")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogAddStructure;
