import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DrawerCustom from "../../../../shared/general/Drawer";
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Typography,
  createFilterOptions,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@tarzui/date-fns-be";
import { AdapterDateFns as DatePickerAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { th, enUS } from "date-fns/locale";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import dayjs from "dayjs";
import {
  addEmployeeShiftChange,
  getEmployeeProfile,
  getEmployeeShiftChange,
  updateEmployeeShiftChange,
} from "../../../../../../actions/employee";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
  "& .MuiSwitch-root": {
    "& .Mui-checked": {
      color: "#007AFE",
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#007AFE",
    },
  },
});

const DialogEdit = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { drawerConfig, onClose } = props;

  const today = new Date();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: shiftStore } = useSelector((state) => state.shift);
  const [shiftList, setShiftList] = useState([]);

  const validateYupSchema = yup.object({
    shift: yup.object().nullable().required("กรุณากรอกข้อมูล"),
    remark: yup
      .string()
      .test(
        "length",
        i18n.language === "th"
          ? "ความยาวตัวอักษรห้ามเกิน 500 ตัวอักษร"
          : "Characters must not be longer than 500",
        (value) => value.length <= 500
      ),
    startDate: yup.date().nullable().required("กรุณากรอกข้อมูล"),
    // .min(new Date(new Date().setHours(0, 0, 0, 0)), i18n.language === "th"? "ไม่สามารถกรอกวันที่ผ่านมาแล้วได้": "Date cannot be in the past"),
  });

  const useHookForm = useForm({
    defaultValues: {
      shift: null,
      remark: "",
      startDate: new Date(),
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const filterOptions = createFilterOptions({
    stringify: (value) => `${value.nameShiftGroup} ${value.nameShift}`,
  });

  useEffect(() => {
    if (shiftStore) {
      const tempShift = [];

      shiftStore.map((shiftGroup) => {
        shiftGroup.shift.map((shift) => {
          tempShift.push({
            idShift: shift.idShift,
            idShiftGroup: shift.idShiftGroup,
            nameShiftGroup: shiftGroup.nameShiftGroup,
            nameShift: shift.nameShift,
          });
        });
      });

      setShiftList(tempShift);
    }
  }, [shiftStore]);

  useEffect(() => {
    if (drawerConfig.isOpen) {
      if (!drawerConfig.isEdit) {
        useHookForm.reset({
          shift: null,
          remark: "",
          startDate: new Date(),
        });
      } else {
        useHookForm.reset({
          shift:
            shiftList.find(
              (item) => item.idShift === drawerConfig.data.idShift
            ) || null,
          remark: drawerConfig.data.remark || "",
          startDate: drawerConfig.data.startDate
            ? new Date(drawerConfig.data.startDate)
            : new Date(),
        });
      }
    }
  }, [drawerConfig.isOpen, shiftList]);

  const onSubmit = async (data) => {
    let result = null;

    if (!drawerConfig.isEdit) {
      const formData = {
        idEmp: employeeProfile.idEmp,
        idShift: data.shift.idShift,
        idShiftGroup: data.shift.idShiftGroup,
        startDate: data.startDate
          ? dayjs(data.startDate).format("YYYY-MM-DD 00:00:00")
          : null,
        remark: data.remark || null,
        isTemporarily: 0,
        isActive: 1,
      };
      result = await dispatch(addEmployeeShiftChange(formData));
    } else {
      const formData = {
        idShift: data.shift.idShift,
        idShiftGroup: data.shift.idShiftGroup,
        // startDate: data.startDate? dayjs(data.startDate).format("YYYY-MM-DD 00:00:00"): null,
        remark: data.remark || null,
      };
      result = await dispatch(
        updateEmployeeShiftChange(drawerConfig.data.idEmployeeShift, formData)
      );
    }

    if (result) {
      onClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
    }
  };

  return (
    <DrawerCustom
      title={
        drawerConfig.isEdit
          ? `${t("Edit")} ${t("Work_Time")}`
          : `${t("Move")} ${t("Work_Time")}`
      }
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="500" paddingBottom="8px">
                {t("ShiftType")} : ({t("ShiftName")})
              </Typography>
              <Controller
                name="shift"
                control={useHookForm.control}
                render={({ field }) => (
                  <Autocomplete
                    options={shiftList ? shiftList : []}
                    getOptionLabel={(option) =>
                      `${option.nameShiftGroup} : (${option.nameShift})`
                    }
                    filterOptions={filterOptions}
                    isOptionEqualToValue={(option, value) =>
                      option.idShift === value.idShift
                    }
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.idShift}>
                        <Box>
                          <Typography>
                            {option.nameShiftGroup} : ({option.nameShift})
                          </Typography>
                        </Box>
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextFieldTheme
                        {...params}
                        placeholder={`${t("Search")}${
                          i18n.language === "th" ? "" : " "
                        }${t("ShiftType")}`}
                        onBlur={field.onBlur}
                        error={
                          useHookForm.formState.errors[field.name]
                            ? true
                            : false
                        }
                        helperText={
                          useHookForm.formState.errors[field.name]
                            ? useHookForm.formState.errors[field.name].message
                            : null
                        }
                      />
                    )}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                    noOptionsText={t("No_Data")}
                    disableClearable
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="500" paddingBottom="8px">
                {t("Reason")}
              </Typography>
              <Controller
                name="remark"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    multiline
                    rows={4}
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="500" paddingBottom="8px">
                {t("EffectiveDate")}
              </Typography>
              <Controller
                name="startDate"
                control={useHookForm.control}
                render={({ field }) => (
                  <LocalizationProvider
                    dateFormats={{
                      year: "yyyy",
                      monthAndYear: "MMMM yyyy",
                      keyboardDate: "dd/MM/yyyy",
                    }}
                    dateAdapter={
                      i18n.language === "th"
                        ? AdapterDateFns
                        : DatePickerAdapterDateFns
                    }
                    adapterLocale={i18n.language === "th" ? th : enUS}
                  >
                    <DatePicker
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="dd/MM/yyyy"
                      openTo="year"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      disabled={drawerConfig.isEdit}
                      // minDate={new Date()}
                      // maxDate={new Date().setFullYear(new Date().getFullYear()+15)}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder:
                              i18n.language === "th"
                                ? "วว/ดด/ปป(พ.ศ.)"
                                : "DD/MM/YYYY",
                          }}
                          onBlur={field.onBlur}
                          error={
                            useHookForm.formState.errors[field.name]
                              ? true
                              : false
                          }
                          helperText={
                            useHookForm.formState.errors[field.name]
                              ? useHookForm.formState.errors[field.name].message
                              : null
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} container gap="8px" justifyContent="flex-end">
              <ButtonBlue
                variant="text"
                onClick={() => {
                  onClose();
                }}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue variant="contained" type="submit">
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
