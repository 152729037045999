import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Box, Dialog, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import SyncProblemIcon from "@mui/icons-material/SyncProblem";

import DialogEditSwitchShift from "./dialogEditSwitchShift";
import DialogSwitchShift from "./dialogSwitchShift";
import EventSwitchShift from "./eventSwitchShift";
import EmployeeShiftList from "./employeeShiftList";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import { Container } from "@mui/material";
import {
  getAllEmployeesShift,
  getEmployeeShiftHistory,
} from "../../../../../actions/employee";
import { getShiftGroupById } from "../../../../../actions/shift";
import AlertResponse from "../../../shared/general/AlertResponse";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: "20px",
  "& .head": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default function ListEmployeePage(props) {
  const { shiftGroup, company, handleClickEditTimeWorking } = props;
  const { idShiftGroup } = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: shiftGroupList } = useSelector((state) => state.shiftGroup);
  const [pattern, setPattern] = React.useState(false);
  const [openSwitchShift, setOpenSwitchShift] = React.useState(false);
  const [openErrorSwitch, setOpenErrorSwitch] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [editHistory, setEditHistory] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  useEffect(() => {
    dispatch(getAllEmployeesShift(shiftGroup.idShiftGroup));
    dispatch(getShiftGroupById(shiftGroup.idShiftGroup));
  }, []);

  React.useEffect(() => {
    dispatch(getEmployeeShiftHistory(shiftGroup.idShiftGroup));
  }, []);

  const handleOpenSwitchShiftPattern = () => {
    setOpenSwitchShift(true);
    setPattern(true);
  };

  const handleCloseSwitchShift = () => {
    setOpenSwitchShift(false);
  };

  const handleOpenSwitchShift = () => {
    setOpenSwitchShift(true);
    setPattern(false);
  };

  const handleClickEditButton = (history) => {
    setEditHistory(history);
    setOpenEditDialog(true);
  };

  const handleGetEmployeeShiftHistory = () => {
    dispatch(getEmployeeShiftHistory(idShiftGroup));
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <StyledRoot>
      <div className="head">
        <Typography variant="h4" style={{ paddingTop: 8, color: "#212b36" }}>
          {t("EmployeeList")}
        </Typography>
        <div className="head">
          <div style={{ marginRight: 8 }}>
            <ButtonBlue variant="outlined" onClick={handleClickEditTimeWorking}>
              {t("EditWorkTime")}
            </ButtonBlue>
          </div>
          <div>
            <ButtonBlue
              variant={"contained"}
              startIcon={<SyncProblemIcon />}
              onClick={handleOpenSwitchShiftPattern}
            >
              {t("SwitchShiftPattern")}
            </ButtonBlue>
          </div>
        </div>
      </div>

      <EmployeeShiftList
        handleOpenSwitchShift={handleOpenSwitchShift}
        idShiftGroup={idShiftGroup}
      />
      {shiftGroupList && (
        <EventSwitchShift
          idShiftGroup={idShiftGroup}
          handleClickEditButton={handleClickEditButton}
          handleGetEmployeeShiftHistory={handleGetEmployeeShiftHistory}
        />
      )}

      {openSwitchShift && shiftGroupList && (
        <DialogSwitchShift
          open={openSwitchShift}
          handleClose={handleCloseSwitchShift}
          pattern={pattern}
          setOpenErrorSwitch={setOpenErrorSwitch}
          handleOpenAlert={handleOpenAlert}
          handleChangeAlertType={handleChangeAlertType}
        />
      )}

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
}
