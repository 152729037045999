import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  Typography,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DialogConfirmDelete from "./dialogConfirmDelete";

import {
  addNewJobLevel,
  getJobLevlByidCompany,
  updateJobLevel,
} from "../../../../../actions/jobLevel";
import { getLevelAdditionsByidJobLevel } from "../../../../../actions/levelAddition";

const StyledRoot = styled("div")({
  width: "400px",
  maxWidth: 550,
  padding: 24,
});

const BoxInput = styled(Box)({
  marginBottom: "10px",
});

const DividerStyled = styled(Divider)({
  height: "0.5px",
  backgroundColor: "#dbdbdb",
  opacity: "0.5",
  margin: "10px 0",
});

const DialogAddJobLevel = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    handleClose,
    nextRank,
    handleOpenSnackbar,
    editJobLevel,
    company,
  } = props;
  const { t, i18n } = useTranslation();

  const { result: AllAdditions } = useSelector(
    (state) => state.additionCompany
  );
  const { result: UserProfile } = useSelector((state) => state.userProfile);
  const { result: LevelAdditions } = useSelector(
    (state) => state.levelAddition
  );
  const [listDelete, setListDelete] = useState([]);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      rank: nextRank,
      minSalary: 0,
      maxSalary: 0,
      timeScan: 1,
      overtime: 1,
      listAdditions: editJobLevel
        ? []
        : [
            {
              idLevelAdditions: null,
              idAddition: "",
              price: 0,
            },
          ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "listAdditions",
  });

  useEffect(() => {
    if (editJobLevel) {
      dispatch(getLevelAdditionsByidJobLevel(editJobLevel.idJobLevel));
      setValue("name", editJobLevel.jobLevelName);
      setValue("rank", editJobLevel.levelRank);
      setValue(
        "minSalary",
        editJobLevel.minSalary ? editJobLevel.minSalary : 0
      );
      setValue(
        "maxSalary",
        editJobLevel.maxSalary ? editJobLevel.maxSalary : 0
      );
      setValue("timeScan", editJobLevel.timeScan);
      setValue("overtime", editJobLevel.overtime);
    }
  }, [editJobLevel]);

  useEffect(() => {
    if (editJobLevel && LevelAdditions) {
      let tempList = [];
      LevelAdditions.map((addition) => {
        tempList.push({
          idLevelAdditions: addition.idLevelAdditions,
          idAddition: addition.idAddition,
          price: addition.value,
        });
      });
      setValue(`listAdditions`, tempList);
    }
  }, [LevelAdditions]);

  const IsPercentage = (values, index) => {
    if (AllAdditions && values[index].idAddition) {
      let addition = AllAdditions.find(
        (value) => value.code === values[index].idAddition
      );
      if (addition) {
        if (addition.isPercentage === 1) {
          return true;
        }
      }
    }
    return false;
  };

  const HandleSubmit = async (value) => {
    let result = null;
    if (editJobLevel) {
      if (listDelete.length > 0) {
        setOpenDialogConfirmDelete(true);
      } else {
        let data = {
          jobLevel: { ...value, price: parseInt(value.price) },
          listDelete: listDelete,
        };
        result = await dispatch(updateJobLevel(editJobLevel.idJobLevel, data));
      }
    } else {
      result = await dispatch(addNewJobLevel(company, value));
    }
    if (result) {
      dispatch(getJobLevlByidCompany(company));
      handleClose();
      handleOpenSnackbar();
    }
  };

  const handleSubmitConfirmDelete = async () => {
    let value = getValues();
    let data = {
      jobLevel: { ...value, price: parseInt(value.price) },
      listDelete: listDelete,
    };
    let result = await dispatch(updateJobLevel(editJobLevel.idJobLevel, data));
    if (result) {
      dispatch(getJobLevlByidCompany(company));
      setOpenDialogConfirmDelete(false);
      handleClose();
      handleOpenSnackbar();
    }
  };

  return (
    <React.Fragment>
      <DrawerCustom title="เพิ่ม Job Level" anchor="right" open={open}>
        <StyledRoot>
          <form onSubmit={handleSubmit(HandleSubmit)}>
            <BoxInput>
              <Typography
                style={{ color: errors && errors.name ? "#d32f2f" : "#212b36" }}
              >
                Name
              </Typography>
              <TextFieldTheme
                error={errors && errors.name ? true : false}
                inputProps={{
                  ...register("name", {
                    required: true,
                  }),
                }}
                helperText={errors && errors.name && `${t("InsertJobLVName")}`}
              />
            </BoxInput>
            <BoxInput>
              <Typography>Rank</Typography>
              <TextFieldTheme
                inputProps={{
                  ...register("rank", {
                    required: true,
                    valueAsNumber: true,
                  }),
                }}
                disabled
              />
            </BoxInput>
            <BoxInput>
              <Typography>Min Salary</Typography>
              <TextFieldTheme
                type="number"
                error={errors && errors.minSalary ? true : false}
                inputProps={{
                  ...register("minSalary", {
                    required: true,
                  }),
                  min: 0,
                }}
                helperText={
                  errors && errors.minSalary && `${t("InsertMinSalary")}`
                }
              />
            </BoxInput>
            <BoxInput>
              <Typography>Max Salary</Typography>
              <TextFieldTheme
                type="number"
                error={errors && errors.maxSalary ? true : false}
                inputProps={{
                  ...register("maxSalary", {
                    required: true,
                  }),
                  min: 0,
                }}
                helperText={
                  errors && errors.minSalary && `${t("InsertMaxSalary")}`
                }
              />
            </BoxInput>
            <BoxInput>
              <Typography>Time Scan</Typography>
              <FormControl fullWidth>
                <Controller
                  name="timeScan"
                  control={control}
                  render={({ field }) => (
                    <Select style={{ borderRadius: "8px" }} {...field}>
                      <MenuItem value={1}>Scan</MenuItem>
                      <MenuItem value={0}>No Scan</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </BoxInput>
            <BoxInput>
              <Typography>OverTime</Typography>
              <FormControl fullWidth>
                <Controller
                  name="overtime"
                  control={control}
                  render={({ field }) => (
                    <Select style={{ borderRadius: "8px" }} {...field}>
                      <MenuItem value={1}>OT</MenuItem>
                      <MenuItem value={0}>No OT</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </BoxInput>
            <DividerStyled />
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>Addition Pay</Typography>
              <IconButton
                onClick={() =>
                  append({ idLevelAdditions: null, idAddition: "", price: 0 })
                }
              >
                <Add style={{ color: "#007afe" }} />
              </IconButton>
            </Box>
            <Grid container spacing={2}>
              {fields.length > 0 &&
                fields.map((addition, index) => (
                  <Fragment key={addition.id}>
                    {index !== 0 && (
                      <Grid item xs={12}>
                        <DividerStyled />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography>
                          {`${t("ExtraCost")} ${index + 1}`}
                        </Typography>
                        {fields.length > 1 && (
                          <IconButton
                            onClick={() => {
                              remove(index);
                              if (addition.idLevelAdditions) {
                                setListDelete([
                                  ...listDelete,
                                  addition.idLevelAdditions,
                                ]);
                              }
                            }}
                          >
                            <Delete style={{ color: "#d32f2f" }} />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography>{t("ItemName")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <FormControl
                        fullWidth
                        error={
                          errors &&
                          errors.listAdditions &&
                          errors.listAdditions[index].idAddition
                            ? true
                            : false
                        }
                      >
                        <Controller
                          name={`listAdditions.${index}.idAddition`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: `${t("ExtraCost")}`,
                            },
                          }}
                          render={({ field }) => (
                            <Select
                              style={{ borderRadius: "8px" }}
                              // {...field}
                              value={field.value}
                              onChange={
                                // field.onChange
                                (value) => {
                                  field.onChange(value);
                                  if (addition.idLevelAdditions) {
                                    setValue(
                                      `listAdditions.${index}.idLevelAdditions`,
                                      null
                                    );
                                  }
                                }
                              }
                            >
                              {watch("listAdditions") &&
                                AllAdditions &&
                                AllAdditions.map((item, index) => (
                                  <MenuItem
                                    key={item.idAddition}
                                    disabled={getValues("listAdditions")
                                      .map((value) => value.idAddition)
                                      .includes(item.idAddition)}
                                    value={item.idAddition}
                                  >
                                    {item.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        />
                        <FormHelperText>
                          {errors &&
                            errors.listAdditions &&
                            errors.listAdditions[index].idAddition &&
                            errors.listAdditions[index].idAddition.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography>{`${t("Total")} (${
                        IsPercentage(fields, index) ? "%" : `${t("Baht")}`
                      })`}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextFieldTheme
                        type="number"
                        inputProps={{
                          ...register(`listAdditions.${index}.price`, {
                            required: true,
                          }),
                          min: 0,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {IsPercentage(fields, index)
                                ? "%"
                                : `${t("Baht")}`}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Fragment>
                ))}
            </Grid>
            <Box
              style={{
                width: "100%",
                marginTop: "25px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ButtonBlue variant="text" onClick={handleClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue type="submit" variant="contained">
                {t("Save")}
              </ButtonBlue>
            </Box>
          </form>
        </StyledRoot>
      </DrawerCustom>
      {openDialogConfirmDelete && (
        <DialogConfirmDelete
          open={openDialogConfirmDelete}
          handleClose={() => setOpenDialogConfirmDelete(false)}
          listDelete={listDelete}
          handleSubmit={handleSubmitConfirmDelete}
        />
      )}
    </React.Fragment>
  );
};

export default DialogAddJobLevel;
