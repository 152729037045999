import { httpClient } from "./httpClient";

const getAllCompanys = () => {
  return httpClient.get(`companys`);
};

const getCompanyProfile = (id) => {
  return httpClient.get(`/company/${id}/profile`);
};

const putCompanyProfile = (id, formData) => {
  return httpClient.put(`/company/${id}/profile`, formData);
};

const getCompanyOrganization = (idCompany) => {
  if (idCompany) {
    return httpClient.get(`/company/${idCompany}/organization`);
  } else {
    return httpClient.get(`/company/organization`);
  }
};

const companyEmployeeType = (idCompany) => {
  if (idCompany) {
    return httpClient.get(`/company/${idCompany}/employee-type`);
  } else {
    return httpClient.get(`/company/employee-type`);
  }
};


const putCompanyProfileMainContact = (id, formData) => {
  return httpClient.put(`/company/${id}/profile/main-contact`, formData);
};

const postCompanyAddress = (formData) => {
  return httpClient.post(`/company/${formData.idCompany}/address`, formData);
};

const putCompanyAddress = (id, idCompanyAddress, formData) => {
  return httpClient.put(`/company/${id}/address/${idCompanyAddress}`, formData);
};

//get company branch api
const getCompanyBranch = (id) => {
  return httpClient.get(`/company/${id}/branch`);
}
//add company branch
const postCompanyBranch = (formData) => {
  return httpClient.post(`/company/${formData.parentCompany}/branch`, formData);
}

const putCompanyBranch = (id, idBranch, formData) => {
  return httpClient.put(`/company/${id}/branch/${idBranch}`, formData);
}

const putCompanyBankDetail = (id, idBankDetail, formData) => {
  return httpClient.put(`/company/${id}/bankDetail/${idBankDetail}`, formData);
};

const getVendorContract = () => {
  return httpClient.get(`/company/vendor-contract`);
};

const getAllCompanysByIdVendor = (idVendor) => {
  return httpClient.get(`/vendor/${idVendor}/companys`);
}; 

const getEmployeeTypeByidCompany = (idCompany) => {
  return httpClient.get(`company/${idCompany}/employee-type`);
};

const getWorkInfoByidCompany = (idCompany) => {
  return httpClient.get(`company/${idCompany}/work-info`);
};

const getManagerByidCompany = (idCompany) => {
  return httpClient.get(`company/${idCompany}/manager`);
};

const getManagerSettingByidCompany = (idCompany) => {
  return httpClient.get(`company/${idCompany}/manager/setting`);
};

const updateManagerSetting = (idCompany, formData) => {
  return httpClient.put(`company/${idCompany}/manager/setting`, formData);
};

const getGroupLevelByIdCompany = (idCompany) => {
  return httpClient.get(`company/${idCompany}/groupLevel`);
};

const updateGroupLevelByIdCompany = (idCompany, formData) => {
  return httpClient.post(`company/${idCompany}/groupLevel`, formData);
};

const updateLevelByIdCompany = (idCompany, formData) => {
  return httpClient.post(`company/${idCompany}/level`, formData);
};

const deleteGroupLevelByIdCompanyIdGroupLevel = (idCompany, idGroupLevel) => {
  return httpClient.delete(`company/${idCompany}/groupLevel/${idGroupLevel}`);
};

const deleteLevelByIdCompanyIdLevel = (idCompany, idLevel) => {
  return httpClient.delete(`company/${idCompany}/level/${idLevel}`);
};

const getLevelByCompanyId = (idCompany, query) => {
  return httpClient.get(`company/${idCompany}/level`, { params: query });
}

export default {
  getEmployeeTypeByidCompany,
  getManagerByidCompany,
  getManagerSettingByidCompany,
  updateManagerSetting,
  getWorkInfoByidCompany,
  getAllCompanys,
  getCompanyProfile,
  putCompanyProfile,
  putCompanyProfileMainContact,
  postCompanyAddress,
  putCompanyAddress,
  getCompanyOrganization,
  companyEmployeeType,
  getCompanyBranch,
  postCompanyBranch,
  putCompanyBranch,
  putCompanyBankDetail,
  getVendorContract,
  getAllCompanysByIdVendor,
  getGroupLevelByIdCompany,
  updateGroupLevelByIdCompany,
  updateLevelByIdCompany,
  deleteGroupLevelByIdCompanyIdGroupLevel,
  deleteLevelByIdCompanyIdLevel,
  getLevelByCompanyId,
};
