import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import DrawerCustom from "../../../../shared/general/Drawer";

import {
  getEmployeeProfile,
  employeeManagerUpdate,
} from "../../../../../../actions/employee";
import { updateUser } from "../../../../../../actions/user";
import {
  getWorkInfoByidCompany,
  getManagerByidCompany,
} from "../../../../../../actions/company";
import { getManagerGroup } from "../../../../../../actions/manager";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  "&.head-text-manager": {
    fontWeight: 400,
    "&.error": {
      color: "#f15e5e",
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
});

const StyledIconButton = styled(IconButton)({
  color: "#f15e5e",
  marginLeft: 4,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 360,
    },
  },
};

const HiringDate = (props) => {
  const { handleChange, formData } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("StartDate")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
          <DatePicker
            value={formData.hiringDate}
            inputFormat="dd/MM/yyyy"
            name={"hiringDate"}
            onChange={(date) => {
              handleChange("hiringDate", date);
            }}
            renderInput={(params) => <StyledTextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("RenewalDate")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
          <DatePicker
            value={formData.contractTermainatoinDate}
            inputFormat="dd/MM/yyyy"
            name={"contractTermainatoinDate"}
            onChange={(date) => {
              handleChange("contractTermainatoinDate", date);
            }}
            renderInput={(params) => <StyledTextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

const EmployeeType = (props) => {
  const { handleChange, formData, handleChangeLocation } = props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: workTypesList } = useSelector((state) => state.workTypes);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getWorkInfoByidCompany(employeeProfile.idCompany));
  }, []);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("JobLevel")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="idJobLevel"
            value={formData.idJobLevel}
            onChange={handleChange}
          >
            {workTypesList &&
              workTypesList.jobLevel.map((item) => (
                <MenuItem value={item.idJobLevel}>{item.jobLevelName}</MenuItem>
              ))}
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("PersonalLevel")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="idPersonnelLevel"
            value={formData.idPersonnelLevel}
            onChange={handleChange}
          >
            {workTypesList &&
              workTypesList.personnelLevel.map((item) => (
                <MenuItem value={item.idPersonnelLevel}>
                  {item.personnelLevelName}
                </MenuItem>
              ))}
          </Select>
        </StyledFormControl>
      </Grid>
      {/* <Grid item xs={12} sm={3}>
        <StyledContentLabel>ประเภทการจ้าง</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="idEmployeeType"
            value={formData.idEmployeeType}
            onChange={handleChange}
          >
            {workTypesList &&
              workTypesList.employeeType.map((item) => (
                <MenuItem value={item.idEmployeeType}>
                  {item.employeeTypeName}
                </MenuItem>
              ))}
          </Select>
        </StyledFormControl>
      </Grid> */}
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("EmploymentTime")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="employmentTime"
            value={formData.employmentTime}
            onChange={handleChange}
          >
            <MenuItem value={"Full time"}>Full time</MenuItem>
            <MenuItem value={"Part time"}>Part time</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>Filed/Office</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="filed_office"
            value={formData.filed_office}
            onChange={handleChange}
          >
            <MenuItem value={"Field"}>Field</MenuItem>
            <MenuItem value={"Office"}>Office</MenuItem>
            <MenuItem value={"field/office"}>Field/Office</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("WorkingLocation")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="workingLocationName"
          value={formData.workingLocationName}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("MainWorkingLocation")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="mainWorkingLocationPoint"
            value={formData.mainWorkingLocationPoint}
            onChange={handleChange}
            disabled={
              formData.methodAttendance === "all" ||
              formData.methodAttendance === "point"
            }
          >
            {workTypesList && workTypesList.groupGpsLocations.length > 0 ? (
              workTypesList.groupGpsLocations.map((item) => (
                <MenuItem value={item.idGroupGpsLocations}>
                  {item.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">
                <em>{t("No_Data")}</em>
              </MenuItem>
            )}
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("Finger")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="isFinger"
            value={formData.isFinger}
            onChange={handleChange}
          >
            <MenuItem value="1">{t("Yes")}</MenuItem>
            <MenuItem value="0">{t("No")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("MethodAttendance")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="methodAttendance"
            value={formData.methodAttendance}
            onChange={handleChangeLocation}
          >
            <MenuItem value={"main"}>{t("MainWorkingLocation")}</MenuItem>
            <MenuItem value={"point"}>{t("Coordinates")}</MenuItem>
            <MenuItem value={"all"}>{t("AllLocation")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
    </Grid>
  );
};

const Manager = (props) => {
  const { mode, handleChange, formData, handleDeleteManagerLV2, formik } =
    props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { t, i18n } = useTranslation();

  const { result: managerList } = useSelector((state) => state.manager);
  const { result: managerGroupList } = useSelector(
    (state) => state.managerGroup
  );
  const [tempFormData, setTempFormData] = useState(null);

  useEffect(() => {
    formik.resetForm();
    dispatch(getManagerByidCompany(employeeProfile.idCompany));
    dispatch(getManagerGroup(employeeProfile.idCompany));
    formik.setFieldValue("level", mode === "managerLV1" ? 1 : 2);
    formik.setFieldValue("idCompany", employeeProfile.idCompany);
    formik.setFieldValue("idEmp", employeeProfile.idEmp);
  }, []);

  useEffect(() => {
    setTempFormData({ ...formData });
  }, [formData]);

  return (
    <Fragment>
      <form autoComplete="off" noValidate>
        <Grid container alignItems="center" spacing={2}>
          {mode === "managerLV1" ? (
            <Fragment>
              <Grid item xs={12} sm={3}>
                <StyledContentLabel style={{ paddingLeft: 8 }}>
                  {t("NoNumber")} 1
                </StyledContentLabel>
                <ButtonBlue
                  variant="text"
                  onClick={() => {
                    if (formik.values.manager) {
                      setTempFormData({
                        ...tempFormData,
                        ["idManagerLV1"]: formData.idManagerLV1,
                      });
                      //formik.setTouched({}, false);
                      formik.setErrors({});
                    } else {
                      setTempFormData({
                        ...tempFormData,
                        ["idManagerLV1"]: null,
                      });
                    }

                    formik.setFieldValue("manager", !formik.values.manager);

                    formik.setFieldValue("managerName", null);
                    formik.setFieldValue("managerPosition", null);
                    formik.setFieldValue("managerEmail", null);
                  }}
                >
                  {formik.values.manager
                    ? `${t("Cancel")}`
                    : `${t("AddManager")}`}
                </ButtonBlue>
              </Grid>
              {tempFormData && (
                <Grid item xs={12} sm={9}>
                  <StyledFormControl
                    fullWidth
                    disabled={formik.values.managerLV1}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // name="idManagerLV1"
                      name="managerLV1"
                      // value={tempFormData.idManagerLV1}
                      value={tempFormData.managerLV1}
                      onChange={handleChange}
                    >
                      {managerList &&
                        managerGroupList &&
                        [...managerList, ...managerGroupList].map(
                          (item, index) => {
                            if (item.idManagerEmployee) {
                              return (
                                <StyledMenuItem
                                  key={index}
                                  value={`idManagerEmployee-${item.idManagerEmployee}`}
                                >
                                  <Typography>{item.name}</Typography>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {item.email}
                                  </Typography>
                                </StyledMenuItem>
                              );
                            } else {
                              return (
                                <StyledMenuItem
                                  key={index}
                                  value={`idManagerGroup-${item.idManagerGroup}`}
                                >
                                  <Typography>{`${item.managerGroupName}`}</Typography>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {item.managerList.length} หัวหน้างาน
                                  </Typography>
                                </StyledMenuItem>
                              );
                            }
                          }
                        )}
                    </Select>
                  </StyledFormControl>
                </Grid>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Grid item xs={12} sm={3}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StyledContentLabel style={{ paddingLeft: 8 }}>
                    {t("NoNumber")} 2
                  </StyledContentLabel>

                  <div>
                    <StyledIconButton
                      aria-label="delete"
                      size="small"
                      onClick={handleDeleteManagerLV2}
                    >
                      <HighlightOffRoundedIcon fontSize="inherit" />
                    </StyledIconButton>
                  </div>
                </div>
                <ButtonBlue
                  variant="text"
                  onClick={() => {
                    if (formik.values.manager) {
                      setTempFormData({
                        ...tempFormData,
                        ["idManagerLV2"]: formData.idManagerLV2,
                      });
                      formik.setErrors({});
                    } else {
                      setTempFormData({
                        ...tempFormData,
                        ["idManagerLV2"]: null,
                      });
                    }

                    formik.setFieldValue("manager", !formik.values.manager);

                    formik.setFieldValue("managerName", null);
                    formik.setFieldValue("managerPosition", null);
                    formik.setFieldValue("managerEmail", null);
                  }}
                >
                  {formik.values.manager
                    ? `${t("Cancel")}`
                    : `${t("AddManager")}`}
                </ButtonBlue>
              </Grid>
              {tempFormData && (
                <Grid item xs={12} sm={9}>
                  <StyledFormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // name="idManagerLV2"
                      // value={tempFormData.idManagerLV2}
                      name="managerLV2"
                      value={tempFormData.managerLV2}
                      onChange={handleChange}
                    >
                      {managerList &&
                        managerGroupList &&
                        [...managerList, ...managerGroupList].map(
                          (item, index) => {
                            if (item.idManagerEmployee) {
                              return (
                                <StyledMenuItem
                                  key={index}
                                  value={`idManagerEmployee-${item.idManagerEmployee}`}
                                >
                                  <Typography>{item.name}</Typography>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {item.email}
                                  </Typography>
                                </StyledMenuItem>
                              );
                            } else {
                              return (
                                <StyledMenuItem
                                  key={index}
                                  value={`idManagerGroup-${item.idManagerGroup}`}
                                >
                                  <Typography>{`${item.managerGroupName}`}</Typography>
                                  <Typography
                                    color="text.secondary"
                                    variant="body2"
                                  >
                                    {item.managerList.length} หัวหน้างาน
                                  </Typography>
                                </StyledMenuItem>
                              );
                            }
                          }
                        )}
                    </Select>
                  </StyledFormControl>
                </Grid>
              )}
            </Fragment>
          )}
          {formik.values.manager && (
            <Fragment>
              <Grid item xs={12} sm={3}></Grid>
              <Grid item xs={12} sm={9}>
                <Fragment>
                  <Grid item xs={12}>
                    <StyledContentLabel
                      variant="body1"
                      className={`head-text-manager ${
                        formik.touched.managerName &&
                        Boolean(formik.errors.managerName) &&
                        "error"
                      }`}
                    >
                      {t("FullName")}
                    </StyledContentLabel>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: 8 }}>
                    <TextFieldTheme
                      id="outlined-full-width"
                      fullWidth
                      name="managerName"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.managerName}
                      error={
                        formik.touched.managerName &&
                        Boolean(formik.errors.managerName)
                      }
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.managerName &&
                        Boolean(formik.errors.managerName) &&
                        formik.errors.managerName
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledContentLabel
                      variant="body1"
                      className={`head-text-manager ${
                        formik.touched.managerPosition &&
                        Boolean(formik.errors.managerPosition) &&
                        "error"
                      }`}
                    >
                      {t("PositionManager")}
                    </StyledContentLabel>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: 8 }}>
                    <TextFieldTheme
                      id="outlined-full-width"
                      fullWidth
                      name="managerPosition"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.managerPosition}
                      error={
                        formik.touched.managerPosition &&
                        Boolean(formik.errors.managerPosition)
                      }
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.managerPosition &&
                        Boolean(formik.errors.managerPosition) &&
                        formik.errors.managerPosition
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledContentLabel
                      variant="body1"
                      className={`head-text-manager ${
                        formik.touched.managerEmail &&
                        Boolean(formik.errors.managerEmail) &&
                        "error"
                      }`}
                    >
                      {t("Email")}
                    </StyledContentLabel>
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: 8 }}>
                    <TextFieldTheme
                      id="outlined-full-width"
                      fullWidth
                      name="managerEmail"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.managerEmail}
                      error={
                        formik.touched.managerEmail &&
                        Boolean(formik.errors.managerEmail)
                      }
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.managerEmail &&
                        Boolean(formik.errors.managerEmail) &&
                        formik.errors.managerEmail
                      }
                    />
                  </Grid>
                </Fragment>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </form>
    </Fragment>
  );
};

const OesyEdit = (props) => {
  const { handleChange, formData } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>OESY ({t("Year")})</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="OESY_Y"
            value={formData.OESY_Y}
            onChange={handleChange}
            MenuProps={MenuProps}
          >
            {[...Array(60).keys()].map((item) => (
              <MenuItem value={item + 1}>{item + 1}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>OESY ({t("Month")})</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="OESY_M"
            value={formData.OESY_M}
            onChange={handleChange}
          >
            {[...Array(12).keys()].map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Grid>
    </Grid>
  );
};

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [formData, setFormData] = useState(null);

  const validationSchema = Yup.object({
    manager: Yup.boolean(),
    managerName: Yup.string().when("manager", {
      is: true,
      then: Yup.string()
        .required(`${t("ThisFieldIsRequired")}`)
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
    managerPosition: Yup.string().when("manager", {
      is: true,
      then: Yup.string()
        .required(`${t("ThisFieldIsRequired")}`)
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
    managerEmail: Yup.string().when("manager", {
      is: true,
      then: Yup.string()
        .required(`${t("ThisFieldIsRequired")}`)
        .email(`${t("InvalidEmail")}`)
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      manager: false,
      managerName: null,
      managerPosition: null,
      managerEmail: null,
      level: null,
      idCompany: employeeProfile.idCompany,
      idEmployeePosition: employeeProfile.idEmployeePosition,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmitManager(values);
    },
    validateOnChange: false,
  });

  useEffect(() => {
    if (mode === "resign") {
      setFormData({
        idEmp: employeeProfile.idEmp,
        hiringDate: employeeProfile.hiringDate,
        contractTermainatoinDate: employeeProfile.contractTermainatoinDate,
      });
    } else if (mode === "employeeType") {
      setFormData({
        idEmp: employeeProfile.idEmp,
        idJobLevel: employeeProfile.idJobLevel,
        idPersonnelLevel: employeeProfile.idPersonnelLevel,
        idEmployeeType: employeeProfile.idEmployeeType,
        mainWorkingLocationPoint: employeeProfile.mainWorkingLocationPoint,
        workingLocationName: employeeProfile.workingLocationName,
        methodAttendance: employeeProfile.methodAttendance,
        employmentTime: employeeProfile.employmentTime,
        filed_office: employeeProfile.filed_office,
        isFinger: employeeProfile.isFinger,
      });
    } else if (mode === "managerLV1") {
      setFormData({
        idEmp: employeeProfile.idEmp,
        // idManagerLV1: employeeProfile.idManagerLV1,
        managerLV1: employeeProfile.idManagerLV1
          ? `idManagerEmployee-${employeeProfile.idManagerLV1}`
          : employeeProfile.idManagerGroupLV1
          ? `idManagerGroup-${employeeProfile.idManagerGroupLV1}`
          : "",
      });
    } else if (mode === "managerLV2") {
      setFormData({
        idEmp: employeeProfile.idEmp,
        // idManagerLV2: employeeProfile.idManagerLV2,
        managerLV2: employeeProfile.idManagerLV2
          ? `idManagerEmployee-${employeeProfile.idManagerLV2}`
          : employeeProfile.idManagerGroupLV2
          ? `idManagerGroup-${employeeProfile.idManagerGroupLV2}`
          : "",
      });
    } else if (mode === "experience") {
      setFormData({
        idEmp: employeeProfile.idEmp,
        OESY_Y: employeeProfile.OESY_Y,
        OESY_M: employeeProfile.OESY_M,
      });
    }
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeLocation = (event) => {
    const { name, value } = event.target;
    let temp = { ...formData };
    temp.methodAttendance = value;
    if (value === "all" || value === "point") {
      temp.mainWorkingLocationPoint = null;
    }
    setFormData(temp);
  };

  const handleDeleteManagerLV2 = () => {
    setFormData({
      ...formData,
      ["idManagerLV2"]: null,
      ["managerLV2"]: null,
    });
  };

  const handleChangeDate = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (mode === "resign") {
      formData.UpdateBy = userProfile.idUsers;
      formData.hiringDate = formData.hiringDate
        ? dayjs(formData.hiringDate).format("YYYY-MM-DD")
        : null;
      formData.contractTermainatoinDate = formData.contractTermainatoinDate
        ? dayjs(formData.contractTermainatoinDate).format("YYYY-MM-DD")
        : null;
      const result = await dispatch(updateUser(formData));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmp));
      }
    } else if (mode === "employeeType") {
      formData.UpdateBy = userProfile.idUsers;
      const result = await dispatch(updateUser(formData));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmp));
      }
    } else if (mode === "experience") {
      console.log("experience formData :", formData);
      formData.UpdateBy = userProfile.idUsers;
      formData.OESY = `${formData.OESY_Y ? formData.OESY_Y : 0}/${
        formData.OESY_M ? formData.OESY_M : 0
      }`;
      const result = await dispatch(updateUser(formData));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmp));
      }
    }
  };

  const handleSubmitManager = async () => {
    const dataRequest = { ...formData };
    dataRequest.updateBy = userProfile.idUsers;
    dataRequest.idEmployeePosition = employeeProfile.idEmployeePosition;

    if (formik.values.manager) {
      console.log("add new");
      console.log("formik.values : ", formik.values);
      const result = await dispatch(updateUser(formik.values, true));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmp));
      }
    } else {
      console.log("change");

      if (mode === "managerLV1") {
        if (dataRequest.managerLV1) {
          if (dataRequest.managerLV1.split("-")[0] === "idManagerEmployee") {
            dataRequest.idManagerLV1 = dataRequest.managerLV1.split("-")[1];
            dataRequest.idManagerGroupLV1 = null;
          } else if (
            dataRequest.managerLV1.split("-")[0] === "idManagerGroup"
          ) {
            dataRequest.idManagerLV1 = null;
            dataRequest.idManagerGroupLV1 =
              dataRequest.managerLV1.split("-")[1];
          }
        } else {
          dataRequest.idManagerLV1 = null;
          dataRequest.idManagerGroupLV1 = null;
        }
      }

      if (mode === "managerLV2") {
        if (dataRequest.managerLV2) {
          if (dataRequest.managerLV2.split("-")[0] === "idManagerEmployee") {
            dataRequest.idManagerLV2 = dataRequest.managerLV2.split("-")[1];
            dataRequest.idManagerGroupLV2 = null;
          } else if (
            dataRequest.managerLV2.split("-")[0] === "idManagerGroup"
          ) {
            dataRequest.idManagerLV2 = null;
            dataRequest.idManagerGroupLV2 =
              dataRequest.managerLV2.split("-")[1];
          }
        } else {
          dataRequest.idManagerLV2 = null;
          dataRequest.idManagerGroupLV2 = null;
        }
      }

      delete dataRequest["managerLV1"];
      delete dataRequest["managerLV2"];

      const result = await dispatch(employeeManagerUpdate(dataRequest));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmp));
      }
    }
  };

  const resetFormDataManager = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <DrawerCustom
      title={
        mode === "manager"
          ? `${t("EditManagerInfo")}`
          : mode === "resign"
          ? `${t("StartWork")}/${t("RenewalDate")}`
          : `${t("EditJobInfo")}`
      }
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {(mode === "managerLV1" || mode === "managerLV2") && (
          <Manager
            mode={mode}
            handleChange={handleChange}
            formData={formData}
            handleDeleteManagerLV2={handleDeleteManagerLV2}
            resetFormDataManager={resetFormDataManager}
            formik={formik}
          />
        )}
        {mode === "resign" && (
          <HiringDate handleChange={handleChangeDate} formData={formData} />
        )}
        {mode === "employeeType" && (
          <EmployeeType
            handleChange={handleChange}
            formData={formData}
            handleChangeLocation={handleChangeLocation}
          />
        )}
        {mode === "experience" && (
          <OesyEdit handleChange={handleChange} formData={formData} />
        )}

        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            {t("Cancel")}
          </ButtonBlue>

          {mode === "managerLV1" || mode === "managerLV2" ? (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <ButtonBlue variant="contained" type="submit">
                {t("Save")}
              </ButtonBlue>
            </form>
          ) : (
            <ButtonBlue
              variant="contained"
              onClick={handleSubmit}
              disabled={!formik.isValid}
            >
              {t("Save")}
            </ButtonBlue>
          )}
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
