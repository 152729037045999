import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Route, Redirect } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Container from "@mui/material/Container";

import AddIcon from "@mui/icons-material/Add";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";

import { allPayTypes, addPaytypes, updatePaytypes } from "../../../../../actions/paytypes";

import Content from "./cardContent";
import DialogNew from "./dialogNew";

const StyledRoot = styled("div")({
  minHeight: 700,
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
    marginTop: 16,
  },
});

const PayrollAdditionDeductionPage = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: paytypesList } = useSelector((state) => state.paytypes);
  const [openDialogNew, setOpenDialogNew] = React.useState(false);
  const [isPayroll, setIsPayroll] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [mode, setMode] = useState("new");

  useEffect(() => {
    if (userProfile) {
      if (
        userProfile.isSuperAdmin === 1 ||
        (userProfile.confidentialData.length === 1 &&
          (userProfile.confidentialData[0].idCompany === null ||
            userProfile.confidentialData[0].idCompany !== null) &&
          userProfile.confidentialData[0].isPayroll === 1)
      ) {
        setIsPayroll(true);
      } else if (userProfile.confidentialData.length > 1) {
        const foundIsPayroll = userProfile.confidentialData.find(
          (x) => x.isPayroll === 1
        );
        if (foundIsPayroll) {
          setIsPayroll(true);
        } else {
          setIsPayroll(false);
        }
      } else {
        setIsPayroll(false);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    dispatch(allPayTypes());
  }, []);

  const handleOpenDialogNew = () => {
    setMode("new");
    setOpenDialogNew(true);
  };

  const handleOpenDialogEdit = (value) => {
    setSelectedValue(value);
    setMode("edit");
    setOpenDialogNew(true);
  };

  const handleCloseDialogNew = () => {
    setOpenDialogNew(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSubmit = async (values) => {
    let formData = { ...values };

    let checkCode = formData.preCode + formData.code;
    let foundDuplicate = paytypesList[formData.payTypes].find(
      (x) => x.code === checkCode && mode === "new"
    );

    if (foundDuplicate) {
      alert("ไม่สามารถบันทึกได้เนื่องจาก...รหัสรายการซ้ำ");
    } else {
      let result = null;
      if (mode === "new") {
        result = await dispatch(addPaytypes(formData));
      } else {
        formData[
          `id${
            formData.payTypes.charAt(0).toUpperCase() +
            formData.payTypes.slice(1)
          }`
        ] =
          selectedValue[
            `id${
              formData.payTypes.charAt(0).toUpperCase() +
              formData.payTypes.slice(1)
            }`
          ];

        result = await dispatch(updatePaytypes(formData));
      }

      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          dispatch(allPayTypes());
        } else {
          handleChangeAlertType("error");
        }
        handleCloseDialogNew();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleCloseDialogNew();
      }
    }
  };

  return (
    <Fragment>
      {userProfile && (
        <Fragment>
          {isPayroll === false ? (
            <Route>
              <Redirect to="/unauthorized" />
            </Route>
          ) : (
            <StyledRoot className={`page`}>
              <Container maxWidth="lg">
                <div className="wrap-btn">
                  <ButtonBlue
                    variant={"contained"}
                    startIcon={<AddIcon />}
                    onClick={handleOpenDialogNew}
                  >
                    {t("CreateNewItem")}
                  </ButtonBlue>
                </div>
                <Content paytype={"Addition"} handleOpenDialogEdit={handleOpenDialogEdit}/>
                <Content paytype={"Deduction"} handleOpenDialogEdit={handleOpenDialogEdit}/>
                <DialogNew
                mode={mode}
                selectedValue={selectedValue}
                  open={openDialogNew}
                  handleClose={handleCloseDialogNew}
                  handleSubmit={handleSubmit}
                />
                <AlertResponse
                  open={openAlert}
                  handleClose={handleCloseAlert}
                  alertType={alertType}
                />
              </Container>
            </StyledRoot>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default PayrollAdditionDeductionPage;
