import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

import { styled } from "@mui/material/styles";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import IconButton from "@mui/material/IconButton";
import {
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Divider,
} from "@mui/material";

import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import DrawerCustom from "../../../../shared/general/Drawer";

import Utils from "../../../../../../utils";

//import { addAddition } from "../../../../../actions/addition";
import { getEmployeeAdditionDeduction } from "../../../../../../actions/employee";
import { updatePayrunDetail } from "../../../../../../actions/payruns";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

const StyledContent = styled("div")({
  width: 550,
  padding: 24,
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    marginBottom: 8,
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .btn-add": {
    display: "flex",
    justifyContent: "center",
  },
});

const StyledList = styled("div")({
  padding: 16,
  "& .line": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    "& .MuiIconButton-root": {
      color: "#919eab",
      padding: 0,
      marginRight: 8,
    },
  },
  "& .wrap-header": {
    marginBottom: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .header": {
    fontWeight: 500,
    fontSize: 14,
  },
  "& .nothing": {
    fontStyle: "oblique",
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    color: "#919eab",
    backgroundColor: "#FFFFFF",
    fontSize: 16,
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007aff",
      border: "1px solid #007aff",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #919eab",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  "&.head-text-manager": {
    fontWeight: 400,
    "&.error": {
      color: "#f15e5e",
    },
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});
const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapActionButton = styled("div")({
  marginTop: 8,
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

const validationSchema = yup.object({
  payRunTypes: yup.string().required("โปรดกรอกข้อมูล"),
  payperiod: yup.string().required("โปรดกรอกข้อมูล"),
  idPaymentType: yup.string().required("โปรดกรอกข้อมูล"),
});

const DialogEdit = (props) => {
  const {
    open,
    handleClose,
    selectedRowsData,
    handleChangeAlertType,
    handleOpenAlert,
    handleCloseAlert,
    handleGetAllDetail
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeAdditionDeduction } = useSelector(
    (state) => state.employeeAdditionDeduction
  );
  const [view, setView] = React.useState("addition");
  const [editMode, setEditMode] = React.useState(null);
  const [formData, setFormData] = React.useState(null);
  const [formDataOt, setFormDataOt] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      payRunTypes: "normal",
      payperiod: new Date(),
      idPaymentType: "2",
      payrunMode: "1",
      payrunName: `${userProfile && userProfile.vendorName} ${dayjs(
        new Date()
      ).format("MMMM BBBB")}`,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    if (selectedRowsData) {
      dispatch(getEmployeeAdditionDeduction(selectedRowsData.idEmp));
      setFormDataOt({
        otOneHours: selectedRowsData.otOneHours,
        otOneFiveHours: selectedRowsData.otOneFiveHours,
        otTwoHours: selectedRowsData.otTwoHours,
        otThreeHours: selectedRowsData.otThreeHours,
      });
    }
  }, [selectedRowsData]);

  const handleSubmit = async () => {
    let data = null;
    if (editMode === "workingDay") {
      data = { ...formData };
      data["idPayrunDetail"] = selectedRowsData.idPayrunDetail;
      data["idPayrun"] = selectedRowsData.idPayrun;
      data["salary"] = selectedRowsData.salary;
      data["salaryPaid"] = selectedRowsData.salaryPaid;
    } else {
      data = { ...formDataOt };
      data["idPayrunDetail"] = selectedRowsData.idPayrunDetail;
      data["idPayrun"] = selectedRowsData.idPayrun;
      data["salary"] = selectedRowsData.salary;
      data["salaryPaid"] = selectedRowsData.salaryPaid;
    }

    console.log(data);
    const result = await dispatch(updatePayrunDetail(data));
    if (result) {
      handleGetAllDetail()
      handleClose()
      handleOpenAlert(result)
      handleChangeAlertType(result)
    }
  };

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
    }
  };

  const handleChangeOt = (event) => {
    const { name, value } = event.target;
    setFormDataOt({ ...formDataOt, [name]: value });
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title={"แก้ไขรายการ"}
      anchor={"right"}
    >
      {selectedRowsData && (
        <StyledContent>
          <Typography variant="h5">{`${selectedRowsData.employeeId}: ${selectedRowsData.firstname} ${selectedRowsData.lastname}`}</Typography>
          <Typography
            color="text.third"
            style={{ fontWeight: 500, marginBottom: 16 }}
          >
            {selectedRowsData.departmentName
              ? `${selectedRowsData.departmentName} — `
              : ""}
            <Typography
              color="text.secondary"
              component="span"
            >{`${selectedRowsData.positionsName}`}</Typography>
          </Typography>
          <Grid container alignItems="center" spacing={2}>
            {selectedRowsData.paymentType === "รายวัน" && (
              <Fragment>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>จำนวนวัน</StyledContentLabel>
                  <ButtonBlue onClick={() => setEditMode("workingDay")}>
                    แก้ไข
                  </ButtonBlue>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <StyledFormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="idPersonnelLevel"
                      defaultValue={selectedRowsData.workingDay}
                      inputProps={{ readOnly: editMode !== "workingDay" }}
                      onChange={(event) => {
                        setFormData({
                          workingDay: event.target.value,
                        });
                      }}
                    >
                      {[...Array(31).keys()].map((item) => (
                        <MenuItem value={item + 1}>{item + 1}</MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                  {editMode === "workingDay" && (
                    <StyledWrapActionButton>
                      <ButtonBlue
                        variant="text"
                        onClick={() => setEditMode(null)}
                      >
                        ยกเลิก
                      </ButtonBlue>
                      <ButtonBlue variant="contained" onClick={handleSubmit}>
                        บันทึกข้อมูล
                      </ButtonBlue>
                    </StyledWrapActionButton>
                  )}
                </Grid>
                <StyledDivider />
              </Fragment>
            )}

            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StyledContentLabel>ชั่วโมงทำงานล่วงเวลา</StyledContentLabel>
                <ButtonBlue onClick={() => setEditMode("ot")}>แก้ไข</ButtonBlue>
              </div>
            </Grid>
            {formDataOt && (
              <Fragment>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>OT 1 (ชั่วโมง)</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextFieldTheme
                    name="otOneHours"
                    value={formDataOt.otOneHours}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>OT 1.5 (ชั่วโมง)</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextFieldTheme
                    name="otOneFiveHours"
                    value={formDataOt.otOneFiveHours}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>OT 2 (ชั่วโมง)</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextFieldTheme
                    name="otTwoHours"
                    value={formDataOt.otTwoHours}
                    onChange={handleChangeOt}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>OT 3 (ชั่วโมง)</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextFieldTheme
                    name="otThreeHours"
                    value={formDataOt.otThreeHours}
                    InputProps={{
                      readOnly: editMode !== "ot",
                    }}
                  />
                </Grid>
              </Fragment>
            )}

            {editMode === "ot" && (
              <StyledWrapActionButton>
                <ButtonBlue variant="text" onClick={() => setEditMode(null)}>
                  ยกเลิก
                </ButtonBlue>
                <ButtonBlue variant="contained" onClick={handleSubmit}>
                  บันทึกข้อมูล
                </ButtonBlue>
              </StyledWrapActionButton>
            )}
            <StyledDivider />
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StyledContentLabel>Addition</StyledContentLabel>
                <ButtonBlue onClick={() => setEditMode("ot")}>เพิ่ม</ButtonBlue>
              </div>
            </Grid>
            {selectedRowsData.payrunDetailAddition.map((item) => (
              <Fragment>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>{`${item.name}`}</StyledContentLabel>
                  <StyledContentLabel>{`(${item.code})`}</StyledContentLabel>
                  <ButtonBlue onClick={() => setEditMode(item.code)}>
                    แก้ไข
                  </ButtonBlue>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <StyledFormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="idPersonnelLevel"
                    >
                      {[...Array(31).keys()].map((item) => (
                        <MenuItem value={item + 1}>{item + 1}</MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                  {editMode === item.code && (
                    <StyledWrapActionButton>
                      <ButtonBlue
                        variant="text"
                        onClick={() => setEditMode(null)}
                      >
                        ยกเลิก
                      </ButtonBlue>
                      <ButtonBlue variant="contained">บันทึกข้อมูล</ButtonBlue>
                    </StyledWrapActionButton>
                  )}
                </Grid>
              </Fragment>
            ))}
            <StyledDivider />
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <StyledContentLabel>Deduction</StyledContentLabel>
                <ButtonBlue onClick={() => setEditMode("ot")}>เพิ่ม</ButtonBlue>
              </div>
            </Grid>
            {selectedRowsData.payrunDetailDeduction.map((item) => (
              <Fragment>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>{`${item.name}`}</StyledContentLabel>
                  <StyledContentLabel>{`(${item.code})`}</StyledContentLabel>
                  <ButtonBlue onClick={() => setEditMode(item.code)}>
                    แก้ไข
                  </ButtonBlue>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <StyledFormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="idPersonnelLevel"
                    >
                      {[...Array(31).keys()].map((item) => (
                        <MenuItem value={item + 1}>{item + 1}</MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                  {editMode === item.code && (
                    <StyledWrapActionButton>
                      <ButtonBlue
                        variant="text"
                        onClick={() => setEditMode(null)}
                      >
                        ยกเลิก
                      </ButtonBlue>
                      <ButtonBlue variant="contained">บันทึกข้อมูล</ButtonBlue>
                    </StyledWrapActionButton>
                  )}
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </StyledContent>
      )}
    </DrawerCustom>
  );
};

export default DialogEdit;
