import React, { useState, useEffect } from "react";
import StyledCard from "../../shared/general/CardCharts";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";

import ChartsArea from "./chartsArea";
import ChartsOTType from "./chartsOTType";
import TableChartOTType from "./ot/tableChartOTType";

export default function CardChart(props) {
  const {
    chartName,
    max,
    chart,
    chartOT36,
    empOTBaht,
    otType,
    absentWork,
    lateWork,
    totalSalary,
    totalOT,
    valueSumOT,
  } = props;

  return (
    <StyledCard>
      <CardContent style={{ paddingBottom: 0 }}>
        <Box>
          <Typography variant="h4" className={`cardTitle`} gutterBottom>
            {chartName}
          </Typography>
          <Box style={{ paddingBottom: 20 }}>
            {chart === "area" ? (
              <>
                {chartOT36 && <ChartsArea  chartOT36={chartOT36} />}
                {empOTBaht && <ChartsArea  empOTBaht={empOTBaht} />}
                {absentWork && <ChartsArea max={max} />}
                {lateWork && <ChartsArea max={max} />}
                {totalSalary && <ChartsArea max={max} />}
                {totalOT && <ChartsArea max={max} totalOT={totalOT} />}
              </>
            ) : (
              <div>
                <ChartsOTType otType={otType} />
                <TableChartOTType otType={otType} valueSumOT={valueSumOT} />
              </div>
            )}
          </Box>
        </Box>
      </CardContent>
    </StyledCard>
  );
}
