import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import ExcelJS from 'exceljs';

import {
    Box,
    Divider,
    Typography,
    Grid,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Autocomplete,
    TextField,
    Stack,
    Button,
} from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getAllCompanysByIdVendor } from "../../../../actions/company";
import DatePickerCustom from "../../shared/date/datePicker";
import { useTranslation } from "react-i18next";
import FileSelected from "../employee/first-upload-employee/file-selected";
import UploadFile from "../payroll/pay-runs/new/upload-file";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { addBillingAddition } from "../../../../actions/billing";
import AlertResponse from "../../shared/general/AlertResponse";
import XlsxIcon from "../../assets/xlsx.png";

const StyledRoot = styled("div")({
    width: 550,
    padding: 24,
    "& .topic": {
        fontWeight: "500",
        marginRight: "8px"
    },
    "& .detail": {
        fontSize: "18px"
    },
    "& .topic-textField": {
        fontWeight: "500",
        marginBottom: "8px"
    },
    "& .textField-bottom": {
        marginBottom: "16px"
    }
});

const StyledFooter = styled("div")({
    padding: "16px 0px",
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const StyledAutoComplete = styled(Autocomplete)({
    "& .MuiFilledInput-root": {
        padding: "13.5px 14px",
        paddingRight: "32px",
        "& input": {
            padding: 0
        }
    }
})

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&:after": {
            border: "none",
        },
        "&:before": {
            border: "none",
            borderBottomStyle: "none !important",
        }
    },
});

const StyledShowSelected = styled("div")({
    maxWidth: 450,
    margin: "auto",
    display: "flex",
    "& img": {
        marginRight: 8,
    },
});

const StyledBtnSave = styled("div")({
    maxWidth: 450,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    marginTop: 36,
});

const DialogCreateBill = (props) => {
    const { t, i18n } = useTranslation();
    const { open, handleClose, additionLists } = props;
    const dispatch = useDispatch();

    const today = new Date();
    const [search, setSearch] = useState({
        start: dayjs(today).set("date", 1),
        end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0))
    });

    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(null);
    const [allCompanies, setAllCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [fileSelected, setFileSelected] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [ListName, setListName] = useState(null);

    const { result: userProfile } = useSelector((state) => state.userProfile);

    const fetchedAllCompanies = async (idVendor) => {
        try {
            const response = await dispatch(getAllCompanysByIdVendor(idVendor));
            if (response && response.data) {
                setAllCompanies(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
        setFileSelected(null);
        setIsSubmitting(false);
        setListName(null);
        handleClose();
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const handleFileChange = (event) => {
        setFileSelected(event.target.files[0]);
    };

    useEffect(() => {
        if (userProfile) {
            fetchedAllCompanies(userProfile.idVendor);
        }
    }, [userProfile]);

    useEffect(() => {
        if (userProfile && allCompanies && allCompanies.length > 0) {
            let ownCompany = allCompanies.find(item => item.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = allCompanies[0];
            }
            setSelectedCompany(ownCompany);
        }
    }, [userProfile, allCompanies]);

    const DownloadxlsxTemplate = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');

            const headerRow = worksheet.addRow(["ชื่อรายการ Additions", "Additions รวม"]);

            const headerStyle1 = {
                font: { bold: true, size: 20, name: 'TH SarabunPSK' },
                alignment: { horizontal: "center", vertical: 'middle' },
            };

            headerRow.eachCell((cell) => {
                cell.style = headerStyle1;
            });

            if (additionLists && additionLists.length > 0) {
                additionLists.forEach((item, index) => {
                    const rowNumber = index + 2;
                    const cell = worksheet.getCell(`A${rowNumber}`);
                    cell.value = item.name;
                    cell.font = { bold: true, size: 18, name: 'TH SarabunPSK' };
                    cell.alignment = { horizontal: "center", vertical: 'middle' };
                });
            }

            worksheet.getColumn(1).width = 40;
            worksheet.getColumn(2).width = 40;

            const data = await workbook.xlsx.writeBuffer();
            const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `Additions.xlsx`;
            a.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error(error);
        }
    };

    const handleClick = async () => {
        try {
            if (!fileSelected) {
                console.error("No file selected");
                return;
            }

            const reader = new FileReader();

            reader.onload = async (event) => {
                try {
                    const data = new Uint8Array(event.target.result);
                    const workbook = new ExcelJS.Workbook();
                    await workbook.xlsx.load(data);

                    let convertedData = [];
                    const worksheet = workbook.getWorksheet(1);

                    worksheet.eachRow((row, rowNumber) => {
                        if (rowNumber > 1) {
                            const rowData = row.values.slice(1);
                            const additionName = rowData[0];
                            const additionValue = rowData[1];
                            const findAdditionId = additionLists && additionLists.find(item => item.name === additionName);

                            convertedData.push({
                                listName: ListName,
                                additionsValue: additionValue,
                                idVendor: userProfile.idVendor,
                                idAddition: findAdditionId ? findAdditionId.idAddition : null,
                                additionName: findAdditionId ? findAdditionId.name : null,
                                start: search.start,
                                end: search.end,
                                idCompany: selectedCompany.idCompany
                            });
                        }
                    });

                    if (convertedData.length > 0) {
                        setIsSubmitting(true);
                        const response = await addBillingAddition(convertedData);
                        if (response && response.status === 200 && response.data) {
                            const base64String = response.data.FiletoSend;
                            const bytes = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
                            const blob = new Blob([bytes], { type: "application/pdf" });
                            const docUrl = URL.createObjectURL(blob);
                            window.open(docUrl, "_blank");

                            convertedData = [];
                            setOpenAlert(true);
                            setAlertType("success");
                        } else {
                            convertedData = [];
                            setOpenAlert(true);
                            setAlertType("error");
                        }
                    } else {
                        convertedData = [];
                        setOpenAlert(true);
                        setAlertType("error");
                    }
                } catch (error) {
                    console.error("Error processing file:", error);
                }
            };

            reader.onerror = (error) => {
                console.error("Error reading file:", error);
            };

            reader.readAsArrayBuffer(fileSelected);
        } catch (error) {
            console.error("Error handling click:", error);
        }
    };

    return (
        <DrawerCustom
            title={"สร้าง Billing"}
            anchor={"right"}
            open={open ? true : false}
            onClose={handleClose}
        >
            <StyledRoot>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            ชื่อรายการ
                        </Typography>
                        <TextFieldTheme variant={"outlined"} onChange={(e) => setListName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">{t("Company")}</Typography>
                        <StyledAutoComplete
                            options={allCompanies ? allCompanies : []}
                            getOptionLabel={(option) => option.companyName}
                            isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                            // disabled={disabled}
                            renderInput={(params) => (
                                <StyledTextField
                                    {...params}
                                    InputProps={{ ...params.InputProps, endAdornment: null }}
                                    variant="filled"
                                    placeholder="เลือกบริษัท"
                                />
                            )}
                            value={selectedCompany}
                            onChange={onChangeCompany}
                            noOptionsText="ไม่พบข้อมูล"
                            disableClearable
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("StartDate")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                                inputFormat="DD/MM/YYYY"
                                value={search.start}
                                name="start"
                                // disabled={isFetching}
                                onChange={(newValue) => {
                                    setSearch({ ...search, ["start"]: newValue });
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("EndDate")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                minDate={search.start}
                                inputFormat="DD/MM/YYYY"
                                value={search.end}
                                name="end"
                                // disabled={isFetching}
                                onChange={(newValue) => {
                                    setSearch({ ...search, ["end"]: newValue });
                                }}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div>
                            <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                                    {t("Upload")} Addition/Deduction
                                </Typography>

                                <ButtonBlue onClick={() => DownloadxlsxTemplate()}>
                                    Download Template
                                </ButtonBlue>
                            </Stack>
                        </div>
                    </Grid>

                    {fileSelected ? (
                        <Grid item xs={12}>
                            <StyledShowSelected>
                                <img src={XlsxIcon} width="60" />
                                <div>
                                    <Typography>{fileSelected.name}</Typography>
                                    <Typography color="text.third">
                                        {(fileSelected.size / 1024 / 1024).toFixed(2)} MB
                                    </Typography>
                                </div>
                            </StyledShowSelected>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <div className="inner-dropzone">
                                <ButtonBlue
                                    variant="outlined"
                                    component="label"
                                >
                                    เลือกไฟล์
                                    <input
                                        type="file"
                                        accept=".xlsx, .xls"
                                        hidden
                                        onChange={handleFileChange}
                                    />
                                </ButtonBlue>
                            </div>
                        </Grid>
                    )}

                </Grid>
                <Divider style={{ margin: "16px 0" }} />
                <StyledFooter>
                    <ButtonBlue
                        className="cancel"
                        onClick={
                            () => {
                                handleClose()
                                setFileSelected(null)
                            }}
                        disabled={isSubmitting

                        }>
                        ยกเลิก
                    </ButtonBlue>

                    <ButtonBlue variant="contained" onClick={handleClick} disabled={!fileSelected || isSubmitting || !ListName}>
                        สร้างบิล
                    </ButtonBlue>
                </StyledFooter>
            </StyledRoot>

            <AlertResponse
                open={openAlert}
                handleClose={() => handleCloseAlert()}
                alertType={alertType}
            />
        </DrawerCustom>
    );
};

export default DialogCreateBill;

// <UploadFile
//     handleSetFileSelected={handleSetFileSelected}
//     setFormFileSelected={setFormFileSelected}
//     formFileSelected={formFileSelected}
// />