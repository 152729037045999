import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useDispatch, useSelector } from "react-redux";


import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import ButtonBlue from "../../shared/general/ButtonBlue";
import HeaderPage from "./../../shared/header/headerPage";

import { addUsers } from "../../../../actions/user";

const columns = [
  { id: "employeeId", label: "Employee ID" },
  { id: "title", label: "Title" },
  { id: "name", label: "Name" },
  { id: "lastname", label: "Last name" },
  { id: "nickname", label: "Nickname" },
  { id: "gender", label: "Gender" },
  { id: "personalId", label: "Personal ID" },
  { id: "birthday", label: "Birthday" },
  { id: "address", label: "Address" },
  { id: "district", label: "District" },
  { id: "provience", label: "Provience" },
  { id: "areaCode", label: "Area Code" },
  { id: "telephoneMobile", label: "Telephone Mobile" },
  { id: "email", label: "Email" },
  { id: "emergencyContact", label: "ผู้ติดต่อหลัก" },
  { id: "emergencyRelationship", label: "ความสัมพันธ์" },
  { id: "emergencyPhone", label: "เบอร์ติดต่อผู้ใกล้ชิด" },
  { id: "employmentType", label: "Employment type" },
  { id: "employmentTime", label: "Employment Time" },
  { id: "workingLocation", label: "Working Location" },
  { id: "filed_office", label: "Filed/Office" },
  { id: "jobLevel", label: "Job Level" },
  { id: "personnelLevel", label: "Personnel Level" },
  { id: "jobGroup", label: "Job Group" },
  { id: "subJobGrpup", label: "Sub Job Grpup" },
  { id: "jobPosition", label: "Job Position" },
  { id: "company", label: "Company" },
  { id: "division", label: "Division" },
  { id: "department", label: "Department" },
  { id: "section", label: "Section" },
  { id: "hiringDate", label: "Hiring Date" },
  { id: "reportingManager", label: "Reporting Manager" },
  { id: "managerPosition", label: "Manager Position" },
  { id: "managerE-Mail", label: "Manager E-Mail" },
  { id: "workingType", label: "Working Type" },
  { id: "shiftType", label: "Shift Type" },
  { id: "shiftName", label: "Shift Name" },
  { id: "workingTimein", label: "Working Time in" },
  { id: "workingTimeOff", label: "Working Time Off" },
  { id: "paymentType", label: "Payment Type" },
  { id: "salary", label: "Salary" },
  { id: "charge", label: "ค่าดำเนินการปกติ" },
  { id: "chargeOt", label: "ค่าดำเนินการ OT" },
  { id: "contractTermainatoinDate", label: "Contract Termainatoin Date" },
  { id: "resignDate", label: "Resign Date" },
  { id: "OESY(Y)", label: "OESY(Y)" },
  { id: "OESY(M)", label: "OESY(M)" },
  { id: "OESY", label: "OESY" },
  { id: "Serv.(Y)", label: "Serv.(Y)" },
  { id: "Serv.(M)", label: "Serv.(M)" },
  { id: "ServYr", label: "ServYr" },
  { id: "ESY(Y)", label: "ESY(Y)" },
  { id: "ESY(M)", label: "ESY(M)" },
  { id: "ESY", label: "ESY" },
  { id: "TIG(Y)", label: "TIG(Y)" },
  { id: "TIG(M)", label: "TIG(M)" },
  { id: "TIG", label: "TIG" },
  { id: "Age(Y)", label: "Age(Y)" },
  { id: "Age(M)", label: "Age(M)" },
  { id: "educationDegree_1", label: "(1)Education Degree" },
  { id: "educationUniversity_1", label: "(1)Education School or University" },
  { id: "faculty_1", label: "(1)Faculty" },
  { id: "major_1", label: "(1)Major" },
  { id: "fromYear_1", label: "(1)From Year" },
  { id: "endYear_1", label: "(1)End Year" },
  { id: "gpa_1", label: "(1)GPA" },
  { id: "educationDegree_2", label: "(2)Education Degree" },
  { id: "educationUniversity_2", label: "(2)Education School or University" },
  { id: "faculty_2", label: "(2)Faculty" },
  { id: "major_2", label: "(2)Major" },
  { id: "fromYear_2", label: "(2)From Year" },
  { id: "endYear_2", label: "(2)End Year" },
  { id: "gpa_2", label: "(2)GPA" },
  {
    id: "performanceReviewYear_1",
    label: "Performance Review Year (Past 1 Year)",
  },
  {
    id: "performanceReviewYear_2",
    label: "Performance Review Year (Past 2 Year)",
  },
  {
    id: "performanceReviewYear_3",
    label: "Performance Review Year (Past 3 Year)",
  },
  { id: "costCenterCharge", label: "Cost Center Charge" },
  { id: "costElementCharge", label: "Cost Element Charge" },
  { id: "io", label: "IO" },
  { id: "ownerCompany", label: "Owner company" },
  { id: "workCompany", label: "บริษัทเจ้าของงาน" },
  { id: "bookBank", label: "Book Bank" },
  { id: "bookBankBranch", label: "Book Bank Branch Name" },
  { id: "bookBankId", label: "Book ID" },
];

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  minWidth: 150,
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    left: 0,
    backgroundColor: "#f4f6f8",
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  borderBottom: "none",
  padding: "8px 16px",
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledWrapHead = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const UploadResult = (props) => {
  const { excelData } = props;
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSubmit = async () => {
    const response = await dispatch(addUsers(userProfile.idCompany, excelData));
    console.log(response)
  }
  return (
    <div>
      <StyledWrapHead>
        <HeaderPage textLabel={"ตรวจสอบข้อมูล"} />
        <div>
          <ButtonBlue variant={"contained"} startIcon={<SaveOutlinedIcon />} onClick={handleSubmit}>
            {`บันทึกข้อมูล ${excelData && excelData.length} คน`}
          </ButtonBlue>
        </div>
      </StyledWrapHead>

      <Box style={{ marginTop: 16 }}>
        <TableContainer sx={{ width: "100%", maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledCellHeader
                  key={"action"}
                  style={{ minWidth: 60 }}
                ></StyledCellHeader>
                {columns.map((column) => (
                  <StyledCellHeader key={column.id}>
                    {column.label}
                  </StyledCellHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {excelData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledRowContent key={row.scG_Employee_ID}>
                      <StyledCellContent
                        key={"action"}
                        style={{ minWidth: 60 }}
                        align="center"
                      >
                        <IconButton aria-label="delete item file">
                          <DeleteOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                      </StyledCellContent>
                      {columns.map((column) => (
                        <StyledCellContent key={column.id}>
                          {row[column.id]}
                        </StyledCellContent>
                      ))}
                    </StyledRowContent>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={excelData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
};

export default UploadResult;
