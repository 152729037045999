import React, { useState, Fragment, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  Grid,
  Pagination,
  Skeleton,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Close, Edit, Save } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import CardHistoryLeave from "./CardHistoryLeave";
import DialogNewLeave from "./dialogNewLeave";

import {
  getEmployeeLeaveRequest,
  getLeaveQuota,
  getLeaveRequestAllYear,
} from "../../../../../../actions/employee";
import DialogEdit from "./DialogEdit";
import CardRequestLeave from "../../../../shared/leave/CardRequestLeave";
import DialogConfirmWithdraw from "../../../../shared/general/DialogConfirmWithdraw";
import DialogWithdrawRequestLeave from "../../../../shared/leave/DialogWithdrawRequestLeave";
import { getLeaveAvailable, withdrawEmployeeLeaveRequest } from "../../../../../../actions/leave";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import utils, { getRoundNumber } from "../../../../../../utils";
import leaveService from "../../../../../../services/leave.service";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DrawerLeaveQuota from "./DrawerLeaveQuota";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledCardDark = styled("div")({
  padding: 8,
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardTitle": {
    fontSize: 22,
  },
});

const StyledWrapHistory = styled("div")({
  display: "flex",
  flexWrap: "wrap",
});

const Leave = () => {

  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: leaveEmployeesList, isFetching: isFetchingLeaveEmployeesList } = useSelector((state) => state.leaveEmployees);
  const { result: leaveAvailable } = useSelector((state) => state.leaveAvailable);

  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [dateSearch, setDateSearch] = useState(dayjs(today).format("YYYY"));

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [leaveRoundOptions, setLeaveRoundOptions] = useState([]);
  const [selectedLeaveRound, setSelectedLeaveRound] = useState("");
  const [isFetchingLeaveRound, setIsFetchingLeaveRound] = useState(true);

  const [drawerLeaveQuotaConfig, setDrawerLeaveQuotaConfig] = useState({
    isOpen: false,
    data: {},
  })

  // const [paginationLeaveEmployeesList, setPaginationEmployeesList] = useState([]);
  
  const [paginationLeaveRequestConfig, setPaginationLeaveRequestConfig] = useState({
    count: 1,
    page: 1,
    data: [],
  })

  const [dialogDeleteLeaveRequestConfig, setDialogDeleteLeaveRequestConfig] = useState({
    isOpen: false,
    data: {},
  })

  const handleChangePageLeaveEmployeesList = (array, page) => {
    setPaginationLeaveRequestConfig(prev => ({
      ...prev,
      page: page,
      data: array.slice((page*10)-10, (page*10))
    }))
  }

  const fetchingLeaveEmployeesList = (year) => {
    dispatch(getEmployeeLeaveRequest(employeeProfile.idEmp, {
      start: `${year}-01-01`,
      end: `${year}-12-31`
    })).then(res => {
      if(res.status === 200){
        setPaginationLeaveRequestConfig(prev => ({
          ...prev,
          count: Math.ceil(res.data.length / 10),
          page: 1,
          data: res.data.slice((1*10)-10, (1*10))
        }))
      }
    })
  }

  const handleOpenDialogLeaveRequest = (data) => {
    console.log(data)
    setDialogDeleteLeaveRequestConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        idLeave: data.idLeave,
        isFullDay: data.isFullDay,
        start: data.start,
        end: data.end,
        leaveGroupName: data.leaveGroupName,
      }
    }))
  }

  const handleCloseDialogDeleteLeaveRequest = () => {
    setDialogDeleteLeaveRequestConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleSubmitDialogDeleteLeaveRequest = async () => {
    console.log(dialogDeleteLeaveRequestConfig.data)
    if(dialogDeleteLeaveRequestConfig.data.idLeave){
      const result = await dispatch(withdrawEmployeeLeaveRequest(dialogDeleteLeaveRequestConfig.data.idLeave))
      if(result && result.status === 200) {
        handleCloseDialogDeleteLeaveRequest();
        dispatch(openNotificationAlert({
          type: "success",
          message: t("DataSaveSuccessful"),
        }));
        fetchingLeaveEmployeesList(dateSearch)
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred")
        }));
      }
    }
  }

  useEffect(() => {
    if (employeeProfile) {
      // dispatch(getLeaveQuota(employeeProfile.idEmp, null, dateSearch));
      // dispatch(
      //   getLeaveRequestAllYear(
      //     dateSearch,
      //     employeeProfile.idEmp
      //   )
      // );
      // fetchingLeaveEmployeesList(dateSearch);
      // dispatch(getLeaveAvailable({ date: dayjs(`${dateSearch}-01-01`).format("YYYY-MM-DD"), idEmp: employeeProfile.idEmp }));
      fetchingLeaveRoundList();
    }
  }, [employeeProfile]);

  const fetchingLeaveAvailable = () => {
    if(selectedLeaveRound){
      dispatch(getLeaveAvailable({ date: dayjs(selectedLeaveRound).format("YYYY-MM-DD"), idEmp: employeeProfile.idEmp }));
    }
  }

  const fetchingLeaveRoundList = () => {
    setIsFetchingLeaveRound(true);
    leaveService.getLeaveRoundList().then(res => {
      const today = dayjs();
      const foundLeaveRound = res.data.find(l => (
        today.isSameOrAfter(dayjs(l.start)) && today.isSameOrBefore(dayjs(l.end))
      ))
      if(foundLeaveRound) setSelectedLeaveRound(foundLeaveRound.date)
      dispatch(getLeaveAvailable({ date: dayjs(foundLeaveRound.date).format("YYYY-MM-DD"), idEmp: employeeProfile.idEmp }));
      fetchingLeaveEmployeesList(dayjs(foundLeaveRound.date).format("YYYY"));
      setLeaveRoundOptions(res.data)
    }).finally(() => {
      setIsFetchingLeaveRound(false);
    })
  }

  const handleCloseDialogConfirmLeave = () => {
    setOpenNewDialog(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleChangeSelectedLeaveRound = (event) => {
    setSelectedLeaveRound(event.target.value);
    fetchingLeaveEmployeesList(event.target.value);
    dispatch(getLeaveAvailable({ date: dayjs(`${event.target.value}`).format("YYYY-MM-DD"), idEmp: employeeProfile.idEmp }));
  }

  const handleCloseDrawerLeaveQuota = () => {
    setDrawerLeaveQuotaConfig(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleOpenDrawerLeaveQuota = (leave) => {
    setDrawerLeaveQuotaConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {
        idLeaveQuota: leave.leaveQuota ? leave.leaveQuota.idLeaveQuota : null,
        leaveValue: leave.leaveQuota ? leave.leaveQuota.leaveValue : null,
        carryValue: leave.leaveQuota ? leave.leaveQuota.carryValue : null,
        idLeaveGroup: leave.idLeaveGroup,
        idLeaveType: leave.idLeaveType,
        name: leave.name,
      }
    }));
  }

  return (
    <div>
      <StyledWrapBranch>
        <Box>
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("leaveAbsent")}
          </StyledHeadLabel>
        </Box>
        <div className="wrap-branch-top">
          <Box marginTop="16px" display="flex" flexGrow={1} alignItems="center" justifyContent="space-between">
            {isFetchingLeaveRound? (
              <Fragment>
                <Skeleton width="250px"/>
                <Skeleton width="150px"/>
              </Fragment>
            ) : (
              <Fragment>
                <Box maxWidth="250px" width="100%">
                  <FormControl fullWidth>
                    <Select
                      onChange={handleChangeSelectedLeaveRound}
                      value={selectedLeaveRound}
                    >
                      {leaveRoundOptions.map(l => (
                        <MenuItem key={l.date} value={l.date}>{`${dayjs(l.start).format("D MMM BBBB")} - ${dayjs(l.end).format("D MMM BBBB")}`}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <ButtonBlue
                  startIcon={<AddIcon />}
                  variant="contained"
                  onClick={() => setOpenNewDialog(true)}
                >
                  {t("CreateLeave")}
                </ButtonBlue>
              </Fragment>
            )}
          </Box>
        </div>

        {employeeProfile && (
          <TableContainer
            style={{
              border: "1px solid rgba(0,0,0,0.1)",
              borderRadius: "16px",
            }}
            sx={{
              "& .MuiTableRow-root:last-child": {
                "& .MuiTableCell-root": {
                  borderBottom: "none",
                }
              }
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 250 }}>
                    {t("LeaveType")}
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 100 }}>
                    {t("LeaveRights")}/{t("CumulativeRights")} ({t("Days")})
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 50 }}>
                    {t("Used")} ({t("Days")})
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: 50 }}>
                    {t("Remaining")} ({t("Days")})
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  leaveAvailable.length > 0 &&
                  leaveAvailable.map((leave) => {
                    return (
                      <TableRow key={leave.idLeaveType}>
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            <IconButton
                              style={{marginRight: "8px"}}
                              size="small"
                              onClick={() => {handleOpenDrawerLeaveQuota(leave)}}
                            >
                              <EditRoundedIcon fontSize="14px"/>
                            </IconButton>
                            <Typography fontSize="14px">{leave.name}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {leave.leaveValue === null ? (
                            <Typography fontSize="14px">{t("Unlimited")}</Typography>
                          ) : (
                            <Typography fontSize="14px">
                              {`${utils.roundToTwo(leave.leaveValue)}`}
                              {`${leave.carryValue ? "/" + `${utils.roundToTwo(leave.carryValue)}` : ""}`}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Typography fontSize="14px">{`${utils.roundToTwo(leave.leaveUsed)}`}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          {leave.leaveValue === null ? (
                            <Typography fontSize="14px">{t("Unlimited")}</Typography>
                          ) : (
                            <Typography align="center" fontSize="14px">
                              {`${utils.roundToTwo(leave.leaveRemain)}`}
                              {`${leave.carryRemain ? "/" + `${utils.roundToTwo(leave.carryRemain)}` : ""
                                }`}
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledWrapBranch>

      <Box marginTop="16px">
        <Typography variant="h4" fontSize="22px" paddingBottom="16px">{t("LeaveHistory")}</Typography>

        {isFetchingLeaveEmployeesList && <Typography color="text.secondary" fontStyle="italic" align="center">{t("LoadingData")}...</Typography>}
        {!isFetchingLeaveEmployeesList && leaveEmployeesList.length === 0 && <Typography color="text.secondary" fontStyle="italic" align="center">{t("NoData")}</Typography>}
        {!isFetchingLeaveEmployeesList && leaveEmployeesList.length > 0 && <Fragment>
          <Box display="flex" justifyContent="flex-end" marginBottom="16px">
            <Pagination
              component="div"
              color="primary"
              count={paginationLeaveRequestConfig.count}
              shape="rounded"
              page={paginationLeaveRequestConfig.page}
              onChange={(_, newPage) => {
                handleChangePageLeaveEmployeesList(leaveEmployeesList, newPage)
              }}
            />
          </Box>
          <Grid container spacing={2}>
            {paginationLeaveRequestConfig.data.map((item) => (
              <Grid item xs={12} md={6} key={item.idLeave}>
                <CardRequestLeave data={item} handleDeleteLeaveRequest={handleOpenDialogLeaveRequest}/>
              </Grid>
            ))}
          </Grid>
        </Fragment>}
      </Box>

      <DialogNewLeave
        open={openNewDialog}
        handleClose={handleCloseDialogConfirmLeave}
        handleOpenAlert={handleOpenAlert}
        handleChangeAlertType={handleChangeAlertType}
        fetchingLeaveEmployeesList={() => {fetchingLeaveEmployeesList(dateSearch)}}
        fetchingLeaveAvailable={fetchingLeaveAvailable}
      />

      <DialogEdit open={openEdit} setOpenEdit={setOpenEdit} />

      <DialogWithdrawRequestLeave
        dialogConfig={dialogDeleteLeaveRequestConfig}
        handleSubmit={handleSubmitDialogDeleteLeaveRequest}
        handleClose={handleCloseDialogDeleteLeaveRequest}
      />

      <DrawerLeaveQuota
        leaveRoundConfig={leaveRoundOptions.find(x => x.date === selectedLeaveRound)}
        drawerConfig={drawerLeaveQuotaConfig}
        onClose={handleCloseDrawerLeaveQuota}
      />

    </div>
  );
};

export default Leave;
