import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
// import saveAs from "file-saver";
// import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";

import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { useTranslation } from "react-i18next";

import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
} from "devextreme-react/data-grid";

import utils from "../../../../../utils";

import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import loading from "../../../assets/social-media.gif";

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-round-2[role="columnheader"]': {
    backgroundColor: "#4caf50",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-wrap-header[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyledLoading = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "inherit",
});

const TableEmployee = (props) => {
  const { result, topic } = props;
  const grid = useRef();
  const { result: summaryTimeList } = useSelector((state) => state.summaryTimeCompany);
  const { isFetching: summaryTimeListFetching } = useSelector(
    (state) => state.summaryTimeCompany
  );
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const { t, i18n } = useTranslation();

  let gridColumnsRound = [
    {
      dataField: "otOneHours1",
      caption: `OT 1 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-total-addition",
      width: 120,
    },
    {
      dataField: "otOneAmount1",
      caption: `OT 1 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-total-addition",
      width: 120,
    },
    {
      dataField: "otOneFiveHours1",
      caption: `OT 1.5 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-total-addition",
      width: 120,
    },
    {
      dataField: "otOneFiveAmount1",
      caption: `OT 1.5 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-total-addition",
      width: 120,
    },
    {
      dataField: "otTwoHours1",
      caption: `OT 2 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-total-addition",
      width: 120,
    },
    {
      dataField: "otTwoAmount1",
      caption: `OT 2 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-total-addition",
      width: 120,
    },
    {
      dataField: "otThreeHours1",
      caption: `OT 3 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-total-addition",
      width: 120,
    },
    {
      dataField: "otThreeAmount1",
      caption: `OT 3 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-total-addition",
      width: 120,
    },
    {
      dataField: "absent",
      caption: `${t("Absent")} (${t("Days")})`,
      dataType: "string",
      cssClass: "column-deduction",
      width: 120,
    },
    {
      dataField: "absentAmont",
      caption: `${t("Absent")} (${t("Baht")})`,
      dataType: "string",
      cssClass: "column-deduction",
      width: 120,
    },
    {
      dataField: "lateEarlyMinute",
      caption: `${t("LateAndEarly")} (${t("Minute")})`,
      dataType: "string",
      cssClass: "column-deduction",
      width: 120,
    },
    {
      dataField: "lateEarlyAmount",
      caption: `${t("LateAndEarly")} (${t("Baht")})`,
      dataType: "string",
      cssClass: "column-deduction",
      width: 120,
    },
  ];

  let gridColumnsRoundSecond = [
    {
      dataField: "otOneHours2",
      caption: `OT 1 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-round-2",
      width: 120,
    },
    {
      dataField: "otOneAmount2",
      caption: `OT 1 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-round-2",
      width: 120,
    },
    {
      dataField: "otOneFiveHours2",
      caption: `OT 1.5 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-round-2",
      width: 120,
    },
    {
      dataField: "otOneFiveAmount2",
      caption: `OT 1.5 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-round-2",
      width: 120,
    },
    {
      dataField: "otTwoHours2",
      caption: `OT 2 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-round-2",
      width: 120,
    },
    {
      dataField: "otTwoAmount2",
      caption: `OT 2 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-round-2",
      width: 120,
    },
    {
      dataField: "otThreeHours2",
      caption: `OT 3 (${t("Hours")})`,
      format: "###,###,##0.00",
      cssClass: "column-round-2",
      width: 120,
    },
    {
      dataField: "otThreeAmount2",
      caption: `OT 3 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-round-2",
      width: 120,
    },
    {
      dataField: "absent2",
      caption: `${t("Absent")} (${t("Days")})`,
      dataType: "string",
      cssClass: "column-deduction",
      width: 120,
    },
    {
      dataField: "absentAmont2",
      caption: `${t("Absent")} (${t("Baht")})`,
      dataType: "string",
      cssClass: "column-deduction",
      width: 120,
    },
    {
      dataField: "lateEarlyMinute2",
      caption: `${t("LateAndEarly")} (${t("Minute")})`,
      dataType: "string",
      cssClass: "column-deduction",
      width: 120,
    },
    {
      dataField: "lateEarlyAmount2",
      caption: `${t("LateAndEarly")} (${t("Baht")})`,
      dataType: "string",
      cssClass: "column-deduction",
      width: 120,
    },
  ];

  useEffect(() => {
    if (summaryTimeList && summaryTimeList.dataResponse) {
      let temp = [...summaryTimeList.dataResponse];
      temp = temp.filter((x) => x.idPaymentType === 1 || x.idPaymentType === 4);
      temp.forEach((element) => {
        element["fullname"] = [element.firstname, element.lastname].join(" ");

        element["otOneHours1"] = element.roundDate[0].ot.otOneHours;
        element["otOneAmount1"] = element.roundDate[0].ot.otOneAmount;
        element["otOneFiveHours1"] = element.roundDate[0].ot.otOneFiveHours;
        element["otOneFiveAmount1"] = element.roundDate[0].ot.otOneFiveAmount;
        element["otTwoHours1"] = element.roundDate[0].ot.otTwoHours;
        element["otTwoAmount1"] = element.roundDate[0].ot.otTwoAmount;
        element["otThreeHours1"] = element.roundDate[0].ot.otThreeHours;
        element["otThreeAmount1"] = element.roundDate[0].ot.otThreeAmount;

        element["workingDay"] = element.roundDate[0].workDay.valueDay;

        element["absent"] = element.roundDate[0].absent.valueDay;
        element["absentAmont"] = element.roundDate[0].absent.valueAmount;

        element["lateEarlyMinute"] = element.roundDate[0].lateEarly.valueMinute;
        element["lateEarlyAmount"] = element.roundDate[0].lateEarly.valueAmount;

        element["absent2"] = element.roundDate[1].absent.valueDay;
        element["absentAmont2"] = element.roundDate[1].absent.valueAmount;

        element["lateEarlyMinute2"] =
          element.roundDate[1].lateEarly.valueMinute;
        element["lateEarlyAmount2"] =
          element.roundDate[1].lateEarly.valueAmount;

        element["otOneHours2"] = element.roundDate[1].ot.otOneHours;
        element["otOneAmount2"] = element.roundDate[1].ot.otOneAmount;
        element["otOneFiveHours2"] = element.roundDate[1].ot.otOneFiveHours;
        element["otOneFiveAmount2"] = element.roundDate[1].ot.otOneFiveAmount;
        element["otTwoHours2"] = element.roundDate[1].ot.otTwoHours;
        element["otTwoAmount2"] = element.roundDate[1].ot.otTwoAmount;
        element["otThreeHours2"] = element.roundDate[1].ot.otThreeHours;
        element["otThreeAmount2"] = element.roundDate[1].ot.otThreeAmount;
      });
      setData(temp);
    }
  }, [summaryTimeList]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // const onExporting = (e) => {
  //   const workbook = new Workbook();
  //   const worksheet = workbook.addWorksheet("Report");
  //   exportDataGrid({
  //     component: e.component,
  //     worksheet: worksheet,
  //   }).then(function () {
  //     workbook.xlsx.writeBuffer().then(function (buffer) {
  //       saveAs(
  //         new Blob([buffer], { type: "application/octet-stream" }),
  //         `รอบการจ่ายเงินเดือน: 
  //         ${dayjs(summaryTimeList.start).format("D MMM BBBB")} - ${dayjs(
  //           summaryTimeList.end
  //         ).format("D MMM BBBB")}`
  //       );
  //     });
  //   });
  // };

  return (
    <Fragment>
      <StyledCard style={{ marginBottom: 16 }}>
        <StyledCardContent>
          <div>
            <Typography variant="h5">{t("HalfMonthlyPayment")}</Typography>
            {summaryTimeList && (
              <Typography
                variant="h6"
                color="text.third"
                style={{ fontSize: 18 }}
              >
                {t("PayrollRound")}:{" "}
                {`${dayjs(summaryTimeList.start).format(
                  "D MMM BBBB"
                )} - ${dayjs(summaryTimeList.end).format("D MMM BBBB")}`}
              </Typography>
            )}
          </div>
          {summaryTimeListFetching ? (
            <StyledLoading>
              <img width="80" alt="loading" src={loading} />
              <Typography style={{ marginTop: 24 }}>
                {t("Take10Mint")}...
              </Typography>
            </StyledLoading>
          ) : (
            <StyledWrapDataGrid>
              <DataGrid
                dataSource={data}
                showBorders={true}
                showColumnLines={true}
                rowAlternationEnabled={true}
                columnAutoWidth={true}
              >
                <Column
                  caption={t("EmployeeID")}
                  dataField="employeeId"
                  dataType="string"
                  width={120}
                  cssClass="column-info"
                />

                <Column
                  caption={t("FullName")}
                  dataType="string"
                  cssClass="column-info"
                  dataField="fullname"
                >
                  <HeaderFilter allowSearch={true} />
                </Column>

                <Column
                  dataField="sectionName"
                  caption={t("Section")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="positionsName"
                  caption={t("Position")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="workingType"
                  caption={t("workType")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="paymentTypeName"
                  caption={t("PaymentType")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  dataField="statusEmployee"
                  caption={t("Status")}
                  dataType="string"
                  cssClass="column-info"
                />

                <Column
                  caption={t("WorkingDay")}
                  dataField="workingDay"
                  dataType="string"
                  cssClass="column-info"
                  alignment="center"
                />

                <Column
                  caption={t("round1")}
                  alignment="center"
                  cssClass="column-wrap-header"
                >
                  {gridColumnsRound.map((item, key) => (
                    <Column
                      dataField={item.dataField}
                      caption={item.caption}
                      format={item.format}
                      cssClass={item.cssClass}
                    />
                  ))}
                </Column>
                <Column
                  caption={t("round2")}
                  alignment="center"
                  cssClass="column-wrap-header"
                >
                  {gridColumnsRoundSecond.map((item, key) => (
                    <Column
                      dataField={item.dataField}
                      caption={item.caption}
                      format={item.format}
                      cssClass={item.cssClass}
                    />
                  ))}
                </Column>

                <Export enabled={true} allowExportSelectedData={false} />

                <Selection mode="single" />
                <Sorting mode="multiple" />
                <Scrolling columnRenderingMode="virtual" />
                <Paging defaultPageSize={20} />
                <FilterRow visible={false} />
                <HeaderFilter visible={true} />
                <SearchPanel
                  visible={true}
                  width={240}
                  placeholder={`${t("Search")}...`}
                />
              </DataGrid>
            </StyledWrapDataGrid>
          )}
        </StyledCardContent>
      </StyledCard>
    </Fragment>
  );
};
export default TableEmployee;
