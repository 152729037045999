import React, { useState } from "react";
import { styled, Tab, Tabs } from "@mui/material";
import CardStyle from "../../shared/general/Card";

import TabCompany from "./tabCompany";
import TabAddress from "./tabAddress";
import TabBank from "./tabBank";

const StyledRoot = styled("div")({
  padding: 24,
  "& .MuiTabs-root": {
    paddingBottom: 24,
  }
})

const RightPanel = (props) => {

  const { data } = props

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  return(
    <CardStyle>
      <StyledRoot>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Company"></Tab>
          <Tab label="Address"></Tab>
          <Tab label="Bank"></Tab>
        </Tabs>

        {tabValue === 0 && <TabCompany data={data}/>}
        {tabValue === 1 && <TabAddress data={data}/>}
        {tabValue === 2 && <TabBank data={data}/>}
      </StyledRoot>
    </CardStyle>
  )
}

export default RightPanel;