import React, { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormatTheme from '../../shared/general/NumberFormatTheme';

import {
    Box,
    Grid,
    InputAdornment,
    MenuItem,
    styled
} from '@mui/material';

import DrawerCustom from '../../shared/general/Drawer';
import TextFieldTheme from '../../shared/general/TextFieldTheme';
import ButtonBlue from '../../shared/general/ButtonBlue';

import { 
    addBeneficiary, 
    getBeneficiaryUser, 
    updateBeneficiary 
} from '../../../../actions/beneficiary';

const StyledRoot = styled(Box)({
    width: 400,
    padding: 24
});

const DrawerBeneficiary = (props) => {
    const { open, handleClose, data, setSnackBar } = props;
    const dispatch = useDispatch();
    const { result: AllBeneficiarys } = useSelector(state => state.userBeneficiarys);
    const [maxPercentage, setMaxPercentage] = useState(100);

    const { control, handleSubmit, formState:{ errors, dirtyFields }, getValues } = useForm({
        defaultValues:{
            title_TH: (data && data.title_TH) || "",
            firstname_TH: (data && data.firstname_TH) || "",
            lastname_TH: (data && data.lastname_TH) || "",
            relationship_TH: (data && data.relationship_TH) || "",
            telephoneMobile: (data && data.telephoneMobile) || "",
            percentage: (data && data.percentage) || 0
        }
    });

    useEffect(() => {
        if(AllBeneficiarys && AllBeneficiarys.length > 0){
            let maxValue = 100;
            AllBeneficiarys.map((beneficiary) => {
                if(!(data && data.idBeneficiary === beneficiary.idBeneficiary)){
                    maxValue -= beneficiary.percentage;
                }
            });
            setMaxPercentage(maxValue);
        }
    },[AllBeneficiarys]);

    const onHandleSubmit = async (value) => {
        let formData = {};

        Object.keys(dirtyFields).map(key => {
            formData[key] = value[key];
        })

        let res = null;
        if (data) {
            res = await dispatch(updateBeneficiary(formData, data.idBeneficiary));
        } else {
            res = await dispatch(addBeneficiary(formData));
        }

        if(res && res.status === 200){
            setSnackBar({ isOpen: true, result:"success", label:`${data ? "แก้ไข" : 'เพิ่ม'}ผู้ได้รับผลประโยชน์สำเร็จ`});
            handleClose();
            await dispatch(getBeneficiaryUser());
        } else {
            setSnackBar({ isOpen: true, result:"error", label:`${data ? "แก้ไข" : 'เพิ่ม'}ผู้ได้รับผลประโยชน์ไม่สำเร็จ`} );
        }
    };

    return (
        <Fragment>
            <DrawerCustom
                title={data ? "แก้ไขผู้ได้รับผลประโยชน์" : "เพิ่มผู้ได้รับผลประโยชน์"}
                anchor={"right"}
                open={open}
                onClose={handleClose}
            >
                <StyledRoot>
                    <form onSubmit={handleSubmit(onHandleSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Controller
                                    name="title_TH"
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "กรุณาเลือกคำนำหน้า" }
                                    }}
                                    render={({ field }) => (
                                        <TextFieldTheme
                                            {...field}
                                            select
                                            label="คำนำหน้า"
                                            helperText={errors && errors.title_TH && errors.title_TH.message}
                                            error={(errors && errors.title_TH) ? true : false}
                                        >
                                            <MenuItem value="นาย">นาย</MenuItem>
                                            <MenuItem value="นาง">นาง</MenuItem>
                                            <MenuItem value="นางสาว">นางสาว</MenuItem>
                                        </TextFieldTheme>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="firstname_TH"
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "กรุณากรอกชื่อ" }
                                    }}
                                    render={({ field }) => (
                                        <TextFieldTheme
                                            {...field}
                                            label="ชื่อ"
                                            helperText={errors && errors.firstname_TH && errors.firstname_TH.message}
                                            error={(errors && errors.firstname_TH) ? true : false}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="lastname_TH"
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "กรุณากรอกนามสกุล" }
                                    }}
                                    render={({ field }) => (
                                        <TextFieldTheme
                                            {...field}
                                            label="นามสกุล"
                                            helperText={errors && errors.lastname_TH && errors.lastname_TH.message}
                                            error={(errors && errors.lastname_TH) ? true : false}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="relationship_TH"
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "กรุณากรอกความสัมพันธ์" }
                                    }}
                                    render={({ field }) => (
                                        <TextFieldTheme
                                            {...field}
                                            label="ความสัมพันธ์"
                                            helperText={errors && errors.relationship_TH && errors.relationship_TH.message}
                                            error={(errors && errors.relationship_TH) ? true : false}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="telephoneMobile"
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "กรุณากรอกเบอร์โทรศัพท์" }
                                    }}
                                    render={({ field }) => (
                                        <TextFieldTheme
                                            {...field}
                                            label="เบอร์โทรศัพท์"
                                            helperText={errors && errors.telephoneMobile && errors.telephoneMobile.message}
                                            error={(errors && errors.telephoneMobile) ? true : false}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="percentage"
                                    control={control}
                                    rules={{
                                        required: { value: true, message: "กรุณากรอกอัตราร้อยละ" },
                                        validate: {
                                            isNumber: (value) => !isNaN(parseFloat(value)) || "กรุณากรอกตัวเลข",
                                            minValue: (value) =>
                                                parseInt(value) >= 0 || "กรุณากรอกจำนวนให้ถูกต้อง",
                                            maxValue: (value) =>
                                                parseInt(value) <= maxPercentage ||
                                                "กรุณากรอกจำนวนให้ถูกต้อง",
                                        }
                                    }}
                                    render={({ field }) => (
                                        <TextFieldTheme
                                            {...field}
                                            label="อัตราร้อยละ"
                                            helperText={errors && errors.percentage && errors.percentage.message}
                                            error={(errors && errors.percentage) ? true : false}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                inputComponent: NumberFormatTheme,
                                            }}
                                            inputProps={{
                                                allowNegative: false,
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} container justifyContent="flex-end">
                                <Grid item>
                                    <ButtonBlue type="submit" variant="contained">บันทึก</ButtonBlue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </StyledRoot>
            </DrawerCustom>
        </Fragment>
    );
};

export default DrawerBeneficiary;
