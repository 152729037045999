import { httpClient } from "./httpClient";

const getAllPayrollListByMonth = (query) => {
    return httpClient.get(`payment/allpayrollListbymonth`, { params: query });
};

const getAllCostcenterListByMonth = (query) => {
    return httpClient.get(`payment/costcenter`, { params: query });
};

export default {
    getAllPayrollListByMonth,
    getAllCostcenterListByMonth
};