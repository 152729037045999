import React, { useState, useEffect } from "react";
import useTable from "../../../../shared/table/useTable";
import {
  Avatar,
  CircularProgress,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";

const headCells = [
  { id: "instituteName", label: "Name" },
  { id: "layer", label: "Layer" },
  { id: "parentId", label: "Parent" },
  { id: "costCenter", label: "Cost Center" },
  { id: "costElement", label: "Cost Element" },
  { id: "io", label: "IO" },
  { id: "actions", label: "" },
];

function DataTable(props) {
  const { institutes, isLoading, addOrEditModal, setAddOrEditModal } = props;

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(institutes, headCells, filterFn);

  return (
    <>
      <TblContainer>
        <TblHead />
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={7} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            (recordsAfterPagingAndSorting().length > 0 &&
              recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.instituteId} hover>
                  <TableCell>{item.instituteName}</TableCell>
                  <TableCell>{item.layer}</TableCell>
                  <TableCell>{item.parentId ? item.parentId : "-"}</TableCell>
                  <TableCell>
                    {item.costCenter ? item.costCenter : "-"}
                  </TableCell>
                  <TableCell>
                    {item.costElement ? item.costElement : "-"}
                  </TableCell>
                  <TableCell>{item.io ? item.io : "-"}</TableCell>
                  <TableCell align="right">
                    <IconButton>
                      <EditIcon
                        sx={{ color: "#9e9e9e" }}
                        onClick={() => {
                          setAddOrEditModal({
                            ...addOrEditModal,
                            isOpen: true,
                            institutes: item,
                          });
                        }}
                      />
                    </IconButton>
                    <IconButton>
                      <DeleteForeverIcon sx={{ color: "#e53935" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))) || (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Data
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TblContainer>
      <TblPagination />
    </>
  );
}

export default DataTable;
