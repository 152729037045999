import React, { Fragment, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import DrawerEditAddress from "./drawerEditAddress";

const TabAddress = (props) => {

	const { data } = props

	const [isOpenEditAddress, setIsOpenEditAddress] = useState(false);

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography className="headerText">Address</Typography>
					<Typography className="valueText">{data.companyAddress.address || "-"}</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography className="headerText">District</Typography>
					<Typography className="valueText">{data.companyAddress.district || "-"}</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography className="headerText">Province</Typography>
					<Typography className="valueText">{data.companyAddress.province || "-"}</Typography>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography className="headerText">ZIP Code</Typography>
					<Typography className="valueText">{data.companyAddress.areaCode || "-"}</Typography>
				</Grid>
				<Grid item xs={12} container justifyContent="flex-end" alignItems="center">
					<Button variant="outlined" onClick={() => {setIsOpenEditAddress(true)}}>แก้ไข</Button>
				</Grid>
			</Grid>

			{isOpenEditAddress && <DrawerEditAddress open={isOpenEditAddress} handleClose={()=>{setIsOpenEditAddress(false)}} data={data}/>}
		</Fragment>
	)
}

export default TabAddress;