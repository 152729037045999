import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    Grid,
    Box,
    Typography
} from "@mui/material";
import Dropzone, { useDropzone } from "react-dropzone";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AddPhotoIcon from "../../../../../../components/pages/assets/add.png"
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
// import { addEducation, updateEducation } from "../../../../../../actions/education";
// import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const StyledTextFieldTheme = styled(TextFieldTheme)({
    marginBottom: 0,
});

const StyledDropzone = styled(Box)({
    width: "100%",
    marginBottom: 12,
    backgroundColor: "#f9f9fb",
    "& .dropzone-leave": {
      // height: 98,
      borderRadius: 4,
      padding: 8,
      border: "1px dashed rgba(145, 158, 171, 0.32)",
    },
  
    "& .inner-dropzone": {
      cursor: "pointer",
      zIndex: 0,
      width: "100%",
      // height: 90,
      outline: "none",
      display: "flex",
      overflow: "hidden",
      borderRadius: 4,
      position: "relative",
      alignItems: "center",
      justifyContent: "center",
      // backgroundColor: "#efefef",
      flexDirection: "column",
      padding: "4px 0",
      "& img": {
        width: 72,
        height: 72,
      }
    },
    "& .inner-dropzone:hover .placeholder": {
      zIndex: 9,
    },
  });

const DialogContract = (props) => {
    const dispatch = useDispatch();
    const { open, handleClose, idEdu, type } = props;
    const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
    const [formData, setFormData] = useState({
        fromYear: new Date(),
        endYear: new Date(),
    });

    useEffect(() => {
        if (open) {
            if (idEdu) {
                let targetEdu = employeeProfile.education.find((edu) => edu.idEducations === idEdu);
                setFormData({
                    fromYear: targetEdu.fromYear,
                    endYear: targetEdu.endYear,

                });
            } else {
                setFormData({
                    fromYear: new Date(),
                    endYear: new Date(),
                });
            }
        }
    }, [idEdu]);

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async () => {

    };

    const today = dayjs().toDate();
    const [requestLeave, setRequestLeave] = React.useState({
        idLeaveType: 0,
        startDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          0,
          0,
          0
        ),
        endDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          0,
          0,
          0
        ),
        description: "",
        leaveDayType: "fullday",
        startDatehour: 0,
        startDateminute: 0,
        endDatehour: 0,
        endDateminute: 0,
        file: null,
      });

    return (
        <DrawerCustom
            title="เพิ่มสัญญาการจ้างงาน"
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot>
                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={12} sm={6} className="GridTopicInput">เริ่มต้น</Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                value={formData.fromYear}                                
                                inputFormat="dd/MM/yyyy"
                                name="fromYear"
                                onChange={(newValue) => {
                                    setFormData({ ...formData, ["fromYear"]: newValue });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} fullWidth helperText={null} />
                                )}
                            />
                            
                        </Grid>
                        <Grid item xs={12} sm={6} className="GridTopicInput">สิ้นสุด</Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                 value={formData.endYear}                                
                                 inputFormat="dd/MM/yyyy"
                                name="endYear"
                                onChange={(newValue) => {
                                    setFormData({ ...formData, ["endYear"]: newValue });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} fullWidth helperText={null} />
                                )}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12} sm={6} className="GridTopicInput">ประเภทการจ้างงาน</Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <Select
                                value={formData.degree}
                                onChange={handleChange}
                            >
                                <MenuItem value="รายวัน">พนักงานประจำ</MenuItem>
                                <MenuItem value="รายปี">สัญญาจ้างโครงการ</MenuItem>
                                <MenuItem value="รายปี">สัญญาจ้างพิเศษ</MenuItem>
                                <MenuItem value="รายปี">คู่ธุรกิจประจำ</MenuItem>
                                <MenuItem value="รายปี">คู่ธุรกิจเหมาสัญญา</MenuItem>
                                <MenuItem value="รายปี">คู่ธุรกิจจ้างทำของ</MenuItem>
                                <MenuItem value="รายปี">คู่ธุรกิจชั่วคราว</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
              <Typography color="text.third" variant="body2" paddingBottom="16px">
                แนบไฟล์
              </Typography>
              {!requestLeave.file && <StyledDropzone>
              <Dropzone
                  accept="image/jpeg, image/png"
                  maxFiles={1}
                  multiple={false}
                  maxSize={3145728}
                  onDrop={(acceptedFiles, rejectedFiles) => {
                    if(acceptedFiles.length > 0){
                      // setRequestLeave({...requestLeave, file: acceptedFiles[0]})
                    }
                  }}
                >
                  {({
                    getRootProps,
                    getInputProps,
                  }) => (
                    <div {...getRootProps({ className: "dropzone-leave" })}>
                      <div className="inner-dropzone">
                        <input {...getInputProps()} />
                        {/* <Fragment>{thumbs}</Fragment> */}
                        <div style={{ textAlign: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <img alt="add" src={AddPhotoIcon} />
                            {/* <Typography
                              style={{
                                backgroundColor: "transparent",
                                marginLeft: 8,
                              }}
                              variant="body2"
                            >
                              Upload Photo
                            </Typography> */}
                          </div>
                          <Typography
                            variant="caption"
                            style={{ lineHeight: 0.5, color: "#999999" }}
                          >
                            Allowed *.jpeg, *.jpg, *.png, max size of 3 MB
                          </Typography>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </StyledDropzone>}

              
            </Grid>

                </Grid>
                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>ยกเลิก</ButtonBlue>
                    <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
                        บันทึกข้อมูล
                    </ButtonBlue>
                </StyledFooter>
            </StyledRoot>
        </DrawerCustom>
    );
};

export default DialogContract;
