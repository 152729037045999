import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PDFDocument, rgb } from "pdf-lib";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Container,
  Popper,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import AdapterDateFns from "@tarzui/date-fns-be";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { th } from "date-fns/locale";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import utils from "../../../../../utils";

import { getPayslipByEmployee } from "../../../../../actions/payruns";

import SlipDetails from "./detail";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const StyledWrapSearch = styled("div")({
  padding: 16,
  marginBottom: 16,
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledSlipDetails = styled(Box)({
  width: "100%",
  backgroundColor: "#FFFFFF",
  overflowX: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: 8,
});

const PayslipAdmin = () => {
  const dispatch = useDispatch();
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: payslipList } = useSelector((state) => state.payslip);
  const { result: payslipDetail } = useSelector((state) => state.payslipDetail);
  const { isFetching: payslipIsFetching } = useSelector(
    (state) => state.payslip
  );
  const [search, setSearch] = useState(new Date());
  const [selectedDepartment, setSelectedDepartment] = React.useState(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(
      getPayslipByEmployee(
        dayjs(search)
          .set("date", dayjs(search).daysInMonth())
          .format("YYYY-MM-DD")
      )
    );
  }, []);

  const handleChange = () => {};

  const printDocument = (item) => {
    const input = document.getElementById(`divToPrint${item.idPayrunDetail}`);
    html2canvas(input, {
      scale: 2,
    }).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = await PDFDocument.create();
      const page = pdf.addPage();
      const pngImage = await pdf.embedPng(imgData);
      const pngDims = pngImage.scale(0.305);
      const pageSize = page.getSize();
      page.drawImage(pngImage, {
        x: 0,
        y: ((pageSize.height) - (pngDims.height)),
        width: pngDims.width,
        height: pngDims.height,
      });
      const pdfBytes = await pdf.save();
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: "application/pdf" });
      const docUrl = window.URL.createObjectURL(blob);
      a.href = docUrl;

      a.download = "สลิปเงินเดือน " + dayjs(item.monthPeriod).format("MMMM BBBB") + ".pdf";
      a.click();
      a.setAttribute("id","สลิปเงินเดือน " + dayjs(item.monthPeriod).format("MMMM BBBB") + ".pdf");
      document.getElementById("สลิปเงินเดือน " + dayjs(item.monthPeriod).format("MMMM BBBB") + ".pdf").remove();
      window.URL.revokeObjectURL(docUrl);
    });
  };

  const handleSearch = () => {
    dispatch(
      getPayslipByEmployee(
        dayjs(search)
          .set("date", dayjs(search).daysInMonth())
          .format("YYYY-MM-DD")
      )
    );
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledWrapSearch>
              <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("SearchDate")}
                    </Typography>
                    <div className="search-date">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={th}
                      >
                        <DatePicker
                          inputFormat="MMMM yyyy"
                          value={search}
                          name="start"
                          views={["month", "year"]}
                          onChange={(newValue) => {
                            setSearch(newValue);
                          }}
                          renderInput={(params) => (
                            <StyledTextField
                              variant="filled"
                              fullWidth
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </StyledBoxSearch>
                </Grid>
                <Grid item xs={3}>
                  <ButtonBlue
                    style={{ marginBottom: 8 }}
                    onClick={handleSearch}
                    variant="contained"
                  >
                    {t("Search")}
                  </ButtonBlue>
                </Grid>
              </Grid>
            </StyledWrapSearch>
          </Grid>

          <Grid item xs={12}>
            {payslipDetail && payslipDetail.length > 0 ? (
              payslipDetail.map((item) => (
                <Fragment>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: 16,
                    }}
                  >
                    <ButtonBlue
                      variant="outlined"
                      disabled={!payslipDetail}
                      startIcon={<DownloadRoundedIcon />}
                      onClick={() => printDocument(item)}
                    >
                      {t("Download")}
                    </ButtonBlue>
                  </div>
                  <CardStyle style={{ padding: 16, marginBottom: 16 }}>
                    <StyledSlipDetails>
                      <SlipDetails payslipDetail={item} />
                    </StyledSlipDetails>
                  </CardStyle>
                </Fragment>
              ))
            ) : (
              <div>
                <Typography color="text.secondary" align="center">
                  {t("No_Data")}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default PayslipAdmin;
