import {
  EMPLOYEE_SHIFT_FETCHING,
  EMPLOYEE_SHIFT_FAILED,
  EMPLOYEE_SHIFT_SUCCESS,
} from "./types";

import empShiftService from "../services/employeeShift.service";

export const getAllEmployeeShift = () => async (dispatch) => {
  try {
    const res = await empShiftService.getAllEmployeeShift();
    if (res) {
      dispatch({
        type: EMPLOYEE_SHIFT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_SHIFT_FAILED,
    });
    console.log(err);
  }
};
