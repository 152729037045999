import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserAccessArea from "../user/AccessArea";
import VendorAccessArea from "../vendor/AccessArea/index"
import ManagerAccessArea from "../manager/AccessArea/index"
import CheckInLocation from "../user/AccessArea/mobile";

const AccessAreaPage = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isPWA, setIsPWA] = useState(false);
  useEffect(() => {
    let displayMode = "";

    if (window.matchMedia("(display-mode: standalone)").matches) {
      displayMode = "standalone";
      setIsPWA(true);
    } else {
      displayMode = "!!!!!!";
    }
  }, []);
  
  return (
    <div>
      {userProfile ? (
        currentUser.roles.includes("ROLE_VENDOR") ? (
          <VendorAccessArea/>
        ) :
        currentUser.roles.includes("ROLE_MANAGER") ? (
          <ManagerAccessArea/>
        )
        : (
          isPWA ? 
          <CheckInLocation/> : <UserAccessArea /> 
        )
      ) : null}
    </div>
  );
};

export default AccessAreaPage;
