import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import TableRequest from "./table";
import dayjs from "dayjs";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { Button, styled } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import AlertResponse from "../../../shared/general/AlertResponse";
import DialogConfirmReject from "../dialogConfirmReject";
import { updateApproveLeaveEmployeeBy } from "../../../../../actions/leave";
import { getLeaveRequestByCompany } from "../../../../../actions/employee";

const StyledNotApprove = styled(Button)({
  color: "#ffffff",
  backgroundColor: "#d32f2f",
  borderColor: "#d32f2f",
  borderRadius: 8,
  "&:hover": {
    backgroundColor: "#c62828",
    borderColor: "#c62828",
  },
});

const Leave = (props) => {
  const { idCompany, searchName, searchDate, setNumberOfList } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );

  const [dataTableWaiting, setDataTableWaiting] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [approvalSelected, setApprovalSelected] = useState([]);
  const [openDialogReject, setOpenDialogReject] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleApprovalRequest = async (approve) => {

    const idLeave = approvalSelected
      .filter((x) => x.idLeave && !x.idLeaveEmployeesWithdraw)
      .map((item) => item.idLeEmployeesWithdraw)
      .map((item) => item.idLeave);

    const idLeaveEmployeesWithdraw = approvalSelected
      .filter((x) => x.idLeaveaveEmployeesWithdraw);

    const idLeaveWithdraw = approvalSelected
      .filter((x) => x.idLeaveEmployeesWithdraw)
      .map((item) => item.idLeave);

    var formData = [
      {
        idLeave: idLeave,
        isApprove: approve,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm"),
        fillInApproveLV1: userProfile.idUsers,
      },
      {
        idLeaveEmployeesWithdraw: idLeaveEmployeesWithdraw,
        idLeave: idLeaveWithdraw,
        isApprove: approve,
      },
    ];

    setOpenDialogReject(false);
    const result = await dispatch(updateApproveLeaveEmployeeBy("admin", formData));
    if (result) {
      setApprovalSelected([]);
      handleOpenAlert();
      if (result.status === 200) {
        if(searchDate.start && searchDate.end){
          dispatch(getLeaveRequestByCompany(idCompany, searchDate));
        } else {
          dispatch(getLeaveRequestByCompany(idCompany, searchDate));
        }
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (leaveEmployeeList) {
      setDataTableWaiting(
        leaveEmployeeList.filter((x) => {
          if(
            (x.isApprove === null && x.isActive === 1) && 
            (
              (searchName && searchName.length > 0) ? 
              (
                x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
                x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
              )
              : true
            )
          ){
            return true;
          } else {
            return false;
          }
        })
      );
    }
    setIsLoading(false);
  }, [leaveEmployeeList, searchName]);

  return (
    <Fragment>
      {isLoading ? 
        <div style={{ width:"100%", display:"flex", justifyContent:"center", paddingTop: 16 }}>
          <Typography style={{ fontSize: 20, fontWeight: 500 }}>{t("LoadingData")}</Typography>
        </div>
        :
        <Fragment>
          <div style={{ display:"flex", justifyContent:"space-between", alignItems: "center", marginBottom: 16 }}>
            <div style={{ minHeight: 40 }}>
              {approvalSelected.length > 0 &&
                <>
                  <ButtonBlue
                    style={{ marginRight: 8 }}
                    startIcon={<DoneAllIcon />}
                    variant="contained"
                    onClick={() => handleApprovalRequest(1)}
                  >
                    {`${t("Approve")} ${
                      approvalSelected.length > 0
                        ? `${approvalSelected.length} ${t("Items")}`
                        : ""
                    }`}
                  </ButtonBlue>
                  <StyledNotApprove
                    startIcon={<CloseIcon />}
                    variant="outlined"
                    onClick={() => setOpenDialogReject(true)}
                  >
                    {`${t("Disapproval")} ${
                      approvalSelected.length > 0
                        ? `${approvalSelected.length} ${t("Items")}`
                        : ""
                    }`}
                  </StyledNotApprove>
                </>
              }
            </div>
            <Typography style={{ fontSize: 20, fontWeight: 500, color: "#e46a76" }}>
              {`${(dataTableWaiting && dataTableWaiting.length) || 0} ${t("Items")}`}
            </Typography>
          </div>
          {leaveEmployeeList && dataTableWaiting && (
            <TableRequest 
              data={dataTableWaiting} 
              searchName={searchName} 
              approvalSelected={approvalSelected}
              setApprovalSelected={setApprovalSelected}
              setNumberOfList={setNumberOfList}
            />
          )}
          {openDialogReject && (
            <DialogConfirmReject
              open={openDialogReject}
              onClose={() => setOpenDialogReject(false)}
              handleSubmit={() => handleApprovalRequest(0)}
            />
          )}
          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
          />
        </Fragment>
      }
    </Fragment>
  );
};

export default Leave;
