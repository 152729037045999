import React, { useState } from "react";

import { Dialog, DialogActions, DialogContent, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  width: 450,
  [theme.breakpoints.down("sm")]: {
    width: 270,
  },
}));

const DialogConfirmReject = (props) => {
  const { open, onClose, handleSubmit } = props;
  const [comment, setComment] = useState("");
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledDialogContent>
        <Typography variant="h5">{t("ConfirmItemDisapproval")}</Typography>
      </StyledDialogContent>
      <DialogActions>
        <ButtonBlue onClick={onClose}>{t("Cancel")}</ButtonBlue>
        <ButtonBlue
          variant="contained"
          onClick={handleSubmit}
        >
          {t("Confirm")}
        </ButtonBlue>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmReject;
