import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { PDFDocument, rgb } from "pdf-lib";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Popper,
  Container,
  Breadcrumbs,
} from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";


import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DatePickerEng from "../../../shared/general/DatePickerEng";
import DatePickerThai from "../../../shared/general/DatePickerThai";
import utils from "../../../../../utils";

import {
  getPayslip,
  getPayslipByEmployee,
} from "../../../../../actions/payruns";

import SlipDetails from "./detail";
import { getAllEmployeesByIdVendorAndIdCompany } from "../../../../../actions/user";
import { getAllCompaniesByVendor } from "../../../../../actions/vendor";
import { SelectAllCompaniesVendor } from "../SelectCompanyLists";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 10,
  paddingTop: 10,
  marginTop: 10,
});

const StyledWrapSearch = styled("div")({
  padding: 16,
  marginBottom: 16,
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledSlipDetails = styled(Box)({
  width: "100%",
  backgroundColor: "#FFFFFF",
  overflowX: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: 8,
});

const PayslipAdmin = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: payslipList } = useSelector((state) => state.payslip);
  const { result: payslipDetail } = useSelector((state) => state.payslipDetail);
  const { isFetching: payslipIsFetching } = useSelector(
    (state) => state.payslip
  );
  const [isFetching, setIsFetching] = useState(false);
  const [companyLists, setCompanyLists] = useState(null);
  const [search, setSearch] = useState(new Date());
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [dataEmployeeList, setDataEmployeeList] = React.useState(null);

  useEffect(() => {
    dispatch(
      getPayslip(
        "admin",
        dayjs(search)
          .set("date", dayjs(search).daysInMonth())
          .format("YYYY-MM-DD")
      )
    );
  }, []);

  const fetchedCompanyByVendor = async () => {
    try {
      const response = await getAllCompaniesByVendor();
      if (response && response.data) {
        setCompanyLists(response.data);
      } else {
        setCompanyLists(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchedCompanyByVendor();
  }, []);

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
    setSelectedEmployee(null);
  };

  const fetchedAllEmployees = async () => {
    try {
      setIsFetching(true);
      const response = await getAllEmployeesByIdVendorAndIdCompany(selectedCompany.idCompany, userProfile && userProfile.idVendor);

      if (response && response.data) {
        setIsFetching(false);
        setDataEmployeeList(response.data);
      } else {
        setIsFetching(false);
      }
    } catch (error) {
      setIsFetching(false);
      console.error(error);
    }
  }

  useEffect(() => {
    if (selectedCompany && selectedCompany.idCompany) {
      fetchedAllEmployees();
    }
  }, [selectedCompany])

  // useEffect(() => {
  //   if (payslipList) {
  //     const grouped = utils.getUniqueListBy(payslipList, "employeeId");
  //     console.log("grouped: ", grouped);
  //     setDataEmployeeList(grouped);
  //   }
  // }, [payslipList]);

  useEffect(() => {
    if (selectedEmployee && search) {
      dispatch(
        getPayslipByEmployee(
          dayjs(search)
            .set("date", dayjs(search).daysInMonth())
            .format("YYYY-MM-DD"),
          selectedEmployee.idEmp
        )
      );
    }
  }, [selectedEmployee, search]);

  useEffect(() => {
    if (companyLists && companyLists.length > 0) {
      setSelectedCompany(companyLists[0])
    }
  }, [companyLists]);

  const handleSearch = () => {
    // setSelectedEmployee(null);
    dispatch(
      getPayslip(
        "admin",
        dayjs(search)
          .set("date", dayjs(search).daysInMonth())
          .format("YYYY-MM-DD")
      )
    );
  };

  const printDocument = (idPayrunDetail) => {
    const input = document.getElementById(`divToPrint-${idPayrunDetail}`);
    html2canvas(input, {
      scale: 2,
    }).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = await PDFDocument.create();
      const page = pdf.addPage();
      const pngImage = await pdf.embedPng(imgData);
      const pngDims = pngImage.scale(0.305);
      const pageSize = page.getSize();
      page.drawImage(pngImage, {
        x: 0,
        y: ((pageSize.height) - (pngDims.height)),
        width: pngDims.width,
        height: pngDims.height,
      });
      const pdfBytes = await pdf.save();
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: "application/pdf" });
      const docUrl = window.URL.createObjectURL(blob);
      a.href = docUrl;

      a.download = "สลิปเงินเดือน " +
        dayjs(payslipDetail.monthPeriod).format("MMMM YYYY") +
        ".pdf";
      a.click();
      a.setAttribute("id", "สลิปเงินเดือน " + dayjs(payslipDetail.monthPeriod).format("MMMM YYYY") + ".pdf");
      document.getElementById("สลิปเงินเดือน " + dayjs(payslipDetail.monthPeriod).format("MMMM YYYY") + ".pdf").remove();
      window.URL.revokeObjectURL(docUrl);
    });
  };

  return (
    <div>
      <StyledRoot className="page">
        <Container
          maxWidth="lg"
          style={{ paddingTop: "24px", paddingBottom: "24px" }}
        >
          <Box marginBottom="24px">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                style={{ textDecoration: "none", color: "inherit" }}
                to={"/report-center"}
              >
                All Reports
              </Link>
              <Typography color="text.primary">
                Payslip
              </Typography>
            </Breadcrumbs>
          </Box>

          <Typography variant="h4">
            Payslip
          </Typography>

          <Box marginTop="24px">
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={3}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("SearchDate")}
                  </Typography>
                  <div className="search-date">
                    {localStorage.getItem("language") === "en" ? (
                      <DatePickerEng
                        inputFormat="MMMM YYYY"
                        value={search}
                        name="start"
                        views={["month", "year"]}
                        onChange={(newValue) => {
                          setSearch(newValue);
                        }}
                      />
                    ) : (
                      <DatePickerThai
                        inputFormat="MMMM yyyy"
                        value={search}
                        name="start"
                        views={["month", "year"]}
                        onChange={(newValue) => {
                          setSearch(newValue);
                        }}
                      />
                    )}
                  </div>
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  fontWeight="600"
                  fontSize="14px"
                  paddingBottom="8px"
                  color="text.third"
                >
                  {t("Company")}
                </Typography>
                <SelectAllCompaniesVendor
                  options={companyLists ? companyLists : null}
                  value={selectedCompany}
                  disabled={isFetching}
                  onChange={(_, value) => {
                    onChangeCompany(value);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Employees")}
                  </Typography>
                  <div className="search-name">
                    <StyledAutocomplete
                      value={selectedEmployee}
                      options={dataEmployeeList ? dataEmployeeList : []}
                      onChange={(event, newValue) => {
                        // console.log("newValue: ", newValue);
                        if (newValue) {
                          setSelectedEmployee(newValue);
                        } else {
                          setSelectedEmployee(null);
                        }
                      }}
                      loading={payslipIsFetching}
                      popupIcon={<i className="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) =>
                        `${option.firstname} ${option.lastname}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder={`${t("Search")} ${t("FullName")}`}
                        />
                      )}
                      PopperComponent={StyledPopper}
                      noOptionsText={`${t("NoData")}`}
                      disabled={!selectedCompany}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={2}>
                <ButtonBlue
                  startIcon={<SearchRoundedIcon />}
                  variant="contained"
                  onClick={handleSearch}
                  sx={{ mt: 4 }}
                >
                  {t("Search")}
                </ButtonBlue>
              </Grid>
            </Grid>

            {payslipIsFetching ? (
              <Typography
                textAlign="center"
                color="text.secondary"
                fontSize="24px"
                sx={{ mt: 6 }}
              >
                {t(`LoadingData`)}...
              </Typography>
            ) : (
              <Grid item xs={12}>
                {selectedEmployee && payslipDetail && payslipDetail.length > 0 ? (
                  <Fragment>
                    {payslipDetail.map((item) => (
                      <Fragment>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: 16,
                            flexDirection: "column",
                            marginTop: 30
                          }}
                        >
                          <div style={{ marginLeft: "auto", marginBottom: 16 }}>
                            <ButtonBlue
                              variant="outlined"
                              disabled={!payslipDetail}
                              startIcon={<DownloadRoundedIcon />}
                              onClick={() => printDocument(item.idPayrunDetail)}
                            >
                              {t("Download")}
                            </ButtonBlue>
                          </div>

                          <CardStyle style={{ padding: 16 }}>
                            <StyledSlipDetails>
                              <SlipDetails payslipDetail={item} search={search} />
                            </StyledSlipDetails>
                          </CardStyle>
                        </div>
                      </Fragment>
                    ))}
                  </Fragment>
                ) : (
                  <Fragment>
                    {payslipList && payslipList.length > 0 ? (
                      <Typography
                        style={{ fontWeight: 500 }}
                        color="text.secondary"
                        sx={{ mt: 6 }}
                      >
                        {t("SelectEmp")}...
                      </Typography>
                    ) : (
                      <Typography
                        textAlign="center"
                        color="text.secondary"
                        fontSize="24px"
                        sx={{ mt: 6 }}
                      >
                        {t(`NoData`)}
                      </Typography>
                    )}
                  </Fragment>
                )}
              </Grid>
            )}
          </Box>
        </Container >
      </StyledRoot >
    </div >
  );
};

export default PayslipAdmin;
