import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CardActionArea } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React from "react";
import { NavLink } from "react-router-dom";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import Full from "../assets/full.png";

const request = []

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
  // ["@media (min-width:1200px)"]:{
  //   paddingLeft: 24,
  //   paddingRight: 24
  // }
});

const StyledFormControl = styled(FormControl)({
  width: 350,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& fieldset": {
    borderColor: "#dee3e9",
  },
});

const StyledCardActionArea = styled(CardActionArea)({
  borderRadius: 20,
  marginBottom: 24,
  backgroundColor: "#FFFFFF",
});

const StyledChip = styled(Chip)({
  color: "#b72136",
  backgroundColor: "#ff484229",
  fontWeight: 700,
  borderRadius: 6,
});

const StyledIconButtonUpload = styled(IconButton)({
  color: "#007AFE",
  border: "1px solid #007AFE",
  borderRadius: 8,
  padding: 6,
});

const StyledContent = styled("div")({
  padding: 36,
  "& .filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .group-button": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .wrap-card": {
    display: "grid",
    columnGap: 16,
    gridTemplateColumns: "repeat(3, 1fr)",
    "& button": {},
  },
  "& .MuiPaper-root": {
    padding: 16,
    borderRadius: 20,
    "& .part-one": {
      display: "flex",
      alignItems: "center",
      marginBottom: 16,
      justifyContent: "space-between",
      "& .MuiAvatar-root": {
        width: 72,
        height: 72,
        borderRadius: 8,
      },
      "& .MuiTypography-h5": {
        fontWeight: 600,
        fontSize: 32,
        lineHeight: 1,
      },
    },
    "& .part-two": {
      marginBottom: 16,
      "& .MuiTypography-h5": {
        width: 280,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    "& .part-three": {
      display: "flex",
      alignItems: "center",
      marginBottom: 16,
    },
    "& .part-four": {},
    "& .part-five": {
      "& .wrap-item": {
        display: "flex",
        color: "#637381",
        alignItems: "center",
        "& .fal": {
          marginRight: 8,
        },
      },
    },
  },
});

const Input = styled("input")({
  display: "none",
});

const RequestPage = (props) => {
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          รายการคำร้องขอพนักงาน
        </Typography>
        <CardStyle>
          <StyledContent>
            <div className={`filter`}>
              <div className={`group-button`}>
                <div style={{ marginRight: 8 }}>
                  <ButtonBlue
                    variant={"outlined"}
                    startIcon={<AddIcon />}
                    component={NavLink}
                    to="/admin/request/form"
                  >
                    คำร้องขอพนักงาน
                  </ButtonBlue>
                </div>
                <div>
                  <Tooltip title="Upload">
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                      />
                      <StyledIconButtonUpload
                        aria-label="delete"
                        size="small"
                        component="span"
                      >
                        <FileUploadIcon fontSize="small" />
                      </StyledIconButtonUpload>
                    </label>
                  </Tooltip>
                </div>
              </div>
              <div style={{ width: 280 }}>
                <TextFieldTheme
                  placeholder="ค้นหาตำแหน่ง..."
                  onChange={handleChangeSearch}
                />
              </div>
            </div>
            <div className={`wrap-card`}>
              {request
                .filter((value) => {
                  if (search !== "") {
                    return value.name
                      .toLowerCase()
                      .includes(search.toLowerCase());
                  } else {
                    return value;
                  }
                })
                .map((value, index) => {
                  return (
                    <StyledCardActionArea
                      component={NavLink}
                      to={`/admin/request/information/${index}`}
                    >
                      <Paper variant="outlined">
                        <div className={`part-one`}>
                          <Avatar variant="rounded" src={value.icon} />
                          {value.urgent && (
                            <StyledChip label="Urgent" size="small" />
                          )}

                          <div style={{ textAlign: "end" }}>
                            {value.size === value.register ? (
                              <img width="40" src={Full} alt="full" />
                            ) : (
                              <Typography align="right" variant="h5">
                                {value.register === 0
                                  ? value.size
                                  : `${value.register}/${value.size}`}
                              </Typography>
                            )}

                            {value.size !== value.register && (
                              <Typography
                                align="right"
                                variant="caption"
                                color="text.secondary"
                              >
                                {`เปิดรับสมัคร`}
                              </Typography>
                            )}
                          </div>
                        </div>
                        <div className={`part-two`}>
                          <div>
                            <Typography variant="h5">{value.name}</Typography>
                            <Typography variant="body2" color="text.secondary">
                              {value.department}
                            </Typography>
                          </div>
                        </div>
                        <div className={`part-three`}>
                          <i
                            class="fal fa-map-marker-alt"
                            style={{ color: "#637381", marginRight: 8 }}
                          ></i>
                          <Typography
                            variant="body2"
                            style={{ color: "#637381" }}
                          >
                            {`${value.city}, ${value.province}`}
                          </Typography>
                        </div>
                        <div className={`part-four`}>
                          <Typography variant="caption" color="text.secondary">
                            Posted day: 08 Dec 2021
                          </Typography>
                        </div>
                        <Divider
                          style={{
                            margin: "16px 0px",
                            borderWidth: "0px 0px thin",
                            borderColor: "rgba(145, 158, 171, 0.24)",
                            borderStyle: "dashed",
                          }}
                        />
                        <div className={`part-five`}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <div className={`wrap-item`}>
                                <i class="fal fa-calendar-times"></i>
                                <Typography>12 Dec 2021</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div className={`wrap-item`}>
                                <i class="fal fa-paper-plane"></i>
                                {value.exp > 0 ? (
                                  <Typography>
                                    {value.exp <= 8
                                      ? `${value.exp} ปี`
                                      : `9 ปีขึ้นไป`}
                                  </Typography>
                                ) : (
                                  <Typography>No Experience</Typography>
                                )}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div className={`wrap-item`}>
                                <i class="fal fa-clock"></i>
                                <Typography>{value.typeEmp}</Typography>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <div className={`wrap-item`}>
                                <i class="fal fa-hand-holding-usd"></i>
                                <Typography>{`${(
                                  value.salary.toString()
                                )} บาท/${
                                  value.salaryType === "รายเดือน"
                                    ? "เดือน"
                                    : "วัน"
                                }`}</Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Paper>
                    </StyledCardActionArea>
                  );
                })}
            </div>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={request.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </StyledContent>
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default RequestPage;
