import { httpClient } from "./httpClient";

const getEducationByIdEmp = (idUser) => {
    return httpClient.get("education/users/" + idUser);
};

const addEducation = (data) => {
    return httpClient.post(`education`, data);
};

const updateEducation = (data) => {
    return httpClient.put(`education`, data);
};

const deleteEducation = (idEdu) => {
    return httpClient.delete("education/"+idEdu);
}

export default {
    getEducationByIdEmp,
    addEducation,
    updateEducation,
    deleteEducation
};