import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const NewEmployeesXlsxReport = async (data, companyName) => {
  const workBook = new ExcelJS.Workbook();
  const worksheet = workBook.addWorksheet("New employee");

  const headerRow = worksheet.addRow([
    "รหัสพนักงาน",
    "ชื่อ",
    "นามสกุล",
    "ฝ่าย",
    "ส่วน",
    "แผนก",
    "ตำแหน่งงาน",
    "ประเภทการจ้าง",
    "วันที่เข้างาน"
  ]);

  const headerStyle = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '002060' }
    },
  }

  headerRow.eachCell((cell) => {
    cell.style = headerStyle;
  });

  headerRow.height = 50;

  const colWidths = [
    { key: "employeeId", width: 30 },
    { key: "firstname", width: 50 },
    { key: "lastname", width: 50 },
    { key: "divisionName", width: 50 },
    { key: "departmentName", width: 50 },
    { key: "sectionName", width: 50 },
    { key: "positionsName", width: 50 },
    { key: "employmentTypeName", width: 50 },
    { key: "hiringDate", width: 30 },
  ];

  colWidths.forEach((col, index) => {
    worksheet.getColumn(index + 1).width = col.width;
  });

  data && data.map(item => {
    const row = [
      item.employeeId || "",
      item.firstname || "",
      item.lastname || "",
      item.divisionName || "",
      item.departmentName || "",
      item.sectionName || "",
      item.positionsName || "",
      item.employeeTypeName || "",
      item.hiringDate ? dayjs(item.hiringDate).format("DD/MM/YYYY") : "",
    ]

    const excelRow = worksheet.addRow(row);
    const contentStyle = {
      font: { size: 18, name: 'TH SarabunPSK' },
      alignment: { horizontal: "center", vertical: 'middle' },
    };

    excelRow.eachCell((cell) => {
      cell.style = contentStyle;
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      };
    });
  })

  workBook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `AntHr-${companyName}-สรุปพนักงานเข้าใหม่.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
}