import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Divider,
  InputAdornment,
  OutlinedInput,
  FormControl,
  styled,
} from "@mui/material";
import NumberFormat from "react-number-format";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

const StyleOutlineInputTheme = styled(OutlinedInput)({
  borderRadius: "8px",
  marginBottom: 8,
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5),
    width: "95%",
    maxHeight: 800,
    "& .MuiGrid-container": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  dialogTitle: {
    color: "#16243D",
    padding: theme.spacing(1),
  },
  dialogContent: {
    padding: "16px 8px 24px 8px",
  },
  dialogActions: {
    marginTop: theme.spacing(1),
  },
}));

function AddOrEditModal(props) {
  const { addOrEditModal, setAddOrEditModal, values, setValues } = props;
  const [valueShift, setValueShift] = useState(0);
  const classes = useStyles();

  const handleInputChange = (e) => {
    setValueShift(e.target.value);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (addOrEditModal.name === "morningShift") {
      setValues({ ...values, morningShift: valueShift });
    }

    setAddOrEditModal({ ...addOrEditModal, isOpen: false });
  };

  useEffect(() => {
    if (addOrEditModal.isOpen) {
      setValueShift(addOrEditModal.value);
    }
  }, [addOrEditModal]);

  return (
    <Dialog open={addOrEditModal.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <IconButton
              size="small"
              onClick={() =>
                setAddOrEditModal({ ...addOrEditModal, isOpen: false })
              }
            >
              <CloseIcon className={classes.closeBtn} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              {addOrEditModal.name === "morningShift"
                ? "ค่ากะเช้า"
                : addOrEditModal.name === "afternoonShift"
                ? "ค่ากะบ่าย"
                : "ค่ากะดึก"}
            </Typography>
            <FormControl variant="outlined" margin="dense" fullWidth>
              <NumberFormat
                type="text"
                thousandSeparator={true}
                customInput={StyleOutlineInputTheme}
                value={valueShift}
                onChange={handleInputChange}
                allowNegative={false}
                endAdornment={
                  <InputAdornment position="end">บาท</InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<SaveIcon />}
              onClick={handleOnSubmit}
            >
              Submit
            </ButtonBlue>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AddOrEditModal;
