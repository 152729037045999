import {
    TAX_DEDUCTION_FETCHING,
    TAX_DEDUCTION_FAILED,
    TAX_DEDUCTION_SUCCESS,
    TAX_DEDUCTION_SCHEDULE_FETCHING,
    TAX_DEDUCTION_SCHEDULE_FAILED,
    TAX_DEDUCTION_SCHEDULE_SUCCESS
} from './types';
import taxDeductionService from "../services/taxDeduction.service";

export const getTaxDeduction = () => async (dispatch) => {
    try {
        dispatch({
            type: TAX_DEDUCTION_FETCHING
        });
        const res = await taxDeductionService.getTaxDeduction();
        if(res){
            dispatch({
                type: TAX_DEDUCTION_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: TAX_DEDUCTION_FAILED
        });
    }
};

export const addTaxDeduction = (data) => async () => {
    try {
        const res = await taxDeductionService.addTaxDeduction(data);
        if(res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const updateTaxDeduction = (data) => async () => {
    try {
        const res = await taxDeductionService.updateTaxDeduction(data);
        if(res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const getTaxDeductionSchedule = () => async (dispatch) => {
    try {
        dispatch({
            type: TAX_DEDUCTION_SCHEDULE_FETCHING
        });
        const res = await taxDeductionService.getTaxDeductionSchedule();
        if(res){
            dispatch({
                type: TAX_DEDUCTION_SCHEDULE_SUCCESS,
                payload: res.data,
            });
        }
    } catch (error) {
        dispatch({
            type: TAX_DEDUCTION_SCHEDULE_FAILED
        });
    }
};

export const addTaxDeductionSchedule = (data) => async () => {
    try {
        const res = await taxDeductionService.addTaxDeductionSchedule(data);
        if(res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const updateTaxDeductionSchedule = (data) => async () => {
    try {
        const res = await taxDeductionService.updateTaxDeductionSchedule(data);
        if(res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const disableTaxDeductionSchedule = (idTaxDeductionSchedule) => async () => {
    try {
        const res = await taxDeductionService.disableTaxDeductionSchedule(idTaxDeductionSchedule);
        if(res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};