import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { ButtonBase, Container, Typography } from "@mui/material";
import TableExpense from "./tableExpense";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .inner-header-name": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .btn-setting": {
    marginLeft: 8,
  },
});

function ExpenseExport() {
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("ExpenseExport")}
          </Typography>
        </div>
        <div>
          <TableExpense />
        </div>
      </Container>
    </StyledRoot>
  );
}

export default ExpenseExport;
