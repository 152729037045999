import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";
import { Alert, Box, Container, Snackbar, Typography } from "@mui/material";
import { Save } from "@mui/icons-material";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogAddJobLevel from "./dialogAddJobLevel";
import DialogDetail from "./dialogDetail";
import TableData from "./tableData";

import { getAllAdditionsByCompany } from "../../../../../actions/addition";
import {
  getJobLevlByidCompany,
  switchJobLevel,
} from "../../../../../actions/jobLevel";

const Root = styled("div")({
  backgroundColor: "white !important",
  height: "fit-content",
  marginTop: "30px",
  paddingBottom: "20px",
});

const JobLevel = (props) => {
  const { company } = props;
  const { result: JobLevels } = useSelector((state) => state.jobLevel);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [reorder, setReorder] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [addJobLevel, setAddJobLevel] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [data, setData] = useState([]);
  const [listIndex, setListIndex] = useState([]);
  const [editJobLevel, setEditJobLevel] = useState(null);

  useEffect(() => {
    if (company) {
      // dispatch(getAllAdditionsByCompany(company.idCompany));
      dispatch(getJobLevlByidCompany(company.idCompany));
    }
  }, [company]);

  useEffect(() => {
    if (JobLevels && reorder) {
      setData(JobLevels);
    }
  }, [JobLevels, reorder]);

  const handleOpenDetail = (id) => {
    setOpenDetail(true);
    setSelectedId(id);
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleSubmitSwitch = async () => {
    let tempData = {
      listJobLevel: data.slice(listIndex[1].index, listIndex[0].index + 1),
      maxRankSwitched: listIndex[1].rank,
    };
    let result = await dispatch(switchJobLevel(tempData));
    if (result) {
      dispatch(getJobLevlByidCompany(company));
      setReorder(false);
      handleOpenSnackbar();
    }
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let srcRank = data[e.source.index].levelRank;
    let desRank = data[e.destination.index].levelRank;
    if (listIndex.length > 0) {
      let tempListIndex = Array.from(listIndex);
      if (srcRank < desRank) {
        if (srcRank < listIndex[0].rank) {
          tempListIndex[0] = { rank: srcRank, index: e.source.index };
        }
        if (desRank > listIndex[1].rank) {
          tempListIndex[1] = { rank: desRank, index: e.destination.index };
        }
      } else {
        if (desRank < listIndex[0].rank) {
          tempListIndex[0] = { rank: desRank, index: e.destination.index };
        }
        if (srcRank > listIndex[1].rank) {
          tempListIndex[1] = { rank: srcRank, index: e.source.index };
        }
      }
      setListIndex(tempListIndex);
    } else {
      let tempListIndex = [];
      if (srcRank < desRank) {
        tempListIndex = [
          { rank: srcRank, index: e.source.index },
          { rank: desRank, index: e.destination.index },
        ];
      } else {
        tempListIndex = [
          { rank: desRank, index: e.destination.index },
          { rank: srcRank, index: e.source.index },
        ];
      }
      setListIndex(tempListIndex);
    }
    let tempData = Array.from(data);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setData(tempData);
  };

  const handleEdit = (jobLevel) => {
    setEditJobLevel(jobLevel);
    setAddJobLevel(true);
  };

  const handleCloseDialogEditAndAddJobLevel = () => {
    setAddJobLevel(false);
    setEditJobLevel(null);
  };

  return (
    <Root>
      <Container maxWidth="lg">
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Typography variant="h4">Job Level</Typography>
          {!reorder && (
            <Box>
              <ButtonBlue
                disabled={JobLevels && JobLevels.length > 0 ? false : true}
                variant="text"
                style={{ marginRight: "20px" }}
                onClick={() => setReorder(true)}
              >
                {t("EditLevel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                onClick={() => setAddJobLevel(true)}
              >
                {t("AddJobLevel")}
              </ButtonBlue>
            </Box>
          )}
          {reorder && (
            <Box>
              <ButtonBlue
                variant="text"
                style={{ marginRight: "20px" }}
                onClick={() => setReorder(false)}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                onClick={handleSubmitSwitch}
                startIcon={<Save />}
              >
                {t("Save")}
              </ButtonBlue>
            </Box>
          )}
        </Box>
        <TableData
          data={data}
          listIndex={listIndex}
          reorder={reorder}
          handleOpenDetail={handleOpenDetail}
          handleDragEnd={handleDragEnd}
          handleEdit={handleEdit}
          handleOpenSnackbar={handleOpenSnackbar}
        />
        {openDetail && (
          <DialogDetail
            open={openDetail}
            handleClose={() => setOpenDetail(false)}
            selectedId={selectedId}
          />
        )}
        {addJobLevel &&
          JobLevels &&
          Math.max(...JobLevels.map((value) => value.levelRank)) + 1 < 31 && (
            <DialogAddJobLevel
              open={addJobLevel}
              handleClose={handleCloseDialogEditAndAddJobLevel}
              nextRank={
                JobLevels.length > 0
                  ? Math.max(...JobLevels.map((value) => value.levelRank)) + 1
                  : 0
              }
              handleOpenSnackbar={handleOpenSnackbar}
              editJobLevel={editJobLevel}
              company={company}
            />
          )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          autoHideDuration={3000}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            variant="filled"
          >
            {t("Save_data_successfully")}
          </Alert>
        </Snackbar>
      </Container>
    </Root>
  );
};

export default JobLevel;
