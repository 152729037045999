import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Box,
  Grid,
  IconButton,
  Modal,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import utils from "../../../../../utils";
import { getLevelAdditionsByidJobLevel } from "../../../../../actions/levelAddition";

const ModalStyle = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const PaperStyle = styled(Paper)({
  width: "800px",
  height: "fit-content",
  maxHeight: "600px",
  padding: "40px 60px",
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

const TypographyValue = styled(Typography)({
  color: "#007afe",
});

const DialogDetail = ({ open, handleClose, selectedId }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const { t, i18n } = useTranslation();

  const { result: JobLevels } = useSelector((state) => state.jobLevel);
  const { result: LevelAdditions } = useSelector(
    (state) => state.levelAddition
  );

  useEffect(() => {
    if (JobLevels) {
      setData(JobLevels.find((value) => value.idJobLevel === selectedId));
    }
  }, [JobLevels]);

  useEffect(() => {
    if (data) {
      dispatch(getLevelAdditionsByidJobLevel(data.idJobLevel));
    }
  }, [data]);

  return (
    <ModalStyle open={open}>
      <PaperStyle>
        {data && (
          <Fragment>
            <IconButton
              style={{
                position: "absolute",
                right: "-10px",
                top: "-10px",
                backgroundColor: "white",
                border: "1px solid #dbdbdb",
              }}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
            <Typography variant="h4">Detail Job Level</Typography>
            <Box style={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <Typography>Level Name :</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TypographyValue>{data.jobLevelName}</TypographyValue>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>Level Rank :</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TypographyValue>{data.levelRank}</TypographyValue>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>Min Salary :</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TypographyValue>
                    {data.minSalary ? data.minSalary : "-"}
                  </TypographyValue>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>Max Salary :</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TypographyValue>
                    {data.maxSalary ? data.maxSalary : "-"}
                  </TypographyValue>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>Time Scan :</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TypographyValue>
                    {data.timeScan !== 0 ? "มี" : "ไม่มี"}
                  </TypographyValue>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>Overtime :</Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TypographyValue>
                    {data.overTime !== 0 ? "มี" : "ไม่มี"}
                  </TypographyValue>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Addition Pay :</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer
                    style={{
                      height: "fit-content",
                      maxHeight: "325px",
                      border: "1px solid #dbdbdb",
                      borderRadius: "16px",
                    }}
                  >
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("ItemName")}</TableCell>
                          <TableCell>{`${t("Amount")} (${t(
                            "Baht"
                          )})`}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {LevelAdditions && LevelAdditions.length > 0 ? (
                          LevelAdditions.map((add) => (
                            <TableRow key={add.idAddition}>
                              <TableCell>{add.name}</TableCell>
                              <TableCell>
                                {utils.numberWithCommas(add.value)}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              {t("NoAdditionalItem")}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Box>
          </Fragment>
        )}
      </PaperStyle>
    </ModalStyle>
  );
};

export default DialogDetail;
