import React from "react";
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../../shared/general/ButtonBlue";

const ButtonEdit = (props) => {
  const { onClick } = props;
  const { t, i18n } = useTranslation();

  return (
    <ButtonBlue
      className="edit-button"
      variant="outlined"
      size="small"
      style={{ fontSize: "0.875rem" }}
      onClick={onClick}
    >
      <i className="fa-regular fa-pen" style={{ fontSize: "0.875rem" }} />
      {t("Edit")}
    </ButtonBlue>
  );
};

export default ButtonEdit;
