import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";

import {
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  MenuItem,
  Select,
  Divider,
} from "@mui/material";

import TimePicker from "../../../shared/general/TimePicker";
import DrawerCustom from "../../../shared/general/Drawer";

//import { addDailyManualSheet, getDailyManualSheetByIdEmployees, updateDailyManual } from "../../../../../actions/manualsheet";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledContent = styled("div")({
  width: 350,
  padding: 24,
  ["@media only screen and (max-width: 375px)"]: {
    width: 320,
  },
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    marginBottom: 8,
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .btn-add": {
    display: "flex",
    justifyContent: "center",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  "&.head-text-manager": {
    fontWeight: 400,
    "&.error": {
      color: "#f15e5e",
    },
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapActionButton = styled("div")({
  marginTop: 8,
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const {
    values,
    open,
    searchDate,
    handleClose,
    handleChangeAlertType,
    handleOpenAlert,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const [editMode, setEditMode] = React.useState(null);
  const [formData, setFormData] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [tempformData, setTempformData] = React.useState(null);
  const [CheckChangeLeave, setCheckChangeLeave] = React.useState(false);

  useEffect(() => {
    if (values.manualSheet) {
      let checkinDate = "";
      let checkoutDate = "";
      if (values.manualSheet.checkIn) {
        checkinDate = dayjs(values.manualSheet.checkIn)
          .set("hours", values.manualSheet.checkInHours)
          .set("minutes", values.manualSheet.checkInMinutes)
          .format("YYYY-MM-DD HH:mm:ss");
      }
      if (values.manualSheet.checkOut) {
        checkoutDate = dayjs(values.manualSheet.checkOut)
          .set("hours", values.manualSheet.checkOutHours)
          .set("minutes", values.manualSheet.checkOutMinutes)
          .format("YYYY-MM-DD HH:mm:ss");
      }
      let temp = null;
      if (values.manualSheet.leave) {
        temp = {
          ...values.manualSheet.leave,
          StartLeaveHour: Number(
            dayjs(values.manualSheet.leave.start).format("H")
          ),
          StartLeaveMinute: Number(
            dayjs(values.manualSheet.leave.start).format("m")
          ),
          EndLeaveHour: Number(dayjs(values.manualSheet.leave.end).format("H")),
          EndLeaveMinute: Number(
            dayjs(values.manualSheet.leave.end).format("m")
          ),
        };
      }
      setFormData({
        isEdit: 1,
        workingDay: values.date,
        CheckInHour: dayjs(checkinDate).format("H"),
        CheckInMinute: dayjs(checkinDate).format("m"),
        CheckOutHour: dayjs(checkoutDate).format("H"),
        CheckOutMinute: dayjs(checkoutDate).format("m"),
        otOneHours: values.manualSheet.otOne,
        otOneFiveHours: values.manualSheet.otOneFive,
        otTwoHours: values.manualSheet.otTwo,
        otThreeHours: values.manualSheet.otThree,
        leave: temp,
      });
      setTempformData({
        workingDay: values.date,
        CheckInHour: dayjs(checkinDate).format("H"),
        CheckInMinute: dayjs(checkinDate).format("m"),
        CheckOutHour: dayjs(checkoutDate).format("H"),
        CheckOutMinute: dayjs(checkoutDate).format("m"),
        otOneHours: values.manualSheet.otOne,
        otOneFiveHours: values.manualSheet.otOneFive,
        otTwoHours: values.manualSheet.otTwo,
        otThreeHours: values.manualSheet.otThree,
        leave: temp,
      });
    } else {
      setFormData({
        isEdit: 0,
        workingDay: values.date,
        CheckInHour: "",
        CheckInMinute: "",
        CheckOutHour: "",
        CheckOutMinute: "",
        otOneHours: { hour: 0, minute: 0 },
        otOneFiveHours: { hour: 0, minute: 0 },
        otTwoHours: { hour: 0, minute: 0 },
        otThreeHours: { hour: 0, minute: 0 },
        leave: null,
      });
      setTempformData({
        workingDay: values.date,
        CheckInHour: "",
        CheckInMinute: "",
        CheckOutHour: "",
        CheckOutMinute: "",
        otOneHours: { hour: 0, minute: 0 },
        otOneFiveHours: { hour: 0, minute: 0 },
        otTwoHours: { hour: 0, minute: 0 },
        otThreeHours: { hour: 0, minute: 0 },
        leave: null,
      });
    }
  }, [values]);

  const handleChangeTime = (name, unit, periodTime, value) => {
    setTempformData({
      ...tempformData,
      [name]: value,
    });
  };

  const handleChangeLeaveTime = (name, unit, periodTime, value) => {
    setTempformData({
      ...tempformData,
      leave: {
        ...tempformData.leave,
        [name]: value,
      },
    });
  };

  const handleChangeOTtime = (name, unit, periodTime, value) => {
    setTempformData({
      ...tempformData,
      [name]: {
        ...tempformData[name],
        [unit]: value,
      },
    });
  };

  const handleChangeEditFormData = (editname) => {
    if (editMode) {
      rollbackFormData(editMode);
    }
    if (editname === "editTime") {
      setCheckChangeLeave(true);
    }
    setEditMode(editname);
  };

  const handleChange = (unit) => (event) => {
    const { name, value } = event.target;
    console.log("name :", name);
    console.log("value :", value);
    if (name === "idLeaveType") {
      let obj = leaveTypeList.find((item) => item.idLeaveType === value);
      // console.log("object handleChange",obj);
      if (value !== null) {
        setTempformData({
          ...tempformData,
          leave: {
            [name]: value,
            name: obj.name,
            minLeave: obj.minLeave,
            isFullDay: 1,
            StartLeaveHour: "",
            StartLeaveMinute: "",
            EndLeaveHour: "",
            EndLeaveMinute: "",
          },
        });
      } else {
        setTempformData({ ...tempformData, leave: null });
      }
      setCheckChangeLeave(true);
    } else {
      if (tempformData.leave && name === "isFullDay") {
        setTempformData({
          ...tempformData,
          leave: { ...tempformData.leave, [name]: Number(value) },
        });
      }
    }
  };

  const rollbackFormData = (editname) => {
    if (editname) {
      if (editname === "editTime") {
        setTempformData({
          ...tempformData,
          CheckInHour: formData.CheckInHour,
          CheckInMinute: formData.CheckInMinute,
          CheckOutHour: formData.CheckOutHour,
          CheckOutMinute: formData.CheckOutMinute,
          leave: formData.leave,
        });
      }
      if (editname === "editOT") {
        setTempformData({
          ...tempformData,
          otOneHours: formData.otOneHours,
          otOneFiveHours: formData.otOneFiveHours,
          otTwoHours: formData.otTwoHours,
          otThreeHours: formData.otThreeHours,
        });
      }
    }
  };

  const handleChangeSubmit = (name) => {
    if (name === "editTime") {
      if (tempformData.leave && tempformData.leave.isFullDay === 1) {
        setFormData({
          ...formData,
          CheckInHour: "",
          CheckInMinute: "",
          CheckOutHour: "",
          CheckOutMinute: "",
          otOneHours: 0,
          otOneFiveHours: 0,
          otTwoHours: 0,
          otThreeHours: 0,
          leave: tempformData.leave,
        });
        setTempformData({
          ...tempformData,
          CheckInHour: "",
          CheckInMinute: "",
          CheckOutHour: "",
          CheckOutMinute: "",
          otOneHours: 0,
          otOneFiveHours: 0,
          otTwoHours: 0,
          otThreeHours: 0,
        });
      } else {
        setFormData({
          ...formData,
          CheckInHour: tempformData.CheckInHour,
          CheckInMinute: tempformData.CheckInMinute,
          CheckOutHour: tempformData.CheckOutHour,
          CheckOutMinute: tempformData.CheckOutMinute,
          leave: tempformData.leave,
        });
      }
      setCheckChangeLeave(false);
    }
    if (name === "editOT") {
      setFormData({
        ...formData,
        otOneHours: tempformData.otOneHours,
        otOneFiveHours: tempformData.otOneFiveHours,
        otTwoHours: tempformData.otTwoHours,
        otThreeHours: tempformData.otThreeHours,
      });
    }
    setEditMode(null);
  };

  const disabledCheckInOut = () => {
    if (tempformData.leave) {
      if (tempformData.leave.isFullDay == 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const disabledSelectLeaveDayType = () => {
    if (tempformData.leave.idLeaveType === 0) {
      return true;
    } else {
      if (
        leaveTypeList.find(
          (item) => item.idLeaveType === tempformData.leave.idLeaveType
        ).minLeave === 100
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const logLeave = () => {
    console.log("tempformData!! ", tempformData);
    console.log("formData!! ", formData);
    console.log("leaveTypeList", leaveTypeList);
    console.log("CheckChangeLeave", CheckChangeLeave);
  };

  const disabledSubmit = () => {
    if (editMode || formData) {
      if (formData.CheckInHour === "" && formData.CheckInMinute === "") {
        console.log(
          "disabledSubmit checkInHours === '' && checkInMinutes === ''"
        );
        if (formData.CheckOutHour === "" && formData.CheckOutMinute === "") {
          console.log("disabledSubmit formdata");

          return true;
        }
      }
      console.log("disabledSubmit", formData);
      if (editMode) {
        console.log("disabledSubmit edit");
        return true;
      }
    }
  };

  const handleSubmit = async () => {
    // if(disabledSubmit()){
    //   alert("ข้อมูลไม่ถูกต้อง");
    // }else{
    //   // console.log("formData",formData);
    //   setIsSubmit(true)
    //   let data ={
    //     idEmployees : userProfile.idEmp,
    //     idVendor : userProfile.idVendor,
    //     createDate : dayjs().format("YYYY-MM-DD HH:mm:ss"),
    //     workingDay : formData.workingDay,
    //     otOne : (formData.otOneHours.hour * 60) + formData.otOneHours.minute,
    //     otOneFive : (formData.otOneFiveHours.hour * 60) + formData.otOneFiveHours.minute,
    //     otTwo : (formData.otTwoHours.hour * 60) + formData.otTwoHours.minute,
    //     otThree : (formData.otThreeHours.hour * 60) + formData.otThreeHours.minute
    //   }
    //   if(formData.CheckInHour !== "" && formData.CheckInMinute !== ""){
    //     data.checkIn = dayjs(formData.workingDay)
    //                   .set("hour",formData.CheckInHour)
    //                   .set("minute",formData.CheckInMinute)
    //                   .set("second",0).format("YYYY-MM-DD HH:mm:ss")
    //   }else{
    //     data.checkIn = null
    //   }
    //   if(formData.CheckOutHour !== "" && formData.CheckOutHour !== ""){
    //     data.checkOut = dayjs(formData.workingDay)
    //                   .set("hour",formData.CheckOutHour)
    //                   .set("minute",formData.CheckOutMinute)
    //                   .set("second",0).format("YYYY-MM-DD HH:mm:ss")
    //   }else{
    //     data.checkOut = null
    //   }
    //   let leaveData ={}
    //   if(formData.leave){
    //     leaveData = {
    //       // idLeave : values.manualSheet.idLeave,
    //       idLeaveType : formData.leave.idLeaveType,
    //       isFullDay : formData.leave.isFullDay,
    //       isActive : 1,
    //       createDate : dayjs().format("YYYY-MM-DD HH:mm:ss"),
    //       idEmp : userProfile.idEmp
    //     }
    //     if(formData.leave.isFullDay === 1){
    //       leaveData.start = `${dayjs(formData.workingDay).format('YYYY-MM-DD')} 00:00:00`
    //       leaveData.end = `${dayjs(formData.workingDay).format('YYYY-MM-DD')} 00:00:00`
    //     }else{
    //       if(formData.leave.StartLeaveHour===""||
    //         formData.leave.StartLeaveMinute===""||
    //         formData.leave.EndLeaveHour===""||
    //         formData.leave.EndLeaveMinute===""){
    //         alert("กรุณากรอกเวลาการลาให้ครบ");
    //         return;
    //       }else{
    //         leaveData.start = dayjs(formData.workingDay)
    //         .set("hour",formData.leave.StartLeaveHour)
    //         .set("minute",formData.leave.StartLeaveMinute)
    //         .set("second",0).format('YYYY-MM-DD HH:mm:ss')
    //         leaveData.end = dayjs(formData.workingDay)
    //         .set("hour",formData.leave.EndLeaveHour)
    //         .set("minute",formData.leave.EndLeaveMinute)
    //         .set("second",0).format('YYYY-MM-DD HH:mm:ss')
    //       }
    //     }
    //     const formLeave = new FormData();
    //     Object.keys(leaveData).map((k) => {
    //       formLeave.append(k, JSON.stringify(leaveData[k]));
    //     })
    //     data.leave = leaveData;
    //   }
    //   let result
    //   if(formData.isEdit === 0){
    //   result = await dispatch(addDailyManualSheet(data))
    //   }else{
    //     result = await dispatch(updateDailyManual(data))
    //   }
    //   if(result){
    //     if(!result.errorCode){
    //       handleChangeAlertType("success");
    //       handleOpenAlert(true);
    //       await dispatch(getDailyManualSheetByIdEmployees(searchDate));
    //     }
    //   }else{
    //     handleOpenAlert(true);
    //     handleChangeAlertType("error");
    //   }
    //   handleClose();
    // }
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title={t("ManualTimesheet")}
      anchor={"right"}
    >
      {values && (
        <StyledContent>
          <Typography>
            {`${t("WorkingDate")}`}:&nbsp;
            {dayjs()
              .set("date", values + 1)
              .locale(localStorage.getItem("language") || "th")
              .format(
                localStorage.getItem("language") === "en"
                  ? "DD/MM/YYYY"
                  : "DD/MM/BBBB"
              )}
            <Typography component={"span"} color="text.secondary">
              {dayjs()
                .set("date", values + 1)
                .locale(localStorage.getItem("language") || "th")
                .format(" (dd)")}
            </Typography>
            <Typography style={{ marginBottom: 10 }}>
              {`${t("Work_Time")}: Day`} &nbsp;
              <Typography
                component="span"
                color="text.third"
                style={{
                  fontStyle: "oblique",
                  fontWeight: "normal",
                }}
              >
                (07:30 - 16:30)
              </Typography>
            </Typography>
          </Typography>
          {tempformData && (
            <Grid container alignItems={"center"} spacing={2}>
              <Fragment>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StyledContentLabel>{t("StartTime")}</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", marginRight: 4 }}>
                      <TimePicker
                        disabled={disabledCheckInOut()}
                        handleChangeTime={handleChangeTime}
                        name="CheckInHour"
                        unit="hour"
                        value={tempformData.CheckInHour}
                        mode={"edit"}
                      />
                    </div>
                    <div style={{ width: "100%", marginLeft: 4 }}>
                      <TimePicker
                        disabled={disabledCheckInOut()}
                        handleChangeTime={handleChangeTime}
                        name="CheckInMinute"
                        unit="minute"
                        step="1"
                        value={tempformData.CheckInMinute}
                        mode={"edit"}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <StyledContentLabel>{t("EndTime")}</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%", marginRight: 4 }}>
                      <TimePicker
                        disabled={disabledCheckInOut()}
                        handleChangeTime={handleChangeTime}
                        name="CheckOutHour"
                        unit="hour"
                        value={tempformData.CheckOutHour}
                        mode={"edit"}
                      />
                    </div>
                    <div style={{ width: "100%", marginLeft: 4 }}>
                      <TimePicker
                        disabled={disabledCheckInOut()}
                        handleChangeTime={handleChangeTime}
                        name="CheckOutMinute"
                        unit="minute"
                        step="1"
                        value={tempformData.CheckOutMinute}
                        mode={"edit"}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <StyledFormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                    {t("leaveAbsent")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        tempformData.leave
                          ? tempformData.leave.idLeaveType
                          : null
                      }
                      label={t("leaveAbsent")}
                      name="idLeaveType"
                      // inputProps={{ readOnly: editMode !== "editTime" || values.ManualSheet.Haveleave }}
                      inputProps={{ readOnly: editMode !== "editTime" }}
                      onChange={handleChange("")}
                    >
                      <MenuItem value={null}>-</MenuItem>
                      {leaveTypeList.map((item) => (
                        <MenuItem value={item.idLeaveType}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                  {tempformData.leave && (
                    <StyledFormControl component="fieldset">
                      <RadioGroup
                        disabled={CheckChangeLeave}
                        row
                        aria-label="gender"
                        name="isFullDay"
                        value={tempformData.leave.isFullDay}
                        onChange={handleChange("")}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="เต็มวัน"
                          disabled={!CheckChangeLeave}
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="ระบุเวลา"
                          disabled={
                            disabledSelectLeaveDayType() || !CheckChangeLeave
                          }
                        />
                      </RadioGroup>
                    </StyledFormControl>
                  )}
                </Grid>
                {tempformData.leave && tempformData.leave.isFullDay === 0 && (
                  <Fragment>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <StyledContentLabel>{t("StartTime")}</StyledContentLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "100%", marginRight: 4 }}>
                          <TimePicker
                            handleChangeTime={handleChangeLeaveTime}
                            name="StartLeaveHour"
                            unit="hour"
                            // value={(formData && formData.CheckInHour) || ""}
                            value={tempformData.leave.StartLeaveHour}
                            mode={editMode === "editTime" ? "edit" : "read"}
                          />
                        </div>
                        <div style={{ width: "100%", marginLeft: 4 }}>
                          <TimePicker
                            handleChangeTime={handleChangeLeaveTime}
                            name="StartLeaveMinute"
                            unit="minute"
                            step="1"
                            value={tempformData.leave.StartLeaveMinute}
                            mode={editMode === "editTime" ? "edit" : "read"}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <StyledContentLabel>{t("EndTime")}</StyledContentLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "100%", marginRight: 4 }}>
                          <TimePicker
                            handleChangeTime={handleChangeLeaveTime}
                            name="EndLeaveHour"
                            unit="hour"
                            value={tempformData.leave.EndLeaveHour}
                            mode={editMode === "editTime" ? "edit" : "read"}
                          />
                        </div>
                        <div style={{ width: "100%", marginLeft: 4 }}>
                          <TimePicker
                            handleChangeTime={handleChangeLeaveTime}
                            name="EndLeaveMinute"
                            unit="minute"
                            step="1"
                            value={tempformData.leave.EndLeaveMinute}
                            mode={editMode === "editTime" ? "edit" : "read"}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Fragment>
                )}
                {editMode !== "editTime" ? (
                  <StyledWrapActionButton>
                    <ButtonBlue
                      onClick={() => handleChangeEditFormData("editTime")}
                    >
                      {t("Edit")}
                    </ButtonBlue>
                  </StyledWrapActionButton>
                ) : (
                  <Fragment>
                    <StyledWrapActionButton>
                      <ButtonBlue
                        variant="text"
                        onClick={() => handleChangeEditFormData(null)}
                      >
                        {t("Cancel")}
                      </ButtonBlue>
                      <ButtonBlue
                        variant="contained"
                        onClick={() => handleChangeSubmit("editTime")}
                      >
                        {t("Save")}
                      </ButtonBlue>
                    </StyledWrapActionButton>
                  </Fragment>
                )}
                <StyledDivider />
              </Fragment>
              <Fragment>
                <Fragment>
                  <Grid item xs={12} sm={12}>
                    <StyledContentLabel>
                      OT 1
                      <Typography component={"span"} color="text.secondary">
                        {` ( ${t("Hours")} : ${t("Minute")} )`}
                      </Typography>
                    </StyledContentLabel>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: 4 }}>
                        <TimePicker
                          handleChangeTime={handleChangeOTtime}
                          name="otOneHours"
                          unit="hour"
                          value={tempformData.otOneHours.hour}
                          mode={editMode === "editOT" ? "edit" : "read"}
                        />
                      </div>
                      <div style={{ width: "100%", marginLeft: 4 }}>
                        <TimePicker
                          handleChangeTime={handleChangeOTtime}
                          name="otOneHours"
                          unit="minute"
                          step="1"
                          value={tempformData.otOneHours.minute}
                          mode={editMode === "editOT" ? "edit" : "read"}
                        />
                      </div>
                    </div>
                  </Grid>
                </Fragment>
                <Fragment>
                  <Grid item xs={12} sm={12}>
                    <StyledContentLabel>
                      OT 1.5
                      <Typography component={"span"} color="text.secondary">
                        {` ( ${t("Hours")} : ${t("Minute")} )`}
                      </Typography>
                    </StyledContentLabel>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: 4 }}>
                        <TimePicker
                          handleChangeTime={handleChangeOTtime}
                          name="otOneFiveHours"
                          unit="hour"
                          value={tempformData.otOneFiveHours.hour}
                          mode={editMode === "editOT" ? "edit" : "read"}
                        />
                      </div>
                      <div style={{ width: "100%", marginLeft: 4 }}>
                        <TimePicker
                          handleChangeTime={handleChangeOTtime}
                          name="otOneFiveHours"
                          unit="minute"
                          step="1"
                          value={tempformData.otOneFiveHours.minute}
                          mode={editMode === "editOT" ? "edit" : "read"}
                        />
                      </div>
                    </div>
                  </Grid>
                </Fragment>
                <Fragment>
                  <Grid item xs={12} sm={12}>
                    <StyledContentLabel>
                      OT 2
                      <Typography component={"span"} color="text.secondary">
                        {` ( ${t("Hours")} : ${t("Minute")} )`}
                      </Typography>
                    </StyledContentLabel>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: 4 }}>
                        <TimePicker
                          handleChangeTime={handleChangeOTtime}
                          name="otTwoHours"
                          unit="hour"
                          value={tempformData.otTwoHours.hour}
                          mode={editMode === "editOT" ? "edit" : "read"}
                        />
                      </div>
                      <div style={{ width: "100%", marginLeft: 4 }}>
                        <TimePicker
                          handleChangeTime={handleChangeOTtime}
                          name="otTwoHours"
                          unit="minute"
                          step="1"
                          value={tempformData.otTwoHours.minute}
                          mode={editMode === "editOT" ? "edit" : "read"}
                        />
                      </div>
                    </div>
                  </Grid>
                </Fragment>
                <Fragment>
                  <Grid item xs={12} sm={12}>
                    <StyledContentLabel>
                      OT 3
                      <Typography component={"span"} color="text.secondary">
                        {` ( ${t("Hours")} : ${t("Minute")} )`}
                      </Typography>
                    </StyledContentLabel>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: 4 }}>
                        <TimePicker
                          handleChangeTime={handleChangeOTtime}
                          name="otThreeHours"
                          unit="hour"
                          value={tempformData.otThreeHours.hour}
                          mode={editMode === "editOT" ? "edit" : "read"}
                        />
                      </div>
                      <div style={{ width: "100%", marginLeft: 4 }}>
                        <TimePicker
                          handleChangeTime={handleChangeOTtime}
                          name="otThreeHours"
                          unit="minute"
                          step="1"
                          value={tempformData.otThreeHours.minute}
                          mode={editMode === "editOT" ? "edit" : "read"}
                        />
                      </div>
                    </div>
                  </Grid>
                </Fragment>

                {!disabledCheckInOut() && (
                  <Fragment>
                    {editMode !== "editOT" ? (
                      <StyledWrapActionButton>
                        <ButtonBlue
                          onClick={() => handleChangeEditFormData("editOT")}
                        >
                          {t("Edit")}
                        </ButtonBlue>
                      </StyledWrapActionButton>
                    ) : (
                      <Fragment>
                        <StyledWrapActionButton>
                          <ButtonBlue
                            variant="text"
                            onClick={() => handleChangeEditFormData(null)}
                          >
                            {t("Cancel")}
                          </ButtonBlue>
                          <ButtonBlue
                            variant="contained"
                            onClick={() => handleChangeSubmit("editOT")}
                          >
                            {t("Save")}
                          </ButtonBlue>
                        </StyledWrapActionButton>
                      </Fragment>
                    )}
                  </Fragment>
                )}
                <StyledDivider />
              </Fragment>
            </Grid>
          )}
          <StyledFooter>
            <ButtonBlue
              className="cancel"
              color="secondary"
              variant="text"
              onClick={handleClose}
            >
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue
              disabled={disabledSubmit() || isSubmit}
              variant="contained"
              onClick={() => {
                handleSubmit();
              }}
            >
              {t("Save")}
            </ButtonBlue>
          </StyledFooter>
        </StyledContent>
      )}
    </DrawerCustom>
  );
};

export default DialogEdit;
