import React, { useEffect, useState } from "react";
import {
  Box,
  CardContent,
  Container,
  Grid,
  styled,
  Tab,
  Tabs,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import CardStyle from "../../../shared/general/Card";
import * as Yup from "yup";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import BasicInfo from "./BasicInfo";
import EmployeeType from "./EmployeeType";
import JobInfo from "./JobInfo";
import ManagerInfo from "./ManagerInfo";
import EducationInfo from "./EducationInfo";
import PayrollInfo from "./PayrollInfo";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SaveIcon from "@mui/icons-material/Save";
import { getAllDataForm } from "../../../../../actions/employee";
import { addUsersOne } from "../../../../../actions/user";
import dayjs from "dayjs";
import AlertDialog from "./AlertDialog";
import { useHistory } from "react-router-dom";
import DialogAddEducation from "./dialogAddEducation";

const StyledRoot = styled(Box)({
  "&.page": {
    backgroundColor: "#FFFFFF",
  },
  "& .control-page-container": {
    marginTop: "24px",
    display: "flex",
    "& .btn-next": {
      marginLeft: "auto",
    },
  },
  "& .topic-textField": {
    marginTop: 12,
  },
  "& .topic-radio": {
    marginTop: 8,
  },
});

const initialValues = {
  // Basic Info
  employeeId: "",
  title: "",
  firstname: "",
  lastname: "",
  title_en: "",
  firstname_en: "",
  lastname_en: "",
  nickname: "",
  gender: "",
  birthday: "",
  personalId: "",
  telephoneMobile: "",
  email: "",
  houseNo: "",
  vilage: "",
  vilageNo: "",
  alley: "",
  road: "",
  areaCode: "",
  subDistrict: "",
  district: "",
  provience: "",
  nationality: "",
  maritalStatus: "",
  passportId: "",
  workPermit: "",
  emergencyContact: "",
  emergencyRelationship: "",
  emergencyPhone: "",
  employmentTime: "",
  idPaymentType: "",
  paymentRound: "",
  hiringDate: "",
  idVendor: "",
  idCompanyCharge: "",
  idPositions: "",
  division: "",
  department: "",
  section: "",
  jobGroup: "",
  idJobLevel: "",
  idPersonnelLevel: "",
  idEmployeeType: "",
  filed_office: "",
  workingType: "",
  idShift: "",
  idShiftGroup: "",
  workingLocationName: "",
  mainWorkingLocationPoint: "",
  isFinger: "1",
  methodAttendance: "main",
  isContractTerminate: "0",
  contractTermainatoinDate: "",
  idManagerLV1: "",
  idManagerLV2: "",
  salary: "",
  chargeSalary: "",
  chargeOt: "",
  chargeHire: "",
  chargeCompensation: "",
  costCenterCharge: "",
  costElementCharge: "",
  bookBank: "",
  bookBankBranch: "",
  bookBankId: "",
  reportBank_BankName: "",
  reportBank_BankId: "",
  reportBank_BookBankId: "",
  reportBank_Name: "",
  reportBank_Ref: "",
  educations: [],
};

const AddEmployeePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const validationSchema = Yup.object({
    employeeId: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    title: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    firstname: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    lastname: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    title_en: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    firstname_en: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    lastname_en: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    gender: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    birthday: Yup.date()
      .required(`${t("ThisFieldIsRequired")}`)
      .nullable(),
    personalId: Yup.string()
      .required(`${t("ThisFieldIsRequired")}`)
      .length(13, `${t("PleaseIDCard")}`),
    telephoneMobile: Yup.string()
      .required(`${t("ThisFieldIsRequired")}`)
      .length(10, `${t("PleasePhoneNumber")}`),
    email: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    emergencyPhone: Yup.string().when({
      is: (emergencyPhone) => emergencyPhone.length > 0,
      then: (schema) => schema.length(10, "โปรดกรอกเบอร์โทร 10 หลัก"),
    }),

    employmentTime: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    idPaymentType: Yup.string().required(`${t("ThisFieldIsRequired")}`),

    hiringDate: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    idVendor: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    idPositions: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    filed_office: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    workingType: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    idShift: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    idShiftGroup: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    workingLocationName: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    mainWorkingLocationPoint: Yup.string().required(
      `${t("ThisFieldIsRequired")}`
    ),

    idManagerLV1: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    idManagerLV2: Yup.string().required(`${t("ThisFieldIsRequired")}`),

    salary: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    chargeSalary: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    chargeOt: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    chargeHire: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    chargeCompensation: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    bookBank: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    bookBankBranch: Yup.string().required(`${t("ThisFieldIsRequired")}`),
    bookBankId: Yup.string().required(`${t("ThisFieldIsRequired")}`),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "educations",
  });

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: empDataForm } = useSelector((state) => state.empDataForm);
  const [tabValue, setTabValue] = useState(0);
  const [companySelect, setCompanySelect] = useState({
    idCompany: "",
    companyName: "",
    job: [],
    jobLevel: [],
    personnelLevel: [],
    employeeType: [],
    groupGpsLocations: [],
    shift: [],
    manager: [],
  });
  const [isOpenDialogAddEducation, setIsOpenDialogAddEducation] =
    useState(false);

  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });

  const [redirectURL, setRedirectURL] = useState(null);

  const handleChangeTab = (_, newValue) => {
    setTabValue(newValue);
  };

  const onSubmit = async (values) => {
    values.idCompany = userProfile.idCompany;
    values.birthday = dayjs(values.birthday).format("YYYY-MM-DD");
    values.hiringDate = dayjs(values.hiringDate).format("YYYY-MM-DD");
    values.contractTermainatoinDate =
      values.isContractTerminate === "1" && values.contractTermainatoinDate
        ? dayjs(values.contractTermainatoinDate).format("YYYY-MM-DD")
        : null;

    values.educations.map((e) => {
      e.fromYear = e.fromYear ? dayjs(e.fromYear).format("YYYY") : null;
      e.endYear = e.endYear ? dayjs(e.endYear).format("YYYY") : null;
    });

    delete values.division;
    delete values.department;
    delete values.section;
    delete values.jobGroup;

    const { isContractTerminate, ...formData } = values;

    // console.log(formData);

    const result = await dispatch(addUsersOne(formData));
    if (result) {
      if (result.status === 200) {
        setModalStatusConfig({
          open: true,
          title: "",
          content: `${t("AddEmpSuccess")}`,
          type: "success",
        });
        setRedirectURL(`/employees/${result.data.idEmp}/edit`);
      } else {
        setModalStatusConfig({
          open: true,
          title: "",
          content: result.message,
          type: "error",
        });
      }
    } else {
      setModalStatusConfig({
        open: true,
        title: "",
        content: `${t("Please_Contact_Admin")}`,
        type: "error",
      });
    }
  };

  const handleNextTab = () => {
    setTabValue(tabValue + 1);
  };

  const handlePrevTab = () => {
    if (tabValue > 0) {
      setTabValue(tabValue - 1);
    }
  };

  const checkValidation = (error) => {
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: `${t("PleaseCompleteRequired")}`,
      type: "error",
    });
  };

  useEffect(() => {
    dispatch(getAllDataForm());
  }, []);

  useEffect(() => {
    if (empDataForm) {
      // let company = empDataForm.find((company) => company.idCompany === userProfile.idCompany);
      // if(company){
      //   setCompanySelect(company);
      // }
    }
  }, [empDataForm]);

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ paddingTop: "16px", paddingBottom: "16px" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CardStyle>
              <CardContent>
                <Tabs
                  orientation="vertical"
                  variant="standard"
                  value={tabValue}
                  onChange={handleChangeTab}
                >
                  <Tab label={`${t("GeneralInfo")}`} />
                  <Tab label={`${t("EmployeeType")}`} />
                  <Tab label={`${t("Job_Description")}`} />
                  <Tab label={`${t("Manager")}`} />
                  <Tab label={`${t("PayrollInfo")}`} />
                  <Tab label={`${t("Education")}`} />
                </Tabs>
              </CardContent>
            </CardStyle>
          </Grid>
          <Grid item xs={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit, checkValidation)}>
              <TabPanel value={tabValue} index={0}>
                <BasicInfo control={control} errors={errors} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <EmployeeType
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <JobInfo
                  companySelect={companySelect}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <ManagerInfo
                  companySelect={companySelect}
                  control={control}
                  errors={errors}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <PayrollInfo control={control} errors={errors} />
              </TabPanel>
              <TabPanel value={tabValue} index={5}>
                <EducationInfo
                  fields={fields}
                  append={append}
                  remove={remove}
                  setIsOpenDialogAddEducation={setIsOpenDialogAddEducation}
                />
              </TabPanel>

              <div className="control-page-container">
                {tabValue > 0 && (
                  <ButtonBlue
                    variant={"outlined"}
                    onClick={handlePrevTab}
                    startIcon={<ChevronLeftIcon />}
                  >
                    {t("Back")}
                  </ButtonBlue>
                )}

                {tabValue < 5 && (
                  <ButtonBlue
                    className="btn-next"
                    variant={"contained"}
                    onClick={handleNextTab}
                    endIcon={<ChevronRightIcon />}
                  >
                    {t("Next")}
                  </ButtonBlue>
                )}

                {tabValue === 5 && (
                  <ButtonBlue
                    className="btn-next"
                    variant={"contained"}
                    startIcon={<SaveIcon />}
                    type="submit"
                  >
                    {t("Save")}
                  </ButtonBlue>
                )}
              </div>
            </form>
          </Grid>
        </Grid>
        {isOpenDialogAddEducation && (
          <DialogAddEducation
            append={append}
            open={isOpenDialogAddEducation}
            onClose={() => {
              setIsOpenDialogAddEducation(false);
            }}
          />
        )}
        <AlertDialog
          open={modalStatusConfig.open}
          title={modalStatusConfig.title}
          type={modalStatusConfig.type}
          content={modalStatusConfig.content}
          callBack={modalStatusConfig.callBack}
          onClose={() => {
            setModalStatusConfig({
              ...modalStatusConfig,
              open: false,
            });
            if (redirectURL) {
              history.push(redirectURL);
            }
          }}
        />
      </Container>
    </StyledRoot>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <CardStyle>{children}</CardStyle>}
    </div>
  );
};

export default AddEmployeePage;
