
import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getExcelBillingStatementById,
} from "../../../../actions/dumpExcel"
import DialogUpload from "./dialogUpload/uploadBills";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import TableList from "./tableList";
import {
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import ButtonBlue from "../../shared/general/ButtonBlue";
import HeaderPage from "../../shared/header/headerPage";
import StyledCard from "../../shared/general/Card";
import SelectCompany from "../shared/selectCompany";
import { useHistory, useLocation } from 'react-router-dom';
import AlertResponse from "../../shared/general/AlertResponse";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .inner-header-name": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .btn-setting": {
    marginLeft: 8,
  },
  "& .search-date": {
    width: 250,
    marginBottom: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const StyledLoading = styled("div")({
  marginTop: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  height: "inherit",
});

const StyledCardContent = styled(CardContent)({});

const StyledPleaseSelectCompany = styled("div")({
  padding: 18,
  width: "100%",
  "& .MuiTypography-root": {
    fontStyle: "oblique",
    letterSpacing: 2,
  },
});

const billingList = (props) => {
  const { vendorContract } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { result: billingStatement } = useSelector((state) => state.billingStatement);
  const [BillingStatements, setBillingStatement] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [label, setLabel] = useState(null);
  const [reload, setReload] = useState(false);
  const [company, setCompany] = useState(null);
  const [contract, setContract] = useState([]);

  const handleOpenAlert = (type, label) => {
    setOpenAlert(true);
    setAlertType(type);
    setLabel(label);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    if (vendorContract) {
      setContract(vendorContract);
    }
  }, [vendorContract]);


  useEffect(() => {
    if (location.state && location.state.idCompany && contract.length > 0) {

      const matchedCompany = contract.filter(
        (contract) => contract.idCompany === Number(location.state.idCompany)
      );

      if (matchedCompany.length > 0) {
        setCompany(matchedCompany[0]);
      }
      if (location.state.idCompany) {
        dispatch(getExcelBillingStatementById(location.state.idCompany));
      }
      if (company && company.idCompany) {
        dispatch(getExcelBillingStatementById(company.idCompany));
      }
    }
  }, [contract, reload]);

  const ReloadData = () => {
    setReload(prevReload => !prevReload);
  }

  useEffect(() => {
    setBillingStatement(billingStatement);
  }, [billingStatement]);

  const [openDialogUpload, setOpenDialogUpload] = useState(false);

  const handleCloseDialogUpload = () => {
    setOpenDialogUpload(false);
  };

  const handleChangeCompany = (newCompany) => {
    setCompany(newCompany);
    if (newCompany) {
      dispatch(getExcelBillingStatementById(newCompany.idCompany));
    }
  };

  const handleClick = (event) => {
    setOpenDialogUpload(true);
  };

  const data = [
    { header: t("Bill_draft"), isApprove: 0 },
    { header: t("Bill_Approved"), isApprove: 1 }
  ];

  return (
    <>
      <div className="wrap-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Fragment>
          <div className="inner-header-name">
            <HeaderPage textLabel={t("BillStatement")} />
          </div>
          <ButtonBlue
            onClick={handleClick}
            variant="contained"
            startIcon={<AddIcon />}
            style={{ marginRight: 16 }}
          >
            {t("CreateInvoice")}
          </ButtonBlue>
        </Fragment>
      </div>
      <div className="search-date" style={{ paddingTop: 24, paddingBottom: 24 }}>
        <SelectCompany
          handleChangeCompany={handleChangeCompany}
          company={company}
        />
      </div>
      <Fragment>
        {company ? (
          <>
            {billingStatement ? (
              <>
                <div style={{ paddingBottom: 16 }}>
                  {data.map((value, index) => (
                    <div key={index} style={{ marginBottom: 16 }}>
                      <StyledCard>
                        <StyledCardContent>
                          <div>
                            <Typography variant="h6" gutterBottom>
                              {value.header}
                            </Typography>
                          </div>
                          <div>
                            {Array.isArray(BillingStatements) ? (
                              <TableList
                                reload={ReloadData}
                                handleOpenAlert={handleOpenAlert}
                                billingsStatement={BillingStatements.filter(
                                  (item) => item.isApprove === value.isApprove
                                )}
                                idCompany={company.idCompany}
                              />
                            ) : (
                              <Typography color="text.secondary" style={{ margin: '8px 0' }}>
                                {t("NoData")}
                              </Typography>
                            )}
                          </div>
                        </StyledCardContent>
                      </StyledCard>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <StyledLoading>
                <Typography variant="h6" color="text.third" gutterBottom>
                  {/* {t("Saving_Data")}... */}
                  {t("LoadingData")}...
                </Typography>
                <div>
                  <CircularProgress />
                </div>
              </StyledLoading>
            )}
          </>
        ) : (
          <StyledPleaseSelectCompany>
            <Typography color="text.third" align="center">
              ...{t("PleaseSelectCompany")}...
            </Typography>
          </StyledPleaseSelectCompany>
        )}
      </Fragment>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
        label={label}
      />
      <DialogUpload
        open={openDialogUpload}
        handleOpenAlert={handleOpenAlert}
        reload={ReloadData}
        handleClose={handleCloseDialogUpload}
        vendorContract={vendorContract}
      />
    </>

  );
};

export default billingList;



