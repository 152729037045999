import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Avatar, MenuItem, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import DialogDeleteEmployee from "./DialogDeleteEmployee";

import { useTranslation } from "react-i18next";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
      width: 45,
      height: 45,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .department": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    fontWeight: 600,
  },
  "& .reportToPosition": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 500px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
});

const ItemTable = (props) => {
  const { filterEmployee } = props;
  const { t, i18n } = useTranslation();
  const { result: employeeList } = useSelector((state) => state.users);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSelected, setValueSelected] = React.useState(null);

  const [dialogDeleteEmployeeConfig, setDialogDeleteEmployeeConfig] = useState({
    open: false,
    idEmp: null,
    employeesName: "",
  });

  useEffect(() => {
    console.log("filterEmployee: ", filterEmployee);
    setPage(0)
  }, [filterEmployee]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const columns = [
    { id: "name", label: `${t("FullName")}`, minWidth: 240 },
    {
      id: "position",
      label: `${t("Position")}/${t("Section")}`,
      minWidth: 250,
    },
    {
      id: "resignDate",
      label: `วันที่พ้นสภาพ`,
      minWidth: 150,
      isResign: "0",
    },
    {
      id: "companyNameShort",
      label: `${t("Company")}`,
      minWidth: 100,
    },
    {
      id: "report_To_1",
      label: `${t("Report_To")} 1`,
      minWidth: 200,
    },
    {
      id: "report_To_2",
      label: `${t("Report_To")} 2`,
      minWidth: 200,
    },
    {
      id: "employeeTypeName",
      label: `${t("Employee_Type")}`,
      minWidth: 150,
    },
  ];

  const rowsFilter = () => {
    if (employeeList) {
      if (filterEmployee.search == "" && filterEmployee.idCompany == "all") {
        return filterEmployee.status === "1"
          ? employeeList.active
          : employeeList.termainate;
      } else {
        let resultFilter =
          filterEmployee.status === "1"
            ? [...employeeList.active]
            : [...employeeList.termainate];

        if (
          resultFilter &&
          filterEmployee.search &&
          filterEmployee.search.length > 0
        ) {
          resultFilter = resultFilter.filter((item, index) => {
            if (
              (item.firstname &&
                item.firstname.indexOf(filterEmployee.search) >= 0) ||
              (item.lastname &&
                item.lastname.indexOf(filterEmployee.search) >= 0) ||
              (item.employeeTypeEmployeeId &&
                item.employeeTypeEmployeeId.indexOf(filterEmployee.search)) >= 0
            )
              return item;
          });
        }

        if (filterEmployee.idCompany != "all") {
          resultFilter = resultFilter.filter((item) => {
            return item.idCompany == filterEmployee.idCompany;
          });
        }

        // if (filterEmployee.idDepartment != "all") {
        //   resultFilter = resultFilter.filter((item) => {
        //     if (item.idDepartment == filterEmployee.idDepartment) return item;
        //   });
        // }

        console.log(resultFilter);
        return resultFilter;
      }
    }
  };

  const handleDeleteEmployee = (employee) => {
    // console.log("Delete")
    handleCloseMenu();
    setDialogDeleteEmployeeConfig({
      open: true,
      idEmp: employee.idEmp,
      employeesName: `${employee.firstname} ${employee.lastname}`,
    });
  };

  return (
    <div>
      <StyledBox className={"close"}>
        <TableContainer style={{ width: "100%", height: 450 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns
                  .filter((x) => {
                    if (filterEmployee.status === "0") {
                      return x;
                    } else {
                      return x.isResign !== "0";
                    }
                  })
                  .map((column) => (
                    <StyledCellHeader
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledCellHeader>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeList &&
                rowsFilter()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <StyledRowContent key={`${page}-${index}`}>
                        <StyledCellContent className="sticky">
                          <div className="fullname">
                            <IconButton
                              aria-label="more"
                              size="small"
                              style={{ marginRight: 8 }}
                              onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setValueSelected(row);
                                console.log(row);
                              }}
                            >
                              <MoreVertIcon fontSize="small" />
                            </IconButton>
                            <Avatar
                              // src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                              src={
                                row.imageProfile ?
                                `${row.imageProfile}`
                                :
                                `${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`
                              }
                            />
                            <div>
                              <Typography>
                                {row.firstname} {row.lastname}
                              </Typography>
                              <Typography variant="body2" color="text.third">
                                {row.telephoneMobile &&
                                  `${row.telephoneMobile.replace(
                                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                                    "$1-$2-$3"
                                  )}`}
                              </Typography>
                            </div>
                          </div>
                        </StyledCellContent>
                        <StyledCellContent>
                          <Typography className="position">
                            {row.positionsName}
                          </Typography>
                          <Typography className="department" color="text.third">
                            {row.sectionName}
                          </Typography>
                        </StyledCellContent>
                        {filterEmployee.status === "0" && (
                          <StyledCellContent>
                            <Typography>
                              {row.resignDate
                                ? dayjs(row.resignDate)
                                    .locale(
                                      localStorage.getItem("language") || "th"
                                    )
                                    .format(
                                      localStorage.getItem("language") === "en"
                                        ? "D MMMM YYYY"
                                        : "D MMMM BBBB"
                                    )
                                : "-"}
                            </Typography>
                          </StyledCellContent>
                        )}

                        <StyledCellContent>
                          <Typography>{row.companyNameShort}</Typography>
                        </StyledCellContent>
                        <StyledCellContent>
                          <Typography className="reportTo">
                            {row.managerLV1Name}
                          </Typography>
                          <Typography
                            className="reportToPosition"
                            color="text.third"
                          >
                            {row.managerLV1Email}
                          </Typography>
                        </StyledCellContent>
                        <StyledCellContent>
                          <Typography className="reportTo">
                            {row.managerLV2Name}
                          </Typography>
                          <Typography
                            className="reportToPosition"
                            color="text.third"
                          >
                            {row.managerLV2Email}
                          </Typography>
                        </StyledCellContent>
                        <StyledCellContent>
                          <Typography>{row.employeeTypeName}</Typography>
                        </StyledCellContent>
                      </StyledRowContent>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={employeeList && rowsFilter().length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <StyledMenu
          id={`more-menu`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {valueSelected && (
            <MenuItem
              component={NavLink}
              to={`/vendor/employees/${valueSelected.idEmp}/edit`}
            >
              <i className="far fa-user-edit" style={{ marginRight: 16 }}></i>
              <Typography>{t("Edit_Data")}</Typography>
            </MenuItem>
          )}

          {valueSelected && (
            <MenuItem
              onClick={() => {
                handleDeleteEmployee(valueSelected);
              }}
              style={{ color: "red" }}
            >
              <i className="far fa-trash-can" style={{ marginRight: 16 }}></i>
              <Typography style={{ color: "inherit" }}>
                {t("Delete_Employee")}
              </Typography>
            </MenuItem>
          )}
        </StyledMenu>
      </StyledBox>
      <DialogDeleteEmployee
        open={dialogDeleteEmployeeConfig.open}
        idEmp={dialogDeleteEmployeeConfig.idEmp}
        employeesName={dialogDeleteEmployeeConfig.employeesName}
        onClose={() => {
          setDialogDeleteEmployeeConfig({
            ...dialogDeleteEmployeeConfig,
            open: false,
          });
        }}
      />
    </div>
  );
};

export default ItemTable;
