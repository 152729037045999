import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Backdrop, Box, Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import ButtonBlue from "../../shared/general/ButtonBlue";
import StyledCard from "../../shared/general/Card";

import dataEmployeeList from "../../assets/data/employees";

import FilterTable from "./filterTable";
import FirstUploadEmployee from "./first-upload-employee";
import ItemTable from "./ItemTable";

import { useTranslation } from "react-i18next";

import "./index.css";

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapHeader: {
    marginTop: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ["@media only screen and (max-width: 600px)"]: {
      "& .MuiTypography-root": {
        fontSize: 26,
      },
    },
    ["@media only screen and (min-width:600px)"]: {
      "& .MuiTypography-root": {
        fontSize: 26,
      },
    },
    ["@media only screen and (min-width:768px)"]: {
      "& .MuiTypography-root": {
        fontSize: 34,
      },
    },
  },
  btnAddNew: {
    ["@media only screen and (max-width: 600px)"]: {
      display: "none",
    },
  },
  btnIconAddNew: {
    ["@media only screen and (min-width:600px)"]: {
      display: "none",
    },
  },
}));

const StyledContent = styled("div")({
  padding: 24,
  "& .wrapFilter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },
  "& .select-company": {
    display: "flex",
    justifyContent: "center",
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: 8,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#007aff",
    border: "1px solid #007aff",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007aff",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #007aff",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const EmployeeList = (props) => {
  const { t, i18n } = useTranslation();
  let query = useQuery();
  const { result: employeeList } = useSelector((state) => state.users);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterEmployee, setFilterEmployee] = useState({
    status: "1",
    search: "",
    idDepartment: "all",
    idCompany: "all",
    idJobGroup: "all",
    employeeType: [],
    level: [],
  });

  useEffect(() => {
    setFilterEmployee({
      ...filterEmployee,
      ["idCompany"]: query.get("filter") ? query.get("filter") : "all",
    });
  }, []);

  const handleChangeFilterEmployee = (event, newStatus, selectName) => {
    var name = event.target.name;
    var value = newStatus;
    console.log(name, value);

    if (newStatus === undefined) {
      setFilterEmployee({
        ...filterEmployee,
        [name]: event.target.value,
      });
    } else {
      if (name === undefined) {
        name = selectName;
      }
      if (name === "status" && value === null) {
      } else {
        setFilterEmployee({
          ...filterEmployee,
          [name]: value,
        });
      }
    }
  };

  const handleChangeCompany = (newStatus) => {
    setFilterEmployee({
      ...filterEmployee,
      ["idCompany"]: newStatus,
    });
  };

  const rowsFilter = () => {
    if (filterEmployee.search == "" && filterEmployee.idCompany == "all") {
      return filterEmployee.status === "1"
        ? employeeList.active
        : employeeList.termainate;
    } else {
      let resultFilter =
        filterEmployee.status === "1"
          ? [...employeeList.active]
          : [...employeeList.termainate];

      console.log("filterEmployee: ", filterEmployee);

      if (filterEmployee.idCompany != "all") {
        console.log("In company");
        resultFilter = resultFilter.filter((item) => {
          return item.idCompany == filterEmployee.idCompany;
        });
      }
      return resultFilter;
    }
  };

  return (
    <div>
      <StyledCard>
        <StyledContent>
          {employeeList.active.length > 0 ? (
            <Fragment>
              <div className="wrapFilter">
                <div>
                  <StyledToggleButtonGroup
                    value={filterEmployee.status}
                    exclusive
                    onChange={handleChangeFilterEmployee}
                    aria-label="filter employee"
                    size="small"
                  >
                    <ToggleButton
                      name="status"
                      value="1"
                      aria-label="active"
                      size="small"
                      style={{ minWidth: 150 }}
                    >
                      {t("Employees")}
                    </ToggleButton>
                    <ToggleButton
                      name="status"
                      value="0"
                      aria-label="terminate"
                      size="small"
                      style={{ minWidth: 150 }}
                    >
                      {t("Employee_Leaved")}
                    </ToggleButton>
                  </StyledToggleButtonGroup>
                </div>
                {employeeList && (
                  <div>
                    <Typography variant="h4" align="right">
                      {rowsFilter().length}
                    </Typography>
                    <Typography
                      variant="body2"
                      align="right"
                      color="text.secondary"
                      style={{ fontWeight: 500 }}
                    >
                      {t("Total_Person")}
                    </Typography>
                  </div>
                )}
              </div>
              <FilterTable
                handleChange={handleChangeFilterEmployee}
                handleChangeCompany={handleChangeCompany}
                filterEmployee={filterEmployee}
                filterStatus={filterStatus}
              />
              <Box>
                <ItemTable filterEmployee={filterEmployee} />
              </Box>
            </Fragment>
          ) : (
            <FirstUploadEmployee />
          )}
        </StyledContent>
      </StyledCard>
    </div>
  );
};

export default EmployeeList;
