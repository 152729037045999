import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";
import {
  Box,
  Checkbox,
  Drawer,
  FilledInput,
  FormControl,
  InputAdornment,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { useTranslation } from "react-i18next";

const StyledBoxSearch = styled(Box)({
  //   marginTop: 22,
  //   "& .label": {
  //     fontWeight: 600,
  //     fontSize: 14,
  //     marginBottom: 8,
  //   },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const filterOptions = createFilterOptions({
  stringify: (option) =>
    option.firstname_TH + option.lastname_TH + option.positionName,
});

const filterOptionsName = createFilterOptions({
  stringify: (option) => option.firstname_TH + option.lastname_TH,
});

const SelectFamily = (props) => {
  let { handleChange, position, field, value, idFamily, control } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: family } = useSelector((state) => state.family);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    // if (userProfile && userProfile.idEmployees)
    // dispatch(getFamilyByIdEmployee(userProfile.idEmployees));
  }, [userProfile]);

  useEffect(() => {
    if (userProfile && family) {
      let familyMembers = family.map((item) => {
        return { ...item, isUseForFamilyMember: 1 };
      });
      setMemberList([
        {
          isUseForFamilyMember: 0,
          idFamily: 0,
          relationship: "ตนเอง",
          firstname_TH: userProfile.firstname_TH,
          lastname_TH: userProfile.lastname_TH,
          idEmployees: userProfile.idEmployees,
        },
        ...familyMembers,
      ]);
    }
  }, [family]);

  return (
    <StyledBoxSearch>
      <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
        {t("RightsHolder")}
      </StyledHeadLabel>

      <Controller
        control={control}
        name={`idFamily`}
        render={({ field, formState }) => (
          <TextFieldTheme
            {...field}
            select
            error={formState.errors && formState.idFamily ? true : false}
            helperText={
              formState.errors && formState.errors.idFamily
                ? formState.errors.idFamily.message
                : null
            }
          >
            <MenuItem value="none" disabled={true}></MenuItem>
            {memberList &&
              memberList.map((item) => (
                <MenuItem
                  id={item.idFamily}
                  key={item.idFamily}
                  value={item.idFamily}
                  onClick={(event) => {
                    if (event.target.id !== "0") {
                      handleChange("isUseForFamilyMember", 1);
                    } else {
                      handleChange("isUseForFamilyMember", 0);
                      handleChange("idFamily", null);
                    }
                  }}
                >
                  {item.firstname_TH} {item.lastname_TH} ({item.relationship})
                </MenuItem>
              ))}
          </TextFieldTheme>
        )}
      />
    </StyledBoxSearch>
  );
};

export default SelectFamily;
