import React, { Fragment, useEffect, useState } from "react";
import { Autocomplete, Box, Breadcrumbs, Container, FormControlLabel, Grid, IconButton, InputAdornment, Link, MenuItem, Radio, RadioGroup, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useHistory, useLocation, useParams } from "react-router-dom";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import DatePickerCustom from "../../shared/date/datePicker";
import ButtonBlue from "../../shared/general/ButtonBlue";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { getCompanyContract } from "../../../../actions/vendor";
import { useDispatch, useSelector } from "react-redux";
import { getAllPositions } from "../../../../actions/positions";
import dayjs from "dayjs";
import { addContractEmployees, updateContractEmployees } from "../../../../actions/contract-employees";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

const StyledRoot = styled(Box)({
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
})

const companyWatched = (control) => {
  const company = useWatch({
    control,
    name: "contractCompany"
  })
  return company
}

const AddOrEditContractEmployeeForm = (props) => {

  const { result: vendorContract } = useSelector((state) => state.vendorContract);
  const { result: positions } = useSelector((state) => state.positions);

  const [isEdit, setIsEdit] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();

  const handleClickListBreadcrumbs = () => {
    history.push({
      pathname: `/contract-employee`,
    });
  }

  const validateYupSchema = yup.object({
    contractCompany: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
    name: yup.string().required(`${t("ThisFieldIsRequired")}`),
    startDate: yup.date().nullable().required(`${t("ThisFieldIsRequired")}`).test("greaterOrEqualStartDate", t("CantSelectEndDateBeforeStartDate"), (value, context) => {
      return !context.parent.endDate || dayjs(value).isSameOrBefore(dayjs(context.parent.endDate))
    }),
    endDate: yup.date().nullable().required(`${t("ThisFieldIsRequired")}`).test("greaterOrEqualStartDate", t("CantSelectEndDateBeforeStartDate"), (value, context) => {
      return !context.parent.startDate || dayjs(value).isSameOrAfter(dayjs(context.parent.startDate))
    }),
    limitPerMonth: yup.string().required(`${t("ThisFieldIsRequired")}`),
    isOT: yup.string().required(`${t("ThisFieldIsRequired")}`),
    isMinAmountEmployees: yup.string().required(`${t("ThisFieldIsRequired")}`),
    contractPosition: yup.array().nullable().when("isMinAmountEmployees", {
      is: value => Number(value) === 0,
      then: schema => schema,
      otherwise: schema => schema
        .of(yup.object().shape({
          position: yup.mixed().nullable().required(`${t("ThisFieldIsRequired")}`),
          amount: yup.string().required(`${t("ThisFieldIsRequired")}`),
          limitPerPerson: yup.string().required(`${t("ThisFieldIsRequired")}`),
        }))
        .test({
          name: "length",
          message: `${t("ThisFieldIsRequired")}`,
          test: arr => arr.length > 0,
        })
    })
  });

  const useHookForm = useForm({
    defaultValues: {
      contractCompany: null,
      name: "",
      startDate: null,
      endDate: null,
      limitPerMonth: "",
      isOT: "0",
      isMinAmountEmployees: "0",
      amountPerDay: "",
      contractPosition: [],
      detail: "",
      deleteContractEmployeePosition: [],
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  const ContractPositionFieldArray = useFieldArray({
    control: useHookForm.control,
    name: "contractPosition"
  })

  const onDeleteContractPosition = (index) => {
    ContractPositionFieldArray.remove(index);
    calculateLimitPerMonth();
    calculateAmountPerDay();
  }

  const calculateLimitPerMonth = () => {
    const totalLimitPerMonth = useHookForm.getValues("contractPosition").reduce((accumulator, currentValue) => accumulator + (currentValue.amount * currentValue.limitPerPerson), 0);
    useHookForm.setValue("limitPerMonth", totalLimitPerMonth)
  }

  const calculateAmountPerDay = () => {
    const totalAmountPerDay = useHookForm.getValues("contractPosition").reduce((accumulator, currentValue) => accumulator + Number(currentValue.amount), 0);
    useHookForm.setValue("amountPerDay", totalAmountPerDay)
  }

  const fetchingPosition = (company) => {
    if(company){
      dispatch(getAllPositions({idCompany: company.idCompany}));
    }
    useHookForm.setValue("contractPosition", [
      {
        idContractEmployeePosition: null,
        position: null,
        amount: "",
        limitPerPerson: "",
      }
    ], { shouldDirty: false, shouldValidate: false, shouldTouch: false })
  }

  const onSubmit = async (data) => {

    let result = null;

    if(isEdit){

      const formData = {
        idCompany: data.contractCompany.idCompany,
        name: data.name,
        startDate: dayjs(data.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(data.endDate).format("YYYY-MM-DD"),
        limitPerMonth: data.limitPerMonth,
        isOT: data.isOT,
        isMinAmountEmployees: data.isMinAmountEmployees,
        amountPerDay: data.amountPerDay,
        contractPosition: data.contractPosition.map(item => ({
          idContractEmployeePosition: item.idContractEmployeePosition,
          idPositions: item.position.idPositions,
          amount: item.amount,
          limitPerPerson: item.limitPerPerson,
        })),
        detail: data.detail? data.detail: null,
        deleteContractEmployeePosition: data.deleteContractEmployeePosition,
      }

      console.log(formData)
      
      result = await dispatch(updateContractEmployees(location.state.idContractEmployees, formData));

    } else {
      const formData = {
        idCompany: data.contractCompany.idCompany,
        name: data.name,
        startDate: dayjs(data.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(data.endDate).format("YYYY-MM-DD"),
        limitPerMonth: data.limitPerMonth,
        isOT: data.isOT,
        isMinAmountEmployees: data.isMinAmountEmployees,
        amountPerDay: data.amountPerDay,
        contractPosition: data.contractPosition.map(item => ({
          idPositions: item.position.idPositions,
          amount: item.amount,
          limitPerPerson: item.limitPerPerson,
        })),
        detail: data.detail? data.detail: null
      }
      
      result = await dispatch(addContractEmployees(formData));
    }


    if(result && result.status === 200) {
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));

      if(isEdit){
        history.push({
          pathname: `/contract-employee/${location.state.idContractEmployees}`
        })
      } else {
        history.push({
          pathname: `/contract-employee/${result.data.idContractEmployees}`
        })
      }

    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }
  }

  const isMinAmountEmployeesWatch = useHookForm.watch("isMinAmountEmployees");

  useEffect(() => {
    if(location.pathname.split("/")[location.pathname.split("/").length-1] === "edit"){
      if(!location.state){
        history.push({
          pathname: `/contract-employee/${params.selected}`,
        })
      } else {
        setIsEdit(true);
        dispatch(getCompanyContract()).then(res => {
          
          const companyList = res.data;

          const foundCompany = companyList.find(x => x.idCompany === location.state.contractCompany);

          if(foundCompany){
            useHookForm.setValue("contractCompany", {idCompany: foundCompany.idCompany, companyName: foundCompany.companyName}, {shouldDirty: false, shouldTouch: false, shouldValidate: false})
          } else {
            useHookForm.setValue("contractCompany", null, {shouldDirty: false, shouldTouch: false, shouldValidate: false})
          }

          useHookForm.setValue("name", location.state.name === null? "": location.state.name, {shouldDirty: false, shouldTouch: false, shouldValidate: false});
          useHookForm.setValue("startDate", location.state.startDate || null, {shouldDirty: false, shouldTouch: false, shouldValidate: false});
          useHookForm.setValue("endDate", location.state.endDate || null, {shouldDirty: false, shouldTouch: false, shouldValidate: false});
          useHookForm.setValue("isMinAmountEmployees", location.state.isMinAmountEmployees === null? "": location.state.isMinAmountEmployees, {shouldDirty: false, shouldTouch: false, shouldValidate: false});
          useHookForm.setValue("amountPerDay", location.state.amountPerDay === null? "": location.state.amountPerDay, {shouldDirty: false, shouldTouch: false, shouldValidate: false});
          useHookForm.setValue("isOT", location.state.isOT === null? "": location.state.isOT, {shouldDirty: false, shouldTouch: false, shouldValidate: false});
          useHookForm.setValue("limitPerMonth", location.state.limitPerMonth === null? "": location.state.limitPerMonth, {shouldDirty: false, shouldTouch: false, shouldValidate: false});
          useHookForm.setValue("detail", location.state.detail === null? "": location.state.detail, {shouldDirty: false, shouldTouch: false, shouldValidate: false});
        
          const contractEmployeePosition = location.state.contractEmployeePosition;

          dispatch(getAllPositions({idCompany: location.state.contractCompany})).then(positionRes => {

            const positionList = positionRes.data;

            const contractEmployeePositionList = [];

            contractEmployeePosition.map(item => {
              
              const foundPosition = positionList.find(x => x.idPositions === item.idPositions)

              contractEmployeePositionList.push({
                idContractEmployeePosition: item.idContractEmployeePosition,
                position: foundPosition? foundPosition: null,
                amount: item.amount,
                limitPerPerson: item.limitPerPerson,
              })
            })

            useHookForm.setValue("contractPosition", contractEmployeePositionList, { shouldDirty: false, shouldValidate: false, shouldTouch: false })

          });
        
        });
      }
    } else {
      dispatch(getCompanyContract());
    }
  }, [])

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg" style={{paddingBottom: "24px"}}>
        {vendorContract && vendorContract.length > 0 && <Fragment>
          <Box marginTop="16px">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link underline="hover" color="inherit" onClick={handleClickListBreadcrumbs} style={{ cursor: "pointer" }}>
                {t("Lists")}
              </Link>
              {isEdit? (
                [
                  <Link
                    key="1"
                    underline="hover"
                    color="inherit"
                    onClick={() => {
                      history.push({
                        pathname: `/contract-employee/${location.state.idContractEmployees}`,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {location.state.name}
                  </Link>,
                  <Link key="2" underline="none" color="inherit">
                    {t(`Edit`)}
                  </Link>
                ]
              ):(
                <Link underline="none" color="inherit">
                  สร้างรายการสัญญาจ้างเหมา
                </Link>
              )}
            </Breadcrumbs>
          </Box>
          <Box paddingTop="20px" paddingBottom="30px">
            {isEdit? (
              <Typography variant="h4">แก้ไขรายการ {location.state.name}</Typography>
            ) : (
              <Typography variant="h4">สร้างรายการสัญญาจ้างเหมา</Typography>
            )}
          </Box>
          <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography fontWeight="600" paddingBottom="8px">{t("ContractCompany")}</Typography>
                <Controller
                  name="contractCompany"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <Autocomplete
                      {...field}
                      options={vendorContract? vendorContract: []}
                      getOptionLabel={(option) => `${option.companyName}`}
                      isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idCompany}>
                          <Box>
                            <Typography color="text.third" fontSize="12px" fontWeight="">{option.companyNameShort}</Typography>
                            <Typography>{option.companyName}</Typography>
                          </Box>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={t("Company")}
                          onBlur={field.onBlur}
                          error={fieldState.error? true: false}
                          helperText={fieldState.error? fieldState.error.message: null}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        if(value && field.value && field.value.idCompany === value.idCompany) return
                        field.onChange(value)
                        fetchingPosition(value)
                      }}
                      noOptionsText={t("NoData")}
                      disableClearable
                      disabled={isEdit}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="600" paddingBottom="8px">ชื่อสัญญา</Typography>
                <Controller
                  name="name"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <TextFieldTheme
                      {...field}
                      error={fieldState.error? true : false}
                      helperText={fieldState.error? fieldState.error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight="600" paddingBottom="8px">วันที่เริ่มต้น</Typography>
                <Controller
                  name="startDate"
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <DatePickerCustom
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="DD MMMM YYYY"
                      openTo="day"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      disableMaskedInput
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: t("SelectDate"),
                            readOnly: true
                          }}
                          onBlur={field.onBlur}
                          error={fieldState.error? true : false}
                          helperText={fieldState.error? fieldState.error.message : null}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight="600" paddingBottom="8px">วันที่สิ้นสุด</Typography>
                <Controller
                  name="endDate"
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <DatePickerCustom
                      {...field}
                      views={["year", "month", "day"]}
                      inputFormat="DD MMMM YYYY"
                      openTo="day"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      disableMaskedInput
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: t("SelectDate"),
                            readOnly: true
                          }}
                          onBlur={field.onBlur}
                          error={fieldState.error? true : false}
                          helperText={fieldState.error? fieldState.error.message : null}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight="600" paddingBottom="8px">วงเงินเรียกเก็บ (ต่อเดือน)</Typography>
                <Controller
                  name="limitPerMonth"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <TextFieldTheme
                      {...field}
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                        endAdornment: <InputAdornment position="end">{t("Baht")}</InputAdornment>
                      }}
                      inputProps={{
                        thousandSeparator: ",",
                        allowNegative: false,
                        allowLeadingZeros: true,
                        value: field.value,
                        decimalScale: 2,
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value)
                        },
                      }}
                      onChange={()=>{}}
                      error={fieldState.error? true: false}
                      helperText={fieldState.error? fieldState.error.message: null}
                      disabled={Number(isMinAmountEmployeesWatch) === 1}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography fontWeight="600" paddingBottom="8px">ทำงานล่วงเวลา</Typography>
                <Controller
                  name="isOT"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <RadioGroup {...field} row>
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="มี"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="ไม่มี"
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight="600" paddingBottom="8px">จำนวนพนักงานขั้นต่ำ (ต่อวัน)</Typography>
                <Controller
                  name="isMinAmountEmployees"
                  control={useHookForm.control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      onChange={(event) => {
                        if(Number(event.target.value) === 1){
                          useHookForm.setValue("limitPerMonth", "0", { shouldDirty: false, shouldValidate: false, shouldTouch: false })
                          useHookForm.setValue("contractPosition", [
                            {
                              idContractEmployeePosition: null,
                              position: null,
                              amount: "",
                              limitPerPerson: "",
                            }
                          ], { shouldDirty: false, shouldValidate: false, shouldTouch: false })
                        } else {
                          useHookForm.setValue("deleteContractEmployeePosition", [...useHookForm.getValues("deleteContractEmployeePosition"), ...useHookForm.getValues("contractPosition").map(x => x.idContractEmployeePosition)]);
                          useHookForm.setValue("contractPosition", [], { shouldDirty: false, shouldValidate: false, shouldTouch: false })
                        }
                        field.onChange(event.target.value)
                      }}
                      row
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="ไม่กำหนด"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="ระบุจำนวน"
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>

              {Number(isMinAmountEmployeesWatch) === 1 && (
                <Fragment>
                  {ContractPositionFieldArray.fields.map((field, index) => (
                    <Fragment key={field.id}>
                      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                        <Typography fontWeight="600" fontSize="18px">{t(`Position`)} {index+1}</Typography>
                        <IconButton
                          disabled={index === 0}
                          onClick={() => {
                            if(field.idContractEmployeePosition){
                              useHookForm.setValue("deleteContractEmployeePosition", [...useHookForm.getValues("deleteContractEmployeePosition"), field.idContractEmployeePosition]);
                            }
                            onDeleteContractPosition(index)
                          }}
                        >
                          <DeleteOutlineRoundedIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography fontWeight="600" paddingBottom="8px">{t(`Position`)}</Typography>
                        <Controller
                          name={`contractPosition.${index}.position`}
                          control={useHookForm.control}
                          render={({field, fieldState}) => (
                            <Autocomplete
                              {...field}
                              options={positions? positions: []}
                              getOptionLabel={(option) => `${option.positionsName}`}
                              isOptionEqualToValue={(option, value) => option.idPositions === value.idPositions}
                              renderOption={(props, option) => (
                                <MenuItem {...props} key={option.idPositions}>
                                  <Box>
                                    <Typography color="text.third" fontSize="12px" fontWeight="">{option.positionsCode}</Typography>
                                    <Typography>{option.positionsName}</Typography>
                                  </Box>
                                </MenuItem>
                              )}
                              renderInput={(params) => (
                                <TextFieldTheme
                                  {...params}
                                  placeholder={t("Position")}
                                  onBlur={field.onBlur}
                                  error={fieldState.error? true: false}
                                  helperText={fieldState.error? fieldState.error.message: null}
                                />
                              )}
                              value={field.value}
                              onChange={(_, value) => {
                                field.onChange(value)
                              }}
                              noOptionsText={t("NoData")}
                              disableClearable
                              disabled={!companyWatched(useHookForm.control)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography fontWeight="600" paddingBottom="8px">จำนวนคน</Typography>
                        <Controller
                          name={`contractPosition.${index}.amount`}
                          control={useHookForm.control}
                          render={({field, fieldState}) => (
                            <TextFieldTheme
                              {...field}
                              InputProps={{
                                inputComponent: NumberFormatTheme,
                                endAdornment: <InputAdornment position="end">{t("Person")}</InputAdornment>
                              }}
                              inputProps={{
                                thousandSeparator: ",",
                                allowNegative: false,
                                allowLeadingZeros: true,
                                value: field.value,
                                decimalScale: 0,
                                onValueChange: (values) => {
                                  const { value } = values;
                                  field.onChange(value);
                                  calculateLimitPerMonth();
                                  calculateAmountPerDay();
                                },
                              }}
                              onChange={()=>{}}
                              error={fieldState.error? true: false}
                              helperText={fieldState.error? fieldState.error.message: null}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography fontWeight="600" paddingBottom="8px">วงเงินเรียกเก็บ (ต่อคน)</Typography>
                        <Controller
                          name={`contractPosition.${index}.limitPerPerson`}
                          control={useHookForm.control}
                          render={({field, fieldState}) => (
                            <TextFieldTheme
                              {...field}
                              InputProps={{
                                inputComponent: NumberFormatTheme,
                                endAdornment: <InputAdornment position="end">{t("Baht")}</InputAdornment>
                              }}
                              inputProps={{
                                thousandSeparator: ",",
                                allowNegative: false,
                                allowLeadingZeros: true,
                                value: field.value,
                                decimalScale: 2,
                                onValueChange: (values) => {
                                  const { value } = values;
                                  field.onChange(value);
                                  calculateLimitPerMonth();
                                  calculateAmountPerDay();
                                },
                              }}
                              onChange={()=>{}}
                              error={fieldState.error? true: false}
                              helperText={fieldState.error? fieldState.error.message: null}
                            />
                          )}
                        />
                      </Grid>
                    </Fragment>
                  ))}

                  <Grid item xs={12} container>
                    <ButtonBlue
                      fullWidth
                      variant="outlined" 
                      onClick={() => {
                        ContractPositionFieldArray.append({
                          idContractEmployeePosition: null,
                          position: null,
                          amount: "",
                          limitPerPerson: "",
                        })
                      }}
                      startIcon={<AddCircleOutlineRoundedIcon />}
                    >
                      เพิ่มตำแหน่งงาน
                    </ButtonBlue>
                    {useHookForm.formState.errors.contractPosition && useHookForm.formState.errors.contractPosition.root && (
                      <Typography margin="3px 14px 0" fontSize="12px" color="error">{useHookForm.formState.errors.contractPosition.root.message}</Typography>
                    )}
                    {useHookForm.formState.errors.contractPosition && useHookForm.formState.errors.contractPosition.type === "length" && useHookForm.formState.errors.contractPosition && (
                      <Typography margin="3px 14px 0" fontSize="12px" color="error">{useHookForm.formState.errors.contractPosition.message}</Typography>
                    )}
                  </Grid>
                </Fragment>
              )}

              <Grid item xs={12}>
                <Typography fontWeight="600" paddingBottom="8px">รายละเอียด</Typography>
                <Controller
                    name="detail"
                    control={useHookForm.control}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                alignSelf: "flex-end",
                                fontSize: "12px",
                                userSelect: "none",
                              }}
                            >
                              {500 - field.value.length}
                            </InputAdornment>
                          ),
                        }}
                        multiline
                        minRows={3}
                        placeholder={t("Description")}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        error={fieldState.error? true: false}
                        onChange={(event) => {
                          if (event.target.value.length > 500) {
                            field.onChange(event.target.value.slice(0, 500));
                          } else {
                            field.onChange(event.target.value);
                          }
                        }}
                      />
                    )}
                  />
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end">
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={useHookForm.formState.isSubmitting}
                >
                  {isEdit? t(`Edit`): t(`Save`)}
                </ButtonBlue>
              </Grid>
            </Grid>
          </form>
        </Fragment>}
      </Container>
    </StyledRoot>
  )
}

export default AddOrEditContractEmployeeForm;