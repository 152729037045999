import dayjs from "dayjs";
import { httpClient } from "./httpClient";

const getLineUserProfile = (id, source) => {
  const today = dayjs(new Date()).format("YYYY-MM-DD");
  if (source) {
    return httpClient.get(`line/profile/${id}?source=pwa&filter=${today}`);
  } else {
    return httpClient.get(`line/profile/${id}?filter=${today}`);
  }
};

const getTodayCheck = (id) => {
  const today = dayjs(new Date()).format("YYYY-MM-DD");
  return httpClient.get(`line/profile/${id}/today-check?filter=${today}`);
};

const postAttendance = (formData) => {
  return httpClient.post(`line/attendance`, formData);
};

const registerLine = (formData) => {
  return httpClient.put(`line/profile/lineId`, formData);
};

export default {
  getLineUserProfile,
  postAttendance,
  registerLine,
  getTodayCheck
};
