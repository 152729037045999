import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';

const score = {
    verygood: 100,
    good: 80,
    meduim: 60,
    fair: 40,
    prettybad: 20,
    bad: 0
}

export const ProbationXlsxFile = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("Probation");

    const headerRow = worksheet1.addRow([
        'รหัสพนักงาน', 'ชื่อ', 'สกุล', 'ตำแหน่ง', 'บริษัท', 'ฝ่าย', 'ส่วน', 'แผนก', 'วันเข้างาน',
        'วันครบทดลองงาน 1', 'ผลทดลองงานรอบที่ 1', 'คะแนน(%) รอบที่ 1',
        'รายละเอียดรอบที่ 1', 'หัวหน้าผู้ประเมินรอบที่ 1', 'วันที่ประเมินรอบที่ 1',
        'วันครบทดลองงาน 2', 'ผลทดลองงานรอบที่ 2', 'คะแนน(%) รอบที่ 2',
        'รายละเอียดรอบที่ 2', 'หัวหน้าผู้ประเมินรอบที่ 2', 'วันที่ประเมินรอบที่ 2'
    ]);

    const headerStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    headerRow.height = 50;

    let colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname_TH", width: 30 },
        { key: "lastname_TH", width: 30 },
        { key: "position", width: 30 },
        { key: "company", width: 30 },
        { key: "division", width: 30 },
        { key: "department", width: 30 },
        { key: "section", width: 30 },
        { key: "hiringDate", width: 40 },
        { key: "probationDate", width: 40 },
        { key: "result", width: 40 },
        { key: "score", width: 40 },
        { key: "description", width: 70 },
        { key: "assessor", width: 40 },
        { key: "evaluationDate", width: 30 },
        { key: "probationDate", width: 40 },
        { key: "result", width: 40 },
        { key: "score", width: 40 },
        { key: "description", width: 70 },
        { key: "assessor", width: 40 },
        { key: "evaluationDate", width: 30 },
    ];

    data.forEach((item) => {
        const hiringDate = dayjs(item.hiringDate).locale('th').format("DD MMMM YYYY") || "-";

        const p1 = item.probation.find(p => p.probationRound === 1)
        const p2 = item.probation.find(p => p.probationRound === 2)

        const probationR1ApproveDate = p1 ? dayjs(p1.probationDate).locale('th').format("DD MMMM YYYY") : "-";
        const probationR2ApproveDate = p2 ? dayjs(p2.probationDate).locale('th').format("DD MMMM YYYY") : "-";
        const assessorR1Fullname = p1 ? p1.approvedBy : null;
        const assessorR2Fullname = p2 ? p2.approvedBy : null;
        const probationR1Remark = p1 ? p1.remark : null;
        const probationR2Remark = p2 ? p2.remark : null;
        const probationR1Date = p1 ? dayjs(p1.probationDate).locale('th').format("DD MMMM YYYY") : "-";
        const probationR2Date = p2 ? dayjs(p2.probationDate).locale('th').format("DD MMMM YYYY") : "-";

        let scoreR1 = "";
        let resultR1 = "";
        if (p1) {
            if (p1.question) {
                scoreR1 = Math.round(p1.question.reduce((acc, curr) => acc + score[curr.answer], 0) / p1.question.length) || 0;
            }
            if (p1.result === 1) {
                resultR1 = "ผ่าน";
            } else if (p1.result === 0) {
                resultR1 = "ไม่ผ่าน";
            } else {
                resultR1 = "รอประเมิน";
            }
        }

        let scoreR2 = "";
        let resultR2 = "";
        if (p2) {
            if (p2.question && p2.question.length > 0) {
                scoreR2 = Math.round(p2.question.reduce((acc, curr) => acc + score[curr.answer], 0) / p2.question.length) || 0;
            }
            if (p2.result === 1) {
                resultR2 = "ผ่าน";
            } else if (p2.result === 0) {
                resultR2 = "ไม่ผ่าน";
            } else {
                resultR2 = "รอประเมิน";
            }
        }

        const row = [
            item.employeeID ? item.employeeID : "-",
            item.firstname_TH ? item.firstname_TH : "-",
            item.lastname_TH ? item.lastname_TH : "-",
            item.positionName ? item.positionName : "-",
            item.companyName ? item.companyName : "-",
            item.divisionName ? item.divisionName : "-",
            item.departmentName ? item.departmentName : "-",
            item.sectionName ? item.sectionName : "-",
            hiringDate,
            probationR1Date,
            resultR1,
            scoreR1,
            probationR1Remark,
            assessorR1Fullname,
            probationR1ApproveDate,
            probationR2Date,
            resultR2,
            scoreR2,
            probationR2Remark,
            assessorR2Fullname,
            probationR2ApproveDate,
        ];

        const excelRow = worksheet1.addRow(row);
        const contentStyle = { font: { size: 18, name: 'TH SarabunPSK' } };

        colWidths.forEach((col, index) => {
            worksheet1.getColumn(index + 1).width = col.width;
        });

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${data && data[0].companyName}-การทดลองงาน.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });

}