import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';
import 'dayjs/locale/th';

export const PfPDFfile = async (type, selectYear, selectedMonth, employeeDetails) => {
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const urlFont2 = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew Bold.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    const fontBytes2 = await fetch(urlFont2).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);
    const fontBold = await pdfDoc.embedFont(fontBytes2);
    const pages = pdfDoc.getPages();
    const page1 = pages[0];
    const page2 = pages[1];

    const pageWidth = page1.getSize().width;
    const pageHeight = page1.getSize().height;
    const centerX = pageWidth / 2;

    // Center the title at the very top
    const titleText = 'แบบฟอร์มรายงานนำส่งเงินเข้ากองทุน';
    const titleWidth = fontBold.widthOfTextAtSize(titleText, 20);
    const titleHeight = fontBold.heightAtSize(20);

    page1.drawText(titleText, {
        x: centerX - titleWidth / 2,
        y: pageHeight - titleHeight - 20,
        size: 20,
        font: fontBold,
        color: rgb(0, 0, 0),
    });

    const secondText = 'เอกสารฉบับนี้ต้องส่งพร้อมการนำส่งเงินและรายละเอียดรายสมาชิกทุกครั้งที่มีการจ่ายเงินเข้ากองทุน';
    const secondTextWidth = font.widthOfTextAtSize(secondText, 16);

    page1.drawText(secondText, {
        x: centerX - secondTextWidth / 2,
        y: pageHeight - titleHeight - 45,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
        textAlign: 'center',
    });

    const thirdText = `วันที่………… เดือน…………………… พ.ศ……………`
    const fourthText = `เรียน	บริษัทหลักทรัพย์จัดการกองทุน ไทยพาณิชย์ จำกัด`
    const fifthText = `เรื่อง 	การส่งเงินเข้ากองทุน ประจำงวดที่…………………… เดือน………………………………… พ.ศ……………………………`
    const sixthText = `บริษัท……………………………………………………………………………………………………รหัสนายจ้าง…………………………………………………………`
    const seventhText = `ใคร่ขอนำส่งรายงานแสดงรายละเอียดการนำส่งเงินเข้าบัญชีกองทุนสำรองเลี้ยงชีพ…………………………………………………………………………………`
    const eighthText = `ซึ่งจดทะเบียนแล้ว โดยมีรายละเอียด ดังนี้`

    const months = [
        { value: "01", name: "มกราคม" },
        { value: "02", name: "กุมภาพันธ์" },
        { value: "03", name: "มีนาคม" },
        { value: "04", name: "เมษายน" },
        { value: "05", name: "พฤษภาคม" },
        { value: "06", name: "มิถุนายน" },
        { value: "07", name: "กรกฎาคม" },
        { value: "08", name: "สิงหาคม" },
        { value: "09", name: "กันยายน" },
        { value: "10", name: "ตุลาคม" },
        { value: "11", name: "พฤศจิกายน" },
        { value: "12", name: "ธันวาคม" },
    ];

    const selectedMonthObject = months.find(month => month.value == selectedMonth);
    const selectedMonthName = selectedMonthObject ? selectedMonthObject.name : "Unknown Month";

    let totalEmp = 0;
    let totalCom = 0;

    console.log(employeeDetails);

    if (employeeDetails) {
        for (let i = 0; i < employeeDetails.length; i++) {
            const item = employeeDetails[i];

            totalEmp += item.value;

            if (item.companyValue) {
                totalCom += item.companyValue;;
            }
        }
    }

    page1.drawText(`${totalEmp === 0 ? "-" : totalEmp.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
        x: 510,
        y: 307,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    })

    page1.drawText(`${totalCom === 0 ? "-" : totalCom.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
        x: 510,
        y: 278,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    })

    page1.drawText(`${(totalCom + totalEmp).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
        x: 510,
        y: 217,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    })

    page1.drawText(thirdText, {
        x: 470,
        y: pageHeight - titleHeight - 70,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    const date = new Date();
    const formattedDate = dayjs(date);

    formattedDate.locale('th');

    page1.drawText(formattedDate.format("DD"), {
        x: 500,
        y: pageHeight - titleHeight - 67,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(formattedDate.locale('th').format("MMMM"), {
        x: 555,
        y: pageHeight - titleHeight - 67,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    const year = formattedDate.year() + 543;

    page1.drawText(year.toString(), {
        x: 630,
        y: pageHeight - titleHeight - 67,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(fourthText, {
        x: 100,
        y: pageHeight - titleHeight - 95,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(`${selectedMonthName}`, {
        x: 380,
        y: pageHeight - titleHeight - 117,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(`${selectYear}`, {
        x: 500,
        y: pageHeight - titleHeight - 117,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(fifthText, {
        x: 100,
        y: pageHeight - titleHeight - 120,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(`${employeeDetails && employeeDetails[0].companyName}`, {
        x: 200,
        y: pageHeight - titleHeight - 143,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(sixthText, {
        x: 140,
        y: pageHeight - titleHeight - 145,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(seventhText, {
        x: 100,
        y: pageHeight - titleHeight - 170,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    page1.drawText(eighthText, {
        x: 100,
        y: pageHeight - titleHeight - 195,
        size: 16,
        font: font,
        color: rgb(0, 0, 0),
    });

    const cellWidth1 = 320;
    const cellWidth2 = 200;
    const cellWidth3 = 50;
    const cellHeight = 30;
    const cellMargin = 5;
    const borderWidth = 1;

    // Calculate the total width of the table
    const totalTableWidth = cellWidth1 + cellWidth2 + cellWidth3;

    // Calculate the center position for the table
    const centerXX = (pageWidth - totalTableWidth) / 2;

    // Set the vertical position for the table
    const tableY = pageHeight - titleHeight - 230;

    const drawTableCell = (text, x, y, width, height, isHeader = false) => {
        const fontSize = isHeader ? 18 : 16;  // Increase font size for headers
        const fontStyle = isHeader ? fontBold : font
        const cellHeightAdjustment = 4;  // Adjust cell height for headers

        const textOptions = {
            size: fontSize,
            font: fontStyle,
            color: rgb(0, 0, 0),
            align: isHeader ? 'center' : 'left',  // Center the text horizontally for headers, left-align for content
            valign: 'center', // Center the text vertically
        };

        page1.drawText(text, {
            x: isHeader ?
                text === "จำนวนเงิน (ตัวบรรจง)" ? x + width / 2 - 50 :
                    text === "หน่วย" ? x + width / 2 - 15
                        : x + width / 2 - 25
                : x + cellMargin,  // Center horizontally for headers, add margin for content
            y: y - (height / 2 + cellHeightAdjustment), // Center vertically within the cell, adjust for cell height
            ...textOptions,
        });

        page1.drawRectangle({
            x: x,
            y: y - height,
            width: width,
            height: height,
            borderColor: rgb(0, 0, 0),
            borderWidth: borderWidth,
        });
    };

    // Adjusted cell and font properties
    const adjustedCellHeight = 30;
    const adjustedFontSize = 16;

    // Draw centered table header
    drawTableCell("รายละเอียด", centerXX, tableY, cellWidth1, adjustedCellHeight, true);
    drawTableCell("จำนวนเงิน (ตัวบรรจง)", centerXX + cellWidth1, tableY, cellWidth2, adjustedCellHeight, true);
    drawTableCell("หน่วย", centerXX + cellWidth1 + cellWidth2, tableY, cellWidth3, adjustedCellHeight, true);

    // Draw left-aligned table content
    const rows = ["เงินสะสม (1)", "เงินสมทบ (2)", "เงินอื่น ๆ (3)  O ค่าปรับ  O เงินบริจาค  O อื่น……………………………………", "รวม (4)"];

    for (let i = 0; i < rows.length; i++) {
        drawTableCell(rows[i], centerXX, tableY - (i + 1) * adjustedCellHeight, cellWidth1, adjustedCellHeight);
        drawTableCell("", centerXX + cellWidth1, tableY - (i + 1) * adjustedCellHeight, cellWidth2, adjustedCellHeight);
        drawTableCell("บาท", centerXX + cellWidth1 + cellWidth2, tableY - (i + 1) * adjustedCellHeight, cellWidth3, adjustedCellHeight);
    }

    //page 2 
    page2.drawRectangle({
        x: 0,
        y: page2.getHeight() - 30,
        width: page2.getWidth(),
        height: 30,
        color: rgb(0, 219 / 255, 153 / 255),
        borderColor: rgb(0, 219 / 255, 153 / 255),
        borderWidth: 0, // Border width
    });

    page2.drawRectangle({
        x: 0,
        y: 0,
        width: page2.getWidth(),
        height: 30,
        color: rgb(0, 219 / 255, 153 / 255),
        borderColor: rgb(0, 219 / 255, 153 / 255),
        borderWidth: 0,
    });

    page2.drawText(`Report ${selectedMonthName}-${selectYear}`, {
        x: 40,
        y: page2.getHeight() - 20,
        color: rgb(1, 1, 1),
        size: 16,
        font: fontBold,
    });

    page2.drawText(`บริษัท:`, {
        x: 40,
        y: page2.getHeight() - 50,
        color: rgb(0, 219 / 255, 153 / 255),
        size: 16,
        font: font
    })

    if (employeeDetails && employeeDetails[0].companyName) {
        page2.drawText(`บริษัท ${employeeDetails[0].companyName}`, {
            x: 80,
            y: page2.getHeight() - 50,
            color: rgb(0, 0, 0),
            size: 16,
            font: font
        })
    }

    page2.drawText(`ช่วงเวลา:`, {
        x: 40,
        y: page2.getHeight() - 80,
        color: rgb(0, 219 / 255, 153 / 255),
        size: 16,
        font: font
    })

    page2.drawText(`01 ${selectedMonthName} ${selectYear} - ${selectedMonthName.endsWith("คม") ? 31 : selectedMonthName.endsWith("พันธ์") ? 28 : 30} ${selectedMonthName} ${selectYear}`, {
        x: 80,
        y: page2.getHeight() - 80,
        color: rgb(0, 0, 0),
        size: 16,
        font: font
    })

    const columns = ["ลำดับที่", "รหัสบริษัท", "คำนำหน้าชื่อ", "ชื่อ", "นามสกุล", "วันที่ยื่น", "เงินเดือน", "เงินสมทบ (พนักงาน)", "เงินสมทบ (บริษัท)", "รวม"];
    const tableTop = page2.getHeight() - 120;
    const columnWidth = (page2.getWidth() - 80) / columns.length;

    // Function to draw column headers
    const drawColumnHeaders = (currentPage) => {
        columns.forEach((column, index) => {
            const x = 40 + index * columnWidth;
            const y = tableTop;
            const cellWidth = columnWidth;
            const cellHeight = 20;

            currentPage.drawRectangle({
                x: x,
                y: y,
                width: cellWidth,
                height: cellHeight,
                color: rgb(1, 1, 1),
                borderColor: rgb(0, 0, 0),
                borderWidth: 1,
            });

            currentPage.drawText(column, {
                x: x + 4,
                y: y + 5,
                color: rgb(0, 0, 0),
                size: 14,
                font: font,
            });
        });
    };

    const itemsPerPage = 20;
    let pageIndex = 0;

    if (employeeDetails) {
        for (let itemIndex = 0; itemIndex < employeeDetails.length; itemIndex++) {
            if (itemIndex % itemsPerPage === 0) {
                // Move to the next page using the template pdfDoc3
                const [newPageTemplate] = await pdfDoc.copyPages(pdfDoc, [1]);
                pdfDoc.addPage(newPageTemplate);
                pageIndex++;

                // Draw column headers for the new page
                drawColumnHeaders(pdfDoc.getPages()[pageIndex]);
            }
            const currentPage = pdfDoc.getPages()[pageIndex];
            const item = employeeDetails[itemIndex];
            // const valueItem = employeeDetails.companyValue[itemIndex];

            const thaiFormattedDate = item.payDate ? dayjs(item.payDate).locale('th').format('DD MMMM') : "-";
            const thaiFormattedYear = item.payDate ? dayjs(item.payDate).format('YYYY') : "-";
            const thaiYear = parseInt(thaiFormattedYear) + 543;

            // const formatter = new Intl.DateTimeFormat('th-TH', { day: '2-digit', month: '2-digit', year: 'numeric' });
            // const formattedDate = formatter.format(payDate);

            // Calculate the page and row index separately
            const pageNumber = Math.floor(itemIndex / itemsPerPage) + 1;
            const rowIndexOnPage = itemIndex % itemsPerPage;

            const rowData = [
                (itemIndex + 1) !== null && (itemIndex + 1) !== undefined ? (itemIndex + 1).toString() : "",
                item.companyCode !== null && item.companyCode !== undefined ? item.companyCode : "",
                item.title !== null && item.title !== undefined ? item.title : "",
                item.firstname !== null && item.firstname !== undefined ? item.firstname : "",
                item.lastname !== null && item.lastname !== undefined ? item.lastname : "",
                item.payDate !== null && item.payDate !== undefined ? thaiFormattedDate + " " + thaiYear : "-",
                item.salary !== null && item.salary !== undefined ? item.salary.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00,
                item.value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                item.companyValue && item.companyValue ? item.companyValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00,
                (item.salary + item.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            ];

            rowData.forEach((cell, columnIndex) => {
                const x = 40 + columnIndex * columnWidth;
                const y = tableTop - (rowIndexOnPage + 1) * 20; // Use rowIndexOnPage instead of currentRow
                const cellWidth = columnWidth;
                const cellHeight = 20;

                currentPage.drawRectangle({
                    x: x,
                    y: y,
                    width: cellWidth,
                    height: cellHeight,
                    color: rgb(1, 1, 1),
                    borderColor: rgb(0, 0, 0),
                    borderWidth: 1,
                });

                if (cell !== null && cell !== undefined) {
                    currentPage.drawText(cell.toString(), {
                        x: x + 5,
                        y: y + 5,
                        color: rgb(0, 0, 0),
                        size: 14,
                        font: font,
                    });
                }
            });
        }
    }

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
};