import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import { getEmployeeProfile } from "../../../../../../actions/employee";
import { updateUser } from "../../../../../../actions/user";

import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const { open, handleCloseDrawer, setStatusAlert } = props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    contractTermainatoinDate: new Date(),
    reasonTerminate: "",
    otherReasonTerminate: "",
    blacklist: false,
  });

  useEffect(() => {
    if (employeeProfile) {
      setFormData({
        idEmp: employeeProfile.idEmp,
        contractTermainatoinDate: employeeProfile.contractTermainatoinDate || new Date(),
        reasonTerminate: employeeProfile.reasonTerminate || "",
        otherReasonTerminate: employeeProfile.otherReasonTerminate || "",
        blacklist: !!(employeeProfile.blacklist) || false,
      });
    }
  }, [employeeProfile]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "blacklist") {
      setFormData({
        ...formData,
        [name]: event.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    const data = { ...formData };
    data.contractTermainatoinDate = data.contractTermainatoinDate
      ? dayjs(data.contractTermainatoinDate).format("YYYY-MM-DD")
      : null;

    console.log("data : ", data);

    const result = await dispatch(updateUser(data));

    if (result && result.status === 200) {
      handleCloseDrawer();
      setStatusAlert({
        isOpen: true,
        type:"success",
        label:"แก้ไขข้อมูลลาออกสำเร็จ"
      });
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
    } else {
      handleCloseDrawer();
      setStatusAlert({
        isOpen: false,
        type:"error",
        label:"แก้ไขข้อมูลลาออกไม่สำเร็จ"
      });
    }
  };

  return (
    <DrawerCustom
      title={`${t("Add")} ${t("TerminatedInfo")}`}
      anchor={"right"}
      open={open}
      onClose={handleCloseDrawer}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledContentLabel gutterBottom>
              {t("EffectiveDate")}
            </StyledContentLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
              <DatePicker
                value={formData.contractTermainatoinDate}
                inputFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    ["contractTermainatoinDate"]: date,
                  });
                }}
                renderInput={(params) => (
                  <StyledTextField fullWidth {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel gutterBottom>{t("Reason")}</StyledContentLabel>
            <StyledFormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="reasonTerminate"
                value={formData.reasonTerminate}
                onChange={handleInputChange}
                displayEmpty
              >
                <MenuItem value={""} disabled></MenuItem>
                <MenuItem value={"หมดสัญญา"}>{t("OutOfContract")}</MenuItem>
                <MenuItem value={"ได้งานใหม่"}>{t("GetNewJob")}</MenuItem>
                <MenuItem value={"ทำธรุกิจส่วนตัว"}>
                  {t("PersonalBusiness")}
                </MenuItem>
                <MenuItem value={"เหตุผลส่วนตัว"}>
                  {t("PersonalReasons")}
                </MenuItem>
                <MenuItem value={"ให้ออก"}>{t("LayOff")}</MenuItem>
              </Select>
            </StyledFormControl>
          </Grid>
          <Grid item xs={12}>
            <StyledContentLabel gutterBottom>
              {t("OtherReason")}
            </StyledContentLabel>
            <StyledTextField
              name="otherReasonTerminate"
              value={formData.otherReasonTerminate}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.blacklist}
                    name="blacklist"
                    onChange={handleInputChange}
                  />
                }
                label="Blacklist"
              />
            </FormGroup>
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDrawer}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
