import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import utils from "../../../../utils";
import { Box } from "@mui/material";

const rand36 = () => {
  return Math.floor(Math.random() * 4);
};

function createData(status, name, created, start, stop, ot1, ot15, ot2, ot3) {
  return {
    status,
    name,
    created,
    start,
    stop,
    ot1,
    ot15,
    ot2,
    ot3,
  };
}

const rows = [
  createData(
    0,
    "วันหยุด",
    new Date(2021, 10, 10, 14, 46, 51),
    new Date(2021, 10, 10, 16, 30, 0),
    new Date(2021, 10, 10, 20, 30, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 11, 14, 46, 51),
    new Date(2021, 10, 11, 16, 30, 0),
    new Date(2021, 10, 11, 20, 0, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    2,
    "วันทำงาน",
    new Date(2021, 10, 12, 14, 46, 51),
    new Date(2021, 10, 12, 16, 30, 0),
    new Date(2021, 10, 12, 18, 45, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันหยุด",
    new Date(2021, 10, 13, 14, 46, 51),
    new Date(2021, 10, 13, 0, 0, 0),
    new Date(2021, 10, 13, 0, 0, 0),
    0,
    0,
    0,
    0,
    0
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 14, 14, 46, 51),
    new Date(2021, 10, 14, 16, 30, 0),
    new Date(2021, 10, 14, 19, 0, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันทำงาน",
    new Date(2021, 10, 15, 14, 46, 51),
    new Date(2021, 10, 15, 16, 30, 0),
    new Date(2021, 10, 15, 20, 30, 0),
    rand36(),
    rand36(),
    rand36(),
    rand36()
  ),
  createData(
    1,
    "วันหยุด",
    new Date(2021, 10, 16, 15, 46, 51),
    new Date(2021, 10, 16, 0, 0, 0),
    new Date(2021, 10, 16, 0, 0, 0),
    0,
    0,
    0,
    0
  ),
];

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
    padding: "8px 0",
  },
  "& .tableCellHead:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderColor: "#919eab3d",
    padding: "8px 0",
  },
  "& .tableCellSummary": {
    borderTop: "5px solid #919eab3d",
    borderBottom: "none",
  },
  "& .cellSummaryBase": {
    backgroundColor: "#007afe",
    border: "none",
    "&.first": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&.last": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
  "& .cellStatusAction": {
    width: 80,
  },
  "& .cellRequest": {
    width: 100,
  },
  "& .cellStartStop": {
    width: 150,
  },
  "& .cellNumber": {
    textAlign: "center",
    width: 95,
  },
  "& .cellSummary": {
    fontSize: 28,
  },
  "& .cellOver": {
    color: "#e46a76",
    fontWeight: 600,
  },
  "& .cellBaht": {
    fontSize: 14,
    color: "#999999",
  },
  "& .cellBahtSum": {
    fontSize: 14,
    color: "#ffffff",
  },
  "& .tableCellBlank": {
    padding: 4,
  },
});

export default function TableOTReport(props) {
  const { reqList } = props;
  const [dataTable, setDataTable] = useState([]);
  const bahtPerHours = 208;

  const sumHoursPerDay = (index) => {
    return (
      rows[index].ot1 + rows[index].ot15 + rows[index].ot2 + rows[index].ot3
    );
  };

  const sumHoursPerDayBaht = (index) => {
    return (
      rows[index].ot1 * bahtPerHours +
      rows[index].ot15 * 1.5 * bahtPerHours +
      rows[index].ot2 * 2 * bahtPerHours +
      rows[index].ot3 * 3 * bahtPerHours
    );
  };

  const sumHours = () => {
    var sum = 0;
    rows.forEach((element, index) => {
      sum = sum + sumHoursPerDay(index);
    });

    return sum;
  };

  const sumHoursBaht = () => {
    var sum = 0;
    rows.forEach((element, index) => {
      sum =
        sum +
        element.ot1 * bahtPerHours +
        element.ot15 * 1.5 * bahtPerHours +
        element.ot2 * 2 * bahtPerHours +
        +(element.ot3 * 3 * bahtPerHours);
    });

    return sum;
  };

  const sumOfTypeOT = (typeOt) => {
    var sum = 0;
    rows.forEach((element) => {
      sum = sum + element[typeOt];
    });

    return sum;
  };

  useEffect(() => {
    setDataTable(reqList !== undefined ? reqList : []);
  }, []);

  return (
    <TableContainer>
      <StyledTable aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={`tableCellHead cellStatusAction`}>
              วันที่
            </TableCell>
            <TableCell className={`tableCellHead cellRequest`}>
              ประเภท
            </TableCell>
            <TableCell className={`tableCellHead cellStartStop`}>
              เริ่มต้น
            </TableCell>
            <TableCell className={`tableCellHead cellStartStop`}>
              สิ้นสุด
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              รวม (ชม.)
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 1 (ชม.)
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 1.5 (ชม.)
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 2 (ชม.)
            </TableCell>
            <TableCell className={`tableCellHead cellNumber`}>
              OT x 3 (ชม.)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reqList.length > 0 ? (
            reqList.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={`tableCell cellStatusAction`}>
                  {dayjs(row.requestTimeStart).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell className={`tableCell cellRequest`}>
                  <div>
                    <Typography color="text.primary">
                      {row.requestTypeName}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className={`tableCell cellStartStop`}>
                  <div>
                    <Typography>
                      {dayjs(row.requestTimeStart).format("HH:mm") === "00:00"
                        ? "-"
                        : dayjs(row.requestTimeStart).format("HH:mm")}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className={`tableCell cellStartStop`}>
                  <div>
                    <Typography>
                      {dayjs(row.requestTimeEnd).format("HH:mm") === "00:00"
                        ? "-"
                        : dayjs(row.requestTimeEnd).format("HH:mm")}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className={`tableCell cellNumber`}>
                  <Typography>{row.sumAllOT}</Typography>
                  <Typography className={`cellBaht`}>
                    = ฿{utils.numberWithCommas(row.valueOT)}
                  </Typography>
                </TableCell>
                <TableCell className={`tableCell cellNumber`}>
                  <Typography>{row.xOne}</Typography>
                  <Typography className={`cellBaht`}>
                    = ฿{utils.numberWithCommas(row.valueSumXOne)}
                  </Typography>
                </TableCell>
                <TableCell className={`tableCell cellNumber`}>
                  <Typography>{row.xOneFive}</Typography>
                  <Typography className={`cellBaht`}>
                    = ฿{utils.numberWithCommas(row.valueSumXOneFive)}
                  </Typography>
                </TableCell>
                <TableCell className={`tableCell cellNumber`}>
                  <Typography>{row.xTwo}</Typography>
                  <Typography className={`cellBaht`}>
                    = ฿{utils.numberWithCommas(row.valueSumXTwo)}
                  </Typography>
                </TableCell>
                <TableCell className={`tableCell cellNumber`}>
                  <Typography>{row.xThree}</Typography>
                  <Typography className={`cellBaht`}>
                    = ฿{utils.numberWithCommas(row.valueSumXThree)}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="center" colSpan="9">
                ไม่มีข้อมูล
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
