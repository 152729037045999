import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Typography, Container, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import EmployeeList from "./employee-list";
import MenuApp from "./menu-app";
import PartnerCompany from "./partner-company";

import Backdrop from "../../shared/general/Backdrop";

import { getAllUsersCompany } from "../../../../actions/user";
import { getVendorContract } from "../../../../actions/company";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapHeader = styled("div")({
  paddingTop: 20,
  paddingBottom: 60,
  ["@media (max-width: 768px)"]: {
    paddingBottom: 30,
  },
});

const HomePageAdmin = () => {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (userProfile) {
      dispatch(getAllUsersCompany(userProfile.idCompany, "all"));
      dispatch(getVendorContract());
    }
  }, [userProfile]);

  return (
    <div>
      {
        <StyledRoot className={`page`}>
          <Backdrop open={!(currentUser && userProfile)} />
          <Container maxWidth="lg">
            {userProfile && (
              <StyledWrapHeader>
                <Typography variant="h4">
                  สวัสดี, {`${userProfile.firstname} ${userProfile.lastname}`}
                </Typography>
              </StyledWrapHeader>
            )}
            <PartnerCompany />
            <EmployeeList />
            <MenuApp />
          </Container>
        </StyledRoot>
      }
    </div>
  );
};

export default HomePageAdmin;
