import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Autocomplete, Drawer, Grid, MenuItem, Stack, styled, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from "react-hook-form";
import DrawerCustom from '../../shared/general/Drawer';
import { getAllCompaniesByVendor, getCompanyAndBranchContract } from '../../../../actions/vendor';
import { SelectAllCompaniesVendor, SelectAllCompanyVendorSections } from './SelectCompanyLists';
import dayjs from 'dayjs';
import DatePickerCustom from '../../shared/date/datePicker';
import ButtonBlue from '../../shared/general/ButtonBlue';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getWorkingTimeReport } from '../../../../actions/report';
import AlertResponse from '../../shared/general/AlertResponse';
import { WorkingTimeXlsxReport } from './xlsx-export/workingTimeXlsxReport';
import { useSelector } from 'react-redux';
import TextFieldTheme from '../../shared/general/TextFieldTheme';

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

export default function WorkingTime(props) {
    const { open, handleClose } = props;
    const { t, i18n } = useTranslation();
    const { control } = useForm({});
    const currentYear = new Date().getFullYear();

    const { result: userProfile } = useSelector((state) => state.userProfile);

    const [companyLists, setCompanyLists] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const [selectedSection, setSelectedSection] = useState({
        idSection: null,
        sectionName: `${t("SelectSection")}`,
    });
    const [selectedDepartment, setSelectedDepartment] = useState({
        idDepartment: null,
        departmentName: `${t("SelectDepartment")}`,
    });
    const [selectedDivision, setSelectedDivision] = useState({
        idDivision: null,
        divisionName: `${t("SelectDivision")}`,
    });

    const today = dayjs().toDate();
    const [sectionList, setSectionList] = useState([]);
    const [divisionList, setDivisionList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [search, setSearch] = useState({
        start: dayjs(today).set("date", 1),
        end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
    });

    const [showSection, setShowSection] = useState(false);
    const [showDepartment, setShowDepartment] = useState(false);
    const [showDivision, setShowDivision] = useState(false);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    useEffect(() => {
        const fetchedCompanyByVendor = async () => {
            try {
                const response = await getAllCompaniesByVendor();
                if (response && response.data) {
                    setCompanyLists(response.data);
                } else {
                    setCompanyLists(null);
                }
            } catch (error) {
                console.error(error);
            };
        };

        fetchedCompanyByVendor();
    }, []);

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
        setSelectedDivision({
            idDivision: null,
            divisionName: `${t("SelectDivision")}`,
        })
        setSelectedDepartment({
            idDepartment: null,
            departmentName: `${t("SelectDepartment")}`,
        })
        setSelectedSection({
            idSection: null,
            sectionName: `${t("SelectSection")}`,
        })
    };

    const onChangeDivision = (newValue) => {
        setSelectedDivision(newValue);
        setSelectedDepartment({
            idDepartment: null,
            departmentName: `${t("SelectDepartment")}`,
        })
        setSelectedSection({
            idSection: null,
            sectionName: `${t("SelectSection")}`,
        })
    };

    const OnChangeDepartment = (value) => {
        setSelectedDepartment(value);
        setSelectedSection({
            idSection: null,
            sectionName: `${t("SelectSection")}`,
        });
    };

    useEffect(() => {
        if (selectedCompany && !(selectedDivision && selectedDivision.idDivision) && !(selectedDepartment && selectedDepartment.idDepartment)) {
            setDivisionList(selectedCompany.division || []);
            setDepartmentList(selectedCompany.department || []);
            setSectionList([{ idSection: "all", sectionName: `${t("All")}` }, ...(selectedCompany.section)]);
        } else if (selectedCompany && (selectedDivision && selectedDivision.idDivision) && !(selectedDepartment && selectedDepartment.idDepartment)) {
            const updatedDepartmentList = selectedCompany.department
                .filter(e => e.idDivision === selectedDivision.idDivision)
                .map(item => ({ ...item }));

            const updatedSectionList = selectedCompany.section
                .filter(e => e.idDivision === selectedDivision.idDivision)
                .map(item => ({ ...item }));

            setDepartmentList(updatedDepartmentList || []);
            setSectionList([{ idSection: "all", sectionName: `${t("All")}` }, ...(updatedSectionList)]);
        } else if (selectedCompany && (selectedDepartment && selectedDepartment.idDepartment) && !(selectedDivision && selectedDivision.idDivision)) {
            const updatedSectionList = selectedCompany.section
                .filter(e => e.idDepartment === selectedDepartment.idDepartment)
                .map(item => ({ ...item }));

            setSectionList([{ idSection: "all", sectionName: `${t("All")}` }, ...updatedSectionList]);
        } else if (selectedCompany && (selectedDivision && selectedDivision.idDivision) && (selectedDepartment && selectedDepartment.idDepartment)) {
            const updatedSectionList = selectedCompany.section
                .filter(e => e.idDepartment === selectedDepartment.idDepartment)
                .map(item => ({ ...item }));

            setSectionList([{ idSection: "all", sectionName: `${t("All")}` }, ...updatedSectionList]);
        }
    }, [selectedCompany, selectedDepartment, selectedDivision]);

    useEffect(() => {
        if (userProfile && (showSection === false)) {
            setSelectedDepartment(null);
        }
    }, [userProfile, showSection]);

    useEffect(() => {
        if (userProfile && (showDepartment === false)) {
            setSelectedDivision(null);
        }
    }, [userProfile, showDepartment]);

    const message = (showDivision && !(showDepartment))
        ? t("NoSelectedDivisionData")
        : (showDepartment && !showSection)
            ? t("NoSelectedDepartmentData")
            : (showSection)
                ? t("NoSelectedSectionData")
                : t("PleaseEnterDetails");

    const handleDownloadFile = async () => {
        try {
            setIsFetching(true);
            const response = await getWorkingTimeReport({
                start: dayjs(search.start).format("YYYY-MM-DD"),
                end: dayjs(search.end).format("YYYY-MM-DD"),
                idCompany: (selectedCompany && selectedCompany.idCompany) || null,
                idDivision: (selectedDivision && selectedDivision.idDivision) || null,
                idDepartment: (selectedDepartment && selectedDepartment.idDepartment) || null,
                idSection: (selectedSection && selectedSection.idSection) || null,
                idEmp: userProfile && userProfile.idEmp
            });
            if (response && response.data && response.data.result && response.data.result.length > 0) {
                setIsFetching(false);
                WorkingTimeXlsxReport(t, response.data.result, response.data.AllLeavesTypeResult, response.data.LeaveEmployeeResult, search);
            } else {
                handleChangeAlertType(message);
                handleOpenAlert(true);
                setIsFetching(false);
            }
        } catch (error) {
            console.error(error);
            handleChangeAlertType(message);
            handleOpenAlert(true);
            setIsFetching(false);
        }
    };

    return (
        <DrawerCustom
            title={`${t("workingTimeReport")}`}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">{t("SelectCompany")}</Typography>
                        <SelectAllCompaniesVendor
                            options={companyLists ? companyLists : null}
                            value={selectedCompany}
                            disabled={isFetching || !companyLists}
                            onChange={(_, value) => {
                                onChangeCompany(value);
                            }}
                        />
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">Sections</Typography>
                        <SelectAllCompanyVendorSections
                            options={selectedCompany && selectedCompany.sections ? selectedCompany.sections : null}
                            value={selectedSection}
                            disabled={isFetching || !companyLists || !selectedCompany}
                            onChange={(_, value) => {
                                onChangeCompany(value);
                            }}
                        />
                    </Grid> */}

                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("StartDate")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                                inputFormat="DD/MM/YYYY"
                                value={search.start}
                                name="start"
                                disabled={isFetching}
                                onChange={(newValue) => {
                                    setSearch({ ...search, ["start"]: newValue });
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("EndDate")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                minDate={search.start}
                                inputFormat="DD/MM/YYYY"
                                value={search.end}
                                name="end"
                                disabled={isFetching}
                                onChange={(newValue) => {
                                    setSearch({ ...search, ["end"]: newValue });
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                        {t("ChooseField")}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <ButtonBlue
                                variant={showDivision ? "contained" : "outlined"}
                                fullWidth
                                onClick={() => {
                                    setSelectedDivision(null);
                                    setShowDivision(prevState => !prevState);
                                }}
                                disabled={showDepartment || isFetching || (divisionList && divisionList.length < 1)}
                            >
                                {t("Division")}
                            </ButtonBlue>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonBlue
                                variant={showDepartment ? "contained" : "outlined"}
                                fullWidth
                                onClick={() => {
                                    setSelectedDepartment(null);
                                    setShowDepartment(prevState => !prevState);
                                }}
                                disabled={showSection || isFetching}
                            >
                                {t("Department")}
                            </ButtonBlue>
                        </Grid>
                        <Grid item xs={12}>
                            <ButtonBlue
                                variant={showSection ? "contained" : "outlined"}
                                fullWidth
                                onClick={() => {
                                    setSelectedSection(null);
                                    setShowSection(prevState => !prevState);
                                }}
                                disabled={!showDepartment || isFetching}
                            >
                                {t("Section")}
                            </ButtonBlue>
                        </Grid>
                    </Grid>
                </Grid>

                {showDivision && (
                    <Grid item xs={12}>
                        <Typography
                            color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px" marginTop="8px"
                        >
                            {t("Division")}
                        </Typography>
                        <Autocomplete
                            options={[
                                ...divisionList,
                            ]}
                            getOptionLabel={(option) =>
                                i18n.resolvedLanguage === "th" ?
                                    (option.divisionName ? option.divisionName : option.divisionName_EN)
                                    : (option.divisionName_EN ? option.divisionName_EN : option.divisionName)
                            }
                            // filterOptions={filterOptions}
                            renderOption={(props, option) => (
                                <MenuItem {...props} key={option.idDivision}>
                                    <Box>
                                        <Typography>
                                            {
                                                option.divisionName === null
                                                    ? `${t("NoDivision")}`
                                                    : i18n.resolvedLanguage === "th" ?
                                                        (option.divisionName ? option.divisionName : option.divisionName_EN)
                                                        : (option.divisionName_EN ? option.divisionName_EN : option.divisionName)
                                            }
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            )}
                            renderInput={(params) => (
                                <TextFieldTheme
                                    {...params}
                                    variant="filled"
                                />
                            )}
                            value={selectedDivision}
                            disableClearable
                            disabled={isFetching || (divisionList && divisionList.length < 1) || !selectedCompany}
                            onChange={(_, value) => {
                                onChangeDivision(value)
                            }}
                        />
                    </Grid>
                )}

                {showDepartment && (
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px" marginTop="8px">
                            {t("Department")}
                        </Typography>
                        <Autocomplete
                            options={[...departmentList]}
                            getOptionLabel={(option) =>
                                option.departmentName === null
                                    ? `${t("NoDepartment")}`
                                    : `${option.departmentName}`
                            }
                            renderOption={(props, option) => (
                                <MenuItem {...props} key={option.idDepartment}>
                                    <Box>
                                        <Typography>
                                            {option.departmentName === null
                                                ? `${t("NoDepartment")}`
                                                : i18n.resolvedLanguage === "th"
                                                    ? option.departmentName
                                                    : option.departmentName_EN}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            )}
                            renderInput={(params) => (
                                <TextFieldTheme
                                    {...params}
                                    variant="filled"
                                />
                            )}
                            value={selectedDepartment}
                            disableClearable
                            disabled={isFetching || (showDivision && showDepartment && !(selectedDivision && selectedDivision.idDivision)) || !selectedCompany}
                            onChange={(_, value) => {
                                OnChangeDepartment(value)
                            }}
                        />
                    </Grid>
                )}

                {showSection && (
                    <Grid item xs={12}>
                        <Typography
                            color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px" marginTop="8px"
                        >
                            {t("Section")}
                        </Typography>
                        <Autocomplete
                            options={[...sectionList]}
                            getOptionLabel={(option) =>
                                option.sectionName === null
                                    ? `${t("NoSection")}`
                                    : `${option.sectionName}`
                            }
                            renderOption={(props, option) => (
                                <MenuItem {...props} key={option.idSection}>
                                    <Box>
                                        <Typography>
                                            {option.sectionName === null
                                                ? `${t("NoSection")}`
                                                : i18n.resolvedLanguage === "th"
                                                    ? option.sectionName
                                                    : option.sectionName_EN}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            )}
                            renderInput={(params) => (
                                <TextFieldTheme
                                    {...params}
                                    variant="filled"
                                />
                            )}
                            value={selectedSection}
                            disableClearable
                            disabled={isFetching || (showDepartment && !(selectedDepartment && selectedDepartment.idDepartment)) || !selectedCompany}
                            onChange={(_, value) => {
                                setSelectedSection(value)
                            }}
                        />
                    </Grid>
                )}

                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>
                        {t("Cancel")}
                    </ButtonBlue>

                    {isFetching ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <ButtonBlue
                                variant="outlined"
                                startIcon={<DownloadRoundedIcon />}
                                onClick={() => handleDownloadFile()}
                                disabled={isFetching}
                            >
                                {t("Download")}
                            </ButtonBlue>
                        </>
                    )}
                </StyledFooter>
            </StyledRoot>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom >
    );
}