import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Drawer, Grid, Stack, styled, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from "react-hook-form";
import DrawerCustom from '../../shared/general/Drawer';
import { getAllCompaniesByVendor, getCompanyAndBranchContract } from '../../../../actions/vendor';
import { SelectAllCompaniesVendor, SelectAllCompanyVendorSections } from './SelectCompanyLists';
import dayjs from 'dayjs';
import DatePickerCustom from '../../shared/date/datePicker';
import ButtonBlue from '../../shared/general/ButtonBlue';
import CircularProgress from '@mui/material/CircularProgress';
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { getWorkingTimeReport } from '../../../../actions/report';
import AlertResponse from '../../shared/general/AlertResponse';
import { AttendanceXlsxReport } from './xlsx-export/attendanceXlsxReport';
import { AttendanceXlsxReportByDepartment } from './xlsx-export/attendanceDepartmentXlsxReport';

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

export default function AttendanceByDepartment(props) {
    const { open, handleClose } = props;
    const { t, i18n } = useTranslation();
    const { control } = useForm({});
    const currentYear = new Date().getFullYear();
    const [companyLists, setCompanyLists] = useState(null);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const today = dayjs().toDate();
    const [search, setSearch] = useState({
        start: dayjs(today).set("date", 1),
        end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0))
    });

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const fetchedCompanyByVendor = async () => {
        try {
            const response = await getAllCompaniesByVendor();
            if (response && response.data) {
                setCompanyLists(response.data);
            } else {
                setCompanyLists(null);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchedCompanyByVendor();
    }, []);

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const handleDownloadFile = async () => {
        try {
            setIsFetching(true);
            const response = await getWorkingTimeReport({
                start: dayjs(search.start).format("YYYY-MM-DD"),
                end: dayjs(search.end).format("YYYY-MM-DD"),
                idCompany: selectedCompany && selectedCompany.idCompany,
                idSection: "all",
            });
            if (response && response.data && response.data.aggregatedData && response.data.aggregatedData.length > 0) {
                setIsFetching(false);
                AttendanceXlsxReportByDepartment(response.data.aggregatedData, response.data.AllLeavesTypeResult, search.start, search.end);
            } else {
                handleChangeAlertType(t("NoData"));
                handleOpenAlert(true);
                setIsFetching(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <DrawerCustom
            title={`${t("SummaryTimeStampDepartment")}`}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">{t("Company")}</Typography>
                        <SelectAllCompaniesVendor
                            options={companyLists ? companyLists : null}
                            value={selectedCompany}
                            disabled={isFetching || !companyLists}
                            onChange={(_, value) => {
                                onChangeCompany(value);
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("StartDate")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                                inputFormat="DD/MM/YYYY"
                                value={search.start}
                                name="start"
                                disabled={isFetching}
                                onChange={(newValue) => {
                                    setSearch({ ...search, ["start"]: newValue });
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("EndDate")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                minDate={search.start}
                                inputFormat="DD/MM/YYYY"
                                value={search.end}
                                name="end"
                                disabled={isFetching}
                                onChange={(newValue) => {
                                    setSearch({ ...search, ["end"]: newValue });
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={() => {
                        handleClose();
                        setSelectedCompany(null);
                    }}>
                        {t("Cancel")}
                    </ButtonBlue>

                    {isFetching ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <ButtonBlue
                                variant="outlined"
                                startIcon={<DownloadRoundedIcon />}
                                onClick={() => handleDownloadFile()}
                                disabled={isFetching || !(selectedCompany && selectedCompany.idCompany)}
                            >
                                {t("Download")}
                            </ButtonBlue>
                        </>
                    )}
                </StyledFooter>
            </StyledRoot>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom >
    );
}