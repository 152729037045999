import React, { useCallback, useEffect, useState } from "react";
import { Box, Breadcrumbs, Container, Grid, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import DatePickerCustom from "../../../shared/date/datePicker";
import CardStyle from "../../../shared/general/Card";
import DataGrid, { Button, Column, Export, FilterRow, HeaderFilter, LoadPanel, Paging, Scrolling, SearchPanel, Selection, Sorting } from "devextreme-react/data-grid";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import TableAll from "./tableAll";
import TableIndividual from "./tableIndividual";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from "react-router-dom";
import { getAllCompaniesByVendor } from "../../../../../actions/vendor";
import { SelectAllCompaniesVendor } from "../SelectCompanyLists";
import { getSummaryOvertimeShift } from "../../../../../actions/report";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .search-date-container": {
    marginTop: "24px",
    display: "flex",
    flexWrap: "nowrap",
    gap: "16px",
    "& .search-date-box": {
      maxWidth: "350px",
      width: "100%",
    },
    "& .search-button": {
      marginTop: "29px",
      display: "flex",
      alignItems: "center",
    },
  },

  ["@media only screen and (max-width: 600px)"]: {
    "& .search-date-container": {
      flexDirection: "column",
      "& .search-date-box": {
        maxWidth: "100%",
      },
      "& .search-button": {
        marginTop: "0",
        width: "100%",
      },
    }
  }
})

const StyledWrapDataGrid = styled(Box)({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },

  '& .column-woring-hours[role="columnheader"]': {
    backgroundColor: "#FFFF00",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
});


const SummaryOTShift = () => {
  const { t, i18n } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [companyLists, setCompanyLists] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [ReportSummaryOvertimeShiftList, setReportSummaryOvertimeShiftList] = useState(null);

  const [filter, setFilter] = useState({
    start: dayjs().set('date', 1).toDate(),
    end: dayjs().set('date', dayjs().daysInMonth()).toDate(),
    mode: "all",
  });

  useEffect(() => {
    const fetchedCompanyByVendor = async () => {
      try {
        const response = await getAllCompaniesByVendor();
        if (response && response.data) {
          setCompanyLists(response.data);
        } else {
          setCompanyLists(null);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchedCompanyByVendor();
  }, []);

  const fetchData = useCallback(async (params) => {
    try {
      setIsFetching(true);
      const response = await getSummaryOvertimeShift(params);
      if (response && response.data) {
        setReportSummaryOvertimeShiftList(response.data);
      } else {
        setReportSummaryOvertimeShiftList(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      const params = {
        idCompany: selectedCompany.idCompany,
        start: dayjs(filter.start).format("YYYY-MM-DD"),
        end: dayjs(filter.end).format("YYYY-MM-DD"),
      };

      if (filter.mode !== "all") {
        params.idEmp = filter.idEmp;
      }

      fetchData(params);
    }
  }, [selectedCompany, filter, fetchData]);

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  const handleClickSearch = useCallback(() => {
    if (!selectedCompany) return;

    const params = {
      idCompany: selectedCompany.idCompany,
      start: dayjs(filter.start).format("YYYY-MM-DD"),
      end: dayjs(filter.end).format("YYYY-MM-DD"),
    };

    if (filter.mode !== "all") {
      params.idEmp = filter.idEmp;
    }

    fetchData(params);
  }, [selectedCompany, filter, fetchData]);

  const handleClickIndividual = useCallback((selected) => {
    const idEmp = selected.row.data.idEmp;
    setFilter(prev => ({
      ...prev,
      mode: "individual",
      idEmp: idEmp,
    }));

    fetchData({
      idEmp: idEmp,
      idCompany: selectedCompany.idCompany,
      start: dayjs(filter.start).format("YYYY-MM-DD"),
      end: dayjs(filter.end).format("YYYY-MM-DD"),
    });
  }, [selectedCompany, filter.start, filter.end, fetchData]);

  useEffect(() => {
    if (companyLists && companyLists.length > 0) {
      setSelectedCompany(companyLists[0])
    }
  }, [companyLists]);

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ paddingTop: "24px", paddingBottom: "24px" }}
      >
        <Box marginBottom="24px">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/report-center"}
            >
              {`${t("AllReports")}`}
            </Link>
            <Typography color="text.primary">{`${t("SummaryOTandShift")}`}</Typography>
          </Breadcrumbs>
        </Box>

        <Typography variant="h4">{t("SummaryOTandShift")}</Typography>

        <Box marginTop="24px">
          <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("Company")}</Typography>
          <SelectAllCompaniesVendor
            options={companyLists ? companyLists : null}
            value={selectedCompany}
            disabled={isFetching || !companyLists || filter.mode === "individual"}
            onChange={(_, value) => {
              onChangeCompany(value);
            }}
          />
        </Box>

        <Box className="search-date-container">
          <Box className="search-date-box">
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("StartDate")}</Typography>
            <DatePickerCustom
              inputFormat="D MMM YYYY"
              value={filter.start}
              views={["year", "month", "day"]}
              onChange={(newValue) => {
                setFilter(prev => ({
                  ...prev,
                  start: newValue
                }));
              }}
            />
          </Box>
          <Box className="search-date-box">
            <Typography fontWeight="600" fontSize="14px" paddingBottom="8px" color="text.third">{t("EndDate")}</Typography>
            <DatePickerCustom
              inputFormat="D MMM YYYY"
              value={filter.end}
              views={["year", "month", "day"]}
              onChange={(newValue) => {
                setFilter(prev => ({
                  ...prev,
                  end: newValue
                }));
              }}
            />
          </Box>
          <Box className="search-button">
            <ButtonBlue
              startIcon={<SearchRoundedIcon />}
              variant="contained"
              fullWidth
              onClick={handleClickSearch}
              disabled={!selectedCompany}
            >
              {t("Search")}
            </ButtonBlue>
          </Box>
        </Box>

        {filter.mode === "individual" && (
          <Box marginTop="24px">
            <ButtonBlue
              variant="text"
              startIcon={<ArrowBackIosNewOutlinedIcon />}
              onClick={() => {
                setFilter(prev => ({
                  ...prev,
                  mode: "all"
                }))
              }}
            >
              {t('Back')}
            </ButtonBlue>
          </Box>
        )}

        <Box marginTop="24px">
          <CardStyle>
            <Box padding="24px">
              <StyledWrapDataGrid>
                {filter.mode === "all" && (
                  <TableAll
                    handleClickIndividual={handleClickIndividual}
                    ReportSummaryOvertimeShiftList={ReportSummaryOvertimeShiftList}
                    isFetching={isFetching}
                  />
                )}
                {filter.mode === "individual" && (
                  <TableIndividual
                    ReportSummaryOvertimeShiftIndividual={ReportSummaryOvertimeShiftList}
                    isFetching={isFetching}
                  />
                )}
              </StyledWrapDataGrid>
            </Box>
          </CardStyle>
        </Box>
      </Container>
    </StyledRoot>
  )
}

export default SummaryOTShift;