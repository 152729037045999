import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogConfirm from "../../../../shared/general/DialogConfirm";
import AlertResponse from "../../../../shared/general/AlertResponse";

import { resetPassword } from "../../../../../../actions/auth";

const StyledRoot = styled("div")({
  height: 100,
});

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleComfirm = async () => {
    const result = await dispatch(
      resetPassword({ idUsers: employeeProfile.idUsers })
    );
    if (result && result.status) {
      handleCloseDialog();
      setAlertType("success");
      handleOpenAlert();
    } else {
      handleCloseDialog();
      setAlertType("error");
      handleOpenAlert();
    }
  };
  return (
    <StyledRoot>
      <ButtonBlue variant="contained" onClick={handleOpenDialog}>
        {t("ResetPass")}
      </ButtonBlue>
      <DialogConfirm
        open={open}
        handleClose={handleCloseDialog}
        text={t("ResetPass")}
        handleComfirm={handleComfirm}
      />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
};

export default ResetPassword;
