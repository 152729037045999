import React, { useEffect } from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DrawerCustom from "../../shared/general/Drawer";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerCustom from "../../shared/date/datePicker";
import TimePicker from "../../shared/general/TimePicker";
import dayjs from "dayjs";
import NumberFormatTheme from "../../shared/general/NumberFormatTheme";
import { addEmployeesSpareContract, getContractEmployeesByDaily, updateEmployeesSpareContract } from "../../../../actions/contract-employees";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  paddingBottom: "8px"
});

const DialogAddOrEditEmployeeSpareContract = (props) => {

  const { drawerConfig, handleClose } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const validateYupSchema = yup.object({
    firstname: yup.string().required(`${t("ThisFieldIsRequired")}`),
    lastname: yup.string().required(`${t("ThisFieldIsRequired")}`),
    mobilePhone: yup.string().required(`${t("ThisFieldIsRequired")}`),
    startDate: yup.date().nullable().required(`${t("ThisFieldIsRequired")}`).test("greaterOrEqualStartDate", t("CantSelectEndDateBeforeStartDate"), (value, context) => {
      return !context.parent.endDate || dayjs(value).isSameOrBefore(dayjs(context.parent.endDate))
    }),
    endDate: yup.date().nullable().required(`${t("ThisFieldIsRequired")}`).test("greaterOrEqualStartDate", t("CantSelectEndDateBeforeStartDate"), (value, context) => {
      return !context.parent.startDate || dayjs(value).isSameOrAfter(dayjs(context.parent.startDate))
    }),
    startHour: yup.string().required(`${t("ThisFieldIsRequired")}`),
    startMinute: yup.string().required(`${t("ThisFieldIsRequired")}`),
    endHour: yup.string().required(`${t("ThisFieldIsRequired")}`),
    endMinute: yup.string().required(`${t("ThisFieldIsRequired")}`),

    validClockInClockOutDate: yup.mixed().nullable().test("greaterOrEqualCockIn", "ไม่สามารถเลือกเวลาออกก่อนเวลาเข้าได้", (_, context) => {
      return (!context.parent.startDate || !context.parent.startHour || !context.parent.startMinute || !context.parent.endDate || !context.parent.endHour || !context.parent.endMinute
        || dayjs(dayjs(context.parent.endDate).set('hour', context.parent.endHour).set('minute', context.parent.endMinute).set('second', 0).format("YYYY-MM-DD HH:mm:ss")).isSameOrAfter(dayjs(dayjs(context.parent.startDate).set('hour', context.parent.startHour).set('minute', context.parent.startMinute).set('second', 0).format("YYYY-MM-DD HH:mm:ss")))
      )
    })
  });

  const useHookForm = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      mobilePhone: "",
      startDate: null,
      startHour: "",
      startMinute: "",
      endDate: null,
      endHour: "",
      endMinute: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })
  
  useEffect(() => {
    if(drawerConfig.isOpen){

      if(drawerConfig.isEdit){

        useHookForm.clearErrors();

        useHookForm.setValue("firstname", drawerConfig.data.firstname === null? "": drawerConfig.data.firstname, {shouldDirty: false, shouldTouch: false, shouldValidate: false});
        useHookForm.setValue("lastname", drawerConfig.data.lastname === null? "": drawerConfig.data.lastname, {shouldDirty: false, shouldTouch: false, shouldValidate: false});
        useHookForm.setValue("mobilePhone", drawerConfig.data.mobilePhone === null? "": drawerConfig.data.mobilePhone, {shouldDirty: false, shouldTouch: false, shouldValidate: false});

        useHookForm.setValue("startDate", drawerConfig.data.startDate === null? null: new Date(dayjs(drawerConfig.data.startDate).format("YYYY-MM-DD")), {shouldDirty: false, shouldTouch: false, shouldValidate: false});
        useHookForm.setValue("startHour", drawerConfig.data.startDate === null? "": dayjs(drawerConfig.data.startDate).format("H"), {shouldDirty: false, shouldTouch: false, shouldValidate: false});
        useHookForm.setValue("startMinute", drawerConfig.data.startDate === null? "": dayjs(drawerConfig.data.startDate).format("m"), {shouldDirty: false, shouldTouch: false, shouldValidate: false});

        useHookForm.setValue("endDate", drawerConfig.data.endDate === null? null: new Date(dayjs(drawerConfig.data.endDate).format("YYYY-MM-DD")), {shouldDirty: false, shouldTouch: false, shouldValidate: false});
        useHookForm.setValue("endHour", drawerConfig.data.endDate === null? "": dayjs(drawerConfig.data.endDate).format("H"), {shouldDirty: false, shouldTouch: false, shouldValidate: false});
        useHookForm.setValue("endMinute", drawerConfig.data.endDate === null? "": dayjs(drawerConfig.data.endDate).format("m"), {shouldDirty: false, shouldTouch: false, shouldValidate: false});

      } else {
        useHookForm.reset(useHookForm.formState.defaultValues, { keepErrors: false, keepDirty: false, keepTouched: false })
      }
    }
  }, [drawerConfig.isOpen])

  const onSubmit = async (data) => {

    console.log(data)

    let result = null;

    if(drawerConfig.isEdit){

      const formData = {
        firstname: data.firstname? data.firstname: null,
        lastname: data.lastname? data.lastname: null,
        mobilePhone: data.mobilePhone? data.mobilePhone: null,
        startDate: dayjs(data.startDate).set('hour', data.startHour).set('minute', data.startMinute).set('second', 0).format("YYYY-MM-DD HH:mm:ss"),
        endDate: dayjs(data.endDate).set('hour', data.endHour).set('minute', data.endMinute).set('second', 0).format("YYYY-MM-DD HH:mm:ss"),
      }
  
      result = await dispatch(updateEmployeesSpareContract(drawerConfig.data.idEmployeesSpareContract, formData));

    } else {
      const formData = {
        firstname: data.firstname? data.firstname: null,
        lastname: data.lastname? data.lastname: null,
        mobilePhone: data.mobilePhone? data.mobilePhone: null,
        startDate: dayjs(data.startDate).set('hour', data.startHour).set('minute', data.startMinute).set('second', 0).format("YYYY-MM-DD HH:mm:ss"),
        endDate: dayjs(data.endDate).set('hour', data.endHour).set('minute', data.endMinute).set('second', 0).format("YYYY-MM-DD HH:mm:ss"),
        idContractEmployees: drawerConfig.data.idContractEmployees,
        workingDate: drawerConfig.data.workingDate,
      }
  
      result = await dispatch(addEmployeesSpareContract(formData));
    }

    if(result && result.status === 200) {
      handleClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getContractEmployeesByDaily(drawerConfig.data.idContractEmployees, dayjs(drawerConfig.data.workingDate).format("YYYY-MM-DD")));
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }

  }

  return (
    <DrawerCustom
        title={drawerConfig.isEdit? t("EditTemporaryReserveEmployee"): t("AddTemporaryReserveEmployee")}
        anchor={"right"}
        open={drawerConfig.isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <StyledRoot>
          <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledHeadLabel color="text.secondary">
                  {`${t("Name")}`}
                </StyledHeadLabel>
                <Controller
                  name="firstname"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <TextFieldTheme
                      {...field}
                      error={fieldState.error? true : false}
                      helperText={fieldState.error? fieldState.error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledHeadLabel color="text.secondary">
                  {`${t("LastName")}`}
                </StyledHeadLabel>
                <Controller
                  name="lastname"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <TextFieldTheme
                      {...field}
                      error={fieldState.error? true : false}
                      helperText={fieldState.error? fieldState.error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledHeadLabel color="text.secondary">
                  {`${t("PhoneNumber")}`}
                </StyledHeadLabel>
                <Controller
                  name="mobilePhone"
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <TextFieldTheme
                      {...field}
                      InputProps={{
                        inputComponent: NumberFormatTheme,
                      }}
                      inputProps={{
                        // thousandSeparator: ",",
                        allowNegative: false,
                        allowLeadingZeros: true,
                        value: field.value,
                        onValueChange: (values) => {
                          const { value } = values;
                          field.onChange(value)
                        },
                      }}
                      onChange={()=>{}}
                      error={fieldState.error? true: false}
                      helperText={fieldState.error? fieldState.error.message: null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledHeadLabel color="text.secondary">
                  {`${t("ClockIn")}`}
                </StyledHeadLabel>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      name="startDate"
                      control={useHookForm.control}
                      render={({ field, fieldState }) => (
                        <DatePickerCustom
                          {...field}
                          views={["year", "month", "day"]}
                          inputFormat="DD MMMM YYYY"
                          openTo="day"
                          value={field.value}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                            useHookForm.trigger("endDate");
                            useHookForm.trigger("validClockInClockOutDate");
                          }}
                          minDate={dayjs(drawerConfig.data.workingDate).add(-1, 'day')}
                          maxDate={dayjs(drawerConfig.data.workingDate).add(1, 'day')}
                          disableMaskedInput
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: t("SelectDate"),
                                readOnly: true
                              }}
                              onBlur={field.onBlur}
                              error={fieldState.error? true : false}
                              helperText={fieldState.error? fieldState.error.message : null}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="startHour"
                      control={useHookForm.control}
                      render={({field, fieldState}) => (
                        <TimePicker
                          handleChangeTime={(_a, _b, _c, value) => {
                            field.onChange(value);
                            useHookForm.trigger("validClockInClockOutDate");
                          }}
                          unit="hour"
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="startMinute"
                      control={useHookForm.control}
                      render={({field, fieldState}) => (
                        <TimePicker
                          handleChangeTime={(_a, _b, _c, value) => {
                            field.onChange(value);
                            useHookForm.trigger("validClockInClockOutDate");
                          }}
                          unit="minute"
                          step="1"
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <StyledHeadLabel color="text.secondary">
                  {`${t("ClockOut")}`}
                </StyledHeadLabel>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Controller
                      name="endDate"
                      control={useHookForm.control}
                      render={({ field, fieldState }) => (
                        <DatePickerCustom
                          {...field}
                          views={["year", "month", "day"]}
                          inputFormat="DD MMMM YYYY"
                          openTo="day"
                          value={field.value}
                          onChange={(newValue) => {
                            field.onChange(newValue);
                            useHookForm.trigger("startDate");
                            useHookForm.trigger("validClockInClockOutDate");
                          }}
                          minDate={dayjs(drawerConfig.data.workingDate).add(-1, 'day')}
                          maxDate={dayjs(drawerConfig.data.workingDate).add(1, 'day')}
                          disableMaskedInput
                          renderInput={(params) => (
                            <TextFieldTheme
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: t("SelectDate"),
                                readOnly: true
                              }}
                              onBlur={field.onBlur}
                              error={fieldState.error? true : false}
                              helperText={fieldState.error? fieldState.error.message : null}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="endHour"
                      control={useHookForm.control}
                      render={({field, fieldState}) => (
                        <TimePicker
                          handleChangeTime={(_a, _b, _c, value) => {
                            field.onChange(value);
                            useHookForm.trigger("validClockInClockOutDate");
                          }}
                          unit="hour"
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="endMinute"
                      control={useHookForm.control}
                      render={({field, fieldState}) => (
                        <TimePicker
                          handleChangeTime={(_a, _b, _c, value) => {
                            field.onChange(value);
                            useHookForm.trigger("validClockInClockOutDate");
                          }}
                          unit="minute"
                          step="1"
                          value={field.value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {useHookForm.formState.errors.validClockInClockOutDate && (
                <Grid item xs={12}>
                  <Typography fontSize="12px" margin="3px 14px 0" color="error">{useHookForm.formState.errors.validClockInClockOutDate.message}</Typography>
                </Grid>
              )}

              <Grid item xs={12} container justifyContent="space-between">
                <ButtonBlue variant="text" onClick={handleClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
                <ButtonBlue variant="contained" type="submit" disabled={useHookForm.formState.isSubmitting}>{drawerConfig.isEdit? t("Edit"): t("Save")}</ButtonBlue>
              </Grid>
            </Grid>
          </form>
        </StyledRoot>
    </DrawerCustom>
  )
}

export default DialogAddOrEditEmployeeSpareContract;