import { httpClient } from "./httpClient";

const getProbationQuestion = (query) => {
  return httpClient.get(`/probation-question`, { params: query });
};

const addProbationQuestion = (formdata) => {
  return httpClient.post(`/probation-question`, formdata);
};

const updateProbationQuestionStatus = (formdata) => {
  return httpClient.put(`/probation-question-status`, formdata);
};

const updateProbationManager = (formdata) => {
  return httpClient.put(`/probation/manager-probation`, formdata);
};

const updateProbationAdmin = (formdata) => {
  return httpClient.put(`/probation/admin-probation`, formdata);
};

const getProbationEmployees = () => {
  return httpClient.get(`/employees-probation`);
};

const getProbationResult = (idEmp) => {
  return httpClient.get(`/probation-result/${idEmp}`);
};

const getProbationAndAnswer = (formData) => {
  return httpClient.get(`/probation/result-probation`, { params: formData });
};

export default {
  getProbationQuestion,
  addProbationQuestion,
  updateProbationQuestionStatus,
  updateProbationManager,
  updateProbationAdmin,
  getProbationEmployees,
  getProbationResult,
  getProbationAndAnswer
};
