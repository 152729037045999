import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import { Grid, Typography } from "@mui/material";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import DrawerCustom from "../../../../shared/general/Drawer";

import {
  updateManagerSetting,
  getManagerByidCompany,
  getManagerSettingByidCompany,
} from "../../../../../../actions/company";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  "&.head-text-manager": {
    fontWeight: 400,
    "&.error": {
      color: "#f15e5e",
    },
  },
});

const StyledFooter = styled("div")({
  padding: "16px 0",
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const DialogEditManager = (props) => {
  const { open, handleCloseDialog, topicEdit } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const [formData, setFormData] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setFormData(topicEdit);
  }, [open, topicEdit]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    var temp = { ...formData };
    if (name === "managerLV1") {
      temp.managerLV1 = value;
      if (value === null || value === "0") {
        temp.managerLV2 = "1";
      } else {
        temp.managerLV2 = null;
      }
    }

    setFormData(temp);
  };

  const handleSubmit = async () => {
    console.log(formData);
    var idCompany = formData.idCompany;

    delete formData["topic"];
    delete formData["idManagerSettingTopic"];
    delete formData["header"];
    delete formData["idCompany"];

    const result = await dispatch(updateManagerSetting(idCompany, formData));
    if (result) {
      handleCloseDialog();
      dispatch(getManagerByidCompany(idCompany));
      dispatch(getManagerSettingByidCompany(idCompany));
    }
  };

  return (
    <DrawerCustom
      title={t("EditOperatingPower")}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      {formData && (
        <StyledRoot>
          <Typography variant="h6" style={{ marginBottom: 16 }}>
            {formData.topic}
          </Typography>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={3}>
              <StyledContentLabel>{t("ManagerLevel")} 1</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="managerLV1"
                  value={formData.managerLV1}
                  onChange={handleChange}
                >
                  <MenuItem value={null}>{t("NotSpecified")}</MenuItem>
                  <MenuItem value={"0"}>{t("Examine")}</MenuItem>
                  <MenuItem value={"1"}>{t("Approve")}</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledContentLabel>{t("ManagerLevel")} 2</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextFieldTheme
                id="outlined-full-width"
                fullWidth
                name="managerLV2"
                variant="outlined"
                value={
                  formData.managerLV2
                    ? formData.managerLV2 === "1" && `${t("Approve")}`
                    : `${t("NotSpecified")}`
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>

          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleCloseDialog}>
              {t("Cancel")}
            </ButtonBlue>

            <ButtonBlue variant="contained" onClick={handleSubmit}>
              {t("Save")}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
    </DrawerCustom>
  );
};

export default DialogEditManager;
