import {
    TIMELINE_FETCHING,
    TIMELINE_FAILED,
    TIMELINE_SUCCESS,
    TIMELINE_MONTH_FETCHING,
    TIMELINE_MONTH_FAILED,
    TIMELINE_MONTH_SUCCESS,
} from "./types";

import timelineService from "../services/timeline.service";

export const clearTimeline = () => async (dispatch) => {
    dispatch({
        type: TIMELINE_SUCCESS,
        payload: null,
    });
    dispatch({
        type: TIMELINE_MONTH_FETCHING,
        payload: null,
    });
};

export const getTimelineOnce = (filter, mode) => async (dispatch) => {
    try {
        dispatch({
            type: TIMELINE_FETCHING,
        });
        const res = await timelineService.getTimelineOnce(filter, mode);
        if (res) {
            dispatch({
                type: TIMELINE_SUCCESS,
                payload: res.data,
            });
            return res;
        }
    } catch (err) {
        dispatch({
            type: TIMELINE_FAILED,
        });
        return err;
    }
};

export const getTimelineById = (id,type,date) => async (dispatch) => {
    try {
        dispatch({
            type: TIMELINE_FETCHING,
        });
        const res = await timelineService.getTimelineById(id,type,date);
        if (res) {
            dispatch({
                type: TIMELINE_SUCCESS,
                payload: res.data,
            });
            return res;
        }
    } catch (err) {
        dispatch({
            type: TIMELINE_FAILED,
        });
        return err;
    }
};

export const getTimelineMonthById = (id,type,date) => async (dispatch) => {
    try {
        dispatch({
            type: TIMELINE_MONTH_FETCHING,
        });
        const res = await timelineService.getTimelineMonthById(id,type,date);
        if (res) {
            dispatch({
                type: TIMELINE_MONTH_SUCCESS,
                payload: res.data,
            });
            return res;
        }
    } catch (err) {
        dispatch({
            type: TIMELINE_MONTH_FAILED,
        });
        return err;
    }
};

export const getCheckTimelineById = (id,type) => async (dispatch) => {
    try {
        dispatch({
            type: TIMELINE_FETCHING,
        });
        const res = await timelineService.getCheckTimelineById(id,type);
        if (res) {
            dispatch({
                type: TIMELINE_SUCCESS,
                payload: res.data,
            });
            return res;
        }
    } catch (err) {
        dispatch({
            type: TIMELINE_FAILED,
        });
        return err;
    }
};

export const addTimeline = (formData) => async () => {
    try {
      const res = await timelineService.addTimeline(formData);
      if (res) {
        return res;
      }
    } catch (error) {
      const result = {
        status: "",
        message: "",
      };
      if (error.response) {
        result.status = error.response.status;
        result.message = error.response.data.message;
      }
      return result;
    }
  };