import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Avatar, MenuItem, Typography } from "@mui/material";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
      width: 45,
      height: 45,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .department": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
    fontWeight: 600,
  },
  "& .reportToPosition": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${280}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 888px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
});

const ItemTable = (props) => {
  const { open } = props;
  const { result: employeeList } = useSelector((state) => state.vendorUsers);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueSelected, setValueSelected] = React.useState(null);
  const { t, i18n } = useTranslation();

  const columns = [
    { id: "name", label: `${t("ContractName")}`, minWidth: 240 },
    {
      id: "jobLevelName",
      label: `${t("StartDate")}`,
      minWidth: 100,
    },
    {
      id: "report_To",
      label: `${t("EndDate")}`,
      minWidth: 120,
    },
    {
      id: `${t("Company")}`,
      label: "",
      minWidth: 200,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <StyledBox className={open ? "open" : "close"}>
      <TableContainer sx={{ width: "100%", maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledCellHeader
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledCellHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeList &&
              employeeList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledRowContent key={row.scG_Employee_ID}>
                      <StyledCellContent key={"position"}>
                        <Typography className="position">
                          {row.positionsName}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"jobLevelName"}>
                        <Typography>{row.jobLevelName}</Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"reportToLv1"}>
                        <Typography className="reportTo">
                          {row.reportToLv1}
                        </Typography>
                        <Typography
                          className="reportToPosition"
                          color="text.third"
                        >
                          {row.report_to_Position_Name}
                        </Typography>
                      </StyledCellContent>
                      <StyledCellContent key={"companyName"}>
                        <Typography>
                          {row.companyName
                            ? row.companyName
                                .replace("บริษัท", "")
                                .replace("จำกัด", "")
                                .trim()
                            : "-"}
                        </Typography>
                      </StyledCellContent>
                    </StyledRowContent>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={employeeList && employeeList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledBox>
  );
};

export default ItemTable;
