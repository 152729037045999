import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const AttendanceXlsxReportByDepartment = (data, leaveData, start, end) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("สรุปเวลาทำงานรายหน่วยงาน");

    let DateStart = dayjs(start).add(543, 'year').format("DD-MM-YYYY");
    let DateEnd = dayjs(end).add(543, 'year').format("DD-MM-YYYY")

    function formatMonthToThai(month) {
        switch (month) {
            case '01':
                return 'มกราคม';
            case '02':
                return 'กุมภาพันธ์';
            case '03':
                return 'มีนาคม';
            case '04':
                return 'เมษายน';
            case '05':
                return 'พฤษภาคม';
            case '06':
                return 'มิถุนายน';
            case '07':
                return 'กรกฎาคม';
            case '08':
                return 'สิงหาคม';
            case '09':
                return 'กันยายน';
            case '10':
                return 'ตุลาคม';
            case '11':
                return 'พฤศจิกายน';
            case '12':
                return 'ธันวาคม';
            default:
                return '';
        }
    }

    const ThaiDateStart = DateStart.split('-').map((value, index) => (index === 1 ? formatMonthToThai(value) : value)).join(' ');
    const ThaiDateEnd = DateEnd.split('-').map((value, index) => (index === 1 ? formatMonthToThai(value) : value)).join(' ');

    const leaveTypeNames = leaveData.map(leaveType => `รวม${leaveType.name}`);

    const headerValues = [
        "หน่วยงาน",
        "จำนวนพนักงาน",
        "วันที่เริ่มข้อมูล",
        "วันที่สิ้นสุดข้อมูล",
        "รวมชั่วโมงทำงาน",
        "รวมชั่วโมงลา",
        "รวมชั่วโมง OT",
        "รวมชั่วโมงขาดงาน",
        "รวมชั่วโมงมาสาย/กลับก่อน",
        ...leaveTypeNames
    ];

    const headerRow = worksheet1.addRow(headerValues);
    headerRow.height = 50;

    const headerStyle1 = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '002060' }
        },
    }

    headerRow.eachCell((cell) => {
        cell.style = headerStyle1;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    let leaveTypeColWidths = [];
    leaveData.forEach(leaveType => {
        leaveTypeColWidths.push({
            key: `leaveType_${leaveType.idLeaveType}`,
            width: leaveType.name.length * 2
        });
    });

    const colWidths = [
        { key: "department", width: 50 },
        { key: "totalEmployee", width: 30 },
        { key: "start", width: 30 },
        { key: "end", width: 30 },
        { key: "totalWorkingHour", width: 30 },
        { key: "totalLeaveHour", width: 30 },
        { key: "totalOTHour", width: 30 },
        { key: "absent", width: 20 },
        { key: "late", width: 30 },
        ...leaveTypeColWidths
    ];

    function formatNumber(number) {
        if (Number.isInteger(number)) {
            return number.toString();
        } else {
            return parseFloat(number.toFixed(2));
        }
    };

    let row = [];

    data && data.map((item) => {
        row = [
            item.departmentName ? item.departmentName : "-",
            item.departmentEmployeeCount ? item.departmentEmployeeCount : "-",
            ThaiDateStart ? ThaiDateStart : "-",
            ThaiDateEnd ? ThaiDateEnd : "-",
            item.totalWorkingHoursByDepartment ? formatNumber(item.totalWorkingHoursByDepartment) : "-",
            item.totalSumLeaveByDepartment ? formatNumber(item.totalSumLeaveByDepartment) : "-",
            item.totalotHourstotalByDepartment ? formatNumber(item.totalotHourstotalByDepartment) : "-",
            "-",
            "-"
        ];

        const leaveHours = {}; // Object to store used hours for each leave type

        // Initialize leaveHours object with 0 values for all leave types
        leaveData.forEach(leaveType => {
            leaveHours[leaveType.idLeaveType] = 0;
        });

        // Add used hours for each leave type to the leaveHours object
        item.totalLeaveByDepartment.forEach(leave => {
            leaveHours[leave.idLeaveType] = formatNumber(leave.used);
        });

        // Add used hours for each leave type to the row
        leaveData.forEach(leaveType => {
            row.push(leaveHours[leaveType.idLeaveType]);
        });

        const excelRow = worksheet1.addRow(row);
        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: {
                vertical: 'middle',
                horizontal: 'center'
            }
        };

        colWidths.map((col, index) => {
            worksheet1.getColumn(index + 1).width = col.width;
        });

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `สรุปเวลาทำงานรายหน่วยงาน.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}
