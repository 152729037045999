import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "react-use";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Container, Typography, Box, Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

import { clearLocations } from "../../../../actions/gpsLocations";

//GPS Check in - out
import GPSSetting from "./GPSCheckInOut";
import LocationList from "./GPSCheckInOut/LocationList";
import EditLocation from "./GPSCheckInOut/EditLocation/EditLocation";
import TaxDeductionSchedule from "./taxDeduction-schedule";

//Calendar
import Calendar from "./calendar";

//leave-manage
import LeaveManage from "./leave-manage";

//job-manage
import JobManage from "./job-manage";

//user-manage
import UserManage from "./user-manage";

//institute-manage
import Manager from "./manager";

// //admin-approve
//import AdminApproveLeave from "./leave-manage/admin-approve";

// //workTime-manage
//import WorkTimeManage from "./workTime-approve";

import JobLevel from "./jobLevel";

//log-record
import LogRecord from "./log-record";

//payroll
import Payroll from "./payroll";

//pf
import Pf from "./pf";

//branch-manage
import BranchManage from "./branch-manage";

//childCompany-manage
import ChildCompanyManage from "./childCompany-manage";

//Probation
// import Probation from "./probation";

//performance
// import Performance from "./performance";

import Reason from "./reason";

import SelectCompany from "../shared/selectCompany";
import { getCompanyContract } from "../../../../actions/vendor";
import OrganizationStructure from "./organization-structure";
import Level from "./level";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapTabs = styled("div")({
  paddingBottom: 36,
  marginTop: 36,
  width: "100%",
});

const SettingPage = () => {
  const { t, i18n } = useTranslation();
  useTitle(`${t("SystemSetting")} - Ant HR`);
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: vendorContract } = useSelector(
    (state) => state.vendorContract
  );

  const [company, setCompany] = useState(null);
  const [branch, setBranch] = useState(null);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCompany = (newCompany) => {
    setCompany(newCompany);
  };

  const handleClearData = () => {
    dispatch(clearLocations());
  };

  useEffect(() => {
    dispatch(getCompanyContract());
  }, []);

  useEffect(() => {
    if (vendorContract) {
      setCompany(vendorContract[0]);
    }
  }, [vendorContract]);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("SystemSetting")}
          </Typography>
        </div>
        <React.Fragment>
          <StyledWrapTabs>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                scrollButtons="auto"
                aria-label="basic tabs example"
                variant={"scrollable"}
              >
                <Tab
                  label={`${t("GPSLocation")}`}
                  {...a11yProps(0)}
                  value={0}
                />
                <Tab
                  label={`${t("HolidayCompany")}`}
                  {...a11yProps(1)}
                  value={1}
                />
                <Tab label={`${t("LeaveDay")}`} {...a11yProps(3)} value={3} />
                {/* <Tab label={`ทดลองงาน`} {...a11yProps(12)} value={12} /> */}
                <Tab label={`${t("ManageJobs")}`} {...a11yProps(4)} value={4} />
                <Tab
                  label={"Manage Organization"}
                  {...a11yProps(15)}
                  value={15}
                />
                <Tab
                  label={`${t("ManageLevel")}`}
                  {...a11yProps(6)}
                  value={6}
                />
                <Tab label={`${t("Manager")}`} {...a11yProps(5)} value={5} />
                {/* <Tab
                    label={`${t("ManageBranch")}`}
                    {...a11yProps(10)}
                    value={10}
                    disabled
                  /> */}
                {/* <Tab
                    label={`${t("ManageSubsidiary")}`}
                    {...a11yProps(11)}
                    value={11}
                    disabled
                  /> */}
                <Tab label="Payroll" {...a11yProps(7)} value={7} />
                <Tab label="จัดการเหตุผล" {...a11yProps(14)} value={14} />
                {/* <Tab
                  label={`${t("ProvidentFund")}`}
                  {...a11yProps(8)}
                  value={8}
                /> */}
                <Tab label={`${t("TaxDeductible")}`} {...a11yProps(9)} value={9} />
                {userProfile && userProfile.isSuperAdmin === 1 && (
                  <Tab label={`${t("Admin")}`} {...a11yProps(10)} value={10} />
                )}
              </Tabs>
            </Box>

            {!(value === 10 || value === 12 || value === 3 || value === 9) && (
              <SelectCompany
                company={company}
                handleClearData={handleClearData}
                handleChangeCompany={handleChangeCompany}
              />
            )}

            <TabPanel value={value} index={0}>
              <GPSSetting company={company} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Calendar company={company} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <LeaveManage company={company} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <JobManage selectedCompany={company} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Manager company={company} />
            </TabPanel>
            <TabPanel value={value} index={6}>
              {/* <JobLevel company={company} /> */}
              <Level company={company}/>
            </TabPanel>
            <TabPanel value={value} index={7}>
              <Payroll company={company} />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <Pf />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <TaxDeductionSchedule />
            </TabPanel>
            <TabPanel value={value} index={10}>
              <UserManage />
            </TabPanel>
            <TabPanel value={value} index={11}>
              {/* <LogRecord /> */}
            </TabPanel>
            {/* <TabPanel value={value} index={12}>
              <Probation />
            </TabPanel>
            <TabPanel value={value} index={13}>
              <Performance />
            </TabPanel> */}
            <TabPanel value={value} index={14}>
              <Reason company={company} />
            </TabPanel>
            <TabPanel value={value} index={15}>
              <OrganizationStructure selectedCompany={company} />
            </TabPanel>

            {/* <TabPanel value={value} index={10}>
                <BranchManage company={company} />
              </TabPanel>
              <TabPanel value={value} index={11}>
                <ChildCompanyManage />
              </TabPanel> */}
          </StyledWrapTabs>
        </React.Fragment>
      </Container>
    </StyledRoot>
  );
};

export default SettingPage;
