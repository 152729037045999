import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const getSummaryTime = (search, filter) => {
  console.log("filter: ", filter);
  if (filter) {
    return httpClient.get(
      `payment/summary-time?start=${dayjs(search.start).format(
        "YYYY-MM-DD"
      )}&end=${dayjs(search.end).format("YYYY-MM-DD")}&filter=${filter}`
    );
  }
};

const getSummaryTimeByIdEmp = (search, idEmp) => {
  let query = "";
  if (idEmp) {
    query = `&search=${idEmp}`;
  }
  
  return httpClient.get(
    `payment/summary-time?start=${dayjs(search.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(search.end).format("YYYY-MM-DD")}${query}`
  );
};

export default {
  getSummaryTime,
  getSummaryTimeByIdEmp,
};
