import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  FilledInput,
  TextField,
} from "@mui/material";

import Daily from "./Daily";
import Monthly from "./Monthly";

import {
  getAttendanceCompanyByIdEmp,
  clearAttendance,
  getAttendanceCompany,
} from "../../../../actions/attendance";
import { getHoliday } from "../../../../actions/holiday";
import { getShiftPattern } from "../../../../actions/shift";
import { getEmployeeProfile } from "../../../../actions/employee";
import { getPayrollSetting } from "../../../../actions/paytypes";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledPleaseSelectCompany = styled("div")({
  padding: 18,
  width: "100%",
  "& .MuiTypography-root": {
    fontStyle: "oblique",
    letterSpacing: 2,
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#007afe",
    border: "1px solid #007afe",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007aff",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #007aff",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
  display: "flex",
  justifyContent: "space-evenly",
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const ManualTimesheet = () => {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: attendanceList } = useSelector((state) => state.attendance);
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const [view, setView] = React.useState("daily");
  const [search, setSearch] = React.useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const [tempSearchDate, setTempSearchDate] = useState({
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
    text: "",
  });
  const [company, setCompany] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [payrollSetting, setPayrollSetting] = useState(null);

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
      setSelectedEmployee(null);
    }
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("manualTable")}
          </Typography>
        </div>
        <Fragment>
          <Fragment>
            <StyledToggleButtonGroup
              value={view}
              exclusive
              onChange={handleChangeView}
              aria-label="view"
              size="small"
            >
              <ToggleButton
                name="status"
                value="daily"
                aria-label="daily"
                size="small"
                style={{ minWidth: 150, marginRight: 12 }}
              >
                {t("attendanceDaily")}
              </ToggleButton>
              <ToggleButton
                name="status"
                value="monthly"
                aria-label="monthly"
                size="small"
                style={{ minWidth: 150, marginRight: 12 }}
              >
                {t("attendanceMonthly")}
              </ToggleButton>
            </StyledToggleButtonGroup>
             {view === "daily" && <Daily />}
            {view === "monthly" && <Monthly />}
          </Fragment>
        </Fragment>
      </Container>
    </StyledRoot>
  );
};

export default ManualTimesheet;
