import dayjs from 'dayjs';
import 'dayjs/locale/th';
import ExcelJS from 'exceljs';

export const TaxDeductionXlsxFile = (data, selectedYear) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    dayjs.locale('th');

    const employeeData = data && data.empResult;
    const deductionData = data && data.TaxDeductedResult;

    const headerRow = worksheet.addRow([
        "รหัสพนักงาน",
        "ชื่อ",
        "นามสกุล",
        "เลขบัตรประชาชน",
        "สถานภาพ",
        "จำนวนบุตรที่มีสิทธิลดหย่อนทั้งหมด",
        "จำนวนบุตรตั้งแต่คนที่ 2 ที่เกิดปี พ.ศ. 2561 เป็นต้นไป มีสิทธิลดหย่อนทั้งหมด",
        "เบี้ยประกันชีวิตที่จ่ายในปีภาษีพนักงาน",
        "เบี้ยประกันชีวิตที่จ่ายในปีภาษีคู่สมรส",
        "เบี้ยประกันสุขภาพ",
        "เงินบริจาค",
        "เงินบริจาคเพื่อการศึกษา",
        "กองทุนรวมเพื่อการเลี้ยงชีพ (RMF)",
        "ลดหย่อนค่าอุปการะบิดา",
        "ลดหย่อนค่าอุปการะมารดา",
        "ลดหย่อนค่าอุปการะบิดาคู่สมรส",
        "ลดหย่อนค่าอุปการะมารดาคู่สมรส",
        "เบี้ยประกันภัยสำหรับการประกันสุขภาพของบิดา",
        "เบี้ยประกันภัยสำหรับการประกันสุขภาพของมารดา",
        "เบี้ยประกันภัยสำหรับการประกันสุขภาพของบิดาคู่สมรส",
        "เบี้ยประกันภัยสำหรับการประกันสุขภาพของมารดาคู่สมรส",
        "อุปการะเลี้ยงดูคนพิการหรือทุพพลภาพ",
        "เบี้ยประกันชีวิตแบบบำนาญ",
        "ดอกเบี้ยเงินกู้บ้าน",
        "ค่าฝากครรภ์/ค่าคลอด",
        "หมายเหตุ",
        "วันที่ทำรายการล่าสุด"
    ]);

    const headerStyle = {
        font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
        alignment: { horizontal: "center", vertical: 'middle' },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '081F5C' } // Grey background color
        },
    };

    headerRow.eachCell((cell) => {
        cell.style = headerStyle;
        cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } }
        };
    });

    headerRow.height = 50;

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname_TH", width: 30 },
        { key: "lastname_TH", width: 30 },
        { key: "personalID", width: 50 },
        { key: "maritalStatus", width: 30 },
        { key: "childDeduction", width: 40 },
        { key: "childYearDeduction", width: 80 },
        { key: "empInsure", width: 50 },
        { key: "mateInsure", width: 50 },
        { key: "healthInsurance", width: 40 },
        { key: "etcDonation", width: 40 },
        { key: "eduDonation", width: 40 },
        { key: "rmfFund", width: 50 },
        { key: "childSupport_empFather", width: 50 },
        { key: "childSupport_empMother", width: 50 },
        { key: "childSupport_mateFather", width: 50 },
        { key: "childSupport_mateMother", width: 50 },
        { key: "healthInsurance_empFather", width: 70 },
        { key: "healthInsurance_empMother", width: 70 },
        { key: "healthInsurance_mateFather", width: 70 },
        { key: "healthInsurance_mateMother", width: 70 },
        { key: "handicappedPerson", width: 50 },
        { key: "lifeInsurance", width: 40 },
        { key: "loanInterestHome", width: 40 },
        { key: "maternityExpenses", width: 40 },
        { key: "description", width: 50 },
        { key: "updatedDate", width: 50 }
    ];

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    employeeData.forEach((item) => {
        const findDeductEmp = deductionData.find(x => x.idEmp === item.idEmp);
        const formattedDate = dayjs(findDeductEmp && findDeductEmp.updatedDate).locale('th').format("DD MMMM YYYY") || "";
        const row = [
            item.employeeId ? item.employeeId : "-",
            item.firstname ? item.firstname : "-",
            item.lastname ? item.lastname : "-",
            item.personalId ? item.personalId : "-",
            item.maritalStatus ? item.maritalStatus : "-",
            findDeductEmp && findDeductEmp.childDeduction ? findDeductEmp.childDeduction : "-",
            findDeductEmp && findDeductEmp.childYearDeduction ? findDeductEmp.childYearDeduction : "-",
            findDeductEmp && findDeductEmp.empInsure ? `${String(findDeductEmp.empInsure).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.mateInsure ? `${String(findDeductEmp.mateInsure).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.healthInsurance ? `${String(findDeductEmp.healthInsurance).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.etcDonation ? `${String(findDeductEmp.etcDonation).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.eduDonation ? `${String(findDeductEmp.eduDonation).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.rmfFund ? `${String(findDeductEmp.rmfFund).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.childSupport_empFather ? findDeductEmp.childSupport_empFather : "-",
            findDeductEmp && findDeductEmp.childSupport_empMother ? findDeductEmp.childSupport_empMother : "-",
            findDeductEmp && findDeductEmp.childSupport_mateFather ? findDeductEmp.childSupport_mateFather : "-",
            findDeductEmp && findDeductEmp.childSupport_mateMother ? findDeductEmp.childSupport_mateMother : "-",
            findDeductEmp && findDeductEmp.healthInsurance_empFather ? `${String(findDeductEmp.healthInsurance_empFather).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.healthInsurance_empMother ? `${String(findDeductEmp.healthInsurance_empMother).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.healthInsurance_mateFather ? `${String(findDeductEmp.healthInsurance_mateFather).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.healthInsurance_mateMother ? `${String(findDeductEmp.healthInsurance_mateMother).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.handicappedPerson ? findDeductEmp.handicappedPerson : "-",
            findDeductEmp && findDeductEmp.lifeInsurance ? `${String(findDeductEmp.lifeInsurance).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.loanInterestHome ? `${String(findDeductEmp.loanInterestHome).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.maternityExpenses ? `${String(findDeductEmp.maternityExpenses).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : "-",
            findDeductEmp && findDeductEmp.description ? findDeductEmp.description : "-",
            formattedDate
        ];

        const excelRow = worksheet.addRow(row);
        const contentStyle = {
            font: { size: 18, name: 'TH SarabunPSK' },
            alignment: { horizontal: "center", vertical: 'middle' },
        };

        excelRow.eachCell((cell) => {
            cell.style = contentStyle;
            cell.border = {
                top: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `รายงานลดหย่อนภาษี-${selectedYear}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
};
