import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const employmentXlsxReport = async (data, companyName) => {

    const headerCellStyle = {
        font: {
            name: "TH Sarabun New",
            size: 20,
            color: { argb: "FFFFFF" }
        },
        alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
        },
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: "002060" }
        },
        // numFmt: "@",
    }

    const dataCellStyle = {
        font: {
            name: "TH Sarabun New",
            size: 18,
        },
        alignment: {
            vertical: "middle",
            horizontal: "center"
        },
        // numFmt: "@",
    }

    const workBook = new ExcelJS.Workbook();

    const worksheet = workBook.addWorksheet("เปลี่ยนประเภทการจ้าง");

    const headerRow = worksheet.addRow([
        "รหัสพนักงาน", "ชื่อ", "นามสกุล", "ตำแหน่ง", "บริษัท", "ฝ่าย", "ส่วน", "แผนก", "ประเภทการจ้างเก่า", "ประเภทการจ้างใหม่", "หมายเหตุ", "วันที่มีผล"
    ]);

    headerRow.eachCell((cell) => {
        cell.style = headerCellStyle;
        cell.numFmt = "@";
    });

    headerRow.height = 50;

    const colWidths = [
        { key: "employeeID", width: 30 },
        { key: "firstname_TH", width: 50 },
        { key: "lastname_TH", width: 50 },
        { key: "positionName", width: 50 },
        { key: "companyName", width: 60 },
        { key: "divisionName", width: 50 },
        { key: "departmentName", width: 50 },
        { key: "sectionName", width: 50 },
        { key: "oldLevel", width: 50 },
        { key: "newLevel", width: 50 },
        { key: "remark", width: 50 },
        { key: "date", width: 50 },
    ];

    colWidths.forEach((col, index) => {
        worksheet.getColumn(index + 1).width = col.width;
    });

    data.map(item => {
        const row = [
            item.employeeId || "-",
            item.firstname || "-",
            item.lastname || "-",
            item.positionsName || "-",
            item.companyName || "-",
            item.divisionName || "-",
            item.departmentName || "-",
            item.sectionName || "-",
            item.oldEmployeeType || "-",
            item.newEmployeeType || "-",
            item.remark || "-",
            item.updatedDate || "-",
        ]

        const excelRow = worksheet.addRow(row);
        excelRow.eachCell((cell) => {
            cell.style = dataCellStyle;
            cell.numFmt = "@";
        });
    })

    workBook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `AntHr-${companyName}-รายงานเปลี่ยนประเภทการจ้าง.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    });
}