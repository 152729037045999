import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  InputAdornment,
  IconButton,
  styled,
  Typography,
  Container,
  Grid,
} from "@mui/material";
import ButtonBlue from "../shared/general/ButtonBlue";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import TextFieldTheme from "../shared/general/TextFieldTheme";
import AlertResponse from "../shared/general/AlertResponse";

import { changePassword } from "../../../actions/auth";
import { useDispatch } from "react-redux";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const StyledWrap = styled("div")({});

const StyledWrapRule = styled("div")({
  marginBottom: 16,
});

const StyledWrapRow = styled("div")({});

const StyledHeadLabel = styled(Typography)({
  marginBottom: 8,
  fontWeight: 600,
  fontSize: 14,
});

const validationSchema = Yup.object({
  old: Yup.string()
    .min(8, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข")
    .max(16, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข")
    .required("โปรดกรอกรหัสผ่านปัจจุบัน"),
  new: Yup.string()
    .min(8, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข")
    .max(16, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข")
    .matches(/[0-9]/, "รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว")
    .matches(/[a-zA-Z]/, "รหัสผ่านต้องมีตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัว")
    .required("โปรดกรอกรหัสผ่านใหม่"),
  confirm: Yup.string()
    .min(8, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข")
    .max(16, "อย่างน้อย 8-16 ตัวอักษรภาษาอังกฤษและตัวเลข")
    .matches(/[0-9]/, "รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว")
    .matches(/[a-zA-Z]/, "รหัสผ่านต้องมีตัวอักษรภาษาอังกฤษเลขอย่างน้อย 1 ตัว")
    .required("โปรดกรอกยืนยันรหัสผ่านใหม่")
    .oneOf([Yup.ref("new"), null], "ไม่ตรงกับรหัสผ่านใหม่"),
});

const ChangePasswordVendor = () => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState({
    old: false,
    new: false,
    confirm: false,
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const formik = useFormik({
    initialValues: {
      old: "",
      new: "",
      confirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values);
    },
  });

  const handleClickShowPassword = (name) => {
    const temp = { ...password };
    temp[name] = !temp[name];
    setPassword(temp);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (values) => {
    const result = await dispatch(changePassword(values));
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        formik.resetForm();
      } else {
        handleOpenAlert();
        handleChangeAlertType(result.message);
        formik.setSubmitting(false);
      }
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  const handleChangeAlertType = (status) => {
    setAlertType(status);
  };
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          เปลี่ยนรหัสผ่าน
        </Typography>
        <StyledWrap>
          <StyledWrapRule>
            <Typography color="text.third" variant="body2">
              *รหัสผ่านต้องมีจำนวน 8-16 ตัวอักษร
            </Typography>
            <Typography color="text.third" variant="body2">
              *ต้องเป็นตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น
            </Typography>
            <Typography color="text.third" variant="body2">
              *ต้องมีตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัว หรือ ตัวเลขอย่างน้อย 1
              ตัว
            </Typography>
          </StyledWrapRule>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <StyledWrapRow>
                      <StyledHeadLabel>รหัสผ่านปัจจุบัน</StyledHeadLabel>
                      <TextFieldTheme
                        name="old"
                        value={formik.values.old}
                        onChange={formik.handleChange}
                        type={password.old ? "text" : "password"}
                        error={formik.touched.old && Boolean(formik.errors.old)}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.old &&
                          Boolean(formik.errors.old) &&
                          formik.errors.old
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword("old")}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {password.old ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      ></TextFieldTheme>
                    </StyledWrapRow>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledWrapRow>
                      <StyledHeadLabel>รหัสผ่านใหม่</StyledHeadLabel>
                      <TextFieldTheme
                        name="new"
                        value={formik.values.new}
                        onChange={formik.handleChange}
                        type={password.new ? "text" : "password"}
                        error={formik.touched.new && Boolean(formik.errors.new)}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.new &&
                          Boolean(formik.errors.new) &&
                          formik.errors.new
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => handleClickShowPassword("new")}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {password.new ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      ></TextFieldTheme>
                    </StyledWrapRow>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledWrapRow>
                      <StyledHeadLabel>ยืนยันรหัสผ่านใหม่</StyledHeadLabel>
                      <TextFieldTheme
                        name="confirm"
                        value={formik.values.confirm}
                        onChange={formik.handleChange}
                        type={password.confirm ? "text" : "password"}
                        error={
                          formik.touched.confirm &&
                          Boolean(formik.errors.confirm)
                        }
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.confirm &&
                          Boolean(formik.errors.confirm) &&
                          formik.errors.confirm
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleClickShowPassword("confirm")
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {password.confirm ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      ></TextFieldTheme>
                    </StyledWrapRow>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledWrapRow>
                      <ButtonBlue
                        type="submit"
                        disabled={formik.isSubmitting}
                        variant={"contained"}
                      >
                        ยืนยันเปลี่ยนรหัสผ่านใหม่
                      </ButtonBlue>
                    </StyledWrapRow>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
          />
        </StyledWrap>
      </Container>
    </StyledRoot>
  );
};

export default ChangePasswordVendor;
