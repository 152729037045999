import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

const StyledChip = styled(Chip)(({ statusrequest }) => ({
  height: 24,
  ...(statusrequest === 1 && {
    backgroundColor: "#ebfaf2",
  }),
  ...(statusrequest === 0 && {
    backgroundColor: "#fdf3f5",
  }),
  ...(statusrequest === null && {
    backgroundColor: "#fffcd6",
  }),
}));

const StatusRequestLeave = ({ status }) => {
  const { t } = useTranslation();

  const renderStatusText = (status) => {
    if (status === 1) {
      return (
        <Typography
          style={{ fontSize: 12, fontWeight: "500", color: "#00c292" }}
        >
          {t("Approve")}
        </Typography>
      );
    } else if (status === 0) {
      return (
        <Typography
          style={{ fontSize: 12, fontWeight: "500", color: "#e46a76" }}
        >
          {t("Disapproval")}
        </Typography>
      );
    } else {
      return (
        <Typography
          style={{ fontSize: 12, fontWeight: "600", color: "#fec90f" }}
        >
          {t("WaitingApproval")}
        </Typography>
      );
    }
  };

  return <StyledChip label={renderStatusText(status)} statusrequest={status} />;
};

export default StatusRequestLeave;