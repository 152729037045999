import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Divider,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  MenuItem
} from "@mui/material";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";

import { updateUser } from "../../../../../../actions/user";
import { getEmployeeProfile, updateEmployeeSalaryUserProfile } from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const Charge = (props) => {
  const { formData, handleChange, handleChangeCheck } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("Salary")}(%)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeSalary"
          value={formData.chargeSalary}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("OT")}(%)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeOt"
          value={formData.chargeOt}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("ChargeHire")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeHire"
          value={formData.chargeHire}
          onChange={handleChange}
          fullWidth
        />
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="percent"
              control={
                <Checkbox
                  checked={!!(formData.isPercentChargeHire)}
                  onChange={handleChangeCheck}
                  name="isPercentChargeHire"
                />
              }
              label={`${t("Percent")}(%)`}
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("ChargeCompensation")}(%)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="chargeCompensation"
          value={formData.chargeCompensation}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const Bank = (props) => {
  const { formData, handleChange } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("BankName")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        {/* <TextFieldTheme
          name="bookBank"
          value={formData.bookBank}
          onChange={handleChange}
          fullWidth
        /> */}
        <TextFieldTheme
          name="bookBank"
          value={formData.bookBank}
          onChange={handleChange}
          select
          fullWidth
        >
          <MenuItem value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</MenuItem>
          <MenuItem value="ธนาคารกรุงไทย">ธนาคารกรุงไทย</MenuItem>
          <MenuItem value="ธนาคารกรุงศรีอยุธยา">ธนาคารกรุงศรีอยุธยา</MenuItem>
          <MenuItem value="ธนาคารกสิกรไทย">ธนาคารกสิกรไทย</MenuItem>
          <MenuItem value="ธนาคารทหารไทยธนชาต">ธนาคารทหารไทยธนชาต</MenuItem>
          <MenuItem value="ธนาคารไทยพาณิชย์">ธนาคารไทยพาณิชย์</MenuItem>
          <MenuItem value="ธนาคารซีไอเอ็มบี ไทย">ธนาคารซีไอเอ็มบี ไทย</MenuItem>
          <MenuItem value="ธนาคารยูโอบี">ธนาคารยูโอบี</MenuItem>
          <MenuItem value="ธนาคารออมสิน">ธนาคารออมสิน</MenuItem>
        </TextFieldTheme>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("BookBankBranch")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="bookBankBranch"
          value={formData.bookBankBranch}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("BookBankID")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="bookBankId"
          value={formData.bookBankId}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <StyledDivider />
      <Grid item xs={12}>
        <StyledContentLabel color="text.secondary">
          สำหรับไฟล์ธนาคาร
        </StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ชื่อพนักงาน</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBank_Name"
          value={formData.reportBank_Name}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ชื่อธนาคาร</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBank_BankName"
          value={formData.reportBank_BankName}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("BranchCode")}</StyledContentLabel>
        <StyledContentLabel color="text.secondary">
          {t("BankFiles")}
        </StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBank_BankId"
          value={formData.reportBank_BankId}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("BookBankID")}</StyledContentLabel>
        <StyledContentLabel color="text.secondary">
          {t("BankFiles")}
        </StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBank_BookBankId"
          value={formData.reportBank_BookBankId}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>อ้่างอิง</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="reportBank_Ref"
          value={formData.reportBank_Ref}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const Cost = (props) => {
  const { formData, handleChange } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>Cost Center</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="costCenterCharge"
          value={formData.costCenterCharge}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>Cost Element</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="costElementCharge"
          value={formData.costElementCharge}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (open) {
      if (mode === "bank") {
        setFormData({
          idEmp: employeeProfile.idEmp,
          bookBank: employeeProfile.bookBank,
          bookBankBranch: employeeProfile.bookBankBranch,
          bookBankId: employeeProfile.bookBankId,
          bookBankBranchId: employeeProfile.bookBankBranchId,
          reportBank_Name: employeeProfile.reportBank_Name,
          reportBank_Ref: employeeProfile.reportBank_Ref,
          reportBank_BookBankId: employeeProfile.reportBank_BookBankId,
          reportBank_BankName: employeeProfile.reportBank_BankName,
          reportBank_BankId: employeeProfile.reportBank_BankId
        });
      } else if (mode === "charge") {
        setFormData({
          idEmp: employeeProfile.idEmp,
          idEmployeeSalary: (employeeProfile.salary && employeeProfile.salary.idEmployeeSalary) || null,
          salaryValue: (employeeProfile.salary && employeeProfile.salary.salaryValue) || 0,
          chargeCompensation: (employeeProfile.salary && employeeProfile.salary.chargeCompensation) || 0,
          chargeHire: (employeeProfile.salary && employeeProfile.salary.chargeHire) || 0,
          chargeSalary: (employeeProfile.salary && employeeProfile.salary.chargeSalary) || 0,
          chargeOt: (employeeProfile.salary && employeeProfile.salary.chargeOt) || 0,
          isPercentChargeHire: (employeeProfile.salary && employeeProfile.salary.isPercentChargeHire) ?
            true : false,
          start: dayjs().format("YYYY-MM-DD"),
          end: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          isEdit: (
            employeeProfile.salary && employeeProfile.salary.updateDate &&
            dayjs().isSame(employeeProfile.salary.updateDate, "date")
          ) ? true : false
        });
      } else if (mode === "cost") {
        setFormData({
          idEmp: employeeProfile.idEmp,
          costCenterCharge: employeeProfile.costCenterCharge || "",
          costElementCharge: employeeProfile.costElementCharge || ""
        });
      }
    }
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    
    if (mode === "bank" || mode === "cost") {
      console.log(formData);
      let cleanFormData = {...formData};
      Object.keys(formData).map(k => {
        if(formData[k] === ""){
          cleanFormData[k] = null;
        }
      });
      setFormData({...cleanFormData});
      const result = await dispatch(updateUser(cleanFormData));
      if (result) {
        handleCloseDialog();
        dispatch(
          getEmployeeProfile(employeeProfile.idEmp)
        );
      }
    } else if (mode === "charge") {
      formData.isPercentChargeHire = formData.chargeHire !== 0 ? formData.isPercentChargeHire : null;
      formData.chargeCompensation = formData.chargeCompensation !== 0 ? formData.chargeCompensation : null;
      formData.chargeHire = formData.chargeHire !== 0 ? formData.chargeHire : null;
      formData.chargeSalary = formData.chargeSalary !== 0 ? formData.chargeSalary : null;
      formData.chargeOt = formData.chargeOt !== 0 ? formData.chargeOt : null;
      const result = await dispatch(updateEmployeeSalaryUserProfile(formData));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmp));
      }
    }
  };

  const handleChangeCheck = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  return (
    <DrawerCustom
      title={mode === "bank" ? "แก้ไขข้อมูลธนาคาร" : "แก้ไขข้อมูลการจ่าย"}
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {/* {JSON.stringify(formData)} */}
        {mode === "bank" && (
          <Bank formData={formData} handleChange={handleChange} />
        )} 
        {mode === "charge" && (
          <Charge formData={formData} handleChange={handleChange} handleChangeCheck={handleChangeCheck} />
        )}
        {mode === "cost" && (
          <Cost formData={formData} handleChange={handleChange} />
        )}
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleCloseDialog}>
            ยกเลิก
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
