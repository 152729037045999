import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TableApproveList from "./tableApproveList";

const StyledWrapHistory = styled("div")({
  marginTop: 16,
});

function TableApproval(props) {
  const { searchName } = props;
  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );
  const [approvalSelected, setApprovalSelected] = useState([]);

  const renderLeave = () => {
    let temp = [...leaveEmployeesList];
    temp = temp.filter((x) => {
      if (x.isApprove !== null) {
        return x;
      }
    });

    let tempWithDraw = leaveEmployeeWithdrawsList.filter(
      (x) => x.isApprove !== null
    );
    temp = [...temp, ...tempWithDraw];

    temp.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });

    return temp;
  };

  return (
    <Box style={{ marginTop: 4 }}>
      <StyledWrapHistory>
        <div>
          <TableApproveList
            checkbox={false}
            dataTable={
              leaveEmployeesList && leaveEmployeeWithdrawsList
                ? renderLeave()
                : []
            }
            approvalSelected={approvalSelected}
            setApprovalSelected={setApprovalSelected}
            searchName={searchName}
          />
        </div>
      </StyledWrapHistory>
    </Box>
  );
}

export default TableApproval;
