import {
  LEVELS_FETCHING,
  LEVELS_FAILED,
  LEVELS_SUCCESS
} from "../actions/types";

const initialState = {
  result: [],
  isFetching: true,
  isError: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LEVELS_FETCHING:
      return { ...state, result: [], isFetching: true, isError: false };
    case LEVELS_FAILED:
      return { ...state, result: [], isFetching: false, isError: true };
    case LEVELS_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
};
