import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const ChartCard = (props) => {
    let { data } = props;

    const [series, setSeries] = useState([
        {
            data: [
                Math.floor(Math.random() * 3000),
                Math.floor(Math.random() * 3000),
                Math.floor(Math.random() * 3000),
                Math.floor(Math.random() * 3000),
                Math.floor(Math.random() * 3000)
            ]
        }
    ]);

    const [optionsChart, setOptionsChart] = useState({
        chart: {
            height: 350,
            type: 'line',
            toolbar:false,
            zoom: {
              enabled: false
            }
        },
        stroke: {
            curve: 'smooth',
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            labels: {
                style: {
                  fontSize:"13px",
                  colors: [
                    "#E6E5E8",
                    "#E6E5E8",
                    "#E6E5E8",
                    "#E6E5E8",
                    "#E6E5E8",
                    "#E6E5E8",
                    "#E6E5E8",
                    "#E6E5E8",
                    "#E6E5E8",
                    "#E6E5E8",
                    "#E6E5E8",
                    "#E6E5E8"
                  ]
                }
            }
        },
        yaxis: {
            min:0,
            forceNiceScale:true,
            labels: {
                style: {
                  fontSize:"13px",
                  colors: [
                    "#E6E5E8"
                  ]
                }
            }
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            labels: {
                colors: "#E6E5E8"
            },
        }
    });

    useEffect(() => {
        if(data){
            setSeries(data);
        }
    },[data]);

    return (
        <Chart height={250} options={optionsChart} series={series} type='line'/>
    );
};

export default ChartCard;