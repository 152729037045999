import {
  JOBFAMILY_DETAIL_FETCHING,
  JOBFAMILY_DETAIL_FAILED,
  JOBFAMILY_DETAIL_SUCCESS,
} from "./types";

import JobFamilyService from "../services/jobFamily.service";

export const getAllJobFamily = () => async (dispatch) => {
  try {
    const res = await JobFamilyService.getAllJobFamily();
    if (res) {
      dispatch({
        type: JOBFAMILY_DETAIL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: JOBFAMILY_DETAIL_FAILED,
    });
    console.log(err);
  }
};

export const postJobFamily = (formData) => async () => {
  try {
    const res = await JobFamilyService.postJobFamily(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const putJobFamily = (id, formData) => async () => {
  try {
    const res = await JobFamilyService.putJobFamily(id, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};
