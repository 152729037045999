import React from "react";
import { useSelector } from "react-redux";
import UserDocumentsReport from "./user-documents-report";
import VendorDocumentsReport from "./vendor-documents-report";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

function GovernmentReportIndex() {
    const { user: currentUser } = useSelector((state) => state.auth);
    const { result: userProfile } = useSelector((state) => state.userProfile);

    const isUserRole = currentUser && currentUser.roles.includes("ROLE_USER");
    const isVendorRole = currentUser && currentUser.roles.includes("ROLE_VENDOR");

    return (
        <>
            {isUserRole ? (
                <UserDocumentsReport />
            ) : isVendorRole ? (
                <VendorDocumentsReport />
            ) : (
                <Redirect to="/unauthorized" />
            )}
        </>
    );
}

export default GovernmentReportIndex;
