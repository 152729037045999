import {
    SERVICES_FETCHING,
    SERVICES_FAILED,
    SERVICES_SUCCESS
} from "./types";

import ServiceService from "../services/service.service";

export const getAllServicesVendor = () => async (dispatch) => {
    try {
        dispatch({
            type: SERVICES_FETCHING
        })
        const res = await ServiceService.getAllServicesVendor();
        if (res) {
            dispatch({
                type: SERVICES_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: SERVICES_FAILED
        })
    }
};

export const updateServicesVendor = (value, listDelete) => async () => {
    try {
        let data = {
            data: value.listServices,
            listDelete: listDelete
        };
        const res = await ServiceService.updateServicesVendor(data);
        if (res) {
            return res;
        }
    } catch (error) {
        const message =
        (error.response && error.response.data && error.response.data.name) ||
        error.name ||
        error.toString();
        return "Error";
    }
};