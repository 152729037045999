import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteEmployeeById } from "../../../../actions/employee";
import DialogNotification from "../../shared/dialogNotification";
import { getAllUsersVendor } from "../../../../actions/vendor";
import { useTranslation } from "react-i18next";

const DialogDeleteEmployee = (props) => {
  const { open, onClose, idEmp, employeesName } = props;
  const { t, i18n } = useTranslation();

  const [notificationConfig, setNotificationConfig] = useState({
    open: false,
    message: "",
    type: "success",
  });
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();

  const onDeleteEmployees = async () => {
    console.log(idEmp);
    setIsFetching(true);
    const result = await dispatch(deleteEmployeeById(idEmp));

    if (result) {
      console.log(result);
      if (result.success) {
        onClose();
        setNotificationConfig({
          open: true,
          type: "success",
          message: `${t("Delete_Employee")}${t("Success")}`,
        });
        dispatch(getAllUsersVendor());
      } else {
        onClose();
        setNotificationConfig({
          open: true,
          type: "error",
          message: result.message,
        });
      }
    } else {
      onClose();
      setNotificationConfig({
        open: true,
        type: "error",
        message: `${t("Please_Contact_Admin")}`,
      });
    }
  };

  useEffect(() => {
    setIsFetching(false);
  }, [open]);

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            maxWidth: "400px",
            width: "100%",
          },
        }}
      >
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography fontSize="16px" paddingBottom="24px" fontWeight={500}>
              {`${t("Confirm")} ${t("Delete_Employee")}`}
            </Typography>
            <Typography fontSize="18px">{employeesName}</Typography>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "16px" }}>
          <Box
            display="flex"
            flexGrow="1"
            sx={{ justifyContent: "space-between" }}
          >
            <Button onClick={onClose}>{t("Cancel")}</Button>
            <Button
              onClick={onDeleteEmployees}
              color="error"
              disabled={isFetching}
            >
              {t("Delete_Employee")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <DialogNotification
        open={notificationConfig.open}
        type={notificationConfig.type}
        onClose={() => {
          setNotificationConfig({
            ...notificationConfig,
            open: false,
          });
        }}
        message={notificationConfig.message}
      />
    </Fragment>
  );
};

export default DialogDeleteEmployee;
