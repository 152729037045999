import React, { useState, useEffect } from "react";
import liff from "@line/liff";
import { useTitle } from "react-use";
import MapContainer from "./MapContainer";

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    marginBottom: "100px",
    "& .MuiTypography-h6": {

      fontSize: "1.5rem",
    },
    "& .MuiSvgIcon-root": {
      marginRight: "5px",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.1rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  paperCheckIn: {
    padding: theme.spacing(3),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
    color: "#9e9e9e",
  },
  dateTime: {
    borderRadius: "10px",
    marginBottom: "20px",
  },
  checkInBtn: {
    color: "#fff",
    width: "100%",
    height: "50px",
    backgroundColor: "#66bb6a",
    border: "1px solid #66bb6a",
    "&:hover": {
      borderColor: "#4caf50",
      backgroundColor: "#4caf50",
    },
  },
  checkOutBtn: {
    color: "#fff",
    width: "100%",
    height: "50px",
    backgroundColor: "#ef5350",
    border: "1px solid #ef5350",
    "&:hover": {
      borderColor: "#e53935",
      backgroundColor: "#e53935",
    },
  },
  headPanel: {
    fontWeight: 600,
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  locationMessage: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  messagePassWrapper: {
    display: "flex",
    alignItems: "center",
    color: "#388e3c",
  },
  messageFailWrapper: {
    display: "flex",
    alignItems: "center",
    color: "#d32f2f",
  },
}));

function GPSCheckInOut() {
  const classes = useStyles();
  useTitle("Check In - Out - HR CORE DATA");
  const [time, setTime] = useState(new Date());
  const [state, setState] = useState({});
  const [messLocation, setMessLocation] = useState({
    inArea: true,
    message: "",
  });

  useEffect(() => {
    console.log("useEffect")
    // liff
    // .init({ liffId: "1657001593-rlxzYdgP" })
    // .then(async () => {
    //   if (!liff.isLoggedIn()) {
    //     liff.login();
    //   }
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  const handleGetProfile = async () => {
    let getProfile = await liff.getProfile();
      if (getProfile) {
        setState({
          name: getProfile.displayName,
          userLineID: getProfile.userId,
          pictureUrl: getProfile.pictureUrl,
          statusMessage: getProfile.statusMessage,
        });
      }
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Box className={classes.headingPage}>
          <Typography variant="h6">Employee Check In - Out</Typography>
        </Box>
        {/* <div>{JSON.stringify(state)}</div>
        <ButtonBlue onClick={handleGetProfile}>Check</ButtonBlue> */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <Paper className={classes.paper}>
              <MapContainer
                messLocation={messLocation}
                setMessLocation={setMessLocation}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper className={classes.paperCheckIn}>
              <Box className={classes.dateTime}>
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography className={classes.headPanel}>
                    Check In - Out
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: "10px" }}>
                  <Grid container>
                    <Grid item xs={4} md={4} lg={4}>
                      <Typography variant="body1" className={classes.wrapIcon}>
                        <TodayIcon />
                        วันที่
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1">
                        {dayjs(new Date()).format("DD MMMM YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container>
                    <Grid item xs={4} md={4} lg={4}>
                      <Typography variant="body1" className={classes.wrapIcon}>
                        <AccessTimeIcon />
                        เวลา
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1">
                        {dayjs(time).format("HH:mm:ss")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Divider className={classes.divider} />
              <Box className={classes.locationMessage}>
                {messLocation.message ? (
                  <Typography
                    variant="body1"
                    className={
                      messLocation.inArea
                        ? classes.messagePassWrapper
                        : classes.messageFailWrapper
                    }
                  >
                    {messLocation.inArea ? <CheckCircleIcon /> : <CancelIcon />}
                    {messLocation.message}
                  </Typography>
                ) : null}
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Button
                      variant="outlined"
                      className={classes.checkInBtn}
                      startIcon={<LoginIcon />}
                      disabled={messLocation.inArea ? false : true}
                    >
                      Check In
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Button
                      variant="outlined"
                      className={classes.checkOutBtn}
                      startIcon={<LogoutIcon />}
                      disabled={messLocation.inArea ? false : true}
                    >
                      Check Out
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default GPSCheckInOut;
