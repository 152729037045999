import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  TableRow,
  TableCell,
  TextField,
  Typography,
  IconButton,
  Popper,
  Box,
  Button,
  FormControl,
} from "@mui/material";

import dayjs from "dayjs";
import "dayjs/locale/th";
import utils from "../../../../../../utils";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

const StyledTableRow = styled(TableRow)({
  marginBottom: 2,
});

const StyledTableCell = styled(TableCell)({
  padding: 0,
  border: "none",
  "& .wrap": {
    height: 72,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    borderTop: "1px solid #e1e1e1",
    borderLeft: "1px solid #e1e1e1",
    borderBottom: "1px solid #e1e1e1",
    "&.first": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "&.last": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderRight: "1px solid #e1e1e1",
    },
  },
  "&.order": {
    width: 28,
  },
  "&.date-document": {
    width: 140,
    "& .MuiInputBase-root": {
      paddingRight: 8,
    },
  },
  "&.service": {
    width: 150,
  },
  "&.description": {
    width: 200,
  },
  "&.amount": {
    width: 104,
  },
  "&.price": {
    width: 104,
  },
  "&.discount": {
    width: 104,
  },
  "&.tax": {
    width: 64,
  },
  "&.price-before-tax": {
    width: 104,
  },
  "&.tax-withholding": {
    width: 104,
  },
  "&.delete": {
    width: 32,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  margin: "0px !important",
  input: {
    textAlign: "right",
  },
  "& .MuiInputBase-root": {
    borderRadius: 0,
    height: 56,
    "& fieldset": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      padding: "5px !important",
    },
  },
});

const StyledTextArea = styled(TextField)({
  width: "100%",
  margin: "0px !important",
  "& .MuiInputBase-root": {
    borderRadius: 0,
    padding: "5px !important",
    height: 72,
    "& textarea": {
      height: "72px !important",
      overflow: "auto !important",
    },
    "& fieldset": {
      border: "none",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    paddingLeft: 0,
    paddingRight: "48px !important",
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const RowSummaryItemExpense = (props) => {
  const {
    index,
    handleDeleteRow,
    count,
    open,
    handleClickMenuTax,
    handleValueChange,
    handleDateChange,
    data,
  } = props;
  const today = dayjs().toDate();
  const [documentDate, setDocumentDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );
  const handleChangeValue = (event) => {
    handleValueChange(event, index);
  };

  const handleChangeDate = (newValue) => {
    handleDateChange(newValue, index);
  };

  return (
    <StyledTableRow>
      <StyledTableCell className="cell-table order">
        <div className="wrap">{index + 1}</div>
      </StyledTableCell>
      <StyledTableCell className="cell-table service">
        <div className="wrap">{data.service}</div>
      </StyledTableCell>

      <StyledTableCell className="cell-table amount">
        <div className="wrap">
          {utils.numberWithCommas(parseFloat(data.amount))}
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table price">
        <div className="wrap">
          {utils.numberWithCommas(parseFloat(data.unitPriceInternational))}
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table tax" align="center">
        <div className="wrap">{data.taxInternational}</div>
      </StyledTableCell>
      <StyledTableCell className="cell-table price-before-tax">
        <div className="wrap">
          {utils.numberWithCommas(parseFloat(data.totalBeforeTaxInternational))}
        </div>
      </StyledTableCell>
      <StyledTableCell className="cell-table tax-withholding">
        <div className="wrap last">
          {utils.numberWithCommas(parseFloat(data.withholdingInternational))}
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default RowSummaryItemExpense;
