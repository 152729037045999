import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import { Container, CardContent } from "@mui/material";

import dayjs from "dayjs";

import General from "./general";
import PerDiem from "./per-diem";
import Mileage from "./mileage";
import { updateStatusExpense } from "../../../../../actions/expense";

const StyledRoot = styled("div")({
  maxWidth: "1280px !important",
  margin: "auto",
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
    },
  },
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  "& .MuiFormControl-root": {
    marginBottom: 8,
  },
  "& .wrap-head": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-row-sum": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const ExpenseViewPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expenseDetail } = useSelector((state) => state.expenseDetail);
  const { typeExpense, idExpense, idEmp, mode } = useParams();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [isAPIpending, setIsAPIpending] = useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleSubmit = async (actionType, status, comment) => {
    setIsAPIpending(true);
    const data = {
      date: dayjs(dayjs().toDate()).format("YYYY/MM/DD HH:mm"),
      comment: comment,
      actionType: actionType,
      status: status,
      typeExpense: expenseDetail.expenseType,
    };
    let result = await dispatch(updateStatusExpense(idExpense, data));

    if (result.status === 200) {
      setIsAPIpending(false);
      handleChangeAlertType("success");
      handleOpenAlert();
      history.push("/expense");
    } else {
      setIsAPIpending(false);
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="xl">
        <StyledCardContent>
          {typeExpense === "ServiceAndGoods" && (
            <General
              idExpense={idExpense}
              idEmp={idEmp}
              mode={mode}
              handleSubmit={handleSubmit}
              handleCloseAlert={handleCloseAlert}
              openAlert={openAlert}
              alertType={alertType}
              isAPIpending={isAPIpending}
            />
          )}
          {typeExpense === "Allowance" && (
            <PerDiem
              idExpense={idExpense}
              idEmp={idEmp}
              mode={mode}
              handleSubmit={handleSubmit}
              handleCloseAlert={handleCloseAlert}
              openAlert={openAlert}
              alertType={alertType}
              isAPIpending={isAPIpending}
            />
          )}
          {typeExpense === "Mileage" && (
            <Mileage
              idExpense={idExpense}
              idEmp={idEmp}
              mode={mode}
              handleSubmit={handleSubmit}
              handleCloseAlert={handleCloseAlert}
              openAlert={openAlert}
              alertType={alertType}
              isAPIpending={isAPIpending}
            />
          )}
        </StyledCardContent>
      </Container>
    </StyledRoot>
  );
};

export default ExpenseViewPage;
