import {
  MANAGER_ID_FETCHING,
  MANAGER_ID_FAILED,
  MANAGER_ID_SUCCESS,
  MANAGER_GROUP_FETCHING,
  MANAGER_GROUP_FAILED,
  MANAGER_GROUP_SUCCESS,
} from "./types";

import ManagerService from "../services/manager.service";

export const getManagerId = (email) => async (dispatch) => {
  try {
    const res = await ManagerService.getManagerId(email);
    if (res) {
      dispatch({
        type: MANAGER_ID_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MANAGER_ID_FAILED,
    });
    console.log(err);
  }
};

export const getManagerGroup = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: MANAGER_GROUP_FETCHING,
    });
    const res = await ManagerService.getManagerGroup(idCompany);
    if (res) {
      dispatch({
        type: MANAGER_GROUP_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MANAGER_GROUP_FAILED,
    });
    // console.log(err);
  }
};

export const addManagerGroup = (formData) => async () => {
  try {
    const res = await ManagerService.addManagerGroup(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const updateManagerGroup = (idManagerGroup, formData) => async () => {
  try {
    const res = await ManagerService.updateManagerGroup(
      idManagerGroup,
      formData
    );
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const deleteManagerGroup = (idManagerGroup) => async () => {
  try {
    const res = await ManagerService.deleteManagerGroup(idManagerGroup);
    if (res) {
      return res;
    }
  } catch (error) {
    // console.log(error.response);
    if (error.response) {
      return error.response;
    }
  }
};

export const updateManager = (formData) => async () => {
  try {
    const res = await ManagerService.updateManager(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
  }
};
