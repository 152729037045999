import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import Container from "@mui/material/Container";
import {
  Box,
  Stack,
  Typography
} from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DataTable from "./dataTable"
import Detail from "./detail"
import DialogCreateBill from "./DialogCreateBill"
import AddIcon from '@mui/icons-material/Add';
import dayjs from "dayjs";
import { getAllAdditionsByVender } from "../../../../actions/billing";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
});

const StyledTypeDiv = styled("div")({
  margin: "16px 0px",
  "& .button": {
    padding: "16px 32px"
  },
  "& .button:first-child": {
    borderRadius: "16px 0px 0px 16px"
  },
  "& .button:last-child": {
    borderRadius: "0px 16px 16px 0px",
  }
});

const StyleButtonBlue = styled(ButtonBlue)(({ active, currentValue }) => ({
  color: active === "true" ? "#007afe" : "#C4C4C4",
  borderColor: active === "true" ? "#007afe" : "#C4C4C4",
  ...active !== "true" ? {
    borderRightWidth: currentValue === "0" ? "1px" : "0px",
    borderLeftWidth: currentValue === "1" ? "1px" : "0px"
  } : {}
}));

const BillingPage = () => {
  const [id] = useState(null);
  const [type, setType] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [additionLists, setAdditionLists] = useState(null);
  const [seeMoreData, setSeeMoreData] = useState(true);

  const onChangeType = (valueType) => {
    setType(valueType)
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  const fetchedAdditions = async () => {
    try {
      const response = await getAllAdditionsByVender();
      if (response && response.data) {
        setAdditionLists(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchedAdditions();
  }, []);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            Billing list
          </Typography>
          {type === 1 && (
            <ButtonBlue
              variant={"contained"}
              startIcon={<AddIcon />}
              onClick={() => handleOpenDialog()}
            >
              {/* {t("NewSalary")} */}
              ทำบิลจ่ายเหมาใหม่
            </ButtonBlue>
          )}
        </Box>

        {seeMoreData && (
          <StyledTypeDiv>
            <StyleButtonBlue
              className="button"
              variant="outlined"
              active={type === 0 ? "true" : "false"}
              currentValue={`${type}`}
              onClick={() => onChangeType(0)}
            >
              จ่ายตามจริง
            </StyleButtonBlue>
            <StyleButtonBlue
              className="button"
              variant="outlined"
              active={type === 1 ? "true" : "false"}
              currentValue={`${type}`}
              onClick={() => onChangeType(1)}
            >
              จ่ายเหมา
            </StyleButtonBlue>
          </StyledTypeDiv>
        )}

        <DataTable type={type} setSeeMoreData={setSeeMoreData} />
        {/* <Detail /> */}
      </Container>

      <DialogCreateBill
        open={openDialog}
        handleClose={handleCloseDialog}
        additionLists={additionLists}
      />
    </StyledRoot>
  );
};

export default BillingPage;
