import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  avatar: {
    marginRight: 30,
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
    marginLeft: 30,
  },
  imageUpload: {
    display: 'flex',
    justifyContent: 'center',
  },
  imagePreview: {
    cursor: 'pointer',
    position: 'relative',
    zIndex: 20,
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    display: 'block',
    objectFit: 'cover',
    transition: 'all 0.3s ease',
    border: '4px solid #fff',
    margin: '10px',
  },
}));

const StyledRoot = styled("div")({
  '& .MuiTypography-subtitle1': {
    fontWeight: 600,
    color: '#fff',
  },
  '& .MuiSvgIcon-root': {
    cursor: 'pointer',
    color: '#fff',
    width: '120px',
    height: '120px',
  },
});

const StyledDropzone = styled("div")({
  "&.dropzoneProfile":{
    width: "100%",
    height: "100%",
  },
  "& .uploadContainer":{
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed #fff',
    borderRadius: '10px',
    padding: 16,
    backgroundColor: '#2a68d3',
  }
});

export default function ProfileImage(props) {
  const classes = useStyles();
  const { empImage, setEmpImage } = props;
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/*',
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setEmpImage(acceptedFiles);
    },
  });

  const thumbs = files.map((file) => (
    <div className={classes.uploadContainer}>
      <div className={classes.imageUpload}>
        <div key={file.name}>
          <img
            alt=' '
            src={file.preview}
            className={classes.imagePreview}
            onClick={open}
          />
        </div>
      </div>
      <Typography variant='subtitle1' component='div' align='center'>
        Employee Image
      </Typography>
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <StyledRoot>
      <StyledDropzone {...getRootProps({ className: 'dropzoneProfile' })}>
        <input {...getInputProps()} />
        {files == 0 ? (
          <div className={`uploadContainer`}>
            <Typography variant='subtitle1' component='div' align='center'>
              Upload
            </Typography>
            <div className={classes.imageUpload}>
              <CloudUploadIcon onClick={open} />
            </div>
            <Typography variant='subtitle1' component='div' align='center'>
              Employee Image
            </Typography>
          </div>
        ) : (
          <aside>{thumbs}</aside>
        )}
      </StyledDropzone>
    </StyledRoot>
  );
}
