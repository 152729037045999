import React, { useEffect, useState, useRef, Fragment } from "react";
import dayjs from "dayjs";
import { withRouter, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import LoopIcon from "@mui/icons-material/Loop";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  Grouping,
  Summary,
  TotalItem,
  GroupItem,
} from "devextreme-react/data-grid";

import utils from "../../../../../utils";

import { getAllBillingCompany } from "../../../../../actions/billing";

const StyledWrapTable = styled("div")({
  "& .dx-header-row ": {},
});

const DataTable = (props) => {
  const dispatch = useDispatch();
  const { additionVendor, listBillingDetail } = props;
  const { result: billingDetail } = useSelector((state) => state.billingDetail);

  const fullNameColumn_calculateCellValue = (rowData) => {
    return rowData.firstname + " " + rowData.lastname;
  };

  const valueOfNumber = (data, key) => {
    return data[key] || "";
  };

  const customizeNumber = (data) => {
    return `${utils.numberWithCommas(data.value || 0)} บาท`;
  };

  const renderData = () => {
    var detailPayrun = [...listBillingDetail];

    const tempList = [];
    const tempListPosition = [];

    const grouped = utils.groupBy(
      detailPayrun,
      (value) => value.departmentName
    );

    grouped.forEach(function (value, key) {
      const temp = {
        key: key,
        count: value.length,
      };
      tempList.push(temp);

      const positions = utils.groupBy(
        value,
        (value) => value.positionsName
      );

      positions.forEach((v, k) => {
        tempListPosition.push({
          key: k,
          keymain: key,
          count: v.length
        })
      })
    });

    for (let index = 0; index < detailPayrun.length; index++) {
      const element = detailPayrun[index];
      const found = tempList.find(
        (item) => item.key === element.departmentName
      );
      let foundPosition = tempListPosition.find(
        (item) => ((item.key === element.positionsName) && (item.keymain === element.departmentName))
      );
      element.displayDepartmentGroup = `${found.key === null ? "-" : found.key
        }`;
      element.displayPositionGroup = `${foundPosition.key
        }`
    }

    return detailPayrun;
  };

  return (
    <StyledWrapTable>
      <DataGrid
        id="gridBillingDetail"
        dataSource={renderData()}
        showBorders={true}
        columnWidth={200}
        keyExpr="idBillingDetail"
        noDataText="ไม่มี Billing พนักงาน"
      >
        <Grouping autoExpandAll={false} />
        <Column
          caption="ชื่อ-สกุล"
          dataType="string"
          minWidth={400}
          calculateCellValue={fullNameColumn_calculateCellValue}
        />
        {/* <Column 
          dataField="positionsName" 
          caption="ตำแหน่ง" 
        /> */}
        <Column
          dataField="displayPositionGroup"
          caption="ตำแหน่ง"
          groupIndex={1}
        />

        <Column
          dataField="displayDepartmentGroup"
          caption="หน่วยงาน"
          groupIndex={0}
        //calculateCellValue={departmentName_calculateCellValue}
        />

        <Column dataField="salary" caption="ค่าแรง" format="###,###,##0.00" />

        <Column
          dataField="chargeSalary"
          caption="Charge ค่าแรง"
          format="###,###,##0.00"
        />

        <Column
          dataField="totalOT"
          caption="ค่าล่วงเวลา"
          format="###,###,##0.00"
        />
        <Column
          dataField="hoursOT1"
          caption="OTx1 (ชม.)"
          dataType="string"
          calculateCellValue={(data) => valueOfNumber(data, `hoursOT1`)}
        />
        <Column
          dataField="perHoursOT1"
          caption="OTx1 (บาท/ชม.)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `perHoursOT1`)}
        />
        <Column
          dataField="chargeOT1"
          caption="OTx1 (Charge)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `chargeOT1`)}
        />
        <Column
          dataField="totalOT1"
          caption="OTx1 (เรียกเก็บ)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `totalOT1`)}
        />
        <Column
          dataField="hoursOT1dot5"
          caption="OTx1.5 (ชม.)"
          dataType="string"
          calculateCellValue={(data) => valueOfNumber(data, `hoursOT1dot5`)}
        />
        <Column
          dataField="perHoursOT1dot5"
          caption="OTx1.5 (บาท/ชม.)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `perHoursOT1dot5`)}
        />
        <Column
          dataField="chargeOT1dot5"
          caption="OTx1.5 (Charge)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `chargeOT1dot5`)}
        />
        <Column
          dataField="totalOT1dot5"
          caption="OTx1.5 (เรียกเก็บ)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `totalOT1dot5`)}
        />
        <Column
          dataField="hoursOT2"
          caption="OTx2 (ชม.)"
          dataType="string"
          calculateCellValue={(data) => valueOfNumber(data, `hoursOT2`)}
        />
        <Column
          dataField="perHoursOT2"
          caption="OTx2 (บาท/ชม.)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `perHoursOT2`)}
        />
        <Column
          dataField="chargeOT2"
          caption="OTx2 (Charge)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `chargeOT2`)}
        />
        <Column
          dataField="totalOT2"
          caption="OTx2 (เรียกเก็บ)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `totalOT2`)}
        />
        <Column
          dataField="hoursOT3"
          caption="OTx3 (ชม.)"
          dataType="string"
          calculateCellValue={(data) => valueOfNumber(data, `hoursOT3`)}
        />
        <Column
          dataField="perHoursOT3"
          caption="OTx3 (บาท/ชม.)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `perHoursOT3`)}
        />
        <Column
          dataField="chargeOT3"
          caption="OTx3 (Charge)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `chargeOT3`)}
        />
        <Column
          dataField="totalOT3"
          caption="OTx3 (เรียกเก็บ)"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `totalOT3`)}
        />
        <Column
          dataField="totalAllOT"
          caption="รวม OT (เรียกเก็บ)"
          format="###,###,##0.00"
        />
        <Column
          dataField="chargeCost"
          caption="Charge เหมา"
          format="###,###,##0.00"
        />
        <Column
          dataField="chargeCompensated"
          caption="Charge ค่าชดเชย"
          format="###,###,##0.00"
        />
        <Column
          dataField="shiftCost"
          caption="ค่ากะ"
          format="###,###,##0.00"
        />
        <Column
          dataField="chargeShiftCost"
          caption="Charge ค่ากะ"
          format="###,###,##0.00"
        />
        {/* {(additionVendor && additionVendor.length > 0) && 
          additionVendor.map(addition => (
            [
              <Column
                dataField={`valueAddition${addition.idAddition}`}
                caption={`${addition.name}(${addition.code})`}
                format="###,###,##0.00"
                width="auto"
                minWidth={200}
                calculateCellValue={(data) => valueOfNumber(data, `valueAddition${addition.idAddition}`)}
              />
            ]
          ))
        }
        <Column
          dataField="totalAddition"
          caption="รวม Addtion"
          format="###,###,##0.00"
        /> */}
        <Column
          dataField="totalCharges"
          caption="รวม Charge"
          format="###,###,##0.00"
        />
        <Column
          dataField="totalEarning"
          caption="รวมรายรับ"
          format="###,###,##0.00"
        />
        <Column
          dataField="otherAddition"
          caption="เพิ่มค่าอื่นๆ"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `otherAddition`)}
        />
        <Column
          dataField="otherDeduction"
          caption="หักค่าอื่นๆ"
          format="###,###,##0.00"
          calculateCellValue={(data) => valueOfNumber(data, `otherDeduction`)}
        />
        <Column
          dataField="totalOther"
          caption="รวมค่าอื่นๆ"
          format="###,###,##0.00"
        />
        <Column
          dataField="totalBilling"
          caption="เรียกเก็บสุทธิ"
          format="###,###,##0.00"
        />
        <Summary>
          <GroupItem
            column="displayDepartmentGroup"
            summaryType="count"
            displayFormat="{0} คน"
          />
          <GroupItem
            column="salary"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="totalOT"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="chargeOT1"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="totalOT1"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="chargeOT1dot5"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="totalOT1dot5"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="chargeOT2"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="totalOT2"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="chargeOT3"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="totalOT3"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="chargeSalary"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="totalAllOT"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="chargeCost"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="chargeCompensated"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="shiftCost"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="chargeShiftCost"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          {(additionVendor && additionVendor.length > 0) &&
            additionVendor.map(addition => (
              [
                <GroupItem
                  column={`valueAddition${addition.idAddition}`}
                  summaryType="sum"
                  valueFormat="###,###,##0.00"
                  displayFormat="{0}"
                  alignByColumn={true}
                />
              ]
            ))
          }
          <GroupItem
            column="totalAddition"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="totalCharges"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="totalEarning"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="otherAddition"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="otherDeduction"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="totalOther"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />
          <GroupItem
            column="totalBilling"
            summaryType="sum"
            valueFormat="###,###,##0.00"
            displayFormat="{0}"
            alignByColumn={true}
          />

          <TotalItem
            column="salary"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="chargeSalary"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="totalOT"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="chargeOT1"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="totalOT1"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="chargeOT1dot5"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="totalOT1dot5"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="chargeOT2"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="totalOT2"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="chargeOT3"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="totalOT3"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="totalAllOT"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="chargeCost"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="chargeCompensated"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="shiftCost"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="chargeShiftCost"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          {(additionVendor && additionVendor.length > 0) &&
            additionVendor.map(addition => (
              [
                <TotalItem
                  column={`valueAddition${addition.idAddition}`}
                  summaryType="sum"
                  customizeText={customizeNumber}
                />
              ]
            ))
          }
          <TotalItem
            column="totalAddition"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="totalCharges"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="totalEarning"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="otherAddition"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="otherDeduction"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="totalOther"
            summaryType="sum"
            customizeText={customizeNumber}
          />
          <TotalItem
            column="totalBilling"
            summaryType="sum"
            customizeText={customizeNumber}
          />
        </Summary>
        <Paging defaultPageSize={10} />
        <Pager
          visible={(listBillingDetail && listBillingDetail.length > 0) ? true : false}
          allowedPageSizes={[
            10,
            ...(listBillingDetail && listBillingDetail.length > 10) ? [20] : [],
            ...(listBillingDetail && listBillingDetail.length > 20) ? [30] : []
          ]}
          showPageSizeSelector={true}
          // showNavigationButtons={true}
          showInfo={true}
        />
      </DataGrid>
      {/* <Typography variant="caption" color="text.third">
        *** หมายถึง รอการตรวจสอบ
      </Typography> */}
    </StyledWrapTable>
  );
};
export default DataTable;
