import {
    EXPERIENCE_FETCHING,
    EXPERIENCE_FAILED,
    EXPERIENCE_SUCCESS
} from "./types";

import experienceService from "../services/experience.service";

export const getExperienceByIdEmp = (idUser) => async (dispatch) => {
    try {
        dispatch({
            type: EXPERIENCE_FETCHING
        });
        const res = await experienceService.getExperienceByIdEmp(idUser);
        if(res){
            dispatch({
                type: EXPERIENCE_SUCCESS,
                payload: res.data
            });
        }
    } catch (error) {
        dispatch({
            type: EXPERIENCE_FAILED
        });
    }
};

export const addExperience = (employeeProfile, data) => async() => {
    try {
        const res = await experienceService.addExperience(employeeProfile, data);
        if(res) {
            return res.data;
        }
    } catch (error) {
        return "Error"
    }
};

export const updateExperience = (experiencesEmp, data) => async() => {
    try {
        const res = await experienceService.updateExperience(experiencesEmp, data);
        if(res) {
            return res.data;
        }
    } catch (error) {
        return "Error"
    }
};

export const deleteExperience = (idExp) => async() => {
    try {
        const res = await experienceService.deleteExperience(idExp);
        if(res){
            return res.data;
        }
    } catch (error) {
        return "Error"
    }
};