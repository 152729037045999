import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import "dayjs/locale/th";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ButtonOrange from "../../shared/general/ButtonOrange";
import DatePickerWeek from "../../shared/general/DatePickerWeek";
import TableOTReport from "./tableOTReport";

import FilterListIcon from "@mui/icons-material/FilterList";

import { Grid } from "@mui/material";
import { getAllTimeline } from "../../../../actions/event";
import ButtonBlue from "../../shared/general/ButtonBlue";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledCard = styled(Card)({
  marginBottom: 16,
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

function createData(status, name, created, start, stop, protein) {
  return { status, name, created, start, stop, protein };
}



function OTReport() {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(today);
  const [filter, setFilter] = React.useState([
    {
      name: "normal",
      checked: true,
      lable: "การลา",
    },
    {
      name: "incomplate",
      checked: true,
      lable: "รับรองเวลาทำงาน",
    },
    {
      name: "ot",
      checked: true,
      lable: "ยกเลิก",
    },
  ]);
  const [events, setEvents] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChange = (event) => {
    const temp = [...filter];
    for (let index = 0; index < temp.length; index++) {
      if (temp[index].name === event.target.name) {
        temp[index].checked = !temp[index].checked;
        break;
      }
    }

    setFilter(temp);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    async function getdata() {
      let result = await dispatch(getAllTimeline());
      if (result) {
        setEvents(result);
      }
    }
    getdata();
  }, []);

  const handleClickSearch = () => {};

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          รายงานค่าล่วงเวลา
        </Typography>
        <div style={{ marginTop: 16 }}>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 16 }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4}>
                <DatePickerWeek setValue={setValue} value={value} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ButtonBlue variant={"contained"} onClick={handleClickSearch}>
                  ค้นหา
                </ButtonBlue>
              </Grid>
            </Grid>
          </div>
          <div></div>
        </div>
        <StyledCard style={{ paddingBottom: 32 }}>
          <TableOTReport value={value} />
        </StyledCard>
      </Container>
    </StyledRoot>
  );
}

export default OTReport;
