import MyDocument from "../../../pages/assets/-_Branding & Communication.png"

export const reportLists = [
    // {
    //     name: "ภ.ง.ด. 91",
    //     image: MyDocument,
    //     link: "PND91",
    //     key: "pnd91Documentt"
    // },
    {
        name: "50 ทวิ",
        image: MyDocument,
        link: "50tawi",
        key: "tawi50Documentt"
    },
    {
        name: "ภ.ง.ด 1ก",
        image: MyDocument,
        link: "PND1k",
        key: "tawi50Documentt"
    }
]