import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Box, 
    Divider,
    Grid, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    styled, 
    Typography,
    useMediaQuery
} from '@mui/material';
import dayjs from 'dayjs';

import {
    Edit
} from '@mui/icons-material';

import ButtonBlue from '../../../shared/general/ButtonBlue';
import DrawerProvidentFund from './drawerProvidentFund';
import DrawerTablePF from './drawerTablePF';

import { 
    getAllProvidentFundByVendor, 
    getAllPFScheduleByVendor 
} from "../../../../../actions/vendor";

const Root = styled("div")({
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
});

const Header = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

const Body = styled(Box)({
    display:"flex",
    flexDirection: "column",
    alignItems: "center",
    "& .topicGrid":{
        fontSize:"1.25rem",
    },
    "& .textCenter":{
        textAlign:"center"
    },
    "& .topicChanging":{
        fontSize:"1.5rem",
    },
    "& .hiddenBorder":{
        borderBottom:"0"
    },
    "& .topicRowColumn":{
        fontSize:"1.20rem",
        "& .index":{
            fontWeight:"bold",
            marginLeft:"10px"
        }
    },
    "& .textDate":{
        fontSize:"1.3rem"
    },
    "& .textBold":{
        fontWeight:"bold"
    }
});

const MockData = [
    {
        id:1,
        minWorkYear:0,
        employeePercent: 2,
        companyPercent: 2
    },
    {
        id:2,
        minWorkYear:1,
        employeePercent: 4,
        companyPercent: 4
    },
    {
        id:3,
        minWorkYear:2,
        employeePercent: 5,
        companyPercent: 5
    },
    {
        id:4,
        minWorkYear:3,
        employeePercent: 6,
        companyPercent: 6
    },
    {
        id:5,
        minWorkYear:4,
        employeePercent: 8,
        companyPercent: 8
    },
    {
        id:6,
        minWorkYear:5,
        employeePercent: 10,
        companyPercent: 10
    },
];

const MockListChanging = [
    {
        id:1,
        dateStart: dayjs().day(1).month(1),
        dateEnd: dayjs().day(15).month(1)
    },
    {
        id:2,
        dateStart: dayjs().day(3).month(5),
        dateEnd: dayjs().day(16).month(5)
    },
    {
        id:3,
        dateStart: dayjs().day(1).month(8),
        dateEnd: dayjs().day(10).month(8)
    },
    {
        id:4,
        dateStart: dayjs().day(16).month(9),
        dateEnd: dayjs().day(23).month(9)
    }
]

const PF = () => {
    const dispatch = useDispatch();
    const { result: AllProvidentFunds } = useSelector(state => state.vendorProvidentFund);
    const { result: AllSchedules } = useSelector(state => state.vendorPfSchedule);
    const phoneSceen  = useMediaQuery("(max-width: 600px)");
    const [openPFDialog, setOpenPFDialog] = useState(false);
    const [openTableDialog, setOpenTableDialog] = useState(false);

    useEffect(() => {
        dispatch(getAllProvidentFundByVendor());
        dispatch(getAllPFScheduleByVendor());
    },[]);
    
    const handleClosePFDialog = () => {
        setOpenPFDialog(false);
    }

    const handleCloseTableDialog = () => {
        setOpenTableDialog(false);
    }

    return (
        <Root>
            <Header>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">กองทุนสำรองเลี้ยงชีพ (PF)</Typography>
                    </Grid>
                    <Grid item>
                        <ButtonBlue
                            variant={"contained"}
                            startIcon={<Edit />}
                            onClick={() => {
                                setOpenPFDialog(true);
                            }}
                        >
                            แก้ไข
                        </ButtonBlue>
                    </Grid>
                </Grid>
            </Header>
            <Body>
                <Grid container spacing={3}>
                    {AllProvidentFunds && AllProvidentFunds.length > 0 ? 
                        AllProvidentFunds.map((pd, index) => (
                            <React.Fragment key={pd.idProvidentFund}>
                                <Grid item xs={12} sm={4}>
                                    <Typography className="textCenter topicGrid" gutterBottom>
                                        ถึงอายุไม่เกิน (ปี)
                                    </Typography>
                                    <Typography className="textCenter" variant="h4">
                                        {pd.minWorkYear ? `${pd.minWorkYear} ปี` : "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography className="textCenter topicGrid" gutterBottom>
                                        สะสมส่วนพนักงานสูงสุด
                                    </Typography>
                                    <Typography className="textCenter" variant="h4">
                                        {pd.employeePercent ? `${pd.employeePercent} %` : "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography className="textCenter topicGrid" gutterBottom>
                                        สะสมส่วนบริษัทสูงสุด
                                    </Typography>
                                    <Typography className="textCenter" variant="h4">
                                        {pd.vendorPercent ? `${pd.vendorPercent} %` : "-"}
                                    </Typography>
                                </Grid>
                                {phoneSceen && index < AllProvidentFunds.length - 1 && 
                                    <Grid item xs={12}>
                                        <Divider style={{ borderBottom:"2px solid rgba(0,0,0,0.09)" }}/>
                                    </Grid>
                                }
                            </React.Fragment>
                        ))
                        :
                        <Grid item xs={12}>
                            <Grid container justifyContent="center">
                                <Grid item style={{ margin:"30px 0" }}>
                                    <Typography>ไม่พบข้อมูล</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Divider style={{ width:"100%", borderBottom:"2px solid rgba(0,0,0,0.09)", marginTop:"30px" }}/>
                <Box style={{ width: '100%', marginTop: "30px", display:"flex", flexDirection:"column", alignItems:"center" }}>
                    <Box style={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"space-between" }}>
                        <Box style={{ display:"flex", alignItems:"center" }}>
                            <i className="fa-solid fa-calendar-clock"  style={{ fontSize:"1.5rem", marginRight:"10px", color: "#007afe" }}/>
                            <Typography className="topicChanging" style={{ width:"100%" }}>
                                วันที่สามารถเปลี่ยนแปลง
                            </Typography>
                        </Box>
                        <ButtonBlue
                            variant={"contained"}
                            startIcon={<Edit />}
                            onClick={() => {
                                setOpenTableDialog(true);
                            }}
                        >
                            แก้ไข
                        </ButtonBlue>
                    </Box>
                    <TableContainer style={{ width:"90%" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell 
                                        className="hiddenBorder"
                                        style={{ minWidth:"153px" }}
                                    >
                                    </TableCell>
                                    <TableCell 
                                        className="hiddenBorder topicRowColumn"
                                        align="center"
                                        style={{ minWidth:"233px" }}
                                    >
                                        วันที่เริ่มต้น
                                    </TableCell>
                                    <TableCell 
                                        className="hiddenBorder topicRowColumn"
                                        align="center"
                                        style={{ minWidth:"233px" }}
                                    >
                                        วันที่สิ้นสุด
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {AllSchedules && AllSchedules.length > 0 ? 
                                    AllSchedules.map((changing,index) => (
                                        <TableRow key={changing.idVendorPFSchedule}>
                                            <TableCell className="hiddenBorder topicRowColumn">
                                                รอบที่<span className="index">{index+1}</span>
                                            </TableCell>
                                            <TableCell 
                                                className="hiddenBorder textDate textBold" 
                                                align="center"
                                            >
                                                {changing.dateStart ? dayjs(changing.dateStart).format("DD/MM/YYYY") : "-"}
                                            </TableCell>
                                            <TableCell 
                                                className="hiddenBorder textDate textBold" 
                                                align="center"
                                            >
                                                {changing.dateEnd ? dayjs(changing.dateEnd).format("DD/MM/YYYY") : "-"}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    :
                                    [...Array(4)].map((value, index) => (
                                        <TableRow key={index}>
                                            <TableCell className="hiddenBorder topicRowColumn">
                                                รอบที่<span className="index">{index+1}</span>
                                            </TableCell>
                                            <TableCell 
                                                className="hiddenBorder textDate textBold" 
                                                align="center"
                                            >
                                                {"-"}
                                            </TableCell>
                                            <TableCell 
                                                className="hiddenBorder textDate textBold" 
                                                align="center"
                                            >
                                                {"-"}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Body>
            <DrawerProvidentFund 
                open={openPFDialog}
                onClose={handleClosePFDialog}
                data={AllProvidentFunds}
            />
            <DrawerTablePF
                open={openTableDialog}
                onClose={handleCloseTableDialog}
                data={AllSchedules}
            />
        </Root>
    );
};

export default PF;