import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Box, Card, Container, Grid, LinearProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import GroupsIcon from "@mui/icons-material/Groups";
import StyledCard from "../../../shared/general/CardDark";
import CardSummary from "../cardSummary";
import CardChart from "../cardChart";
import CardRank from "../cardRank";
import { getAllEmployees } from "../../../../../actions/employee";
//import { getAllAttendanceToManager } from "../../../../../actions/attendance";

import utils from "../../../../../utils";

const rand40 = () => {
  return Math.floor(Math.random() * 40);
};

const StyledWrapOT36Hours = styled(Card)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  backgroundColor: "#fff",
  borderRadius: 20,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  position: "relative",
  padding: 24,
  "& .MuiTypography-h3": {
    fontSize: 80,
  },
  "& .iconEmployee": {
    position: "absolute",
    right: 16,
    bottom: 36,
    color: "#007afe",
    "& svg": {
      fontSize: 120,
    },
    "& i": {
      fontSize: 100,
    },
  },
});

export default function DashboardWorkingTime() {
  const dispatch = useDispatch();
  const [absentWork, setAbsentWork] = useState([]);
  const [lateWork, setLateWork] = useState([]);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: empStore } = useSelector((state) => state.employees);
  const { result: attendanceStore } = useSelector((state) => state.attendance);
  const [employees, setEmployees] = useState([]);
  const [totalAbsents, setTotalAbsents] = useState(0);
  const [totalLateWorks, setTotalLateWorks] = useState(0);
  const [totalLeaveWorks, setTotalLeaveWorks] = useState(0);
  const [topAbsents, setTopAbsents] = useState([]);
  const [topLateWorks, setTopLateWorks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [attendanceTemp, setAttendanceTemp] = useState([]);

  const fetchData = (attendanceList) => {
    let attends = [];
    employees.forEach(function (emp) {
      let empAtt = attendanceList.filter((x) => x.idEmp === emp.idEmp);

      let sumAbsent = empAtt.reduce(function (accumulator, curValue) {
        return accumulator + curValue.absent;
      }, 0);
      emp.totalAbsent = sumAbsent;

      let sumLate = empAtt.reduce(function (accumulator, curValue) {
        return accumulator + curValue.late;
      }, 0);
      emp.totalLate = sumLate;

      let sumLeave = empAtt.reduce(function (accumulator, curValue) {
        return accumulator + curValue.leave;
      }, 0);
      emp.totalLeave = sumLeave;

      attends.push(emp);
    });

    let absents = attends.filter((x) => x.totalAbsent > 0);
    setTotalAbsents(absents);

    let lates = attends.filter((x) => x.totalLate > 0);
    setTotalLateWorks(lates);

    let leaves = attends.filter((x) => x.totalLeave > 0);
    setTotalLeaveWorks(leaves);

    //Find Top 5 Absent
    let absentSort = attends.sort(function (a, b) {
      return b.totalAbsent - a.totalAbsent;
    });

    let arrayAbsent = [];

    for (var i = 0; i < 5; i++) {
      if (absentSort[i] !== undefined) {
        arrayAbsent.push(absentSort[i]);
      }
    }

    setTopAbsents(arrayAbsent);

    //Find Top 5 LeaveWork
    let leaveSort = attends.sort(function (a, b) {
      return b.totalLate - a.totalLate;
    });

    let arrayLeave = [];

    for (var i = 0; i < 5; i++) {
      if (leaveSort[i] !== undefined) {
        arrayLeave.push(leaveSort[i]);
      }
    }

    setTopLateWorks(arrayLeave);
    setIsLoading(false);
  };

  useEffect(() => {
    if (empStore) {
      /* Fetch Employees */
      let empList = [...empStore];
      let empFilter = empList.filter(function (e) {
        return (
          e.idManagerLV1 === currentUser.id || e.idManagerLV2 === currentUser.id
        );
      });

      setEmployees(empFilter);
    }
  }, [empStore]);

  useEffect(() => {
    if (attendanceStore) {
      let attendanceList = [...attendanceStore];
      fetchData(attendanceList);
    }
  }, [attendanceStore]);

  useEffect(() => {
    if (currentUser) {
      setIsLoading(true);
      //dispatch(getAllEmployees());
      //dispatch(getAllAttendanceToManager(currentUser.id));
    }
  }, []);

  return (
    <Container>
      <Box sx={{ paddingTop: 12 }}>
        <Typography variant="h4" style={{ marginTop: 16, marginBottom: 16 }}>
          ภาพรวมเวลาทำงาน
        </Typography>
        {isLoading ? (
          <Box sx={{ marginTop: 2, marginBottom: 5 }}>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <LinearProgress />
            </Box>
          </Box>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CardSummary
                      value={{
                        label: "พนักงาน (คน)",
                        isPeople: true,
                        amount: employees.length,
                        icon: <GroupsIcon />,
                      }}
                      top={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CardSummary
                      value={{
                        label: "ขาดงาน (คน)",
                        isPeople: true,
                        amount: totalAbsents.length,
                        icon: <i class="fal fa-do-not-enter"></i>,
                      }}
                      top={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CardSummary
                      value={{
                        label: "มาสาย (คน)",
                        isPeople: true,
                        amount: totalLateWorks.length,
                        icon: <i class="fal fa-alarm-exclamation"></i>,
                      }}
                      top={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ height: "81%" }}>
                  <StyledWrapOT36Hours>
                    <Typography color="text.secondary" variant="body1">
                      {`ลางาน (คน)`}
                    </Typography>
                    <Grid container>
                      <Grid item>
                        <Typography variant="h3" align={"left"}>
                          {totalLeaveWorks.length}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <div className={`iconEmployee`}>
                          <i class="fal fa-umbrella-beach"></i>
                        </div>
                      </Grid>
                    </Grid>
                  </StyledWrapOT36Hours>
                </Box>
              </Grid>
            </Grid>
            <div style={{ marginTop: 16 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CardRank
                    name={`Top 5 ขาดงาน`}
                    unit={`ครั้ง`}
                    topAbsents={topAbsents}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CardRank
                    name={`Top 5 มาสาย`}
                    unit={`ครั้ง`}
                    topLateWorks={topLateWorks}
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: 16 }}>
              <CardChart
                chart="area"
                chartName={`ภาพรวมขาดงาน (คน)`}
                absentWork={absentWork}
                max={50}
              />
            </div>
            <div style={{ marginTop: 16, marginBottom: 36 }}>
              <CardChart
                chart="area"
                chartName={`ภาพรวมมาสาย (คน)`}
                lateWork={lateWork}
                max={100}
              />
            </div>
          </>
        )}
      </Box>
    </Container>
  );
}
