import { Grid, InputAdornment, styled } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Controller, useForm } from "react-hook-form";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import {
  getVendorProfile,
  updateVenderCompanyProfile,
} from "../../../../actions/vendor";

const StyledRoot = styled("div")({
  padding: 24,
});

const DrawerPrice = (props) => {
  const { open, handleClose, data } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      chargeWage: (data && data.chargeWage) || "",
      chargeOt: (data && data.chargeOt) || "",
      chargeCost: (data && data.chargeCost) || "",
      chargeCompensation: (data && data.chargeCompensation) || "",
    },
  });

  const onSubmit = async (value) => {
    const result = await dispatch(updateVenderCompanyProfile(value));
    if (result) {
      dispatch(getVendorProfile(1));
      handleClose();
    }
  };

  return (
    <DrawerCustom
      title={t("Expenses")}
      anchor={"right"}
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { maxWidth: 550, width: "100%" } }}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="chargeWage"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("chargeWage")}`,
                  },
                }}
                render={({ field }) => {
                  return (
                    <TextFieldTheme
                      {...field}
                      label={t("chargeWage")}
                      type="number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        min: "10",
                        max: "50",
                        step: "1",
                      }}
                      helperText={
                        errors && errors.chargeWage && errors.chargeWage.message
                      }
                      error={errors && errors.chargeWage ? true : false}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="chargeOt"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("chargeOt")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("chargeOt")}
                    type="number"
                    inputProps={{
                      min: "10",
                      max: "50",
                      step: "1",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    helperText={
                      errors && errors.chargeOt && errors.chargeOt.message
                    }
                    error={errors && errors.chargeOt ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="chargeCost"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("chargeCost")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("chargeCost")}
                    type="number"
                    inputProps={{
                      min: "10",
                      max: "50",
                      step: "1",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    helperText={
                      errors && errors.chargeCost && errors.chargeCost.message
                    }
                    error={errors && errors.chargeCost ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="chargeCompensation"
                rules={{
                  required: {
                    value: true,
                    message: `${t("PleaseEnter")} ${t("chargeCompensation")}`,
                  },
                }}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={t("chargeCompensation")}
                    type="number"
                    inputProps={{
                      min: "10",
                      max: "50",
                      step: "1",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    helperText={
                      errors &&
                      errors.chargeCompensation &&
                      errors.chargeCompensation.message
                    }
                    error={errors && errors.chargeCompensation ? true : false}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="outlined" onClick={handleClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue variant="contained" type="submit">
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerPrice;
