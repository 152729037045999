import React, { Fragment } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Divider, Paper, styled, Typography } from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledPaper = styled("div")({
  border: "none",
  display: "flex",
  alignItems: "flex-start",
  marginBottom: 16,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ExpertiseTab = () => {
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <div>
      <StyledWrapHead>
        <StyledHeadLabel style={{ marginBottom: 16 }}>
          {`${t("EducationHistory")}`}
        </StyledHeadLabel>
        {/* <ButtonBlue size="small" startIcon={<AddIcon />}>
          เพิ่มวุฒิการศึกษา
        </ButtonBlue> */}
      </StyledWrapHead>

      {userProfile.education.length > 0 ? (
        userProfile.education.map((education) => (
          <Fragment>
            <StyledPaper>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <Typography
                  variant="h6"
                  style={{ width: "calc(100% - 143px)" }}
                >
                  {`(${
                    education.fromYear
                      ? dayjs(education.fromYear).format("YYYY")
                      : "-"
                  } ${t("To")} ${
                    education.endYear
                      ? dayjs(education.endYear).format("YYYY")
                      : `${t("NotSpecified")}`
                  }) ${
                    education.university
                      ? education.university
                      : `${t("NoUniversitySpecified")}`
                  }`}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {`${t("Major")} ${
                    education.major ? education.major : t("NotSpecified")
                  }, ${t("Faculty")} ${
                    education.faculty ? education.faculty : t("NotSpecified")
                  }, ${t("Degree")} ${education.degree}`}
                </Typography>
                <Typography style={{ fontWeight: 700, marginTop: 8 }}>
                  {`${t("GPA")}: ${
                    education.gpa ? education.gpa : t("NotSpecified")
                  }`}
                </Typography>
              </div>
            </StyledPaper>
            <StyledDivider />
          </Fragment>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" style={{ color: "grey" }}>
            ไม่มีข้อมูลประวัติการศึกษา
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ExpertiseTab;
