import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import dayjs from 'dayjs';
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { Checkbox, Grid } from "@mui/material";

import { addExperience, updateExperience, getExperienceByIdEmp } from "../../../../../../actions/experience";
import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput":{
    display:"flex",
    alignItems:"center"
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledTextFieldTheme = styled(TextFieldTheme)({
  marginTop: 5,
});

const DialogExperience = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose, idExp, type } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: Experiences } = useSelector(state => state.experience);
  const [isWorking, setIsWorking] = useState(false);
  const [formData, setFormData] = useState({
    startDate: new Date(),
    endDate: new Date(),
    positionName:"",
    companyName: "",
    description: ""
  });

  useEffect(() => {
    if(open){
      if(idExp){
        let targetExp = employeeProfile.experience.find((exp) => exp.idExperience === idExp);
        if(!targetExp.endDate){
          setIsWorking(true);
        }
        setFormData({
          startDate: new Date(targetExp.startDate),
          endDate: targetExp.endDate ? new Date(targetExp.endDate) : new Date(),
          positionName: targetExp.positionName,
          companyName: targetExp.companyName,
          description: targetExp.description
        });
      } else {
        setFormData({
          startDate: new Date(),
          endDate: new Date(),
          positionName:"",
          companyName: "",
          description: ""
        });
      }
    }
  },[idExp])

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleCheckWorking = () => {
    setIsWorking(!isWorking);
  };

  const handleSubmit = async () => {
    let result;
    let data = {
      ...formData, 
      startDate: dayjs(formData.startDate).format("YYYY-MM-DD"), 
      endDate: !isWorking ? dayjs(formData.endDate).format("YYYY-MM-DD") : null
    };
    if(type === "Add"){
      result = await dispatch(addExperience(employeeProfile, data));
    } else {
      let experienceEmp = employeeProfile.experience.find((exp) => exp.idExperience === idExp);
      result = await dispatch(updateExperience(experienceEmp, data));
    }
    if(result){
      handleClose();
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
    }
  };

  return (
    <DrawerCustom
      title="เพิ่มประสบการณ์ทำงาน"
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12}>
              <FormControlLabel
                label="ที่ทำงานปัจจุบัน"
                control={
                  <Checkbox 
                    checked={isWorking}
                    onChange={handleCheckWorking}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="GridTopicInput">เริ่มต้น</Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                views={["year","month"]}
                value={formData.startDate}
                name="startDate"
                onChange={(newValue) => {
                  setFormData({ ...formData, ["startDate"]: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth helperText={null} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="GridTopicInput">สิ้นสุด</Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                disabled={isWorking}
                views={["year","month"]}
                value={formData.endDate}
                name="endDate"
                onChange={(newValue) => {
                  setFormData({ ...formData, ["endDate"]: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth helperText={null} />
                )}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12}>
            ชื่อตำแหน่งงาน
            <StyledTextFieldTheme
              name="positionName"
              value={formData.positionName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            ชื่อบริษัท
            <StyledTextFieldTheme
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            รายละเอียดงาน
            <StyledTextFieldTheme
              style={{ marginBottom:0 }}
              name="description"
              value={formData.description}
              multiline
              rows={4}
              inputProps={{
                maxLength: 255
              }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={handleClose}>ยกเลิก</ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
            บันทึกข้อมูล
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogExperience;
