import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import {
  Container,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

import {
  getAllCompany,
  getAllVendor,
} from "../../../../actions/ant-team/customer";
import { useDispatch, useSelector } from "react-redux";

import AddHomeRoundedIcon from "@mui/icons-material/AddHomeRounded";

import EmployeeList from "./employeeList";
import DialogAdd from "./DialogAdd";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AlertResponse from "../../shared/general/AlertResponse";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .section-one": {
    display: "flex",
    justifyContent: "space-between",
  },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    color: "#007aff",
    border: "1px solid #007aff",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007aff",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderLeft: "1px solid #007aff",
      borderBottomRightRadius: 8,
      borderTopRightRadius: 8,
    },
    "&:first-of-type": {
      borderBottomLeftRadius: 8,
      borderTopLeftRadius: 8,
    },
  },
}));

const CustomerPage = (props) => {
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState("company");
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  useEffect(() => {
    dispatch(getAllCompany());
  }, []);

  const handleChangeCustomer = (event, newStatus) => {
    var name = event.target.name;
    var value = newStatus;
    console.log(name, value);
    setCustomer(value);

    if (value === "company") {
      dispatch(getAllCompany());
    } else {
      dispatch(getAllVendor());
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          รายชื่อลูกค้า
        </Typography>
        <div>
          <div className="section-one">
            <div style={{ marginBottom: 24 }}>
              <StyledToggleButtonGroup
                value={customer}
                exclusive
                onChange={handleChangeCustomer}
                aria-label="filter employee"
                size="small"
              >
                <ToggleButton
                  name="customer"
                  value="company"
                  aria-label="company"
                  size="small"
                  style={{ minWidth: 150 }}
                >
                  Company
                </ToggleButton>
                <ToggleButton
                  name="customer"
                  value="vendor"
                  aria-label="vendor"
                  size="small"
                  style={{ minWidth: 150 }}
                >
                  Vendor
                </ToggleButton>
              </StyledToggleButtonGroup>
            </div>
            <div>
              <ButtonBlue
                startIcon={<AddHomeRoundedIcon />}
                variant="contained"
                onClick={() => setOpen(true)}
              >{`เพิ่ม ${customer}`}</ButtonBlue>
            </div>
          </div>

          <EmployeeList customer={customer} />
          <DialogAdd
            open={open}
            handleCloseDialog={handleClose}
            customer={customer}
            handleChangeAlertType={handleChangeAlertType}
            handleOpenAlert={handleOpenAlert}
          />
          <AlertResponse
            open={openAlert}
            handleClose={handleCloseAlert}
            alertType={alertType}
          />
        </div>
      </Container>
    </StyledRoot>
  );
};

export default CustomerPage;
