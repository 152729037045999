import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import ButtonBlue from '../../shared/general/ButtonBlue';

const ConfirmDialog = ({ pendingApproval, type, open, onClose, handleConfirm }) => {
  const { t } = useTranslation();
  const isApprove = pendingApproval ? pendingApproval.isApprove : null;

  const dialogMessage = isApprove === 1
    ? t("Bill_Approved")
    : t("Cancel");

  const message = isApprove === 1
    ? t("SentFormDialogTitle")
    : t("RejectFormDialogDes");

  return (
    <>
      {type === 'approve' && pendingApproval ? (
        <Dialog open={open} onClose={onClose} sx={{ minWidth: 300 }}>
          <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center', pb: 1 }}>
            {dialogMessage}
          </DialogTitle>
          {message && (
            <DialogContent sx={{ textAlign: 'center', p: 2 }}>
              <Typography variant="body1" sx={{ paddingTop: '10px' }}>
                {message}
              </Typography>
            </DialogContent>
          )}
          <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
            <ButtonBlue onClick={onClose} variant="outlined" sx={{ mx: 1 }}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue onClick={handleConfirm} variant="contained" color="primary" sx={{ mx: 1 }}>
              {t("Confirm")}
            </ButtonBlue>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={onClose} sx={{ minWidth: 300 }}>
          <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center', pb: 1 }}>
            {t("ConfirmToDelete")}
          </DialogTitle>
          <DialogContent sx={{ textAlign: 'center', p: 2 }}>
            <Typography variant="body1">
              {t("AreYouSure")}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
            <ButtonBlue onClick={onClose} variant="outlined" sx={{ mx: 1 }}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue onClick={handleConfirm} variant="contained" color="error" sx={{ mx: 1 }}>
              {t("Confirm")}
            </ButtonBlue>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ConfirmDialog;