import React, { useState } from "react";
import { Button, Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import ButtonEdit from "./shared/ButtonEdit";
import DrawerProfile from "./drawerProfile";

const StyledRoot = styled("div")({});

const Profile = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();

  const [isOpenDrawerProfile, setIsOpenDrawerProfile] = useState(false);

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">{t("CompanyCode")}</Typography>
          <Typography className="valueText">
            {data.vendorCode || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {t("TaxpayerIdentificationNumber")}
            {/* Social Security A/C */}
          </Typography>
          <Typography className="valueText">
            {data.socialSecurityAccount || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {t("NameAuthorized")} 1{/* Authorized Signatory Name */}
          </Typography>
          <Typography className="valueText">
            {data.authorizedSignatureOneName || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {t("PositionAuthorized")} 1{/* Authorized Signatory Position */}
          </Typography>
          <Typography className="valueText">
            {data.authorizedSignatureOnePosition || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {t("NameAuthorized")} 2{/* Authorized Signatory Name */}
          </Typography>
          <Typography className="valueText">
            {data.authorizedSignatureTwoName || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {t("PositionAuthorized")} 2{/* Authorized Signatory Position */}
          </Typography>
          <Typography className="valueText">
            {data.authorizedSignatureTwoPosition || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {t("WitnessName")}
            {/* Authorized Signatory Name */}
          </Typography>
          <Typography className="valueText">
            {data.witnessSignatureName || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {t("WitnessPosition")}
            {/* Authorized Signatory Position */}
          </Typography>
          <Typography className="valueText">
            {data.witnessSignaturePosition || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {t("RegisteredCapital")}
          </Typography>
          <Typography className="valueText">
            {data.registeredCapital || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {t("ProvidentFundAccountNumber")}
            {/* Provident Fund Code */}
          </Typography>
          <Typography className="valueText">
            {data.providentFundCode || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {t("YearEstablishment")}
          </Typography>
          <Typography className="valueText">
            {data.establishedYear || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">
            {`${t("CompanyIncome")} (${t("LastYear")})`}
          </Typography>
          <Typography className="valueText">
            {data.vendorRevenue || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">{t("ManpowerSize")}</Typography>
          <Typography className="valueText">
            {data.manpowerSize || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className="headerText">{t("Email")}</Typography>
          <Typography className="valueText">
            {data.vendorEmail || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="headerText">{t("About")}</Typography>
          <Typography className="valueText">
            {data.vendorAbout || "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonEdit
            onClick={() => {
              setIsOpenDrawerProfile(true);
            }}
          />
        </Grid>
      </Grid>

      {isOpenDrawerProfile && (
        <DrawerProfile
          open={isOpenDrawerProfile}
          handleClose={() => {
            setIsOpenDrawerProfile(false);
          }}
          data={data}
        />
      )}
    </StyledRoot>
  );
};

export default Profile;
