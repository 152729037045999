import { httpClient } from "./httpClient";

const getAllJobFamily = () => {
  return httpClient.get("jobFamily");
};

const postJobFamily = (data) => {
  return httpClient.post(`jobFamily/postJobFamily`, data);
};

const putJobFamily = (id, data) => {
  return httpClient.post(`jobFamily/putJobFamily/${id}`, data);
};

export default {
  getAllJobFamily,
  postJobFamily,
  putJobFamily,
};
