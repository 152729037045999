import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Typography, Divider, Paper, Container, Box } from "@mui/material";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

import ButtonBlue from "../../shared/general/ButtonBlue";
import StyledCard from "../../shared/general/Card";

import dataEmployeeList from "../../assets/data/employees";

import FilterTable from "./filterTable";
import ItemTable from "./itemTable";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
});

const StyledContent = styled("div")({
  padding: 24,
});

const TeamManPowerPage = () => {
  let { id } = useParams();
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterEmployee, setFilterEmployee] = useState({
    search: "",
    idDepartment: id,
    idVendor: "",
    idJobGroup: "",
    employeeType: [],
    level: [],
  });

  const handleChangeFilterEmployee = (event, newStatus, selectName) => {
    var name = event.target.name;
    var value = newStatus;
    if (newStatus === undefined) {
      setFilterEmployee({
        ...filterEmployee,
        [name]: event.target.value,
      });
    } else {
      if (name === undefined) {
        name = selectName;
      }
      setFilterEmployee({
        ...filterEmployee,
        [name]: value,
      });
    }
  };

//   const rowsFilter = () => {
//     if (
//       filterEmployee.search == "" &&
//       filterEmployee.idVendor == "all" &&
//       filterEmployee.idJobGroup == "all" && 
//       filterEmployee.employeeType.length == 0 && 
//       filterEmployee.level.length == 0
//     ) {
//       return dataEmployeeList.filter(value => value.idDepartment === filterEmployee.idDepartment);
//     } else {
//       let resultFilter = [...dataEmployeeList];

//       if (filterEmployee.status != "all") {
//         resultFilter = resultFilter.filter((item) => {
//           if (item.status == filterEmployee.status) return item;
//         });
//       }

//       if (filterEmployee.search.length > 0) {
//         resultFilter = resultFilter.filter((item, index) => {
//           if (
//             item.firstname
//               .toUpperCase()
//               .indexOf(filterEmployee.search.toUpperCase()) >= 0 ||
//             item.lastname
//               .toUpperCase()
//               .indexOf(filterEmployee.search.toUpperCase()) >= 0
//           )
//             return item;
//         });
//       }

//       if (filterEmployee.idDepartment != "all") {
//         resultFilter = resultFilter.filter((item) => {
//           if (item.idDepartment == filterEmployee.idDepartment) return item;
//         });
//       }

//       if (filterEmployee.site != "all") {
//         resultFilter = resultFilter.filter((item) => {
//           if (item.site == filterEmployee.site) return item;
//         });
//       }
//       return resultFilter;
//     }
//   };


  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div>
          <StyledCard>
            <StyledContent>
              <div>
                <ButtonBlue
                  onClick={() => setFilterStatus(!filterStatus)}
                  startIcon={
                    filterStatus ? <FilterAltOffIcon /> : <FilterAltIcon />
                  }
                >
                  {`${filterStatus ? "ปิด" : "เปิด"}ตัวกรอง`}
                </ButtonBlue>
              </div>
              <Box style={{ display: "flex", position: "relative" }}>
                <FilterTable
                  handleChange={handleChangeFilterEmployee}
                  filterEmployee={filterEmployee}
                  filterStatus={filterStatus}
                />
                <ItemTable open={filterStatus} data={dataEmployeeList} />
              </Box>
            </StyledContent>
          </StyledCard>
        </div>
      </Container>
    </StyledRoot>
  );
};

export default TeamManPowerPage;
