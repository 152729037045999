import React from "react";
import { styled } from "@mui/material/styles";
import {
  TextField,
  IconButton,
  Typography,
  Container,
  Divider,
  Grid,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { th } from "date-fns/locale";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import imageCourse from "../../assets/6115095.jpg";

const StyledRoot = styled("div")({
  "& .wrap-filter": {
    paddingTop: 36,
    marginBottom: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .left": {
      display: "flex",
      alignItems: "center",
      "& .btn-search": {
        marginLeft: 8,
      },
    },
  },
});

const StyledTextField = styled(TextField)({
  width: "200px",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 40,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledIconButtonUpload = styled(IconButton)({
  color: "#FFFFFF",
  backgroundColor: "#007AFE",
  border: "1px solid #007AFE",
  borderRadius: 8,
  padding: 6,
  marginRight: 8,
  "&:hover": {
    borderColor: "#0046b7",
    backgroundColor: "#0046b7",
  },
});

const Content = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <StyledRoot>
      <div className="wrap-filter">
        <div className="left">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
            <DatePicker
              views={["year", "month"]}
              //value={search.start}
              name="start"
              onChange={(newValue) => {
                //setSearch({ ...search, ["start"]: newValue });
              }}
              renderInput={(params) => (
                <StyledTextField variant="filled" fullWidth {...params} />
              )}
            />
          </LocalizationProvider>
          <div className="btn-search">
            <StyledIconButtonUpload
              aria-label="upload file"
              component="span"
              size="large"
            >
              <SearchIcon fontSize="small" />
            </StyledIconButtonUpload>
          </div>
        </div>
      </div>
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              รุ่นที่ 1 ( 3 พ.ค. 2565 / 9:00-12:00 )
            </Typography>
            <Typography color="text.secondary">
              ลงทะเบียน{" "}
              <Typography
                color="text.secondary"
                style={{ fontWeight: 600 }}
                component={"span"}
              >
                34
              </Typography>
              /50 คน
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nulla facilisi. Phasellus sollicitudin nulla et quam mattis
              feugiat. Aliquam eget maximus est, id dignissim quam.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              รุ่นที่ 2 ( 11 พ.ค. 2565 / 9:00-12:00 )
            </Typography>
            <Typography color="text.secondary">
              ลงทะเบียน{" "}
              <Typography
                color="text.secondary"
                style={{ fontWeight: 600 }}
                component={"span"}
              >
                34
              </Typography>
              /50 คน
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Donec placerat, lectus sed mattis semper, neque lectus feugiat
              lectus, varius pulvinar diam eros in elit. Pellentesque convallis
              laoreet laoreet.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              รุ่นที่ 3 ( 16 พ.ค. 2565 / 9:00-12:00 )
            </Typography>
            <Typography color="text.secondary">
              ลงทะเบียน{" "}
              <Typography
                color="text.secondary"
                style={{ fontWeight: 600 }}
                component={"span"}
              >
                34
              </Typography>
              /50 คน
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              รุ่นที่ 4 ( 20 พ.ค. 2565 / 9:00-12:00 )
            </Typography>
            <Typography color="text.secondary">
              ลงทะเบียน{" "}
              <Typography
                color="text.secondary"
                style={{ fontWeight: 600 }}
                component={"span"}
              >
                34
              </Typography>
              /50 คน
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer
              sit amet egestas eros, vitae egestas augue. Duis vel est augue.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </StyledRoot>
  );
};

export default Content;
