import { httpClient } from "./httpClient";

const getPersonnelLevelByidCompany = (idCompany) => {
    return httpClient.get(`company/${idCompany}/personnel-level`);
  };

const getJobLevlByidCompany = (idCompany) => {
    return httpClient.get(`company/${idCompany}/job-level`);
};

const addNewJobLevel = (idCompany,data) => {
    return httpClient.post(`jobLevel/add/company/${idCompany}`,data);
};

const switchJobLevel = (data) => {
    return httpClient.put(`jobLevel/switch`,data);
};

const updateJobLevel = (idJobLevel, data) => {
    return httpClient.put(`jobLevel/update/${idJobLevel}`,data);
};

const deleteJobLevel = (idJobLevel) => {
    return httpClient.delete(`jobLevel/delete/${idJobLevel}`);
};

export default {
    getPersonnelLevelByidCompany,
    getJobLevlByidCompany,
    addNewJobLevel,
    switchJobLevel,
    updateJobLevel,
    deleteJobLevel
};