import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import styled from "@emotion/styled";

import { useTranslation } from "react-i18next";

const StyledAutoComplete = styled(Autocomplete)({
    "& .MuiFilledInput-root": {
        padding: "13.5px 14px",
        paddingRight: "32px",
        "& input": {
            padding: 0
        }
    }
})

const StyledTextField = styled(TextField)({
    width: "100%",
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "& .MuiFilledInput-input": {
            padding: "7px 4px",
        },
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            "& button": {
                color: "#919EAB",
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&:after": {
            border: "none",
        },
        "&:before": {
            border: "none",
            borderBottomStyle: "none !important",
        }
    },
});

export const SelectAllCompaniesVendor = (props) => {
    const { t, i18n } = useTranslation();

    const {
        options,
        value,
        onChange,
        disabled
    } = props

    return (
        <StyledAutoComplete
            options={options ? options : []}
            getOptionLabel={(option) => option.companyName}
            isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
            disabled={disabled}
            renderInput={(params) => (
                <StyledTextField
                    {...params}
                    InputProps={{ ...params.InputProps, endAdornment: null }}
                    variant="filled"
                    placeholder="เลือกบริษัท"
                />
            )}
            value={value}
            onChange={onChange}
            noOptionsText="ไม่พบข้อมูล"
            disableClearable
        />

    )
};

export const SelectAllCompanyVendorSections = (props) => {
    const { t, i18n } = useTranslation();

    const {
        options,
        value,
        onChange,
        disabled
    } = props

    return (
        <StyledAutoComplete
            options={options ? options : []}
            getOptionLabel={(option) => option.sectionName}
            isOptionEqualToValue={(option, value) => option.idSection === value.idSection}
            disabled={disabled}
            renderInput={(params) => (
                <StyledTextField
                    {...params}
                    InputProps={{ ...params.InputProps, endAdornment: null }}
                    variant="filled"
                    placeholder="เลือก Section"
                />
            )}
            value={value}
            onChange={onChange}
            noOptionsText="ไม่พบข้อมูล"
            disableClearable
        />

    )
};