import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Container, Typography, Box, Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

//GPS Check in - out
import LocationList from "./GPSCheckInOut/LocationList/LocationList";
import EditLocation from "./GPSCheckInOut/EditLocation/EditLocation";

//Calendar
import Calendar from "./calendar";

//leave-manage
import LeaveManage from "./leave-manage";

//job-manage
// import JobManage from "./job-manage";
import JobManage from "../../vendor/setting/job-manage/index";

//user-manage
import UserManage from "./user-manage";

//institute-manage
import InstituteManage from "./institute-manage";

//admin-approve
import AdminApproveLeave from "./leave-manage/admin-approve";

//workTime-manage
import WorkTimeManage from "./workTime-approve";

//log-record
import LogRecord from "./log-record";

//payroll
import Payroll from "./payroll";

//branch-manage
import BranchManage from "./branch-manage";

//childCompany-manage
import ChildCompanyManage from "./childCompany-manage";

import Performance from "./performance";

import PF from "./pf";
import Level from "./level";
import Reason from "./reason";
import SelectVendor from "../shared/selectVendor";
import { getVendorContract } from "../../../../actions/company";
import { useDispatch, useSelector } from "react-redux";
import OrganizationStructure from "./organization-structure";
import { useTranslation } from "react-i18next";
import Probation from "./probation";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledWrapTabs = styled("div")({
  paddingBottom: 36,
  marginTop: 36,
  width: "100%",
});

const SettingPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: companyContract } = useSelector((state) => state.companyContract);
  const [value, setValue] = useState(0);
  const [itemEdit, setItemEdit] = useState(null);
  const [viewGps, setViewGps] = useState({
    list: true,
    add: false,
    edit: false,
  });
  const [selectVendor, setSelectVendor] = useState(null);

  const handleClickAddGps = () => {
    const temp = {
      list: false,
      add: true,
      edit: false,
    };

    setViewGps(temp);
  };
  const handleClickSaveGps = () => {
    const temp = {
      list: true,
      add: false,
      edit: false,
    };

    setViewGps(temp);
  };
  const handleClickEditGps = (item) => {
    const temp = {
      list: false,
      add: false,
      edit: true,
    };

    setViewGps(temp);
    setItemEdit(item);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeVendor = (newValue) => {
    setSelectVendor(newValue);
  }

  useEffect(() => {
    dispatch(getVendorContract());
  }, []);

  useEffect(() => {
    if (companyContract) {
      setSelectVendor(companyContract[0]);
    }
  }, [companyContract]);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          ข้อมูลระบบ
        </Typography>
        <StyledWrapTabs>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              scrollButtons="auto"
              aria-label="basic tabs example"
              variant={"scrollable"}
            >
              <Tab label="ตำแหน่ง GPS" {...a11yProps(0)} value={0} />
              <Tab label="วันหยุดบริษัท" {...a11yProps(1)} value={1} />
              <Tab label="วันลา" {...a11yProps(3)} value={3} />
              <Tab label="จัดการหน่วยงาน" {...a11yProps(5)} value={5} disabled/>
              <Tab label="จัดการสาขา" {...a11yProps(11)} value={11} disabled/>
              <Tab label="จัดการบริษัทลูก" {...a11yProps(12)} value={12} disabled/>
              <Tab label="จัดการวันลา" {...a11yProps(6)} value={6} disabled/>
              <Tab label="จัดการเวลาทำงาน" {...a11yProps(7)} value={7} disabled/>
              <Tab label={`${t("ManageJobs")}`} {...a11yProps(4)} value={4} />
              <Tab label="Manage Organization" {...a11yProps(16)} value={16} />
              <Tab label="จัดการระดับงาน" {...a11yProps(15)} value={15} />
              <Tab label="จัดการ Performance" {...a11yProps(14)} value={14} />
              <Tab label="Payroll" {...a11yProps(8)} value={8} />
              <Tab label="กองทุนสำรองเลี้ยงชีพ" {...a11yProps(13)} value={13} />
              <Tab label="จัดการเหตุผล" {...a11yProps(2)} value={2} />
              <Tab label="ทดลองงาน" {...a11yProps(17)} value={17} />
              <Tab label="ผู้ดูแลระบบ" {...a11yProps(10)} value={10} disabled/>
            </Tabs>
          </Box>
          {/* <SelectVendor vendor={selectVendor} handleChange={handleChangeVendor} /> */}
          {/* <TabPanel value={value} index={0}>
            {viewGps.list && (
              <LocationList
                onClick={handleClickAddGps}
                onClickEdit={handleClickEditGps}
              />
            )}
            {viewGps.edit && (
              <EditLocation
                dataEdit={itemEdit}
                onClickSave={handleClickSaveGps}
              />
            )}
          </TabPanel> */}
          <TabPanel value={value} index={1}>
            <Calendar />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Reason vendor={selectVendor} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <LeaveManage vendor={selectVendor} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <JobManage />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <InstituteManage />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <AdminApproveLeave />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <WorkTimeManage />
          </TabPanel>
          <TabPanel value={value} index={8}>
            <Payroll />
          </TabPanel>
          <TabPanel value={value} index={9}>
            <LogRecord />
          </TabPanel>
          <TabPanel value={value} index={10}>
            <UserManage />
          </TabPanel>
          <TabPanel value={value} index={11}>
            <BranchManage />
          </TabPanel>
          <TabPanel value={value} index={12}>
            <ChildCompanyManage />
          </TabPanel>
          <TabPanel value={value} index={13}>
            <PF />
          </TabPanel>
          <TabPanel value={value} index={14}>
            <Performance />
          </TabPanel>
          <TabPanel value={value} index={15}>
            <Level />
          </TabPanel>
          <TabPanel value={value} index={16}>
            <OrganizationStructure />
          </TabPanel>
          <TabPanel value={value} index={17}>
            <Probation />
          </TabPanel>
        </StyledWrapTabs>
      </Container>
    </StyledRoot>
  );
};

export default SettingPage;
