import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Link,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";

import DrawerCustom from "../../../shared/general/Drawer";

import AddIcon from "../../../assets/add.png";

import FileSelected from "./file-selected";
import ShowResponse from "./show-response";

import { addUsers, addUsersFirstTime } from "../../../../../actions/user";
import { addEmployeeAdditionDeduction } from "../../../../../actions/employee";
import { getAllUsersCompany } from "../../../../../actions/user";
import { getAllUsersVendor } from "../../../../../actions/vendor";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { dumpExcelEmployees } from "../../../../../actions/dumpExcel";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyleSelect = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    padding: "0px 12px",
    "& .MuiSelect-select": {
      padding: "13.5px 0px",
    },
  },
});

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const StyledLoading = styled("div")({
  height: 200,
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const DialogUpload = (props) => {
  const { open, toggleDrawer } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { t, i18n } = useTranslation();
  // const { result: vendorContract } = useSelector(
  //   (state) => state.vendorContract
  // );
  const { result: companyContractList } = useSelector(
    (state) => state.companyContract
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [dumpSelect, setDumpSelect] = React.useState("");
  const [fileSelected, setFileSelected] = useState(null);
  const [vendorSelected, setVendorSelected] = useState("");
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [openLoading, setOpenLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [selectedDumpList, setSelectedDumpList] = useState(null);
  const [waitingAPI, setWaitingAPI] = useState(false);
  const [type, setType] = useState(null);

  useEffect(() => {
    setDumpSelect("");
    setErrorResponse(null);
    setFileSelected(null);
    setVendorSelected(null);
    setFormFileSelected(null);
    setOpenLoading(null);
    setSelectedDumpList(null);
  }, [open]);

  useEffect(() => {
    setDumpSelect(null);
    setErrorResponse(null);
    setFileSelected(null);
    setVendorSelected(null);
    setFormFileSelected(null);
    setOpenLoading(null);
    setErrorResponse(null);
  }, [selectedDumpList]);

  const dumpList = [
    {
      id: 1,
      name: `${t("Employee_Data")} (${t("New_Company")})`,
      disabled: true,
    },
    {
      id: 2,
      name: `${t("Employee_Data")}`,
      disabled: false,
    },
    {
      id: 3,
      name: `${t("Works")}`,
      disabled: true,
    },
    {
      id: 4,
      name: `${t("Manager")}`,
      disabled: true,
    },
    {
      id: 5,
      name: `${t("Employee_Experience")}`,
      disabled: true,
    },
    {
      id: 6,
      name: `${t("Adjust_Level")}`,
      disabled: true,
    },
    {
      id: 7,
      name: `${t("Adjust_Wage")}`,
      disabled: false,
    },
    {
      id: 8,
      name: "Additions/Deductions",
      disabled: true,
    },
  ];

  const insertDumpList = [
    {
      id: 1,
      name: `${t("Employee_Data")} (${t("New_Company")})`,
      disabled: true,
    },
    {
      id: 2,
      name: `${t("Employee_Data")}`,
      disabled: false,
    },
    {
      id: 8,
      name: "Additions/Deductions",
      disabled: false,
    },
    {
      id: 10,
      name: `${t("Adjust_Wage")}`,
      disabled: false,
    },
    {
      id: 11,
      name: `${t("Manager")}`,
      disabled: false,
    },
    {
      id: 32,
      name: `${t("TransferPosition")}`,
      disabled: false,
    },
  ];

  const editDumpList = [
    {
      id: 12,
      name: `${t("Nationality")}`,
      disabled: false,
    },
    {
      id: 13,
      name: `${t("MaritalStatus")}`,
      disabled: false,
    },
    {
      id: 14,
      name: `${t("PassportNumber")}`,
      disabled: false,
    },
    {
      id: 15,
      name: `${t("WorkPermitNumber")}`,
      disabled: false,
    },
    {
      id: 16,
      name: `${t("BirthDate")}`,
      disabled: false,
    },
    {
      id: 17,
      name: `${t("PhoneNumber")}`,
      disabled: false,
    },
    {
      id: 18,
      name: "email",
      disabled: true,
    },
    {
      id: 19,
      name: `${t("EmergencyContact")}`,
      disabled: false,
    },
    {
      id: 20,
      name: `${t("Address")}`,
      disabled: false,
    },
    {
      id: 21,
      name: `${t("TerminateDate")}`,
      disabled: false,
    },
    {
      id: 22,
      name: `${t("RecordAttendance")}`,
      disabled: false,
    },
    {
      id: 23,
      name: `${t("BankInfo")}`,
      disabled: false,
    },
    {
      id: 24,
      name: `${t("ResignDate")}`,
      disabled: true,
    },
    {
      id: 25,
      name: `${t("PersonalLevel")}`,
      disabled: true,
    },
    {
      id: 26,
      name: `${t("Wages")}`,
      disabled: false,
    },
    {
      id: 27,
      name: `${t("Manager")}`,
      disabled: false,
    },
    {
      id: 28,
      name: `${t("CompanyCharge")}`,
      disabled: false,
    },
    {
      id: 29,
      name: `${t("PerformanceReview")}`,
      disabled: true,
    },
    {
      id: 30,
      name: `${t("Nickname")}`,
      disabled: false,
    },
    {
      id: 31,
      name: `${t("Gender")}`,
      disabled: false,
    },
  ];

  const defaultHeader = {
    รหัสพนักงาน: "",
    ชื่อ: "",
    สกุล: "",
    เลขบัตรประจำตัวประชาชน: "",
  };

  const template = {
    template7: {
      sheet: ["wage"],
      fileName: "ปรับค่าจ้าง.xlsx",
      header: {
        ...defaultHeader,
        ค่าจ้าง: "",
      },
    },
    template10: {
      sheet: ["salary"],
      fileName: "ค่าจ้าง.xlsx",
      header: {
        ...defaultHeader,
        "วัน/เดือน/ปี ที่มีผล": "",
        ค่าจ้าง: "",
        เหตุผล: "",
        หมายเหตุ: "",
      },
    },
    template11: {
      sheet: ["manager"],
      fileName: "หัวหน้างาน.xlsx",
      header: {
        ชื่อ: "",
        ชื่อตำแหน่ง: "",
        email: "",
        ชื่อบริษัท: "",
      },
    },
    template12: {
      sheet: ["nationality"],
      fileName: "สัญชาติ.xlsx",
      header: {
        ...defaultHeader,
        สัญชาติ: "",
      },
    },
    template13: {
      sheet: ["marital status"],
      fileName: "สถานะ.xlsx",
      header: {
        ...defaultHeader,
        สถานะ: "",
      },
    },
    template14: {
      sheet: ["passportId"],
      fileName: "เลขพาสปอร์ต.xlsx",
      header: {
        ...defaultHeader,
        เลขพาสปอร์ต: "",
      },
    },
    template15: {
      sheet: ["work permit"],
      fileName: "เลขที่ใบอนุญาตทำงาน.xlsx",
      header: {
        ...defaultHeader,
        เลขที่ใบอนุญาตทำงาน: "",
      },
    },
    template16: {
      sheet: ["birthday"],
      fileName: "วันเกิด.xlsx",
      header: {
        ...defaultHeader,
        "วัน/เดือน/ปีค.ศ.เกิด": "",
      },
    },
    template17: {
      sheet: ["telephone number"],
      fileName: "เบอร์โทรศัพท์.xlsx",
      header: {
        ...defaultHeader,
        เบอร์โทรศัพท์: "",
      },
    },
    template18: {
      sheet: ["email"],
      fileName: "email.xlsx",
      header: {
        ...defaultHeader,
        email: "",
      },
    },
    template19: {
      sheet: ["emergency contact"],
      fileName: "ผู้ติดต่อ.xlsx",
      header: {
        ...defaultHeader,
        "ชื่อ นามสกุล ผู้ติดต่อ": "",
        ความสัมพันธ์: "",
        เบอร์โทรศัพท์ผู้ติดต่อ: "",
      },
    },
    template20: {
      sheet: ["address"],
      fileName: "ที่อยู่.xlsx",
      header: {
        ...defaultHeader,
        บ้านเลขที่: "",
        หมู่บ้าน: "",
        หมู่ที่: "",
        ซอย: "",
        ถนน: "",
        "ตำบล/แขวง": "",
        "เขต/อำเภอ": "",
        จังหวัด: "",
        รหัสไปรษณีย์: "",
      },
    },
    template21: {
      sheet: ["terminate date"],
      fileName: "วันที่หมดสัญญา.xlsx",
      header: {
        ...defaultHeader,
        "วัน/เดือน/ปีค.ศ.ที่หมดสัญญา": "",
      },
    },
    template22: {
      sheet: ["finger"],
      fileName: "การบันทึกเวลาทำงาน.xlsx",
      header: {
        ...defaultHeader,
        ต้องการบันทึกเวลาทำงาน: "",
      },
    },
    template23: {
      sheet: ["bank information"],
      fileName: "ข้อมูลธนาคาร.xlsx",
      header: {
        ...defaultHeader,
        ชื่อธนาคาร: "",
        สาขา: "",
        เลขที่บัญชี: "",
        "ชื่อผู้รับเงิน Report ส่งธนาคาร": "",
        "ชื่อธนาคาร Report ส่งธนาคาร": "",
        "รหัสธนาคาร Report ส่งธนาคาร": "",
        "เลขที่บัญชี Report ส่งธนาคาร": "",
        "อ้างอิง Report ส่งธนาคาร": "",
      },
    },
    template24: {
      sheet: ["resign"],
      fileName: "ลาออก.xlsx",
      header: {
        ...defaultHeader,
        "วัน/เดือน/ปีค.ศ.ที่มีผล": "",
        เหตุผล: "",
        หมายเหตุ: "",
      },
    },
    template25: {
      sheet: ["personal level"],
      fileName: "ระดับพนักงาน.xlsx",
      header: {
        ...defaultHeader,
      },
    },
    template26: {
      sheet: ["wage"],
      fileName: "ค่าจ้าง.xlsx",
      header: {
        ...defaultHeader,
        ค่าจ้าง: "",
      },
    },
    template27: {
      sheet: ["manager"],
      fileName: "หัวหน้างาน.xlsx",
      header: {
        ...defaultHeader,
        "ชื่อบริษัทหัวหน้างานระดับที่ 1": "",
        "email หัวหน้างานระดับที่ 1": "",
        "ชื่อบริษัทหัวหน้างานระดับที่ 2": "",
        "email หัวหน้างานระดับที่ 2": "",
      },
    },
    template28: {
      sheet: ["company charge"],
      fileName: "บริษัทที่เรียกเก็บเงิน.xlsx",
      header: {
        ...defaultHeader,
        บริษัทที่เรียกเก็บเงิน: "",
      },
    },

    template30: {
      sheet: ["nickname"],
      fileName: "ชื่อเล่น.xlsx",
      header: {
        ...defaultHeader,
        ชื่อเล่น: "",
      },
    },
    template31: {
      sheet: ["gender"],
      fileName: "เพศ.xlsx",
      header: {
        ...defaultHeader,
        เพศ: "",
      },
    },
    template32: {
      sheet: ["transfer"],
      fileName: "โอนย้าย.xlsx",
      header: {
        ...defaultHeader,
        ชื่อบริษัท: "",
        ชื่อตำแหน่ง: "",
        ฝ่าย: "",
        ส่วน: "",
        แผนก: "",
        "วัน/เดือน/ปีค.ศ.ที่มีผล": "",
        "email หัวหน้างานระดับที่ 1": "",
        "email หัวหน้างานระดับที่ 2": "",
        หมายเหตุ: "",
      },
    },
  };

  const handleChangeDumpSelect = (event) => {
    setDumpSelect(event.target.value);
  };

  const handleChangeDumpListSelect = (event) => {
    setSelectedDumpList(event.target.value);
    if (event.target.value === "editDumpList") {
      setType("edit");
    } else if (event.target.value === "insertDumpList") {
      setType("insert");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.map((file) => {
        formData.append("file", file);
        formData.append("selected", vendorSelected);
      });
      setFormFileSelected(formData);
      setFileSelected({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    },
    maxFiles: 1,
  });

  const handleClickUpload = async () => {
    if (dumpSelect === 2) {
      const result = await dispatch(addUsers(formFileSelected));
      if (result) {
        console.log(result);
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 8) {
      const result = await dispatch(
        addEmployeeAdditionDeduction(formFileSelected)
      );
    } else {
      const result = await dispatch(
        dumpExcelEmployees(formFileSelected, {
          idVendor: companyContractList.map((vendor) => vendor.idVendor),
          type: type,
          document: template[`template${dumpSelect}`].sheet[0],
        })
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    }
    setLoadingUpload(false);
  };

  const handleChange = (event) => {
    setVendorSelected(event.target.value);
  };

  const handleCloseLoading = () => {
    //setOpenLoading(false);
    toggleDrawer(false);
    dispatch(getAllUsersCompany(userProfile.idCompany, "all"));
  };

  const handleClickDownload = () => {
    // console.log(dumpSelect);
    // const template = {
    //   template7: {
    //     sheet: ["salary"],
    //     fileName: "ปรับเงินเดือน.xlsx",
    //     header: {
    //       รหัสพนักงาน: "",
    //       ชื่อ: "",
    //       สกุล: "",
    //       เลขบัตรประจำตัวประชาชน: "",
    //       เงินเดือน: "",
    //     },
    //   },
    // };

    // if (dumpSelect === 7) {
    //   const elementList = [];

    //   elementList.push(template[`template${dumpSelect}`].header);

    //   const ws = XLSX.utils.json_to_sheet(elementList);

    //   var workbook = XLSX.utils.book_new();

    //   template[`template${dumpSelect}`].sheet.forEach((element) => {
    //     XLSX.utils.book_append_sheet(workbook, ws, element);
    //   });

    //   XLSX.writeFile(workbook, template[`template${dumpSelect}`].fileName, {
    //     type: "file",
    //   });
    // }

    if (dumpSelect === 2) {
      window.open(
        `${process.env.REACT_APP_API_URL}files/PIS%20Master%20%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%9F%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%A1%E0%B9%80%E0%B8%9E%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%9E%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%87%E0%B8%B2%E0%B8%99%202023-04-08.xlsx`
      );
    }
    if (dumpSelect === 10) {
      window.open(`${process.env.REACT_APP_API_URL}files/ปรับค่าจ้าง.xlsx`);
    }

    const downloadList = [
      9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
      28, 30, 31, 32,
    ];

    if (downloadList.includes(dumpSelect)) {
      const elementList = [];

      elementList.push(template[`template${dumpSelect}`].header);

      const ws = XLSX.utils.json_to_sheet(elementList);

      var workbook = XLSX.utils.book_new();

      template[`template${dumpSelect}`].sheet.forEach((element) => {
        XLSX.utils.book_append_sheet(workbook, ws, element);
      });

      XLSX.writeFile(workbook, template[`template${dumpSelect}`].fileName, {
        type: "file",
      });
    }
  };

  return (
    <DrawerCustom
      title={`${t("Upload")}`}
      anchor={"right"}
      open={open}
      onClose={() => toggleDrawer(false)}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel variant="subtitle1">{t("SelectType")}</FormLabel>
            <StyleSelect fullWidth disabled={waitingAPI}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="selectedDumpList"
                value={selectedDumpList}
                onChange={handleChangeDumpListSelect}
              >
                {/* <FormControlLabel
                  value="historyDumpList"
                  control={<Radio />}
                  label="อัพโหลดเพื่อเก็บข้อมูลย้อนหลัง"
                /> */}
                <FormControlLabel
                  value="editDumpList"
                  control={<Radio />}
                  label={t("UploadEdit")}
                />
                <FormControlLabel
                  value="insertDumpList"
                  control={<Radio />}
                  label={t("UploadInsert")}
                />
              </RadioGroup>
            </StyleSelect>
          </Grid>
          <Grid item xs={12}>
            {selectedDumpList && (
              <StyleSelect fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("Select_File_Upload")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="dump-list"
                  label={t("Select_File_Upload")}
                  onChange={handleChangeDumpSelect}
                >
                  {selectedDumpList ? (
                    selectedDumpList === "editDumpList" ? (
                      editDumpList.map((item) => (
                        <MenuItem
                          key={item.id}
                          disabled={item.disabled}
                          value={item.id}
                        >
                          {item.name}
                        </MenuItem>
                      ))
                    ) : selectedDumpList === "insertDumpList" ? (
                      insertDumpList.map((item) => (
                        <MenuItem
                          key={item.id}
                          disabled={item.disabled}
                          value={item.id}
                        >
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )
                  ) : null}
                </Select>
              </StyleSelect>
            )}
          </Grid>
          {dumpSelect && (
            <Grid item xs={12}>
              {openLoading ? (
                <StyledLoading>
                  <Typography variant="h6" color="text.third" gutterBottom>
                    {t("Saving_Data")}...
                  </Typography>
                  <div>
                    <CircularProgress />
                  </div>
                </StyledLoading>
              ) : (
                <Fragment>
                  {errorResponse ? (
                    <ShowResponse
                      errorResponse={errorResponse}
                      handleCloseLoading={handleCloseLoading}
                    />
                  ) : (
                    <Fragment>
                      {(dumpSelect === 1 || dumpSelect === 2) &&
                        companyContractList && (
                          <Fragment>
                            <div className="select-company">
                              <StyleSelect fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  {t("Select_Company")}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  name="dump-list"
                                  label={t("Select_Company")}
                                  value={vendorSelected}
                                  onChange={handleChange}
                                >
                                  {companyContractList.map((item) => (
                                    <MenuItem
                                      key={item.idVendor}
                                      value={item.idVendor}
                                    >
                                      {item.vendorName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </StyleSelect>
                            </div>
                          </Fragment>
                        )}
                      <StyledImportFile>
                        <div
                          style={{
                            marginBottom: 16,
                            display: "flex",
                            justifyContent: "flex-end",
                            cursor: "pointer",
                          }}
                        >
                          <Link variant="body2" onClick={handleClickDownload}>
                            {t("Download_Form")}
                          </Link>
                        </div>
                        <div
                          {...getRootProps({
                            className: "dropzone-upload-file",
                          })}
                        >
                          <input {...getInputProps()} />
                          <div className="inner-dropzone">
                            <img alt="AddIcon" src={AddIcon} width="80" />
                            <Typography
                              style={{
                                marginTop: 8,
                                backgroundColor: "transparent",
                              }}
                              className={`${
                                fileSelected != 0 && `placeholderLabel`
                              }`}
                              variant="body2"
                              color="text.secondary"
                            >
                              Drag & Drop / {t("Click_For_Select_File")}
                            </Typography>
                          </div>
                        </div>
                      </StyledImportFile>
                      {fileSelected && (
                        <FileSelected
                          fileSelected={fileSelected}
                          handleClickUpload={handleClickUpload}
                        />
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Grid>
          )}
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogUpload;
