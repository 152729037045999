import {
  PROBATION_QUESTION_FETCHING,
  PROBATION_QUESTION_FAILED,
  PROBATION_QUESTION_SUCCESS,
  RESULT_PROBATION_SUCCESS,
  RESULT_PROBATION_FETCHING,
  RESULT_PROBATION_FAILED,
  PROBATION_EMPLOYEES_FETCHING,
  PROBATION_EMPLOYEES_SUCCESS,
  PROBATION_EMPLOYEES_FAILED,
} from "./types";
import ProbationService from "../services/probation.service";

export const getProbationQuestion = (query) => async (dispatch) => {
  try {
    dispatch({
      type: PROBATION_QUESTION_FETCHING,
    });
    const res = await ProbationService.getProbationQuestion(query);
    if (res) {
      dispatch({
        type: PROBATION_QUESTION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

export const addProbationQuestion = (formdata) => async (dispatch) => {
  try {
    const res = await ProbationService.addProbationQuestion(formdata);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

export const updateProbationQuestionStatus = (formdata) => async (dispatch) => {
  try {
    const res = await ProbationService.updateProbationQuestionStatus(formdata);
    if (res) {
      return res;
    }
  } catch (error) {
    dispatch({
      type: PROBATION_QUESTION_FAILED,
    });
  }
};

export const updateProbationManager = (formData) => async () => {
  try {
    const res = await ProbationService.updateProbationManager(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const updateProbationAdmin = (formData) => async () => {
  try {
    const res = await ProbationService.updateProbationAdmin(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const getProbationAndAnswer = async (formData) => {
  try {
    const res = await ProbationService.getProbationAndAnswer(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const getProbationEmployees = () => async (dispatch) => {
  try {
    dispatch({
      type: PROBATION_QUESTION_FETCHING,
    });
    const res = await ProbationService.getProbationEmployees();
    if (res) {
      dispatch({
        type: PROBATION_EMPLOYEES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: PROBATION_EMPLOYEES_FAILED,
    });
    console.log(err);
  }
};

export const getProbationResult = (idEmp) => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_PROBATION_FETCHING,
    });
    const res = await ProbationService.getProbationResult(idEmp);
    if (res) {
      dispatch({
        type: RESULT_PROBATION_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: RESULT_PROBATION_FAILED,
    });
  }
};
