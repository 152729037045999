import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Divider,
  Grid,
  IconButton,
  styled,
  Typography,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

import utils from "../../../../../../utils";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import AlertResponse from "../../../../shared/general/AlertResponse";
import DialogEdit from "./DialogEdit";

import { getEmployeeAdditionDeduction } from "../../../../../../actions/employee";
import DialogDelete from "./DialogDelete";

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: 16,
  "&.inner": {
    marginBottom: 0,
  },
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  "&.MuiTypography-subtitle1": {
    fontSize: 16,
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StylePriceDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const StyledBoxList = styled(Box)({
  width: "100%",
  padding: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& p": {
    fontStyle: "oblique",
  },
});

const AdditionDeduction = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [mode, setMode] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: employeeAdditionDeduction } = useSelector(
    (state) => state.employeeAdditionDeduction
  );

  useEffect(() => {
    dispatch(getEmployeeAdditionDeduction(employeeProfile.idEmp));
  }, []);

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickDelete = (mode) => {
    setOpenDelete(true);
    setMode(mode);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleSetAlertType = (newValue) => {
    setAlertType(newValue);
  };

  return (
    <div>
      <div>
        <StyledWrapTop>
          <StyledHeadLabel variant="subtitle1">{t("Addition")}</StyledHeadLabel>

          {/* <IconButton
            aria-label="add"
            size="small"
            onClick={() => handleClick("addition new")}
          >
            <AddCircleRoundedIcon fontSize="small" />
          </IconButton> */}
          <ButtonBlue
            variant="outlined"
            size="small"
            startIcon={<AddRoundedIcon />}
            onClick={() => {
              setDataEdit(null);
              handleClick("addition new");
            }}
          >
            {t("Add")}
          </ButtonBlue>
        </StyledWrapTop>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel variant="body2" color="text.secondary">
              {t("Items")}
            </StyledHeadLabel>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledHeadLabel variant="body2" color="text.secondary">
              {t("Amount")}
            </StyledHeadLabel>
          </Grid>
          {employeeAdditionDeduction &&
          employeeAdditionDeduction.addition.length > 0 ? (
            employeeAdditionDeduction.addition.map((addition) => (
              <Fragment key={addition.idEmployeeAddition}>
                <Grid item xs={12} md={6}>
                  <div>
                    <Typography variant="h6">{addition.name}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StylePriceDiv>
                    <Typography
                      variant="h6"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {`${utils.numberWithCommas(addition.value)} ${t("Baht")}`}
                    </Typography>
                    <div>
                      <IconButton 
                        aria-label="edit" 
                        size="small"
                        onClick={() => {
                          setDataEdit(addition);
                          handleClick("addition edit");
                        }}
                      >
                        <EditRoundedIcon
                          fontSize="inherit"
                        />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        style={{ color: "red" }}
                        onClick={() => {
                          setDataEdit(addition);
                          handleClickDelete("addition");
                        }}
                      >
                        <DeleteOutlineRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </StylePriceDiv>
                </Grid>
              </Fragment>
            ))
          ) : (
            <StyledBoxList>
              <Typography color="text.secondary">{t("No_Data")}</Typography>
            </StyledBoxList>
          )}
        </Grid>
      </div>
      <StyledDivider />
      <div>
        <StyledWrapTop>
          <StyledHeadLabel variant="subtitle1" gutterBottom>
            {t("Deduction")}
          </StyledHeadLabel>
          <ButtonBlue
            variant="outlined"
            size="small"
            startIcon={<AddRoundedIcon />}
            onClick={() => {
              setDataEdit(null);
              handleClick("deduction new");
            }}
          >
            {t("Add")}
          </ButtonBlue>
        </StyledWrapTop>
        <Grid container spacing={2}>
          {employeeAdditionDeduction &&
          employeeAdditionDeduction.deduction.length > 0 ? (
            employeeAdditionDeduction.deduction.map((deduction) => (
              <Fragment key={deduction.idEmployeeDeduction}>
                <Grid item xs={12} md={6}>
                  <StyledHeadLabel color="text.secondary">
                    {t("ItemName")}
                  </StyledHeadLabel>
                  <div
                    style={{
                      height: "calc( 100% - 21px )",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">{deduction.name}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledHeadLabel color="text.secondary">
                    {t("Amount")}
                  </StyledHeadLabel>
                  <StylePriceDiv>
                    <Typography
                      variant="h6"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {`${utils.numberWithCommas(deduction.value)} ${t("Baht")}`}
                    </Typography>
                    <div>
                      <IconButton 
                        aria-label="edit" 
                        size="small"
                        onClick={() => {
                          setDataEdit(deduction);
                          handleClick("deduction edit");
                        }}
                      >
                        <EditRoundedIcon
                          fontSize="inherit"
                        />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        style={{ color: "red" }}
                        onClick={() => {
                          setDataEdit(deduction);
                          handleClickDelete("deduction");
                        }}
                      >
                        <DeleteOutlineRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  </StylePriceDiv>
                </Grid>
              </Fragment>
            ))
          ) : (
            <StyledBoxList>
              <Typography color="text.secondary">{t("No_Data")}</Typography>
            </StyledBoxList>
          )}
        </Grid>
      </div>
      <DialogEdit
        mode={mode}
        open={openDialog}
        dataEdit={dataEdit}
        handleCloseDialog={handleCloseDialog}
        handleOpenAlert={handleOpenAlert}
        handleSetAlertType={handleSetAlertType}
        dataAlready={employeeAdditionDeduction}
      />

      <DialogDelete
        mode={mode}
        open={openDelete}
        dataEdit={dataEdit}
        handleClose={handleCloseDelete}
        handleOpenAlert={handleOpenAlert}
        handleSetAlertType={handleSetAlertType}
      />

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </div>
  );
};

export default AdditionDeduction;
