import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import TableRequest from "./table";

const TimeAttendance = (props) => {
  const { searchName } = props;
  const { t } = useTranslation();
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (requestTimeList) {
      setDataTable(
        requestTimeList.filter((x) => {
          if(
            x.idRequestType === 1 && (
              (x.isDoubleApproval === 1 && (x.isManagerLV1Approve !== null && x.isManagerLV2Approve !== null)) ||
              (x.approvalLevel === 1 && x.isManagerLV1Approve !== null) ||
              (x.approvalLevel === 2 && x.isDoubleApproval === 0 && x.isManagerLV2Approve !== null) || 
              x.isActive === 0
            ) && (
              searchName && searchName.length > 0 ? 
                (
                  x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
                  x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
                )
                : true
            )
          ){
            return true;
          }else{
            return false;
          }
        })
      );
    }
    setIsLoading(false);
  }, [requestTimeList, searchName]);

  return (
    <Fragment>
      {isLoading ? 
        <div style={{ width:"100%", display:"flex", justifyContent:"center", paddingTop: 16 }}>
          <Typography style={{ fontSize: 20, fontWeight: 500 }}>{t("LoadingData")}</Typography>
        </div>
        :
        <Fragment>
          {requestTimeList && dataTable && (
            <TableRequest data={dataTable} searchName={searchName} />
          )}
        </Fragment>
      }
    </Fragment>
  );
};

export default TimeAttendance;
