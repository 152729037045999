import React, { useEffect } from 'react';
import {
    FormControlLabel,
    FormGroup,
    Grid,
    styled,
    Switch,
    Typography
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { 
    addRequestReason,
    getRequestReason,
    updateRequestReason 
} from "../../../../../actions/requestTime";

import ButtonBlue from '../../../shared/general/ButtonBlue';
import DrawerCustom from '../../../shared/general/Drawer';
import TextFieldTheme from '../../../shared/general/TextFieldTheme';

const Root = styled("div")({
    minWidth: "320px",
    padding: "16px"
});

const DrawerReason = (props) => {
    const { open, onClose, type, reason, renderRequestReason, handleOpenAlert, idCompany } = props;
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { handleSubmit, control, formState:{ errors }, reset } = useForm({
        defaultValues:{
            name: "",
            startDate: dayjs().add(1,"day"),
            isPayShift: false,
            isBreak: false,
        }
    });

    useEffect(() => {
        if(reason){
            reset({
                name: reason.name || "",
                startDate: reason.startDate || dayjs().add(1,"day"),
                isPayShift: reason.isPayShift === 1 ? true : false,
                isBreak: reason.isBreak === 1 ? true : false,
            })
        }
    }, [reason]);

    const onHandleSubmit = async (values) => {
        let data = {
            name: values.name,
            isPayShift: values.isPayShift ? 1 : 0,
            isBreak: values.isBreak ? 1 : 0,
            isOT: type === "OT",
            isResign: type === "resign",
            idRequestType: type === "OT" ? 2 : type === "requestTime" ? 1 : null,
            ...reason ? {
                idRequestReason : reason.idRequestReason
            } : {},
            idVendor: userProfile.idVendor,
            idCompany,
        };
        let result = null; 
        if(reason){
            result = await dispatch(updateRequestReason(data));
        } else {
            result = await dispatch(addRequestReason(data));
        }
        if (result.status === 201) {
            dispatch(getRequestReason(idCompany));
            handleOpenAlert("success");
            renderRequestReason();
        } else {
            handleOpenAlert("error");
        }
        onClose();
    };

    const title = {
        OT: "ขอค่าล่วงเวลา",
        requestTime: "ขอรับรองเวลาทำงาน",
        resign: "ขอลาออก",
    }

    return (
        <DrawerCustom
            title={`${reason ? "แก้ไขเหตุผล" : "เพิ่มเหตุผล"}${title[type]}`}
            anchor="right"
            open={open}
            onClose={onClose}
        >
            <Root>
                <form onSubmit={handleSubmit(onHandleSubmit)}>
                    <Typography>ชื่อเหตุผล</Typography>
                    <Controller 
                        control={control}
                        name="name"
                        rules={{
                            required: { value: true, message: "กรุณากรอกชื่อเหตุผล" }
                        }}
                        render={({ field }) => (
                            <TextFieldTheme 
                                {...field}
                                style={{ marginTop: "8px" }}
                                error={errors && errors.name}
                                helperText={errors && errors.name && errors.name.message}
                            />
                        )}
                    />
                    {type === "OT" && 
                        <div>
                            <div style={{ marginTop: "16px" }}>
                                <FormGroup>
                                    <Controller 
                                        control={control}
                                        name="isPayShift"
                                        render={({ field }) => (
                                            <FormControlLabel 
                                                control={
                                                    <Switch
                                                        checked={field.value}
                                                        onChange={(event) => {
                                                            field.onChange(event.target.checked);
                                                        }}
                                                    />
                                                } 
                                                label="ได้รับค่ากะ" 
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </div>
                            <div style={{ marginTop: "16px" }}>
                                <FormGroup>
                                    <Controller 
                                        control={control}
                                        name="isBreak"
                                        render={({ field }) => (
                                            <FormControlLabel 
                                                control={
                                                    <Switch
                                                        checked={field.value}
                                                        onChange={(event) => {
                                                            field.onChange(event.target.checked);
                                                        }}
                                                    />
                                                } 
                                                label="เวลาพัก" 
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    }
                    <Grid container justifyContent="flex-end"  style={{ marginTop: "16px" }}>
                        <Grid item>
                            <ButtonBlue
                                onClick={onClose}
                            >ยกเลิก</ButtonBlue>
                            <ButtonBlue
                                type="submit"
                                variant="contained"
                                style={{ marginLeft: "8px" }}
                            >
                                {reason ? `บันทึก` : `ยืนยัน`}
                            </ButtonBlue>
                        </Grid>
                    </Grid>
                </form>
            </Root>
        </DrawerCustom>
    );
};

export default DrawerReason;