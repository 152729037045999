import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Box, Grid, InputAdornment, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import NumberFormat from "react-number-format";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { updateEmployeeSalary } from "../../../../../../actions/salary";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled(Box)({
  padding: "16px",
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  paddingBottom: "8px"
});

const DrawerEditSalary = (props) => {

  const { drawerConfig, handleClose } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);

  const validateYupSchema = yup.object({
    salaryBilling: yup.string(),
  });

  const useHookForm = useForm({
    defaultValues: {
      salaryBilling: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  })

  useEffect(() => {
    if(drawerConfig.isOpen){
      useHookForm.setValue("salaryBilling", drawerConfig.data.salaryBilling === null? "": drawerConfig.data.salaryBilling, { shouldDirty: false, shouldTouch: false, shouldValidate: false })
    }
  }, [drawerConfig.isOpen])

  const onSubmit = async (data) => {

    const formData = {
      salaryBilling: data.salaryBilling === ""? null: data.salaryBilling,
    }

    // console.log("formData", formData)
    // console.log("idEmployeeSalary", drawerConfig.data.idEmployeeSalary)

    const result = await dispatch(updateEmployeeSalary(drawerConfig.data.idEmployeeSalary, formData));

    if(result && result.status === 200) {
      handleClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }
  }

  return (
    <DrawerCustom
      title={t("DailyShiftChange")}
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {`${t("SalaryBilling")}`}
              </StyledHeadLabel>
              <Controller
                name="salaryBilling"
                control={useHookForm.control}
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormat,
                      endAdornment: <InputAdornment position="end">{t("Baht")}</InputAdornment>
                    }}
                    inputProps={{
                      thousandSeparator:",",
                      allowNegative: false,
                      allowLeadingZeros: true,
                      value: field.value,
                      decimalScale: 2,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value)
                      },
                    }}
                    onChange={()=>{}}
                    error={fieldState.error? true: false}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue className="cancel" onClick={handleClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerEditSalary;