import {
  SHIFT_FETCHING,
  SHIFT_FAILED,
  SHIFT_SUCCESS,
  SHIFT_PATTERN_FETCHING,
  SHIFT_PATTERN_FAILED,
  SHIFT_PATTERN_SUCCESS,
  EMPLOYEE_SHIFT_FETCHING,
  EMPLOYEE_SHIFT_FAILED,
  EMPLOYEE_SHIFT_SUCCESS,
  SHIFT_GROUP_FETCHING,
  SHIFT_GROUP_FAILED,
  SHIFT_GROUP_SUCCESS,
  EMPLOYEE_SHIFT_SCHEDULE_FETCHING,
  EMPLOYEE_SHIFT_SCHEDULE_FAILED,
  EMPLOYEE_SHIFT_SCHEDULE_SUCCESS,
} from "./types";

import ShiftService from "../services/shift.service";

export const getShiftPattern = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: SHIFT_PATTERN_FETCHING,
    });
    const res = await ShiftService.getShiftPattern(idCompany);
    if (res) {
      dispatch({
        type: SHIFT_PATTERN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SHIFT_PATTERN_FAILED,
    });
    console.log(err);
  }
};

export const getShift = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: SHIFT_FETCHING,
    });
    const res = await ShiftService.getShift(idCompany);
    if (res) {
      dispatch({
        type: SHIFT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SHIFT_FAILED,
    });
    console.log(err);
  }
};
export const getShiftGroupById = (idShiftGroup) => async (dispatch) => {
  try {
    dispatch({
      type: SHIFT_GROUP_FETCHING,
    });
    const res = await ShiftService.getShiftGroupById(idShiftGroup);
    if (res) {
      dispatch({
        type: SHIFT_GROUP_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: SHIFT_GROUP_FAILED,
    });
    console.log(err);
  }
};

export const getAllEmployeeShift = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_SHIFT_FETCHING,
    });
    const res = await ShiftService.getAllEmployeeShift(idCompany);
    if (res) {
      dispatch({
        type: EMPLOYEE_SHIFT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_SHIFT_FAILED,
    });
    console.log(err);
  }
};

export const addnewShift = (data) => async (dispatch) => {
  try {
    const res = await ShiftService.addNewShift(data);
    if(res){
      return res;
    }
  } catch (error) {
    return "Error";
  }
};

export const getShiftScheduleByEmployeeId = (idEmployees, query) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_SHIFT_SCHEDULE_FETCHING,
    });
    const res = await ShiftService.getShiftScheduleByEmployeeId(idEmployees, query);
    if (res) {
      dispatch({
        type: EMPLOYEE_SHIFT_SCHEDULE_SUCCESS,
        payload: res.data,
      });
      return res
    }
  } catch (err) {
    dispatch({
      type: EMPLOYEE_SHIFT_SCHEDULE_FAILED,
    });
  }
};