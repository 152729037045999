import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { Divider, Grid, Typography } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../shared/general/ButtonBlue";

import DialogContact from "./DialogContact";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledTextEmpty = styled(Typography)({
  fontStyle: "oblique",
  fontWeight: 400,
  color: "#b8b8b8",
});

const ProfileTab = (props) => {
  const { t, i18n } = useTranslation();
  const { handleChangeAlertType, handleOpenAlert } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [openDialogContact, setOpenDialogContact] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialogContact(false);
  };

  return (
    <Grid container spacing={2}>
      {userProfile && (
        <Fragment>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              {t("EmployeeID")}
            </StyledHeadLabel>
            <Typography variant="h6">{userProfile.employeeId}</Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              {t("IDCardNumber")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {userProfile.personalId.replace(
                /(\d)(\d\d\d\d)(\d\d\d\d\d)(\d\d)(\d)/,
                "$1 $2 $3 $4 $5"
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledHeadLabel color="text.secondary">
              {t("BirthDate")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {dayjs(userProfile.birthday)
                .locale(localStorage.getItem("language") || "th")
                .format(
                  localStorage.getItem("language") === "en"
                    ? "DD MMM YYYY"
                    : "DD MMM BBBB"
                )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledDivider />
            <StyledWrapHead>
              <StyledHeadLabel style={{ marginBottom: 4 }}>
                {t("ContactInfo")}
              </StyledHeadLabel>
              <ButtonBlue
                size="small"
                startIcon={<EditIcon />}
                onClick={() => setOpenDialogContact(true)}
              >
                {t("Edit")}
              </ButtonBlue>
            </StyledWrapHead>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">
                {t("PhoneNumber")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {userProfile.telephoneMobile ? (
                  userProfile.telephoneMobile.replace(
                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                    "$1-$2-$3"
                  )
                ) : (
                  <StyledTextEmpty>
                    {t("fillInData")}
                  </StyledTextEmpty>
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel color="text.secondary">{t("Email")}</StyledHeadLabel>
              <Typography variant="h6">{userProfile.email}</Typography>
            </div>
          </Grid>
        </Fragment>
      )}
      <DialogContact
        open={openDialogContact}
        handleCloseDialog={handleCloseDialog}
        handleChangeAlertType={handleChangeAlertType}
        handleOpenAlert={handleOpenAlert}
      />
    </Grid>
  );
};

export default ProfileTab;
