import { styled } from "@mui/material/styles";
import React from "react";

//import materials
import { CardContent, Grid, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";

const StyledCardContent = styled(CardContent)({
  padding: "24px !important",
  height: 536,
  overflow: "auto",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiFormControl-root": {
    width: "100%",
  },
});

const StyledProfileTitle = styled("div")({
  backgroundColor: "#2F74EB",
  padding: 16,
  "& .MuiTypography-root": {
    color: "#FFFFFF",
    fontSize: 20,
    fontWeight: 500,
  },
});

const StyledTextHeading = styled(Typography)({
  fontSize: 16,
  flexBasis: "33.33%",
  marginTop: 16,
  "&.error": {
    color: "#f15e5e",
  },
});

export default function EmploymentType(props) {
  const { onChange, formik } = props;
  return (
    <div>
      <form autoComplete="off" noValidate>
        <StyledProfileTitle>
          <Typography style={{ marginLeft: 8 }}>ประเภทพนักงาน</Typography>
        </StyledProfileTitle>
        <StyledCardContent>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <StyledTextHeading
                variant="h6"
                className={
                  formik.touched.employmentTime &&
                  Boolean(formik.errors.employmentTime) &&
                  "error"
                }
              >
                รูปแบบงาน
              </StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="employmentTime"
                  name="employmentTime"
                  onChange={onChange}
                  value={formik.values.employmentTime}
                  error={formik.touched.employmentTime && Boolean(formik.errors.employmentTime)}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    value="Full time"
                    control={<Radio />}
                    label="Full time"
                  />
                  <FormControlLabel
                    value="Part time"
                    control={<Radio />}
                    label="Part time"
                  />
                </RadioGroup>
                {formik.touched.employmentTime && Boolean(formik.errors.employmentTime) && (
                  <FormHelperText style={{ color: "#f15e5e", marginLeft: 0 }}>
                    {formik.errors.employmentTime}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
              <StyledTextHeading variant="h6" className={
                  formik.touched.idPaymentType &&
                  Boolean(formik.errors.idPaymentType) &&
                  "error"
                }>รูปแบบการจ่าย</StyledTextHeading>
            </Grid>
            <Grid item md={8} xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="idPaymentType"
                  name="idPaymentType"
                  onChange={(event) => {
                    formik.setFieldValue("idPaymentType", event.target.value, true);
                    if(event.target.value === "1"){
                      formik.setFieldValue("paymentRound", "รายครึ่งเดือน");
                    } else if(event.target.value === "2"){
                      formik.setFieldValue("paymentRound", "รายเดือน");
                    } else if(event.target.value === "4"){
                      formik.setFieldValue("paymentRound", "รายครึ่งเดือน");
                    } else if(event.target.value === "5"){
                      formik.setFieldValue("paymentRound", "รายเดือน");
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.idPaymentType}
                >
                  <FormControlLabel
                    value="1"
                    label="รายวัน"
                    control={<Radio />}
                  />
                  <Typography color="textSecondary" variant="body1">
                    รอบจ่าย: รายครึ่งเดือน
                  </Typography>
                  <FormControlLabel
                    value="5"
                    label="รายวัน"
                    control={<Radio />}
                  />
                  <Typography color="textSecondary" variant="body1">
                    รอบจ่าย: รายเดือน
                  </Typography>
                  <FormControlLabel
                    value="4"
                    label="รายเดือน"
                    control={<Radio />}
                  />
                  <Typography color="textSecondary" variant="body1">
                    รอบจ่าย: รายครึ่งเดือน
                  </Typography>
                  <FormControlLabel
                    value="2"
                    label="รายเดือน"
                    control={<Radio />}
                  />
                  <Typography color="textSecondary" variant="body1">
                    รอบจ่าย: รายเดือน
                  </Typography>
                </RadioGroup>
                {formik.touched.idPaymentType && Boolean(formik.errors.idPaymentType) && (
                  <FormHelperText style={{ color: "#f15e5e", marginLeft: 0 }}>
                    {formik.errors.idPaymentType}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </StyledCardContent>
      </form>
    </div>
  );
}
