import LineService from "../services/line.service";

export const getLineUserProfile = (id, source) => async () => {
  try {
    const res = await LineService.getLineUserProfile(id, source);
    if (res) {
        console.log(res)
      return res.data;
    }
  } catch (error) {
    var result = {
      status: "",
    };
    if (error.response) {
      result.status = error.response.status;
    }
    return result;
  }
};

export const getTodayCheck = (id) => async () => {
  try {
    const res = await LineService.getTodayCheck(id);
    if (res) {
        console.log(res)
      return res.data;
    }
  } catch (error) {
    var result = {
      status: "",
    };
    if (error.response) {
      result.status = error.response.status;
    }
    return result;
  }
};

export const postAttendance = (formData) => async () => {
  try {
    const res = await LineService.postAttendance(formData);
    if (res) {
      return res
    }
  } catch (error) {
    console.log(error)
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error"
  }
};

export const registerLine = (formData) => async () => {
  try {
    console.log(formData)
    const res = await LineService.registerLine(formData);
    if (res) {
      return res
    }
  } catch (error) {
    console.log(error)
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error"
  }
};
