import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import materials
import { Grid } from "@mui/material";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Select,
  FormHelperText,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import AdapterDateFns from "@tarzui/date-fns-be";
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { th } from "date-fns/locale";

import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import utils from "../../../../../utils";

//import style
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";

const StyledCardContent = styled(CardContent)({
  padding: "24px !important",
  height: 536,
  overflow: "auto",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiFormControl-root": {
    width: "100%",
  },
});

const StyleSelect = styled(Select)({
  borderRadius: 8,
  "& .MuiSelect-select": {
    padding: "13.5px 14px",
  },
});

const StyledProfileTitle = styled("div")({
  backgroundColor: "#2F74EB",
  padding: 16,
  "& .MuiTypography-root": {
    color: "#FFFFFF",
    fontSize: 20,
    fontWeight: 500,
  },
});

const StyledTextHeading = styled(Typography)({
  fontSize: 16,
  flexBasis: "33.33%",
  marginTop: 16,
  "&.error": {
    color: "#f15e5e",
  },
});

const methodAttendance = [
  {
    label: "ที่ทำงานหลัก",
    value: "main",
  },
  {
    label: "ทุกสถานที่",
    value: "location",
  },
  {
    label: "พิกัด",
    value: "point",
  },
];

export default function JobDetails(props) {
  const { onChange, formik, companySelect, setCompanySelect } = props;
  const { result: empDataForm } = useSelector((state) => state.empDataForm);
  const [job, setJob] = useState({
    division: "",
    department: "",
    section: "",
    jobGroup: "",
  });

  return (
    <div>
      {empDataForm && (
        <form autoComplete="off" noValidate>
          <StyledProfileTitle>
            <Typography color="text.secondary" style={{ marginLeft: 8 }}>
              รายละเอียดงาน
            </Typography>
          </StyledProfileTitle>
          <Divider />
          <StyledCardContent>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <StyledTextHeading
                  variant="h6"
                  className={
                    formik.touched.hiringDate &&
                    Boolean(formik.errors.hiringDate) &&
                    "error"
                  }
                >
                  วันที่เริ่มงาน
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <DatePicker
                    label=""
                    name="hiringDate"
                    value={formik.values.hiringDate}
                    onChange={(date) => {
                      formik.setFieldValue("hiringDate", date);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme
                        fullWidth
                        {...params}
                        error={
                          formik.touched.hiringDate &&
                          Boolean(formik.errors.hiringDate)
                        }
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.hiringDate &&
                          Boolean(formik.errors.hiringDate) &&
                          formik.errors.hiringDate
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading
                  variant="h6"
                  className={
                    formik.touched.idCompany &&
                    Boolean(formik.errors.idCompany) &&
                    "error"
                  }
                >
                  บริษัทเจ้าของงาน
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <Fragment>
                  <FormControl fullWidth>
                    <StyleSelect
                      name="idCompany"
                      value={formik.values.idCompany}
                      onChange={(event) => {
                        formik.setFieldValue("idCompany", event.target.value);
                        const company = empDataForm.find((item) => {
                          return item.idCompany === event.target.value;
                        });

                        if (company) {
                          setCompanySelect(company);
                          setJob({
                            division: "",
                            department: "",
                            section: "",
                            jobGroup: "",
                          })
                        }
                      }}
                      error={
                        formik.touched.idCompany &&
                        Boolean(formik.errors.idCompany)
                      }
                      onBlur={formik.handleBlur}
                    >
                      {empDataForm.map((item) => (
                        <MenuItem value={item.idCompany}>
                          {item.companyName}
                        </MenuItem>
                      ))}
                    </StyleSelect>
                    {formik.touched.idCompany &&
                      Boolean(formik.errors.idCompany) && (
                        <FormHelperText style={{ color: "#f15e5e" }}>
                          {formik.errors.idCompany}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Fragment>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading
                  variant="h6"
                  className={
                    formik.touched.idPositions &&
                    Boolean(formik.errors.idPositions) &&
                    "error"
                  }
                >
                  ตำแหน่งงาน
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl fullWidth disabled={!formik.values.idCompany}>
                  <StyleSelect
                    name="idPositions"
                    onChange={(event) => {
                      formik.setFieldValue("idPositions", event.target.value);
                      const foundJob = companySelect.job.find(
                        (x) => x.idPositions === event.target.value
                      );
                      setJob({
                        division: foundJob ? foundJob.divisionName : "",
                        department: foundJob ? foundJob.departmentName : "",
                        section: foundJob ? foundJob.sectionName : "",
                        jobGroup: foundJob ? foundJob.jobGroupName : "",
                      });
                    }}
                    value={formik.values.idPositions}
                    error={
                      formik.touched.idPositions &&
                      Boolean(formik.errors.idPositions)
                    }
                    onBlur={formik.handleBlur}
                  >
                    {companySelect.job.map((item) => (
                      <MenuItem value={item.idPositions}>
                        {item.positionsName}
                      </MenuItem>
                    ))}
                  </StyleSelect>
                  {formik.touched.idPositions &&
                    Boolean(formik.errors.idPositions) && (
                      <FormHelperText style={{ color: "#f15e5e" }}>
                        {formik.errors.idPositions}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">ฝ่าย</StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextFieldTheme
                  id="outlined-full-width"
                  fullWidth
                  variant="outlined"
                  name="division"
                  value={job.division}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">ส่วน</StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextFieldTheme
                  id="outlined-full-width"
                  fullWidth
                  variant="outlined"
                  name="department"
                  value={job.department}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">แผนก</StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextFieldTheme
                  id="outlined-full-width"
                  fullWidth
                  variant="outlined"
                  name="section"
                  value={job.section}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">กลุ่มงาน</StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextFieldTheme
                  id="outlined-full-width"
                  fullWidth
                  variant="outlined"
                  name="jobGroup"
                  value={job.jobGroup}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">ระดับงาน</StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl fullWidth disabled={!formik.values.idCompany}>
                  <StyleSelect
                    name="idJobLevel"
                    onChange={onChange}
                    value={formik.values.idJobLevel}
                  >
                    {companySelect.jobLevel.map((item) => (
                      <MenuItem value={item.idJobLevel}>
                        {item.jobLevelName}
                      </MenuItem>
                    ))}
                  </StyleSelect>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">ระดับพนักงาน</StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl fullWidth disabled={!formik.values.idCompany}>
                  <StyleSelect
                    name="idPersonnelLevel"
                    onChange={onChange}
                    value={formik.values.idPersonnelLevel}
                  >
                    {companySelect.personnelLevel.map((item) => (
                      <MenuItem value={item.idPersonnelLevel}>
                        {item.personnelLevelName}
                      </MenuItem>
                    ))}
                  </StyleSelect>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading
                  variant="h6"
                  className={
                    formik.touched.idEmployeeType &&
                    Boolean(formik.errors.idEmployeeType) &&
                    "error"
                  }
                >
                  ประเภทพนักงาน
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="idEmployeeType"
                    onChange={onChange}
                    value={formik.values.idEmployeeType}
                    error={
                      formik.touched.idEmployeeType &&
                      Boolean(formik.errors.idEmployeeType)
                    }
                    onBlur={formik.handleBlur}
                  >
                    {companySelect.employeeType.map((item) => (
                      <FormControlLabel
                        value={item.idEmployeeType}
                        control={<Radio />}
                        label={item.employeeTypeName}
                      />
                    ))}
                  </RadioGroup>
                  {formik.touched.idEmployeeType &&
                    Boolean(formik.errors.idEmployeeType) && (
                      <FormHelperText
                        style={{ color: "#f15e5e", marginLeft: 0 }}
                      >
                        {formik.errors.idEmployeeType}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading
                  variant="h6"
                  className={
                    formik.touched.filed_office &&
                    Boolean(formik.errors.filed_office) &&
                    "error"
                  }
                >
                  ประเภทงาน
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="filed_office"
                    onChange={onChange}
                    value={formik.values.filed_office}
                    error={
                      formik.touched.filed_office &&
                      Boolean(formik.errors.filed_office)
                    }
                    onBlur={formik.handleBlur}
                  >
                    <Grid container>
                      <Grid item md={6}>
                        <FormControlLabel
                          value="Filed"
                          control={<Radio />}
                          label="Filed"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          value="Office"
                          control={<Radio />}
                          label="Office"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  {formik.touched.filed_office &&
                    Boolean(formik.errors.filed_office) && (
                      <FormHelperText
                        style={{ color: "#f15e5e", marginLeft: 0 }}
                      >
                        {formik.errors.filed_office}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading
                  variant="h6"
                  className={
                    formik.touched.workingType &&
                    Boolean(formik.errors.workingType) &&
                    "error"
                  }
                >
                  ประเภทเวลางาน
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="workingType"
                    onChange={onChange}
                    value={formik.values.workingType}
                    error={
                      formik.touched.workingType &&
                      Boolean(formik.errors.workingType)
                    }
                    onBlur={formik.handleBlur}
                  >
                    <Grid container>
                      <Grid item md={6}>
                        <FormControlLabel
                          value="Day"
                          control={<Radio />}
                          label="Day"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControlLabel
                          value="Shift"
                          control={<Radio />}
                          label="Shift"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  {formik.touched.workingType &&
                    Boolean(formik.errors.workingType) && (
                      <FormHelperText
                        style={{ color: "#f15e5e", marginLeft: 0 }}
                      >
                        {formik.errors.workingType}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">
                  ประเภทกะ/ชื่อกะ
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl fullWidth disabled={!formik.values.idCompany}>
                  <StyleSelect
                    name="idShift"
                    onChange={(event) => {
                      const found = companySelect.shift.find(
                        (x) => x.idShift === event.target.value
                      );
                      formik.setFieldValue(
                        "idShift",
                        found ? found.idShift : null
                      );
                      formik.setFieldValue(
                        "idShiftGroup",
                        found ? found.idShiftGroup : null
                      );
                    }}
                    value={formik.values.idShift}
                    error={
                      formik.touched.idShift && Boolean(formik.errors.idShift)
                    }
                    onBlur={formik.handleBlur}
                  >
                    {companySelect.shift.map((item) => (
                      <MenuItem value={item.idShift}>
                        {`${item.nameShiftGroup} : ${item.nameShift}`}
                      </MenuItem>
                    ))}
                  </StyleSelect>
                  {formik.touched.idShift && Boolean(formik.errors.idShift) && (
                    <FormHelperText style={{ color: "#f15e5e" }}>
                      {formik.errors.idShift}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">สถานที่ทำงาน</StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextFieldTheme
                  id="outlined-select-currency"
                  fullWidth
                  name="workingLocationName"
                  onChange={onChange}
                  value={formik.values.workingLocationName}
                  variant="outlined"
                ></TextFieldTheme>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">ที่ทำงานหลัก</StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl fullWidth disabled={!formik.values.idCompany}>
                  <StyleSelect
                    name="mainWorkingLocationPoint"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "mainWorkingLocationPoint",
                        event.target.value
                      );
                    }}
                    value={formik.values.mainWorkingLocationPoint}
                    onBlur={formik.handleBlur}
                  >
                    {companySelect.groupGpsLocations.map((item) => (
                      <MenuItem value={item.idGroupGpsLocations}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </StyleSelect>
                  {formik.touched.mainWorkingLocationPoint &&
                    Boolean(formik.errors.mainWorkingLocationPoint) && (
                      <FormHelperText style={{ color: "#f15e5e" }}>
                        {formik.errors.mainWorkingLocationPoint}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">
                  การบันทึกเวลา
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl fullWidth>
                  <StyleSelect
                    name="isFinger"
                    onChange={onChange}
                    value={formik.values.isFinger}
                  >
                    <MenuItem value={1}>ใช่</MenuItem>
                    <MenuItem value={0}>ไม่ใช่</MenuItem>
                  </StyleSelect>
                </FormControl>
              </Grid>
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">
                  จุดการบันทึกเวลา
                </StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl fullWidth>
                  <StyleSelect
                    name="methodAttendance"
                    onChange={onChange}
                    value={formik.values.methodAttendance}
                  >
                    {methodAttendance.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </StyleSelect>
                </FormControl>
              </Grid>
              {/* Terminate */}
              <Grid item md={4} xs={12}>
                <StyledTextHeading variant="h6">สิ้นสุด</StyledTextHeading>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="isContractTermainatoinDate"
                    onChange={onChange}
                    value={formik.values.isContractTermainatoinDate}
                  >
                    <FormControlLabel
                      value="none"
                      control={<Radio color="primary" />}
                      label="ยังไม่ระบุ"
                    />
                    <FormControlLabel
                      value="date"
                      control={<Radio color="primary" />}
                      label="ระบุวันที่"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {formik.values.isContractTermainatoinDate === "date" && (
                <Fragment>
                  <Grid item md={4} xs={12}>
                    <StyledTextHeading></StyledTextHeading>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <FormControl component="fieldset">
                      <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        locale={th}
                      >
                        <DatePicker
                          label=""
                          name="contractTermainatoinDate"
                          value={formik.values.contractTermainatoinDate}
                          onChange={(date) => {
                            formik.setFieldValue(
                              "contractTermainatoinDate",
                              date
                            );
                          }}
                          renderInput={(params) => (
                            <TextFieldTheme fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </StyledCardContent>
        </form>
      )}
    </div>
  );
}
