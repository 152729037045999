import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, MenuItem, Stack, Typography, styled, Popper, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AlertResponse from "../../shared/general/AlertResponse";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { reportLists } from "./all-reports";
import { Tawi50PDFfile } from "./filePDFgeneration/50tawiPDFfile";
import { PND91PdfFile } from "./filePDFgeneration/PND91PdfFile";
import { getEmployeesTaxDetails } from "../../../../actions/employee";
import { getUserProfile } from "../../../../actions/user";
import { getAllUsersVendor } from "../../../../actions/vendor";
import { Tawi50XlsxFile } from "./fileXlsxgeneration/50tawiXlsxFile";
import { PND1kPDFfile } from "./filePDFgeneration/PND1kPDFfile";

const StyledRoot = styled(Box)({
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
    "& .MuiSelect-filled": {
        padding: "16px 32px 16px 12px",
    },
});

const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});

export default function VendorDocumentsReport() {
    const { type } = useParams();
    const [openAlert, setOpenAlert] = useState({
        isOpen: false,
        type: null,
        label: null,
    });

    const dispatch = useDispatch();
    const { control, handleSubmit, watch } = useForm({});
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: employeeList } = useSelector((state) => state.users);

    useEffect(() => {
        dispatch(getUserProfile());
    }, [dispatch]);

    useEffect(() => {
        if (userProfile) {
            dispatch(getAllUsersVendor('all'));
        }
    }, [userProfile]);

    const thaiYears = Array.from(
        { length: 6 },
        (_, index) => currentYear + 543 - index
    );

    const handleOpenAlertError = (label) => {
        setOpenAlert({ isOpen: true, type: "error", label: label });
    };

    const handleCloseAlert = () => {
        setOpenAlert({ isOpen: false, type: null, label: null });
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    useEffect(() => {
        if (selectedYear && userProfile && selectedEmployee) {
            getEmployeesTaxDetails(selectedYear, selectedEmployee, type);
        }
    }, [userProfile, selectedYear, type]);

    const modifyPdf = async (type, selectedYear) => {
        try {
            const data = await getEmployeesTaxDetails(selectedYear, selectedEmployee, type);
            if (!data.data) {
                handleOpenAlertError("ไม่มีข้อมูล");
                return;
            }

            const generateFunctionMap = {
                "PND91": PND91PdfFile,
                "50tawi": Tawi50PDFfile,
                "PND1k": PND1kPDFfile
            };

            const generateFunction = generateFunctionMap[type];

            if (!generateFunction) {
                handleOpenAlertError("Unsupported report type");
                return;
            }

            if (type === "PND91") {
                generateFunction(type, selectedYear, data.data);
            } else if (type === "50tawi") {
                generateFunction(type, selectedYear, data.data);
            } else if (type === "PND1k") {
                generateFunction(type, selectedYear, data.data);
            }
        } catch (error) {
            handleOpenAlertError("เกิดข้อผิดพลาขณะดึงข้อมูล");
        }
    };

    const modifyExcel = async (type, selectedYear) => {
        try {
            const data = await getEmployeesTaxDetails(selectedYear, selectedEmployee, type);
            if (!data.data) {
                handleOpenAlertError("ไม่มีข้อมูล");
                return;
            }

            if (type === "PND1k") {
                Tawi50XlsxFile(type, selectedYear, data.data);
            }
        } catch (error) {
            handleOpenAlertError("เกิดข้อผิดพลาขณะดึงข้อมูล");
        }
    };

    const namePage = reportLists.find((items) => items.link == type);

    const combinedEmp = employeeList && employeeList.active && employeeList.termainate
        ? employeeList.active.concat(employeeList.termainate)
        : [];

    function mergeSameFullName(employees) {
        const merged = {};
        employees.forEach(employee => {
            const fullname = `${employee.firstname} ${employee.lastname}`;
            if (!merged[fullname] || merged[fullname].idEmp > employee.idEmp) {
                merged[fullname] = employee;
            }
        });
        return Object.values(merged);
    }

    const mergedEmployees = mergeSameFullName(combinedEmp);

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Box margin="16px 0">
                    <Typography variant="h4">{namePage.name}</Typography>
                </Box>

                <CardStyle style={{ padding: 24 }}>
                    <Grid container spacing={2}>
                        {type !== "PND1k" &&
                            <Grid item xs={12} md={6}>
                                <Typography className="label" color="text.third">
                                    ค้นหา/เลือกพนักงาน
                                </Typography>
                                <div className="search-name">
                                    {employeeList && employeeList.active && employeeList.termainate ? (
                                        <StyledAutocomplete
                                            options={mergedEmployees}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setSelectedEmployee(newValue.idEmp);
                                                } else {
                                                    setSelectedEmployee(null);
                                                }
                                            }}
                                            popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                            getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    placeholder="ค้นหา/เลือกพนักงาน"
                                                />
                                            )}
                                            PopperComponent={StyledPopper}
                                            noOptionsText={"ไม่พบข้อมูล"}
                                        />
                                    ) : null}
                                </div>
                            </Grid>
                        }

                        <Grid item xs={12} md={6}>
                            <Typography className="label" color="text.third">
                                ปี
                            </Typography>
                            <Controller
                                name="year"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme
                                        {...field}
                                        variant="filled"
                                        select
                                        fullWidth
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                    >
                                        {thaiYears.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </TextFieldTheme>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} container justifyContent="flex-end">
                            <ButtonBlue
                                variant="contained"
                                type="submit"
                                disabled={type === "PND1k" ? (selectedYear === null || type === "PND91") : (selectedEmployee === null || selectedYear === null || type === "PND91")}
                                onClick={
                                    async () => {
                                        await modifyPdf(type, selectedYear);
                                    }}
                            >
                                ดูตัวอย่าง PDF
                            </ButtonBlue>

                            {type === "50tawi" &&
                                <ButtonBlue
                                    variant="contained"
                                    type="submit"
                                    disabled={selectedYear === null || type === "PND91"}
                                    onClick={
                                        async () => {
                                            await modifyExcel("PND1k", selectedYear);
                                        }}
                                    sx={{ ml: 1 }}
                                >
                                    ดาวน์โหลด XLSX
                                </ButtonBlue>
                            }

                        </Grid>

                    </Grid>
                </CardStyle>
            </Container>
            {openAlert.isOpen && (
                <AlertResponse
                    open={openAlert.isOpen}
                    alertType={openAlert.type}
                    label={openAlert.label}
                    handleClose={handleCloseAlert}
                />
            )}
        </StyledRoot>
    );
}
