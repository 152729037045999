import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const StyledRoot = styled("div")({
  "& .row-1": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
  },
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const CardZone = (props) => {
  const { area } = props;
  return (
    <StyledRoot>
      <div className="row-1">
        <Typography variant="h6">
          {area.areaName}{" "}
          <Typography component="span" color="text.secondary">
            {`(${area.amount})`}
          </Typography>
        </Typography>

        <Typography variant="h6">{`${area.headCount} คน`}</Typography>
      </div>
      <div>
        <BorderLinearProgress
          variant="determinate"
          value={((area.headCount ? area.headCount : 0) * 100) / area.amount}
        />
      </div>
    </StyledRoot>
  );
};

export default CardZone;
