import { Avatar, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


const StyledWrapTeamList = styled("div")(({}) => ({
  paddingBottom: 80,
  "& .header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
      "& span": {
        letterSpacing: 0.5,
      },
    },
  },
  "& .employee-list": {
    display: "grid",
    gridTemplateRows: "1fr auto",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    gridAutoRows: 0,
    minWidth: 350,
    columnGap: 8,
    "& .employee-item": {
      marginTop: 24,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      width: 72,
      height: 72,
      marginBottom: 8,
    },
    "& .MuiTypography-root": {
      maxWidth: 120,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
}));

const TeamList = () => {
  const dispatch = useDispatch();
  const { result: teamList } = useSelector((state) => state.users);

  useEffect(() => {
    //dispatch(getAllUsersCompany());
  }, []);

  return (
    <Fragment>
      {teamList && (
        <StyledWrapTeamList>
          <div className="header">
            <Typography color="text.secondary">
              เพื่อนร่วมทีม <span>{`(${teamList.length} คน)`}</span>
            </Typography>
            <Button variant="text">ทั้งหมด</Button>
          </div>
          <div className="employee-list">
            {teamList
              .filter((emp, index) => {
                return index < 17;
              })
              .map((emp, index) => (
                <div className="employee-item">
                  <Avatar
                    key={index}
                    alt={emp.username}
                    src={`${process.env.REACT_APP_API_URL}image/profile/${emp.image}`}
                  />
                  <Typography>{`${emp.username}`}</Typography>
                </div>
              ))}
          </div>
        </StyledWrapTeamList>
      )}
    </Fragment>
  );
};

export default TeamList;
