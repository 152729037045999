import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Radio from "@mui/material/Radio";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import dayjs from "dayjs";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TimePicker from "../../shared/general/TimePicker";
import TextFieldTheme from "../../shared/general/TextFieldTheme.js"
import { Grid } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    width: "100%",
  },
});

const StyledTSelectTimePerCandidate = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    width: "100%",
  },
});

export default function DialogAddManpower(props) {
  const { open, handleClose } = props;
  const top100Films = [
    { title: "Lleyton Wilkes", year: 1994 },
    { title: "Philip Knight", year: 1972 },
    { title: "Patryk Rowe", year: 1974 },
    { title: "Jarrad Jimenez", year: 2008 },
    { title: "Chester Currie", year: 1957 },
    { title: "Natalia Simmonds", year: 1993 },
    { title: "Reema Field", year: 1994 },
    {
      title: "Lyndsey Saunders",
      year: 2003,
    },
    { title: "Xavier Stein", year: 1966 },
    { title: "Mercy Jackson", year: 1999 },
    {
      title: "Aneurin Keeling",
      year: 2001,
    },
  ];
  const [requestAppointment, setRequestAppointment] = useState({
    serviceAppointment: "service",
    dateAppointment: dayjs().toDate(),
    timePerCandidate: 10,
    exam: "no",
    startMinute: "",
    startHours: "",
    endMinute: "",
    endHours: "",
    reason: "",
  });

  const handleChange = (event) => {
    setRequestAppointment({
      ...requestAppointment,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeTimePerCandidate = (event) => {
    setRequestAppointment({
      ...requestAppointment,
      ["timePerCandidate"]: event.target.value,
    });
  };

  const handleChangeTime = (name, unit, periodTime, value) => {
    setRequestAppointment({
      ...requestAppointment,
      [name]: value,
    });
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth={"sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"สร้างแผนกำลังพล"}</DialogTitle>
        <DialogContent>
          <div style={{ paddingTop: 8 }}>
            <TextFieldTheme label="Team"/>
          </div>
          <div style={{ paddingTop: 8 }}>
            <TextFieldTheme label="Level"/>
          </div>
          <div style={{ paddingTop: 8 }}>
            <TextFieldTheme label="Manpower plan"/>
          </div>
           
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <ButtonBlue variant={"contained"}>บันทึกแผนกำลังพล</ButtonBlue>
        </DialogActions>
      </Dialog>
    </div>
  );
}
