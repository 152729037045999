import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import 'dayjs/locale/th';
import {
    Grid
} from "@mui/material";
import ExcelJS from 'exceljs';
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import SelectCompany from "../shared/selectCompany";

import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";
import { getSalaryAdjustEmployee } from "../../../../actions/report";
import { SelectAllCompaniesVendor } from "./SelectCompanyLists";
import { getAllCompaniesByVendor } from "../../../../actions/vendor";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center",
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const SalaryAdjust = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const [companyLists, setCompanyLists] = useState(null);
    const { open, handleClose } = props;
    const [isFetching, setIsFetching] = useState(false);
    const [SalaryAdjustList, setSalaryAdjustList] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [inputSearch, setInputSearch] = useState(new Date());
    const [selectedCompany, setSelectedCompany] = useState(null);

    const fetchedSalaryAdjustEmployees = async () => {
        try {
            setIsFetching(true);
            const response = await getSalaryAdjustEmployee({
                idCompany: selectedCompany.idCompany,
                inputSearch: dayjs(inputSearch).locale('th').format("YYYY-MM")
            });
            if (response && response.data) {
                setIsFetching(false);
                setSalaryAdjustList(response.data);
            } else {
                setIsFetching(false);
            }
        } catch (error) {
            setIsFetching(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (selectedCompany && inputSearch) {
            fetchedSalaryAdjustEmployees();
        }
    }, [selectedCompany, inputSearch]);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const fetchedCompanyByVendor = async () => {
        try {
            const response = await getAllCompaniesByVendor();
            if (response && response.data) {
                setCompanyLists(response.data);
            } else {
                setCompanyLists(null);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchedCompanyByVendor();
    }, []);

    const handleSubmit = async () => {
        if (SalaryAdjustList !== null && SalaryAdjustList.salaryAdjustments.length > 0) {
            const workbook = new ExcelJS.Workbook();
            const worksheet1 = workbook.addWorksheet("รายงานปรับเงินเดือน");

            const headerRow = worksheet1.addRow([
                "รหัสพนักงาน",
                "ชื่อ",
                "สกุล",
                "ตำแหน่ง",
                "บริษัท",
                "ฝ่าย",
                "ส่วน,",
                "แผนก",
                "เงินเดือนเก่า",
                "เงินเดือนใหม่",
                "เงินส่วนที่ปรับ",
                "เหตุผล",
                "หมายเหตุ",
                "วันที่มีผล"
            ]);

            headerRow.height = 50;

            const headerStyle1 = {
                font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
                alignment: { horizontal: "center", vertical: 'middle' },
                fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: '002060' }
                },
            }

            headerRow.eachCell((cell) => {
                cell.style = headerStyle1;
            });

            const colWidths = [
                { key: "employeeID", width: 30 },
                { key: "firstname", width: 30 },
                { key: "lastname", width: 30 },
                { key: "position", width: 30 },
                { key: "company", width: 40 },
                { key: "section", width: 30 },
                { key: "depatment", width: 30 },
                { key: "division", width: 30 },
                { key: "oldSalary", width: 20 },
                { key: "newSalary", width: 20 },
                { key: "adjustSalary", width: 20 },
                { key: "reason", width: 40 },
                { key: "remark", width: 50 },
                { key: "start", width: 30 },
            ];

            SalaryAdjustList.salaryAdjustments.forEach((item) => {
                const row = [
                    item.companyInfo.employeeId ? item.companyInfo.employeeId : "-",
                    item.companyInfo.firstname ? item.companyInfo.firstname : "-",
                    item.companyInfo.lastname ? item.companyInfo.lastname : "-",
                    item.companyInfo.positionsName ? item.companyInfo.positionsName : "-",
                    item.companyInfo.companyName ? item.companyInfo.companyName : "-",
                    item.companyInfo.divisionName ? item.companyInfo.divisionName : "-",
                    item.companyInfo.departmentName ? item.companyInfo.departmentName : "-",
                    item.companyInfo.sectionName ? item.companyInfo.sectionName : "-",
                    item.beforeLastSalary ? item.beforeLastSalary : "-",
                    item.lastSalary ? item.lastSalary : "-",
                    item.lastSalary - item.beforeLastSalary < 0 ? item.beforeLastSalary - item.lastSalary : item.lastSalary - item.beforeLastSalary,
                    item.reason ? item.reason : "-",
                    item.remark ? item.remark : "-",
                    item.lastSalaryDate ? dayjs(item.lastSalaryDate).locale('th').format("YYYY-MM-DD HH:mm") : "-"
                ];

                const excelRow = worksheet1.addRow(row);
                const contentStyle = {
                    font: { size: 18, name: 'TH SarabunPSK' },
                    alignment: { horizontal: "center", vertical: 'middle' }
                };

                colWidths.forEach((col, index) => {
                    worksheet1.getColumn(index + 1).width = col.width;
                });

                excelRow.eachCell((cell) => {
                    cell.style = contentStyle;
                    cell.border = {
                        top: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                        right: { style: 'thin', color: { argb: '000000' } }
                    };
                });
            })

            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `AntHr-${selectedCompany && selectedCompany.companyName}-รายงานปรับเงินเดือน.xlsx`;
                a.click();
                URL.revokeObjectURL(url);
            });
        } else {
            handleChangeAlertType(t("NoData"));
            handleOpenAlert(true);
        }
    };

    return (

        <DrawerCustom
            title={`${t("SalaryAdjustmentReport")}`}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Grid container spacing={2} style={{ marginBottom: 16 }}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingRight: "16px",
                        }}
                    >
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridTopicInput">
                        {t("SearchDate")}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DatePickerCustom
                            maxDate={dayjs()}
                            inputFormat="MMMM YYYY"
                            value={inputSearch}
                            name="start"
                            views={['year', 'month']}
                            openTo={'month'}
                            onChange={(newValue) => {
                                setInputSearch(newValue);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridTopicInput">
                        {t("SelectCompany")}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectAllCompaniesVendor
                            options={companyLists ? companyLists : null}
                            value={selectedCompany}
                            disabled={isFetching}
                            onChange={(_, value) => {
                                onChangeCompany(value);
                            }}
                        />
                    </Grid>
                </Grid>
                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>
                        {t("Cancel")}
                    </ButtonBlue>
                    <ButtonBlue
                        variant="outlined"
                        startIcon={<DownloadRoundedIcon />}
                        onClick={handleSubmit}
                        disabled={selectedCompany === null || isFetching}
                    >
                        {t("Download")}
                    </ButtonBlue>
                </StyledFooter>
            </StyledRoot>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </DrawerCustom>

    );
};

export default SalaryAdjust