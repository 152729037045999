import {
  POSITIONS_FETCHING,
  POSITIONS_FAILED,
  POSITIONS_SUCCESS,
} from "../actions/types";

const initialState = {
  result: [],
  isFetching: true,
  isError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case POSITIONS_FETCHING:
      return { ...state, result: [], isFetching: true, isError: false };
    case POSITIONS_FAILED:
      return { ...state, result: [], isFetching: false, isError: true };
    case POSITIONS_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    default:
      return state;
  }
}
