import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Add, Edit, MoreVert } from "@mui/icons-material";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerProvidentFund from "./drawerProvidentFund";
import DrawerTablePF from "./drawerTablePF";

import {
  getAllProvidentFundByVendor,
  getAllPFScheduleByVendor,
  deleteProvidentFund,
  deletePFSchedule,
} from "../../../../../actions/vendor";

const Root = styled("div")({
  marginTop: "30px",
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
});

const Header = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Body = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .topicGrid": {
    fontSize: "1.25rem",
  },
  "& .textCenter": {
    textAlign: "center",
  },
  "& .topicChanging": {
    fontSize: "1.5rem",
  },
  "& .hiddenBorder": {
    borderBottom: "0",
  },
  "& .topicRowColumn": {
    fontSize: "1.20rem",
    "& .index": {
      fontWeight: "bold",
      marginLeft: "10px",
    },
  },
  "& .textDate": {
    fontSize: "1.3rem",
  },
  "& .textBold": {
    fontWeight: "bold",
  },
});

const StyledMenu = styled(Menu)({
  "& .edit": {
    color: "#1976D2",
    "& span": {
      color: "#1976D2",
    },
  },
  "& .delete": {
    color: "#ff0000",
    "& span": {
      color: "#ff0000",
    },
  },
});

const MenuOption = (props) => {
  const { handleClickEdit, handleClickDelete, isDisabledDelete } = props;
  const { t, i18n } = useTranslation();

  const [openAnchorEl, setOpenAnchorEl] = useState({
    isOpen: false,
    anchorEl: null,
  });

  return (
    <Fragment>
      <IconButton
        aria-label="option"
        onClick={(e) =>
          setOpenAnchorEl({ isOpen: true, anchorEl: e.currentTarget })
        }
      >
        <MoreVert />
      </IconButton>
      {openAnchorEl.isOpen && (
        <StyledMenu
          aria-labelledby="Menu-Option"
          anchorEl={openAnchorEl.anchorEl}
          open={openAnchorEl.isOpen}
          onClose={() => setOpenAnchorEl({ isOpen: false, anchorEl: null })}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem
            onClick={() => {
              setOpenAnchorEl({ isOpen: false, anchorEl: null });
              handleClickEdit();
            }}
          >
            <ListItemIcon>
              <i className="fa-regular fa-pen icon edit" />
            </ListItemIcon>
            <ListItemText className="edit" style={{ width: 72 }}>
              {t("Edit")}
            </ListItemText>
          </MenuItem>
          {!isDisabledDelete && (
            <MenuItem
              onClick={() => {
                setOpenAnchorEl({ isOpen: false, anchorEl: null });
                handleClickDelete();
              }}
            >
              <ListItemIcon>
                <i className="fa-regular fa-trash-can icon delete" />
              </ListItemIcon>
              <ListItemText className="delete" style={{ width: 72 }}>
                {t("Delete")}
              </ListItemText>
            </MenuItem>
          )}
        </StyledMenu>
      )}
    </Fragment>
  );
};

const PF = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: AllProvidentFunds } = useSelector(
    (state) => state.vendorProvidentFund
  );
  const { result: AllSchedules } = useSelector(
    (state) => state.vendorPfSchedule
  );
  const phoneSceen = useMediaQuery("(max-width: 600px)");
  const [openPFDialog, setOpenPFDialog] = useState({
    status: false,
    data: null,
  });
  const [openTableDialog, setOpenTableDialog] = useState({
    isOpen: false,
    data: null,
  });
  const [statusAPI, setStatusAPI] = useState({
    isOpen: false,
    result: null,
    label: null,
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    isOpen: false,
    id: null,
    isSettingPF: false,
  });

  useEffect(() => {
    dispatch(getAllProvidentFundByVendor());
    dispatch(getAllPFScheduleByVendor());
  }, []);

  const handleClosePFDialog = () => {
    setOpenPFDialog(false);
  };

  const handleCloseTableDialog = () => {
    setOpenTableDialog({ isOpen: false, data: null });
  };

  const handleClickEdit = (value) => {
    setOpenPFDialog({ status: true, data: [value] });
  };

  const handleClickEditSchedule = (value) => {
    setOpenTableDialog({ isOpen: true, data: [value] });
  };

  const handleUpdateListSettingPF = async (result, label) => {
    setStatusAPI({ isOpen: true, result: result, label: label });
    await dispatch(getAllProvidentFundByVendor());
  };

  const handleUpdateListSchedulePF = async (result, label) => {
    setStatusAPI({ isOpen: true, result: result, label: label });
    await dispatch(getAllPFScheduleByVendor());
  };

  const handleClickDelete = (id, isSettingPF) => {
    setOpenDeleteDialog({ isOpen: true, id: id, isSettingPF: isSettingPF });
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog({ isOpen: false, id: null, isSettingPF: false });
  };

  const handleSubmitDelete = async () => {
    let res = null;
    if (openDeleteDialog.isSettingPF) {
      res = await dispatch(deleteProvidentFund(openDeleteDialog.id));
    } else {
      res = await dispatch(deletePFSchedule(openDeleteDialog.id));
    }
    let isSuccess = res.status === 200;
    if (isSuccess) {
      if (openDeleteDialog.isSettingPF) {
        await dispatch(getAllProvidentFundByVendor());
      } else {
        await dispatch(getAllPFScheduleByVendor());
      }
    }
    setStatusAPI({
      isOpen: true,
      result: isSuccess ? "success" : "error",
      label: `ลบกองทุนสำรองเลี้ยงชีพ${isSuccess ? "สำเร็จ" : "ไม่สำเร็จ"}`,
    });
    handleCloseDeleteDialog();
  };

  return (
    <Root>
      <Header>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">{t("ProvidentFund")} (PF)</Typography>
          </Grid>
          <Grid
            item
            style={{
              width: phoneSceen ? "100%" : "fit-content",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: phoneSceen ? 16 : 0,
            }}
          >
            <ButtonBlue
              variant={"contained"}
              startIcon={<Add />}
              onClick={() => {
                setOpenPFDialog({ status: true, data: null });
              }}
            >
              {t("AddPF")}
            </ButtonBlue>
          </Grid>
        </Grid>
      </Header>
      <Body>
        <Grid container spacing={3}>
          {AllProvidentFunds && AllProvidentFunds.length > 0 ? (
            AllProvidentFunds.map((pd, index) => (
              <React.Fragment key={pd.idProvidentFund}>
                <Grid item xs={12} sm={2}>
                  <Typography className="textCenter topicGrid" gutterBottom>
                    {t("MinYearOfWork")}
                  </Typography>
                  <Typography className="textCenter" variant="h4">
                    {pd.minServ_Y || pd.minServ_Y === 0
                      ? `${pd.minServ_Y} ${t("Year")}`
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className="textCenter topicGrid" gutterBottom>
                    {t("MaxEmployeePF")}
                  </Typography>
                  <Typography className="textCenter" variant="h4">
                    {pd.maxEmployeePF || pd.maxEmployeePF === 0
                      ? `${pd.maxEmployeePF} %`
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className="textCenter topicGrid" gutterBottom>
                    {t("MinEmployeePF")}
                  </Typography>
                  <Typography className="textCenter" variant="h4">
                    {pd.minEmployeePF || pd.minEmployeePF === 0
                      ? `${pd.minEmployeePF} %`
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className="textCenter topicGrid" gutterBottom>
                    {t("MaxVendorPF")}
                  </Typography>
                  <Typography className="textCenter" variant="h4">
                    {pd.vendorPF || pd.vendorPF === 0
                      ? `${pd.vendorPF} %`
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={1}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "center",
                      marginBottom: 16,
                    }}
                  >
                    <MenuOption
                      handleClickEdit={() => handleClickEdit(pd)}
                      handleClickDelete={() =>
                        handleClickDelete(pd.idVendorSettingPF, true)
                      }
                    />
                  </div>
                </Grid>
                {phoneSceen && index < AllProvidentFunds.length - 1 && (
                  <Grid item xs={12}>
                    <Divider
                      style={{ borderBottom: "2px solid rgba(0,0,0,0.09)" }}
                    />
                  </Grid>
                )}
              </React.Fragment>
            ))
          ) : (
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item style={{ margin: "30px 0" }}>
                  <Typography>{t("No_Data")}</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Divider
          style={{
            width: "100%",
            borderBottom: "2px solid rgba(0,0,0,0.09)",
            marginTop: "30px",
          }}
        />
        <Box
          style={{
            width: "100%",
            marginTop: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <i
                className="fa-solid fa-calendar-clock"
                style={{
                  fontSize: "1.5rem",
                  marginRight: "10px",
                  color: "#007afe",
                }}
              />
              <Typography className="topicChanging" style={{ width: "100%" }}>
                {t("DateCanChange")}
              </Typography>
            </Box>
            {AllSchedules && AllSchedules.length < 4 && (
              <ButtonBlue
                variant={"contained"}
                startIcon={<Add />}
                onClick={() => {
                  setOpenTableDialog({ isOpen: true, data: null });
                }}
              >
                {t("AddChangeDate")}
              </ButtonBlue>
            )}
          </Box>
          <TableContainer style={{ width: "90%" }}>
            <Table>
              <TableHead>
                {AllSchedules && AllSchedules.length > 0 ? (
                  <TableRow>
                    <TableCell
                      className="hiddenBorder"
                      style={{ minWidth: "32px" }}
                    ></TableCell>
                    <TableCell
                      className="hiddenBorder topicRowColumn"
                      align="center"
                      style={{ minWidth: "152px" }}
                    >
                      {t("StartDate")}
                    </TableCell>
                    <TableCell
                      className="hiddenBorder topicRowColumn"
                      align="center"
                      style={{ minWidth: "152px" }}
                    >
                      {t("EndDate")}
                    </TableCell>
                    <TableCell
                      className="hiddenBorder topicRowColumn"
                      align="center"
                      style={{ minWidth: "152px" }}
                    >
                      {t("DueDate")}
                    </TableCell>
                    <TableCell
                      className="hiddenBorder topicRowColumn"
                      align="center"
                      style={{ minWidth: "40px" }}
                    ></TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell
                      style={{ padding: 0, borderWidth: 0 }}
                    ></TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {AllSchedules && AllSchedules.length > 0 ? (
                  AllSchedules.map((changing, index) => (
                    <TableRow key={changing.idVendorSchedulePF}>
                      <TableCell className="hiddenBorder topicRowColumn">
                        {`${t("RoundOf")} ${index + 1}`}
                      </TableCell>
                      <TableCell
                        className="hiddenBorder textDate textBold"
                        align="center"
                      >
                        {changing.startDate
                          ? dayjs(changing.startDate).format("DD MMM")
                          : "-"}
                      </TableCell>
                      <TableCell
                        className="hiddenBorder textDate textBold"
                        align="center"
                      >
                        {changing.endDate
                          ? dayjs(changing.endDate).format("DD MMM")
                          : "-"}
                      </TableCell>
                      <TableCell
                        className="hiddenBorder textDate textBold"
                        align="center"
                      >
                        {changing.useDate
                          ? dayjs(changing.useDate).format("DD/MM/YYYY")
                          : "-"}
                      </TableCell>
                      <TableCell className="hiddenBorder">
                        {!(
                          dayjs().isBefore(changing.startDate) ||
                          dayjs().isAfter(changing.endDate)
                        ) ? (
                          <Typography
                            style={{
                              color: "#1976D2",
                              fontSize: 20,
                              fontWeight: 500,
                            }}
                          >
                            <i
                              className="fa-regular fa-clock"
                              style={{ color: "#1976D2", marginRight: 16 }}
                            />
                            {t("UnderSchedule")}
                          </Typography>
                        ) : (
                          <MenuOption
                            handleClickEdit={() =>
                              handleClickEditSchedule(changing)
                            }
                            handleClickDelete={() =>
                              handleClickDelete(
                                changing.idVendorSchedulePF,
                                false
                              )
                            }
                            // isDisabledDelete={AllSchedules.length <= 4}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      style={{
                        height: 150,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderWidth: "0",
                      }}
                    >
                      <Typography align="center">
                        {t("NoChangeDate")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Body>
      {openPFDialog.status && (
        <DrawerProvidentFund
          open={openPFDialog.status}
          onClose={handleClosePFDialog}
          data={openPFDialog.data}
          handleUpdateListSettingPF={handleUpdateListSettingPF}
        />
      )}
      {openDeleteDialog.isOpen && (
        <Dialog
          open={openDeleteDialog.isOpen}
          onClose={handleCloseDeleteDialog}
        >
          <DialogContent>
            <div style={{ width: 300 }}>
              <Typography style={{ fontSize: 28, fontWeight: 500 }}>
                {t("ConfirmDelete")}
              </Typography>
              <Divider style={{ marginTop: 16 }} />
              <Grid
                container
                justifyContent={"space-between"}
                style={{
                  marginTop: 16,
                }}
              >
                <Grid item>
                  <ButtonBlue onClick={handleCloseDeleteDialog}>
                    {t("Cancel")}
                  </ButtonBlue>
                </Grid>
                <Grid item>
                  <ButtonBlue variant="contained" onClick={handleSubmitDelete}>
                    {t("Save")}
                  </ButtonBlue>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {openTableDialog.isOpen && (
        <DrawerTablePF
          open={openTableDialog.isOpen}
          onClose={handleCloseTableDialog}
          data={openTableDialog.data}
          handleUpdateListSchedulePF={handleUpdateListSchedulePF}
        />
      )}
      {statusAPI.isOpen && (
        <AlertResponse
          open={statusAPI.isOpen}
          handleClose={() =>
            setStatusAPI({ isOpen: false, result: null, label: null })
          }
          alertType={statusAPI.result}
          label={statusAPI.label}
        />
      )}
    </Root>
  );
};

export default PF;
