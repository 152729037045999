import React, { Fragment, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import DrawerEditCompany from "./drawerEditCompany";

const TabCompany = (props) => {

  const { data } = props
  const [isOpenEditCompany, setIsOpenEditCompany] = useState(false);

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography className="headerText">Company Code</Typography>
          <Typography className="valueText">{data.companyCode || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className="headerText">Social Security A/C</Typography>
          <Typography className="valueText">{data.companySocialSecurityAccount || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className="headerText">Provident Fund Code</Typography>
          <Typography className="valueText">{data.companyProvidentFundCode || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className="headerText">Authorized Signatory Name</Typography>
          <Typography className="valueText">{data.companyAuthorizedSignatoryName || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className="headerText">Authorized Signatory Position</Typography>
          <Typography className="valueText">{data.companyAuthorizedSignatoryPosition || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className="headerText">Email Address</Typography>
          <Typography className="valueText">{data.companyEmail || "-"}</Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
          <Button variant="outlined" onClick={()=>{setIsOpenEditCompany(true)}}>แก้ไข</Button>
        </Grid>
      </Grid>

      {isOpenEditCompany && <DrawerEditCompany open={isOpenEditCompany} handleClose={()=>{setIsOpenEditCompany(false)}} data={data}/>}
    </Fragment>
  )
}

export default TabCompany;