import { httpClient } from "./httpClient";

const getAllExpertise = () => {
    return httpClient.get("expertises");
};

const getAllExpertiseVendor = () => {
    return httpClient.get("vendor/expertises");
};

const updateExpertiseVendor = (data) => {
    return httpClient.put("vendor/expertises/update",data);
};

export default {
    getAllExpertise,
    getAllExpertiseVendor,
    updateExpertiseVendor
};