import { httpClient } from "./httpClient";

const getAllGroupLevelsByIdCompany = (idCompany) => {
    if(idCompany){
        return httpClient.get(`group-levels/company/${idCompany}`);
    }else{
        return httpClient.get(`group-levels/company`);
    }
};

const updateGroupLevel = (data, idCompany) => {
    if(idCompany){
        return httpClient.put(`group-level/company/${idCompany}`, data);
    }else{
        return httpClient.put(`group-level/company`, data);
    }
};

const updateLevel = (data, idCompany) => {
    if(idCompany){
        return httpClient.put(`level/company/${idCompany}`, data);
    }else{
        return httpClient.put(`level/company`, data);
    }
};

const updateEmployeePersonnelLevel = (idEmployeePersonnelLevel, formData) => {
    return httpClient.put(`/employee-personnel-level/${idEmployeePersonnelLevel}`, formData)
}

export default {
    getAllGroupLevelsByIdCompany,
    updateGroupLevel,
    updateLevel,
    updateEmployeePersonnelLevel,
};