import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as React from "react";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";


import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const StyledRoot = styled("div")({
  width: 500,
  padding: 24,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyleSelect = styled(Select)({
  borderRadius: 8,
});

const educationDegree = [
  "ประถมศึกษา",
  "มัธยมศึกษาตอนต้น",
  "มัธยมศึกษาตอนปลาย",
  "ประกาศนียบัตรวิชาชีพ (ปวช.)",
  "ประกาศนียบัตรวิชาชีพชั้นสูง (ปวส.)",
  "ปริญญาตรี",
  "ปริญญาโท",
  "ปริญญาเอก",
];

export default function DialogAddEducation(props) {
  const { open, handleCloseDialog, handleAddEducation } = props;
  const [age, setAge] = React.useState("");
  const [requestTimeline, setRequestTimeline] = React.useState({
    requestType: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
    reason: "",
  });

  const formik = useFormik({
    initialValues: {
      degree: "",
      university: "",
      faculty: "",
      major: "",
      fromYear: new Date(),
      endYear: new Date(),
      gpa: "",
    },
    //validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    console.log(values);
    handleAddEducation(values);
    formik.resetForm();
  };

  return (
    <div>
      <DrawerCustom
        title={"เพิ่มการศึกษา"}
        anchor="right"
        open={open}
        onClose={handleCloseDialog}
      >
        <form onSubmit={formik.handleSubmit}>
          <StyledRoot>
            <Grid container spacing={2} style={{ marginTop: 8 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    ระดับการศึกษา
                  </InputLabel>
                  <StyleSelect
                    label="ระดับการศึกษา"
                    name="degree"
                    onChange={formik.handleChange}
                  >
                    {educationDegree.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </StyleSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextFieldTheme
                  id="outlined-multiline-static"
                  onChange={formik.handleChange}
                  name="university"
                  label="ชื่อสถาบัน"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldTheme
                  id="outlined-multiline-static"
                  onChange={formik.handleChange}
                  name="faculty"
                  label="คณะ"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldTheme
                  id="outlined-multiline-static"
                  onChange={formik.handleChange}
                  name="major"
                  label="สาขาวิชา"
                />
              </Grid>

              <Grid item xs={12} sm={5}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <DatePicker
                    label=""
                    name="fromYear"
                    views={["year"]}
                    inputFormat="yyyy"
                    value={formik.values.fromYear}
                    onChange={(date) => {
                      formik.setFieldValue("fromYear", date);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={5}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <DatePicker
                    label=""
                    name="endYear"
                    views={["year"]}
                    inputFormat="yyyy"
                    value={formik.values.endYear}
                    onChange={(date) => {
                      formik.setFieldValue("endYear", date);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextFieldTheme
                  id="outlined-multiline-static"
                  onChange={formik.handleChange}
                  name="gpa"
                  label="GPA"
                />
              </Grid>
            </Grid>
          </StyledRoot>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleCloseDialog}>
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue type="submit" variant="contained">
              เพิ่มข้อมูล
            </ButtonBlue>
          </StyledFooter>
        </form>
      </DrawerCustom>
    </div>
  );
}
