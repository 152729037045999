import React, { useEffect, useState, Fragment } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";

import { useFormik } from "formik";
import * as yup from "yup";

import { styled } from "@mui/material/styles";

import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../../shared/general/Drawer";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import ButtonLoadingBlue from "../../../../shared/general/ButtonLoadingBlue";
import DatePickerEng from "../../../../shared/general/DatePickerEng";
import DatePickerThai from "../../../../shared/general/DatePickerThai";
import SelectCompany from "../../../../shared/general/selectCompany";

import { createPayruns } from "../../../../../../actions/payruns";
import {
  allPayTypes,
  getPayrollSetting,
} from "../../../../../../actions/paytypes";
import { Typography } from "@mui/material";
import UploadFile from "./upload-file";
import FileSelected from "./file-selected";
import { useTranslation } from "react-i18next";

const StyledContent = styled("div")({
  width: 550,
  padding: 24,
  "& .textField": {
    marginBottom: 16,
    width: "100%",
  },
  "& .radio": {
    width: "100%",
  },
  "& .MuiFormControl-root": {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .wrap-upload": {
    display: "flex",
    justifyContent: "space-between",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  marginTop: 16,
  marginBottom: 16,
  "&.radio": {
    marginTop: 8,
    marginBottom: 0,
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFormLabel = styled(FormLabel)({
  fontWeight: 600,
  fontSize: 14,
  color: "#637381",
  marginBottom: 8,
});

const StyledTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
  color: "#637381",
});

const DialogNew = (props) => {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: paytypesList } = useSelector((state) => state.paytypes);
  const [fileSelected, setFileSelected] = useState({
    ot: null,
    finger: null,
    additionDeduction: null,
  });
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vendorPayrollSetting, setVendorPayrollSetting] = useState(null);
  const [company, setCompany] = useState(null);

  const validationSchema = yup.object({
    payRunTypes: yup
      .string()
      .required(`${t("ThisFieldIsRequired")}`)
      .nullable(),
    payperiod: yup
      .string()
      .required(`${t("ThisFieldIsRequired")}`)
      .nullable(),
    idPaymentType: yup
      .string()
      .required(`${t("ThisFieldIsRequired")}`)
      .nullable(),
    // paymentRound: yup
    //   .string()
    //   .required(`${t("ThisFieldIsRequired")}`)
    //   .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      payRunTypes: "normal",
      payperiod: new Date(),
      idPaymentType: "0",
      payrunMode: "3",
      paymentRound: "",
      paymentRoundNumber: "",
      payrunName: ``,
      idCompany: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    dispatch(allPayTypes());
    dispatch(getPayrollSetting());
  }, []);

  useEffect(() => {
    if (payrollSettingList) {
      setVendorPayrollSetting(
        payrollSettingList.find((x) => x.idCompany === null)
      );
    }
  }, [payrollSettingList]);

  const handleSubmit = async (values) => {
    console.log("formFileSelected: ", formFileSelected);
    setLoading(true);
    if (formFileSelected) {
      const result = await dispatch(createPayruns(formFileSelected));
      if (result && result.idPayrun > 0) {
        history.push(`/vendor/payroll/run/` + result.idPayrun);
      }
    } else {
      const formData = {
        payrunMode: values.payrunMode,
        payrunPeriod:
          values.paymentRound === "month"
            ? dayjs(values.payperiod)
                .set("date", 1)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .format("YYYY-MM-DD HH:mm:ss")
            : dayjs(values.payperiod)
                .set("date", 16)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .format("YYYY-MM-DD HH:mm:ss"),
        idPaymentType: parseInt(values.idPaymentType),
        payRunTypes: values.payRunTypes,
        payrunName: values.payrunName,
        paymentRound: values.paymentRound,
        paymentRoundNumber: values.paymentRoundNumber,
        //idCompany: values.idCompany,
      };
      const result = await dispatch(createPayruns(formData));
      if (result && result.idPayrun > 0) {
        history.push(`/vendor/payroll/run/` + result.idPayrun);
      }
    }
  };

  const handleSetFileSelected = (mode, value) => {
    setFileSelected({ ...fileSelected, [mode]: value });
  };

  const handleSetFormFileSelected = (mode, value) => {
    setFormFileSelected({ ...formFileSelected, [mode]: value });
  };

  const handleClickDownload = (mode) => {
    if (mode === "OT") {
      window.open(
        `${process.env.REACT_APP_API_URL}files/Template payroll working-day_OT.xlsx`
      );
    } else {
      const template = {
        finger: {
          sheet: ["attendance"],
          fileName: "attendance-finger.xlsx",
          header: {
            รหัสพนักงาน: "",
            ชื่อ: "",
            สกุล: "",
            เลขบัตรประจำตัวประชาชน: "",
            ประเภท: "",
            เวลาทำงาน: "",
            รหัสเครื่อง: "",
          },
        },
        additionDeduction: {
          sheet: ["addition", "deduction"],
          fileName: "attendance-deduction.xlsx",
          headerAddition: {
            รหัสพนักงาน: "",
            ชื่อ: "",
            สกุล: "",
            เลขบัตรประจำตัวประชาชน: "",
          },
          headerDeduction: {
            รหัสพนักงาน: "",
            ชื่อ: "",
            สกุล: "",
            เลขบัตรประจำตัวประชาชน: "",
          },
        },
      };

      if (mode === "additionDeduction") {
        paytypesList.addition.forEach((x) => {
          if (x.isRecurring === 0) {
            template["additionDeduction"].headerAddition[
              `${x.name}_${x.code}`
            ] = "";
          }
        });
        paytypesList.deduction.forEach((x) => {
          if (x.isRecurring === 0) {
            template["additionDeduction"].headerDeduction[
              `${x.name}_${x.code}`
            ] = "";
          }
        });
      }

      const elementListAddition = [];
      const elementListDeduction = [];

      elementListAddition.push(template[mode].headerAddition);
      elementListDeduction.push(template[mode].headerDeduction);

      const wsAddition = XLSX.utils.json_to_sheet(elementListAddition);
      const wsDeduction = XLSX.utils.json_to_sheet(elementListDeduction);

      var workbook = XLSX.utils.book_new();

      template[mode].sheet.forEach((element) => {
        XLSX.utils.book_append_sheet(
          workbook,
          element === "addition" ? wsAddition : wsDeduction,
          element
        );
      });

      XLSX.writeFile(workbook, template[mode].fileName, { type: "file" });
    }
  };

  const handleChangeCompany = (newCompany) => {
    console.log("newCompany: ", newCompany);
    formik.setFieldValue("idCompany", newCompany ? newCompany.idCompany : "");
  };

  return (
    <DrawerCustom
      open={open}
      onClose={handleClose}
      title={t("CreatePayroll")}
      anchor={"right"}
    >
      <StyledContent>
        <form onSubmit={formik.handleSubmit}>
          <StyledFormLabel component="legend">{t("ItemName")}</StyledFormLabel>
          <TextFieldTheme
            style={{ marginBottom: 8 }}
            name="payrunName"
            value={formik.values.payrunName}
            onChange={formik.handleChange}
          />
          {/* <div>
            <SelectCompany
              handleChangeCompany={handleChangeCompany}
              variant="outlined"
              placeholder="ทุกบริษัท"
            />
          </div> */}
          <div style={{ marginTop: 8 }}>
            <StyledFormLabel component="legend">เดือน</StyledFormLabel>
            {localStorage.getItem("language") === "en" ? (
              <DatePickerEng
                views={["month", "year"]}
                inputFormat="MMMM YYYY"
                value={formik.values.payperiod}
                onChange={(date) => {
                  formik.setFieldValue("payperiod", date);
                }}
                name="payperiod"
                error={
                  formik.touched.payperiod && Boolean(formik.errors.payperiod)
                }
                helperText={formik.touched.payperiod && formik.errors.payperiod}
                variant="outlined"
              />
            ) : (
              <DatePickerThai
                views={["month", "year"]}
                inputFormat="MMMM yyyy"
                value={formik.values.payperiod}
                onChange={(date) => {
                  formik.setFieldValue("payperiod", date);
                }}
                name="payperiod"
                error={
                  formik.touched.payperiod && Boolean(formik.errors.payperiod)
                }
                helperText={formik.touched.payperiod && formik.errors.payperiod}
                variant="outlined"
              />
            )}
          </div>

          <StyledFormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.idPaymentType &&
              Boolean(formik.errors.idPaymentType)
            }
          >
            <StyledFormLabel component="legend">
              {t("PaymentType")}
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="idPaymentType"
              onChange={formik.handleChange}
              name="idPaymentType"
              value={formik.values.idPaymentType}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={t("Daily")}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={t("Monthly")}
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label={t("DailyAndMonthly")}
              />
            </RadioGroup>
            {Boolean(formik.errors.idPaymentType) && (
              <FormHelperText>{formik.errors.idPaymentType}</FormHelperText>
            )}
          </StyledFormControl>
          <StyledFormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.payRunTypes && Boolean(formik.errors.payRunTypes)
            }
          >
            <StyledFormLabel component="legend">
              {t("PayrunType")}
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="payRunTypes"
              onChange={formik.handleChange}
              name="payRunTypes"
              value={formik.values.payRunTypes}
            >
              <FormControlLabel
                value="normal"
                control={<Radio />}
                label={t("Normal")}
              />
              <FormControlLabel
                value="off"
                control={<Radio />}
                label="Off-Cycle"
              />
            </RadioGroup>
          </StyledFormControl>
          {vendorPayrollSetting &&
          vendorPayrollSetting.paymentPeriod === "2" ? (
            <Fragment>
              <StyledFormControl
                component="fieldset"
                className="radio"
                error={
                  formik.touched.payRunTypes &&
                  Boolean(formik.errors.payRunTypes)
                }
              >
                <StyledFormLabel component="legend">
                  {t("PaymentRound")}
                </StyledFormLabel>
                <RadioGroup
                  row
                  aria-label="paymentRound"
                  //onChange={formik.handleChange}
                  onChange={(event) => {
                    formik.setFieldValue("paymentRoundNumber", "first");
                    formik.setFieldValue("paymentRound", event.target.value);
                  }}
                  name="paymentRound"
                  value={formik.values.paymentRound}
                >
                  <FormControlLabel
                    value="month"
                    control={<Radio />}
                    label={t("MonthPayrun")}
                  />

                  <FormControlLabel
                    value="week"
                    control={<Radio />}
                    label={t("WeekPayrun")}
                  />
                  <FormControlLabel
                    value="month-week"
                    control={<Radio />}
                    label={t("MonthPayrunAndWeekPayrun")}
                  />
                </RadioGroup>
              </StyledFormControl>
              <FormControl component="fieldset" className="radio">
                <RadioGroup
                  row
                  aria-label="paymentRoundNumber"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "paymentRoundNumber",
                      event.target.value
                    );
                  }}
                  name="paymentRoundNumber"
                  value={formik.values.paymentRoundNumber}
                >
                  <FormControlLabel
                    value="first"
                    control={<Radio />}
                    label={t("FirstRound")}
                  />
                  <FormControlLabel
                    value="second"
                    control={<Radio />}
                    label={t("SecondRound")}
                    disabled={
                      formik.values.paymentRound === "month" ||
                      formik.values.paymentRound === "month-week"
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Fragment>
          ) : null}

          {formik.values.paymentRound && (
            <div style={{ marginBottom: 8 }}>
              {formik.values.paymentRound === "week" && (
                <Fragment>
                  {formik.values.paymentRoundNumber === "first" ? (
                    <Fragment>
                      <Typography
                        style={{ fontWeight: 500 }}
                        color="text.third"
                      >
                        {t("SalaryRoundAndOT")}
                      </Typography>
                      <Typography color="text.secondary">
                        {`${t("Salary")}: ${dayjs(formik.values.payperiod)
                          .set("date", 1)
                          .format("D MMM BBBB")} - ${dayjs(
                          formik.values.payperiod
                        )
                          .set("date", 15)
                          .format("D MMM BBBB")}`}
                      </Typography>
                      <Typography color="text.secondary">
                        {`${t("OT")}: ${dayjs(formik.values.payperiod)
                          .set("date", 1)
                          .format("D MMM BBBB")} - ${dayjs(
                          formik.values.payperiod
                        )
                          .set("date", 15)
                          .format("D MMM BBBB")}`}
                      </Typography>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Typography
                        style={{ fontWeight: 500 }}
                        color="text.third"
                      >
                        {t("SalaryRoundAndOT")}
                      </Typography>
                      <Typography color="text.secondary">
                        {`${t("Salary")}: ${dayjs(formik.values.payperiod)
                          .set("date", 16)
                          .format("D MMM BBBB")} -
                  ${dayjs(formik.values.payperiod)
                    .set("date", dayjs(formik.values.payperiod).daysInMonth())
                    .format("D MMM BBBB")}`}
                      </Typography>
                      <Typography color="text.secondary">
                        {`${t("OT")}: ${dayjs(formik.values.payperiod)
                          .set("date", 16)
                          .format("D MMM BBBB")} -
                  ${dayjs(formik.values.payperiod)
                    .set("date", dayjs(formik.values.payperiod).daysInMonth())
                    .format("D MMM BBBB")}`}
                      </Typography>
                    </Fragment>
                  )}
                </Fragment>
              )}

              {formik.values.paymentRound === "month" && (
                <Fragment>
                  <Typography style={{ fontWeight: 500 }} color="text.third">
                    {t("SalaryRoundAndOT")}
                  </Typography>
                  <Typography color="text.secondary">
                    {`${t("Salary")}: ${dayjs(formik.values.payperiod)
                      .set("date", 1)
                      .format("D MMM BBBB")} -
                  ${dayjs(formik.values.payperiod)
                    .set("date", dayjs(formik.values.payperiod).daysInMonth())
                    .format("D MMM BBBB")}`}
                  </Typography>
                  <Typography color="text.secondary">
                    {`${t("OT")}: ${dayjs(formik.values.payperiod)
                      .subtract(1, "month")
                      .set("date", 1)
                      .format("D MMM BBBB")} -
                  ${dayjs(formik.values.payperiod)
                    .subtract(1, "month")
                    .set(
                      "date",
                      dayjs(formik.values.payperiod)
                        .subtract(1, "month")
                        .daysInMonth()
                    )
                    .format("D MMM BBBB")}`}
                  </Typography>
                </Fragment>
              )}

              {formik.values.paymentRound === "month-week" && (
                <Fragment>
                  <Typography style={{ fontWeight: 500 }} color="text.third">
                    {t("SalaryRoundAndOT")} ({t("HalfMonthlyPayment")})
                  </Typography>
                  <Typography color="text.secondary">
                    {`${t("Salary")}: ${dayjs(formik.values.payperiod)
                      .set("date", 1)
                      .format("D MMM BBBB")} -
                  ${dayjs(formik.values.payperiod)
                    .set("date", 15)
                    .format("D MMM BBBB")}`}
                  </Typography>
                  <Typography color="text.secondary" gutterBottom>
                    {`${t("OT")}: ${dayjs(formik.values.payperiod)
                      .set("date", 1)
                      .format("D MMM BBBB")} -
                  ${dayjs(formik.values.payperiod)
                    .set("date", 15)
                    .format("D MMM BBBB")}`}
                  </Typography>
                  <Typography style={{ fontWeight: 500 }} color="text.third">
                    {t("SalaryRoundAndOT")} ({t("MonthlyPayment")})
                  </Typography>
                  <Typography color="text.secondary">
                    {`${t("Salary")}: ${dayjs(formik.values.payperiod)
                      .set("date", 1)
                      .format("D MMM BBBB")} -
                  ${dayjs(formik.values.payperiod)
                    .set("date", dayjs(formik.values.payperiod).daysInMonth())
                    .format("D MMM BBBB")}`}
                  </Typography>
                  <Typography color="text.secondary">
                    {`${t("OT")}: ${dayjs(formik.values.payperiod)
                      .subtract(1, "month")
                      .set("date", 1)
                      .format("D MMM BBBB")} -
                  ${dayjs(formik.values.payperiod)
                    .subtract(1, "month")
                    .set(
                      "date",
                      dayjs(formik.values.payperiod)
                        .subtract(1, "month")
                        .daysInMonth()
                    )
                    .format("D MMM BBBB")}`}
                  </Typography>
                </Fragment>
              )}
            </div>
          )}
          {/*<StyledFormControl
            component="fieldset"
            className="radio"
            error={
              formik.touched.idPaymentType &&
              Boolean(formik.errors.idPaymentType)
            }
          >
            <StyledFormLabel component="legend">
              เวลาทำงาน
            </StyledFormLabel>
            <RadioGroup
              row
              aria-label="idPaymentType"
              onChange={formik.handleChange}
              name="idPaymentType"
              value={formik.values.idPaymentType}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="ในระบบ"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Upload"
              />
            </RadioGroup>
            {Boolean(formik.errors.idPaymentType) && (
              <FormHelperText>{formik.errors.idPaymentType}</FormHelperText>
            )}
          </StyledFormControl> */}

          <StyledFormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="payrunMode"
              value={formik.values.payrunMode}
              onChange={formik.handleChange}
            >
              {/* <MenuItem value={"0"}>All Upload</MenuItem> */}
              {/* <MenuItem value={"1"}>Time attendance (Without OT)</MenuItem> */}
              <MenuItem value={"2"}>
                Time attendance + Addition/Deduction(Recurring)
              </MenuItem>
              <MenuItem value={"3"}>
                Time attendance + Addition/Deduction(Recurring & One Time)
              </MenuItem>
              <MenuItem value={"4"}>Addition/Deduction One Time</MenuItem>
            </Select>
          </StyledFormControl>
          {formik.values.payrunMode === "1" && (
            <div>
              <div className="wrap-upload">
                <Typography>อัพโหลดรายการ OT</Typography>
                <Link variant="body2" onClick={() => handleClickDownload("OT")}>
                  ดาวน์โหลดเทมเพลต
                </Link>
              </div>

              {fileSelected.ot ? (
                <FileSelected
                  fileSelected={fileSelected.ot}
                  mode="ot"
                  handleSetFileSelected={handleSetFileSelected}
                />
              ) : (
                <UploadFile
                  mode="ot"
                  handleSetFileSelected={handleSetFileSelected}
                  setFormFileSelected={setFormFileSelected}
                  formFileSelected={formFileSelected}
                  values={formik.values}
                />
              )}
            </div>
          )}
          {formik.values.payrunMode === "3" && (
            <div>
              <div className="wrap-upload">
                <Typography>{t("Upload")} Addition/Deduction</Typography>
                {paytypesList && (
                  <Link
                    variant="body2"
                    onClick={() => handleClickDownload("additionDeduction")}
                  >
                    {t("DownloadTemplate")}
                  </Link>
                )}
              </div>

              {fileSelected.additionDeduction ? (
                <FileSelected
                  fileSelected={fileSelected.additionDeduction}
                  mode="additionDeduction"
                  handleSetFileSelected={handleSetFileSelected}
                />
              ) : (
                <UploadFile
                  mode="additionDeduction"
                  handleSetFileSelected={handleSetFileSelected}
                  setFormFileSelected={setFormFileSelected}
                  formFileSelected={formFileSelected}
                  values={formik.values}
                />
              )}
            </div>
          )}

          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>
            {/* <LoadingButton loading={loading} type="submit" variant="contained">
              สร้างรายการ
            </LoadingButton> */}
            <ButtonLoadingBlue
              loading={loading}
              type="submit"
              variant="contained"
            >
              {t("Create")}
            </ButtonLoadingBlue>
          </StyledFooter>
        </form>
      </StyledContent>
    </DrawerCustom>
  );
};

export default DialogNew;
