import { styled } from "@mui/material/styles";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography, IconButton } from "@mui/material";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import XlsxIcon from "../../../../pages/assets/xlsx.png";

const StyledShowSelected = styled("div")({
  position: "relative",
  margin: "auto",
  display: "flex",
  height: 120,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& img": {
    marginRight: 8,
  },
});

const StyledWrapDelete = styled("div")({
  position: "absolute",
  right: 0,
  top: 0,
});

const StyledBtnSave = styled("div")({
  maxWidth: 450,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  marginTop: 36,
});

const fileSelected = (props) => {
  const { handleClickUpload, fileSelected, handleSetFileSelected,  loadingUpload  } = props;
  const { t, i18n } = useTranslation();
  
  const handleClickDelete = () => {
    handleSetFileSelected("name", null);
    handleSetFileSelected("size", null);
  };
  
  return (
    <Fragment>
      {fileSelected && fileSelected.name && fileSelected.size && (
        <StyledShowSelected>
          <StyledWrapDelete>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={handleClickDelete}
            >
              <HighlightOffRoundedIcon fontSize="small" />
            </IconButton>
          </StyledWrapDelete>
          <div>
            <img src={XlsxIcon} width="60" />
          </div>
          <div>
            <Typography>{fileSelected.name}</Typography>
            <Typography color="text.third">
              {(fileSelected.size / 1024 / 1024).toFixed(2)} MB
            </Typography>
          </div>
        </StyledShowSelected>
      )}
      <StyledBtnSave>
        <div>
          <LoadingButton
            loading={loadingUpload}
            loadingPosition="start"
            variant="contained"
            onClick={handleClickUpload}
          >
            {t("Upload")}
          </LoadingButton>
        </div>
      </StyledBtnSave>
    </Fragment>
  );
};

export default fileSelected;
