import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import leaveService from "../../../../services/leave.service";
import dayjs from "dayjs";
// import { exportExcelLeaveReport } from "../../../../actions/exportExcel";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAllCompaniesByVendor } from "../../../../actions/vendor";
import { exportExcelLeaveReport } from "../../../../actions/report";


const StyledRoot = styled(Box)({
    padding: "16px",
    "& .MuiAutocomplete-root": {
        "& .MuiOutlinedInput-root": {
            padding: "13.5px 14px",
            paddingRight: "32px",
            "& input": {
                padding: 0,
            },
        },
    },
    "& .field-label": {
        fontSize: "14px",
        fontWeight: "500",
        paddingBottom: "4px",
    },
});

const DrawerExportExcelLeaveReport = (props) => {

    const { drawerConfig, onClose } = props;

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const [companyLists, setCompanyLists] = useState(null);

    const [leaveRoundList, setLeaveRoundList] = useState([]);

    const validateYupSchema = yup.object({
        company: yup.mixed().nullable().required(t("PleaseSelectInformation")),
        leaveRoundDate: yup.string().required(t("PleaseSelectInformation")),
    });

    const useHookForm = useForm({
        defaultValues: {
            company: null,
            leaveRoundDate: "",
        },
        resolver: yupResolver(validateYupSchema),
        mode: "all",
    });

    const fetchLeaveRound = (idCompany) => {
        leaveService.getLeaveRoundList({ idCompany: idCompany }).then(res => {
            setLeaveRoundList(res.data)
            const today = dayjs();
            const foundLeaveRound = res.data.find(item => (
                dayjs(today).isBetween(
                    dayjs(item.start),
                    dayjs(item.end),
                    "[]"
                )
            ))
            useHookForm.resetField("leaveRoundDate", { defaultValue: foundLeaveRound.date })
        }).catch(error => {
            setLeaveRoundList([])
            useHookForm.resetField("leaveRoundDate", { defaultValue: "" })
        })
    }

    const renderValueLeaveRound = (value) => {
        const foundLeaveRoundValue = leaveRoundList.find(item => item.date === value)

        if (foundLeaveRoundValue) {
            return <Typography>
                {i18n.language === "th" ?
                    `${dayjs(foundLeaveRoundValue.start).format("D MMMM BBBB")} - ${dayjs(foundLeaveRoundValue.end).format("D MMMM BBBB")}`
                    :
                    `${dayjs(foundLeaveRoundValue.start).locale(i18n.language).format("D MMMM YYYY")} - ${dayjs(foundLeaveRoundValue.end).locale(i18n.language).format("D MMMM YYYY")}`
                }
            </Typography>
        } else {
            return <Typography>-</Typography>
        }
    }

    const fetchedCompanyByVendor = async () => {
        try {
            const response = await getAllCompaniesByVendor();
            if (response && response.data) {
                setCompanyLists(response.data);
            } else {
                setCompanyLists(null);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (drawerConfig.isOpen) {
            fetchedCompanyByVendor();
        }
    }, [drawerConfig.isOpen]);

    const onSubmit = async (data) => {

        const foundLeaveRound = leaveRoundList.find(item => data.leaveRoundDate === item.date)

        let leaveRoundName = "";

        if (foundLeaveRound) {
            leaveRoundName = `${dayjs(foundLeaveRound.start).format("D MMMM BBBB")} - ${dayjs(foundLeaveRound.end).format("D MMMM BBBB")}`
        } else {
            leaveRoundName = dayjs(data.leaveRoundDate).format("BBBB")
        }

        const result = await exportExcelLeaveReport({
            date: data.leaveRoundDate,
            idCompany: data.company.idCompany,
        })

        if (result && result.status === 200) {
            const excelBlob = new Blob([result.data], {
                type: result.headers["content-type"],
            });
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(excelBlob);
            downloadLink.download = `AntHR ${data.company.companyName} รายงานสิทธิ์การลาของพนักงาน รอบการลา ${leaveRoundName} ข้อมูล ณ ${dayjs().format("DD MMMM BBBB HH.mm.ssน.")}.xlsx`;
            downloadLink.click();
        } else {

        }
    }

    return (
        <DrawerCustom
            title={t("DownloadLeaveReport")}
            anchor="right"
            open={drawerConfig.isOpen}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: "100%",
                    maxWidth: "500px",
                },
            }}
        >
            <StyledRoot>
                <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("Company")}</Typography>
                            <Controller
                                name="company"
                                control={useHookForm.control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        options={companyLists ? companyLists : []}
                                        getOptionLabel={(option) => `${option.companyName}`}
                                        isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                                        renderInput={(params) => (
                                            <TextFieldTheme
                                                {...params}
                                                placeholder={`${t("SelectCompany")}`}
                                                onBlur={field.onBlur}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                error={fieldState.error ? true : false}
                                            />
                                        )}
                                        value={field.value}
                                        onChange={(_, value) => {
                                            field.onChange(value);
                                            fetchLeaveRound(value.idCompany);
                                        }}
                                        noOptionsText={`${t("NoData")}`}
                                        disableClearable
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="text.secondary" fontSize="14px" fontWeight="500" marginBottom="8px">{t("SelectLeaveRound")}</Typography>
                            <Controller
                                name="leaveRoundDate"
                                control={useHookForm.control}
                                render={({ field, fieldState }) => (
                                    <TextFieldTheme
                                        {...field}
                                        select
                                        helperText={fieldState.error ? fieldState.error.message : null}
                                        error={fieldState.error ? true : false}
                                        SelectProps={{
                                            displayEmpty: true,
                                            renderValue: (selected) => {
                                                if (selected.length === 0) {
                                                    return <Typography color="text.secondary">{t("SelectLeaveRound")}</Typography>
                                                } else {
                                                    return renderValueLeaveRound(selected)
                                                }
                                            }
                                        }}
                                    >
                                        <MenuItem value="" disabled>{t("SelectLeaveRound")}</MenuItem>
                                        {leaveRoundList.map(item => (
                                            <MenuItem value={item.date}>
                                                {i18n.language === "th" ?
                                                    `${dayjs(item.start).format("D MMMM BBBB")} - ${dayjs(item.end).format("D MMMM BBBB")}`
                                                    :
                                                    `${dayjs(item.start).locale(i18n.language).format("D MMMM YYYY")} - ${dayjs(item.end).locale(i18n.language).format("D MMMM YYYY")}`
                                                }
                                            </MenuItem>
                                        ))}
                                    </TextFieldTheme>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} container justifyContent="space-between">
                            <ButtonBlue variant="text" onClick={onClose}>{t("Cancel")}</ButtonBlue>
                            <ButtonBlue
                                type="submit"
                                variant="outlined"
                                startIcon={<DownloadRoundedIcon />}
                                disabled={useHookForm.formState.isSubmitting}
                            >
                                {t("Download")}
                            </ButtonBlue>
                        </Grid>
                    </Grid>
                </form>
            </StyledRoot>
        </DrawerCustom>
    )
}

export default DrawerExportExcelLeaveReport;