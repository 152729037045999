import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";

import { Avatar, Typography, Popover } from "@mui/material";

import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";

import CardStyle from "../../shared/general/Card";

import {
  getAttendanceCompanyByIdEmp,
  clearAttendance,
  getAttendanceCompany,
} from "../../../../actions/attendance";
import { getHoliday } from "../../../../actions/holiday";
import { getShiftPattern } from "../../../../actions/shift";

const columns = [
  {
    id: "name",
    label: "ชื่อ-สกุล/ตำแหน่ง",
    minWidth: 240,
    className: "sticky",
  },
];

const StyledCellHeader = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 22,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    zIndex: 20,
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledContent = styled("div")({
  padding: 24,
});

const StyledChipTime = styled(Chip)({
  height: 28,
  width: 100,
  fontSize: 14,
  borderRadius: 8,
  border: "none",
  "&.check-in": {
    color: "#229a16",
    backgroundColor: "#54d62c29",
    marginBottom: 4,
    "&:hover": {
      backgroundColor: "#54d62c57 !important",
    },
    "& .MuiSvgIcon-root": {
      color: "#229a16",
      fontSize: 20,
    },
  },

  "&.check-out": {
    color: "#b72136",
    backgroundColor: "#ff484229",
    "&:hover": {
      backgroundColor: "#ff484257 !important",
    },
    "& .MuiSvgIcon-root": {
      color: "#b72136",
      fontSize: 20,
    },
  },
});

const StyledPopover = styled(Popover)({
  "& .MuiPaper-root": {
    backgroundColor: "#616161eb",
    padding: "4px 8px",
    "& .MuiTypography-root": {
      color: "#FFFFFF",
      padding: "0px !important",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const Timesheet = (props) => {
  const { company, searchDate } = props;
  const today = new Date();
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isBetween);
  const dispatch = useDispatch();
  const { result: shiftPattern } = useSelector((state) => state.shiftPattern);
  const { result: holidayList } = useSelector((state) => state.holiday);
  const attendanceList = useSelector((state) => state.attendanceList);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [columnDate, setColumnDate] = React.useState(null);
  const [searchText, setSearchText] = useState("");
  const [tempSearchDate, setTempSearchDate] = useState({
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
    text: "",
  });
  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const [anchorElLocation, setAnchorElLocation] = React.useState(null);
  const [overData, setOverData] = React.useState(null);
  const [overLocation, setOverLocation] = React.useState(null);

  useEffect(() => {
    dispatch(getHoliday(company.idCompany, tempSearchDate));
    dispatch(getShiftPattern(company.idCompany));
    dispatch(getAttendanceCompany(company.idCompany, searchDate));
  }, []);

  useEffect(() => {
    if (holidayList) {
      setColumnDate(renderDateTable());
    }
  }, [holidayList]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderDateTable = () => {
    const columnDate = [];
    const daysOfMonth = dayjs(searchDate.start).daysInMonth();

    for (let index = 0; index < daysOfMonth + 1; index++) {
      const date = dayjs(searchDate.start).set("date", index + 1);
      const element = {
        date: date,
        holiday: holidayList.find(
          (item) =>
            dayjs(item.dateHoliday).format("YYYY-MM-DD") ===
            dayjs(date).format("YYYY-MM-DD")
        ),
      };

      columnDate.push(element);
    }

    return columnDate;
  };

  const handlePopoverOpen = (holiday) => (event) => {
    if (holiday) {
      setOverData(holiday);
      setAnchorElPopover(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    console.log("handlePopoverClose");
    setAnchorElPopover(null);
    setOverData(null);
  };

  const openPopover = Boolean(anchorElPopover);
  const openLocation = Boolean(anchorElLocation);

  const handleTooltipClose = () => {
    console.log("handleTooltipClose");
    setAnchorElLocation(null);
    setOverLocation(null);
  };

  const handleTooltipOpen = (location) => (event) => {
    if (location) {
      setOverLocation(location);
      setAnchorElLocation(event.currentTarget);
    }
  };

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const rowsFilter = () => {
    let data = [...attendanceList.result];

    if (searchText.length > 0) {
      data = data.filter((item, index) => {
        if (
          item.firstname.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 ||
          item.lastname.toUpperCase().indexOf(searchText.toUpperCase()) >= 0
        )
          return item;
      });
    }

    return data;
  };

  return (
    <Fragment>
      <StyledWrapFilter>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={4}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                ชื่อ-สกุล
              </Typography>
              <div className="search-name">
                <FormControl fullWidth variant="filled">
                  <StyledFilledInput
                    id="filled-sarch-name"
                    placeholder="ค้นหาชื่อ-สกุล"
                    name="search"
                    onChange={handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
            </StyledBoxSearch>
          </Grid>
        </Grid>
      </StyledWrapFilter>
      <Box style={{ paddingBottom: "30px" }}>
        <CardStyle>
          <StyledContent>
            {shiftPattern &&
            holidayList &&
            attendanceList &&
            attendanceList.result ? (
              <Box style={{ display: "flex", position: "relative" }}>
                <StyledBox className={"close"}>
                  <TableContainer style={{ width: "100%", height: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns
                            .filter((item) => {
                              return item.className === "sticky";
                            })
                            .map((column) => (
                              <StyledCellHeader
                                key={column.id}
                                align={"center"}
                                className="sticky"
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </StyledCellHeader>
                            ))}
                          {/* </StyledCellHeader> */}

                          {columnDate &&
                            columnDate.map((column, index) => (
                              <StyledCellHeader
                                key={index}
                                style={{ minWidth: 150 }}
                              >
                                <Typography
                                  variant="body2"
                                  align="center"
                                  className={
                                    dayjs(column.date).day() === 0 ||
                                    dayjs(column.date).day() === 6
                                      ? "weekend"
                                      : "workday"
                                  }
                                >
                                  {dayjs(column.date).format("DD/MM/YYYY")}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  align="center"
                                  className={
                                    dayjs(column.date).day() === 0 ||
                                    dayjs(column.date).day() === 6
                                      ? "weekend"
                                      : "workday"
                                  }
                                  aria-owns={
                                    openPopover
                                      ? "mouse-over-popover"
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  onMouseEnter={handlePopoverOpen(
                                    column.holiday
                                  )}
                                  onMouseLeave={handlePopoverClose}
                                >
                                  {dayjs(column.date).format("dddd")}{" "}
                                  {column.holiday && (
                                    <i className="fa-regular fa-circle-info"></i>
                                  )}
                                </Typography>
                              </StyledCellHeader>
                            ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {rowsFilter()
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            return (
                              <StyledRowContent key={row.idEmp}>
                                <StyledCellContent className="sticky">
                                  <div className="avatar">
                                    <Avatar
                                      src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
                                    />
                                    <div>
                                      <Typography className="fullname">
                                        {row.firstname} {row.lastname}
                                      </Typography>
                                      <Typography className="position">
                                        {row.positionsName}
                                      </Typography>
                                    </div>
                                  </div>
                                </StyledCellContent>
                                {row.attendance.map((column, index) => (
                                  <Fragment key={index}>
                                    <StyledCellContent
                                      align="center"
                                      key={`${row.idEmp} ${index}`}
                                    >
                                      {column.checkIn &&
                                        column.checkIn.length > 0 && (
                                          <StyledChipTime
                                            aria-describedby={
                                              openLocation
                                                ? "mouse-over-popover-location"
                                                : undefined
                                            }
                                            onClick={handleTooltipOpen(
                                              column.checkIn[0].gpsLocationsName
                                            )}
                                            icon={
                                              column.checkIn[0]
                                                .idAttendanceType === 2 ? (
                                                <FingerprintIcon />
                                              ) : (
                                                <LocationOnIcon />
                                              )
                                            }
                                            className={"check-in"}
                                            label={
                                              column.checkIn[0]
                                                .attendanceTextTime
                                            }
                                            variant="outlined"
                                            aria-owns={
                                              openLocation
                                                ? "mouse-over-popover-location"
                                                : undefined
                                            }
                                            aria-haspopup="true"
                                          />
                                        )}

                                      {column.checkOut &&
                                        column.checkOut.length > 0 && (
                                          <div className="secondaryAction">
                                            <StyledChipTime
                                              aria-describedby={
                                                openLocation
                                                  ? "mouse-over-popover-location"
                                                  : undefined
                                              }
                                              onClick={handleTooltipOpen(
                                                column.checkOut[0]
                                                  .gpsLocationsName
                                              )}
                                              icon={
                                                column.checkOut[0]
                                                  .idAttendanceType === 2 ? (
                                                  <FingerprintIcon />
                                                ) : (
                                                  <LocationOnIcon />
                                                )
                                              }
                                              className={"check-out"}
                                              label={
                                                column.checkOut[0]
                                                  .attendanceTextTime
                                              }
                                              variant="outlined"
                                            />
                                          </div>
                                        )}
                                    </StyledCellContent>
                                  </Fragment>
                                ))}
                              </StyledRowContent>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={attendanceList ? attendanceList.result.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </StyledBox>
              </Box>
            ) : (
              <Fragment>
                {attendanceList && attendanceList.isFetching ? (
                  <Typography variant="h6" style={{ textAlign: "center" }}>
                    กำลังโหลดข้อมูลพนักงาน...
                  </Typography>
                ) : (
                  <Typography variant="h6" style={{ textAlign: "center" }}>
                    ไม่พบพนักงาน
                  </Typography>
                )}
              </Fragment>
            )}
          </StyledContent>
        </CardStyle>
        {overData && (
          <Popover
            id="mouse-over-popover"
            style={{
              pointerEvents: "none",
            }}
            open={openPopover}
            anchorEl={anchorElPopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography style={{ padding: 16 }}>{overData.name}</Typography>
          </Popover>
        )}
        {overLocation && (
          <StyledPopover
            id="mouse-over-popover-location"
            open={openLocation}
            anchorEl={anchorElLocation}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleTooltipClose}
          >
            <Typography style={{ padding: 16 }}>{overLocation}</Typography>
          </StyledPopover>
        )}
      </Box>
    </Fragment>
  );
};

export default Timesheet;
