import * as React from "react";
import { useSelector } from "react-redux";
import { 
  Checkbox, 
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function StepOne(props) {
  const { onChangeDate, onChange, dataNewShift, formik, onChangeCheckBox } = props;
  const { result: allWorkTypes } = useSelector(state => state.workTypes);

  return (
    <React.Fragment>
      <div>
        <TextField
          style={{ width: "100%" }}
          id="outlined-basic"
          label="ชื่อประเภทกะ"
          variant="outlined"
          name="nameShift"
          onChange={onChange}
        />
      </div>
      <Grid container spacing={2} style={{ marginTop: 5 }}>
        <Grid item xs={12} sm={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="วันที่เริ่มต้น"
              value={dataNewShift.start}
              name="start"
              onChange={onChangeDate}
              format="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField style={{ width: "100%" }} {...params} />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4}>
          {allWorkTypes && 
            <FormControl fullWidth>
              <InputLabel>ประเภทกะ</InputLabel>
              <Select
                name="workType"
                value={dataNewShift.workType}
                label="ประเภทกะ"
                onChange={onChange}
              >
                {allWorkTypes.map((type) => (
                  <MenuItem 
                    key={`${type.idWorkingType}_${type.workingTypeName}`}
                    value={type.idWorkingType}
                  >
                    {type.workingTypeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </Grid>
        <Grid item xs={12} sm={4}>
          <div style={{ height:"100%", display:"flex", alignItems:"center" }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="startInMondy"
                  onChange={onChangeCheckBox}
                  checked={dataNewShift.startInMondy}
                  style={{ color: "#007afe" }}
                />
              }
              label="เริ่มทำงานวันจันทร์"
            />
          </div>
        </Grid>
      </Grid>
      <div style={{ marginTop: 16 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              className={`amount`}
              id="outlined-number"
              label="วันทำงาน"
              type="number"
              name="workday"
              value={dataNewShift.workday}
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              className={`amount`}
              id="outlined-number"
              label="วันหยุด"
              type="number"
              name="offday"
              value={dataNewShift.offday}
              onChange={onChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              className={`amount`}
              id="outlined-number"
              label="จำนวนชุดกะ"
              type="number"
              name="amountShift"
              value={dataNewShift.amountShift}
              onChange={onChange}
              inputProps={{
                min:1
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: 16 }}>
          <TextField
            style={{ width: "100%" }}
            id="outlined-basic"
            label="เหตุผล"
            variant="outlined"
            name="reason"
            onChange={onChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
