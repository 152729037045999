import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';

import ButtonBlue from '../../../shared/general/ButtonBlue';
import DrawerCustom from '../../../shared/general/Drawer';

import { getUserProfile, updatePFPersonal } from '../../../../../actions/user';

const StyledRoot = styled("div")({
    width: 300,
    padding: 16,
});

const IconButtonStyled = styled(IconButton)(({ disabled }) => ({
    width:"40px", 
    height:"40px",
    "& .icon":{
        color: disabled === true ? "rgba(0, 0, 0, 0.26)" : "#1976d2"
    }
}));

const DialogEditProvidentFund = (props) => {
    const { open, onClose } = props;
    const dispatch = useDispatch();
    const { result: UserProfile } = useSelector(state => state.userProfile);
    const { result: UserProvidentFund } = useSelector(state => state.userProvidentFund);
    const { handleSubmit, setValue, getValues, watch } = useForm({
        defaultValues:{
            userPF: 0
        }
    });

    React.useEffect(() => {
        if(UserProfile){
            if(UserProfile.userPF){
                setValue("userPF",UserProfile.userPF);
            } else {
                if(UserProvidentFund){
                    setValue("userPF",UserProvidentFund.employeePercent);
                }
            }
        }
    },[UserProfile]);

    const handleOnSubmit = async (value) => {
        const result = await dispatch(updatePFPersonal(UserProfile.idEmp, value));
        if (result) {
            dispatch(getUserProfile(UserProfile.idEmp));
            onClose();
        }
    };

    const handleAddPercent = () => {
        setValue("userPF",parseInt(getValues("userPF"))+1);
    };

    const handleMinusPercent = () => {
        setValue("userPF",parseInt(getValues("userPF"))-1);
    };

    return(
        <DrawerCustom
            open={open}
            title="แก้ไขกองทุนสำรองเลี้ยงชีพส่วนบุคคล"
            anchor="right"
        >
            <StyledRoot>
                <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            ปรับเปลี่ยนกองทุนสำรองเลี้ยงชีพส่วนบุคคล
                        </Grid>
                        <Grid item xs={12} style={{ margin:"30px 0" }}>
                            <Grid container justifyContent="center">
                                <Grid item style={{ width:"280px", display:"flex", justifyContent:"space-between", alignItems:"center" }}>
                                    <IconButtonStyled
                                        disabled={
                                            UserProvidentFund && UserProvidentFund.employeePercent ? 
                                            parseInt(watch("userPF")) === 1 ? true : false 
                                            : true
                                        }
                                        onClick={handleMinusPercent}
                                    ><Remove className="icon" /></IconButtonStyled>
                                    <Box 
                                        style={{ 
                                            width:"calc(100% - 120px)", 
                                            height:"50px",
                                            display:"flex", 
                                            alignItems:"center", 
                                            justifyContent:"center",
                                            border:"1px solid #bdbdbd",
                                            borderRadius:"8px"
                                        }}
                                    >
                                        <Typography variant="h6">{watch("userPF")}</Typography>
                                    </Box>
                                    <IconButtonStyled
                                        disabled={
                                            UserProvidentFund && UserProvidentFund.employeePercent ? 
                                            parseInt(watch("userPF")) === UserProvidentFund.employeePercent ? true : false 
                                            : true
                                        }
                                        onClick={handleAddPercent}
                                    ><Add className="icon" /></IconButtonStyled>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <ButtonBlue
                                        variant="outlined"
                                        onClick={onClose}
                                    >
                                        ยกเลิก
                                    </ButtonBlue>
                                </Grid>
                                <Grid item>
                                    <ButtonBlue
                                        type="submit"
                                        variant="contained"
                                    >
                                        ยืนยัน
                                    </ButtonBlue>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </StyledRoot>
        </DrawerCustom>
    );
};

export default DialogEditProvidentFund;