import React, { forwardRef } from "react";
import ReactApexChart from "react-apexcharts";

const bgColorChart = {
  id: "bgColorChart",
  beforeDraw: (chart) => {
    const ctx = chart.canvas.getContext("2d");
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const gaugeNeedle = {
  id: "gaugeNeedle",
  beforeDraw: (chart, args, options) => {
    const { ctx, data } = chart;

    ctx.save();
    const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0);
    const needleValue = data.datasets[0].leftData

    const angle = Math.PI + (1 / dataTotal) * (needleValue) * Math.PI;
    const cx = chart.getDatasetMeta(0).data[0].x
    const cy = chart.getDatasetMeta(0).data[0].y

    ctx.translate(cx, cy);
    ctx.rotate(angle);
    
    //gauge
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.moveTo(0, -5);
    ctx.lineTo(chart._metasets[chart._metasets.length-1].data[0].innerRadius, 0);
    ctx.lineTo(0, 5);  
    ctx.fillStyle = options.needleColor;
    ctx.fill();

    //dot
    ctx.translate(-cx, -cy);
    ctx.beginPath();
    ctx.arc(cx, cy, 10, 0, 10);
    ctx.fillStyle = options.needleDotColor;
    ctx.fill();

    ctx.restore();
  },
};

const doughnutLabel = {
  id: "doughnutLabel",
  afterDatasetsDraw: (chart, args, plugins) => {
    const { ctx, width, height, data } = chart;

    const centerX = chart.getDatasetMeta(0).data[0].x
    const centerY = chart.getDatasetMeta(0).data[0].y * 0.9

    const leftData = data.datasets[0].leftData
    const rightData = data.datasets[0].rightData

    const ratio = Math.ceil(centerX / width)

    //text
    ctx.save();
    ctx.font = `bold ${16 + (ratio * 16)}px sans-serif`;
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.textBaseLine = 'middle';
    //left
    ctx.fillText(`${leftData}%`, centerX * 0.75, centerY); 
    //right
    ctx.fillText(`${rightData}%`, centerX * 1.25, centerY);

    //round
    chart.data.datasets.forEach((dataset, i) => {
      chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
        const { x, y } = datapoint.tooltipPosition();
        const halfwidth = width / 2;
        const halfheight = height / 2;
        
        const xLine = x >= halfwidth ? x + 40 : x - 40
        const yLine = y >= halfheight ? y + 24 : y - 24

        ctx.font = `bold ${10 + (ratio * 10)}px sans-serif`;
        ctx.textAlign = 'center'
        ctx.fillText(`${chart.data.labels[index]} ${dataset.data[index]}%`, xLine, yLine);
      })
    });
  }
}

const Gauge = forwardRef((props, ref) => {
  const { totalUse, budget } = props

  // const data = Object.values(grades).map(item => Math.round(item * 100))
  // const leftData = data[0] + data[1]  //Grad A + B
  // const rightData = data[2] + data[3] //Grad C + D
  const data = [Math.ceil(totalUse / budget * 100)]
  const options = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '33px',
            fontWeight: 400,
          },
        }
      }
    },
    states: {
      active: {
        filter: {
          type: 'none',
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      },
    },
    labels: ['Average Results'],
  };

  return (
    <ReactApexChart options={options} series={data} type="radialBar" height={350} />
  );
});

export default Gauge;
