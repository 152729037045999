import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";

import AddIcon from "../../../../pages/assets/add.png";

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 120,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 8,
      },
      "& .MuiTypography-root": {
        marginTop: 8,
        backgroundColor: "transparent",
      },
    },
  },
});

const uploadFile = (props) => {
  const { setFormFileSelected, setFileSelected,  fileSelected, companySelected } = props;

  const { t, i18n } = useTranslation();


  const { getRootProps, getInputProps } = useDropzone({ 
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {

      if (acceptedFiles.length > 0) {
        const formData = new FormData();
        acceptedFiles.map((file) => {
          formData.append("file", file);
          formData.append("selected", companySelected);
        });
        setFormFileSelected(formData);

        setFileSelected({
          name: acceptedFiles[0].name,
          size: acceptedFiles[0].size,
        });
      
      } else {
        console.error("No files were selected.");
      }
    },
    maxFiles: 1,
  });

  return (
    <div>
        <div
          {...getRootProps({
            className: "dropzone-upload-file",
          })}
        >
          <input {...getInputProps()} />
          <div className="inner-dropzone">
            <img alt="AddIcon" src={AddIcon} width="80" />
            <Typography
              style={{
                marginTop: 8,
                backgroundColor: "transparent",
              }}
              className={fileSelected != 0 ? 'placeholderLabel' : ''}
              variant="body2"
              color="text.secondary"
            >
                {`Drag & Drop / ${t("Click_For_Select_File")}`}
            </Typography>
          </div>
        </div>
    </div>
  );
};

export default uploadFile;
