import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Container, FilledInput } from "@mui/material";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import StyledCard from "../../shared/general/Card";
import DatePickerWeek from "../../shared/general/DatePickerWeek";

import TableOTReport from "./tableOTReport";
import ButtonBlue from "../../shared/general/ButtonBlue";
import SelectVendors from "../shared/selectVendor";

import { getOvertime36Hours, getOvertime36HoursDetail } from "../../../../actions/requestTime";

const rand40 = () => {
  return Math.floor(Math.random() * 40);
};

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
  "& .empty-data": {
    display: "flex",
    justifyContent: "center",
    padding: 32,
    "& .text": {
      fontStyle: "oblique",
      fontWeight: 400,
    },
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px dashed #DDDDDD`,
  "&:not(:last-child)": {},
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  paddingLeft: 0,
  "& .inner": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .hours": {
      display: "flex",
      alignItems: "baseline",
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledAvatar = styled(Avatar)({
  width: 40,
  height: 40,
});

const StyledCellName = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiAvatar-root": {
    marginRight: 8,
  },
  "& .MuiTypography-h6": {
    lineHeight: 1,
  },
  "& .MuiTypography-body2": {
    color: "#999999",
    fontStyle: "oblique",
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

export default function OTMonitoring() {
  const dispatch = useDispatch();
  const { result: overtime36HoursList } = useSelector(
    (state) => state.overtime36Hours
  );
  const [searchDate, setSearchDate] = useState(new Date());
  const [value, setValue] = useState(new Date());
  const [searchText, setSearchText] = useState("");
  const [vendor, setVendor] = useState(null);
  const [expanded, setExpanded] = useState(null)

  const person = [
    rand40(),
    rand40(),
    rand40(),
    rand40(),
    rand40(),
    rand40(),
    rand40(),
    rand40(),
  ];

  const handleChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleChangeVendor = (vendor) => {
    if (vendor) {
      setVendor(vendor.idVendor);
    } else {
      setVendor(null);
    }
  };

  const handleClickSearch = () => {
    setExpanded(null);
    dispatch(getOvertime36Hours(vendor, searchDate));
  };

  const handleChangeExpanded = (panel, idEmp) => (event, newExpanded) => {
    if (newExpanded) {
      dispatch(getOvertime36HoursDetail(vendor, idEmp, searchDate));
    }
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingBottom: 16 }}>
          ตรวจสอบค่าล่วงเวลา
        </Typography>
        <div>
          <StyledCard>
            <div style={{ padding: 24 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography className="label" color="text.third">
                    ช่วงวันที่
                  </Typography>
                  <DatePickerWeek
                    value={value}
                    setValue={setValue}
                    setSearchDate={setSearchDate}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className="label" color="text.third">
                    บริษัทต้นสังกัด
                  </Typography>
                  <SelectVendors
                    label={false}
                    handleChange={handleChangeVendor}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <div style={{ marginTop: 22 }}>
                    <ButtonBlue
                      disabled={vendor === null}
                      startIcon={<SearchRoundedIcon />}
                      variant={"contained"}
                      onClick={handleClickSearch}
                    >
                      ค้นหา
                    </ButtonBlue>
                  </div>
                </Grid>
              </Grid>

              <div style={{ marginTop: 36 }}>
                {overtime36HoursList ? (
                  overtime36HoursList.map((value, index) => {
                    return (
                      <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChangeExpanded(`panel${index}`, value.idEmp)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div className="inner">
                            <StyledCellName>
                              <StyledAvatar
                                alt={"username"}
                                src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                              />
                              <div>
                                <Typography variant="h6">
                                  {`${value.firstname} ${value.lastname}`}
                                </Typography>
                                <Typography variant="body2">
                                  {value.positionsName}
                                </Typography>
                              </div>
                            </StyledCellName>
                            <div className="hours">
                              <Typography
                                variant="h4"
                                style={{
                                  marginRight: 4,
                                  color: value.isOver36Hours ? "#e46a76" : "",
                                  fontWeight: value.isOver36Hours ? 600 : 400,
                                }}
                              >
                                {value.amountHours}
                              </Typography>
                              <Typography color="text.third">{` ชม.`}</Typography>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableOTReport />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <div className="empty-data">
                    <Typography className="text" color="text.secondary">
                      โปรดเลือกบริษัทต้นสังกัดและกดค้นหา
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </StyledCard>
        </div>
      </Container>
    </StyledRoot>
  );
}
