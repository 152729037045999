import React, { useState } from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import {
  FormControl,
  TextField,
  Typography,
  InputLabel,
  Button,
  Select,
  MenuItem,
  Grid,
  Box,
  Popper,
  IconButton,
} from '@mui/material';

import { grey, green } from '@mui/material/colors';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import ConfirmDialog from "./confirmDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from 'sweetalert2';

import {
  editBillingStatement,
  deleteBillingStatement,
  getExcelBillingStatementById,
} from "../../../../actions/dumpExcel"
import AlertResponse from "../../shared/general/AlertResponse";

const StyledTableContainer = styled(TableContainer)({
  width: "100%",
  minWidth: 720,
});

const StyledTable = styled(Table)({
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    // boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    // boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
  },
  "& .tableCell": {
    borderColor: "#919eab3d",
    padding: "8px 16px",
  },
  "& .cellStatusAction": {
    width: 30,
  },
  "& .cellFullName": {
    width: 150,
  },
  "& .cellTypeLeave": {
    width: 100,
  },
  "& .cellStartStop": {
    width: 150,
  },
});

const ApproveButton = styled(Button)(({ isApprove }) => ({
  backgroundColor: isApprove
    ? '#63C004' // อนุมัติแล้ว
    : '#FFFFFF', // ยังไม่อนุมัติ
  color: isApprove
    ? '#FFFFFF'
    : 'black',
  background: isApprove
    ? '#63C004'
    : 'white',
  borderRadius: '50px',
  padding: '5px 10px',
  fontSize: '14px',
  fontWeight: 'bold',
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
  textTransform: 'none',
  minWidth: '100px',
  '&:hover': {
    background: isApprove
      ? 'linear-gradient(45deg, #FFCDD2, #E57373)' // ไม่เปลี่ยนสีเมื่อ hover ถ้าอนุมัติแล้ว
      : '#B0C4DE',
    color: isApprove
      ? 'black'
      : 'black',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: isApprove
      ? 'none' // ไม่ให้แสง glowing ถ้าอนุมัติแล้ว
      : '0px 0px 5px rgba(67, 160, 71, 0.7)',
  },
}));

const StyledBoxSearch = styled(Box)({
  marginTop: 0,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const IconComponent = ({ row }) => (
  <CheckCircleIcon
    style={{
      fontSize: '20px',
      color: row.isApprove ? green[500] : grey[500], // สีของไอคอน
      backgroundColor: row.isApprove ? 'white' : 'transparent', // สีของวงกลมถ้า approve แล้ว
      borderRadius: '50%', // ทำให้เป็นวงกลม
    }}
  />
);

export default function TableList(props) {
  const { reload, billingsStatement, idCompany, handleOpenAlert } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pendingApproval, setPendingApproval] = useState(null);
  const [dialogType, setDialogType] = useState(null);
  const [filteredName, setFilteredName] = useState('');
  const [month, setMonth] = useState(dayjs().get("month") + 1);
  const [year, setYear] = useState(dayjs().get("year"));

  const handleClickOpen = (type, row) => {
    setPendingApproval(row);
    setDialogType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPendingApproval(null);
  };

  const handleConfirm = async () => {
    let data = {
      idBillingStatement: pendingApproval.idBillingStatement,
      idCompany: pendingApproval.idCompany || '',
      isApprove: pendingApproval.isApprove || '',
    };
    setLoading(true);
    try {
      let result;
      switch (dialogType) {
        case 'approve':
          result = await dispatch(editBillingStatement(data))
          break;
        case 'delete':
          result = await dispatch(deleteBillingStatement(data));
          break;
        default:
          handleOpenAlert("error");
          break;
      }
      if (result) {
        handleOpenAlert("success", dialogType === 'delete' ? `${t("DeletedSuccessfully")}` : null);
        await dispatch(getExcelBillingStatementById(idCompany));
        handleClose();
      } else {
        handleOpenAlert("error");
        handleClose();
      };
    } catch (error) {
      handleOpenAlert("error");
      handleClose();
    } finally {
      setLoading(false);
      reload();
    };
  };

  const filteredBillings = (billingsStatement || []).filter((row) => {
    const nameFilter = typeof filteredName === 'string' ? filteredName : '';

    const isNameMatch = row.billingStatementName
      .toLowerCase()
      .includes(nameFilter.toLowerCase());
    const isMonthMatch = dayjs(row.billsPeriod)
      .format("MMMM") === dayjs().month(month - 1).format("MMMM");

    return isNameMatch && isMonthMatch;
  });

  return (
    <StyledTableContainer
      className="tableContainer"
      style={{ width: "100%", minHeight: 160 }}
    >
      <Grid container spacing={4} mb={3} mt={0}>
        <Grid item xs={12} sm={6} md={3} mb={2}>
          <StyledBoxSearch>
            <Typography className="label" color="text.third">
              {t("DocumentName")}
            </Typography>
            <div className="search-name">
              {filteredBillings && (
                <StyledAutocomplete
                  options={filteredBillings}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFilteredName(newValue.billingStatementName || '');
                    } else {
                      setFilteredName('');
                    }
                  }}
                  popupIcon={<i className="fa-light fa-chevron-down"></i>}
                  getOptionLabel={(option) =>
                    `${option.billingStatementName}`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      placeholder={`${t("SearchDocName")}`}
                    />
                  )}
                  PopperComponent={StyledPopper}
                  noOptionsText={`${t("NoData")}`}
                />
              )}
            </div>
          </StyledBoxSearch>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ marginTop: 0, padding: '12px 40px' }}>
          <FormControl
            variant="filled"
            style={{ marginTop: '49px' }}
          >
            <InputLabel>{t("Month")}</InputLabel>
            <Select
              label={`${t("Month")}`}
              displayEmpty
              disableUnderline
              value={month}
              onChange={(e) => {
                setMonth(e.target.value);
              }}
            >
              {[...Array(12)].map((_, index) => {
                if (
                  dayjs()
                    .set("month", index)
                    .set("year", year)
                    .isSameOrAfter(
                      dayjs().set("month", 1).set("year", 2023)
                    ) &&
                  !dayjs()
                    .set("month", index)
                    .set("year", year)
                    .isAfter(dayjs())
                ) {
                  return (
                    <MenuItem key={index} value={index + 1}>
                      {dayjs().month(index).format("MMMM")}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <StyledTable aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell className={`tableCellHead`} align="center">
              {t("ItemName")}
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              {t("Month_Period")}
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              {t("Approved")}
            </TableCell>
            <TableCell className={`tableCellHead`} align="center">
              {t("Manage")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBillings && filteredBillings.length > 0 ? (
            filteredBillings.map((row, index) => (
              <>
                <TableRow key={index}>
                  <TableCell align="center" className={`tableCell`}>
                    <Link
                      component={NavLink}
                      to={`/vendor/bills-collected/billingDetails/${idCompany}/${row.idBillingStatement}`}
                      underline="hover"
                    >
                      {row.billingStatementName}
                    </Link>
                  </TableCell>
                  <TableCell align="center" className={`tableCell`}>
                    <Typography>
                      {dayjs(row.billsPeriod)
                        .locale(localStorage.getItem("language") || "th")
                        .format(
                          localStorage.getItem("language") === "en"
                            ? "MMMM YYYY"
                            : "MMMM BBBB"
                        )}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className={`tableCell`}
                    style={{ padding: '10px', verticalAlign: 'middle' }}
                  >
                    <ApproveButton
                      startIcon={<IconComponent row={row} />}
                      isApprove={row.isApprove}
                      onClick={() => {
                        const newIsApprove = row.isApprove === 1 ? 0 : 1;
                        handleClickOpen('approve', { ...row, isApprove: newIsApprove });
                      }}
                    >
                      {row.isApprove ? t("Approve") : t("Approved")}
                    </ApproveButton>
                  </TableCell>
                  <TableCell align="center" className={`tableCell`}>
                    <IconButton
                      style={{ color: "#ff1744" }}
                      onClick={() => handleClickOpen('delete', row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <Typography color="text.secondary" style={{ margin: "8px 0" }}>
              {t("NoData")}
            </Typography>
          )}
        </TableBody>
        <ConfirmDialog
          open={open}
          type={dialogType}
          onClose={handleClose}
          pendingApproval={pendingApproval}
          handleConfirm={handleConfirm}
        />
      </StyledTable>
    </StyledTableContainer>
  );
}
