import React, { useState, useEffect } from "react";
import { useTitle } from "react-use";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
  Container,
} from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "120px",
    marginBottom: "120px",
  },
  pageTitle: {
    color: "#16243D",
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  paper: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(2),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  description: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  button_container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  button: {
    borderColor: "#007afe",
    color: "#ffffff",
    backgroundColor: "#007afe",
    borderRadius: 8,
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
    },
  },
}));

function TaxDeduction() {
  const classes = useStyles();
  useTitle("รายการเพื่อการหักลดหย่อนภาษี (ล.ย. 01) - HR CORE DATA");
  const history = useHistory();
  const [accepted, setAccepted] = useState(false);

  const handleCheck = (e) => {
    setAccepted(e.target.checked);
  };

  const handleOnClick = () => {
    if (accepted) {
      history.push({
        pathname: "/taxlist",
      });
    }
  };

  return (
    <div className={classes.root} style={{ minHeight:"calc(100vh - 120px)" }}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item>
            <Typography className={classes.pageTitle}>
              รายการเพื่อการหักลดหย่อนภาษี (ล.ย. 01)
            </Typography>
          </Grid>
        </Grid>
        <Paper className={classes.paper}>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                เรื่อง การแจ้งลดหย่อนภาษีเงินได้บุคคลธรรมดา
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.description}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  -
                  การแจ้งรายการค่าลดหย่อนภาษีเป็นความรับผิดชอบของพนักงานที่ต้องกรอกข้อมูลที่เกิดขึ้นจริงในปีภาษี
                </Typography>
                <Typography variant="body1" gutterBottom>
                  - รายการลดหย่อน "เงินบริจาค"
                  "เงินบริจาคเพื่อการศึกษา"และ"ค่าซื้อกองทุน LTF/RMF/SSF
                  และกองทุนอื่นในลักษณะเดียวกัน"
                  แจ้งรายการลดหย่อนได้เมื่อพนักงานจ่ายเงินบริจาคหรือซื้อกองทุนจริง
                </Typography>
                <Typography variant="body1" gutterBottom>
                  - กรณีแจ้งรายการลดหย่อนไม่ถูกต้องตามจริง
                  กรมสรรพากรอาจเรียกเก็บภาษีเพิ่มและเงินเพิ่มได้
                </Typography>
                <Typography variant="body1" gutterBottom>
                  - การแจ้งรายการลดหย่อนใช้ได้สำหรับปีภาษีปัจจุบันเท่านั้น
                </Typography>
                <Typography variant="body1" gutterBottom>
                  - การเปลี่ยนแปลงข้อมูลค่าลดหย่อนภาษีหลังวันที่ 15 ของเดือน
                  ระบบจะนำข้อมูลไปคำนวณค่าลดหย่อนภาษีให้ในเดือนถัดไป
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Grid container justifyContent="center">
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  onClick={handleCheck}
                  control={<Checkbox />}
                  label="ยอมรับ"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <div className={classes.button_container}>
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.button}
                  endIcon={<NavigateNextIcon />}
                  disabled={accepted ? false : true}
                  onClick={handleOnClick}
                >
                  ถัดไป
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Container>
    </div>
  );
}

export default TaxDeduction;
