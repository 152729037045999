import React, { useState, useEffect } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useDispatch } from "react-redux";

import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import DrawerCustom from "../../../shared/general/Drawer";

import {
  getAllProvidentFundByVendor,
  updateProvidentFund,
} from "../../../../../actions/vendor";

const StyledRoot = styled("div")(({ phone }) => ({
  width: phone === "true" ? 300 : 600,
  padding: 16,
}));

const DrawerProvidentFund = (props) => {
  const { open, onClose, data } = props;
  const dispatch = useDispatch();
  const [listDelete, setListDelete] = useState([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      listPF: [
        {
          minWorkYear: "",
          employeePercent: 0,
          vendorPercent: 0,
        },
      ],
    },
  });

  const phoneSceen = useMediaQuery("(max-width:600px)");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "listPF",
  });

  useEffect(() => {
    if (data) {
      setValue("listPF", data);
    }
  }, [data]);

  const handleDelete = (index, idProvidentFund) => {
    remove(index);
    if (idProvidentFund) {
      setListDelete([...listDelete, idProvidentFund]);
    }
  };

  const handleOnSubmit = async (value) => {
    const result = await dispatch(
      updateProvidentFund(value.listPF, listDelete)
    );
    if (result) {
      dispatch(getAllProvidentFundByVendor());
      onClose();
    }
  };

  return (
    <DrawerCustom open={open} title="แก้ไขกองทุนสำรองเลี้ยงชีพ" anchor="right">
      <StyledRoot phone={toString(phoneSceen)}>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Grid container spacing={2} style={{ marginBottom: "20px" }}>
            {fields.map((item, index) => (
              <React.Fragment key={item.id}>
                {index != 0 && (
                  <Grid item xs={12}>
                    <Divider
                      style={{ borderBottom: "2px solid rgba(0,0,0,0.09)" }}
                    />
                  </Grid>
                )}
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography>{`รายการที่ ${index + 1}`}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      style={{ color: "#d32f2f" }}
                      onClick={() => handleDelete(index, item.idProvidentFund)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name={`listPF.${index}.minWorkYear`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "กรุณากรอกปีขั้นต่ำในการทำงาน",
                        },
                        validate: {
                          minValue: (value) =>
                            parseInt(value) >= 0 ||
                            "กรุณากรอกจำนวนปีให้ถูกต้อง",
                        },
                      }}
                      render={({ field }) => (
                        <TextFieldTheme
                          {...field}
                          label="ปีขั้นต่ำในการทำงาน"
                          type="number"
                          helperText={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].minWorkYear &&
                            errors.listPF[index].minWorkYear.message
                          }
                          error={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].minWorkYear
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name={`listPF.${index}.employeePercent`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "กรุณากรอกสะสมส่วนพนักงานสูงสุด",
                        },
                        validate: {
                          minValue: (value) =>
                            parseInt(value) >= 0 || "กรุณากรอกจำนวนให้ถูกต้อง",
                          maxValue: (value) =>
                            parseInt(value) <= 100 ||
                            "กรุณากรอกจำนวนให้ถูกต้อง",
                        },
                      }}
                      render={({ field }) => (
                        <TextFieldTheme
                          {...field}
                          label="สะสมส่วนพนักงานสูงสุด"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                          helperText={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].employeePercent &&
                            errors.listPF[index].employeePercent.message
                          }
                          error={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].employeePercent
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Controller
                      name={`listPF.${index}.vendorPercent`}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "กรุณากรอกสะสมส่วนบริษัทสูงสุด",
                        },
                        validate: {
                          minValue: (value) =>
                            parseInt(value) >= 0 || "กรุณากรอกจำนวนให้ถูกต้อง",
                          maxValue: (value) =>
                            parseInt(value) <= 100 ||
                            "กรุณากรอกจำนวนให้ถูกต้อง",
                        },
                      }}
                      render={({ field }) => (
                        <TextFieldTheme
                          {...field}
                          label="สะสมส่วนบริษัทสูงสุด"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                          helperText={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].vendorPercent &&
                            errors.listPF[index].vendorPercent.message
                          }
                          error={
                            errors &&
                            errors.listPF &&
                            errors.listPF[index] &&
                            errors.listPF[index].vendorPercent
                              ? true
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <ButtonBlue
                variant="contained"
                style={{ width: "100%", borderRadius: "25px" }}
                onClick={() =>
                  append({
                    minWorkYear: "",
                    employeePercent: 0,
                    vendorPercent: 0,
                  })
                }
              >
                <Add />
              </ButtonBlue>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ borderBottom: "2px solid rgba(0,0,0,0.09)" }} />
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid item>
              <ButtonBlue variant="outlined" onClick={onClose}>
                ยกเลิก
              </ButtonBlue>
            </Grid>
            <Grid item>
              <ButtonBlue type="submit" variant="contained">
                ยืนยัน
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerProvidentFund;
