import React, { useState, useEffect, Fragment } from "react";
import { styled } from "@mui/material/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import StyledCard from "../../../shared/general/Card";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Avatar from "@mui/material/Avatar";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";

import AvatarOff from "../../../shared/general/AvatarOff";
import AvatarShift from "../../../shared/general/AvatarShift";
import AvatarPeriod from "../../../shared/general/AvatarPeriod";

import utils from "../../../../../utils";

import Day from "../assets/day.png";
import Night from "../assets/night.png";
import Mid from "../assets/mid.png";

import dayjs from "dayjs";
import { Container } from "@mui/material";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledFormControlSelect = styled(FormControl)(({}) => ({
  width: 200,
  marginTop: 16,
  marginBottom: 16,
  marginRight: 8,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiSelect-select": {
      padding: "8.5px 14px",
    },
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

const StyledAvatarBeforeStart = styled(Avatar)(({ shift, size }) => ({
  width: 25,
  height: 25,
  backgroundColor: "#dddddd",
  "& .MuiSvgIcon-root": {
    fontSize: 18,
  },
}));

export default function ListEmployeePage(props) {
  let { id } = useParams();
  const { t, i18n } = useTranslation();
  const [tableShiftYear, setTableShiftYear] = useState(null);
  const [tableShift, setTableShift] = useState(null);
  const [tablePeriod, setTablePeriod] = useState([]);
  const [selectedYear, setSelectedYear] = useState(2021);
  var period = [
    {
      title: "day",
      has: false,
    },
    {
      title: "mid",
      has: false,
    },
    {
      title: "night",
      has: false,
    },
    {
      title: "off",
      has: false,
    },
  ];
  const [dataNewShift, setDataNewShift] = React.useState({
    nameShift: "3 หยุด 3",
    created: dayjs().toDate(),
    start: dayjs(new Date(2021, 11, 12)),
    reason: "",
    workday: 0,
    offday: 0,
    amountShift: 0,
    shift: ["Shift A", "Shift B", "Shift C", "Shift D"],
    patternShift: null,
    periodTime: [
      {
        periodTime: "เช้า",
        periodTimeEN: "DAY",
        check: true,
        checkOT: false,
        timeStartHours: "7",
        timeStartMinute: "30",
        timeEndHours: "20",
        timeEndMinute: "0",
        otHours: "",
        timeOtStartHours: "",
        timeOtStartMinute: "",
        timeOtEndHours: "",
        timeOtEndMinute: "",
      },
      {
        periodTime: "บ่าย",
        periodTimeEN: "MID",
        check: false,
        checkOT: false,
        timeStartHours: "",
        timeStartMinute: "",
        timeEndHours: "",
        timeEndMinute: "",
        otHours: 0,
        timeOtStartHours: "",
        timeOtStartMinute: "",
        timeOtEndHours: "",
        timeOtEndMinute: "",
      },
      {
        periodTime: "ดึก",
        periodTimeEN: "NIGHT",
        check: true,
        checkOT: false,
        timeStartHours: "20",
        timeStartMinute: "0",
        timeEndHours: "7",
        timeEndMinute: "30",
        otHours: 0,
        timeOtStartHours: "",
        timeOtStartMinute: "",
        timeOtEndHours: "",
        timeOtEndMinute: "",
      },
    ],
  });

  useEffect(() => {
    console.log(id);
    const patternShift = {
      pattern1: {
        shiftA: [
          { id: "0", title: "DAY" },
          { id: "1", title: "DAY" },
          { id: "2", title: "DAY" },
          { id: "3", title: "OFF" },
          { id: "4", title: "OFF" },
          { id: "5", title: "OFF" },
          { id: "6", title: "NIGHT" },
          { id: "7", title: "NIGHT" },
          { id: "8", title: "NIGHT" },
          { id: "9", title: "OFF" },
          { id: "10", title: "OFF" },
          { id: "11", title: "OFF" },
        ],
        shiftB: [
          { id: "8", title: "NIGHT" },
          { id: "6", title: "NIGHT" },
          { id: "7", title: "NIGHT" },
          { id: "9", title: "OFF" },
          { id: "10", title: "OFF" },
          { id: "11", title: "OFF" },
          { id: "0", title: "DAY" },
          { id: "1", title: "DAY" },
          { id: "2", title: "DAY" },
          { id: "3", title: "OFF" },
          { id: "4", title: "OFF" },
          { id: "5", title: "OFF" },
        ],
        shiftC: [
          { id: "9", title: "OFF" },
          { id: "10", title: "OFF" },
          { id: "11", title: "OFF" },
          { id: "0", title: "DAY" },
          { id: "1", title: "DAY" },
          { id: "2", title: "DAY" },
          { id: "3", title: "OFF" },
          { id: "4", title: "OFF" },
          { id: "5", title: "OFF" },
          { id: "6", title: "NIGHT" },
          { id: "7", title: "NIGHT" },
          { id: "8", title: "NIGHT" },
        ],
        shiftD: [
          { id: "3", title: "OFF" },
          { id: "4", title: "OFF" },
          { id: "5", title: "OFF" },
          { id: "6", title: "NIGHT" },
          { id: "7", title: "NIGHT" },
          { id: "8", title: "NIGHT" },
          { id: "9", title: "OFF" },
          { id: "10", title: "OFF" },
          { id: "11", title: "OFF" },
          { id: "1", title: "DAY" },
          { id: "0", title: "DAY" },
          { id: "2", title: "DAY" },
        ],
      },
      pattern2: {
        shiftA: [
          { id: "0", title: "DAY" },
          { id: "1", title: "DAY" },
          { id: "2", title: "DAY" },
          { id: "3", title: "DAY" },
          { id: "4", title: "OFF" },
          { id: "5", title: "OFF" },
          { id: "6", title: "NIGHT" },
          { id: "7", title: "NIGHT" },
          { id: "8", title: "NIGHT" },
          { id: "9", title: "NIGHT" },
          { id: "10", title: "OFF" },
          { id: "11", title: "OFF" },
        ],
        shiftB: [
          { id: "10", title: "OFF" },
          { id: "11", title: "OFF" },
          { id: "9", title: "NIGHT" },
          { id: "8", title: "NIGHT" },
          { id: "7", title: "NIGHT" },
          { id: "6", title: "NIGHT" },
          { id: "4", title: "OFF" },
          { id: "5", title: "OFF" },
          { id: "0", title: "DAY" },
          { id: "1", title: "DAY" },
          { id: "2", title: "DAY" },
          { id: "3", title: "DAY" },
        ],
        shiftC: [
          { id: "8", title: "NIGHT" },
          { id: "9", title: "NIGHT" },
          { id: "10", title: "OFF" },
          { id: "11", title: "OFF" },
          { id: "0", title: "DAY" },
          { id: "1", title: "DAY" },
          { id: "2", title: "DAY" },
          { id: "3", title: "DAY" },
          { id: "4", title: "OFF" },
          { id: "5", title: "OFF" },
          { id: "6", title: "NIGHT" },
          { id: "7", title: "NIGHT" },
        ],
      },
    };

    const tempTableShiftYear = [];
    var countLoopPattern = 0;
    var firstDateOfPattern = dayjs(
      new Date(
        dayjs(dataNewShift.start).year(),
        dayjs(dataNewShift.start).month(),
        1
      )
    );
    for (let index = 0; index < 31 + 365 + 365; index++) {
      if (countLoopPattern >= 12) {
        countLoopPattern = 0;
      }

      const temp = {
        date: dayjs(firstDateOfPattern).add(index, "day"),
      };
      if (dayjs(dataNewShift.start).isAfter(dayjs(temp.date))) {
        temp["status"] = false;
      } else {
        for (const [key, value] of Object.entries(
          patternShift["pattern" + 1]
        )) {
          var newKey = value[countLoopPattern].title;

          period.find((x) => x.title == newKey.toLowerCase()).has = true;

          if (newKey.toLowerCase() === "off") {
            if (temp[newKey.toLowerCase()] === undefined) {
              temp[newKey.toLowerCase()] = [];
            }
            temp[newKey.toLowerCase()].push(key.slice(-1));
          } else {
            temp[newKey.toLowerCase()] = key.slice(-1);
          }
          temp["status"] = true;
        }
        countLoopPattern++;
      }

      tempTableShiftYear.push(temp);
    }

    setTableShiftYear(tempTableShiftYear);
    const dataSelectedYear = utils.groupBy(tempTableShiftYear, (value) =>
      dayjs(value.date).year() === selectedYear ? selectedYear : "not"
    );

    setTableShift(dataSelectedYear.get(selectedYear));
    setTablePeriod(period);
  }, []);

  const handleChangeSelectedYear = (event) => {
    setSelectedYear(event.target.value);
    const dataSelectedYear = utils.groupBy(tableShiftYear, (value) =>
      dayjs(value.date).year() === event.target.value
        ? event.target.value
        : "not"
    );
    setTableShift(dataSelectedYear.get(event.target.value));
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8, color: "#212b36" }}>
          {t("YearlyShiftSchedule")}
        </Typography>
        <StyledCard>
          <div style={{ display: "grid", padding: 16 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Typography variant="h5">ตาราง 3 หยุด 3</Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {t("GetStarted")} 12/12/2021
                </Typography>
              </div>
              <div>
                <StyledFormControlSelect fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedYear}
                    onChange={handleChangeSelectedYear}
                  >
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                  </Select>
                </StyledFormControlSelect>
              </div>
            </div>

            {tableShift &&
              [...Array(12).keys()]
                .filter((month) => {
                  return tableShift.find(
                    (o) => dayjs(o.date).month() === month
                  );
                })
                .map((month) => {
                  return (
                    <Fragment>
                      <Typography
                        variant="h5"
                        style={{ textAlign: "center", marginBottom: 24 }}
                      >
                        {dayjs(new Date(selectedYear, month, 1)).format(
                          "MMMM YYYY"
                        )}
                      </Typography>
                      <TableContainer>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              {tableShift &&
                                tableShift
                                  .filter((v) => {
                                    return (
                                      dayjs(v.date).year() === selectedYear &&
                                      dayjs(v.date).month() === month
                                    );
                                  })
                                  .map((value, index) => {
                                    return (
                                      <TableCell
                                        align="center"
                                        style={{ padding: 0 }}
                                      >
                                        {dayjs(value.date).day() === 0 ||
                                        dayjs(value.date).day() === 6 ? (
                                          <Typography
                                            style={{
                                              fontWeight: 500,
                                              color: "#e46a76",
                                            }}
                                          >
                                            {dayjs(value.date).format("D")}
                                          </Typography>
                                        ) : (
                                          <Typography>
                                            {dayjs(value.date).format("D")}
                                          </Typography>
                                        )}
                                      </TableCell>
                                    );
                                  })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableShift &&
                              tablePeriod
                                .filter((value) => {
                                  if (value.has) return value;
                                })
                                .map((row, index) => (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{
                                        padding:
                                          row.title === "off"
                                            ? "28px 16px"
                                            : "6px 16px",
                                      }}
                                    >
                                      {row.title === "day" && (
                                        <AvatarPeriod
                                          title={row.title}
                                          size={40}
                                          src={Day}
                                        />
                                      )}
                                      {row.title === "mid" && (
                                        <AvatarPeriod
                                          title={row.title}
                                          size={40}
                                          src={Mid}
                                        />
                                      )}
                                      {row.title === "night" && (
                                        <AvatarPeriod
                                          title={row.title}
                                          size={40}
                                          src={Night}
                                        />
                                      )}

                                      {row.title === "off" && <AvatarOff />}
                                    </TableCell>
                                    {tableShift
                                      .filter((v) => {
                                        if (
                                          dayjs(v.date).year() ===
                                            selectedYear &&
                                          dayjs(v.date).month() === month
                                        )
                                          return v;
                                      })
                                      .map((value, index) => {
                                        return (
                                          <TableCell
                                            align="center"
                                            style={{ padding: 0 }}
                                          >
                                            {value.status ? (
                                              <Fragment>
                                                {row.title === "off" ? (
                                                  <div>
                                                    {value[row.title].map(
                                                      (v) => {
                                                        return (
                                                          <div
                                                            style={{
                                                              marginBottom: 8,
                                                            }}
                                                          >
                                                            <AvatarShift
                                                              fontSize={18}
                                                              title={v}
                                                              size={25}
                                                              opacity={true}
                                                            />
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                ) : (
                                                  <AvatarShift
                                                    fontSize={18}
                                                    title={value[row.title]}
                                                    size={25}
                                                  />
                                                )}
                                              </Fragment>
                                            ) : (
                                              <Fragment>
                                                <StyledAvatarBeforeStart>
                                                  <CloseIcon />
                                                </StyledAvatarBeforeStart>
                                              </Fragment>
                                            )}
                                          </TableCell>
                                        );
                                      })}
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Fragment>
                  );
                })}
          </div>
        </StyledCard>
      </Container>
    </StyledRoot>
  );
}
