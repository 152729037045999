import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as React from "react";


import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";


import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import { Grid } from "@mui/material";

const StyledRoot = styled("div")({
  width: 500,
  padding: 24,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

export default function DialogAddExperience(props) {
  const { open, handleCloseDialog, handleAddExperience } = props;

  const formik = useFormik({
    initialValues: {
      positionName: "",
      companyName: "",
      startDate: "",
      endDate: "",
      description: "",
    },
    //validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    console.log(values);
    handleAddExperience(values);
    formik.resetForm();
  };

  return (
    <div>
      <DrawerCustom
        title={"เพิ่มการศึกษา"}
        anchor="right"
        open={open}
        onClose={handleCloseDialog}
      >
        <form onSubmit={formik.handleSubmit}>
          <StyledRoot>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextFieldTheme
                  id="outlined-multiline-static"
                  onChange={formik.handleChange}
                  name="companyName"
                  label="ชื่อบริษัท"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldTheme
                  id="outlined-multiline-static"
                  onChange={formik.handleChange}
                  name="positionName"
                  label="ตำแหน่ง"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <DatePicker
                    label=""
                    name="fromYear"
                    views={["year", "month"]}
                    inputFormat="MMM yyyy"
                    value={formik.values.fromYear}
                    onChange={(date) => {
                      formik.setFieldValue("fromYear", date);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                  <DatePicker
                    label=""
                    name="fromYear"
                    views={["year", "month"]}
                    inputFormat="MMM yyyy"
                    value={formik.values.fromYear}
                    onChange={(date) => {
                      formik.setFieldValue("fromYear", date);
                    }}
                    renderInput={(params) => (
                      <TextFieldTheme fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </StyledRoot>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleCloseDialog}>
              ยกเลิก
            </ButtonBlue>
            <ButtonBlue type="submit" variant="contained">
              เพิ่มข้อมูล
            </ButtonBlue>
          </StyledFooter>
        </form>
      </DrawerCustom>
    </div>
  );
}
