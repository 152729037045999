import { Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import React from "react";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#007afe",
  },
}));

export default function ChartsLeave(props) {
  const { useLeave, max, leave } = props;
  return (
    <div style={{ marginBottom: 16 }}>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <Typography variant="h4" style={{ marginRight: 4 }}>
        {useLeave}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          วัน
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body1" color="text.secondary" style={{ fontWeight: 500 }}>
          {leave}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {max} วัน
        </Typography>
      </div>
      <BorderLinearProgress variant="determinate" value={50} />
    </div>
  );
}
