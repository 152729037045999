import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Container,
  Grid,
  Paper,
  styled,
  Typography,
  Divider,
} from "@mui/material";

import LeftPanel from "./left";
import RightPanel from "./right";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const ProfilePage = (props) => {

  return (
    <StyledRoot className={`page`}>
      {/* <StyledCover /> */}
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <LeftPanel />
          </Grid>
          <Grid item xs={12} sm={8}>
            <RightPanel />
          </Grid>
        </Grid>
      </Container>
    </StyledRoot>
  );
};

export default ProfilePage;
