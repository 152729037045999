import React from "react";
import ListMenu from "../../../pages/shared/listMenu";

import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import SummarizeIcon from "@mui/icons-material/Summarize";

const UserMenu = () => {
  const dataListMenu = [
    {
      listItemIcon: <HomeIcon />,
      listItemText: "Home",
      listLink: "/home",
      listKey: "home",
    },
    {
      listItemIcon: <DashboardIcon />,
      listItemText: "ตารางเวลาทำงาน",
      listLink: "/timeline",
      listKey: "timeline",
    },
    {
      listItemIcon: <DashboardIcon />,
      listItemText: "ลางาน",
      listLink: "/leave",
      listKey: "leave",
    },
    {
      listItemIcon: <PersonIcon />,
      listItemText: "สถานะรายการ",
      listLink: "/requestlist",
      listKey: "requestlist",
    },
    {
      listItemIcon: <PersonIcon />,
      listItemText: "รายงานค่าล่วงเวลา",
      listLink: "/overtime/overtimereport",
      listKey: "overtimereport",
    },
    {
      listItemIcon: <PersonIcon />,
      listItemText: "Payslip",
      listLink: "/pay-slip",
      listKey: "payslip",
    },
    // {
    //   listItemIcon: <SummarizeIcon />,
    //   listItemText: "Report",
    //   listLink: "",
    //   listKey: "reportTax",
    //   collapse: [
    //     {
    //       listItemIcon: <SummarizeIcon />,
    //       listItemText: "ฟอร์มภาษี",
    //       listLink: "/reportTax",
    //       listKey: "reporttax",
    //     },
    //   ],
    // },
  ];

  return <ListMenu key="user" dataListMenu={dataListMenu} />;
};

export default UserMenu;
