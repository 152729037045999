import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";

import DialogCommentManager from "../../shared/requestList/dialogCommentManager";
import StatusRequest from "../../shared/general/stausRequest";

import RequestDialog from "./components/RequestDialog";

const headCells = [
  {
    id: "name",
    label: "ชื่อ-สกุล",
    minWidth: 200,
  },
  {
    id: "leaveTypeName",
    label: "ประเภท",
  },
  {
    id: "description",
    label: "เหตุผล",
  },
  {
    id: "start-end",
    label: "เริ่มต้น-สิ้นสุด",
    minWidth: 220,
  },
  {
    id: "Button",
    label: "",
  },
];

const StyledAvatar = styled(Avatar)({
  width: 35,
  height: 35,
});

const StyledChip = styled(Chip)({
  height: 20,
  backgroundColor: "#ff3034",
  marginRight: 8,
  "& .MuiChip-label": {
    paddingLeft: 8,
    paddingRight: 8,
    color: "#FFFFFF",
    fontWeight: 600,
  },
});

const StyledBadge = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangular": {
    backgroundColor: "#ff3034",
  },
});

const StyledCellName = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiAvatar-root": {
    marginRight: 8,
  },
  "& .MuiTypography-body1": {
    width: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .MuiTypography-body2": {
    color: "#999999",
    fontStyle: "oblique",
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  padding: "8px 14px",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 16,
    paddingRight: 0,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "&:last-of-type": {
    paddingRight: 8,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "& .reason": {
    "& .main": {
      fontWeight: 500,
    },
    "& .other": {
      fontWeight: 500,
    },
  },
  "&:first-of-type": {
    paddingLeft: 16,
    paddingRight: 0,
  },
  "&:last-of-type": {
    paddingRight: 8,
  },
  "& .request-withdraw": {
    display: "flex",
    alignItems: "center",
  },
}));

export default function TableApproveList(props) {
  const {
    dataTable,
    checkbox,
    approvalSelected,
    setApprovalSelected,
    searchName,
  } = props;
  dayjs.extend(customParseFormat);
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const pages = [10, 25, 50];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [openDialog, setOpenDialog] = useState({
    isOpen: false,
    request: [],
  });

  const [filterData, setFilterData] = useState(dataTable);
  const [openDialogCommentManager, setOpenDialogCommentManager] = useState({ open: false, comment: "" });

  useEffect(() => {
    let temp = dataTable.filter((n) => {
      if(searchName && searchName !== ""){
        if(n.firstname.includes(searchName) || n.lastname.includes(searchName)){
          return true
        }
      } else {
        return true
      }
      return false;
    });
    setFilterData(temp);
  },[searchName,dataTable]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = dataTable.map((n) => n);
      setApprovalSelected(newSelected);
      return;
    }

    setApprovalSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = approvalSelected.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(approvalSelected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(approvalSelected.slice(1));
    } else if (selectedIndex === approvalSelected.length - 1) {
      newSelected = newSelected.concat(approvalSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        approvalSelected.slice(0, selectedIndex),
        approvalSelected.slice(selectedIndex + 1)
      );
    }

    setApprovalSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => approvalSelected.indexOf(row) !== -1;

  const renderOT = (row) => {
    const findPayroll = payrollSettingList.find(
      (x) => x.idCompany === row.idCompany && x.idVendor === row.idVendor
    );

    const payrollSetting = findPayroll ? findPayroll : payrollSettingList[0];

    let textRow = [];
    if (payrollSetting) {
      if (row.xOT > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" key="xOT">
            {`OT x ${payrollSetting.xOT}`} ={" "}
            {parseFloat(row.xOT / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xWorkingDailyHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" key="xWorkingDailyHoliday">
            {`OT x ${payrollSetting.xWorkingDailyHoliday}`} ={" "}
            {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xWorkingMonthlyHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" key="xWorkingMonthlyHoliday">
            {`OT x ${payrollSetting.xWorkingMonthlyHoliday}`} ={" "}
            {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (row.xOTHoliday > 0) {
        textRow.push(
          <Typography style={{ fontWeight: 500 }} color="text.third" key="xOTHoliday">
            {`OT x ${payrollSetting.xOTHoliday}`} ={" "}
            {parseFloat(row.xOTHoliday / 60).toFixed(2)} ชม.
          </Typography>
        );
      }
      if (textRow.length === 0) {
        return <Typography color="text.third">{""}</Typography>;
      } else {
        return textRow;
      }
    }
  };

  const whatLevel = (row) => {
    if (row.isDoubleApproval) {
      if (row.isManagerLV1Approve === 1) {
        return 2;
      } else {
        return 1;
      }
    } else {
      return null;
    }
  };

  const isRequestApprove = (row) => {
    if (row.idRequestTimeWithdraw) {
      return row.isApprove;
    } else {
      if (
        row.isManagerLV1Approve === null &&
        row.isManagerLV2Approve === null
      ) {
        return null;
      } else {
        if (row.isDoubleApproval) {
          if (row.isManagerLV1Approve === null) {
            return null;
          } else if (row.isManagerLV1Approve === 1) {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          } else {
            return row.isManagerLV1Approve;
          }
        } else {
          if (row.approvalLevel === 1) {
            if (row.isManagerLV1Approve === null) {
              return null;
            } else {
              return row.isManagerLV1Approve;
            }
          } else {
            if (row.isManagerLV2Approve === null) {
              return null;
            } else {
              return row.isManagerLV2Approve;
            }
          }
        }
      }
    }
  };

  const handleOpenDialogCommentManager = (comment) => {
    setOpenDialogCommentManager({ open: true, comment: comment })
  };

  return (
    <Box style={{ width: "100%" }}>
      <TableContainer style={{ width: "100%", height: 450 }}>
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              {checkbox && dataTable ? (
                <StyledCellHeader>
                  <Checkbox
                    style={{ padding: 0 }}
                    color="primary"
                    indeterminate={
                      approvalSelected.length > 0 &&
                      approvalSelected.length < filterData.length
                    }
                    checked={
                      dataTable.length > 0 &&
                      approvalSelected.length === filterData.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all desserts",
                    }}
                  />
                </StyledCellHeader>
              ) : (
                <StyledCellHeader>สถานะ</StyledCellHeader>
              )}

              {headCells.map((column, index) => (
                <StyledCellHeader
                  key={column.id}
                  style={{ minWidth: column.minWidth ? column.minWidth : 150 }}
                >
                  <Typography variant="body2" align="center">
                    {column.label}
                  </Typography>
                </StyledCellHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTable && dataTable.length > 0 && filterData.length > 0 ? (
              (rowsPerPage > 0
                ? 
                filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : 
                filterData
              ).map((row, index) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <StyledRowContent
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`${index}_${page}`}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                  >
                    {checkbox ? (
                      <StyledCellContent padding="checkbox">
                        <Checkbox
                          style={{ padding: 0 }}
                          onClick={(event) => handleClick(event, row)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </StyledCellContent>
                    ) : (
                      <Fragment>
                        {row.idLeave ? (
                          <StyledCellContent>
                            <StatusRequest
                              boxSize={40}
                              fontSize={22}
                              borderRadius={8}
                              status={
                                row.isApprove === 1
                                  ? 1
                                  : row.isApprove === 0
                                  ? 0
                                  : null
                              }
                              active={row.isActive}
                            />
                          </StyledCellContent>
                        ) : (
                          <StyledCellContent>
                            <StatusRequest
                              boxSize={40}
                              fontSize={22}
                              borderRadius={8}
                              step={whatLevel(row)}
                              status={isRequestApprove(row)}
                              active={row.isActive}
                            />
                          </StyledCellContent>
                        )}
                      </Fragment>
                    )}

                    <StyledCellContent className={`fullname`}>
                      <StyledCellName>
                        <StyledAvatar
                          alt={"username"}
                          src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                        />
                        <div>
                          <Typography
                            style={{
                              fontSize: "18px",
                              fontWeight: 600,
                            }}
                          >
                            {`${row.firstname} ${row.lastname}`}
                          </Typography>
                          <Typography variant="body2">
                            {row.positionsName}
                          </Typography>
                        </div>
                      </StyledCellName>
                    </StyledCellContent>
                    <StyledCellContent className={`cellName`}>
                      <div className="request-withdraw">
                        {(row.idRequestTimeWithdraw ||
                          row.idLeaveEmployeesWithdraw) && (
                          <StyledChip label="ถอน" />
                        )}
                        {row.isWithdraw ? (
                          <StyledBadge badgeContent=" " variant="dot">
                            <Typography color="text.primary">
                              {row.name}
                            </Typography>
                          </StyledBadge>
                        ) : (
                          <Typography color="text.primary">
                            {row.name}
                          </Typography>
                        )}
                      </div>
                      {(row.idRequestType === 2 || row.idRequestType === 3) &&
                        payrollSettingList &&
                        renderOT(row)}
                    </StyledCellContent>
                    <StyledCellContent className={`cellName`}>
                      {row.idLeave ? (
                        <Typography color="text.primary">
                          {row.description || "-"}
                        </Typography>
                      ) : (
                        <div className="reason">
                          {(row.reasonName || row.otherReason) ?
                            <>
                              <Typography className="main">{row.reasonName}</Typography>
                              <Typography className="other" color="text.third">
                                {row.otherReason}
                              </Typography>
                            </>
                            :
                            <Typography color="text.primary">
                              {row.description || "-"}
                            </Typography>
                          }
                        </div>
                      )}
                    </StyledCellContent>
                    <StyledCellContent align="center">
                      <div>
                        {row.isFullDay ? (
                          <Fragment>
                            {row.startText === row.endText ? (
                              <Typography>
                                {dayjs(
                                  row.startText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("D MMM BBBB")}
                              </Typography>
                            ) : row.used > 1 ? (
                              <Typography>
                                {`${dayjs(
                                  row.startText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("D MMM BBBB")} - ${dayjs(
                                  row.endText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("D MMM BBBB")}`}
                              </Typography>
                            ) : (
                              <Typography>
                                {dayjs(
                                  row.startText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("D MMM BBBB")}
                              </Typography>
                            )}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <div>
                              <Typography>
                                {dayjs(
                                  row.startText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("D MMM BBBB")}
                              </Typography>
                              <Typography>
                                {`${dayjs(
                                  row.startText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("HH:mm")} - ${dayjs(
                                  row.endText,
                                  "DD/MM/YYYY HH:mm"
                                ).format("HH:mm")}`}
                              </Typography>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </StyledCellContent>
                    <StyledCellContent align="center">
                      {((row.isApprove === 0)
                        && (row.commentManager || row.commentManagerLV1 || row.commentManagerLV2)) &&
                        <Tooltip title="ดูรายละเอียด" placement="top">
                          <IconButton aria-label="comment"
                            onClick={() => {
                              let comment = row.commentManager;
                              if (row.commentManagerLV1) {
                                comment = row.commentManagerLV1;
                              }
                              if (row.commentManagerLV2) {
                                comment = row.row.commentManagerLV2;
                              }
                              handleOpenDialogCommentManager(comment);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </StyledCellContent>
                  </StyledRowContent>
                );
              })
            ) : (
              <StyledRowContent>
                <StyledCellContent colSpan="7">
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="body1">ไม่พบข้อมูล</Typography>
                  </Box>
                </StyledCellContent>
              </StyledRowContent>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={filterData ? filterData.length : 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {openDialogCommentManager.open && 
        <DialogCommentManager 
          open={openDialogCommentManager.open}
          onClose={() => setOpenDialogCommentManager({ open: false, comment: "" })}
          comment={openDialogCommentManager.comment}
        />
      }
      <RequestDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
    </Box>
  );
}
