import {
    VENDOR_PROVIDENT_FUND_FETCHING,
    VENDOR_PROVIDENT_FUND_SUCCESS,
    VENDOR_PROVIDENT_FUND_FAILED
} from '../actions/types';

const initialState = {
    result: null,
    isFetching: false,
    isError: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case VENDOR_PROVIDENT_FUND_FETCHING:
            return { ...state, result: null, isFetching: true, isError: false };
        case VENDOR_PROVIDENT_FUND_FAILED:
            return { ...state, result: null, isFetching: false, isError: true };
        case VENDOR_PROVIDENT_FUND_SUCCESS:
            return { ...state, result: payload, isFetching: false, isError: false };
        default:
            return state;
    }
};