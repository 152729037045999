import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import axios from "axios";
import { useTranslation } from "react-i18next";

import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  MenuItem,
  Modal,
  Paper,
  styled,
  Stack,
  Typography,
} from "@mui/material";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { getDataWritePDF } from "../../../../actions/user";
import { getEmployeesTaxDetails } from "../../../../actions/employee";
import { PND91PdfFile } from "../governmentReport/filePDFgeneration/PND91PdfFile";
import { Tawi50PDFfile } from "../governmentReport/filePDFgeneration/50tawiPDFfile";

const RootStyled = styled("div")({
  "& .paper-list": {
    marginTop: 24,
    padding: 16,
  },
  "& .filename": {
    fontSize: 16,
  },
  "& .navBox": {
    display: "flex",
    height: 56,
    alignItems: "center",
    "& .downloadButton": {
      width: 160,
      minWidth: 160,
      height: 36,
      marginLeft: 16,
    },
  },
});

const DividerStyled = styled(Divider)({
  margin: "8px 0",
});

const DownloadFileButton = ({
  type,
  year,
  disabled,
  handleOpenAlertError,
  setOpenBackDrop,
}) => {
  const dispatch = useDispatch();
  const [imgLogo, setImgLogo] = useState("");
  const { t, i18n } = useTranslation();

  const handleOnCertEmp = async () => {
    setOpenBackDrop(true);
    const res = await dispatch(getDataWritePDF(type, year));

    if (res.status === 200 && res.data.data && res.data.data.length > 0) {
      const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
      const existingPdfBytes = await fetch(url).then((res) =>
        res.arrayBuffer()
      );
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
      const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
      pdfDoc.registerFontkit(fontkit);

      const pages = pdfDoc.getPages();
      const font = await pdfDoc.embedFont(fontBytes);
      const widthSize = 200;
      let imgSource = "";
      let sigImg = "";
      let signatureName = "";
      let signaturePosition = "";

      res.data.data.map(async (page, index) => {
        const currentPage = pages[index];
        await Promise.all(
          page.map(async (item) => {
            if (item.text) {
              let xContainer = 0;
              if (item.containerSize !== undefined) {
                const textWidth = font.widthOfTextAtSize(item.text, item.size);
                xContainer = (widthSize - textWidth) / 2;
              }

              currentPage.drawText(
                type === "PND91"
                  ? item.text.replace(/\u200B/g, "").trim()
                  : item.text,
                {
                  x: xContainer == 0 ? item.x : item.x + xContainer,
                  y: item.y,
                  ...(item.font ? { font: font } : {}),
                  ...(item.size ? { size: item.size } : {}),
                  ...(item.maxWidth ? { maxWidth: item.maxWidth } : {}),
                  ...(item.fontWeight ? { fontWeight: item.fontWeight } : {}),
                }
              );
            }

            if (item.imageLogo !== undefined) {
              imgSource = item.imageLogo;
            }

            if (item.signatureImage !== undefined) {
              sigImg = item.signatureImage;
            }

            if (item.authorizedSignatureOneName !== undefined) {
              signatureName = item.authorizedSignatureOneName;
            }

            if (item.authorizedSignatureOnePosition !== undefined) {
              signaturePosition = item.authorizedSignatureOnePosition;
            }
          })
        );
      });

      if (imgSource) {
        const pngImage = await pdfDoc.embedPng(imgSource);
        const pngDims = pngImage.scale(0.25);
        pages[0].drawImage(pngImage, {
          x: 40,
          y: 680,
          width: pngDims.width,
          height: pngDims.height,
        });
      }

      if (sigImg) {
        const pngImage = await pdfDoc.embedPng(sigImg);
        const pngDims = pngImage.scale(0.2);
        pages[0].drawImage(pngImage, {
          x: 310 + (150 - pngDims.width) / 2,
          y: 400,
          width: pngDims.width,
          height: pngDims.height,
        });
      }

      if (signatureName) {
        const textWidth = font.widthOfTextAtSize(signatureName, 16);
        pages[0].drawText(signatureName, {
          x: 310 + (150 - textWidth) / 2,
          y: 380,
          size: 16,
          font: font,
        });
      }

      if (signaturePosition) {
        const textWidth = font.widthOfTextAtSize(signaturePosition, 16);
        pages[0].drawText(signaturePosition, {
          x: 310 + (150 - textWidth) / 2,
          y: 360,
          size: 16,
          font: font,
        });
      }

      /*
      const textWidth = font.widthOfTextAtSize(text, textSize);
      const textHeight = font.heightAtSize(textSize);

      pages[0].drawRectangle({
        x: 180,
        y: 480,
        width: 200,
        height: textHeight,
        borderColor: rgb(1, 0, 0),
        borderWidth: 1.5,
      });
      */

      const pdfBytes = await pdfDoc.save();
      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: "application/pdf" });
      const docUrl = URL.createObjectURL(blob);
      window.open(docUrl, "_blank");
    } else if (res.status === 404) {
      handleOpenAlertError(`${t("IncompleteInfo")}`);
    } else {
      handleOpenAlertError();
    }
    setOpenBackDrop(false);
  };

  return (
    <ButtonBlue
      variant="contained"
      className="downloadButton"
      onClick={handleOnCertEmp}
      disabled={disabled}
    >
      {t("Download")}
    </ButtonBlue>
  );
};

const MyDocument = () => {
  const { result: UserProfile } = useSelector((state) => state.userProfile);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = useState({
    isOpen: false,
    type: null,
    label: null,
  });
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const { control, watch } = useForm({
    defaultValues: {
      year50Tawi: "",
      yearPND91: "",
    },
  });

  const checkIsWorkingYear = (year) => {
    let result = true;
    if (UserProfile && UserProfile.hiringDate) {
      result = year >= parseInt(dayjs(UserProfile.hiringDate).format("YYYY"));
    }
    return result;
  };

  const handleOpenAlertError = (label) => {
    setOpenAlert({ isOpen: true, type: "error", label: label });
  };

  const handleCloseAlert = () => {
    setOpenAlert({ isOpen: false, type: null, label: null });
  };

  const [type, setType] = useState(null);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedYear2, setSelectedYear2] = useState(null);

  const thaiYears = Array.from(
    { length: 6 },
    (_, index) => currentYear + 543 - index
  );

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleYearChange2 = (event) => {
    setSelectedYear2(event.target.value);
  };

  useEffect(() => {
    if (selectedYear && UserProfile) {
      getEmployeesTaxDetails(selectedYear, UserProfile.idEmp);
    }
  }, [UserProfile, selectedYear]);

  const modifyPdf = async (type, selectedYear) => {
    try {
      const data = await getEmployeesTaxDetails(selectedYear, UserProfile.idEmp, type);
      if (!data.data) {
        handleOpenAlertError("ไม่มีข้อมูล");
        return;
      }

      const generateFunctionMap = {
        "PND91": PND91PdfFile,
        "50tawi": Tawi50PDFfile,
      };

      const generateFunction = generateFunctionMap[type];

      if (!generateFunction) {
        handleOpenAlertError("Unsupported report type");
        return;
      }

      if (type === "PND91") {
        generateFunction(type, selectedYear, data.data);
      } else if (type === "50tawi") {
        generateFunction(type, selectedYear, data.data);
      }
    } catch (error) {
      handleOpenAlertError("เกิดข้อผิดพลาขณะดึงข้อมูล");
    }
  };

  return (
    <RootStyled className="page" style={{ height: "calc(100vh - 80px)" }}>
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {t("MyDocument")}
        </Typography>
        <Paper className="paper-list">
          <List>
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">
                    {t("CertificateSalary")}
                  </Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <DownloadFileButton
                      type={"certificateSalary"}
                      handleOpenAlertError={handleOpenAlertError}
                      setOpenBackDrop={setOpenBackDrop}
                    />
                  </div>
                </Grid>
              </Grid>
            </ListItem>
            <DividerStyled />
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">
                    {t("CertificateEmp")}
                  </Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <DownloadFileButton
                      type={"certificateEmp"}
                      handleOpenAlertError={handleOpenAlertError}
                      setOpenBackDrop={setOpenBackDrop}
                    />
                  </div>
                </Grid>
              </Grid>
            </ListItem>
            <DividerStyled />
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">{t("PND91")}</Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <Stack spacing={2} direction={"row"} alignItems={"center"}>
                      {/* <Controller
                        name="year"
                        control={control}
                        render={({ field }) => (
                          <TextFieldTheme
                            {...field}
                            label={t("SelectYear")}
                            select
                            value={selectedYear}
                            onChange={handleYearChange}
                            style={{ width: 150 }}
                          >
                            {thaiYears.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </TextFieldTheme>
                        )}
                      /> */}
                      <ButtonBlue
                        variant="contained"
                        type="submit"
                        disabled={selectedYear === null}
                        onClick={
                          async () => {
                            await modifyPdf("PND91", selectedYear);
                          }}
                        style={{ width: 160 }}
                      >
                        ดูตัวอย่าง PDF
                      </ButtonBlue>
                    </Stack>
                  </div>
                </Grid>
              </Grid>
            </ListItem>
            <DividerStyled />
            <ListItem>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
              >
                <Grid item>
                  <Typography className="filename">{t("Tawi50")}</Typography>
                </Grid>
                <Grid item>
                  <div className="navBox">
                    <Stack spacing={2} direction={"row"} alignItems={"center"}>
                      <Controller
                        name="year"
                        control={control}
                        render={({ field }) => (
                          <TextFieldTheme
                            {...field}
                            label={t("SelectYear")}
                            select
                            value={selectedYear2}
                            onChange={handleYearChange2}
                            style={{ width: 150 }}
                          >
                            {thaiYears.map((year) => (
                              <MenuItem key={year} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </TextFieldTheme>
                        )}
                      />
                      <ButtonBlue
                        variant="contained"
                        type="submit"
                        disabled={selectedYear2 === null}
                        onClick={
                          async () => {
                            await modifyPdf("50tawi", selectedYear2);
                          }}
                        style={{ width: 160 }}
                      >
                        ดูตัวอย่าง PDF
                      </ButtonBlue>
                    </Stack>
                  </div>
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </Paper>
      </Container>
      {openAlert.isOpen && (
        <AlertResponse
          open={openAlert.isOpen}
          alertType={openAlert.type}
          label={openAlert.label}
          handleClose={handleCloseAlert}
        />
      )}
    </RootStyled>
  );
};

export default MyDocument;
