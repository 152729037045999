import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";

import { Typography, Divider, Grid } from "@mui/material";

// import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import EditIcon from "@mui/icons-material/Edit";

import AvatarEmployee from "./AvatarEmployee";
import DialogEdit from "./DialogEdit";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import utils from "../../../../../../utils";

const StyledInformation = styled("div")({
  marginTop: 16,
  "& .started": {
    fontSize: 14,
  },
  "& .wrap-show-hide-personalId": {
    display: "flex",
    justifyContent: "center",
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledTextValue = styled(Typography)({
  fontSize: 18,
  "&.dot": {
    marginTop: 4,
    fontSize: 8,
    "& i": {
      padding: 2,
    },
  },
});

// const StyledImageProfile = styled("div")({
//   width: "100%",
//   "& .dropzone-profile": {
//     flex: 1,
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     padding: 20,
//     borderWidth: 2,
//     borderRadius: 2,
//     borderColor: "#eeeeee",
//     borderStyle: "dashed",
//     backgroundColor: "#fafafa",
//     color: "#bdbdbd",
//     outline: "none",
//     transition: "border 0.24s ease-in-out",
//     height: 160,
//     width: 160,
//     margin: "auto",
//     borderRadius: "50%",
//     padding: 8,
//     border: "1px dashed rgba(145, 158, 171, 0.32)",
//   },
//   "& .inner-dropzone-profile": {
//     cursor: "pointer",
//     zIndex: 0,
//     width: "100%",
//     height: "100%",
//     outline: "none",
//     display: "flex",
//     overflow: "hidden",
//     backgroundColor: "#FFFFFF",
//     borderRadius: "50%",
//     position: "relative",
//     "& .placeholder": {
//       //display: "none",
//     },
//     "&:hover .placeholder": {
//       zIndex: 9,
//       display: "none",
//       //display: "flex",
//     },
//   },
//   "& .uploadImage": {
//     width: "105%",
//     zIndex: 8,
//   },
//   "& .placeholder": {
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     position: "absolute",
//     alignItems: "center",
//     flexDirection: "column",
//     justifyContent: "center",
//     color: "rgb(99, 115, 129)",
//     backgroundColor: "rgb(244, 246, 248)",
//     transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
//   },
//   "& .placeholderImageProfile": {
//     display: "none",
//     color: "rgb(255, 255, 255)",
//     backgroundColor: "rgba(22, 28, 36, .72)",
//   },
//   "& .placeholderLabel": {
//     color: "rgb(255, 255, 255)",
//   },
//   "& .wrapCaptionImage": {
//     marginTop: 16,
//     textAlign: "center",
//   },
//   "& .captionImage": {
//     color: "#212b36",
//   },
// });

const StyledDivider = styled(Divider)({
  marginTop: 20,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const Profile = (props) => {
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  // const [fileSelected, setFileSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [showPersonalId, setShowPersonalId] = useState(false);
  const { t, i18n } = useTranslation();

  // useEffect(
  //   () => () => {
  //     // Make sure to revoke the data uris to avoid memory leaks
  //     fileSelected.forEach((file) => URL.revokeObjectURL(file.preview));
  //   },
  //   [fileSelected]
  // );

  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: "image/jpeg, image/png",
  //   noClick: true,
  //   noKeyboard: true,
  //   onDrop: (acceptedFiles) => {
  //     let formData = new FormData();
  //     acceptedFiles.map((file) => formData.append("file", file));
  //     setFileSelected(
  //       acceptedFiles.map((file) =>
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       )
  //     );
  //   },
  //   maxFiles: 1,
  // });

  // const thumbs = (row) => {
  //   if (fileSelected.length === 0) {
  //     return (
  //       <img
  //         src={`${process.env.REACT_APP_API_URL}image/vendor/${row.idVendor}/${row.personalId}.jpg`}
  //         className={`uploadImage`}
  //       />
  //     );
  //   } else {
  //     return (
  //       <img
  //         alt={fileSelected[0].name}
  //         src={fileSelected[0].preview}
  //         className={`uploadImage`}
  //       />
  //     );
  //   }
  // };

  const handleClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div style={{ padding: 24 }}>
      <div>
        <AvatarEmployee />
        <StyledInformation>
          <Typography align="center" variant="subtitle1">
            {employeeProfile.employeeTypeEmployeeId}
          </Typography>
          <Typography align="center" variant="subtitle1">
            {employeeProfile.positionsName}
          </Typography>
          <Typography align="center" variant="subtitle1" gutterBottom>
            {employeeProfile.telephoneMobile
              ? `${employeeProfile.telephoneMobile.replace(
                  /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                  "$1-$2-$3"
                )}`
              : "-"}
          </Typography>
          <Typography
            align="center"
            color="text.secondary"
            className="started"
            gutterBottom
          >{`${t("StartWork")} ${dayjs(employeeProfile.hiringDate)
            .locale(localStorage.getItem("language") || "th")
            .format(
              localStorage.getItem("language") === "th"
                ? "DD MMMM BBBB"
                : "DD MMMM YYYY"
            )}`}</Typography>
          <StyledDivider style={{ margin: "24px 0" }} />
          <div>
            <StyledWrapTop>
              <div>
                <ButtonBlue
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={handleClick}
                >
                  {t("Edit")}
                </ButtonBlue>
              </div>
            </StyledWrapTop>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div>
                  <StyledHeadLabel color="text.secondary" align="center">
                    {t("FullName")}
                  </StyledHeadLabel>
                  <StyledTextValue variant="h6" align="center">
                    {`${employeeProfile.title} ${employeeProfile.firstname} ${
                      employeeProfile.lastname
                    } ${
                      employeeProfile.nickname
                        ? `(${employeeProfile.nickname})`
                        : ``
                    }`}
                  </StyledTextValue>
                  <StyledTextValue variant="h6" align="center">
                    {
                      (employeeProfile.firstname_en && employeeProfile.lastname_en) && 
                      `${employeeProfile.firstname_en} ${employeeProfile.lastname_en}`
                    }
                  </StyledTextValue>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <StyledHeadLabel color="text.secondary" align="center">
                    {t("BirthDate")}
                  </StyledHeadLabel>
                  <StyledTextValue variant="h6" align="center">
                    {employeeProfile.birthday ? dayjs(employeeProfile.birthday)
                      .locale(localStorage.getItem("language") || "th")
                      .format(
                        localStorage.getItem("language") === "th"
                          ? "DD MMMM BBBB"
                          : "DD MMMM YYYY"
                      ) : "ไม่มีข้อมูล"}
                  </StyledTextValue>
                  <Typography align="center" color="text.third">{`(${t(
                    "Age"
                  )} ${utils.getAge(
                    dayjs(employeeProfile.birthday).format("DD/MM/YYYY")
                  )})`}</Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <StyledHeadLabel color="text.secondary" align="center">
                    {t("IDCardNumber")}
                  </StyledHeadLabel>
                  <div
                    className="wrap-show-hide-personalId"
                    onMouseEnter={() => setShowPersonalId(true)}
                    onMouseLeave={() => setShowPersonalId(false)}
                  >
                    {showPersonalId ? (
                      <StyledTextValue variant="h6" align="center">
                        {employeeProfile.personalId.replace(
                          /(\d)(\d\d\d\d)(\d\d\d\d\d)(\d\d)(\d)/,
                          "$1 $2 $3 $4 $5"
                        )}
                      </StyledTextValue>
                    ) : (
                      <StyledTextValue
                        className="dot"
                        variant="h6"
                        align="center"
                      >
                        {[...Array(13).keys()].map((_, index) => (
                          <i
                            key={index}
                            className="fa-sharp fa-solid fa-circle"
                          ></i>
                        ))}
                      </StyledTextValue>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </StyledInformation>
      </div>
      <DialogEdit open={openDialog} handleCloseDialog={handleCloseDialog} />
    </div>
  );
};

export default Profile;
