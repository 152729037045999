import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { useTranslation } from "react-i18next";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import ButtonBlue from "../../shared/general/ButtonBlue";
import TableRequest from "../../shared/requestList/tableRequest";
import DialogConfirmWithdraw from "../../shared/general/DialogConfirmWithdraw";
import AlertResponse from "../../shared/general/AlertResponse";
import StatusRequest from "../../shared/requestList/stausRequest";

import { getAllRequestTimeBy } from "../../../../actions/requestTime";
import {
  withdrawLeaveEmployee,
  getAllLeaveWithDrawBy,
} from "../../../../actions/leave";
import { getLeaveRequest } from "../../../../actions/employee";
import { withdrawRequestTime } from "../../../../actions/requestTime";
import { getPayrollSetting } from "../../../../actions/paytypes";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import DialogCommentManager from "../../shared/requestList/dialogCommentManager";

const StyledCard = styled(Card)({
  padding: 16,
  marginTop: 24,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "&.filter": {
    marginBottom: 32,
  },
});

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledPaper = styled(Paper)({
  border: "none",
  borderRadius: 16,
  paddingBottom: 16,
  "& .inner-info": {
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      margin: "0 8px 0 0!important",
    },
  },
});

function Requestlist() {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: requestTimeList } = useSelector((state) => state.requestTime);

  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const [filterRequestType, setFilterRequestType] = React.useState("all");
  const [dataWithDraw, setDataWithDraw] = React.useState(false);
  const [openConfirmWithDraw, setOpenConfirmWithDraw] = React.useState(false);
  const [search, setSearch] = React.useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const [tabTable, setTabTable] = React.useState("1");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [payrollSetting, setPayrollSetting] = useState(null);
  const [openDialogCommentManager, setOpenDialogCommentManager] = useState({
    open: false,
    comment: "",
  });

  const handleClickSearch = () => {
    // let hours = dayjs(search.end).diff(dayjs(search.start), "hours");
    // const days = Math.floor(hours / 24);
    // var result = [];
    // for (let index = 0; index < days + 2; index++) {
    //   result.push({
    //     dateTimeline: dayjs(search.start).add(index, "day"),
    //   });
    // }

    dispatch(getLeaveRequest("id", search));
    dispatch(getAllRequestTimeBy("id", search));
    dispatch(getAllLeaveWithDrawBy("id", search));
    dispatch(getPayrollSetting());
  };

  useEffect(() => {
    dispatch(getLeaveRequest("id", search));
    dispatch(getAllRequestTimeBy("id", search));
    dispatch(getAllLeaveWithDrawBy("id", search));
    dispatch(getPayrollSetting());
  }, []);

  useEffect(() => {
    if (userProfile && payrollSettingList) {
      const findPayroll = payrollSettingList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      setPayrollSetting(findPayroll ? findPayroll : payrollSettingList[0]);
    }
  }, [userProfile, payrollSettingList]);

  const handleClickWithDraw = (data) => {
    setDataWithDraw(data);
    setOpenConfirmWithDraw(true);
    setTabTable(data.idLeave ? 2 : 1);
  };

  const handleClickCloseDialog = () => {
    setOpenConfirmWithDraw(false);
  };

  const handleConfirmWithdraw = async () => {
    console.log(dataWithDraw);
    // if (dataWithDraw.isApprove === null) {
    //   const result = await dispatch(withdrawLeaveEmployee(null, dataWithDraw));
    // }
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleDeleteRequestTime = async () => {
    let formData = {
      idRequestTime: dataWithDraw.idRequestTime,
      managerApprove: null,
      isApprove: null,
    };

    //ยังไม่ได้ Approve
    if (
      dataWithDraw.isManagerLV1Approve === null &&
      dataWithDraw.isManagerLV1Approve === null
    ) {
      formData.isApprove = null;
    } else {
      // Approve แล้ว
      if (dataWithDraw.isDoubleApproval) {
        if (dataWithDraw.isManagerLV2Approve) {
          formData.managerApprove = dataWithDraw.managerLV2Id;
          formData.isApprove = true;
        } else {
          formData.managerApprove = dataWithDraw.managerLV1Id;
          formData.isApprove = true;
        }
      } else {
        if (dataWithDraw.approvalLevel === 1) {
          formData.managerApprove = dataWithDraw.managerLV1Id;
          formData.isApprove = true;
        } else {
          formData.managerApprove = dataWithDraw.managerLV2Id;
          formData.isApprove = true;
        }
      }
    }

    const result = await dispatch(withdrawRequestTime(formData));
    if (result && result.status === 200) {
      handleClickCloseDialog();
      handleChangeAlertType("success");
      handleOpenAlert();
      dispatch(getAllRequestTimeBy("id", search));
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  const handleDeleteLeave = async () => {
    const formData = {
      idLeave: dataWithDraw.idLeave,
      isApprove: dataWithDraw.isApprove,
      managerApprove: dataWithDraw.idManagerEmployee,
    };

    const result = await dispatch(withdrawLeaveEmployee(null, formData));
    if (result && result.status === 200) {
      handleClickCloseDialog();
      handleChangeAlertType("success");
      handleOpenAlert();
      dispatch(getLeaveRequest("id", search));
      dispatch(getAllLeaveWithDrawBy("id", search));
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  const handleChangeFilterRequestType = (event) => {
    setFilterRequestType(event.target.value);
  };

  const renderData = () => {
    let temp = [
      ...requestTimeList,
      ...leaveEmployeesList,
      ...leaveEmployeeWithdrawsList,
    ];

    switch (filterRequestType) {
      case "1":
        temp = requestTimeList.filter(
          (x) => x.idRequestType && x.idRequestType === 1
        );
        break;
      case "2":
        temp = requestTimeList.filter(
          (x) =>
            (x.idRequestType && x.idRequestType === 2) ||
            (x.idRequestType && x.idRequestType === 3)
        );
        break;
      case "leave":
        temp = leaveEmployeesList.filter((x) => x.idLeave && x.idLeave > 0);
        break;
      default:
        break;
    }

    temp.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });

    return temp;
  };

  const handleOpenDialogCommentManager = (comment) => {
    setOpenDialogCommentManager({ open: true, comment: comment });
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {t("RequestList")}
        </Typography>
        <div style={{ marginTop: 16, paddingBottom: 24 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  label={t("StartDate")}
                  value={search.start}
                  onChange={(newValue) => {
                    setSearch({ ...search, ["start"]: newValue });
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme style={{ width: "100%" }} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <DatePicker
                  label={t("EndDate")}
                  minDate={search.start}
                  value={search.end}
                  onChange={(newValue) => {
                    setSearch({ ...search, ["end"]: newValue });
                  }}
                  renderInput={(params) => (
                    <TextFieldTheme style={{ width: "100%" }} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3}>
              <ButtonBlue variant={"contained"} onClick={handleClickSearch}>
                {t("Search")}
              </ButtonBlue>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  {t("RequestType")}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={filterRequestType}
                  onChange={handleChangeFilterRequestType}
                >
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label={t("All")}
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={t("WorkTimeRequest")}
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label={t("OvertimeRequest")}
                  />
                  <FormControlLabel
                    value="leave"
                    control={<Radio />}
                    label={t("EmpLeaveRequest")}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <StyledCard>
            <StyledPaper variant="outlined">
              <Typography
                gutterBottom
                color="text.third"
                style={{ fontWeight: 500, fontSize: 14 }}
              >
                {t("StatusRequest")}
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={2}>
                  <div className="inner-info">
                    <StatusRequest
                      boxSize={30}
                      fontSize={18}
                      borderRadius={8}
                      status={1}
                      active={1}
                    />
                    <Typography>{t("Approve")}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <div className="inner-info">
                    <StatusRequest
                      boxSize={30}
                      fontSize={18}
                      borderRadius={8}
                      status={0}
                      active={1}
                    />
                    <Typography>{t("Disapproval")}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <div className="inner-info">
                    <StatusRequest
                      boxSize={30}
                      fontSize={18}
                      borderRadius={8}
                      status={null}
                      step={null}
                      active={0}
                    />
                    <Typography>{t("CancelRequest")}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <div className="inner-info">
                    <StatusRequest
                      boxSize={30}
                      fontSize={18}
                      borderRadius={8}
                      status={null}
                      step={null}
                      active={1}
                    />
                    <Typography>{t("WaitingApproval")}</Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <div className="inner-info">
                    <StatusRequest
                      boxSize={30}
                      fontSize={18}
                      borderRadius={8}
                      status={null}
                      step={1}
                      active={1}
                    />
                    <Typography style={{ fontSize: 14 }}>
                      {t("WaitingForFirstApprover")}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </StyledPaper>
            {requestTimeList &&
              leaveEmployeesList &&
              leaveEmployeeWithdrawsList && (
                <TableRequest
                  handleClickWithDraw={handleClickWithDraw}
                  list={renderData()}
                  payrollSetting={payrollSetting}
                  handleOpenDialogCommentManager={
                    handleOpenDialogCommentManager
                  }
                />
              )}
          </StyledCard>
        </div>
      </Container>
      {openDialogCommentManager.open && (
        <DialogCommentManager
          open={openDialogCommentManager.open}
          onClose={() =>
            setOpenDialogCommentManager({ open: false, comment: "" })
          }
          comment={openDialogCommentManager.comment}
        />
      )}
      <DialogConfirmWithdraw
        mode={tabTable}
        open={openConfirmWithDraw}
        data={dataWithDraw}
        handleClose={handleClickCloseDialog}
        handleDeleteRequestTime={handleDeleteRequestTime}
        handleDeleteLeave={handleDeleteLeave}
      />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
}

export default Requestlist;
