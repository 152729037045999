import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import ButtonBlue from "../../../shared/general/ButtonBlue";


const StyledButtonCheck = styled(LoadingButton)({
    "&.check-in-btn": {
        color: "#fff",
        width: "100%",
        height: "50px",
        backgroundColor: "#66bb6a",
        border: "1px solid #66bb6a",
        "&:hover": {
            borderColor: "#4caf50",
            backgroundColor: "#4caf50",
        },
    },
    "&.check-out-btn": {
        color: "#fff",
        width: "100%",
        height: "50px",
        backgroundColor: "#ef5350",
        border: "1px solid #ef5350",
        "&:hover": {
            borderColor: "#e53935",
            backgroundColor: "#e53935",
        },
    },
    "&.loadMap": {
        width: "100%",
        height: "50px",
    }
});

const SelectedGroupLocations = (props) => {
    const {
        userProfile,
        mode,
        handleCancel,
        handleChangeClickCheck,
        loadMap,
    } = props;

    return (
        <div>
            {userProfile && (
                <div>
                    <div>
                        {mode === "in" ? (
                            <StyledButtonCheck
                                variant="outlined"
                                className={`${loadMap ? "loadMap" : "check-in-btn"}`}
                                startIcon={<LoginIcon />}
                                onClick={handleChangeClickCheck}
                                loading={loadMap}
                                loadingPosition="start"
                            >
                                {`${loadMap ? "ดึงตำแหน่งปัจจุบัน" : "ยืนยันลงเวลาเข้างาน"}`}
                            </StyledButtonCheck>
                        ) : (
                            <StyledButtonCheck
                                variant="outlined"
                                className={`${loadMap ? "loadMap" : "check-out-btn"}`}
                                startIcon={<LogoutIcon />}
                                onClick={handleChangeClickCheck}
                                loading={loadMap}
                                loadingPosition="start"
                            >
                                {`${loadMap ? "ดึงตำแหน่งปัจจุบัน" : "ยืนยันลงเวลาออกงาน"}`}
                            </StyledButtonCheck>
                        )}
                    </div>
                    <div
                        style={{ marginTop: 16, display: "flex", justifyContent: "center" }}
                    >
                        <ButtonBlue onClick={handleCancel}>ยกเลิก</ButtonBlue>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SelectedGroupLocations;
