import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import 'dayjs/locale/th';
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import {
    Table, TableBody, TableCell,
    TableContainer, TableHead, TablePagination,
    TableRow, Box, Typography, Container,
    Breadcrumbs, TextField, Grid,
    Popper, Avatar, InputAdornment, Stack
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import SearchIcon from "@mui/icons-material/Search";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import { useTranslation } from "react-i18next";
import { LeaveListXlsx } from "./xlsx-export/leave-list-xlsx";
import { getLeaveDetails } from "../../../../actions/report";
import { getAllCompaniesByVendor } from "../../../../actions/vendor";
import { SelectAllCompaniesVendor } from "./SelectCompanyLists";
import DatePickerCustom from "../../shared/date/datePicker";
import AlertResponse from "../../shared/general/AlertResponse";

const StyledRoot = styled(Box)({
    backgroundColor: "#FFFFFF !important",
    "& .search-filter-container": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .filter-wrap": {},
        "& .action-container": {},
    },
});

const StyledBox = styled(Box)({
    "&.open": {
        paddingLeft: 16,
        width: "calc(100% - 1000px)",
    },
    "&.close": {
        paddingLeft: 0,
        width: "100%",
    },
});

const StyledCellHeader = styled(TableCell)({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    whiteSpace: "nowrap",
    "& .MuiTypography-root": {
        color: "#637381",
        backgroundColor: "#f4f6f8",
        "&.weekend": {
            fontStyle: "oblique",
        },
        "&.workday": {
            fontWeight: 600,
        },
    },
    "&.sticky": {
        padding: 0,
        position: "sticky",
        left: 0,
        zIndex: 4,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        boxShadow: "20px 0px 20px #EEEEEE",
        "& .MuiTableCell-root": {
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
        },
    },
    "&:last-of-type": {
        paddingRight: 24,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
});

const StyledRowContent = styled(TableRow)({
    cursor: "pointer",
    textDecoration: "none",
    "&.MuiTableRow-hover:hover": {
        backgroundColor: "#f7f7f7",
        "& .sticky": {
            backgroundColor: "#f7f7f7",
        },
    },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
    padding: 8,
    whiteSpace: "nowrap",
    "&.sticky": {
        position: "sticky",
        left: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "8px 20px 20px #EEEEEE",
    },
    "& .avatar": {
        minWidth: 240,
        "& .MuiAvatar-root": {
            marginRight: 8,
        },
        display: "flex",
        alignItems: "center",
    },
    "& .fullname": {
        fontWeight: 600,
    },
    "& .position": {
        color: theme.palette.text.secondary,
        fontSize: 14,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: 300,
    },
    "& .secondaryAction": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .location": {
            fontWeight: 600,
        },
    },
    "&:first-of-type": {
        paddingLeft: 0,
    },
    "&:last-of-type": {
        paddingRight: 24,
    },
}));

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const QuotaLeaveList = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { open, handleClose } = props;
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [leaveList, setLeaveList] = useState(null);
    const [leaveType, setLeaveType] = useState(null);
    const [inputSearch, setInputSearch] = useState(new Date());
    const [companyLists, setCompanyLists] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const fetchedCompanyByVendor = async () => {
        try {
            const response = await getAllCompaniesByVendor();
            if (response && response.data) {
                setCompanyLists(response.data);
            } else {
                setCompanyLists(null);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchedCompanyByVendor();
    }, []);

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const fetchData = async () => {
        try {
            setIsFetching(true);
            const response = await getLeaveDetails({
                idCompany: selectedCompany && selectedCompany.idCompany,
                inputSearch: dayjs(inputSearch).locale('th').format("YYYY")
            });

            if (response && response.data) {
                setIsFetching(false);
                setLeaveList(response.data.LeaveEmployees ? response.data.LeaveEmployees : null);
                setLeaveType(response.data.leaveType ? response.data.leaveType : null);
            } else {
                handleChangeAlertType(`${t("NoData")}`);
                handleOpenAlert(true);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSubmit = () => {
        if (leaveList && leaveList.length > 0) {
            LeaveListXlsx(leaveList, leaveType);
        } else {
            handleChangeAlertType(`${t("NoData")}`);
            handleOpenAlert(true);
        }
    };

    useEffect(() => {
        if (selectedCompany && inputSearch) {
            fetchData();
        }
    }, [selectedCompany, inputSearch]);

    const LeaveTypeColumns = leaveType && leaveType.flatMap(i => ([
        {
            id: `${i.idLeaveType}_quota`,
            label: `โควต้า ${i.name}`,
            minWidth: 170
        },
        {
            id: `${i.idLeaveType}_used`,
            label: `${i.name} ใช้ไป`,
            minWidth: 170
        },
        {
            id: `${i.idLeaveType}_remaining`,
            label: `${i.name} คงเหลือ`,
            minWidth: 170
        },
    ]));

    const columns = [
        { id: "JopPosition", label: "ตำแหน่ง", minWidth: 170 },
        { id: "Company", label: "บริษัท", minWidth: 170 },
        { id: "Division", label: "ฝ่าย", minWidth: 170 },
        { id: "Section", label: "ส่วน", minWidth: 100 },
        { id: "Department", label: "แผนก", minWidth: 170 },
        ...(LeaveTypeColumns || [])
    ];

    useEffect(() => {
        if (companyLists && companyLists.length > 0) {
            setSelectedCompany(companyLists[0])
        }
    }, [companyLists]);

    return (
        <StyledRoot className="page">
            <Container maxWidth="lg">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/report-center"}
                    >
                        {`${t("AllReports")}`}
                    </Link>
                    <Typography color="text.primary">{t("QuotaOnLeave")}</Typography>
                </Breadcrumbs>

                <Typography variant="h4" sx={{ mt: 3 }}>
                    {t("QuotaOnLeave")}
                </Typography>

                <Box sx={{ mt: 4 }}>
                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                    >
                        <Grid item xs={6} md={3}>
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                    {t("SelectYear")}
                                </Typography>
                                <div className="search-date">
                                    <DatePickerCustom
                                        maxDate={dayjs()}
                                        inputFormat="YYYY"
                                        value={inputSearch}
                                        name="start"
                                        views={['year']}
                                        openTo={'year'}
                                        onChange={(newValue) => {
                                            setInputSearch(newValue);
                                        }}
                                    />
                                </div>
                            </StyledBoxSearch>
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                    {`${t("Company")}`}
                                </Typography>
                                <div className="search-name">
                                    <SelectAllCompaniesVendor
                                        options={companyLists ? companyLists : null}
                                        value={selectedCompany}
                                        disabled={isFetching}
                                        onChange={(_, value) => {
                                            onChangeCompany(value);
                                        }}
                                    />
                                </div>
                            </StyledBoxSearch>
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <StyledBoxSearch>
                                <Typography className="label" color="text.third">
                                    {`${t("FullName")}`}
                                </Typography>
                                <div className="search-name">
                                    <TextFieldTheme
                                        style={{
                                            background: "#fff",
                                            borderRadius: "10px",
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="center">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            placeholder: `${t("Search")}`,
                                        }}
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </div>
                            </StyledBoxSearch>
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <StyledBoxSearch>
                                <div className="search-name">
                                    <ButtonBlue
                                        variant="contained"
                                        startIcon={<DownloadRoundedIcon />}
                                        onClick={() => handleSubmit()}
                                        disabled={isFetching}
                                        sx={{ mt: 3 }}
                                    >
                                        {`${t("Download")}`}
                                    </ButtonBlue>
                                </div>
                            </StyledBoxSearch>
                        </Grid>
                    </Grid>
                </Box>

                <CardStyle style={{ marginTop: "3vh" }}>
                    <Box style={{ padding: "24px" }}>
                        {isFetching ? (
                            <Typography textAlign="center" color="text.secondary" fontSize="24px">
                                {t(`LoadingData`)}...
                            </Typography>
                        ) : (
                            <Box style={{ display: "flex", position: "relative" }}>
                                <StyledBox className={"close"}>
                                    <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledCellHeader
                                                        align={"center"}
                                                        className="sticky"
                                                        style={{ minWidth: 240 }}
                                                    >
                                                        {t("FullName")}
                                                    </StyledCellHeader>

                                                    {columns.map((column) => (
                                                        <StyledCellHeader key={column.id}>
                                                            {column.label}
                                                        </StyledCellHeader>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <Fragment>
                                                    {leaveList && leaveList.length > 0 ? (
                                                        leaveList.slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage + rowsPerPage
                                                        ).filter((row) => {
                                                            const fullname = `${row.firstname + " " + row.lastname}`.toLowerCase();
                                                            return fullname.includes(
                                                                searchText.toLowerCase()
                                                            );
                                                        }).map((row, index) => (
                                                            <StyledRowContent hover key={index}>
                                                                <StyledCellContent
                                                                    className="sticky"
                                                                    style={{
                                                                        borderBottom:
                                                                            "1px dashed rgba(224, 224, 224, 1)",
                                                                    }}
                                                                >
                                                                    <div className="avatar">
                                                                        <Avatar src={`${row.imageProfile}`} />
                                                                        <div>
                                                                            <Typography className="fullname">
                                                                                {row.firstname + " " + row.lastname}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>{row.positionsName ? row.positionsName : "-"}</Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>{row.companyName ? row.companyName : "-"}</Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>{row.divisionName ? row.divisionName : "-"}</Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>{row.sectionName ? row.sectionName : "-"}</Typography>
                                                                </StyledCellContent>

                                                                <StyledCellContent>
                                                                    <Typography>{row.departmentName ? row.departmentName : "-"}</Typography>
                                                                </StyledCellContent>

                                                                {leaveType && leaveType.map((leave, idx) => {
                                                                    const matchedLeave = row.leaves && row.leaves.length > 0 && row.leaves.find(l => l.idLeaveType === leave.idLeaveType);
                                                                    const used = matchedLeave ? (Number.isInteger(matchedLeave.used) ? matchedLeave.used : matchedLeave.used.toFixed(2)) : '-';
                                                                    const remaining = matchedLeave && typeof matchedLeave.remaining === 'number' ? matchedLeave.remaining.toFixed(2) : '-';
                                                                    const quota = matchedLeave ? matchedLeave.quota : '-';

                                                                    const usedColumnId = `${leave.idLeaveType}_used`;
                                                                    const remainingColumnId = `${leave.idLeaveType}_remaining`;
                                                                    const quotaColumnId = `${leave.idLeaveType}_quota`;

                                                                    const usedColumn = columns.find(col => col.id === usedColumnId);
                                                                    const remainingColumn = columns.find(col => col.id === remainingColumnId);
                                                                    const quotaColumn = columns.find(col => col.id === quotaColumnId);

                                                                    return (
                                                                        <React.Fragment key={idx}>
                                                                            <StyledCellContent>
                                                                                <Typography style={{ textAlign: "center" }}>{quotaColumn && <span>{quota}</span>}</Typography>
                                                                            </StyledCellContent>
                                                                            <StyledCellContent>
                                                                                <Typography style={{ textAlign: "center" }}>{usedColumn && <span>{used}</span>}</Typography>
                                                                            </StyledCellContent>
                                                                            <StyledCellContent>
                                                                                <Typography style={{ textAlign: "center" }}>{remainingColumn && <span>{remaining}</span>}</Typography>
                                                                            </StyledCellContent>
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </StyledRowContent>
                                                        ))
                                                    ) : (
                                                        <StyledRowContent>
                                                            <StyledCellContent
                                                                colSpan={5}
                                                                style={{ height: 150 }}
                                                            >
                                                                <Typography align="center">
                                                                    {t("NoData")}
                                                                </Typography>
                                                            </StyledCellContent>
                                                        </StyledRowContent>
                                                    )}
                                                </Fragment>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={leaveList ? leaveList.length : 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </StyledBox>
                            </Box>
                        )}
                    </Box>
                </CardStyle>
            </Container>
            <AlertResponse
                open={openAlert}
                handleClose={handleCloseAlert}
                alertType={alertType}
            />
        </StyledRoot>
    );
};

export default QuotaLeaveList;
