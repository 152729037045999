import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Divider,
  TextField,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";

import ButtonBlue from "../../../shared/general/ButtonBlue";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5),
    width: "95%",
    maxHeight: 800,
  },
  dialogTitle: {
    color: "#16243D",
    padding: theme.spacing(1),
  },
  dialogContent: {
    padding: "16px 8px 24px 8px",
  },
  dialogActions: {
    marginTop: theme.spacing(1),
  },
  headInput: {
    color: "#616161",
  },
}));

const initialValues = () => ({
  id: 0,
  type: "",
  title: "",
  start: null,
  end: null,
  color: "#ffc107",
});

function AddEvent(props) {
  const { addModal, setAddModal, onPostHoliday, fetchData } = props;
  const [values, setValues] = useState(initialValues);
  const classes = useStyles();
  const [errors, setErrors] = useState({
    type: false,
    title: false,
    start: false,
    end: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (values.type && values.title && values.start) {
      const formData = {
        name: values.title,
        dateHoliday: dayjs(values.start).format("YYYY-MM-DD"),
        idCompany: "1",
        holidayYear: dayjs(values.start).format("YYYY"),
      };

      //console.log("values", values.start);
      //console.log(dayjs(values.start).format("YYYY-MM-DD"));

      //Add new Holiday
      onPostHoliday(formData);
      setAddModal({ ...addModal, isOpen: false });
      fetchData();
    } else {
      setErrors({
        type: values.type ? false : true,
        title: values.title ? false : true,
      });
    }
  };

  useEffect(() => {
    if (addModal) {
      setValues(initialValues);
    }
  }, [addModal]);

  return (
    <Dialog open={addModal.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              Add Event
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={() => setAddModal({ ...addModal, isOpen: false })}
            >
              <CloseIcon className={classes.closeBtn} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              Type
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <FormControl margin="dense" error={errors.type} fullWidth>
              <InputLabel>Select Type</InputLabel>
              <Select
                name="type"
                value={values.type}
                onChange={handleInputChange}
                input={<OutlinedInput label="Select Type" />}
                displayEmpty
              >
                <MenuItem value="holiday">วันหยุดนักขัตฤกษ์</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              Title
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <TextField
              variant="outlined"
              name="title"
              value={values.title}
              onChange={handleInputChange}
              margin="dense"
              error={errors.title}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              Start
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Select Date"
                name="start"
                inputFormat="dd/MM/yyyy"
                value={values.start}
                onChange={(date) => {
                  setValues({
                    ...values,
                    start: date,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder="DD/MM/YYYY"
                    format="dd/MM/yyyy"
                    margin="dense"
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant="body1" className={classes.headInput}>
              End
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Select Date"
                name="end"
                inputFormat="dd/MM/yyyy"
                value={values.end}
                onChange={(date) => {
                  setValues({
                    ...values,
                    end: date,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder="DD/MM/YYYY"
                    format="dd/MM/yyyy"
                    margin="dense"
                    error={errors.end}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<SaveIcon />}
              onClick={handleOnSubmit}
            >
              บันทึก
            </ButtonBlue>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AddEvent;
