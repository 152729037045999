import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Container,
  Tab,
  Tabs,
  Table,
  TablePagination,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Box,
  CardContent,
  Button,
  Chip,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";

import ListAltIcon from "@mui/icons-material/ListAlt";
import CardStyle from "../../shared/general/Card";
import HistoryIcon from "@mui/icons-material/History";

import { getAllExpenseByIdCompany } from "../../../../actions/expense";
import dayjs from "dayjs";
import utils from "../../../../utils";

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    textAlign: "center",
    "&.nothing": {
      padding: 32,
    },
  },
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "& .cellAction": {
    width: 48,
    padding: "0px 0px 0px 4px",
  },
  "& .tableCellHead-document-no": {
    width: 140,
  },
  "& .tableCellHead-document-name": {
    textAlign: "center",
    //width: 250,
  },
  "& .tableCellHead-document-amount": {
    width: 120,
  },
  "& .tableCellHead-document-status": {
    width: 120,
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  "& .tableCellBody-document-name": {
    textAlign: "left",
  },
  "& .tableCellBody-center": {
    textAlign: "center",
  },

  "& .tableCellWeekend": {
    color: "#959595",
    fontStyle: "oblique",
  },
  "& .cellStatusAction": {
    textAlign: "left",
    "& .wrap-status": {
      display: "flex",
      "& .item": {
        marginRight: 4,
      },
    },
  },
  "& .cellRequest": {},
  "& .cellStartStop": {
    minWidth: 80,
    "& .MuiButtonBase-root": {
      marginRight: 2,
    },
  },
  "& .rowLink": {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(243, 246, 248, 0.5)",
    },
  },
});

const DetailButton = styled(Button)(({ variant, theme }) => ({
  color: "#007AFE",
  backgroundColor: "transparent",
  border: "solid",
  borderColor: "#007AFE",
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 18,
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#007AFE",
  },
}));

const SubTableWithButton = (props) => {
  const { t, i18n } = useTranslation();
  const {
    data,
    textButton,
    iconButton,
    handleClick,
    dialog,
    dialogTitle,
    dialogDescription,
    ...other
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <TableContainer>
        <StyledTable aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              >
                {t("DocumentId")}
              </TableCell>
              <TableCell className={`tableCellHead tableCellHead-document-no`}>
                {t("FullName")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-name`}
              >
                cost center
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-amount`}
              >
                {t("Title")}
              </TableCell>

              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              >
                {t("Amount")}
              </TableCell>
              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              >
                {t("lastUpdateDate")}
              </TableCell>

              <TableCell
                className={`tableCellHead tableCellHead-document-status`}
              ></TableCell>
            </TableRow>
          </TableHead>

          {data.length > 0 ? (
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row) => (
                <TableRow key={row.idExpense}>
                  <TableCell className="tableCellBody-center">
                    {row.documentId}
                    <br></br>
                    <Typography variant="body" color="text.secondary">
                      [{dayjs(row.createDate).format("DD-MM-BBBB")}]
                    </Typography>
                  </TableCell>
                  <TableCell className="tableCellBody-center">
                    {row.firstname} {row.lastname}
                  </TableCell>
                  <TableCell className="tableCellBody-center">
                    {row.costCenter}
                  </TableCell>
                  <TableCell className="tableCellBody-center">
                    {row.expenseName}
                  </TableCell>
                  <TableCell>
                    {utils.numberWithCommas(parseFloat(row.net))} {t("Baht")}
                  </TableCell>
                  <TableCell className="tableCellBody-center">
                    {dayjs(row.lastUpdateDate).format("DD/MM/BBBB")}
                  </TableCell>
                  <TableCell>
                    <DetailButton
                      startIcon={<ListAltIcon />}
                      onClick={() => {
                        handleClick(row.idExpense, row.expenseType, row.idEmp);
                      }}
                    >
                      {t("Details")}
                    </DetailButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell className="nothing" colSpan={7}>
                {t("No_Data")}
              </TableCell>
            </TableBody>
          )}
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[
          10,
          20,
          30,
          40,
          50,
          { label: "ทั้งหมด", value: -1 },
        ]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("RowPerPage")}
      />
    </div>
  );
};

export default SubTableWithButton;
