import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  Box,
  Card,
  CardContent, Checkbox,
  FormControlLabel, Grid, IconButton, Typography
} from "@mui/material";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import th from "date-fns/locale/th";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Utils from "../../../../utils";
import ButtonOrange from "../../shared/general/ButtonOrange";
import CardSummary from "../../shared/homepage/cardSummary";

import ChartsLeave from "./chartsLeave";
import ChartsOT36HoursWeek from "./chartsOT36HoursWeek";
import ChartsOTAllMonth from "./chartsOTAllMonth";
import ChartsOTTypeWeek from "./chartsOTTypeWeek";
import TableRequest from "./tableRequest";

import { getEventsByMonth } from "../../../../actions/event";

const locales = {
  "th-TH": th,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const useStyles = makeStyles((theme) => ({
  calendarOptions: {
    display: "flex",
    flexDirection: "column",
  },
  wrapOptions: {
    display: "flex",
    alignItems: "center",
    "& i": {
      color: "#d0d0d0",
    },
    
  },
}));

const StyledRoot = styled("div")({
  minWidth: 350,
    width: "100%",
    backgroundColor: "#f1f4f9",
    paddingBottom: 36,
    // ["@media (min-width:1200px)"]:{
    //   paddingLeft: 24,
    //   paddingRight: 24
    // }
});

const StyledCard = styled(Card)({
  marginTop: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});

const StyledFormControlLabel = styled(FormControlLabel)({
  "& .option-holiday": {
    color: "#7451ff !important",
  },
  "& .option-ot": {
    color: "#1e4db6 !importan",
  },
  "& .option-invalidTime": {
    color: "#fdc90c !important",
  },
  "& .option-absent": {
    color: "#fc4b6c !important",
  },
  "& .option-late": {
    color: "#1997f5 !important",
  },
  "& .option-leave": {
    color: "#38ca7f !important",
  },
});

const StyledToolbarCalendar = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .todayBtn": {
    borderColor: "#007afe",
    color: "#ffffff",
    backgroundColor: "#007afe",
  },
  "& .prevNextBtn": {
    marginRight: 4,
    marginLeft: 4,
  },
  "& .toolbarCalendarLabel": {
    fontSize: 24,
  },
});

const StyledCalendar = styled(Calendar)({
  height: "500px !important",
  "& .holidayEvent": {
    backgroundColor: "#7451ff !important",
    color: "#FFFFFF !important",
  },
  "& .otEvent": {
    backgroundColor: "#1e4db6 !important",
    color: "#FFFFFF !important",
  },
  "& .invalidTimeEvent": {
    backgroundColor: "#fdc90c !important",
    color: "#FFFFFF !important",
  },
  "& .absentEvent": {
    backgroundColor: "#fc4b6c !important",
    color: "#FFFFFF !important",
  },
  "& .lateEvent": {
    backgroundColor: "#1997f5 !important",
    color: "#FFFFFF !important",
  },
  "& .leaveEvent": {
    backgroundColor: "#38ca7f !important",
    color: "#FFFFFF !important",
  },
});

const StyledInfoCalendarHeader = styled("div")({
  display: "flex",
  alignItems: "baseline",
  color: "#999999",
  fontSize: 20,
  marginBottom: 16,
  "& i": {
    marginRight: 4,
  },
  "& .MuiTypography-root": {
    fontSize: 20,
  },
});

const rand1000 = () => {
  return Math.floor(Math.random() * 5000) + 1000;
};

function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const [options, setOptions] = React.useState({
    holiday: true,
    ot: true,
    invalidTime: true,
    absent: true,
    late: true,
    leave: true,
  });
  const [optionsFilter, setOptionsFilter] = useState([
    "holiday",
    "ot",
    "invalidTime",
    "absent",
    "late",
    "leave",
  ]);

  const { t, i18n } = useTranslation();
  const [events, setEvents] = useState([]);

  const handleChangeOptions = (event) => {
    setOptions({ ...options, [event.target.name]: event.target.checked });
    console.log(event.target.checked);
    if (event.target.checked) {
      const temp = [...optionsFilter];
      temp.push(event.target.name);
      setOptionsFilter(temp);
    } else {
      const temp = optionsFilter.filter((v) => v !== event.target.name);
      setOptionsFilter(temp);
    }
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    let newStyle = {
      color: "black",
      borderRadius: 4,
      border: "none",
    };
    if (event.resource) {
      return {
        className: event.resource.typeEvent + "Event",
        style: newStyle,
      };
    } else {
      return {
        className: "",
        style: newStyle,
      };
    }
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = async () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate("prev");
      let result = await dispatch(getEventsByMonth(toolbar.date.getMonth()));
      if (result) {
        setEvents(result);
      }
    };

    const goToNext = async () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate("next");
      let result = await dispatch(getEventsByMonth(toolbar.date.getMonth()));
      if (result) {
        setEvents(result);
      }
    };

    const goToCurrent = async () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate("current");
      let result = await dispatch(getEventsByMonth(now.getMonth()));
      if (result) {
        setEvents(result);
      }
    };

    const label = () => {
      const date = dayjs(toolbar.date);
      return (
        <span>
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </span>
      );
    };

    return (
      <StyledToolbarCalendar>
        <label className={"toolbarCalendarLabel"}>{label()}</label>
        <div>
          <IconButton
            aria-label="prev"
            size="small"
            className={`prevNextBtn`}
            onClick={goToBack}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <ButtonOrange
            size={"small"}
            variant={"contained"}
            value={"วันนี้"}
            onClick={goToCurrent}
          />
          <IconButton
            aria-label="next"
            size="small"
            className={`prevNextBtn`}
            onClick={goToNext}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </div>
      </StyledToolbarCalendar>
    );
  };

  useEffect(() => {
    async function getdata() {
      let result = await dispatch(getEventsByMonth(today.getMonth()));
      if (result) {
        setEvents(result);
      }
    }
    getdata();
  }, []);

  const CardStatusList = () => {
    return (
      <StyledCard>
        <CardContent>
          <div className={`cardHeader`}>
            <Typography variant="h4" className={`cardTitle`}>
              สถานะรายการ
            </Typography>
            <ButtonOrange
              size="small"
              value={"ดูทั้งหมด"}
              variant={"contained"}
            />
          </div>

          <div style={{ overflow: "scroll" }}>
            <TableRequest />
          </div>
        </CardContent>
      </StyledCard>
    );
  };

  const CardOT36Hours = () => {
    return (
      <StyledCard>
        <CardContent style={{ paddingBottom: 0 }}>
          <div>
            <Typography variant="h4" className={`cardTitle`}>
              OT 36 ชั่วโมง
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              gutterBottom
              style={{ marginBottom: 16 }}
            >
              20/9/2021 - 26/9/2021
            </Typography>
            <div style={{ marginTop: 16, width: 300, margin: "auto" }}>
              <ChartsOT36HoursWeek />
            </div>
          </div>
        </CardContent>
      </StyledCard>
    );
  };

  const CardBalanceLeave = () => {
    return (
      <StyledCard>
        <CardContent>
          <div className={`cardHeader`}>
            <Typography variant="h4" className={`cardTitle`}>
              สิทธิการลาคงเหลือ
            </Typography>
          </div>

          <div style={{ overflow: "scroll" }}>
            <ChartsLeave useLeave={4} max={6} leave={"วันหยุดพักผ่อนประจำปี"} />
            <ChartsLeave useLeave={16} max={30} leave={"ลาป่วย"} />
            <ChartsLeave useLeave={6} max={6} leave={"ลากิจ"} />
          </div>
        </CardContent>
      </StyledCard>
    );
  };

  const CardOTType = () => {
    return (
      <StyledCard>
        <CardContent style={{ paddingBottom: 0 }}>
          <Box>
            <Typography variant="h4" className={`cardTitle`} gutterBottom>
              ประเภท OT
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              gutterBottom
              style={{ marginBottom: 16 }}
            >
              20/9/2021 - 26/9/2021
            </Typography>
            <Box style={{ marginTop: 16, width: 300, margin: "auto" }}>
              <ChartsOTTypeWeek />
            </Box>
          </Box>
        </CardContent>
      </StyledCard>
    );
  };

  const CardIncomeOT = () => {
    return (
      <StyledCard>
        <CardContent>
          <div className={`cardHeader`}>
            <Typography variant="h4" className={`cardTitle`}>
              เงินค่าทำงานล่วงเวลา
            </Typography>
          </div>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)" }}
            >
              <div style={{ marginBottom: 8 }}>
                <Typography variant="h5">
                  {Utils.numberWithCommas(rand1000())} บาท
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  OT x 1.0
                </Typography>
              </div>
              <div style={{ marginBottom: 8 }}>
                <Typography variant="h5">
                  {Utils.numberWithCommas(rand1000())} บาท
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  OT x 1.5
                </Typography>
              </div>
              <div style={{ marginBottom: 8 }}>
                <Typography variant="h5">
                  {Utils.numberWithCommas(rand1000())} บาท
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  OT x 2.0
                </Typography>
              </div>
              <div style={{ marginBottom: 8 }}>
                <Typography variant="h5">
                  {Utils.numberWithCommas(rand1000())} บาท
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  OT x 3.0
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={8}>
              <ChartsOTAllMonth />
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    );
  };

  const initial = [
    {
      id: `2`,
      content: <CardOT36Hours />,
    },
    {
      id: `1`,
      content: <CardStatusList />,
    },
    {
      id: `4`,
      content: <CardOTType />,
    },
    {
      id: `3`,
      content: <CardBalanceLeave />,
    },
    {
      id: `5`,
      content: <CardIncomeOT />,
    },
  ];

  const [cardSummaryData, setCardSummaryData] = useState([
    {
      id: `1`,
      variant: "outlined",
      title: "วันทำงาน",
      icon: "far fa-calendar-alt",
      value: "20",
      unit: "วัน",
    },
    {
      id: `2`,
      variant: "contained",
      title: "เงินค่าจ่าง",
      icon: "far fa-wallet",
      value: "8,000",
      unit: "บาท",
    },
    {
      id: `3`,
      variant: "outlined",
      title: "จำนวน OT",
      icon: "far fa-alarm-exclamation",
      value: "128",
      unit: "ชั่วโมง",
    },
    {
      id: `4`,
      variant: "contained",
      title: "เงินค่า OT",
      icon: "far fa-wallet",
      value: "9,600",
      unit: "บาท",
    },
  ]);

  const [state, setState] = useState({
    left: initial.filter((el, index) => {
      return index % 2 !== 0;
    }),
    right: initial.filter((el, index) => {
      return index % 2 === 0;
    }),
  });

  const getList = (id) => state[id];

  function onDragEnd(result) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      let newState = { left: items, right: state.right };

      if (source.droppableId === "right") {
        newState = { left: state.left, right: items };
      }

      setState(newState);
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      setState({
        left: result.left,
        right: result.right,
      });
    }
  }

  const onDragEndHorizontal = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      cardSummaryData,
      result.source.index,
      result.destination.index
    );

    setCardSummaryData(items);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  return (
    <StyledRoot className={`page`}>
      <DragDropContext onDragEnd={onDragEndHorizontal}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                display: "flex",
              }}
            >
              <Grid container spacing={3}>
                {cardSummaryData.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <Grid item xs={6} sm={6} md={3}>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <CardSummary
                            variant={item.variant}
                            title={item.title}
                            icon={item.icon}
                            value={item.value}
                            unit={item.unit}
                          ></CardSummary>
                        </div>
                      </Grid>
                    )}
                  </Draggable>
                ))}
              </Grid>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={9}>
                <StyledCalendar
                  localizer={localizer}
                  events={events.filter((event) =>
                    optionsFilter.includes(event.resource.typeEvent)
                  )}
                  views={["month"]}
                  startAccessor="start"
                  endAccessor="end"
                  popup={true}
                  defaultDate={new Date()}
                  onSelectEvent={(selected) => {
                    console.log(selected);
                  }}
                  eventPropGetter={eventPropGetter}
                  components={{
                    toolbar: CustomToolbar,
                  }}
                  formats={{
                    dateFormat: "d",
                    dayHeaderFormat: "d MMMM yyyy",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <StyledInfoCalendarHeader>
                  <i className="far fa-info-circle"></i>
                  <Typography color="text.secondary">
                    ข้อมูลเพิ่มเติม
                  </Typography>
                </StyledInfoCalendarHeader>
                <div className={classes.calendarOptions}>
                  <div className={classes.wrapOptions}>
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={options.holiday}
                          onChange={handleChangeOptions}
                          name="holiday"
                          className={"option-holiday"}
                        />
                      }
                      label="วันหยุดประจำปี"
                    />
                    <IconButton aria-label="information" size="small">
                      <i className="fas fa-caret-circle-right MuiTypography-colorTextSecondary"></i>
                    </IconButton>
                  </div>
                  <div className={classes.wrapOptions}>
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={options.ot}
                          onChange={handleChangeOptions}
                          name="ot"
                          className={`option-ot`}
                        />
                      }
                      label={"ทำงานล่วงเวลา"}
                    />
                    <IconButton aria-label="information" size="small">
                      <i className="fas fa-caret-circle-right MuiTypography-colorTextSecondary"></i>
                    </IconButton>
                  </div>
                  <div className={classes.wrapOptions}>
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={options.invalidTime}
                          onChange={handleChangeOptions}
                          name="invalidTime"
                          className={`option-invalidTime`}
                        />
                      }
                      label="เวลาทำงานไม่สมบูรณ์"
                    />
                    <IconButton aria-label="information" size="small">
                      <i className="fas fa-caret-circle-right MuiTypography-colorTextSecondary"></i>
                    </IconButton>
                  </div>
                  <div className={classes.wrapOptions}>
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={options.absent}
                          onChange={handleChangeOptions}
                          name="absent"
                          className={`option-absent`}
                        />
                      }
                      label="ขาดงาน"
                    />
                    <IconButton aria-label="information" size="small">
                      <i className="fas fa-caret-circle-right MuiTypography-colorTextSecondary"></i>
                    </IconButton>
                  </div>
                  <div className={classes.wrapOptions}>
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={options.late}
                          onChange={handleChangeOptions}
                          name="late"
                          className={`option-late`}
                        />
                      }
                      label="มาสาย"
                    />
                    <IconButton aria-label="information" size="small">
                      <i className="fas fa-caret-circle-right MuiTypography-colorTextSecondary"></i>
                    </IconButton>
                  </div>
                  <div className={classes.wrapOptions}>
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={options.leave}
                          onChange={handleChangeOptions}
                          name="leave"
                          className={`option-leave`}
                        />
                      }
                      label="ลางาน"
                    />
                    <IconButton aria-label="information" size="small">
                      <i className="fas fa-caret-circle-right MuiTypography-colorTextSecondary"></i>
                    </IconButton>
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Droppable droppableId="left">
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                  {state.left.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Droppable droppableId="right">
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                  {state.right.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {item.content}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
        </Grid>
      </DragDropContext>
    </StyledRoot>
  );
}

export default Home;
