import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ChartProvidentFund = (props) => {
  const { Provident, year, JanValue } = props;

  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 328,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      series: [],
      title: {
        text: "บริษัทนำส่งรวม",
        align: "left",
        offsetY: 25,
        offsetX: 20,
      },
      subtitle: {
        text: "Statistics",
        offsetY: 55,
        offsetX: 20,
      },
      markers: {
        size: 6,
        strokeWidth: 0,
        hover: {
          size: 9,
        },
      },
      grid: {
        show: true,
        padding: {
          bottom: 0,
        },
      },
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      xaxis: {
        tooltip: {
          enabled: false,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetY: -20,
      },
    },
  });

  useEffect(() => {
    const newSeries = [
      {
        name: "Total Provident",
        data: year < 2024 ?
          [{ Jan: JanValue }, ...Object.values(Provident)].map((item, index) => index === 0 ? item.Jan : item.Total_Provident) :
          Object.values(Provident).map((item) => item.Total_Provident)
      },
      {
        name: "Total Company Provident",
        data: year < 2024 ?
          [{ Jan: 0 }, ...Object.values(Provident)].map((item, index) => index === 0 ? item.Jan : item.Total_Com_Provident) :
          Object.values(Provident).map((item) => item.Total_Com_Provident)
      },
    ];

    setChartData((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        series: newSeries,
      },
    }));
  }, [Provident, JanValue]);

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={chartData.options}
            series={chartData.options.series}
            type="line"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default ChartProvidentFund;
