import React, { useState } from "react";
import { styled } from "@mui/material/styles";

import { Button } from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PanToolIcon from "@mui/icons-material/PanTool";

import ConfirmDialog from "../../../user/expense/confirm-dialog";
import { useTranslation } from "react-i18next";

const RejectButton = styled(Button)(({ variant, theme }) => ({
  backgroundColor: "#ef5350",
  color: "#ffffff",
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 14,
  marginLeft: 12,
  marginRight: 12,
  minWidth: 124,
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#e53935",
  },
}));

const PayButton = styled(Button)(({ variant, theme }) => ({
  color: "#ffffff",
  backgroundColor: "#66bb6a",
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 14,
  marginLeft: 12,
  marginRight: 12,
  minWidth: 124,
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#43a047",
  },
}));

const ActionButtons = (props) => {
  const { t, i18n } = useTranslation();
  const { handleSubmit, isAPIpending } = props;
  const RejectFormDialogTitle = t("DeleteDraftFormDialogTitle");
  const ApproveFormDialogTitle = t("DeleteDraftFormDialogTitle");
  const RejectFormDialogDes = t("DeleteDraftFormDialogTitle");
  const ApproveFormDialogDes = t("DeleteDraftFormDialogTitle");
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const handleOpenRejectDialog = () => {
    setOpenRejectDialog(true);
  };
  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
  };
  const handleOnClickRejectDialog = (action, status) => {
    console.log("Raction", action, "status", status);
    handleSubmit(action, status);
    setOpenRejectDialog(false);
  };

  const handleOpenApproveDialog = () => {
    setOpenApproveDialog(true);
  };
  const handleCloseApproveDialog = () => {
    setOpenApproveDialog(false);
  };
  const handleOnClickApproveDialog = (action, status) => {
    console.log("action", action, "status", status);
    handleSubmit(action, status);
    setOpenApproveDialog(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        height: 60,
        paddingTop: 20,
      }}
    >
      <RejectButton
        startIcon={<CancelIcon />}
        onClick={handleOpenRejectDialog}
        disabled={isAPIpending}
      >
        {t("Reject")}
      </RejectButton>
      <ConfirmDialog
        title={RejectFormDialogTitle}
        description={RejectFormDialogDes}
        open={openRejectDialog}
        handleOnClick={() => {
          handleOnClickRejectDialog(4, 7);
        }}
        handleClose={handleCloseRejectDialog}
      ></ConfirmDialog>
      <PayButton
        startIcon={<CheckCircleIcon />}
        onClick={handleOpenApproveDialog}
        disabled={isAPIpending}
      >
        {t("Approve")}
      </PayButton>
      <ConfirmDialog
        title={ApproveFormDialogTitle}
        description={ApproveFormDialogDes}
        open={openApproveDialog}
        handleOnClick={() => {
          handleOnClickApproveDialog(9, 3);
        }}
        handleClose={handleCloseApproveDialog}
      ></ConfirmDialog>
    </div>
  );
};

export default ActionButtons;
