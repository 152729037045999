import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import dayjs from "dayjs";

import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@mui/icons-material/Search";

import StyledCard from "../../shared/general/Card";
import DatePickerWeek from "../../shared/general/DatePickerWeek";
import ButtonBlue from "../../shared/general/ButtonBlue";

import TableOTReport from "./tableOTReport";
import { Box, Container, LinearProgress } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getAllEmployeeShift } from "../../../../actions/employeeShift";
import { getAllRequestTimeById } from "../../../../actions/requestTime";

const rand40 = () => {
  return Math.floor(Math.random() * 40);
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid #e3e3e3`,
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  paddingLeft: 0,
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledAvatar = styled(Avatar)({
  width: 40,
  height: 40,
});

const StyledCellName = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiAvatar-root": {
    marginRight: 8,
  },
  "& .MuiTypography-h6": {
    lineHeight: 1,
  },
  "& .MuiTypography-body2": {
    color: "#999999",
    fontStyle: "oblique",
  },
});

export default function OTMonitoring() {
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: empShiftStore } = useSelector((state) => state.employeeShift);
  const { result: reqTimeStore } = useSelector((state) => state.requestTime);

  const [empSearch, setEmpSearch] = useState("");
  const [week, setWeek] = useState({ startDate: null, endDate: null });
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [employeeItems, setEmployeeItems] = useState([]);
  const [reqTimes, setReqTimes] = useState([]);

  const handleOnSearch = () => {
    fetchData();
  };

  const fetchData = () => {
    const empShiftList = [...empShiftStore];

    let empShiftFilter = empShiftList.filter(function (emp) {
      if (emp.startDate && emp.endDate) {
        var shiftStart = new Date(emp.startDate);
        var shiftEnd = new Date(emp.endDate);

        return (
          shiftStart >= week.startDate &&
          shiftEnd <= week.endDate &&
          (emp.idManagerLV1 === currentUser.id ||
            emp.idManagerLV2 === currentUser.id)
        );
      }
    });

    empShiftFilter.forEach(function (emp) {
      if (reqTimeStore) {
        let reqFilter = reqTimeStore.filter(function (req) {
          var reqStart = new Date(req.requestTimeStart);
          var reqEnd = new Date(req.requestTimeEnd);

          return (
            reqStart >= week.startDate &&
            reqEnd <= week.endDate &&
            req.idEmp === emp.idEmp
          );
        });

        emp.reqList = reqFilter;

        let sumOTHours = reqFilter.reduce(function (accumulator, curValue) {
          return accumulator + curValue.sumAllOT;
        }, 0);
        emp.sumOTHours = sumOTHours;
      }
    });

    setEmployees(empShiftFilter);
    setEmployeeItems(empShiftFilter);
    setIsLoading(false);
  };

  useEffect(() => {
    if (empShiftStore) {
      fetchData();
    }
  }, [empShiftStore]);

  useEffect(() => {
    let x = [...employeeItems];
    x = x.filter((y) => {
      let fullName = `${y.name} ${y.lastname}`;
      return fullName.toLowerCase().includes(empSearch.toLocaleLowerCase());
    });
    setEmployees(x);
  }, [empSearch]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getAllEmployeeShift());

      const start = startOfWeek(new Date());
      const end = endOfWeek(new Date());

      setWeek({
        startDate: start,
        endDate: end,
      });

      dispatch(getAllRequestTimeById());
    }
  }, []);

  return (
    <Container sx={{ marginTop: 12 }}>
      <Typography variant="h4" sx={{ paddingTop: 2, marginBottom: 2 }}>
        OT Monitoring
      </Typography>
      {isLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <div>
          <StyledCard>
            <div style={{ padding: 36 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <FilterListIcon style={{ marginRight: 4, fontSize: 14 }} />
                <Typography variant="body2"> ค้นหาข้อมูล</Typography>
              </div>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid item xs={12} sm={6} md={8}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs>
                      <DatePickerWeek setWeek={setWeek} />
                    </Grid>
                    <Grid item xs>
                      <ButtonBlue
                        variant="contained"
                        size="large"
                        startIcon={<SearchIcon />}
                        onClick={handleOnSearch}
                      >
                        ค้นหา
                      </ButtonBlue>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="ค้นหาชื่อ"
                    variant="outlined"
                    value={empSearch}
                    onChange={(e) => setEmpSearch(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: 36 }}>
                {employees.length > 0 ? (
                  employees.map((item) => (
                    <Accordion key={item.idEmployeeShift}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <StyledCellName>
                            <StyledAvatar
                              alt={"username"}
                              src={`${process.env.REACT_APP_API_URL}image/profile/1.jpg`}
                            />
                            <div>
                              <Typography variant="h6">
                                {`${item.name} ${item.lastname}`}
                              </Typography>
                              <Typography variant="body2">
                                {item.positionsName}
                              </Typography>
                            </div>
                          </StyledCellName>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "baseline",
                            }}
                          >
                            <Typography
                              variant="h4"
                              style={{
                                marginRight: 4,
                                color: item.sumOTHours >= 36 && "#f44336",
                                fontWeight: item.sumOTHours >= 36 ? 600 : 400,
                              }}
                            >
                              {item.sumOTHours}
                            </Typography>
                            <Typography
                              variant="boby1"
                              color="text.secondary"
                            >{` ชม.`}</Typography>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableOTReport reqList={item.reqList} />
                      </AccordionDetails>
                    </Accordion>
                  ))
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                      sx={{
                        backgroundColor: "#e0e0e0",
                        borderRadius: "10px",
                        padding: 1,
                        marginTop: 2,
                        paddingLeft: 5,
                        paddingRight: 5,
                        marginTop: 2,
                      }}
                    >
                      <Typography variant="body1">
                        ไม่มีข้อมูลพนักงาน
                      </Typography>
                    </Box>
                  </Box>
                )}
              </div>
            </div>
          </StyledCard>
        </div>
      )}
    </Container>
  );
}
