import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Container, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import HeaderPage from "./../../shared/header/headerPage";

import EmployeeList from "./employeeList";

import iconHeader from "./assets/teamwork.svg";

import DialogDownload from "./DialogDownload";
import DialogUpload from "./DialogUpload";

import {
  getAllUsersVendor,
  getCompanyContract,
} from "../../../../actions/vendor";

import "./index.css";

const useStyles = makeStyles((theme) => ({
  btnAddNew: {},
  btnIconAddNew: {
    ["@media only screen and (min-width:600px)"]: {
      display: "none",
    },
  },

  divider: {
    margin: "10px 0",
  },
  wrapFilterStatusEmployee: {
    marginTop: 16,
  },
  btnFilterStatusEmployee: {
    "& button": {
      borderRadius: 0,
    },
    "& .MuiToggleButton-root": {
      border: "none",
    },
  },
  wrapFilter: {
    margin: "16px 0",
  },
  formControl: {
    width: "100%",
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 8,
  },
  wrapName: {
    width: 350,
  },
  textName: {
    fontSize: 18,
  },
  textPosition: {},
  statusTag: {
    height: 22,
    minWidth: 22,
    borderRadius: 8,
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "inline-flex",
    justifyContent: "center",
    padding: "0px 8px",
  },
  statusTagActive: {
    color: "rgb(34, 154, 22)",
    backgroundColor: "rgba(84, 214, 44, 0.16)",
  },
  statusTagTerminate: {
    color: "rgb(183, 33, 54)",
    backgroundColor: "rgba(255, 72, 66, 0.16)",
  },
  statusTagLabel: {
    lineHeight: 0,
    fontWeight: 700,
    fontSize: 14,
    textTransform: "capitalize",
  },
  wrapFirstColumn: {
    display: "flex",
    alignItems: "center",
  },
  textOverFlow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  sectionAbout: {
    width: 420,
    display: "flex",
    justifyContent: "space-around",
  },
  labelAbout: {
    fontSize: 12,
  },
  textAbout: {
    fontSize: 14,
  },
  iconAction: {
    marginRight: 16,
  },
  smallScreen: {},
}));

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .wrapHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& .wrap-action-btn": {
      display: "flex",
      alignItems: "center",
      marginBottom: 16,
      "& .download, .upload": {
        marginRight: 16,
      },
    },
    ["@media only screen and (max-width: 600px)"]: {
      "& .download, .upload, .add-new": {
        display: "none",
      },
    },
    ["@media only screen and (min-width: 600px)"]: {
      "& .icon": {
        display: "none",
      },
    },
  },
});

const EmployeesPage = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDialogUpload, setOpenDialogUpload] = useState(false);
  const [openDialogDownload, setOpenDialogDownload] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeList } = useSelector((state) => state.users);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (userProfile) {
      dispatch(getAllUsersVendor('all'));
      dispatch(getCompanyContract());
    }
  }, [userProfile]);

  const toggleDrawerDownload = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDialogDownload(open);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOpenDialogUpload = () => {
    setOpenDialogUpload(true);
  };

  const handleCloseDialogUpload = () => {
    setOpenDialogUpload(false);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Fragment>
          <div className={`wrapHeader`}>
            <Fragment>
              <HeaderPage textLabel={t("EmployeeList")} icon={iconHeader} />
              {employeeList && employeeList.active.length > 0 && (
                <Fragment>
                  <div className="wrap-action-btn">
                    <div className="icon">
                      <IconButton aria-label="add to shopping cart">
                        <MoreVertIcon />
                      </IconButton>
                    </div>
                    <ButtonBlue
                      className="download"
                      variant="outlined"
                      aria-label="download"
                      onClick={toggleDrawerDownload(true)}
                      startIcon={<FileDownloadIcon />}
                    >
                      {t("Download")}
                    </ButtonBlue>
                    <ButtonBlue
                      className="upload"
                      variant="outlined"
                      aria-label="upload"
                      onClick={handleOpenDialogUpload}
                      startIcon={<FileUploadIcon />}
                    >
                      {t("Upload")}
                    </ButtonBlue>
                    <div className="add-new">
                      <ButtonBlue
                        variant={"contained"}
                        component={NavLink}
                        to="/vendor/employees/register_new"
                        startIcon={<AddIcon />}
                      >
                        {t("Add_Employee")}
                      </ButtonBlue>
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </div>
          {employeeList && <EmployeeList />}
        </Fragment>
      </Container>
      <DialogUpload
        open={openDialogUpload}
        handleClose={handleCloseDialogUpload}
        handleOpenAlert={handleOpenAlert}
        handleChangeAlertType={handleChangeAlertType}
      />
      <DialogDownload
        open={openDialogDownload}
        toggleDrawer={toggleDrawerDownload}
      />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      /> 
    </StyledRoot>
  );
};

export default EmployeesPage;
