import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Typography,
  Box,
  TextField,
  Container,
  Popper,
} from "@mui/material";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import { getSummaryTimeByIdEmp } from "../../../../actions/summary-time";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerEng from "../../shared/general/DatePickerEng";
import DatePickerThai from "../../shared/general/DatePickerThai";

import loading from "../../assets/social-media.gif";

import TableList from "./table";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginTop: 16,
    marginBottom: 8,
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
  },
});

const StyledLoading = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "inherit",
});

const SummaryTimePage = () => {
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: summaryTimeList } = useSelector((state) => state.summaryTime);
  const { isFetching: summaryTimeListIsFetching } = useSelector(
    (state) => state.summaryTime
  );
  const [search, setSearch] = useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(today).set("date", dayjs(today).daysInMonth()),
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(getSummaryTimeByIdEmp(search, null));
  }, []);

  const handleClickSearch = () => {
    dispatch(getSummaryTimeByIdEmp(search, null));
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8 }}>
          {t("WorkTimeSummary")}
        </Typography>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginBottom: 16 }}
        >
          <Grid item xs={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("StartDate")}
              </Typography>
              <div className="search-date">
                {localStorage.getItem("language") === "th" ? (
                  <DatePickerThai
                    inputFormat="dd MMM yyyy"
                    value={search.start}
                    name="start"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["start"]: newValue });
                    }}
                  />
                ) : (
                  <DatePickerEng
                    inputFormat="DD MMM YYYY"
                    value={search.start}
                    name="start"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["start"]: newValue });
                    }}
                  />
                )}
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={3}>
            <StyledBoxSearch>
              <Typography className="label" color="text.third">
                {t("EndDate")}
              </Typography>
              <div className="search-date">
                {localStorage.getItem("language") === "th" ? (
                  <DatePickerThai
                    inputFormat="dd MMM yyyy"
                    value={search.end}
                    name="end"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["end"]: newValue });
                    }}
                  />
                ) : (
                  <DatePickerEng
                    inputFormat="DD MMM YYYY"
                    value={search.end}
                    name="end"
                    onChange={(newValue) => {
                      setSearch({ ...search, ["end"]: newValue });
                    }}
                  />
                )}
              </div>
            </StyledBoxSearch>
          </Grid>
          <Grid item xs={3}>
            <StyledBoxSearch>
              <div className="wrap-search-action">
                <ButtonBlue
                  startIcon={<SearchRoundedIcon />}
                  variant="contained"
                  onClick={handleClickSearch}
                >
                  {t("Search")}
                </ButtonBlue>
              </div>
            </StyledBoxSearch>
          </Grid>
        </Grid>
        {summaryTimeListIsFetching ? (
          <StyledLoading>
            <img width="80" alt="loading" src={loading} />
          </StyledLoading>
        ) : (
          <Fragment>
            {summaryTimeList && <TableList />}
          </Fragment>
        )}
      </Container>
    </StyledRoot>
  );
};

export default SummaryTimePage;
