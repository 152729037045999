import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useTitle } from "react-use";
import "./styles.css";
import AddEvent from "./AddEvent";
import InfoEvent from "./InfoEvent";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Stack,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  TableCell,
} from "@mui/material";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";
import FullCalendar from "@fullcalendar/react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { useTranslation } from "react-i18next";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import th from "date-fns/locale/th";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";

import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import DialogUpload from "./DialogUpload";

import { allHoliday } from "../../../../../actions/holiday";
import EditHoliday from "./EditHoliday";

const useStyles = makeStyles((theme) => ({
  calendar_container: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(2),
    borderRadius: "5px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    borderTop: "5px solid #ffc107",
  },
}));

const StyledRoot = styled("div")({
  marginTop: 28,
  marginBottom: "100px",
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
});

const calendarEvents = [
  {
    id: "1",
    type: "วันหยุดนักขัตฤกษ์",
    title: "วันจักรี",
    start: "2022-04-06",
    color: "#ffc107",
  },
  {
    id: "2",
    type: "วันหยุดนักขัตฤกษ์",
    title: "วันสงกรานต์",
    start: "2022-04-13",
    end: "2022-04-16",
    color: "#ffc107",
  },
  {
    /*
  { id: "3", title: "event 3", start: "2022-04-03", color: "purple" },
  { id: "4", title: "event 4", start: "2022-04-05", allDay: false },
  { id: "5", title: "event 5", start: "2022-03-07", end: "2022-03-10" },
  {
    id: "6",
    title: "event 6",
    start: "2022-04-08T14:30:00",
    color: "green",
    allDay: false,
  },
*/
  },
];

const StyledCalendar = styled(Calendar)({
  height: "350px !important",
  "& .rbc-date-cell": {
    paddingRight: 0,
    textAlign: "center",
  },
  "& .rbc-month-row": {
    justifyContent: "center",
  },
  "& .rbc-event-content": {
    display: "none !important",
  },
  "& .rbc-row-segment": {
    //]]display: "none !important",
  },
  "& .rbc-today": {
    borderRadius: 4,
  },
});

const StyledToolbarCalendar = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .todayBtn": {
    borderColor: "#007afe",
    color: "#ffffff",
    backgroundColor: "#007afe",
  },
  "& .prevNextBtn": {
    marginRight: 4,
    marginLeft: 4,
  },
  "& .toolbarCalendarLabel": {
    fontSize: 24,
  },
});

const StyledEmpty = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 350,
});

const locales = {
  "th-TH": th,
};

const StyledCellHeader = styled(TableCell)({
  padding: "13.5px 16px",
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    boxShadow: "20px -10px 20px #EEEEEE",
    zIndex: 4,
  },
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    backgroundColor: "#f4f6f8",
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 16px",
});

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function CalendarSetting(props) {
  const { company } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const today = dayjs().toDate();
  dayjs.extend(isSameOrAfter);
  const { result: holiday } = useSelector((state) => state.holiday);
  const [activeMonth, setActiveMonth] = useState(dayjs(today).get("month"));
  const [activeYear, setActiveYear] = useState(dayjs(today).get("year"));
  const [openDialogUpload, setOpenDialogUpload] = useState(false);
  const [addModal, setAddModal] = useState({
    isOpen: false,
  });

  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    event: [],
  });
  const [selectYear, setSelectYear] = useState(dayjs());

  useEffect(() => {
    if (company) {
      dispatch(allHoliday(company.idCompany, dayjs(new Date()).format("YYYY")));
    }
  }, [company]);

  const toggleDrawerUpload = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDialogUpload(open);
  };

  const handleClickOpenUpload = () => {
    setOpenDialogUpload(true);
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    let newStyle = {
      color: "black",
      borderRadius: 4,
      border: "none",
    };
    if (event.resource) {
      return {
        className: event.resource.typeEvent + "Event",
        style: newStyle,
      };
    } else {
      return {
        className: "",
        style: newStyle,
      };
    }
  };

  const handleClose = () => {
    setAddModal({
      ...addModal,
      isOpen: false,
      type: "",
      data: [],
    });
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = async () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate("prev");
      setActiveMonth(toolbar.date.getMonth());
      setActiveYear(toolbar.date.getFullYear());
      //   let result = await dispatch(getEventsByMonth(toolbar.date.getMonth()));
      //   if (result) {
      //     setEvents(result);
      //   }
    };

    const goToNext = async () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate("next");
      setActiveMonth(toolbar.date.getMonth());
      setActiveYear(toolbar.date.getFullYear());
      //   let result = await dispatch(getEventsByMonth(toolbar.date.getMonth()));
      //   if (result) {
      //     setEvents(result);
      //   }
    };

    const goToCurrent = async () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate("current");
      setActiveMonth(toolbar.date.getMonth());
      setActiveYear(toolbar.date.getFullYear());
      //   let result = await dispatch(getEventsByMonth(now.getMonth()));
      //   if (result) {
      //     setEvents(result);
      //   }
    };

    const label = () => {
      const date = dayjs(toolbar.date);
      return (
        <span style={{ fontSize: 18, margin: 8 }}>
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </span>
      );
    };

    return (
      <StyledToolbarCalendar>
        <label className={"toolbarCalendarLabel"}>{label()}</label>
        <div>
          <IconButton
            aria-label="prev"
            size="small"
            className={`prevNextBtn`}
            onClick={goToBack}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <ButtonBlue
            size={"small"}
            variant={"contained"}
            onClick={goToCurrent}
          >
            {t("Today")}
          </ButtonBlue>
          <IconButton
            aria-label="next"
            size="small"
            className={`prevNextBtn`}
            onClick={goToNext}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </div>
      </StyledToolbarCalendar>
    );
  };

  const RenderHolidayList = () => {
    if (holiday) {
      var holidayMonth = holiday.filter(
        (value) =>
          dayjs(value.dateHoliday).get("month") === activeMonth &&
          dayjs(value.dateHoliday).get("year") === activeYear
      );
      if (holidayMonth.length > 0) {
        return holidayMonth.map((value) => (
          <div style={{ marginBottom: 8 }}>
            <Typography variant="h6" style={{ fontSize: 18 }}>
              <CircleIcon
                style={{
                  fontSize: 12,
                  marginRight: 4,
                  color: "#fc4b6c",
                }}
              />
              {value.name}
            </Typography>
            <Typography
              color="text.secondary"
              style={{
                paddingLeft: 12,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {dayjs(value.dateHoliday).format("DD MMM YYYY")}
            </Typography>
          </div>
        ));
      } else {
        return (
          <StyledEmpty className="empty-holiday">
            <Typography color="text.secondary" align="center">
              {t("NoHoliday")}
            </Typography>
          </StyledEmpty>
        );
      }
    }
  };

  const RenderTable = () => {
    const { t, i18n } = useTranslation();
    if (holiday) {
      const holidayMonth = holiday.filter((value) =>
        dayjs(value.dateHoliday).isSame(selectYear, "year")
      );
      if (holidayMonth.length > 0) {
        return (
          <TableContainer style={{ width: "100%", minHeight: "450px" }}>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <StyledCellHeader align="center">
                    {t("Date")}/{t("HolidayName")}
                  </StyledCellHeader>
                  <StyledCellHeader align="center">
                    {t("Date")}/{t("NameOfHolidayCompensate")}
                  </StyledCellHeader>
                  <StyledCellHeader align="center">
                    {t("Status")}
                  </StyledCellHeader>
                  <StyledCellHeader align="center">
                    {t("Manage")}
                  </StyledCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {holidayMonth.map((row, index) => (
                  <TableRow key={index}>
                    <StyledCellContent>
                      <Typography variant="subtitle1">
                        {dayjs(row.dateHoliday).format("DD MMM YYYY")}
                      </Typography>
                      <Typography color="text.third">
                        {i18n.resolvedLanguage === "th"
                          ? row.name
                          : row.name_EN
                          ? row.name_EN
                          : "-"}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography variant="subtitle1">
                        {row.compensateDate
                          ? dayjs(row.compensateDate).format("DD MMM YYYY")
                          : "-"}
                      </Typography>
                      <Typography color="text.third">
                        {i18n.resolvedLanguage === "th"
                          ? row.compensateName
                          : row.compensateName_EN
                          ? row.compensateName_EN
                          : "-"}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent align="center">
                      {row.isActive === 1 ? `${t("Use")}` : `${t("NotInUse")}`}
                    </StyledCellContent>
                    <StyledCellContent align="center">
                      <ButtonBlue
                        size="small"
                        startIcon={<ModeEditOutlineRoundedIcon />}
                        onClick={() => {
                          setAddModal({
                            ...addModal,
                            isOpen: true,
                            type: "Edit",
                            data: row,
                          });
                        }}
                      ></ButtonBlue>
                    </StyledCellContent>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      } else {
        return (
          <StyledEmpty className="empty-holiday">
            <Typography color="text.secondary" align="center">
              {t("NoHolidays")}
            </Typography>
          </StyledEmpty>
        );
      }
    }
  };

  return (
    <StyledRoot>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h6">{t("Calendar")}</Typography>
        </Grid>
        <Grid item>
          <ButtonBlue
            disabled={!company}
            variant={"contained"}
            style={{ marginRight: 8 }}
            onClick={toggleDrawerUpload(true)}
          >
            {t("Upload")}
          </ButtonBlue>
          {/* <ButtonBlue
            disabled={!company}
            variant={"contained"}
            startIcon={<AddIcon />}
            onClick={() => {
              setAddModal({
                ...addModal,
                isOpen: true,
              });
            }}
          >
            {t("AddCalendar")}
          </ButtonBlue> */}
        </Grid>
      </Grid>
      <div style={{ marginBottom: 28 }}></div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div>
            {holiday ? (
              <StyledCalendar
                className="calendar-leave"
                localizer={localizer}
                events={holiday}
                views={["month"]}
                startAccessor="start"
                endAccessor="end"
                popup={true}
                defaultDate={new Date()}
                onSelectEvent={(selected) => {
                  console.log(selected);
                }}
                eventPropGetter={eventPropGetter}
                components={{
                  toolbar: CustomToolbar,
                }}
                formats={{
                  dateFormat: "d",
                  dayHeaderFormat: "d MMMM yyyy",
                  weekdayFormat: "E",
                }}
              />
            ) : (
              <StyledEmpty className="empty-holiday">
                <Typography color="text.secondary" align="center">
                  {t("No_Data")}
                </Typography>
              </StyledEmpty>
            )}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>{RenderHolidayList()}</div>
        </Grid>
      </Grid>
      <Stack spacing={2} marginTop={5}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h6">{t("ListOfAnnualHolidays")}</Typography>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={i18n.language}
          >
            <DatePicker
              label={t("SelectYear")}
              views={["year"]}
              minDate={dayjs()}
              maxDate={dayjs().add(1, "year")}
              value={selectYear}
              onChange={(newValue) => setSelectYear(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Stack>
        {RenderTable()}
      </Stack>
      <DialogUpload
        company={company}
        open={openDialogUpload}
        toggleDrawer={toggleDrawerUpload}
      />
      {/* <AddEvent addModal={addModal} setAddModal={setAddModal} /> */}
      <EditHoliday
        addModal={addModal}
        setAddModal={setAddModal}
        handleClose={handleClose}
        company={company}
      />
      <InfoEvent infoModal={infoModal} setInfoModal={setInfoModal} />
    </StyledRoot>
  );
}

export default CalendarSetting;
