import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  styled,
  Link,
  Breadcrumbs,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ModeEditRoundedIcon from "@mui/icons-material/ModeEditRounded";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";

import ItemTableDaily from "./ItemTable-daily";
import ItemTableMonthly from "./ItemTable-monthly";
import AlertResponse from "../../shared/general/AlertResponse";

import utils from "../../../../utils";
import {
  getContractEmployeesByID,
  getContractEmployeesByDaily,
  getEmployeesReserveContract,
} from "../../../../actions/contract-employees";
import { getCompanyContract } from "../../../../actions/vendor";
import { getAllContractEmployees } from "../../../../actions/contract-employees";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 36,
});

const StyledContent = styled("div")({
  padding: 36,
  paddingTop: 24,
  "& .first-row": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .label": {
    fontWeight: 500,
  },
  "& .MuiDivider-root": {
    margin: "16px 0px",
    borderWidth: "0px 0px thin",
    borderColor: "rgba(145, 158, 171, 0.24)",
    borderStyle: "dashed",
  },
});

const StyledFormControl = styled(FormControl)({
  width: "150px",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const ContractDetailEmployee = (props) => {
  let { selected } = useParams();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  dayjs.locale("th");
  const [searchDate, setSearchDate] = React.useState(new Date());
  const history = useHistory();
  const { result: companyProfile } = useSelector(
    (state) => state.companyProfile
  );
  const { result: allContractEmployees } = useSelector(
    (state) => state.allContractEmployees
  );
  const { result: contractEmployeesDetail } = useSelector(
    (state) => state.contractEmployeesDetail
  );

  const { result: reserveEmployees } = useSelector(
    (state) => state.reserveEmployees
  );
  const { result: vendorContract } = useSelector(
    (state) => state.vendorContract
  );
  const [openDialogAdd, setOpenDialogAdd] = React.useState("");
  const [contract, setContract] = useState(null);
  const [modeSelected, setModeSelected] = useState("daily");
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    dispatch(getContractEmployeesByID(selected));
    dispatch(
      getContractEmployeesByDaily(
        selected,
        dayjs(searchDate).format("YYYY-MM-DD")
      )
    );
  }, []);

  useEffect(() => {
    let temp = [];
    temp.push(
      <Link underline="hover" key="1" color="inherit" onClick={handleClickAll}>
        {t("Lists")}
      </Link>
    );
    if (contractEmployeesDetail) {
      temp.push(
        <Link
          underline="hover"
          key="2"
          color="inherit"
          onClick={handleClickAll}
        >
          {contractEmployeesDetail.name}
        </Link>
      );

      setBreadcrumbs(temp);
    }
  }, [contractEmployeesDetail]);

  const handleClickAll = () => {
    history.push({
      pathname: `/contract-employee`,
    });
  };

  const handleClickEdit = (data) => {
    history.push({
      pathname: `/contract-employee/${data.idContractEmployees}/edit`,
      state: data
    });
  }

  const handleChangeSearch = () => {
    dispatch(
      getContractEmployeesByDaily(
        selected,
        dayjs(searchDate).format("YYYY-MM-DD")
      )
    );
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        {contractEmployeesDetail && (
          <Fragment>
            <div style={{ marginTop: 16 }}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link underline="hover" color="inherit" onClick={handleClickAll} style={{ cursor: "pointer" }}>
                  {t("Lists")}
                </Link>
                <Link underline="none" color="inherit">
                  {contractEmployeesDetail.name}
                </Link>
                {/* {breadcrumbs} */}
              </Breadcrumbs>
            </div>
            <Typography variant="h4" style={{ padding: "16px 0" }}>
              {contractEmployeesDetail.name}
            </Typography>
            <CardStyle>
              <StyledContent>
                <Box style={{ margin: 8 }}>
                  <div className="first-row">
                    <div>
                      <Typography
                        className="label"
                        color="text.secondary"
                        variant="body2"
                      >
                        {t("ContractCompany")}
                      </Typography>
                      <Typography variant="h6" gutterBottom>
                        {contractEmployeesDetail.companyName}
                      </Typography>
                    </div>
                    <div>
                      <ButtonBlue
                        startIcon={<ModeEditRoundedIcon />}
                        onClick={() => {handleClickEdit(contractEmployeesDetail)}}
                      >
                        {t("Edit")}
                      </ButtonBlue>
                    </div>
                  </div>

                  <Typography
                    className="label"
                    color="text.secondary"
                    variant="body2"
                  >
                    {t("startEndDate")}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {contractEmployeesDetail.endDate
                      ? `${dayjs(contractEmployeesDetail.startDate)
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "D MMMM BBBB"
                              : "D MMMM YYYY"
                          )} - ${dayjs(contractEmployeesDetail.endDate)
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "D MMMM BBBB"
                              : "D MMMM YYYY"
                          )}`
                      : contractEmployeesDetail.startDate
                      ? `${dayjs(contractEmployeesDetail.startDate)
                          .locale(localStorage.getItem("language") || "th")
                          .format(
                            localStorage.getItem("language") === "th"
                              ? "D MMMM BBBB"
                              : "D MMMM YYYY"
                          )}`
                      : t("Unspecified")}
                  </Typography>

                  <Typography
                    className="label"
                    color="text.secondary"
                    variant="body2"
                  >
                    {t("employeeMinimumPerDays")}
                  </Typography>
                  <Typography variant="h6" gutterBottom>{`${
                    contractEmployeesDetail.isMinAmountEmployees === 1
                      ? contractEmployeesDetail.amountPerDay + " คน"
                      : "ไม่กำหนดจำนวนคน"
                    }`}
                  </Typography>
                  <Typography
                    className="label"
                    color="text.secondary"
                    variant="body2"
                  >
                    OT
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {`${contractEmployeesDetail.isOT === 1 ? "มี" : "ไม่มี"}`}
                    <Checkbox
                      disabled={!contractEmployeesDetail.isOT ? true : false}
                      checked={contractEmployeesDetail.isOT ? true : false}
                    />
                  </Typography>
                  <Typography
                    className="label"
                    color="text.secondary"
                    variant="body2"
                  >
                    {t("costCharge")}
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {`${utils.numberWithCommas(
                      contractEmployeesDetail.limitPerMonth
                    )} ${t("Baht")}`}
                  </Typography>
                  {contractEmployeesDetail.contractEmployeePosition.length > 0 && <Box paddingTop="16px">
                    <Grid container spacing={2}>
                      {contractEmployeesDetail.isMinAmountEmployees === 1 && contractEmployeesDetail.contractEmployeePosition.map((position, index) => (
                        <Fragment key={position.idContractEmployeePosition}>
                          <Grid item xs={12} md={3}>
                            <Typography className="label" color="text.secondary" variant="body2">{t(`Position`)}</Typography>
                            <Typography>{position.positionsName}</Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Typography className="label" color="text.secondary" variant="body2">{t("NumberOfPeople")}</Typography>
                            <Typography>{utils.numberWithCommasWithoutDot(position.amount)}</Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Typography className="label" color="text.secondary" variant="body2">{t("CostChargePerPerson")}</Typography>
                            <Typography>{utils.numberWithCommas(position.limitPerPerson)} {t("Baht")}</Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Typography className="label" color="text.secondary" variant="body2">{t("Total")}</Typography>
                            <Typography>{utils.numberWithCommas(position.amount * position.limitPerPerson)} {t("Baht")}</Typography>
                          </Grid>
                        </Fragment>
                      ))}
                    </Grid>
                  </Box>}
                </Box>
                <Divider
                  style={{
                    margin: "16px 0px",
                    borderWidth: "0px 0px thin",
                    borderColor: "rgba(145, 158, 171, 0.24)",
                    borderStyle: "dashed",
                  }}
                />
                <div style={{ margin: 8 }}>
                  <Typography
                    className="label"
                    color="text.secondary"
                    variant="body2"
                  >
                    {t("Description")}
                  </Typography>
                  <Typography fontSize="14px" gutterBottom whiteSpace="pre-line">
                    {contractEmployeesDetail.detail? (
                      contractEmployeesDetail.detail
                    ): (
                      "-"
                    )}
                  </Typography>
                </div>
              </StyledContent>
            </CardStyle>

            <div style={{ marginBottom: 24 }}></div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 16,
              }}
            >
              {/* <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="mondeSelected"
                  value={modeSelected}
                  inputProps={{ readOnly: true }}
                  onChange={(event) => setModeSelected(event.target.value)}
                >
                  <MenuItem value={"daily"}>{t("Daily")}</MenuItem>
                  <MenuItem value={"monthly"}>{t("Monthly")}</MenuItem>
                </Select>
              </StyledFormControl> */}
            </div>
            <CardStyle>
              <StyledContent>
                {modeSelected === "daily" ? (
                  <ItemTableDaily
                    searchDate={searchDate}
                    setSearchDate={setSearchDate}
                    handleChangeSearch={handleChangeSearch}
                    idContractEmployees={
                      contractEmployeesDetail.idContractEmployees
                    }
                  />
                ) : (
                  <ItemTableMonthly
                    Employees={contractEmployeesDetail.Employees}
                  />
                )}
              </StyledContent>
            </CardStyle>
          </Fragment>
        )}
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      </Container>
    </StyledRoot>
  );
};

export default ContractDetailEmployee;