import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  Avatar,
  Chip,
  Container,
  Divider,
  Grid,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import DrawerBranch from "./DrawerBranch";
import { getVendorProfile } from "../../../../../actions/vendor";

const StyledRoot = styled("div")({
  minWidth: 350,
  width: "100%",
  backgroundColor: "#f1f4f9",
  paddingBottom: 36,
});

const StyledContent = styled("div")({
  padding: 36,
  paddingTop: 24,
  "& .part-one": {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 8,
    "& .MuiAvatar-root": {
      width: 160,
      height: 160,
      fontSize: 42,
      marginRight: 36,
      backgroundColor: "white",
      color: "inherit",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
      "& i": {
        fontSize: 120,
        color: "inherit",
      },
    },
    "& .detail": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "& .start": {
        "& .vendor-name": {
          marginBottom: 12,
        },
        "& .company-industry": {
          marginBottom: 12,
        },
        "& div": {
          marginRight: 32,
          ["@media only screen and (max-width: 600px)"]: {
            marginRight: 0,
          },
        },
      },
    },
  },
  "& .MuiDivider-root": {
    margin: "16px 0px",
    borderWidth: "0px 0px thin",
    borderColor: "rgba(145, 158, 171, 0.24)",
    borderStyle: "dashed",
  },
  "& .part-two": {
    marginBottom: 16,
    "& .wrap-item": {
      "& .label": {
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 4,
        "& span": {
          marginLeft: 16,
        },
      },
      "& .value": {
        fontSize: 18,
        marginLeft: 32,
      },
      "& .fal": {
        marginRight: 8,
      },
    },
  },
  "& .topic": {
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 8,
  },
  "& .MuiTabs-root": {
    marginBottom: 16,
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },
  "& .part-three": {
    "& .branch-count": {
      marginLeft: 32,
    },
  },
});

const StyledBranchContent = styled("div")({
  // padding: 16,
  paddingLeft: 16,
  // borderRadius: 20,
  // border: "1px solid #ececec",
  "& .branch-name": {
    fontSize: 18,
  },
  "& i": {
    marginRight: 8,
  },
  "& .contact-container": {
    display: "flex",
    flexFlow: "row wrap",
    "& .contact-wrap": {
      marginRight: 16,
    },
  },
});

const ContractDetailVendor = (props) => {
  let { selected } = useParams();
  const dispatch = useDispatch();

  const [isOpenDrawerBranch, setIsOpenDrawerBranch] = useState(false);

  const [branch, setBranch] = useState([]);


  const { result: vendorProfile } = useSelector(
    (state) => state.vendorProfile
  );

  useEffect(() => {
    dispatch(getVendorProfile(selected));
  }, []);


  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {vendorProfile && (
          <Fragment>
            <Typography variant="h4" style={{ padding: "24px 0" }}>
              ข้อมูล Vendor
            </Typography>
            <CardStyle>
              <StyledContent>
                <div className="part-one">
                  <Avatar
                    variant="rounded"
                    src={
                      vendorProfile.vendorImage
                        ? `${process.env.REACT_APP_API_URL}image/vendor/${vendorProfile.vendorImage}`
                        : ""
                    }
                  >
                    <img src="/assets/logo_default.png" />
                  </Avatar>
                  <div className={`detail`}>
                    <div className={`start`}>
                      <div className="vendor-name">
                        <Typography variant="h5">
                          {vendorProfile.vendorName}
                        </Typography>
                      </div>
                      <div className="vendor-expertise">
                        <Typography variant="caption" color="text.secondary">
                          ขนาดองค์กร
                        </Typography>
                        <Typography variant="h5">{`ขนาดกลาง`}</Typography>
                      </div>
                      <div className="vendor-expertise">
                        <Typography variant="caption" color="text.secondary">
                          ขนาด Manpower
                        </Typography>
                        <Typography variant="h5">{`${
                          vendorProfile.manpowerSize || 0
                        } คน`}</Typography>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider
                  style={{
                    margin: "16px 0px",
                    borderWidth: "0px 0px thin",
                    borderColor: "rgba(145, 158, 171, 0.24)",
                    borderStyle: "dashed",
                  }}
                />
                <div className="part-two">
                  <div className="wrap-item">
                    <Typography className="label" color="text.secondary">
                      <i className="far fa-map-marker-alt"></i>
                      <span>ที่อยู่</span>
                    </Typography>
                    <Typography className="value">{`เลขที่ 99 ถนนตัดเก่า แขวงบางครั้ง เขตฐานทัพ 10100 กรุงเทพมหานคร ประเทศไทย`}</Typography>
                  </div>
                </div>

                <div className="part-three">
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div>
                      <Typography className="label" color="text.secondary">
                        <i className="fa-regular fa-building"></i>
                        <span style={{ marginLeft: 14 }}>สาขาของบริษัท</span>
                      </Typography>
                      {branch.length > 1 && (
                        <Typography className="branch-count">{`${branch.length} สาขา`}</Typography>
                      )}
                    </div>
                    <ButtonBlue
                      startIcon={
                        <i className="fa-regular fa-magnifying-glass"></i>
                      }
                      variant="outlined"
                      onClick={() => {
                        setIsOpenDrawerBranch(true);
                      }}
                    >
                      ดูสาขาทั้งหมด
                    </ButtonBlue>
                  </Grid>
                  <Grid container spacing={2}>
                    {branch.length > 1 ? (
                      <></>
                    ) : (
                      <>
                        {branch.map((b, index) => (
                          <Grid key={index} item xs={12}>
                            <StyledBranchContent>
                              <Typography className="branch-name" variant="h7">
                                {b.branchName}
                              </Typography>
                              <Typography color="text.secondary">
                                <i className="far fa-map-marker-alt"></i>
                                <span>{`${b.address}`}</span>
                              </Typography>
                              <div className="contact-container">
                                <div className="contact-wrap">
                                  <Typography>
                                    <i className="fa-regular fa-user-headset"></i>
                                    <span>{`${b.mainContactName}`}</span>
                                  </Typography>
                                </div>
                                <div className="contact-wrap">
                                  <i className="fa-regular fa-phone"></i>
                                  <span>{`${b.mainContactPhone}`}</span>
                                </div>
                                <div className="contact-wrap">
                                  <i className="fa-regular fa-envelope"></i>
                                  <span>{`${b.mainContactEmail}`}</span>
                                </div>
                              </div>
                            </StyledBranchContent>
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </div>
              </StyledContent>
            </CardStyle>

            <div style={{ marginBottom: 24 }}></div>

            <CardStyle>
              <StyledContent>
                <Typography
                  className="topic"
                  style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
                >
                  ข้อมูลติดต่อ
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="caption" color="text.secondary">
                      ชื่อผู้ติดต่อ
                    </Typography>
                    <Typography>{vendorProfile.mainContactName || "-"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="caption" color="text.secondary">
                      เบอร์โทรศัพท์
                    </Typography>
                    <Typography>{vendorProfile.mainContactPhone || "-"}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="caption" color="text.secondary">
                      อีเมล
                    </Typography>
                    <Typography>{vendorProfile.mainContactEmail || "-"}</Typography>
                  </Grid>
                </Grid>
              </StyledContent>
            </CardStyle>

            <div style={{ marginBottom: 24 }}></div>

            <CardStyle>
              <StyledContent>
                <Typography
                  className="topic"
                  style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
                >
                  เกี่ยวกับ Vendor
                </Typography>
                <Typography>{vendorProfile.vendorAbout || "-"}</Typography>
              </StyledContent>
            </CardStyle>

            <div style={{ marginBottom: 24 }}></div>

            <CardStyle>
              <StyledContent>
                <Typography
                  className="topic"
                  style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
                >
                  ความเชี่ยวชาญ
                </Typography>
                <div>
                  <Chip label="Marketing" />
                  <Chip label="Product Management" />
                  <Chip label="Design" />
                </div>
              </StyledContent>
            </CardStyle>

            <div style={{ marginBottom: 24 }}></div>

            <Grid container justifyContent="flex-end">
              <ButtonBlue variant="contained">ขึ้นทะเบียน</ButtonBlue>
            </Grid>
          </Fragment>
        )}
      </Container>

      {isOpenDrawerBranch && (
        <DrawerBranch
          open={isOpenDrawerBranch}
          onClose={() => {
            setIsOpenDrawerBranch(false);
          }}
        />
      )}
    </StyledRoot>
  );
};

export default ContractDetailVendor;
