import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { red } from "@mui/material/colors";
import Logo from "./assets/logo/ant_logo.png";
import ButtonBlue from "./shared/general/ButtonBlue";
import StyledCard from "./shared/general/Card";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { login, logout } from "../../actions/auth";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  rootCard: {
    width: 345,
    marginTop: 100,
  },
  media: {
    height: 155,
    padding: 20,
    paddingBottom: 0,
    margin: 10,
  },
  version: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  showPassword: {
    position: "absolute",
    justifyContent: "center",
  },
});

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Contain at least 8 characters";
  }

  return errors;
};

const StyledFieldPassword = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px !important",
  },
  "& input": {
    padding: "13.5px 14px",
  },
});

const StyledFieldUser = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px !important",
  },
  "& input": {
    padding: "13.5px 14px",
  },
});

const StyledAlert = styled(Alert)({
  borderRadius: 8,
});

const LoginButton = withStyles((theme) => ({
  root: {
    marginTop: 10,
    borderColor: "#007afe",
    color: "#ffffff",
    backgroundColor: "#007afe",
    borderRadius: 8,
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
    },
  },
}))(Button);

const PasswordField = ({ isSubmitting, values, handleChange, error }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <StyledFieldPassword
      variant="outlined"
      style={{ width: "100%", marginTop: 8 }}
      error={error}
      fullWidth
      margin="normal"
    >
      <InputLabel htmlFor="outlined-adornment-password">รหัสผ่าน</InputLabel>
      <OutlinedInput
        disabled={isSubmitting}
        label="รหัสผ่าน"
        name="password"
        id="password"
        type={showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              size="large"
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </StyledFieldPassword>
  );
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoginPage = (props) => {
  const classes = useStyles();
  let query = useQuery();
  const dispatch = useDispatch();
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState("");

  const location = useLocation();
  const linkState = location.state;

  useEffect(() => {
    //anyNameFunction();
  }, []);

  const handleCloseError = () => {
    setOpenError(false);
  };

  const showForm = ({
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <StyledFieldUser
          variant="outlined"
          margin="normal"
          fullWidth
          id="username"
          name="username"
          label="ชื่อผู้ใช้"
          onChange={handleChange}
          value={values.username}
          autoFocus
          error={errors.username}
          disabled={isSubmitting}
        ></StyledFieldUser>

        {errors.username && (
          <Typography style={{ color: "#f44336" }}>
            {errors.username}
          </Typography>
        )}
        <PasswordField
          isSubmitting={isSubmitting}
          values={values}
          handleChange={handleChange}
          name="password"
          label="รหัสผ่าน"
          error={errors.password}
        ></PasswordField>
        {errors.password && (
          <Typography style={{ color: "#f44336" }}>
            {errors.password}
          </Typography>
        )}
        {openError && (
          <StyledAlert onClose={handleCloseError} severity="error">
            {messageError}
          </StyledAlert>
        )}

        <LoginButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={isSubmitting}
          className={classes.submit}
        >
          {" "}
          {isSubmitting ? "รอสักครู่..." : "เข้าสู่ระบบ"}
        </LoginButton>

        <Typography variant="body2" noWrap className={classes.version}>
          Version {process.env.REACT_APP_VERSION}
        </Typography>
      </form>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.rootCard}>
        <StyledCard>
          {
            <CardMedia
              style={{ backgroundSize: "contain" }}
              className={classes.media}
              image={Logo}
              title="Contemplative Reptile"
            />
          }
          <CardContent>
            <Typography variant="h4">เข้าสู่ระบบ</Typography>
            {/* HOC */}
            <Formik
              onSubmit={async (values, { setSubmitting }) => {
                const result = await dispatch(
                  login(values.username, values.password)
                );
                if (result && result.message) {
                  setMessageError(result.message);
                  setOpenError(true);
                  dispatch(logout());
                } else if (result) {
                  if (linkState && linkState.redirect) {
                    props.history.push(linkState.redirect);
                    window.location.reload();
                  } else {
                    if (query.get("source") === "pwa") {
                      if (result.roles.includes("ROLE_USER")) {
                        props.history.push("/gps-check?source=pwa");
                      }
                    } else {
                      if (result.roles.includes("ROLE_USER")) {
                        props.history.push("/home");
                      } else if (result.roles.includes("ROLE_MANAGER")) {
                        props.history.push("/home");
                      } else if (result.roles.includes("ROLE_ADMIN")) {
                        props.history.push("/home");
                      } else if (result.roles.includes("ROLE_VENDOR")) {
                        props.history.push("/home");
                      } else if (result.roles.includes("ROLE_TRAINING")) {
                        props.history.push("/courses");
                      } else if (result.roles.includes("ROLE_ANT")) {
                        props.history.push("/home");
                      } else if (result.roles.includes("ROLE_ACCOUNT")) {
                        props.history.push("/home");
                      }
                    }

                    window.location.reload();
                  }
                } else {
                  // setMessageError("เกิดข้อผิดพลาด กรุณาลองใหม่");
                  // setOpenError(true);
                  // dispatch(logout());
                }
              }}
              initialValues={{
                username: "",
                password: "",
                showPassword: false,
              }}
              validate={validate}
            >
              {(props) => showForm(props)}
            </Formik>
          </CardContent>
        </StyledCard>
      </div>
    </div>
  );
};

export default LoginPage;
