import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import {
  Avatar,
  FilledInput,
  Typography,
  FormControl,
  InputAdornment,
  Grid,
  TextField,
  Chip,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DatePickerEng from "../../shared/general/DatePickerEng";
import DatePickerThai from "../../shared/general/DatePickerThai";

const columns = [
  { id: "name", label: "ชื่อ-สกุล", minWidth: 280 },
  {
    id: "position",
    label: "ตำแหน่ง/หน่วยงาน",
    minWidth: 250,
  },
  {
    id: "employeeType",
    label: "ประเภท",
    minWidth: 120,
  },
  {
    id: "workingDay",
    label: "วันทำงาน",
    minWidth: 120,
  },
  {
    id: "absent",
    label: "วันลา",
    minWidth: 120,
  },
  {
    id: "salary",
    label: "เงินเดือน",
    minWidth: 120,
  },
  {
    id: "ot",
    label: "ค่าล่วงเวลา",
    minWidth: 120,
  },
  {
    id: "total",
    label: "รวมรายได้",
    minWidth: 120,
  }
];

const employee = [
  {
    idEmp: 1,
    fullname: "Ricardo Gonzalez",
    telephoneMobile: "0652202205",
    imageProfile: "2.jpg",
    positionsName: "Position 1",
    departmentName: "Department A",
    employeeType: "รายเดือน",
    isReserve: false,
  },
  {
    idEmp: 2,
    fullname: "Ava-Rose Houston",
    telephoneMobile: "0627795993",
    imageProfile: "3.jpg",
    positionsName: "Position 2",
    departmentName: "Department A",
    employeeType: "รายเดือน",
    isReserve: false,
  },
  {
    idEmp: 3,
    fullname: "Leena Shannon",
    telephoneMobile: "0841599250",
    imageProfile: "4.jpg",
    positionsName: "Position 1",
    departmentName: "Department B",
    employeeType: "รายเดือน",
    isReserve: false,
  },
  {
    idEmp: 4,
    fullname: "Brooklyn Schwartz",
    telephoneMobile: "0643406544",
    imageProfile: "5.jpg",
    positionsName: "Position 2",
    departmentName: "Department B",
    employeeType: "รายเดือน",
    isReserve: false,
  },
  {
    idEmp: 5,
    fullname: "Silas Randolph",
    telephoneMobile: "0840295950",
    imageProfile: "6.jpg",
    positionsName: "Position 1",
    departmentName: "Department C",
    employeeType: "รายเดือน",
    isReserve: false,
  },
  {
    idEmp: 6,
    fullname: "Steve Pugh",
    telephoneMobile: "0967259590",
    imageProfile: "7.jpg",
    positionsName: "Position 1",
    departmentName: "Department C",
    employeeType: "รายเดือน",
    isReserve: false,
  },
  // {
  //   idEmp: 7,
  //   fullname: "Wojciech Delacruz",
  //   telephoneMobile: "0961234962",
  //   imageProfile: "8.jpg",
  //   positionsName: "Position 2",
  //   departmentName: "Department C",
  //   employeeType: "รายเดือน",
  //   isReserve: false,
  // },
  // {
  //   idEmp: 8,
  //   fullname: "Cade Myers",
  //   telephoneMobile: "0613839373",
  //   imageProfile: "9.jpg",
  //   positionsName: "Position 3",
  //   departmentName: "Department C",
  //   employeeType: "รายเดือน",
  //   isReserve: false,
  // },
  // {
  //   idEmp: 9,
  //   fullname: "Faith King",
  //   telephoneMobile: "0613766495",
  //   imageProfile: "10.jpg",
  //   positionsName: "Position 1",
  //   departmentName: "Department D",
  //   employeeType: "รายเดือน",
  //   isReserve: false,
  // },
  {
    idEmp: 10,
    fullname: "Humza Herrera",
    telephoneMobile: "0654432145",
    imageProfile: "11.jpg",
    positionsName: "Position 3",
    departmentName: "Department D",
    employeeType: "รายเดือน",
    isReserve: false,
  },
  {
    idEmp: 11,
    fullname: "Jeffrey Calderon",
    telephoneMobile: "0613839373",
    imageProfile: "9.jpg",
    positionsName: "Position 3",
    departmentName: "Department C",
    employeeType: "รายเดือน",
    isReserve: true,
  },
  {
    idEmp: 12,
    fullname: "Roman Gilbert",
    telephoneMobile: "0613766495",
    imageProfile: "10.jpg",
    positionsName: "Position 1",
    departmentName: "Department D",
    employeeType: "รายเดือน",
    isReserve: true,
  },
  {
    idEmp: 13,
    fullname: "Elysia Herrera",
    telephoneMobile: "0654432145",
    imageProfile: "11.jpg",
    positionsName: "Position 3",
    departmentName: "Department D",
    employeeType: "รายเดือน",
    isReserve: true,
  },
];

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px -10px 20px #EEEEEE",
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    zIndex: 4,
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "none",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .fullname": {
    minWidth: 240,
    display: "flex",
    alignItems: "flex-start",
    "& .profile": {
      marginRight: 8,
      width: 45,
      height: 45,
    },
    "& .first-row": {
      display: "flex",
    },
  },
  "& .position": {
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .department": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .reportTo": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",

    fontWeight: 600,
  },
  "& .reportToPosition": {
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledBox = styled(Box)({
  "& .wrap-filter": {
    marginBottom: 16,
  },
  "& .count": {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 16px",
  },
});

const StyledBoxSearch = styled(Box)({
  width: "100%",
  "&.first": {
    marginRight: 8,
  },
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledTableContainer = styled(TableContainer)({
  "& .MuiTableCell-root": {
    padding: "10px 16px",
    borderBottom: "1px #DDDDDD dashed",
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledWrapRepresent = styled("div")({
  display: "flex",
  alignItems: "center",
  "& .MuiChip-root": {
    marginLeft: 8,
    backgroundColor: "#fec90f",
    borderRadius: 4,
    height: 18,
    fontWeight: 600,
    fontSize: 10,
    "& .MuiChip-label": {
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  "& .MuiTypography-root": {
    fontSize: 14,
    fontWeight: 500,
    marginRight: 8,
  },
});

const ItemTableMonthly = (props) => {
  const { Employees } = props;
  const { t, i18n } = useTranslation();
  const { filterEmployee } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchDate, setSearchDate] = React.useState(null);
  const [searchName, setSearchName] = React.useState("");
  const [employeesList, setEmployeesList] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const columns = [
    { id: "name", label: t("FullName"), minWidth: 280 },
    {
      id: "position",
      label: t("positionDepartment"),
      minWidth: 250,
    },
    {
      id: "employeeType",
      label: t("Employee_Type"),
      minWidth: 120,
    },
    {
      id: "workingDay",
      label: t("WorkingDay"),
      minWidth: 120,
    },
    {
      id: "absent",
      label: t("LeaveDay"),
      minWidth: 120,
    },
    {
      id: "salary",
      label: t("Salary"),
      minWidth: 120,
    },
    {
      id: "ot",
      label: t("OvertimeTable"),
      minWidth: 120,
    },
  ];

  useEffect(() => {
    if (Employees) {
      setEmployeesList(Employees)
    }
  }, [])

  const handleChangeSearch = () => {
    console.log("searchName", searchName)
    if (Employees && Employees.length > 0) {
      if (searchDate && searchName) {
        setEmployeesList(Employees.filter(emp => (emp.firstname + emp.lastname).includes(searchName) && emp.startDate === searchDate))
      } else if (searchName) {
        setEmployeesList(Employees.filter(emp => (emp.firstname + emp.lastname).includes(searchName)))
      } else if (searchDate) {
        setEmployeesList(Employees.filter(emp => (emp.startDate === searchDate)))
      }

    }

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const rowsFilter = () => {
    return employee;
  };

  return (
    <div>
      <StyledBox className={"close"}>
        <div className="wrap-filter">
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} sm={6} md={4}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("SearchDate")}
                </Typography>
                <div className="search-date">
                  {localStorage.getItem("language") === "en" ? (
                    <DatePickerEng
                      value={searchDate}
                      name="searchDate"
                      onChange={(newValue) => {
                        setSearchDate(newValue);
                      }}
                    />
                  ) : (
                    <DatePickerThai
                      value={searchDate}
                      name="searchDate"
                      onChange={(newValue) => {
                        setSearchDate(newValue);
                      }}
                    />
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledBoxSearch className="first">
                <Typography className="label" color="text.third">
                  {t("searchName")}
                </Typography>
                <FormControl fullWidth variant="filled">
                  <StyledFilledInput
                    id="filled-sarch-name"
                    placeholder={t("searchFirstLast")}
                    name="search"
                    onChange={(newValue) => {
                      setSearchName(newValue.target.value);
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div style={{ display: "flex", marginBottom: 8 }}>
                <div style={{ marginRight: 8 }}>
                  <ButtonBlue variant="contained" startIcon={<SearchIcon />} onClick={handleChangeSearch} >
                    {t("Search")}
                  </ButtonBlue>
                </div>
                <div>
                  <ButtonBlue
                    variant="outlined"
                    startIcon={<DownloadRoundedIcon />}
                  >
                    {t("Download")}
                  </ButtonBlue>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="count">
          <div>
            <Typography className="label" color="text.third" variant="body2">
              {t("permanent")}
            </Typography>
            <Typography variant="h4">{employeesList.filter(x => (x.isPermanent === 1)).length}</Typography>
          </div>
          <div>
            <Typography className="label" color="text.third" variant="body2">
              {t("reserve")}
            </Typography>
            <Typography variant="h4" align="right">
              {employeesList.filter(x => (x.isReserve === 1)).length}
            </Typography>
          </div>
        </div>
        <StyledTableContainer style={{ width: "100%", height: 450 }}>
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledCellHeader
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledCellHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {employee &&
                employee
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <StyledRowContent key={`${page}-${index}`}>
                        <StyledCellContent className="sticky">
                          <div className="fullname">
                            <Typography style={{ width: 45 }} variant="h4">
                              {index + 1}
                            </Typography>
                            <Avatar
                              className="profile"
                              src={`${process.env.REACT_APP_API_URL}image/profile/${row.imageProfile}`}
                            />
                            <div>
                              <div className="first-row">
                                <Typography>{`${row.firstname} ${row.lastname}`}</Typography>
                                {row.isReserve ? (
                                  <StyledWrapRepresent>
                                    <Chip label={`RESERVE`} size="small" />
                                  </StyledWrapRepresent>
                                ) : <div></div>}
                              </div>
                              <Typography variant="body2" color="text.third">
                                {row.telephoneMobile &&
                                  `${row.telephoneMobile.replace(
                                    /(\d\d\d)(\d\d\d)(\d\d\d\d)/,
                                    "$1-$2-$3"
                                  )}`}
                              </Typography>
                            </div>
                          </div>
                        </StyledCellContent>
                        <StyledCellContent>
                          <Box sx={{ textAlign: "center" }}>
                            <Typography className="position">
                              {row.position}
                            </Typography>
                            <Typography className="department" color="text.third">
                              {row.department}
                            </Typography>
                          </Box>
                        </StyledCellContent>
                        <StyledCellContent>
                          <Box sx={{ textAlign: "center" }}>
                            <Typography className="reportTo">
                              {dayjs(row.startDate).format("D MMM BBBB")}
                            </Typography>
                          </Box>
                        </StyledCellContent>
                        <StyledCellContent>
                          <Box sx={{ textAlign: "center" }}>
                            <Typography className="reportTo">
                              {dayjs(row.endDate).format("D MMM BBBB")}
                            </Typography>
                          </Box>
                        </StyledCellContent>
                      </StyledRowContent>
                    );
                  })}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={employeesList && employeesList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledBox>
    </div>
  );
};

export default ItemTableMonthly;
