import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Avatar,
  CircularProgress,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Typography,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    boxShadow: "20px -10px 20px #EEEEEE",
    zIndex: 4,
  },
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    backgroundColor: "#f4f6f8",
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "transparent",
    "& .sticky": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 16px",
  width: 250,
});

function DataTable(props) {
  const { t, i18n } = useTranslation();

  const { search, modeSearch, isLoading, addOrEditModal, setAddOrEditModal } =
    props;
  const { result: leaveTypeList } = useSelector((state) => state.leaveType);
  const { result: positionList } = useSelector((state) => state.positions);
  const positionStore = useSelector((state) => state.positions);

  const headCells = [
    { id: "position", label: `${t("Position")}`, align: "left", minWidth: 200 },
    {
      id: "section",
      label: `${t("Section")}` ,
      align: "left",
      minWidth: 200,
    },
    {
      id: "section",
      label: `${t("Department")}`,
      align: "left",
      minWidth: 200,
    },
    {
      id: "section",
      label: `${t("Division")}`,
      align: "left",
      minWidth: 200,
    },
  ];

  const pages = [10, 25, 50, { label: "All", value: -1 }];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderRow = () => {
    let dataTemp = null;
    switch (modeSearch) {
      case "position":
        dataTemp = positionList.filter(
          (x) =>
            x.positionsName &&
            x.positionsName.toUpperCase().includes(search.toUpperCase())
        );
        break;
      case "section":
        dataTemp = positionList.filter(
          (x) =>
            x.sectionName &&
            x.sectionName.toUpperCase().includes(search.toUpperCase())
        );
        break;
      case "department":
        dataTemp = positionList.filter(
          (x) =>
            x.departmentName &&
            x.departmentName.toUpperCase().includes(search.toUpperCase())
        );
        break;
      case "division":
        dataTemp = positionList.filter(
          (x) =>
            x.divisionName &&
            x.divisionName.toUpperCase().includes(search.toUpperCase())
        );
        break;
      default:
        dataTemp = positionList;
        break;
    }

    return dataTemp;
  };

  return (
    <>
      <TableContainer style={{ width: "100%", height: 450 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headCells.map((head) => (
                <StyledCellHeader
                  key={head.id}
                  align={head.align}
                  className={head.className}
                  style={{ minWidth: head.minWidth }}
                >
                  {head.label}
                </StyledCellHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {positionStore.isFetching ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              (positionList &&
                positionList.length > 0 &&
                renderRow()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <StyledRowContent key={index}>
                      <StyledCellContent>
                        <Typography>{item.positionsName}</Typography>
                      </StyledCellContent>
                      <StyledCellContent>
                        <Typography>{item.sectionName}</Typography>
                      </StyledCellContent>
                      <StyledCellContent>
                        <Typography>{item.departmentName}</Typography>
                      </StyledCellContent>
                      <StyledCellContent>
                        <Typography>{item.divisionName}</Typography>
                      </StyledCellContent>
                    </StyledRowContent>
                  ))) || (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No Data
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={positionList ? positionList.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default DataTable;
