import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  InputAdornment,
  Box,
  FilledInput,
  FormControl,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";

import SearchIcon from "@mui/icons-material/Search";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import DailyPanel from "./daily";
import Timesheet from "./timesheet";
import Individual from "./individual";

import SelectEmployeesManager from "../../shared/general/selectEmployeesManager";
import ButtonBlue from "../../shared/general/ButtonBlue";

import {
  getAttendanceCompanyByIdEmp,
  clearAttendance,
  getAttendanceCompany,
} from "../../../../actions/attendance";
import { getHoliday } from "../../../../actions/holiday";
import { getShiftPattern } from "../../../../actions/shift";
import { getPayrollSetting } from "../../../../actions/paytypes";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 24,
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    color: "#007afe",
    border: "1px solid #007afe",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#007aff",
      "&:hover": {
        borderColor: "#0046b7",
        backgroundColor: "#0046b7",
        color: "#FFFFFF",
      },
    },
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: 8,
      borderLeft: "1px solid #007aff",
    },
    "&:first-of-type": {
      borderRadius: 8,
    },
  },
}));

const StyledBoxSearchButton = styled(Box)({
  marginTop: 50,
  display: "flex",
  justifyContent: "space-evenly",
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const TimeManagementVendorPage = () => {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: attendanceList } = useSelector((state) => state.attendance);
  const { result: payrollSettingList } = useSelector(
    (state) => state.payrollSetting
  );
  const [view, setView] = React.useState("daily");
  const [search, setSearch] = React.useState({
    start: dayjs(today).set("date", 1),
    end: dayjs(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
  });
  const [tempSearchDate, setTempSearchDate] = useState({
    start: new Date(today.getFullYear(), today.getMonth(), 1),
    end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
    text: "",
  });
  const [company, setCompany] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [payrollSetting, setPayrollSetting] = useState(null);

  useEffect(() => {
    dispatch(getPayrollSetting());
  }, []);

  useEffect(() => {
    if (payrollSettingList && selectedEmployee) {
      const findPayroll = payrollSettingList.find(
        (x) => x.idCompany === selectedEmployee.idCompany
      );
      setPayrollSetting(findPayroll ? findPayroll : payrollSettingList[0]);
    }
  }, [selectedEmployee, payrollSettingList]);

  const handleChangeView = (event, newView) => {
    if (newView) {
      setView(newView);
      setSelectedEmployee(null);
      // if (newView === "all") {
      //   dispatch(getHoliday(company.idCompany, tempSearchDate));
      //   dispatch(getShiftPattern(company.idCompany));
      //   dispatch(getAttendanceCompany(company.idCompany, search));
      // }
    }
  };

  const handleSelectEmployee = (employee) => {
    dispatch(clearAttendance());
    setSelectedEmployee(employee);
  };

  const handleOnClickSearch = () => {
    setSearch(tempSearchDate);
    if (view === "individual" && selectedEmployee) {
      dispatch(
        getAttendanceCompanyByIdEmp(
          tempSearchDate,
          selectedEmployee.idCompany,
          selectedEmployee.idEmp
        )
      );
    } else {
      // dispatch(getHoliday(company.idCompany, tempSearchDate));
      // dispatch(getShiftPattern(company.idCompany));
      // dispatch(getAttendanceCompany(company.idCompany, search));
    }
  };

  const excelExport = () => {
    const elementList = [];
    for (let index = 0; index < attendanceList.length; index++) {
      const element = {
        วันที่: dayjs(attendanceList[index].date).format("D/MM/YYYY (ddd)"),
        กะ:
          attendanceList[index].pattern.idWorkingType === 1
            ? `${attendanceList[index].pattern.nameShiftGroup}  (${attendanceList[index].pattern.nameShift})`
            : `${attendanceList[index].pattern.nameShiftGroup}`,
        เวลาทำงาน:
          attendanceList[index].pattern.isWorkingDay === 1
            ? `${dayjs(attendanceList[index].pattern.timeIn, "HH:mm:ss").format(
                "HH:mm"
              )}-${dayjs(
                attendanceList[index].pattern.timeOut,
                "HH:mm:ss"
              ).format("HH:mm")}`
            : "วันหยุด",
        "เข้างาน-ออกงาน_1": attendanceList[index]
          ? `${
              attendanceList[index].attendance.round1.checkIn
                ? attendanceList[index].attendance.round1.checkIn
                    .attendanceTextTime
                : ""
            } - ${
              attendanceList[index].attendance.round1.checkOut
                ? attendanceList[index].attendance.round1.checkOut
                    .attendanceTextTime
                : ""
            }`
          : "-",
        "เข้างาน-ออกงาน_2": attendanceList[index]
          ? `${
              attendanceList[index].attendance.round2.checkIn
                ? attendanceList[index].attendance.round2.checkIn
                    .attendanceTextTime
                : ""
            } - ${
              attendanceList[index].attendance.round2.checkOut
                ? attendanceList[index].attendance.round2.checkOut
                    .attendanceTextTime
                : ""
            }`
          : "-",
        รับรองเวลาทำงาน_1: attendanceList[index]
          ? `${
              attendanceList[index].requestTime[0] &&
              attendanceList[index].requestTime[0].isManagerLV1Approve === 1
                ? `${attendanceList[index].requestTime[0].startText} - ${attendanceList[index].requestTime[0].endText}`
                : ""
            }`
          : "-",
        รับรองเวลาทำงาน_2: attendanceList[index]
          ? `${
              attendanceList[index].requestTime[1] &&
              attendanceList[index].requestTime[1].isManagerLV1Approve === 1
                ? `${attendanceList[index].requestTime[1].startText} - ${attendanceList[index].requestTime[1].endText}`
                : "-"
            }`
          : "-",
        ทำงานล่วงเวลา_1: attendanceList[index]
          ? `${
              attendanceList[index].ot[0]
                ? `${attendanceList[index].ot[0].startText} - ${attendanceList[index].ot[0].endText}`
                : "-"
            }`
          : "-",
        [`OT ${payrollSetting.xWorkingMonthlyHoliday}x`]: attendanceList[index]
          ? `${
              attendanceList[index].ot[0] &&
              attendanceList[index].ot[0].isManagerLV1Approve === 1
                ? attendanceList[index].ot[0].xWorkingMonthlyHoliday > 0
                  ? `${parseFloat(
                      attendanceList[index].ot[0].xWorkingMonthlyHoliday / 60
                    ).toFixed(2)} ชม.`
                  : ""
                : "-"
            }`
          : "-",
        [`OT ${payrollSetting.xOT}x`]: attendanceList[index]
          ? `${
              attendanceList[index].ot[0] &&
              attendanceList[index].ot[0].isManagerLV1Approve === 1
                ? attendanceList[index].ot[0].xOT > 0
                  ? `${parseFloat(attendanceList[index].ot[0].xOT / 60).toFixed(
                      2
                    )} ชม.`
                  : ""
                : "-"
            }`
          : "-",
        [`OT ${payrollSetting.xWorkingDailyHoliday}x`]: attendanceList[index]
          ? `${
              attendanceList[index].ot[0] &&
              attendanceList[index].ot[0].isManagerLV1Approve === 1
                ? attendanceList[index].ot[0].xWorkingDailyHoliday > 0
                  ? `${parseFloat(
                      attendanceList[index].ot[0].xWorkingDailyHoliday / 60
                    ).toFixed(2)} ชม.`
                  : ""
                : "-"
            }`
          : "-",
        [`OT ${payrollSetting.xOTHoliday}x`]: attendanceList[index]
          ? `${
              attendanceList[index].ot[0] &&
              attendanceList[index].ot[0].isManagerLV2Approve === 1
                ? attendanceList[index].ot[0].xOTHoliday > 0
                  ? `${parseFloat(
                      attendanceList[index].ot[0].xOTHoliday / 60
                    ).toFixed(2)} ชม.`
                  : ""
                : "-"
            }`
          : "-",
        การลางาน: attendanceList[index]
          ? `${
              attendanceList[index].leave[0] &&
              attendanceList[index].leave[0].isApprove === 1
                ? attendanceList[index].leave[0].name
                : ""
            }`
          : "-",
        การลางาน_เวลา: attendanceList[index]
          ? `${
              attendanceList[index].leave[0] &&
              attendanceList[index].leave[0].isApprove === 1
                ? `${attendanceList[index].leave[0].startText} - ${attendanceList[index].leave[0].endText}`
                : ""
            }`
          : "-",
        เหตุผลลางาน: attendanceList[index]
          ? `${
              attendanceList[index].leave[0] &&
              attendanceList[index].leave[0].isApprove === 1
                ? `${attendanceList[index].leave[0].description}`
                : ""
            }`
          : "-",
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);

    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "รายงาน");
    XLSX.writeFile(
      workbook,
      `รายงานเวลาทำงาน ${selectedEmployee.employeeId}_${selectedEmployee.firstname}_${selectedEmployee.lastname}.xlsx`,
      { type: "file" }
    );
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {t("TimeManagement")}
          </Typography>
        </div>
        <Fragment>
          <StyledToggleButtonGroup
            value={view}
            exclusive
            onChange={handleChangeView}
            aria-label="view"
            size="small"
          >
            <ToggleButton
              name="status"
              value="daily"
              aria-label="daily"
              size="small"
              style={{ minWidth: 150, marginRight: 12 }}
            >
              {t("Daily")}
            </ToggleButton>
            <ToggleButton
              name="status"
              value="individual"
              aria-label="individual"
              size="small"
              style={{ minWidth: 150, marginRight: 12 }}
            >
              {t("Personal")}
            </ToggleButton>
            {/* <ToggleButton
              name="status"
              value="all"
              aria-label="all"
              size="small"
              style={{ minWidth: 150 }}
            >
              ข้อมูลทั้งหมด
            </ToggleButton> */}
          </StyledToggleButtonGroup>
          {view === "daily" ? (
            <DailyPanel company={company} />
          ) : (
            (view === "individual" || view === "all") && (
              <Fragment>
                <Grid
                  container
                  spacing={2}
                  style={{ marginBottom: 16 }}
                  alignItems={"center"}
                >
                  <Grid item xs={12} sm={3}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.third">
                        {t("StartDate")}
                      </Typography>
                      <div className="search-date">
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={th}
                        >
                          <DatePicker
                            inputFormat="dd/MM/yyyy"
                            value={tempSearchDate.start}
                            name="start"
                            onChange={(newValue) => {
                              setTempSearchDate({
                                ...tempSearchDate,
                                ["start"]: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <StyledTextField
                                variant="filled"
                                fullWidth
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </StyledBoxSearch>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.third">
                        {t("EndDate")}
                      </Typography>
                      <div className="search-date">
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={th}
                        >
                          <DatePicker
                            minDate={tempSearchDate.start}
                            inputFormat="dd/MM/yyyy"
                            value={tempSearchDate.end}
                            name="end"
                            onChange={(newValue) => {
                              setTempSearchDate({
                                ...tempSearchDate,
                                ["end"]: newValue,
                              });
                            }}
                            renderInput={(params) => (
                              <StyledTextField
                                variant="filled"
                                fullWidth
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </StyledBoxSearch>
                  </Grid>
                  {view === "individual" && (
                    <Grid item xs={12} sm={3}>
                      <SelectEmployeesManager
                        handleChange={handleSelectEmployee}
                        company={company}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <StyledBoxSearchButton>
                      <div>
                        <ButtonBlue
                          variant="contained"
                          startIcon={<SearchIcon />}
                          onClick={handleOnClickSearch}
                        >
                          {t("Search")}
                        </ButtonBlue>
                      </div>
                      <div>
                        <ButtonBlue
                          onClick={excelExport}
                          variant="outlined"
                          startIcon={<DownloadRoundedIcon />}
                          disabled={selectedEmployee === null}
                        >
                          {t("Download")}
                        </ButtonBlue>
                      </div>
                    </StyledBoxSearchButton>
                  </Grid>
                </Grid>
                {view === "individual" ? (
                  <Fragment>
                    <Typography variant="h4" style={{ paddingTop: 8 }}>
                      {t("TimeTable")}
                    </Typography>
                    {selectedEmployee && (
                      <Individual
                        company={company}
                        employee={selectedEmployee}
                        search={search}
                      />
                    )}
                  </Fragment>
                ) : (
                  <Timesheet company={company} searchDate={search} />
                )}
              </Fragment>
            )
          )}
        </Fragment>
      </Container>
    </StyledRoot>
  );
};

export default TimeManagementVendorPage;
