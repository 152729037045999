import {
    USER_BENEFICIARYS_FETCHING,
    USER_BENEFICIARYS_FAILED,
    USER_BENEFICIARYS_SUCCESS
} from './types';

import BeneficiaryService from "../services/beneficiary.service";

export const getBeneficiaryUser = (idEmp) => async (dispatch) => {
    try {
        dispatch({
            type: USER_BENEFICIARYS_FETCHING
        })
        const res = await BeneficiaryService.getBeneficiaryUser(idEmp);
        if (res) {
            dispatch({
                type: USER_BENEFICIARYS_SUCCESS,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch({
            type: USER_BENEFICIARYS_FAILED
        });
    }
};

export const addBeneficiary = (formData) => async () => {
    try {
        const res = await BeneficiaryService.addBeneficiary(formData);
        if(res){
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const updateBeneficiary = (formData, idBeneficiary) => async () => {
    try {
        const res = await BeneficiaryService.updateBeneficiary(formData, idBeneficiary);
        if(res){
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const deleteBeneficiary = (idBeneficiary) => async () => {
    try {
        const res = await BeneficiaryService.deleteBeneficiary(idBeneficiary);
        if(res){
            return res;
        }
    } catch (error) {
        return error;
    }
};