import dayjs from "dayjs";
import { httpClient } from "./httpClient";

const getEmployeeProfile = (id, filter) => {
  if (filter) {
    return httpClient.get(`employees/${id}?filter=${filter}`);
  } else {
    return httpClient.get(`employees/${id}`);
  }
};

const getEmployeesProfileByIdForCompany = (id, filter) => {
  if (filter) {
    return httpClient.get(`company/employees/${id}?filter=${filter}`);
  } else {
    return httpClient.get(`company/employees/${id}`);
  }
};

const deleteManagerDetail = (employeeProfile, idManagerEmployee) => {
  return httpClient.delete(
    `manager/${idManagerEmployee}/users/${employeeProfile.idEmp}`
  );
};

const getAllEmployees = (filter) => {
  if (filter) {
    return httpClient.get(`employees?filter=${filter}`);
  } else {
    return httpClient.get("employees");
  }
};

const getPaymentType = () => {
  return httpClient.get("employees/payment-type");
};

const getLeaveQuota = (idEmp, idVendor, dateSearch) => {
  if (idEmp) {
    return httpClient.get(`employee/${idEmp}/leave?idVendor=${idVendor}&year=${dateSearch}`);
  } else {
    return httpClient.get("employee/leave");
  }
};

const updateLeaveCarry = (idEmp, formData) => {
  return httpClient.put(`employee/${idEmp}/leave/carry`, formData);
};

// const getLeaveRequest = (startDate) => {
//   return httpClient.get(
//     `employee/request/leave?year=${dayjs(startDate).format("YYYY")}`
//   );
// };

const getLeaveRequest = (filter, search) => {
  if (search) {
    return httpClient.get(
      `request/leave?filter=${filter}&start=${dayjs(search.start).format(
        "YYYY-MM-DD"
      )}&end=${dayjs(search.end).format("YYYY-MM-DD")}`
    );
  } else {
    return httpClient.get(`request/leave?filter=${filter}`);
  }
};

const getLeaveRequestAllYear = (filter, idEmp) => {
  if (idEmp) {
    return httpClient.get(`employee/${idEmp}/request/leave?year=${filter}`);
  } else {
    return httpClient.get(`request/leave/year?filter=${filter}`);
  }
};

const getLeaveRequestOnce = (id, startDate) => {
  if (id) {
    return httpClient.get(
      `company/${id}/employee/request/leave?date=${startDate}`
    );
  } else {
    return httpClient.get(`request/leave?date=${startDate}`);
  }
};

const getLeaveRequestByCompany = (id, dateFilter) => {
  return httpClient.get(
    `company/${id}/employee/request/leave?start=${dayjs(
      dateFilter.start
    ).format("YYYY-MM-DD")}&end=${dayjs(dateFilter.end).format("YYYY-MM-DD")}`
  );
};

const getAllDataForm = () => {
  return httpClient.get("employees/data-form");
};

const employeeChangeShift = (data) => {
  return httpClient.post("employees/changeShift", data);
};

const getAllEmployeesNotInShift = (idShiftGroup) => {
  return httpClient.get("employees/shift/not/" + idShiftGroup);
};

const getAllEmployeesShift = (idShiftGroup, searchDate, idCompany) => {
  if (idShiftGroup && searchDate) {
    if (idCompany) {
      return httpClient.get(
        `company/${idCompany}/employees/shift/${idShiftGroup}?start=${dayjs(
          searchDate.start
        ).format("YYYY-MM")}`
      );
    } else {
      return httpClient.get(
        `employees/shift/${idShiftGroup}?start=${dayjs(searchDate.start).format(
          "YYYY-MM"
        )}`
      );
    }
  } else if (idShiftGroup) {
    return httpClient.get(`employees/shift/${idShiftGroup}`);
  } else {
    return httpClient.get("employees/shift");
  }
};

const getEmployeeShiftHistory = (idShiftGroup) => {
  return httpClient.get("employees/Shift/History/" + idShiftGroup);
};

const updateEmployeeShiftHistory = (data) => {
  return httpClient.put("employees/Shift/History/Update", data);
};

const getAllEmployeesByShiftGroup = (idShiftGroup) => {
  return httpClient.get("employees/ShiftGroup/" + idShiftGroup);
};

const getEmployeesSearchByCompany = (filter) => {
  return httpClient.get(`employees/search?filter=${filter}`);
};

const getEmployeesSearchByVendor = () => {
  return httpClient.get(`employees/search?filter=vendor`);
};

const getEmployeesPublic = () => {
  return httpClient.get(`/employees/public`);
};

const addEmployeeAdditionDeduction = (formData) => {
  return httpClient.post(`/employees/addition-deduction`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const employeeSalary = (formData, mode) => {
  if (mode === "edit") {
    return httpClient.put(`/employees/change-salary?mode=edit`, formData);
  } else {
    return httpClient.put(`/employees/change-salary`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
};

const employeeEmployeeTypeChange = (formData) => {
  return httpClient.put(`/employees/change-employee-type`, formData);
};

const employeeManagerUploadFile = (formData) => {
  return httpClient.put(`/employees/change-manager?mode=upload`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const employeeManagerUpdate = (formData) => {
  return httpClient.put(`/employees/change-manager`, formData);
};

const employeeDeduction = (formData) => {
  return httpClient.post(`/employees/deduction`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getEmployeeAdditionDeduction = (idEmp) => {
  return httpClient.get(`employees/${idEmp}/addition-deduction`);
};

const updateEmployeeAdditionDeduction = (idEmp, formData) => {
  return httpClient.put(`employees/${idEmp}/addition-deduction`, formData);
};

const deleteEmployeeAddition = (idEmp, idEmployeeAddition) => {
  return httpClient.delete(
    `employees/${idEmp}/addition-deduction/addition/${idEmployeeAddition}`
  );
};

const deleteEmployeeDeduction = (idEmp, idEmployeeDeduction) => {
  return httpClient.delete(
    `employees/${idEmp}/addition-deduction/deduction/${idEmployeeDeduction}`
  );
};

const updateEmployeePosition = (formData) => {
  return httpClient.put("employees/position", formData);
};

const getEmployeePositionChange = (idEmp) => {
  return httpClient.get(`employees/${idEmp}/position/change`);
};

const getEmployeeSalaryChange = (idEmp) => {
  return httpClient.get(`employees/${idEmp}/salary/change`);
};

const getEmployeeEmployeeTypeChange = (idEmp) => {
  return httpClient.get(`employees/${idEmp}/employee-type/change`);
};

const employeeChangeTime = (data) => {
  return httpClient.post("employees/change-time", data);
};

const deleteEmployeeById = (idEmp) => {
  return httpClient.delete(`employees/${idEmp}`);
};

const getAllEmployeesAllRolesByIdCompany = (idCompany) => {
  return httpClient.get(`employees-allRoles-byCompany/${idCompany}`);
};

const getEmployeeDataWritePDF = (
  type,
  year,
  idDepartment,
  idCompany,
  idEmployee
) => {
  return httpClient.get(
    `employees/data-writePDF/${type}/${year}/${idDepartment}/${idCompany}/${idEmployee}`
  );
};

const updateEmployeeProfileManager = (formData) => {
  return httpClient.put("employee/manager", formData);
};

const addEmployeeContractByIdEmp = (formData) => {
  return httpClient.post("employee/contract", formData);
};

const employeeAddPerformance = (formData) => {
  return httpClient.post("employees/performance", formData);
};

const uploadProfileImage = (formData) => {
  return httpClient.put("employee/upload-profile", formData);
};

const updateEmployeeSalaryUserProfile = (formData) => {
  return httpClient.put("employee/update-salary", formData);
};

const addEmployeePersonnelLevel = (data) => {
  return httpClient.post("employee/add-personnel-level", data);
};

const updateEmployeePersonnelLevel = (data) => {
  return httpClient.put("employee/update-personnel-level", data);
};

const getEmployeeShiftChange = (idEmp) => {
  return httpClient.get(`employees/${idEmp}/shift/change`);
};

const addEmployeeShiftChange = (formData) => {
  return httpClient.post(`employees/shift-change`, formData);
};

const updateEmployeeShiftChange = (idEmployeeShift, formData) => {
  return httpClient.put(`employees/shift-change/${idEmployeeShift}`, formData);
};

const deleteEmployeeShiftChange = (idEmployeeShift) => {
  return httpClient.delete(`employees/shift-change/${idEmployeeShift}`);
};

const getEmployeesDetails = (selectedYear, selectedEmployee, type) => {
  if (selectedYear) {
    return httpClient.get(`employeesDetails?selectedYear=${selectedYear - 543}&selectedEmployee=${selectedEmployee}&type=${type}`);
  }
}

const getEmployeesPFvalue = (query) => {
  return httpClient.get(`employeesDetails`, { params: query });
};

const getEmployeeShiftChangeHistory = (idEmp, query) => {
  return httpClient.get(`employees/${idEmp}/shift/history`, { params: query });
}

const patchEmployeeShiftChangeHistoryStandard = (idEmp, idEmployeeShift, formData) => {
  return httpClient.patch(`employees/${idEmp}/shift/history/standard/${idEmployeeShift}`, formData);
}

const deleteEmployeeShiftChangeHistoryStandard = (idEmp, idEmployeeShift) => {
  return httpClient.delete(`employees/${idEmp}/shift/history/standard/${idEmployeeShift}`);
}

const patchEmployeeShiftChangeHistoryDaily = (idEmp, idEmployeeShiftDaily, formData) => {
  return httpClient.patch(`employees/${idEmp}/shift/history/daily/${idEmployeeShiftDaily}`, formData);
}

const deleteEmployeeShiftChangeHistoryDaily = (idEmp, idEmployeeShiftDaily) => {
  return httpClient.delete(`employees/${idEmp}/shift/history/daily/${idEmployeeShiftDaily}`);
}

const getEmployeeLeaveRequest = (idEmp, query) => {
  return httpClient.get(`employees/${idEmp}/leave/request`, { params: query });
}

const getEmployeePersonnelLevelChange = (idEmp) => {
  return httpClient.get(`employees/${idEmp}/personnel-level-change`);
}

const changeEmployeePersonnelLevel = (formData) => {
  return httpClient.post(`/employee-personnel-level`, formData);
}

const getEmployeeProbationStatus = (query) => {
  return httpClient.get(`employees-probation-status`, { params: query });
}

const getEmployeesDetailsTaxDeduction = (query) => {
  return httpClient.get(`employees-tax-deduction`, { params: query });
}

const getSummaryTaxGroupedByEmployee = (query) => {
  return httpClient.get("employee-tax-remittance", { params: query });
}

export default {
  employeeSalary,
  employeeManagerUploadFile,
  employeeManagerUpdate,
  getEmployeeProfile,
  getLeaveQuota,
  updateLeaveCarry,
  getLeaveRequest,
  getLeaveRequestAllYear,
  getLeaveRequestOnce,
  getLeaveRequestByCompany,
  getAllEmployees,
  getPaymentType,
  deleteManagerDetail,
  employeeChangeTime,
  getAllDataForm,
  employeeChangeShift,
  getAllEmployeesShift,
  getAllEmployeesNotInShift,
  getAllEmployeesByShiftGroup,
  getEmployeeShiftHistory,
  updateEmployeeShiftHistory,
  getEmployeesSearchByCompany,
  getEmployeesSearchByVendor,
  getEmployeesPublic,
  addEmployeeAdditionDeduction,
  employeeDeduction,
  getEmployeeAdditionDeduction,
  updateEmployeeAdditionDeduction,
  deleteEmployeeAddition,
  deleteEmployeeDeduction,
  updateEmployeePosition,
  getEmployeePositionChange,
  getEmployeeSalaryChange,
  getEmployeeEmployeeTypeChange,
  employeeEmployeeTypeChange,
  deleteEmployeeById,
  getAllEmployeesAllRolesByIdCompany,
  getEmployeeDataWritePDF,
  updateEmployeeProfileManager,
  addEmployeeContractByIdEmp,
  employeeAddPerformance,
  uploadProfileImage,
  updateEmployeeSalaryUserProfile,
  getEmployeesProfileByIdForCompany,
  addEmployeePersonnelLevel,
  updateEmployeePersonnelLevel,
  getEmployeeShiftChange,
  addEmployeeShiftChange,
  updateEmployeeShiftChange,
  deleteEmployeeShiftChange,
  getEmployeesDetails,
  getEmployeeShiftChangeHistory,
  patchEmployeeShiftChangeHistoryStandard,
  deleteEmployeeShiftChangeHistoryStandard,
  patchEmployeeShiftChangeHistoryDaily,
  deleteEmployeeShiftChangeHistoryDaily,
  getEmployeeLeaveRequest,
  getEmployeePersonnelLevelChange,
  changeEmployeePersonnelLevel,
  getEmployeeProbationStatus,
  getEmployeesPFvalue,
  getEmployeesDetailsTaxDeduction,
  getSummaryTaxGroupedByEmployee
};
