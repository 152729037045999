import {
  EXPENSE_EXPORT_FETCHING,
  EXPENSE_EXPORT_FAILED,
  EXPENSE_EXPORT_SUCCESS,
} from "./types";

import expense_exportService from "../services/expenseExport.service";

export const getdataEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPENSE_EXPORT_FETCHING,
    });
    const res = await expense_exportService.getdataEmployee();
    if (res) {
      dispatch({
        type: EXPENSE_EXPORT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: EXPENSE_EXPORT_FAILED,
    });
    console.log(err);
  }
};
