import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { CardContent, Typography, Container } from "@mui/material";

import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import LateAbsentPanel from "./late-absent";
import GeneralPanel from "./general";
import OtShiftPanel from "./ot-shift";

const StyledRoot = styled("div")({
  minHeight: 700,
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
    marginTop: 16,
  },
});

const StyledCardContent = styled(CardContent)({
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .sub-header-1": {
    fontWeight: 500,
    fontSize: 18,
  },
  "& .sub-header": {
    fontWeight: 500,
  },
  "& .header-item": {
    fontSize: "1.25rem",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  "& .wrap-item-data": {
    marginLeft: 16,
    marginBottom: 8,
  },
  "& .wrap-check": {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-root":{
      paddingLeft: 0
    }
  },
  "& .item-name": {
    fontWeight: 600,
    marginBottom: 4,
  },
  "& .wrap-label-switch": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .custom-date-payment": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    alignItems: "center",
  },
});

const PayrollSettingPage = () => {
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isPayroll, setIsPayroll] = useState(null);
  const [dataForm, setDataForm] = useState({
    paymentPeriod: "0",
    cutOff: "-1",
    approve: "18",
    approveNextMonth: false,
    calculateOt: "previous​",
    payslipDate: "25",
    payslipNextMonth: false,
    payslipTime: "08:00",
    payDateMethod: "auto",
    payDate: "28",
    payDayoff: false,
    payDateYear: dayjs(today).format("YYYY"),
    cutOffTwo: "15",
    startOne: "1",
    endOne: "15",
    startTwo: "16",
    endTwo: "-1",
    late: "1",
    absent: "1",
  });

  const [showHide, setShowHide] = useState({
    general: "hide",
    lateAbsent: "hide",
    otShift: "hide",
  });

  useEffect(() => {
    if (userProfile) {
      if (
        userProfile.isSuperAdmin === 1 ||
        (userProfile.confidentialData.length === 1 &&
          (userProfile.confidentialData[0].idCompany === null ||
            userProfile.confidentialData[0].idCompany !== null) &&
          userProfile.confidentialData[0].isPayroll === 1)
      ) {
        setIsPayroll(true);
      } else if (userProfile.confidentialData.length > 1) {
        const foundIsPayroll = userProfile.confidentialData.find(
          (x) => x.isPayroll === 1
        );
        if (foundIsPayroll) {
          setIsPayroll(true);
        } else {
          setIsPayroll(false);
        }
      } else {
        setIsPayroll(false);
      }
    }
  }, [userProfile]);

  const handleOnChangeData = (event) => {
    const { name, value } = event.target;
    setDataForm({ ...dataForm, name: value });
  };

  return (
    <Fragment>
      {userProfile && (
        <Fragment>
          {isPayroll === false ? (
            <Route>
              <Redirect to="/unauthorized" />
            </Route>
          ) : (
            <StyledRoot className={`page`}>
              <Container maxWidth="lg">
                <div style={{ marginBottom: 8 }}>
                  <Typography variant="h4" style={{ paddingTop: 8 }}>
                    ตั้งค่าจัดการเงินเดือน
                  </Typography>
                </div>
                <StyledCard style={{ marginBottom: 16 }}>
                  <StyledCardContent>
                    <div className="wrap-header">
                      <Typography className="header-item" gutterBottom>
                        ตั้งค่าทั่วไป
                      </Typography>
                      <div>
                        <ButtonBlue
                          onClick={() => {
                            setShowHide({
                              ...showHide,
                              general:
                                showHide.general === "hide" ? "show" : "hide",
                            });
                          }}
                        >
                          {showHide.general === "hide" ? "แสดง" : "ซ่อน"}
                        </ButtonBlue>
                      </div>
                    </div>
                    {showHide.general === "show" && (
                      <GeneralPanel
                        dataForm={dataForm}
                        handleOnChangeData={handleOnChangeData}
                      />
                    )}
                  </StyledCardContent>
                </StyledCard>
                <StyledCard style={{ marginBottom: 16 }}>
                  <StyledCardContent>
                    <div className="wrap-header">
                      <Typography className="header-item" gutterBottom>
                        ตั้งค่ามาสาย/ขาดงาน
                      </Typography>
                      <div>
                        <ButtonBlue
                          onClick={() => {
                            setShowHide({
                              ...showHide,
                              lateAbsent:
                                showHide.lateAbsent === "hide"
                                  ? "show"
                                  : "hide",
                            });
                          }}
                        >
                          {showHide.lateAbsent === "hide" ? "แสดง" : "ซ่อน"}
                        </ButtonBlue>
                      </div>
                    </div>
                    {showHide.lateAbsent === "show" && (
                      <LateAbsentPanel
                        dataForm={dataForm}
                        handleOnChangeData={handleOnChangeData}
                      />
                    )}
                  </StyledCardContent>
                </StyledCard>
                <StyledCard style={{ marginBottom: 16 }}>
                  <StyledCardContent>
                    <div className="wrap-header">
                      <Typography className="header-item" gutterBottom>
                        ตั้งค่าทำงานล่วงเวลา/ค่ากะทำงาน
                      </Typography>
                      <div>
                        <ButtonBlue
                          onClick={() => {
                            setShowHide({
                              ...showHide,
                              otShift:
                                showHide.otShift === "hide" ? "show" : "hide",
                            });
                          }}
                        >
                          {showHide.otShift === "hide" ? "แสดง" : "ซ่อน"}
                        </ButtonBlue>
                      </div>
                    </div>
                    {showHide.otShift === "show" && (
                      <OtShiftPanel
                        dataForm={dataForm}
                        handleOnChangeData={handleOnChangeData}
                      />
                    )}
                  </StyledCardContent>
                </StyledCard>
              </Container>
            </StyledRoot>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default PayrollSettingPage;
