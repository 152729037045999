import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm, useFieldArray } from 'react-hook-form';

import {
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Typography
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import ButtonBlue from '../../../shared/general/ButtonBlue';
import DrawerCustom from '../../../shared/general/Drawer';
import TextFieldTheme from '../../../shared/general/TextFieldTheme';

import { 
    addPerformanceByIdCompany,
    getPerformanceByIdCompany,
    updatePerformanceByIdCompany
} from "../../../../../actions/performance";

const DialogPerformance = (props) => {
    const { open, onClose, mode, data, handleOpenAlert } = props;
    const dispatch = useDispatch();
    const { result: performanceCompany } = useSelector((state) => state.performanceCompany);
    const { control, handleSubmit, reset } = useForm({
        defaultValues:{
            list:[
                { performanceName:"" }
            ]
        }
    });

    useEffect(() => {
        if(data){
            reset({
                list: [
                    { 
                        idPerformance: data.idPerformance,
                        performanceName: data.performanceName 
                    }
                ]
            })
        }
    },[data]);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "list"
    });

    const onHandleSubmit = async (value) => {
        let res;
        if(mode === "add"){
            let maxLevel = (performanceCompany && performanceCompany.length);
            let formatData = [];
            value.list.map((per) => {
                maxLevel++;
                formatData.push({...per, level: maxLevel});
            })
            res = await dispatch(addPerformanceByIdCompany({ list: formatData }));
        } else {
            res = await dispatch(updatePerformanceByIdCompany(value.list[0]));
        }
        if(res.status === 200){
            dispatch(getPerformanceByIdCompany());
            handleOpenAlert("success");
            onClose();
        } else {
            handleOpenAlert("error");
        }
    };

    return (
        <DrawerCustom
            anchor="right"
            open={open}
            title={`${mode === "add" ? "เพิ่ม" : "แก้ไข"}ผลประเมิน`}
            onClose={onClose}
        >
            <div style={{ width:"300px", padding:"16px" }}>
                <form onSubmit={handleSubmit(onHandleSubmit)}>
                    <div>
                        {fields.map((value, index) => {
                            if(performanceCompany && performanceCompany.length < 6){
                                return (
                                    <Fragment key={value.id}>
                                        {index !== 0 && 
                                            <Divider 
                                                style={{ 
                                                    borderBottom:"1px dashed #CDCDCD", 
                                                    marginTop:"16px",
                                                    marginBottom:"16px"
                                                }} 
                                            />
                                        }
                                        <div 
                                            style={{ 
                                                display:"flex", 
                                                justifyContent:"space-between" 
                                            }}
                                        >
                                            <Typography
                                                style={{ marginBottom:"8px" }}
                                                variant='h6'
                                            >{`ระดับที่ ${mode === "add" ? (performanceCompany.length + (index + 1)) : data.level}`}</Typography>
                                            {fields.length > 1 &&
                                                <IconButton
                                                    style={{ color:"#FF0000" }}
                                                    onClick={() => remove(index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        </div>
                                        <Typography
                                            style={{ marginBottom:"8px" }}
                                        >{"ชื่อผลประเมิน"}</Typography>
                                        <Controller
                                            control={control}
                                            name={`list[${index}].performanceName`}
                                            render={({ field }) => (
                                                <TextFieldTheme 
                                                    style={{ marginBottom:"16px" }}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Fragment>
                                );
                            }
                        })}
                        {(mode === "add" && (
                            ((performanceCompany && performanceCompany.length) || 0) + fields.length
                        ) < 6) && 
                            <ButtonBlue
                                variant="contained"
                                style={{ 
                                    width:"100%",
                                    marginTop:"16px"
                                }}
                                onClick={() => { append({ performanceName:"", level:"" })}}
                            >
                                <AddIcon style={{ marginRight:"8px" }} />เพิ่ม
                            </ButtonBlue>
                        }
                    </div>
                    <div
                        style={{ 
                            width:"100%",
                            display:"flex", 
                            justifyContent:"flex-end",
                            marginTop:"16px"
                        }}
                    >
                        <ButtonBlue
                            style={{ marginRight:"8px" }}
                            onClick={onClose}
                        >
                            ยกเลิก
                        </ButtonBlue>
                        <ButtonBlue
                            variant="contained"
                            type="submit"
                        >
                            บันทึก
                        </ButtonBlue>
                    </div>
                </form>
            </div>
        </DrawerCustom>
    );
};

export default DialogPerformance;