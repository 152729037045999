import { styled } from "@mui/material/styles";
import React, { Fragment } from "react";

import { Typography } from "@mui/material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import ButtonLoadingBlue from "../../../../shared/general/ButtonLoadingBlue";

import XlsxIcon from "../../../../assets/xlsx.png";

const StyledShowSelected = styled("div")({
  maxWidth: 450,
  margin: "auto",
  display: "flex",
  "& img": {
    marginRight: 8,
  },
});

const StyledBtnSave = styled("div")({
    maxWidth: 450,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    marginTop: 36,
  });

const FileSelected = (props) => {
  const { handleClickUpload, fileSelected, loading } = props;
  return (
    <Fragment>
      <StyledShowSelected>
        <img src={XlsxIcon} width="60" />
        <div>
          <Typography>{fileSelected.name}</Typography>
          <Typography color="text.third">
            {(fileSelected.size / 1024 / 1024).toFixed(2)} MB
          </Typography>
        </div>
      </StyledShowSelected>
      <StyledBtnSave>
        <div>
          <ButtonLoadingBlue loading={loading} variant="contained" onClick={handleClickUpload}>
            อัพโหลดข้อมูล
          </ButtonLoadingBlue>
        </div>
      </StyledBtnSave>
    </Fragment>
  );
};

export default FileSelected;
