import { styled } from "@mui/material/styles";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import ButtonBlue from "../../../shared/general/ButtonBlue";

import XlsxIcon from "../../../assets/xlsx.png";

const StyledShowSelected = styled("div")({
  maxWidth: 450,
  margin: "auto",
  display: "flex",
  "& img": {
    marginRight: 8,
  },
});

const StyledBtnSave = styled("div")({
  maxWidth: 450,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  marginTop: 36,
});

const FileSelected = (props) => {
  const { handleClickUpload, fileSelected, loadingUpload } = props;
  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      <StyledShowSelected>
        <img src={XlsxIcon} width="60" />
        <div>
          <Typography>{fileSelected.name}</Typography>
          <Typography color="text.third">
            {(fileSelected.size / 1024 / 1024).toFixed(2)} MB
          </Typography>
        </div>
      </StyledShowSelected>
      <StyledBtnSave>
        <div>
          <LoadingButton
            loading={loadingUpload}
            loadingPosition="start"
            variant="contained"
            onClick={handleClickUpload}
          >
            {t("Upload")}
          </LoadingButton>
          {/* <ButtonBlue variant="contained" onClick={handleClickUpload}>
            อัพโหลดข้อมูล
          </ButtonBlue> */}
        </div>
      </StyledBtnSave>
    </Fragment>
  );
};

export default FileSelected;
