import React from "react";
import { Grid, MenuItem, styled, Typography } from "@mui/material";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from 'react-hook-form';
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";

const StyledRoot = styled("div")({
  padding: "24px",
});

const initialValues = {
  fromYear: "",
  endYear: "",
  degree: "",
  university: "",
  faculty: "",
  major: "",
  gpa: "",
};

const DialogAddEducation = (props) => {
  const { open, onClose, append } = props;
  const { t } = useTranslation();

  const { control, handleSubmit, formState:{ errors } } = useForm({
    defaultValues: initialValues
  });

  const onHandleSubmit = (value) => {
    append(value);
    onClose();
  };

  return (
    <DrawerCustom
      title={`${t("AddEducation")}`}
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={handleSubmit(onHandleSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography>{t("StartYear")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <Controller 
                  control={control}
                  name="fromYear"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      openTo="year"
                      views={["year"]}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          error={errors && errors.fromYear ? true : false}
                          helperText={errors && errors.fromYear && errors.fromYear.message}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: `${t("Year")}`
                          }}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("EndYear")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <Controller 
                  control={control}
                  name="endYear"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      openTo="year"
                      views={["year"]}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          error={errors && errors.endYear ? true : false}
                          helperText={errors && errors.endYear && errors.endYear.message}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: `${t("Year")}`,
                          }}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("Degree")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller 
                control={control}
                name="degree"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={errors && errors.degree ? true : false}
                    helperText={errors && errors.degree && errors.degree.message}
                    fullWidth
                    select
                  >
                    <MenuItem value="ป.6">{t("PrimarySchool")}</MenuItem>
                    <MenuItem value="ม.3">{t("JuniorHighSchool")}</MenuItem>
                    <MenuItem value="ม.6">{t("SeniorHighSchool")}</MenuItem>
                    <MenuItem value="ปวช.">{t("VocationalCertificate")}</MenuItem>
                    <MenuItem value="ปวส.">{t("HighVocationalCertificate")}</MenuItem>
                    <MenuItem value="ปริญญาตรี">{t("BachelorDegree")}</MenuItem>
                    <MenuItem value="ปริญญาโท">{t("MasterDegree")}</MenuItem>
                    <MenuItem value="ปริญญาเอก">{t("DoctorDegree")}</MenuItem>
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("University")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller 
                control={control}
                name="university"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={errors && errors.university ? true : false}
                    helperText={errors && errors.university && errors.university.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("Faculty")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller 
                control={control}
                name="faculty"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={errors && errors.faculty ? true : false}
                    helperText={errors && errors.faculty && errors.faculty.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("Major")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller 
                control={control}
                name="major"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={errors && errors.major ? true : false}
                    helperText={errors && errors.major && errors.major.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>{t("GPA")}</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Controller 
                control={control}
                name="gpa"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      inputComponent: NumberFormatTheme
                    }}
                    inputProps={{
                      allowNegative: false,
                      value: field.value,
                      onValueChange: (values) => {
                        const { value } = values;
                        field.onChange(value)
                      },
                      isAllowed: (values) => {
                        return values.value <= 4;
                      },
                      decimalScale:2
                    }}
                    error={errors && errors.gpa ? true : false}
                    helperText={errors && errors.gpa && errors.gpa.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant={"outlined"} onClick={onClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                type="submit"
                variant={"contained"}
                startIcon={<SaveIcon />}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogAddEducation;
