import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";

import {
  Box,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import AlertResponse from "../../shared/general/AlertResponse";
import HeaderPage from "./../../shared/header/headerPage";
import ButtonBlue from "../../shared/general/ButtonBlue";

import EmployeeList from "./employeeList";
import UploadResult from "./upload-result";

import iconHeader from "./assets/teamwork.svg";

import DialogDownload from "./DialogDownload";
import DialogUpload from "./DialogUpload";

import { getVendorContract } from "../../../../actions/company";
import { getAllUsersCompany } from "../../../../actions/user";

import "./index.css";

const useStyles = makeStyles((theme) => ({
  btnAddNew: {
    ["@media only screen and (max-width: 600px)"]: {
      display: "none",
    },
  },
  btnIconAddNew: {
    ["@media only screen and (min-width:600px)"]: {
      display: "none",
    },
  },

  divider: {
    margin: "10px 0",
  },
  wrapFilterStatusEmployee: {
    marginTop: 16,
  },
  btnFilterStatusEmployee: {
    "& button": {
      borderRadius: 0,
    },
    "& .MuiToggleButton-root": {
      border: "none",
    },
  },
  wrapFilter: {
    margin: "16px 0",
  },
  formControl: {
    width: "100%",
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 8,
  },
  wrapName: {
    width: 350,
  },
  textName: {
    fontSize: 18,
  },
  textPosition: {},
  statusTag: {
    height: 22,
    minWidth: 22,
    borderRadius: 8,
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "inline-flex",
    justifyContent: "center",
    padding: "0px 8px",
  },
  statusTagActive: {
    color: "rgb(34, 154, 22)",
    backgroundColor: "rgba(84, 214, 44, 0.16)",
  },
  statusTagTerminate: {
    color: "rgb(183, 33, 54)",
    backgroundColor: "rgba(255, 72, 66, 0.16)",
  },
  statusTagLabel: {
    lineHeight: 0,
    fontWeight: 700,
    fontSize: 14,
    textTransform: "capitalize",
  },
  wrapFirstColumn: {
    display: "flex",
    alignItems: "center",
  },
  textOverFlow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  sectionAbout: {
    width: 420,
    display: "flex",
    justifyContent: "space-around",
  },
  labelAbout: {
    fontSize: 12,
  },
  textAbout: {
    fontSize: 14,
  },
  iconAction: {
    marginRight: 16,
  },
  smallScreen: {},
}));

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .wrapHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 16,
    "& .download, .upload": {
      marginRight: 8,
    },
  }, 
  "& .wrap-action-btn": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .btn-add-new": {
      marginLeft: 4,
      "& .MuiButton-root": {
        width: "100%",
      },
      ["@media only screen and (max-width: 600px)"]: {
        display: "none",
      },
    },
  },
});

const EmployeesPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mode, setMode] = useState("list");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [alertLabel, setAlertLabel] = useState("");
  const [openDialogUpload, setOpenDialogUpload] = useState(false);
  const [openDialogDownload, setOpenDialogDownload] = useState(false);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { isFetching: isFetchingEmployees, result: employeeList } = useSelector((state) => state.users);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // if (!employeeList) {
      if (userProfile) {
        dispatch(getAllUsersCompany(userProfile.idCompany, "all"));
        dispatch(getVendorContract());
      }
    // }
  }, [userProfile]);

  const handleOpenAddEmployeeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const toggleDrawerUpload = (open) => {
    setOpenDialogUpload(open);
  };

  const toggleDrawerDownload = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDialogDownload(open);
  };
  
  const handleOpenAlert = (type, label) => {
    setOpenAlert(true);
    setAlertType(type);
    setAlertLabel(label);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <Fragment>
          <div className={`wrapHeader`}>
            <Fragment>
              <HeaderPage textLabel={t("EmployeeList")} icon={iconHeader} />
              {employeeList && employeeList.active && employeeList.active.length > 0 && (
                <Fragment>
                  <div className="wrap-action-btn">
                    <ButtonBlue
                      className="download"
                      variant="outlined"
                      aria-label="download"
                      onClick={toggleDrawerDownload(true)} 
                      startIcon={<FileDownloadIcon />}
                    >
                      {t("Download")}
                    </ButtonBlue>
                    <ButtonBlue
                      className="upload"
                      variant="outlined"
                      aria-label="upload"
                      onClick={() => toggleDrawerUpload(true)}
                      startIcon={<FileUploadIcon />}
                    >
                      {t("Upload")}
                    </ButtonBlue>
                    <div className="add-new">
                      <ButtonBlue
                        variant={"contained"}
                        component={NavLink}
                        to="/admin/employees/register"
                        startIcon={<AddIcon />}
                      >
                        {t("Add_Employee")}
                      </ButtonBlue>
                    </div>
                  </div>
                  <div className={classes.btnIconAddNew}>
                    <IconButton aria-label="add" size="large">
                      <AddIcon />
                    </IconButton>
                  </div>
                </Fragment>
              )}
            </Fragment>
          </div>
          {(employeeList && !isFetchingEmployees) && <EmployeeList handleOpenAlert={handleOpenAlert} />}
          {isFetchingEmployees && 
            <Box
              style={{
                height: 150,
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                justifyContent:"center"
              }}
            >
              <CircularProgress 
                style={{ marginBottom: 16}}
              />
              <Typography align="center">กำลังโหลดข้อมูล</Typography>
            </Box>
          }
        </Fragment>
      </Container>
      {openDialogUpload && <DialogUpload open={openDialogUpload} toggleDrawer={toggleDrawerUpload} />}
      <DialogDownload
        open={openDialogDownload}
        toggleDrawer={toggleDrawerDownload}
      />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
        label={alertLabel}
      />
    </StyledRoot>
  );
};

export default EmployeesPage;
