import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTitle } from "react-use";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Grid, Chip, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import DataTable from "./components/DataTable";
import AddUser from "./components/AddUser";
import { getAllUsersCompany } from "../../../../../actions/user";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import FaceIcon from "@mui/icons-material/Face";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    paddingBottom: "100px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
    "& .MuiAccordionSummary-root": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
  },
  iconHeader: {
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: "0.9rem",
          color: "#fff",
          backgroundColor: "#007afe",
          padding: "4px",
          borderRadius: "50%",
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#fff",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  borderTop: "none",
  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
  backgroundColor: "#fafafa",
}));

function UserManage() {
  const classes = useStyles();
  useTitle("User Manage - HR CORE DATA");
  const dispatch = useDispatch();
  const { result: userStore } = useSelector((state) => state.users);
  const [superAdmins, setSuperAdmins] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [managers, setManagers] = useState([]);

  const [addModal, setAddModal] = useState({
    isOpen: false,
  });

  const fetchSuperAdminData = () => {
    if (userStore !== null) {
      let userList = [...userStore];

      //Add field Full Name
      userList.forEach(function (element) {
        element.fullName = element.firstname + " " + element.lastname;
      });

      let userFilters = userList.filter((item) => {
        return item.authorities[0] === "ROLE_SUPERADMIN";
      });

      setSuperAdmins(userFilters);
    }
  };

  const fetchAdminData = () => {
    if (userStore !== null) {
      let userList = [...userStore];

      //Add field Full Name
      userList.forEach(function (element) {
        element.fullName = element.firstname + " " + element.lastname;
      });

      let userFilters = userList.filter((item) => {
        return item.authorities[0] === "ROLE_ADMIN";
      });

      setAdmins(userFilters);
    }
  };

  const fetchManagerData = () => {
    if (userStore !== null) {
      let userList = [...userStore];

      //Add field Full Name
      userList.forEach(function (element) {
        element.fullName = element.firstname + " " + element.lastname;
      });

      let userFilters = userList.filter((item) => {
        return item.authorities[0] === "ROLE_MANAGER";
      });

      setManagers(userFilters);
    }
  };

  useEffect(() => {
    fetchSuperAdminData();
    fetchAdminData();
    fetchManagerData();
  }, [userStore]);

  useEffect(() => {
    //dispatch(getAllUsers());
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.headingPage}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">User Manage</Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<AddIcon />}
              onClick={() => {
                setAddModal({
                  ...addModal,
                  isOpen: true,
                });
              }}
            >
              เพิ่ม User
            </ButtonBlue>
          </Grid>
        </Grid>
      </Box>
      <Paper className={classes.paper}>
        <Accordion>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography className={classes.wrapIcon}>
                  <GroupIcon className={classes.iconHeader} />
                  Super Admin
                </Typography>
              </Grid>
              <Grid item>
                <Chip
                  icon={<FaceIcon />}
                  variant="outlined"
                  label={superAdmins.length + " คน"}
                  sx={{ marginRight: "15px", border: "1px solid #e0e0e0" }}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable data={superAdmins} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography className={classes.wrapIcon}>
                  <GroupIcon className={classes.iconHeader} />
                  Admin
                </Typography>
              </Grid>
              <Grid item>
                <Chip
                  icon={<FaceIcon />}
                  variant="outlined"
                  label={admins.length + " คน"}
                  sx={{ marginRight: "15px", border: "1px solid #e0e0e0" }}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable data={admins} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography className={classes.wrapIcon}>
                  <GroupIcon className={classes.iconHeader} />
                  Manager
                </Typography>
              </Grid>
              <Grid item>
                <Chip
                  icon={<FaceIcon />}
                  variant="outlined"
                  label={managers.length + " คน"}
                  sx={{ marginRight: "15px", border: "1px solid #e0e0e0" }}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <DataTable data={managers} />
          </AccordionDetails>
        </Accordion>
      </Paper>
      <AddUser addModal={addModal} setAddModal={setAddModal} />
    </div>
  );
}

export default UserManage;
