import React, { useEffect } from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getUserProfile, updateUser } from "../../../../actions/user";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import { useDispatch } from "react-redux";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
})

const DrawerAddress = (props) => {

    const { drawerConfig, handleClose } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const validateYupSchema = yup.object({
      houseNo: yup.string().test("length", "", val => val.length <= 100),
      vilage: yup.string().test("length", "", val => val.length <= 100),
      vilageNo: yup.string().test("length", "", val => val.length <= 100),
      alley: yup.string().test("length", "", val => val.length <= 100),
      road: yup.string().test("length", "", val => val.length <= 100),
      subDistrict: yup.string().test("length", "", val => val.length <= 100),
      district: yup.string().test("length", "", val => val.length <= 100),
      provience: yup.string().test("length", "", val => val.length <= 100),
      areaCode: yup.string().test("length", "", val => val.length <= 100),
    });
  
    const useHookForm = useForm({
      defaultValues: {
        houseNo: "",
        vilage: "",
        vilageNo: "",
        alley: "",
        road: "",
        subDistrict: "",
        district: "",
        provience: "",
        areaCode: "",
      },
      resolver: yupResolver(validateYupSchema),
      mode: "all",
    })

    const onSubmit = async (data) => {

      const cleansingData = {
        idEmp: drawerConfig.data.idEmp,
        houseNo: data.houseNo === ""? null: data.houseNo,
        vilage: data.vilage === ""? null: data.vilage,
        vilageNo: data.vilageNo === ""? null: data.vilageNo,
        alley: data.alley === ""? null: data.alley,
        road: data.road === ""? null: data.road,
        subDistrict: data.subDistrict === ""? null: data.subDistrict,
        district: data.district === ""? null: data.district,
        provience: data.provience === ""? null: data.provience,
        areaCode: data.areaCode === ""? null: data.areaCode,
      }

      const result = await dispatch(updateUser(cleansingData));

      if(result && result.status === 200) {
        handleClose();
        dispatch(openNotificationAlert({
          type: "success",
          message: t("DataSaveSuccessful"),
        }));
        dispatch(getUserProfile())
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred")
        }));
      }
    }

    useEffect(() => {
      if(drawerConfig.isOpen){
        useHookForm.setValue("houseNo", drawerConfig.data.houseNo? drawerConfig.data.houseNo: "", { shouldDirty: false, shouldTouch: false, shouldValidate: false })
        useHookForm.setValue("vilage", drawerConfig.data.vilage? drawerConfig.data.vilage: "", { shouldDirty: false, shouldTouch: false, shouldValidate: false })
        useHookForm.setValue("vilageNo", drawerConfig.data.vilageNo? drawerConfig.data.vilageNo: "", { shouldDirty: false, shouldTouch: false, shouldValidate: false })
        useHookForm.setValue("alley", drawerConfig.data.alley? drawerConfig.data.alley: "", { shouldDirty: false, shouldTouch: false, shouldValidate: false })
        useHookForm.setValue("road", drawerConfig.data.road? drawerConfig.data.road: "", { shouldDirty: false, shouldTouch: false, shouldValidate: false })
        useHookForm.setValue("subDistrict", drawerConfig.data.subDistrict? drawerConfig.data.subDistrict: "", { shouldDirty: false, shouldTouch: false, shouldValidate: false })
        useHookForm.setValue("district", drawerConfig.data.district? drawerConfig.data.district: "", { shouldDirty: false, shouldTouch: false, shouldValidate: false })
        useHookForm.setValue("provience", drawerConfig.data.provience? drawerConfig.data.provience: "", { shouldDirty: false, shouldTouch: false, shouldValidate: false })
        useHookForm.setValue("areaCode", drawerConfig.data.areaCode? drawerConfig.data.areaCode: "", { shouldDirty: false, shouldTouch: false, shouldValidate: false })
      }
    }, [drawerConfig.isOpen])

    return (
      <DrawerCustom
        title={t("Address")}
        anchor={"right"}
        open={drawerConfig.isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="8px">{t("HouseNo")}</Typography>
              <Controller
                control={useHookForm.control}
                name="houseNo"
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("NotSpecified")}
                    error={fieldState.error? true: null}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="8px">{t("Village")}</Typography>
              <Controller
                control={useHookForm.control}
                name="vilage"
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("NotSpecified")}
                    error={fieldState.error? true: null}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="8px">{t("VillageNo")}</Typography>
              <Controller
                control={useHookForm.control}
                name="vilageNo"
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("NotSpecified")}
                    error={fieldState.error? true: null}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="8px">{t("Alley")}</Typography>
              <Controller
                control={useHookForm.control}
                name="alley"
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("NotSpecified")}
                    error={fieldState.error? true: null}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="8px">{t("Road")}</Typography>
              <Controller
                control={useHookForm.control}
                name="road"
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("NotSpecified")}
                    error={fieldState.error? true: null}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="8px">{t("SubDistrict")}</Typography>
              <Controller
                control={useHookForm.control}
                name="subDistrict"
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("NotSpecified")}
                    error={fieldState.error? true: null}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="8px">{t("District")}</Typography>
              <Controller
                control={useHookForm.control}
                name="district"
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("NotSpecified")}
                    error={fieldState.error? true: null}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="8px">{t("Province")}</Typography>
              <Controller
                control={useHookForm.control}
                name="provience"
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("NotSpecified")}
                    error={fieldState.error? true: null}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" fontWeight="600" color="text.secondary" paddingBottom="8px">{t("PostalCode")}</Typography>
              <Controller
                control={useHookForm.control}
                name="areaCode"
                render={({field, fieldState}) => (
                  <TextFieldTheme
                    {...field}
                    placeholder={t("NotSpecified")}
                    error={fieldState.error? true: null}
                    helperText={fieldState.error? fieldState.error.message: null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue onClick={handleClose}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue
                type="submit"
                variant="contained"
                disabled={useHookForm.formState.isSubmitting || !useHookForm.formState.isDirty}
              >
                {t("Edit")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerAddress;