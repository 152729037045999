import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { styled } from "@mui/material/styles";

import "./App.css";
import "./style/uicons-bold-rounded.css";
import "./style/uicons-regular-rounded.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./devextreme/devextreme.css";
import { BrowserRouter as Router } from "react-router-dom";
import {
  Header,
  AdminMenu,
  ManagerMenu,
  UserMenu,
  AdminVendorMenu,
} from "./components/layouts";
import Routers from "./Routers";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Chip from "@mui/material/Chip";
import { Avatar, Typography } from "@mui/material";

import { getUserProfile } from "./actions/user";
import { getManagerId } from "./actions/manager";
import NotificationGlobal from "./components/pages/shared/general/NotificationGlobal";


const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      weight: "4px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
}));

const drawerUseStyles = makeStyles((theme) => ({
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
  },
  bannerOpen: {
    backgroundImage:
      // "url(" +
      // process.env.PUBLIC_URL +
      // "/images/background_menu.jpg" +
      // ")"
      `url(${process.env.PUBLIC_URL}/images/background_menu.jpg)`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  isActive: {
    backgroundColor: "#e0f5fd",
    color: "#0080ff",
  },
}));

const drawerWidth = 280;

const StyledToolbar = styled("div")(({}) => ({
  paddingTop: 36,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .textName": {
    fontSize: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const StyledAvatarMenuBar = styled(Avatar)(({}) => ({
  width: 48,
  height: 48,
  marginRight: 8,
}));

const StyledBoxProfileMenuBar = styled(Box)(({}) => ({
  backgroundColor: "#f2f3f5",
  borderRadius: 20,
  padding: "16px 12px",
  display: "flex",
  alignItems: "center",
  width: 230,
}));

const DrawerContainer = ({ open, matchesBig, handleDrawerOpen }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    if (currentUser) {
      setIsUser(currentUser.roles.includes("ROLE_USER"));
      setIsAdmin(currentUser.roles.includes("ROLE_ADMIN"));
      setIsManager(currentUser.roles.includes("ROLE_MANAGER"));
      setIsVendor(currentUser.roles.includes("ROLE_VENDOR"));
    }
  }, [currentUser]);

  const classes = drawerUseStyles();
  return (
    <Drawer
      variant={matchesBig ? "persistent" : "temporary"}
      anchor="left"
      open={open}
      onClose={handleDrawerOpen}
      className={clsx({
        [classes.drawerOpen]: open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.bannerOpen]: open,
        }),
      }}
    >
      <StyledToolbar>
        <StyledBoxProfileMenuBar>
          {userProfile && (
            <Fragment>
              {userProfile.idVendor ? (
                <StyledAvatarMenuBar
                  src={`${process.env.REACT_APP_API_URL}image/vendor/${userProfile.idVendor}/${userProfile.personalId}.jpg`}
                />
              ) : (
                <StyledAvatarMenuBar
                  src={`${process.env.REACT_APP_API_URL}image/profile/${userProfile.personalId}.jpg`}
                />
              )}
            </Fragment>
          )}

          {userProfile && (
            <div style={{ width: 175 }}>
              {userProfile.idManagerEmployee ? (
                <Typography
                  variant="h6"
                  style={{
                    fontSize: 16,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${userProfile.name}`}
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  style={{
                    fontSize: 16,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${userProfile.firstname} ${userProfile.lastname}`}
                </Typography>
              )}

              <Typography
                style={{
                  width: 160,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant="body1"
                color="text.secondary"
              >
                {`${userProfile.positionsName}`}
              </Typography>
            </div>
          )}
        </StyledBoxProfileMenuBar>
      </StyledToolbar>
      {isUser && (
        <Fragment>
          <UserMenu />
        </Fragment>
      )}
      {isManager && (
        <Fragment>
          <ManagerMenu />
        </Fragment>
      )}
      {isAdmin && (
        <Fragment>
          <AdminMenu open={open} />
        </Fragment>
      )}
      {isVendor && (
        <Fragment>
          <AdminVendorMenu open={open} />
        </Fragment>
      )}
    </Drawer>
  );
};

function App() {
  useStyles();
  const dispatch = useDispatch();
  const matchesBig = useMediaQuery("(min-width:1200px)");
  //const matchesLocationPath = useMediaQuery("(display-mode: standalone)");
  const [open, setOpen] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  useEffect(() => {
    console.log("window.location.pathname: ", window.location.pathname);
  }, []);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile());
    }
  }, [currentUser]);

  useEffect(() => {
    if (userProfile) {
      dispatch(getManagerId(userProfile.email));
    }
  }, [userProfile]);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Router>
      <div
        className={`section`}
        style={{ display: "flex", backgroundColor: !isLoggedIn && "#FFFFFF" }}
      >
        {isLoggedIn && userProfile && (
          <Fragment>
            <Header
              handleDrawerOpen={handleDrawerOpen}
              matchesBig={matchesBig}
              open={open}
              isLoggedIn={isLoggedIn}
            />
            <DrawerContainer
              handleDrawerOpen={handleDrawerOpen}
              matchesBig={matchesBig}
              open={open}
            />
          </Fragment>
        )}
        <Routers />
      </div>
      <NotificationGlobal />
    </Router>
  );
}

export default App;
