import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Grid,
  styled,
  Typography,
} from "@mui/material";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DataTable from "./dataTable";
import StyledCard from "../../../shared/general/Card";
import utils from "../../../../../utils";

import {
  createBilling,
  getPayrunDetailForBilling,
} from "../../../../../actions/payruns";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 24,
  "& .label": {
    fontSize: 14,
  },
});

const CreateBilling = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: PayrunDetail } = useSelector((state) => state.payRunDetail);
  const { result: PayrunDetailEmployees } = useSelector(
    (state) => state.payRunDetailEmployees
  );
  const { result: BillingEmployees } = useSelector(
    (state) => state.billingEmployees
  );
  const [backdrop, setBackdrop] = useState(false);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [statusAlert, setStatusAlert] = useState({
    isOpen: false,
    alertType: null,
  });
  const [isDisplaySection, setIsDisplaySection] = useState(false);

  useEffect(() => {
    if(
      !PayrunDetail || 
      !PayrunDetailEmployees ||
      PayrunDetailEmployees.length <= 0
    ){
      history.push(`/vendor/payroll/run/${params.idPayrun}`);
    }

    if (params && params.idPayrun) {
      dispatch(getPayrunDetailForBilling(params.idPayrun));
    }
  }, [params]);

  useEffect(() => {
    if (
      BillingEmployees &&
      BillingEmployees.listEmployeeSectionSort &&
      BillingEmployees.payrunDetailList
    ) {
      let sum = 0;
      BillingEmployees.payrunDetailList.map((bill) => {
        sum += bill.totalEarnings;
      });
      setTotalEarnings(sum);
    } else {
      setTotalEarnings(0);
    }
  }, [
    BillingEmployees, 
    (BillingEmployees && BillingEmployees.listEmployeeSectionSort), 
    (BillingEmployees && BillingEmployees.payrunDetailList)
  ]);

  const handleCreateBilling = async () => {
    setBackdrop(true);
    let result = await dispatch(createBilling(params.idPayrun));
    setBackdrop(false);
    if (result.status === 200) {
      history.push("/vendor/billing");
      setStatusAlert({
        isOpen: true,
        alertType: "success",
      });
    } else {
      setStatusAlert({
        isOpen: true,
        alertType: "error",
      });
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Box marginBottom="16px">
          <Typography variant="h4" gutterBottom>
            {t("createBilling")}
          </Typography>
        </Box>
        <StyledCard style={{ padding: 16 }}>
          <Typography variant="h4" gutterBottom>
            {t("Description")}
          </Typography>
          <StyledCard style={{ padding: 16, marginBottom: 16 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  className="label"
                  variant="caption"
                  color="text.secondary"
                >
                  {t("ItemName")}
                </Typography>
                <Typography variant="h5">
                  {(PayrunDetail && PayrunDetail.payrunName) || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  className="label"
                  variant="caption"
                  color="text.secondary"
                >
                  บริษัทนายจ้าง
                </Typography>
                <Typography variant="h5">
                  {(PayrunDetailEmployees &&
                    PayrunDetailEmployees.length > 0 &&
                    PayrunDetailEmployees[0] &&
                    PayrunDetailEmployees[0].companyName) ||
                    "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  className="label"
                  variant="caption"
                  color="text.secondary"
                >
                  รอบเดือน
                </Typography>
                <Typography variant="h5">{`${
                  (PayrunDetail &&
                    PayrunDetail.payrunPeriod &&
                    dayjs(PayrunDetail.payrunPeriod)
                      .locale(i18n.language)
                      .format("MMMM YYYY")) ||
                  "-"
                }`}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  className="label"
                  variant="caption"
                  color="text.secondary"
                >
                  จำนวนพนักงาน
                </Typography>
                <Typography variant="h5">{`${
                  (PayrunDetailEmployees && PayrunDetailEmployees.length) || "-"
                } คน`}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  className="label"
                  variant="caption"
                  color="text.secondary"
                >
                  ยอดรวมทั้งหมด
                </Typography>
                <Typography variant="h5">{`${
                  utils.numberWithCommas(totalEarnings) || "-"
                } ${t("Baht")}`}</Typography>
              </Grid>
            </Grid>
          </StyledCard>
          <Box
            style={{
              width:"100%",
              display:"flex",
              justifyContent:"space-between",
              marginBottom:"16px"
            }}
          >
            <ButtonBlue
              variant="contained"
              onClick={() => {
                setIsDisplaySection(!isDisplaySection);
              }}
            >
              {isDisplaySection ? `แสดงแบบรายคน` : `แสดงแบบแผนก`}
            </ButtonBlue>
            {BillingEmployees &&
              BillingEmployees.payrunDetailList && 
              BillingEmployees.payrunDetailList.length > 0 &&
              BillingEmployees.payrunDetailList[0] &&
              !BillingEmployees.payrunDetailList[0].idBilling && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                >
                  <ButtonBlue
                    style={{ marginRight: 16 }}
                    onClick={() => history.goBack()}
                  >
                    ยกเลิก
                  </ButtonBlue>
                  <ButtonBlue variant="contained" onClick={handleCreateBilling}>
                    ยืนยัน
                  </ButtonBlue>
                </Box>
            )}
          </Box>
          <DataTable isDisplaySection={isDisplaySection} />
        </StyledCard>
        <AlertResponse
          open={statusAlert.isOpen}
          handleClose={() =>
            setStatusAlert({
              isOpen: false,
              alertType: null,
            })
          }
          alertType={statusAlert.alertType}
        />
      </Container>
      <Backdrop open={backdrop}>
        <CircularProgress style={{ color: "#FFFFFF" }} />
      </Backdrop>
    </StyledRoot>
  );
};

export default CreateBilling;
