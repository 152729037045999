import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";

import {
  Container,
  Typography,
  Box,
  FilledInput,
  FormControl,
  InputAdornment,
  Grid,
  TextField,
  Popper,
  Divider,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import HeaderPage from "./../../shared/header/headerPage";
import ButtonBlue from "../../shared/general/ButtonBlue";

import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@mui/icons-material/Search";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import AdjustEmployee from "./adjust-employee";

import utils from "../../../../utils";
import {
  getCompanyContract,
  getAllUsersVendor,
} from "../../../../actions/vendor";
import {
  getAllContractEmployees,
} from "../../../../actions/contract-employees";
import AlertResponse from "../../shared/general/AlertResponse";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .header": {
    paddingTop: 20,
    paddingBottom: 30,
  },
  "& .wrap-add": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
  },
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  borderTop: "none",
  display: "flex",
  flexWrap: "wrap",
  "& .MuiBox-root": {
    width: "100%",
    margin: 8,
    marginBottom: 16,
  },
  "& .label": {
    fontWeight: 500,
  },
  "& .last-row": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-add": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 8,
  },
}));

const StyledEmpty = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 150,
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  margin: "auto",
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const ContractEmployee = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [searchContract, setSearchContract] = React.useState({
    idCompany: null,
    name: null,
  });
  const [expanded, setExpanded] = React.useState("");
  const [modeSelected, setModeSelected] = React.useState("search");

  const { result: vendorContract } = useSelector(
    (state) => state.vendorContract
  );

  const { result: allContractEmployees } = useSelector(
    (state) => state.allContractEmployees
  );

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeSearch = (event) => {
    const { name, value } = event.target;
    setSearchContract({
      ...searchContract,
      ["name"]: value,
    });
  };

  const rowRenderContract = () => {
    if (allContractEmployees) {
      let temp = [...allContractEmployees];
      if (searchContract.idCompany) {
        temp = temp.filter(
          (x) => x.contractCompany === searchContract.idCompany
        );
      }

      if (searchContract.name) {
        temp = temp.filter((x) => x.name.includes(searchContract.name));
      }

      return temp;
    }
  };

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    dispatch(getCompanyContract());
    dispatch(getAllContractEmployees());
  }, []);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className="header">
          <HeaderPage textLabel={t("ContractEmployeeList")} />
        </div>
        <div style={{ marginBottom: 16 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("ContractCompany")}
                </Typography>
                <StyledAutocomplete
                  options={vendorContract}
                  onChange={(event, newValue) => {
                    console.log("newValue: ", newValue);
                    if (newValue) {
                      setSearchContract({
                        ...searchContract,
                        ["idCompany"]: newValue.idCompany,
                      });
                    } else {
                      setSearchContract({
                        ...searchContract,
                        ["idCompany"]: null,
                      });
                    }
                  }}
                  popupIcon={<i class="fa-light fa-chevron-down"></i>}
                  getOptionLabel={(option) => option.companyName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      placeholder={t("ContractCompany")}
                    />
                  )}
                  PopperComponent={StyledPopper}
                  noOptionsText={t("No_Data")}
                />
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("SearchContractEmployee")}
                </Typography>
                <FormControl fullWidth variant="filled">
                  <StyledFilledInput
                    id="filled-sarch-name"
                    placeholder={t("SearchContractEmployee")}
                    name="search"
                    onChange={handleChangeSearch}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </StyledBoxSearch>
            </Grid>
          </Grid>
        </div>
        <div className="wrap-add">
          <ButtonBlue
            variant="contained"
            startIcon={<AddRoundedIcon />}
            onClick={() => {
              history.push({
                pathname: "/contract-employee/new"
              })
            }}
          >
            {t("AddContractEmployee")}
          </ButtonBlue>
        </div>
        {allContractEmployees &&
        rowRenderContract() &&
        rowRenderContract().length > 0 &&
        allContractEmployees.length > 0 ? (
          <div>
            {rowRenderContract().map((item, index) => (
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <div className="wrap">
                    <Typography>{`${item.name}`}</Typography>
                    <div>
                      <ButtonBlue
                        size="small"
                        onClick={() =>
                          history.push({
                            pathname: `/contract-employee/${item.idContractEmployees}`,
                          })
                        }
                      >
                        {t("Description")}
                      </ButtonBlue>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Typography
                      className="label"
                      color="text.secondary"
                      variant="body2"
                    >
                      {t("ContractCompany")}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {item.companyName}
                    </Typography>
                    <Typography
                      className="label"
                      color="text.secondary"
                      variant="body2"
                    >
                      {t("startEndDate")}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {item.endDate
                        ? `${dayjs(item.startDate)
                            .locale("th")
                            .format("D MMMM BBBB")} - ${dayjs(item.endDate)
                            .locale("th")
                            .format("D MMMM BBBB")}`
                        : item.startDate
                        ? `${dayjs(item.startDate)
                            .locale("th")
                            .format("D MMMM BBBB")}`
                        : "ไม่ระบุ"}
                    </Typography>
                    <Typography
                      className="label"
                      color="text.secondary"
                      variant="body2"
                    >
                      ค่าล่วงเวลา
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      {`${item.isOT === 1 ? "มี" : "ไม่มี"}`}
                      <Checkbox
                        disabled={!item.isOT ? true : false}
                        checked={item.isOT ? true : false}
                      />
                    </Typography>
                    <div className="last-row">
                      <div>
                        <Typography
                          className="label"
                          color="text.secondary"
                          variant="body2"
                        >
                          {t("costCharge")}
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                          {`${utils.numberWithCommas(item.limitPerMonth)} บาท`}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          className="label"
                          color="text.secondary"
                          variant="body2"
                        >
                          {t("employeeMinimumPerDays")}
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                          {" "}
                          {`${
                            item.isMinAmountEmployees === 1
                              ? item.amountPerDay + " คน"
                              : "ไม่กำหนดจำนวนคน"
                          }`}
                        </Typography>
                      </div>
                    </div>
                    <Box paddingTop="16px">
                      <Grid container spacing={2}>
                        {item.isMinAmountEmployees === 1 && item.contractEmployeePosition.map((position, index) => (
                          <Fragment key={position.idContractEmployeePosition}>
                            <Grid item xs={12} md={3}>
                              <Typography className="label" color="text.secondary" variant="body2">{t(`Position`)}</Typography>
                              <Typography>{position.positionsName}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className="label" color="text.secondary" variant="body2">จำนวนคน</Typography>
                              <Typography>{utils.numberWithCommasWithoutDot(position.amount)}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className="label" color="text.secondary" variant="body2">วงเงินเรียกเก็บ (ต่อคน)</Typography>
                              <Typography>{utils.numberWithCommas(position.limitPerPerson)} บาท</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography className="label" color="text.secondary" variant="body2">รวม</Typography>
                              <Typography>{utils.numberWithCommas(position.amount * position.limitPerPerson)} บาท</Typography>
                            </Grid>
                          </Fragment>
                        ))}
                      </Grid>
                    </Box>
                    <StyledDivider />
                    <Box
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <StyledToggleButtonGroup
                        size="small"
                        exclusive
                        aria-label="text alignment"
                        value={modeSelected}
                        onChange={(event, newValue) =>
                          setModeSelected(newValue)
                        }
                      >
                        <ToggleButton value="search" aria-label="left aligned">
                          <PersonSearchRoundedIcon style={{ marginRight: 8 }} />
                          {t("Total_Person")}
                        </ToggleButton>
                        <ToggleButton value="add" aria-label="left aligned">
                          <GroupAddRoundedIcon style={{ marginRight: 8 }} />
                          {t("AddEditEmployee")}
                        </ToggleButton>
                        {/* <ToggleButton value="change" aria-label="centered">
                          <ManageAccountsRoundedIcon
                            style={{ marginRight: 8 }}
                          />
                          {t("ChangeEmployee")}
                        </ToggleButton> */}
                      </StyledToggleButtonGroup>
                    </Box>
                    <AdjustEmployee
                      modeSelected={modeSelected}
                      idContractEmployees={item.idContractEmployees}
                      setModeSelected={setModeSelected}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        ) : (
          <StyledEmpty className="empty-holiday">
            <Typography color="text.secondary" align="center">
              {t("No_Data")}
            </Typography>
          </StyledEmpty>
        )}
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      </Container>
    </StyledRoot>
  );
};

export default ContractEmployee;
