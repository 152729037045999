import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import TableRequest from "./table";

const Leave = (props) => {
  const { searchName } = props;
  const { t } = useTranslation();

  const { result: leaveEmployeeList } = useSelector(
    (state) => state.leaveEmployees
  );

  const [dataTable, setDataTable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (leaveEmployeeList) {
      setDataTable(leaveEmployeeList.filter((x) => {
        if(
          (x.isApprove !== null || x.isActive === 0) && 
          (
            (searchName && searchName.length > 0) ? 
            (
              x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
              x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
            )
            : true
          )
        ){
          return true;
        } else {
          return false;
        }
      }));
    }
    setIsLoading(false);
  }, [leaveEmployeeList, searchName]);

  return (
    <Fragment>
      {isLoading ? 
        <div style={{ width:"100%", display:"flex", justifyContent:"center", paddingTop: 16 }}>
          <Typography style={{ fontSize: 20, fontWeight: 500 }}>{t("LoadingData")}</Typography>
        </div>
        :
        <Fragment>
          {leaveEmployeeList && dataTable && (
            <TableRequest data={dataTable} searchName={searchName} />
          )}
        </Fragment>
      }
    </Fragment>
  );
};

export default Leave;
