import React from 'react';
import ButtonBlue from '../../../shared/general/ButtonBlue';

const ButtonDelete = (props) => {
    let { onClick } = props;
    return (
        <ButtonBlue 
            className="delete-button" 
            variant="outlined" 
            size="small"
            onClick={onClick} 
            style={{ marginLeft: 8, fontSize:"0.875rem" }}
        >
            <i className="fa-regular fa-trash-can" style={{ fontSize:"0.875rem" }} />ลบ
        </ButtonBlue>
    );
};

export default ButtonDelete;