import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Typography,
  createFilterOptions,
  styled,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Alert,
  Snackbar,
} from "@mui/material";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import { updateManager } from "../../../../../../actions/manager";
import {
  getManagerByidCompany,
  getManagerSettingByidCompany,
} from "../../../../../../actions/company";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { resetPassword } from "../../../../../../actions/auth";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .option-header": {
    fontSize: "14px",
    color: "#919eab",
  },
});

const StyledResetButton = styled(Button)({
  backgroundColor: "#FF0000",
  padding: "8px",
  margin: "8px",
  marginTop: "16px",
  marginBottom: "16px",
  color: "#ffffff",
  boxShadow: "0px 2px 2px 2px rgba(0,0,0,0.25)",
  ":hover": {
    backgroundColor: "#b20000",
    padding: "8px",
    margin: "8px",
    marginTop: "16px",
    marginBottom: "16px",
    color: "#ffffff",
    boxShadow: "0px 2px 2px 2px rgba(0,0,0,0.25)",
  },
});

const StyledResetDiv = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const filterOptions = createFilterOptions({
  stringify: (value) =>
    `${value.divisionName} ${value.departmentName} ${value.sectionName} ${value.positionName}`,
});

const DrawerEditManager = (props) => {
  const { open, onClose, values, isEdit, selectedCompany } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateYupSchema = yup.object({
    name: yup.string().required("กรุณากรอกข้อมูล"),
    positionsName: yup.string().required("กรุณากรอกข้อมูล"),
  });

  const [openDialog, setOpenDialog] = React.useState(false);
  const [alertType, setAlertType] = useState({
    status: null,
    type: "",
    message: "",
    open: false,
  });

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const useHookForm = useForm({
    defaultValues: {
      name: "",
      positionsName: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const onSubmit = async (submitData) => {
    const formData = {};

    Object.keys(submitData).map((k) => {
      formData[k] = submitData[k];
    });

    // formData.idCompany = selectedCompany.idCompany;

    if (isEdit) {
      const response = await dispatch(updateManager(formData));
      if (response.status === 200) {
        dispatch(getManagerByidCompany(selectedCompany.idCompany));
        onClose();
      } else {
        if (response.data.field) {
          useHookForm.setError(response.data.field, {
            message: response.data.message,
          });
        }
      }
    }
    // else {
    //   //   const response = await dispatch(addManager(formData));
    //   const response = { status: 200 };
    //   if (response.status === 200) {
    //     dispatch(getManagerByidCompany(selectedCompany.idCompany));
    //     onClose();
    //   } else {
    //     if (response.data.field) {
    //       useHookForm.setError(response.data.field, {
    //         message: response.data.message,
    //       });
    //     }
    //   }
    // }
  };

  useEffect(() => {
    if (open === true) {
      useHookForm.reset({
        ...values,
      });
    }
  }, [open]);

  const handleConfirmResetPassword = async () => {
    if (isEdit === true) {
      const response = await dispatch(
        resetPassword({ idUsers: values.idUsers })
      );
      if (response.status === 200) {
        setAlertType({
          ...alertType,
          open: true,
          status: 200,
          type: "success",
          message: t("PasswordIsReseted"),
        });
        handleCloseDialog();
      } else {
        setAlertType({
          ...alertType,
          open: true,
          status: 500,
          type: "error",
          message: t("FailToResetPassword"),
        });
      }
    }
  };

  return (
    <DrawerCustom
      title={isEdit ? `${t("EditManager")}` : `${t("AddManager")}`}
      anchor={"right"}
      open={open}
      onClose={isSubmitting ? () => {} : onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form
          onSubmit={useHookForm.handleSubmit(onSubmit, (error) => {
            console.log(error);
          })}
        >
          <Grid container spacing={2}>
            {isEdit === true ? (
              <Grid item xs={12}>
                <StyledResetDiv>
                  <StyledResetButton
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                  >
                    {t("ResetPass")}
                  </StyledResetButton>
                  <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle id="alert-dialog-title">
                      {t("ResetPass")}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {t("confirmResetPassword")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDialog}>{t("Cancel")}</Button>
                      <Button
                        onClick={handleConfirmResetPassword}
                        autoFocus
                        color="error"
                      >
                        {t("ResetPass")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </StyledResetDiv>
                <StyledDivider />
              </Grid>
            ) : (
              <span></span>
            )}

            <Grid item xs={12}>
              <Typography paddingBottom="4px" fontSize="14px" fontWeight="500">
                {t("FullName")}
              </Typography>
              <Controller
                name="name"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography paddingBottom="4px" fontSize="14px" fontWeight="500">
                {t("Position")}
              </Typography>
              <Controller
                name="positionsName"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.positionsName]
                        ? useHookForm.formState.errors[field.positionsName]
                            .message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.positionsName]
                        ? true
                        : false
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue
                disabled={isSubmitting}
                onClick={() => {
                  onClose();
                }}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                disabled={isSubmitting}
                type="submit"
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
      {alertType.status && alertType.status === 200 && (
        <Snackbar
          open={alertType.open}
          autoHideDuration={3000}
          onClose={() => {
            setAlertType({ ...alertType, open: false });
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert variant="filled" severity={alertType.type}>
            {alertType.message}
          </Alert>
        </Snackbar>
      )}
    </DrawerCustom>
  );
};

export default DrawerEditManager;
