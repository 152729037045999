import { httpClient } from "../httpClient";

const getAllCompany = () => {
  return httpClient.get(`/companies`);
};

const getAllVendor = () => {
  return httpClient.get(`/vendors`);
};

const addNewCompanies = (formData) => {
  return httpClient.post(`/companies`, formData);
};

const addNewVendor = (formData) => {
  return httpClient.post(`/vendors`, formData);
};

export default {
  getAllCompany,
  getAllVendor,
  addNewCompanies,
  addNewVendor,
};
