import * as React from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { Box, Grid, TextField, Typography } from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { updateEmployeeShiftHistory } from "../../../../../actions/employee";

var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

const DialogEditSwitchShift = (props) => {
  const {
    open,
    setOpen,
    listEmployee,
    selectIdEmployeeShift,
    idShiftGroup,
    handleGetEmployeeShiftHistory,
  } = props;
  const dispatch = useDispatch();
  const [editEmployee, setEditEmployee] = React.useState(null);
  const [isUpdateComplete, setIsUpdateComplete] = React.useState(true);

  React.useEffect(() => {
    let templist = listEmployee.find(
      (emp) => emp.idEmployeeShift === selectIdEmployeeShift
    );
    setEditEmployee(templist);
  }, [listEmployee, selectIdEmployeeShift]);

  const handleClickSubmitConfirm = async () => {
    let result = await dispatch(updateEmployeeShiftHistory([editEmployee]));
    if (result) {
      setOpen(false);
      setIsUpdateComplete(true);
      handleGetEmployeeShiftHistory();
    } else {
      setIsUpdateComplete(false);
    }
  };

  return (
    <React.Fragment>
      {editEmployee && (
        <DrawerCustom
          title={`แก้ไข${
            editEmployee.idShiftGroup === parseInt(idShiftGroup)
              ? "สลับกะ"
              : "ย้ายรูปแบบกะ"
          }`}
          anchor={"right"}
          open={open}
        >
          <Box
            style={{
              width: "500px",
              height: "calc(100% - 30px)",
              margin: "15px 20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography>วันที่เริ่มต้น : </Typography>
                </Grid>
                <Grid item xs={8}>
                  <DatePicker
                    disabled={dayjs().isSameOrAfter(
                      dayjs(editEmployee.startDate)
                    )}
                    style={{ width: "100px" }}
                    value={editEmployee.startDate}
                    name="startDate"
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      setEditEmployee({
                        ...editEmployee,
                        ["startDate"]: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth helperText={null} />
                    )}
                  />
                </Grid>
                {editEmployee.isTemporarily === 1 && (
                  <React.Fragment>
                    <Grid
                      item
                      xs={4}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography>วันที่สิ้นสุด : </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <DatePicker
                        style={{ width: "100px" }}
                        minDate={new Date(editEmployee.startDate)}
                        value={editEmployee.endDate}
                        name="endDate"
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          setEditEmployee({
                            ...editEmployee,
                            ["endDate"]: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth helperText={null} />
                        )}
                      />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </LocalizationProvider>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginTop: "20px",
              }}
            >
              {!isUpdateComplete && (
                <Typography>แก้ไขข้อมูลไม่สำเร็จ</Typography>
              )}
              <Box style={{ display: "flex" }}>
                <ButtonBlue
                  style={{ marginRight: "20px" }}
                  onClick={() => setOpen(false)}
                >
                  ยกเลิก
                </ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  onClick={handleClickSubmitConfirm}
                >
                  ยืนยัน
                </ButtonBlue>
              </Box>
            </Box>
          </Box>
        </DrawerCustom>
      )}
    </React.Fragment>
  );
};

export default DialogEditSwitchShift;
