import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Grid, styled, Typography } from "@mui/material";
import * as Yup from "yup";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { updateUser, getUserProfile } from "../../../../actions/user";

const StyledRoot = styled("div")({
  width: 350,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const phoneRegExp = /^0\d\d\d\d\d\d\d\d\d$/;
const validationSchema = Yup.object({
  emergencyPhone: Yup.string()
    .required("โปรดกรอกข้อมูล")
    .matches(phoneRegExp, "เบอร์โทรศัพท์ไม่ถูกต้อง")
    .nullable(),
});

const DialogEmergency = (props) => {
  const { open, handleCloseDialog, handleChangeAlertType, handleOpenAlert } =
    props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [state, setstate] = useState(false);

  useEffect(() => {
    formik.resetForm();
    formik.setFieldValue("idEmp", userProfile.idEmp);
    formik.setFieldValue("emergencyContact", userProfile.emergencyContact);
    formik.setFieldValue(
      "emergencyRelationship",
      userProfile.emergencyRelationship
    );
    formik.setFieldValue("emergencyPhone", userProfile.emergencyPhone);
  }, []);

  const formik = useFormik({
    initialValues: {
      idEmp: "",
      emergencyContact: "",
      emergencyRelationship: "",
      emergencyPhone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async () => {
    setstate(true);
    const result = await dispatch(updateUser(formik.values));
    if (result && result.status === 200) {
      handleChangeAlertType("success");
      handleOpenAlert();
      setTimeout(function () {
        handleCloseDialog();
        dispatch(getUserProfile());
      }, 3000);
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
    }
  };

  return (
    <DrawerCustom
      title={t("Edit_Data")}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
    >
      <form onSubmit={formik.handleSubmit}>
        <StyledRoot>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("EmergencyContact")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="emergencyContact"
                value={formik.values.emergencyContact}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>
                {t("EmergencyRelationship")}
              </StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="emergencyRelationship"
                value={formik.values.emergencyRelationship}
                fullWidth
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("EmergencyPhone")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextFieldTheme
                name="emergencyPhone"
                value={formik.values.emergencyPhone}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.emergencyPhone &&
                  Boolean(formik.errors.emergencyPhone)
                }
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.emergencyPhone &&
                  Boolean(formik.errors.emergencyPhone) &&
                  formik.errors.emergencyPhone
                }
              />
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleCloseDialog}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue
              type="submit"
              variant="contained"
              disabled={!formik.isValid || state}
            >
              {t("Save")}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      </form>
    </DrawerCustom>
  );
};

export default DialogEmergency;
