import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  Typography,
  Container,
  Divider,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";

import ImageFire from "../assets/6115095.jpg";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const WrapHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 36,
});

const WrapCard = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
});

const CardStyleCustom = styled("div")({
  width: 350,
  margin: 16,
  "& .card": {
    margin: 0,
  },
  "& .card-content": {
    position: "relative",
  },
  "& .date-sign": {
    width: 60,
    height: 60,
    textAlign: "center",
    position: "absolute",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    top: -30,
    right: 28,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .day, .month": {
      lineHeight: 1,
    },
    "& .day": {
      fontSize: 22,
      fontWeight: "bold",
    },
    "& .month": {
      fontWeight: "bold",
    },
  },
  "& .card-bottom": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "& .MuiTypography-subtitle1": {
      fontSize: 22,
    },
  },
});
const CourseTraining = () => {
  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div>
          <WrapHeader>
            <Typography variant="h4">อบรมเร็วๆนี้</Typography>
            <div>
              {/* <ButtonBlue size="small" variant="contained">
                เพิ่มเติม
              </ButtonBlue> */}
            </div>
          </WrapHeader>
          <WrapCard>
            {[...Array(6).keys()].map((value, index) => (
              <CardStyleCustom key={index}>
                <CardStyle className={`card`}>
                  <CardActionArea
                    component={NavLink}
                    to={`/admin/courses/${index + 1}`}
                  >
                    <CardMedia
                      component="img"
                      height="140"
                      image={ImageFire}
                      alt="green iguana"
                    />
                    <CardContent className="card-content">
                      <div className="date-sign">
                        <Typography className="day">11</Typography>
                        <Typography className="month" color="text.third">
                          พ.ค.
                        </Typography>
                      </div>
                      <Typography
                        variant="body2"
                        color="text.third"
                        gutterBottom
                      >
                        <i class="fal fa-clock"></i> 9:00 - 12:00
                      </Typography>
                      <Typography gutterBottom variant="h5" component="div">
                        Basic Fire
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Lizards are a widespread group of squamate reptiles,
                        with over 6,000 species, ranging across all continents
                        except Antarctica
                      </Typography>
                      <StyledDivider />
                      <div className="card-bottom">
                        <div>
                          <Typography variant="body2" color="text.secondary">
                            รุ่นที่
                          </Typography>
                          <Typography variant="subtitle1">1</Typography>
                        </div>
                        <div>
                          <Typography variant="body2" color="text.secondary">
                            ลงทะเบียน
                          </Typography>
                          <Typography align="right" variant="subtitle1">
                            1
                            <Typography
                              variant="body1"
                              component={"span"}
                              color="text.third"
                            >
                              /30
                            </Typography>
                          </Typography>
                        </div>
                      </div>
                    </CardContent>
                  </CardActionArea>
                </CardStyle>
              </CardStyleCustom>
            ))}
          </WrapCard>
        </div>
      </Container>
    </StyledRoot>
  );
};

export default CourseTraining;
