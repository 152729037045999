import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  CardContent,
  Typography,
  Container,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Menu,
  MenuItem,
  Switch,
  Select,
  Grid,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Checkbox,
} from "@mui/material";

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const labelOt = { inputProps: { 'aria-label': 'checkbox ot' } };
const labelShift = { inputProps: { 'aria-label': 'checkbox shift' } };

const OtShiftPanel = (props) => {
  const { dataForm, handleOnChangeData } = props;
  return (
    <Box>
      <div className="wrap-item-data">
        <div className="wrap-check">
          <Checkbox {...labelOt} />
          <Typography className="sub-header-1" variant="body1">
            ค่าทำงานล่วงเวลา
          </Typography>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                หักเงินค่าจ้าง
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                คำนวนประกันสังคม
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                คำนวนกองทุนสำรองเลี้ยงชีพ
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
      <StyledDivider />
      <div className="wrap-item-data">
        <div className="wrap-check">
          <Checkbox {...labelShift} />
          <Typography className="sub-header-1" variant="body1">
            ค่ากะทำงาน
          </Typography>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                หักเงินค่าจ้าง
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                คำนวนประกันสังคม
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography className="sub-header" variant="body1">
                คำนวนกองทุนสำรองเลี้ยงชีพ
              </Typography>
              <div>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="round-payroll"
                    name="late"
                    value={dataForm.late}
                    onChange={handleOnChangeData}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="ใช่"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="ไม่ใช่"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default OtShiftPanel;
