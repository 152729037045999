import React from "react";
import { useSelector } from "react-redux";
import { Controller } from 'react-hook-form';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { makeStyles } from "@mui/styles";
import { Button, styled } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useDropzone } from "react-dropzone";
import { Close, TextSnippet } from '@mui/icons-material';
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiTab-root": {
      display: "flex",
      flexDirection: "row",
      fontSize: "0.9rem",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#007afe",
    },
    "& .MuiSvgIcon-root": {
      marginRight: 8,
    },
  },
  button: {
    borderColor: "#007afe",
    color: "#ffffff",
    backgroundColor: "#007afe",
    borderRadius: 8,
    paddingLeft: 16,
    paddingRight: 16,
    "&:hover": {
      borderColor: "#0046b7",
      backgroundColor: "#0046b7",
    },
  },
  disabledButton: {
    backgroundColor: "rgba(0,0,0,0.12)",
    paddingLeft: 16,
    paddingRight: 16,
  },
  dropzone: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    transition: "border .24s ease-in-out",
  },
}));

const DisplayFilesBox = styled("div")({
  height: 200,
  display: "flex",
  marginTop: 8,
  padding: "8px 8px 16px 16px",
  border: "1px dashed rgb(208, 208, 208)",
  borderRadius: "8px",
  justifyContent:"center",
  alignItems:"center",
  position:"relative"
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function FileAttachment(props) {
  const { setValue, watch, getValues, isDisabled } = props;
  const { result: taxDeduction } = useSelector((state) => state.taxDeduction);
  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    onDrop: (acceptedFiles) => {
      let fileInput = acceptedFiles.map((f) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      setValue("file",fileInput[0], { shouldDirty: true });
    },
    maxFiles: 1,
    multiple: false
  });

  return (
    <section className={classes.dropzone_section}>
      {(!watch("file")) &&
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <p>Drag & Drop หรือ คลิ๊กเพื่อเลือกไฟล์</p>
        </div>
      }
      <aside>
        <h4>ไฟล์ที่อัปโหลด</h4>
          <DisplayFilesBox>
            {(watch("file")) ?
              <>
                <div 
                  style={{ 
                    display:"flex", 
                    flexDirection:"column", 
                    alignItems:"center",
                    cursor:"pointer"
                  }}
                  onClick={() => {
                    window.open(getValues("file").preview, "_blank");
                  }}
                >
                  <TextSnippet style={{ fontSize: 64 }} />
                  <Typography style={{ marginTop: 16 }}>{getValues("file").name}</Typography>
                </div>
                {!isDisabled && 
                  <Close 
                    style={{ 
                      position:"absolute", 
                      right: 8, 
                      top: 8, 
                      marginRight: 0, 
                      cursor:"pointer" 
                    }}
                    onClick={() => {
                      setValue("file",null);
                      if(taxDeduction && taxDeduction.data.nameDocument && taxDeduction.data.documentPath){
                        setValue("isDeletedFile", true, { shouldDirty: true });
                      }
                    }}
                  />
                }
              </>
              :
              <Typography
                style={{ margin: "16px 0" }}
              >
                ไม่มีการอัปโหลดไฟล์
              </Typography>
            }
          </DisplayFilesBox>
      </aside>
    </section>
  );
}

function TaxDesc(props) {
  const { setValue, handleSubmit, control, dirtyFields, watch, getValues, isDisabled } = props;
  const classes = useStyles();
  const [tabs, setTabs] = React.useState(0);

  const handleChange = (_, newValue) => {
    setTabs(newValue);
  };

  return (
    <Box className={classes.root}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabs}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab icon={<StickyNote2Icon />} label="หมายเหตุ" {...a11yProps(0)} />
          <Tab icon={<AttachFileIcon />} label="เอกสารแนบ" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabs} index={0}>
        <Controller 
          name="description"
          control={control}
          render={({ field }) => (
            <TextFieldTheme
              disabled={isDisabled}
              {...field}
              variant="outlined"
              placeholder="หมายเหตุ"
              multiline
              rows={4}
              fullWidth
            />
          )}
        />
      </TabPanel>
      <TabPanel value={tabs} index={1}>
        <FileAttachment setValue={setValue} watch={watch} getValues={getValues} isDisabled={isDisabled} />
      </TabPanel>
      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: "25px" }}
      >
        <Button
          className={(!(Object.keys(dirtyFields).length > 0) || isDisabled) ? classes.disabledButton : classes.button}
          onClick={handleSubmit}
          sx={{ mr: 1 }}
          startIcon={<SaveIcon />}
          disabled={!(Object.keys(dirtyFields).length > 0) || isDisabled}
        >
          ตรวจสอบและยืนยัน
        </Button>
      </Box>
    </Box>
  );
}

export default TaxDesc;
