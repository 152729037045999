import React from "react";
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import FormContainer from "./FormContainer";
import { useTranslation } from "react-i18next";

const EmployeeType = (props) => {
  const { control, errors, setValue } = props;
  const { t } = useTranslation();

  return (
    <FormContainer title={`${t("EmployeeType")}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography className="topic-radio">{t("WorkType")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl component="fieldset" error={(errors && errors.employmentTime) ? true : false}>
            <Controller 
              control={control}
              name="employmentTime"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                >
                  <FormControlLabel
                    value="Full time"
                    label="Full time"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value="Part time"
                    label="Part time"
                    control={<Radio />}
                  />
                </RadioGroup>
              )}
            />
            {(errors && errors.employmentTime && errors.employmentTime.message) && 
              <FormHelperText error>
                {errors.employmentTime.message}
              </FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-radio">{t("PaymentType")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl component="fieldset">
            <Controller 
              control={control}
              name="idPaymentType"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    if(event.target.value === "1" || event.target.value === "4"){
                      setValue("paymentRound", "รายครึ่งเดือน");
                    } else if (event.target.value === "2" || event.target.value === "5") {
                      setValue("paymentRound", "รายเดือน");
                    }
                  }}
                >
                  <FormControlLabel
                    value="1"
                    label={`${t("Daily")}`}
                    control={<Radio />}
                  />
                  <Typography color="textSecondary" variant="body1">
                    {`${t("Payment")}: ${t("HalfMonth")}`}
                  </Typography>
                  <FormControlLabel
                    value="5"
                    label={`${t("Daily")}`}
                    control={<Radio />}
                  />
                  <Typography color="textSecondary" variant="body1">
                    {`${t("Payment")}: ${t("Monthly")}`}
                  </Typography>
                  <FormControlLabel
                    value="4"
                    label={`${t("Monthly")}`}
                    control={<Radio />}
                  />
                  <Typography color="textSecondary" variant="body1">
                    {`${t("Payment")}: ${t("HalfMonth")}`}
                  </Typography>
                  <FormControlLabel
                    value="2"
                    label={`${t("Monthly")}`}
                    control={<Radio />}
                  />
                  <Typography color="textSecondary" variant="body1">
                    {`${t("Payment")}: ${t("Monthly")}`}
                  </Typography>
                </RadioGroup>
              )}
            />
            {(errors && errors.idPaymentType && errors.idPaymentType.message) &&
              <FormHelperText error>
                {errors.idPaymentType.message}
              </FormHelperText>
            }
          </FormControl>
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default EmployeeType;
