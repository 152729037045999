import React from "react";
import { Avatar, Box, Grid, Icon, styled, Typography } from "@mui/material";
import CardData from "../../shared/dashboard/CardData";
import Utils from "../../../../utils";
import ChartAreaCustom from "../../shared/dashboard/ChartArea";
import dayjs from "dayjs";
import CardDashboard from "../../shared/dashboard/CardDashboard";
import { useSelector } from "react-redux";
import ChartDonutCustom from "../../shared/dashboard/ChartDonut";

const StyledRoot = styled(Box)({
  "& .top-employee": {
    padding: "8px",
    // paddingTop: "16px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #ececec",
    borderRadius: "4px"
  }
})

const OvertimePanel = (props) => {

  const { month, year } = props;

  const {result: dashboardOvertime} = useSelector(state => state.dashboardOvertime);

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardData 
                    title="พนักงาน" 
                    dataValue={Utils.numberWithCommasWithoutDot(dashboardOvertime.employeeTotal)}
                    dataUnit="คน"
                    dynamicFontSize
                    titleIcon={
                      <Icon baseClassName="fal" className="fa-users" color="primary" style={{display: "inline-table" }}/>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardData 
                    title="รายจ่ายค่าล่วงเวลา"
                    dataValue={Utils.numberWithCommas(Object.keys(dashboardOvertime.otTotal).reduce((a,b) => a + dashboardOvertime.otTotal[b].payTotal, 0))}
                    dataUnit="บาท"
                    dynamicFontSize
                    titleIcon={
                      <Icon className="fal fa-hand-holding-usd" color="warning" style={{display: "inline-table", color: "#ffcd38"}}/>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardData 
                    title="ทำงานล่วงเวลา"
                    dataValue={Utils.numberWithCommas(Number(Object.keys(dashboardOvertime.otTotal).reduce((a, b) => a + dashboardOvertime.otTotal[b].hourTotal, 0).toFixed(2)))}
                    dataUnit="ชั่วโมง"
                    dynamicFontSize
                    titleIcon={
                      <Icon className="fal fa-hand-holding-usd" color="warning" style={{display: "inline-table", color: "#ffcd38"}}/>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CardData
                title="OT เกิน 36 ชั่วโมง"
                dataValue={dashboardOvertime.employeeOTOver36Total.weekInMonth.map(week => {return {name: `${dayjs(week.weekStartDateText).format("dd D MMM")} - ${dayjs(week.weekEndDateText).format("dd D MMM")}`, value: week.over36HoursEmployeeTotal}})}
                dataUnit="คน"
                dynamicFontSize
                select
                titleIcon={
                  <Icon className="far fa-alarm-exclamation" color="error" style={{display: "inline-table" }}/>
                }
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={12}>
          <CardDashboard>
            <Typography marginBottom="16px">รายจ่ายค่าล่วงเวลา (รายปี {year})</Typography>
            <ChartAreaCustom
              series={[
                {
                  name: "รายจ่าย",
                  data: dashboardOvertime.otTotalAllYear.map(s => {return {x: dayjs().month(s.month-1).format("MMM"), y: s.payTotal}})
                }
              ]}
            />
          </CardDashboard>
        </Grid> */}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <CardDashboard>
                <Typography marginBottom="16px">OT แต่ละประเภท</Typography>
                <ChartDonutCustom
                  series={Object.keys(dashboardOvertime.otTotal).map(ot => dashboardOvertime.otTotal[ot].payTotal)}
                  labels={Object.keys(dashboardOvertime.otTotal).map(ot => `OT x${ot}`)}
                />
              </CardDashboard>
              {/* <StyledCard style={{height: "100%"}}>
                <CardContent style={{padding: 16}}>
                  <Typography>OT แต่ละประเภท</Typography>
                  <ChartDonutCustom
                    series={Object.keys(dashboardOvertime.otTotal).map(ot => dashboardOvertime.otTotal[ot].payTotal)}
                    labels={Object.keys(dashboardOvertime.otTotal).map(ot => `OT x${ot}`)}
                  />
                </CardContent>
              </StyledCard> */}
            </Grid>

            <Grid item xs={12} lg={4}>
              
              <CardDashboard>
                <Typography marginBottom="16px">5 อันดับ ทำงานล่วงเวลาเกิน 36 ชม.</Typography>
                {dashboardOvertime.employeeOTOver36Total.top5EmployeeOver36.length > 0 && (
                  <Box style={{display: "flex", gap: "16px", flexDirection: "column"}}>
                    {dashboardOvertime.employeeOTOver36Total.top5EmployeeOver36.map((e, index) => (
                      <Box key={index} className="top-employee">
                        <Icon className={`fa-solid fa-square-${index+1}`} color="primary" style={{display: "inline-table", color: index <= 2? "#ffcd38": undefined}}/>
                        <Box style={{flexGrow: "1", display: "flex", alignItems: "center"}}>
                          <Avatar src={e.imageProfile} style={{marginRight: "8px", width: "32px", height: "32px"}}/>
                          <Typography>{e.firstname} {e.lastname}</Typography>
                        </Box>
                        <Typography>{e.over36Total} ครั้ง</Typography>
                      </Box>
                    ))}
                  </Box>
                )}
                {dashboardOvertime.employeeOTOver36Total.top5EmployeeOver36.length <= 0 && (
                  <Typography textAlign="center" color="text.third">ไม่มีการทำเกิน 36 ชม.</Typography>
                )}
              </CardDashboard>
            </Grid>

          </Grid>
        </Grid>


      </Grid>
    </StyledRoot>
  )
}

export default OvertimePanel;