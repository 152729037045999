import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import UserPage from "./user/homepage";
import AdminPage from "./admin/homepage";
import HomePageVendor from "./vendor/homepage";
import ManagerPage from "./manager/homepage";
import HomePageTeamAnt from "./ant-team/homepage";
import HomePageAccount from "./admin-accountant/homepage";
import Backdrop from "./shared/general/Backdrop";

const Homepage = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const render = () => {
    if (currentUser.roles.includes("ROLE_USER")) {
      return <UserPage />;
    } else if (currentUser.roles.includes("ROLE_ADMIN")) {
      return <AdminPage />;
    } else if (currentUser.roles.includes("ROLE_VENDOR")) {
      return <HomePageVendor />;
    } else if (currentUser.roles.includes("ROLE_MANAGER")) {
      return <ManagerPage />;
    } else if (currentUser.roles.includes("ROLE_ANT")) {
      return <HomePageTeamAnt />;
    } else if (currentUser.roles.includes("ROLE_ACCOUNT")) {
      return <HomePageAccount />;
    }
  };

  return (
    <div>
      {currentUser && userProfile ? (
        <Fragment>{render()}</Fragment>
      ) : (
        <Fragment>
          <Backdrop open={!(currentUser && userProfile)} />
        </Fragment>
      )}
    </div>
  );
};

export default Homepage;
