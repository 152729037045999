import React from "react";
import { Dialog, styled, Typography, Grid, DialogTitle, Divider, DialogContent, Button, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const StyledRoot = styled(Dialog)({

})

const DeleteModal = (props) => {

    const { deleteModal, setDeleteModal } = props;

    const onClose = () => {
        setDeleteModal({
            ...deleteModal,
            isOpen: false,
            companyCode: null
        })
    }

    return(
        <StyledRoot open={deleteModal.isOpen} onClose={onClose}>
            <DialogTitle>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography>ยืนยันการลบ</Typography>
                    <IconButton
                        size="small"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Typography variant="h5">ยืนยันที่จะลบหรือไม่ ?</Typography>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Button 
                        onClick={() => {
                            //ลบบริษัทลูก
                            console.log("delete", deleteModal.companyCode)
                            setDeleteModal({
                                isOpen: false,
                                companyCode: null
                            })
                        }}
                        variant="outlined"
                        color="error"
                    >
                        ลบ
                    </Button>
                    <Button onClick={onClose} variant="contained">ยกเลิก</Button>
                </Grid>
            </DialogContent>
        </StyledRoot>
    )
}

export default DeleteModal;