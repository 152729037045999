import { Avatar, Box, Card, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Fab } from "@mui/material";
import { reportLists } from "./all-reports";
import { useTranslation } from "react-i18next";

const StyledWrapMenuApp = styled("div")(({ }) => ({
    position: "relative",
    backgroundColor: '#fff',
    "& .header": {
        marginBottom: 16,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .MuiTypography-root": {
            fontWeight: 500,
            "& span": {
                letterSpacing: 0.5,
            },
        }
    },
    "& .app-list": {
        display: "grid",
        gridTemplateRows: "1fr auto auto",
        gridTemplateColumns: "repeat(4, 1fr)",
        gridAutoRows: 0,
        minWidth: 350,
        columnGap: 8,
        ["@media only screen and (max-width: 600px)"]: {
            gridTemplateRows: "1fr auto auto",
            gridTemplateColumns: "repeat(1, 1fr)",
        },
        ["@media only screen and (min-width:600px)"]: {
            gridTemplateRows: "1fr auto auto",
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        ["@media only screen and (min-width:768px)"]: {
            gridTemplateRows: "1fr auto auto",
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        ["@media only screen and (min-width:992px)"]: {
            gridTemplateRows: "1fr auto auto",
            gridTemplateColumns: "repeat(4, 1fr)",
        },
        ["@media only screen and (min-width:1200px)"]: {
            gridTemplateRows: "1fr auto auto",
            gridTemplateColumns: "repeat(4, 1fr)",
        },

        "& .app-item": {
            marginTop: 24,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
    },
}));

const StyledCard = styled(Card)({
    //width: 150,
    boxShadow: "none",
    borderRadius: 16,
    border: "1px solid #919eab3d",
    margin: 8,
    userSelect: "none",
    cursor: "pointer",
    "&.gray": {
        filter: "grayscale(100%)",
    },
    "&:hover": {
        cursor: "pointer",
        transition:
            "color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,transform 0.15s ease-in-out",
        transform: "translateY(-5px)",
    },
    "& .inner": {
        padding: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        "& div": {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
        },
        "& .MuiTypography-subtitle": {
            fontSize: 22,
            lineHeight: 1.2,
        },
        "& .MuiTypography-caption": {
            lineHeight: 1.2,
            fontSize: 14,
        },
        "& .MuiAvatar-root": {
            width: 100,
            height: 100,
            marginBottom: 16,
        },
    },
});

const StyledGrid = styled(Grid)({
    display: "flex",
    justifyContent: "center", // Center align the items horizontally
    ["@media only screen and (max-width: 600px)"]: {
        height: "100%",
    },
    ["@media only screen and (max-width: 375px)"]: {
        height: 368,
    },
    ["@media only screen and (max-width: 360px)"]: {
        height: 448,
    },
    ["@media only screen and (min-width: 600px)"]: {
        //height: 480,
    },
});

const StyledFab = styled(Fab)({
    display: "none",
    ["@media only screen and (max-width: 600px)"]: {
        display: "inline-flex",
        position: "fixed",
        right: 8,
        bottom: 16,
    },
});

export default function ReportListsPage() {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);

    const StyledRoot = styled("div")({
        backgroundColor: "#FFFFFF !important",
    });

    const StyledWrapHeader = styled("div")({
        paddingTop: 20,
        paddingBottom: 60,
    });

    const goDocumentReport = (link) => {
        history.push(`/document-report/${link}`);
        window.location.reload();
    }

    return (
        <div style={{ backgroundColor: '#fff' }}>
            <Container maxWidth="lg" sx={{ mt: 8 }}>
                <StyledWrapHeader>
                    {currentUser && currentUser.roles.includes("ROLE_USER") === true || currentUser.roles.includes("ROLE_VENDOR") ? (
                        <StyledWrapMenuApp>
                            <div className="header">
                                <Typography color="text.secondary">{t("GovernmentReport")}</Typography>
                            </div>
                            <StyledGrid container spacing={2}>
                                {reportLists
                                    && reportLists.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={3} key={index}>
                                            <StyledCard
                                                onClick={() => {
                                                    if (
                                                        item.class !== "gray"
                                                    ) {
                                                        if (item.state) {
                                                            history.push(item.link, item.state);
                                                        } else {
                                                            goDocumentReport(item.link)
                                                        }
                                                    }
                                                }}
                                                className={
                                                    item.class
                                                }
                                            >
                                                <div className="inner">
                                                    <Avatar variant="rounded" src={item.image} style={{ pointerEvents: "none" }} />
                                                    <div>
                                                        <Typography
                                                            variant="subtitle"
                                                            textAlign={"center"}
                                                            gutterBottom
                                                            whiteSpace={"pre-line"}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </StyledCard>
                                        </Grid>
                                    ))}
                            </StyledGrid>
                        </StyledWrapMenuApp>
                    ) : null}

                </StyledWrapHeader>
            </Container>
        </div>
    )
}