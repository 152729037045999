import payrunsExportService from "../services/payruns-export.service";

export const getAllPayrollListByMonth = async (query) => {
    try {
        const res = await payrunsExportService.getAllPayrollListByMonth(query);
        if (res) {
            return res;
        }
    } catch (error) {
        return error;
    }
};

export const getAllCostcenterListByMonth = async (query) => {
    try {
        const res = await payrunsExportService.getAllCostcenterListByMonth(query);
        if (res) {
            return res;
        }
    } catch (error) {
        console.error(error);
        return error;
    }
};
