import React, { useEffect, useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

import {
  Grid,
  Typography,
  FormControl,
  Box,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

import DrawerCustom from "../../../../shared/general/Drawer";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import AddIcon from "../../../../assets/add.png";

import { uploadFileHoliday } from "../../../../../../actions/holiday";

import ShowResponse from "./show-response";
import FileSelected from "./file-selected";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyleBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: 20,
});

const StyleSelect = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    padding: "0px 12px",
    "& .MuiSelect-select": {
      padding: "13.5px 0px",
    },
  },
});

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const StyledLoading = styled("div")({
  height: 200,
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const DialogUpload = (props) => {
  const { open, toggleDrawer } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: vendorContract } = useSelector(
    (state) => state.vendorContract
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [fileSelected, setFileSelected] = useState(null);
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [openLoading, setOpenLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.map((file) => {
        formData.append("file", file);
        formData.append("year", 2022);
      });
      setFormFileSelected(formData);
      setFileSelected({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    },
    maxFiles: 1,
  });

  const handleClickUpload = async () => {
    setOpenLoading(true);

    //console.log(companySelected)
    const result = await dispatch(uploadFileHoliday(formFileSelected));
    if (result) {
      setErrorResponse(result.data);
      setOpenLoading(false);
      console.log(result);
    }
  };

  const handleCloseLoading = () => {
    //setOpenLoading(false);
    toggleDrawer(false);
  };

  return (
    <DrawerCustom
      title={"อัพโหลดรายการวันหยุด"}
      anchor={"right"}
      open={open}
      onClose={toggleDrawer(false)}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {openLoading ? (
              <StyledLoading>
                <Typography variant="h6" color="text.third" gutterBottom>
                  กำลังบันทึกข้อมูล...
                </Typography>
                <div>
                  <CircularProgress />
                </div>
              </StyledLoading>
            ) : (
              <Fragment>
                {errorResponse ? (
                  <ShowResponse
                    errorResponse={errorResponse}
                    handleCloseLoading={toggleDrawer(false)}
                  />
                ) : (
                  <Fragment>
                    <div>
                      <StyledImportFile>
                        <div
                          {...getRootProps({
                            className: "dropzone-upload-file",
                          })}
                        >
                          <input {...getInputProps()} />
                          <div className="inner-dropzone">
                            <img alt="AddIcon" src={AddIcon} width="80" />
                            <Typography
                              style={{
                                marginTop: 8,
                                backgroundColor: "transparent",
                              }}
                              className={`${
                                fileSelected != 0 && `placeholderLabel`
                              }`}
                              variant="body2"
                              color="text.secondary"
                            >
                              Drag & Drop หรือ คลิ๊กเพื่อเลือกไฟล์
                            </Typography>
                          </div>
                        </div>
                      </StyledImportFile>
                    </div>

                    {fileSelected && (
                      <FileSelected
                        fileSelected={fileSelected}
                        handleClickUpload={handleClickUpload}
                      />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Grid>
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogUpload;
