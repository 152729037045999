import React, { useEffect, useState, Fragment } from "react";
import { NavLink, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  CardContent,
  Typography,
  Container,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import ExposureIcon from "@mui/icons-material/Exposure";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

import HeaderPage from "../../../shared/header/headerPage";
import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DatePickerEng from "../../../shared/general/DatePickerEng";
import DatePickerThai from "../../../shared/general/DatePickerThai";

import { allPayruns } from "../../../../../actions/payruns";

import TableList from "./tableList";
import DialogNew from "./new";
import DialogUpload from "./upload-payroll";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .inner-header-name": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .btn-setting": {
    marginLeft: 8,
  },
  "& .search-date": {
    width: 250,
    marginBottom: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
});

const StyledCardContent = styled(CardContent)({});

const PayRunsPage = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payRunList } = useSelector((state) => state.payRun);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openDialogNew, setOpenDialogNew] = React.useState(false);
  const [openDialogUpload, setOpenDialogUpload] = React.useState(false);
  const [isPayroll, setIsPayroll] = useState(null);
  const [search, setSearch] = useState(new Date());

  const mode = [
    {
      id: 1,
      header: `รายการเงินเดือน(แบบร่าง)`,
      listPayrun: [],
      isDraft: 1,
      isApprove: 0,
      isPaid: 0,
    },
    {
      id: 2,
      header: `${t("AccountingPeriod")}`,
      listPayrun: [],
      isDraft: 0,
      isApprove: 1,
      isPaid: 0,
    },
    // {
    //   id: 3,
    //   header: "จ่ายเงินแล้ว",
    //   listPayrun: [],
    //   isDraft: 0,
    //   isApprove: 1,
    //   isPaid: 1,
    // },
  ];

  useEffect(() => {
    if (userProfile) {
      dispatch(allPayruns(dayjs(new Date()).format("YYYY")));
      if (
        userProfile.isSuperAdmin === 1 ||
        (userProfile.confidentialData.length === 1 &&
          (userProfile.confidentialData[0].idCompany === null ||
            userProfile.confidentialData[0].idCompany !== null) &&
          userProfile.confidentialData[0].isPayroll === 1)
      ) {
        setIsPayroll(true);
      } else if (userProfile.confidentialData.length > 1) {
        const foundIsPayroll = userProfile.confidentialData.find(
          (x) => x.isPayroll === 1
        );
        if (foundIsPayroll) {
          setIsPayroll(true);
        } else {
          setIsPayroll(false);
        }
      } else {
        setIsPayroll(false);
      }
    }
  }, [userProfile]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialogNew = () => {
    setAnchorEl(null);
    setOpenDialogNew(true);
  };

  const handleCloseDialogNew = () => {
    setOpenDialogNew(false);
  };

  const handleOpenDialogUpload = () => {
    setAnchorEl(null);
    setOpenDialogUpload(true);
  };

  const handleCloseDialogUpload = () => {
    setOpenDialogUpload(false);
  };

  return (
    <Fragment>
      {userProfile && (
        <Fragment>
          {isPayroll === false ? (
            <Route>
              <Redirect to="/unauthorized" />
            </Route>
          ) : (
            <StyledRoot className={`page`}>
              <Container maxWidth="lg">
                <div className="wrap-header">
                  <Fragment>
                    <div className="inner-header-name">
                      <HeaderPage textLabel="จัดการเงินเดือน" />
                      <div>
                        <ButtonBlue
                          className="btn-setting"
                          size="small"
                          component={NavLink}
                          to="/payroll/setting"
                          variant="outlined"
                          color="secondary"
                          startIcon={<SettingsRoundedIcon />}
                        >
                          ตั้งค่า
                        </ButtonBlue>
                      </div>
                    </div>

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: 8 }}>
                        <ButtonBlue
                          variant={"outlined"}
                          component={NavLink}
                          to="/payroll/addition-deduction"
                          startIcon={<ExposureIcon />}
                        >
                          รายการเงินได้ - เงินหัก
                        </ButtonBlue>
                      </div>
                      <div style={{ marginLeft: 8 }}>
                        <ButtonBlue
                          variant={"contained"}
                          startIcon={<AddIcon />}
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        >
                          {t("NewSalary")}
                        </ButtonBlue>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem onClick={handleOpenDialogNew}>
                            {t("NewSalary")}
                          </MenuItem>
                          <MenuItem onClick={handleOpenDialogUpload}>
                            Upload Payroll
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </Fragment>
                </div>
                {payRunList && (
                  <div className="search-date">
                    {localStorage.getItem("language") === "en" ? (
                      <DatePickerEng
                        value={search}
                        inputFormat="YYYY"
                        minDate={dayjs(today).set("year",payRunList.resultMinYear)}
                        maxDate={dayjs(today)}
                        name="search"
                        views={["year"]}
                        height={48}
                        onChange={(newValue) => {
                          setSearch(newValue);
                          dispatch(allPayruns(dayjs(newValue).format("YYYY")));
                        }}
                      />
                    ) : (
                      <DatePickerThai
                        value={search}
                        name="search"
                        minDate={dayjs(today).set("year",payRunList.resultMinYear)}
                        maxDate={dayjs(today)}
                        inputFormat="yyyy"
                        views={["year"]}
                        height={48}
                        onChange={(newValue) => {
                          setSearch(newValue);
                          dispatch(allPayruns(dayjs(newValue).format("YYYY")));
                        }}
                      />
                    )}
                  </div>
                )}

                <div style={{ paddingBottom: 16 }}>
                  {mode.map((value, index) => {
                    return (
                      <div key={index} style={{ marginBottom: 16 }}>
                        <StyledCard>
                          <StyledCardContent>
                            <div>
                              <Typography variant="h6" gutterBottom>
                                {value.header}
                              </Typography>
                            </div>
                            <div>
                              {payRunList && (
                                <TableList
                                  payRunList={payRunList.resultData.filter(
                                    (item) => item.isDraft === value.isDraft
                                  )}
                                />
                              )}
                            </div>
                          </StyledCardContent>
                        </StyledCard>
                      </div>
                    );
                  })}
                </div>
                <DialogNew
                  open={openDialogNew}
                  handleClose={handleCloseDialogNew}
                />
                <DialogUpload
                  open={openDialogUpload}
                  handleClose={handleCloseDialogUpload}
                />
              </Container>
            </StyledRoot>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default PayRunsPage;
