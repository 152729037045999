import {
  ADDITIONS_FETCHING,
  ADDITIONS_FAILED,
  ADDITIONS_SUCCESS,
  ADDITIONS_COMPANY_FETCHING,
  ADDITIONS_COMPANY_FAILED,
  ADDITIONS_COMPANY_SUCCESS,
  ADDITIONS_VENDOR_FETCHING,
  ADDITIONS_VENDOR_FAILED,
  ADDITIONS_VENDOR_SUCCESS
} from "./types";

import AdditionService from "../services/addition.service";

export const addAddition = (formData) => async () => {
  try {
    const res = await AdditionService.addAddition(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getAllAdditions = () => async (dispatch) => {
  try {
    dispatch({
      type: ADDITIONS_FETCHING
    });
    const res = await AdditionService.getAllAdditions();
    if(res){
      dispatch({
        type: ADDITIONS_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_FAILED
    });
  }
};

export const getAllAdditionsByCompany = (idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITIONS_COMPANY_FETCHING
    });
    const res = await AdditionService.getAllAdditionsByCompany(idCompany);
    if(res){
      dispatch({
        type: ADDITIONS_COMPANY_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_COMPANY_FAILED
    });
  }
};

export const getAllAdditionsByVendor = (idVendor) => async (dispatch) => {
  try {
    dispatch({
      type: ADDITIONS_VENDOR_FETCHING
    });
    const res = await AdditionService.getAllAdditionsByVendor(idVendor);
    if(res){
      dispatch({
        type: ADDITIONS_VENDOR_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: ADDITIONS_VENDOR_FAILED
    });
  }
};
