import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const allHoliday = (id, year) => {
  return httpClient.get(`/company/${id}/holidayYear/${year}`);
};

const getHoliday = (id, filter) => {
  return httpClient.get(
    `/company/${id}/holiday?start=${dayjs(filter.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
  );
};

const allHolidayByIdVendor = (id, year) => {
  return httpClient.get(`/vendor/${id}/holiday?year=${year}`);
};

const getHolidayByIdVendor = (id, filter) => {
  return httpClient.get(
    `/vendor/${id}/holiday?start=${dayjs(filter.start).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(filter.end).format("YYYY-MM-DD")}`
  );
};

const postHoliday = (data) => {
  return httpClient.post(`/company/holiday/postHoliday`, data);
};

const putHoliday = (id, data) => {
  return httpClient.post(`/company/holiday/putHoliday/${id}`, data);
};

const deleteHoliday = (id, data) => {
  return httpClient.post(`/company/holiday/deleteHoliday/${id}`, data);
};

const uploadFileHoliday = (formData) => {
  return httpClient.post(`/holiday`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
   }) ;
};

export default {
  allHoliday,
  getHoliday,
  allHolidayByIdVendor,
  getHolidayByIdVendor,
  postHoliday,
  putHoliday,
  deleteHoliday,
  uploadFileHoliday
};
