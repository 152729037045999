import React, { Fragment, useState } from "react";
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import FormContainer from "./FormContainer";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@tarzui/date-fns-be";
import { th } from "date-fns/locale";
import { useSelector } from "react-redux";
import DialogAddPosition from "./DialogAddPosition";
import { useTranslation } from "react-i18next";

const JobInfo = (props) => {
  const { companySelect, control, errors, setValue, watch } = props;
  const { t } = useTranslation();

  const { result: empDataForm } = useSelector((state) => state.empDataForm);

  const [isOpenDialogAddPosition, setIsOpenDialogAddPosition] = useState(false);

  return (
    <FormContainer title={`${t("Job_Description")}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("HiringDate")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
            <Controller 
              control={control}
              name="hiringDate"
              render={({ field }) => (
                <DatePicker 
                  {...field}
                  openTo="year"
                  views={["year", "month", "day"]}
                  renderInput={(params) => (
                    <TextFieldTheme
                      {...params}
                      error={
                        errors && errors.hiringDate ? true : false
                      }
                      helperText={
                        errors && errors.hiringDate && errors.hiringDate.message
                      }
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD/MM/YYYY",
                      }}
                      fullWidth
                    />
                  )}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("OwnerCompany")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="idVendor"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.idVendor ? true : false}
                helperText={errors && errors.idVendor && errors.idVendor.message}
                fullWidth
                select
              >
                {empDataForm && empDataForm.vendorList && 
                  empDataForm.vendorList.map((item) => (
                    <MenuItem key={item.idVendor} value={item.idVendor}>
                      {item.vendorName}
                    </MenuItem>
                  ))
                }
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Position")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="idPositions"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  const foundJob = empDataForm.job.find(
                    (x) => x.idPositions === event.target.value
                  );
                  setValue("division", (foundJob && foundJob.divisionName) ? foundJob.divisionName : "-");
                  setValue("department", (foundJob && foundJob.departmentName) ? foundJob.departmentName : "-");
                  setValue("section", (foundJob && foundJob.sectionName) ? foundJob.sectionName : "-");
                  setValue("jobGroup", (foundJob && foundJob.jobGroupName) ? foundJob.jobGroupName : "-");
                }}
                error={errors && errors.idPositions ? true : false}
                helperText={errors && errors.idPositions && errors.idPositions.message}
                fullWidth
                select
              >
                {empDataForm && empDataForm.job && 
                  empDataForm.job.map((item) => (
                  <MenuItem key={item.idPositions} value={item.idPositions}>
                    {item.positionsName}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Division")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="division"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                fullWidth
                variant="outlined"
                disabled={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Department")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="department"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                fullWidth
                variant="outlined"
                disabled={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Section")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="section"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                fullWidth
                variant="outlined"
                disabled={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("JobGroup")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="jobGroup"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                fullWidth
                variant="outlined"
                disabled
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("JobLevel")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="idJobLevel"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                fullWidth
                select
                variant="outlined"
                error={errors && errors.idJobLevel ? true : false}
                helperText={errors && errors.idJobLevel && errors.idJobLevel.message}
              >
                {empDataForm && empDataForm.jobLevel && 
                empDataForm.jobLevel.map((item) => (
                  <MenuItem key={item.idJobLevel} value={item.idJobLevel}>
                    {item.jobLevelName}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("PersonalLevel")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="idPersonnelLevel"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                fullWidth
                select
                variant="outlined"
                error={errors && errors.idPersonnelLevel ? true : false}
                helperText={errors && errors.idPersonnelLevel && errors.idPersonnelLevel.message}
              >
                {empDataForm && empDataForm.jobLevel && 
                empDataForm.jobLevel.map((item) => (
                  <MenuItem key={item.idJobLevel} value={item.idJobLevel}>
                    {item.jobLevelName}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("EmployeeType")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="idEmployeeType"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                fullWidth
                select
                variant="outlined"
                error={errors && errors.idEmployeeType ? true : false}
                helperText={errors && errors.idEmployeeType && errors.idEmployeeType.message}
              >
                {empDataForm && empDataForm.employeeType && 
                empDataForm.employeeType.map((item) => (
                  <MenuItem key={item.idEmployeeType} value={item.idEmployeeType}>
                    {item.employeeTypeName}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-radio">{t("FieldOffice")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl component="fieldset" fullWidth>
            <Controller 
              control={control}
              name="filed_office"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                >
                  <Grid container>
                    <Grid item md={6}>
                      <FormControlLabel
                        value="Field"
                        control={<Radio />}
                        label="Field"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel
                        value="Office"
                        control={<Radio />}
                        label="Office"
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              )}
            />
            {(errors && errors.filed_office && errors.filed_office.message) &&
              <FormHelperText error>
                {errors.filed_office.message}
              </FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-radio">{t("WorkingType")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl component="fieldset" fullWidth>
            <Controller 
              control={control}
              name="workingType"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                >
                  <Grid container>
                    <Grid item md={6}>
                      <FormControlLabel
                        value="Day"
                        control={<Radio />}
                        label="Day"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel
                        value="Shift"
                        control={<Radio />}
                        label="Shift"
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              )}
            />
            {(errors && errors.workingType && errors.workingType.message) &&
              <FormHelperText error>
                {errors.workingType.message}
              </FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{`${t("ShiftType")}/${t("ShiftName")}`}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="idShift"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                onChange={(event) => {
                  const foundShift = (empDataForm && empDataForm.shift) ? empDataForm.shift.find(
                    (x) => x.idShift === event.target.value
                  ) : null;
                  field.onChange(event);
                  if(foundShift){
                    setValue("idShiftGroup", (foundShift && foundShift.idShiftGroup) ? foundShift.idShiftGroup : "");
                  }
                }}
                error={errors && errors.idShift ? true : false}
                helperText={errors && errors.idShift && errors.idShift.message}
                fullWidth
                select
              >
                {empDataForm && empDataForm.shift && 
                empDataForm.shift.map((item) => (
                  <MenuItem key={item.idShift} value={item.idShift}>
                    {`${item.nameShiftGroup} : ${item.nameShift}`}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("WorkingLocation")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="workingLocationName"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                fullWidth
                error={errors && errors.workingLocationName ? true : false}
                helperText={errors && errors.workingLocationName && errors.workingLocationName.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("MainWorkingLocation")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="mainWorkingLocationPoint"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                fullWidth
                select
                error={errors && errors.mainWorkingLocationPoint ? true : false}
                helperText={errors && errors.mainWorkingLocationPoint && errors.mainWorkingLocationPoint.message}
              >
                {empDataForm && empDataForm.groupGpsLocations && 
                empDataForm.groupGpsLocations.map((item) => (
                  <MenuItem
                    key={item.idGroupGpsLocations}
                    value={item.idGroupGpsLocations}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Finger")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="isFinger"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.isFinger ? true : false}
                helperText={errors && errors.isFinger && errors.isFinger.message}
                fullWidth
                select
              >
                <MenuItem value="1">{t("Yes")}</MenuItem>
                <MenuItem value="0">{t("No")}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("MethodAttendance")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="methodAttendance"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.methodAttendance ? true : false}
                helperText={errors && errors.methodAttendance && errors.methodAttendance.message}
                fullWidth
                select
              >
                <MenuItem value="main">{t("MainWorkingLocation")}</MenuItem>
                <MenuItem value="point">{t("Coordinates")}</MenuItem>
                <MenuItem value="all">{t("AllLocation")}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-radio">{t("ContractTerminate")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl component="fieldset" fullWidth>
            <Controller 
              control={control}
              name="isContractTerminate"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                >
                  <Grid container>
                    <Grid item md={6}>
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label={`${t("Unspecified")}`}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={`${t("DateSpecify")}`}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              )}
            />
            {(errors && errors.isContractTerminate && errors.isContractTerminate.message) &&
              <FormHelperText error>
                {errors.isContractTerminate.message}
              </FormHelperText>
            }
          </FormControl>
        </Grid>
        {watch("isContractTerminate") === "1" && (
          <Fragment>
            <Grid item xs={12} md={4}>
              {/* <Typography>วันที่เริ่มงาน</Typography> */}
            </Grid>
            <Grid item xs={12} md={8}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
                <Controller 
                  control={control}
                  name="contractTermainatoinDate"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      openTo="year"
                      views={["year", "month", "day"]}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          error={errors && errors.contractTermainatoinDate ? true : false}
                          helperText={errors && errors.contractTermainatoinDate && errors.contractTermainatoinDate.message}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD/MM/YYYY",
                          }}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Fragment>
        )}
      </Grid>
      <DialogAddPosition
        open={isOpenDialogAddPosition}
        onClose={() => {
          setIsOpenDialogAddPosition(false);
        }}
      />
    </FormContainer>
  );
};

export default JobInfo;
