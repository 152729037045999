import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Typography, Grid, FormControl, Select, MenuItem } from "@mui/material";

import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import DrawerCustom from "../../../../shared/general/Drawer";
import { getVendorProfile } from "../../../../../../actions/vendor";
import { updatePerformance } from "../../../../../../actions/performance";
import { employeeAddPerformance, getEmployeeProfile } from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: "16px 0px",
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogEdit = (props) => {
  const { open, setOpenEdit } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: vendorPerformance } = useSelector(
    (state) => state.vendorProfile
  );

  const [state, setState] = useState({
    resultPerformance: "",
  });

  useEffect(() => {
    dispatch(getVendorProfile(null, "performance"));
  }, []);

  useEffect(() => {
    if (employeeProfile && employeeProfile.performance.length > 0) {
      setState({
        resultPerformance: employeeProfile.performance[0].resultPerformance,
        idEmployeesPerformance: employeeProfile.performance[0].idEmployeesPerformance,
      });
    }
  }, [employeeProfile]);

  const handleSave = () => {
    let dataForm = { ...state };
    dataForm.idEmp = employeeProfile.idEmp;
    dataForm.yearPerformance = parseInt(
      dayjs(today).subtract(1, "year").locale("en").format("YYYY")
    );
    dataForm.createBy = userProfile.idUsers;

    const result = dispatch(employeeAddPerformance(dataForm));
    if (result) {
      setOpenEdit(false);
      dispatch(getEmployeeProfile(employeeProfile.idEmp));
    }
  };

  return (
    <DrawerCustom
      title={t("EditWorks")}
      anchor={"right"}
      open={open}
      onClose={() => setOpenEdit(false)}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={3}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>
              {dayjs(today)
                .subtract(1, "year")
                .locale(localStorage.getItem("language") || "th")
                .format(
                  localStorage.getItem("language") === "th" ? "BBBB" : "YYYY"
                )}
            </StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={9}>
            {vendorPerformance && (
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="resultPerformance"
                  value={state.resultPerformance}
                  onChange={(event) => {
                    setState({
                      ...state,
                      ["resultPerformance"]: event.target.value,
                    });
                  }}
                >
                  {vendorPerformance["performanceOne"] && (
                    <MenuItem value={vendorPerformance["performanceOne"]}>
                      {vendorPerformance["performanceOne"]}
                    </MenuItem>
                  )}
                  {vendorPerformance["performanceTwo"] && (
                    <MenuItem value={vendorPerformance["performanceTwo"]}>
                      {vendorPerformance["performanceTwo"]}
                    </MenuItem>
                  )}
                  {vendorPerformance["performanceThree"] && (
                    <MenuItem value={vendorPerformance["performanceThree"]}>
                      {vendorPerformance["performanceThree"]}
                    </MenuItem>
                  )}
                  {vendorPerformance["performanceFour"] && (
                    <MenuItem value={vendorPerformance["performanceFour"]}>
                      {vendorPerformance["performanceFour"]}
                    </MenuItem>
                  )}
                  {vendorPerformance["performanceFive"] && (
                    <MenuItem value={vendorPerformance["performanceFive"]}>
                      {vendorPerformance["performanceFive"]}
                    </MenuItem>
                  )}
                  {vendorPerformance["performanceSix"] && (
                    <MenuItem value={vendorPerformance["performanceSix"]}>
                      {vendorPerformance["performanceSix"]}
                    </MenuItem>
                  )}
                </Select>
              </StyledFormControl>
            )}
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue className="cancel" onClick={() => setOpenEdit(false)}>
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSave} autoFocus>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
