import { Box, Grid, Typography, styled } from "@mui/material";
import React, { useEffect } from "react";
import DrawerCustom from "../../../shared/general/Drawer";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addMDOffice,
  getOrganizationStructure,
} from "../../../../../actions/organizationStructure";
import { useDispatch, useSelector } from "react-redux";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const DialogEditCEO = (props) => {
  const { drawerConfig, onClose, setAlertConfig } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const validateYupSchema = yup.object({
    mdOfficeName: yup.string().required("กรุณากรอกข้อมูล"),
    costCenter: yup.string(),
  });

  const useHookForm = useForm({
    defaultValues: {
      mdOfficeName: "",
      costCenter: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  const onSubmit = async (data) => {
    const formData = {
      idMDOffice: drawerConfig.data.idMDOffice || null,
      mdOfficeName: data.mdOfficeName,
      costCenter: data.costCenter,
      idCompany: userProfile.idCompany,
    };

    const result = await dispatch(addMDOffice(formData));

    if (result) {
      if (result.status === 200) {
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: `${t("DataSaveSuccessful")}`,
        }));
        dispatch(
          getOrganizationStructure({ idCompany: userProfile.idCompany })
        );
        onClose();
      } else {
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: `${t("AnErrorOccurred")} ${t("ContactAdmin")}`,
        }));
      }
    } else {
      setAlertConfig((prev) => ({
        ...prev,
        isOpen: true,
        type: "error",
        message: `${t("AnErrorOccurred")} ${t("ContactAdmin")}`,
      }));
    }
  };

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      if (drawerConfig.isEdit) {
        useHookForm.reset({
          mdOfficeName: drawerConfig.data.name || "",
          costCenter: drawerConfig.data.costCenter || "",
        });
      } else {
        useHookForm.reset({
          mdOfficeName: "",
          costCenter: "",
        });
      }
    }
  }, [drawerConfig.isOpen]);

  return (
    <DrawerCustom
      title={
        drawerConfig.isEdit
          ? `${t("Edit")} CEO Office`
          : `${t("Add")} CEO Office`
      }
      anchor={"right"}
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  {t("Name")}
                </Typography>
              </Box>
              <Controller
                name="mdOfficeName"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                marginBottom="4px"
                display="flex"
                justifyContent="space-between"
              >
                <Typography fontSize="14px" fontWeight="500">
                  Cost Center
                </Typography>
              </Box>
              <Controller
                name="costCenter"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    InputProps={{
                      placeholder: `(${t("Optional")})`,
                    }}
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} container gap="8px" justifyContent="flex-end">
              <ButtonBlue onClick={onClose}>{t("Cancle")}</ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("SaveData")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEditCEO;
