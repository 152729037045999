import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Paper, Typography } from "@mui/material";
import DataTable from "./components/DataTable";
import AddOrEditModal from "./components/AddOrEditModal";
import { useTranslation } from "react-i18next";

import { getAllVendorCompanyLeaveType } from "../../../../../actions/leave";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import AddIcon from "@mui/icons-material/Add";
import DrawerLeave from "./components/drawerLeave";

import CardStyle from "../../../shared/general/Card";
import AccordionRow from "./components/accordionRow";
import LeaveForm from "./components/leaveForm";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
});

function Index(props) {
  const { company } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: leaveWorkStore } = useSelector((state) => state.leaveWorks);
  const { result: leaveTypeStore } = useSelector((state) => state.leaveType);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [leaveWorks, setLeaveWorks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [leaveTypes, setLeaveTypes] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const [addOrEditModal, setAddOrEditModal] = useState({
    isOpen: false,
    leaveWorks: [],
  });

  const [isOpenDrawerLeave, setIsOpenDrawerLeave] = useState(false);
  const [leave, setLeave] = useState(null);
  const [type, setType] = useState("create");

  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: null,
  });

  const [deleteConfig, setDeleteConfig] = useState({
    isOpen: false,
    data: {},
  });

  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  });

  useEffect(() => {
    dispatch(
      getAllVendorCompanyLeaveType({
        idCompany: company.idCompany,
        idVendor: userProfile.idVendor,
      })
    );
    if (leaveTypeStore !== null) {
      let leaveTypeList = [...leaveTypeStore].reverse();
      setLeaveTypes(leaveTypeList);
    }
    setIsLoading(false);
    console.log(leaveTypeStore, "l");
  }, [company]);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (alertType) => {
    setAlertType(alertType);
  };

  // useEffect(() => {
  //   if (selectedCompany) {
  //     // dispatch(getAllLeaveV2({ idCompany: selectedCompany.idCompany }));
  //     // dispatch(
  //     //   getEmploymentTypeByidCompany({ idCompany: selectedCompany.idCompany })
  //     // );
  //     // dispatch(getLevelByidCompany({ idCompany: selectedCompany.idCompany }));
  //     handleCloseDrawer();
  //   }
  // }, [selectedCompany]);

  const getLeaveType = () => {
    if (leaveTypes) {
      return leaveTypes;
    } else {
      return [];
    }
  };

  const handleOpenDrawer = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: true,
      isEdit: false,
      data: {},
    }));
  };

  const handleCloseDrawer = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleCloseDeleteModal = () => {
    setDeleteConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleDeleteLeaveType = async () => {
    // const response = await dispatch(
    //   deleteLeaveGroup(deleteConfig.data.idLeaveGroup)
    // );
    const response = "";

    if (response) {
      if (response.status === 200) {
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "success",
          message: `${t("DeletedSuccessfully")}`,
        }));
        // dispatch(getAllLeaveV2({ idCompany: selectedCompany.idCompany }));
        handleCloseDeleteModal();
      } else {
        setAlertConfig((prev) => ({
          ...prev,
          isOpen: true,
          type: "error",
          message: `${t("CantDeletedPleaseContactAdmin")}`,
        }));
      }
    } else {
      setAlertConfig((prev) => ({
        ...prev,
        isOpen: true,
        type: "error",
        message: `${t("AnErrorOccurred")} ${t("PleaseContactAdmin")}`,
      }));
    }

    setDeleteConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };
  return (
    <div className={classes.root}>
      {!drawerConfig.isOpen && (
        <>
          <StyledHeadingPage>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">{t("LeaveDay")}</Typography>
              </Grid>
              <Grid item>
                <ButtonBlue
                  variant={"contained"}
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setIsOpenDrawerLeave(true);
                    setType("create");
                    setDrawerConfig((prev) => ({
                      ...prev,
                      isOpen: true,
                      isEdit: false,
                    }));
                  }}
                >
                  {t("AddLeaveDay")}
                </ButtonBlue>
              </Grid>
            </Grid>
          </StyledHeadingPage>
          <CardStyle style={{ padding: 16 }}>
            {/* <DataTable
          leaveTypes={leaveTypes}
          isLoading={isLoading}
          addOrEditModal={addOrEditModal}
          setAddOrEditModal={setAddOrEditModal}
          handleOpen={() => {
            setIsOpenDrawerLeave(true);
          }}
          setLeave={setLeave}
          setType={setType}
        /> */}
            {leaveTypeStore && leaveTypeStore.length > 0 ? (
              leaveTypeStore.map((item, index) => (
                <AccordionRow
                  data={item}
                  key={`accordion_${index}`}
                  setDrawerConfig={setDrawerConfig}
                  setDeleteConfig={setDeleteConfig}
                  setAlertType={setAlertType}
                  setOpenAlert={setOpenAlert}
                  selectedCompany={company}
                />
              ))
            ) : (
              <Typography style={{ textAlign: "center", color: "red" }}>
                {t("NoData")}
              </Typography>
            )}
          </CardStyle>
        </>
      )}

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {/* {isOpenDrawerLeave && (
        <DrawerLeave
          company={company}
          open={isOpenDrawerLeave}
          handleClose={() => {
            setIsOpenDrawerLeave(false);
          }}
          handleOpenAlert={handleOpenAlert}
          handleChangeAlertType={handleChangeAlertType}
          leave={leave}
          type={type}
        />
      )} */}
      {drawerConfig.isOpen && (
        <LeaveForm
          drawerConfig={drawerConfig}
          onClose={handleCloseDrawer}
          setAlertType={setAlertType}
          setOpenAlert={setOpenAlert}
          selectedCompany={company}
        />
      )}
    </div>
  );
}

export default Index;
