import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Divider,
  TextField,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(5),
    width: "95%",
    maxHeight: 800,
    "& .MuiGrid-container": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiTypography-body1": {
      fontWeight: 500,
    },
  },
  dialogTitle: {
    color: "#16243D",
    padding: theme.spacing(1),
  },
  dialogContent: {
    padding: "16px 8px 24px 8px",
  },
  dialogActions: {
    marginTop: theme.spacing(1),
  },
}));

const initialValues = () => ({
  instituteId: 0,
  instituteName: "",
  layer: "",
  parentId: "",
  costCenter: "",
  costElement: "",
  io: "",
  createBy: "Admin",
  createDate: new Date(),
});

const Placeholder = ({ children }) => {
  return <div style={{ color: "#9e9e9e" }}>{children}</div>;
};

function AddOrEditModal(props) {
  const { institutes, addOrEditModal, setAddOrEditModal } = props;
  const classes = useStyles();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({
    instituteName: false,
  });
  const [parentSelected, setParentSelected] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleParentSelected = (e, newValue) => {
    console.log(newValue.instituteName);
    setParentSelected(newValue);
    setValues({
      ...values,
      parentId: newValue.instituteName,
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    console.log("Data Submit", values);
  };

  useEffect(() => {
    if (addOrEditModal.isOpen) {
      setErrors({
        instituteName: false,
      });
      if (addOrEditModal.institutes.instituteId === undefined) {
        setParentSelected([]);
        setValues(initialValues);
      } else {
        if (addOrEditModal.institutes.parentId !== "") {
          //Set ParentId
          var newArray = institutes.filter(function (item) {
            return item.instituteName.includes(
              addOrEditModal.institutes.parentId
            );
          });

          setParentSelected(newArray[0]);
        } else {
          setParentSelected([]);
        }

        setValues(addOrEditModal.institutes);
      }
    }
  }, [addOrEditModal]);

  return (
    <Dialog open={addOrEditModal.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              เพิ่มหน่วยงาน
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              onClick={() =>
                setAddOrEditModal({ ...addOrEditModal, isOpen: false })
              }
            >
              <CloseIcon className={classes.closeBtn} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="body1">Name</Typography>
            <TextField
              variant="outlined"
              name="instituteName"
              value={values.instituteName}
              onChange={handleInputChange}
              margin="dense"
              fullWidth
              error={errors.instituteName}
              helperText={
                errors.instituteName ? "This Field is required" : null
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">Leyer</Typography>
            <FormControl margin="dense" fullWidth>
              <Select
                displayEmpty
                value={values.layer}
                onChange={(e) =>
                  setValues({ ...values, layer: e.target.value })
                }
                renderValue={
                  values.layer !== ""
                    ? undefined
                    : () => <Placeholder>Select Level</Placeholder>
                }
              >
                <MenuItem value={"CEO"}>CEO</MenuItem>
                <MenuItem value={"Division"}>Division</MenuItem>
                <MenuItem value={"Department"}>Department</MenuItem>
                <MenuItem value={"Section"}>Section</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">Parent</Typography>
            <Autocomplete
              value={parentSelected}
              options={institutes}
              getOptionLabel={(option) =>
                option.instituteName ? option.instituteName : ""
              }
              onChange={handleParentSelected}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  placeholder="Select Parent"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">Cost Center</Typography>
            <TextField
              variant="outlined"
              name="costCenter"
              value={values.costCenter}
              onChange={handleInputChange}
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">Cost Element</Typography>
            <TextField
              variant="outlined"
              name="costElement"
              value={values.costElement}
              onChange={handleInputChange}
              margin="dense"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="body1">IO</Typography>
            <TextField
              variant="outlined"
              name="io"
              value={values.io}
              onChange={handleInputChange}
              margin="dense"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              startIcon={<SaveIcon />}
              onClick={handleOnSubmit}
            >
              Submit
            </ButtonBlue>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AddOrEditModal;
