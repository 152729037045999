import { httpClient } from "./httpClient";

const getDashboardOverview = (query) => {
  console.log("1")
  return httpClient.get(`/dashboard/overview`, {params: query});
};

const getDashboardOvertime = (query) => {
  // console.log(query)
  return httpClient.get(`/dashboard/overtime`, {params: query});
};

const getDashboardCost = (query) => {
  return httpClient.get(`/dashboard/cost`, {params: query});
};

const getDashboardWorkingtime = (query) => {
  return httpClient.get(`/dashboard/workingtime`, {params: query});
};

const getDashboardData = (query) => {
  return httpClient.get(`/dashboard/data`, {params: query});
}

export default {
  getDashboardOverview,
  getDashboardOvertime,
  getDashboardCost,
  getDashboardWorkingtime,
  getDashboardData,
};
