import React from "react";
import { Grid, InputAdornment, MenuItem, Typography } from "@mui/material";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import FormContainer from "./FormContainer";
import { useTranslation } from "react-i18next";
import { Controller } from 'react-hook-form';
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";

const PayrollInfo = (props) => {
  const { control, errors } = props;
  const { t } = useTranslation();

  return (
    <FormContainer title={`${t("PayrollInfo")}`}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("Salary")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="salary"
            render={({ field }) => (
              <TextFieldTheme 
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  thousandSeparator:",",
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  },
                }}
                error={errors && errors.salary ? true : false}
                helperText={errors && errors.salary && errors.salary.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("ChargeSalary")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller 
                control={control}
                name="chargeSalary"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={`${t("Salary")}`}
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      max:100,
                      min:0
                    }}
                    error={errors && errors.chargeSalary ? true : false}
                    helperText={errors && errors.chargeSalary && errors.chargeSalary.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller 
                control={control}
                name="chargeOt"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={`${t("OT")}`}
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      max:100,
                      min:0
                    }}
                    error={errors && errors.chargeOt ? true : false}
                    helperText={errors && errors.chargeOt && errors.chargeOt.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller 
                control={control}
                name="chargeHire"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={`${t("ChargeHire")}`}
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      max:100,
                      min:0
                    }}
                    error={errors && errors.chargeHire ? true : false}
                    helperText={errors && errors.chargeHire && errors.chargeHire.message}
                  />
                )}
              />
              
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller 
                control={control}
                name="chargeCompensation"
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label={`${t("ChargeCompensation")}`}
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatTheme,
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    inputProps={{
                      max:100,
                      min:0
                    }}
                    error={errors && errors.chargeCompensation ? true : false}
                    helperText={errors && errors.chargeCompensation && errors.chargeCompensation.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">Cost Center</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="costCenterCharge"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.costCenterCharge ? true : false}
                helperText={errors && errors.costCenterCharge && errors.costCenterCharge.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">Cost Element</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="costElementCharge"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.costElementCharge ? true : false}
                helperText={errors && errors.costElementCharge && errors.costElementCharge.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("BookBank")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="bookBank"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.bookBank ? true : false}
                helperText={errors && errors.bookBank && errors.bookBank.message}
                fullWidth
                select
              >
                <MenuItem value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</MenuItem>
                <MenuItem value="ธนาคารกรุงไทย">ธนาคารกรุงไทย</MenuItem>
                <MenuItem value="ธนาคารกรุงศรีอยุธยา">ธนาคารกรุงศรีอยุธยา</MenuItem>
                <MenuItem value="ธนาคารกสิกรไทย">ธนาคารกสิกรไทย</MenuItem>
                <MenuItem value="ธนาคารทหารไทยธนชาต">ธนาคารทหารไทยธนชาต</MenuItem>
                <MenuItem value="ธนาคารไทยพาณิชย์">ธนาคารไทยพาณิชย์</MenuItem>
                <MenuItem value="ธนาคารซีไอเอ็มบี ไทย">ธนาคารซีไอเอ็มบี ไทย</MenuItem>
                <MenuItem value="ธนาคารยูโอบี">ธนาคารยูโอบี</MenuItem>
                <MenuItem value="ธนาคารออมสิน">ธนาคารออมสิน</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("BookBankBranch")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="bookBankBranch"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.bookBankBranch ? true : false}
                helperText={errors && errors.bookBankBranch && errors.bookBankBranch.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("BookBankID")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="bookBankId"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                fullWidth
                InputProps={{
                  inputComponent: NumberFormatTheme
                }}
                inputProps={{
                  max:100,
                  min:0,
                  allowLeadingZeros: true,
                  allowNegative: false
                }}
                error={errors && errors.bookBankId ? true : false}
                helperText={errors && errors.bookBankId && errors.bookBankId.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("ReportBankName")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="reportBank_BankName"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                fullWidth
                error={errors && errors.reportBank_BankName ? true : false}
                helperText={errors && errors.reportBank_BankName && errors.reportBank_BankName.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("ReportBankID")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="reportBank_BankId"
            render={({ field }) => (
              <TextFieldTheme 
                {...field}
                error={errors && errors.reportBank_BankId ? true : false}
                helperText={errors && errors.reportBank_BankId && errors.reportBank_BankId.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("ReportBookBankID")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="reportBank_BookBankId"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.reportBank_BookBankId ? true : false}
                helperText={errors && errors.reportBank_BookBankId && errors.reportBank_BookBankId.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("ReportBankPayneeName")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="reportBank_Name"
            render={({ field }) => (
              <TextFieldTheme
                {...field} 
                error={errors && errors.reportBank_Name ? true : false}
                helperText={errors && errors.reportBank_Name && errors.reportBank_Name.message}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className="topic-textField">{t("ReportBankRef")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller 
            control={control}
            name="reportBank_Ref"
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={errors && errors.reportBank_Ref ? true : false}
                helperText={errors && errors.reportBank_Ref && errors.reportBank_Ref.message}
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </FormContainer>
  );
};

export default PayrollInfo;
