import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled, Tab, Tabs } from "@mui/material";
import Profile from "./profile";
import Expertise from "./expertise";
import Price from "./price";
import Service from "./service";
import AddressPanel from "./addressPanel";
import BankPanel from "./bankPanel";
import { useTranslation } from "react-i18next";

import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

const StyledRoot = styled("div")({
  padding: 24,
  "& .MuiTabs-root": {
    paddingBottom: 24,
  },
  "& .icon": {
    fontSize: 16,
  },
});

const RightContainer = (props) => {
  const { result: vendorProfile } = useSelector((state) => state.vendorProfile);
  const [tabValue, setTabValue] = useState(0);
  const { t, i18n } = useTranslation();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <StyledRoot>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab
          icon={<BusinessOutlinedIcon />}
          iconPosition="start"
          label={t("CompanyInfo")}
        ></Tab>
        <Tab
          icon={<i className="fa-solid fa-map-location-dot icon" />}
          iconPosition="start"
          label={t("Address")}
        ></Tab>
        <Tab
          icon={<EmojiEventsOutlinedIcon />}
          iconPosition="start"
          label={t("Expertise")}
        ></Tab>
        <Tab
          icon={<ReceiptOutlinedIcon />}
          iconPosition="start"
          label={t("Expenses")}
        ></Tab>
        <Tab
          icon={<VolunteerActivismOutlinedIcon />}
          iconPosition="start"
          label={t("Service")}
        ></Tab>
        <Tab
          icon={<AccountBalanceIcon />}
          iconPosition="start"
          label={t("BookBank")}
        ></Tab>
      </Tabs>

      {tabValue === 0 && <Profile data={vendorProfile} />}
      {tabValue === 1 && <AddressPanel data={vendorProfile} />}
      {tabValue === 2 && <Expertise data={vendorProfile} />}
      {tabValue === 3 && <Price data={vendorProfile} />}
      {tabValue === 4 && <Service data={vendorProfile} />}
      {tabValue === 5 && <BankPanel data={vendorProfile} />}
    </StyledRoot>
  );
};

export default RightContainer;
