import React, { Component } from "react";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  Polygon,
  InfoWindow,
  Circle,
} from "google-maps-react";
//import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Box, Typography } from "@mui/material";

const mapStyles = {
  position: "relative",
  width: "100%",
  height: "500px",
  borderRadius: "10px",
};

const infoWindowStyles = {
  paddingRight: "10px",
  paddingLeft: "10px",
};

const textWindowStyles = {
  fontSize: "18px",
  marginTop: 0,
  marginBottom: 0,
};

export class MapContainer extends Component {
  state = {
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false,
    isLoading: true,
    currentLocation: {
      lat: 0,
      lng: 0,
    },
    companyArea: [
      { lat: 12.706941, lng: 101.135534 },
      { lat: 12.703241, lng: 101.133505 },
      { lat: 12.699737, lng: 101.133377 },
      { lat: 12.6995844, lng: 101.1372722 },
      { lat: 12.7069945, lng: 101.1373152 },
      { lat: 12.706941, lng: 101.135534 },
    ],
    companyLocation: [
      {
        lat: 12.70313,
        lng: 101.13537,
      },
    ],
  };

  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
  };

  onCheckLocationPoly = (myLat, myLng) => {
    const { google } = this.props;
    const maps = google.maps;

    const companyPolygon = new maps.Polygon({
      paths: this.state.companyArea,
    });

    maps.geometry.poly.containsLocation(
      new maps.LatLng(
        myLat,
        myLng
        //12.7022,
        //101.13541
      ),
      companyPolygon
    )
      ? this.props.setMessLocation({
          inArea: true,
          message: "คุณอยู่ในพื้นที่ทำงาน",
        })
      : this.props.setMessLocation({
          inArea: false,
          message: "คุณอยู่นอกพื้นที่ทำงาน",
        });
  };

  onCheckLocationArea = (myLat, myLng) => {
    const { google } = this.props;
    const maps = google.maps;

    //area radius 500 meter
    var searchAreaRadius = 500;

    //use for test only
    //var myLocation = new google.maps.LatLng(12.70313, 101.13537);

    var myLocation = new google.maps.LatLng(myLat, myLng);

    var marker_lat_lng = new google.maps.LatLng(
      this.state.companyLocation[0].lat,
      this.state.companyLocation[0].lng
    );

    var distance_from_location =
      google.maps.geometry.spherical.computeDistanceBetween(
        myLocation,
        marker_lat_lng
      );

    console.log("distance_from_location", distance_from_location);

    //compare distance with searchArea
    if (distance_from_location <= searchAreaRadius) {
      this.props.setMessLocation({
        inArea: true,
        message: "คุณอยู่ในพื้นที่ทำงาน",
      });
    } else {
      this.props.setMessLocation({
        inArea: false,
        message: "คุณอยู่นอกพื้นที่ทำงาน",
      });
    }
  };

  onMapLoad = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            acc: position.coords.accuracy,
          };

          console.log("pos", pos);

          this.setState({
            currentLocation: {
              lat: pos.lat,
              lng: pos.lng,
            },
          });

          //Check Location in Poly
          this.onCheckLocationPoly(pos.lat, pos.lng);

          //Check Location in Area Circle
          //this.onCheckLocationArea(pos.lat, pos.lng);

          //Show map
          this.setState({
            isLoading: false,
          });
        },
        () => {
          console.log("Error: The Geolocation service failed.");
        }
      );
    }
  };

  componentDidMount() {
    this.onMapLoad();
    console.log(this.state.companyArea);
  }

  render() {
    const companyArea = [
      { lat: 12.706941, lng: 101.135534 },
      { lat: 12.703241, lng: 101.133505 },
      { lat: 12.699737, lng: 101.133377 },
      { lat: 12.6995844, lng: 101.1372722 },
      { lat: 12.7069945, lng: 101.1373152 },
      { lat: 12.706941, lng: 101.135534 },
    ];

    const companyLocation = {
      lat: 12.70313,
      lng: 101.13537,
    };

    return (
      <>
        {this.state.isLoading ? (
          <Box>Loading...</Box>
        ) : (
          <Box>
            <Map
              google={this.props.google}
              style={mapStyles}
              containerStyle={{ position: "relative" }}
              className={"map"}
              zoom={16}
              initialCenter={this.state.currentLocation}
            >
              <Polygon
                paths={companyArea}
                strokeColor={
                  this.props.messLocation.inArea ? "#2e7d32" : "#f44336"
                }
                strokeOpacity={0.8}
                strokeWeight={2}
                fillColor={
                  this.props.messLocation.inArea ? "#2e7d32" : "#f44336"
                }
                fillOpacity={0.35}
              />

              <Marker onClick={this.onMarkerClick} name={"คุณอยู่ที่นี่"} />

              <InfoWindow
                marker={this.state.activeMarker}
                onClose={this.onInfoWindowClose}
                visible={this.state.showingInfoWindow}
              >
                <div style={infoWindowStyles}>
                  <h1 style={textWindowStyles}>
                    {this.state.selectedPlace.name}
                  </h1>
                </div>
              </InfoWindow>
            </Map>
          </Box>
        )}
      </>
    );
  }
}

// function MyMapComponent() {
//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: "AIzaSyBJEmVrMq5Zgj27dilri-OK76pn7pgQCho"
//   })

//   return (
//     <>
//       {isLoaded ? (
//         <Box>
//           <Typography align="center">Map Loading...</Typography>
//         </Box>
//       ) : (
//         <Box>
//           {/* <Map
//             google={this.props.google}
//             style={mapStyles}
//             containerStyle={{ position: "relative" }}
//             className={"map"}
//             zoom={16}
//             initialCenter={this.state.currentLocation}
//           >
//             <Polygon
//               paths={this.state.paragon}
//               strokeColor="#231f20"
//               strokeOpacity={0.8}
//               strokeWeight={2}
//               fillColor="#231f20"
//               fillOpacity={0.35}
//             />
//             <Marker onClick={this.onMarkerClick} name={"คุณอยู่ที่นี่"} />

//             <InfoWindow
//               marker={this.state.activeMarker}
//               onClose={this.onInfoWindowClose}
//               visible={this.state.showingInfoWindow}
//             >
//               <div style={infoWindowStyles}>
//                 <h1 style={textWindowStyles}>
//                   {this.state.selectedPlace.name}
//                 </h1>
//               </div>
//             </InfoWindow>
//           </Map> */}
//         </Box>
//       )}
//     </>
//   );
// }

// export default React.memo(MyMapComponent)

export default GoogleApiWrapper({
  //apiKey: "AIzaSyCZ41b0hPCVif4tewQUlrc2aivCyZf2DWc",
  apiKey: "AIzaSyBJEmVrMq5Zgj27dilri-OK76pn7pgQCho",
  libraries: ["geometry"],
})(MapContainer);
