import React, { useState } from "react";
import Chart from "react-apexcharts";

const rand036 = () => {
  return Math.floor(Math.random() * 37);
};

export default function ChartsOT36HoursWeek() {
  const [ot36Hours, setOt36Hours] = useState(rand036());
  const [series, setseries] = useState([(ot36Hours / 36) * 100]);
  const [optionsChart, setOptionsChart] = React.useState({
    chart: {
      id: "chartOT36Hours",
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "60%",
        },
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#eeeff1",
          strokeWidth: "95%",
        },
        dataLabels: {
          name: {
            show: true,
            color: "#888",
            fontSize: "16px",
            fontFamily:
              "Roboto Kanit 'Helvetica Neue' Arial sans-serif 'Apple Color Emoji' 'Segoe UI Emoji' 'Segoe UI Symbol'",
          },
          value: {
            offsetY: -40,
            fontSize: "48px",
            fontFamily:
              "Roboto Kanit 'Helvetica Neue' Arial sans-serif 'Apple Color Emoji' 'Segoe UI Emoji' 'Segoe UI Symbol'",
            formatter: function (val, opts) {
              console.log(opts);
              return ot36Hours;
            },
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      opacity: 1,
      colors: [
        function ({ value, seriesIndex, w }) {
          if (value < 25) {
            return "#00c292";
          } else if (value >= 25 && value < 50) {
            return "#fec90c";
          } else if (value >= 50 && value < 75) {
            return "#fbb976";
          } else {
            return "#e56a75";
          }
        },
      ],
      // type: "gradient",
      // gradient: {
      //   type: 'horizontal',
      //   shadeIntensity: 1,
      //   opacityFrom: 0.7,
      //   opacityTo: 0.9,
      //   colorStops: [
      //     {
      //       offset: 0,
      //       color: "#95DA74",
      //       opacity: 1,
      //     },
      //     {
      //       offset: 33,
      //       color: "#FAD375",
      //       opacity: 1,
      //     },
      //     {
      //       offset: 66,
      //       color: "#f29472",
      //       opacity: 1,
      //     },
      //     {
      //       offset: 100,
      //       color: "#EB656F",
      //       opacity: 1,
      //     },
      //   ],
      // },
      // stroke: {
      //   lineCap: "round",
      // },
    },
    labels: ["ชั่วโมง"],
  });

  return <Chart options={optionsChart} series={series} type="radialBar" />;
}
