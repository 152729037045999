import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";

import TableRequest from "./table";

const TimeAttendance = (props) => {
  const { searchName } = props;
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    if (requestTimeList) {
      setDataTable(
        requestTimeList.filter((x) => {
          if(
            x.idRequestType === 1 && (
              (x.isDoubleApproval === 1 && (x.isManagerLV1Approve !== null && x.isManagerLV2Approve !== null)) ||
              (x.approvalLevel === 1 && x.isManagerLV1Approve !== null) ||
              (x.approvalLevel === 2 && x.isDoubleApproval === 0 && x.isManagerLV2Approve !== null) || 
              x.isActive === 0
            ) && (
              searchName && searchName.length > 0 ? 
                (
                  x.firstname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0 ||
                  x.lastname.toUpperCase().indexOf(searchName.toUpperCase()) >= 0
                )
                : true
            )
          ){
            return true;
          }else{
            return false;
          }
        })
      );
    }
  }, [requestTimeList, searchName]);

  return (
    <Fragment>
      {requestTimeList && dataTable && (
        <TableRequest data={dataTable} searchName={searchName} />
      )}
    </Fragment>
  );
};

export default TimeAttendance;
